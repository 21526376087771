import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getAllMasterDrug,
  getAllSupplierList,
  getAllDrugTypes,
  getAllDrugCategories,
  getPurchaseInfoRecord,  
  addPurchaseInfo,
  updatePurchaseInfo,
  deletePurchaseInfo,
  getDrugPlanningDetails,
  getAllDrugMeasures,
  getTimeoutbycategory
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";

import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
// Import React Table
import "react-table/react-table.css";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Spinner } from "../../components/UI/Spinner";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import FooterSection from "../../components/UI/FooterSection";
import { ExpirePopup } from "../../components/UI/ExpirePopup";
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity
  };
}
const onlineOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "In-active" },
];

class InpRoomTransfer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
      
            drug_id_list:[],
      records: [],
      masterdrugmeasure:[],
      searchrecords: [],
      purchase_action: "Add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      showexpirePopup: this.props.validity,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      newDatatoAdd: {},
      addPopup: false,
      drugList: {
        s_no: "",
        drug_id: "",
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      purchaseinfo: {
        id: "",
        drug_id: "",
        pir:"",
        supplier_id:"",
        category:"",
        type:"",
        order_unit:"",        
        manufacturer: "",
        min_lot: "",
        max_lot: "",
        discount: "",
        gst: "",
        price_per_unit: "",
        status:""
      },
      errors: {
        id: "",
        drug_id: "",
        pir:"",
        supplier_id:"",
        category:"",
        type:"",
        order_unit:"",        
        manufacturer: "",
        min_lot: "",
        max_lot: "",
        discount: "",
        gst: "",
        price_per_unit: "",
        status:""
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
      columns: [
        { title: "Drug Id", field: "drug_id" },
        { title: "Drug Name", field: "drug_name" },
        { title: "Drug Category", field: "drug_category" },
        { title: "Drug Type", field: "drug_type" },
        { title: "Manufacturer", field: "manufacturer" },
        { title: "Active Ingredients", field: "active_ingredients" },
      ],
     
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      
      },
      {
        key: "category",
        text: "Category",
        className: "pir",
        align: "left",
        sortable: true,
      
      },
     
      {
        key: "facility_id",
        text: "Facility ID",
        className: "facility_id",
        align: "left",
        sortable: true,
        
      },
      {
        key: "drug_id",
        text: "Facility Type",
        className: "drug_id",
        align: "left",
        sortable: true,
       
      },

      {
        key: "category",
        text: "Ward",
        className: "category1",
        sortable: true,
        align: "left",
        
      },

      {
        key: "type",
        text: "Floor",
        className: "type",
        sortable: true,
        align: "left",
        
      },

   /*    {
        key: "manufacturer",
        text: "Manufacturer",
        className: "manufacturer",
        sortable: true,
        align: "left",
        
      },

      {
        key: "order_unit",
        text: "Order Unit",
        className: "order_unit",
        sortable: true,
        align: "left",
       
      },

      {
        key: "min_lot",
        text: "Min Lot",
        className: "min_lot",
        sortable: true,
        align: "left",
        
      },
      {
        key: "max_lot",
        text: "Max Lot",
        className: "max_lot",
        sortable: true,
        align: "left",
        
      },

      {
        key: "discount",
        text: "Discount(%)",
        className: "discount",
        sortable: true,
        align: "left",
        
      },
      {
        key: "gst",
        text: "Tax Percentage(%)",
        className: "gst",
        sortable: true,
        align: "left",
        
      },


      {
        key: "price_per_unit",
        text: "Price Per Unit",
        className: "price_per_unit",
        sortable: true,
        align: "left",
        
      },
      {
        key: "status",
        text: "Purchaseinfo Status",
        className: "status sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.status,
            value: record.status,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="status_obj"
              value={obj}
              onChange={() => this.handleStatusColumnChange(record)}
              options={onlineOptions}
            />
          );
        },
      }, */
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

   
  }

  handleCategoryColumnChange = (value, record, field) => {
    // console.log(field);
    // console.log(value);
    // console.log(record);
    value[field] = record;
    // console.log(record);
    // record.availability = "Yes" === record.availability ? "No" : "Yes";
    this.UpdateColumnChange(value);
  };
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;

    this.UpdateColumnChange(record);
  };
  UpdateColumnChange = (record) => {
    console.log(record);
    record.user_id = this.props.userdata.id;
    axios.post(updatePurchaseInfo, record, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", record.id);

      records[recIndex].drug_id = record.drug_id;
      records[recIndex].supplier_id = record.supplier_id;
      records[recIndex].category = record.category;
      records[recIndex].type = record.type;
      records[recIndex].manufacturer = record.manufacturer;
      records[recIndex].order_unit= record.order_unit;
      records[recIndex].min_lot=record.min_lot;
      records[recIndex].max_lot = record.max_lot;
      records[recIndex].discount = record.discount;
      records[recIndex].gst = record.gst;
      records[recIndex].price_per_unit = record.price_per_unit;
      records[recIndex].status = record.status;
      records[recIndex].user_id = record.user_id;
      records[recIndex].id = record.id;

      this.setState({ records, tableSuccess: list.data });
      toast.success("Purchase info updated ",{
        className:"toast-success"
      });
      this.clearMessage();
  });
  };
  componentDidMount = () => {
    
    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
        axios
          .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status === "1")
              this.setState({
                hospitaltimeout: data.data.timeout
    
              });
          })
          .catch(() => { });
    

    axios
      .post(getDrugPlanningDetails, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let drugList = list.data.data;
        let drugDataArr = [];
        
        drugList.forEach((element, index) => {
          console.log(element);
          drugDataArr.push({ value: element.drug_id, label: element.drug_id,category:element.drug_category,type:element.drug_type,manufacturer:element.manufacturer });
        });;

        this.setState({ drug_id_list: drugDataArr });
      })
      .catch(() => { });

      axios
            .post(getAllSupplierList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let SupplierList = list.data.data;
        let SupplierDataArr = [];
        
        SupplierList.forEach((element, index) => {
          
          SupplierDataArr.push({ value: element.id, label: element.supplier_id,discount: element.discount, gst: element.gst });
        });;

        this.setState({ supplier_id_list: SupplierDataArr });
      })
      .catch(() => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugTypes, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element, index) => {
          console.log(element);
          drugTypes.push({ value: element.drug_type, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch((e) => { });
     
      axios
        .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
        .then((drugCategoryResponse) => {
          let list = drugCategoryResponse.data.data;
  
          let drugCategories = [];
          list.forEach((element) => {
            drugCategories.push({
              value: element.drug_category,
              label: element.drug_category,
            });
          });
          this.setState({ drugCategories });
        })
        .catch(() => { });
 
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getPurchaseInfoRecord, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        
        let purchaseinfo = list.data.data;
        let purchaseinfoArr = [];
        console.log(purchaseinfo)
        purchaseinfo.forEach((element, index) => {
          let purchaseinfoData = {};
          purchaseinfoData = element;
          purchaseinfoData.s_no = index + 1;
          purchaseinfoArr[index] = purchaseinfoData;
        });
console.log(purchaseinfoArr);
        this.setState({ records: purchaseinfoArr });
      })
      .catch((e) => { });


    axios
      .post(getAllDrugMeasures, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugmeasureList = drugMasterRespons.data.data;
        console.log(masterdrugmeasureList);
        let masterdrugmeasure = [];
        masterdrugmeasureList.forEach((element, index) => {
          masterdrugmeasure.push({
            value: element.unit_of_measure,
            label: element.unit_of_measure,

          });
        });

        this.setState({ masterdrugmeasure });
      })
      .catch((e) => { });
  };
 
  editRecord(record) {
    let purchase_action = this.state.purchase_action;
    purchase_action = "Edit";
    this.setState({ purchase_action });

    let purchaseinfo = this.state.purchaseinfo;
    purchaseinfo.s_no = record.s_no;
    purchaseinfo.id = record.id;
    purchaseinfo.pir = record.pir;

    purchaseinfo.drug_id = {
      label: record.drug_id,
      value: record.drug_id,
    };


    
    purchaseinfo.supplier_id = {
      label: record.supplier_id,
      value: record.supplier_id,
    };


    
    purchaseinfo.order_unit = {
      label: record.order_unit,
      value: record.order_unit,
    };
    purchaseinfo.manufacturer = record.manufacturer;
    purchaseinfo.min_lot = record.min_lot;
    purchaseinfo.max_lot = record.max_lot;
    purchaseinfo.status = {
      label: record.status,
      value: record.status,
    };

    purchaseinfo.category = {
      label: record.category,
      value: record.category,
    };
    purchaseinfo.type = {
      label: record.type,
      value: record.type,
    };


    
    
    purchaseinfo.discount = record.discount;
    purchaseinfo.gst = record.gst;
    purchaseinfo.price_per_unit = record.price_per_unit;
    this.setState({ purchaseinfo: purchaseinfo });
  }

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.purchaseinfo;
    data[e.name] = selectedOptions;
    console.log(selectedOptions);
    if (e.name === "supplier_id") {
      data.discount = selectedOptions.discount;
      data.gst = selectedOptions.gst;
      
    }
     else if(e.name === "drug_id"){
      data.manufacturer = selectedOptions.manufacturer;
      data.category = {label:selectedOptions.category,value:selectedOptions.category};
      data.type = {label:selectedOptions.type,value:selectedOptions.type};
      
    } else {
      data[e.name] = selectedOptions;
    }
    console.log(data)
    this.setState({ purchaseinfo: data });
  };
  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;

    // if (e.name === "drug_name") {
    //   data.manufacturer = selectedOptions.manufacturer;
    //   data.active_ingredients = selectedOptions.active_ingredients;
    // } else {
    //   data[e.name] = selectedOptions;
    // }
    // if (e.name === "manufacturer" || e.name === "active_ingredients") {
    //   data[e.name] = selectedOptions.target.value;
    // } else {
    data[e.name] = selectedOptions;
    // }
    this.setState({ searchData: data });
  };

  handleInput = (e) => {
    let data = this.state.purchaseinfo;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ purchaseinfo: data });
  };

 

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deletePurchaseInfo, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        // this.setState({ tableSuccess: res.data });
        console.log(response.message);
        toast.success(response.message, {
          className: "toast-success"
        });
        this.clearMessage();
      })
      .catch((e) => { });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearErrors = (errors) => {
    errors.drug_id = "";
    errors.supplier_id = "";
    errors.category = "";
    errors.type = "";
    errors.order_unit = "";
    errors.manufacturer = "";
    errors.min_lot = "";
    errors.max_lot = "";
    errors.discount = "";
    errors.gst = "";
    errors.price_per_unit = "";
    errors.status = "";
    
    return errors;
};
  

  cancelPurchaseinfo = () => {
    let data = {
      id: "",
      drug_id: "",
      pir:"",
      supplier_id:"",
      category:"",
      type:"",
      order_unit:"",        
      manufacturer: "",
      min_lot: "",
      max_lot: "",
      discount: "",
      gst: "",
      price_per_unit: "",
      status:""
    };
   
   
   let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ purchaseinfo: data, actions: "Add", errors: errors });
};
changepurchaseinfo = () => {
  
    let {purchaseinfo,errors,purchase_action} = this.state;
    
    
console.log(purchaseinfo,errors,purchase_action);
    // this.setState({ loading: true });
  
    purchaseinfo.page = "addpurchaseinfo";
    console.log(purchaseinfo);
    errors = Validation(purchaseinfo, errors);
    this.setState({errors: errors});
    console.log(errors);//return false;
   
    if (errors.formIsValid) {
      let data = {
        drug_id:purchaseinfo.drug_id.label,
        supplier_id:purchaseinfo.supplier_id.label,
        category:purchaseinfo.category.label,
        type:purchaseinfo.type.label,
        manufacturer:purchaseinfo.manufacturer,
        order_unit:purchaseinfo.order_unit.label,
        min_lot:purchaseinfo.min_lot,
        max_lot:purchaseinfo.max_lot,
        discount:purchaseinfo.discount,
        gst:purchaseinfo.gst,
        
        price_per_unit:purchaseinfo.price_per_unit,
        status:purchaseinfo.status.label,
        user_id:this.props.userdata.id
        
      }
      console.log(data);
if(purchase_action === "Add"){
  axios
  .post(addPurchaseInfo, data, CONFIG)
  .then((addPurchaseResponse) => {
    let res = addPurchaseResponse.data;
    console.log(res);
    if (res.status === "1") {
     
      this.setState({
        
        // searchTableSuccess: res,
        loading: false,
      });

      toast.success(res.message, {
        className: "toast-success"
      });

      let addpurchaseinfo = {};
                  addpurchaseinfo.supplier_id = data.supplier_id;
                  addpurchaseinfo.drug_id = data.drug_id;
                  addpurchaseinfo.manufacturer = data.manufacturer;
                  addpurchaseinfo.category = data.category;
                  addpurchaseinfo.type = data.type;
                  addpurchaseinfo.order_unit = data.order_unit;
                  addpurchaseinfo.min_lot = data.min_lot;
                  addpurchaseinfo.max_lot = data.max_lot;
                  addpurchaseinfo.discount=data.discount;
                  addpurchaseinfo.gst=data.gst;
                  addpurchaseinfo.price_per_unit=data.price_per_unit;
                  addpurchaseinfo.status=data.status;
                  addpurchaseinfo.user_id=data.user_id;
                  addpurchaseinfo.id = addPurchaseResponse.data.data[0].id;
                  addpurchaseinfo.pir = addPurchaseResponse.data.pir;
               //   addpurchaseinfo.id = list.data.data;

                  let records = this.state.records;
                  addpurchaseinfo.s_no = records.length + 1;
                  records.push(addpurchaseinfo);
                  this.setState({record:records});

      // setTimeout(
      //   () =>
          
      //       window.location.href = '/druglist'
      //   ,
      //   1000
      // );
      
    } else {
      this.setState({
        // searchrecords: records,
        // searchTableSuccess: res,
        loading: false,
      });
      toast.error(res.message, {
        className: "toast-error"
      });
      
    }
  })
  .catch(() => { });
} else {
  data.pir = purchaseinfo.pir;
  data.id = purchaseinfo.id;
  console.log(data);

  axios.post(updatePurchaseInfo, data, CONFIG).then((list) => {
    if ('0' === list.data.status) {
        this.setState({ loading: false });
        this.setState({ errors: { supplier_id: list.data.message } });
    } else {
        let records = this.state.records;

        var recIndex = findWithAttr(records, "id", data.id);
        console.log(recIndex);
        console.log(records);
        records[recIndex].supplier_id = data.supplier_id;
        records[recIndex].drug_id = data.drug_id;
        records[recIndex].category = data.category;
        records[recIndex].type = data.type;
        records[recIndex].status = data.status;
        records[recIndex].order_unit = data.order_unit;
        records[recIndex].min_lot = data.min_lot;
        records[recIndex].max_lot = data.max_lot;
        records[recIndex].price_per_unit = data.price_per_unit;
        records[recIndex].manufacturer = data.manufacturer;
        records[recIndex].gst = data.gst;
        records[recIndex].discount = data.discount;
        records[recIndex].user_id = data.user_id;
        records[recIndex].id = data.id;

       this.setState({ records });
       this.setState({ loading: false });
    toast.success("Purchase info updated. ",{
        className:"toast-success"
      });
        this.clearMessage();
        this.cancelPurchaseinfo();
    }
});
}
      
    }
   
  
}
handleStatusColumnChange = (record) => {
  record.status = "Active" === record.status ? "In-active" : "Active";
  this.UpdateColumnChange(record);
};
  render() {
    let Popupclose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    const {
      drug_id_list,
      drugTypes,
      drugCategories,
      supplier_id_list,
      purchaseinfo,
      errors,
      searchData,
      loading,
      formSuccess,
      searchTableSuccess,
      editformSuccess,
      tableSuccess,
      searchErrorMessage,
      masterdrugmeasure,
      hospitaltimeout,
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;

    return (
<div id="layout-wrapper">  
          {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />  */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

     {/*    <Spinner loading={loading} /> */}
     {loading && <Dnaspinner />}
        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
      
      <div className="main-content bacfot">
      <div className="mainpage">
        <Header user_id={this.props.userdata.id} />
        <div className="main-box">
          <div className="sidemenu pr0">
            <BusinessUserSideBar />
          </div>

          <section className="main-body hospital-profilegap">
           <div className="container-fluid">
            <div className="col-lg-13">
            <h4 className="font-weight-bold"> Room Transfer</h4>
            <div className="row ">
            <div className="col-sm-12">

<div className="card">
  <div className="card-body ">
  <div className="row">
         <div className="col-sm-3">
            <div className="row ">
            <div className="col-sm-4">
                          <div className="required">
                            <label className="f12">
                              
                             Total Beds
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"phone_number"}
                           //     controlFunc={this.handlePhoneInput}
                            //    content={this.state.searchData.phone_number}
                                placeholder={""}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                         //     title={this.state.searchData.error}
                            />

                          </div>
                        </div>
                      
          </div>
          </div>
          <div className="col-sm-3">
          <div className="row ">
            <div className="col-sm-3">
                          <div >
                            <label >
                              
                             Occupied
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"phone_number"}
                           //     controlFunc={this.handlePhoneInput}
                            //    content={this.state.searchData.phone_number}
                                placeholder={""}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                         //     title={this.state.searchData.error}
                            />

                          </div>
                        </div>
                      
          </div>
          </div>
          <div className="col-sm-3">
          <div className="row ">
            <div className="col-sm-3">
                          <div >
                            <label >
                              
                            Available
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"phone_number"}
                           //     controlFunc={this.handlePhoneInput}
                            //    content={this.state.searchData.phone_number}
                                placeholder={""}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                         //     title={this.state.searchData.error}
                            />

                          </div>
                        </div>
                      
          </div>
          </div>
          <div className="col-sm-3">
          <div className="row ">
            <div className="col-sm-4">
                          <div >
                            <label>
                              
                             Next Day Availablity
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"phone_number"}
                           //     controlFunc={this.handlePhoneInput}
                            //    content={this.state.searchData.phone_number}
                                placeholder={""}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                         //     title={this.state.searchData.error}
                            />

                          </div>
                        </div>
                      
          </div>
          </div>
          </div>
    </div>
  </div>
</div>
                      <div className="col-sm-12">

                        <div className="card">
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                               
                                    <div >
                                      <ReactDatatable
                                        config={this.config}
                                       records={this.state.records}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />
                                      <Popup
                                            show={this.state.addPopup}
                                            onHide={Popupclose}
                                            onConfirm={PopupConfirm}
                                            title={"Confirmation"}
                                            message={"Are you sure you want to delete this record?"}
                                        />

                      
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                <div className="col-sm-12 pr0">
                    <div className="card b-12-hite">
                        <div className="card-body">
                            <div className="user-notification">
                                <SpanField
                                    classname={
                                        0 === formSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                />
                            </div>
                            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                            <div>
                            <div class="card-header"> <div class="card-title">
                                    {this.state.purchase_action === "Add" ? "Add" : "Edit"}  Room Transfer
                  </div></div>
                               {/*  <hr className="rowline" /> */}
                                <div className="row">
                                   
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">From</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                 </div>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">To</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                 </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Fac Category</label>
                                            </div>
                                               

                                                <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                <Select
                                             className="form-control p-0"
                                             name="supplier_id"
                                             isMandatory={true}
                                             value={purchaseinfo.supplier_id}
                                             onChange={this.handleselectChange}
                                             options={supplier_id_list}
                                             />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.supplier_id}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Fac Category</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                            <InputField
                                                            inputType={"text"}
                                                            name={"manufacturer"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.manufacturer}
                                                            placeholder={""}
                                                        />
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.manufacturer}
                                                    />
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Tentative Discharge Date</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                            <InputField
                                                            inputType={"text"}
                                                            name={"discount"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.discount}
                                                            placeholder={""}
                                                        />
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.discount}
                                                    />
                                                
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Facility ID </label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                <Select
                                             className="form-control p-0"
                                             name="drug_id"
                                             isMandatory={true}
                                             value={purchaseinfo.drug_id}
                                             onChange={this.handleselectChange}
                                             options={drug_id_list}
                                             />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.drug_id}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                             </div>

                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Facility ID</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                <Select
                                             className="form-control p-0"
                                             name="order_unit"
                                             isMandatory={true}
                                             value={purchaseinfo.order_unit}
                                             onChange={this.handleselectChange}
                                             options={this.state.masterdrugmeasure}
                                             />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.order_unit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Status</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"gst"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.gst}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.gst}
                                                    />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                               </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Patient Name</label>
                                            </div>
                                            <div className="form-group frlrit">
                                            <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="category"
                                                           value={purchaseinfo.category}
                                                            onChange={this.handleselectChange}
                                                            options={drugCategories}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.category}
                                                    />
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Patient Name</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"min_lot"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.min_lot}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.min_lot}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Payment Status</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"price_per_unit"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.price_per_unit}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.price_per_unit}
                                                    />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Doctor Name</label>
                                            </div>
                                            <div className="form-group frlrit">
                                            <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="type"
                                                           value={purchaseinfo.type}
                                                            onChange={this.handleselectChange}
                                                            options={drugTypes}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.type}
                                                    />
                                                </div>

                                               
                                            </div>
                                            </div>
                                           </div>

                                           <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Doctor Name</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"max_lot"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.max_lot}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.max_lot}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                           </div>





                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Total Days of stay</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="status"
                                                           value={purchaseinfo.status}
                                                            onChange={this.handleselectChange}
                                                            options={onlineOptions}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.status}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Nurse/Therapist</label>
                                            </div>
                                            <div className="form-group frlrit">
                                            <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="type"
                                                           value={purchaseinfo.type}
                                                            onChange={this.handleselectChange}
                                                            options={drugTypes}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.type}
                                                    />
                                                </div>

                                               
                                            </div>
                                            </div>
                                           </div>

                                           <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Nurse/Therapist</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"max_lot"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.max_lot}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.max_lot}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                           </div>





                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Guest Name</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="status"
                                                           value={purchaseinfo.status}
                                                            onChange={this.handleselectChange}
                                                            options={onlineOptions}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.status}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Admission Date</label>
                                            </div>
                                            <div className="form-group frlrit">
                                            <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="type"
                                                           value={purchaseinfo.type}
                                                            onChange={this.handleselectChange}
                                                            options={drugTypes}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.type}
                                                    />
                                                </div>

                                               
                                            </div>
                                            </div>
                                           </div>

                                           <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Change Date</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"max_lot"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.max_lot}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.max_lot}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                           </div>





                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Emergency Contact</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="status"
                                                           value={purchaseinfo.status}
                                                            onChange={this.handleselectChange}
                                                            options={onlineOptions}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.status}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Current Price</label>
                                            </div>
                                            <div className="form-group frlrit">
                                            <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="type"
                                                           value={purchaseinfo.type}
                                                            onChange={this.handleselectChange}
                                                            options={drugTypes}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.type}
                                                    />
                                                </div>

                                               
                                            </div>
                                            </div>
                                           </div>

                                           <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">New Price</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"max_lot"}
                                                            controlFunc={this.handleInput}
                                                            content={purchaseinfo.max_lot}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.max_lot}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                           </div>





                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Emergency Contact</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="status"
                                                           value={purchaseinfo.status}
                                                            onChange={this.handleselectChange}
                                                            options={onlineOptions}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.status}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    




                                <div className="row">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-0 btn-align-center">
                                            {this.state.purchase_action === "Add" ? ( <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml btnaln"
                                                onClick={this.changepurchaseinfo}
                                            >
                                             Change
                                            </button>) : ( <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml btnaln"
                                                onClick={this.changepurchaseinfo}
                                            >
                                              Update
                                            </button>)}
                                            <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml"
                                                onClick={this.cancelPurchaseinfo}
                                            >
                                              Cancel
                                            </button>
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div></section>
        <FooterSection />
</div>
</div>
</div>
</div>





    );
}
}
export default connect(mapStateToProps)(InpRoomTransfer);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
