import React, { Fragment } from "react";
import PropTypes from "prop-types";

const CheckboxGroup = (props) => (

  <div className={props.className}>
    <div className="cusflex custom-checkbox mb-2">
      <label className="group-checkbox-title">{props.title}</label>
      {props.options.map((option) => {
        return (
          <Fragment key={option}>
            <div
              className="custom-control custom-checkbox checkwidth"
              data-toggle="tooltip"
              title=""
            >
                
              <input
                className="custom-control-input "
                name={props.name}
                onChange={props.controlFunc}
                value={option}
                checked={
                  props.selectedOptions
                    ? props.selectedOptions.indexOf(option) > -1
                    : 0
                }
                name={props.name ? props.name : ""}
                type={props.type}
                //option : check value
                //title: group checkbox is belongs to which topic
                //page: to differentiate from which page
                id={option + "|" + props.page}
              />
              <label
                className="custom-control-label"
                htmlFor={option + "|" + props.page}
              >
                {props.islabel ? option.split("|").pop() : ""}
              </label>
            </div>
          </Fragment>
        );
      })}
    </div>
  </div>
);

CheckboxGroup.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["checkbox"]).isRequired,
  setName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  controlFunc: PropTypes.func.isRequired,
  className: PropTypes.func.isRequired,
};

export default CheckboxGroup;
