import http from "../Business/httpcommon";
import axios from "axios";
class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();
    console.log(formData);
    formData.append("file", file);
    
    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new UploadFilesService();