import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../../components/container/layout/client/Header";
//import ClientSideBar from "../components/container/layout/client/SideBarNew";
//import ClientSideBar from "../components/container/SideBar";
import ClientSideBar from "../../components/container/SideBar";
import Select from "react-select";
import ToggleButton from "../../components/UI/ToggleButton";
import InputField from "../../components/UI/InputField";
import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
import Label from "../../components/UI/Label";
import DatePicker from "react-date-picker";
//import Button from '../../UI/Button';
import * as moment from "moment";
import FooterSection from "../../components/UI/FooterSection";
import {
  nationalityArray,
  gender,
  language,
  relationship,
  maritial_status,
  card_type,
} from "../../config/data";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
//import Label from '../../UI/Label';
import axios from "axios";
import { getClientById } from "../../config/url.json";
import { updateClient } from "../../config/url.json";
import { CONFIG } from "../../config/data";
import MonthPick from "../../components/UI/MonthPick";
import YearPick from "../../components/UI/YearPick";
import { Spinner } from "../../components/UI/Spinner";
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

const google = window.google;

let nationality = [];
nationalityArray.forEach((element) => {
  nationality.push({ value: element, label: element });
});
const currentYear = new Date().getFullYear();

class ViewClientProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInuserId: props.userdata.id,
      access_token: props.access_token,
      isDisable: true,
      editEnabled: false,
      editDisabled: true,
      formSuccess: { status: "", message: "" },
      headerInfo: {
        avatar: "",
        full_name: "",
        registeration_id: "",
        blood_group: "",
      },
      clientData: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        blood_group: "",
        gender: "",
        maritial_status: "",
        nationality: "",
        language: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        user_type: "patient",
        phone_number: "",
        emergency_contact: {
          first_name: "",
          last_name: "",
          relationship: "",
          phone_number: "",
        },
        vehicle_details: [
          {
            vehicle_make: "",
            vehicle_model: "",
            vehicle_registration: "",
            year_of_make: "",
            vin_number: "",
          },
        ],

        payment_details: [
          {
            credit_card: "",
            card_name: "",
            card_type: "",
            expiry_month: "",
            expiry_year: "",
            cvv: "",
            payment_option: "",
          },
        ],
        employment_details: {
          employer_name: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
          phone_number: "",
          employee_status: "",
        },
        insurance_details: {
          insurance_provider: "",
          policy_id_number: "",
          health_insurance_confirmation: "",
          patient_policy_holder: "",
        },
        medical_history: [],
        family_medical_history: [],
      },
      vehicle_details: [
        {
          vehicle_make: "",
          vehicle_model: "",
          vehicle_registration: "",
          year_of_make: "",
          vin_number: "",
        },
      ],

      payment_details: [
        {
          credit_card: "",
          card_name: "",
          card_type: "",
          expiry_month: "",
          expiry_year: "",
          cvv: "",
          payment_option: "",
        },
      ],
      family_details: [
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
      errors: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        blood_group: "",
        gender: "",
        maritial_status: "",
        nationality: "",
        language: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
      },
      family_details_errors: [
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
    };
    // this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    //  this.autocomplete = null;
    //this.handleWorkhoursChange = this.handleWorkhoursChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    // this.handleEmployeePlaceSelect = this.handleEmployeePlaceSelect.bind(this);
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    //this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), {})
    //this.autocomplete.addListener("place_changed", this.handleEmployeePlaceSelect)
    console.log(this.props.user_id);
     //console.log(this.props.location.state.user_id);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .post(getClientById, { client_id: this.props.user_id }, CONFIG)
      .then((result) => {
        if (result.data.status === "1") {
          let data = result.data.data;
          let family_details = result.data.data.family_details;

          this.setState({ family_details: family_details });
          let ExistingvehicleDetails = this.state.vehicle_details;
          let ExistingPaymentDetails = this.state.payment_details;

          if (data.payment_details == null) {
            data.payment_details = {
              credit_card: "",
              card_name: "",
              expiry_month: "",
              expiry_year: "",
              cvv: "",
              payment_option: "",
            };
          } else {
            // data.payment_details = data.payment_details;
          }
          if (data.vehicle_details == null) {
            data.vehicle_details = {
              vehicle_make: "",
              vehicle_model: "",
              vehicle_registration: "",
              year_of_make: "",
              vin_number: "",
            };
          } else {
            data.vehicle_details = JSON.parse(data.vehicle_details);
            // console.log( JSON.parse(data.vehicle_details));
            // ExistingvehicleDetails = JSON.parse(data.vehicle_details);
          }
          if (data.emergency_contact == null) {
            data.emergency_contact = {
              first_name: "",
              last_name: "",
              relationship: "",
              phone_number: "",
            };
          }
          if (data.employment_details == null) {
            data.employment_details = {
              employer_name: "",
              address: "",
              city: "",
              country: "",
              state: "",
              zip_code: "",
              phone_number: "",
              employee_status: "",
            };
          }
          if (data.insurance_details == null) {
            data.insurance_details = {
              insurance_provider: "",
              policy_id_number: "",
              health_insurance_confirmation: "",
              patient_policy_holder: "",
            };
          }
          const yourDate = data.date_of_birth;

          data.date_of_birth = moment(yourDate, moment.defaultFormat).toDate();

          this.handleDOBChange(data.date_of_birth);

          if (data.family_medical_history == null) {
            data.family_medical_history = [];
          }
          if (data.medical_history == null) {
            data.medical_history = [];
          }
          let convertedblood = [];
          if (data.blood_group != null) {
            convertedblood = {
              label: data.blood_group,
              value: data.blood_group,
            };
          }

          let convertedgender = [];
          if (data.gender != null) {
            convertedgender = {
              label: data.gender,
              value: data.gender,
            };
          }
          let convertedmaritial_status = [];
          if (data.marital_status != null) {
            convertedmaritial_status = {
              label: data.marital_status,
              value: data.marital_status,
            };
          }
          let convertedlanguage = [];
          if (data.language != null) {
            convertedlanguage = {
              label: data.language,
              value: data.language,
            };
          }
          data.language = convertedlanguage;
          let convertednationality = [];
          if (data.nationality != null) {
            convertednationality = {
              label: data.nationality,
              value: data.nationality,
            };
          }
          data.nationality = convertednationality;
          data.gender = convertedgender;
          data.blood_group = convertedblood;
          data.maritial_status = convertedmaritial_status;
          data.phone_number = data.phone_code == null ? "+91"+data.phone_number : data.phone_code.concat(data.phone_number);
          ExistingvehicleDetails.splice(0, 1, data.vehicle_details);

          ExistingPaymentDetails.splice(0, 1, data.payment_details);

          this.setState({
            vehicle_details: ExistingvehicleDetails,
            payment_details: ExistingPaymentDetails,
          });
          this.setState({
            clientData: data,

            // family_details: JSON.parse(response.data.data.family_details),
          });
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("business user getbyid failed",e);
      });
    console.log("mountdone");
    console.log(this.state.vehicle_details);
  }

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          addDisResponseMessage: { message: "" },
          formSuccess: { message: "" },
          searchformSuccess: { message: "" },
        }),
      5000
    );
  };

  addVehicleDetail() {
    this.setState((prevState) => ({
      vehicle_details: [
        ...prevState.vehicle_details,
        {
          vehicle_make: "",
          vehicle_model: "",
          vehicle_registration: "",
          year_of_make: "",
          vin_number: "",
        },
      ],
    }));
  }

  removeVehicleDetail(i) {
    let vehicle_details = [...this.state.vehicle_details];
    vehicle_details.splice(i, 1);
    this.setState({ vehicle_details });
  }

  handleVehicleDetailChange(i, value, field) {
    let fields = this.state.vehicle_details;

    if (value === "vehicle_make") {
      fields[i].vehicle_make = field.target.value;
    } else if (value === "vehicle_model") {
      fields[i].vehicle_model = field.target.value;
    } else if (value === "vehicle_registration") {
      fields[i].vehicle_registration = field.target.value;
    } else if (value === "year_of_make") {
      fields[i].year_of_make = field.target.value;
    } else if (value === "vin_number") {
      fields[i].vin_number = field.target.value;
    }
    this.setState({
      vehicle_details: fields,
    });
  }

  addPaymentDetail() {
    this.setState((prevState) => ({
      payment_details: [
        ...prevState.payment_details,
        {
          credit_card: "",
          card_name: "",
          card_type: "",
          expiry_month: "",
          expiry_year: "",
          cvv: "",
          payment_option: "",
        },
      ],
    }));
  }

  removePaymentDetail(i) {
    let payment_details = [...this.state.payment_details];
    payment_details.splice(i, 1);
    this.setState({ payment_details });
  }

  handlePaymentDetailChange(i, value, field) {
    let fields = this.state.payment_details;
    console.log(value);
    console.log(field);

    if (value === "credit_card") {
      fields[i].credit_card = field.target.value;
    } else if (value === "card_name") {
      fields[i].card_name = field.target.value;
    } else if (value === "cvv") {
      fields[i].cvv = field.target.value;
    } else if (value === "payment_option") {
      fields[i].payment_option = field.target.value;
    } else if (value === "card_type") {
      fields[i].card_type = field;
    } else if (value === "expiry_month") {
      fields[i].expiry_month = field;
    } else if (value === "expiry_year") {
      fields[i].expiry_year = field;
    }
    this.setState({
      vehicle_details: fields,
    });
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { clientData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1")
          clientData.state = val;
        if (addressType === "locality")
          clientData.city = val;
        if (addressType === "country") clientData.country = val;

        clientData.address1 = place.name;
        if (addressType === "sublocality_level_1") clientData.address2 = val;
        if (addressType === "postal_code") clientData.zip_code = val;
      }
    }

    this.setState({
      clientData,
    });
  }

  validateMaxLength(name, value, max) {
    let data = this.state.clientData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ clientData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.clientData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "first_name" ||
      name === "last_name" ||
      name === "email" ||
      name === "city" ||
      name === "zip_code" ||
      name === "phone_number"
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ clientData: data });
  };

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.clientData;
    data[e.name] = selectedOptions;

    this.setState({ clientData: data });
  };

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.clientData;
    data.country = val;
    this.setState({ clientData: data });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.clientData;
    data.state = val;
    this.setState({ clientData: data });
  }
  handleback()
  {
    document.location.href = "/client-profile";
    //window.location.history("/client-profile");   
   // this.props.history.push("/client-profile"); 
  }

  handlePhoneInput = (event) => {
    let clientData = this.state.clientData;
    clientData.phone_number = event;
    this.setState({ clientData });
  };

  handleDOBChange = (date) => {
    let clientData = this.state.clientData;
    clientData.date_of_birth = date;
    this.setState({ clientData: clientData });
  };

  handleEmploymentDetails(field, value) {
    let data = this.state.clientData;
    if (
      field === "country" ||
      field === "state" ||
      field === "employee_status" ||
      field === "phone_number"
    )
      data.employment_details[field] = value;
    else data.employment_details[field] = value.target.value;
    this.setState({ clientData: data });
  }

  handleInsuranceDetails(field, event) {
    let data = this.state.clientData;

    console.log("handle inc change");
    console.log(field);
    console.log(event.target.checked);

    if (
      field === "health_insurance_confirmation" ||
      field === "patient_policy_holder"
    ) {
      data.insurance_details[field] =
        event.target.checked === true ? "Yes" : "No";
    } else {
      data.insurance_details[field] = event.target.value;
    }

    this.setState({ clientData: data });
  }

  handelVehicleDetails(field, value) {
    let data = this.state.clientData;
    data.vehicle_details[field] = value.target.value;
    this.setState({ clientData: data });
  }
  handelPaymentDetails(field, value) {
    let data = this.state.clientData;
    if (
      field === "card_type" ||
      field === "expiry_month" ||
      field === "expiry_year"
    )
      data.payment_details[field] = value;
    else data.payment_details[field] = value.target.value;

    this.setState({ clientData: data });
  }

  handelEmergencyContact(field, value) {
    let data = this.state.clientData;
    if (field === "relationship" || field === "phone_number")
      data.emergency_contact[field] = value;
    else data.emergency_contact[field] = value.target.value;

    this.setState({ clientData: data });
  }

  editProfile() {
    this.setState({ editEnabled: !this.state.editEnabled });
    this.setState({ editDisabled: !this.state.editDisabled });

    //enable the fields to edit
    this.setState({ isDisable: !this.state.isDisable });
  }

  cancelEdit() {
    this.setState({ editDisabled: !this.state.editDisabled });
    this.setState({ editEnabled: !this.state.editEnabled });
  }

  updateProfile = (e) => {
    e.preventDefault();

    let clientData = this.state.clientData;
    let errors = this.state.errors;
    clientData.user_id = this.props.user_id;

    let data = {};
    data.blood_group = clientData.blood_group.label;
    data.gender = clientData.gender.label;
    data.marital_status = clientData.maritial_status.label;
    data.language = clientData.language.label;
    data.nationality = clientData.nationality.label;
    data.first_name = clientData.first_name;
    data.last_name = clientData.last_name;
    data.address1 = clientData.address1;
    data.address2 = clientData.address2;
    data.city = clientData.city;
    data.country = clientData.country;
    data.state = clientData.state;
    data.zip_code = clientData.zip_code;
    data.date_of_birth = clientData.date_of_birth;
    data.phone_code=clientData.phone_code;
    data.phone_number = clientData.phone_number;
    data.email = clientData.email;
    data.user_id = this.props.user_id;
    data.emergency_contact = clientData.emergency_contact;
    data.vehicle_details = clientData.vehicle_details;
    data.employment_details = clientData.employment_details;
    data.insurance_details = clientData.insurance_details;
    data.medical_history = clientData.medical_history;
    data.family_medical_history = clientData.family_medical_history;
    data.payment_details = clientData.payment_details;
    data.family_details = clientData.family_details;

    clientData.user_type = "patient";

    errors = Validation(clientData, errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.state.access_token}`;
      this.setState({ loading: true }, () => {
        axios
          .put(updateClient, data, CONFIG)
          .then((result) => {
            console.log(result);
            this.setState({ loading: false });
            this.setState({ formSuccess: result.data });
            this.clearMessage();
          })
          .catch(() => {
            console.log("business user getbyid failed");
          });
      });
    }
  };

  render() {
    const {
      clientData,
      payment_details,
      errors,
      formSuccess,
      loading
    } = this.state;

    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];
    let disease = [];
    disease_array.forEach((element) => {
      disease.push(element.disease_name);
    });
    disease_array = disease;

    let specialities = [];
    specialities_array.forEach((element) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let facilities_array = this.props.appdata
      ? this.props.appdata[0].facilities
      : [];
    let facilities = [];
    facilities_array.forEach((element) => {
      facilities.push({ value: element.id, label: element.facility_name });
    });
    facilities_array = facilities;
    return (
      <div className="main-content bacfot">
        <div className="mainpage">
        {/*   <Spinner loading={this.state.loading} /> */}
        {loading && <Dnaspinner />}
          {/* <Header info={this.state.headerInfo} /> */}
          <Header user_id={this.props.user_id} />
          <div className="main-box">
          <div className="sidemenu pr0">
              <ClientSideBar />

            </div>
          <section className="main-body hospital-profilegap">
            <div className="container-fluid a-page-1-hight-rightnew">
              <div className="checkout-tabs ">
                <div className="row">
                 <div className="col-sm-12">
               
                 </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6 pr0">
                        {/* <a className="pull-right" href="/client-profile">
                          Back
                        </a> */}

                        <div className="card">
                        <div class="card-header"> <div class="card-title">Primary contact</div> </div>
                          <div className="card-body">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="col-sm-12">
                                  <SpanField
                                    classname={
                                      "0" === formSuccess.status
                                        ? "text-danger user-notification"
                                        : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                  />
                              

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="First Name"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"first_name"}
                                       // controlFunc={this.handleInput}
                                        content={clientData.first_name}
                                        placeholder={""}
                                        isvisible={true}
                                        max={50}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.first_name}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Last Name"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"last_name"}
                                        controlFunc={this.handleInput}
                                        content={clientData.last_name}
                                        placeholder={""}
                                        isvisible={true}
                                        max={50}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.last_name}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label title="Age" />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        isMandatory={true}
                                        inputType={"text"}
                                        name={"age"}
                                        max={3}
                                        controlFunc={this.handleInput}
                                        content={
                                          clientData.age ? clientData.age : ""
                                        }
                                        placeholder={""}
                                        isvisible={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.age}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label title="Sex" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="gender"
                                        value={clientData.gender}
                                        onChange={this.handleselectChange}
                                        options={gender}
                                        isvisible={true}
                                        isDisabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.gender}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Marital Status"
                                        isMandatory={false}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="maritial_status"
                                        value={clientData.maritial_status}
                                        onChange={this.handleselectChange}
                                        options={maritial_status}
                                        isDisabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.maritial_status}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label
                                        title="Nationality"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="nationality"
                                        value={clientData.nationality}
                                        onChange={this.handleselectChange}
                                        options={nationality}
                                        isvisible={true}
                                        isDisabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.nationality}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Address"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        id="address1"
                                        inputType={"text"}
                                        name={"address1"}
                                        controlFunc={this.handleInput}
                                        content={clientData.address1}
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.address1}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label title="City" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        inputType={"text"}
                                        name={"city"}
                                        controlFunc={this.handleInput}
                                        content={clientData.city}
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                        max={20}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.city}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label title="State" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <RegionDropdown
                                        name="state"
                                        defaultOptionLabel="Select State"
                                        country={clientData.country}
                                        value={clientData.state || ""}
                                        className="form-control"
                                        onChange={(val) =>
                                          this.selectState(val)
                                        }
                                        placeholder="State"
                                        disabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.state}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Country"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <CountryDropdown
                                        placeholder="Country"
                                        name="country"
                                        value={clientData.country || ""}
                                        className="form-control"
                                        onChange={(val) =>
                                          this.selectCountry(val)
                                        }
                                        disabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.country}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <Label
                                        title="Zip code"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        inputType={"text"}
                                        name={"zip_code"}
                                        controlFunc={this.handleInput}
                                        content={clientData.zip_code}
                                        max={10}
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.zip_code}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Primary Mobile"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className=" frlrit">
                                      <div className="form-control">
                                        <PhoneInput
                                          international
                                          defaultCountry="IN"
                                          placeholder="Enter phone number"
                                          value={clientData.phone_number}
                                          onChange={this.handlePhoneInput}
                                          max={15}
                                          error={
                                            clientData.phone_number
                                              ? isValidPhoneNumber(
                                                clientData.phone_number
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                              : "Phone number required"
                                          }
                                          disabled={true}
                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.phone_number}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label title="Email" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        // isMandatory={true}
                                        inputType={"text"}
                                        name={"email"}
                                        //controlFunc={this.handleInput}
                                        content={clientData.email}
                                        placeholder={""}
                                        max={50}
                                        isvisible={this.state.isDisable}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.email}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Language"
                                        isMandatory={false}
                                      />
                                    </div>
                                    <div className="form-group frlrit">
                                      <Select maxMenuHeight={120}
                                        // className="form-control p-0 selectclas"
                                        className="form-control p-0"
                                        isMulti
                                        name="language"
                                        value={clientData.language}
                                        onChange={this.handleselectChange}
                                        options={language}
                                        isDisabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.language}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <Label
                                        title="Date of birth"
                                        isMandatory={true}
                                      />
                                    </div>
                                    <div className="form-group frlrit datepickerdiv">
                                      <DatePicker
                                        className="form-control"
                                        value={clientData.date_of_birth}
                                        format="dd-MM-yyyy"
                                        onChange={(date) =>
                                          this.handleDOBChange(date)
                                        }
                                        disabled={true}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.date_of_birth}
                                      />
                                    </div>
                                  </div>

                                </div>{" "}
                                {/** end of first column */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                           <div class="card-header"> <div class="card-title">  Employment details</div> </div>
                           <div className="card-body">
                  

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Employer</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        name={"employer_name"}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "employer_name"
                                        )}
                                        content={
                                          clientData.employment_details
                                            .employer_name
                                            ? clientData.employment_details
                                              .employer_name
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                    </div>
                                  </div>

                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Address</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        // title={'Last Name'}
                                        name={"address"}
                                        id={"address"}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "address"
                                        )}
                                        content={
                                          clientData.employment_details.address
                                            ? clientData.employment_details
                                              .address
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>City</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        // title={'Last Name'}
                                        name={"city"}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "city"
                                        )}
                                        content={
                                          clientData.employment_details.city
                                            ? clientData.employment_details.city
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                        
                                      />{" "}
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <label>State</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <RegionDropdown
                                        name="state"
                                        defaultOptionLabel="Select State"
                                        country={
                                          clientData.employment_details.country
                                        }
                                        value={
                                          clientData.employment_details.state ||
                                          ""
                                        }
                                        className="form-control"
                                        onChange={(val) =>
                                          this.handleEmploymentDetails(
                                            "state",
                                            val
                                          )
                                        }
                                        placeholder="State"
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Country</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <CountryDropdown
                                        placeholder="Country"
                                        name="country"
                                        value={
                                          clientData.employment_details
                                            .country || ""
                                        }
                                        className="form-control"
                                        onChange={(val) =>
                                          this.handleEmploymentDetails(
                                            "country",
                                            val
                                          )
                                        }
                                        disabled={true}
                                      />
                                    </div>
                                    <div className="form-group frlrclfgf-1">
                                      <label>Zip</label>
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        isMandatory={false}
                                        inputType={"text"}
                                        // title={'Last Name'}
                                        name={"zip_code"}
                                        controlFunc={this.handleEmploymentDetails.bind(
                                          this,
                                          "zip_code"
                                        )}
                                        content={
                                          clientData.employment_details.zip_code
                                            ? clientData.employment_details
                                              .zip_code
                                            : ""
                                        }
                                        placeholder={""}
                                        isvisible={this.state.isDisable}
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div className="form-group frllft">
                                      <label>Employer contact </label>
                                    </div>
                                    <div className=" frlrit">
                                      <div className="form-control">
                                        <PhoneInput
                                          international
                                          defaultCountry="IN"
                                          placeholder="Enter phone number"
                                          value={
                                            clientData.employment_details
                                              .phone_number
                                          }
                                          onChange={this.handleEmploymentDetails.bind(
                                            this,
                                            "phone_number"
                                          )}
                                          error={
                                            clientData.employment_details
                                              .phone_number
                                              ? isValidPhoneNumber(
                                                clientData.employment_details
                                                  .phone_number
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                              : "Phone number required"
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div align="right" className="mb-2">
                          <button type="button" className="signupbtn abtn btdpml" onClick={() =>this.handleback()}>
                            Back
                          </button>
                          </div>
                           </div>
                        </div>
                      </div>

                      <div className="col-sm-6 pl0 ">
                        <div className="card">
                        
                        <div class="card-header"> <div class="card-title"> Medical Insurance</div> </div>
                          <div className="card-body">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                          

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Insurance provider</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          // isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"insurance_provider"}
                                          controlFunc={this.handleInsuranceDetails.bind(
                                            this,
                                            "insurance_provider"
                                          )}
                                          content={
                                            clientData.insurance_details
                                              .insurance_provider
                                              ? clientData.insurance_details
                                                .insurance_provider
                                              : ""
                                          }
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                          isDisabled={true}
                                        />
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Policy ID Number</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          // isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"policy_id_number"}
                                          controlFunc={this.handleInsuranceDetails.bind(
                                            this,
                                            "policy_id_number"
                                          )}
                                          content={
                                            clientData.insurance_details
                                              .policy_id_number
                                              ? clientData.insurance_details
                                                .policy_id_number
                                              : ""
                                          }
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                          isDisabled={true}
                                        />
                                      </div>
                                    </div>
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                        <div class="card-header"> <div class="card-title">Policy Holder Confirmation</div> </div>
                        <div className="card-body">
                        <div className="frllftfull">
                                   

                                      <div className="row mt-10 mb-10">
                                        <div className="col-sm-6">
                                          <label>
                                            Health Insurance Confirmation
                                          </label>

                                          <div className="row">
                                            <div className="col-sm-12">
                                              <ToggleButton
                                                name="health_insurance_confirmation"
                                                dataOn={"Yes"}
                                                dataOff={"No"}
                                                controlFunc={this.handleInsuranceDetails.bind(
                                                  this,
                                                  "health_insurance_confirmation"
                                                )}
                                                checked={
                                                  clientData.insurance_details
                                                    .health_insurance_confirmation ===
                                                    "Yes"
                                                    ? true
                                                    : false
                                                }
                                              />

                                              {/* <div className="form-check fltx">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                                        <label className="form-check-label" for="exampleRadios1">
                                                           Yes
                                                        </label>
                                                    </div>
													
													            <div className="form-check fltx">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                                        <label className="form-check-label" for="exampleRadios1">
                                                           No
                                                        </label>
                                                      </div>*/}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-sm-6">
                                          <label>
                                            Is the patient the policy holder?
                                          </label>

                                          <div className="row">
                                            <div className="col-sm-12">
                                              <ToggleButton
                                                name="patient_policy_holder"
                                                dataOn={"Yes"}
                                                dataOff={"No"}
                                                controlFunc={this.handleInsuranceDetails.bind(
                                                  this,
                                                  "patient_policy_holder"
                                                )}
                                                checked={
                                                  clientData.insurance_details
                                                    .patient_policy_holder ===
                                                    "Yes"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                        </div>
                        </div>
                        <div className="card">
                        <div class="card-header"> <div class="card-title">Emergency contact</div> </div>
                        <div className="card-body">


                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Emergency Contact Name</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <InputField
                                          isMandatory={true}
                                          inputType={"text"}
                                          // title={'Last Name'}
                                          name={"first_name"}
                                          controlFunc={this.handelEmergencyContact.bind(
                                            this,
                                            "first_name"
                                          )}
                                          content={
                                            clientData.emergency_contact
                                              .first_name
                                              ? clientData.emergency_contact
                                                .first_name
                                              : ""
                                          }
                                          placeholder={""}
                                          isvisible={this.state.isDisable}
                                        />
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>
                                          Emergency Contact Relationship
                                        </label>
                                      </div>
                                      <div className=" frlrit">
                                        <div className="form-group">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="relationship"
                                            value={
                                              clientData.emergency_contact
                                                .relationship
                                                ? clientData.emergency_contact
                                                  .relationship
                                                : ""
                                            }
                                            onChange={this.handelEmergencyContact.bind(
                                              this,
                                              "relationship"
                                            )}
                                            options={relationship}
                                            isDisabled={true}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="frllftfull">
                                      <div className="form-group frllft">
                                        <label>Primary mobile</label>
                                      </div>
                                      <div className=" frlrit">
                                        <div className="form-control">
                                          <PhoneInput
                                            international
                                            defaultCountry="IN"
                                            placeholder="Enter phone number"
                                            value={
                                              clientData.emergency_contact
                                                .phone_number
                                                ? clientData.emergency_contact
                                                  .phone_number
                                                : ""
                                            }
                                            disabled={true}
                                            onChange={this.handelEmergencyContact.bind(
                                              this,
                                              "phone_number"
                                            )}
                                            error={
                                              clientData.emergency_contact
                                                .phone_number
                                                ? isValidPhoneNumber(
                                                  clientData.emergency_contact
                                                    .phone_number
                                                )
                                                  ? undefined
                                                  : "Invalid phone number"
                                                : "Phone number required"
                                            }
                                            
                                          />
                                        </div>
                                      </div>
                                    </div>
                        </div>
                        </div>
                        <div className="card">
                            <div class="card-header"> <div class="card-title">Payment details</div> </div>
                            <div className="card-body">
                            {payment_details.map((el, i) => (
                                      <Fragment key={i}>
                                        <div className="frllftfull  mt-10">
                                          {/* {i === 0 ? (
                                            <h5 className="regxs regxsd">
                                              Payment details
                                              <button
                                                type="button"
                                                onClick={this.addPaymentDetail.bind(
                                                  this
                                                )}
                                                className="plusbtn"
                                              >
                                                <i
                                                  className="fa fa-plus-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </h5>
                                          ) : (
                                            ""
                                          )} */}
                                          <hr className="mrgtp" />
                                          {i !== 0 ? (
                                            <button
                                              type="button"
                                              onClick={this.removePaymentDetail.bind(
                                                this
                                              )}
                                              className="plusbtn"
                                            >
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Credit card</label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"credit_card"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "credit_card"
                                              )}
                                              content={
                                                payment_details[i].credit_card
                                                  ? payment_details[i]
                                                    .credit_card
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Name in card</label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"card_name"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                "card_name"
                                              )}
                                              content={
                                                payment_details[i].card_name
                                                  ? payment_details[i].card_name
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                          
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Expiration date</label>
                                          </div>
                                          <div className="form-group frlrclfgf-3">
                                            <MonthPick
                                              defaultValue="MM"
                                              year={
                                                payment_details[i].expiry_month
                                              }
                                              selectedOption={
                                                payment_details[i].expiry_month
                                                  ? payment_details[i]
                                                    .expiry_month
                                                  : ""
                                              }
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,

                                                "expiry_month"
                                              )}
                                              id="expiry_month"
                                              name="expiry_month"
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>

                                          <div className="form-group frlrclfgf-1">
                                            {/* <label>Expiry Year</label> */}
                                          </div>
                                          <div className="form-group frlrclfgf-3 mg-left3">
                                            <YearPick
                                              defaultValue="YY"
                                              startyear={currentYear}
                                              endyear={currentYear + 20}
                                              isDisabled={true}
                                              selectedOption={
                                                payment_details[i].expiry_year
                                                  ? payment_details[i]
                                                    .expiry_year
                                                  : ""
                                              }
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,

                                                "expiry_year"
                                              )}
                                              id="expiry_year"
                                              name="expiry_year"
                                            />
                                          </div>
                                          <div className="form-group frllft">
                                            <label>CVV</label>
                                          </div>
                                          <div className="form-group frlrit frlrclfgf mg-tp2 float-right">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"cvv"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "cvv"
                                              )}
                                              content={
                                                payment_details[i].cvv
                                                  ? payment_details[i].cvv
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Card type </label>
                                          </div>
                                          <div className="form-group sform-control frlrit">
                                            <Select maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="card_type"
                                              value={
                                                payment_details[i].card_type
                                                  ? payment_details[i].card_type
                                                  : ""
                                              }
                                              onChange={this.handlePaymentDetailChange.bind(
                                                this,
                                                "card_type"
                                              )}
                                              options={card_type}
                                              isDisabled={true}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>
                                              Netbanking/ Paytm / Paypal
                                            </label>
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'Last Name'}
                                              name={"payment_option"}
                                              controlFunc={this.handlePaymentDetailChange.bind(
                                                this,
                                                i,
                                                "payment_option"
                                              )}
                                              content={
                                                payment_details[i]
                                                  .payment_option
                                                  ? payment_details[i]
                                                    .payment_option
                                                  : ""
                                              }
                                              placeholder={""}
                                              isvisible={this.state.isDisable}
                                            />
                                          </div>
                                        </div>
                                      </Fragment>
                                    ))}
                            </div>
                            </div>
                      </div>
                      {/** end of second column */}
                    </div>

                    {/* <Footerad /> */}
                   
                  </div>
                
                </div>
          
                {/** end of row */}
              
              </div>
            </div>
           

          </section>
          <FooterSection />
          </div>
         
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ViewClientProfile);
