import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Validation from "../../actions/Validation";
import { withRouter } from "react-router-dom";
import {
  getAllDrugMeasures,
  getTimeoutbycategory,
  getAllSupplierList,
  getDrugNamebyDrugId,
  getAllStorageBinList,
  updateSupplier,
  getcurrencybycountryname,
  getcountrybyhospital,
} from "../../config/url.json";
import{addMaterialMovement} from "../../config/url.json";
import Label from "../../components/UI/Label";
import {
  getDateISOFormat,
  getPatientProfileColor,
  calculate_age,
} from "../../constants/globalLevelFunctions";

import axios from "axios";
import { CONFIG } from "../../config/data";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import TextArea from "../../components/UI/TextArea";
import { Spinner } from "../../components/UI/Spinner";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import Select from "react-select";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Modal } from "react-bootstrap";
import { ExpirePopup } from "../../components/UI/ExpirePopup";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import {
  
    movement_type,
    stock_type

} from "../../constants/config/data";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FooterSection from "../../components/UI/FooterSection";
import { option } from "yargs";
import Dnaspinner from "../../components/UI/Dnaspinner";
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
          return i;
      }
  }
  return -1;
}
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity

  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
/* var cc = require("country-code");
cc.find({name: "Algeria"})
 */
class MaterialMovement extends Component {
  constructor(props) {
    super(props);

    this.searchData = {
      phone_number: "",
      error: "",
    };
    this.clientData = {
      emergency_schedule: [],
      id: "",
      relationship: {},
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      blood_group: "",
      checkin_category: {
        label: "Normal",
        value: "Normal",
      },

      //  checkin_category: checkin_category,
      queue_number: "",
      reason_for_visit: "",
      other_comments: "",
      schedule_day_id: "",
      queue_data: [],

      //patient basic check up
      height: "",
      weight: "",
      bmi: "",
      temperature: "",
      bp: "",
      pulse: "",
      symptoms: "",
    };
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
    const currency=this.state;
   
    this.state = {
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      actions: "add",
      selectedSymptoms: [],
      symptoms: [],
      activeIndex: '',
      cloneSymptoms: [],
      countryName: '',
      countryCode: '',
      symptomCategory: [],
      showexpirePopup: this.props.validity,

      emergency_queue_data: [],
      clientData: Object.assign({}, this.clientData),
      errors: {},
      searchData: this.searchData,
      family_details: [],
      searchSuccess: {
        message: "",
        status: "",
      },
      disable_insert_queue: false,
      is_add_btn: true,
      // queueData: {},
      loading: false,
      // businessdata: [],
      doctor_appointment_detail_list: [],
      selected_doctor_appointment_detail_list: [],
      doctor_list: [],
      selected_doc: {},
      selectedPerson: "",
      patientBasicData: [],
      is_queued: false,
      is_check_in: false,
      expected_time: "",
      cancelModel: false,
      Materialmovement: {
        materialmovement_id: "",
        materialmovement_name: "",
        document_date: "",
        drug_id: "",
        drug_name: "",
        quantity:"",
        unit_of_measure:"",
        batch_number:"",
        stock_type:"",
        manufacture_date:"",
        expiration_date:"",
        order_number:"",
        storage_bin:"",
        supplier:"",
        customer:"",
        cost_center:"",
        cost:"",
        currency:currency,
        reference:"",
        receiving_person:""

    },

    errors: {
        id: "",
        materialmovement_id: "",
        materialmovement_name: "",
        document_date: "",
        drug_id: "",
        drug_name: "",
        quantity:"",
        unit_of_measure:"",
        batch_number:"",
        stock_type:"",
        manufacture_date:"",
        expiration_date:"",
        order_number:"",
        storage_bin:"",
        supplier:"",
        customer:"",
        cost_center:"",
        cost:"",
        currency:"",
        reference:"",
        receiving_person:""

    },

    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }

  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout = (e) => {

    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");

  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    //this.props.logout();
    this.props.history.push("/");
  }
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryName: data.country_name,
            countryCode: data.country_calling_code
        });
    }).catch((error) => {
        console.log(error);
    });
};
 

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          searchSuccess: { message: "" },
        }),
      5000
    );
  };
  
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.Materialmovement;
    data[e.name] = selectedOptions;
   
    // console.log(selectedOptions)
    this.setState({ Materialmovement: data });
};
  componentDidMount = () => {
    this.getGeoInfo();
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });

      axios
      .post(getAllDrugMeasures, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugmeasureList = drugMasterRespons.data.data;
        console.log(masterdrugmeasureList);
        let masterdrugmeasure = [];
        masterdrugmeasureList.forEach((element) => {
          masterdrugmeasure.push({
            value: element.unit_of_measure,
            label: element.unit_of_measure,

          });
        });

        this.setState({ masterdrugmeasure });
      })
      .catch(() => { });
      axios
      .post(getAllSupplierList, { user_id: this.props.userdata.id }, CONFIG)
      .then((supplierListRespons) => {
        let supplierList = supplierListRespons.data.data;
        console.log(supplierList);
        let supplier = [];
        supplierList.forEach((element) => {
          supplier.push({
            value: element.supplier_id,
            label: element.supplier_name,

          });
        });

        this.setState({ supplier });
      })
      .catch(() => { });

      axios
      .post(getAllStorageBinList, { user_id: this.props.userdata.id }, CONFIG)
      .then((storagebinListRespons) => {
        let storagebinList = storagebinListRespons.data.data;
        console.log(storagebinList);
        let storagebin = [];
        storagebinList.forEach((element) => {
          storagebin.push({
            value: element.storagebin_id,
            label: element.storagebin_id,

          });
        });

        this.setState({ storagebin });
      })
      .catch(() => { });
      let Materialmovement=this.state.Materialmovement;
      if(Materialmovement.currency === "" || Materialmovement.currency === null || Materialmovement.currency === undefined){
                
       /*  axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            this.setState({
                countryName: data.country_name,
                countryCode: data.country_calling_code,
                currencyvalue:data.currency
            }); 
            let countryName=this.state.countryName;*/
            axios
            .post(getcountrybyhospital, { user_id:this.props.userdata.id}, CONFIG)
            .then((res) => {
              let data = res.data;
              console.log(res);
              console.log(data);
              console.log(data.data[0].country);
              if (data.status == "1")
                this.setState({
                  country: data.data[0].country
      
                });
                axios
                .post(getcurrencybycountryname, { country:data.data[0].country}, CONFIG)
                .then((res) => {
                  let data = res.data;
                  console.log(res);
                  console.log(data);
                  console.log(data.data[0].currency);
                  Materialmovement.currency=data.data[0].currency;
                  if (data.status == "1")
                    this.setState({
                      currency: data.data[0].currency
          
                    });
                })
                .catch((e) => { });
            })
            .catch((e) => { });
       
      //  }).catch((error) => {
      //      console.log(error);
      //  });
    
      console.log(this.state.countryName);
  }


  };
  updateMaterialmovement = (e) => {
    e.preventDefault();
    let { Materialmovement, errors } = this.state;
    let data = {};
    data.type = "Materialmovement";
    data.materialmovement_name = Materialmovement.materialmovement_name.label;
    data.document_date = Materialmovement.document_date;
    data.drug_id = Materialmovement.drug_id;
    data.drug_name = Materialmovement.drug_name.label;
    data.quantity = Materialmovement.quantity;
    data.unit_of_measure = Materialmovement.unit_of_measure.label;
    data.batch_number = Materialmovement.batch_number;
    data.stock_type=Materialmovement.stock_type.label;
    data.manufacture_date=Materialmovement.manufacture_date;
    data.expiration_date=Materialmovement.expiration_date;
    data.order_number=Materialmovement.order_number;
    data.storage_bin=Materialmovement.storage_bin;
    data.supplier=Materialmovement.supplier.label;
    data.customer=Materialmovement.customer;
    data.cost_center=Materialmovement.cost_center;
    data.cost=Materialmovement.cost;
    data.currency=Materialmovement.currency;
    data.reference=Materialmovement.reference;
    data.receiving_person=Materialmovement.receiving_person;
    data.user_id = this.props.userdata.id;
    console.log(data);
  //  this.setState({ loading: true });
    errors = Validation(data, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
        // console.log(data); return false;

     //   let url = this.state.actions === "add" ? addSupplier : updatesupplier;
        if (this.state.actions === "add") {
            axios.post(addMaterialMovement, data, CONFIG).then((list) => {
                let res = list.data;
                if (res.status === "1") {
                    let materialmovementData = {};
                    materialmovementData.materialmovement_name = data.materialmovement_name.label;
                    materialmovementData.document_date = data.document_date;
                    materialmovementData.drug_id = data.drug_id;
                    materialmovementData.drug_name = data.drug_name.label;
                    materialmovementData.quantity = data.quantity;
                    materialmovementData.unit_of_measure = data.unit_of_measure.label;
                    materialmovementData.batch_number = data.batch_number;
                    materialmovementData.stock_type=data.stock_type.label;
                    materialmovementData.manufacture_date=data.manufacture_date;
                    materialmovementData.expiration_date=data.expiration_date;
                    materialmovementData.order_number=data.order_number;
                    materialmovementData.storage_bin=data.storage_bin;
                    materialmovementData.supplier=data.supplier.label;
                    materialmovementData.customer=data.customer;
                    materialmovementData.cost_center=data.cost_center;
                    materialmovementData.cost=data.cost;
                    materialmovementData.currency=data.currency;
                    materialmovementData.reference=data.reference;
                    materialmovementData.receiving_person=data.receiving_person;
                    materialmovementData.user_id=data.user_id;
                    materialmovementData.materialmovement_id = list.data.data[0].materialmovement_id;
                 //   addsupplier.id = list.data.data;

                    let records = this.state.records;
                    //materialmovementData.s_no = records.length + 1;
                 //   records.push(materialmovementData);
                
                  //  this.setState({ records});
                    toast.success("Material Movement added ",{
                        className:"toast-success"
                      });
                //    this.setState({ loading: false });
                    this.clearMessage();
                 //   this.cancel();
          //      }
                    }
                    if ('0' === list.data.status) {
                      this.setState({ loading: false });
                      this.setState({ errors: { storage_bin: list.data.message } });
                  } 
            });
        } 
        /* else {
            data.materialmovement_id = Materialmovementdata.materialmovement_id;
            console.log(data)
            axios.put(updateSupplier, data, CONFIG).then((list) => {
                if ('0' === list.data.status) {
                    this.setState({ loading: false });
                    this.setState({ errors: { supplier_id: list.data.message } });
                } else {
                    let records = this.state.records;

                    var recIndex = findWithAttr(records, "id", data.id);
                    console.log(recIndex);
                    console.log(records);
                    records[recIndex].supplier_id = data.supplier_id;
                    records[recIndex].supplier_name = data.supplier_name;
                    records[recIndex].address = data.address;
                    records[recIndex].phone_number = data.phone_number;
                    records[recIndex].status = data.status;
                    records[recIndex].payment_terms = data.payment_terms;
                    records[recIndex].gst = data.gst;
                    records[recIndex].discount = data.discount;
                    records[recIndex].user_id = data.user_id;
                    records[recIndex].id = data.id;

                  //  this.setState({ records, formSuccess: list.data });
                //    this.setState({ loading: false });
                toast.success("Supplier Updated Successfully. ",{
                    className:"toast-success"
                  });
                    this.clearMessage();
                    this.cancel();
                }
            });
        } */
    }
};
  handlePhoneInput = (event) => {
    event.preventDefault();
    let data = this.state.searchData;
    let errors = this.state.errors;
    data[event.target.name] = event.target.value
      .replace(/\s/g, "")
      .replace(/[^0-9+]+/g, "");
    data.error = "";
    this.setState({ searchData: data, errors });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.clientData;
    let errors = this.state.errors;


    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Exceeds maximum length";
      data[name] = value1;
    } else {
      errors[name] = "";

    }
    this.setState({ clientData: data, errors });
  }
  handleInput = (e) => {
    e.preventDefault();
    let data = this.state.Materialmovement;
    data[e.target.name] = e.target.value;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

      data[name] = value;

    
    this.setState({ Materialmovement: data });
  };
  handledrugIdInput = (e) => {
    e.preventDefault();
    let data = this.state.Materialmovement;
    data[e.target.name] = e.target.value;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    data[name] = value;
   
    
   
    axios
    .post(getDrugNamebyDrugId, {user_id:this.props.userdata.id,drug_id:value}, CONFIG)
    .then((drugRespons) => {
      let drugList = drugRespons.data.data;
      console.log(drugList);
      let DrugNames = [];
      drugList.forEach((element) => {
        DrugNames.push({
          value: element.drug_id,
          label: element.drug_name,

          });
      });

      this.setState({ DrugNames });
    })
    .catch(() => { });
    axios
    .post(getDrugNamebyDrugId, {user_id:this.props.userdata.id,drug_id:value}, CONFIG)
    .then((drugRespons) => {
      let drugList = drugRespons.data.data[0];
      console.log(drugList);
    let batch_management=drugList.batch_management;
    console.log(batch_management);
   let  batch_managementarray= JSON.parse(batch_management);
   let batch_management_value=batch_managementarray.batch_management;
     
   this.setState({ batch_management_value });  


    })
    .catch(() => { });
    this.setState({ Materialmovement: data });
  };
  handlestoragebinInput = (e) => {
    e.preventDefault();
    let data = this.state.Materialmovement;
    data[e.target.name] = e.target.value;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    data[name] = value;

    
    this.setState({ Materialmovement: data });
  };
  handleDocumentdateChange = (date) => {
    let data = this.state.Materialmovement;
    let errors = this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    data.document_date = offsetDate;
    errors.document_date = "";
    this.setState({ Materialmovement: data, errors });
  };
  handleManufacturedateChange = (date) => {
    let data = this.state.Materialmovement;
    let errors = this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    data.manufacture_date = offsetDate;
    errors.manufacture_date = "";
    this.setState({ Materialmovement: data, errors });
  };
  handleExpirationdateChange = (date) => {
    let data = this.state.Materialmovement;
    let errors = this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    data.expiration_date = offsetDate;
    errors.expiration_date = "";
    this.setState({ Materialmovement: data, errors });
  };
  handlePatientChange(value, field) {
    let fields = this.state.clientData;
    let errors = this.state.errors;
    if (value === "gender") {
      fields.gender = field;
      errors.gender = "";
      // errors[i].specialization = '';
    } else if (value === "reason_for_visit") {
      fields.reason_for_visit = field;
      errors.reason_for_visit = "";

    } else if (value === "blood_group") {
      fields.blood_group = field;
    } else if (value === "emergency_schedule") {
      fields.emergency_schedule = field;
    } else if (value === "checkin_category") {
      fields.checkin_category = field;

      if (field.label === "Normal") {
        this.setState({ disable_insert_queue: false });
      } else {
        this.setState({ disable_insert_queue: true });
      }
      this.getQueueList();
    } else if (value === "disease") {
      fields.disease = field;
      // errors[i].specialization = '';
    } else if (value === "relationship") {
      fields.relationship = field;
      errors.relationship = "";
    }

    this.setState({
      clientData: fields,
    });
  }

  closePopup = (e) => {
    this.setState({
      cancelModel: false,
    });
  };


 

  render() {
    const { searchSuccess, errors,  hospitaltimeout,masterdrugmeasure,supplier,DrugNames,Materialmovement,searchErrorMessage,currency,loading } = this.state;
   
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupConfirm = () => {
      window.location.href = "/showsubscription"
      // this.props.history.push({
      //   pathname: "/showsubscription",
      //   state: {}
      // });
    };
    return (
      <div className="container-fluid">
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
      {/*   <Spinner loading={this.state.loading} /> */}
      {loading && <Dnaspinner />}
        <div className="main-content bacfot">
      <div className="mainpage">
        <Header user_id={this.props.userdata.id} />
        <div className="main-box">
          <div className="sidemenu pr0">
            <BusinessUserSideBar />
          </div>

          <section className="main-body hospital-profilegap">
           <div className="container-fluid">
        <div className="row checkout-tabs">

          <div className="col-sm-12">

            <h4 className="font-weight-bold">Material Movement</h4>

            
              <form>
              
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-shipping"
                      role="tabpanel"
                      aria-labelledby="v-pills-shipping-tab"
                    >
                      <div className="row">
                        <div className="user-notification">
                          <SpanField
                            classname={
                              0 === searchSuccess.status
                                ? "text-danger user-notification"
                                : "text-success user-notification"
                            }
                            title={searchSuccess.message}
                          />
                          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={1000} closeOnClick={false} pauseOnHover />

                        </div>
                    
                        <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                      </div>
                    </div>
                  </div>
              
              </form>


             
           
          </div>
          <div className="col-sm-12 pr0">
            <div className="card ">
            <div class="card-header"> <div class="card-title">Material Movement</div> </div>
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                      
                      <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Movement Type</label>
                          </div>
                          <div className="form-group frlrclfgf ">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="materialmovement_name"
                              value={Materialmovement.materialmovement_name}
                              onChange={this.handleselectChange}
                              options={movement_type}
                             
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Document Date</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <DatePicker
                              className="form-control"
                              value={Materialmovement.document_date}
                              format="dd-MM-yyyy"
                              onChange={(date) => this.handleDocumentdateChange(date)}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.document_date}
                            />
                          </div>
                          
                          
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Drug ID</label>
                          </div>
                          <div className="form-group frlrclfgf ">
                            <InputField
                              inputType={"text"}
                              name={"drug_id"}
                              controlFunc={this.handledrugIdInput}
                              content={Materialmovement.drug_id}
                              placeholder={""}
                             
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Drug Name</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="drug_name"
                              content={Materialmovement.drug_name}
                              onChange={this.handleselectChange}
                              options={DrugNames}                             
                            />
                        
                         
                            <SpanField
                              classname="text-danger"
                              title={errors.drug_name}
                            />
                          </div>
                        </div>
                      
                      

                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Quantity</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"quantity"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.quantity}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.quantity}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Unit of Measure</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="unit_of_measure"
                              content={Materialmovement.unit_of_measure}
                              onChange={this.handleselectChange}
                              options={masterdrugmeasure}
                             
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.unit_of_measure}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Batch</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <InputField
                              inputType={"text"}
                              name={"batch_number"}
                              controlFunc={this.handleInput}
                              readOnly={this.state.batch_management_value==""?true:false}
                              content={Materialmovement.batch_number}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.batch_number}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Stock Type</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="stock_type"
                             content={Materialmovement.stock_type} 
                              onChange={this.handleselectChange}
                              options={stock_type}
                             
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.stock_type}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Manufacture Date</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <DatePicker
                              className="form-control"
                            value={Materialmovement.manufacture_date}
                              format="dd-MM-yyyy"
                              onChange={(date) => this.handleManufacturedateChange(date)}
                            />
                        
                            <SpanField
                              classname="text-danger"
                              title={errors.manufacture_date}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Expiration Date</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <DatePicker
                              className="form-control"
                              value={Materialmovement.expiration_date}
                              format="dd-MM-yyyy"
                              onChange={(date) => this.handleExpirationdateChange(date)}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.expiration_date}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Order Number</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <InputField
                              inputType={"text"}
                              name={"order_number"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.order_number}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.order_number}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Storage Bin</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"storage_bin"}
                              controlFunc={this.handlestoragebinInput}
                              content={Materialmovement.storage_bin}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.storage_bin}
                            />
                          </div>
                        </div>
                       
                        <div className="frllftfull">
                          <div className="form-group frllft">
                            <label>Supplier</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="supplier"
                              content={Materialmovement.supplier}
                              onChange={this.handleselectChange}
                              options={supplier}                             
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label className="terd">Customer</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <InputField
                              inputType={"text"}
                              name={"customer"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.customer}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.customer}
                            />

                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Cost Center</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"cost_center"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.cost_center}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost_center}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Cost</label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2 required">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                            <label>Currency</label>
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Reference</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"reference"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.reference}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.reference}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Receiving Person</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"receiving_person"}
                              controlFunc={this.handleInput}
                              content={Materialmovement.receiving_person}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.receiving_person}
                            />
                          </div>
                        </div>
                     
                        <div className="row">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-0 btn-align-center">
                                           
                                            <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml"
                                                onClick={this.updateMaterialmovement}
                                            >
                                                {this.state.actions === "add" ? "Submit" : "Submit"}
                                            </button>
                                       
                                        </div>
                                    </div>
                                </div>

                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </div>

      </div>
      </section>
      <FooterSection />
      </div>
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialMovement));
