import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import axios from "axios";

import LoadingSpinner from "../components/UI/LoadingSpinner";

import { drugimport, getAllMasterDrug } from "../config/url.json";
import { CONFIG } from "../config/data";

import readXlsxFile from 'read-excel-file';
import AdminDrugTemplate from "../views/AdminDrugTemplate";
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

// const input = document.getElementById('file')
// console.log(input);
// input.addEventListener('change', () => {
//     readXlsxFile(input.files[0]).then((rows) => {
//         console.log(rows);
//         console.log("rows");
//         // `rows` is an array of rows
//         // each row being an array of cells.
//     })
// })

class DrugImport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            drugData: [],
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],

            loading: false,

            formSuccess: {
                message: "",
                status: "",
            },
        };
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };

        this.columns = [
            {
                key: "s_no",
                text: "S.No",
                className: "s_no",
                align: "left",
                sortable: true,
                width: 100,
              },
            {
                key: "drug_name",
                text: "Drug Name",
                className: "drug_name",
                align: "left",
                sortable: true,
                width: 100,


            },
            {
                key: "generic_name",
                text: "Generic Name",
                className: "generic_name",
                sortable: true,
                align: "left",
                width: 100,
        
              
              },
            {
                key: "unit_of_measure",
                text: "Unit of Measure",
                className: "unit_of_measure",
                align: "left",
                sortable: true,
                width: 100,



            },

          /*   {
                key: "drug_category",
                text: "Drug Category",
                className: "drug_category",
                sortable: true,
                align: "left",
                width: 100,


            },

            {
                key: "drug_type",
                text: "Drug Type",
                className: "drug_type",
                sortable: true,
                align: "left",
                width: 100,


            }, */

            {
                key: "manufacturer",
                text: "Manufacturer",
                className: "manufacturer",
                sortable: true,
                align: "left",
                width: 20,


            },

            {
                key: "active_ingredients",
                text: "Active Incredients",
                className: "active_ingredients",
                sortable: true,
                align: "left",
                width: 20,


            },


        ];
    }



    onFileChange = event => {

        // Update the state
        this.setState({ file: event.target.files[0] });
        readXlsxFile(event.target.files[0]).then((rows) => {
            console.log(rows);
            console.log("rows");
            this.setState({ drugData: rows });
            // `rows` is an array of rows
            // each row being an array of cells.
        })

    };
    componentDidMount() {
        this.setState({ loading: true });
        this.getmaster()
    }

    // On file upload (click the upload button)
    onFileUpload = () => {

        let data =
            { data: this.state.drugData };
        if (this.state.drugData.length) {
            axios.post(drugimport, data).then((result) => {
                console.log(result);
                this.setState({ formSuccess: result.data, file: "", drugData: [] })
                this.getmaster();

                this.clearMessage();
            });
        }

    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },

                }), window.location.reload(),
            5000
        );




    };
    getmaster = () => {


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getAllMasterDrug, "", CONFIG)
            .then((drugMasterRespons) => {
                let masterdrugNameList = drugMasterRespons.data.data;
                this.setState({ loading: false });
                 let drugDataArr = [];
        
                masterdrugNameList.forEach((element, index) => {
                  console.log(element);
                  let drugData = {};
                  drugData = element
                  
                  drugData.s_no = index + 1;
                 
                  drugDataArr[index] = drugData;
                }); 
                this.setState({ records: masterdrugNameList });
            })
            .catch(() => { });
    }


    render() {
        const {
            formSuccess,
            tableSuccess,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3">Master Drug List </h4>
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <div className="card">
                                            <div class="card-header"> <div class="card-title">Import Drug</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                      
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    0 === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div className="form-group  mt-2 d-flex justify-content-center mg-btm5">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin ml-2"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.cancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                        <div className="float-right btn mg-tp5 "> <AdminDrugTemplate /></div>
                                                        <div className="row">
                                                            <div className="col-sm-4" >
                                                                <div className="custom-file text-left">
                                                                    {/* <input type="file" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" /> */}
                                                                    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" type="file" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" />
                                                                  {/*   <label className="custom-file-label" htmlFor="file">Choose file</label> */}
                                                                    <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                                                                    {/* <div className="form-group  mt-2 d-flex justify-content-end">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.cancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div> */}
                                                                </div>
                                                            </div>

                                                            {/* <div className="form-group"><label className="control-label">{this.state.file ? this.state.file.name : ""}</label></div> */}

                                                           
                                                        </div>
                                                    </div>
                                                   {/*  <div className="form-group"><label className="control-label">{this.state.file ? this.state.file.name : ""}</label></div> */}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                                show={this.state.deletePopup}
                                                                onHide={() =>
                                                                    this.setState({
                                                                        deletePopup: false,
                                                                    })
                                                                }
                                                                onConfirm={() =>
                                                                    this.deleteRecord(this.state.record)
                                                                }
                                                                title={"Confirmation"}
                                                                message={
                                                                    "Are you sure you want to delete this record?"
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </section>
                            <FooterSection />
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default connect(
    mapStateToProps,
)(DrugImport);