import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { getAllBusinessList,removeBusinessFromBusinesslist,GetAppointmentCompletedReport } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import moment from "moment";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import Select from "react-select";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import Validation from "../actions/Validation";
import DatePicker from "react-date-picker";
function mapStateToProps(state) {
return {
appdata: state.AppData,
userdata: state.AuthReducer.user,
access_token: state.AuthReducer.access_token,
};
}
function findWithAttr(array, attr, value) {
for (var i = 0; i < array.length; i += 1) {
if (array[i][attr] === value) {
return i;
}
}
return -1;
}
class AppointmentCompletedReport extends Component {
constructor(props) {
super(props);
this.state = {
tableSuccess: {
message: "",
status: "",
},
records: [],
actions: "update",
loading: false,
showDeleteConfirmPopup: false,
confirmedDelete: false,
deletePopup: false,
hospitalreport:
{
//      id:"",
//    user_id: "",
business_name:"",
Fromdate:"",
Todate:"",
},
errors: {
business_name:"",
Fromdate:"",
Todate:"",
},
formSuccess: {
message: "",
status: "",
},
};
this.columns = [
{ text: "S.No",
className: "s_no",
key:"s_no",
align: "left",
sortable: true,
},
{
key: "business_name",
text: "Business Name",
className: "business_name",
align: "left",
sortable: true,
},
{
key: "doctor_name",
text: "Doctor Name",
className: "doctor_name",
align: "left",
sortable: true,
},
{
key: "Patient_id",
text: "Patient ID",
className: "Patient_id",
align: "left",
sortable: true,
},
{
key: "patient_name",
text: "Patient Name",
className: "patient_name",
align: "left",
sortable: true,
},
{
key: "email",
text: "Email",
className: "email",
align: "left",
sortable: true,
},
{
key: "date_of_birth",
text: "Date Of Birth",
className: "date_of_birth",
align: "left",
sortable: true,
cell: (record) => {
return moment(record.date_of_birth).format("DD-MM-YYYY");
},
},
{
key: "gender",
text: "Gender",
className: "gender",
sortable: true,
align: "left",
},
/*     {
key: "marital_status",
text: "Marital Status",
className: "marital_status",
sortable: true,
align: "left",
}, */
{
key: "blood_group",
text: "Blood Group",
className: "blood_group",
sortable: true,
align: "left",
},
{
key: "phone_number",
text: "Phone Number",
className: "phone_number",
sortable: true,
align: "left",
},
{
key: "ApptDate",
text: "Appointment Date",
className: "ApptDate",
sortable: true,
align: "left",
},
{
key: "ApptTime",
text: "Appt time",
className: "ApptTime",
sortable: true,
align: "left",
},
{
key: "ApptEndTime",
text: "Check out time",
className: "ApptEndTime",
sortable: true,
align: "left",
},
];
this.config = {
page_size: 10,
length_menu: [10, 20, 50],
button: {
excel: false,
print: false,
},
};
}
handleColumnChange = (record, selectedOptions) => {
record.status = selectedOptions ? selectedOptions.value : "Pending";
this.UpdateColumnChange(record);
};
UpdateColumnChange = (data) => {
// axios.defaults.headers.common[
//     "Authorization"
// ] = `Bearer ${this.props.access_token}`;
// let request = {
//     business_user_id: data.user_id, status: data.status
// }
// axios.post(updateDoctorStatus1, request).then((list) => {
let records = this.state.records;
//     // data = list.data.data;
//     if (list.data.status === "1") {
var recIndex = findWithAttr(records, "user_id", data.user_id);
records[recIndex].status = data.status;
this.setState({ records, tableSuccess: "" });
// }
this.clearMessage();
// });
};
handleselectChange = (selectedOptions, e) => {
let data = this.state.hospitalreport;
data[e.name] = selectedOptions;
this.setState({ hospitalreport: data });
};
clearMessage = () => {
setTimeout(
() =>
this.setState({
formSuccess: { message: "" },
tableSuccess: { message: "" },
}),
5000
);
};
handleFromDOBChange = (date) => {
let Hospitalreport = this.state.hospitalreport;
Hospitalreport.Fromdate = date;
this.setState({ hospitalreport: Hospitalreport });
};
handleToDOBChange = (date) => {
let Hospitalreport = this.state.hospitalreport;
Hospitalreport.Todate = date;
this.setState({ hospitalreport: Hospitalreport });
};
componentDidMount = () => {
axios.defaults.headers.common[
"Authorization"
] = `Bearer ${this.props.access_token}`;
axios
.get(getAllBusinessList, null, CONFIG)
.then((list) => {
console.log(list)
let business = [];
let userlist = list.data.data;
userlist.forEach((element, index) => {
business.push({ label: element.business_name, value: element.user_id })
});
this.setState({ business_list: business });
})
.catch((e) => { console.log(e) });
}
cancel = () => {
let data = this.state.hospitalreport;
data.id="";
data.business_name = "";
data.Fromdate="";
data.Todate="";
this.setState({ hospitalreport: data, actions: "update" });
this.setState({  reportList: [] });
};
Printpage=()=>
{   
window.print();
}
editRecord = (record) => {
console.log(record);
this.setState({ actions: "edit" });
let hospitaldata = this.state.hospitaldata;
hospitaldata.id=record.id;
hospitaldata.user_id = record.user_id;
hospitaldata.business_name=record.business_name;
hospitaldata.id = record.id;
hospitaldata.user_id = record.user_id;
hospitaldata.email=record.email;
hospitaldata.phone_number = record.phone_number;
hospitaldata.subscription={
label: record.subscription,
value: record.subscription,
};
hospitaldata.payment_status = {
label: record.payment_status,
value: record.payment_status,
};
if(hospitaldata.expires_at===null|| hospitaldata.expires_at==="")
{
hospitaldata.expires_at =record.expires_at;
}else
{
hospitaldata.expires_at=moment(record.expires_at,moment.defaultFormat).toDate();
}
this.handleHospitalChange("user_id", hospitaldata.user_id);
this.setState({ hospitaldata: hospitaldata });
};
clearAll() {
let actions = this.state.actions;
actions = "update";
this.setState({ actions });
let hospitaldata = {};
hospitaldata.id="";
hospitaldata.business_name="";
hospitaldata.email = "";
hospitaldata.phone_number = "";
hospitaldata.subscription="";
hospitaldata.payment_status="";
hospitaldata.expires_at="";
this.setState({ hospitaldata });
}
handleHospitalChange(value, field) {
let fields = this.state.hospitaldata;
// let errors = this.state.educationerror;
console.log(field);
if (value === "email") {
fields.email = field.target.value;
// errors[i].register_id = '';
}else if (value === "id") {
fields.id = field;
// errors[i].education = '';
} 
else if (value === "business_name") {
fields.business_name = field.target.value;
// errors[i].education = '';
}  
else if (value === "phone_number") {
fields.phone_number = field.target.value;
// errors[i].education = '';
}  
else if (value === "subscription") {
fields.subscription = field.target.value;
// errors[i].education = '';
}  
else if (value === "expires_at") {
fields.expires_at = field.target.value;
// errors[i].education = '';
}else if (value === "payment_status") {
fields.payment_status = field.target.value;
// errors[i].education = '';
}                
this.setState({
hospitaldata: fields,
});
}
getHospitalReport=(e)=> {
// let user_id = record.user_id;
let { hospitalreport, errors } = this.state;
//  hospitaldata.type = "hospitallist";
errors = Validation(hospitalreport, errors);
this.setState({ errors: errors });
if (errors.formIsValid) {
let data = this.state.hospitalreport;
//  let data = {};
data.business_id=hospitalreport.business_name.value;
//console.log(moment.utc(hospitalreport.Fromdate).format('MM/DD/YYYY'));
data.Fromdate=hospitalreport.Fromdate;
data.Todate=hospitalreport.Todate;
axios.post(GetAppointmentCompletedReport, data, CONFIG).then((list) => {
console.log(data);
console.log(list.data);
let response=list.data;
if (response.status === "1") {
console.log(list.data.data);
this.setState({ records: list.data.data });
let records=list.data.data;
let reportList = [];
records.forEach((element, index) => {
let report = {};
report.s_no = index+1;
report.business_name=element.business_name;
report.doctor_name=element.doctor_name;
report.Patient_id = element.Patient_id;
report.patient_name = element.patient_name;
report.date_of_birth = element.date_of_birth;
report.gender = element.gender;
report.blood_group = element.blood_group;
report.phone_number = element.phone_number;
report.email = element.email;
report.ApptDate=element.ApptDate;
report.ApptTime=element.ApptTime;
report.ApptEndTime=element.ApptEndTime;
reportList[index] = report;
});
this.setState({ reportList: reportList });
this.setState({ formSuccess: list.data });
this.clearAll();
this.clearMessage();
}
});
}
}
deleteRecord(record) {
let user_id = record.user_id;
axios.defaults.headers.common[
"Authorization"
] = `Bearer ${this.props.access_token}`;
axios
.post(removeBusinessFromBusinesslist, { user_id: user_id }, CONFIG)
.then((res) => {
let response = res.data;
if (response.status === "1") {
let records = this.state.records;
const deleteIndex = records.map((item) => item.user_id).indexOf(record.user_id);
records.splice(deleteIndex, 1);
this.setState({ records });
this.setState({ tableSuccess: res.data });
this.clearMessage();
}
})
.catch((e) => { });
this.setState({ deletePopup: false, record: null });
this.setState({
confirmedDelete: false,
});
axios.defaults.headers.common[
"Authorization"
] = `Bearer ${this.props.access_token}`;
axios
.get(getAllBusinessList, null, CONFIG)
.then((list) => {
console.log(list)
this.setState({ records: list.data.data });
})
.catch((e) => { console.log(e) });
}
render() {
let PopupClose = () => this.setState({ addPopup: false });
let PopupConfirm = () => this.deleteRecord(this.state.record);
const {
business_list,
hospitalreport,
formSuccess,
tableSuccess,
errors,
loading,
} = this.state;
return (
<div id="layout-wrapper">
   {/* <!-- ============================================================== --> */}
   {/* <!-- Start right Content here --> */}
   {/* <!-- ============================================================== --> */}
   <div className="main-content grayback">
      <div className="mainpage">
      <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
         <div className="main-box adminMainBox">
            <div className="sidemenu pr0">
               <AdminSideBar />
            </div>
            <section className="main-body hospital-profilegap checkout-tabs">
               <div className="col-sm-12">
                  <h4 className="font-weight-bold mt-3 mb-3"> Appointment Completed Report </h4>
                  <div className="row mt-4">
                     <div className="col-sm-12">
                        <div className="card">
                           <div className="card-body">
                              <div>
                                 <div className="user-notification">
                                    <SpanField
                                    classname={
                                    0 === formSuccess.status
                                    ? "text-danger user-notification"
                                    : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                    />
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-4">
                                       <div>
                                          <label>Business Name</label>
                                       </div>
                                       <div className="form-group">
                                          <Select maxMenuHeight={120}
                                             className="w-100 p-0"
                                             name="business_name"
                                             value={hospitalreport.business_name}
                                             onChange={
                                             this.handleselectChange
                                             }
                                             options={business_list}
                                             isClearable={true}
                                             />
                                          <div><span>&nbsp;</span></div>
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.business_name}
                                             />
                                       </div>
                                    
                                    </div>
                                    <div className="col-lg-4">
                                       <div>
                                          <label>From Date</label>
                                       </div>
                                       <div className="form-group">
                                          <DatePicker
                                             className="form-control"
                                             value={hospitalreport.Fromdate}
                                             format="dd-MM-yyyy"
                                             onChange={(date) =>
                                          this.handleFromDOBChange(date)
                                          }
                                          yearPlaceholder='YYYY'
                                          monthPlaceholder='MM'
                                          dayPlaceholder='DD'
                                           />
                                       </div>
                                       <SpanField
                                          classname="text-danger"
                                          title={errors.Fromdate}
                                          />
                                    </div>
                                    <div className="col-lg-4">
                                       <div>
                                          <div>
                                             <label>To Date</label>
                                          </div>
                                          <div className="form-group">
                                             <DatePicker
                                                className="form-control"
                                                value={hospitalreport.Todate}
                                                format="dd-MM-yyyy"
                                                onChange={(date) =>
                                             this.handleToDOBChange(date)
                                             }
                                             yearPlaceholder='YYYY'
                                             monthPlaceholder='MM'
                                             dayPlaceholder='DD'
                                            
                                             />
                                          </div>
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.Todate}
                                             />
                                       </div>
                                    </div>
                                    <div className='col-lg-12 text-center'>
                                       <div className='float-right'>
                                    <button
                                             ref="btn"
                                             href="patients-register"
                                             className="signupbtn abtn btdpml btn-margin"
                                             onClick={this.getHospitalReport}>
                                             {loading ? (
                                             <LoadingSpinner title="Get Report" />
                                             ) : this.state.actions === "Update" ? (
                                             "Get Report"
                                             ) : (
                                             "Get Report"
                                             )}
                                          </button>
                                          <button
                                             href="patients-register"
                                             className="signupbtn abtn btdpml btn-margin"
                                             onClick={this.cancel}
                                             >
                                          Cancel
                                          </button>
                                          <button
                                             href="patients-register"
                                             className="signupbtn abtn btdpml"
                                             onClick={this.Printpage}
                                             >
                                          print
                                          </button>
                                          </div>
                                    </div>
                                 </div>
                              
                              </div>
                             
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-12">
                        <div className="card">
                           <div className="card-body">
                              <div className="tab-content" id="v-pills-tabContent">
                                 <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                    >
                                    <div className="user-notification">
                                       <SpanField
                                       classname={
                                       0 === tableSuccess.status
                                       ? "text-danger user-notification"
                                       : "text-success user-notification"
                                       }
                                       title={tableSuccess.message}
                                       />
                                    </div>
                                    <ReactDatatable
                                       config={this.config}
                                       records={this.state.reportList}
                                       columns={this.columns}
                                       className="table table-bordered table-striped kas  mobile_table"
                                       />
                                    <Popup
                                       show={this.state.deletePopup}
                                       onHide={() =>
                                    this.setState({
                                    deletePopup: false,
                                    })
                                    }
                                    onConfirm={() =>
                                    this.deleteRecord(this.state.record)
                                    }
                                    title={"Confirmation"}
                                    message={
                                    "Are you sure you want to delete this record?"
                                    }
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <FooterSection />
         </div>
      </div>
   </div>
</div>
);
}
}
export default connect(
mapStateToProps,
)(AppointmentCompletedReport);