import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import InputField from "../components/UI/InputField";
import { referral_categories } from "../config/data";
import Select from "react-select";
import Label from "../components/UI/Label";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import SpanField from "../components/UI/SpanField";
import Validation from "../actions/Validation";
import { Spinner } from "../components/UI/Spinner";
import { sendReferralemail,BaseUrl,sendsms, getReferralDetails,getTimeoutbycategory,redeemReferralPoints } from "../config/url.json";
import { CONFIG } from "../config/data";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FooterSection from "../components/UI/FooterSection";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class Referrals extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      
      referralData: {
        name: "",
        category: "",
        email: "",
        phone_number: "",
        user_id: props.userdata.id,
          
      },
      errors: {
        name: "",
        category: "",
        email: "",
        phone_number: "",
        pointstoberedeemed:"",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      tableerror:
      {
        
      },
      referral_details: {},
      copied: false,
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleInput = this.handleInput.bind(this);
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  

  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let url = getReferralDetails;
    let data = { user_id: this.props.userdata.id };
    axios
      .post(url, data, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status !== "0")
          this.setState({
            referral_details: data.data,
          });
        else {
          this.setState({
            referral_details: {},
          });
        }
      })
      .catch((e) => { });
  };
  submitReferrals = (event) => {
    event.preventDefault();
    let { referralData, errors } = this.state;

    referralData.type = "referral_page";

    errors = Validation(referralData, errors);

    this.setState({ errors: errors });
    if (errors.formIsValid) {
      this.setState({ loading: true });

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let referralData = this.state.referralData;
      // console.log(referralData);
      let user = this.props.userdata;

      let reqdata = {
        "to" : referralData.email,
        "subject" : "Referral from Business",
        "mailHeader" : "Welcome to Business",
        "message": "<p>Hi "+referralData.name+",</p>"+"You’ve been invited by "+ user.first_name+", who thinks you’ll like KlinicQ.</p><p>Kindly join our business by using the referral link!</p><p> Sign up to proceed further.</p><a href="+BaseUrl+"/?referral_code="+user.id+"><b>Click Here</b></a><p>It would be great,if you could refer to someone.</p><p>Sincerely,</p><p>KlinicQ Team.</p>"
    };
    
    let notificationdata = {
      "message_code": "REFERRAL",
      "language_code": "en",

      "data": {
        "first_name": user.first_name,
        "base_url":BaseUrl,
        "unique_id": user.id,

        // "referral_code":"referral_code"


      },
      "receiver": {
        "phoneNumber": referralData.phone_number
      }
    };
    console.log(reqdata);
    console.log(notificationdata);
      let url = sendReferralemail;
      // console.log(this.props.userdata);
    //  referralData.category = referralData.category.label;
      axios
        .post(url, reqdata)
        .then((res) => {
          let resdata = res.data;


          
          axios
          .post(sendsms, notificationdata)
          .then((result) => {
            console.log(result)
          }).catch((err) => {
            console.log(err);
          })
          console.log(resdata);
          this.setState({ loading: false });
          let data = {
            message:"",
            status:""
          }
          if (resdata.status===1) {
            data.error_type = "success";
            data.message = "Referral email sent successfully"
            data.status = "1"
          //  this.setState({
           //   formSuccess: data,
           // });
            toast.success("Thank you! Your referral link has been sent.",{
              className:"toast-success"
            });
            this.setState({ loading: false });
            this.clearMessage();
          } else {
            data.error_type = "error";
            data.message = "Failed"
            data.status = "0"
           // this.setState({
           //   formSuccess: data,
           // });
            toast.error("There was a problem with your submission. ",{
              className:"toast-error"
            });
            this.setState({ loading: false });
            this.clearMessage();
          }
        })
        .catch((e) => { });
    }
  };
  redeemReferralpoints=(event)=>
  {
    event.preventDefault();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let referraldata=this.state.referral_details;
    if(referraldata.total_credits>0)
    {
    let data = {

      user_id:this.props.userdata.id,
      points_redeemed:referraldata.total_credits
    };
     
    axios
      .post(redeemReferralPoints,data, CONFIG)
      .then((res) => 
      {
        let data = res.data;
        console.log(data);
        if (data.status === "1") 
        {
        /*   let tableSuccess = {
            message: "Points Redeemed Successfully",
            status: "1",
          };
         */  
       //   this.setState({ tableSuccess});
       toast.success("Points Redeemed Successfully. ",{
        className:"toast-success"
      });
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
      
          let url = getReferralDetails;
          let data = { user_id: this.props.userdata.id };
          axios
            .post(url, data, CONFIG)
            .then((res) => {
              let data = res.data;
              console.log(data);
              if (data.status !== "0")
                this.setState({
                  referral_details: data.data,
                });
              else {
                this.setState({
                  referral_details: {},
                });
              }
            })
            .catch((e) => { }); 
          
          
        }
        else {
         /*  let tableSuccess = {
            message: "Points Not Redeemed",
            status: "0",
          }; */
        //  this.setState({ tableSuccess});
        toast.error("Points Not Redeemed. ",{
          className:"toast-error"
        });
          this.clearMessage();
        }
      })
    }else
    {
      let tableSuccess = {
        message: "You have zero points u cannot Redeem",
        status: "0",
      };
      this.setState({ tableSuccess});
      this.clearMessage();
    }
  };

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
        }),
      5000
    );
    let referralData = {
      name: "",
      category: "",
      email: "",
      phone_number: "",
      user_id: this.props.userdata.id,
        
    }; let errors = {
      name: "",
      category: "",
      email: "",
      phone_number: "",
      pointstoberedeemed:"",
    };
    console.log(referralData)
    this.setState({errors:errors,referralData:referralData});
  };
  handlePhoneInput = (event) => {
    let referralData = this.state.referralData;
    let errors = this.state.errors
    referralData.phone_number = event;
    errors.phone_number = ""
    this.setState({ referralData,errors });
  };
  handleInput = (e) => {
    let data = this.state.referralData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
let errors = this.state.errors
    if (name === "name" || name === "email" || name === "phone_number") {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
    }
    this.setState({ referralData: data,errors });
  };
  handleRedeemInput = (e) => {
    let data = this.state.referralData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (name=== "pointstoberedeemed") {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ referralData: data });
  };

  validateMaxLength(name, value, max) {
    let data = this.state.referralData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      // const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ referralData: data, errors: errors });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.referralData;
    data[e.name] = selectedOptions;
let errors = this.state.errors
errors[e.name] = ""
    this.setState({ referralData: data,errors });
  };
  render() {
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const { referralData, errors, formSuccess,tableSuccess, referral_details,hospitaltimeout,loading } = this.state;
    return (
      <div className="main-content bacfot">
        <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

      {/*   <Spinner loading={this.state.loading} /> */}
      {loading && <Dnaspinner />}
        <div className="mainpage">
          <section className="my-head">
            <div className="container-fluid ">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessHeader user_id={this.props.userdata.id} />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserHeader user_id={this.props.userdata.id} />
              ) : (
                <Header user_id={this.props.userdata.id} />
              )}
            </div>
          </section>
          <div className="main-box">



            <div className="sidemenu">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessUserSideBar />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserSideBar />
              ) : (
                <ClientSideBar />
              )}
            </div>
            <section className="main-body hospital-profilegap">
              <div className="container-fluid">

                <div className="row checkout-tabs">


                  <div className="col-lg-12">

                    <div className="">
                      <h4 className="font-weight-bold">Referral</h4>
                    </div>
                   {(this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
                    <div className="row mt-4">
                    <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                                  <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                                            </div>
                                                           
                      <div className="col-sm-6 pr0">
                        <div className="card">
                        <div class="card-header"> <div class="card-title">Refer a Friend</div> </div>
                          <div className="card-body c-page-10hite2">
                            <div className="user-notification">
                              <SpanField
                                classname={
                                  "0" === formSuccess.status
                                    ? "text-danger user-notification"
                                    : "text-success user-notification"
                                }
                                title={formSuccess.message}
                              />
                            </div>
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                              
                                <div className="row  align-items-center referfriend">
                                  <div className="col-sm-4">
                                    <div className="form-group mb-0">
                                    <Label title="Name" isMandatory={true} className="mb-0" />
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                      {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                          /> */}
                                      <InputField
                                        isMandatory={true}
                                        inputType={"text"}
                                        // title={"Business name"}
                                        name={"name"}
                                        controlFunc={this.handleInput}
                                        content={referralData.name}
                                        placeholder={""}
                                        max={50}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.name}
                                      />
                                    </div>
                                  </div>
                                </div>
                              {/*   <div className="row">
                                  <div className="col-sm-4">
                                    <div className="form-group">
                                      <label>Category </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <div className="form-group sform-control">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="category"
                                        value={referralData.category}
                                        onChange={this.handleselectChange}
                                        options={referral_categories}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.category}
                                      />
                                    </div>
                                  </div>
                                </div> */}
                                <div className="row align-items-center ">
                                  <div className="col-sm-4">
                                    <div className="form-group mb-0">
                                    <Label title="E-mail" isMandatory={true} className="mb-0" />
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                      {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                          /> */}
                                      <InputField
                                        isMandatory={true}
                                        inputType={"text"}
                                        // title={"Business name"}
                                        name={"email"}
                                        controlFunc={this.handleInput}
                                        content={referralData.email}
                                        placeholder={""}
                                        max={50}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.email}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row align-items-center">
                                  <div className="col-sm-4">
                                    <div className="form-group mb-0">
                                    <Label title="Mobile Number" isMandatory={true} className="mb-0" />
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                    
                                      <div className="form-control">
                                        <PhoneInput
                                          international
                                          defaultCountry="IN"
                                          placeholder="Enter phone number"
                                          value={referralData.phone_number}
                                          onChange={this.handlePhoneInput}
                                          maxLength={15}
                                          error={
                                            referralData.phone_number
                                              ? isValidPhoneNumber(
                                                referralData.phone_number
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                              : "Phone number required"
                                          }
                                        />
                                      
                                      </div>
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.phone_number}
                                        />
                                    </div>
                                    </div>
                                    <div className="col-sm-4">
                                  
                                    </div>
                                    {/* <div className="col-sm-8">
                                    <div className="form-group frlrit">
                                      <p className="socialmediai">
                                        <a href="#" className="whatsapp a-disable">
                                          <i className="fa fa-whatsapp "></i>
                                        </a>
                                        <a href="#" className="youtube a-disable">
                                          <i className="fa fa-google-plus"></i>
                                        </a>
                                        <a href="#" className="twitter a-disable">
                                          <i className="fa fa-twitter"></i>
                                        </a>
                                        <a href="#" className="facebook a-disable">
                                          <i className="fa fa-facebook-square"></i>
                                        </a>
                                        <a href="#" className="linkedin a-disable" >
                                          <i className="fa fa-linkedin-square"></i>
                                        </a>
                                        <a href="#" className="youtube a-disable">
                                          <i className="fa fa-youtube-play"></i>
                                        </a>
                                      </p>
                                    </div>
                                    </div> */}
                                   
                                    
                                    <div className="col-sm-8">
                                    <div className=" form-group  pl-35">
                                      <button
                                        // href="patients-register"
                                        className="signupbtn abtn  adbt float-right"
                                        data-toggle="modal"
                                        data-target=".bs-example-modal-center"
                                        onClick={this.submitReferrals}
                                      >
                                        Send
                                      </button>
                                    </div>
                                    {/* <div className="form-group text-center">
                                    <CopyToClipboard
                                      text={
                                        window.location.origin +
                                        "?referral_code=" +
                                        referral_details.referal_code
                                      }
                                      onCopy={() =>
                                        this.setState({ copied: true })
                                      }
                                    >
                                      <button className="btn btn-link alert alert-primary text-decoration-none font-weight-bold">Copy Link to Share</button>
                                    </CopyToClipboard>
                                    </div> */}
                                   
                                    <div>
                                      {this.state.copied ? (
                                        <span style={{ color: "red" }}>
                                          Copied.
                                        </span>
                                      ) : null}
                                    </div>
                                    </div>
                                   
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 pl0">
                        <div className="row">
                          <div className="col-sm-6 pr0">
                            <div className="card">
                            <div class="card-header"> <div class="card-title">  Number of Referral</div> </div>
                              <div className="card-body c-page-10hite">
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                  
                                    <h4 className="c10cont">
                                      <a href="/referral-details">
                                        {" "}
                                        {referral_details.numberofreferrals}
                                      </a>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 pl0">
                            <div className="card">
                            <div class="card-header"> <div class="card-title">Referrals Accepted</div> </div>
                              <div className="card-body c-page-10hite">
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                 
                                    <h4 className="c10cont">
                                      {
                                        referral_details.numberofreferrals_accepted
                                      }
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                        <div class="card-header"> <div class="card-title"> Referral Points</div> </div>
                          <div className="card-body c-page-10hite">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                            
                                <table className="messagesc5 wid100 mt-0">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <a href="/#">
                                          Hospital & Doctor Referrals
                                        </a>
                                      </td>
                                      <td>
                                        <a href="/#">
                                          <p className="font-family">
                                            {
                                              referral_details.business_professional_credit
                                            }{" "}
                                            Points
                                          </p>
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a href="/#">Client referrals</a>{" "}
                                      </td>
                                      <td>
                                        <a href="/#">
                                          <p className="font-family">
                                            {referral_details.client_credit}{" "}
                                            Points
                                          </p>
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a href="/#">Total </a>{" "}
                                      </td>
                                      <td>
                                        <a href="/#">
                                          <p className="font-family">
                                            {referral_details.total_credits}{" "}
                                            Points
                                          </p>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                        <div class="card-header"> <div class="card-title">     Redeem Points</div> </div>
                          <div className="card-body c-page-10hite">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                          
                                <div className="row mt-25">
                                  <div className="col-sm-4">
                                    <div className="form-group mb-0">
                                      <label className="mb-0 font-family">Points to be Redeemed </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <div className="form-group">
                                    <InputField
                                        isMandatory={true}
                                        inputType={"text"}
                                         name={"pointstoberedeemed"}
                                        controlFunc={this.handleRedeemInput}
                                        content={referral_details.total_credits}
                                        placeholder={""}
                                        max={50}
                                      />
                                      
                                 
                                    </div>
                                    </div>
                                    <div className="col-sm-12">
                                    <div className="form-group text-center mb-0 pl-35 ">
                                      <button
                                        href="patients-register"
                                        className="signupbtn abtn  adbt float-right"
                                        data-toggle="modal"
                                        data-target=".bs-example-modal-center"
                                        onClick={this.redeemReferralpoints}
                                      >
                                        Redeem
                                      </button>
                                    </div>
                                    </div>
                                  
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)}

                  </div>


                  {/* <Footerad /> */}

                </div>
              </div>

          

            </section>
            <FooterSection />
          </div>


        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Referrals);
