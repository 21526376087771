
import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";

export default function TextEditor(props) {
  const editor = useRef(null);
  const [content, setContent] = useState(props.content);

  const config = {
    readonly: false
  };

  return (
    <>
      <JoditEditor
      id={props.id}
      value={props.content}
        ref={editor}
        // value={content}
        config={config}
        tabIndex={1}
        onBlur={(e) => setContent(e)}
      />
      {/* <div dangerouslySetInnerHTML={{ __html: props.content }} /> */}
    </>
  );
}
