import { Route, Redirect } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";

const GuestRoute = ({ component: Component, ...rest }) => {
  console.log(rest);
  return (
    <Route
      {...rest}
      render={(props) =>
        !rest.loggedIn ? (
          <Component {...props} />
        ) : (rest.user.user_type === "admin" ? (
          <Redirect
            to={{
              pathname: "/admindashboard",
              state: { from: props.location },
            }}
          />
        ) : (
          // <Redirect
          //   to={{
          //     pathname: "/dashboard",
          //     state: { from: props.location },
          //   }}
          // />
          <Component {...props} />
        )

        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.AuthReducer.loggedIn,
    user: state.AuthReducer.user,
  };
};
export default connect(mapStateToProps)(GuestRoute);
