import React, { Component } from "react";
import { connect } from "react-redux";
// import InputField from "../../../components/UI/InputField";
import axios from "axios";
import {
  getCheckinDetails,
  getClientShortDetail,
  updateCheckin,
} from "../../../config/url.json";
import { CONFIG } from "../../../config/data";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

// function mapDispatchToProps(dispatch) {
//   return {};
// }

class ConsultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkinData: [],
      headerInfo: {},
    };
  }
  handleInput = (e) => {
    let data = this.state.checkinData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ checkinData: data });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    console.log(this.props.id)
    // const checkinData = { id: this.props.id };
    data.id = this.props.id;

    axios
      .put(updateCheckin, { id: this.props.id,height:data.height,weight:data.weight,temperature:data.temperature,bp:data.bp,pulse:data.pulse }, CONFIG)
      .then((res) => {
        let data = res.data.data || res.data.date;
        if (res.data.status !== "1" || !data) {
          return;
        }

        // this.setState({
        //   checkinData: data,
        // });
      }).catch((e) => { });
  }
  componentDidMount = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    console.log(this.props.id)
    const checkinData = { id: this.props.id };
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        console.log(res);
        let data = res.data.data || res.data.date;
        if (res.data.status !== "1" || !data) {
          return;
        }

        this.setState({
          checkinData: data,
        });

        // window.setTimeout(() => {
        console.log(data.client_id);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        // let client_id = this.state.checkinData.client_id;
      //  console.log(this.props.userdata.id)
        axios
          .post(getClientShortDetail, { client_id: this.state.checkinData.client_id })
          .then((short_details) => {
            if (short_details.data.status === "1") {
              let info = short_details.data.data[0];
              let headerInfo = {};
              headerInfo.avatar = info.avatar;
              headerInfo.full_name = info.first_name;
              headerInfo.registeration_id = info.patient_id;
              headerInfo.blood_group = info.blood_group;
              headerInfo.age = info.age;
              headerInfo.profile_percentage = info.profile_percentage;
              this.setState({ headerInfo });
            }
          })
          .catch((e) => {
            console.log("client short info failed");
          });
        // }, 1000);
      })
      .catch((e) => { });
  };

  render() {
    const { checkinData, headerInfo } = this.state;
    return (
      <section class="my-head">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-2 text-left pr0">
              <div class="text-center logocenter">
                <a href="#">
                  {" "}
                  <img alt="" src={require("../../../assets/images/logo.png")} />
                </a>
              </div>
            </div>
            <div class="col-sm-3 pl0">
              <div class="row justify-content-end">
                <div class="col-sm-5">
                  <div class="doctorimg">
                    <img alt="" src={headerInfo.avatar
                          ? headerInfo.avatar
                          :require("../../../assets/images/user.png")} />
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="doctorprofile">
                    <h5>
                      <span class="witfnt">
                        Patient ID:
                        {headerInfo.registeration_id}
                      </span>
                    </h5>
                    <h5>
                      <span class="witfnt">
                        {headerInfo.full_name}, {headerInfo.age}
                        {headerInfo.gender}
                      </span>
                      {headerInfo.blood_group}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7 pl0 rw16">

              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between s-118">
                      <h5 className="border-0 small mb-0">Height
                      </h5>
                      <img alt="" 
                          src={require("../../../assets/images/vitaldetails1.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        //disabled
                        value={checkinData.height}
                        name="height"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">cm </span>



                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between s-118 ">
                      <h5 className="border-0 small mb-0">Weight
                      </h5>
                      <img alt="" class=""
                          src={require("../../../assets/images/vitaldetails2.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={checkinData.weight}
                       // disabled
                        name="weight"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">kg </span>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between pr-0 s-118 ">
                      <h5 className="border-0 small mb-0">Temperature
                       

                      </h5>
                      <img alt="" class="btn-space"
                          src={require("../../../assets/images/vitaldetails3.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={checkinData.temperature}
                       // disabled
                        // placeholder="Temperature"
                        name="temperature"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">°F
                        </span>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between pr-0 s-118 ">
                      <h5 className="border-0 small mb-0">BP
                       

                      </h5>
                      <img alt="" class=""
                          src={require("../../../assets/images/vitaldetails4.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={checkinData.bp}
                      //  disabled
                        name="bp"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text p-0">mmHg </span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between pr-0 s-118 ">
                      <h5 className="border-0 small mb-0">Pulse
                       

                      </h5>
                      <img alt="" class=""
                          src={require("../../../assets/images/vitaldetails5.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={checkinData.pulse}
                    //    disabled
                        name="pulse"
                        onChange={this.handleInput}

                      />

                      <div class="input-group-append">
                        <span class="input-group-text p-0">per min</span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>






            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(mapStateToProps)(ConsultHeader);
