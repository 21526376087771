import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import {
  getAllMasterDrug,
  getAllDrugs,
  getAllDrugTypes,
  getAllDrugCategories,
  addDrug,
  updateDrug,
  deleteDrug,
  searchDrug,
  getAllDrugMeasures,
  getTimeoutbycategory,
  getAllTransfertypedata
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";

import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
// Import React Table
import "react-table/react-table.css";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Spinner } from "../../components/UI/Spinner";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import FooterSection from "../../components/UI/FooterSection";
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class StockReport extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
        timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
      
      records: [],

      searchrecords: [],
      drug_action: "Add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      newDatatoAdd: {},
      addPopup: false,
      Transfertype:
      {
        id:"",
        Transfer_type:"",
        
      },
     
      drugList: {
        s_no: "",
        drug_id: "",
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      drugData: {
        id: "",
        drug_id: "",
        manufacturer: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        active_ingredients: "",
        unit_of_measure: "",
      },
      errors: {
        drug_id: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        unit_of_measure: "",
      },
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
     
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "alert",
        text: "Alert",
        className: "alert",
        align: "left",
        sortable: true,
      
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
      
      },
     
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
        
      },
      {
        key: "category",
        text: "Category",
        className: "category",
        align: "left",
        sortable: true,
       
      },

      {
        key: "type",
        text: "Type",
        className: "type",
        sortable: true,
        align: "left",
        
      },

      {
        key: "batch_id",
        text: "Batch ID",
        className: "batch_id",
        sortable: true,
        align: "left",
        
      },

      {
        key: "serial_id",
        text: "Serial Id",
        className: "serial_id",
        sortable: true,
        align: "left",
        
      },

      {
        key: "unit",
        text: "Unit",
        className: "unit",
        sortable: true,
        align: "left",
       
      },

      {
        key: "expiry_date",
        text: "Expiry Date",
        className: "expiry_date",
        sortable: true,
        align: "left",
        
      },
      {
        key: "safety",
        text: "Safety",
        className: "safety",
        sortable: true,
        align: "left",
        
      },

      {
        key: "storage_bin",
        text: "Storage Bin",
        className: "storage_bin",
        sortable: true,
        align: "left",
        
      },
      {
        key: "rack",
        text: "Rack",
        className: "rack",
        sortable: true,
        align: "left",
        
      },


      {
        key: "aisle",
        text: "Aisle",
        className: "aisle",
        sortable: true,
        align: "left",
        
      },
      {
        key: "available_stock",
        text: "Available Stock",
        className: "available_stock",
        sortable: true,
        align: "left",
        
      },
      {
        key: "blocked_stock",
        text: "Blocked Stock",
        className: "blocked_stock",
        sortable: true,
        align: "left",
        
      },
      {
        key: "inventory_value",
        text: "Inventory Value",
        className: "inventory_value",
        sortable: true,
        align: "left",
        
      },

   
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
   
  }

 
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;

    this.UpdateColumnChange(record);
  };
  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].drug_id = record.drug_id;
    records[recIndex].drug_name = record.drug_name;
    records[recIndex].drug_category = record.drug_category;
    records[recIndex].drug_type = record.drug_type;

    this.setState({ searchrecords: records });
  };
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch(() => { });

      axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
        .get(getAllTransfertypedata, null, CONFIG)
        .then((list) => {
            console.log(list)
            let Transfertype = list.data.data;
            let TransferTypeData = [];   
            Transfertype.forEach((element, index) => {
              
              TransferTypeData.push({
                value: element.id,
                label: element.Transfer_type,
                
              });
            });

            this.setState({ TransferTypeData });
        })
        .catch((e) => { console.log(e) });

 let members = [];

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllMasterDrug, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugNameList = drugMasterRespons.data.data;
        let masterdrugNames = [];
        masterdrugNameList.forEach((element, index) => {
          masterdrugNames.push({
            value: element.id,
            label: element.drug_name,
            
          });
        });

        this.setState({ masterdrugNames });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugTypes, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element, index) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;

        let drugCategories = [];
        list.forEach((element, index) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugs, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let drugList = list.data.data;
        let drugDataArr = [];
        drugList.forEach((element, index) => {
          let drugData = {};
          drugData.s_no = index + 1;
          drugData.id = element.id;
          drugData.user_id = this.props.userdata.id;
          drugData.drug_id = element.drug_id;
          drugData.drug_name = element.drug_name;
          drugData.drug_type = element.drug_type;
          drugData.drug_category = element.drug_category;
          drugData.manufacturer = element.manufacturer;
          drugData.active_ingredients = element.active_ingredients;
          drugData.unit_of_measure = element.unit_of_measure;
          drugDataArr[index] = drugData;
        });

        this.setState({ records: drugDataArr });
      })
      .catch((e) => { });


    axios
      .post(getAllDrugMeasures, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugmeasureList = drugMasterRespons.data.data;
        console.log(masterdrugmeasureList);
        let masterdrugmeasure = [];
        masterdrugmeasureList.forEach((element, index) => {
          masterdrugmeasure.push({
            value: element.unit_of_measure,
            label: element.unit_of_measure,

          });
        });

        this.setState({ masterdrugmeasure });
      })
      .catch((e) => { });
  };
  
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.drugData;
    data[e.name] = selectedOptions;
    // if (e.name === "drug_name") {
    //   data.manufacturer = selectedOptions.manufacturer;
    //   data.active_ingredients = selectedOptions.active_ingredients;
    // }
    this.setState({ drugData: data });
  };
  

  handleInput = (e) => {
    let data = this.state.drugData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ drugData: data });
  };

 
  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDrug, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        this.clearMessage();
      })
      .catch((e) => { });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearAll() {
    let drug_action = this.state.drug_action;
    drug_action = "Add";
    this.setState({ drug_action });
    let drugData = {};
    drugData.drug_id = "";
    drugData.drug_name = "";
    drugData.manufacturer = "";
    drugData.drug_category = "";
    drugData.drug_type = "";
    drugData.active_ingredients = "";
    drugData.unit_of_measure = "";
    this.setState({ drugData });
  }
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    const {
      drugData,
      drugTypes,
      drugCategories,
      masterdrugNames,
      errors,
      searchData,
      loading,
      formSuccess,
      searchTableSuccess,
      editformSuccess,
      tableSuccess,
      searchErrorMessage,
      hospitaltimeout,
      TransferTypeData
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;

    return (
<div id="layout-wrapper">  
          {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />  */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

      {/*   <Spinner loading={loading} />
 */}
             {loading && <Dnaspinner />}
      <div className="main-content bacfot">
      <div className="mainpage">
        <Header user_id={this.props.userdata.id} />
        <div className="main-box">
          <div className="sidemenu pr0">
            <BusinessUserSideBar />
          </div>

          <section className="main-body hospital-profilegap">
           <div className="container-fluid">
           
        <div className="col-lg-13">
            <h4 className="font-weight-bold"> Stock Report</h4>
            <div className="row ">
                      <div className="col-sm-12">

                        <div className="card">
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                               
                                    <div >
                                      <ReactDatatable
                                        config={this.config}
                                       // records={this.state.records}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />
                                      <Popup
                                        show={this.state.deletePopup}
                                        onHide={() =>
                                          this.setState({
                                            deletePopup: false,
                                          })
                                        }
                                        onConfirm={() =>
                                          this.deleteRecord(this.state.record)
                                        }
                                        title={"Confirmation"}
                                        message={
                                          "Are you sure you want to delete this record?"
                                        }
                                      />

                      
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                <div className="col-sm-12 pr0">
                    <div className="card b-12-hite">
                        <div className="card-body">
                            <div className="user-notification">
                                <SpanField
                                    classname={
                                        0 === formSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                />
                            </div>

                            <div>
                            <div class="card-header"> <div class="card-title">
                                    {this.state.actions === "add" ? "Add" : "Edit"}  Stock Report
                  </div></div>
                                <hr className="rowline" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd font-weight-bold">Transfers</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">From</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                 </div>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">To</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                 </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Drug ID</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"drug_id"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.drug_id}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Batch / Serial ID</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                         <InputField
                                                            inputType={"text"}
                                                            name={"batch_Serial_id1"}
                                                            controlFunc={this.handleInput}
                                                            placeholder={""}
                                                        /> 
                                                      

                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.batch_Serial_id1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Batch / Serial ID</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                         <InputField
                                                            inputType={"text"}
                                                            name={"batch_Serial_id2"}
                                                            controlFunc={this.handleInput}
                                                            placeholder={""}
                                                        />
                                                        

                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.batch_Serial_id2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Quantity</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"quantity"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.quantity}
                                                    />
                                                </div>
                                            
                                            </div>
                                            </div>
                                             </div>

                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Storage Bin</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"storage_binfrom"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.storage_binfrom}
                                                    />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Storage Bin</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"storage_binto"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.storage_binto}
                                                    />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                               </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Transfer Type</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                    <Select
                                                            className="form-control p-0"
                                                            name="Transfer_type"
                                                            //value={suppliesData.supplies_category}
                                                            onChange={this.handleselectChange}
                                                            options={TransferTypeData}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.Transfer_type}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Rack</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"rackfrom"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_name}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.rackfrom}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Rack</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"rackto"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.rackto}
                                                    />
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">New Expiration Date </label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"expiration_date"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.expiration_date}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                           </div>

                                           <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Asile</label>
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"asilefrom"}
                                                            controlFunc={this.handleInput}
                                                            //content={suppliesData.supplies_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.asilefrom}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                           </div>





                                    <div className="col-sm-4">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                                <label className="ffgrd">Aisle</label>
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                         <InputField
                                                            inputType={"text"}
                                                            name={"asileto"}
                                                            controlFunc={this.handleInput}
                                                           // content={suppliesData.supplies_category}
                                                            placeholder={""}
                                                        /> 
                                                     
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.asileto}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    




                                <div className="row">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-0 btn-align-center">
                                            
                                            <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml"
                                                onClick={this.cancel}
                                            >
                                                {"Save"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div></section>
        <FooterSection />
</div>
</div>
</div>
</div>





    );
}
}
export default connect(mapStateToProps)(StockReport);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
