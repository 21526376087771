import React, { Component,Fragment  } from 'react';
import { connect } from 'react-redux';
import Spinner from "../components/UI/Spinner";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import FooterSection from '../components/UI/FooterSection';
import SpanField from '../components/UI/SpanField';
import { CONFIG } from "../config/data";
import axios from 'axios';
import { getOffers, ChangeOffers } from '../config/url.json';
import { Popup } from "../components/UI/Popup";
import ReactDatatable from "@ashvin27/react-datatable";
import TextEditor from '../components/UI/TextEditor';
import Label from '../components/UI/Label';
import Validation from "../actions/Validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function mapStateToProps(state) {
    return {

    };
}
function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}
class Offers extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            addPopup: false,

            action:"Add",
            newsFeeds: { short_description: "", content: "",link:""},
            allnewsFeeds:[],
            formSuccess: { status: "", message: "" },
            tableSuccess:{status:"", message:""},
            errors: { short_description: "", content: "",link:""}
        }
        this.columns = [
            {
                key: "s_no",
                text: "S.No",
                className: "s_no",
                align: "left",
                sortable: true,
            },
            {
                key: "short_description",
                text: "short_description",
                className: "Short Description",
                align: "left",
                sortable: true,
            },
            {
                key: "content",
                text: "Content",
                className: "content",
                align: "left",
                sortable: true,
                cell: (record) => {
                return (                    
                    // <div> <TextEditor config={{disabled:true}}   content={record.content} />                    </div>
                    // <div>{record.content}</div>
                    <div
  dangerouslySetInnerHTML={{
    __html: record.content
  }}></div>
                );
                },
            },
            {
                key: "link",
                text: "Link",
                className: "link",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: true,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.onDeleteClick(record)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    onDeleteClick(record) {
        this.setState({ addPopup: true, record: record });
    }
    
    deleteRecord(record) {
        let userId = record.id;
        this.setState({ loading: true });

        axios
            .post(ChangeOffers, { id: userId,'action':"Delete" }, CONFIG)
            .then((res) => {
                let response = res.data;
                if (response.status === "1") {
                    let records = this.state.allnewsFeeds;
                    const deleteIndex = records.map((item) => item.id).indexOf(record.id);
                    records.splice(deleteIndex, 1);
                    this.setState({ allnewsFeeds:records });
                }
                this.setState({ addPopup: false, loading: false });
                this.setState({ tableSuccess: res.data });
                toast.error("Offers deleted. ", {
                    className: "toast-error"
                  });
                this.clearMessage();
            })
            .catch((e) => { });
    }

  
   
    editRecord(record) {
        console.log(record.content)    
        this.setState({
            newsFeeds: record,
            action: "Edit",
            // errors: errors,
        });
    }
    clearErrors = (errors) => {
        errors.short_description = "";
        errors.content = "";
        errors.link = "";
        // errors.expires_at = "";
        return errors;
    };
    handletexteditor = (e) =>{
        let data = this.state.newsFeeds;
        console.log(e.target.value);
        data[e.target.name] = e.target.value;
        this.setState({ newsFeeds:data});
    }
   
    handleInput = (e) => {
        let data = this.state.newsFeeds;
        console.log(e.target.value);
        data[e.target.name] = e.target.value;

        this.setState({ newsFeeds:data});
    }
    componentDidMount() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getOffers, null)
            .then((response) => {
                console.log(response.data)
                let newsfeedslist = response.data.data;

                newsfeedslist.forEach((element, index) => {
                    let newsfeed = {};
                    newsfeed.s_no = index + 1;
                    newsfeed.short_description = element.short_description;
                    newsfeed.link = element.link;
                    newsfeed.content = element.content;
                    
                    newsfeed.id = element.id;

                    newsfeedslist[index] = newsfeed;
                });

                this.setState({ allnewsFeeds: newsfeedslist })
                // console.log(this.state.newsFeeds)
            }).catch(() => {
                console.log("get queue failed");
            });
    }
    saveData = () => {
        console.log(document.getElementById('content').value)
        let newsFeeds = this.state.newsFeeds;
        newsFeeds.content = document.getElementById('content')? document.getElementById('content').value : "";
        // newsFeeds.terms = document.getElementById('terms')? document.getElementById('terms').value : "";
        // newsFeeds.faq = document.getElementById('faq')? document.getElementById('faq').value : "";
        // newsFeeds.offers = document.getElementById('offers')? document.getElementById('offers').value : "";
        console.log(newsFeeds)
        let request = {
            privacy_policy:newsFeeds.privacy_policy,
            offers:newsFeeds.offers,
            faq:newsFeeds.faq,
            terms:newsFeeds.terms
        };
        
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        
        axios
            .post(ChangeOffers, request)
            .then((response) => {
                console.log(response);
                console.log(response.data)
                this.setState({ newsFeeds: newsFeeds,formSuccess:response.data });

            }).catch(() => {
                console.log("Update queue failed");
            });
    }
    cancel = () => {
        let data = this.state.newsFeeds;
        data.short_description = "";
        data.content = "";
        data.link = "";
        data.id = "";
        document.getElementById('content').value = "";
        // data.expires_at = "";

        let errors = this.state.errors;

        errors = this.clearErrors(errors);

        this.setState({ newsFeeds: data, action: "Add", errors: errors });
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };
    
    updatenewsfeeds = (e) => {
        e.preventDefault();
        let { newsFeeds, errors } = this.state;
        let data = {};
        data.type = "news_feeds";
        data.action = this.state.action;
        data.short_description = newsFeeds.short_description;
        data.link = newsFeeds.link;
        data.content = document.getElementById('content')? document.getElementById('content').value : "";
        data.id = newsFeeds.id;
        
        this.setState({ loading: true });
        errors = Validation(data, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
            // console.log(data); return false;

            let url = ChangeOffers;
            if (this.state.action === "Add") {
                axios.post(url, data, CONFIG).then((list) => {
                    console.log(list);
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });

                        this.setState({ errors: { footer_adverdisement: list.data.message } });
                    } else {
                        let addnewsfeeds = {};
                        addnewsfeeds.short_description = data.short_description;
                        addnewsfeeds.content = data.content;
                        addnewsfeeds.link = data.link;
                        
                        addnewsfeeds.id = list.data.data;

                        let records = this.state.allnewsFeeds;
                        addnewsfeeds.s_no = records.length + 1;
                        records.push(addnewsfeeds);
                        this.setState({ allnewsFeeds: records, formSuccess: list.data });

                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                       
                    }
                });
            } else {
                data.id = newsFeeds.id;
                
                axios.post(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { footer_adverdisement: list.data.message } });
                    } else {
                        let records = this.state.allnewsFeeds;

                        var recIndex = findWithAttr(records, "id", data.id);
                        console.log(recIndex);
                        console.log(records);
                        console.log(data)
                        records[recIndex].short_description = this.state.newsFeeds.short_description;
                        records[recIndex].link = this.state.newsFeeds.link;
                        records[recIndex].content = this.state.newsFeeds.content;
                        
                        records[recIndex].id = this.state.newsFeeds.id;
console.log(records);
                        this.setState({ allnewsFeeds:records, formSuccess: list.data });
                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                        window.location.reload();
                    }
                });
            }
        }
    };
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            newsFeeds,
             loading,
            errors,
            formSuccess,
            
            tableSuccess,
            
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>
                            {/* <section className="my-head">
                                <div className="container-fluid">
                                    <div className="row">
                                        <LogoSection />
                                    </div>
                                </div>
                            </section> */}

                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Offers List </h4>
                                    <div className="row">
                                    <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.allnewsFeeds}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                                show={this.state.addPopup}
                                                                onHide={PopupClose}
                                                                onConfirm={PopupConfirm}
                                                                title={"Confirmation"}
                                                                message={"Are you sure you want to delete this record ?"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 pr0">
                                            <div className="card b-12-hite">
                                            <div class="card-header"> 
                                            <div class="card-title">
                                            {this.state.action === "Add" ? "Add" : "Edit"} Offers  
                                            </div> 
                                            </div>
                                                <div className="card-body">
                                                    <div className="user-notification">
                                                        <SpanField
                                                            classname={
                                                                0 === formSuccess.status
                                                                    ? "text-danger user-notification"
                                                                    : "text-success user-notification"
                                                            }
                                                            title={formSuccess.message}
                                                        />
                                                    </div>

                                                    <div>
                                                      
                                                       
                                                        <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Short Description"
                                                                                isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                            <input className="form-control" type="text" name="short_description" 
                                                                            max={200} value={newsFeeds.short_description} onChange={this.handleInput} />


                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.short_description}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                            <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Link"
                                                                                isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                            <input className="form-control" type="text" name="link" value={newsFeeds.link} onChange={this.handleInput} />


                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.link}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="frllftfull">
                                                                    <div className="form-group frllft mbbre">
                                                                        <label className="ffgrd">Offers</label>
                                                                    </div>
                                                                    <div className="form-group frlrit">
                                                                        <div className="position-relative facilityin">
                                                                        <TextEditor id="content" onChange={this.handletexteditor} content={newsFeeds.content} />
                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.content}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>




                                                        <div className="row">
                                                         
                                                            <div className="col-sm-12">
                                                                <div className="form-group mb-0 text-center float-right mg-top3">
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.updatenewsfeeds}
                                                                    >
                                                                        {this.state.action === "Add" ? "+ Add" : "Update"}
                                                                    </button>

                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.cancel}
                                                                    >
                                                                        {"Cancel"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                           
                            </section>
                          
                        </div>
                        <FooterSection />
                       
                    </div>
                    
                </div>
            </div>

           
        );
    }
}

export default connect(
    mapStateToProps,
)(Offers);