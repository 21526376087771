import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import {
  getAllMasterDrug,
  getAllDrugs,
  getAllDrugTypes,
  getAllDrugCategories,
  addDrug,
  updateDrug,
  deleteDrug,
  searchDrug,
  getAllDrugMeasures,
  getTimeoutbycategory
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";

import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
// Import React Table
import "react-table/react-table.css";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Spinner } from "../../components/UI/Spinner";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import FooterSection from "../../components/UI/FooterSection";
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class RequirementList extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
        timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
      
      records: [],

      searchrecords: [],
      drug_action: "Add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      newDatatoAdd: {},
      addPopup: false,
      drugList: {
        s_no: "",
        drug_id: "",
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      drugData: {
        id: "",
        drug_id: "",
        manufacturer: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        active_ingredients: "",
        unit_of_measure: "",
      },
      errors: {
        drug_id: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        unit_of_measure: "",
      },
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
     
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "alert",
        text: "Alert",
        className: "alert",
        align: "left",
        sortable: true,
      
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
      
      },
     
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
        
      },
      {
        key: "category",
        text: "Category",
        className: "category",
        align: "left",
        sortable: true,
       
      },

      {
        key: "type",
        text: "Type",
        className: "type",
        sortable: true,
        align: "left",
        
      },

        {
        key: "unitofmeasure",
        text: "UOM",
        className: "unitofmeasure",
        sortable: true,
        align: "left",
       
      },
      
      {
        key: "safety",
        text: "Safety",
        className: "safety",
        sortable: true,
        align: "left",
        
      },

      {
        key: "available_stock",
        text: "Available Stock",
        className: "available_stock",
        sortable: true,
        align: "left",
        
      },
      
     {
        key: "blocked_stock",
        text: "Blocked Stock",
        className: "blocked_stock",
        sortable: true,
        align: "left",
        
      },
      {
        key: "required_date",
        text: "Required Date",
        className: "required_date",
        sortable: true,
        align: "left",
        
      },
      {
        key: "purchase_info_record",
        text: "PIR",
        className: "purchase_info_record",
        sortable: true,
        align: "left",
        
      },
      
      {
        key: "vendor_id",
        text: "Vendor ID",
        className: "vendor_id",
        sortable: true,
        align: "left",
        
      },
     
   
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

  
  }

  handleCategoryColumnChange = (value, record, field) => {
    // console.log(field);
    // console.log(value);
    // console.log(record);
    value[field] = record;
    // console.log(record);
    // record.availability = "Yes" === record.availability ? "No" : "Yes";
    this.UpdateColumnChange(value);
  };
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;

    this.UpdateColumnChange(record);
  };
  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].drug_id = record.drug_id;
    records[recIndex].drug_name = record.drug_name;
    records[recIndex].drug_category = record.drug_category;
    records[recIndex].drug_type = record.drug_type;

    this.setState({ searchrecords: records });
  };
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch(() => { });
 let members = [];

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllMasterDrug, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugNameList = drugMasterRespons.data.data;
        let masterdrugNames = [];
        masterdrugNameList.forEach((element, index) => {
          masterdrugNames.push({
            value: element.id,
            label: element.drug_name,
            manufacturer: "",
            active_incredient: "",
          });
        });

        this.setState({ masterdrugNames });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugTypes, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element, index) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;

        let drugCategories = [];
        list.forEach((element, index) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugs, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let drugList = list.data.data;
        let drugDataArr = [];
        drugList.forEach((element, index) => {
          let drugData = {};
          drugData.s_no = index + 1;
          drugData.id = element.id;
          drugData.user_id = this.props.userdata.id;
          drugData.drug_id = element.drug_id;
          drugData.drug_name = element.drug_name;
          drugData.drug_type = element.drug_type;
          drugData.drug_category = element.drug_category;
          drugData.manufacturer = element.manufacturer;
          drugData.active_ingredients = element.active_ingredients;
          drugData.unit_of_measure = element.unit_of_measure;
          drugDataArr[index] = drugData;
        });

        this.setState({ records: drugDataArr });
      })
      .catch((e) => { });


    axios
      .post(getAllDrugMeasures, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugmeasureList = drugMasterRespons.data.data;
        console.log(masterdrugmeasureList);
        let masterdrugmeasure = [];
        masterdrugmeasureList.forEach((element, index) => {
          masterdrugmeasure.push({
            value: element.unit_of_measure,
            label: element.unit_of_measure,

          });
        });

        this.setState({ masterdrugmeasure });
      })
      .catch((e) => { });
  };
  addRecord = (record) => {
    let formvalid = true;
    if (record.drug_id === "") {
      record.drugid_error = "Please enter drug id";
      formvalid = false;
    } else {
      record.drugid_error = "";
    }
    if (record.drug_name.label === "") {
      formvalid = false;
      record.drugname_error = "Please enter drug name";
    } else {
      record.drugname_error = "";
    }
    if (record.drug_category.label === "") {
      formvalid = false;
      record.drugcategory_error = "Please enter drug category";
    } else {
      record.drugcategory_error = "";
    }
    if (record.drug_type.label === "") {
      record.drugtype_error = "Please enter drug type";
      formvalid = false;
    } else {
      record.drugtype_error = "";
    }
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex] = record;
    this.setState({ searchrecords: records });
    if (formvalid) {
      let drug_action = this.state.drug_action;
      this.setState({ drug_action: "Add" });
      this.setState({ newDatatoAdd: record, loading: true });
      // this.addorupdateDrug();

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      let selectedData = record;
      // console.log(selectedData); return false;
      let data = {};
      data.user_id = this.props.userdata.id;
      data.drug_id = selectedData.drug_id;
      data.drug_name = selectedData.drug_name.label;
      data.drug_type = selectedData.drug_type.label;
      data.drug_category = selectedData.drug_category.label;
      data.unit_of_measure = selectedData.unit_of_measure.label;
      data.manufacturer = selectedData.manufacturer;
      data.active_ingredients = selectedData.active_ingredients;

      axios
        .post(addDrug, data, CONFIG)
        .then((addDrugResponse) => {
          let res = addDrugResponse.data;
          if (res.status === "1") {
            let drug = {};
            let records = this.state.records;
            drug.s_no = this.state.records.length + 1;
            drug.drug_id = data.drug_id;
            drug.id = res.data[0].id;
            drug.drug_name = data.drug_name;
            drug.drug_category = data.drug_category;
            drug.unit_of_measure = data.unit_of_measure;
            drug.manufacturer = data.manufacturer;
            drug.drug_type = data.drug_type;
            drug.active_ingredients = data.active_ingredients;
            records.push(drug);
            // this.setState({ searchrecords: records });
            this.setState({
              records: records,
              searchTableSuccess: res,
              loading: false,
            });
            this.clearMessage();
          } else {
            this.setState({
              searchrecords: records,
              searchTableSuccess: res,
              loading: false,
            });
            this.clearMessage();
          }
        })
        .catch((e) => { });
    }
  };
  editRecord(record) {
    let drug_action = this.state.drug_action;
    drug_action = "Edit";
    this.setState({ drug_action });

    let drugData = this.state.drugData;
    drugData.s_no = record.s_no;
    drugData.id = record.id;
    drugData.drug_id = record.drug_id;

    drugData.drug_name = {
      label: record.drug_name,
      value: record.drug_name,
    };
    drugData.manufacturer = record.manufacturer;
    drugData.drug_type = {
      label: record.drug_type,
      value: record.drug_type,
    };
    drugData.drug_category = {
      label: record.drug_category,
      value: record.drug_category,
    };
    drugData.unit_of_measure = {
      label: record.unit_of_measure,
      value: record.unit_of_measure,
    };
    drugData.active_ingredients = record.active_ingredients;
    this.setState({ drugData: drugData });
  }

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.drugData;
    data[e.name] = selectedOptions;
    // if (e.name === "drug_name") {
    //   data.manufacturer = selectedOptions.manufacturer;
    //   data.active_ingredients = selectedOptions.active_ingredients;
    // }
    this.setState({ drugData: data });
  };
  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;

    // if (e.name === "drug_name") {
    //   data.manufacturer = selectedOptions.manufacturer;
    //   data.active_ingredients = selectedOptions.active_ingredients;
    // } else {
    //   data[e.name] = selectedOptions;
    // }
    // if (e.name === "manufacturer" || e.name === "active_ingredients") {
    //   data[e.name] = selectedOptions.target.value;
    // } else {
    data[e.name] = selectedOptions;
    // }
    this.setState({ searchData: data });
  };

  handleInput = (e) => {
    let data = this.state.drugData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ drugData: data });
  };

  addorupdateDrug() {
    let url = this.state.drug_action === "Add" ? addDrug : updateDrug;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let selectedData = this.state.newDatatoAdd;

    let { drugData, errors } = this.state;
    drugData.type = "drug";
    errors = Validation(drugData, errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {
      this.setState({ loading: true });

      let data = {};
      data.s_no = drugData.s_no;
      data.user_id = this.props.userdata.id;
      data.id = drugData.id;
      data.drug_id = drugData.drug_id;
      data.drug_name = drugData.drug_name.label;
      data.drug_type = drugData.drug_type.label;
      data.unit_of_measure = drugData.unit_of_measure.label;
      data.drug_category = drugData.drug_category.label;
      data.manufacturer = drugData.manufacturer;
      data.active_ingredients = drugData.active_ingredients;
      let records = this.state.records;
      if (this.state.drug_action === "Add") {
        axios
          .post(url, data, CONFIG)
          .then((addDrugResponse) => {
            let res = addDrugResponse.data;
            if (res.status === "1") {
              let drug = {};

              drug.s_no = this.state.records.length + 1;
              drug.drug_id = data.drug_id;
              drug.id = res.data[0].id;
              drug.drug_name = data.drug_name;
              drug.drug_category = data.drug_category;
              drug.manufacturer = data.manufacturer;
              drug.unit_of_measure = data.unit_of_measure;
              drug.drug_type = data.drug_type;
              drug.active_ingredients = data.active_ingredients;
              records.push(drug);
              // this.setState({ searchrecords: records });
              this.setState({
                records: records,
                searchTableSuccess: res,
                loading: false,
                editformSuccess: res,
              });
              this.clearMessage();
              this.clearAll();
            } else {
              this.setState({
                searchrecords: records,
                searchTableSuccess: res,
                loading: false,
              });
              this.clearMessage();
            }
          })
          .catch((e) => { });
      } else {
        axios
          .put(url, data, CONFIG)
          .then((updateDrugResponse) => {
            let updateResponse = updateDrugResponse.data;
            if (updateResponse.status === "1") {
              let addDrugSuccess = this.state.addDrugSuccess;
              addDrugSuccess = updateResponse.message;
              this.setState({ addDrugSuccess: addDrugSuccess });

              /** Updating the data table */
              let existingRecords = this.state.records;
              var index = existingRecords.findIndex(
                (item) => item.id === data.id
              );
              existingRecords.splice(index, 1, data);
              this.setState({ records: existingRecords });
              // this.setState({ tableSuccess: updateResponse });
              this.setState({ editformSuccess: updateResponse });
              this.clearMessage();
              this.clearAll();
            } else {
              this.setState({ editformSuccess: updateResponse });
              this.clearMessage();
            }
            this.setState({ loading: false });
          })
          .catch((e) => { });
      }
    }
  }

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDrug, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        this.clearMessage();
      })
      .catch((e) => { });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearAll() {
    let drug_action = this.state.drug_action;
    drug_action = "Add";
    this.setState({ drug_action });
    let drugData = {};
    drugData.drug_id = "";
    drugData.drug_name = "";
    drugData.manufacturer = "";
    drugData.drug_category = "";
    drugData.drug_type = "";
    drugData.active_ingredients = "";
    drugData.unit_of_measure = "";
    this.setState({ drugData });
  }

  searchandshowDrug() {
    let searchData = this.state.searchData;
    console.log(searchData);
    if (
      (searchData.drug_name === "" || null === searchData.drug_name) &&
      (searchData.drug_category === "" || null === searchData.drug_category) &&
      (searchData.drug_type === "" || null === searchData.drug_type) &&
      searchData.manufacturer === "" &&
      searchData.active_ingredients === ""
    ) {
      this.setState({
        searchErrorMessage:
          "Choose any one of the search filter to search.",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }
    let data = {};
    data.drug_name = searchData.drug_name ? searchData.drug_name.label : "";
    data.drug_category = searchData.drug_category
      ? searchData.drug_category.label
      : "";
    data.drug_type = searchData.drug_type ? searchData.drug_type.label : "";
    data.manufacturer = searchData.manufacturer;
    data.active_ingredients = searchData.active_ingredients;
    data.type = "admin";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(searchDrug, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        let data = [];
        if (result.countOfRecords > 0) {
          let records = result.data;
          console.log(this.state.masterdrugmeasure[0]);
          records.forEach((element, index) => {
            console.log(element)
            data.push({
              drug_id: "",
              drug_name: { label: element.drug_name, value: element.drug_name },
              drug_name_hide: element.drug_name,
              drug_category: {
                label: element.drug_category,
                value: element.drug_category,
              },
              unit_of_measure: element.unit_of_measure ? {
                label: element.unit_of_measure,
                value: element.unit_of_measure,
              } : this.state.masterdrugmeasure[0],
              drug_category_hide: element.drug_category,
              drug_type: { label: element.drug_type, value: element.drug_type },
              drug_type_hide: element.drug_type,
              manufacturer: element.manufacturer,
              active_ingredients: element.active_ingredients,
              id: element.id,
              drugid_error: "",
              drugcategory_error: "",
              drugtype_error: "",
              drugname_error: "",
            });
          });

          this.setState({ searchrecords: data });
          this.setState({
            search_action: true,
          });
        } else {
          this.setState({ searchrecords: [], search_action: true });
        }
      });
    });
  }

  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    const {
      drugData,
      drugTypes,
      drugCategories,
      masterdrugNames,
      errors,
      searchData,
      loading,
      formSuccess,
      searchTableSuccess,
      editformSuccess,
      tableSuccess,
      searchErrorMessage,
      hospitaltimeout,
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;

    return (
<div id="layout-wrapper">  
          {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />  */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

      {/*   <Spinner loading={loading} /> */}

      {loading && <Dnaspinner />}
      <div className="main-content bacfot">
      <div className="mainpage">
        <Header user_id={this.props.userdata.id} />
        <div className="main-box">
          <div className="sidemenu pr0">
            <BusinessUserSideBar />
          </div>

          <section className="main-body hospital-profilegap">
           <div className="container-fluid">
           
        <div className="col-lg-13">
            <h4 className="font-weight-bold">Requirement List</h4>
            <div className="row ">
                      <div className="col-sm-12">

                        <div className="card">
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                               
                                    <div >
                                      <ReactDatatable
                                        config={this.config}
                                       // records={this.state.records}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />
                                      <Popup
                                        show={this.state.deletePopup}
                                        onHide={() =>
                                          this.setState({
                                            deletePopup: false,
                                          })
                                        }
                                        onConfirm={() =>
                                          this.deleteRecord(this.state.record)
                                        }
                                        title={"Confirmation"}
                                        message={
                                          "Are you sure you want to delete this record?"
                                        }
                                      />

                      
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
              
            </div>
        </div>
        </div></section>
        <FooterSection />
</div>
</div>
</div>
</div>





    );
}
}
export default connect(mapStateToProps)(RequirementList);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
