
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { updateguidevideo } from "../config/url.json";
import axios from "axios";
import { CONFIG,imageconfig,videoconfig } from "../config/data";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import LoadingSpinner from "../components/UI/LoadingSpinner";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";
import S3 from "react-aws-s3";
import Label from "../components/UI/Label";
import Spinner from "../components/UI/Spinner";
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}
const status = [
    { value: "Pending", label: "Pending" },
    { value: "Accepted", label: "Accepted" },
];
function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

class Videoupload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],
            actions: "update",
            loading: false,
            showDeleteConfirmPopup: false,
            confirmedDelete: false,
      
             deletePopup: false,
             timeoutdata:
             {
               id:"",
               category:"",
               timeout:"",
              
             },
            
             errors: {
                
                category:"",
                timeout:"",
               
            },
            formSuccess: {
                message: "",
                status: "",
              },
        };

     
        this.columns = [
            {
                key: "id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "category",
                text: "Category",
                className: "timeout_category",
                align: "left",
                sortable: true,
            },
            {
                key: "timeout",
                text: "Timeout",
                className: "timeout",
                align: "left",
                sortable: true,
            },
        
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                    this.setState({ deletePopup: true, record: record })
                                }
                            >
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };

    UpdateColumnChange = (data) => {
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // let request = {
        //     business_user_id: data.user_id, status: data.status
        // }
        // axios.post(updateDoctorStatus1, request).then((list) => {
        let records = this.state.records;

        //     // data = list.data.data;
        //     if (list.data.status === "1") {
        var recIndex = findWithAttr(records, "user_id", data.user_id);

        records[recIndex].status = data.status;

        this.setState({ records, tableSuccess: "" });
        // }

        this.clearMessage();
        // });
    };
    handleselectChange = (selectedOptions, e) => {
      let data = this.state.timeoutdata;
      data[e.name] = selectedOptions;
  
      this.setState({ timeoutdata: data });
    };
    onFileChange = event => {
        this.setState({ file: event.target.files[0] });
    }
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };
    Videoupload = (event) => {
      event.preventDefault();
      this.setState({ loading: true })
      let actions = this.state.actions;
      actions = "update";
      let file = this.state.file;
     
      let newfileName = this.state.file.name;
  
      // console.log(imageconfig);
     // imageconfig.dirName = 'business_user/' + this.props.userdata.id
      console.log(videoconfig);
     console.log(file);
     console.log(newfileName);
      const reacts3client = new S3(videoconfig);
      console.log(reacts3client)
      console.log( this.props.userdata.user_type);
      reacts3client.uploadFile(file,newfileName).then(data => {
        console.log(data)
        if (data.status === 204) {
          console.log('success');
          let resultdata = {
              'name':"guide_video",
             'value': data.location,
                   
         }
         
          axios.put(updateguidevideo, resultdata, CONFIG).then((result) => {
                  
          this.setState({tableSuccess: { message: "Video uploaded Succesfully" }});
          this.setState({ loading: false })
          this.clearMessage();
         })
        } else {
          console.log('error');
         // this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
        }
      });
    
  
    };
   
    componentDidMount = () => {
               
    }
   
    
    clearAll= () => {
        this.setState({file:""});
       }

      handleTimeoutChange(value, field) {
        let fields = this.state.timeoutdata;
        // let errors = this.state.educationerror;
        console.log(field);
         if (value === "category") {
          fields.category = field.target.value;
          // errors[i].register_id = '';
        }else if (value === "id") {
          fields.id = field;
          // errors[i].education = '';
        } 
        else if (value === "timeout") {
          fields.timeout = field.target.value;
          // errors[i].education = '';
        }  
        this.setState({
            timeoutdata: fields,
        });
      }
    
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            timeoutdata,
            formSuccess,
            tableSuccess,
            errors,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <Spinner loading={loading} /> */}
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3">Video Upload </h4>
                                    <div className="row">

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            {/* <div className="form-group">
                        <label className="row">Upload Video</label>
                        <input type="file" accept="file" name="uploadedvideo" onChange={this.Videoupload} />
                        
            
                      </div> */}
                        <div className="row">
                      <div className="form-group mb-4" >
                      <Label title="Choose the file to Upload" />  
                      
                    <div className="custom-file text-left">
                    
                    <input type="file" accept="file" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" />
                     <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                     
                        </div>
                        </div>
                            </div>                                     

<div className="row d-flex justify-content-center mg-tp4">
    <button
        ref="btn"
        href="patients-register"
        className="signupbtn abtn btdpml btn-margin"
        onClick={this.Videoupload}>
      {loading ? (
        <LoadingSpinner title="Uploading..." />
          ) : this.state.actions === "Update" ? ("Upload") : ("Upload")}
    </button>
    <button
        href="patients-register"
        className="signupbtn abtn btdpml"
        onClick={this.clearAll}
    >
        Cancel
    </button>
</div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                          
                                        </div>

                                    </div>
                                </div>
                               
                            </section>
                            <FooterSection />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
)(Videoupload);