import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Heading4 from "../../components/UI/Heading4";
import InputField from "../UI/InputField";
import TextArea from "../UI/TextArea";
import CheckboxOrRadioGroup from "../UI/CheckboxOrRadioGroup";
import ReactDatatable from "@ashvin27/react-datatable";
import DatePicker from "react-date-picker";
// import Button from '../UI/Button'
import axios from "axios";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Label from "../UI/Label";
import Validation from "../../actions/Validation";
import PhoneInput, { isValidPhoneNumber,parsePhoneNumber } from "react-phone-number-input";
import TimePicker from "react-bootstrap-time-picker";
import SpanField from "../UI/SpanField";
import Select from "react-select";
import GenderField from "../UI/GenderField";
import StarField from "../UI/StarField";
import YearPick from "../UI/YearPick";
import MonthPick from "../UI/MonthPick";
import * as moment from "moment";
import { blood_group, gender, AUTH_CONFIG, language, CONFIG, imageconfig } from "../../config/data";
import {
  MONTH_PAIR,
  BLOOD_GROUPS,
  EDUCATION_OPTIONS,
} from "../../constants/config/data";
import { getBusinessUserById, updateBusinessUser,updateavatar,updatedigitalsignature } from "../../config/url.json";
import { Popup } from "../../components/UI/Popup";
import { UncontrolledTooltip } from "reactstrap";
import { Route, withRouter } from "react-router-dom";
import {
  parseObjectValue,
  stringToLabelValue,
} from "../../constants/globalLevelFunctions";
import S3 from "react-aws-s3";
import ReactDOM from 'react-dom'
import SignatureCanvas from 'react-signature-canvas'
import SignaturePad from 'react-signature-canvas'
import { ContactsOutlined } from "@material-ui/icons";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    access_token: state.AuthReducer.access_token,
    userdata: state.AuthReducer.user,
  };
}
const google = window.google;
const scuedueType = ["Queue", "appointment"];
const working_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);
const blood_group_options = BLOOD_GROUPS;

let eq_record = {
  id: "",
  education: "",
  specialization: "",
  university: "",
  completion_month: "",
  completion_year: "",
  completion_year_month:""
};

let cert_record = {
  organization: "",
  specialization: "",
  valid_URL: "",
  certification_completion_month: "",
  certification_completion_year: "",
  valid_upto_month: "",
  valid_upto_year: "",
};

let experience_record = {
  hospital: "",
  ex_address: "",
  ex_city: "",
  ex_state: "",
  ex_country: "",
  ex_zipcode:"",
  experience_from: "",
  experience_to: "",
};

let medical_licence_record = {
  organization: "",
  licence_Id: "",
  expiration_date: "",
};

class BusinessUserSignup1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alldaysbtnClicked: false,
      hoursdisabled: false,
      checkalldays: false,
      trimmedDataURL: null,
      is_clear:false,
      is_addeducation:false,
      businessUserData: {
        first_name: "",
        last_name: "",
        profile_pic: "",
        image:"",
        date_of_birth: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        gender: "",
        blood_group: "",
        licence_id: "",
        expiry_date: "",
        user_type: "business_user",
        schedule_type: [],
        qualification: "",
        educational_qualification: [],
        certification: [],
        experience: [],
        medical_licence_details: [],
        working_hours: {
          morning_opens_at: '',
          morning_close_at: '',
          evening_opens_at: '',
          evening_close_at: '',
          hours_24: false,
        },
        working_days: [],
        working_all_days: false,
        schedule_type:"",
        speciality: [],
        profile_summary: "",
        language: "",
        education: "",
        specialization: "",
        university: "",
        completion_year: "",
        completion_month: "",
        organization: "",
        specialization: "",
        valid_URL: "",
        certification_completion_month: "",
        certification_completion_year: "",
        valid_upto_month: "",
        valid_upto_year: "",
        hospital:"",
        ex_address:"",
        ex_city:"",
        ex_country:"",
        ex_state:"",
        experience_from:"",
        experience_to:"",

      },

      educational_qualification_formData: {
        id: "",
        education: "",
        specialization: "",
        university: "",
        completion_month: "",
        completion_year: "",
      },

      certifications_formData: {
        organization: "",
        specialization: "",
        valid_URL: "",
        certification_completion_month: "",
        certification_completion_year: "",
        valid_upto_month: "",
        valid_upto_year: "",
      },

      experiences_formData: {
        hospital: "",
        ex_address: "",
        ex_city: "",
        ex_state: "",
        ex_country: "",
        ex_zipcode:"",
        experience_from: "",
        experience_to: "",
      },

      errors: {
        first_name: "",
        last_name: "",
        profile_pic: "",
        image:"",
        date_of_birth: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        gender: "",
        blood_group: "",
        licence_id: "",
        expiry_date: "",
        schedule_type: "",
        working_hours_morning: "",
        working_hours_evening: "",
        working_days: "",
        speciality: "",
        profile_summary: "",
        education: "",
        specialization: "",
        university: "",
        completion_year: fromMonth,
        completion_month: "",
        completion_year_month: "",
        qualification: "",
      },
      educational_qualification_errors: [
        {
          education: "",
          specialization: "",
          university: "",
          completion_year_month: "",
        },
      ],
      certificate_errors: [
        {
          organization: "",
          specialization: "",
          valid_URL: "",
          certification_completion_month: "",
          certification_completion_year: "",
          valid_upto_month: "",
          valid_upto_year: "",
        },
      ],
      experiences_errors: [
        {
          hospital: "",
          ex_address: "",
          city: "",
          state: "",
          country: "",
          ex_zipcode:"",
          experience_from: "",
          experience_to: "",
        },
      ],
      genderoptions: ["Male", "Female"],
    };

    this.columns = [
      {
        key: "id",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "education",
        text: "Education",
        className: "education",
        align: "left",
        sortable: true,
      },
      {
        key: "specialization",
        text: "Specialization",
        className: "specialization",
        align: "left",
        sortable: true,
      },
      {
        key: "university",
        text: "University",
        className: "university",
        sortable: true,
        align: "left",
      },
      {
        key: "completion_year_month",
        text: "Completion Date",
        className: "completion_year_month",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record, "education")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.certificateColumns = [
      {
        key: "id",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "organization",
        text: "Organization",
        className: "organization",
        align: "left",
        sortable: true,
      },
      {
        key: "specialization",
        text: "Specialization",
        className: "specialization",
        align: "left",
        sortable: true,
      },
      {
        key: "valid_URL",
        text: "Valid URL",
        className: "valid_URL",
        sortable: true,
        align: "left",
      },
      {
        key: "completion_year_month",
        text: "Completion Date",
        className: "certification_completion",
        sortable: true,
        align: "left",
      },
      {
        key: "valid_date",
        text: "Valid Date",
        className: "valid_Date",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record, "certificate")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.experienceColumns = [
      {
        key: "id",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "hospital",
        text: "Hospital",
        className: "hospital",
        align: "left",
        sortable: true,
      },
      {
        key: "ex_address",
        text: "Address",
        className: "ex_address",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_city",
        text: "City",
        className: "ex_city",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_state",
        text: "State",
        className: "ex_state",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_country",
        text: "Country",
        className: "ex_country",
        sortable: true,
        align: "left",
      },
      {
        key: "ex_zipcode",
        text: "Pincode",
        className: "ex_zipcode",
        sortable: true,
        align: "left",
      },
      {
        key: "experience_from",
        text: "Experience From",
        className: "experience_from",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.experience_from).format("DD-MMM-yyyy");
        },
      },
      {
        key: "experience_to",
        text: "Experience To",
        className: "experience_to",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.experience_to).format("DD-MMM-yyyy");
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record, "experience")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      show_pagination: false,
      show_filter: false,
      show_length_menu: false,
      show_info: false,
      button: {
        excel: false,
        print: false,
      },
    };

    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleExperiencePlaceSelect = this.handleExperiencePlaceSelect.bind(
      this
    );
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEductionCompletionYearMonthChange = this.handleEductionCompletionYearMonthChange.bind(
      this
    );
  
  }
  componentDidMount() {
    this.autocomplete_ex_address = new google.maps.places.Autocomplete(
      document.getElementById("ex_address"),
      {}
    );

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    this.autocomplete_ex_address.addListener(
      "place_changed",
      this.handleExperiencePlaceSelect
    );

    window.setTimeout(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(
          getBusinessUserById,
          { business_user_id: this.props.userdata.id },
          AUTH_CONFIG
        )
        .then((response) => {
          if (response.data.status === "1") {
            let resultData = response.data.data;
            console.log(response.data.data);
            /* Data of birth section */
            resultData.date_of_birth = moment(
              resultData.date_of_birth,
              moment.defaultFormat
            ).toDate();
            this.handleDOBChange(resultData.date_of_birth);
console.log(resultData);
            resultData.expiry_date = resultData.expiry_date
              ? moment(resultData.expiry_date, moment.defaultFormat).toDate()
              : "";
            // this.handleExDateChange(resultData.expiry_date);
            /* Gender Section*/
            let convertedgender = [];

            if (resultData.gender != null) {
              convertedgender = {
                label: resultData.gender,
                value: resultData.gender,
              };
            }
            resultData.gender = convertedgender;

            /* Blodd Group */

            let obj = {};
            blood_group_options.filter(function (item) {
              if (resultData.blood_group == item.value)
                resultData.blood_group = item;
            });

            /* Working Hours secton */
            if (
              "null" === resultData.working_hours ||
              null == resultData.working_hours
            ) {
              let working_hours = {
                morning_shift_start: '',
                morning_shift_end: '',
                evening_shift_start: '',
                evening_shift_end: '',
                hours_24: false,
              };
              resultData.working_hours = working_hours;
            } else {
              resultData.working_hours = resultData.working_hours;
            }

            /* Working Days section */
            if (null == resultData.working_days) {
              resultData.working_days = [];
            } else {
              resultData.working_days = (resultData.working_days);
              if (7 === resultData.working_days.length) {
                resultData.working_all_days = true;
              } else {
                resultData.working_all_days = false;
              }
            }

            /* Summary */
            resultData.profile_summary =
              null == resultData.profile_summary
                ? ""
                : resultData.profile_summary;
            resultData.address1 =
              null == resultData.address1 ? "" : resultData.address1;
            resultData.address2 =
              null == resultData.address2 ? "" : resultData.address2;
            resultData.city = null == resultData.city ? "" : resultData.city;

            resultData.language = stringToLabelValue(resultData.language);

            resultData.educational_qualification = resultData.educational_qualification ? resultData.educational_qualification:[];
            resultData.certification = 
              resultData.certification ? resultData.certification : []
            ;
            resultData.experience = resultData.experience ? resultData.experience : [];

            let speciality = [];
            let specialities_array = this.props.appdata
              ? this.props.appdata[0].specialities
              : [];
           /*  if (resultData.speciality) {
              specialities_array.forEach((element, index) => {
                if (resultData.speciality.indexOf(element.id) > -1) {
                  speciality.push({
                    value: element.id,
                    label: element.name,
                  });
                }
              });
            } */
            if (resultData.speciality) {
              specialities_array.forEach((element) => {
              //  console.log(details.speciality.includes(element.id));
                if (resultData.speciality.includes(element.id) === true && resultData.speciality===element.id.toString()) {
                  speciality.push({
                    value: element.id,
                    label: element.name,
                  });
                }
              });
            }
            resultData.speciality = speciality;
            resultData.schedule_type = resultData.schedule_type ? resultData.schedule_type : "";
            resultData.phone_number = resultData.phone_number ? resultData.phone_code.concat(resultData.phone_number):"";
            resultData.image=resultData.image;
console.log(resultData)
            this.setState({
              businessUserData: resultData,
            });
          }
        })
        .catch((e) => {
          console.log("business user getbyid failed");
          console.log(e);
        });
    }, 1000);
  }
  clear = (e) => {
    e.preventDefault();
    this.sigPad.clear()
  }
  trim = (e) => {
    e.preventDefault();
    let errors =this.state.errors;
    this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})

      let image=this.sigPad.getTrimmedCanvas().toDataURL('image/png');
      console.log(image);
      if(image!=null)
      {
        let data=this.state.businessUserData;
        let errors=this.state.errors;
        data.image=image;
        errors.image="";
        
      let resultdata = {
        'image': image,
        'user_id': this.props.userdata.id
        
      }
      axios.post(updatedigitalsignature, resultdata, CONFIG).then((result) => {
       console.log("updated ds");
      })
     
    this.setState({is_clear:true});
    this.setState({errors:errors});
   
    }
  }
  selectFile=(event)=> {
    let errors =this.state.errors;
    errors.image="";
    event.preventDefault();
    let base64code="";
    let currentFile= event.target.files[0]; 
    let previewImage= URL.createObjectURL(event.target.files[0]);
    let asciitext=this.getbase64(currentFile);
    //console.log(asciitext);
   
 this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    
    });
   // console.log(previewImage);
   // console.log(currentFile);
  
  }
  onLoad=(image)=>
  {
   this.base64code=image;
   console.log(this.base64code);
   console.log(image);
   if(image!=null){
    let data=this.state.businessUserData;
    let errors=this.state.errors;
    data.image=image;
    errors.image="";
    
    let resultdata = {
      'image': image,
      'user_id': this.props.userdata.id
      
    }
    axios.post(updatedigitalsignature, resultdata, CONFIG).then((result) => {
     console.log("updated ds");
    })
    this.setState({is_clear:true});
    this.setState({errors:errors});
   
   }
   
  
  }
  getbase64=(currentFile)=>{
    
     let reader = new FileReader();
    reader.readAsDataURL(currentFile);
    reader.onload=()=>
    {
      this.onLoad(reader.result);
    }
  
    }
    
  
 /*  download=(e)=>{
let link=e.currentTarget;
link.setAttribute('download','canvas.png');
let image=this.sigPad.current.toDataURL('image/png');
link.setAttribute('href',image);
  } */
  validateMaxLength(name, value, max) {
    let data = this.state.businessUserData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ businessUserData: data, errors: errors });
  }


  handleExperiencePlaceSelect() {
    let place = this.autocomplete_ex_address.getPlace();

    let { experiences_formData,experiences_errors } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1")
      {
        experiences_formData.ex_state = val;
          experiences_errors.ex_state = ""
      }
        if (addressType === "locality")
         {
          experiences_formData.ex_city = val;
          experiences_errors.ex_city=""
         }
        if (addressType === "country")
        {
          experiences_formData.ex_country = val;
          experiences_errors.ex_country=""
        }

        experiences_formData.ex_address = place.name;
        if (addressType === "postal_code") {
          experiences_formData.ex_zipcode = val;
          experiences_errors.ex_zipcode = ""
        }
      }
    }

    this.setState({
      experiences_formData,experiences_errors
    });
  }
  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { businessUserData,errors } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1"){
          businessUserData.state = val;
          errors.state = ""
        }          
        
        if (addressType === "locality")
          {
            
            businessUserData.city = val;
          errors.city = ""
          }
        if (addressType === "country") {businessUserData.country = val;errors.country=""}

        businessUserData.address1 = place.name;
        errors.address1 = ""
        if (addressType === "sublocality_level_1")
         {
          businessUserData.address2 = val;
          errors.address2 = ""
         }
        if (addressType === "postal_code") {
          businessUserData.zip_code = val;
          errors.zip_code = ""
        }
      }
    }

    this.setState({
      businessUserData,errors
    });
  }
  handleFormSubmit = (e) => {
    e.preventDefault();
    let specialities_array = this.props.appdata
    ? this.props.appdata[0].specialities
    : []; 
    let { businessUserData, errors } = this.state;
    businessUserData.user_type = "business_user";
    errors = Validation(businessUserData, errors);
    console.log(errors);
    console.log(businessUserData);
    
    let formData = this.state.educational_qualification_formData;
    formData.type = "addEducationDetail";

    let { educational_qualification_errors } = this.state;
    educational_qualification_errors.formIsValid = true;
    if(!businessUserData.educational_qualification.length){
   
    console.log(educational_qualification_errors)
    educational_qualification_errors = Validation(formData, educational_qualification_errors);
    this.setState({ educational_qualification_errors: educational_qualification_errors });
    }
    this.setState({ errors: errors });

    let updateData = businessUserData;


    if (errors.formIsValid &&educational_qualification_errors.formIsValid){
      if(this.state.is_addeducation===true)
      {
      updateData.type = "doctor";
      updateData.blood_group = updateData.blood_group.value;
      updateData.gender = updateData.gender.value;

      let speciality = [];
      updateData.phone_code = "+" + parsePhoneNumber(updateData.phone_number).countryCallingCode;
      updateData.phone_number = parsePhoneNumber(updateData.phone_number).nationalNumber;
      const offsetDate = new Date(updateData.date_of_birth.getTime() - (updateData.date_of_birth.getTimezoneOffset() * 60000));
      updateData.date_of_birth = offsetDate;
    
     updateData.date_of_birth=offsetDate;
     // updateData.date_of_birth=moment(updateData.date_of_birth).format('DD-MM-YYYY').toString();
      // console.log(updateData);return false;
     /*  if (updateData.speciality) {
        updateData.speciality.forEach((element) =>
          speciality.push(element.value)
        );
      }
      updateData.speciality = speciality.toString(); */

      if(updateData.speciality!==undefined){
        specialities_array.forEach((element, index) => {
       
          if (updateData.speciality === element.id) {
            updateData.speciality = element.id;
            updateData.Specialities = element.name;
          }
         
        });
        updateData.speciality=updateData.speciality.value;
      }
      let lang = [];

      if (updateData.language) {
        updateData.language.forEach((element) => lang.push(element.value));
      }
      updateData.language = lang.toString();
     
      medical_licence_record.licence_Id = updateData.licence_id;
      medical_licence_record.expiration_date = moment(updateData.expiry_date,moment.defaultFormat).toDate();
      medical_licence_record.id = 1;
      updateData.medical_licence_details = [medical_licence_record];

      updateData.schedule_type = updateData.schedule_type ? updateData.schedule_type : "";

      updateData.schedule_type = updateData.schedule_type.toString();

      console.log(updateData);
      try {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        const result = axios.put(updateBusinessUser, updateData, AUTH_CONFIG);

        const p = Promise.resolve(result);

        p.then((value) => {
          if (value.data.status === "1") {
            // this.props.history.push("/dashboard");
            window.location.replace("/dashboard");
          }
          if (value.data.status === "0") {
            
            updateData.phone_number = updateData.phone_code.concat(updateData.phone_number);

            this.setState({ error: value.data.message, businessUserData: updateData});
          }
        });
      } catch (e) {
        alert(e);
      }
    }
    }
  };

  handleInputChange = (e) => {
    let data = this.state.businessUserData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
let {errors} =this.state;

    if (
      name === "first_name" ||
      name === "last_name" ||
      name === "address1" ||
      name === "address2" ||
      name === "city" ||
      name === "zip_code" ||
      name === "email" ||
      name === "licence_id"||
      name === "qualification"||
      name ==="valid_URL"
      
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name]="";
      errors.address1="";
      errors.city="";
      errors.state="";
      errors.country="";
      errors.zip_code="";
     
    }
    this.setState({ businessUserData: data,errors });
  };

  handleDOBChange = (date) => {
    let errors = this.state.errors;
    let businessUserData = this.state.businessUserData;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    businessUserData.date_of_birth = offsetDate;
    this.setState({ businessUserData: businessUserData });
    errors.date_of_birth="";
    this.setState({ errors });
  };

  handlePhoneInputChange = (event) => {
    let errors = this.state.errors;
    let businessUserData = this.state.businessUserData;
    businessUserData.phone_number = event;
    this.setState({ businessUserData });
    errors.phone_number="";
    this.setState({ errors });
  };

  /* for country detail change */
  selectCountry(val) {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data.country = val;
    this.setState({ businessUserData: data });
    errors.country="";
    this.setState({ errors });
  }

  /* for state detail change */
  selectState(val) {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data.state = val;
    this.setState({ businessUserData: data });
    errors.state="";
    this.setState({ errors });
  }

  handleselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data[e.name] = selectedOptions;
    this.setState({ businessUserData: data });
    errors.language="";
    this.setState({ errors });
  };
  handlebloodgroupselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data[e.name] = selectedOptions;

    this.setState({ businessUserData: data });
    errors.blood_group="";
    this.setState({ errors });
  };

  handlegenderselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data[e.name] = selectedOptions;

    this.setState({ businessUserData: data });
    errors.gender="";
    this.setState({ errors });
  };
  handlespecialityselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data[e.name] = selectedOptions;

    this.setState({ businessUserData: data });
    errors.speciality="";
    this.setState({ errors });
  };


  handleYearMonthChange = (name, selectedOptions) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data[name] = selectedOptions;
    this.setState({ businessUserData: data });
    errors = {};
    this.setState({ errors });
  };

  handleExDateChange = (date) => {
    let errors = this.state.errors;
    let businessUserData = this.state.businessUserData;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    businessUserData.expiry_date = offsetDate;

    errors.expiry_date = ""
    this.setState({ businessUserData: businessUserData,errors });

  };

  handleSechduleType = (e) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    data.schedule_type = [e.target.value];
    errors.schedule_type = ""
    this.setState({ businessUserData: data,errors });

  };
  handleWorkingDays = (e) => {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    let working_all_days_data = data.working_all_days;

    if ("allDaysId" === e.target.id) {
      working_all_days_data = !working_all_days_data;

      data.working_all_days = working_all_days_data;

      data.working_all_days
        ? (data.working_days = working_days)
        : (data.working_days = []);
    } else {
      const newSelection = e.target.value;
      let newSelectionArray;

      if (data.working_days.indexOf(newSelection) > -1) {
        newSelectionArray = data.working_days.filter((s) => s !== newSelection);
      } else {
        newSelectionArray = [...data.working_days, newSelection];
      }

      data.working_days = newSelectionArray;
      let wd = [];
      data.working_days.forEach((element, index) => {
        if (working_days.indexOf(element) > -1) {
          wd.push(element);
        }
      });

      data.working_days = wd;
    }

    data.working_days.length === 7
      ? (data.working_all_days = true)
      : (data.working_all_days = false);
   if  ( data.working_days.length > 0)  errors.working_days = "";


    this.setState({ businessUserData: data,errors });

  };
  handleWorkhoursChange(field, value) {
    let errors = this.state.errors;
    let data = this.state.businessUserData;
    let working_hours_data = data.working_hours;
    if ("24Hours" === field) {
      working_hours_data.hours_24 = !working_hours_data.hours_24;
      working_hours_data.morning_shift_start = '00:00';errors.morning_shift_start="";
      working_hours_data.morning_shift_end = '11:59';errors.morning_shift_end="";
      working_hours_data.evening_shift_start = '12:00';errors.evening_shift_start="";
      working_hours_data.evening_shift_end = '23:59';errors.evening_shift_end = "";
      
      
    } else {
      working_hours_data.hours_24 = false;
      working_hours_data[`${field}`] = value.target.value;
      errors[`${field}`] = ""
    }

    data.working_hours = working_hours_data;

    errors.working_hours = "";
    this.setState({ businessUserData: data,errors });
  }
  enableHours() {
    let field = this.state.businessUserData;
    this.setState({ businessUserData: !field.hoursdisabled });
  }

  handleEductionCompletionYearMonthChange(completion_year_month) {
    this.setState({ completion_year_month });
  }

  /* for education change */
  addEducationDetail(e) {
    e.preventDefault();
    let formData = this.state.educational_qualification_formData;
    formData.type = "addEducationDetail";
    let { educational_qualification_errors } = this.state;
    console.log(educational_qualification_errors)
    educational_qualification_errors = Validation(formData, educational_qualification_errors);
    this.setState({ educational_qualification_errors: educational_qualification_errors });

    if (educational_qualification_errors.formIsValid) {
      let data = this.state.businessUserData;

      let records = data.educational_qualification;

      let record = Object.assign({}, eq_record);

      record.id = records.length + 1;
      record.education = formData.education;
      record.education_id = formData.education;
      record.specialization_id = formData.specialization.value;
      record.specialization = formData.specialization.label;
      record.university = formData.university;
      record.completion_year_month =
        MONTH_PAIR.get(formData.completion_month) +
        "-" +
        formData.completion_year;

      records.push(record);
      this.setState({ records: records });

      let x = Object.assign({}, eq_record);

      this.setState({ educational_qualification_formData: x,educational_qualification_errors,is_addeducation:true });
    }
  }

  onDeleteClick(record, tableName) {
    this.setState({ addPopup: true, record: record, tableName: tableName });
  }

  deleteRecord(record, tableName) {
    if ("education" === tableName) {
      let records = this.state.businessUserData.educational_qualification;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      this.setState({ educational_qualification: records });
    } else if ("certificate" === tableName) {
      let records = this.state.businessUserData.certification;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      this.setState({ certifications: records });
    } else if ("experience" === tableName) {
      let records = this.state.businessUserData.experience;
      const deleteIndex = records.map((item) => item.id).indexOf(record.id);
      records.splice(deleteIndex, 1);
      this.setState({ experiences: records });
    }
    this.setState({ addPopup: false, record: "" });
  }

  editRecord(record) {
    let dataObj = this.state.educational_qualification_formData;
    dataObj.id = record.id;
    dataObj.education = { value: record.education_id, label: record.education };
    dataObj.specialization = {
      value: record.specialization_id,
      label: record.specialization,
    };
    dataObj.university = record.university;

    var completionObj = record.completion_year_month.split("-");

    dataObj.completion_month = this.getMonthValue(completionObj[0]);

    dataObj.completion_year = completionObj[1];

    this.setState({ educational_qualification_formData: dataObj });
  }

  getMonthValue(val) {
    return [...MONTH_PAIR.entries()]
      .filter(({ 1: v }) => v === val)
      .map(([k]) => k);
  }

  handleEducationDetailChange(field, value) {
    let fields = this.state.educational_qualification_formData;
let {educational_qualification_errors} =this.state;

    if (field === "education") {
      fields.education = value;
      //educational_qualification_errors.education = ""
    } else if (field === "specialization") {
      fields.specialization = value;
     // educational_qualification_errors.specialization = ""
    } else if (field === "university") {
      fields.university = value.target.value;
      //educational_qualification_errors.university = ""
    } else if (field === "completion_year") {
      fields.completion_year = value;
      //educational_qualification_errors.completion_year = ""
    } else if (field === "completion_month") {
      fields.completion_month = value;
      //educational_qualification_errors.completion_month = ""
    }
    this.setState({
      educational_qualification_formData: fields,
      educational_qualification_errors
    });
  }
  handleEducationInputChange = (e) => {
    let data = this.state.educational_qualification_formData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    let {educational_qualification_errors} = this.state; 

    if (
      name === "university"  || name === "education" 
      
    ) {
      this.validateMaxLengthforeducation(name, value, max);
    } else {
      data[name] = value;
     // educational_qualification_errors[name] = ""
    }
   
    
    this.setState({ educational_qualification_formData: data,educational_qualification_errors });
  };
  validateMaxLengthforeducation(name, value, max) {
    let data = this.state.educational_qualification_formData;
    let errors = this.state.educational_qualification_errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
     // errors[name] = "";
      data[name] = value;
    }
    this.setState({ educational_qualification_formData: data, educational_qualification_errors: errors });
  }


  handleCertificationChange(field, value) {
    let fields = this.state.certifications_formData;
let certificate_errors = this.state.certificate_errors;
    if (field === "organization") {
      fields.organization = value.target.value;
      certificate_errors.organization = ""
    } else if (field === "specialization") {
      fields.specialization = value.target.value;
      certificate_errors.specialization = ""
    } else if (field === "valid_URL") {
      fields.valid_URL = value.target.value;
      certificate_errors.valid_URL = ""
    } else if (field === "certification_completion_month") {
      fields.certification_completion_month = value;
      certificate_errors.certification_completion_month = ""
    } else if (field === "certification_completion_year") {
      fields.certification_completion_year = value;
      certificate_errors.certification_completion_year = ""
    }  else if (field === "valid_upto_month") {
      fields.valid_upto_month = value;
      certificate_errors.valid_upto_month = ""
    } else if (field === "valid_upto_year") {
      fields.valid_upto_year = value;
      certificate_errors.valid_upto_year = ""
    }
    this.setState({
      certifications_formData: fields,
      certificate_errors
    });
  }
  handleCertificateInputChange = (e) => {
    let data = this.state.certifications_formData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
let certificate_errors = this.state.certificate_errors;
    if (
      name === "specialization" ||
      name === "organization" ||
      name === "valid_URL"
      
    ) {
      this.validateMaxLengthforcertificates(name, value, max);
    } else {
      certificate_errors[name] = ""
      data[name] = value;
    }
   
    
    this.setState({ certifications_formData: data,certificate_errors });
  };

  validateMaxLengthforcertificates(name, value, max) {
    let data = this.state.certifications_formData;
    let certificate_errors = this.state.certificate_errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      certificate_errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      certificate_errors[name] = "";
      data[name] = value;
    }
    this.setState({ certifications_formData: data, certificate_errors: certificate_errors });
  }

 
  /* for certifications change */
  addCertificationDetail() {
    let formData = this.state.certifications_formData;
    formData.type = "addCertificate";
    let { certificate_errors } = this.state;
    certificate_errors = Validation(formData, certificate_errors);
    console.log(certificate_errors);
    this.setState({ certificate_errors: certificate_errors });

    if (certificate_errors.formIsValid) {
      let records = this.state.businessUserData.certification;

      let record = Object.assign({}, cert_record);

      record.id = records.length + 1;
      record.organization = formData.organization;
      record.specialization = formData.specialization;
      record.valid_URL = formData.valid_URL;
      record.completion_year_month =
        MONTH_PAIR.get(formData.certification_completion_month) +
        "-" +
        formData.certification_completion_year;
      record.valid_date =
        MONTH_PAIR.get(formData.valid_upto_month) +
        "-" +
        formData.valid_upto_year;

      records.push(record);
      this.setState({ certifications: records });

      let initalData = Object.assign({}, cert_record);
console.log(initalData);
      this.setState({ certifications_formData: initalData,certificate_errors });
    } else
    {
      this.setState({certificate_errors})
    }
  }

  /* for education change */
  addExperienceDetail() {
    let formData = this.state.experiences_formData;
    formData.type = "addExperience";
    let { experiences_errors } = this.state;
    experiences_errors = Validation(formData, experiences_errors);
    console.log(experiences_errors)
    this.setState({ experiences_errors: experiences_errors });

    if (experiences_errors.formIsValid) {
      let records = this.state.businessUserData.experience;

      let record = Object.assign({}, experience_record);

      record.id = records.length + 1;
      record.hospital = formData.hospital;
      record.ex_address = formData.ex_address;
      record.ex_city = formData.ex_city;
      record.ex_state = formData.ex_state;
      record.ex_country = formData.ex_country;
      record.ex_zipcode = formData.ex_zipcode;
      record.experience_from = formData.experience_from;
      record.experience_to = formData.experience_to;

      records.push(record);
      this.setState({ experiences: records });

      let initalData = Object.assign({}, experience_record);

      this.setState({ experiences_formData: initalData,experiences_errors });
    } 
  }

  handleExperienceChange(field, value) {
    let fields = this.state.experiences_formData;
let experiences_errors = this.state.experiences_errors;
    if (field === "hospital") {
      fields.hospital = value.target.value;
      experiences_errors.hospital = ""
    } else if (field === "ex_address") {
      fields.ex_address = value.target.value;
      experiences_errors.ex_address = ""
    } else if (field === "ex_city") {
      fields.ex_city = value.target.value;
      experiences_errors.ex_city = ""
    } else if (field === "ex_state") {
      fields.ex_state = value;
      experiences_errors.ex_state = ""
    } else if (field === "ex_country") {
      fields.ex_country = value;
      experiences_errors.ex_country = ""
    } else if (field === "ex_zipcode") {
      fields.ex_zipcode = value;
      experiences_errors.ex_zipcode = ""
    }
     else if (field === "experience_from") {
      fields.experience_from = value;
      experiences_errors.experience_from = ""
    } else if (field === "experience_to") {
      fields.experience_to = value;
      experiences_errors.experience_to = ""
    }

    this.setState({
      experiences_formData: fields,
    });
  }
  handleExperienceInputChange = (e) => {
    let data = this.state.experiences_formData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
let {experiences_errors} = this.state;
    if (
      name === "ex_address" ||
      name === "ex_city" ||
      name === "hospital"
      
    ) {
      this.validateMaxLengthforexperiences(name, value, max);
    } else {
      data[name] = value;
      experiences_errors[name]=""
    }
   
    
    this.setState({ experiences_formData: data,experiences_errors });
  };
  validateMaxLengthforexperiences(name, value, max) {
    let data = this.state.experiences_formData;
    let experiences_errors = this.state.experiences_errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      experiences_errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      experiences_errors[name] = "";
      data[name] = value;
    }
    this.setState({ experiences_formData: data, experiences_errors: experiences_errors });
  }

  imageUpload = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let newfileName = event.target.files[0].name;

    // console.log(imageconfig);
    imageconfig.dirName = 'business_user/' + this.props.userdata.id
    console.log(imageconfig);

    const reacts3client = new S3(imageconfig);
    console.log(reacts3client)
    reacts3client.uploadFile(file, newfileName).then(data => {
      console.log(data)
      if (data.status === 204) {
        console.log('success');
        let resultdata = {
          'avatar': data.location,
          'user_id': this.props.userdata.id,
          'user_type': "business_user"
        }
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        axios.post(updateavatar, resultdata, CONFIG).then((result) => {
          this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
        })
      } else {
        console.log('error');
        this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
      }
    });

  };
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () =>
      this.deleteRecord(this.state.record, this.state.tableName);

    const certification_options = [
      { value: "eandv", label: "Eye and Vision" },
      { value: "echo", label: "Echo Workshop" },
      { value: "homeo", label: "Homeopathic" },
    ];

    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let specializations_array = this.props.appdata
      ? this.props.appdata[0].specializations
      : [];
    let specializations = [];
    specializations_array.forEach((element, index) => {
      specializations.push({ value: element.id, label: element.name });
    });
    specializations_array = specializations;

    const {
      businessUserData,
      errors,
      educational_qualification,
      educational_qualification_formData,
      educational_qualification_errors,
      certifications,
      certifications_formData,
      certificate_errors,
      experiences,
      experiences_formData,
      experiences_errors,
      trimmedDataURL,
      previewImage,
      currentFile,
    } = this.state;
    const phone_number = businessUserData.phone_number;
    return (
      <form className="custom-validation">
        <div className="col-lg-12">
          <div className="card  bghome">
            <div className="card-body pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <h4 className="card-title">BASIC DETAILS</h4>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"First Name"}
                          readOnly={true}
                          name={"first_name"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.first_name}
                          placeholder={""}
                          max={50}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.first_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Last Name"}
                          readOnly={true}
                          name={"last_name"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.last_name}
                          placeholder={""}
                          max={50}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.last_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                    <div className="form-group">
                        <label className="row">Profile picture</label>
                        <input type="file" accept="image/*" name="uploadedimage" onChange={this.imageUpload} />
                      </div>
                    </div>
                    <div className="col-sm-3">
                    <div className="form-group">
                    <Label title="Digital Signature" isMandatory={true} /> 
                        <SignaturePad canvasProps={{width: 200, height: 100, className: 'sigContainer'}}
          ref={(ref) => { this.sigPad = ref }} />               
      <div>
        <button className="buttons" id="clear" disabled={this.state.is_clear} onClick={this.clear}>
          Clear
        </button>
        <button className="buttons" id="upload" onClick={this.trim}>
          Upload
        </button>
        <input type="file" accept="image/*" onChange={this.selectFile} />
      </div>
      {previewImage && (
          <div>
            <img className={'sigImage'} src={previewImage} alt="" />
          </div>
        )}
    
            {trimmedDataURL
        ? <img alt="" className={'sigImage'}
          src={trimmedDataURL} />
        : null}
<div>
                         <SpanField classname="text-danger"
                          title={errors.image}
                        />
     </div>          
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group datepickerdiv">
                        <Label title="DOB" isMandatory={true} />

                        <DatePicker
                          className="form-control"
                          disabled={true}
                          value={businessUserData.date_of_birth}
                          format="dd-MM-yyyy"
                          onChange={(date) => this.handleDOBChange(date)}
                        />

                        <SpanField
                          classname="text-danger"
                          title={errors.date_of_birth}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          id="address1"
                          inputType={"text"}
                          title={"Address 01"}
                          name={"address1"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.address1}
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address1}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          isMandatory={false}
                          id="address2"
                          inputType={"text"}
                          title={"Address 02"}
                          name={"address2"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.address2}
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address2}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"City"}
                          name={"city"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.city}
                          placeholder={""}
                          max={30}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.city}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Country" isMandatory={true} />

                        <CountryDropdown
                          placeholder="Country"
                          name="country"
                          value={businessUserData.country || ""}
                          className="form-control"
                          onChange={(val) => this.selectCountry(val)}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.country}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="State" isMandatory={true} />
                        <RegionDropdown
                          name="state"
                          defaultOptionLabel="Select State"
                          country={businessUserData.country}
                          value={businessUserData.state || ""}
                          className="form-control"
                          onChange={(val) => this.selectState(val)}
                          placeholder="State"
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.state}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Zip/Postal Code"}
                          name={"zip_code"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.zip_code}
                          placeholder={""}
                          max={10}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.zip_code}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group mb-0 ">
                        
                        <label>
                          Phone number
                          <StarField />
                        </label>
                      </div>
                      <div className="form-control">
                      <PhoneInput
                        international
                        defaultCountry="IN"
                        placeholder="Enter phone number"
                        disabled="true"
                        value={phone_number}
                        onChange={this.handlePhoneInputChange}
                        max={30}
                        error={
                          phone_number
                            ? isValidPhoneNumber(phone_number)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                      <SpanField
                        classname="text-danger"
                        title={errors.phone_number}
                      />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Email"}
                          name={"email"}
                          readOnly={true}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.email}
                          placeholder={""}
                          max={30}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.email}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Gender" isMandatory={true} />

                        <div className="row">
                          <div className="col-sm-12">
                            <Select
                            maxMenuHeight={120}
                              className="form-control p-0 border-0"
                              name="gender"
                              value={businessUserData.gender}
                              onChange={this.handlegenderselectChange}
                              options={gender}
                            />
                          </div>
                        </div>
                        <SpanField
                          classname="text-danger"
                          title={errors.gender}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="control-label">
                          Blood Group
                          <StarField />
                        </label>
                        <Select
                        maxMenuHeight={120}
                          className="form-control p-0 border-0"
                          name="blood_group"
                          value={businessUserData.blood_group}
                          onChange={this.handlebloodgroupselectChange}
                          options={blood_group_options}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.blood_group}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Medical License ID"}
                          name={"licence_id"}
                          controlFunc={this.handleInputChange}
                          content={businessUserData.licence_id}
                          placeholder={""}
                          max={30}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.licence_id}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group datepickerdiv">
                        <Label title="Expiry Date" isMandatory={true} />
                        <DatePicker
                          className="form-control"
                          value={businessUserData.expiry_date}
                          format="dd-MM-yyyy"
                          onChange={(date) => this.handleExDateChange(date)}
                          yearPlaceholder='YYYY'
                          monthPlaceholder='MM'
                          dayPlaceholder='DD'
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.expiry_date}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <Label title="Languages" isMandatory={false} />

                      <div className="mobiletop">
                        <div className="position-relative">
                          <div className="input-group">
                            <Select
                            maxMenuHeight={120}
                              className="form-control p-0 border-0"
                              isMulti
                              name="language"
                              value={businessUserData.language}
                              onChange={this.handleselectChange}
                              options={language}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.language}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="formlayout ml-mr0">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="txtss">
                          Degree (Ex: M.B.B.S., M.D., F.R.C.S.,)
                          <StarField />
                        </h5>
                        <hr />
                        <div className="form-group">
                          <InputField
                            isMandatory={true}
                            inputType={"text"}
                            name={"qualification"}
                            controlFunc={this.handleInputChange}
                            content={businessUserData.qualification}
                            placeholder={""}
                            max={50}
                          />
                          <SpanField
                            classname="text-danger"
                            title={errors.qualification}
                          />
                        </div>

                        <div></div>
                      </div>

                      <div className="col-sm-6">
                        <h5 className="txtss">
                          Speciality
                          <StarField />
                        </h5>
                        <hr />
                        <div className="row mb-10">
                          <div className="col-sm-12">
                            <div className="input-group">
                              <Select
                              maxMenuHeight={120}
                                className="form-control p-0 border-0"  
                                name="speciality"
                              //  isMulti
                                value={businessUserData.speciality}
                                onChange={this.handlespecialityselectChange}
                                options={specialities_array}
                              />
                             
                            </div>
                            <SpanField
                                classname="text-danger width-100"
                                title={errors.speciality}
                              />
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Profile summary </label>
                        <TextArea
                          rows={5}
                          resize={false}
                          content={businessUserData.profile_summary}
                          name={"profile_summary"}
                          controlFunc={this.handleInputChange}
                          placeholder={""}
                           maxLength={200}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="card  bghome">
            <div className="card-body pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <h4 className="card-title">OTHER DETAILS</h4>
                  </div>

                  <div className="formlayout ml-mr0">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5 className="txtss mt-0">
                              Working hours <StarField />
                            </h5>
                            <hr className="mrgtp" />
                          </div>
                          <div className="col-sm-2">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Morning(HH:MM) : </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>From</label>
                                  {/* <TimePicker
                                    disabled={
                                      businessUserData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_shift_start"
                                    )}
                                   // step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessUserData.working_hours
                                        .morning_shift_start
                                        ? businessUserData.working_hours
                                            .morning_shift_start
                                        : ""
                                    }
                                    placeholder="From"
                                  /> */}
                                  <input
                                    disabled={
                                      businessUserData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="morning_shift_start"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_shift_start"
                                    )}
                                    value={businessUserData.working_hours
                                      ? businessUserData
                                        .working_hours
                                        .morning_shift_start
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.working_hours_morning}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>To</label>
                                  {/* <TimePicker
                                    disabled={
                                      businessUserData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_shift_end"
                                    )}
                                   // step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessUserData.working_hours
                                        .morning_shift_end
                                    }
                                    placeholder="To"
                                  /> */}
                                  <input
                                    disabled={
                                      businessUserData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="morning_shift_end"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_shift_end"
                                    )}
                                    value={businessUserData.working_hours
                                      ? businessUserData
                                        .working_hours
                                        .morning_shift_end
                                      : ""}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3 text-center hal">
                            <label className="dpns">&nbsp;</label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch33"
                                switch="none"
                                onChange={this.handleWorkhoursChange.bind(
                                  this,
                                  "24Hours"
                                )}
                                checked={
                                  businessUserData.working_hours.hours_24
                                }
                              />
                              <label
                                htmlFor="square-switch33"
                                data-on-label="24 hours"
                                data-off-label="24 hours"
                                className="dcte22 wid-115"
                              ></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-2">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Evening(HH:MM) : </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>From</label>
                                  {/* <TimePicker
                                    disabled={
                                      businessUserData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_shift_start"
                                    )}
                                 //   step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessUserData.working_hours
                                        .evening_shift_start
                                    }
                                    placeholder="From"
                                  /> */}
                                  <input
                                    disabled={
                                      businessUserData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="evening_shift_start"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_shift_start"
                                    )}
                                    value={businessUserData.working_hours
                                      ? businessUserData
                                        .working_hours
                                        .evening_shift_start
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.evening_shift_start}
                                  />
                                  {/* <input type="time" className="form-control" placeholder="" /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>To</label>
                                  {/* <TimePicker
                                    disabled={
                                      businessUserData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_shift_end"
                                    )}
                                   // step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessUserData.working_hours
                                        .evening_shift_end
                                    }
                                    placeholder="To"
                                  /> */}
                                  <input
                                    disabled={
                                      businessUserData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="evening_shift_end"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_shift_end"
                                    )}
                                    value={businessUserData.working_hours
                                      ? businessUserData
                                        .working_hours
                                        .evening_shift_end
                                      : ""}
                                  />

                                  {/* <input type="time" className="form-control" placeholder="" /> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3 text-center hal"></div>
                        </div>
                        <div className="row">
                          <SpanField
                            classname="text-danger offset-3"
                            title={errors.working_hours_evening}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5 className="txtss">
                              Working days
                              <StarField />
                            </h5>
                            <hr />
                            <div className="mt-4 mt-lg-0">
                              <CheckboxOrRadioGroup
                                setName={"working_days"}
                                type={"checkbox"}
                                controlFunc={this.handleWorkingDays}
                                options={working_days}
                                name={"working_days"}
                                selectedOptions={businessUserData.working_days}
                              />

                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="allDaysId"
                                  switch="none"
                                  onChange={this.handleWorkingDays}
                                  checked={businessUserData.working_all_days}
                                />
                                <label
                                  htmlFor="allDaysId"
                                  data-on-label="All days"
                                  data-off-label="All days"
                                  className="dcte22 wid-115"
                                ></label>
                              </div>
                            </div>
                            <SpanField
                            classname="text-danger working_days"
                            title={errors.working_days}
                          />
                          </div>

                        
                          
                          <div className="col-sm-12">
                            <Label title="Schedule type" isMandatory="true" />

                            <CheckboxOrRadioGroup
                              // title={'Which kinds of pets would you like to adopt?'}
                              setName={"schedule_type"}
                              type={"radio"}
                              controlFunc={this.handleSechduleType}
                              options={scuedueType}
                              selectedOptions={businessUserData.schedule_type}
                            />
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.schedule_type}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="formlayout ml-mr0 pb-0">
                    <div className="row">
                      <Fragment>
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">
                            Educational Qualifications*
                          </h5>

                          <hr className="mrgtp" />
                        </div>

                        <div className="col-sm-3">
                          <div className="form-group">
                           {/*  <label>
                              Education
                              <StarField />
                            </label> */}
                            <InputField
                              isMandatory={true}
                              inputType={"text"}
                              title={"Education"}
                              name={"education"}
                              controlFunc={this.handleEducationInputChange}
                              content={
                                educational_qualification_formData.education
                              }
                              placeholder={""}
                              max={50}
                            />
                            {/* <Select
                            maxMenuHeight={120}
                              className="form-control p-0"
                              name="education"
                              value={
                                educational_qualification_formData.education
                              }
                              onChange={this.handleEducationDetailChange.bind(
                                this,
                                "education"
                              )}
                              options={EDUCATION_OPTIONS}
                            /> */}
                            <SpanField
                              classname="text-danger"
                              title={educational_qualification_errors.education}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>
                              Field Of Study
                              <StarField />
                            </label>
                            <Select
                            maxMenuHeight={120}
                              className="form-control p-0 border-0"
                              name="specialization"
                              value={
                                educational_qualification_formData.specialization
                              }
                              onChange={this.handleEducationDetailChange.bind(
                                this,

                                "specialization"
                              )}
                              options={specializations_array}
                            />
                            <SpanField
                              classname="text-danger"
                              title={
                                educational_qualification_errors.specialization
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <InputField
                              isMandatory={true}
                              inputType={"text"}
                              title={"University"}
                              name={"university"}
                              controlFunc={this.handleEducationInputChange}
                              content={
                                educational_qualification_formData.university
                              }
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={
                                educational_qualification_errors.university
                              }
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Completion date
                              <StarField />
                            </label>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <MonthPick
                                defaultValue="Select Month"
                                selectedOption={
                                  educational_qualification_formData.completion_month
                                }
                                controlFunc={this.handleEducationDetailChange.bind(
                                  this,
                                  "completion_month"
                                )}
                                id="completion_month"
                                name="completion_month"
                              />
                               <SpanField
                              classname="text-danger"
                              title={
                                educational_qualification_errors.completion_month
                              }
                            />
                            </div>
                            <div className="col-sm-6">
                              <YearPick
                                defaultValue="Select Year"
                                start={currentYear}
                                selectedOption={
                                  educational_qualification_formData.completion_year
                                }
                                controlFunc={this.handleEducationDetailChange.bind(
                                  this,

                                  "completion_year"
                                )}
                                id="completion_year"
                                name="completion_year"
                              />
                              <SpanField
                              classname="text-danger"
                              title={
                                educational_qualification_errors.completion_year
                              }
                            />
                            </div>

                            
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group mb-0">
                            <label></label>
                          </div>
                          <div className="row">
                            <button
                              type="buttonadd"
                              id="addeducation"
                              onClick={this.addEducationDetail.bind(this)}
                              className="plusbtn-lft plusbtn-position"
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                          <div className={(this.state.businessUserData.educational_qualification.length>0)?"visibleHidden":""}>
                          <SpanField
                              classname="text-danger"
                              title={"Click the + button to add education details"}
                            />
                          </div>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    {this.state.businessUserData.educational_qualification && this.state.businessUserData.educational_qualification.length ? (
                        <div
                        className={
                          this.state.businessUserData.educational_qualification
                            .length > 0
                            ? "show-reactData-table"
                            : "hide-reactData-table"
                        }
                      >
                        <ReactDatatable
                          config={this.config}
                          records={
                            this.state.businessUserData.educational_qualification
                          }
                          columns={this.columns}
                          className="table table-bordered table-striped kas  mobile_table"
                        />
                      </div>
                    ):""}
                  
                    <Popup
                      show={this.state.addPopup}
                      onHide={PopupClose}
                      onConfirm={PopupConfirm}
                      title={"Confirmation"}
                      message={"Are you sure you want to delete this record?"}
                    />
                  </div>

                  <div className="formlayout ml-mr0 pb-15">
                    <div className="row">
                      <Fragment>
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">
                            Certifications/memerships
                          </h5>

                          <hr className="mrgtp" />
                        </div>

                        <div className="col-sm-3">
                          <div className="form-group">
                            <InputField
                              inputType={"text"}
                              title={"Specialization"}
                              name={"specialization"}
                              controlFunc={this.handleCertificateInputChange}
                              content={certifications_formData.specialization}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={certificate_errors.specialization}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <InputField
                              inputType={"text"}
                              title={"Organization"}
                              name={"organization"}
                              controlFunc={this.handleCertificateInputChange}
                              content={certifications_formData.organization}
                              placeholder={""}
                              max={100}
                            />
                            <SpanField
                              classname="text-danger"
                              title={certificate_errors.organization}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <InputField
                              inputType={"text"}
                              title={"Valid URL"}
                              name={"valid_URL"}
                              controlFunc={this.handleCertificateInputChange}
                              content={certifications_formData.valid_URL}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={certificate_errors.valid_URL}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-4">
                              <label>Completion date</label>
                              <div className="row">
                                <div className="col-sm-6">
                                  <MonthPick
                                    defaultValue="Select Month"
                                    selectedOption={
                                      certifications_formData.certification_completion_month
                                    }
                                    controlFunc={this.handleCertificationChange.bind(
                                      this,

                                      "certification_completion_month"
                                    )}
                                    id="certification_completion_month"
                                    name="certification_completion_month"
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={
                                      certificate_errors.certification_completion_month
                                    }
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <YearPick
                                    defaultValue="Select Year"
                                    start={currentYear}
                                    selectedOption={
                                      certifications_formData.certification_completion_year
                                    }
                                    controlFunc={this.handleCertificationChange.bind(
                                      this,

                                      "certification_completion_year"
                                    )}
                                    id="certification_completion_year"
                                    name="certification_completion_year"
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={
                                      certificate_errors.certification_completion_year
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <label>Valid till</label>
                              <div className="row">
                                <div className="col-sm-6">
                                  <MonthPick
                                    defaultValue="Select Month"
                                    selectedOption={
                                      certifications_formData.valid_upto_month
                                    }
                                    controlFunc={this.handleCertificationChange.bind(
                                      this,

                                      "valid_upto_month"
                                    )}
                                    id="valid_upto_month"
                                    name="valid_upto_month"
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={certificate_errors.valid_upto_month}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <YearPick
                                    defaultValue="Select Year"
                                    start={currentYear}
                                    endyear={currentYear + 20}
                                    selectedOption={
                                      certifications_formData.valid_upto_year
                                    }
                                    controlFunc={this.handleCertificationChange.bind(
                                      this,

                                      "valid_upto_year"
                                    )}
                                    id="valid_upto_year"
                                    name="valid_upto_year"
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={certificate_errors.valid_upto_year}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <label></label>
                              <div className="row">
                                <button
                                  type="button"
                                  onClick={this.addCertificationDetail.bind(
                                    this
                                  )}
                                  className="plusbtn-lft plusbtn-position"
                                >
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    <div
                      className={
                        this.state.businessUserData.certification.length > 0
                          ? "show-reactData-table"
                          : "hide-reactData-table"
                      }
                    >
                      <ReactDatatable
                        config={this.config}
                        records={this.state.businessUserData.certification}
                        columns={this.certificateColumns}
                        className="table table-bordered table-striped kas  mobile_table"
                      />
                    </div>
                  </div>

                  <div
                    className="formlayout ml-mr0 pb-15"
                    style={{ paddingBottom: "0", marginBottom: "0" }}
                  >
                    <div className="row">
                      <Fragment>
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">Experience</h5>

                          <hr className="mrgtp" />
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <InputField
                              id="hospital"
                              inputType={"text"}
                              title={"Hospital"}
                              name={"hospital"}
                              controlFunc={this.handleExperienceInputChange}
                              content={experiences_formData.hospital}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.hospital}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <InputField
                              id="ex_address"
                              inputType={"text"}
                              title={"Address"}
                              name={"ex_address"}
                              controlFunc={this.handleExperienceInputChange}
                              content={experiences_formData.ex_address}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.ex_address}
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <InputField
                              inputType={"text"}
                              title={"City"}
                              name={"ex_city"}
                              controlFunc={this.handleExperienceInputChange}
                              content={experiences_formData.ex_city}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.ex_city}
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>Country</label>
                            <CountryDropdown
                              placeholder="Country"
                              name="ex_country"
                              value={experiences_formData.ex_country || ""}
                              className="form-control"
                              onChange={this.handleExperienceChange.bind(
                                this,
                                "ex_country"
                              )}
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.ex_country}
                            />
                          </div>
                        </div>

                        <div className="col-sm-2">
                          <div className="form-group">
                            <label>State</label>

                            <RegionDropdown
                              name="ex_state"
                              defaultOptionLabel="Select State"
                              country={experiences_formData.ex_country}
                              value={experiences_formData.ex_state || ""}
                              className="form-control"
                              onChange={this.handleExperienceChange.bind(
                                this,
                                "ex_state"
                              )}
                              placeholder="State"
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.ex_state}
                            />
                          </div>
                        </div>
                        <div className="col-sm-2">
                        <div className="form-group">
                            <InputField
                              inputType={"text"}
                              title={"Pincode"}
                              name={"ex_zipcode"}
                              controlFunc={this.handleExperienceInputChange}
                              content={experiences_formData.ex_zipcode}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={experiences_errors.ex_zipcode}
                            />
                          </div>
                          </div>
                        <div className="col-sm-3 datepickerdiv">
                          <label>From</label>

                          <DatePicker
                            className="form-control"
                            value={experiences_formData.experience_from}
                            format="dd-MM-yyyy"
                            onChange={this.handleExperienceChange.bind(
                              this,
                              "experience_from"
                            )}
                            yearPlaceholder='YYYY'
                            monthPlaceholder='MM'
                            dayPlaceholder='DD'
                          />
                          <SpanField
                            classname="text-danger"
                            title={experiences_errors.experience_from}
                          />
                        </div>
                        <div className="col-sm-3 datepickerdiv">
                          <label>To</label>

                          <DatePicker
                            className="form-control"
                            value={experiences_formData.experience_to}
                            format="dd-MM-yyyy"
                            onChange={this.handleExperienceChange.bind(
                              this,

                              "experience_to"
                            )}
                            yearPlaceholder='YYYY'
                            monthPlaceholder='MM'
                            dayPlaceholder='DD'
                          />
                          <SpanField
                            classname="text-danger"
                            title={experiences_errors.experience_to}
                          />
                        </div>
                        <div className="col-sm-3">
                          <label></label>
                          <div className="row">
                            <button
                              type="button"
                              onClick={this.addExperienceDetail.bind(this)}
                              className="plusbtn-lft plusbtn-position"
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    {this.state.businessUserData.experience && this.state.businessUserData.experience.length ? (
                    <div
                      className={
                        this.state.businessUserData.experience && this.state.businessUserData.experience.length > 0
                          ? "show-reactData-table"
                          : "hide-reactData-table"
                      }
                    >
                      <ReactDatatable
                        config={this.config}
                        records={this.state.businessUserData.experience}
                        columns={this.experienceColumns}
                        className="table table-bordered table-striped kas  mobile_table"
                      />
                    </div>):""}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 btncent">
          <div className="form-group signupformbtn">
            <button
              className="signupbtn"
              data-toggle="modal"
              data-target=".bs-example-modal-center"
              onClick={this.handleFormSubmit}
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
    );
  }
}
export default withRouter(connect(mapStateToProps)(BusinessUserSignup1));
