import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import {
    GetFollowupData,
    updateclientappointmentstatus, getBusinessforBusinessUser, updateQueueStatus,
    getConsultingDoctors, getTimeoutbycategory, doctorUpcomingappointment,
    getscheduletypeforbusinessuser,
    getbasicscheduletypeinfoforbusinessuser
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Header from "../components/container/layout/business_user/Header";
import SideBar from "../components/container/SideBar";
import FooterSection from "../components/UI/FooterSection";
import Spinner from "../components/UI/Spinner";
import SpanField from "../components/UI/SpanField";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";
import Select from "react-select";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Dnaspinner from '../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}

class DoctorConsultation extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const timeout = this.state;

        this.state = {
            adminstatus: this.props.userdata.adminstatus,
            timeout: timeout,
            isTimedOut: false,
            showModal: false,
            loading: false,
            business_id: "",
            records: [],
            doc_list: [],
            selected_doctor: "",
            doctor_shift: [],
            selected_shift: "",
            tableSuccess: {
                message: "",
                status: "",
            },
            btndisabled: false,
        }
        this.appointmentcolumns = [

            {
                key: "s_no",
                text: "Appointment No",
                className: "preference",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_id",
                text: "Patient ID",
                className: "patient_id",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_name",
                text: "Patient Name",
                className: "patient_name",
                align: "left",
                sortable: true,
            },
            {
                key: "blood_group",
                text: "Blood Group",
                className: "blood_group",
                align: "left",
                sortable: true,
            },
            {
                key: "phone_number",
                text: "Phone Number",
                className: "phone_number",
                align: "left",
                sortable: true,
            },
            {
                key: "other_comments",
                text: "Reason For Visit",
                className: "other_comments",
                align: "left",
                sortable: true,
            },
            {
                key: "appt_date",
                text: "Appointment Date",
                className: "appt_date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.appt_date).format("DD-MM-yyyy");
                },
            },

            {
                key: "appt_time",
                text: "Appointment Time",
                className: "appt_time",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.appt_time ? moment(record.appt_time).format("hh:mm A") : "";
                },
            },
            {
                key: "schedule_type",
                text: "Appointment Type",
                className: "schedule_type",
                align: "left",
                sortable: true,
               
            },
            // {
            //     key: "wait_time",
            //     text: "Wait Time",
            //     className: "wait_time",
            //     align: "left",
            //     sortable: true,
            //     cell: (record) => {
            //         return record.wait_time ? moment.duration(record.wait_time).asMinutes().toFixed() + ' minutes' : "";
            //     },
            // },
            {
                key: "appt_status",
                text: "Status",
                className: "appt_status",
                align: "left",
                sortable: true,
                cell: (record) => {
                    if(record.appt_status === 'RESCHEDULE')
                    {
                     return "Rescheduled";
                    } else if(record.appt_status==='SCHEDULED')
                    {
                     return "Scheduled";
                    }else
                    return record.appt_status;
                },
            }, {
                key: "Action",
                text: "Action",
                className: "action",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    // console.log(i)
                    return (<td className="checkbtnou">
                        <button onClick={this.handleAction.bind(this, record, i)} 
                            className="">
                            {(record.appt_status === 'SCHEDULED' || record.appt_status === 'RESCHEDULE' || record.appt_status === 'Check-In') ? 'Admit' : record.appt_status}</button>
                    </td>)
                }
            },
            {
                key: "Meeting",
                text: "Meeting",
                className: "meeting",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    // console.log(i)
                    return (
                        (record.appt_status !== 'Check-In' && record.schedule_type=='online')?(<a  rel="noopener noreferrer" 
                        //href={"/join-room/" + record.client_appt_id + '/' + record.doctor_reg_id }
                        href="javascript:void(0)" onClick={()=>this.joinroom(record)}
                        
                        //onClick={() => this.gotoappointment(data)}
                        >Join</a>):("")
                    )
                }
            },
        ];
        this.columns = [
            {
                key: "preference",
                text: "Token No",
                className: "preference",
                align: "left",
                sortable: true,
            },
            {
                key: "id",
                text: "Patient ID",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_name",
                text: "Patient Name",
                className: "patient_name",
                align: "left",
                sortable: true,
            },
            {
                key: "date_of_birth",
                text: "DOB",
                className: "date_of_birth",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.date_of_birth).format("DD-MM-yyyy");
                },
            },
            {
                key: "blood_group",
                text: "Blood Group",
                className: "blood_group",
                align: "left",
                sortable: true,
            },
            {
                key: "phone_number",
                text: "Phone Number",
                className: "phone_number",
                align: "left",
                sortable: true,
            },
            {
                key: "expected_time",
                text: "Token Time",
                className: "expected_time",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.expected_time ? moment(record.expected_time).format("hh:mm A") : "";
                },
            },
            // {
            //     key: "wait_time",
            //     text: "Wait Time",
            //     className: "wait_time",
            //     align: "left",
            //     sortable: true,
            //     cell: (record) => {
            //         return record.wait_time ? moment.duration(record.wait_time).asMinutes().toFixed() + ' minutes' : "";
            //     },
            // },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
            }, {
                key: "Action",
                text: "Action",
                className: "action",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    // console.log(i)
                    return (<td className="checkbtnou">
                        <button onClick={this.hanldeAction.bind(this, record, i)} disabled={(this.state.btndisabled && record.status !== 'Consulting') || i !== 0 ? true : false} className="">
                            {record.status === 'Check-In' ? 'Admit' : record.status}</button>
                    </td>)
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    handleClose() {
        this.setState({ showModal: false })
    }
    joinroom = (data)=>{
        console.log(data)
        this.props.history.push({
          pathname: "/join-room/"+data.client_appt_id+"/"+data.doctor_reg_id,
          state: {
            
            patient_name : this.props.userdata.first_name+" "+this.props.userdata.last_name
            
          }
        });
      }

    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
  if(isTimedOut===true)
  {
    localStorage.removeItem("token");
    this.props.logout();
    
  }    }


    handleAction = (record, index, e) => {

        // console.log(record);
        // return false;
        this.setState({ loading: true })
        // axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
        // let clientapppointmentId = record.q_id;
        // axios.get(getQueueByid + clientapppointmentId)
        //     .then((queue_data) => {
        //         console.log(queue_data);

        // if (queue_data.data !== '' && queue_data.data != null) {
        // let queuein_data = queue_data.data
        // queuein_data.status = 'Consulting';
        // let clientlist = [];
        // clientlist.push(record.client_id);
        // queuein_data.clientList = clientlist;
        // console.log(queuein_data);
        // // queuein_data.clientList = [queuein_data.data.client_id];
        this.setState({ queue_data: record })

        console.log(record); //return false;
        // {
        //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
        //     "n_schedule_day_id": "f4f392a4-bdc8-11eb-97e3-0ad8e4601dac",   
        //     "n_status": "Check-In",   
        //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
        //     "n_schedule_type": "Queue",
        //     "n_q_id":"8c9d1193-aa87-4907-bb5b-96c6e3e441bd"
        // }

        // axios
        //  .post(updateQueueStatus, queuedata)
        // .then((response) => {
        // console.log(response); return false;
        //     this.setState({ loading: false });
        // socketObj.emit('queueStatusChanged', () => {});
        //     this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
        //    window.location.href = "/consult/" + record.id



        // this.props.history.push("/consult");

        //   }).catch((e) => {
        //     console.log(e);
        //    console.log("Update queue failed");
        // });
        // }
        // }).catch((e) => {
        //     console.log("Get appoinment by id failed");
        // });



        let req_data = {
            "order_id": "",
            "payment_id": "",
            "amount": "",
            "appt_status": "Consulting",
            "payment_status": "",
            "checkin_id": record.id,
            "client_appt_id": record.client_appt_id
        }
        console.log(req_data);
        //   return false;
        axios.post(updateclientappointmentstatus, req_data, CONFIG).then((res) => {
            console.log(res);
            setTimeout(
                () =>
                    (res.data.status === "1") ?
                        window.location.href = "/consult/" + record.id : ""
                ,
                500
            );
        }).catch((err) => {


        })


        // if (index === 0) {
        // this.props.history.push("/consult/" + record.checkin_id);
        // }
    }

    hanldeAction = (record, index, e) => {

        // console.log(record);
        // return false;
        this.setState({ loading: true })
        // axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
        // let clientapppointmentId = record.q_id;
        // axios.get(getQueueByid + clientapppointmentId)
        //     .then((queue_data) => {
        //         console.log(queue_data);

        // if (queue_data.data !== '' && queue_data.data != null) {
        // let queuein_data = queue_data.data
        // queuein_data.status = 'Consulting';
        // let clientlist = [];
        // clientlist.push(record.client_id);
        // queuein_data.clientList = clientlist;
        // console.log(queuein_data);
        // // queuein_data.clientList = [queuein_data.data.client_id];
        this.setState({ queue_data: record })
        let queuedata = {
            "n_business_id": record.business_id,
            "n_business_user_id": this.props.userdata.id,
            "n_schedule_day_id": record.schedule_day_id,
            "n_q_id": record.q_id,
            "n_status": "Consulting",
            "n_preference": record.preference,
            "n_schedule_type": "Queue"
        }

        // {
        //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
        //     "n_schedule_day_id": "f4f392a4-bdc8-11eb-97e3-0ad8e4601dac",   
        //     "n_status": "Check-In",   
        //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
        //     "n_schedule_type": "Queue",
        //     "n_q_id":"8c9d1193-aa87-4907-bb5b-96c6e3e441bd"
        // }
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(updateQueueStatus, queuedata)
            .then((response) => {
                // console.log(response); return false;
                this.setState({ loading: false });
                // socketObj.emit('queueStatusChanged', () => {});
                this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
                window.location.href = "/consult/" + record.checkin_id



                // this.props.history.push("/consult");

            }).catch((e) => {
                console.log(e);
                console.log("Update queue failed");
            });
        // }
        // }).catch((e) => {
        //     console.log("Get appoinment by id failed");
        // });


        // if (index === 0) {
        // this.props.history.push("/consult/" + record.checkin_id);
        // }
    }
    componentDidMount = () => {


        console.log(this.state.selected_doctor);
        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        timeout: data.data.timeout

                    });
            })
            .catch((e) => { });
            axios
            .post(getbasicscheduletypeinfoforbusinessuser, { business_user_id: this.props.userdata.id}, CONFIG)
            .then((res) => {
              let data = res.data;
              console.log(res);
              console.log(data);
              console.log(data.data[0].schedule_type);
              if (data.status === "1")
                this.setState({
                  basicscheduletypefordoctor: data.data[0].schedule_type
                  
                });
              })
            .catch((e) => { });
        
        axios
            .post(getscheduletypeforbusinessuser, { business_user_id: this.props.userdata.id }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data[0].schedule_type);
                if (data.status === "1")
                    this.setState({
                        schedule_type: data.data[0].schedule_type

                    });
                  
                
                    this.setState({ loading: true });
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${this.props.access_token}`;
            
                    let urlbu =
                        getBusinessforBusinessUser +
                        "?business_user_id=" +
                        this.props.userdata.id;
                    axios
                        .get(urlbu, null, CONFIG)
                        .then((res) => {
                            let data = res.data;
                            // console.log(data.data);
            
                            let doc_list = [];
                            data.data.forEach((e, index) => {
                                // console.log(element)
            
                                let doctor_shift = [];
                                const reqdata = {
                                    business_id: e.business_id,
                                    // n_currentdate: getDateISOFormat(new Date()),
                                };
                                axios.defaults.headers.common[
                                    "Authorization"
                                ] = `Bearer ${this.props.access_token}`;
                                axios
                                    .post(getConsultingDoctors, reqdata)
                                    .then((result) => {


                                        

                                        // console.log(JSON.parse(JSON.stringify(result.data.data[0].doctor_Appointments)), typeof result.data.data[0].doctor_Appointments)
                                        result.data.data.forEach((element, index) => {
                                            if (element.business_user_id === this.props.userdata.id) {
                                                
                                                // if (element.schedule_type !== 'appointment')
                                                doc_list.push({ value: element.business_id, label: element.business_name, doctor_Appointments: element.doctor_Appointments });
                                               
                                                console.log(element.doctor_Appointments);
                                            }
                                           
                                            
                                        });
                                        // console.log(JSON.parse(JSON.stringify(doc_list[0].doctor_Appointments)));
            
            
                                        if (doc_list.length) {
                                          
                                            doc_list[0].doctor_Appointments.reverse().forEach((doc, k) => {
                                               
                                                doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
                                            
                                                doctor_shift.reverse();
                                            })
                                          
                                        }
                                       
                                        console.log(doctor_shift.reverse());
                        
            console.log(this.state.basicscheduletypefordoctor,this.state.schedule_type);
            
            
                                        this.setState({ doctor_appointment: result.data.data, doc_list: doc_list, selected_doctor: doc_list[0], doctor_shift: doctor_shift, selected_shift: doctor_shift[0], loading: false, business_id: data.data[0].business_id });


                                        if(this.state.schedule_type == 'Queue'||this.state.basicscheduletypefordoctor=='Queue'){
                                            this.GetFollowupData(this.state.doc_list[0].value, this.props.userdata.id, this.state.doctor_shift[0].value);
            
                                        }

                                        if((this.state.basicscheduletypefordoctor && this.state.basicscheduletypefordoctor.toLowerCase()=="appointment")||(this.state.schedule_type && this.state.schedule_type.toLowerCase()=="appointment"))
                                        {
                                          this.doctorUpcomingappointment(this.state.doc_list[0].value, this.props.userdata.id);
                                     
                                        }
                                    
            
                                    })
                                    .catch((e) => { console.log(e); });
                            });
                            this.setState({ loading: false })
            
            
                        }).catch((e) => {
                            this.setState({ loading: false })
                        })


                    
            })
            .catch((e) => { });


  

    };

    GetFollowupData = (business_id, business_user_id, schedule_day_id) => {
        this.setState({ loading: true })
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(GetFollowupData, { business_id: business_id, business_user_id: business_user_id, schedule_day_id: schedule_day_id }, CONFIG)
            .then((list) => {
                console.log(list.data.countOfRecords);
                if(list.data.countOfRecords=="0")
                {
                    toast.success("No token appoinment is available in this shift.",{
                        className:"toast-success"
                      });
                }
                this.setState({ loading: false })
                let btndisabled = false
                list.data.data.forEach((element) => {
                    if (element.status === 'Consulting') {
                        btndisabled = true;
                    }
                })
                this.setState({ records: list.data.data, btndisabled: btndisabled, loading: false });
            })
            .catch((e) => { this.setState({ loading: false }) });
    }

    doctorUpcomingappointment = (business_id, business_user_id) => {
                                      
       // this.setState({ loading: true })
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
axios
    .post(doctorUpcomingappointment, { business_user_id: business_user_id, business_id: business_id }, CONFIG)
    .then((result) => {
        console.log(result);
        if (result.data.status === "1") {
            if(result.data.countOfRecords=="0")
                {
                    toast.success("Currently ,there are no appointments available during this shift.",{
                        className:"toast-success"
                      });
                }
            console.log(result.data.data);
            let doctorupcoming = result.data.data;
            let doctorupcomingarray = [];
            doctorupcoming.forEach((element, index) => {

                element.s_no = index + 1;

                doctorupcomingarray.push(element);
            });
            this.setState({ doctorupcoming: doctorupcomingarray })
        } else {
            this.setState({ doctorupcoming: [] })
        }
    }).catch((err) => {
        console.log('error')
    })


    }



    
    handleselectChange = (selectedoption, event) => {

        let { doc_list, selected_doctor } = this.state;
        let doctor_shift = [];
        if (event.name === "doc_list") {

            doc_list[event.name] = selectedoption
            // console.log(selectedoption);

            selectedoption.doctor_Appointments.forEach((doc, k) => {
                doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
            })

            this.setState({ doc_list: doc_list, selected_doctor: selectedoption, doctor_shift: doctor_shift, selected_shift: doctor_shift[0] });
            this.GetFollowupData(selectedoption.value, this.props.userdata.id, doctor_shift[0].value)
        }
        if (event.name === 'doctor_shift') {

            this.setState({ selected_shift: selectedoption })

            // console.log(selected_doctor.value, this.props.userdata.id, selectedoption.value)
            this.GetFollowupData(selected_doctor.value, this.props.userdata.id, selectedoption.value)
        }
    }
    handleselecthospitalChange = (selectedoption, event) => {

        let { doc_list, selected_doctor } = this.state;
        let doctor_shift = [];
        if (event.name === "doc_list") {

            doc_list[event.name] = selectedoption
            // console.log(selectedoption);

           // selectedoption.doctor_Appointments.forEach((doc, k) => {
            //    doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
         //   })

            this.setState({ doc_list: doc_list, selected_doctor: selectedoption });
        //    this.GetFollowupData(selectedoption.value, this.props.userdata.id, doctor_shift[0].value)

        this.doctorUpcomingappointment(selectedoption.value, this.props.userdata.id);
        }
       
    }
    render() {
        const {

            tableSuccess,
            loading, doc_list,
            timeout,
            schedule_type,
            basicscheduletypefordoctor
        } = this.state;
        return (
            <div id="layout-wrapper">
                <Header user_id={this.props.userdata.id} />
             {/*    <Spinner loading={loading} /> */}
             {loading && <Dnaspinner />}
                <div className="main-content bacfot">
                    <div className="mainpage">
                        <div className="main-box">


                            <div className="sidemenu">
                                <SideBar />
                            </div>
                            <section className="main-body hospital-profilegap">

                                <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                />
   <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={timeout} />
                                {
                                    this.state.adminstatus !== "" && this.state.adminstatus === "Pending" ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    Admin not approved your account yet to view this page. Please contact the administrator to approve your account.
                                                </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
                                        (<Fragment>
                                            {schedule_type === "Queue"||basicscheduletypefordoctor==="Queue"?
                                                (<div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        <div className="row align-items-center">

                                                            <div className="col-lg-4">
                                                                <h4 className="font-weight-bold">Doctor Consultation</h4>
                                                            </div>
                                                            {/* <div className="col-lg-4">
                                                                <Select maxMenuHeight={120}
                                                                    className="form-control mt-2 queuedropdown"
                                                                    name="doc_list"
                                                                    // value={selected_queue.first_name}
                                                                    onChange={
                                                                        this.handleselectChange
                                                                    }
                                                                    value={this.state.selected_doctor}
                                                                    options={doc_list}
                                                                // isClearable={true}
                                                                />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <Select maxMenuHeight={120}
                                                                    className="form-control mt-2 queuedropdown"
                                                                    name="doctor_shift"
                                                                    // value={selected_queue.first_name}
                                                                    onChange={
                                                                        this.handleselectChange
                                                                    }
                                                                    value={this.state.selected_shift}
                                                                    options={this.state.doctor_shift}
                                                                // isClearable={true}
                                                                />
                                                            </div> */}
                                                            </div>
                                                            <div className='row'>
                                                            <div className="col-sm-12">
                                                                <div className="card">
                                                                    <div className="card-body ">
                                                                    <div className='row d-flex justify-content-center'>
                                                                    {/* <div className="col-lg-3 mg-btm3"> */}
                                                                    <div className="col-12 col-sm-6 col-md-3 mg-btm3">
                                                                <Select maxMenuHeight={120}
                                                                    className="form-control mt-2 queuedropdown "
                                                                    name="doc_list"
                                                                    // value={selected_queue.first_name}
                                                                    onChange={
                                                                        this.handleselectChange
                                                                    }
                                                                    value={this.state.selected_doctor}
                                                                    options={doc_list}
                                                                // isClearable={true}
                                                                />
                                                            </div>
                                                            {/* <div className="col-lg-3 mg-btm3"> */}
                                                            <div className="col-12 col-sm-6 col-md-3 mg-btm3">
                                                                <Select maxMenuHeight={120}
                                                                    className="form-control mt-2 queuedropdown"
                                                                    name="doctor_shift"
                                                                    // value={selected_queue.first_name}
                                                                    onChange={
                                                                        this.handleselectChange
                                                                    }
                                                                    value={this.state.selected_shift}
                                                                    options={this.state.doctor_shift}
                                                                // isClearable={true}
                                                                />
                                                            </div>
                                                            </div>

                                                                        <div className="tab-content" id="v-pills-tabContent">
                                                                            <div
                                                                                className="tab-pane fade show active"
                                                                                id="v-pills-shipping"
                                                                                role="tabpanel"
                                                                                aria-labelledby="v-pills-shipping-tab"
                                                                            >
                                                                                <div className="user-notification">
                                                                                    <SpanField
                                                                                        classname={
                                                                                            0 === tableSuccess.status
                                                                                                ? "text-danger user-notification"
                                                                                                : "text-success user-notification"
                                                                                        }
                                                                                        title={tableSuccess.message}
                                                                                    />
                                                                                </div>
                                                                                <ReactDatatable
                                                                                className="table table-bordered table-striped kas  mobile_table"
                                                                                    config={this.config}
                                                                                    records={this.state.records}
                                                                                    columns={this.columns}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                          


                                                     

                                                    </div>

                                                </div>
                                                ) :

                                                (
                                                    <div className="container-fluid">
                                                        <div className="checkout-tabs">
                                                            <div className="row mb-4 align-items-center">

                                                                <div className="col-lg-4">
                                                                    <h4 className="font-weight-bold mb-0">Doctor Appointments</h4>
                                                                </div>
                                                                {/* <div className="col-lg-8">
                                                                    <Select maxMenuHeight={120}
                                                                        className="w-50 ml-auto"
                                                                        name="doc_list"
                                                                        // value={selected_queue.first_name}
                                                                        onChange={
                                                                            this.handleselecthospitalChange
                                                                        }
                                                                        value={this.state.selected_doctor}
                                                                        options={doc_list}
                                                                    // isClearable={true}
                                                                    />
                                                                </div> */}
                                                                {/*  <div className="col-lg-4">
                                                <Select maxMenuHeight={120}
                                                    className="form-control mt-2 queuedropdown"
                                                    name="doctor_shift"
                                                    // value={selected_queue.first_name}
                                                    onChange={
                                                        this.handleselectChange
                                                    }
                                                    value={this.state.selected_shift}
                                                    options={this.state.doctor_shift}
                                                // isClearable={true}
                                                />
                                            </div> */}


                                                                

                                                            </div>
                                                            <div className="row">
                                                            <div className="col-sm-12">
                                                                    <div className="card ">
                                                                        <div className="card-body ">
                                                                        <div className="col-sm-6 ">
                                                                    <Select maxMenuHeight={120}
                                                                        className="w-50 ml-auto mg-btm8"
                                                                        name="doc_list"
                                                                        // value={selected_queue.first_name}
                                                                        onChange={
                                                                            this.handleselecthospitalChange
                                                                        }
                                                                        value={this.state.selected_doctor}
                                                                        options={doc_list}
                                                                    // isClearable={true}
                                                                    />
                                                                </div>
                                                                {/* <div className="col-lg-4">
                                                <Select maxMenuHeight={120}
                                                    className="form-control mt-2 queuedropdown"
                                                    name="doctor_shift"
                                                    // value={selected_queue.first_name}
                                                    onChange={
                                                        this.handleselectChange
                                                    }
                                                    value={this.state.selected_shift}
                                                    options={this.state.doctor_shift}
                                                // isClearable={true}
                                                />
                                            </div> */}
                                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                                <div
                                                                                    className="tab-pane fade show active"
                                                                                    id="v-pills-shipping"
                                                                                    role="tabpanel"
                                                                                    aria-labelledby="v-pills-shipping-tab"
                                                                                >
                                                                                    <div className="user-notification">
                                                                                        <SpanField
                                                                                            classname={
                                                                                                0 === tableSuccess.status
                                                                                                    ? "text-danger user-notification"
                                                                                                    : "text-success user-notification"
                                                                                            }
                                                                                            title={tableSuccess.message}
                                                                                        />
                                                                                    </div>
                                                                                    
                                                                                    <ReactDatatable
                                                                                    className="table table-bordered table-striped kas  mobile_table"
                                                                                        config={this.config}
                                                                                        records={this.state.doctorupcoming}
                                                                                        columns={this.appointmentcolumns}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )}

                                        </Fragment>)
                                }
                           
                            </section>
                                <FooterSection />
                        </div>



                    </div>


                </div>

            </div >

        );

    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(DoctorConsultation);