import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = (props) => (

    <label className="switch">
        <input className="switch-input" name={props.name} type="checkbox" onChange={props.controlFunc} value={props.value} checked={props.checked}/>
        <span className="switch-label" data-on={props.dataOn} data-off={props.dataOff} ></span>
        <span className="switch-handle"></span>
    </label>
);

ToggleButton.propTypes = {
    // title: PropTypes.string.isRequired,

    controlFunc: PropTypes.func.isRequired
};

export default ToggleButton;