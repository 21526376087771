import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import ReactDatatable from "@ashvin27/react-datatable";
import { Modal } from "react-bootstrap";
import SpanField from "../components/UI/SpanField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  queueAssignmentList,
  QueueAssignmentListbySelectedDoctor,
  getMasterPharmacy,
  consultDoctor,
  updateQueueStatus,
  getDocByBusiness,
  getTimeoutbycategory,
  sendsms,
  sendemail,
  getBusinessShortDetail,
  getPharmacydetailsbyid,
  getBusinessuserShortDetail
} from "../config/url.json";
import InputField from "../components/UI/InputField";
import { ExpirePopup } from "../components/UI/ExpirePopup";
import axios from "axios";
import { CONFIG } from "../config/data";
import moment from "moment";
import Select from "react-select";
import { DAY_TYPES } from "../constants/config/data";
import FooterSection from "../components/UI/FooterSection";
import { getDateISOFormat } from "../constants/globalLevelFunctions";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { Popupinfo } from "../components/UI/Popupinfo";

function mapStateToProps(state) {
  return {
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity,
    business_short_detail: state.SearchReducer.business_short_detail

  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class QueueAssignment extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      showexpirePopup: this.props.validity,
      doc_list: [],
      selected_doctor: "",
      doctor_appointment: [],
      queue: {
        id: "",
        client_id: "",
        business_id: "",
        business_user_id:"",
        first_name: "",
        last_name: "",
        age: "",
        date_of_birth: "",
        gender: "",
        marital_status: "",
        phone_number: "",
        status: "cancelled",
        patient_name: "",
        actual_checkin_time: "",
        expected_time: "",
        waitTime: "",
      },
      successMessage: {
        message: "",
        status: "1",
      },
      errorMessage:"",
      errors:{
        pharmacy_details: "",
        follow_up_details: "",
        consultation_fee: "",
      },
      actionFormData: {
        registrationId: "",
        pharmacy_details: "",
        follow_up_details: "",
        consultation_fee: "",
        medicine_description:"",
        selectedDaysType: "",
        successMessage: "",
        errorMessage: "",

      },
      cancelModel: false,
      queueList: [],
      pharmacy_array: [],
      selectedRecord: "",
      cancelSelRecord: {},
      admin_working_hours_detail: {
        doctor_arrived: "Yes",
        hospital_opened: "Yes",
        hospital_opened_data: "No",
        doctor_arrived_data: "No",
        schedule_type: "",
        cycle_time: "",
        cycle_time_break: { label: "min", value: "min" },
        reschedule_time: "",
        reschedule_time_break: { label: "min", value: "min" },
        hospital_open_date: moment().format("YYYY-MM-DD"),
        hospital_open_time: moment().format("HH:mm"),
        doctor_arrival_date: moment().format("YYYY-MM-DD"),
        doctor_arrival_time: moment().format("HH:mm"),
      },
    };
    this.columns = [
      {
        // key: "queue_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <a
              onClick={() => this.editRecord(record)}
              className="tableAnchorTag"
            >
              {record.s_no}
            </a>
          );
        },
      },
      {
        key: "patient_id",
        text: "Patient ID",
        className: "patient_id",
        align: "left",
        sortable: true,
      },
      {
        key: "patient_name",
        text: "Patient Name",
        className: "patient_name",
        align: "left",
        sortable: true,
      },

      {
        key: "dob",
        text: "Date of Birth",
        className: "date_of_birth",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.dob).format("DD-MM-YYYY");
        },
      },
      {
        key: "blood_group",
        text: "Blood Group",
        className: "blood_group",
        sortable: true,
        align: "left",
      },

      {
        key: "phone_number",
        text: "Mobile Number",
        className: "text-center",
        sortable: true,
        align: "left",
        cell: (record) => {
          return record.phone_number ? record.phone_number:record.parent_phone_number ;
        },
      },
      {
        key: "TokenDate",
        text: "Token Date",
        className: "text-center",
        sortable: true,
        align: "left",
      },
      {
        key: "TokenTime",
        text: "Token Time",
        className: "text-center",
        sortable: true,
        align: "left",
      },
      {
        key: "wait_time",
        text: "Wait Time",
        className: "text-center",
        sortable: true,
        align: "left",
      },
      {
        key: "status",
        text: "Status",
        className: "text-center",
        sortable: true,
        align: "left",
      },
      {
        key: "payment_status",
        text: "Payment Status",
        className: "text-center",
        sortable: true,
        align: "left",
      },
    /*   {
        key: "edit",
        text: "Edit",
        className: "edit",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>

              <button
                className="btn btn-plain"
                onClick={() => this.editRecord(record)}
              >
                <i className="fa fa-edit iconftsiz" aria-hidden="true"></i>
              </button>

            </Fragment>
          );
        },
      },
     */  {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>

              {record.isprescription  ? (
                <button
                  className="btn btn-plain"
                  onClick={() => this.editRecord(record)}
                >
                  <i className="fa fa-print iconftsiz" aria-hidden="true"></i>
                </button>
              ) : (
                ""
              )}
              {record.isfee  ? (
                <button
                  className="btn btn-plain"
                  onClick={() => this.editRecord(record)}
                >
                  <i className="fa fa-usd iconftsiz" aria-hidden="true"></i>
                </button>
              ) : (
                ""
              )}
              {record.isfollowup  ? (
                <button
                  className="btn btn-plain"
                  onClick={() => this.editRecord(record)}
                >
                  <i
                    className="fa fa-calendar iconftsiz"
                    aria-hidden="true"
                  ></i>
                </button>
              ) : (
                ""
              )}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
   
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  

  cancelRecord(record) {
    let selrecord = this.state.selectedRecord;

    if (selrecord) {
      this.setState(
        {
          cancelSelRecord: selrecord,
        },
        this.setState({
          cancelModel: true,
        })
      );
    }

  }

  editRecord(record) {
console.log(record)
    let actionFormData = {
      registrationId: "",
      pharmacy_details: "",
      follow_up_details: "",
      consultation_fee: "",
      medicine_description:"",
      selectedDaysType: "",
      successMessage: "",
      errorMessage: "",

    };
    let selectedRecord = this.state.selectedRecord;
    console.log(selectedRecord);
    selectedRecord = record;

    actionFormData.registrationId = record.patient_id;
    actionFormData.email = record.email;
    actionFormData.phone_number = record.phone_number;
    actionFormData.phone_code = record.phone_code;
    actionFormData.first_name = record.first_name;
    actionFormData.medicine_description = record.medicine_description;

    actionFormData.consultation_fee = record.consultation_fee
      ? record.consultation_fee
      : "";
    actionFormData.pharmacy_details = record.pharmacy_details
      ? record.pharmacy_details
      : "";

    if (record.follow_up_details) {
      let temp_array = record.follow_up_details;
      actionFormData.follow_up_details = temp_array;
      actionFormData.selectedDaysType = {
        label: temp_array,
        value: temp_array,
      };
    }
    console.log(record.pharmacy_details.value);
    console.log(record.pharmacy_details.label);
    axios
    .post(getPharmacydetailsbyid,{id:record.pharmacy_details.value}, CONFIG)
    .then((res) => {
      //console.log(res);
      // console.log(res.data);
      let response = res.data;
      console.log(response.data[0]);
      console.log(response.data[0].pharmacy_location);

      // return false;
      if (response.status == "1") {
        // window.location.href = "/vendor/" + response.data;
        this.setState({ pharmacy_location: response.data[0].pharmacy_location });
      }

    })
    .catch((e) => {
      console.log(e);
    });

    axios
    .post(getBusinessuserShortDetail,{business_user_id:record.business_user_id}, CONFIG)
    .then((res) => {
      let response = res.data;
      console.log(response.data);
      console.log(response.data.last_name);

      // return false;
      if (response.status == "1") {
        // window.location.href = "/vendor/" + response.data;
        this.setState({ doctor_name: response.data.first_name +' '+ response.data.last_name,speciality_name:response.data.speciality_name });
      }

    })
    .catch((e) => {
      console.log(e);
    });



    this.setState({ actionFormData: actionFormData });
    this.setState({ selectedRecord: selectedRecord });
    
  }

  conformCancelAppointment = () => {
    let record = this.state.cancelSelRecord;
    let data = {};
    data.n_business_id = this.props.userdata.id;
    data.n_business_user_id = "";
    data.n_schedule_day_id = record.schedule_day_id;
    data.n_q_id = record.q_id;
    data.n_status = "Cancelled";
    data.n_preference = record.preference;
    data.n_savefor = "C";
    console.log(data);
    axios
      .post(updateQueueStatus, data)
      .then((res) => {
        console.log(res);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(
            queueAssignmentList,
            { business_id: this.props.userdata.id },
            CONFIG
          )
          .then((res) => {

            let resdata = res.data.data;
            let queueList = [];
            resdata.forEach((element, index) => {
              let queue = {};
              queue.queue_no = element.preference;
              queue.patient_id = element.patient_id;
              queue.patient_name = element.first_name + " " + element.last_name;
              queue.dob = element.date_of_birth;
              queue.gender = element.gender;
              queue.blood_group = element.blood_group;
              queue.phone_number = element.phone_number;
              queue.app_time = element.expected_time;
              queue.wait_time = element.waitTime;
              queue.status = element.status;
              queue.checkin_id = element.checkin_id;
              queue.isfee = element.fee;
              queue.isprescription = element.prescription;
              queue.isfollowup = element.followup;
              queue.client_id = element.client_id;
              queue.business_user_id=element.business_user_id;
              queue.id = element.id;
              queue.q_id = element.q_id;
              queue.schedule_day_id = element.schedule_day_id;
              queue.pharmacy_details = element.pharmacy_details
                ? element.pharmacy_details
                : "";
              queue.consultation_fee = element.consultation_fee
                ? element.consultation_fee
                : "";
              queue.follow_up_details = element.follow_up_details
                ? element.follow_up_details
                : "";
              queueList[index] = queue;
            });

            // let clientmedicinehistory = {};
            this.setState({ queueList: queueList });
          })
          .catch((e) => { });
        this.closePopup();
      })
      .catch((e) => { });

  };

  clearActionForm = () => {
   // e.preventDefault();
let errors=this.state.errors;
    let actionFormData = this.state.actionFormData;
    actionFormData.registrationId = "";
    actionFormData.pharmacy_details = {};
    actionFormData.follow_up_details = "";
    actionFormData.consultation_fee = "";
    actionFormData.selectedDaysType = "";
    actionFormData.medicine_description = "";
    this.setState({ actionFormData: actionFormData,errors:"" });
    this.setState({ selectedRecord: "" });

  };
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          successMessage: { message: "" },
         
        }),
      5000
    );
  };
  performAction = (e) => {

    const postData = this.state.selectedRecord;

    if ("" === postData || null === postData) {
      alert("Select any one of the record fields.");

    } else {
      postData.business_id = this.props.userdata.id;
      postData.pharmacy_details = this.state.actionFormData.pharmacy_details;
      postData.business_user_id=this.state.actionFormData.business_user_id;
      postData.follow_up_details =
        this.state.actionFormData.follow_up_details;
      postData.consultation_fee = this.state.actionFormData.consultation_fee;
      postData.medicine_description = this.state.actionFormData.medicine_description;
      postData.id = postData.checkin_id;
      if ("complete" === e.target.id) {
        // postData.status = "complete";
      }
      postData.actionType = e.target.id;
      let errors = this.state.errors;
      errors.pharmacy_details = "";
      errors.follow_up_details = "";
      errors.consultation_fee = "";
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      this.setState({ loading: true }, () => {
        axios
          .post(consultDoctor, postData, CONFIG)
          .then((res) => {
            
            const result = res.data;
           let successMessage = this.state.successMessage;
            if (result.status === "1") 
              this.setState({ successMessage: result });
              if ("print" === postData.actionType) {
                if(postData.pharmacy_details === "" || !postData.pharmacy_details)
                {
                  errors.pharmacy_details = "Please choose pharmacy";
                  this.setState({errors})
                  return false;                  
                }
                else 
                {
                 /*  this.props.history.push(
                  "/hospitalprint_prescription/" + postData.id + "/business");
                  */ this.props.history.push({
                    pathname: "/hospitalprint_prescription/" + postData.id + "/business",
                  state: {
                    business_id:this.props.userdata.id,
                    business_name:this.state.business_name,
                    hospital_address:this.state.hospital_address,
                    hospital_city:this.state.hospital_city,
                    hospital_state:this.state.hospital_state,
                    hospital_zipcode:this.state.hospital_zipcode,
                    patient_id:this.state.selectedRecord.patient_id,
                    patient_name:this.state.selectedRecord.patient_name,
                    pharmacy_name:this.state.selectedRecord.pharmacy_details.label,
                    pharmacy_location:this.state.pharmacy_location,
                    doctor_name:this.state.doctor_name,
                    speciality_name:this.state.speciality_name
                  }
                });
              }
              } else if ("complete" === postData.actionType) {
                console.log(postData);
                if(postData.consultation_fee === "")
                {
               

                  errors.consultation_fee = "The Consultation fees field is required.";
                  this.setState({errors}); return false;

                } else {
                 // this.conformCompleteAction();
                  this.props.history.push({
                    pathname: "/add_prescription/" + postData.checkin_id,
                    data: {
                      business_id: this.props.userdata.id,
                      completion_data: postData,
                      doctor_name:this.state.doctor_name,
                    }
                  });
                }
                
              } 
              else if("setupReminder" === postData.actionType){
                console.log(postData)
                if(postData.follow_up_details === "")
                {
                  errors.follow_up_details = "Please enter followup days";
                  this.setState({errors}); return false;
                } else{
                  
                  let reqdata = {
                    "to" : postData.email,
                    "subject" : "Remainder Email",
                    "mailHeader" : "Hi "+postData.patient_name,
                    "message": "You have an appointment remainder "+postData.follow_up_details+" days from today."
                  }
                  let notificationdata = {
                    "message_code": "FOLLOW_REMINDER",
                    "language_code": "en",
                    "data": {
                      "patient_name": postData.patient_name,
                      "followup_days": postData.follow_up_details,
                                
                    },
                    "receiver": {
                      "phoneNumber":  postData.phone_code.concat(postData.phone_number)
                    }
                  };
                  console.log(notificationdata)
                  console.log(reqdata)
                  if(postData.phone_number){
                    axios
                    .post(sendsms, notificationdata)
                    .then((result) => {
                      console.log(result)
                      successMessage.message="Remainder sent successfully to your registered mobile number.";
                      successMessage.status="1";
                    }).catch((err) => {
                      console.log(err);
                      successMessage = {message: err,status:"0"};
                    })
                  //  this.setState({ successMessage: successMessage });
                /*   toast.success("Remainder sent successfully to your registered mobile number.",{
                    className:"toast-success"
                  }); */

                  }
                  if(postData.email){
                    axios
                    .post(sendemail, reqdata)
                    .then((res) => {
                      let resdata = res.data;
                      // successMessage = {message: "Remainder send successfully to your registered email id",status:"1"};
                      successMessage.message="Remainder sent successfully to your registered email Id.";
                      successMessage.status="1";
                    }).catch((err) => {
                      console.log(err);
                      successMessage = {message: err,status:"0"};
                    })
                  //  this.setState({ successMessage: successMessage });
                /*   toast.success("Remainder sent successfully to your registered email Id.",{
                    className:"toast-success"
                  }); */
                  }
                 
                  toast.success("The Remainder has been sent successfully.",{
                    className:"toast-success"
                  });

                

                this.clearMessage();
              }
            } else 
            {
              this.setState({ errorMessage: result.message || "Failed to submit prescription details", });
              toast.error("Failed to submit prescription details.",{
                className:"toast-error"
              });
            }

            this.setState({ loading: false });
          })
          .catch((e) => {
            this.setState({
              loading: false,
              errorMessage: "Failed to submit consultant details",
            });
          });
      });
    }
  };
  sendPrescriptionmail = (data) => {
    
    if((data.medicine_description !== "" || data.medicine_description !== null) && data.pharmacy_details !== ""){
      let selectedRecord = this.state.selectedRecord;
      // let style = "table,th,td{border: 1px solid #dfdfdf;}";
      let reqdata = { 
        "to" : data.email,
        "subject" : "Prescription for "+selectedRecord.patient_name,
        "mailHeader" : "Prescription for "+selectedRecord.patient_name,
        "message": '<div class="row"><div class="col-md-6" style="text-align:left"><table style="text-align:left;margin-left:auto;" class="billing_table" cellpadding="10%"><tbody><tr style="background-color: #2196f3;"><td>Registration. No.</td><td>'+data.registrationId+'</td></tr><tr style="background-color: #2196f3;"><td>Patient Name</td><td>'+selectedRecord.patient_name+'</td></tr><tr style="background-color: #2196f3;"><td>Hospital Address</td><td>'+ this.props.business_short_detail.business_name + ", " + this.props.business_short_detail.address1
        + ", " + this.props.business_short_detail.city
        + ", " + this.props.business_short_detail.state
        + " - " + this.props.business_short_detail.zip_code+'</td></tr></tbody></table></div></div><table class="table table-bordered table-striped" style="border: 1px solid #dfdfdf; width: 100%;margin-bottom:1rem;color: #495057;" id="as-react-datatable"><thead class=""><tr style="background-color: #2196f3;"><th class="sortable  text-left" style="border: 1px solid #dfdfdf;" width="">Medicine Description</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Days</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Before Food</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Morning</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Noon</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Night</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Total</th></tr></thead>',
      }
      
      data.medicine_description.forEach((element, index) => {
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td class="medicine_description">'+element.medicine_description+'</td><td class="days">'+element.days+'</td><td class="before_food">'+element.before_food+'</td><td class="morning">'+element.morning+'</td><td class="noon">'+element.noon+'</td><td class="night">'+element.night+'</td><td class="total">'+element.total+'</td></tr>';
      });
      reqdata.message += '</tbody></table>';
      axios
      .post(sendemail, reqdata)
      .then((res) => {
        let resdata = res.data;
        console.log(resdata);
        
      }).catch((err) => {
        console.log(err);
        // successMessage = {message: err,status:"0"};
      })
    }
    

  }

  completeAction = (e) => {
    const postData = this.state.selectedRecord;
    this.sendPrescriptionmail(this.state.actionFormData);
// console.log(postData); return false;

    this.conformCompleteAction();
    
    if ("" === postData || null === postData) {
      alert("Please select the record");
    } else {
      postData.business_id = this.props.userdata.id;
      postData.pharmacy_details = this.state.actionFormData.pharmacy_details;

      postData.follow_up_details =
        this.state.actionFormData.follow_up_details;
      postData.consultation_fee = this.state.actionFormData.consultation_fee;
      postData.id = postData.checkin_id;
      if ("finish" === e.target.id) {
        postData.status = "Completed";
      }
      postData.actionType = e.target.id;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      this.setState({ loading: true }, () => {
        axios
          .post(consultDoctor, postData, CONFIG)
          .then((res) => {
            this.setState({ loading: false });
            const result = res.data;
            if (result.status === "1") {
              console.log(result.message);
              let successMessage = {
                message: "The Patient has completed the transaction successfully.",
                status: "1",
              };
              toast.success("The Patient has completed the transaction successfully.",{
                className:"toast-success"
              });
           //   this.setState({ successMessage });
              this.clearActionForm();
              this.clearMessage();
              // if ("print" === postData.actionType) {
              //   this.props.history.push(
              //     "/print_prescription/" + postData.id + "/business"
              //   );
              // } else
              if ("finish" === postData.actionType) {
                // console.log(postData);
                // this.props.history.push({
                //   pathname: "/add_prescription",
                //   data: {
                //     business_id: this.props.userdata.id,
                //     completion_data: postData,
                //  
              }
              //  });
            
            } else {
              this.setState({
                errorMessage:
                  result.message || "Failed to complete the action",
              });
              toast.error("Failed to complete the action.",{
                className:"toast-error"
              });
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
              errorMessage: "Failed to complete the action",
            });
          });
      });
    }
  };
  conformCompleteAction = () => {

    let record = this.state.selectedRecord;
    let data = {};
    data.n_business_id = this.props.userdata.id;
    data.n_business_user_id = "";
    data.n_schedule_day_id = record.schedule_day_id;
    data.n_q_id = record.q_id;
    data.n_status = "Completed";
    data.n_preference = record.preference;
    data.n_savefor = "C";
    console.log(data);
    axios
      .post(updateQueueStatus, data)
      .then((res) => {
        console.log(res);

        this.getQueueDatabyselecteddoctor(this.state.selected_doctor.value,this.state.selected_shift.value)
        this.closePopup();
      })
      .catch((e) => { });

  };

  closePopup = (e) => {
    this.setState({
      cancelModel: false,
    });
  };

  handleInput = (e) => {

    let data = this.state.actionFormData;
    let name = e.target.name;
    let value = e.target.value;
    let errors=this.state.errors;
    if(name==="follow_up_details" || name === "consultation_fee")
     data[name] = value.replace(/[^0-9]/ig,'');
    else data[name] = value;
    this.setState({ actionFormData: data });
    errors.follow_up_details = "";
  };
  printOrder = () => {
    const printableElements = document.getElementById("printme").innerHTML;
    let orderHTML =
      "<html><head><title></title></head><body>" +
      printableElements +
      "</body></html>";
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage;
  };
  handleselectPharmacy = (selectedOptions, e) => {
    let data = this.state.actionFormData;
    data[e.name] = selectedOptions;
    this.setState({ actionFormData: data });
  };

  componentDidMount = () => {

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });


    // this.getData();

    axios
      .post(getMasterPharmacy, {user_id:this.props.userdata.id}, CONFIG)
      .then((res) => {
        console.log(res);
        let pharmacy_array = res.data.data;

        let pharmacyList = [];

        pharmacy_array.forEach((element, index) => {
          pharmacyList.push({
            value: element.id,
            label: element.pharmacy_name,
            email: element.email,
          });
        });

        this.setState({ pharmacy_array: pharmacyList });
      })
      .catch((e) => { });
    this.setState({ loading: true });

    const data = {
      n_business_id: this.props.userdata.id,
      n_currentdate: getDateISOFormat(new Date()),
    };
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getDocByBusiness, data, CONFIG)
      .then((result) => {

        let doc_list = [], doctor_shift = [];
        result.data.data.forEach((element, index) => {
          
          if (element.schedule_type.toLowerCase() !== 'appointment')
            doc_list.push({ value: element.business_user_id, label: element.doctor_name, doctor_Appointments: element.doctor_Appointments });
        });
        if (doc_list.length) {
          doc_list[0].doctor_Appointments.forEach((doc, k) => {
            doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
          })
        }


        this.setState({ doctor_appointment: result.data.data, doc_list: doc_list, selected_doctor: doc_list[0], doctor_shift: doctor_shift, selected_shift: doctor_shift[0], loading: false });
        this.getQueueDatabyselecteddoctor(doc_list[0].value, doctor_shift[0].value);


      })
      .catch((e) => { console.log(e); this.setState({ loading: false }) });

      axios
      .post(getBusinessShortDetail, { business_id: this.props.userdata.id }, CONFIG)
      .then((res) => {
        let response = res.data;
        console.log(response);
        // return false;
        if (response.status !== "0") {
          
          this.setState({ hospital_details: response.data[0],hospital_address:response.data[0].address1,
            business_name:response.data[0].business_name,hospital_city:response.data[0].city,
            hospital_state:response.data[0].state,hospital_zipcode:response.data[0].zip_code,business_name:response.data[0].business_name });
        }
        console.log(this.state.hospital_details);
        console.log(this.state.hospital_address);

      })

      .catch((e) => { });

  };
  
  getData = () => {
    this.setState({ loading: true });
    this.getQueueDatabyselecteddoctor(this.state.selected_doctor.value,this.state.selected_shift.value)
  }
  getQueueDatabyselecteddoctor = (business_user_id, schedule_day_id) => {
    this.setState({ loading: true });
console.log('here')
    if (business_user_id !== "" && schedule_day_id !== "") {
      let reqdata = {
        business_id: this.props.userdata.id,
        business_user_id: business_user_id,
        schedule_day_id: schedule_day_id
      }
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          QueueAssignmentListbySelectedDoctor,
          reqdata,
          CONFIG
        )
        .then((res) => {

          let resdata = res.data.data;
          let queueList = [];
          resdata.forEach((element, index) => {
            let queue = {};
            queue.s_no = index+1;
            queue.queue_no = element.preference;
            queue.patient_id = element.patient_id;
            queue.patient_name = element.first_name + " " + element.last_name;
            queue.dob = element.date_of_birth;
            queue.gender = element.gender;
            queue.blood_group = element.blood_group;
            queue.phone_number = element.phone_number;
            queue.parent_phone_number = element.parent_phone_number;
            queue.phone_code = element.phone_code;
            queue.email = element.email;
            queue.app_time = element.expected_time;
            queue.TokenDate=element.TokenDate;
            queue.TokenTime=element.TokenTime;
            
            queue.wait_time = element.waitTime;
            queue.status = element.status;
            queue.payment_status=element.payment_status;
            queue.checkin_id = element.checkin_id;
            queue.isfee = element.fee;
            queue.isprescription = element.prescription;
            queue.isfollowup = element.followup;
            queue.client_id = element.client_id;
            queue.business_user_id=element.business_user_id;
            queue.id = element.id;
            queue.q_id = element.q_id;
            queue.schedule_day_id = element.schedule_day_id;
            queue.medicine_description = element.medicine_description;
            queue.pharmacy_details = element.pharmacy_details
              ? element.pharmacy_details
              : "";
            queue.consultation_fee = element.consultation_fee
              ? element.consultation_fee
              : "";
            queue.follow_up_details = element.follow_up_details
              ? element.follow_up_details
              : "";
            queueList[index] = queue;
          });

          // let clientmedicinehistory = {};
          this.setState({ queueList: queueList, loading: false });
        })
        .catch((e) => { });
    }
  }
  handleselectChange = (selectedoption, event) => {

    let { doc_list } = this.state;
    let doctor_shift = [];
    if (event.name === "doc_list") {

      doc_list[event.name] = selectedoption


      selectedoption.doctor_Appointments.forEach((doc, k) => {
        doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
      })

      this.setState({ doc_list: doc_list, selected_doctor: selectedoption, doctor_shift: doctor_shift, selected_shift: doctor_shift[0] });
      this.getQueueDatabyselecteddoctor(selectedoption.value, doctor_shift[0].value)
    }
    if (event.name === 'doctor_shift') {

      this.setState({ selected_shift: selectedoption })
      this.getQueueDatabyselecteddoctor(this.state.selected_doctor.value, selectedoption.value)
    }
  }
  render() {

    const {
      headerinfo,
      queueList,
      pharmacy_array,
      actionFormData,
      errorMessage,
      successMessage,
      errors,
      doc_list,
      hospitaltimeout,
      doctor_name
    } = this.state;
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      this.props.history.push({
        pathname: "/showsubscription",
        state: {}
      });
    };
    return (


      <div className="main-content bacfot">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
        <div className="mainpage">
          <section className="my-head">
            <div className="container-fluid">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessHeader user_id={this.props.userdata.id} />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserHeader user_id={this.props.userdata.id} />
              ) : (
                <Header user_id={this.props.userdata.id} />
              )}
            </div>
          </section>
          <div className="main-box">
            <div className="sidemenu ">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessUserSideBar />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserSideBar />
              ) : (
                <ClientSideBar />
              )}
            </div>
            {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

            <section className="main-body hospital-profilegap">
              <div className="container-fluid">
                <div className="checkout-tabs">



                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="font-weight-bold">Queue Management</h4>
                    </div>


                    <div className="col-sm-12">
                      <div className="card">
                      <div class="card-header"> <div class="card-title">Actions</div> </div>
                        <div className="card-body">
                          <div
                            className="tab-content"
                            id="v-pills-tabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="v-pills-shipping"
                              role="tabpanel"
                              aria-labelledby="v-pills-shipping-tab"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                            
                                  <div className="user-notification">
                                      {/* <SpanField
                                        classname={
                                          "0" === addEmployeeSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                        }
                                        title={addEmployeeSuccess.message}
                                      /> */}
                                      {/* <SpanField
                                        classname="text-success"
                                        title={successMessage}
                                      /> */}
                                    </div>
                                  <div className="row queuAssignmentbtn">
                                    <div className="col-sm-12 queue_assignmentmob">
                                      <div className="frllftfull">
                                        <div className=" frllft33">
                                          <label>Registration ID</label>
                                        </div>
                                        <div className="flex">
                                          <div className="form-group frllft37 form-control">
                                            {actionFormData.registrationId}
                                          
                                          </div>
                                          <div className="buttonpage13">
                                            <button
                                            className="s1-1"
                                            onClick={this.clearActionForm}
                                            // onClick={() => this.cancelRecord()}
                                          >
                                            Reset
                                          </button>
                                          </div>
                                        </div>
                                      
                                      </div>
                                      <div className="frllftfull">
                                        <div className=" frllft33">
                                          <label>Followup days</label>
                                        </div>
                                        <div className="flex">
                                        <div className="form-group frllft37">
                                          <div className="">
                                                      <InputField
                                                        inputType={"text"}
                                                        name={
                                                          "follow_up_details"
                                                        }
                                                        controlFunc={
                                                          this.handleInput
                                                        }
                                                        content={
                                                          actionFormData.follow_up_details
                                                        }
                                                        placeholder={"Days"}
                                                      />
                                                      <SpanField classname="text-danger" title={errors.follow_up_details} />
                                            </div>
                                        
                                        </div>
                                        <div className="buttonpage13">
                                              <button
                                                className="s2-1"
                                                onClick={this.performAction}
                                                id="setupReminder"
                                              >
                                                Reminder
                                              </button>
                                        </div>
                                        </div>
                                       
                                      </div>
                                      <div className="frllftfull">
                                        <div className=" frllft33">
                                          <label>Prescription</label>
                                        </div>
                                        <div className="flex">
                                        <div className="form-group sform-control frllft37">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="pharmacy_details"
                                            value={
                                              actionFormData.pharmacy_details
                                            }
                                            onChange={this.handleselectPharmacy}
                                            options={pharmacy_array}
                                            isClearable={true}
                                          />
                                          <SpanField classname="text-danger" title={errors.pharmacy_details} />
                                        </div>
                                        <div className="buttonpage13">
                                        <button
                                        className="s3-1"
                                        onClick={this.performAction}
                                        id="print"
                                      >
                                        Print
                                      </button>
                                        </div>
                                        </div>
                                      
                                      </div>
                                      <div className="frllftfull">
                                        <div className=" frllft33">
                                          <label>Consultation Fee</label>
                                        </div>
                                        <div className="flex">
                                        <div className="form-group frllft37  flex">
                                          <div className="w-100">
                                                      <InputField
                                                        inputType={"text"}
                                                        name={
                                                          "consultation_fee"
                                                        }
                                                        controlFunc={
                                                          this.handleInput
                                                        }
                                                        content={
                                                          actionFormData.consultation_fee
                                                        }
                                                        placeholder={""}
                                                      />
                                                      <SpanField classname="text-danger" title={errors.consultation_fee} />
                                          </div>
                                          <div className="inrDiv queue_assignmentINR">
                                                    <select  disabled className="form-control">
                                                      <option>INR</option>
                                                      <option>INR</option>
                                                      <option>INR</option>
                                                    </select>
                                          </div>
                                        </div>
                                        <div className="buttonpage13 mg-top5">
                                                          <button
                                                          
                                                  className="s4 "
                                                  onClick={this.performAction}
                                                  id="complete"
                                                >
                                                  Billing
                                                </button>
                                                <button
                                              
                                        className="s5 mt-3"
                                        onClick={this.completeAction}
                                        id="finish"
                                      >
                                        Complete
                                        </button>
                                                
                                                </div>
                                        </div>
                                      
                                      </div>
                                      <div className="frllftfull">
                                          <div className=" frllft33">
                                            <label className="visibleHidden">Consultation Fee</label>
                                          </div>
                                          <div className="d-flex justify-content-center ">
                                               
                                                <div className="buttonpage13">
                                                {/* <button
                                                ref="btn"
                                        className="s5"
                                        onClick={this.completeAction}
                                        id="finish"
                                      >
                                        Complete
                                      </button> */}
                                                </div>
                                          </div>
                                      </div>
                                    </div>
                                  

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                  
                      <div className="card">
                        <div className="card-body">
                        <div className="user-notification">
                          <SpanField
                            classname={
                              "0" === successMessage.status
                                ? "text-danger user-notification"
                                : "text-success user-notification"
                            }
                            title={successMessage.message}
                          />
                        <SpanField
                            classname="text-danger user-notification"
                            title={errorMessage}
                          />   
                          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                           </div>
                          <div className="row d-flex justify-content-center mg-right3">
                            {/* <div className="col-lg-4">
                            </div> */}
                        
                            <div className="col-lg-2 col-md-4 col-sm-6 mg-btm1 ">
                              
                              <Select maxMenuHeight={120}
                                className="form-control mt-2 queuedropdown"
                                name="doc_list"

                                onChange={
                                  this.handleselectChange
                                }
                                value={this.state.selected_doctor}
                                options={doc_list}

                              />
                              
                            </div>
                            
                            <div className="col-lg-3 col-md-4 col-sm-6 mg-btm1">
                            
                              <Select maxMenuHeight={120}
                                className="form-control mt-2 queuedropdown "
                                name="doctor_shift"

                                onChange={
                                  this.handleselectChange
                                }
                                value={this.state.selected_shift}
                                options={this.state.doctor_shift}

                              />
                            
                            </div>
                          </div>
                          <div className="tab-content" id="v-pills-tabContent">
                            <button className="float-right btn btn-primary" onClick={this.getData}>Refresh <i className="fa fa-refresh"></i></button>



                            <div
                              className="tab-pane fade show active"
                              id="v-pills-shipping"
                              role="tabpanel"
                              aria-labelledby="v-pills-shipping-tab"
                            >

                              <ReactDatatable
                                config={this.config}
                                records={this.state.queueList}
                                columns={this.columns}
                                className="table table-bordered table-striped kas  mobile_table"
                              />
                            </div>


                            <Modal
                              show={this.state.cancelModel}
                              onHide={this.closePopup}
                              centered={true}
                            >
                              <Modal.Header
                                closeButton
                                className="model-hptd"
                              >
                                <Modal.Title className="h5 model-text-color">
                                  Cancel Appointment
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                  <p>
                                    Are you sure you want to Cancel the appoinment
                                    ?
                                  </p>

                                  <div className="row reverse">
                                    <div className="form-group mb-0 text-right">
                                      <button
                                        className="grnbtn reddg"
                                        onClick={
                                          this.conformCancelAppointment
                                        }
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    &nbsp;
                                    <div className="form-group mb-0 text-right">
                                      <button
                                        className="grnbtn "
                                        onClick={() => {
                                          this.setState({
                                            cancelModel: false,
                                            cancelRecord: {},
                                          });
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Footerad /> */}


                </div>
              </div>

         
            </section>
           )}
                <FooterSection />
          </div>
        </div>
      </div>


    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(QueueAssignment);
