import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Validation from "../../actions/Validation";
import { withRouter } from "react-router-dom";
import {
  getclientbyphonenumber,
  getQEntryByClientId,
  getBusinessById,
  GetQueueDetailsByScheduleDay,
  addCheckinURL,
  addTempClient,
  getDocByBusiness,
  addQueue_modified,
  addFamilyDetails,
  updateQueueStatus,
  UpdateQueueRemove,
  emergencyUrl,
  getMasterSymptoms,
  getSymptomByCategory,
  getTimeoutbycategory,
  updateclientqueuestatus,
  CancelQueue,
  addMedicalrecordspath,

} from "../../config/url.json";
import Label from "../../components/UI/Label";
import {
  getDateISOFormat,
  getPatientProfileColor,
  calculate_age,
} from "../../constants/globalLevelFunctions";

import axios from "axios";
import { CONFIG } from "../../config/data";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import TextArea from "../../components/UI/TextArea";
import { Spinner } from "../../components/UI/Spinner";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import Select from "react-select";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Modal } from "react-bootstrap";
import { ExpirePopup } from "../../components/UI/ExpirePopup";
import {
  blood_group,
  gender,
  checkin_category,
  relationship,
  
} from "../../constants/config/data";
import {clientmedimageconfig} from "../../../src/config/data";
import S3 from "react-aws-s3";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadService from "../Business/UploadFilesservice";
import "bootstrap/dist/css/bootstrap.min.css";
import { element } from "prop-types";
import { Popupinfo } from "../../components/UI/Popupinfo";
import Dnaspinner from "../../components/UI/Dnaspinner";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity

  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


class PatientCheckin extends Component {
  fileObj = [];
    fileArray = [];
  constructor(props) {
    super(props);

    this.searchData = {
      phone_number: "",
      error: "",
    };
    this.clientData = {
      emergency_schedule: [],
      id: "",
      relationship: {},
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      blood_group: "",
      checkin_category: {
        label: "Normal",
        value: "Normal",
      },

      //  checkin_category: checkin_category,
      queue_number: "",
      reason_for_visit: "",
      other_comments: "",
      schedule_day_id: "",
      queue_data: [],

      //patient basic check up
      height: "",
      weight: "",
      bmi: "",
      temperature: "",
      bp: "",
      pulse: "",
      symptoms: "",
    };
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
    
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      selectedSymptoms: [],
      symptoms: [],
      activeIndex: '',
      cloneSymptoms: [],

      symptomCategory: [],
      showexpirePopup: this.props.validity,

      emergency_queue_data: [],
      clientData: Object.assign({}, this.clientData),
      errors: {},
      searchData: this.searchData,
      family_details: [],
      searchSuccess: {
        message: "",
        status: "",
      },
      disable_insert_queue: false,
      is_add_btn: true,
      // queueData: {},
      loading: false,
      // businessdata: [],
      doctor_appointment_detail_list: [],
      selected_doctor_appointment_detail_list: [],
      doctor_list: [],
      selected_doc: {},
      selectedPerson: "",
      patientBasicData: [],
      is_queued: false,
      is_check_in: false,
      expected_time: "",
      cancelModel: false,
      selectedFiles: undefined,
      progressInfos: [],
      message: [],

      fileInfos: [],
      file: [null],
      fileUploaded:false,
      uploadedUser_id:"",
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout = (e) => {

    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");

  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    //this.props.logout();
    this.props.history.push("/");
  }
  }

  searchAndShowSymptom(searchData, index) {

    let data = {};
    data.symptom_category = searchData.label;
    data.user_id = this.props.userdata.id;
    console.log(data.user_id);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(getSymptomByCategory, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        if (result.countOfRecords > 0) {
         let errors=this.state;
          let records = result.data;
          let symptoms = [];
          records.forEach((element) => {
            symptoms.push({
              symptom_name: element.symptom_name,
              id: element.id,
            });
          });
         errors.symptoms="";
          this.setState({
            symptoms: symptoms,
            cloneSymptoms: symptoms,
            // consult: { symptoms: symptoms[0].symptom_name },
            activeIndex: index,
          errors:errors
            // selectedSymptoms: [],
          });
        } else {
          this.setState({
            symptoms: [],
            // consult: { symptoms: [] },
            activeIndex: index,
            // selectedSymptoms: [],
          });
        }
      });
    });
  }
  handleselectDocChange = (selectedOptions, e) => {
    let data = this.state.selected_doc;
    data = selectedOptions;

    this.setState({ selected_doc: data });

    let appointmentDetails = this.state.doctor_appointment_detail_list;
    let selected_appointment = this.state
      .selected_doctor_appointment_detail_list;
    // console.log(selected_appointment)
    appointmentDetails.forEach((element, index) => {
      if (element.business_user_id === selectedOptions.value) {
        selected_appointment = element.doctor_Appointments;
      }
      // else {
      //   selected_appointment = "";
      // }
    });
    this.setState({
      selected_doctor_appointment_detail_list: selected_appointment,
    });
    // console.log(this.state.selected_doctor_appointment_detail_list)
  };
  getPatientDetail = () => {
    // event.preventDefault();

    let searchData = this.state.searchData;
    if (searchData.phone_number === "") {
      searchData.error = "Phone number is required";
      this.setState({ searchData: searchData });
      return;
    }
    if (searchData.phone_number.length !== 10) {
      searchData.error = "Enter a valid phone number";
      this.setState({ searchData: searchData });
      return;
    }


    if (searchData.phone_number !== "" && searchData.phone_number.length === 10) {
      this.setState({ loading: true });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          getclientbyphonenumber,
          { phone_number: searchData.phone_number },
          CONFIG
        )
        .then((result) => {
          this.setState({ loading: false });
          this.handleReset();
          if (result.data.status === "1") {
            let data = result.data.client;
            data.reason_for_visit = "";

            data.queue_data = result.data.queue_data;
            data.gender = { label: data.gender, value: data.gender };
            data.blood_group = {
              label: data.blood_group,
              value: data.blood_group,
            };

            data.date_of_birth = moment(
              data.date_of_birth,
              moment.defaultFormat
            ).toDate();

            if (!data.relationship) {
              data.relationship = {
                label: "self",
                value: "self",
              };
            } else {
              data.relationship = {
                label: data.relationship,
                value: data.relationship,
              };
            }

            let family_details = result.data.family_details;

            searchData.error = "";
            this.setState({
              family_details: family_details,
              searchData: searchData,
              // searchSuccess: result.data,
              is_add_btn: false,
            });
           /*  toast.success("Patient family members listed. ", {
              className: "toast-success"
            }); */
            //By default select the parent profile picture in navigation icon
            let patientBasicData = [];
            patientBasicData.push(data);
            //Family Details

            family_details.map((data, i) => {

              if (data.queue_data.length) {
                data.queue_data = data.queue_data[0];
              }
              patientBasicData.push(data);
            });

            this.setState({
              patientBasicData: patientBasicData,
              selectedPerson: result.data.client.user_id,
            });

            //If queue data is present then show the paitent selected doctor
            let selected_doc = this.state.selected_doc;
            console.log(selected_doc);
            let is_queued = false;
            let is_check_in = false;
            let expected_time = "";
            console.log(data.queue_data);
            if (data.queue_data.length != 0) {
              console.log(data.queue_data);
              is_queued = true;
              is_check_in = data.queue_data.status === "Check-In";
              expected_time = data.queue_data.expected_time;
              let q_doctor_id = data.queue_data.business_user_id;

              this.state.doctor_list.map((data, i) => {
                if (data.value === q_doctor_id) {
                  selected_doc = data;
                }
              });

              data.height = data.queue_data.height;
              //  data.reason_for_visit = data.queue_data.reason_for_visit;
              console.log(data.queue_data);
              data.weight = data.queue_data.weight;
              data.bmi = data.queue_data.bmi;
              data.temperature = data.queue_data.temperature;
              data.bp = data.queue_data.bp;
              data.pulse = data.queue_data.pulse;
              data.queue_number = data.queue_data.preference;
              data.reason_for_visit = data.queue_data.reason_for_visit;
              data.symptoms = data.queue_data.symptoms;
              data.other_comments = data.queue_data.other_comments;

              data.checkin_category = {
                label: data.queue_data.queue_type ? data.queue_data.queue_type : 'Normal',
                value: data.queue_data.queue_type ? data.queue_data.queue_type : 'Normal',
              };



            } else {
              data.checkin_category = {
                label: "Normal",
                value: "Normal",
              };
            }
            console.log(data)
            this.setState({
              clientData: data,
              selected_doc: selected_doc,
              is_queued: is_queued,
              is_check_in: is_check_in,
              expected_time: expected_time,
              selectedPerson: result.data.client.user_id,
            });
            this.getQueueList();
            this.clearMessage();
           
          } else {
            searchData.error = result.data.message;
            this.setState({
              searchData,
              is_add_btn: true,
              clientData: {},
              patientBasicData: [],
              loading: false,
              selectedPerson: "",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          console.log(e.data);
          console.log("business user getbyid failed");
          //          searchData.error = e.data.message;
          this.setState({ loading: false });
        });
    }
  };

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          searchSuccess: { message: "" },
        }),
      5000
    );
  };
  getQueueList = () => {
    console.log(this.state.clientData)
    let clientData = this.state.clientData;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    console.log(this.state.selected_doctor_appointment_detail_list)
    axios
      .post(
        GetQueueDetailsByScheduleDay,
        {
          "n_business_id": this.props.userdata.id,
          "n_schedule_day_id": this.state.selected_doctor_appointment_detail_list ? this.state.selected_doctor_appointment_detail_list[0].schedule_day_id : "",
          "n_business_user_id": this.state.selected_doc ? this.state.selected_doc.value : ""
        }
      )
      .then((response) => {

        let responseData = response.data;
        let emergency_queue_data = [];
        if (responseData.length > 0) {

          responseData.forEach((element, index) => {
            if (element.status === "queue-in" || element.status === "Check-In")
              emergency_queue_data.push({
                label: element.preference,
                value: element.queueID,
                client_id: element.clientID
              });
          });
          let clientData = this.state.clientData;
          clientData.emergency_schedule = emergency_queue_data[0]
          console.log(emergency_queue_data)
          this.setState({
            emergency_queue_data: emergency_queue_data,
            clientData: clientData

          });

        } else {
          this.setState({
            emergency_queue_data: [],
          });
        }
      }).catch((e) => {
        console.log(e);
        this.setState({ loading: false });
      });
  }

  componentDidMount = () => {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      });
    });
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getMasterSymptoms, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let symptomCategory = list.data.data;
        let symptomCategorylist = [];
        symptomCategory.forEach((element) => {
          symptomCategorylist.push({
            value: element.id,
            label: element.symptom_category,
          });
        });
        var result = symptomCategorylist.reduce((unique, o) => {
          if (!unique.some(obj => obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);

        result.push({ label: "Others", value: "0" })
        this.setState({ symptomCategory: result });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    const data = {
      n_business_id: this.props.userdata.id,
      n_currentdate: getDateISOFormat(new Date()),
    };

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getDocByBusiness, data, CONFIG)
      .then((result) => {
        let resData = result.data.data;
        console.log(resData);
        let doctor_appointment_detail_list = [];
        let docList = [];

        resData.forEach((element, index) => {
          if (element.schedule_type === "Queue" || element.schedule_type === "Q") {
            let doctor_obj = {};

            doctor_obj.doc_name = element.doctor_name;
            doctor_obj.business_user_id = element.business_user_id;
            doctor_obj.schedule_day_id = element.schedule_day_id;
            doctor_obj.schedule_id = element.schedule_id;
            doctor_obj.appt_id = element.appt_id;
            doctor_obj.doctor_Appointments = element.doctor_Appointments;

            doctor_appointment_detail_list.push(doctor_obj);
            docList.push({
              value: element.business_user_id,
              label: element.doctor_name,
            });

          }

        });

        this.setState({
          doctor_list: docList,
          doctor_appointment_detail_list: doctor_appointment_detail_list,
        });

        //By default select first doctor
        let selected_doc = docList[0];
        let selected_appointment = [];

        //Based on default doctor selection select corresponding appointment details
        doctor_appointment_detail_list.forEach((element, index) => {
          if (element.business_user_id === docList[0].value) {
            selected_appointment = element.doctor_Appointments;
          }
        });

        this.setState({
          selected_doc: selected_doc,
          selected_doctor_appointment_detail_list: selected_appointment,
        });

        axios
          .post(
            getBusinessById,
            { business_id: this.props.userdata.id },
            CONFIG
          )
          .then((response) => {
            let responseData = response.data;
            if (responseData.status === "1") {
              let data = responseData.data[0];

              this.setState({
                businessdata: data,
              });
              this.setState({ loading: false });
            }
          });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false });
      });

  };
  handlePhoneInput = (event) => {
    event.preventDefault();
    let data = this.state.searchData;
    let errors = this.state.errors;
    data[event.target.name] = event.target.value
      .replace(/\s/g, "")
      .replace(/[^0-9+]+/g, "");
    data.error = "";
    this.setState({ searchData: data, errors });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.clientData;
    let errors = this.state.errors;


    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";

    }
    this.setState({ clientData: data, errors });
  }
  handleInput = (e) => {
    e.preventDefault();
    let data = this.state.clientData;
    let errors = this.state.errors;
    data[e.target.name] = e.target.value;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (e.target.name === 'height' || e.target.name === 'weight') {

      data.bmi = ((data.weight /
        data.height /
        data.height) *
        10000).toFixed(1);

    }

    if (
      name === "first_name" ||
      name === "last_name"
    ) {
      this.validateMaxLength(name, value, max);
    }
     else if (e.target.name === "height" || e.target.name === "weight" || e.target.name === "pulse") {
      data[e.target.name] = e.target.value.replace(/[^0-9]/ig, '');
    }
    else if (e.target.name === "bp") {
      data[e.target.name] = e.target.value.replace(/[^0-9+\-\/\*\(\)]/ig, '');
    }
    else if (e.target.name === "temperature") {
      data[e.target.name] = e.target.value.replace(/[^0-9]^[+-]?\d*(?:[.,]\d*)?$/ig, '');
    }
    else {

      data[name] = value;
      errors[name] = "";
    }
    this.setState({ clientData: data });
  };
  handleDOBChange = (date) => {
    let data = this.state.clientData;
    let errors = this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    data.date_of_birth = offsetDate;
    errors.date_of_birth = "";
    this.setState({ clientData: data, errors });
  };
  handlePatientChange(value, field) {
    let fields = this.state.clientData;
    let errors = this.state.errors;
    if (value === "gender") {
      fields.gender = field;
      errors.gender = "";
      // errors[i].specialization = '';
    } else if (value === "reason_for_visit") {
      fields.reason_for_visit = field;
      errors.reason_for_visit = "";

    } else if (value === "blood_group") {
      fields.blood_group = field;
      errors.blood_group="";
    } else if (value === "emergency_schedule") {
      fields.emergency_schedule = field;
    } else if (value === "checkin_category") {
      fields.checkin_category = field;

      if (field.label === "Normal") {
        this.setState({ disable_insert_queue: false });
      } else {
        this.setState({ disable_insert_queue: true });
      }
      this.getQueueList();
    } else if (value === "disease") {
      fields.disease = field;
      // errors[i].specialization = '';
    } else if (value === "relationship") {
      fields.relationship = field;
      errors.relationship = "";
    }

    this.setState({
      clientData: fields,
    });
  }

  handleReset = () => {
    // let data = this.state.clientData;
    let data = Object.assign({}, this.clientData);
    console.log(data);
    let errors = this.state.errors;
    errors = {};

    this.setState({
      clientData: data,
      selectedPerson: "addNewFamilyPerson",
      family_details: [],
      is_add_btn: true,
      disable_insert_queue: false,
      is_queued: false,
      is_check_in: false,
      expected_time: "",
      errors: errors,
      // symptoms:[],
      reason_for_visit: "",
      selectedSymptoms: [],
      searchSuccess:"",
      selectedFiles:undefined,
    // fileUploaded:false,
     fileObj: [],
     fileArray : [],
     url:"",
    });
  };
  selectCheckin = (details) => {

    let data = this.state.clientData;

    data = {
      first_name: details.first_name,
      last_name: details.last_name,
      phone_number: details.phone_number,
      user_id: details.user_id,
      id: details.id,
      date_of_birth: moment(
        details.date_of_birth,
        moment.defaultFormat
      ).toDate(),
      reason_for_visit: "",
      symptoms: "",
      height: "",
      weight: "",
      bmi: "",
      temperature: "",
      bp: "",
      pulse: "",
      selectedSymptoms: [],
      other_comments: "",
      checkin_category: {
        label: "Normal",
        value: "Normal",
      },
      queue_number: "",
      selectedFiles:undefined,
   //   fileUploaded:false,
      
    };
    
    if ("string" === typeof details.gender) {
      data.gender = {
        label: details.gender,
        value: details.gender,
      };
    } else {
      data.gender = details.gender;
    }

    if ("string" === typeof details.relationship) {
      data.relationship = {
        label: details.relationship,
        value: details.relationship,
      };
    } else {
      data.relationship = details.relationship;
    }

    if ("string" === typeof details.blood_group) {
      data.blood_group = {
        label: details.blood_group,
        value: details.blood_group,
      };
    } else {
      data.blood_group = details.blood_group;
    }

    //If queue data is present then show the paitent selected doctor
    let selected_doc = this.state.selected_doc;
    let is_queued = false;
    let is_check_in = false;
    let expected_time = "";
    if (details.queue_data.length != 0) {
      console.log('iffffffffffffff');
      is_queued = true;
      is_check_in = details.queue_data.status === "Check-In";
      expected_time = details.queue_data.expected_time;
      let q_doctor_id = details.queue_data.business_user_id;

      this.state.doctor_list.map((data, i) => {
        if (data.value === q_doctor_id) {
          selected_doc = data;
        }
      });
      console.log(details.queue_data);

      console.log(details.queue_data.reason_for_visit);
      console.log(details.queue_data.height);
      console.log(details.queue_data.symptoms);
      data.queue_number = details.queue_data.preference;
      data.height = details.queue_data.height ? details.queue_data.height : "";

      data.weight = details.queue_data.weight ? details.queue_data.weight : "";
      data.bmi = details.queue_data.bmi ? details.queue_data.bmi : "";
      data.temperature = details.queue_data.temperature ? details.queue_data.temperature : "";
      data.bp = details.queue_data.bp ? details.queue_data.bp : "";
      data.pulse = details.queue_data.pulse ? details.queue_data.pulse : "";
      data.reason_for_visit = details.queue_data.reason_for_visit;
      data.symptoms = details.queue_data.symptoms;
      data.other_comments = details.queue_data.other_comments;

      data.checkin_category = {
        label: details.queue_data.queue_type ? details.queue_data.queue_type : "Normal",
        value: details.queue_data.queue_type ? details.queue_data.queue_type : "Normal",
      };

      data.queue_data = details.queue_data;

    } else {
      data.queue_data = [];
      console.log('elsessssssssss')
    }

    if (data.checkin_category.value === "Normal" || data.queue_type === null) {
      this.setState({ disable_insert_queue: false });
    } else {
      this.setState({ disable_insert_queue: true });
    }
    // this.getQueueList();

    this.setState({
      clientData: data,
      selected_doc: selected_doc,
      is_queued: is_queued,
      is_check_in: is_check_in,
      expected_time: expected_time,
      // selectedPerson:data.client.user_id,
      is_add_btn: false,
    });
  };

  closePopup = (e) => {
    this.setState({
      cancelModel: false,
    });
  };

  cancelAppointment = (e) => {
    let clientData = this.state.clientData.queue_data;
    console.log(clientData);
    
    if(clientData=[]&& this.state.clientData.queue_data.length == 0)
    {let searchSuccess = {
      message: "No queue has been found.",
      status: "1",
    };
    this.setState({ searchSuccess});
    }
    if(this.state.clientData.queue_data.length !== 0)
    {
    this.setState({
      cancelModel: true,
    });
  }
  };

  conformCancelAppointment = () => {
    console.log(this.state.clientData);
    let clientData = this.state.clientData;
    let queue_data = clientData.queue_data;
    console.log(queue_data);
    let cancelData = {
     // n_business_id: queue_data.business_id,
     // n_business_user_id: queue_data.business_user_id,
     // n_schedule_day_id: queue_data.schedule_day_id,
      q_id: queue_data.q_id,
     // "n_status": "D",
     // "n_schedule_type": "D",
     // "n_preference": 1
    };

    //   {
    //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
    //     "n_schedule_day_id": "17a5fc03-4426-11eb-9047-0ad8e4601dac",   
    //     "n_status": "D",   
    //     "n_business_user_id": "122c2790-4426-11eb-b734-1d4f488af3f3",
    //     "n_schedule_type": "D",
    //     "n_q_id":"dda2c67c-c6e0-4743-9055-0780f7206108",
    //     "n_preference":1
    // }

    console.log(cancelData);
    let req_data = {
                    
      status: 'Cancelled',
      q_id: queue_data.q_id

    }
    axios
    .post(updateclientqueuestatus, req_data, CONFIG)
    .then((res) => {console.log(res)}).catch((e) => {
   
  });
  
  let queuedata = {
    "n_business_id": queue_data.business_id,
    "n_business_user_id": queue_data.business_user_id,
    "n_schedule_day_id": queue_data.schedule_day_id,
    "n_q_id": queue_data.q_id,
    "n_status": "D",
    "n_schedule_type": "D",
    "n_preference": 1
  }
 
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${this.props.access_token}`;
  axios
    .post(UpdateQueueRemove, queuedata)
    .then(() => {
    
    }).catch(() => {
      console.log("Update queue failed");
    }); 
     axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(CancelQueue, cancelData)
      .then((response) => {
        if (response.data.status === "1") {
        
          // let searchSuccess = {
          //   message: "Patient Queue Successfully Cancelled",
          //   status: "1",
          // };
          toast.success("Your Patient queue  has been canceled successfully.", {
            className: "toast-success"
          });
          this.setState({  loading: false, cancelModel: false });
          this.clearMessage();
          this.handleReset();
          this.getPatientDetail();
          //update the check-in patient height, weigth and temperature
          // this.saveCheckInData();
        }
      })
      .catch((e) => {
        this.setState({ loading: false, cancelModel: false });
        alert("Error while update queue status");
      });
  };

  handleCheckin = (event) => {
    //socketObj.emit("queueStatusChanged", () => { });

    event.preventDefault();
    if (this.state.searchData.phone_number === "") {
      return;
    }
    let errors = {};
    if (!this.state.clientData.reason_for_visit)
     {
      errors.reason_for_visit = "Select  the diagnosis field.";
      this.setState({ errors: errors });
       return;
      }
    else {
      errors.reason_for_visit = "";
      this.setState({ errors: errors });
    }
   
    /* if (this.state.clientData.symptoms==""||this.state.clientData.symptoms==null||this.state.clientData.symptoms==undefined) {
      errors.symptoms = "Please choose the symptoms";
      this.setState({ errors: errors });
      return;
    } else {
      errors.symptoms = "";
      this.setState({ errors: errors });
    }
    if (this.state.clientData.timing==""||!this.state.clientData.schedule_day_id)
     {
      errors.timing = "Please choose timings";
      this.setState({ errors: errors });
      return;
    } else {
      errors.timing = "";
      this.setState({ errors: errors });
    } */
    if (!this.state.clientData.other_comments) {
      errors.other_comments = "The Reason for the visit field is required.";
      this.setState({ errors: errors });
      return;
    } else {
      errors.other_comments = "";
      this.setState({ errors: errors });
    }
    if (this.state.clientData.symptoms==""||this.state.clientData.symptoms==null||this.state.clientData.symptoms==undefined||this.state.selectedSymptoms==null) {
      errors.symptoms = "Select  the Symptoms field.";
      this.setState({ errors: errors });
      return;
    } else {
      errors.symptoms = "";
      this.setState({ errors: errors });
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let q_status = "";
    if (this.state.clientData.queue_data.length != 0) {
      q_status = this.state.clientData.queue_data.status || this.state.clientData.queue_data[0].status;
    }
    console.log(this.state.clientData.user_id)
    //Patient already booked queue so update the status to check-in
    if ("queue-in" === q_status) {
      this.setState({ loading: true });
      let updateQData = {};
      updateQData = this.state.clientData.queue_data || this.state.clientData.queue_data[0];
      let selectedClientid = [];
      selectedClientid.push(this.state.clientData.user_id);
      updateQData.clientList = selectedClientid;
      updateQData.status = "Check-In";

      updateQData.is_active = "Yes";
      console.log(updateQData);
      console.log(this.state.clientData);
      let updatedata = {};
      let url = "";

      if (this.state.clientData.checkin_category.value === "Normal") {
        updatedata = {
          n_business_id: updateQData.business_id,
          n_business_user_id: updateQData.business_user_id,
          n_schedule_day_id: updateQData.schedule_day_id,
          n_q_id: updateQData.q_id,
          n_status: "Check-In",
          n_schedule_type: "Queue",
          n_preference: updateQData.preference,
          n_savedtime: moment().format('YYYY-MM-DD HH:mm:ss'),
        };

        //   {
        //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
        //     "n_schedule_day_id": "f4f392a4-bdc8-11eb-97e3-0ad8e4601dac",   
        //     "n_status": "Check-In",   
        //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
        //     "n_schedule_type": "Queue",
        //     "n_q_id":"8c9d1193-aa87-4907-bb5b-96c6e3e441bd"
        // }

        console.log(updateQData, updatedata);
        // return false;
        url = updateQueueStatus;

        this.saveCheckInData();
      }
      else {
        // console.log(updateQData)
        updatedata = {
          n_business_id: updateQData.business_id,
          n_business_user_id: updateQData.business_user_id,
          n_schedule_day_id: updateQData.schedule_day_id,
          n_q_id: this.state.clientData.emergency_schedule.value,
          "n_schedule_type": "ER",
          n_client_id: this.state.clientData.user_id

        };

        //   {
        //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
        //     "n_schedule_day_id": "f7ce8742-987e-11eb-97e3-0ad8e4601dac",
        //     "n_business_user_id": "6f95d620-9703-11eb-b7ee-8f72d424d76f",
        //     "schedule_type": "ER",
        //     "n_q_id":"f9911136-a4bc-41d0-9591-d7b6f19d8d56"
        // }

        url = emergencyUrl;
      }
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(url, updatedata)
        .then((response) => {
          this.setState({ loading: false });
          let data = this.state.clientData;
          let queue_data = {
            q_id: response.data.qId,
            status: "Check-In",
          };
          data.queue_data = [queue_data];
console.log(data.queue_data);
          this.setState({
            clientData: data,
          });
          //update the check-in patient height, weigth and temperature
          //  this.saveCheckInData();
        })
        .catch((e) => {
          this.setState({ loading: false });
          alert("Error while update queue status");
        });
    } else {
      //create new queue & along with checkin details
      this.createQueueSaveCheckIn();
    }
  };

  createQueueSaveCheckIn = () => {
    let errors = {};
    console.log(this.state.clientData)
    let clientData = this.state.clientData;
    if (!clientData.schedule_day_id) {
      console.log('jo');
      errors.timing = "Select  the timing field.";
      this.setState({ errors: errors });
      return;
    }else {
      errors.timing = "";
      this.setState({ errors: errors });
    }

    let createQData = {};
    // createQData = this.state.clientData;

    this.getQueueList();

    createQData.n_client_id = clientData.user_id;
    createQData.clientList = [clientData.user_id];
    createQData.n_business_id = this.props.userdata.id;
    createQData.n_status = "Check-In";
    createQData.n_isActive = "yes";
    createQData.n_savedtime = moment().format('YYYY-MM-DD HH:mm:ss');
    createQData.schedule_type = "Queue";
    createQData.n_schedule_day_id = clientData.schedule_day_id;
    if (this.state.selected_doc) {
      createQData.n_business_user_id = this.state.selected_doc.value;
    } else {
      createQData.n_business_user_id = "";
    }
    if (clientData.checkin_category.label === "Normal") {
      createQData.type = "createQueue";

      axios
        .post(addQueue_modified, createQData, CONFIG)
        .then((response) => {
          let result = response.data.data[0];
          console.log(result);
          if (result.status === "100") {
            let data = clientData;
            let queue_data = {
              q_id: result.qId,
              status: "Check-In",
            };
            data.queue_data = [queue_data];

            this.setState({
              clientData: data,
            });

            this.saveCheckInData();
          } else {
            alert(result.message);
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          alert("Error while creating new queue");
        });
    } else {


      clientData = this.state.clientData;
      console.log('hi');
      console.log(clientData.checkin_category)
      if (!clientData.emergency_schedule && clientData.checkin_category.label === "Emergency") {
        errors.emergency_schedule = "Please choose a Queue for emergency";
        this.setState({ errors: errors });
        return;
      }
      let updatedata = {
        n_business_id: this.props.userdata.id,
        n_business_user_id: clientData.business_user_id,
        n_schedule_day_id: clientData.schedule_day_id,
        n_q_id: clientData.emergency_schedule ? clientData.emergency_schedule.value : "",
        "n_schedule_type": "ER",
        n_client_id: clientData.emergency_schedule ? this.state.clientData.user_id : ""

      };
      console.log(updatedata)

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(emergencyUrl, updatedata)
        .then((response) => {
          this.setState({ loading: false });
          console.log(response.data);
          console.log(response.data.data[0].qId);
          let data = clientData;
          let queue_data = {
            q_id: response.data.data[0].qId,
            status: "Check-In",
          };
          data.queue_data = [queue_data];
          this.setState({
            clientData: data,
          });

          //update the check-in patient height, weigth and temperature
       //   this.saveCheckInData();
     
          let Checkindata = {
            business_id: this.props.userdata.id,
            business_user_id:this.state.selected_doc.value,
            client_id: clientData.user_id,
            reason_for_visit: clientData.reason_for_visit,
            other_comments: clientData.other_comments,
            queue_type:
              !clientData.checkin_category && !clientData.checkin_category.value
                ? ""
                : clientData.checkin_category.value,
            queue_number: clientData.preference,
            height: clientData.height,
            weight: clientData.weight,
            bmi: clientData.bmi,
            temperature: clientData.temperature,
            bp: clientData.bp,
            pulse: clientData.pulse,
            q_id: response.data.data[0].qId,
            symptoms: clientData.symptoms
          };
          console.log(Checkindata);
          axios
            .post(addCheckinURL, Checkindata, CONFIG)
            .then((result) => {
    
              if (result.data.status === "1") {
                // let searchSuccess = {
                //   message: "Patient Check-In details added successfully",
                //   status: "1",
                // };
                toast.success("Your Patient has been checked in successfully.", {
                  className: "toast-success"
                });
                Checkindata.status = "Check-In";
    
                //Load chekin details
                let clientData = this.state.clientData;
                clientData.queue_data = Checkindata;
                let is_queued = true;
                let is_check_in = true;
                let selected_doc = this.state.selected_doc;
                let expected_time = this.state.expected_time;
                let selectedPerson = this.state.selectedPerson;
                selectedPerson = clientData.user_id
                console.log(expected_time);
                console.log(clientData)
                console.log(Checkindata);
                console.log(clientData.user_id);
                // let patientBasicData = this.state.patientBasicData;
                // patientBasicData.queue_data=Checkindata;
                // if(patientBasicData.queue_data!=0)
                // {
                //   console.log("test");
                // }
                this.setState({
                  // searchSuccess,
                  loading: false,
                  clientData,
                  selectedPerson: clientData.user_id,
                  is_check_in: is_check_in,
                  is_queued: is_queued,
                  selected_doc: selected_doc,
                  expected_time: expected_time,
    
                });
                this.clearMessage();
            //    this.getPatientDetail();
                this.reloadPage();
              }
            })

        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
          alert("Error while update queue status1");
        });
    }

  };
  saveCheckInData = () => {
    let clientData = this.state.clientData;
    console.log(clientData)
    if (clientData.user_id !== "") {
      this.setState({ loading: true });
      let Checkindata = {
        business_id: this.props.userdata.id,
        business_user_id:this.state.selected_doc.value,
        client_id: clientData.user_id,
        reason_for_visit: clientData.reason_for_visit,
        other_comments: clientData.other_comments,
        queue_type:
          !clientData.checkin_category && !clientData.checkin_category.value
            ? ""
            : clientData.checkin_category.value,
        queue_number: clientData.queue_number,
        height: clientData.height,
        weight: clientData.weight,
        bmi: clientData.bmi,
        temperature: clientData.temperature,
        bp: clientData.bp,
        pulse: clientData.pulse,
        q_id: clientData.queue_data.q_id || clientData.queue_data[0].q_id,
        symptoms: clientData.symptoms
      };
      console.log(Checkindata);
      axios
        .post(addCheckinURL, Checkindata, CONFIG)
        .then((result) => {

          if (result.data.status === "1") {
            // let searchSuccess = {
            //   message: "Patient Check-In details added successfully",
            //   status: "1",
            // };
            toast.success("Your Patient has been checked in successfully.", {
              className: "toast-success"
            });
            Checkindata.status = "Check-In";

            //Load chekin details
            let clientData = this.state.clientData;
            clientData.queue_data = Checkindata;
            let is_queued = true;
            let is_check_in = true;
            let selected_doc = this.state.selected_doc;
            let expected_time = this.state.expected_time;
            let selectedPerson = this.state.selectedPerson;
            selectedPerson = clientData.user_id
            console.log(expected_time);
            console.log(clientData)
            console.log(Checkindata);
            console.log(clientData.user_id);
            // let patientBasicData = this.state.patientBasicData;
            // patientBasicData.queue_data=Checkindata;
            // if(patientBasicData.queue_data!=0)
            // {
            //   console.log("test");
            // }
            this.setState({
              // searchSuccess,
              loading: false,
              clientData,
              selectedPerson: clientData.user_id,
              is_check_in: is_check_in,
              is_queued: is_queued,
              selected_doc: selected_doc,
              expected_time: expected_time,

            });
            this.clearMessage();
        //    this.getPatientDetail();
            this.reloadPage();
          }
        })
        .catch((e) => {
          let searchSuccess = {
            message: e.data,
            status: "0",
          };
          this.setState({ searchSuccess, loading: false });
          this.clearMessage();
        });
    }
  };

  old = () => {
    axios
      .post(
        getQEntryByClientId,
        { client_id: this.state.clientData.user_id },
        CONFIG
      )
      .then((result) => {
        if (result.data.status === "1") {
          let queuein_data = result.data.data[0];
          // scheduleArr.forEach((element, index) => {
          queuein_data.schedule_day_id = result.data.data[0].schedule_day_id;
          // });

          let selectedClientid = [];
          selectedClientid.push(this.state.clientData.user_id);
          queuein_data.clientList = selectedClientid;
          queuein_data.status = "Check-In";
          queuein_data.is_active = "Yes";
          let updatedata = {
            n_business_id: queuein_data.business_id,
            n_business_user_id: queuein_data.business_user_id,
            n_schedule_day_id: queuein_data.schedule_day_id,
            n_q_id: queuein_data.q_id,
            n_status: "Check-In",
            n_savefor: "C",
          };
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .put(updateQueueStatus, updatedata)
            .then((response) => {

              let clientData = this.state.clientData;
              console.log(clientData);
              if (clientData.user_id !== "") {
                let Checkindata = {
                  business_id: this.props.userdata.id,
                  business_user_id:this.state.selected_doc.value,
                  client_id: clientData.user_id,
                  reason_for_visit: clientData.reason_for_visit,
                  other_comments: clientData.other_comments,
                  queue_type: clientData.queue_type,
                  queue_number: clientData.queue_number,
                  height: clientData.height,
                  weight: clientData.weight,
                  bmi: clientData.bmi,
                  temperature: clientData.temperature,
                  bp: clientData.bp,
                  pulse: clientData.pulse,
                  q_id: clientData.q_id,
                  symptoms: clientData.symptoms,
                };
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;

                axios
                  .post(addCheckinURL, Checkindata, CONFIG)
                  .then((result) => {
                  //  console.log("jeraldinbooksecond");
                    console.log(Checkindata);

                    if (result.data.status === "1") {
                      // let searchSuccess = {
                      //   message: "Patient Check-In details added successfully",
                      //   status: "1",
                      // };
                      toast.success("Your Patient has been checked in successfully.", {
                        className: "toast-success"
                      });
                      this.setState({  loading: false });
                      this.clearMessage();
                      this.handleReset();
                      // this.saveCheckInData();
                    }
                  })
                  .catch((e) => {
                    let searchSuccess = {
                      message: e.data,
                      status: "0",
                    };
                    this.setState({ searchSuccess, loading: false });
                    this.clearMessage();
                  });
              }
              this.clearMessage();
              // }
            })
            .catch((e) => {
              let searchSuccess = {
                message: e.data,
                status: "0",
              };
              this.setState({ searchSuccess, loading: false });
            });
          window.setTimeout(() => {
            this.setState({ queuein_data });
          }, 1000);
        } else {
          let queuein_data = this.state.queuein_data;

          let value = this.state.businessdata;

          // queuein_data.business_name = value.business_name;
          // queuein_data.address = value.address1 + " " + value.city;
          // queuein_data.expected_wait_time = value.expected_wait_time;
          // queuein_data.businessId = value.user_id;
          // queuein_data.expected_wait_time = value.wait_time;

          if (value.user_id !== "") {
            let { queuein_data, errors } = this.state;
            // let listofClientForQueue = this.state.listofClientForQueue;

            //Need to change from string to array after fix the queue issue
            // queuein_data.n_client_id = listofClientForQueue;
            queuein_data.n_client_id = this.state.clientData.user_id;
            queuein_data.clientList = [this.state.clientData.user_id];
            queuein_data.n_business_id = this.props.userdata.id;
            queuein_data.n_status = "queue-in";
            queuein_data.n_isActive = "yes";
            queuein_data.n_savedtime = new Date();
            if (this.state.selected_doc) {
              queuein_data.n_business_user_id = this.state.selected_doc.value;
            } else {
              queuein_data.n_business_user_id = "";
            }
            queuein_data.type = "createQueue";

            this.setState({ queuein_data: queuein_data, loading: false });

            errors = Validation(queuein_data, errors);
            this.setState({ errors: errors });

            //  socketObj.emit("queueStatusChanged", () => { });

            if (errors.formIsValid) {
              this.setState({ loading: true });
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.props.access_token}`;

              axios
                .post(addQueue_modified, this.state.queuein_data, CONFIG)

                .then((response) => {
                  console.log(this.state.queuein_data);
                  let result = response.data;
                  if (result.status === 1) {
                    let clientData = this.state.clientData;

                    if (clientData.user_id !== "") {
                      let Checkindata = {
                        business_id: this.props.userdata.id,
                        business_user_id:this.state.selected_doc.value,
                        client_id: clientData.user_id,
                        reason_for_visit: clientData.reason_for_visit,
                        other_comments: clientData.other_comments,
                        queue_type: clientData.queue_type,
                        queue_number: clientData.queue_number,
                        height: clientData.height,
                        weight: clientData.weight,
                        bmi: clientData.bmi,
                        temperature: clientData.temperature,
                        bp: clientData.bp,
                        pulse: clientData.pulse,
                        q_id: clientData.q_id,
                        symptoms: clientData.symptoms,
                      };
                      axios.defaults.headers.common[
                        "Authorization"
                      ] = `Bearer ${this.props.access_token}`;

                      axios
                        .post(addCheckinURL, Checkindata, CONFIG)
                        .then((result) => {
                          if (result.data.status === "1") {
                            // let searchSuccess = {
                            //   message:
                            //     "Patient Check-In details added successfully",
                            //   status: "1",
                            // };
                            toast.success("Patient Check-In details added successfully.", {
                              className: "toast-success"
                            });
                            this.setState({  loading: false });
                            this.clearMessage();
                            this.handleReset();
                            this.saveCheckInData();
                          }
                        })
                        .catch((e) => {
                          let searchSuccess = {
                            message: e.data,
                            status: "0",
                          };
                          this.setState({ searchSuccess, loading: false });
                          this.clearMessage();
                        });
                    }
                    this.clearMessage();
                  } else {
                    let errors = this.state.errors;
                    this.setState({ loading: false });
                    errors.message = result.message;
                    this.setState({ errors: errors });
                  }
                })
                .catch((e) => {
                  this.setState({ loading: false });
                });
            }
          }

          window.setTimeout(() => {
            this.setState({ queuein_data });
          }, 1000);
        }
      })
      .catch((e) => {
        console.log(e.data);
        console.log("business user getbyid failed");
        this.setState({ loading: false });
      });
  };

  addFamilyDetails = () => {
    // console.log(this.state.patientBasicData[0]);
    // console.log(this.state.patientBasicData[0].user_id);
    let parent_user_id = this.state.patientBasicData[0].user_id;
    let data = {
      user_id: parent_user_id,
      first_name: this.state.clientData.first_name,
      last_name: this.state.clientData.last_name,
      gender: this.state.clientData.gender.value
        ? this.state.clientData.gender.value
        : "",
      date_of_birth: this.state.clientData.date_of_birth,
      blood_group: this.state.clientData.blood_group.value
        ? this.state.clientData.blood_group.value
        : "",
      relationship: this.state.clientData.relationship.value
        ? this.state.clientData.relationship.value
        : "",
      type: "AddFamilyDetails",

    };

    let { errors } = this.state;
    errors = Validation(data, errors);
    this.setState({
      errors: errors,
    });

    if (errors.formIsValid) {
      this.setState({ loading: true });
      axios
        .post(addFamilyDetails, data, CONFIG)
        .then((result) => {
          console.log(result);
          console.log(result.data.data.id);
          this.setState({ loading: false, 
          //  searchSuccess: result.data 
          });
          toast.success("New family member added. ", {
            className: "toast-success"
          });
          if (result.data.status === "1") {
            //add new family details to navigation profile menu
            let patientBasicData = this.state.patientBasicData;
            patientBasicData.id = result.data.data.id;
            console.log(patientBasicData.id);
            data.id = result.data.data.id;
            console.log(data.id);
            data.user_id = result.data.data.user_id;
            console.log(data.user_id);
            data.queue_data = [];
            patientBasicData.push(data);
            let clientData = this.state.clientData;
            clientData.id = result.data.data.id;
            clientData.user_id = result.data.data.user_id;
            this.setState({
              patientBasicData: patientBasicData,
              id: result.data.data.id,
              selectedPerson: result.data.data.user_id,
              is_add_btn: false,
            });
            //  this.getPatientDetail();

          } else {
            alert("Error while adding new family members " + result.message);
          }
        })
        .catch((e) => {
          console.log(e.data);
          console.log("business user getbyid failed", e);
          this.setState({ loading: false });
        });
    }
  };

  addPatient = (e) => {
    e.preventDefault();
    console.log(this.state.selectedPerson);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    if (this.state.selectedPerson === "addNewFamilyPerson") {
      this.addFamilyDetails();
    } else {
      let data = {
        first_name: this.state.clientData.first_name,
        last_name: this.state.clientData.last_name,
        date_of_birth: this.state.clientData.date_of_birth,
        phone_number: this.state.searchData.phone_number,
        gender:
          this.state.clientData.gender && this.state.clientData.gender.value
            ? this.state.clientData.gender.value
            : "",
        relationship:
          this.state.clientData.relationship &&
            this.state.clientData.relationship.value
            ? this.state.clientData.relationship.value
            : "",
        type: "AddTempClient",
      };
      let { errors, searchData } = this.state;
      errors = Validation(data, errors);
      searchData.error = errors.phone_number;

      this.setState({
        errors: errors,
        searchData: searchData,
      });

      console.log(this.state.searchData);

      if (!errors.formIsValid) {
        return;
      }
      this.setState({ loading: true });
      axios
        .post(addTempClient, data, CONFIG)
        .then((result) => {
          this.setState({ loading: false });
          if (result.data.status === "1") {
            console.log(result);

            let clientData = this.state.clientData;

            clientData.user_id = result.data.user.user_id;
            clientData.id = result.data.user.id;
            clientData.queue_data = [];
            this.setState({ is_add_btn: true, clientData: clientData });

            let patientBasicData = [];
            patientBasicData.push(clientData);

            this.setState({
              selectedPerson: result.data.user.user_id,
              patientBasicData: patientBasicData,
              is_add_btn: false,
            });
          }
        })
        .catch((e) => {
          console.log(e.data);
          console.log("business user getbyid failed");
        });
    }
  };
  handleTimingSelect = (event) => {
    let data = this.state.clientData;
    data.schedule_day_id = event.target.value;
    this.setState({ clientData: data });
  };

  reloadPage = () => {
    this.handleReset();
    let patientBasicData = this.state.patientBasicData;
    patientBasicData = [];

    let searchData = this.state.searchData;
    searchData = { phone_number: "", errors: "" };

    let selectedPerson = this.state.selectedPerson;
    selectedPerson = "";

    this.setState({
      patientBasicData: patientBasicData,
      selectedPerson: selectedPerson,
      searchData: searchData,
      selectedFiles:undefined,
      fileObj: [],
      fileArray : [],
      file:""
    });
   
  };

  handleChange = (event, newValue) => {
    this.handleReset();
    this.setState({
      selectedPerson: newValue,
    });
    console.log(newValue);
    if ("addNewFamilyPerson" !== newValue) {
      let detail = this.state.patientBasicData.filter((obj) => obj.user_id === newValue);
      console.log(detail[0]);
      this.selectCheckin(detail[0]);
    }
    if(document.getElementById("uploadfile").value!="")
    {
    document.getElementById("uploadfile").value = "";
    }
  };
  handleSymptomSearch = (e) => {
    let cloneSymptoms = this.state.cloneSymptoms;
    if (null == e.target.value || "" === e.target.value) {
      this.setState({
        symptoms: cloneSymptoms
      });
    } else {
      //let symptoms = this.state.symptoms;
      const filteredSymptopms = cloneSymptoms.filter(symptom => {
        return symptom.symptom_name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      this.setState({
        symptoms: filteredSymptopms
      });
    }


  };
  
  selectSymptom = (symptom, e) => {
    const options = this.state.selectedSymptoms;
    let errors=this.state.errors;
    errors.symptoms="";
    let index;
    
    // check if the check box is checked or unchecked
    if (e.target.checked) {
     
      // add the numerical value of the checkbox to options array
      options.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(e.target.value);
      options.splice(index, 1);
    }
    let selectedSymptoms = [];



    options.forEach((element, index) => {

      selectedSymptoms.push({
        value: element,
        label: element,
      });
    });
   
    this.setState({ selectedSymptoms: options });

    let consult = this.state.clientData;

    consult.symptoms = selectedSymptoms;
  
    this.setState({
      clientData: consult,
     
    });
   
  };
  uploadFiles(event) {
      
  }

  
  selectFiles(event) {
   /*  this.setState({
      progressInfos: [],
      selectedFiles: event.target.files,
    }); */
   
    let selectedFiles= event.target.files;
    this.fileObj.push(selectedFiles);
   /*  const MAX_LENGTH = 11;
    if (Array.from(event.target.files).length > MAX_LENGTH) {
      event.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    } */
    console.log(this.fileObj[0].length);
    if(this.fileObj[0].length>=11)
    {
      alert('exceeds');
    }else{
     // let a=this.fileObj[0].length;
     
    for (let i = 0; i < this.fileObj[0].length; i++) {
      
    //  console.log(this.fileObj[0][i]);
      //  this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
     
        let file = event.target.files[i];
       // console.log(event.target.files[i]);
        let newfileName = event.target.files[i].name;
        console.log(newfileName);
    let uploadedUser_id=this.state.selectedPerson;
        // console.log(imageconfig);
        clientmedimageconfig.dirName = 'client_previous_medicalrecords/' + uploadedUser_id
        const reacts3client = new S3(clientmedimageconfig);
        console.log(reacts3client)
        reacts3client.uploadFile(file, newfileName).then(data => {
          console.log(data);
        
          console.log(data.location);
          if (data.status === 204) {
            console.log('success');
           
            let resultdata = {
              'client_id': this.state.clientData.user_id,
              'business_id':this.props.userdata.id,
              'image_url': data.location,
                          }
             axios.defaults.headers.common[
               "Authorization"
             ] = `Bearer ${this.props.access_token}`;
             axios.post(addMedicalrecordspath, resultdata, CONFIG).then(() => {
                let searchSuccess = {
             message: "Medical records uploaded successfully",
             status: "1",
             };
              this.setState({ searchSuccess ,uploadedUser_id });

            })
           
         } else {
            console.log('error');
         //   this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
          }
        });
    }
    
   // this.setState({ file: this.fileArray })
  }
  }
  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        this.setState((prev) => {
          let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
          return {
            message: nextMessage
          };
        });

        return UploadService.getFiles();
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
          return {
            progressInfos: _progressInfos,
            message: nextMessage
          };
        });
      });
  }


  render() {
    const { searchSuccess, errors, selected_doc, doctor_list, emergency_queue_data, symptomCategory, activeIndex, hospitaltimeout,selectedFiles, progressInfos, message, fileInfos,uploadedUser_id,loading } = this.state;
    let disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];
    let disease = [];
    disease_array.forEach((element, index) => {
      disease.push({
        label: element.disease_name,
        value: element.disease_name,
      });
    });
    disease_array = disease;
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
     let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      window.location.href = "/showsubscription"
      // this.props.history.push({
      //   pathname: "/showsubscription",
      //   state: {}
      // });
    };
    return (
      <div className="container-fluid">
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
      {/*   <Spinner loading={this.state.loading} /> */}
      {loading && <Dnaspinner />}
        {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
        <div className="row checkout-tabs">

          <div className="col-sm-12">

            <h4 className="font-weight-bold">Hospital Details</h4>

            <div className="card mt-4">
              <form>
                <div className="card-body pad-btm0">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-shipping"
                      role="tabpanel"
                      aria-labelledby="v-pills-shipping-tab"
                    >
                      <div className="row">
                        <div className="user-notification">
                          <SpanField
                            classname={
                              0 === searchSuccess.status
                                ? "text-danger user-notification"
                                : "text-success user-notification"
                            }
                            title={searchSuccess.message}
                          />
                          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={1000} closeOnClick={false} pauseOnHover />

                        </div>
                        <div className="col-sm-2">
                          <div className="required">
                            <label className="f12">
                              <img
                                alt=""
                                src={require("../../assets/images/phone.png")}
                                className="imgsfd"
                              />{" "}
                              Mobile number 
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-3 ">
                          <div className="form-group">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"phone_number"}
                                controlFunc={this.handlePhoneInput}
                                content={this.state.searchData.phone_number}
                                placeholder={""}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={this.state.searchData.error}
                            />

                          </div>
                        </div>
                        <div className="col-sm-3 bm-9 ">
                          <div className="form-group ">
                            <div className="row">
                              <table className="tablebtn">
                                <tbody>
                                  <tr>
                                    <td>
                                      <button
                                        type="button"
                                        className="signupbtn abtn"
                                        onClick={this.getPatientDetail}
                                      >
                                        Search
                                      </button>
                                    </td>
                                    &nbsp;
                                    <td>
                                      <button
                                        type="button"
                                        className="s1"
                                        onClick={this.reloadPage}
                                      >
                                        Reset
                                      </button>
                                    </td>
                                    &nbsp;
                                    <td>
                                      {this.state.is_add_btn ? null : (
                                        <button
                                          type="button"
                                          onClick={this.cancelAppointment}
                                          className=" s3"
                                        >
                                          Cancel
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>


              {Object.keys(this.state.patientBasicData).length === 0 ? (
                ""
              ) : (
                <div className="card-body pad-btm0">
                    <div className="">
                    
                  <BottomNavigation

                    style={{ paddingBottom: 25 + "px" }}
                    className="BottomNavigation"
                    value={this.state.selectedPerson}
                    showLabels
                    onChange={this.handleChange}
                  >
                    {this.state.patientBasicData.map((data, i) => {
                      return (
                        <BottomNavigationAction
                          type="button"
                          className={"signupbtn2 btnview  mr-20 span " + getPatientProfileColor(data.queue_data)}
                          label={data.first_name + " . " + data.last_name}
                          value={data.user_id}
                          title={data.first_name + " . " + data.last_name}
                          icon={
                            data.queue_data.length != 0 ? (
                              <PersonIcon
                                fontSize={"small"}
                                htmlColor={getPatientProfileColor(data.queue_data)}
                              />
                            ) : (
                              <PersonIcon fontSize={"small"} />
                            )
                          }

                        />

                      );
                    })}


                    <BottomNavigationAction
                      label="Add New" className="addPersonBtn"
                      value="addNewFamilyPerson"
                      icon={<PersonAddIcon fontSize={"small"} />}
                    />
                  </BottomNavigation>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 pr0">
            <div className="card ">
            <div class="card-header"> <div class="card-title">Patient Details</div> </div>
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                      

                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Registration ID</label>
                          </div>
                          <div className="form-group frlrclfgf ">
                            <InputField
                              inputType={"text"}
                              name={"id"}
                              controlFunc={this.handleInput}
                              content={this.state.clientData.id}
                              placeholder={""}
                              isvisible={true}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Relationship</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            {this.state.clientData.relationship &&
                              this.state.clientData.relationship.label ===
                              "self" ? (
                              <InputField
                                inputType={"text"}
                                name={"relationship"}
                                //controlFunc={this.handleInput}
                                content={
                                  this.state.clientData.relationship.label
                                }
                                placeholder={""}
                                isvisible={true}
                              />
                            ) : (
                              <Select maxMenuHeight={120}
                                className="form-control p-0"
                                name="relationship"
                                value={this.state.clientData.relationship}
                                onChange={this.handlePatientChange.bind(
                                  this,
                                  "relationship"
                                )}
                                options={relationship}
                              />
                            )}
                            <SpanField
                              classname="text-danger"
                              title={errors.relationship}
                            />
                          </div>
                        </div>

                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Date of Birth</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <DatePicker
                              className="form-control"
                              disabled={!this.state.is_add_btn?true:false}
                              value={this.state.clientData.date_of_birth}
                              format="dd-MM-yyyy"
                              onChange={(date) => this.handleDOBChange(date)}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.date_of_birth}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Age</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"age"}
                              disabled={true}
                              //controlFunc={this.handleInput}
                              content={calculate_age(
                                this.state.clientData.date_of_birth
                              )}
                              placeholder={""}
                              isvisible={true}
                            />
                          </div>
                        </div>

                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>First Name</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"first_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                              content={this.state.clientData.first_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.first_name}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Last Name</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"last_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                              content={this.state.clientData.last_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.last_name}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Gender</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="gender"
                              isDisabled={!this.state.is_add_btn?true:false}
                              value={this.state.clientData.gender}
                              onChange={this.handlePatientChange.bind(
                                this,
                                "gender"
                              )}
                              options={gender}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.gender}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Blood Group</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            <Select maxMenuHeight={120}
                              className="form-control p-0"
                              isDisabled={!this.state.is_add_btn?true:false}
                              name="blood_group"
                              value={this.state.clientData.blood_group}
                              onChange={this.handlePatientChange.bind(
                                this,
                                "blood_group"
                              )}
                              options={blood_group}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.blood_group}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft">
                            <label>Category</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="checkin_category"
                              value={checkin_category.filter(
                                (option) => option.label === "Normal"
                              ) && this.state.clientData.checkin_category}
                              onChange={this.handlePatientChange.bind(
                                this,
                                "checkin_category"
                              )}
                              options={checkin_category}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label className="terd">Insert Queue</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            {this.state.clientData.checkin_category && this.state.clientData.checkin_category.label === 'Normal' ? (<InputField
                              inputType={"number"}
                              name={"queue_number"}
                              controlFunc={this.handleInput}
                              content={this.state.clientData.queue_number}
                              placeholder={""}
                              isvisible={
                                this.state.disable_insert_queue ? false : true
                              }
                            />) : (<Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="emergency_schedule"
                              value={this.state.clientData.emergency_schedule}
                              onChange={this.handlePatientChange.bind(
                                this,
                                "emergency_schedule"
                              )}
                              disabled={this.state.disable_insert_queue}
                              options={emergency_queue_data}
                            />)}

                            <SpanField
                              classname="text-danger"
                              title={errors.emergency_schedule}
                            />

                          </div>
                        </div>

                        <div>
                          <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft required"
                                  : "form-group frllft "
                              }
                            >
                              <label>Diagnosis</label>
                            </div>
                            <div className="form-group sform-control frlrit">
                              <Select maxMenuHeight={120}
                                isMulti
                                className="p-0 "
                                name="reason_for_visit"
                                value={this.state.clientData.reason_for_visit}
                                onChange={this.handlePatientChange.bind(
                                  this,
                                  "reason_for_visit"
                                )}
                                options={disease_array}
                              />
                              <SpanField
                                classname="text-danger"
                                title={errors.reason_for_visit}
                              />
                            </div>
                          </div>
                          <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft required"
                                  : "form-group frllft"
                              }
                            >
                              <label>Symptoms</label>
                            </div>
                            <div className="form-group sform-control frlrit">

                              <Select maxMenuHeight={120}
                                isMulti
                                className="form-controls w-100 p-0"
                                disabled={true}
                                name="symptoms"
                                value={this.state.clientData.symptoms}
                                isDisabled={true}
                              />
                              <SpanField
                                classname="text-danger"
                                title={errors.symptoms}
                              />
                            </div>
                          </div>

                          <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft mbbre required"
                                  : "form-group frllft mbbre"
                              }
                            >
                              <label className="ffgrd">Select Doctor</label>
                            </div>
                            <div className="form-group sform-control frlrit">
                              <div className="position-relative">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text inpico">
                                      <i className="fas fa-search"></i>
                                    </span>
                                  </div>
                                  <Select maxMenuHeight={120}
                                    className="form-control p-0"
                                    name="selected_doc"
                                    value={selected_doc}
                                    onChange={this.handleselectDocChange}
                                    options={doctor_list}
                                    isDisabled={this.state.is_check_in}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft mbbre required"
                                  : "form-group frllft mbbre"
                              }
                            >
                              <label className="ffgrd">Select Timing</label>
                            </div>
                            {this.state.is_queued ? (
                              <div className="row">
                                {this.state.expected_time}
                              </div>
                            ) : (
                              <div className="row" >
                                {this.state
                                  .selected_doctor_appointment_detail_list
                                  ? this.state.selected_doctor_appointment_detail_list.map(
                                    (option, i) => {
                                      return (
                                        <Fragment key={option.event_name + i}>
                                          <div className="col-sm-12">
                                            <div className="form-check fltx">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name={option.event_name}
                                                id={option.event_name}
                                                value={option.schedule_day_id}
                                                checked={
                                                  this.state.clientData
                                                    .schedule_day_id ===
                                                  option.schedule_day_id
                                                }
                                                onChange={
                                                  this.handleTimingSelect
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={option.event_name}
                                              >
                                                {option.event_name} (
                                                {option.starttime} -{" "}
                                                {option.endtime})
                                              </label>
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.timing}
                                            />
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  )
                                  : ""}
                              </div>
                            )}
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.message}
                          />

                          <div className="frllftfull">
                            <div className="form-group">
                               <Label title="Reason For Visit" isMandatory={true} />
                              <TextArea
                                rows={4}
                                content={this.state.clientData.other_comments}
                                name={"other_comments"}
                                controlFunc={this.handleInput}
                                placeholder={""}
                                maxLength={200}
                                isMandatory={true}
                                />
                                <SpanField
                                classname="text-danger"
                                title={errors.other_comments}
                              />
                            </div>
                          </div>
                          

{(this.state.clientData.user_id!==undefined)?(
                          <div>
 {/* {(this.state.fileUploaded==false && this.state.is_check_in==false)||(this.state.fileUploaded==true)? */}                           
       <Label title="Patient Old Records Images" />
      {/*  :""} */}
 {/* {(this.state.fileUploaded==false && this.state.is_check_in==false)||(this.state.fileUploaded==true)?  */}                          
       <p>* Please upload maximum of 10 images</p>
      {/*  :""} */}
       
 {/* {(this.state.fileUploaded==false && this.state.is_check_in==false)? */}
        <div className="row my-3">
          <div className="col-8">
            <label className="btn btn-default p-0">
              <input type="file" multiple accept="image/*" id="uploadfile" onChange={this.selectFiles} />
            </label>
          </div>
        </div>
      {/*   :""} */}
      {/*   {(this.state.fileUploaded==true && this.state.clientData.user_id!==uploadedUser_id &&
           this.state.clientData.user_id!==undefined)?
        (<p>Uploading Medical Images for other patient pls Wait...</p>):""} */}

        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}
{/* {(this.state.fileUploaded==true && this.state.clientData.user_id==uploadedUser_id)?
        <div className="card">
          <div className="card-header">List of Files</div>
          <ul className="list-group list-group-flush">
            {fileInfos &&
              fileInfos.map((file, index) => (
                <li className="list-group-item" key={index}>
                  <a href={file.url}>{file.name}</a>
                  <img src={file.url} alt="Document" />
                </li>
              ))}

          </ul>
        </div>:""}
 */}
       {/*  {(this.state.clientData.user_id==uploadedUser_id)? 
        <div className="form-group multi-preview">
                    {(this.fileArray ||[]).map(url => (
                        <img src={url} alt="..." />
                    ))}
                </div>:""} */}
                          </div>
                          ):""}
                          

                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <table className="tablebtn1">
                              <tbody>
                                <tr>
                                  <td>
                                    {this.state.is_add_btn == true ?
                                      <button

                                        className="button s1"
                                        onClick={this.addPatient}
                                      >
                                        Add Patient{" "}
                                      </button> : <button
                                        disabled={
                                          !this.state.is_add_btn ? "disabled" : ""
                                        }
                                        className="button s1"
                                        onClick={this.addPatient}
                                      >
                                        Add Patient{" "}
                                      </button>}
                                  </td>
                                  {this.state.is_add_btn ? null : (
                                    <td>
                                      <button
                                        onClick={
                                          this.state.is_check_in || this.state.is_queued
                                            ? this.handleCheckin
                                            : this.handleCheckin
                                        }
                                        className="s2"
                                      >
                                        {this.state.is_check_in
                                          ? "Checked-In"
                                          : "Check In"}
                                      </button>
                                    </td>
                                  )}
                                  <td>
                                    {" "}
                                    <button
                                      onClick={this.handleReset}
                                      className="s3"
                                    >
                                      Reset
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <Modal
                              show={this.state.cancelModel}
                              onHide={this.closePopup}
                              centered={true}
                            >
                              <Modal.Header closeButton className="model-hptd">
                                <Modal.Title className="h5 model-text-color">
                                  Cancel Appointment
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div>
                                  <p>
                                    Are you sure you want to cancel the appointment ?
                                  </p>

                                  <div className="row reverse">
                                  <div className="form-group mb-0 text-right">
                                      <button
                                        className="grnbtn "
                                        onClick={() => {
                                          this.setState({
                                            cancelModel: false,
                                            cancelRecord: {},
                                          });
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                    &nbsp;
                                    <div className="form-group mb-0 text-right">
                                      <button
                                        className="grnbtn reddg"
                                        onClick={this.conformCancelAppointment}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                   
                                    
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 pl0">
            <div className="card ">
            <div class="card-header"> <div class="card-title">Vital Details</div> </div>
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        
                        <div className="rowGrid vdRow ">
                          <div className="rowGridList ">
                            <div className="card vitals cpag1">
                              <div className="card-body">
                                <div className="text-left p-1 s-118">
                                  {/* <h5 className="border-0 small  titleFlex ">Height */}
                                  <h5 className="border-0 small titleFlex justify-content-between">Height
                                    <img className="mr1-37"
                                    // <img className="ml-37"
                                      alt=""
                                      src={require("../../assets/images/vitaldetails1.png")}
                                    />                              </h5>
                                </div>

                                <div className="input-group input-group-sm mb-0 w-75">
                                {/* <div className="input-group input-group-sm mb-0"> */}
                                  <input type="text" className="dis-inlnflx form-control" name="height" value={this.state.clientData.height} onChange={this.handleInput} />
                                  <div className="input-group-append">
                                    <span className="input-group-text px-3">cm </span>

                                  </div></div>
                              </div>
                            </div>
                          </div>
                          <div className="rowGridList">
                          <div className="card vitals cpag1">
                              <div className="card-body">
                                <div className="text-left p-1 s-118">
                                  {/* <h5 className="border-0 small  titleFlex">Weight */}
                                  <h5 className="border-0 small  titleFlex justify-content-between">Weight
                                    <img className="mr1-37"
                                    // <img className="ml-37"
                                      alt=""
                                      src={require("../../assets/images/vitaldetails2.png")}
                                    />
                                  </h5>
                                </div>
                                <div className="input-group input-group-sm mb-0 w-75">
                                {/* <div className="input-group input-group-sm mb-0"> */}
                                  <input type="text" className="dis-inlnflx form-control" name="weight"
                                    value={this.state.clientData.weight} onChange={this.handleInput} />
                                  <div className="input-group-append">
                                    {/* <span className="input-group-text input-group-text-1 txt-c">kg </span> */}
                                    <span className="input-group-text px-3">kg </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rowGridList">
                            <div className="card vitals cpag1">
                              <div className="card-body">
                                <div className="text-left p-1 s-118">
                                  {/* <h5 className="border-0 small titleFlex ">Temp */}
                                  <h5 className="border-0 small titleFlex justify-content-between">Temp
                                    <img className="mr1-37"
                                    //  <img className="ml-6"
                                      alt=""
                                      src={require("../../assets/images/vitaldetails3.png")}
                                    />
                                  </h5>
                                </div>
                                <div className="input-group input-group-sm mb-0 w-75">
                                {/* <div className="input-group input-group-sm mb-0"> */}
                                  <input type="text" className="dis-inlnflx form-control" name="temperature" value={this.state.clientData.temperature} onChange={this.handleInput} />
                                  <div className="input-group-append">
                                    {/* <span className="input-group-text input-group-text-1 txt-c">°F </span> */}
                                    <span className="input-group-text px-3">°F </span>
                                  </div></div>
                              </div>
                            </div>
                          </div>
                          <div className="rowGridList">
                            <div className="card vitals cpag1">
                              <div className="card-body">
                                <div className="text-left p-1 s-118">
                                  {/* <h5 className="border-0 small titleFlex ">BMI */}
                                  <h5 className="border-0 small titleFlex justify-content-between">BMI
                                    <img className="mr1-37"
                                    //  <img className="ml-53"
                                      alt=""
                                      src={require("../../assets/images/bmi_icon.png")}
                                    />

                                  </h5>
                                </div>
                                <div className="input-group input-group-sm mb-0 w-75">
                                {/* <div className="input-group input-group-sm mb-0"> */}
                                  <input className="form-control" name="bmi" type="text" disabled value={this.state.clientData.bmi} onChange={this.handleInput} />
                                  <div className="input-group-append">
                                    <span className="input-group-text px-3" id="basic-addon2">BMI</span>
                                  </div></div>
                              </div>
                            </div>
                          </div>
                          <div className="rowGridList">
                            <div className="card vitals cpag1">
                              <div className="card-body">
                                <div className="text-left p-1 s-118">
                                  {/* <h5 className="border-0 small titleFlex">BP */}
                                  <h5 className="border-0 small titleFlex justify-content-between">BP
                                    <img className="mr1-37"
                                    // {/* <img className="ml-53" */}
                                      alt=""
                                      src={require("../../assets/images/vitaldetails4.png")}
                                    />
                                  </h5>
                                </div>
                                <div className="input-group input-group-sm mb-0 w-75">
                                {/* <div className="input-group input-group-sm mb-0"> */}
                                  <input type="text" className="dis-inlnflx form-control" name="bp" value={this.state.clientData.bp} onChange={this.handleInput} />
                                  <div className="input-group-append">
                                    <span className="input-group-text">mmHg </span>

                                  </div></div>
                              </div>
                            </div>
                          </div>
                          <div className="rowGridList">
                            <div className="card vitals cpag1">
                              <div className="card-body">
                                <div className="text-left p-1 s-118">
                                  {/* <h5 className="border-0 small titleFlex">Pulse */}
                                  <h5 className="border-0 small titleFlex justify-content-between">Pulse
                                    <img className="mr1-37"
                                    //  <img className="ml-70"
                                      alt=""
                                      src={require("../../assets/images/vitaldetails5.png")}
                                    />
                                  </h5>
                                </div>
                                {/* <div className="input-group input-group-sm mb-0"> */}
                                <div className="input-group input-group-sm mb-0 w-75">
                                  <input type="text" className="dis-inlnflx form-control" name="pulse" value={this.state.clientData.pulse} onChange={this.handleInput} />
                                  <div className="input-group-append">
                                    <span className="input-group-text">per min</span>

                                  </div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                     
                      </div>
                      </div>
                      
                   

                  </div>
                </div>
              </div>
            </div>
           
            <div className="row">
              <div className="col-sm-6">
              <div className="card h383">
            <div class="card-header"> <div class="card-title">Symptom List</div> </div>
              <div className="card-body">
              <div className="row">

<div className="col-sm-12">
  <div className="card">
    <div className="">
      <div
        className="tab-content"
        id="v-pills-tabContent"
      >
        <div
          className="tab-pane fade show active"
          id="v-pills-shipping"
          role="tabpanel"
          aria-labelledby="v-pills-shipping-tab"
        >
          <div
            className="card"

          >
          
            <div className="input-group">
              <span className="input-group-addon "><i className="glyphicon fa fa-search icontextb"></i></span>
              <InputField
                inputType={"text"}
                name={"symptomSearch"}
                controlFunc={this.handleSymptomSearch}
                content={
                  this.state.symptomSearch
                }
                placeholder={""}
              />
            </div>



          </div>
          <div className="row symtomsscrol">

            <div className="col-sm-12 c-pagebutton">
              {this.state.selectedSymptoms}

              {!this.state.is_check_in && this.state.symptoms.map((symptom, i) => {
                return (
                  <div className="custom-control custom-checkbox mb-1">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={symptom.symptom_name}
                      id={symptom.id}
                      onClick={this.selectSymptom.bind(
                        this,
                        symptom
                      )}
                      checked={this.state.selectedSymptoms.indexOf(symptom.symptom_name) > -1}

                    />
                    <label
                      className="custom-control-label"
                      htmlFor={symptom.id}
                    >
                      {symptom.symptom_name}
                    </label>
                  </div>
                );
              })}

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
              </div>
            </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                <div class="card-header"> <div class="card-title">Symptom Category</div> </div>
                  <div className="card-body">
                  <div className="col-sm-12">
  <div className="card">
    <a
      href="#collapse2"
      className="text-dark"
      data-toggle="collapse"
      aria-expanded="true"
      aria-controls="collapse2"
    >
     
    </a>
    <div className="card-body p-0 resultscrol">
      <div className=" c-pagebutton c-pagebtn">
        {symptomCategory.map(
          (symptom, index) => {
            return (
              <button
                disabled={this.state.is_check_in}
                className={
                  (activeIndex === index && activeIndex !== '')
                    ? "active"
                    : ""
                }
                onClick={() =>
                  this.searchAndShowSymptom(
                    symptom,
                    index
                  )
                }
              >
                {symptom.label}
              </button>
            );
          }
        )}

      </div>

    </div>
  </div>
</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>)}

      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientCheckin));
