import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LogoSection from "../components/UI/LogoSection";
import Label from "../components/UI/Label";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import Button from "../components/UI/Button";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Popup } from "../components/UI/Popup";
import FooterSection from '../components/UI/FooterSection';

import Validation from "../actions/Validation";
import axios from "axios";
import {
  addVendor,
  getallVendor,
  getAllSubscriptions,
  removeVendorFromVendorlist,
} from "../config/url.json";
import { vendor_category, CONFIG, imageconfig } from "../config/data";
import S3 from "react-aws-s3";

import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
// const currentYear = new Date().getFullYear();
const google = window.google;
function mapStateToProps(state) {
 // console.log(state);
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    loggedIn: state.AuthReducer.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

class VendorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableSuccess: {
        message: "",
        status: "",
    },
      records: [],
      vendorData: {
        id:"",
        vendor_name: "",
        vendor_category: "",
        vendor_photo: "",
        vendor_website: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        google_address: false,
        phone_code: "+91",
        phone_number: "",
        email: "",
        type: "vendorform",
        vendor_subscription: "",
      },
      vendor_image_url: "",
      vendor_subscription_list: [],
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      deletePopup: false,
      errors: {
        vendor_name: "",
        vendor_category: "",
        vendor_photo: "",
        vendor_website: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        phone_number: "",
        email: "",
        vendor_subscription: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
    };
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "vendor_name",
        text: "Vendor Name",
        className: "vendor_name",
        align: "left",
        sortable: true,
      },
      {
        key: "vendor_category",
        text: "Vendor Category",
        className: "vendor_category",
        sortable: true,
        align: "left",
      },
      {
        key: "phone_number",
        text: "Phone number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        sortable: true,
        align: "left",
      },
      {
        key: "vendor_subscription_label",
        text: "Vendor Subscription",
        className: "vendor_subscription_label",
        sortable: true,
        align: "left",
      },
      {
        key: "payment_status",
        text: "Payment Status",
        className: "payment_status",
        sortable: true,
        align: "left",
      },
      {
        key: "expires_at",
        text: "Expires at",
        className: "expires_at",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.expires_at ? moment(record.expires_at).format("DD-MM-YYYY") : '';
        },
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.onEditClick(record)}
              >
                <i className="fa fa-eye"></i>
              </button>
              <span>{" "}</span>
              <button
                className="btn btn-danger btn-sm"
                  onClick={() =>
                  this.setState({ deletePopup: true, record: record })
                  }
                  >
                  <i className="fa fa-trash"></i>
                  </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      show_pagination: true,
      show_filter: false,
      show_length_menu: false,
      show_info: false,
      button: {
        excel: false,
        print: false,
      },
    };
  }
  onEditClick = (event) => {
    window.location.href = "/vendor/" + event.id;
  };
  deleteRecord(record) {
      
    let id = record.id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(removeVendorFromVendorlist, { id: id }, CONFIG)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.status === "1") {
          let records = this.state.records;

          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
          this.setState({ tableSuccess: res.data });
          this.clearMessage();
        }
      })
      .catch((e) => { });
    this.setState({ deletePopup: false, record: null });

    this.setState({
      confirmedDelete: false,
    });
    axios.defaults.headers.common[
      "Authorization"
  ] = `Bearer ${this.props.access_token}`;
  axios
      .post(getallVendor, null, CONFIG)
      .then((list) => {
          console.log(list);
          let users = [];
          let userlist = list.data.data;
          userlist.forEach((element, index) => {
            let user = {};
            user.s_no = index + 1;
            user.id=element.id;
            user.vendor_name = element.vendor_name;
            user.vendor_category = element.vendor_category;
            user.phone_number = element.phone_number;
            user.email = element.email;
            user.vendor_subscription_label = element.vendor_subscription_label;
            user.payment_status = element.payment_status;
            user.expires_at = element.expires_at;
            users[index] = user;
          });
  
          this.setState({ records: users });
      //    this.setState({ records: list.data.data });
      })
      .catch((e) => { console.log(e) });
  
  }
  handleFormSubmit = (e) => {
    e.preventDefault();

    let { vendorData, errors, google_address } = this.state;
    console.log(vendorData);
    errors = Validation(vendorData, errors);
    console.log(errors);
    if (!google_address && vendorData.address1 !== "") {
      errors.address1 = "Please choose address from autocomplete";
    }
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      this.setState({ loading: true });

      console.log("form is valid");

      try {
        let data = this.state.vendorData;
        data.vendor_category = data.vendor_category.value;
        data.vendor_subscription = data.vendor_subscription.value;
        data.vendor_subscription_label = data.vendor_subscription.label;
        data.phone_number = data.phone_number.replace(data.phone_code, "");

        const result = axios
          .post(addVendor, data, CONFIG)
          .then((res) => {
            let response = res.data;

            // return false;
            if (response.status !== "0") {
              window.location.href = "/vendor/" + response.data;
            } else {
              data.phone_number = data.phone_code.concat(data.phone_number);
              data.vendor_subscription = {
                label: data.vendor_subscription_label,
                value: data.vendor_subscription,
              };
              data.vendor_category = {
                label: data.vendor_category,
                value: data.vendor_category,
              };
              this.setState({ error: response.message, vendorData: data });
            }
          })
          .catch((e) => {
            console.log(e);
          });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        this.setState({ loading: false });

        // dispatch(
        // addError(e.message)
        // )
      }
    }
  };
  handlevendorcategoryselectChange = (selectedOptions, e) => {
    let data = this.state.vendorData;
    let errors = this.state.errors;
    data[e.name] = selectedOptions;
   errors.vendor_category="";
    this.setState({ vendorData: data,errors:errors });
  };
  handlevendorsubscriptionselectChange = (selectedOptions, e) => {
    let data = this.state.vendorData;
    let errors = this.state.errors;
    data[e.name] = selectedOptions;
   errors.vendor_subscription="";
    this.setState({ vendorData: data,errors:errors });
  };
  handleInput = (e) => {
    let data = this.state.vendorData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    // if (name === "business_name") {
    //     this.validateMaxLength(name, value, max);
    // } else if (name === "email") {
    //     this.validateMaxLength(name, value, max);
    // } else if (name === "password") {
    //     this.validateMaxLength(name, value, max);
    // } else if (name === "address1") {
    //     this.validateMaxLength(name, value, max);
    // } else if (name === "address2") {
    //     this.validateMaxLength(name, value, max);
    // } else if (name === "city") {
    //     this.validateMaxLength(name, value, max);
    // } else {
    data[name] = value;
    errors[name] = "";
    // }

    this.setState({ vendorData: data, errors: errors });
  };

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    console.log("dd");

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    axios.defaults.headers.common[
      "Authorization"
  ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllSubscriptions, null, CONFIG)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "1") {
          let vendor_subscription_list = [];
          result.data.data.forEach((element, index) => {
            let obj = {};
            obj = element;
            obj.label = element.subscription_name;
            obj.value = element.id;
            vendor_subscription_list[index] = obj;
          });

          this.setState({
            vendor_subscription_list: vendor_subscription_list,
          });
        } else {
          alert("Subscription list empty");
        }
      })
      .catch((e) => {
        console.log(e);
        console.log("business user getbyid failed");
      });
      axios.defaults.headers.common[
            "Authorization"
         ] = `Bearer ${this.props.access_token}`;
           axios
             .post(getallVendor, null, CONFIG)
             .then((result) => {
               console.log(result);
               if (result.data.status === "1") {
                let users = [];
                let userlist = result.data.data;
                userlist.forEach((element, index) => {
                  let user = {};
                  user.s_no = index + 1;
                  user.id=element.id;
                  user.vendor_name = element.vendor_name;
                  user.vendor_category = element.vendor_category;
                  user.phone_number = element.phone_number;
                  user.email = element.email;
                  user.vendor_subscription_label = element.vendor_subscription_label;
                  user.payment_status = element.payment_status;
                  user.expires_at = element.expires_at;
                  users[index] = user;
                });
        
                this.setState({ records: users });
               } else {
                 this.setState({ records: [] });
               }
             })
            .catch((e) => {
               console.log(e.data);
               console.log("business user getbyid failed");
             });
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();
    let errors=this.state.errors;
    console.log(place);

    let { vendorData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    vendorData.latitude = lat.toString();
    vendorData.longitude = lng.toString();
    vendorData.place_url = place.url;

    // console.log(place.name);
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
          vendorData.state = val;
        if (addressType === "locality")
          vendorData.city = val;
        if (addressType === "country") vendorData.country = val;
        // if (addressType === "street_number") {

        vendorData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1") vendorData.address2 = val;
        if (addressType === "postal_code") vendorData.zipcode = val;
      }
    }

    // let ClientData = this.state;
    //errors.city_error="";
    //errors.state_error="";
    //errors.country_error="";
   //errors.zip_code_error="";
    errors.city="";
    errors.state="";
    errors.country="";
     errors.zipcode="";
    this.setState({
      vendorData,
      google_address: true,
    });
  }

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.vendorData;
    let errors = this.state.errors;
    data.country = val;
    errors.country = ""
    this.setState({ vendorData: data,errors:errors });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.vendorData;
    let errors = this.state.errors;
    data.state = val;
    errors.state = ""
    this.setState({ vendorData: data,errors:errors });
  }
  handlePhoneInput = (event) => {
    let vendorData = this.state.vendorData;
    let errors = this.state.errors;
    vendorData.phone_number = event;
    errors.phone_number=""
    this.setState({ vendorData,errors:errors });
  };
  onUploadChange = (event) => {
    event.preventDefault();

    console.log(event.target.files[0]);
    let file = event.target.files[0];

    let img = URL.createObjectURL(event.target.files[0]);
    this.setState({ vendor_image_url: img });
    let newfileName = event.target.files[0].name;
    imageconfig.dirName = "vendor";

    console.log(imageconfig);
    const reacts3client = new S3(imageconfig);
    console.log(reacts3client);
    reacts3client.uploadFile(file, newfileName).then((data) => {
      console.log(data);
      if (data.status === 204) {
        console.log("success");
        let vendorData = this.state.vendorData;
        vendorData.vendor_photo = data.location;
        this.setState({ vendorData: vendorData });
      } else {
        console.log("error");
      }
    });
  };
  cancel = () => {

    let data = this.state.vendorData;
    data.vendor_name = "";
    data.vendor_website="";
    data.id = "";
    data.vendor_category = "";
    data.address1="";
    data.city="";
    data.country="";
    data.state="";
    data.phone_number="";
    data.zipcode="";
    data.email="";
    data.vendor_subscription="";

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ vendorData: data});
  }

  clearErrors = (errors) => {

    errors.vendor_name = "";
    errors.vendor_category = "";
    errors.address1 = "";
    errors.city="";
    errors.state="";
    errors.country="";
    errors.email="";
    errors.vendor_subscription="";
    errors.phone_number="";
    errors.zipcode="";
    return errors
  }
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
    const { vendorData, errors,tableSuccess,formSuccess } = this.state;

    return (
      <div id="layout-wrapper">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Start right Content here --> */}
        {/* <!-- ============================================================== --> */}
        <div className="main-content grayback">
          <div className="mainpage">
          <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
            <div className="main-box adminMainBox">
              <div className="sidemenu pr0">
                <AdminSideBar />
              </div>
              {/* <section className="my-head">
              <div className="container-fluid">
                <div className="row">
                  <LogoSection />
                  
                </div>
              </div>
            </section> */}

              <section className="main-body hospital-profilegap checkout-tabs">
                {/* {error ? <ErrorAlert message={error} /> : null} */}
                <div className="col-sm-12">
                <h4 className="font-weight-bold mt-3 mb-3">Advertisement Management </h4>
                <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card  bghome">
                  <div class="card-header"> <div class="card-title">Vendor Form</div> </div>
                    <div className="card-body pd-0">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-shipping"
                          role="tabpanel"
                          aria-labelledby="v-pills-shipping-tab"
                        >
                          
                          <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                          <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            className="table table-bordered table-striped kas  mobile_table"
                          />
                           <Popup show={this.state.deletePopup}
                               onHide={() =>
                                this.setState({
                                 deletePopup: false,
                                  })
                                   }
                                  onConfirm={() =>
                                  this.deleteRecord(this.state.record)
                                   }
                                  title={"Confirmation"}
                                  message={
                                  "Are you sure you want to delete this record?"
                                 }
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card  bghome">
                 
                      <section className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Label title="Vendor name" isMandatory={true} />
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  name={"vendor_name"}
                                  controlFunc={this.handleInput}
                                  content={
                                    vendorData.vendor_name
                                      ? vendorData.vendor_name
                                      : ""
                                  }
                                  placeholder={""}
                                  max={50}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.vendor_name}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Label
                                  title="Vendor Category"
                                  isMandatory={true}
                                />
                                <Select maxMenuHeight={120}
                                  className="w-100 p-0"
                                  name="vendor_category"
                                  value={vendorData.vendor_category}
                                  onChange={this.handlevendorcategoryselectChange}
                                  options={vendor_category}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.vendor_category}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="form-group">
                                <Label title="Website" isMandatory={false} />

                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  name={"vendor_website"}
                                  controlFunc={this.handleInput}
                                  content={
                                    vendorData.vendor_website
                                      ? vendorData.vendor_website
                                      : ""
                                  }
                                  placeholder={""}
                                  max={50}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.vendor_website}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <InputField
                                  isMandatory={true}
                                  id="address1"
                                  inputType={"text"}
                                  title={"Address 1"}
                                  name={"address1"}
                                  controlFunc={this.handleInput}
                                  content={
                                    vendorData.address1 ? vendorData.address1 : ""
                                  }
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.address1}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <InputField
                                  // isMandatory={true}
                                  inputType={"text"}
                                  title={"Address 2"}
                                  name={"address2"}
                                  controlFunc={this.handleInput}
                                  content={
                                    vendorData.address2 ? vendorData.address2 : ""
                                  }
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.address2}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  title={"City"}
                                  name={"city"}
                                  max={20}
                                  controlFunc={this.handleInput}
                                  content={vendorData.city ? vendorData.city : ""}
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.city}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Label title="Country" isMandatory={true} />

                                <CountryDropdown
                                  placeholder="Country"
                                  name="country"
                                  value={vendorData.country || ""}
                                  className="form-control"
                                  onChange={(val) => this.selectCountry(val)}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.country}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Label title="State" isMandatory={true} />

                                <RegionDropdown
                                  name="state"
                                  defaultOptionLabel="Select State"
                                  country={vendorData.country}
                                  value={vendorData.state || ""}
                                  className="form-control"
                                  onChange={(val) => this.selectState(val)}
                                  placeholder="State"
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.state}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  title={"Zip/Pincode"}
                                  name={"zipcode"}
                                  max={10}
                                  controlFunc={this.handleInput}
                                  content={vendorData.zipcode}
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.zipcode}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group mb-0">
                                <Label title="Phone number" isMandatory={true} />
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-control">
                                    <PhoneInput
                                      international
                                      defaultCountry="IN"
                                      placeholder="Enter phone number"
                                      value={vendorData.phone_number}
                                      onChange={this.handlePhoneInput}
                                      error={
                                        vendorData.phone_number
                                          ? isValidPhoneNumber(
                                            vendorData.phone_number
                                          )
                                            ? undefined
                                            : "Invalid phone number"
                                          : "Phone number required"
                                      }
                                    />
                                  </div>
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.phone_number}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-sm-6">
                                            <div className="form-group mb-0">
                                                <label>Primary mobile number*</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-4">
                                                    <div className="form-group">
                                                        <select className="form-control">
                                                            <option>+91</option>
                                                            <option>+91</option>
                                                            <option>+91</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xs-8">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                            <div className="col-sm-6">
                              <div className="form-group">
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  title={"Email"}
                                  name={"email"}
                                  max={50}
                                  controlFunc={this.handleInput}
                                  content={vendorData.email}
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.email}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Label
                                  title="Vendor Subscription"
                                  isMandatory={true}
                                />
                                <Select maxMenuHeight={120}
                                  className=""
                                  name="vendor_subscription"
                                  value={vendorData.vendor_subscription}
                                  onChange={this.handlevendorsubscriptionselectChange}
                                  options={this.state.vendor_subscription_list}
                                  />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.vendor_subscription}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <Label title="Subscription Amount" />
                                <p className="form-control">
                                  {vendorData.vendor_subscription
                                    ? vendorData.vendor_subscription.amount
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <Label
                            title="Select Banner"

                          />
                          <div className="custom-file">
                            <input
                              accept="image/*"
                              type="file"
                              className="custom-file-input"
                              onChange={this.onUploadChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFile"
                            >
                              Choose file
                            </label>
                          </div>

                          <div className="vendorImageDisplay">
                            <img
                              alt="banner"
                              style={{ maxWidth: "100%" }}
                              src={this.state.vendor_image_url}
                            />
                          </div>

                        </div>
                        
                      </section>

                      <div className="col-sm-12 btncent">
                        <div className="form-group signupformbtn float-right">
                          {/* <button className="signupbtn" data-toggle="modal" data-target=".bs-example-modal-center">Sign Up</button> */}
                       {/*    <Button
                            clsname="signupbtn abtn"
                            btntype="submit"
                            title="Submit"
                            onClick={this.handleFormSubmit}
                          /> */}
                          <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.handleFormSubmit}
                                                                    >
                                                                        Submit
                                                                    </button>&nbsp;
                            <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.cancel}
                                                                    >
                                                                        Cancel
                                                                    </button>
                        </div>
                      </div>
                      </div>
                      </div>
                  
                  </div>
                </div>
           
              </section>
              <FooterSection />
            </div>
          </div>
        </div>
        {/* <FooterAds /> */}
        {/* <FooterSection /> */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(VendorForm);
