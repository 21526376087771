import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Camera, FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';



function handleCameraError(error) {
  console.log('handleCameraError', error);
}

function handleCameraStart(stream) {
  console.log('handleCameraStart');
}

function handleCameraStop() {
  console.log('handleCameraStop');
}

export class ProfilePicModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Create a reference to the hidden file input element
      hiddenProfileImageInput: React.createRef(),
      startcamera: false,
    }
  }

  editImage = event => {
    this.setState({ startcamera: false });
    this.state.hiddenProfileImageInput.current.click();
  }






  imageSelectHandler = event => {
    console.log(event.target.files[0]);
    //this.setState({profile_img: event.target.files[0]});
    //call api to upload the latest image
    this.setState({ profile_img: URL.createObjectURL(event.target.files[0]) });
  }

  render() {
    const { headerinfo, hiddenProfileImageInput } = this.state;
    return (<Modal
      {...this.props}

      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton className="model-hptd">
        <Modal.Title className="h5 model-text-color">
          {this.props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alignCenter">
          <Button onClick={this.editImage} className="profilePicbtn abtn btdpml">Choose Picture</Button>
          <Button onClick={this.props.data.startWebCamera} className="profilePicbtn abtn btdpml">Take Picture</Button>
        </div>
        <input type="file" id="profileimg" onChange={this.props.data.imageSelection}
          ref={hiddenProfileImageInput} hidden accept="image/*" />
        {this.props.data.openWebCamera ? <Camera
          onTakePhoto={(dataUri) => { this.props.data.takePicture(dataUri); }}
          onCameraError={(error) => { handleCameraError(error); }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ width: 640, height: 480 }}
          imageType={IMAGE_TYPES.JPG}
          imageCompression={0.97}
          isMaxResolution={true}
          isImageMirror={false}
          isSilentMode={false}
          isDisplayStartCameraError={true}
          isFullscreen={false}
          sizeFactor={1}
          onCameraStart={(stream) => { handleCameraStart(stream); }}
          onCameraStop={() => { handleCameraStop(); }}
        /> : ""}
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>)
  }
}

export default ProfilePicModel;