import React, { Component, Fragment } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { connect } from "react-redux";
import SideBar from "../components/container/layout/client/SideBar";
import ReactDatatable from "@ashvin27/react-datatable";
import Header from "../components/container/layout/client/Header";
import axios from "axios";
import { Spinner } from "../components/UI/Spinner";
import * as moment from "moment";
import Label from "../components/UI/Label";
import {
  getAllBusinessList,
  searchBusiness,
  getClientFamilyDetails,
  getDocByBusiness,
  getDocByBusinesswithspeciality,
  addCheckinURL,
  addQueue_modified,
  getTimeoutbycategory,
  createNotification,
  RazorPayPayment,
   getCredentials,
   updateclientqueuestatus,
   getpaymenttypeforbusiness,
   getdoctordetails,
   UpdateQueueRemove,
   CancelQueue,
   getServiceChargesbycategory
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import InputField from "../components/UI/InputField";
import { gender } from "../config/data";
import { getDateISOFormat, getPatientsubstring } from "../constants/globalLevelFunctions";
import { Modal, Button } from "react-bootstrap";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import FooterSection from "../components/UI/FooterSection";
import TextArea from "../components/UI/TextArea";
import Footerad from "../components/UI/Footerad";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from "../components/UI/Dnaspinner";
import {
  VISIT_TYPE
} from "../../src/constants/config/data";
import {
 
  getGivenDateStringFormat
 
} from "../constants/globalLevelFunctions";
const mapStyles = {
  width: "100%",
  height: "100%",
};
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    user: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    searchData: state.SearchReducer.searchData,
    userdata: state.AuthReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
    setSearchData: (data) => dispatch({ type: "SET_SEARCH_DATA",payload:data})
  };
}
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

class Search extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout = this.state;
      var today = new Date(),

      Todaydate = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
     var thisday=getGivenDateStringFormat(today);
      
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "first_name",
      //   text: "M/F",
      //   className: "first_name",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
        
      //     return (
      //       <Fragment>
      //       <i
      //      className="fa fa-user-md clrblue"
      //      aria-hidden="true"
      //      ></i>
      //      </Fragment>
      //     )
      //   }
      // },
      {
        key: "expected_wait_time",
        text: "Wait Time",
        className: "expected_wait_time",
        align: "left",
        sortable: true,
      },
      {
        key: "business_name",
        text: "Hospital Name",
        className: "business_name",
        align: "left",
        sortable: true,
     },
      {
        key: "hospital_name",
        text: "Hospital Address",
        className: "hospital_name",
        align: "left",
        sortable: true,
        cell: (record )=>{
          return( 
            <Fragment>
            {
              record.business_name
              }
              -{" "}
              {
              record.wait_time
              }{" "}
              ,{" "}
              {
              record.kilometer
              }{" "}
              ,{" "}
              {record.city}{" "}
              ,
              {record.state}
              </Fragment>
          )
        }
      },
    /*   {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
     }, */
      {
        key: "phone_number",
        text: "Phone Number",
        width: 100,
        className: "phone_number",
        align: "left",
        sortable: true,
        cell: (record) => {
          return(
          <Fragment>
               <a
           href={"tel:+91"+record.phone_number}
           title={
            record.phone_number
           }
           >
        <i
           className="fa fa-phone-square grnbk"
           aria-hidden="true"
           ></i>
        </a>
          </Fragment>
          )
        },
      },
      {
        key: "directions",
        text: "Directions",
        className: "directions",
        sortable: true,
        align: "left",
        cell: (record) => {
          return (
          <Fragment>
              <a
           target="_blank"
           rel="noopener noreferrer"
           href={"https://www.google.com/maps/search/?api=1&query="+record.latitude+","+
           record.longitude
           }
           >
        <i
           className="fa fa-compass grnbk"
           aria-hidden="true"
           ></i>
        &nbsp;
        </a>
          </Fragment>
          )
        },
      },
      

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 200,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
          <Fragment>
               <button
        onClick={this.showFamilydetail.bind(
        this,
        record
        )}
        className="signupbtn mtlogin"
        // data-toggle="modal"
        // data-target=".bs-example-modal-center"
        >
        Select
        </button>
          </Fragment>
          )
        },
     
      },
    ];
    this.state = {
      timeout: timeout,
      isTimedOut: false,
      showModal: false,
      records:[],
      // queuein_data: [],
      loading: false,
      business_list: [],
      business_name: "",
      latitude: "",
      longitude: "",
      bookModel : false,
     // IsNext:false,
      currentdate:thisday,
      visit_type: VISIT_TYPE[0],
      payment_mode: "",
      searchData: {
        business_name: props.searchData ? props.searchData.business_name : "",
        doctor_name: props.searchData ? props.searchData.doctor_name : "",
        facility: props.searchData ? props.searchData.facility : "",
        speciality: props.searchData ? props.searchData.speciality : "",
        city: props.searchData ? props.searchData.city : "",
        gender: props.searchData ? props.searchData.gender : "",
        distance:
          props.searchData && props.searchData.distance != ""
            ? props.searchData.distance
            : "25",
        reviews: props.searchData ? props.searchData.reviews : "",
        distance_type:
          props.searchData && props.searchData.distance != ""
            ? props.searchData.distance_type
            : "KM",
        
      },
      searchresults: {
        data: [],
      },

      queuein_data: {
        n_client_id: "",
        n_business_id: "",
        n_business_user_id: "",
        n_schedule_day_id: "",
        n_savedtime: "",
        n_status: "",
        n_isActive: "",
        business_name: "",
        address: "",
        expected_wait_time: "",
        schedule_type: "",
        other_comments: "",
        payment_mode:"",
      },
      selectedPlace: { value: "", label: "" },
      doctor_list: [],
      selected_doc: { value: "", label: "" },
      doctor_appointment_detail_list: [],
      selected_doctor_appointment_detail_list: [],
      selected_doc_schedule_type: "",

      family_list: [],
      enableqin: false,
      listofClientForQueue: [],
      loggedInClient: { client_id: "", name: "", relationship: "" },
      error: "",
      openModal: false,
      errors: {
        doctor_name: "",
        other_comments:"",
        payment_type_error:""
      },
      searchErrorMessage: "",
      //   category: this.props.appdata.category,
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
      }
  }

  showFamilydetail = (value, el) => {
    this.setState({ loading: true });
   // console.log(this.state.searchData.speciality.value);
   if(this.state.searchData.speciality.value==undefined||this.state.searchData.speciality.value==null||this.state.searchData.speciality.value=="")
   {
    const data = {
      n_business_id: value.user_id,
      n_currentdate: getDateISOFormat(new Date()),
      
    };
    console.log(data);
    if (value.user_id !== "") {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getDocByBusiness, data, CONFIG)
        .then((result) => {

          let resData = result.data.data;
          console.log(result);
          console.log(resData);
          let queuein_data = {};

          queuein_data.n_business_id = value.user_id;
          queuein_data.business_name = value.business_name;
          queuein_data.address = value.address1 + " " + value.city;
          queuein_data.expected_wait_time = value.expected_wait_time;

          let doctor_appointment_detail_list = [];
          let docList = [];

          console.log(resData);
          if (resData) {
            resData.forEach((element, index) => {
              let doctor_obj = {};

              doctor_obj.doc_name = element.doctor_name;
              doctor_obj.business_user_id = element.business_user_id;
              doctor_obj.schedule_day_id = element.schedule_day_id;
              doctor_obj.schedule_id = element.schedule_id;
              doctor_obj.schedule_type = element.schedule_type;
              
              doctor_obj.appt_id = element.appt_id;
              doctor_obj.doctor_Appointments = element.doctor_Appointments;

              doctor_appointment_detail_list.push(doctor_obj);
              docList.push({
                value: element.business_user_id,
                label: element.doctor_name,
                speciality_name: element.speciality_name,
              });
            });
          }


          console.log(docList);

          this.setState({
            doctor_list: docList,
            queuein_data: queuein_data,
            doctor_appointment_detail_list: doctor_appointment_detail_list,
          });

          console.log(this.state.doctor_appointment_detail_list);

          axios
    .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:docList[0].value}, CONFIG)
    .then((res) => {
      let response = res.data;
      console.log(response);
      // return false;
      if (response.status !== "0") {
        // window.location.href = "/vendor/" + response.data;
        this.setState({ doctor_details: response.data });
      }

    })
    .catch((e) => {
      console.log(e);
    });

          //By default select on doctor
          let selected_doc = docList[0];
          let selected_appointment = [];
          let selected_doc_schedule_type = this.setState.selected_doc_schedule_type;

          //Based on default doctor selection select corresponding appointment details
          doctor_appointment_detail_list.forEach((element, index) => {
            if (element.business_user_id == docList[0].value) {
              selected_appointment = element.doctor_Appointments;
              selected_doc_schedule_type = element.schedule_type;
            }
          });

          this.setState({
            selected_doc: selected_doc,
            selected_doctor_appointment_detail_list: selected_appointment,
            selected_doc_schedule_type: selected_doc_schedule_type
          });
          console.log(this.state.selected_doctor_appointment_detail_list);
          console.log(this.state.selected_doc);

          this.setState({ openModal: true, loading: false });
        })
        .catch((e) => { });

        axios
        .post(getpaymenttypeforbusiness, { user_id: value.user_id}, CONFIG)
        .then((res) => {
          let data = res.data;
          console.log(res);
          console.log(data);
    
          console.log(data.data[0].payment_type);
          if (data.status === "1")
            this.setState({
              paymenttypeforhospital: data.data[0].payment_type
              
            });
          })
        .catch((e) => { });
    }
  }else
  {
    const data = {
      n_business_id: value.user_id,
      n_currentdate: getDateISOFormat(new Date()),
      speciality:this.state.searchData.speciality.value,
                
    };
    console.log(data);
    if (value.user_id !== "") {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getDocByBusinesswithspeciality, data, CONFIG)
        .then((result) => {

          let resData = result.data.data;
          console.log(result);
          console.log(resData);
          let queuein_data = {};

          queuein_data.n_business_id = value.user_id;
          queuein_data.business_name = value.business_name;
          queuein_data.address = value.address1 + " " + value.city;
          queuein_data.expected_wait_time = value.expected_wait_time;

          let doctor_appointment_detail_list = [];
          let docList = [];

          console.log(resData);
          if (resData) {
            resData.forEach((element, index) => {
              let doctor_obj = {};

              doctor_obj.doc_name = element.doctor_name;
              doctor_obj.business_user_id = element.business_user_id;
              doctor_obj.schedule_day_id = element.schedule_day_id;
              doctor_obj.schedule_id = element.schedule_id;
              doctor_obj.schedule_type = element.schedule_type;
              doctor_obj.appt_id = element.appt_id;
              doctor_obj.doctor_Appointments = element.doctor_Appointments;

              doctor_appointment_detail_list.push(doctor_obj);
              docList.push({
                value: element.business_user_id,
                speciality_name: element.speciality_name,
                label: element.doctor_name,
              });
            });
          }


          console.log(docList);

          this.setState({
            doctor_list: docList,
            queuein_data: queuein_data,
            doctor_appointment_detail_list: doctor_appointment_detail_list,
          });

          console.log(this.state.doctor_appointment_detail_list);

          //By default select on doctor
          let selected_doc = docList[0];
          let selected_appointment = [];
          let selected_doc_schedule_type = this.setState.selected_doc_schedule_type;

          //Based on default doctor selection select corresponding appointment details
          doctor_appointment_detail_list.forEach((element, index) => {
            if (element.business_user_id == docList[0].value) {
              selected_appointment = element.doctor_Appointments;
              selected_doc_schedule_type = element.schedule_type;
            }
          });

          this.setState({
            selected_doc: selected_doc,
            selected_doctor_appointment_detail_list: selected_appointment,
            selected_doc_schedule_type: selected_doc_schedule_type
          });
          
        axios
        .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:docList[0].value}, CONFIG)
        .then((res) => {
          let response = res.data;
          console.log(response);
          // return false;
          if (response.status !== "0") {
            // window.location.href = "/vendor/" + response.data;
            this.setState({ doctor_details: response.data });
          }
    
        })
        .catch((e) => {
          console.log(e);
        });
          console.log(this.state.selected_doctor_appointment_detail_list);
          console.log(this.state.selected_doc);

          this.setState({ openModal: true, loading: false });
        })
        .catch((e) => { });


        axios
        .post(getpaymenttypeforbusiness, { user_id: value.user_id}, CONFIG)
        .then((res) => {
          let data = res.data;
          console.log(res);
          console.log(data);
    
          console.log(data.data[0].payment_type);
          if (data.status === "1")
            this.setState({
              paymenttypeforhospital: data.data[0].payment_type
              
            });
          })
        .catch((e) => { });
    }

  }
  };
  closePopup = (e) => {
    this.setState({
      openModal: false, errors: {}, error: "",payment_mode:"",
    /*   queuein_data: {}, */
       listofClientForQueue: [],
      // IsNext:false
    });
  };

  closebookPopup = (e) => {
    console.log(this.state.bookModel)
    // console.log(this.state.editModel)
    console.log(this.state.rebookModel)

    this.setState({
      bookModel: false,
      editModel: false,
      rebookModel: false,
     

    });

  };

  bookQueueappointment=(e)=>{
    let { queuein_data, errors } = this.state;
    
    axios
    .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:this.state.selected_doc.value}, CONFIG)
    .then((res) => {
      let response = res.data;
      console.log(response);
      // return false;
      if (response.status !== "0") {
        // window.location.href = "/vendor/" + response.data;
        this.setState({ doctor_details: response.data });
      }

    })
    .catch((e) => {
      console.log(e);
    });
  
 
    this.setState({payment_type_error : ""});
    let q_id=this.state.qId;
    this.displayRazorpay(q_id);
  }
  displayRazorpay = (q_id) => {
   /*  let clientData = this.state.clientData;
    let queue_data = clientData.queue_data; */
    let { queuein_data, errors } = this.state;
        console.log(q_id)
        const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')
    
        if (!res) {
          alert('Razorpay SDK failed to load. Are you online?')
          return
        }
        const option = {
          method: 'POST',
          // headers: new Headers({ 'content-type': 'application/json' }),
          mode: 'no-cors'
        };
    
        let paymentForm = this.state.selectedHours;
        let data = {};
        let consulting_fees = ((this.state.doctor_details && this.state.payment_mode=="Online")) ?  this.state.doctor_details.consulting_fees:"";
        let service_charges=this.state.directservice_charges;
        let total_charges=parseInt(consulting_fees)+parseInt(service_charges);
         data.payable_amt =(this.state.payment_mode=="Online") ? total_charges : service_charges;
        let payment_status=(this.state.payment_mode=="Online") ? 'Completed':'Pending';
        let payment_type=this.state.payment_mode;
        let selected_doc=this.state.selected_doc.label;
       //
        console.log(data);
       /*  let doc_firstname=this.state.doctor_details.first_name;
        let doc_lastname=this.state.doctor_details.last_name; */
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
    let razorpay_key = "";
        axios
    .post(getCredentials, {
      "credential_type":"razorpay"
    })
    .then((res) => {
      console.log(res.data);
      razorpay_key = res.data.data[0].value;
    
    
    
      axios.post(RazorPayPayment, data, data).then((res) => {
        console.log(res);
        let data = res.data;
      //  this.sendBillingmail(this.state.doctor_details);
        //  let vendorData = this.state.vendorData;
        //  let paymentForm = this.state.paymentForm;
        //  console.log(vendorData);
        console.log(razorpay_key);
        const options = {
          // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
          key: razorpay_key,
          currency: data.currency,
          amount: data.amount.toString(),
          order_id: data.id,
          name: 'Billing and Payment',
          description: '',
          image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
          handler: function (response) {
            console.log(response);
          //  alert(response.razorpay_payment_id)
            // return false; 
            // alert(response.razorpay_order_id)
            // alert(response.razorpay_signature)
            
            /* let req_data = {
              status:'queue-in',
              'order_id': response.razorpay_order_id,
              'payment_id': response.razorpay_payment_id,
              //   'vendor_id': vendorData.id,
              //   vendor_subscription: vendorData.vendor_subscription.id,
              //    number_of_months: paymentForm.months.value,
              //    amount: paymentForm.payable_amt,
              amount: consulting_fees,
              payment_status: payment_status,
              service_charges:service_charges,
              payment_type:payment_type,
              q_id: q_id
    
            } */
            toast.success("Your Appointment has been confirmed with Dr. "+ selected_doc , {
              className: "toast-success",
              }); 
              const url = updateclientqueuestatus;
              let req_data = {
                status:'queue-in',
                'order_id': response.razorpay_order_id,
                'payment_id': response.razorpay_payment_id,
                //   'vendor_id': vendorData.id,
                //   vendor_subscription: vendorData.vendor_subscription.id,
                //    number_of_months: paymentForm.months.value,
                //    amount: paymentForm.payable_amt,
                amount: consulting_fees,
                payment_status: payment_status,
                service_charges:service_charges,
                scharges_paymentstatus:'Completed',
                payment_type:payment_type,
                q_id: q_id
      
              };
                               
             axios.post(updateclientqueuestatus, req_data, CONFIG).then((res) => {
              console.log(res);
             
              setTimeout(
                () =>
                  (res.data.status) ?
                    window.location.href ="/scheduleconfirmation/" + q_id : ""
                ,
                1000
              );
             
            }).catch((err) => {
    
    
            }) 
          },
    
          prefill: {
            name: this.props.userdata.first_name+" "+this.props.userdata.last_name,
            email: this.props.userdata.email,
            contact: this.props.userdata.phone_number
          },
          modal: {
            confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
            // This function is executed when checkout modal is closed
            // There can be 3 reasons when this modal is closed.
            ondismiss: async (reason) => {
              const {
                reason: paymentReason, field, step, code,
              } = reason && reason.error ? reason.error : {};
              // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
              if (reason === undefined) {
                console.log('cancelled');
                let cancel_data = {
                  status:'Cancelled',
                  amount: consulting_fees,
                  payment_status: "",
                  service_charges:service_charges,
                  payment_type:payment_type,
                  q_id: q_id
        
                };
                const handlePayment  = async (url, cancel_data) => {
                  try {
                    const response = await axios.post(url, cancel_data);
                    return response.data;
                   
                  } catch (error) {
                    console.error('Error:', error);
                    throw error;
                  }
                };  
                let queuedata = {
                  "n_business_id": queuein_data.n_business_id,
                  "n_business_user_id": queuein_data.n_business_user_id,
                  "n_schedule_day_id": queuein_data.n_schedule_day_id,
                  "n_q_id": q_id,
                  "n_status": "D",
                  "n_schedule_type": "D",
                  "n_preference": 1
                }
               
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
                axios
                  .post(UpdateQueueRemove, queuedata)
                  .then(() => {
                  
                  }).catch(() => {
                    console.log("Update queue failed");
                  });  
                  let cancelData = {
                   
                     q_id: q_id,
                   
                   };
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${this.props.access_token}`;
              
                  axios
                    .post(CancelQueue, cancelData)
                    .then(() => {
                  
                    }).catch(() => {
                      console.log("Cancelld Queue");
                    });  
                handlePayment(updateclientqueuestatus,cancel_data);
                toast.error("Your Appointment and Payment has been Cancelled." , {
                  className: "toast-error",
                  }); 
         //       handlePayment('Cancelled');
              } 
              // Reason 2 - When modal is auto closed because of time out
              else if (reason === 'timeout') {
                console.log('timedout');
                let cancel_data = {
                  status:'Cancelled',
                  amount: consulting_fees,
                  payment_status: "",
                  service_charges:service_charges,
                  payment_type:payment_type,
                  q_id: q_id
        
                };
                const handlePayment  = async (url, cancel_data) => {
                  try {
                    const response = await axios.post(url, cancel_data);
                    return response.data;
                  } catch (error) {
                    console.error('Error:', error);
                    throw error;
                  }
                };  
                let queuedata = {
                  "n_business_id": queuein_data.n_business_id,
                  "n_business_user_id": queuein_data.n_business_user_id,
                  "n_schedule_day_id": queuein_data.n_schedule_day_id,
                  "n_q_id": q_id,
                  "n_status": "D",
                  "n_schedule_type": "D",
                  "n_preference": 1
                }
               
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
                axios
                  .post(UpdateQueueRemove, queuedata)
                  .then(() => {
                  
                  }).catch(() => {
                    console.log("Update queue failed");
                  });  
                  let cancelData = {
                   
                    q_id: q_id,
                  
                  };
                 axios.defaults.headers.common[
                   "Authorization"
                 ] = `Bearer ${this.props.access_token}`;
             
                 axios
                   .post(CancelQueue, cancelData)
                   .then(() => {
                 
                   }).catch(() => {
                     console.log("Cancelld Queue");
                   });   
                handlePayment(updateclientqueuestatus,cancel_data);
                toast.error("Your Appointment and Payment has been Cancelled." , {
                  className: "toast-error",
                  }); 
         //       handlePayment('timedout');
              } 
              // Reason 3 - When payment gets failed.
              else {
                console.log('failed');
                console.log(paymentReason);
                let cancel_data = {
                  status:'Cancelled',
                  amount: consulting_fees,
                  payment_status: "",
                  service_charges:service_charges,
                  payment_type:payment_type,
                  q_id: q_id
        
                };
                const handlePayment  = async (url, cancel_data) => {
                  try {
                    const response = await axios.post(url, cancel_data);
                    return response.data;
                  } catch (error) {
                    console.error('Error:', error);
                    throw error;
                  }
                };  
                let queuedata = {
                  "n_business_id": queuein_data.n_business_id,
                  "n_business_user_id": queuein_data.n_business_user_id,
                  "n_schedule_day_id": queuein_data.n_schedule_day_id,
                  "n_q_id": q_id,
                  "n_status": "D",
                  "n_schedule_type": "D",
                  "n_preference": 1
                }
               
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
                axios
                  .post(UpdateQueueRemove, queuedata)
                  .then(() => {
                  
                  }).catch(() => {
                    console.log("Update queue failed");
                  });   
                  let cancelData = {
                   
                    "n_q_id": q_id,
                  
                  };
                 axios.defaults.headers.common[
                   "Authorization"
                 ] = `Bearer ${this.props.access_token}`;
             
                 axios
                   .post(CancelQueue, cancelData)
                   .then(() => {
                 
                   }).catch(() => {
                     console.log("Cancelld Queue");
                   });  
                handlePayment(updateclientqueuestatus,cancel_data);
                toast.error("Your Appointment and Payment has been Cancelled." , {
                  className: "toast-error",
                  }); 
              /*  handlePayment('failed', {
                  paymentReason, field, step, code,
                }); */
              }
            },
          },
          timeout: 300, // Time limit in Seconds
          theme: {
          color: '#037ac7', // Custom color for your checkout modal.
    },
        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.on('payment.submit', (response) => {
         // paymentMethod.current = response.method;
        //  alert(response.method);
        });
        paymentObject.on('payment.failed', function (response){
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
    });
    
        paymentObject.open()
    
      }).catch((e) => { console.log(e) });
    
    }) 
    .catch((e) => { 
      console.log(e)
    });
    
      
    
    
      }
      openbookQueueAppointment = (e)=>{
     
        let { queuein_data, errors } = this.state;
        let listofClientForQueue = this.state.listofClientForQueue;
    
        //Need to change from string to array after fix the queue issue
        // queuein_data.n_client_id = listofClientForQueue;
        console.log(listofClientForQueue);
        queuein_data.n_client_id = listofClientForQueue.length > 0 ? listofClientForQueue[0].client_id : null;
        queuein_data.clientList = listofClientForQueue;
        queuein_data.n_status = "queue-in";
        queuein_data.n_isActive = "yes";
        queuein_data.n_savedtime = new Date();
        if (this.state.selected_doc) {
          queuein_data.n_business_user_id = this.state.selected_doc.value;
          queuein_data.doc_name = this.state.selected_doc.label;
        } else {
          queuein_data.n_business_user_id = ""
        }
    
        queuein_data.type = "createQueue";
        queuein_data.schedule_type = this.state.selected_doc_schedule_type.toLowerCase();
       queuein_data.payment_mode=this.state.payment_mode;
    
        this.setState({ queuein_data: queuein_data });
      
    this.setState({payment_type_error : ""});
        errors = Validation(queuein_data, errors);
        console.log(errors);
        this.setState({ errors: errors });
       
        if (errors.formIsValid) {
    
    
    
          if (errors.formIsValid && queuein_data.schedule_type.toLowerCase() != "appointment") {
           
            /* */
            let listofClientIdForQueue = []
            listofClientForQueue.map((item) => {
              listofClientIdForQueue.push(item.client_id);
            });
            queuein_data.clientList = listofClientIdForQueue;
            // console.log(queuein_data); return false;
    
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            axios
              .post(addQueue_modified, this.state.queuein_data, CONFIG)
              .then((response) => {
                console.log(response.data);
                let result = response.data;
                console.log(result.data[0]);
                if (result.data[0].status === '100') {
                  let qId = result.data[0].qId;
                  if (this.state.payment_mode == 'Direct') {
                    this.closePopup();
                     toast.success("Your Appointment has been confirmed with Dr. "+ this.state.selected_doc.label , {
                      className: "toast-success",
                      });
                     
                    setTimeout(
                      () =>
                        (result.data.status) ?
                          window.location.href = '/dashboard' : ""
                      ,
                      1000
                    );
                    window.location.replace("/scheduleconfirmation/" + qId);
                    return true; 
                    }
                  this.displayRazorpay(result.data[0].qId);
                  let Checkindata = {
                    business_id: queuein_data.n_business_id,
                    client_id: queuein_data.n_client_id,
                    business_user_id : this.state.selected_doc.value,
                    other_comments: queuein_data.other_comments,
                    q_id: qId,
    
                  };
                  console.log(Checkindata);
                  let notificationdata = {
                    message_from: queuein_data.n_business_id,
                    message_to: this.props.user.id,
                    message: "Token has been created successfully.",
                    link: "/scheduleconfirmation/" + qId,
    
                  }
    
    
                  axios
                    .post(addCheckinURL, Checkindata, CONFIG)
                    .then((result) => {
                      if (result.data.status === "1") {
                      
                       
                       
                     
                        axios.post(createNotification, notificationdata).then((res) => {
                          console.log(qId)
                          this.setState({ qId: qId });
                          this.closePopup();
                          this.openbookAppointment();
                         
                         /*  toast.success("Your Appointment has been confirmed with Dr. "+ this.state.selected_doc.label , {
                            className: "toast-success",
                            }); 
                            setTimeout(
                              () =>
                                (res.data.status) ?
                                  window.location.href = '/dashboard' : ""
                              ,
                              1000
                            ); */
                         // window.location.replace("/scheduleconfirmation/" + qId);
                        }).catch((e) => {
    
                        });
    
                      }
                    })
                    .catch((e) => {
    
                    });
    
                 //   this.setState({ bookModel: true,openModal:false });
                } else {
                  console.log(result);
                  // this.closePopup();

                  let errors = this.state.errors;
                  errors.message = result.data[0].message;
                  this.setState({ errors: errors });
                }
              })
              .catch((e) => { });
    
          }
        }
       
      };
        createAppointmentandAddQueue() {

    let { queuein_data, errors } = this.state;
    let listofClientForQueue = this.state.listofClientForQueue;

    //Need to change from string to array after fix the queue issue
    // queuein_data.n_client_id = listofClientForQueue;
    console.log(listofClientForQueue);
    queuein_data.n_client_id = listofClientForQueue.length > 0 ? listofClientForQueue[0].client_id : null;
    queuein_data.clientList = listofClientForQueue;
    queuein_data.n_status = "queue-in";
    queuein_data.n_isActive = "yes";
    queuein_data.n_savedtime = new Date();
    if (this.state.selected_doc) {
      queuein_data.n_business_user_id = this.state.selected_doc.value;
      queuein_data.doc_name = this.state.selected_doc.label;
    } else {
      queuein_data.n_business_user_id = ""
    }

    queuein_data.type = "createQueue";
    queuein_data.schedule_type = this.state.selected_doc_schedule_type.toLowerCase();
   

    this.setState({ queuein_data: queuein_data });

    errors = Validation(queuein_data, errors);
    console.log(errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {



      if (errors.formIsValid && queuein_data.schedule_type.toLowerCase() != "appointment") {

        /* */
        let listofClientIdForQueue = []
        listofClientForQueue.map((item) => {
          listofClientIdForQueue.push(item.client_id);
        });
        queuein_data.clientList = listofClientIdForQueue;
        // console.log(queuein_data); return false;

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(addQueue_modified, this.state.queuein_data, CONFIG)
          .then((response) => {
            console.log(response.data);
            let result = response.data;
            console.log(result.data[0]);
            if (result.data[0].status === '100') {
              let qId = result.data[0].qId;


              let Checkindata = {
                business_id: queuein_data.n_business_id,
                client_id: queuein_data.n_client_id,
                business_user_id : this.state.selected_doc.value,
                other_comments: queuein_data.other_comments,
                q_id: qId,

              };
              console.log(Checkindata);
              let notificationdata = {
                message_from: queuein_data.n_business_id,
                message_to: this.props.user.id,
                message: "Token has been created successfully.",
                link: "/scheduleconfirmation/" + qId,

              }


              axios
                .post(addCheckinURL, Checkindata, CONFIG)
                .then((result) => {
                  if (result.data.status === "1") {
                  
                    axios
                    .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:this.state.selected_doc.value}, CONFIG)
                    .then((res) => {
                      let response = res.data;
                      console.log(response);
                      // return false;
                      if (response.status !== "0") {
                        // window.location.href = "/vendor/" + response.data;
                        this.setState({ doctor_details: response.data });
                      }
                
                    })
                    .catch((e) => {
                      console.log(e);
                    });

                    axios
      .post(getpaymenttypeforbusiness, { user_id: this.state.queuein_data.n_business_id}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
  
        console.log(data.data[0].payment_type);
        if (data.status === "1")
          this.setState({
            paymenttypeforhospital: data.data[0].payment_type
            
          });
        })
      .catch((e) => { });
                 
                    axios.post(createNotification, notificationdata).then((res) => {
                      console.log(qId)
                      this.setState({ qId: qId });
                      this.closePopup();
                      this.openbookAppointment();
                     
                     /*  toast.success("Your Appointment has been confirmed with Dr. "+ this.state.selected_doc.label , {
                        className: "toast-success",
                        }); 
                        setTimeout(
                          () =>
                            (res.data.status) ?
                              window.location.href = '/dashboard' : ""
                          ,
                          1000
                        ); */
                     // window.location.replace("/scheduleconfirmation/" + qId);
                    }).catch((e) => {

                    });

                  }
                })
                .catch((e) => {

                });


            } else {
              console.log(result);
              // this.closePopup();
              let errors = this.state.errors;
              errors.message = result.data[0].message;
              this.setState({ errors: errors });
            }
          })
          .catch((e) => { });

      } else if (errors.formIsValid) {
        this.props.history.push({
          pathname: "/client_book_appointments",
          state: {
            business_id: this.state.queuein_data.n_business_id,
            business_name: this.state.queuein_data.business_name,
            business_user_id: this.state.queuein_data.n_business_user_id,
            doc_name: this.state.queuein_data.doc_name,
            other_comments: this.state.queuein_data.other_comments,
            client_details: queuein_data.clientList[0],
          }
        });
      }
    }
  }

  createAppointment() {

    let { queuein_data, errors } = this.state;
    let listofClientForQueue = this.state.listofClientForQueue;

    //Need to change from string to array after fix the queue issue
    // queuein_data.n_client_id = listofClientForQueue;
    console.log(listofClientForQueue);
    queuein_data.n_client_id = listofClientForQueue.length > 0 ? listofClientForQueue[0].client_id : null;
    queuein_data.clientList = listofClientForQueue;
    queuein_data.n_status = "queue-in";
    queuein_data.n_isActive = "yes";
    queuein_data.n_savedtime = new Date();
    if (this.state.selected_doc) {
      queuein_data.n_business_user_id = this.state.selected_doc.value;
      queuein_data.doc_name = this.state.selected_doc.label;
    } else {
      queuein_data.n_business_user_id = ""
    }

    queuein_data.type = "createQueue";
    queuein_data.schedule_type = this.state.selected_doc_schedule_type.toLowerCase();
   

    this.setState({ queuein_data: queuein_data });

    errors = Validation(queuein_data, errors);
    console.log(errors);
    this.setState({ errors: errors });


 if (errors.formIsValid) {
        this.props.history.push({
          pathname: "/client_book_appointments",
          state: {
            business_id: this.state.queuein_data.n_business_id,
            business_name: this.state.queuein_data.business_name,
            business_user_id: this.state.queuein_data.n_business_user_id,
            doc_name: this.state.queuein_data.doc_name,
            other_comments: this.state.queuein_data.other_comments,
            client_details: queuein_data.clientList[0],
          }
        });
      }
    
  }
  handleselectUpdateChange = (selected) => {
    console.log(selected);
    this.setState({ visit_type: selected })
  }
  handlePaymentSelect = (e) => {
    const { name, value } = e.target;
    let errors = this.state.errors;
    if(e.target.value)
    {
      errors.payment_type_error="";
    }
 
    this.setState({
      [name]: value,
      payment_type_error:"",
      
    });
  }
  selectMemberForQueueIn(value, element) {

    console.log(value);
    // let selectedClientId = value.client_id;
    let selectedClientId = value;
    let { errors, listofClientForQueue } = this.state

    if (!listofClientForQueue.includes(selectedClientId)) {
      listofClientForQueue.push(selectedClientId);
    } else {
      const deleteIndex = listofClientForQueue
        .map((item) => item)
        .indexOf(selectedClientId);
      listofClientForQueue.splice(deleteIndex, 1);
    }
    if (listofClientForQueue.length === 0) {
     
      errors.n_client_id = "Please select atleast one person";
      errors.message="";
      errors.formIsValid = false;
      this.setState({ errors: errors });
    } else {
      errors.n_client_id = "";
      errors.message="";
      this.setState({ listofClientForQueue });
    }
  }
  validateMaxLength(name, value, max) {
    let data = this.state.searchData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ searchData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    data[name] = value;
    if (
      name === "doctor_name" ||
      name === "city"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ searchData: data });

  };
  handleQueueInput = (e) => {
    let data = this.state.queuein_data;
    let errors=this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    errors[name]="";
    this.setState({ queuein_data: data,errors });
  }
  handleselectDocChange = (selectedOptions, e) => {
    let data = this.state.selected_doc;
    data = selectedOptions;

   console.log(selectedOptions);
   


    this.setState({ selected_doc: data });

    console.log(this.state.doctor_appointment_detail_list);

    let appointmentDetails = this.state.doctor_appointment_detail_list;

    let selected_appointment = this.state
      .selected_doctor_appointment_detail_list;

    let selected_doc_schedule_type = this.state.selected_doc_schedule_type;

    appointmentDetails.forEach((element, index) => {
      console.log(element.business_user_id);
      console.log(element.doctor_Appointments);
      if (element.business_user_id == selectedOptions.value) {
        console.log("true");
        selected_appointment = element.doctor_Appointments;
        selected_doc_schedule_type = element.schedule_type;
      } else {
        console.log("false");
      }
    });
    this.setState({
      selected_doctor_appointment_detail_list: selected_appointment,
      selected_doc_schedule_type: selected_doc_schedule_type
    });


    axios
    .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:selectedOptions.value}, CONFIG)
    .then((res) => {
      let response = res.data;
      console.log(response);
      // return false;
      if (response.status !== "0") {
        // window.location.href = "/vendor/" + response.data;
        this.setState({ doctor_details: response.data,payment_mode:"" });
      }

    })
    .catch((e) => {
      console.log(e);
    });
  
  };

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    if (e.name === "business_name" && null != selectedOptions) {
      console.log(selectedOptions);
      data.latitude = selectedOptions.latitude;
      data.longitude = selectedOptions.longitude;
          this.searchBusiness();

    } else {
      data.latitude = this.state.latitude;
      data.longitude = this.state.longitude;
    }
    this.setState({ searchData: data });

    // this.searchBusiness();
  };
  handlespecialityselectChange = (selectedOptions, e) => {
     let data = this.state.searchData;
     data[e.name] = selectedOptions;
      data.latitude = this.state.latitude;
      data.longitude = this.state.longitude;
      this.setState({ searchData: data });
   
  };
  
  reviewChange(value, field) {
    let data = this.state.searchData;
    console.log(data);
    /* To uncheck the star*/
    if (data.reviews == 5) {
      value = 0;
    }

    var searchReviewId = document.getElementById("SearchReviewId");
    for (var i = 0; i < 5; i++) {
      if (i < value && data.reviews != 5) {
        searchReviewId.children[i].classList.add("fa-star");
      } else {
        searchReviewId.children[i].classList.remove("fa-star");
      }
    }

    data.reviews = value;
    console.log(value);
    this.setState({ searchData: data });
  }
  handleDistanceChange = (event) => {
    let data = this.state.searchData;
    data.distance = event.target.value;
    this.setState({ searchData: data });
  };
  handleTimingSelect = (event) => {
    let data = this.state.queuein_data;
    let errors=this.state.errors;
    data.n_schedule_day_id = event.target.value;
    errors.n_schedule_day_id="";
    this.setState({ queuein_data: data });
  };
  getmyLocation = () => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        console.log(position.coords);
        console.log("position.coords");
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }, () => {
          this.getData();
        })
      }, (error) => {
        // this.setState({ latitude: '9.9261153', longitude: '78.1140983' },
        //   () => {
        //     this.getData();
        //   })

      }, { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 })
    }
    else {
      this.getData();
    }
  }

  componentDidMount = () => {
    console.log(this.props.user.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.user.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            timeout: data.data.timeout

          });
      })
      .catch((e) => { });

      axios
      .post(getServiceChargesbycategory, {category : 'Direct'}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.service_charges);
        if (data.status == "1")
          this.setState({
            directservice_charges: data.data.service_charges
            
          });
        })
      .catch((e) => { });
      axios
      .post(getServiceChargesbycategory, {category : 'Online'}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.service_charges);
        if (data.status == "1")
          this.setState({
            onlineservice_charges: data.data.service_charges
            
          });
        })
      .catch((e) => { });

    this.getData();
    this.getmyLocation();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .get(getAllBusinessList, null, CONFIG)
      .then((list) => {
        console.log(list)
        let business = [];
        let userlist = list.data.data;
        userlist.forEach((element, index) => {
          business.push({ label: element.business_name, value: element.user_id })
         
        });

        this.setState({ business_list: business });
      
      })
      .catch((e) => { console.log(e) });

      
      

      
    
  }

  getData = () => {
    console.log(this.state.latitude);
    let searchData = this.state.searchData;
    searchData.latitude = this.state.latitude ? this.state.latitude.toString() : "";
    searchData.longitude = this.state.longitude ? this.state.longitude.toString() : "";
    this.setState({ searchData });
   // this.reviewChange(this.state.searchData.reviews);
  //  this.searchBusiness();
    console.log("Latitude is :", this.state.latitude);
    console.log("Longitude is :", this.state.longitude);

    let loggedInClient = {};
    loggedInClient.client_id = this.props.user.id;
    loggedInClient.name = this.props.user.first_name;
    loggedInClient.relationship = "Self";

    this.setState({ loggedInClient });
    // this.setState({ latitude: latitude, longitude: longitude });

    //To get the Family details
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getClientFamilyDetails, { client_id: this.props.user.id }, CONFIG)
      .then((client_family) => {
        let list = [];
        list.unshift(this.state.loggedInClient);
        this.setState({ family_list: list });

        let dataArr = client_family.data.data;

        let family_list = this.state.family_list;
        dataArr.forEach((element, index) => {
          let family_member = {};
          family_member.client_id = element.user_id;
          family_member.name = element.first_name + " " + element.last_name;
          family_member.relationship = element.relationship;

          family_list[index + 1] = family_member;
          this.setState({ family_list: family_list ,client_name:family_member.name});
        });
        this.setState({ enableqin: true });
      })
      .catch((e) => { });
  }


  clearSearchData = () => {
    var searchData = this.state.searchData;
    let searchErrorMessage=this.state.searchErrorMessage;
    searchData.business_name = "";
    searchData.doctor_name = "";
    searchData.facility = "";
    searchData.speciality = "";
    searchData.gender = "";
    searchData.city = "";
    searchData.distance = 5;
    searchData.distance_type = "KM";
    this.props.setSearchData(searchData);
    this.setState({  searchErrorMessage:""  });
    this.setState({ searchData: searchData,
      searchresults:{
        data: [],
      } 
    });
    searchData.reviews = 0;
   // this.getData();
    this.getmyLocation();
  };
  searchBusiness = () => {
    console.log("param");

    
   
   let params = this.state.searchData;
    console.log(params.speciality);
    if((null===params.speciality || "" === params.speciality) && params.business_name == "")       
    {
      this.setState({searchErrorMessage:"Choose the Speciality field and Distance field to search.",});
      return;
    } 
    else 
    {
      this.setState({ searchErrorMessage: "", });
    }
     //  let params = this.state.searchData;
    console.log(params);
    let data = {
      category: params.category ? params.category.label : "",
      business_name: params.business_name ? params.business_name.label : "",
      doctor_name: params.doctor_name ? params.doctor_name : "",
      facility: params.facility ? params.facility.value : "",
      speciality: params.speciality ? params.speciality.value : "",
      city: params.city,
      gender: params.gender ? params.gender.value : "",
      distance: params.distance,
      //distance: "",
      reviews: params.reviews,
      latitude: params.latitude,
      longitude: params.longitude,
      // latitude: "",
      // longitude: "",
      distance_type: params.distance_type,
      // distance_type: "",
      visit_type: params.visit_type,
    };
  
    console.log(data);
    axios
      .post(searchBusiness, data, CONFIG)
      .then((searchresults) => {
        console.log("business list");

        if (searchresults.data.status === "1") {
          console.log(searchresults);
         
          this.setState({ searchresults: searchresults.data,latitude:searchresults.data.data[0].latitude,longitude:searchresults.data.data[0].longitude });
          let result = this.state.searchresults;
          if (result.data.length) {
            result.data.forEach((element, index) => {

              // console.log(element);
              /*      if (element.user_id !== "") {
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
   
                axios
                  .get(queueBusinessURL + element.user_id)
                  .then((details) => {
                    console.log("got response for waittime");
                    element.wait_time = details.data.waitTime;
                  })
                  .catch((e) => {});
              }*/
              element.s_no = index+1;
              result.data[index] = element;
            });
            console.log(result);
            let records=result.data;
            console.log(result.data);
            window.setTimeout(() => {
              this.setState({ searchresults: result,records:records });
            }, 500);
          }
        } else {
          this.setState({ searchErrorMessage: searchresults.data.message +",”Try adjusting the distance limit.”"});
          this.setState({ 
            searchresults:{
              data: [],
            } 
          });
           window.setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        }
      })
      .catch((e) => {
        console.log("business list failed");
      });
  };



  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  displayInfo = () => {
    return this.state.searchresults
      ? this.state.searchresults.data.map((business, index) => {
        return (
          <InfoWindow
            marker={this.state.activeMarker}
            mapCenter={{
              lat: business.latitude,
              lng: business.longitude,
            }}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h1>{business.business_name}</h1>
            </div>
          </InfoWindow>
        );
      })
      : "";
  };

  onMarkerClick = (props, marker, e) => {
    console.log(props);
    console.log(marker);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };
  getCenterValue = (mapProps, map) => {
    console.log(mapProps.center);
    let url = new URL(map.mapUrl);
    let ll = url.searchParams.get("ll");
    console.log(ll.split(","));

    console.log("param");
    let params = this.state.searchData;
    params.latitude = ll.split(",")[0];
    params.longitude = ll.split(",")[1];
    console.log(params);
    //console.log(params.latitude);
    //console.log(params.longitude);
    console.log(this.state.searchresults);
    //this.setState({searchData: params});

    let data = {
      category: params.category ? params.category.label : "",
      business_name: params.business_name ? params.business_name.label : "",
      doctor_name: params.doctor_name ? params.doctor_name : "",
      facility: params.facility ? params.facility.value : "",
      speciality: params.speciality ? params.speciality.value : "",
      city: params.city,
      gender: params.gender ? params.gender.value : "",
      distance: params.distance,
      reviews: params.reviews,
      latitude: ll.split(",")[0],
      longitude: ll.split(",")[1],
      distance_type: params.distance_type,
      visit_type: params.visit_type,
    };


    axios
      .post(searchBusiness, data, CONFIG)
      .then((searchresults) => {
        console.log("business list");

        if (searchresults.data.status === "1") {
          console.log(searchresults);
          // this.setState({ searchresults: searchresults.data });
          let result = this.state.searchresults;
          if (result.data.length) {
            result.data.forEach((element, index) => {
              result.data[index] = element;
            });
            window.setTimeout(() => {
              // this.setState({ searchresults: result });
            }, 500);
          }
        } else {
          this.setState({ error: searchresults.data.message });
          window.setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 5000);
        }
      })
      .catch((e) => {
        console.log("business list failed");
      });




  };
  displayMarkers = () => {
    let mapImage = require("../assets/images/map-pin.png")
    let iconMarker = new window.google.maps.MarkerImage(
      mapImage,
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(100, 100)
    );
    return this.state.searchresults
      ? this.state.searchresults.data.map((business, index) => {
        //This need to replcae real waiting time from queue table


      
       let waitingtime = business.expected_wait_time
        //  console.log(waitingtime)
        // if (!before30min) {
        return (
          <Marker
            key={index}
            id={index}
            name={business.business_name}
            position={{
              lat: business.latitude,
              lng: business.longitude,
            }}
            label={{
              text: waitingtime,
              color: "black",
              fontSize: "20px",
            }}
            cursor="pointer"
            disableDefaultUI={true}
            icon={iconMarker}
            onClick={this.showFamilydetail.bind(this, business)}
          />
        );
        // }

      })
      : "";
  };
  render() {
    let {
      business_list,
      errors,
      searchData,
      searchresults,
      queuein_data,
      family_list,

    } = this.state;
    let category_array = this.props.appdata.length
      ? this.props.appdata[0].category
      : [];
    let category = [];

    category_array.forEach((element, index) => {
      category.push({ value: element.id, label: element.category_name });
    });
    category_array = category;

    let facility_list = this.props.appdata.length
      ? this.props.appdata[0].facilities
      : [];
    let facility = [];

    facility_list.forEach((element, index) => {
      facility.push({ value: element.id, label: element.facility_name });
    });
    facility_list = facility;
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;
    const { selected_doc, doctor_list, timeout, searchErrorMessage,loading,Todaydate,thisday } = this.state;

    return (
      <Fragment>
        <div id="layout-wrapper">
          {/* <Spinner loading={this.state.loading} /> */}
         {loading && <Dnaspinner />}
          <div className="main-content bacfot">
            <div className="mainpage">
              <Header user_id={this.props.user.id} />
              <div className="main-box">
                <div className="sidemenu">
                  <SideBar />
                </div>


                <section className="main-body hospital-profilegap">
                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={8000}  showToast={true} closeOnClick={false} pauseOnHover />

                  <IdleTimeOutModal
                    showModal={this.state.showModal}
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                  />

                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={timeout} />


                  <div className="container-fluid">
                    <div className="checkout-tabs">
                      <div className="">
                        <h4 className="font-weight-bold"> Queue-in</h4>
                      </div>
                      <div className="row mt-4">

                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-8">

                              <div className="card h616">
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="row">
                                        <div className="col-sm-8">
                                          <div className="frllftfull">
                                            <div className="form-group frllft">
                                              <label>Find Hospital</label>
                                            </div>
                                            <div className="form-group sform-control frlrit">
                                              <div className="input-group">
                                                <span className="input-group-addon">
                                                  <i className="glyphicon fa fa-search icontextb"></i>
                                                </span>
                                                <Select maxMenuHeight={120}
                                                  className="form-control p-0"
                                                  name="business_name"
                                                  value={searchData.business_name}
                                                  onChange={
                                                    this.handleselectChange
                                                  }
                                                  options={business_list}
                                                  isClearable={true}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <Map
                                          className="mapclas"
                                          google={this.props.google}
                                          zoom={10}
                                          style={mapStyles}


                                          center={{
                                            lat: this.state.latitude,
                                            lng: this.state.longitude
                                          }}
                                          onDragend={this.getCenterValue}
                                        >
                                          {this.displayMarkers()}
                                          <InfoWindow
                                            marker={this.state.activeMarker}
                                            visible={this.state.showingInfoWindow}
                                          >
                                            <div>
                                              <h1>
                                                {this.state.selectedPlace
                                                  ? this.state.selectedPlace.name
                                                  : ""}
                                              </h1>
                                            </div>
                                          </InfoWindow>
                                        </Map>

                                        <div className="col-sm-12 mt-3 adminPharmacyTable">
                                          {/* <h5 className="regxs regxsd">
                                            Choose Hospital
                                          </h5>
                                          <hr className="rowline" /> */}
                                          {searchresults.data.length ? (
                                           <ReactDatatable
                                           config={this.config}
                                           records={this.state.records}
                                           columns={this.columns}
                                           className="table table-bordered table-striped kas  mobile_table"
                                         />
                                          ) : (
                                                ""
                                              )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 pl0">
                              <div className="card h616">
                              <div className="card-header"> <div className="card-title">Choose Hospital</div> </div>
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="row">
                                        <div className="col-sm-12">
                                     
                                          <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                                          <div className="frllftfull">
                                            <div className="form-group frllft2">
                                              <label>Doctor Name</label>
                                            </div>
                                            <div className="form-group frlrit2">
                                              <div className="input-group">
                                                <InputField
                                                  inputType={"text"}
                                                  name={"doctor_name"}
                                                  controlFunc={this.handleInput}
                                                  content={searchData.doctor_name}
                                                  placeholder={""}
                                                  max={50}
                                                />

                                              </div>
                                            </div>
                                          </div>
                                          <div className="frllftfull">
                                            <div className="form-group frllft2">
                                            <Label title="Speciality" isMandatory={true} />
                                              {/* <label isMandatory={true}>Speciality</label> */}
                                            </div>
                                            <div className="form-group sform-control frlrit2">
                                              <Select maxMenuHeight={120}
                                                isClearable={true}
                                                className="form-control p-0"
                                                name="speciality"
                                                // isMulti
                                                value={searchData.speciality}
                                                onChange={this.handlespecialityselectChange}
                                                options={specialities_array}                                                                                           
                                              />
                                            </div>
                                          </div>
                                          <div className="frllftfull">
                                            <div className="form-group frllft2">
                                              <label>Facility</label>
                                            </div>
                                            <div className="form-group sform-control frlrit2">
                                              <Select maxMenuHeight={120}
                                                isClearable={true}
                                                className="form-control p-0"
                                                name="facility"
                                                value={searchData.facility}
                                                onChange={this.handleselectChange}
                                                options={facility_list}
                                              />
                                            </div>
                                          </div>
                                          <div className="frllftfull">
                                            <div className="form-group frllft2">
                                              <label>Gender</label>
                                            </div>
                                            <div className="form-group sform-control frlrit2">
                                              <Select maxMenuHeight={120}
                                               isClearable={true}
                                                className="form-control p-0"
                                                name="gender"
                                                value={searchData.gender}
                                                onChange={this.handleselectChange}
                                                options={gender}
                                              />
                                              {/* <select className="form-control">
                                              <option>Male</option>
                                              <option>Select</option>
                                              <option>Select</option>
                                            </select> */}
                                            </div>
                                          </div>
                                          <div className="frllftfull">
                                            <div className="form-group frllft2">
                                              <label>City</label>
                                            </div>
                                            <div className="form-group frlrit2">
                                              <InputField
                                                inputType={"text"}
                                                // title={"Full name"}
                                                name={"city"}
                                                controlFunc={this.handleInput}
                                                content={searchData.city}
                                                placeholder={""}
                                                max={30}
                                              />
                                            </div>
                                          </div>

                                          <div className="frllftfull">
                                            <div className="form-group frllft2">
                                              <label>Distance </label>
                                            </div>
                                            <div className="form-group ">
                                              <div className="col-sm-12">
                                                <div className="fltx">
                                                  <output name="rangeVal">
                                                    {searchData.distance}
                                                  </output>
                                                </div>
                                                <div className="form-check fltx">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="distance_type"
                                                    id="kM"
                                                    onChange={this.handleInput}
                                                    value="KM"
                                                    checked={
                                                      searchData.distance_type ==
                                                      "KM"
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="KM"
                                                  >
                                                    KM
                                                  </label>
                                                </div>
                                                <div className="form-check fltx">
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="Miles"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={this.handleInput}
                                                      name="distance_type"
                                                      id="Miles"
                                                      value="Miles"
                                                      checked={
                                                        searchData.distance_type ==
                                                        "Miles"
                                                      }
                                                    />
                                                    Miles
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group frlrit2">
                                              <input
                                                className="form-control"
                                                type="range"
                                                id="range_01"
                                                min="0"
                                                max="200"
                                                step="1"
                                                value={searchData.distance}
                                                onChange={
                                                  this.handleDistanceChange
                                                }
                                              />
                                            </div>
                                          </div>

                                          {/* <div className="frllftfull">
                                            <div className="form-group frllft2">
                                              <label>Reviews </label>
                                            </div>
                                            <div className="form-group frlrit2 searchStar reviewsdesign">
                                              <p id="SearchReviewId">
                                                <i
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                  onClick={this.reviewChange.bind(
                                                    this,
                                                    1
                                                  )}
                                                ></i>
                                                <i
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                  onClick={this.reviewChange.bind(
                                                    this,
                                                    2
                                                  )}
                                                ></i>
                                                <i
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                  onClick={this.reviewChange.bind(
                                                    this,
                                                    3
                                                  )}
                                                ></i>
                                                <i
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                  onClick={this.reviewChange.bind(
                                                    this,
                                                    4
                                                  )}
                                                ></i>
                                                <i
                                                  className="fa fa-star-o"
                                                  aria-hidden="true"
                                                  onClick={this.reviewChange.bind(
                                                    this,
                                                    5
                                                  )}
                                                ></i>
                                              </p>
                                            </div>
                                          </div>
    */}                                       
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-12 text-center">
                                          <div className="form-group mb-0">
                                            <button
                                              onClick={this.searchBusiness}
                                              className="signupbtn mr-3 abtn"
                                            >
                                              Search
                                            </button>
                                            <button
                                              onClick={this.clearSearchData}
                                              className="signupbtn abtn"
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <Footerad /> */}
                        </div>
                      </div>
                    </div>
                  </div>
             
                </section>
                <FooterSection />
              </div>
            </div>
          </div>
          <Modal show={this.state.openModal} onHide={this.closePopup}>



            <Modal.Header closeButton className="model-hptd">
              <Modal.Title className="h5 model-text-color">
                Queue-In
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">

              <h5 className="text-center">
                {queuein_data.business_name} , {queuein_data.address}{" "}
              </h5>
              <input
                type="hidden"
                name="business_id"
                value={queuein_data.businessId}
              />
                <Label className="ffgrd f-w-b" title ="Who is this visit for?" isMandatory="true" />
              
                      <div>
                        {" "}
                        <span style={{ color: "#f46a6a" }}>
                          {this.state.error}
                        </span>
                      </div>
                      <div className="w-100 text-center">
                      <SpanField
                        classname="text-danger"
                        title={errors.n_client_id || errors.n_business_id || errors.n_business_user_id || errors.message}
                      />
                      </div>
                   
                        <div className="dgrids">
                      {family_list.map((el, i) => (
                        <Fragment key={i}>
                        
                          <div className="square-switch ">
                            <input
                              type="checkbox"
                              id={"square-switch" + (i + 1)}
                              switch="none"
                              onClick={this.selectMemberForQueueIn.bind(
                                this,
                                el
                              )}
                              
                            />
                            <label
                              className="w-100"
                              htmlFor={"square-switch" + (i + 1)}
                              data-on-label={getPatientsubstring(el.name)}
                              data-off-label={
                                getPatientsubstring(el.name) + "-" + el.relationship
                              }
                            ></label>
                          </div>
                        
                         
                        </Fragment>
                      ))}
                      </div>

              <div className="frllftfull">
                <div className="form-group frllft m8">
                  <Label className="ffgrd f-w-b" title ="Select Doctor" />
                 </div>
                <div className="form-group frlrit">
                  <div className="position-relative">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text inpico">
                          <i className="fas fa-search"></i>
                        </span>
                      </div>
                      {/* <Select maxMenuHeight={120}
                        className="form-control p-0"
                        name="selected_doc"
                        defaultvalue={doctor_list[0]}
                        onChange={this.handleselectDocChange}
                        options={doctor_list}
                        placeholder="Select a doctor"
                      /> */}
                      <Select maxMenuHeight={120}
                        className="form-control p-0"
                        name="selected_doc"
                        value={selected_doc}
                        onChange={this.handleselectDocChange}
                        options={doctor_list}
                        placeholder={"Select Doctor"}
                       
                      />
                      

                    </div>
                    <label className="mrgtp">
                                
                                
                                 {selected_doc ? selected_doc.speciality_name:""}
                                 </label>
                  </div>
                </div>
              </div>

              <div className="frllftfull">
                <div className="form-group frllft mbbre">
                <Label className="ffgrd f-w-b" title ="Select timing" isMandatory="true" />
                  </div>
                {(this.state.selected_doc_schedule_type.toLowerCase() == "appointment") ?
                  "Click Next to select the time" :

                  <div className="row">
                    {this.state.selected_doctor_appointment_detail_list ? (
                      this.state.selected_doctor_appointment_detail_list.map(
                      (option, i) => {
                        return (
                          <Fragment key={option.event_name + i}>
                            <div className="col-sm-12">
                              <div className="form-check fltx">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={option.event_name}
                                  id={option.event_name}
                                  value={option.schedule_day_id}
                                  checked={
                                    this.state.queuein_data
                                      .n_schedule_day_id ===
                                    option.schedule_day_id
                                  }
                                  onChange={this.handleTimingSelect}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={option.event_name}
                                >
                                  {option.event_name} ({option.starttime} -{" "}
                                  {option.endtime})
                                </label>
                                
                              </div>
                              <label className="pull-right">
                                Wait time: {option.wait_time}</label>
                            </div>
                          </Fragment>
                          
                        );
                      }
                    )
                    ) :""}
                    <SpanField
                        classname="text-danger"
                        title={errors.n_schedule_day_id || errors.message}
                      />
                  </div>
                }
              </div>
              <div className="frllftfull">
                <div className="form-group">
                  <Label className="ffgrd f-w-b" title ="Reason for visit" isMandatory="true" />
                  <TextArea
                    rows={4}
                    isMandatory={true}
                    content={this.state.queuein_data.other_comments}
                    name={"other_comments"}
                    controlFunc={this.handleQueueInput}
                    placeholder={""}
                    maxLength={100}
                  />
                  <SpanField
                        classname="text-danger"
                        title={errors.other_comments}
                      />
                </div>
              </div>
              {(this.state.selected_doc_schedule_type.toLowerCase()!='appointment' && this.state.paymenttypeforhospital=="Online")||(this.state.selected_doc_schedule_type.toLowerCase()!='appointment' && this.state.paymenttypeforhospital=="Direct")?
              (<div className="frllftfull">
                 < >
                        <div className="form-group frllft">
                          <label>Payment Type</label>
                        </div>
                       
                          <div className="form-group frlrit">
                        {this.state.paymenttypeforhospital=="Online"?
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">

                                <input
                                  checked={this.state.payment_mode==="Online"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Online"
                                  value="Online"
                                  name="payment_mode"

                                  onChange={this.handlePaymentSelect}


                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay online"
                                >
                                  Online
                                </label>
                               
                              </div>
                              
                            </div>
                            <SpanField classname="text-danger" title={errors.payment_type_error} />
                          </div>
                           :""}
                         {this.state.paymenttypeforhospital==="Direct"?
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">
                              
                                <input
                                 checked={this.state.payment_mode==="Direct"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Direct"
                                  name="payment_mode"
                                  value="Direct"
                                  onChange={this.handlePaymentSelect}

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay at Clinic"
                                >
                                  Cash
                                </label>
                               
                              </div>
                             
                            </div>
                            <SpanField classname="text-danger" title={errors.payment_type_error} />
                          </div>
                          :""}
                          </div>
                        </>
                      </div>):""}

                  {(this.state.selected_doc_schedule_type.toLowerCase() !='appointment' && this.state.payment_mode=="Online")?
                    (<><div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Consulting Fees</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.doctor_details ? this.state.doctor_details.consulting_fees : ""
                        } INR
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Service Charges</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.directservice_charges} INR
                      </div>
                    </div></>): ""}
                    {this.state.payment_mode=="Direct"?
                    (<div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Service Charges</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.directservice_charges} INR
                      </div>
                    </div>):""}

            </Modal.Body>
            <Modal.Footer>
                <div className="text-center w-100 m-0">
                {this.state.selected_doc_schedule_type.toLowerCase()=='appointment'?
                (<Button
                className=" signupbtn abtn pdrs"
                 onClick={this.createAppointment.bind(this)} 
           
              >
                Next
              </Button>):""} &nbsp;&nbsp;
              {this.state.selected_doc_schedule_type.toLowerCase()!='appointment'?
             ( <Button
                className=" signupbtn abtn pdrs"
               /*  disabled={this.state.IsNext} */
                 onClick={this.openbookQueueAppointment.bind(this)} 
           
              >
                Next
              </Button>):""} &nbsp;&nbsp;
              
              <Button
                onClick={this.closePopup}
                className=" bg-red signupbtn abtn pdrs"
              >
                Cancel
              </Button>
                </div>
              

            </Modal.Footer>



          </Modal>

          <Modal
              show={this.state.bookModel}
              onHide={this.closebookPopup}
              centered={true}
            >
              <Modal.Header closeButton className="model-hptd">
                <Modal.Title className="h5 model-text-color">
                  Book a Token Appointment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Hospital Name</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{queuein_data.business_name}</span>
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Doctor Name</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{queuein_data.doc_name}</span>
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Token Date</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{this.state.currentdate}</span>
                      </div>
                    </div>

                    
                   {/*  <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Payment Type</label>
                      </div>
                      <div className="form-group frlrit">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="visit_type"
                         
                          value={this.state.visit_type}
                          onChange={this.handleselectUpdateChange}
                          options={VISIT_TYPE}
                      
                        />
                      </div>
                    </div> */}
                    
                      
                      <div className="frllftfull">
                        <div className="form-group frllft">
                          <label>Payment Type</label>
                        </div>




                        <Fragment >
                          <div className="form-group frlrit">
                        {this.state.paymenttypeforhospital=="Online"?
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">

                                <input
                                  checked={this.state.payment_mode==="Online"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Online"
                                  value="Online"
                                  name="payment_mode"

                                  onChange={this.handlePaymentSelect}


                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay online"
                                >
                                  Online
                                </label>
                               
                              </div>
                              
                            </div>
                            <SpanField classname="text-danger" title={this.state.payment_type_error} />
                          </div>
                           :""}
                         {this.state.paymenttypeforhospital==="Direct"?
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">
                              
                                <input
                                 checked={this.state.payment_mode==="Direct"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Direct"
                                  name="payment_mode"
                                  value="Direct"
                                  onChange={this.handlePaymentSelect}

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay at Clinic"
                                >
                                  Cash
                                </label>
                               
                              </div>
                             
                            </div>
                            <SpanField classname="text-danger" title={this.state.payment_type_error} />
                          </div>
                          :""}
                          </div>
                        </Fragment>

                      </div>
                  {this.state.payment_mode=="Online"?
                    (<div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Consulting Fees</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.doctor_details ? this.state.doctor_details.consulting_fees : ""
                        } INR
                      </div>
                    </div>):""}
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Service Charges</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.directservice_charges} INR
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group">
                        <label>Reason for visit</label>

                        <TextArea
                          rows={7}
                          content={queuein_data.other_comments}
                          name={"other_comments"}
                          controlFunc={this.handleQueueInput}
                          placeholder={""}
                          maxLength={100}
                        />
                        <SpanField classname="text-danger" title={this.state.other_comments_error} />
                      </div>
                    </div>
                    

                  </div>
                </div>
                <div>
                  <div className="row reverse">
                    <div className="form-group mb-0 w-100 text-center">
                      {this.state.reschedule ? (<Fragment>
                        <button className="grnbtn reddg" onClick={this.beforerescheduleclose}>
                          Close
                        </button>&nbsp;
                        <button className="grnbtn" onClick={this.cancelAppointment}>
                          Cancel
                        </button>
                        &nbsp;
                        <button className="grnbtn" onClick={this.Onreschedule}>
                          Reschedule
                        </button>
                        &nbsp;
                       {/*  <a className="grnbtn joinbtn" target="_blank" href={"https://dqqpa02ik3.execute-api.ap-south-1.amazonaws.com/Prod?m=" + this.state.selectedHours.client_appt_id + '&name=' + this.state.client_details.name + '&type=p'} //onClick={this.Onreschedule}
                        >
                          Join
                        </a> */}
                        </Fragment>) : (
                        <Fragment>
                           <div className="text-center w-100 m-0">
                           <button className="grnbtn" onClick={this.bookQueueappointment}>
                            Book
                          </button>&nbsp;
                          <button className="grnbtn reddg" onClick={this.closebookPopup}>
                            Close
                          </button>
                           </div>
                      
                         </Fragment>)}
                      {/* <button className="grnbtn" onClick={this.bookAppointment}>
                      Book
                  </button> */}
                    </div>

                  </div>

                </div>
              </Modal.Body>
            </Modal>


        </div>



      </Fragment >
    );
  }
}



// export default Search;
export default connect(mapStateToProps, mapDispatchToProps)(
  GoogleApiWrapper({
    apiKey: "AIzaSyC4pBKGj8xlAtXpfpV5jhVa7NbMt_8Yb4g",
  })(Search)
);
