import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import {
    getSettingsData
} from "../config/url.json";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import FooterSection from "../components/UI/FooterSection";

import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class OffersWeb extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            settingsData: {},
            loading: false,
            
        }
       
    }

    componentDidMount() {

       

        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getSettingsData, null)
            .then((res) => {

                let settingsData = [];
                res.data.data.forEach((element, index) => {
                    let name = element.name;
                    settingsData[name] = element.value
                })
                console.log(settingsData)
                this.setState({ settingsData: settingsData })

            })
            .catch((e) => { });
    }


    render() {
        const { settingsData } = this.state;


        return (

            <div className="main-content bacfot">

                <div className="mainpage">
                    <section className="my-head">
                        <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div>
                    </section>
                    <div className="main-box">
                        <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div>

                        <section className="main-body hospital-profilegap">
                            

                            <div className="container-fluid checkout-tabs">
                               

                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="font-weight-bold">OFFERS</h4>
                                    </div>


                                </div>

                                <div className="card pt-4">
                                    <div className="card-body  ">
                                        <div className="tab-content text-center" id="v-pills-tabContent">
                                            {/* <h5 className="hedtxt2 ">Point your phone to the scanner to check-in by capturing this code</h5> */}

                                            <div
                                                className="tab-pane fade show active qrcode pd-15"
                                                id="v-pills-shipping"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-shipping-tab"

                                            >

                                                {settingsData.offers ? renderHTML(settingsData.offers) : ""}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Footerad /> */}
                            </div>
                        
                        </section>
                        <FooterSection />
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OffersWeb);