import { ADD_USER_DETAILS } from '../constants/users';

export const users = (state = [], action) => {
    switch (action.type) {
        case ADD_USER_DETAILS:
            return [
                ...state,
                action.payload
            ];
        // case DELETE_PRODUCT:
        //     return state.filter(product => product.id !== action.payload.id);
        // case EDIT_PRODUCT:
        //     return state.map(product => product.id === action.payload.id ? action.payload : product);
        // case CHANGE_PRODUCTS:
        //     return action.payload;
        default:
            return state
    }
};
