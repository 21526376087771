import React, { Component } from "react";
import $ from 'jquery';
/* Import Components */
import InputField from "../UI/InputField";
import Button from "../UI/Button";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Label from "../UI/Label";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import Validation from "../../actions/Validation";
import SpanField from "../UI/SpanField";
import Select from "react-select";
import { connect } from "react-redux";
import {
  RegisterUrl,
  textNotificationURL,
  emailNotificationURL,
} from "../../config/url.json";
import axios from "axios";
import ErrorAlert from "../UI/ErrorAlert";
import { withRouter } from "react-router-dom";
import { CONFIG } from "../../config/data";
import CheckboxField from "../UI/CheckboxField";

const google = window.google;
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
  };
}

const agreeOptions = [
  {
    key: 1,
    value: "Receive relevant offers and promotional communication from Nucleo",
  },
  { key: 2, value: "By signing up, I agree to  our terms and conditions and privacy policies" },
];

class BusinessSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessData: {
        business_name: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        phone_number: "",
        phone_code: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        category: "",
        category_array: "",
        user_type: "hospital",
        user_type_id: "1",
        latitude: "",
        longitude: "",
        google_loc_id: "",
        agreedOption: [1],
        //phone_number:""
      },
      errors: {
        business_name: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        phone_number: "",
        phone_code: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        category: "",
        agreeTC: "",
      },
      error: "",
      loading: false,
      google_address: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.autocomplete = null;
    this.handleInput = this.handleInput.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handlepassword = (e) => {

    $(".businesstoggle-password").toggleClass("fa-eye fa-eye-slash");
    
    let input = $('#businesspassword');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    console.log(place);

    let { businessData,errors } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    businessData.latitude = lat.toString();
    businessData.longitude = lng.toString();
    businessData.google_loc_id = place.url;

    // console.log(place.name);
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1"){
          businessData.state = val;
          errors.state = ""
        }
          
        if (addressType === "locality"){
          businessData.city = val;
          errors.city = ""
        }          
        if (addressType === "country"){
          businessData.country = val;
          errors.country = ""
        } 
        // if (addressType === "street_number") {

        businessData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1")
        { 
          businessData.address2 = val;
          errors.address2 = ""
        }
        if (addressType === "postal_code")
        { 
          businessData.zip_code = val;
          errors.zip_code = ""
        }
      }
    }

    // let ClientData = this.state;
    this.setState({
      businessData,
      errors,
      google_address: true,
    });
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  validateMaxLength(name, value, max) {
    let data = this.state.businessData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value1;
    }
    this.setState({ businessData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.businessData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (name === "business_name") {
      this.validateMaxLength(name, value, max);
    } else if (name === "email") {
      this.validateMaxLength(name, value, max);
    } else if (name === "password") {
      this.validateMaxLength(name, value, max);
    } else if (name === "address1") {
      this.validateMaxLength(name, value, max);
    } else if (name === "address2") {
      this.validateMaxLength(name, value, max);
    } else if (name === "city"){
      this.validateMaxLength(name, value, max);
    } else if (name === "zip_code") {
      this.validateMaxLength(name, value, max); 
    } else {
      data[name] = value;
      errors[name] = ""
    }

    this.setState({ businessData: data, errors: errors });
  };

  handleCheck = (e) => {
    const newSelection = e.target.value;
    let data = this.state.businessData;

    if (data.agreedOption.includes(parseInt(newSelection, 10))) {
      data.agreedOption.splice(
        data.agreedOption.indexOf(parseInt(newSelection, 10)),
        1
      );
    } else {
      data.agreedOption.push(parseInt(newSelection, 10));
    }
    let errors = this.state.errors;
    if(data.agreedOption.length > 1){
      errors.agreeTC = "";
    }
    this.setState({ businessData: data,errors });
  };

  handlePhoneInput = (event) => {
    let businessData = this.state.businessData;
    businessData.phone_number = event;
    let errors = this.state.errors;
    errors.phone_number = "";
    this.setState({ businessData,errors });
  };
  /* for country detail change */
  selectCountry(val) {
    let data = this.state.businessData;
    let errors = this.state.errors
    data.country = val;
    errors.country = ""
    this.setState({ businessData: data,errors });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.businessData;
    let errors = this.state.errors
    data.state = val;
    errors.state = ""
    this.setState({ businessData: data,errors });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let { businessData, errors, google_address } = this.state;
    console.log(businessData);
    errors = Validation(businessData, errors);
    //         console.log(errors);
    if (!google_address && businessData.address1 !== "") {
      errors.address1 = "Please choose address from autocomplete";
    }
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      this.setState({ loading: true });

      businessData.category = businessData.category_array.value;

      console.log("form is valid");

      try {
        let data = this.state.businessData;


        data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
        data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;

        const result = axios.post(RegisterUrl, data, CONFIG);
        const p = Promise.resolve(result);
        this.setState({ loading: false });

        let resultdata = p.then((value) => {
          // console.log(value.data.status);
          // console.log(value.data.data);
          let Notificationdata = {};
          const options = {
            headers: {
              Accept: "*/*",
            },
          };
          if (value.data.status === "1") {
            // Notificationdata.email = businessData.email;
            // Notificationdata.trackingId = value.data.data.unique_id;
            // Notificationdata.message =
            //   value.data.data.otp +
            //   " is Your OTP for Mobile no verification to activate your account.";
            // // let isValidEmail = this.validateEmail(data["emailphone"]);

            // axios
            //   .post(emailNotificationURL, Notificationdata, options)
            //   .then((response) => {
            //     console.log(response);
            //     // data.loading = false;
            //     this.props.setUserData(value.data.data.user);
            //     this.props.history.push("/verify/" + value.data.data.unique_id);
            //   })
            //   .catch((error) => {
            //     if (e.response) {
            //       this.setState({ error: error.response.data.errors });
            //       window.setTimeout(() => {
            //         this.setState({
            //           error: "",
            //         });
            //       }, 5000);
            //     }
            //   });
            // this.props.setUserData(value.data.data.user);
            // this.props.history.push("/updateprofile");
            this.props.setUserData(value.data.data.user);
            this.props.history.push("/verify");
          }
          if (value.data.status === "0") {
            data.phone_number = data.phone_code.concat(data.phone_number);
            this.setState({ error: value.data.message,businessData: data});
          }
        }); // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        this.setState({ loading: false });

        // dispatch(
        // addError(e.message)
        // )
      }
    }
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.businessData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;

    this.setState({ businessData: data,errors });
  };
  render() {
    const { businessData, errors, error, loading } = this.state;
    let category_array = this.props.appdata.length
      ? this.props.appdata[0].category
      : [];
    let category = [];

    category_array.forEach((element, index) => {
      category.push({ value: element.id, label: element.category_name });
    });
    category_array = category;
    return (
      <form className="custom-validation" onSubmit={this.handleFormSubmit}>
        {error ? <ErrorAlert message={error} /> : null}
        <div className="row clientSignUpclr">
          <div className="col-sm-6">
            <div className="form-group">
            <Label title="Business Category" className="text_white" isMandatory={true} />

<div className="input-group sform-control">
  <span className="input-group-addon">
    <i className="glyphicon fa fa-search icontextb"></i>
  </span>
  {/* <input id="email" type="text" className="form-control" name="text" placeholder="Search" /> */}
  <Select
  maxMenuHeight={120}
  id="businesscategory"
    className="form-control p-0"
    name="category_array"
    value={businessData.category_array}
    onChange={this.handleselectChange}
    options={category_array}
  />
</div> <SpanField classname="text-danger" title={errors.category_array} />
            </div>
         
           
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="businessname"
                min={5}
                isMandatory={true}
                inputType={"text"}
                title={"Business Name"}
                name={"business_name"}
                controlFunc={this.handleInput}
                max={50}
                content={businessData.business_name}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.business_name} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-0">
              <Label
                title="Phone Number"
                isMandatory={true}
              />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-control">
                  <PhoneInput
                  id="businessphonenumber"
                    international
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={businessData.phone_number}
                    onChange={this.handlePhoneInput}
                    max={13} error={
                      
                      businessData.phone_number 
                      ? isValidPhoneNumber(businessData.phone_number)
                        
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                     
                    }
                  />
                </div>
                <SpanField
                  classname="text-danger"
                  title={errors.phone_number}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="businessemail"
                isMandatory={true}
                inputType={"text"}
                title={"Email"}
                name={"email"}
                controlFunc={this.handleInput}
                content={businessData.email}
                placeholder={""}
                max={50}
              />
              <SpanField classname="text-danger" title={errors.email} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="businesspassword"
                isMandatory={true}
                inputType={"password"}
                title={"Password"}
                name={"password"}
                max={30}
                controlFunc={this.handleInput}
                content={businessData.password}
                placeholder={""}
              />
<span toggle="#password-field" onClick={this.handlepassword} class="fa fa-fw fa-eye field-icon businesstoggle-password"></span>
              <SpanField classname="text-danger" title={errors.password} />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              // id="businessaddress1"
                isMandatory={true}
                id="address1"
                inputType={"text"}
                title={"Address 1"}
                name={"address1"}
                max={100}
                controlFunc={this.handleInput}
                content={businessData.address1}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.address1} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="businessaddress2"
                // isMandatory={true}
                inputType={"text"}
                title={"Address 2"}
                name={"address2"}
                max={50}
                controlFunc={this.handleInput}
                content={businessData.address2}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.address2} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="businesscity"
                isMandatory={true}
                inputType={"text"}
                title={"City"}
                name={"city"}
                max={20}
                controlFunc={this.handleInput}
                content={businessData.city}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.city} />
            </div>
          </div>
          <div className="col-sm-6">
          <div className="form-group">
          <Label title="Country" isMandatory={true} className="text_white" />

<CountryDropdown
id="businesscountry"
  placeholder="Country"
  name="country"
  value={businessData.country || ""}
  className="form-control"
  onChange={(val) => this.selectCountry(val)}
/>
<SpanField classname="text-danger" title={errors.country} />
          </div>
           
           
          </div>
          <div className="col-sm-6">
            <div className="form-group">
            <Label title="State" isMandatory={true} className="text_white" />

<RegionDropdown
id="businesstate"
  name="state"
  defaultOptionLabel="Select State"
  country={businessData.country}
  value={businessData.state || ""}
  className="form-control"
  onChange={(val) => this.selectState(val)}
  placeholder="State"
/>
<SpanField classname="text-danger" title={errors.state} />
            </div>
           
          
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="busineszip"
                isMandatory={true}
                inputType={"text"}
                title={"Zip/Pincode"}
                name={"zip_code"}
                controlFunc={this.handleInput}
                content={businessData.zip_code}
                placeholder={""}
                max={10}
              />
              <SpanField classname="text-danger" title={errors.zip_code} />
            </div>
            {/* <label>Last name*</label>
                            <input type="text" className="form-control" placeholder="" /> */}
          </div>
          <div className="col-sm-12">
          <CheckboxField
            setName={"agreeTC"}
            className="col-sm-12"
            type={"checkbox"}
            controlFunc={this.handleCheck}
            options={agreeOptions}
            name={"agreeTC"}
            page={"business"}
            selectedOptions={businessData.agreedOption}
          />
          </div>
          

          <div className="col-sm-12">
            <SpanField classname="text-danger" title={errors.agreeTC} />
          </div>
          <div className="col-sm-12">
          <p className="_58mv">By clicking Sign Up, you agree to our <a href="/terms" id="terms-link" target="_blank" rel="nofollow">Terms</a>, <a href="/privacy_policy" id="privacy-link" target="_blank" rel="nofollow">Privacy Policy</a>.</p>
          </div>
       
          
          <div className="col-sm-12 btncent">
            <div className="form-group mb-0">
              <Button id="businesssignup"
                disabled={loading}
                loading={loading}
                clsname="signupbtn abtn"
                btntype="submit"
                title="Sign Up"
                action=""
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

// export default BusinessSignup;

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (user) => dispatch({ type: "SET_USERDATA", payload: user }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessSignup)
);
