import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { getAllDoctorSubscriptiondata,updateDoctorcount } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import LoadingSpinner from "../components/UI/LoadingSpinner";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

class DoctorCountBySubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],
            actions: "update",
            loading: false,
            showDeleteConfirmPopup: false,
            confirmedDelete: false,
      
             deletePopup: false,
             doctorcountdata:
             {
               id:"",
               subscription:"",
               doctor_count:"",
               patient_visit_count:"",
              
             },
            
             errors: {
                
                subscription:"",
                doctor_count:"",
                patient_visit_count:"",
               
            },
            formSuccess: {
                message: "",
                status: "",
              },
        };

     
        this.columns = [
            {
                key: "id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "subscription",
                text: "Subscription",
                className: "subscription",
                align: "left",
                sortable: true,
            },
            {
                key: "doctor_count",
                text: "Doctor Count",
                className: "doctor_count",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_visit_count",
                text: "Total Patient Visit Count",
                className: "patient_visit_count",
                align: "left",
                sortable: true,
            },
        
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                    this.setState({ deletePopup: true, record: record })
                                }
                            >
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };

    UpdateColumnChange = (data) => {
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // let request = {
        //     business_user_id: data.user_id, status: data.status
        // }
        // axios.post(updateDoctorStatus1, request).then((list) => {
        let records = this.state.records;

        //     // data = list.data.data;
        //     if (list.data.status === "1") {
        var recIndex = findWithAttr(records, "user_id", data.user_id);

        records[recIndex].status = data.status;

        this.setState({ records, tableSuccess: "" });
        // }

        this.clearMessage();
        // });
    };
    handleselectChange = (selectedOptions, e) => {
      let data = this.state.timeoutdata;
      data[e.name] = selectedOptions;
  
      this.setState({ timeoutdata: data });
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

   
    componentDidMount = () => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .get(getAllDoctorSubscriptiondata, null, CONFIG)
            .then((list) => {
                console.log(list)
                this.setState({ records: list.data.data });
            })
            .catch((e) => { console.log(e) });



        
    }
    cancel = () => {
        let data = this.state.doctorcountdata;
        data.id="";
        data.subscription = "";
        data.doctor_count = "";
        data.patient_visit_count="";
        this.setState({ doctorcountdata: data, actions: "update" });
      };
    editRecord = (record) => {
        console.log(record);
        this.setState({ actions: "edit" });
    
        let doctorcountdata = this.state.doctorcountdata;
        doctorcountdata.id=record.id;
        doctorcountdata.subscription = record.subscription;
        doctorcountdata.doctor_count=record.doctor_count;
        doctorcountdata.patient_visit_count=record.patient_visit_count;   
        this.handleDoctorCountChange("id", doctorcountdata.id);
        this.setState({ doctorcountdata: doctorcountdata });
      };
    
      clearAll() {
        let actions = this.state.actions;
        actions = "update";
        this.setState({ actions });
        let doctorcountdata = {};
        doctorcountdata.id="";
        doctorcountdata.subscription="";
        doctorcountdata.doctor_count = "";
        doctorcountdata.patient_visit_count="";
        this.setState({ doctorcountdata });
      }

      handleDoctorCountChange(value, field) {
        let fields = this.state.doctorcountdata;
        // let errors = this.state.educationerror;
        console.log(field);
         if (value === "subscription") {
          fields.subscription = field.target.value;
          // errors[i].register_id = '';
        }else if (value === "id") {
          fields.id = field;
          // errors[i].education = '';
        } 
        else if (value === "doctor_count") {
          fields.doctor_count = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "patient_visit_count") {
            fields.patient_visit_count = field.target.value;
            // errors[i].education = '';
          }  
        this.setState({
            doctorcountdata: fields,
        });
      }
    updateDoctorcount=(e)=> {
     // let user_id = record.user_id;
        let { doctorcountdata, errors } = this.state;
        doctorcountdata.type = "doctorcountdata";
        errors = Validation(doctorcountdata, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
          let data = this.state.doctorcountdata;
    
      //  let data = {};
        
        data.id=doctorcountdata.id;
        data.subscription=doctorcountdata.subscription;
        data.doctor_count = doctorcountdata.doctor_count;
        data.patient_visit_count=doctorcountdata.patient_visit_count;
        axios.put(updateDoctorcount, data, CONFIG).then((list) => {
         console.log(data);
         console.log(list.data);
          let response=list.data;
          if (response.status === "1") {

          let member = {};

          member.id=data.id;
          member.subscription=data.subscription;
          member.doctor_count = data.doctor_count;
          member.patient_visit_count=data.patient_visit_count;
          let records = this.state.records;

          /** Updating the data table */
          let existingRecords = this.state.records;
          var index = existingRecords.findIndex((item) => item.id === data.id);
          existingRecords.splice(index, 1, member);
          this.setState({ records: existingRecords });

          this.setState({ records });
          this.setState({ formSuccess: list.data });
          this.clearAll();
          this.clearMessage();
         
          }
        });
      }
      }
    //   deleteRecord(record) {
      
    //     let id = record.id;
    //     axios.defaults.headers.common[
    //       "Authorization"
    //     ] = `Bearer ${this.props.access_token}`;
    //     axios
    //       .post(removeBusinessFromBusinesslist, { id: id }, CONFIG)
    //       .then((res) => {
    //         let response = res.data;
    //         if (response.status === "1") {
    //           let records = this.state.records;
    
    //           const deleteIndex = records.map((item) => item.id).indexOf(record.id);
    //           records.splice(deleteIndex, 1);
    //           this.setState({ records });
    //           this.setState({ tableSuccess: res.data });
    //           this.clearMessage();
    //         }
    //       })
    //       .catch((e) => { });
    //     this.setState({ deletePopup: false, record: null });
    
    //     this.setState({
    //       confirmedDelete: false,
    //     });
    //     axios.defaults.headers.common[
    //       "Authorization"
    //   ] = `Bearer ${this.props.access_token}`;
    //   axios
    //       .get(getAllTimeoutdata, null, CONFIG)
    //       .then((list) => {
    //           console.log(list)
    //           this.setState({ records: list.data.data });
    //       })
    //       .catch((e) => { console.log(e) });

    //   }

    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            doctorcountdata,
            formSuccess,
            tableSuccess,
            errors,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3">Doctor Count By Subscription</h4>
                                    <div className="row mt-4">

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                            show={this.state.deletePopup}
                                                             onHide={() =>
                                                             this.setState({
                                                              deletePopup: false,
                                                               })
                                                               }
                                                             onConfirm={() =>
                                                             this.deleteRecord(this.state.record)
                                                             }
                                                            title={"Confirmation"}
                                                              message={
                                                               "Are you sure you want to delete this record?"
                                                               }
                                                             />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                            <div className="card">
                            <div class="card-header"> <div class="card-title">   {" "}
                                    {this.state.actions === "Edit"
                                      ? "Edit"
                                      : "Edit"}{" "}
                                    Doctor Count</div> </div>
                              <div className="card-body">
                                <div>
                               
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                 
                                  <div className="col-lg-4">
                                    <div>
                                        <label>Subscription</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"subscription"}
                                          controlFunc={this.handleDoctorCountChange.bind(this,"subscription")}
                                          content={doctorcountdata.subscription}
                                          placeholder={""}
                                          max={50}
                                        />
                                  
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.subscription}
                                      /> 
                                     
                                    </div>

                                    <div className="col-lg-4">
                                    
                                      </div>
                                     

                                    </div>
                                    <div className="col-lg-4">
                                    <div>
                                        <label>Doctor Count</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"doctor_count"}
                                          controlFunc={this.handleDoctorCountChange.bind(this,"doctor_count")}
                                          content={doctorcountdata.doctor_count}
                                          placeholder={""}
                                          max={50}
                                        />
                                 
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.doctor_count}
                                      /> 
                                     
                                    </div>

                                    <div className="col-lg-4">
                                    
                                      </div>
                                     

                                    </div>
                                    <div className="col-lg-4">
                                    <div>
                                        <label>Patient Visit Count</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"patient_visit_count"}
                                          controlFunc={this.handleDoctorCountChange.bind(this,"patient_visit_count")}
                                          content={doctorcountdata.patient_visit_count}
                                          placeholder={""}
                                          max={50}
                                        />
                                 
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.patient_visit_count}
                                      /> 
                                      <div className='visibleHidden'>
                                        <label>Timeout</label>
                                      </div>

                                      <div className="float-right">
                                      <button
                                          ref="btn"
                                          href="patients-register"
                                          className="signupbtn abtn btdpml btn-margin"
                                          onClick={this.updateDoctorcount}>
                                                                                 
                                          {loading ? (
                                            <LoadingSpinner title="Update" />
                                          ) : this.state.actions === "Update" ? (
                                            "Update"
                                          ) : (
                                            "Update"
                                          )}
                                        </button>
                                        <button
                                          href="patients-register"
                                          className="signupbtn abtn btdpml"
                                          onClick={this.cancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                     
                                    </div>

                                    {/* <div className="col-lg-4">
                                    
                                      </div> */}
                                     

                                    </div>
                                    <div className="col-lg-4">
                                    
                                      
                                    <div className="form-group">
                                    
                                      </div>
                                      </div>
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                                        </div>

                                    </div>
                                </div>
                             
                            </section>
                                <FooterSection />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
)(DoctorCountBySubscription);