import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
// import MobileSidebar from "../components/container/layout/client/MobileSidebar";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import Popup from "../components/UI/Popup";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import ExpirePopup from "../components/UI/ExpirePopup";
import { blood_group, gender, maritial_status } from "../config/data";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import DatePicker from "react-date-picker";
import { removeClientFromBusiness } from "../config/url.json";
import Validation from "../actions/Validation";
import * as moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import {
  getallclientbyBusiness,
  updateClient,
  addClient,
  getBusinessScheduleDayId,
  createQueue,
  getTimeoutbycategory
} from "../config/url.json";
import { CONFIG } from "../config/data";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClientsExport from "./ClientsExport";
import { Popupinfo } from "../components/UI/Popupinfo";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class Clients extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      showexpirePopup: this.props.validity,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      edit_clicked:false,
      records: [],
      actions: "update",
      loading: false,
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      newClientId: [],
      deletePopup: false,
      clientData: {
        s_no: "",
        user_id: "",
        registration_id: "",
        name: "",
        first_name: "",
        last_name: "",
        age: "",
        blood_group: "",
        phone_number: "",
        phone_code:"",
        gender: "",
        maritial_status: "",
        date_of_birth: "",
      },
      errors: {
        user_id: "",
        registration_id: "",
        first_name: "",
        last_name: "",
        age: "",
        blood_group: "",
        phone_number: "",
        gender: "",
        maritial_status: "",
        date_of_birth: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "registration_id",
        text: "Registration ID",
        className: "registration_id",
        align: "left",
        sortable: true,
      },
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
      },

      {
        key: "date_of_birth",
        text: "Date of Birth",
        className: "date_of_birth",
        sortable: true,
        align: "left",
        cell: (record) => {
          return moment(record.date_of_birth).format("DD-MMM-yyyy");
        },
      },

      {
        key: "blood_group",
        text: "Blood Group",
        className: "blood_group",
        sortable: true,
        align: "left",
      },

      {
        key: "phone_number",
        text: "Mobile Number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
               <Tooltip title="Edit">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              </Tooltip>
              <Tooltip title="Delete">
              <button
                className="btn btn-danger btn-sm"
                onClick={() =>
                  this.setState({ deletePopup: true, record: record })
                }
              >
                <i className="fa fa-trash"></i>
              </button>
               </Tooltip>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();

  };

  _onAction(e) {
    // console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  componentDidMount = () => {

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getallclientbyBusiness, { business_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let clientList = list.data;
        console.log(list);
        let clientDataArr = [];
        clientList.data.sort((a,b) => a.id - b.id);
        clientList.data.forEach((element, index) => {
          console.log(element);
          let clientData = {};
          clientData.s_no = index + 1;
          clientData.queue_id = element.queue_id;
          clientData.registration_id = element.id;
          clientData.user_id = element.client_id;
          clientData.first_name=element.first_name;
          clientData.last_name=element.last_name;
          clientData.name = element.first_name + " " + element.last_name;
          clientData.age = element.age;
          clientData.blood_group = element.blood_group;
          clientData.phone_code=element.phone_code;
          clientData.phone_number = (element.phone_number==null)?element.phone_number: (element.phone_number.includes("+91")? element.phone_number :element.phone_code+element.phone_number);
          clientData.gender = element.gender;
          if (element.gender === 1) {
            clientData.gender = "Female";
          } else if (element.gender === 0) {
            clientData.gender = "Male";
          } else if (element.gender === 2){
            clientData.gender = "Other";
          }

          clientData.maritial_status = element.marital_status;
          clientData.date_of_birth = element.date_of_birth;
          // if (element.date_of_birth !== "") {
          //   clientData.date_of_birth = moment(element.date_of_birth).format(
          //     "DD-MM-YYYY"
          //   );
          // }
          clientDataArr[index] = clientData;
        
        });
        
       console.log(clientDataArr);
        this.setState({ records: clientDataArr });
      })
      .catch((e) => { console.log(e)});
  };

  cancel = () => {
    // s_no: "",
    //     user_id: "",
    //     registration_id: "",
    //     first_name: "",
    //     last_name: "",
    //     age: "",
    //     blood_group: "",
    //     phone_number: "",
    //     gender: "",
    //     maritial_status: "",
    //     date_of_birth: "",
    let data = this.state.clientData;
    data.s_no = "";
    data.user_id = "";
    data.registration_id = "";
    data.first_name = "";
    data.last_name = "";
    data.age = "";
    data.blood_group = "";
    data.phone_number = "";
    data.gender = "";
    data.maritial_status = "";
    data.date_of_birth = "";
    let errors = this.state.errors;
    errors = {};

    this.setState({ clientData: data, actions: "update",searchErrorMessage:"",edit_clicked:false });
    this.setState({ errors:errors });
  };
  editRecord = (record) => {
    console.log(record);
    this.setState({ actions: "edit",edit_clicked:true });

    let clientData = this.state.clientData;
    clientData.s_no = record.s_no;
    clientData.id = record.id;
    clientData.registration_id = record.registration_id;
    clientData.user_id = record.user_id;
    let name = record.name;
    var nameArr = name.split(/\s+/);
    clientData.name = record.name;
    clientData.first_name = nameArr.slice(0, -1).join(" ");
    clientData.last_name = nameArr.pop();
    clientData.age = record.age;
    clientData.queue_id = record.queue_id;
    clientData.blood_group = {
      label: record.blood_group,
      value: record.blood_group,
    };
    clientData.phone_number = record.phone_number;
    clientData.gender = {
      label: record.gender,
      value: record.gender,
    };

    clientData.maritial_status = {
      label: record.maritial_status,
      value: record.maritial_status,
    };
   
    clientData.date_of_birth = moment(
      record.date_of_birth,
      moment.defaultFormat
    ).toDate();
    this.handlePatientChange("date_of_birth", clientData.date_of_birth);
    console.log(clientData.date_of_birth);
    this.setState({ clientData: clientData });
  };

  deleteRecord(record) {
    // let record = this.state.recordToDelete;

    console.log(record);
    // let drug_id = record.drug_id;
    let queue_id = record.queue_id;
    let client_id=record.user_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(removeClientFromBusiness, { queue_id: queue_id,client_id:client_id }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;

          const deleteIndex = records
            .map((item) => item.queue_id)
            .indexOf(record.queue_id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        //  this.setState({ tableSuccess: res.data });
          toast.success("The Patient record has been deleted successfully.",{
            className:"toast-success"
          });
          this.clearMessage();
        }
      })
      .catch((e) => { });
    this.setState({ deletePopup: false, record: null });

    this.setState({
      confirmedDelete: false,
    });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
        }),
      5000
    );
    
  };
  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  handlePatientChange(value, field) {
    let fields = this.state.clientData;
     let errors = this.state.errors;
    console.log(field);
    if (value === "first_name") {
      fields.first_name = field.target.value;
       errors.first_name = '';
    } else if (value === "last_name") {
      fields.last_name = field.target.value;
       errors.last_name = '';
    } else if (value === "date_of_birth") {
      fields.date_of_birth = field;
       errors.date_of_birth = '';
    } else if (value === "gender") {
      fields.gender = field;
       errors.gender = '';
    } else if (value === "blood_group") {
      fields.blood_group = field;
      errors.blood_group='';
    } else if (value === "phone_number") {
      fields.phone_number = field;
      errors.phone_number='';
    } else if (value === "maritial_status") {
      fields.maritial_status = field;
       errors.maritial_status = '';
    }

    this.setState({
      clientData: fields,
    });
  }
  handleDOBChange = (date) => {
    let clientData = this.state.clientData;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    clientData.date_of_birth = offsetDate;
    this.setState({ ClientData: clientData });
  };
  updateClientRec() {
    let { clientData, errors } = this.state;
    clientData.type = "clientlist";
    errors = Validation(clientData, errors);
    this.setState({ errors: errors });
console.log(errors);
console.log(clientData); //return false;
    let data = {};
    data.first_name = clientData.first_name;
    data.last_name = clientData.last_name;
    data.age = clientData.age;
    data.gender = clientData.gender.label;
    data.marital_status = clientData.maritial_status.label;
    data.maritial_status = clientData.maritial_status.label;
    data.blood_group = clientData.blood_group.label;
    data.phone_number = clientData.phone_number;
    data.s_no = clientData.s_no;
    data.registration_id = clientData.registration_id;
    data.user_id = clientData.user_id;

    if(data.user_id===""||null==data.user_id)
    {
      this.setState({
        searchErrorMessage:
          "Please edit the client records to update",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }
    if (errors.formIsValid) {
     // this.setState({ errors:"" });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
   //   let url = this.state.actions === "add" ? addClient : updateClient;
/* 
      if (this.state.actions === "add") {
        if (clientData.date_of_birth !== "") {
          data.date_of_birth = moment(clientData.date_of_birth).format(
            "DD-MM-YYYY"
          );
        }

        this.setState({ loading: true }, () => {
          axios.post(url, data, CONFIG).then((res) => {
            if (res.data.status === "1") {
              
              let businessId = this.props.userdata.id;
              let createdClientData = res.data.data;
              let createdClientId = createdClientData.user_id;

              if (businessId !== "") {
                let queueData = {};
                queueData.clientList = [createdClientId];
                queueData.status = "Queue-In";
                queueData.is_active = "No";
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
                axios
                  .get(getBusinessScheduleDayId + businessId)
                  .then((schedules) => {
                    let scheduleArr = schedules.data;

                    scheduleArr.forEach((element, index) => {
                      queueData.schedule_day_id = element.schedule_day_id;
                    });

                    if (queueData.clientList.length !== 0) {
                      axios.defaults.headers.common[
                        "Authorization"
                      ] = `Bearer ${this.props.access_token}`;
                      axios
                        .post(createQueue, queueData)
                        .then((response) => {
                          let result = response.data.data[0];
                          if (result.status === "100") {
                            let newRecord = {};
                            newRecord.s_no = this.state.records.length + 1;
                            newRecord.user_id = createdClientData.user_id;
                            newRecord.registration_id = createdClientData.id;
                            newRecord.name =
                              createdClientData.first_name +
                              " " +
                              createdClientData.last_name;
                            newRecord.blood_group =
                              createdClientData.blood_group;
                            newRecord.age = createdClientData.age;
                            newRecord.phone_number =
                              createdClientData.phone_number;
                            newRecord.gender = createdClientData.gender;
                            newRecord.maritial_status =
                              createdClientData.marital_status;
                            newRecord.date_of_birth =
                              createdClientData.date_of_birth;

                            let records = this.state.records;
                            records.push(newRecord);
                            this.setState({ records });
                            toast.success("Patient Added Successfully.",{
                              className:"toast-success"
                            });
                            this.setState({ loading: false });
                            
                            this.cancel();
                          }
                        })
                        .catch((e) => { });
                    }
                  })
                  .catch((e) => { });
              }
            }
          });
        });
      } else { */
      
        data.date_of_birth = clientData.date_of_birth;

        this.setState({ loading: true }, () => {
          axios.put(updateClient, data, CONFIG).then((res) => {
            this.setState({ loading: false });
            data.date_of_birth = moment(data.date_of_birth).format();
            data.name = data.first_name + " " + data.last_name;
            data.marital_status = clientData.maritial_status.label;
            data.maritial_status = clientData.maritial_status.label;

            // let records = this.state.records;
            /** Updating the data table */
            let existingRecords = this.state.records;
            var index = existingRecords.findIndex(
              (item) => item.user_id === data.user_id
            );
            existingRecords.splice(index, 1, data);
            this.setState({ records: existingRecords });
           // this.setState({  formSuccess: res.data });
            toast.success("The Patient details have been updated successfully.",{
              className:"toast-success"
            });
            this.cancel();
            this.clearMessage();

            let actions = "update";
            this.setState({ actions });

          }
          );
        });
     // }
    }
  }
  render() {
    const {
      clientData,
      errors,
      loading,
      formSuccess,
      tableSuccess,
      hospitaltimeout,
      searchErrorMessage,edit_clicked
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    return (

      <div id="layout-wrapper">
        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
        {/* <SideBarNew /> */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu ">
                <SideBar />
              </div>

              <section className="main-body hospital-profilegap">
              <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                <div className="container-fluid">
                  <div className="checkout-tabs">

                    <div className="row">

                      <div className="col-sm-12">
                        <h4 className="font-weight-bold"> Patient List </h4>
                        {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
                        <div className="row mt-4">

                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-body ">
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div className="float-right btn "> <ClientsExport business_id={this.props.userdata.id} records={this.state.records}/></div>
                                

                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="user-notification">
                                      <SpanField
                                        classname={
                                          0 === tableSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                        }
                                        title={tableSuccess.message}
                                      />
                                    </div>
                                 
                                    <ReactDatatable
                                      config={this.config}
                                      records={this.state.records}
                                      columns={this.columns}
                                      className="table table-bordered table-striped kas  mobile_table"
                                    />
  
                                    <Popup
                                      show={this.state.deletePopup}
                                      onHide={() =>
                                        this.setState({
                                          deletePopup: false,
                                        })
                                      }
                                      onConfirm={() =>
                                        this.deleteRecord(this.state.record)
                                      }
                                      title={"Confirmation"}
                                      message={
                                        "Are you sure you want to delete this record?"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {edit_clicked?(<div className="col-sm-12">
                            <div className="card">
                            <div class="card-header"> 
                            <div class="card-title">
                            {" "}
                                    {this.state.actions === "edit"
                                      ? "Edit"
                                      : "Edit"}{" "}
                                    Patient
                              </div> 
                              </div>
                              <div className="card-body">
                                <div>
                                <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div>
                                        <label>First Name</label>
                                      </div>
                                      <div className="form-group">
                                        <InputField
                                          inputType={"text"}
                                          name={"first_name"}
                                          controlFunc={this.handlePatientChange.bind(
                                            this,
                                            "first_name"
                                          )}
                                          content={clientData.first_name}
                                          placeholder={""}
                                          max={50}
                                        />
                                      </div>
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.first_name}
                                      />
                                    </div>

                                    <div className="col-lg-4">
                                      <div>
                                        <label>Last Name</label>
                                      </div>
                                      <div className="form-group">
                                        <InputField
                                          inputType={"text"}
                                          name={"last_name"}
                                          controlFunc={this.handlePatientChange.bind(
                                            this,
                                            "last_name"
                                          )}
                                          content={clientData.last_name}
                                          placeholder={""}
                                          max={50}
                                        />
                                      </div>
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.last_name}
                                      />

                                    </div>
                                    <div className="col-lg-4">
                                      <div>
                                        <label>Gender</label>
                                      </div>
                                      <div className="form-group sform-control">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="gender"
                                          value={clientData.gender}
                                          onChange={this.handlePatientChange.bind(
                                            this,
                                            "gender"
                                          )}
                                          options={gender}
                                        />
                                      </div>
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.gender}
                                      />

                                    </div>


                                    <div className="col-sm-4">
                                      <div>
                                        <label>Marital Status </label>
                                      </div>
                                      <div className="form-group sform-control">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="maritial_status"
                                          value={clientData.maritial_status}
                                          onChange={this.handlePatientChange.bind(
                                            this,
                                            "maritial_status"
                                          )}
                                          options={maritial_status}
                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.maritial_status}
                                        />
                                      </div>



                                    </div>
                                    <div className="col-sm-4">
                                      <div>

                                      </div>
                                      <div>
                                        <div>
                                          <label>Date of Birth</label>
                                        </div>
                                        <div className="form-group">
                                          <DatePicker
                                            className="form-control"
                                            value={clientData.date_of_birth}
                                            format="dd-MM-yyyy"
                                            onChange={(date) =>
                                              this.handleDOBChange(date)
                                            }
                                            placeholder={"Select your DOB"}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.date_of_birth}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div>

                                      </div>
                                      <div>
                                        <div>
                                          <label>Blood Group</label>
                                        </div>
                                        <div className="form-group sform-control">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="blood_group"
                                            value={clientData.blood_group}
                                            onChange={this.handlePatientChange.bind(
                                              this,
                                              "blood_group"
                                            )}
                                            options={blood_group}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="frllftfull">
                                        <div className="form-group mb-0">
                                          <label>Mobile Number</label>
                                        </div>

                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="form-control">
                                              <PhoneInput
                                                international
                                                defaultCountry="IN"
                                                placeholder="Enter phone number"
                                                value={clientData.phone_number}
                                                onChange={this.handlePatientChange.bind(
                                                  this,
                                                  "phone_number"
                                                )}
                                                error={
                                                  clientData.phone_number
                                                    ? isValidPhoneNumber(
                                                      clientData.phone_number
                                                    )
                                                      ? undefined
                                                      : "Invalid phone number"
                                                    : "Phone number required"
                                                }
                                              // disabled={true}
                                              />
                                              <SpanField
                                                classname="text-danger"
                                                title={errors.phone_number}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">

                                      <div className="form-group mt2rem mb-0 ">
                                     
                                        <button
                                          ref="btn"
                                          href="patients-register"
                                          title="Update Client Details"
                                          className="signupbtn abtn btdpml btn-margin"
                                          onClick={this.updateClientRec.bind(
                                            this
                                          )}
                                        >
                                          {loading ? (
                                            <LoadingSpinner title="Update" />
                                          ) : this.state.actions === "Edit" ? (
                                            " Update"
                                          ) : (
                                            "Update"
                                          )}
                                        </button>
                                     
                                        <button
                                          href="patients-register"
                                          className="signupbtn abtn btdpml"
                                          onClick={this.cancel}
                                          title="Cancel"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>):("")}
                          
                        </div>)}

                        {/* <Footerad /> */}

                        {/* <Modal isOpen={modalVar}>
                        <ModalHeader>
                          <label className="text-center wdt100">
                            Confirmation
                          </label>
                        </ModalHeader>
                        <ModalBody>
                          <div className="col-sm-12">
                            Do you want to delete this user's account?
                          </div>
                          <div className="row">
                            <div className="col-sm-3">
                              <Button
                                color="success"
                                onClick={this.okDelete.bind(this)}
                              >
                                Ok
                              </Button>
                            </div>
                            <div className="col-sm-3">
                              <Button
                                color="danger"
                                onClick={this.cancelDelete.bind(this)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal> */}
                      </div>
                    </div>
                  </div>
                </div>

          
              </section>
              <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
