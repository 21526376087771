import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CONFIG } from "../../config/data";
import { getAllBusinessUserList,getTimeoutbycategory } from "../../config/url.json";
import axios from "axios";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Popupinfo } from "../../components/UI/Popupinfo";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchData: (searchData) =>
      dispatch({ type: "SET_SEARCH_DATA", payload: searchData }),
      logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class BusinessuserProfile extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      records: [],
    };
    this.columns = [
      {
           key: "s_no",
           text: "S.No",
           className: "s_no",
           align: "left",
           sortable: true,
         },
         {
        key: "employee_id",
        text: "Employee ID",
        className: "emp_id",
        align: "left",
        sortable: true,
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "Specialities",
        text: "Speciality",
        align: "left",
        className: "speciality",
        sortable: true,
      },
      {
        key: "dob",
        text: "Date of Birth",
        className: "dob",
        align: "left",
        sortable: true,
        cell: (record) => {
          return moment(record.dob).format("DD-MM-YYYY");
        },
      },
      {
        key: "gender",
        text: "Gender",
        className: "gender",
        sortable: true,
        align: "left",
      },
      {
        key: "marital_status",
        text: "Marital Status",
        className: "marital_status",
        sortable: true,
        align: "left",
      },
      {
        key: "blood_group",
        text: "Blood Group",
        className: "blood_group",
        sortable: true,
        align: "left",
      },
      {
        key: "mobile_number",
        text: "Mobile Number",
        className: "mobile_number",
        sortable: true,
        align: "left",
      },
      {
        key: "designation",
        text: "Designation",
        className: "designation",
        sortable: true,
        align: "left",
      },
      
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button 
                className="btn btn-primary btn-sm"
               onClick={() => this.viewProfile(record)}
                style={{ marginRight: "5px" ,marginLeft: "25px"}}
              >
                <i className="fa fa-eye"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  
  }
  
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   this.props.history.push("/");
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
  }

  viewProfile = (user) => {
    console.log(user);
   // this.props.setSearchData({ user_id: user.user_id, user_type: "employee" });
    this.props.history.push({
      pathname: "/viewprofile",
      state: {
        user_id: user.user_id, user_type: "employee"
      }
    });
  //  window.location.replace("/viewprofile");
  };
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(
        getAllBusinessUserList,
        { business_id: this.props.userdata.id },
        CONFIG
      )
      .then((list) => {
        let users = [];
        let userlist = list.data.data;
        userlist.sort((a,b) => a.id - b.id);
        userlist.forEach((element, index) => {
          let user = {};
          user.s_no = index + 1;
          user.employee_id = element.id;
          user.user_id = element.user_id;
          user.professional_id = element.professional_id;
          user.email = element.email;
          user.first_name = element.first_name;
          user.last_name = element.last_name;
          user.speciality = element.speciality;
          user.Specialities = element.Specialities;
          user.dob = element.date_of_birth;
          user.gender = element.gender;
          user.marital_status = element.marital_status;
          user.blood_group = element.blood_group;
          user.mobile_number = element.phone_number;
          user.emergency_contact = element.emergency_number;
          user.designation = element.designation;
          users[index] = user;
        });

        this.setState({ records: users });
      })
      .catch((e) => {});
      let specialities = [];
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;
    this.setState({ specialities_array: specialities_array });
  };
  render() {
    const {hospitaltimeout}=this.state;
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    return (
      <div className="col-sm-12 pl0">
        <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

           <h4 className="font-weight-bold">Employee Profile </h4>
           {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
        <div className="row mt-4 checkout-tabs">
          
          <div className="col-sm-12">
       
            <div className="card">
              <div className="card-body ">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <div>
                          <ReactDatatable
                          className="table table-bordered table-striped kas  mobile_table"
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                          />
                          {/* <Popup
                            show={this.state.deletePopup}
                            onHide={() =>
                              this.setState({
                                deletePopup: false,
                              })
                            }
                            onConfirm={() =>
                              this.deleteRecord(this.state.record)
                            }
                            title={"Confirmation"}
                            message={"Are you sure you want to delete this record?"}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BusinessuserProfile));
