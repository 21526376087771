import React, { Component } from "react";
import { connect } from "react-redux";
import $ from 'jquery';
/* Import Components */
import GenderField from "../UI/GenderField";
import InputField from "../UI/InputField";
// import Select from '../UI/Select';
import Button from "../UI/Button";
import Label from "../UI/Label";
import PhoneInput, { isPossiblePhoneNumber,parsePhoneNumber } from "react-phone-number-input";

import SpanField from "../UI/SpanField";
import Validation from "../../actions/Validation";
import axios from "axios";

import {
  RegisterUrl,
  textNotificationURL,
  emailNotificationURL,
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import ErrorAlert from "../UI/ErrorAlert";
import { withRouter } from "react-router-dom";
import CheckboxField from "../UI/CheckboxField";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
const agreeOptions = [
  {
    key: 1,
    value: "Receive relevant offers and promotional communication from Nucleo",
  },
  { key: 2, value: "By signing up, I agree to  our terms and conditions and privacy policies" },
];

class ConsumerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumerData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        phone_code: "",
        gender: "",
        date_of_birth: "",
        user_type: "doctor",
        user_type_id: "1",
        agreedOption: [1],
      },
      errors: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        phone_code: "",
        gender: "",
        date_of_birth: "",
        agreeTC: "",
      },
      error: "",
      genderoptions: ["Male", "Female"],
      loading: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleInput = this.handleInput.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }
  componentDidMount() {
    // fetch('./fake_db.json')
    //     .then(res => res.json())
    //     .then(data => {
    //         this.setState({
    //             first_name: data.first_name,
    //         });
    //     });
  }

  handlepassword = (e) => {

    $(".doctortoggle-password").toggleClass("fa-eye fa-eye-slash");
    
    let input = $('#consumerpassword');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  }
  validateMaxLength(name, value, max) {
    let data = this.state.consumerData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value1;
    }
    this.setState({ consumerData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.consumerData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    let errors = this.state.errors;
    if (name === "first_name") {
      this.validateMaxLength(name, value, max);
    } else if (name === "last_name") {
      this.validateMaxLength(name, value, max);
    } else if (name === "email") {
      this.validateMaxLength(name, value, max);
    } else if (name === "password") {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = "";
    }

    this.setState({ consumerData: data,errors:errors });
  };
  handleDOBChange = (date) => {
    let consumerData = this.state.consumerData;
    let errors = this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    consumerData.date_of_birth = offsetDate;
    errors.date_of_birth = "";
    this.setState({ consumerData: consumerData,errors });
  };

  handleCheck = (e) => {
    const newSelection = e.target.value;
    let data = this.state.consumerData;

    if (data.agreedOption.includes(parseInt(newSelection, 10))) {
      data.agreedOption.splice(
        data.agreedOption.indexOf(parseInt(newSelection, 10)),
        1
      );
    } else {
      data.agreedOption.push(parseInt(newSelection, 10));
    }

    let errors = this.state.errors;
    if(data.agreedOption.length > 1){
      errors.agreeTC = "";
    }
    this.setState({ consumerData: data,errors });
  };

  handleFormSubmit(e) {
    e.preventDefault();

    let { consumerData, errors } = this.state;

    errors = Validation(consumerData, errors);

    this.setState({ errors: errors });
    if (errors.formIsValid) {
      console.log("form is valid");
      this.setState({ loading: true });
      try {
        let data = this.state.consumerData;
        data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
        data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;
       data.date_of_birth=data.date_of_birth;
       // data.date_of_birth=moment(data.date_of_birth).format('DD-MM-YYYY').toString();
// console.log(data); return false;
        const result = axios.post(RegisterUrl, data, CONFIG);
        const p = Promise.resolve(result);
        this.setState({ loading: false });

        let resultdata = p.then((value) => {
          if (value.data.status === "1") {
            // let Notificationdata = {};
            // const options = {
            //   headers: {
            //     Accept: "*/*",
            //   },
            // };
            // console.log(value);
            // if (consumerData.phone_number !== "") {
            //   Notificationdata.phoneNumber = consumerData.phone_number;
            //   Notificationdata.trackingId = value.data.data.unique_id;
            //   Notificationdata.message =
            //     value.data.data.otp +
            //     " is Your OTP for Mobile no verification to activate your account.";
            //   // let isValidEmail = this.validateEmail(data["emailphone"]);

            //   axios
            //     .post(textNotificationURL, Notificationdata, options)
            //     .then((response) => {
            //       console.log(response);
            //       // data.loading = false;
            //       this.props.setUserData(value.data.data.user);
            //       this.props.history.push(
            //         "/verify/" + value.data.data.unique_id
            //       );
            //     })
            //     .catch((error) => {
            //       if (e.response) {
            //         this.setState({ error: error.response.data.errors });
            //         window.setTimeout(() => {
            //           this.setState({
            //             error: "",
            //           });
            //         }, 5000);
            //       }
            //     });
            // } else {
            //   Notificationdata.email = consumerData.email;
            //   Notificationdata.trackingId = value.data.data.unique_id;
            //   Notificationdata.message =
            //     value.data.data.otp +
            //     " is Your OTP for Mobile no verification to activate your account.";
            //   // let isValidEmail = this.validateEmail(data["emailphone"]);

            //   axios
            //     .post(emailNotificationURL, Notificationdata, options)
            //     .then((response) => {
            //       console.log(response);
            //       // data.loading = false;
            //       this.props.setUserData(value.data.data.user);
            //       this.props.history.push(
            //         "/verify/" + value.data.data.unique_id
            //       );
            //     })
            //     .catch((error) => {
            //       if (e.response) {
            //         this.setState({ error: error.response.data.errors });
            //         window.setTimeout(() => {
            //           this.setState({
            //             error: "",
            //           });
            //         }, 5000);
            //       }
            //     });
            // }

            this.props.setUserData(value.data.data.user);

            this.props.history.push("/verify");
          }
          if (value.data.status === "0") {
            data.phone_number = data.phone_code.concat(data.phone_number)

            this.setState({ error: value.data.message,consumerData:data });
            window.setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 5000);
          }
        });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
    }
  }

  handlePhoneInput = (event) => {
    let consumerData = this.state.consumerData;
    consumerData.phone_number = event;
    let errors = this.state.errors;
    errors.phone_number = "";
    this.setState({ consumerData,errors });
  };
  render() {
    const { consumerData, genderoptions, errors, error, loading } = this.state;

    return (
      <form className="custom-validation" onSubmit={this.handleFormSubmit}>
        {error ? <ErrorAlert message={error} /> : null}
        <div className="row clientSignUpclr">
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="consumerfirstname"
                isMandatory={true}
                inputType={"text"}
                title={"First name"}
                name={"first_name"}
                max={50}
                controlFunc={this.handleInput}
                content={consumerData.first_name}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.first_name} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="consumerlastname"
                isMandatory={true}
                inputType={"text"}
                title={"Last name"}
                name={"last_name"}
                max={50}
                controlFunc={this.handleInput}
                content={consumerData.last_name}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.last_name} />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="consumeremail"
                isMandatory={true}
                inputType={"text"}
                title={"Email"}
                name={"email"}
                max={50}
                controlFunc={this.handleInput}
                content={consumerData.email}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={errors.email} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-0">
              <Label title="Phone number" isMandatory={true} />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-control">
                  <PhoneInput
                  id="consumerphonenumber"
                    international
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={consumerData.phone_number}
                    onChange={this.handlePhoneInput}
                    error={
                      consumerData.phone_number
                        ? isPossiblePhoneNumber(consumerData.phone_number)
                          ? undefined
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />

                  {/* <InputField

    inputType={'text'}
    title={''}
    name={'phone_number'}
    controlFunc={this.handleInput}
    content={ClientData.phone_number}
    placeholder={''} /> */}
                </div>
                <SpanField
                  classname="text-danger"
                  title={errors.phone_number}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="consumerpassword"
                isMandatory={true}
                inputType={"password"}
                title={"Password"}
                name={"password"}
                max={30}
                controlFunc={this.handleInput}
                content={consumerData.password}
                placeholder={""}
              />
              <span toggle="#password-field" onClick={this.handlepassword} className="fa fa-fw fa-eye field-icon doctortoggle-password"></span>
              <SpanField classname="text-danger" title={errors.password} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group ">
              <Label title="Date of birth" isMandatory={true} />
              {/* <DateField

                                name={'date_of_birth'}
                                controlFunc={this.handleInput}
                                // content={ClientData.password}
                                placeholder={'mm-dd-yyyy'} /> */}
              {/* <ModernDatepicker
                date={consumerData.date_of_birth}
                format={"DD-MM-YYYY"}
                showBorder
                onChange={(date) => this.handleDOBChange(date)}
                placeholder={"Select your Date of birth"}
              /> */}
              <DatePicker
               id="consumerdateofbirth"
                className="form-control"
                value={consumerData.date_of_birth}
                format="dd-MM-yyyy"
                onChange={(date) => this.handleDOBChange(date)}
                yearPlaceholder='YYYY'
                monthPlaceholder='MM'
                dayPlaceholder='DD'
              />

              <SpanField classname="text-danger" title={errors.date_of_birth} />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <Label title="Gender" isMandatory={true} />

              <div className="row">
                <div className="col-sm-12">
                  {genderoptions.map((option) => {
                    return (
                      <GenderField
                      id="consumer"
                        key={option}
                        name="gender"
                        labelcls="inputimgslct"
                        spanclass={
                          option === "Male"
                            ? "checkmark chekca1"
                            : "checkmark chekca2"
                        }
                        value={option}
                        controlFunc={this.handleInput}
                        title={option}
                      />
                    );
                  })}
                </div>
              </div>
              <SpanField classname="text-danger" title={errors.gender} />
            </div>
          </div>

          
           <div className="col-sm-12">
           <CheckboxField
            setName={"agreeTC"}
            className="col-sm-12"
            type={"checkbox"}
            controlFunc={this.handleCheck}
            options={agreeOptions}
            name={"agreeTC"}
            page={"consumer"}
            selectedOptions={consumerData.agreedOption}
          />
          </div>
          <div className="col-sm-12">
            <SpanField classname="text-danger" title={errors.agreeTC} />
          </div>
          <div className="col-sm-12">
          <p className="_58mv">By clicking Sign Up, you agree to our <a href="/terms" id="terms-link" target="_blank" rel="nofollow">Terms</a>, <a href="/privacy_policy" id="privacy-link" target="_blank" rel="nofollow">Privacy Policy</a>.</p>
          </div>
          
          

          <div className="col-sm-12 btncent">
            <div className="form-group mb-0">
              <Button id="doctorsignup"
                clsname="signupbtn abtn"
                btntype="submit"
                title="Sign Up"
                action=""
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (user) => dispatch({ type: "SET_USERDATA", payload: user }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConsumerSignup)
);

// export default withRouter(ConsumerSignup);
