import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import axios from "axios";

import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import FooterSection from "../components/UI/FooterSection";


import { CONFIG } from "../config/data";

import { getSettingsData,getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';




function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

class FaqWeb extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const timeout=this.state;
    

        this.state = {
            timeout:timeout,
            isTimedOut: false,
            showModal: false,
         
            settingsData: {},
            loading: false,


        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
      

    }
    handleClose() {
        this.setState({showModal: false})
      }
    
     
       handleLogout = (e) => {
        this.setState({showModal: false})
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.history.push('/');
       
    };
    
      _onAction(e) {
        console.log('user did something', e)
        
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        
        console.log('user is active', e)
        this.setState({isTimedOut: false})
      }
       
      _onIdle = (e) =>  {
        console.log('user is idle', e)
        let isTimedOut  = this.state.isTimedOut
        let timeout=this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
    {
    localStorage.removeItem("token");
    this.props.history.push('/');
    }
      }
      
  
    componentDidMount() {

        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
         axios
          .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status == "1")
              this.setState({
                timeout: data.data.timeout
                
              });
            })
          .catch((e) => { });
    


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getSettingsData, null)
            .then((res) => {

                let settingsData = [];
                res.data.data.forEach((element, index) => {
                    let name = element.name;
                    settingsData[name] = element.value
                })
                console.log(settingsData)
                this.setState({ settingsData: settingsData })

            })
            .catch((e) => { });
    }


    render() {
        const { settingsData,timeout } = this.state;
        return (

            <div className="main-content bacfot">
                {this.props.userdata ? (
                    <div className="mainpage">
                        <section className="my-head">
                            <div className="container-fluid">
                                {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                    <BusinessHeader user_id={this.props.userdata.id} />
                                ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                    <BusinessUserHeader user_id={this.props.userdata.id} />
                                ) : (
                                    <Header user_id={this.props.userdata.id} />
                                )}
                            </div>
                        </section>
                        <div className="main-box">
                            <div className="sidemenu ">
                                {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                    <BusinessUserSideBar />
                                ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                    <BusinessUserSideBar />
                                ) : (
                                    <ClientSideBar />
                                )}
                            </div>

                            <section className="main-body hospital-profilegap">
                            <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />


                                <div className="container-fluid">


                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="font-weight-bold">FAQ</h4>
                                        </div>


                                    </div>

                                    <div className="card pt-4">
                                        <div className="card-body   ">
                                            <div className="tab-content text-center" id="v-pills-tabContent">
                                                {/* <h5 className="hedtxt2 ">Point your phone to the scanner to check-in by capturing this code</h5> */}

                                                <div
                                                    className="tab-pane fade show active  pd-15"
                                                    id="v-pills-shipping"
                                                    role="tabpanel"
                                                    aria-labelledby="v-pills-shipping-tab"

                                                >
                                                    {settingsData.faq ? renderHTML(settingsData.faq) : ""}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Footerad /> */}
                                </div>
                           
                            </section>
                            <FooterSection />
                        </div>

                    </div>
                ) : ""}

            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(FaqWeb);