import {
  isValidPhoneNumber,

} from "react-phone-number-input";

export default function Validations(data, errors) {
  
  errors = {};
  errors.formIsValid = true;
  if(data.page === "Rooms"){
    console.log(data);
    if (data.room_id === "") {
      errors.room_id = "Please enter Room id";
      errors.formIsValid = false;
    } 
    
    if (data.is_available === "") {
      errors.is_available = "Please choose availability status";
      errors.formIsValid = false;
    } 
   
    if (data.price_per_day === "") {
      errors.price_per_day = "Please enter price";
      errors.formIsValid = false;
    } 
    if (data.facilities === "") {
      errors.facilities = "Please choose facilities";
      errors.formIsValid = false;
    } 
    if (data.status === "") {
      errors.status = "Please choose room status";
      errors.formIsValid = false;
    } 
  }
  if(data.page === "addpurchaseinfo"){
    console.log(data);
    if (data.supplier_id === "") {
      errors.supplier_id = "Please enter Supplier id";
      errors.formIsValid = false;
    } 
    
    if (data.drug_id === "") {
      errors.drug_id = "Please enter Drug ID";
      errors.formIsValid = false;
    } 
   
    if (data.category === "") {
      errors.category = "Please enter category";
      errors.formIsValid = false;
    } 
    if (data.type === "") {
      errors.type = "Please enter type";
      errors.formIsValid = false;
    } 
    if (data.order_unit === "") {
      errors.order_unit = "Please enter order unit";
      errors.formIsValid = false;
    } 
    if (data.manufacturer === "") {
      errors.manufacturer = "Please enter Manufacturer";
      errors.formIsValid = false;
    } 
    if (data.min_lot === "") {
      errors.min_lot = "Please enter Min lot";
      errors.formIsValid = false;
    } 
   
    if (data.max_lot === "") {
      errors.max_lot = "Please enter Max lot";
      errors.formIsValid = false;
    } 
    if (data.discount === "") {
      errors.discount = "Please enter discount";
      errors.formIsValid = false;
    } 
    if (data.gst === "") {
      errors.gst = "Please enter GST";
      errors.formIsValid = false;
    } 
    if (data.price_per_unit === "") {
      errors.price_per_unit = "Please enter Price per unit";
      errors.formIsValid = false;
    } 
    if (data.status === "") {
      errors.status = "Please choose status";
      errors.formIsValid = false;
    } 
    
    
    

  }
  if (data.type === 'supplierlist') {
    if (data.supplier_id === "") {
      errors.supplier_id = "Please enter a Supplier Id";
      errors.formIsValid = false;
    } else if (typeof data.supplier_id !== "undefined") {
      if (!data.supplier_id.match(/^[A-Za-z0-9]+$/)) {
        errors.supplier_id = "Please enter valid supplier ID .";
        errors.formIsValid = false;
      }
    }
    if (data.supplier_name === "") {
      errors.supplier_name = "Please enter a Supplier Name";
      errors.formIsValid = false;
    } else if (typeof data.supplier_name !== "undefined") {
      if (!data.supplier_name.match(/^[a-zA-Z ]*$/)) {
        errors.supplier_name = "Please enter valid Supplier Name .";
        errors.formIsValid = false;
      }
    }
    if (data.status === "") {
      errors.status = "Please choose Supplier status";
      errors.formIsValid = false;
    }
    if (data.payment_terms === "") {
      errors.payment_terms = "Please enter Payment terms";
      errors.formIsValid = false;
    }
    if (data.address === "") {
      errors.address = "Please enter a Address";
      errors.formIsValid = false;
    }
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }
    if (data.gst === "") {
      errors.gst = "Please enter GST percentage";
      errors.formIsValid = false;
    }
    if (data.discount=== "") {
      errors.discount = "Please enter Discount percentage";
      errors.formIsValid = false;
    }
  }
  if (data.type === 'storageBinlist') {
    if (data.storagebin_id === "") {
      errors.storagebin_id = "Please enter a storage bin ID";
      errors.formIsValid = false;
    } else if (typeof data.storagebin_id !== "undefined") {
      if (!data.storagebin_id.match(/^[A-Za-z0-9]+$/)) {
        errors.storagebin_id = "Please enter valid storage  bin ID .";
        errors.formIsValid = false;
      }
    }
    if (data.storage_type === "") {
      errors.storage_type = "Please enter a storage type Name";
      errors.formIsValid = false;
    } else if (typeof data.storage_type !== "undefined") {
      if (!data.storage_type.match(/^[a-zA-Z ]*$/)) {
        errors.storage_type = "Please enter valid storage type Name .";
        errors.formIsValid = false;
      }
    }
    if (data.capacity === "") {
      errors.capacity = "Please enter a capacity";
      errors.formIsValid = false;
    }
    if (data.stock_placement === "") {
      errors.stock_placement = "Please choose a stock placement";
      errors.formIsValid = false;
    }
    if (data.stock_removal === "") {
      errors.stock_removal = "Please choose a stock removal";
      errors.formIsValid = false;
    }
    if (data.rack === "") {
      errors.rack = "Please enter a rack";
      errors.formIsValid = false;
    }
    if (data.aisle === "") {
      errors.aisle = "Please enter a aisle";
      errors.formIsValid = false;
    }
    if (data.status=== "") {
      errors.status = "Please choose a status";
      errors.formIsValid = false;
    }
  }

  if (data.type === 'Transfertype') {
    
    if (data.Transfer_type === "") {
      errors.Transfer_type = "Please enter a Transfer type Name";
      errors.formIsValid = false;
    } else if (typeof data.Transfer_type !== "undefined") {
      if (!data.Transfer_type.match(/^[a-zA-Z ]*$/)) {
        errors.Transfer_type = "Please enter valid Transfer type Name .";
        errors.formIsValid = false;
      }
    }
    
  }

if(data.type === "adddrugplanningdata"){
  console.log(data);
  if (data.drug_id === "") {
    errors.drug_id = "Please enter Drug id";
    errors.formIsValid = false;
  } 
  // if (data.vendor_drug_id === "") {
  //   errors.vendor_drug_id = "Please enter Vendor Drug id";
  //   errors.formIsValid = false;
  // } 
  // if (data.drug_category === "") {
  //   errors.drug_category = "Please enter Drug category";
  //   errors.formIsValid = false;
  // } 
  // if (data.drug_type === "") {
  //   errors.drug_type = "Please enter Drug type";
  //   errors.formIsValid = false;
  // } 
  // if (data.active_ingredients === "") {
  //   errors.active_ingredients = "Please enter Active Ingredients";
  //   errors.formIsValid = false;
  // } 
  // if (data.manufacturer === "") {
  //   errors.manufacturer = "Please enter Manufacturer";
  //   errors.formIsValid = false;
  // } 
  if (data.generic_name === "") {
    errors.generic_name = "Please enter Generic name";
    errors.formIsValid = false;
  } 
  if (data.mrp === "") {
    errors.mrp = "Please enter MRP";
    errors.formIsValid = false;
  } 
  if (data.expiry_date === "") {
    errors.expiry_date = "Please choose Expiry date";
    errors.formIsValid = false;
  } 
  if (data.stock_keeping_unit === "") {
    errors.stock_keeping_unit = "Please enter Stock Keeping Unit";
    errors.formIsValid = false;
  } 
  if (data.ordering_unit === "") {
    errors.ordering_unit = "Please enter Ordering Unit";
    errors.formIsValid = false;
  } 
  // if (data.safety_stock === "") {
  //   errors.safety_stock = "Please enter Safety Stock";
  //   errors.formIsValid = false;
  // } 
  
  
  
  
  
  
  // if (data.maximum_stock_level === "") {
  //   errors.maximum_stock_level = "Please enter Maximum Stock Level";
  //   errors.formIsValid = false;
  // } if (data.minimum_lot_size === "") {
  //   errors.minimum_lot_size = "Please enter Minimum lot sizw";
  //   errors.formIsValid = false;
  // } if (data.maximum_lot_size === "") {
  //   errors.maximum_lot_size = "Please enter Maximum lot size";
  //   errors.formIsValid = false;
  // } if (data.re_order_point === "") {
  //   errors.re_order_point = "Please enter Reorder Point";
  //   errors.formIsValid = false;
  // } if (data.storage_bin === "") {
  //   errors.storage_bin = "Please enter Storage bin";
  //   errors.formIsValid = false;
  // } if (data.rake === "") {
  //   errors.rake = "Please enter Rakename";
  //   errors.formIsValid = false;
  // } 
  // if (data.asile === "") {
  //   errors.asile = "Please enter Asile";
  //   errors.formIsValid = false;
  // } 
  // if (data.asile === "") {
  //   errors.asile = "Please enter Asile";
  //   errors.formIsValid = false;
  // } 

  if (data.price_per_unit === "") {
    errors.price_per_unit = "Please enter Price per unit";
    errors.formIsValid = false;
  } 
  // if (data.discount_percentage === "") {
  //   errors.discount_percentage = "Please enter Discount percentage";
  //   errors.formIsValid = false;
  // } 
  if (data.tax_category === "") {
    errors.tax_category = "Please Tax category";
    errors.formIsValid = false;
  } 
}
  if(data.type === "adddrugplanningdata"){
    console.log(data);
    if (data.drug_id === "") {
      errors.drug_id = "Please enter Drug id";
      errors.formIsValid = false;
    } 
    // if (data.vendor_drug_id === "") {
    //   errors.vendor_drug_id = "Please enter Vendor Drug id";
    //   errors.formIsValid = false;
    // } 
    if (data.drug_name === "") {
      errors.drug_name = "Please enter Drug Name";
      errors.formIsValid = false;
    } 
    // if (data.drug_type === "") {
    //   errors.drug_type = "Please enter Drug type";
    //   errors.formIsValid = false;
    // } 
    // if (data.active_ingredients === "") {
    //   errors.active_ingredients = "Please enter Active Ingredients";
    //   errors.formIsValid = false;
    // } 
    // if (data.manufacturer === "") {
    //   errors.manufacturer = "Please enter Manufacturer";
    //   errors.formIsValid = false;
    // } 
    if (data.generic_name === "") {
      errors.generic_name = "Please enter Generic name";
      errors.formIsValid = false;
    } 
    if (data.mrp === "") {
      errors.mrp = "Please enter MRP";
      errors.formIsValid = false;
    } 
    if (data.expiry_date === "") {
      errors.expiry_date = "Please choose Expiry date";
      errors.formIsValid = false;
    } 
    if (data.stock_keeping_unit === "") {
      errors.stock_keeping_unit = "Please enter Stock Keeping Unit";
      errors.formIsValid = false;
    } 
    if (data.ordering_unit === "") {
      errors.ordering_unit = "Please enter Ordering Unit";
      errors.formIsValid = false;
    } 
    // if (data.safety_stock === "") {
    //   errors.safety_stock = "Please enter Safety Stock";
    //   errors.formIsValid = false;
    // } 
    
    
    
    
    
    
    // if (data.maximum_stock_level === "") {
    //   errors.maximum_stock_level = "Please enter Maximum Stock Level";
    //   errors.formIsValid = false;
    // } if (data.minimum_lot_size === "") {
    //   errors.minimum_lot_size = "Please enter Minimum lot sizw";
    //   errors.formIsValid = false;
    // } if (data.maximum_lot_size === "") {
    //   errors.maximum_lot_size = "Please enter Maximum lot size";
    //   errors.formIsValid = false;
    // } if (data.re_order_point === "") {
    //   errors.re_order_point = "Please enter Reorder Point";
    //   errors.formIsValid = false;
    // } if (data.storage_bin === "") {
    //   errors.storage_bin = "Please enter Storage bin";
    //   errors.formIsValid = false;
    // } if (data.rake === "") {
    //   errors.rake = "Please enter Rakename";
    //   errors.formIsValid = false;
    // } 
    // if (data.asile === "") {
    //   errors.asile = "Please enter Asile";
    //   errors.formIsValid = false;
    // } 
    // if (data.asile === "") {
    //   errors.asile = "Please enter Asile";
    //   errors.formIsValid = false;
    // } 

    if (data.price_per_unit === "") {
      errors.price_per_unit = "Please enter Price per unit";
      errors.formIsValid = false;
    } 
    // if (data.discount_percentage === "") {
    //   errors.discount_percentage = "Please enter Discount percentage";
    //   errors.formIsValid = false;
    // } 
    if (data.tax_category === "") {
      errors.tax_category = "Please Tax category";
      errors.formIsValid = false;
    } 

    
    
    

  }
  if (data.type === 'medicinesupplies') {
    if (data.supplies_id === "") {
      errors.supplies_id = "The Supplies ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.supplies_id !== "undefined") {
      if (!data.supplies_id.match(/^[A-Za-z0-9]+$/)) {
        errors.supplies_id = "Please enter valid supplies ID .";
        errors.formIsValid = false;
      }
    }
    if (data.supplies_name === "") {
      errors.supplies_name = "The Supplies Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.supplies_name !== "undefined") {
      if (!data.supplies_name.match(/^[a-zA-Z ]*$/)) {
        errors.supplies_name = "Only Characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.supplies_category === "") {
      errors.supplies_category = "Select your Supply Category field.";
      errors.formIsValid = false;
    }
    if (data.amount === "") {
      errors.amount = "The Amount field is required.";
      errors.formIsValid = false;
    }
  }
  if (data.type === 'promocodeList') {
    if (data.promo_code === "") {
      errors.promo_code = "Please enter a promocode";
      errors.formIsValid = false;
    }
    if (data.percentage === "") {
      errors.percentage = "Please enter a percentage";
      errors.formIsValid = false;
    }
    if (data.expires_at === "" || data.expires_at == null) {
      errors.expires_at = "Please choose Expire date";
      errors.formIsValid = false;
    }
  }
  if (data.type === 'applyPromo') {
    if (data.promo_code === "") {
      errors.promo_code = "Please enter a promocode";
      errors.formIsValid = false;
    }
    if (data.months === "") {
      errors.months = "Please choose month";
      errors.formIsValid = false;
    }
    if (data.subscription_id === "" || data.subscription_id == null) {
      errors.subscription_id = "Please choose subscription type";
      errors.formIsValid = false;
    }
  }
  if (data.type === "vendorform") {
    if (data.vendor_name === "") {
      errors.vendor_name = "Please enter a vendor name";
      errors.formIsValid = false;
    }
    if (data.vendor_category === "") {
      errors.vendor_category = "Please enter a vendor category";
      errors.formIsValid = false;
    } if (data.address1 === "" || data.address1 == null) {
      errors.address1 = "Choose address from Google.";
      errors.formIsValid = false;
    }

    if (data.city === "" || data.city == null) 
    
    {
      errors.city = "Please enter your City.";
      errors.formIsValid = false;
    }
    if (data.country === "" || data.country == null) {
      errors.country = "Please enter your Country.";
      errors.formIsValid = false;
    }
    if (data.state === "" || data.state == null) {
      errors.state = "Please enter your State.";
      errors.formIsValid = false;
    }
    if (data.zipcode === "" || data.zipcode == null) {
      errors.zipcode = "Please enter your Zip/Pincode.";
      errors.formIsValid = false;
    }

    if (
      data.phone_number !== "" &&
      typeof data["phone_number"] !== "undefined"
    ) {
      if (!isValidPhoneNumber(data.phone_number)) {
        // formIsValid = false;
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }
    if (data.vendor_subscription === "") {
      errors.vendor_subscription = "Please enter vendor subscription.";
      errors.formIsValid = false;
    }
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
  }
  if (data.type === "referral_page") {
    if (data.name === "" || data.name == null) {
      errors.name = "The Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.name !== "undefined") {
      if (!data.name.match(/^[a-zA-Z]*$/)) {
        errors.name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /* if (data.category === "") {
      errors.category = "Please Select a category.";
      errors.formIsValid = false;
    } */
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }
  }
  if (data.user_type === "patientsignup") {
    if (data.first_name === "" || data.first_name == null) {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      if (!data.first_name.match(/^[a-zA-Z]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    // if (
    //   data.phone_number !== "" &&
    //   typeof data["phone_number"] !== "undefined"
    // ) {
    //   if (!isValidPhoneNumber(data.phone_number)) {
    //     // formIsValid = false;
    //     errors["phone_number"] = "Please enter valid Phone number.";
    //     errors.formIsValid = false;
    //   }
    // }
    // if (data.phone_number === "") {
    //   errors.phone_number = "Please enter phone number.";
    //   errors.formIsValid = false;
    // }

    if (data.password === "") {
      errors.password = "The Password field is required.";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        // formIsValid = false;
        errors["password"] =
          "Your password must fulfill the following requirements. The password must contain one upper letters(A-Z) and one lower case letters(a-z) one number(0-9) and one special character (ex. !@#$%^&*) and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }

    if (data.gender === "" || data.gender == null) {
       errors.gender = "Select the Gender field.";
       errors.formIsValid = false;
     
    }


    if (data.date_of_birth === "" || data.date_of_birth == null) {
      errors.date_of_birth = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }
    if (!data.agreedOption.includes(2)) {
      errors.agreeTC = "Accept Terms & Privacy Policy before submitting.";
      errors.formIsValid = false;
    }
  }
  if (data.user_type === "patient") {
    errors.first_name = ""; errors.last_name = ""; errors.email = ""; errors.phone_number = ""; errors.gender = ""; errors.date_of_birth = "";
    errors.address1 = "";
    errors.city = "";
    errors.country = "";
    errors.state = "";
    errors.zip_code = "";
    errors.nationality = "";
    errors.marital_status = "";
    if (data.first_name === "" || data.first_name == null) {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      if (!data.first_name.match(/^[a-zA-Z]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }


    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    // if (data.age === "" || data.age == null) {
    //   errors.age = "Please enter your age";
    //   errors.formIsValid = false;
    // }

    // if (data.email === '') {
    //     errors.email = 'The Email field is required';
    //     errors.formIsValid = false;
    // }
    if (data.email !== "" && data.email !== null) {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }
    if (
      data.phone_number !== "" &&
      typeof data["phone_number"] !== "undefined"
    ) {
      if (!isValidPhoneNumber(data.phone_number)) {
        // formIsValid = false;
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    /* if (
      data.emergency_contact.phone_number !== "" &&
      typeof data["emergency_contact"] !== "undefined"
    ) {
      if (!isValidPhoneNumber(data.emergency_contact.phone_number)) {
        // formIsValid = false;
        errors["emergency_contact"] = "Please enter a valid Emergency number.";
        errors.formIsValid = false;
      }
    } */

    if (data.password === "") {
      errors.password = "The Password field is required.";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        // formIsValid = false;
        errors["password"] =
          "Please enter secure and strong Password. The password must contain one upper and one lower case character one number and one special character and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }

    if (data.gender === "" || data.gender == null) {
      errors.gender = "Select the Gender field.";
      errors.formIsValid = false;
    }
    if (data.date_of_birth === "" || data.date_of_birth == null) {
      errors.date_of_birth = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }

    if (data.address1 === "" || data.address1 == null) {
      errors.address1 = "Choose the Address field from Google.";
      errors.formIsValid = false;
    }

    if (data.city === "" || data.city == null) {
      errors.city = "Choose the City field from Google.";
      errors.formIsValid = false;
    }
    if (data.country === "" || data.country == null) {
      errors.country = "Choose the Country field from Google.";
      errors.formIsValid = false;
    }
    if (data.state === "" || data.state == null) {
      errors.state = " Choose the State field from Google.";
      errors.formIsValid = false;
    }
    if (data.zip_code === "" || data.zip_code == null) {
      errors.zip_code = "Choose the Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

   /*  if (
      data.blood_group === "" ||
      data.blood_group == null ||
      data.blood_group.length === 0
    ) {
      errors.blood_group = "Select the Blood Group field.";
      errors.formIsValid = false;
    } */

    if (data.gender === "" || data.gender == null) {
      errors.gender = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }

   /*  if (
      data.marital_status === "" ||
      data.marital_status == null|| data.marital_status.length === 0
    ) {
      errors.marital_status = "Select the Marital Status field.";
      errors.formIsValid = false;
    } */
    if (
      data.nationality === "" ||
      data.nationality == null ||
      data.nationality.length === 0
    ) {
      errors.nationality = "Select the Nationality field.";
      errors.formIsValid = false;
    }
  } else if (data.user_type === "doctor") {
    if (data.first_name === "" || data.first_name == null) {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      if (!data.first_name.match(/^[a-zA-Z]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    if (data.password === "") {
      errors.password = "The Password field is required";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        // formIsValid = false;
        errors["password"] =
          "Please enter secure and strong Password.The password must contain one upper letters(A-Z) and one lower case letters(a-z) one number(0-9) and one special character (ex. !@#$%^&*) and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }

    if (data.gender === "") {
      errors.gender = "Select your Gender field.";
      errors.formIsValid = false;
    }
    if (data.date_of_birth === "") {
      errors.date_of_birth = "Select your DOB field.";
      errors.formIsValid = false;
    }
    if (!data.agreedOption.includes(2)) {
      errors.agreeTC = "Accept the Terms & Privacy Policy before submitting.";
      errors.formIsValid = false;
    }
  } else if (data.user_type === "hospital") {
    
    if (data.category_array === "") {
      errors.category_array = "Select your Business Category field.";
      errors.formIsValid = false;
    }

    if (data.business_name === "" || data.business_name == null) {
      errors.business_name = "The Business Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.business_name !== "undefined") {
      if (!data.business_name.match(/^[a-zA-Z ]*$/)) {
        errors.business_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }


    if (data.address1 === "") {
      errors.address1 = "Choose the Address field from Google.";
      errors.formIsValid = false;
    }

    if (data.city === "") {
      errors.city = " Choose the City field from Google.";
      errors.formIsValid = false;
    }
    if (data.country === "") {
      errors.country = "Choose the Country field from Google.";
      errors.formIsValid = false;
    }
    if (data.state === "") {
      errors.state = "Choose the State field from Google.";
      errors.formIsValid = false;
    }
    if (data.zip_code === "") {
      errors.zip_code = "Choose the Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (typeof data["email"] !== "undefined") {
      //regular expression for email validation

      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
     if (data.phone_number === "" || data.phone_number == null) {
       errors.phone_number = "The Phone number is required.";
       errors.formIsValid = false;
     } else if (typeof data["phone_number"] !== "undefined") {
       if (!isValidPhoneNumber(data.phone_number)) {
         errors["phone_number"] = "Invalid Phone number.";
         errors.formIsValid = false;
       }
     }

    if (data.password === "") {
      errors.password = "The Password field is required.";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        // formIsValid = false;
        errors["password"] =
          "Please enter secure and strong Password. The password must contain one upper and one lower case character one number and one special character and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }

    if (!data.agreedOption.includes(2)) {
      errors.agreeTC = "Accept the Terms & Privacy Policy before submitting";
      errors.formIsValid = false;
    }
  }
   else if (data.user_type === "hospitalsignup") {
    if (data.category_array === "") {
      errors.category_array = "Select your Business Category field.";
      errors.formIsValid = false;
    }

    if (data.business_name === "") {
      errors.business_name = "The Business Name field is required";
      errors.formIsValid = false;
    }

    if (data.schedule_type === "" || null === data.schedule_type) {
      errors.schedule_type = "Choose your Schedule type field.";
      errors.formIsValid = false;
    }
    if (data.address1 === "") {
      errors.address1 = "Choose address from Google.";
      errors.formIsValid = false;
    }

    //   if (typeof data.address2 !== "undefined") {
    //     
    //   if (!data.address2.match(/^[a-zA-Z]*$/)) {
    //     errors.address2 = "Please enter valid Address.";
    //     errors.formIsValid = false;
    //   }
    // }

    if (data.city === "") {
      errors.city = "Choose the City field from Google.";
      errors.formIsValid = false;
    } else if (typeof data.city !== "undefined") {
      
      if (!data.city.match(/^[a-zA-Z ]*$/)) {
        errors.city = "Please enter valid City.";
        errors.formIsValid = false;
      }
    }
    if (data.country === "") {
      errors.country = " Choose the Country field from Google.";
      errors.formIsValid = false;
    }  else if (typeof data.country !== "undefined") {
      
      if (!data.country.match(/^[a-zA-Z ]*$/)) {
        errors.country = "Please enter valid Country.";
        errors.formIsValid = false;
      }
    } 
    if (data.state === "") {
      errors.state = " Choose the State field from Google.";
      errors.formIsValid = false;
    }

    if (data.zip_code === "") {
      errors.zip_code = "Choose the Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    if (data.fax_number != null ) {
      //      
      if (data.fax_number  && !data.fax_number.match(/^[0-9\b]+$/)) {
        errors.fax_number = "Please enter valid fax number.";
        errors.formIsValid = false;
      }
    }


    // if (data.email === "") {
    //   errors.email = "Please enter your Email address.";
    //   errors.formIsValid = false;
    // }
    // if (typeof data["email"] !== "undefined") {
    //   //regular expression for email validation
    //   pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    //   if (!pattern.test(data["email"])) {
    //     errors.formIsValid = false;
    //     errors["email"] = "Please enter valid Email-ID.";
    //   }
    // }




    // if (data.phone_number === "") {
    //   errors.phone_number = "Please enter your Phone number.";
    //   errors.formIsValid = false;
    // } else if (typeof data["phone_number"] !== "undefined") {
    //   if (!isValidPhoneNumber(data.phone_number)) {
    //     errors["phone_number"] = "Please enter valid Phone number.";
    //     errors.formIsValid = false;
    //   }
    // }

    if (data.password === "") {
      errors.password = "The Password field is required.";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        // formIsValid = false;
        errors["password"] =
          "Please enter secure and strong Password. The password must contain one upper and one lower case character one number and one special character and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }
    
    
    if (
      (data.working_days && !data.working_days.length) ||
      null === data.working_days || "" === data.working_days
    ) {
      errors.working_days = "Choose your Working Days field";
      errors.formIsValid = false;
    }
    if (data.working_hours.morning_opens_at === "" && data.working_hours.morning_close_at === "") {
      errors.morning_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
    }
    else if (data.working_hours.evening_opens_at === "" && data.working_hours.evening_close_at === "") {
      errors.evening_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
    } 
    else if(data.working_hours.morning_opens_at !== "" && data.working_hours.morning_close_at === "") {
      errors.morning_close_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.morning_close_at !== "" && data.working_hours.morning_opens_at === "") {
      errors.morning_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_opens_at !== "" && data.working_hours.evening_close_at === "") {
      errors.evening_close_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_close_at !== "" && data.working_hours.evening_opens_at === "") {
      errors.evening_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 



    if (data.working_hours.morning_opens_at >= data.working_hours.morning_close_at) {
      errors.working_hours_morning = "Please select valid morning slots";
      errors.formIsValid = false;
    }
    if (
      data.working_hours &&
      data.working_hours.evening_opens_at === "" &&
      data.working_hours.evening_opens_at === ""
    ) {
      errors.evening_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
    } else {
      if (
        data.working_hours.evening_opens_at >=
        data.working_hours.evening_close_at
      ) {
        errors.working_hours_evening = "Please select valid evening slots";
        errors.formIsValid = false;
      }
    }
    if (
      data.working_hours.evening_opens_at < data.working_hours.morning_close_at
    ) {
      errors.working_hours_evening = "Please select valid evening slots";
      errors.formIsValid = false;
    }




    // if (data.phone_number === "" || data.phone_number == null) {
    //   errors.phone_number = "Please enter your Phone number.";
    //   errors.formIsValid = false;
    // } else if (typeof data["phone_number"] !== "undefined") {
    //   if (!isValidPhoneNumber(data.phone_number)) {
    //     errors["phone_number"] = "Please enter valid Phone number.";
    //     errors.formIsValid = false;
    //   }
    // }
    
    if (null === data.speciality || !data.speciality.length || data.speciality == []) {
      errors.speciality = "Select your Speciality field.";
      errors.formIsValid = false;
    }
  }  else if (data.user_type === "pharmacysignup") {
    if (data.category_array === "") {
      errors.category_array = "Select your Business Category field.";
      errors.formIsValid = false;
    }

    if (data.pharmacy_name === "") {
      errors.pharmacy_name = "The Business Name field is required";
      errors.formIsValid = false;
    }

   /*  if (data.schedule_type === "" || null === data.schedule_type) {
      errors.schedule_type = "Choose your Schedule type field.";
      errors.formIsValid = false;
    } */
    if (data.address1 === "") {
      errors.address1 = "Choose address from Google.";
      errors.formIsValid = false;
    }

    //   if (typeof data.address2 !== "undefined") {
    //     
    //   if (!data.address2.match(/^[a-zA-Z]*$/)) {
    //     errors.address2 = "Please enter valid Address.";
    //     errors.formIsValid = false;
    //   }
    // }

    if (data.city === "") {
      errors.city = "Choose the City field from Google.";
      errors.formIsValid = false;
    } else if (typeof data.city !== "undefined") {
      
      if (!data.city.match(/^[a-zA-Z ]*$/)) {
        errors.city = "Please enter valid City.";
        errors.formIsValid = false;
      }
    }
    if (data.country === "") {
      errors.country = " Choose the Country field from Google.";
      errors.formIsValid = false;
    }  else if (typeof data.country !== "undefined") {
      
      if (!data.country.match(/^[a-zA-Z ]*$/)) {
        errors.country = "Please enter valid Country.";
        errors.formIsValid = false;
      }
    } 
    if (data.state === "") {
      errors.state = " Choose the State field from Google.";
      errors.formIsValid = false;
    }

    if (data.zip_code === "") {
      errors.zip_code = "Choose the Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    if (data.fax_number != null ) {
      //      
      if (data.fax_number  && !data.fax_number.match(/^[0-9\b]+$/)) {
        errors.fax_number = "Please enter valid fax number.";
        errors.formIsValid = false;
      }
    }


    // if (data.email === "") {
    //   errors.email = "Please enter your Email address.";
    //   errors.formIsValid = false;
    // }
    // if (typeof data["email"] !== "undefined") {
    //   //regular expression for email validation
    //   pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    //   if (!pattern.test(data["email"])) {
    //     errors.formIsValid = false;
    //     errors["email"] = "Please enter valid Email-ID.";
    //   }
    // }




    // if (data.phone_number === "") {
    //   errors.phone_number = "Please enter your Phone number.";
    //   errors.formIsValid = false;
    // } else if (typeof data["phone_number"] !== "undefined") {
    //   if (!isValidPhoneNumber(data.phone_number)) {
    //     errors["phone_number"] = "Please enter valid Phone number.";
    //     errors.formIsValid = false;
    //   }
    // }

    if (data.password === "") {
      errors.password = "The Password field is required.";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        // formIsValid = false;
        errors["password"] =
          "Please enter secure and strong Password. The password must contain one upper and one lower case character one number and one special character and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }
    
    
    if (
      (data.working_days && !data.working_days.length) ||
      null === data.working_days || "" === data.working_days
    ) {
      errors.working_days = "Choose your Working Days field";
      errors.formIsValid = false;
    }
    if (data.working_hours.morning_opens_at === "" && data.working_hours.morning_close_at === "") {
      errors.morning_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
    }
    else if (data.working_hours.evening_opens_at === "" && data.working_hours.evening_close_at === "") {
      errors.evening_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
    } 
    else if(data.working_hours.morning_opens_at !== "" && data.working_hours.morning_close_at === "") {
      errors.morning_close_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.morning_close_at !== "" && data.working_hours.morning_opens_at === "") {
      errors.morning_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_opens_at !== "" && data.working_hours.evening_close_at === "") {
      errors.evening_close_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_close_at !== "" && data.working_hours.evening_opens_at === "") {
      errors.evening_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 



    if (data.working_hours.morning_opens_at >= data.working_hours.morning_close_at) {
      errors.working_hours_morning = "Please select valid morning slots";
      errors.formIsValid = false;
    }
    if (
      data.working_hours &&
      data.working_hours.evening_opens_at === "" &&
      data.working_hours.evening_opens_at === ""
    ) {
      errors.evening_opens_at = "Select your Working Hours field.";
      errors.formIsValid = false;
    } else {
      if (
        data.working_hours.evening_opens_at >=
        data.working_hours.evening_close_at
      ) {
        errors.working_hours_evening = "Please select valid evening slots";
        errors.formIsValid = false;
      }
    }
    if (
      data.working_hours.evening_opens_at < data.working_hours.morning_close_at
    ) {
      errors.working_hours_evening = "Please select valid evening slots";
      errors.formIsValid = false;
    }




    // if (data.phone_number === "" || data.phone_number == null) {
    //   errors.phone_number = "Please enter your Phone number.";
    //   errors.formIsValid = false;
    // } else if (typeof data["phone_number"] !== "undefined") {
    //   if (!isValidPhoneNumber(data.phone_number)) {
    //     errors["phone_number"] = "Please enter valid Phone number.";
    //     errors.formIsValid = false;
    //   }
    // }
    
    if (null === data.speciality || !data.speciality.length || data.speciality == []) {
      errors.speciality = "Select your Speciality field.";
      errors.formIsValid = false;
    }
  } 
  else if (data.user_type === "forgetpwd_user") {
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }
  } else if (data.user_type === "verifyotp_user") {
    if (data.otp === "") {
      errors.otp = "Please enter OTP";
      errors.formIsValid = false;
    } else if (typeof data["otp"] !== "undefined") {
      if (!data["otp"].match(/^[0-9]{6}$/)) {
        // formIsValid = false;
        errors["otp"] = "Please enter valid OTP.";
        errors.formIsValid = false;
      }
    }
  } else if (data.user_type === "resetpwd_user") {
    if (data.password === "") {
      errors.password = "The New Password field is required.";
      errors.formIsValid = false;
    }

    if (data.confirm_password === "") {
      errors.confirm_password = "The Confirm Password field is required.";
      errors.formIsValid = false;
    }

    if (typeof data["password"] !== "undefined" && data["password"] !== "") {
      if (
        !data["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        errors["password"] =
          "Please enter secure and strong Password. The password must contain one upper and one lower case character one number and one special character and total length of 8 characters.";
        errors.formIsValid = false;
      } else if (data["password"] !== data["confirm_password"]) {
        errors["confirm_password"] = "Password didn't match";
        errors.formIsValid = false;
      }
    }
  } else if (data.user_type === "business_user") {
    
    /*first name */
    if (data.first_name === "") {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      
      
      if (!data.first_name.match(/^[a-zA-Z ]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /*last name*/
    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z ]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }
    /* digital signature*/
    if (data.image === "" || data.image === null) {
      errors.image = "Please sign in the canvas or upload a pic of signature";
      errors.formIsValid = false;
    }

    /* DOB */
    if (data.date_of_birth === "" || data.date_of_birth === null) {
      errors.date_of_birth = "Select your DOB field.";
      errors.formIsValid = false;
    }

    /* Address */

    if (data.address1 === "" || data.address1 == null) {
      errors.address1 = "Choose your Address field from Google.";
      errors.formIsValid = false;
    } else if (typeof data.address1 !== "undefined") {
      // if (!data.address1.match(/^[A-Za-z0-9 ]+$/)) {
      //   errors.address1 = "Please enter valid Address.";
      //   errors.formIsValid = false;
      // }
    }

    /* City */
    if (data.city === "" || data.city === null) {
      errors.city = "Choose your City field from Google.";
      errors.formIsValid = false;
    } else if (typeof data.city !== "undefined") {
      
      if (!data.city.match(/^[a-zA-Z ]*$/)) {
        errors.city = "Please enter valid City.";
        errors.formIsValid = false;
      }
    }

    /* Country */
    if (data.country === "" || data.country === null) {
      errors.country = "Choose your Country field from Google.";
      errors.formIsValid = false;
    }

    /* State */
    if (data.state === "" || data.state === null) {
      errors.state = " Choose your State field from Google.";
      errors.formIsValid = false;
    }

    /* Zip */
    if (data.zip_code === "" || data.zip_code === null) {
      errors.zip_code = "Choose your Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    /*phone number*/
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data.phone_number !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors.phone_number = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    /* Email */
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    } else if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }

    /* Gender */
    if (data.gender === "" || data.gender === null) {
      errors.gender = "Select your Gender field.";
      errors.formIsValid = false;
    }

    /* Speciality */
    // if (null === data.speciality || !data.speciality.length) {
    //   errors.speciality = "Please select your Speciality";
    //   errors.formIsValid = false;
    // }
    if (null === data.speciality) {
      errors.speciality = "Select your Speciality field.";
      errors.formIsValid = false;
    }

    /* Blood Group */
    if (
      null === data.blood_group ||
      "" === data.blood_group ||
      undefined === data.blood_group
    ) {
      errors.blood_group = "Select your Blood Group field.";
      errors.formIsValid = false;
    }

    /* medical licence id */
    if (data.licence_id === "" || data.licence_id === null) {
      errors.licence_id = "Select your medical license id field.";
      errors.formIsValid = false;
    }

    /* Expiry date */
    if (data.expiry_date === "" || data.expiry_date === null) {
      errors.expiry_date = "Select your expiry date field.";
      errors.formIsValid = false;
    }

    if (data.qualification === "" || null == data.qualification) {
      errors.qualification = "The Degree field is required.";
      errors.formIsValid = false;
    }

    // if (data.emergency_contact === "" || data.emergency_contact == null) {
    //   errors.emergency_contact = "Please enter your emergency contact.";
    //   errors.formIsValid = false;
    // }
    // if (
    //   data.emergency_contact !== "" &&
    //   typeof data["emergency_contact"] !== "undefined"
    // ) {
    //   if (!isValidPhoneNumber(data.emergency_contact)) {
    //     errors["emergency_contact"] = "Please enter valid emergency contact.";
    //     errors.formIsValid = false;
    //   }
    // }

    /* Speciality*/

    /* OTHER DETAILS */
    /* Working Days*/
    
    if (data.working_hours.morning_shift_start === "" && data.working_hours.moring_shift_end === "") {
      errors.morning_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
    }
    else if (data.working_hours.evening_shift_start === "" && data.working_hours.evening_shift_end === "") {
      errors.evening_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
    } 
    else if(data.working_hours.morning_shift_start !== "" && data.working_hours.moring_shift_end === "") {
      errors.moring_shift_end = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.moring_shift_end !== "" && data.working_hours.morning_shift_start === "") {
      errors.morning_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_shift_start !== "" && data.working_hours.evening_shift_end === "") {
      errors.evening_shift_end = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_shift_end !== "" && data.working_hours.evening_shift_start === "") {
      errors.evening_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
  
  
  
    if (data.working_hours.morning_shift_start >= data.working_hours.moring_shift_end) {
      errors.working_hours_morning = "The valid morning slots is required.";
      errors.formIsValid = false;
    }
    if (
      data.working_hours &&
      data.working_hours.evening_shift_start === "" &&
      data.working_hours.evening_shift_start === ""
    ) {
      errors.evening_shift_start = "The working hours is required.";
      errors.formIsValid = false;
    } else {
      if (
        data.working_hours.evening_shift_start >=
        data.working_hours.evening_shift_end
      ) {
        errors.working_hours_evening = "The valid evening slots is required.";
        errors.formIsValid = false;
      }
    }
    if (
      data.working_hours.evening_shift_start < data.working_hours.moring_shift_end
    ) {
      errors.working_hours_evening = "The valid evening slots is required.";
      errors.formIsValid = false;
    }
    if (
      (data.working_days && !data.working_days.length) ||
      null === data.working_days || "" === data.working_days
    ) {
      errors.working_days = "Choose your Working Days field";
      errors.formIsValid = false;
    }
    /* SCHEDULE TYPE */

    if (data.schedule_type === "") {
      errors.schedule_type = "Choose your Schedule type field.";
      errors.formIsValid = false;
    }
  }else if (data.user_type === "update_business_user") {
    
    /*first name */
    if (data.first_name === "") {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      
      
      if (!data.first_name.match(/^[a-zA-Z ]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /*last name*/
    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z ]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /* DOB */
    if (data.date_of_birth === "" || data.date_of_birth === null) {
      errors.date_of_birth = "Select your DOB field.";
      errors.formIsValid = false;
    }

    /* Address */

    if (data.address1 === "" || data.address1 == null) {
      errors.address1 = "Choose your Address field from Google.";
      errors.formIsValid = false;
    } else if (typeof data.address1 !== "undefined") {
      // if (!data.address1.match(/^[A-Za-z0-9 ]+$/)) {
      //   errors.address1 = "Please enter valid Address.";
      //   errors.formIsValid = false;
      // }
    }

    /* City */
    if (data.city === "" || data.city === null) {
      errors.city = "Choose your City field from Google. ";
      errors.formIsValid = false;
    } else if (typeof data.city !== "undefined") {
      
      if (!data.city.match(/^[a-zA-Z ]*$/)) {
        errors.city = "Please enter valid City.";
        errors.formIsValid = false;
      }
    }

    /* Country */
    if (data.country === "" || data.country === null) {
      errors.country = "Choose your Country field from Google.";
      errors.formIsValid = false;
    }

    /* State */
    if (data.state === "" || data.state === null) {
      errors.state = "Choose your State field from Google.";
      errors.formIsValid = false;
    }

    /* Zip */
    if (data.zip_code === "" || data.zip_code === null) {
      errors.zip_code = "Choose your Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    /*phone number*/
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data.phone_number !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors.phone_number = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    /* Email */
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    } else if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }

    /* Gender */
    if (data.gender === "" || data.gender === null) {
      errors.gender = "Select your Gender field.";
      errors.formIsValid = false;
    }

    /* Speciality */
    // if (null === data.speciality || !data.speciality.length) {
    //   errors.speciality = "Please select your Speciality";
    //   errors.formIsValid = false;
    // }
    if (null === data.speciality ) {
      errors.speciality = " Select your Speciality field.";
      errors.formIsValid = false;
    }

    /* Blood Group */
    if (
      null === data.blood_group ||
      "" === data.blood_group ||
      undefined === data.blood_group
    ) {
      errors.blood_group = "Select your Blood Group field.";
      errors.formIsValid = false;
    }

    /* medical licence id */
    if (data.licence_id === "" || data.licence_id === null) {
      errors.licence_id = "The Medical License ID field is required.";
      errors.formIsValid = false;
    }

    /* Expiry date */
    if (data.expiry_date === "" || data.expiry_date === null) {
      errors.expiry_date = "Select your Expiry Date field.";
      errors.formIsValid = false;
    }

    if (data.qualification === "" || null == data.qualification) {
      errors.qualification = "The Degree field is required.";
      errors.formIsValid = false;
    }

    // if (data.emergency_contact === "" || data.emergency_contact == null) {
    //   errors.emergency_contact = "Please enter your emergency contact.";
    //   errors.formIsValid = false;
    // }
    // if (
    //   data.emergency_contact !== "" &&
    //   typeof data["emergency_contact"] !== "undefined"
    // ) {
    //   if (!isValidPhoneNumber(data.emergency_contact)) {
    //     errors["emergency_contact"] = "Please enter valid emergency contact.";
    //     errors.formIsValid = false;
    //   }
    // }

    /* Speciality*/

    /* OTHER DETAILS */
    /* Working Days*/
    
    if (data.working_hours.morning_shift_start === "" && data.working_hours.moring_shift_end === "") {
      errors.morning_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
    }
    else if (data.working_hours.evening_shift_start === "" && data.working_hours.evening_shift_end === "") {
      errors.evening_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
    } 
    else if(data.working_hours.morning_shift_start !== "" && data.working_hours.moring_shift_end === "") {
      errors.moring_shift_end = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.moring_shift_end !== "" && data.working_hours.morning_shift_start === "") {
      errors.morning_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_shift_start !== "" && data.working_hours.evening_shift_end === "") {
      errors.evening_shift_end = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
    else if(data.working_hours.evening_shift_end !== "" && data.working_hours.evening_shift_start === "") {
      errors.evening_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
      
    } 
  
  
  
    if (data.working_hours.morning_shift_start >= data.working_hours.moring_shift_end) {
      errors.working_hours_morning = "Please select valid morning slots";
      errors.formIsValid = false;
    }
    if (
      data.working_hours &&
      data.working_hours.evening_shift_start === "" &&
      data.working_hours.evening_shift_start === ""
    ) {
      errors.evening_shift_start = "Select your Working Hours field.";
      errors.formIsValid = false;
    } else {
      if (
        data.working_hours.evening_shift_start >=
        data.working_hours.evening_shift_end
      ) {
        errors.working_hours_evening = "Please select valid evening slots";
        errors.formIsValid = false;
      }
    }
    if (
      data.working_hours.evening_shift_start < data.working_hours.moring_shift_end
    ) {
      errors.working_hours_evening = "Please select valid evening slots";
      errors.formIsValid = false;
    }
    if (
      (data.working_days && !data.working_days.length) ||
      null === data.working_days || "" === data.working_days
    ) {
      errors.working_days = "Choose your Working Days field.";
      errors.formIsValid = false;
    }
    /* SCHEDULE TYPE */

    /* if (data.schedule_type === "") {
      errors.schedule_type = "Please select your schedule type";
      errors.formIsValid = false;
    } */
  }else if (data.user_type === "employee_list") {
    
    /*first name */
    if (data.first_name === "") {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      
      
      if (!data.first_name.match(/^[a-zA-Z ]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /*last name*/
    if (data.last_name === "") {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /* DOB */
    if (data.date_of_birth === "") {
      errors.date_of_birth = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }

    /* Address */

    if (data.address1 === "") {
      errors.address1 = "Choose the Address field from Google. ";
      errors.formIsValid = false;
    }

    /* City */
    if (data.city === "") {
      errors.city = "Choose the City field from Google.     ";
      errors.formIsValid = false;
    } else if (typeof data.city !== "undefined") {
      
      if (!data.city.match(/^[a-zA-Z ]*$/)) {
        errors.city = "Please enter valid City.";
        errors.formIsValid = false;
      }
    }

    /* Country */
    if (data.country === "") {
      errors.country = "Choose the Country field from Google.";
      errors.formIsValid = false;
    }

    /* State */
    if (data.state === "") {
      errors.state = "Choose the State field from Google.";
      errors.formIsValid = false;
    }

    /* Zip */
    if (data.zip_code === "") {
      errors.zip_code = "Choose the Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    /*phone number*/
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data.phone_number !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors.phone_number = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }
    /* if (typeof data.emergency_contact !== "undefined") {
      if (!isValidPhoneNumber(data.emergency_contact)) {
        errors.emergency_contact = "Please enter valid Phone number.";
        errors.formIsValid = false;
      }
    } */

    if (typeof data.employee_id !== "undefined") {
      if (!data.employee_id.match(/^[A-Za-z0-9]+$/)) {
        errors.employee_id = "Please enter valid employee ID .";
        errors.formIsValid = false;
      }
    }

    /* Email */
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    } else if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }

    /* Gender */
    if (data.gender === "") {
      errors.gender = "Select your Gender field.";
      errors.formIsValid = false;
    }
    if (data.designation === "" || data.designation == null) {
      errors.designation = "Select your Designation field.";
      errors.formIsValid = false;
    }

    /* Speciality */
    
    if ((data.speciality === "" || data.speciality === undefined || Object.keys(data.speciality).length === 0) && (data.designation && data.designation.label.toLowerCase() === "doctor")) {
      errors.speciality = "Select your Speciality field.";
      errors.formIsValid = false;
    } else {
      errors.speciality = "";
      // errors.formIsValid = true;
    }

    /* Blood Group */
     if (
      null === data.blood_group ||
      "" === data.blood_group ||
      undefined === data.blood_group
    ) {
      errors.blood_group = "Select your Blood Group field.";
      errors.formIsValid = false;
    }
 
    /* Speciality*/

    /* OTHER DETAILS */
    /* Working Days*/
    // 
    // if (data.working_days && !data.working_days.length) {
    //   errors.working_days = "Please enter working days.";
    //   errors.formIsValid = false;
    // }
    // if (
    //   (data.working_hours && data.working_hours.morning_shift_start === "") ||
    //   (data.working_hours && data.working_hours.morning_shift_end === "")
    // ) {
    //   errors.working_hours_morning = "Please select morning slots";
    //   errors.formIsValid = false;
    // } else if (
    //   data.working_hours &&
    //   typeof data.working_hours.morning_shift_start !== "undefined" &&
    //   typeof data.working_hours.morning_shift_end !== "undefined"
    // ) {
    //   if (
    //     data.working_hours.morning_shift_start >
    //     data.working_hours.morning_shift_end
    //   ) {
    //     errors.working_hours_morning = "Please select valid morning slots";
    //     errors.formIsValid = false;
    //   }
    // }

    // if (
    //   (data.working_hours && data.working_hours.evening_shift_start === "") ||
    //   (data.working_hours && data.working_hours.evening_shift_end === "")
    // ) {
    //   errors.working_hours_evening = "Please select evening slots";
    //   errors.formIsValid = false;
    // } else if (
    //   data.working_hours &&
    //   typeof data.working_hours.evening_shift_start !== "undefined" &&
    //   typeof data.working_hours.evening_shift_end !== "undefined"
    // ) {
    //   if (
    //     data.working_hours.evening_shift_start >
    //     data.working_hours.evening_shift_end
    //   ) {
    //     errors.working_hours_evening = "Please select valid evening slots";
    //     errors.formIsValid = false;
    //   }
    // }

    // /* SCHEDULE TYPE */

    // if (data.schedule_type == "") {
    //   errors.schedule_type = "Please select your schedule type";
    //   errors.formIsValid = false;
    // }
    if (data.consulting_fees === "" && (data.designation && data.designation.label === "doctor")) {
      errors.consulting_fees = "The Consulting Fees field is required.";
      errors.formIsValid = false;
    } 
  }
  else if(data.user_type === "search_employee_list" )
  {
    
       if (typeof data.id !== "undefined") {
        if (!data.id.match(/^[0-9]+$/)) {
          errors.id = "Enter a valid employee ID .";
          errors.formIsValid = false;
        }
      }
  }
  if (data.type === "login") {
    
    if (data.email === "") {
      errors.email = "Email address or phone number cannot be blank";
      errors.formIsValid = false;
    }
    // if (data.email !== "" && typeof data["email"] !== "undefined") {
    //   //regular expression for email validation
    //   pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );
    //   if (!pattern.test(data["email"])) {
    //     errors.formIsValid = false;
    //     errors["email"] = "Please enter valid Email-ID.";
    //   }
    // }
    if (data.password === "") {
      errors.password = "Password cannot be blank";
      errors.formIsValid = false;
    }
    // if (typeof data["password"] !== "undefined" && data["password"] !== "") {
    //   if (
    //     !data["password"].match(
    //       /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
    //     )
    //   ) {
    //     // formIsValid = false;
    //     errors["password"] =
    //       "Please enter secure and strong Password. The password must contain one upper and one lower case character one number and one special character and total length of 8 characters.";
    //     errors.formIsValid = false;
    //   }
    // }
  }
  if (data.type === "verification") {
    if (data.otp === "") {
      errors.otp = "Please enter your OTP";
      errors.formIsValid = false;
    }
  }
  if (data.type === "disease") {
    if (data.disease_id === "") {
      errors.disease_id = "The Disease ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.disease_id !== "undefined") {
      if (!data.disease_id.match(/^[A-Za-z0-9]+$/)) {
        errors.disease_id = "Please enter valid disease ID .";
        errors.formIsValid = false;
      }
    }
    // if (data.disease_name === "") {
    //   errors.disease_name = "Please enter Disease Name";
    //   errors.formIsValid = false;
    // } 
    /* else if (typeof data.disease_name !== "undefined") {
      if (!data.disease_name.match(/^[a-zA-Z ]*$/)) {
        errors.disease_name = "Please enter valid disease Name .";
        errors.formIsValid = false;
      }
    } */

    if (data.disease_name === "") {
      errors.disease_name = "The Disease Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.disease_name !== "undefined") {
      if (!data.disease_name.match(/^[a-zA-Z ]*$/)) {
        errors.disease_name = "Only Characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.disease_category === "") {
      errors.disease_category = "Choose your Disease category field.";
      errors.formIsValid = false;
    }
  }

  if (data.type === "adddisease") {
   // let index = data.id;
    if (data.searchdisease_id === "") {
      errors.diseaseid_error = "The Disease ID field is required.";
      
      errors.formIsValid = false;
    }
    if (data.disease_name === "") {
      errors.disease_name = "The Disease Name field is required.";
      errors.formIsValid = false;
      
    }
    if (data.disease_category === "") {
      errors.disease_category = "Choose your Disease category field.";
      errors.formIsValid = false;
      
    }
  }

  if (data.type === "clientlist") {
    /*first name */
    if (data.first_name === "") {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      if (!data.first_name.match(/^[a-zA-Z]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    /*last name*/
    if (data.last_name === "") {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (
      data.maritial_status === "" ||
      data.maritial_status.label == null|| data.maritial_status.length === 0
    ) {
      errors.maritial_status = "Select the Marital Status field.";
      errors.formIsValid = false;
    }
    /* DOB */
    if (data.date_of_birth === "") {
      errors.date_of_birth = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }

    /* Gender */
    if (data.gender === "") {
      errors.gender = "Select the Gender field.";
      errors.formIsValid = false;
    }
    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }


  }

  if (data.type === "hospitallist") {
    /*first name */

    /*last name*/
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }

    /* DOB */
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }

    if (data.subscription === "") {
      errors.subscription = "Select the Subscription field.";
      errors.formIsValid = false;
    }
    if (data.payment_status === "") {
      errors.payment_status = "Select the Payment Status field.";
      errors.formIsValid = false;
    }
    if (data.expires_at === "") {
      errors.expires_at = "The Expiration date is required.";
      errors.formIsValid = false;
    }

  }

  if (data.type === "patientlist") {
    /*first name */

    /*last name*/
    if (data.email === "" || data.email === null) {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
   else if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }

    /* DOB */
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    if (data.subscription === "") {
      errors.subscription = "Select the Subscription field.";
      errors.formIsValid = false;
    }
    if (data.payment_status === "") {
      errors.payment_status = "Select the Payment Status field.";
      errors.formIsValid = false;
    }
    if (data.expires_at === "") {
      errors.expires_at = "The Expiration date is required.";
      errors.formIsValid = false;
    }

  }

  if (data.type === "doctorlist") {
    /*first name */

    /*last name*/
    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }

    /* DOB */
    if (data.phone_number === "") {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }

    if (data.subscription === "") {
      errors.subscription = "Select the Subscription field.";
      errors.formIsValid = false;
    }
    if (data.payment_status === "") {
      errors.payment_status = "Select the Payment Status field.";
      errors.formIsValid = false;
    }
    if (data.expires_at === "") {
      errors.expires_at = "The Expiration date is required.";
      errors.formIsValid = false;
    }

  }
  if (data.type === "drug") {
    if (data.drug_id === "") {
      errors.drug_id = "The Drug ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.drug_id !== "undefined") {
      if (!data.drug_id.match(/^[A-Za-z0-9]+$/)) {
        errors.drug_id = "Please enter valid drug id .";
        errors.formIsValid = false;
      }
    }
    if (data.drug_name === "") {
      errors.drug_name = "The Drug Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.drug_name !== "undefined") {
      if (!data.drug_name.match(/^[a-zA-Z ]*$/)) {
        errors.drug_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }
    if (data.generic_name === "") {
      errors.generic_name = "The Generic Name field is required.";
      errors.formIsValid = false;
    } /* else if (typeof data.generic_name !== "undefined") {
       if (!data.generic_name.match(/^[a-zA-Z0-9 ]*$/)) {
         errors.generic_name = "Please enter valid Generic Name .";
         errors.formIsValid = false;
       }
    } */

    if (data.unit_of_measure === "") {
      errors.unit_of_measure = "Select your Unit of Measures field.";
      errors.formIsValid = false;
    } else if (typeof data.unit_of_measure !== "undefined") {
      // if (!data.drug_name.match(/^[a-zA-Z]*$/)) {
      //   errors.drug_name = "Please enter valid drug Name .";
      //   errors.formIsValid = false;
      // }
    }

    if (data.active_ingredients === "") {
      errors.active_ingredients = "The Active ingredients is required.";
      errors.formIsValid = false;
    } /* else if (typeof data.active_ingredients !== "undefined") {
       if (!data.active_ingredients.match(/^[a-zA-Z0-9 ]*$/)) {
         errors.active_ingredients = "Please enter valid active ingredients .";
         errors.formIsValid = false;
       }
    } */

    if (data.manufacturer === "") {
      errors.manufacturer = "The Manufacturer field is required.";
      errors.formIsValid = false;
    } else if (typeof data.manufacturer !== "undefined") {
       if (!data.manufacturer.match(/^[a-zA-Z ]*$/)) {
         errors.manufacturer = "Only characters are allowed.";
         errors.formIsValid = false;
       }
    }




   /*  if (data.drug_category === "") {
      errors.drug_category = "Please choose Drug Category";
      errors.formIsValid = false;
    }

    if (data.drug_type === "") {
      errors.drug_type = "Please choose Drug Category";
      errors.formIsValid = false;
    } */
  }

  if (data.type === "facilityList") {
    if ("" === data.facility_id || null === data.facility_id) {
      errors.facility_id = "The Facility ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.facility_id !== "undefined") {
      // if (!data.facility_id.match(/^[A-Za-z0-9]+$/)) {
      //   errors.facility_id = "Please enter valid Facility ID .";
      //   errors.formIsValid = false;
      // }
    }
    if ("" === data.facility_name || null === data.facility_name) {
      errors.facility_name = "The Description field is required";
      errors.formIsValid = false;
    } else if (typeof data.facility_name !== "undefined") {
      // if (!data.facility_name.match(/^[a-zA-Z ]*$/)) {
      //   errors.facility_name = "Please enter valid Facility description .";
      //   errors.formIsValid = false;
      // }
    }

    if (
      undefined === data.facility_category_obj.value ||
      "" === data.facility_category_obj.value
    ) {
      errors.facility_category = "Select the Category field.";
      errors.formIsValid = false;
    }
    if (
      undefined === data.facility_availability.value ||
      "" === data.facility_availability.value
    ) {
      errors.availability = "Choose the Availability field.";
      errors.formIsValid = false;
    }
     if ("" === data.occupied || null === data.occupied) {
       errors.occupied = "The Occupied Count field is required.";
       errors.formIsValid = false;
     }
    // if ("" === data.available || null === data.available) {
    //   errors.available = "Please enter Available Count";
    //   errors.formIsValid = false;
    // }
     if ("" === data.total || null === data.total) {
       errors.total = "The Total Count field is required.";
       errors.formIsValid = false;
     }
  }

  if (data.type === "pharmacyList") {
    
    if ("" === data.pharmacy_id || null === data.pharmacy_id) {
      errors.pharmacy_id = "The Pharmacy ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.pharmacy_id !== "undefined") {
      if (!data.pharmacy_id.match(/^[A-Za-z0-9]+$/)) {
        errors.pharmacy_id = "Please enter valid pharmacy ID .";
        errors.formIsValid = false;
      }
    }
    if ("" === data.pharmacy_name || null === data.pharmacy_name) {
      errors.pharmacy_name = "The Pharmacy Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.pharmacy_name !== "undefined") {
      if (!data.pharmacy_name.match(/^[a-zA-Z ]*$/)) {
        errors.pharmacy_name = "Only Characters are allowed.";
        errors.formIsValid = false;
      }
    }
    if (
      undefined === data.online_prescription_obj.value ||
      "" === data.online_prescription_obj.value
    ) {
      errors.online_prescription = "Choose your Online Prescription field.";
      errors.formIsValid = false;
    }

    if ("" === data.address || null === data.address) {
      errors.address = "Choose your Address field from Google."
      errors.formIsValid = false;
    }
    if ("" === data.city || null === data.city) {
      errors.city = " Choose your City field from Google.";
      errors.formIsValid = false;
    }
    if ("" === data.country || null === data.country) {
      errors.country = "Choose your Country field from Google.";
      errors.formIsValid = false;
    }
    if ("" === data.state || null === data.state) {
      errors.state = "Choose your State field from Google.";
      errors.formIsValid = false;
    }
    if ("" === data.zip_code || null === data.zip_code) {
      errors.zip_code = " Choose your Zip/Pincode field from Google.";
      errors.formIsValid = false;
    }

    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }


    if (data.email === "") {
      errors.email = "The Email field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      pattern = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
  }
  if (data.type === "labList") {
    
    if ("" === data.laboratory_id || null === data.laboratory_id) {
      errors.laboratory_id = "The Laboratory ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.laboratory_id !== "undefined") {
      if (!data.laboratory_id.match(/^[A-Za-z0-9]+$/)) {
        errors.laboratory_id = "Please enter valid laboratory ID .";
        errors.formIsValid = false;
      }
    }
    if ("" === data.laboratory_name || null === data.laboratory_name) {
      errors.laboratory_name = "The Laboratory Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.laboratory_name !== "undefined") {
      if (!data.laboratory_name.match(/^[a-zA-Z ]*$/)) {
        errors.laboratory_name = "Only Characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    } else if (typeof data["phone_number"] !== "undefined") {
      if (!isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }

    if ("" === data.laboratory_location || null === data.laboratory_location) {
      errors.laboratory_location = "Choose your Address field from Google.";
      errors.formIsValid = false;
    }
  }

  if (data.type === "symptom") {
    if (data.symptom_id === "") {
      errors.symptom_id = "The Symptom ID field is required.";
      errors.formIsValid = false;
    } else if (typeof data.symptom_id !== "undefined") {
      if (!data.symptom_id.match(/^[A-Za-z0-9]+$/)) {
        errors.symptom_id = "Please enter valid symptom ID .";
        errors.formIsValid = false;
      }
    }
    if (data.symptom_name === "") {
      errors.symptom_name = "The Symptom Name field is required.";
      errors.formIsValid = false;
    }
    if (data.symptom_category === "") {
      errors.symptom_category = "Choose your Symptom category field.";
      errors.formIsValid = false;
    }
  }

  if (data.type === "hospitalreport") {
   
    if (data.Fromdate === "") {
      errors.Fromdate = "Choose the From Date field.";
      errors.formIsValid = false;
    }
    if (data.Todate === "") {
      errors.Todate = "Choose the To Date field.";
      errors.formIsValid = false;
    }

  }

  if ("addEducationDetail" === data.type) {
    if (data.education === "") {
      errors.education = "The Education field is required.";
      errors.formIsValid = false;
    }

    if (data.specialization === "") {
      errors.specialization = "Select the Field of study.";
      errors.formIsValid = false;
    }
    if (data.university === "") {
      errors.university = "The University field is required.";
      errors.formIsValid = false;
    }
    if (data.completion_month === "" && data.completion_year !== "") {
      errors.completion_month = "Select the Completion Month field.";
      errors.formIsValid = false;
    } else if (data.completion_year === "" && data.completion_month !== "") {
      errors.completion_year = "Select the Completion Year field.";
      errors.formIsValid = false;
    }
      if (data.completion_month === "" && data.completion_year === "") {
      errors.completion_month = "Select the Completion Month field.";
      errors.completion_year = "Select the Completion Year field.";
      errors.formIsValid = false;
    } 
  }

  if ("addCertificate" === data.type) {
    
    if (data.organization === "") {
      errors.organization = "The Organization field is required.";
      errors.formIsValid = false;
    }

    if (data.specialization === "") {
      errors.specialization = "Select the Field of study.";
      errors.formIsValid = false;
    }
    // if (data.valid_URL === "") {
    //   errors.valid_URL = "Please select Valid URL";
    //   errors.formIsValid = false;
    // }
    if (data.certification_completion_month === "") {
      errors.certification_completion_month = "Select the Completion Month field.";
      errors.formIsValid = false;
    }
    if (data.certification_completion_year === "") {
      errors.certification_completion_year = " Select the Completion Year field.";
      errors.formIsValid = false;
    }
    if (data.valid_upto_month === "") {
      errors.valid_upto_month = "Please enter valid month";
      errors.formIsValid = false;
    }
    if (data.valid_upto_year === "") {
      errors.valid_upto_year = "Please enter valid year";
      errors.formIsValid = false;
    }
  }

  if ("addExperience" === data.type) {
    
    if (data.hospital === "") {
      errors.hospital = "The Hospital field is required.";
      errors.formIsValid = false;
    }

    if (data.ex_address === "") {
      errors.ex_address = "Choose the Address field from Google.";
      errors.formIsValid = false;
    }
    if (data.ex_city === "") {
      errors.ex_city = "Choose the City field from Google. ";
      errors.formIsValid = false;
    }
    if (data.ex_state === "") {
      errors.ex_state = "Choose the State field from Google.";
      errors.formIsValid = false;
    }
    if (data.ex_country === "") {
      errors.ex_country = "Choose the Country field from Google.";
      errors.formIsValid = false;
    }
    if (data.experience_from === "") {
      errors.experience_from = "Select your date of joining the field.";
      errors.formIsValid = false;
    }
    if (data.experience_to === "") {
      errors.experience_to = "Select your date of leaving the field.";
      errors.formIsValid = false;
    }
  }

  if ("addMedicalLicence" === data.type) {
    if (null == data.organization || "" === data.organization) {
      errors.organization = "The Organization field is required.";
      errors.formIsValid = false;
    }

    if (data.licence_Id === "") {
      errors.licence_Id = "The  License Id field is required.";
      errors.formIsValid = false;
    }
    if (data.expiration_date === "") {
      errors.expiration_date = "The License Valid date field is required.";
      errors.formIsValid = false;
    }
  }


  if ("AddTempClient" === data.type) {

    if (data.first_name === "" || data.first_name == null) {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    }

    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    }

    if (data.gender === "" || data.gender == null) {
      errors.gender = "Select the Gender field.";
      errors.formIsValid = false;
    }
    if (data.date_of_birth === "" || data.date_of_birth == null) {
      errors.date_of_birth = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }

    if (data.relationship === "" || data.relationship == null) {
      errors.relationship = "Select the relationship field.";
      errors.formIsValid = false;
    }

    if (data.phone_number === "" || data.phone_number == null) {
      errors.phone_number = "The Phone number is required.";
      errors.formIsValid = false;
    }

  }

  if ("AddFamilyDetails" === data.type) {
    
    if (data.first_name === "" || data.first_name == null) {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      if (!data.first_name.match(/^[a-zA-Z]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }


    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (
      null === data.blood_group ||
      "" === data.blood_group ||
      undefined === data.blood_group
    ) {
      errors.blood_group = "Select the Blood Group field.";
      errors.formIsValid = false;
    }
    
    if("Self" !== data.relationship){
      
    // if (data.phone_number === "" || data.phone_number == null) {
    //   errors.phone_number = "Please enter your Phone number.";
    //   errors.formIsValid = false;
    // } else
    
     if (typeof data["phone_number"] !== "undefined") {
      if (data.phone_number !== "" && !isValidPhoneNumber(data.phone_number)) {
        errors["phone_number"] = "Invalid Phone number.";
        errors.formIsValid = false;
      }
    }
    }


    if (data.gender === "" || data.gender == null) {
      errors.gender = "Select the Gender field.";
      errors.formIsValid = false;
    }
    if (data.date_of_birth === "" || data.date_of_birth == null) {
      errors.date_of_birth = "Select the DOB field.";
      errors.formIsValid = false;
    }

    if (data.relationship === "" || data.relationship == null) {
      errors.relationship = "Select the Relationship field.";
      errors.formIsValid = false;
    }
  }

  if ("UpdateFamilyDetails" === data.type) {
    
    if (data.first_name === "" || data.first_name == null) {
      errors.first_name = "The First Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.first_name !== "undefined") {
      if (!data.first_name.match(/^[a-zA-Z]*$/)) {
        errors.first_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }


    if (data.last_name === "" || data.last_name == null) {
      errors.last_name = "The Last Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.last_name !== "undefined") {
      if (!data.last_name.match(/^[a-zA-Z]*$/)) {
        errors.last_name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (
      null === data.blood_group ||
      "" === data.blood_group ||
      undefined === data.blood_group
    ) {
      errors.blood_group = "Select your Blood Group field.";
      errors.formIsValid = false;
    }
   
    if (data.gender === "" || data.gender == null) {
      errors.gender = "Select the Gender field.";
      errors.formIsValid = false;
    }
    if (data.date_of_birth === "" || data.date_of_birth == null) {
      errors.date_of_birth = "Select the Date Of Birth field.";
      errors.formIsValid = false;
    }

    if (data.relationship === "" || data.relationship == null) {
      errors.relationship = "Select the relationship field.";
      errors.formIsValid = false;
    }
  }

  if ("HospitalDasboardAdmin" === data.type) {
    
    if (data.cycle_time === "" || data.cycle_time == null) {
      errors.cycle_time = "The Cycle Time field is required.";
      errors.formIsValid = false;
    }

    if (data.reschedule_time === "" || data.reschedule_time == null) {
      errors.reschedule_time = "The Reschedule Time field is required.";
      errors.formIsValid = false;
    }

    if (data.daily_info_open_time === "" || data.daily_info_open_time == null) {
      errors.daily_info_open_time = "Select the Hospital Open Time field.";
      errors.formIsValid = false;
    }
    
    if (null === data.schedule_type || data.schedule_type.length <= 0) {
      errors.schedule_type = "Select the Schedule Type field.";
      errors.formIsValid = false;
    }
  }


  if ("changePassword" === data.type) {
    if (data.password === "" || data.password == null) {
      errors.password = "The New Password field is required.";
      errors.formIsValid = false;
    }

    if (
      data.password_confirmation === "" ||
      data.password_confirmation == null
    ) {
      errors.password_confirmation = "The Confirm Password field is required.";
      errors.formIsValid = false;
    }

    if (data.password_confirmation !== data.password) {
      errors.matchPassword = "Passwords do not match.Password 1 should match with password2.";
      errors.formIsValid = false;
    } else {
      if (
        !data.password.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        errors.securePassword = "Please enter secure and strong Password.The password must contain one upper letters(A-Z) and one lower case letters(a-z) one number(0-9) and one special character (ex. !@#$%^&*) and total length of 8 characters.";
        errors.formIsValid = false;
      }
    }
  }

  if ("ShiftPlannerCreateEvent" === data.type) {
    if (data.selectedDoc === null || data.selectedDoc === "") {
      errors.selectedDoc = "Select your doctor field.";
      errors.formIsValid = false;
    }
    if (data.from === null || data.from === "") {
      errors.from = "Select your From Date field.";
      errors.formIsValid = false;
    }
    if (data.to === null || data.to === "") {
      errors.to = " Select your To Date field.";
      errors.formIsValid = false;
    }
    if ((data.morning_shift_start === "" || data.morning_shift_end === "")
      && (data.evening_shift_start === "" || data.evening_shift_end === "")) {
      errors.shift = "Select atleast one shift";
      errors.formIsValid = false;
    }

    if ((data.morning_shift_start === data.morning_shift_end)
      && (data.evening_shift_start === data.evening_shift_end)) {
      errors.shift = "Select two different timings for at least one shift";
      errors.formIsValid = false;
    }

    if (data.schedule_type === null || data.schedule_type === "") {
      errors.schedule_type = "Select your Schedule Type field.";
      errors.formIsValid = false;
    }
  }
  if ("DoctorShiftPlannerCreateEvent" === data.type) {
    if (data.selectedDoc === null || data.selectedDoc === "") {
      errors.selectedDoc = "Select your hospital field.";
      errors.formIsValid = false;
    }
    if (data.from === null || data.from === "") {
      errors.from = "Select your From Date field.";
      errors.formIsValid = false;
    }
    if (data.to === null || data.to === "") {
      errors.to = " Select your To Date field.";
      errors.formIsValid = false;
    }
    if ((data.morning_shift_start === "" || data.morning_shift_end === "")
      && (data.evening_shift_start === "" || data.evening_shift_end === "")) {
      errors.shift = "Select atleast one shift";
      errors.formIsValid = false;
    }

    if ((data.morning_shift_start === data.morning_shift_end)
      && (data.evening_shift_start === data.evening_shift_end)) {
      errors.shift = "Select two different timings for at least one shift";
      errors.formIsValid = false;
    }

    if (data.schedule_type === null || data.schedule_type === "") {
      errors.schedule_type = "Select your Schedule Type field.";
      errors.formIsValid = false;
    }
  }

  if ("createQueue" === data.type) {
    //Need to change after queue issue fix
    // if (data.n_client_id === null || data.n_client_id.length == 0) {
    if (data.n_client_id === null || data.n_client_id == null) {
      errors.n_client_id = "Select any one of the people below.";
      errors.formIsValid = false;
    }

    if (data.n_business_id === "" || data.n_business_id == null) {
      errors.n_business_id = "Hospital Id is missing";
      errors.formIsValid = false;
    }

    if (data.n_business_user_id === "" || data.n_business_user_id === null) {
      errors.n_business_user_id = "Please select the doctor";
      errors.formIsValid = false;
    }
    if (data.other_comments === "" || data.other_comments=== null||data.other_comments === undefined) {
      errors.other_comments = "Please enter the Reason for visit";
      errors.formIsValid = false;
    }
    

    if (data.schedule_type !== "appointment") {
      if (
        data.n_schedule_day_id === "" ||
        data.n_schedule_day_id === undefined ||
        !data.n_schedule_day_id
      ) {
        errors.n_schedule_day_id = "Choose the Timing field.";
        errors.formIsValid = false;
      }
      if (data.other_comments === "" || data.other_comments=== null||data.other_comments === undefined) {
        errors.other_comments = "The Reason for visiting is required.";
        errors.formIsValid = false;
      }
       if((data.payment_mode === ""|| data.payment_mode === undefined || !data.payment_mode))
    {
      errors.payment_type_error = "The Payment type is required.";
      errors.formIsValid = false;

    }  
  }
    //else {
    

    if (data.clientList === null || data.clientList == null || data.clientList.length > 1) {
      errors.n_client_id = "Select only one person.";
      errors.formIsValid = false;
    }
    
    //}
  }
  if (data.type === "support") {
    if (data.name === "" || data.name == null) {
      errors.name = "The Name field is required.";
      errors.formIsValid = false;
    } else if (typeof data.name !== "undefined") {
      if (!data.name.match(/^[a-zA-Z ]*$/)) {
        errors.name = "Only characters are allowed.";
        errors.formIsValid = false;
      }
    }

    if (data.category === null || data.category === "") {
      errors.category = "Choose the Category field.";
      errors.formIsValid = false;
    }
    if (data.issue_description === null || data.issue_description === "") {
      errors.issue_description = "Choose the Description Issue field.";
      errors.formIsValid = false;
    }

    if (data.email === "") {
      errors.email = "The Email ID field is required.";
      errors.formIsValid = false;
    }
    if (data.email !== "" && typeof data["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(data["email"])) {
        errors.formIsValid = false;
        errors["email"] = "Invalid Email Address.";
      }
    }
    // if (
    //   data.phone_number !== "" &&
    //   typeof data["phone_number"] !== "undefined"
    // ) {
    //   if (!isValidPhoneNumber(data.phone_number)) {
    //     // formIsValid = false;
    //     errors["phone_number"] = "Please enter valid Phone number.";
    //     errors.formIsValid = false;
    //   }
    // }
    // if (data.phone_number === "") {
    //   errors.phone_number = "Please enter phone number.";
    //   errors.formIsValid = false;
    // }
  }

  return errors;
}

