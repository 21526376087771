import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import { Popup } from "../components/UI/Popup";
import {
    getAllPromocode,

    addpromocode,
    updatepromocode,
    deletepromocode,
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Validation from "../actions/Validation";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import FooterSection from '../components/UI/FooterSection';
import AdminHeader from '../components/container/layout/admin/Header';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}


class Promocode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            addPopup: false,
            actions: "add",

            promocodeData: {
                id: "",
                promo_code: "",
                percentage: "",
                expires_at: "",

            },

            errors: {
                promo_code: "",
                percentage: "",
                expires_at: "",
            },
            tableSuccess: {
                message: "",
                status: "",
            },
            formSuccess: {
                message: "",
                status: "",
            },
        };
        this.columns = [
            {
                key: "s_no",
                text: "S.No",
                className: "s_no",
                align: "left",
                sortable: true,
            },
            {
                key: "id",
                text: "Id",
                className: "hidden-column",
                TrOnlyClassName: "hidden-column",

                align: "left",
                sortable: true,
            },
            {
                key: "promo_code",
                text: "Promo code",
                className: "promo_code",
                align: "left",
                sortable: true,
            },
            {
                key: "percentage",
                text: "Percentage",
                className: "percentage",
                align: "left",
                sortable: true,
            },

            {
                key: "expires_at",
                text: "Expires at",
                className: "expires_at",
                sortable: true,
                align: "left",
            },

            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: true,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.onDeleteClick(record)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    UpdateColumnChange = (data) => {

        axios.put(updatepromocode, data, CONFIG).then((list) => {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].promo_code = data.promo_code;
            records[recIndex].percentage = data.percentage;
            records[recIndex].expires_at = data.expires_at;
            records[recIndex].id = data.id;

            this.setState({ records, tableSuccess: list.data });
            this.clearMessage();
        });
    };
    Updatepromocode = (e) => {
        e.preventDefault();
        let { promocodeData, errors } = this.state;
        let data = {};
        data.type = "promocodeList";
        data.expires_at = promocodeData.expires_at ? moment(promocodeData.expires_at).format("YYYY-MM-DD") : "";
        data.promo_code = promocodeData.promo_code;
        data.percentage = promocodeData.percentage;
        this.setState({ loading: true });
        errors = Validation(data, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
            // console.log(data); return false;

            let url = this.state.actions === "add" ? addpromocode : updatepromocode;
            if (this.state.actions === "add") {
                axios.post(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });

                        this.setState({ errors: { promo_code: list.data.message } });
                    } else {
                        let addpromocode = {};
                        addpromocode.promo_code = data.promo_code;
                        addpromocode.percentage = data.percentage;
                        addpromocode.expires_at = moment(data.expires_at).format('DD-MM-YYYY');

                        addpromocode.id = list.data.data;

                        let records = this.state.records;
                        addpromocode.s_no = records.length + 1;
                        records.push(addpromocode);
                        this.setState({ records, formSuccess: list.data });

                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            } else {
                data.id = promocodeData.id;
                console.log(data)
                axios.put(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { promocode_id: list.data.message } });
                    } else {
                        let records = this.state.records;

                        var recIndex = findWithAttr(records, "id", data.id);
                        console.log(recIndex);
                        console.log(records);
                        records[recIndex].promo_code = data.promo_code;
                        records[recIndex].percentage = data.percentage;
                        records[recIndex].expires_at = moment(data.expires_at).format('DD-MM-YYYY');
                        records[recIndex].id = data.id;

                        this.setState({ records, formSuccess: list.data });
                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            }
        }
    };

    clearErrors = (errors) => {
        errors.promo_code = "";
        errors.percentage = "";
        errors.expires_at = "";
        return errors;
    };

    cancel = () => {
        let data = this.state.promocodeData;
        data.promo_code = "";
        data.percentage = "";
        data.id = "";
        data.expires_at = "";

        let errors = this.state.errors;

        errors = this.clearErrors(errors);

        this.setState({ promocodeData: data, actions: "add", errors: errors });
    };

    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

    editRecord(record) {
        console.log(record)
        let promocodeData = {};
        promocodeData.promo_code = record.promo_code;
        promocodeData.percentage = record.percentage;
        promocodeData.id = record.id;

        promocodeData.expires_at = moment(
            record.expires_at,
            moment.defaultFormat
        ).toDate();
        console.log(promocodeData)
        let errors = this.state.errors;
        errors = this.clearErrors(errors);

        this.setState({
            promocodeData: promocodeData,
            actions: "edit",
            errors: errors,
        });
    }
    componentDidMount = () => {

        axios
            .post(getAllPromocode, { user_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
                let promocodelist = list.data.data;

                promocodelist.forEach((element, index) => {
                    let promocode = {};
                    promocode.s_no = index + 1;
                    promocode.promo_code = element.promo_code;
                    promocode.expires_at = moment(element.expires_at).format('DD-MM-YYYY');
                    promocode.percentage = element.percentage;
                    promocode.id = element.id;

                    promocodelist[index] = promocode;
                });

                // let clientmedicinehistory = {};
                console.log(promocodelist)
                this.setState({ records: promocodelist });
            })
            .catch((e) => { });


    };
    onDeleteClick(record) {
        this.setState({ addPopup: true, record: record });
    }
    deleteRecord(record) {
        let userId = record.id;
        this.setState({ loading: true });

        axios
            .delete(deletepromocode, { data: { id: userId } }, CONFIG)
            .then((res) => {
                let response = res.data;
                if (response.status === "1") {
                    let records = this.state.records;
                    const deleteIndex = records.map((item) => item.id).indexOf(record.id);
                    records.splice(deleteIndex, 1);
                    this.setState({ records });
                }
                this.setState({ addPopup: false, record: "", loading: false });
                this.setState({ tableSuccess: res.data });
                this.clearMessage();
            })
            .catch((e) => { });
    }

    handleInput = (e) => {
        let data = this.state.promocodeData;
        let name = e.target.name;
        let value = e.target.value;
        const re = /^[0-9\b]+$/;
        if (name === "percentage") {
            if (value === "" || re.test(value)) {
                data[name] = value;
            }
        } else {
            data[name] = value;
        }

        this.setState({ promocodeData: data });
    };
    handleExpiresatChange = (date) => {
        let promocodeData = this.state.promocodeData;
        promocodeData.expires_at = date;
        this.setState({ promocodeData: promocodeData });
    };
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            promocodeData,

            errors,
            formSuccess,
            tableSuccess,
        } = this.state;
        return (

            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>
                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Promocode List </h4>
                                    <div className="row mt-4">

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                                show={this.state.addPopup}
                                                                onHide={PopupClose}
                                                                onConfirm={PopupConfirm}
                                                                title={"Confirmation"}
                                                                message={"Are you sure you want to delete this record?"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                            <div class="card-header"> <div class="card-title">    {this.state.actions === "add" ? "Add" : "Edit"} promocode</div> </div>
                                                <div className="card-body">
                                                    <div className="user-notification">
                                                        <SpanField
                                                            classname={
                                                                0 === formSuccess.status
                                                                    ? "text-danger user-notification"
                                                                    : "text-success user-notification"
                                                            }
                                                            title={formSuccess.message}
                                                        />
                                                    </div>

                                                    <div>
                                                    
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="frllftfull">
                                                                    <div className="form-group frllft mbbre">
                                                                        <label className="ffgrd">Promocode</label>
                                                                    </div>
                                                                    <div className="form-group frlrit">
                                                                        <div className="position-relative">
                                                                            <div className="input-group">
                                                                                <InputField
                                                                                    inputType={"text"}
                                                                                    name={"promo_code"}
                                                                                    controlFunc={this.handleInput}
                                                                                    content={promocodeData.promo_code}
                                                                                    placeholder={""}
                                                                                />
                                                                            </div>
                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.promo_code}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="frllftfull">
                                                                    <div className="form-group frllft mbbre">
                                                                        <label className="ffgrd">Percentage</label>
                                                                    </div>
                                                                    <div className="form-group  frlrit">
                                                                        <div className="position-relative">
                                                                            <div className="input-group">
                                                                                <InputField
                                                                                    inputType={"text"}
                                                                                    name={"percentage"}
                                                                                    controlFunc={this.handleInput}
                                                                                    content={promocodeData.percentage}
                                                                                    placeholder={""}
                                                                                />
                                                                            </div>
                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.percentage}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="frllftfull">
                                                                    <div className="form-group frllft mbbre">
                                                                        <label className="ffgrd">Expires at</label>
                                                                    </div>
                                                                    <div className="form-group frlrit">
                                                                        <div className="position-relative">
                                                                            <div className="input-group">
                                                                                <DatePicker
                                                                                    className="form-control"
                                                                                    value={promocodeData.expires_at}
                                                                                    format="dd-MM-yyyy"
                                                                                    onChange={(date) => this.handleExpiresatChange(date)}
                                                                                    yearPlaceholder='YYYY'
                                                                                    monthPlaceholder='MM'
                                                                                    dayPlaceholder='DD'
                                                                                />
                                                                            </div>
                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.expires_at}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                         
                                                            <div className="col-sm-12">
                                                                <div className="form-group mb-0 text-center float-right mg-top3">
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.Updatepromocode}
                                                                    >
                                                                        {this.state.actions === "add" ? "+ Add" : "Update"}
                                                                    </button>

                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.cancel}
                                                                    >
                                                                        {"Cancel"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                           
                            </section>
                            <FooterSection />
                        </div>
                       
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(Promocode);