import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import FooterSection from "../components/UI/FooterSection";
import { CONFIG, time_limit } from "../config/data";
import { getAllUserSubscriptions, getPriceandDiscountforsubscription, RazorPayPayment,getCredentials, addSubscription,getTimeoutbycategory,getclientsubscriptionplanbyuserid } from "../config/url.json";
import Label from "../components/UI/Label";
import axios from "axios";
import Select from "react-select";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import * as moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";


function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

//const timelimit = ["Daily", "Weekly","Monthly","Quarterly","Yearly"];
//const subscription_type=["Silver","Gold","Platinum"]

class ShowsubscriptionClient extends Component {
  constructor() {
    super();
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    var today = new Date(),

      date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

    this.state = {
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      records: [],
      name: "React",
      showdetails: false,
      showsubscription:
      {
        //  id:"",
        subscription_type: "",
        time_limit: "",
        //  price:"",
        // discount:"",
        //  amount:"",
        category: "",
        // expiresat:"",

      },
      usersubscriptionlist: [],
      getsubscription:
      {
        id: "",
        subscription_type: "",
        time_limit: "",
        price: "",
        discount: "",
        amount: "",
        category: "",
        expiresat: "",
      },
      currentDate: date,
    };
    this.columns = [
      
      {
        key: "subscription_type",
        text: "Subscription Plan",
        className: "subscription_type",
        align: "left",
        sortable: true,
      },
      {
        key: "time_limit",
        text: "Time Limit",
        className: "time_limit",
        align: "left",
        sortable: true,
      },
      {
        key: "expires_at",
        text: "Valid Till",
        className: "expires_at",
        align: "left",
        sortable: true,
      },
      {
        key: "subscription_status",
        text: "Subscription Status",
        className: "subscription_status",
        sortable: true,
        align: "left",
      },
     
      
    ];
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  

  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.showsubscription;
    data[e.name] = selectedOptions;
    //data.subscription_type=selectedOptions.value;
    // console.log(data.subscription_type);
    this.setState({ showsubscription: data, showdetails: false });
  };

  handledropdownchange = (selectedOptions, e) => {


    let data = this.state.showsubscription;
    data[e.name] = selectedOptions;
    this.setState({ showsubscription: data, showdetails: false });
    //let timelimit = e.target.name;
    //data[timelimit] = e.target.checked === true ? 1 : 0;

    // this.setState({ showsubscription: data });
    // console.log(data)
    //      let { showsubscription, errors } = this.state;
    //      data.time_limit=showsubscription.time_limit.label;
    //      data.category = this.props.userdata.user_type;
    //      data.subscription_type =showsubscription.subscription_type.label;
    //    console.log(data);
    //    axios.defaults.headers.common[
    //     "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
    // this.setState({ loading: true }, () => {
    //     axios
    //          .post(getPriceandDiscountforsubscription, data, CONFIG)

    //         .then((res) => {
    //             this.setState({ loading: false });
    //             const result = res.data;
    //             console.log(result);
    //             if (result.status === "1") {
    //                 this.setState({ successMessage: result.message });
    //             } else {
    //                 this.setState({
    //                     errorMessage:
    //                         result.message || "Failed to submit details",
    //                 });
    //             }
    //         })
    //         .catch((e) => {
    //             this.setState({
    //                 loading: false,
    //                 errorMessage: "Failed to submit consultant details",
    //             });
    //         });
    // });

  }
  dopayment = () => {
    console.log(this.state.getsubscription);
    let userdata = this.props.userdata;
    let getsubscription = this.state.getsubscription;
    if (getsubscription.amount === "0") {
      let req_data = {
        'order_id': "",
        'payment_id': "",
        'user_id': userdata.id,
        'user_type': userdata.user_type,
        subscription_type: getsubscription.subscription_type,
        time_limit: getsubscription.time_limit,

        discount: getsubscription.discount,
        amount: getsubscription.price,
        expires_at: getsubscription.expiresat,
        payment_status: 'Completed',
        subscription_status: 'Active'


      }
      this.completepayment(req_data);
    }
    else {
      this.displayRazorpay(userdata)
    }
  }
  completepayment = (req_data) => {
    axios.post(addSubscription, req_data, CONFIG).then((res) => {
      console.log(res);
      if (res.data.status === '1') {
        window.location.href = "/dashboard"
      }
      // setTimeout(
      //   () =>
      //     (res.data.status) ?
      //       window.location.href = '/client_book_appointments' : ""
      //   ,
      //   500
      // );
    }).catch(() => {


    })
  }
  displayRazorpay = (userdata) => {
    // console.log(client_appt_id)
    const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    let data = {};
    let { getsubscription } = this.state;
    // let consulting_fees = this.state.getsubscription.amount ? this.state.getsubscription.amount : "10";
    data.payable_amt = getsubscription.amount ? getsubscription.amount : "10";
    console.log(data);

    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
    let razorpay_key = "";
    axios
.post(getCredentials, {
  "credential_type":"razorpay"
})
.then((res) => {
  console.log(res.data);
  razorpay_key = res.data.data[0].value;

    axios.post(RazorPayPayment, data, data).then((res) => {
      console.log(res);
      let data = res.data;

      //  let vendorData = this.state.vendorData;
      //  let paymentForm = this.state.paymentForm;
      //  console.log(vendorData);
      const options = {
        // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
        key:razorpay_key,
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: 'Billing and Payment',
        description: '',
        image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
        handler: function (response) {
        //  alert(response.razorpay_payment_id)
         // alert(response.razorpay_order_id)
          //alert(response.razorpay_signature)
          let req_data = {
            'order_id': response.razorpay_order_id,
            'payment_id': response.razorpay_payment_id,
            'user_id': userdata.id,
            'user_type': userdata.user_type,
            subscription_type: getsubscription.subscription_type,
            time_limit: getsubscription.time_limit,

            discount: getsubscription.discount,
            amount: getsubscription.price,
            expires_at: getsubscription.expiresat,
            payment_status: 'Completed',
            subscription_status: 'Active'


          }

          axios.post(addSubscription, req_data, CONFIG).then((res) => {
            console.log(res);
            if (res.data.status === '1') {
              window.location.href = "/dashboard"
            }
            // setTimeout(
            //   () =>
            //     (res.data.status) ?
            //       window.location.href = '/client_book_appointments' : ""
            //   ,
            //   500
            // );
          }).catch(() => {


          })

        },

        prefill: {
          name: "",
          email: "",
          contact: ""
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()

    }).catch((e) => { console.log(e) });
  }).catch((e) => { 
    console.log(e)
  });
  }

  calculate = () => {
    let data = this.state.showsubscription;
    let { showsubscription } = this.state;
    data.time_limit = showsubscription.time_limit.label;
    data.category = this.props.userdata.user_type;
    data.subscription_type = showsubscription.subscription_type.label;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios
        .post(getPriceandDiscountforsubscription, data, CONFIG)

        .then((res) => {
          this.setState({ loading: false });
          const result = res.data;
          console.log(result.data.price);
          if (result.status === "1") {
            data.time_limit = { label: data.time_limit, value: data.time_limit }
            data.subscription_type = { label: data.subscription_type, value: data.subscription_type }
            this.setState({ successMessage: result.message, showsubscription: data, showdetails: true });
            let { currentDate } = this.state;
            if (result.data.price === "Free") {
              result.data.amount = "0";
              console.log(result.data.amount);
              this.setState({ getsubscription: result.data });
            } else {
              result.data.amount = result.data.price - ((result.data.discount / 100) * result.data.price);
              console.log(result.data.amount);
              this.setState({ getsubscription: result.data });
            }
            if (result.data.time_limit === "Daily") {
              result.data.expiresat = currentDate;
              console.log(this.state.currentDate);
              this.setState({ getsubscription: result.data });
            }
            else if (result.data.time_limit === "Weekly") {
              function pad2(n) {
                return (n < 10 ? '0' : '') + n;
              }
              var today = new Date();
              today.setDate(today.getDate() + 7);
              today.toLocaleDateString();
              var date = new Date(today.toLocaleDateString());
              var month = pad2(date.getMonth() + 1);//months (0-11)
              var day = pad2(date.getDate());//day (1-31)
              var year = date.getFullYear();

              var formattedDate = day + "-" + month + "-" + year;
              result.data.expiresat = formattedDate;

              //(today.getDate()+7) + '-' + (today.getMonth() + 1) + '-' +today.getFullYear();
              console.log(result.data.expiresat);
              this.setState({ getsubscription: result.data });

            }
            else if (result.data.time_limit === "Monthly") {
              function pad2(n) {
                return (n < 10 ? '0' : '') + n;
              }
              var today = new Date();
              today.setMonth(today.getMonth() + 1);
              today.toLocaleDateString();
              var date = new Date(today.toLocaleDateString());
              var month = pad2(date.getMonth() + 1);//months (0-11)
              var day = pad2(date.getDate());//day (1-31)
              var year = date.getFullYear();

              var formattedDate = day + "-" + month + "-" + year;
              result.data.expiresat = formattedDate;
              //today.getDate() + '-' + ((today.getMonth() + 1)+1) + '-' +today.getFullYear();
              console.log(result.data.expiresat);
              this.setState({ getsubscription: result.data });

            }
            else if (result.data.time_limit === "Quarterly") {
              function pad2(n) {
                return (n < 10 ? '0' : '') + n;
              }
              var today = new Date();
              today.setMonth(today.getMonth() + 4);
              today.toLocaleDateString();
              var date = new Date(today.toLocaleDateString());
              var month = pad2(date.getMonth() + 1);//months (0-11)
              var day = pad2(date.getDate());//day (1-31)
              var year = date.getFullYear();

              var formattedDate = day + "-" + month + "-" + year;
              result.data.expiresat = formattedDate;

              // var month=today.getMonth() + 1;
              // if(month=="12")
              // {
              //   month=0;
              //   result.data.expiresat=today.getDate() + '-' + ((month)+3) + '-' + (today.getFullYear()+1);  
              // }else if(month=="11")
              // {
              //   month=-1;
              //   result.data.expiresat=today.getDate() + '-' + ((month)+3) + '-' + (today.getFullYear()+1);
              // }
              // else if(month=="10")
              // {
              //   month=-2;
              //   result.data.expiresat=today.getDate() + '-' + ((month)+3) + '-' + (today.getFullYear()+1);
              // }
              // else if(month=="1"|| month=="2"|| month=="3"|| month=="4"||month=="5"||month=="6"||month=="7"||month=="8"||month=="9")
              // {
              // result.data.expiresat=today.getDate() + '-' + ((today.getMonth() + 1)+3) + '-' +today.getFullYear();
              // }
              // console.log(today);
              // console.log(month);
              console.log(result.data.expiresat);
              this.setState({ getsubscription: result.data });

            }
            else if (result.data.time_limit === "Yearly") {
              function pad2(n) {
                return (n < 10 ? '0' : '') + n;
              }
              var today = new Date();
              today.setFullYear(today.getFullYear() + 1);
              today.toLocaleDateString();
              var date = new Date(today.toLocaleDateString());
              var month = pad2(date.getMonth() + 1);//months (0-11)
              var day = pad2(date.getDate());//day (1-31)
              var year = date.getFullYear();

              var formattedDate = day + "-" + month + "-" + year;
              result.data.expiresat = formattedDate;

              //today.getDate() + '-' + (today.getMonth() + 1) + '-' +(today.getFullYear()+1);
              console.log(result.data.expiresat);
              this.setState({ getsubscription: result.data });

            }



          } else {
            this.setState({
              errorMessage:
                result.message || "Failed to submit details",
            });
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            errorMessage: "Failed to submit consultant details",
          });
        });
    });

  }

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }

  formSubmit(event) {
    event.preventDefault();
    console.log(this.state.selectedOption)
  }

  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch(() => { });

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getclientsubscriptionplanbyuserid, {user_id:this.props.userdata.id}, CONFIG)
        .then((result) => { 
          let subscriptionlist = result.data.data;
          console.log(subscriptionlist);
      
           let subscriptionArr = [];
           subscriptionlist.forEach((element, index) => {
              let getsubscription = {};
            
            //  getsubscription.s_no = index + 1;
              getsubscription.subscription_type = element.subscription_type;
              getsubscription.expires_at = element.expires_at;
              getsubscription.time_limit=element.time_limit;
              getsubscription.subscription_status= element.subscription_status;
           
              subscriptionArr[index] = getsubscription;
          
          
           
          });
          this.setState({ records: subscriptionArr }); 
        /*   this.setState({
           subscription_plan:data.data.subscription_type,
           expiry_date:moment(data.data.expires_at).format('MMMM Do YYYY'),
           status:data.data.status,
          }); */
       
  //      }
         });


    let { currentDate } = this.state;
    var d = new Date();
    console.log(currentDate);
    // console.log(d);
    console.log(d.toLocaleDateString());
    d.setMonth(d.getMonth() + 3);
    console.log(d.toLocaleDateString());
    // d.setFullYear(d.getFullYear() + 1);
    console.log(d.toLocaleDateString());
    function pad2(n) {
      return (n < 10 ? '0' : '') + n;
    }

    var date = new Date(d.toLocaleDateString());
    var month = pad2(date.getMonth() + 1);//months (0-11)
    var day = pad2(date.getDate());//day (1-31)
    var year = date.getFullYear();

    var formattedDate = day + "-" + month + "-" + year;
    console.log(formattedDate); //28-02-2021
    // console.log(d.toString('YYYY-MM-dd'));
    // console.log(moment().toString());
    //console.log(moment().toLocaleDateString());
    // console.log(moment().format("DD-MM-YYYY"));
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllUserSubscriptions, null, CONFIG)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "1") {
          let usersubscriptionlist = [];
          result.data.data.forEach((element, index) => {
            let obj = {};
            obj = element;
            obj.label = element.subscription_name;
            obj.value = element.id;
            usersubscriptionlist[index] = obj;
          });

          this.setState({
            usersubscriptionlist: usersubscriptionlist,
          });
        } else {
          alert("Subscription list empty");
        }
      })
      .catch((e) => {
        console.log(e);
        console.log("business user getbyid failed");
      });



  }




  render() {



    const {
      showsubscription,
      getsubscription,
      showdetails,
      hospitaltimeout
    } = this.state;
    return (
      <div className="main-content bacfot">
        <div className="mainpage">
          <section className="my-head">
            <div className="container-fluid">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessHeader user_id={this.props.userdata.id} />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserHeader user_id={this.props.userdata.id} />
              ) : (
                <Header user_id={this.props.userdata.id} />
              )}
            </div>
          </section>
          <div className="main-box">
            <div className="sidemenu ">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessUserSideBar />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserSideBar />
              ) : (
                <ClientSideBar />
              )}
            </div>

            <section className="main-body hospital-profilegap">
            <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

              <div className="container-fluid checkout-tabs">
                <div className="row">
                <div className="col-sm-12">
                    <h4 className="font-weight-bold">Current Client Subscription</h4>
                  </div>
                  <div className="col-sm-12">
                  <div className="card">
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      className="table table-bordered table-striped kas  mobile_table"
                    />
                  </div>
                </div>
              </div>
                 </div>
                 </div>
                  <div className="col-sm-12">
                    <h5 className="font-weight-bold">Subscription Details</h5>
                  </div>


                </div>

                <div className="card pt-4">
                  <div className="card-body">
                    <div className="tab-content text-center" id="v-pills-tabContent">
                      <div className="row">
                      <div className="col-sm-2">
                      <div className="form-group text-left">
                          <Label title="Subscription Type" />
                        </div>
                      </div>
                      
                        <div className="col-sm-3">
                          <div className="form-group frlrit">
                            <Select maxMenuHeight={120}
                              className=" p-0"
                              name="subscription_type"
                              value={showsubscription.subscription_type}
                              onChange={this.handleselectChange}
                              options={this.state.usersubscriptionlist}
                            />
                          </div>
                        </div>
                      </div>

                     
                      <div className="row">
                      <div className="col-sm-2">
                      <div className="form-group text-left">
                          <Label title="Time Limit" />
                        </div>
                      </div>
                       
                        <div className="col-sm-3">
                          <div className="form-group frlrit">
                            <Select maxMenuHeight={120}
                              className="w-100 p-0"
                              name="time_limit"
                              value={showsubscription.time_limit}
                              onChange={this.handledropdownchange}
                              options={time_limit}
                            />
                          
                          </div>

                        </div>

                      </div>
                      <div className='row'>
                      <div className="col-sm-7 ml-4">
                      <button id="Pay" type="submit" className="signupbtn abtn btdpml" onClick={this.calculate}>
                        Get Details
                      </button>
                      </div>
                     
                      </div>
                     
                      {showdetails ? (
                        <Fragment >
                          <div className="row mt-2">
                            <div className='col-sm-2'>
                            <div className="text-left">
                              <Label title="Subscription Type" />
                            </div>
                            </div>
                         
                            <div className="col-sm-3">
                              <div className="form-group frlrit">
                                {/* <p className="form-control">
                                   {getsubscription.subscription_type
                                     ? getsubscription.price
                                     : null}
                                 </p> */}
                                <label className="control-label">{
                                  getsubscription.subscription_type
                                }</label>
                                {/* <InputField
                               isMandatory={true}
                               inputType={"text"}
                               name={"price"}
 
                               content={
                                 getsubscription.subscription_type
                               }
                               placeholder={""}
                               max={35}
                             /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          <div className='col-sm-2'>
                            <div className="text-left">
                            <Label title="Time limit" />
                            </div>
                            </div>
                     
                            <div className="col-sm-3">
                              <div className="form-group frlrit">
                                {/* <p className="form-control">
                                   {getsubscription.subscription_type
                                     ? getsubscription.price
                                     : null}
                                 </p> */}
                                <label className="control-label">{
                                  getsubscription.time_limit
                                }</label>
                                {/* <InputField
                               isMandatory={true}
                               inputType={"text"}
                               name={"price"}
 
                               content={
                                 getsubscription.time_limit
                               }
                               placeholder={""}
                               max={35}
                             /> */}
                              </div>
                            </div>
                          </div>



                          <div className="row">
                          <div className='col-sm-2'>
                            <div className="text-left">
                            <Label title="Price" />
                            </div>
                            </div>
                       
                            <div className="col-sm-3">
                              <div className="form-group frlrit">
                                {/* <p className="form-control">
                                   {getsubscription.subscription_type
                                     ? getsubscription.price
                                     : null}
                                 </p> */}
                                <label className="control-label">{
                                  getsubscription.price
                                }</label>
                                {/* <InputField
                               isMandatory={true}
                               inputType={"text"}
                               name={"price"}
 
                               content={
                                 getsubscription.price
                               }
                               placeholder={""}
                               max={35}
                             /> */}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                          <div className='col-sm-2'>
                            <div className="text-left">
                            <Label title="Discount %" />
                            </div>
                            </div>
                       
                            <div className="col-sm-3">
                              <div className="form-group frlrit">
                                {/* <InputField
                               isMandatory={true}
                               inputType={"text"}
                               name={"discount"}
                               controlFunc={this.handleInput}
                               content={
                                 getsubscription.discount
                               }
                               placeholder={""}
                               max={35}
                             /> */}
                                <label className="control-label">{
                                  getsubscription.discount
                                }</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          <div className='col-sm-2'>
                            <div className="text-left">
                            <Label title="Amount" />
                            </div>
                            </div>
                            
                       
                            <div className="col-sm-3">
                              <div className="form-group frlrit">

                                {/* <InputField
                               isMandatory={true}
                               inputType={"text"}
                               name={"amount"}
                               controlFunc={this.handleInput}
                               content={
                                 getsubscription.amount
                               }
                               placeholder={""}
                               max={35}
                             /> */}
                                <label className="control-label">{
                                  getsubscription.amount
                                }</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          <div className='col-sm-2'>
                            <div className="text-left">
                            <Label title="Expires At" />
                            </div>
                            </div>
                       
                            <div className="col-sm-3">
                              <div className="form-group frlrit">
                                {/* <InputField
                               isMandatory={true}
                               inputType={"text"}
                               name={"expiresat"}
                               content={
                                 getsubscription.expiresat
                               }
                               placeholder={""}
                               max={35}
                             /> */}
                                <label className="control-label">{
                                  getsubscription.expiresat
                                }</label>
                              </div>
                            </div>
                          </div>

                          {
                            (getsubscription.amount === "0") ? (<div className="form-group mt2rem mb-0 ">
                              <button onClick={this.dopayment} id="complete" type="button" className="signupbtn abtn btdpml btn-margin">
                                Complete
                              </button>
                            </div>) : (<div className="form-group mt2rem mb-0 ">
                              <button onClick={this.dopayment} id="Pay" type="submit" className="signupbtn abtn btdpml btn-margin">
                                Pay
                              </button>
                            </div>)
                          }

                        </Fragment>
                      ) : ""}

                    </div>
                  </div>
                </div>
                {/* <Footerad /> */}

              </div>
            
            </section>
                <FooterSection />
          </div>

        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,mapDispatchToProps
)(ShowsubscriptionClient);



