import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import BusinessUserSideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
import InputField from "../components/UI/InputField";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Popup } from "../components/UI/Popup";
import { Spinner } from "../components/UI/Spinner";
import {
  getAllMasterDrug,
  getAllDrugs,
  getAllDrugTypes,
  getDrugPlanningDetails,
  addDrug,
  updateDrugPlanningDetails,
  deleteDrug,
  searchDrug,
  getAllDrugMeasures
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";

import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
// Import React Table
import "react-table/react-table.css";
import Dnaspinner from "../components/UI/Dnaspinner";
const onlineOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "In-active" },
];
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class PharDrugList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],

      searchrecords: [],
      drug_action: "Add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      newDatatoAdd: {},
      addPopup: false,
      drugList: {
        s_no: "",
        drug_id: "",
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      drugData: {
        id: "",
        drug_id: "",
        manufacturer: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        active_ingredients: "",
        unit_of_measure: "",
      },
      errors: {
        drug_id: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        unit_of_measure: "",
      },
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
      columns: [
        { title: "Drug Id", field: "drug_id" },
        { title: "Drug Name", field: "drug_name" },
        { title: "Drug Category", field: "drug_category" },
        { title: "Drug Type", field: "drug_type" },
        { title: "Manufacturer", field: "manufacturer" },
        { title: "Active Ingredients", field: "active_ingredients" },
      ],
      data: [
        {
          drug_id: "1",
          drug_name: "da",
          drug_category: "ds",
          drug_type: "sdd",
          manufacturer: "sdsd",
          active_ingredients: "adsfd",
        },
      ],
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      
      },
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
       
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
        
      },

      {
        key: "drug_category",
        text: "Drug Category",
        className: "drug_category",
        sortable: true,
        align: "left",
        
      },

      {
        key: "drug_type",
        text: "Drug Type",
        className: "drug_type",
        sortable: true,
        align: "left",
        
      },

      {
        key: "manufacturer",
        text: "Manufacturer",
        className: "manufacturer",
        sortable: true,
        align: "left",
        
      },

      {
        key: "batch",
        text: "Batch",
        className: "batch",
        sortable: true,
        align: "left",
        cell: (record) => {
            return record.batch_management.batch_number;
        },
    
      },

      {
        key: "serial no",
        text: "Serial No",
        className: "serial no",
        sortable: true,
        align: "left",
          cell: (record) => {
            return record.serial_management.serial_number;
        },
      },

      {
        key: "mrp",
        text: "MRP",
        className: "mrp",
        sortable: true,
        align: "left",
        
      },




      {
        key: "expiry",
        text: "Expiry",
        className: "expiry",
        sortable: true,
        align: "left",
        cell: (record) => {
          return record.expiry_date;
      },
        
      },
      {
        key: "sku",
        text: "SKU",
        className: "sku",
        sortable: true,
        align: "left",
        cell: (record) => {
          return record.stock_keeping_unit;
      },
      },
       {
        key: "ou",
        text: "OU",
        className: "ou",
        sortable: true,
        align: "left",
        cell: (record) => {
          return record.ordering_unit;
      },
        
      },



      {
        key: "storage_bin",
        text: "Storage Bin",
        className: "storage_bin",
        sortable: true,
        align: "left",
     
      },

      {
        key: "price",
        text: "Price",
        className: "price",
        sortable: true,
        align: "left",
        cell: (record) => {
          return record.price_per_unit;
        },
      },
      {
        key: "drug_status",
        text: "Purchaseinfo Status",
        className: "status sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.drug_status,
            value: record.drug_status,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="status_obj"
              value={obj}
              onChange={() => this.handleStatusColumnChange(record)}
              options={onlineOptions}
            />
          );
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                // onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
   
  }

  
  handleStatusColumnChange = (record) => {
    record.drug_status = "Active" === record.drug_status ? "In-active" : "Active";
    this.UpdateColumnChange(record);
  };

  UpdateColumnChange = (record) => {
    console.log(record);
    record.user_id = this.props.userdata.id;
    axios.post(updateDrugPlanningDetails, record, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", record.id);

      records[recIndex].drug_status = record.drug_status;
      // records[recIndex].supplier_id = record.supplier_id;
      // records[recIndex].category = record.category;
      // records[recIndex].type = record.type;
      // records[recIndex].manufacturer = record.manufacturer;
      // records[recIndex].order_unit= record.order_unit;
      // records[recIndex].min_lot=record.min_lot;
      // records[recIndex].max_lot = record.max_lot;
      // records[recIndex].discount = record.discount;
      // records[recIndex].gst = record.gst;
      // records[recIndex].price_per_unit = record.price_per_unit;
      // records[recIndex].status = record.status;
      // records[recIndex].user_id = record.user_id;
      // records[recIndex].id = record.id;

      this.setState({ records, tableSuccess: list.data });
      toast.success("Drug status updated ",{
        className:"toast-success"
      });
      this.clearMessage();
  });
  };
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  componentDidMount = () => {

    

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getDrugPlanningDetails, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let drugList = list.data.data;
        let drugDataArr = [];
        
        drugList.forEach((element, index) => {
          console.log(element);
          let drugData = {};
          drugData = element
          
          drugData.s_no = index + 1;
         
          drugDataArr[index] = drugData;
        });

        this.setState({ records: drugDataArr });
      })
      .catch(() => { });


    
  };
 
  editRecord(record) {
    
    this.props.history.push(
      "/editdrug/" + record.id
    );
  }

  

  

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDrug, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        this.clearMessage();
        
      })
      .catch(() => { });
  }
 

  render() {
    const {
      loading,
      searchTableSuccess,
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;

    return (
      <div id="layout-wrapper">
        {/* <SideBarNew /> */}
     {/*    <Spinner loading={loading} /> */}
     {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>


              <section className="main-body hospital-profilegap">
                <div className="container-fluid">
                  <div className="checkout-tabs">

                  <div className="col-lg-13">
                      <h4 className="font-weight-bold"> Drug List </h4>
                     
                      <div className="row ">
                      <div className="col-sm-13">

                        <div className="card">
                          <div className="card-body ">
                          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                               <a className="float-right btn btn-primary" href="/adddrugs">Add Drug
                              <i class="fa fa-pluse">
                              </i></a>

                                    <div className="table-responsive">
                                      <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                      />
                                      <Popup
                                        show={this.state.deletePopup}
                                        onHide={() =>
                                          this.setState({
                                            deletePopup: false,
                                          })
                                        }
                                        onConfirm={() =>
                                          this.deleteRecord(this.state.record)
                                        }
                                        title={"Confirmation"}
                                        message={
                                          "Are you sure you want to delete this record?"
                                        }
                                      />

                      
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                       

                        
                      </div>
                      {/* <Footerad /> */}
                    </div>
                    <Modal isOpen={modalVar}>
                      <ModalHeader>
                        <label className="text-center wdt100">
                          Confirmation
                        </label>
                      </ModalHeader>
                      <ModalBody>
                        <div className="col-sm-12">
                        Are you sure you want to delete this record? ?
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <Button
                              color="success"
                              onClick={() => this.okDelete.bind(this)}
                            >
                              Ok
                            </Button>
                          </div>
                          <div className="col-sm-3">
                            <Button
                              color="danger"
                              onClick={this.cancelDelete.bind(this)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>

                  </div>
                </div>

         
              </section>
                  <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(PharDrugList);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
