import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import { Spinner } from "../../components/UI/Spinner";
import { getsupplies, RazorPayPayment,getCredentials, getCheckinDetails, consultDoctor,updateclientappointmentstatus,getpaymentstatusfromclientappointment } from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import FooterSection from "../../components/UI/FooterSection";
import BusinessUserSideBar from "../../components/container/SideBar";
import Header from "../../components/container/layout/business/Header";
import { renameKey } from "../../constants/globalLevelFunctions";
import SpanField from "../../components/UI/SpanField";
import * as moment from "moment";
import { consultation_fee } from "../../constants/config/data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var converter = require("number-to-words");

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    business_short_detail: state.SearchReducer.business_short_detail,
  };
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

let record = {
  s_no: "",
  description: "",
  fee: "",
  disc: 0,
  taxable_value: "",
  gst_rate: "",
  gst_amount: "",
  total_amount: "",
};

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

class Addapptprescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage:{message:"",status:""},
      loggedInuserId: props.userdata.id,
      access_token: props.access_token,
      loading: false,
      records: [],
      supplies: [],
      client_id:"",
      payment_status:"Pending",
      totalAmtSec: {
        amt: 0,
        taxVal: 0,
        taxAmt: 0,
        totalAmt: 0,
        totalAmtWords: "",
      },
      defaultEnterSize: 6,
    };

    this.extraButtons = [
      {
        className: "btn btn-primary buttons-pdf",
        title: "Export TEst",
        children: [
          <span>
            <i
              className="glyphicon glyphicon-print fa fa-print"
              aria-hidden="true"
            ></i>
          </span>,
        ],
        onClick: (event) => {
          console.log(event);
        },
      },
      {
        className: "btn btn-primary buttons-pdf",
        title: "Export TEst",
        children: [
          <span>
            <i
              className="glyphicon glyphicon-print fa fa-print"
              aria-hidden="true"
            ></i>
          </span>,
        ],
        onClick: (event) => {
          console.log(event);
        },
        onDoubleClick: (event) => {
          console.log("doubleClick");
        },
      },
    ];

    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: "5%",
      },
      {
        key: "description",
        text: "Description SAC code for service",
        className: "sform-control",
        sortable: false,
        align: "left",
        width: "25%",
        cell: (billing_record) => {
          return this.generatetagforcolumn(billing_record);
        },
      },
      {
        key: "fee",
        text: "Amount (in Rs)",
        className: "cellTextRight",
        align: "left",
        sortable: true,
        width: "20%",
        right: true,
      },

      {
        key: "taxable_value",
        text: "Tax Value",
        className: "cellTextRight",
        sortable: true,
        align: "left",
        width: "15%",
      },
      {
        key: "gst_rate",
        text: "Rate",
        tHeadClassName: "csutomcolspan",
        className: "gst_rate",
        sortable: true,
        align: "left",
        width: "10%",
      },

      {
        key: "gst_amount",
        text: "Amount",
        TrOnlyClassName: "someshright",
        tHeadClassName: "GST",
        className: "cellTextRight",
        sortable: true,
        align: "left",
        width: "10%",
      },
      {
        key: "total_amount",
        text: "Total Amount (in Rs)",
        className: "cellTextRight",
        sortable: true,
        align: "left",
        width: "15%",
      },
    ];
    this.config = {
      page_size: 100,
      show_first: false,
      show_info: false,
      show_last: false,
      show_filter: false,
      show_length_menu: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
      },
    };
  }

  generatetagforcolumn = (billing_record) => {
    if (consultation_fee !== billing_record.description) {
      return (
        <Select maxMenuHeight={120}
          placeholder=""
          isClearable={true}
          openMenuOnClick={false}
          value={billing_record.selectedSearchBoxValue}
          options={this.state.supplies}
          className="customselectbilling"
          onChange={(record) => {
            let records = this.state.records;
            var recIndex = findWithAttr(records, "s_no", billing_record.s_no);
            if (null != record) {
              records[recIndex].selectedSearchBoxValue = record;
              records[recIndex].fee = record.amount;
              records[recIndex].gst_rate = record.tax_percentage + " %";
              records[recIndex].gst_amount =
                (record.tax_percentage / 100) * record.amount;
              records[recIndex].total_amount =
                Number(records[recIndex].fee) +
                Number(records[recIndex].gst_amount);
            } else {
              records[recIndex].selectedSearchBoxValue = "";
              records[recIndex].fee = "";
              records[recIndex].gst_rate = "";
              records[recIndex].gst_amount = "";
              records[recIndex].total_amount = "";
            }

            console.log(recIndex);
            console.log(records.length - 1);
            if (recIndex === records.length - 1) {
              let default_rec = Object.assign({}, record);
              default_rec.s_no = records.length + 1;
              records.push(default_rec);
            }
            this.setState({ records }, () => {
              this.calculateSection();
            });
          }}
        />
      );
    } else {
      return billing_record.description;
    }
  };

  calculateSection = () => {
    let records = this.state.records;
    let totalAmtSec = this.state.totalAmtSec;
    let amt = 0.0;
    let taxAmt = 0.0;
    let taxVal = 0.0;
    let totalAmt = 0.0;

    records.map((data, i) => {
      if (undefined !== data.fee) {
        amt = Number(amt) + Number(data.fee);
      }
      if (undefined !== data.gst_amount) {
        taxAmt = Number(taxAmt) + Number(data.gst_amount);
      }
      if (undefined !== data.taxable_value) {
        taxVal = Number(taxVal) + Number(data.taxable_value);
      }
      if (undefined !== data.total_amount) {
        totalAmt = Number(totalAmt) + Number(data.total_amount);
      }
    });

    totalAmtSec.amt = amt;
    totalAmtSec.taxAmt = taxAmt;
    totalAmtSec.taxVal = taxVal;
    totalAmtSec.totalAmt = totalAmt;
    totalAmtSec.totalAmtWords =converter.toWords(totalAmt)+" rupees only";
    this.setState({ totalAmtSec });
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    let data = {};

    data.business_id = this.props.userdata.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    console.log(this.props.checkin_id)
    const checkinData = { id: this.props.match.params.checkin_id };
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        let completion_data = res.data.data || res.data.date;
        if (res.data.status !== "1" || !data) {
          return;
        }
        console.log(completion_data)
        this.setState({client_id:completion_data.client_id,q_id:completion_data.q_id,
          Reg_id:completion_data.Reg_id,patient_firstname:completion_data.first_name,patient_lastname:completion_data.last_name,
          doctor_firstname:completion_data.doctor_firstname,doctor_lastname:completion_data.doctor_lastname});

        console.log(completion_data);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        let client_appt_id=this.state.q_id;
        console.log(client_appt_id);
      
        axios
          .post(getpaymentstatusfromclientappointment, {client_appt_id:this.state.q_id}, CONFIG)
          .then((res) => {
            let data = res.data;
         
            console.log(data);
          
            console.log(data.data[0].payment_status);
            if(data.status=="1")
            {
              this.setState({
                paymentstatus: data.data[0].payment_status
              
              });
   
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.props.access_token}`;
              data.business_id = this.props.userdata.id;
              axios
                .post(getsupplies, data, CONFIG)
                .then((result) => {
                  let supplies = result.data.data;
                  //to rename json key object
                  supplies.forEach((obj) => renameKey(obj, "id", "value"));
                  supplies.forEach((obj) => renameKey(obj, "supplies_name", "label"));
      
                 console.log(data.data[0].payment_status);
               if(data.data[0].payment_status=="Pending")
               {
                  //set first record as consultation fee by default
                  let consultation_fee_record = {};
                  consultation_fee_record.s_no = 1;
                  consultation_fee_record.description = "Consultation Fee";
                  consultation_fee_record.fee = completion_data.consultation_fee.replace(/[^0-9]/g, '');
                  consultation_fee_record.disc = "";
                  consultation_fee_record.taxable_value = "";
                  consultation_fee_record.gst_rate = "";
                  consultation_fee_record.gst_amount = "";
                  consultation_fee_record.total_amount = completion_data.consultation_fee.replace(/[^0-9]/g, '');
      
                  let records = [];
                  records.push(consultation_fee_record);
      
                 
                  let defaultEnterSize = this.state.defaultEnterSize;
      
                  for (let i = 2; i <= defaultEnterSize; i++) {
                    let default_rec = Object.assign({}, record);
                    default_rec.s_no = i;
                    records.push(default_rec);
                  }
               
      
                  this.setState(
                    {
                      supplies: supplies,
                      loading: false,
                      records: records,
                    },
                    () => {
                      this.calculateSection();
                    }
                  );
                  }
                  else{
                    console.log("hi");
                    let records = [];
                  
                   
                    let defaultEnterSize = this.state.defaultEnterSize;
        
                    for (let i = 1; i <= defaultEnterSize; i++) {
                      let default_rec = Object.assign({}, record);
                      default_rec.s_no = i;
                      records.push(default_rec);
                    }
                 
        
                    this.setState(
                      {
                        supplies: supplies,
                        loading: false,
                        records: records,
                      },
                      () => {
                        this.calculateSection();
                      }
                    );
                  }
                })
                .catch((e) => {
                  this.setState({ loading: false });
                });


           
            }
    
           }).catch((e) => {
            console.log("client short info failed",e);
          });

     


        })
        
        .catch((e) => {
        console.log("client short info failed");
      });
    console.log(this.props);
    // console.log(this.state.headerinfo);
   

  };


  printData = () => {
    var win = window.open('', '_blank');

    var style = "<style>";
    style = style + "table {width: 100%;font: 17px Calibri;}";
    style = style + ".hidecontent {display: none;}";

    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align:left;}";
    style = style + "</style>";

    var tableHtml = document.getElementById("divcontents").innerHTML;

    win.document.write('<html><head>');
    win.document.write('<title>Prescription</title>');
    win.document.write(style);
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write('<h1>Prescription</h1>');
    win.document.write(tableHtml);
    win.document.write('</body></html>');
    win.print();
    win.close();




  }
  cancel=()=>
  {
    window.location.replace("/ApptCheckout");
  }
  displayRazorpay = () => {

  /*   const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    const option = {
      method: 'POST',
      // headers: new Headers({ 'content-type': 'application/json' }),
      mode: 'no-cors'
    };

    let totalAmtSec = this.state.totalAmtSec;
    let data = {};
    data.payable_amt = totalAmtSec.totalAmt;
    let client_id = this.state.client_id;
    let business_id = this.props.userdata.id;
    let records = this.state.records;
    let checkin_id = this.props.match.params.checkin_id;
    let client_appt_id=this.state.q_id; */
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;

   /*  let razorpay_key = "";
    axios
.post(getCredentials, {
  "credential_type":"razorpay"
})
.then((res) => {
  console.log(res.data);
  razorpay_key = res.data.data[0].value;



    axios.post(RazorPayPayment, data, data).then((res) => {
      console.log(res);
      let data = res.data;

      //   let vendorData = this.state.vendorData;
      //   let paymentForm = this.state.paymentForm;

      const options = {
        // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
        key: razorpay_key,
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: 'Billing and Payment',
        description: '',
        image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
        handler: function (response) { */
          // alert(response.razorpay_payment_id)
          // alert(response.razorpay_order_id)
          // alert(response.razorpay_signature)
          
          let totalAmtSec = this.state.totalAmtSec;
          let client_id = this.state.client_id;
          let business_id = this.props.userdata.id;
          let records = this.state.records;
          let checkin_id = this.props.match.params.checkin_id;
          let client_appt_id=this.state.q_id;
          let req_data = {
         //   'order_id': response.razorpay_order_id,
         //   'payment_id': response.razorpay_payment_id,
            //   'vendor_id': vendorData.id,
            //   vendor_subscription: vendorData.vendor_subscription.id,
            //    number_of_months: paymentForm.months.value,
            //    amount: paymentForm.payable_amt,
            amount: totalAmtSec.totalAmt,
            appt_status: 'Completed',
            payment_status: 'Completed',
            business_id: business_id,
            client_id:client_id,
           // otherdata:records,
           // id:checkin_id,
            client_appt_id:client_appt_id,
          }
          console.log(req_data);
          
          axios.post(updateclientappointmentstatus, req_data, CONFIG).then((res) => {
            console.log(res);
           
                      
          }).catch((err) => {
          })
          axios.post(consultDoctor, req_data, CONFIG).then((res) => {
            console.log(res);
            toast.success("The Patient has completed the Billing process successfully.",{
              className:"toast-success"
            });
           /*  this.setState({successMessage:res.data})
            console.log(this.state.successMessage);
            setTimeout(
              () =>
                this.setState({
                  successMessage: { message: "" },
                 
                }),
              5000
            ); */
          //  window.location.href = '/queue_assignment';
            
          }).catch((err) => {
           // this.setState({successMessage:err})

          })


       /*  },

        prefill: {
          name: this.props.userdata.first_name+" "+this.props.userdata.last_name,
          email: this.props.userdata.email,
          contact: this.props.userdata.phone_number
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()

    }).catch((e) => { console.log(e) });
  }).catch((e) => { 
    console.log(e)
  }); */
  }
  actionComplete = () => {
    this.setState({ payment_status: "Completed"});
    this.displayRazorpay();

  }
  clearForm=()=>{
    setTimeout(
      () =>
        this.setState({
          successMessage: { message: "" },
         
        }),
      5000
    );
  }
  render() {
    let {successMessage,Reg_id,patient_name,payment_status,paymentstatus,doctor_firstname,doctor_lastname,patient_firstname,patient_lastname} = this.state;
    return (
      <div id="layout-wrapper" >
        {/* <SideBarNew /> */}
        < Spinner loading={this.state.loading} />
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

            <div className="main-box">
              <div className="pr0">
                <BusinessUserSideBar />
              </div>
              <section className="main-body hospital-profilegap">
                <div className="container-fluid">
                  <div className="checkout-tabs">
                    <div className="row ">
                      <div className="col-sm-12">
                        <h4 className="sform-control"> Billing </h4>
                        <div className="row mt-4 " id="divcontents">
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-body ">
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="row bill_details">
                                          <div>
                                           {/*  <div>
                                              <span>GSTIN</span>
                                            </div> */}

                                            <div>
                                              <span>Payment Status</span>
                                            </div>
                                          </div>

                                          <div className="col">
                                          {/*   <div>
                                              <span>T453469875</span>
                                            </div> */}

                                            <div>
                                              <span>{payment_status}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <table
                                          className="billing_table"
                                          cellPadding="10%"
                                        >
                                          <tbody>
                                          <tr>
                                              <td>Registration. No.</td>
                                              <td>{Reg_id}</td>
                                            </tr>
                                            <tr>
                                              <td>Patient Name</td>
                                              <td>{patient_firstname}{' '}{patient_lastname}</td>
                                            </tr>
                                            <tr>
                                              <td>Doctor Name</td>
                                              <td>{this.props.location.data.doctor_name}{doctor_firstname}{' '}{doctor_lastname}</td>
                                            </tr>
                                            <tr>
                                              <td>Hospital Address</td>
                                              <td>
                                                {this.props.business_short_detail ? this.props.business_short_detail.business_name + ", " + this.props.business_short_detail.address1
                                                  + ", " + this.props.business_short_detail.city
                                                  + ", " + this.props.business_short_detail.state
                                                  + " - " + this.props.business_short_detail.zip_code : ""}
                                              </td>
                                            </tr>
                                          
                                            <tr>
                                              <td>Date</td>

                                              <td>
                                                {moment(new Date()).format(
                                                  "DD MMM YYYY hh:mm A"
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div>
                                    <div className="user-notification">
                          <SpanField
                            classname={
                              "0" === successMessage.status
                                ? "text-danger user-notification"
                                : "text-success user-notification"
                            }
                            title={successMessage.message}
                          />
                                             </div>
                                      <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        extraButtons={this.extraButtons}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />
                                      <table
                                        className="total_bill_section"
                                        cellPadding="10%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td width="30%" colSpan="2">
                                              Total Amount
                                            </td>
                                            <td
                                              width="20%"
                                              className="cellTextRight"
                                            >
                                              {this.state.totalAmtSec.amt}
                                            </td>
                                            <td
                                              width="15%"
                                              className="cellTextRight"
                                            >
                                              {this.state.totalAmtSec.taxVal}
                                            </td>
                                            <td
                                              width="20%"
                                              colSpan="2"
                                              className="cellTextRight"
                                            >
                                              {this.state.totalAmtSec.taxAmt}
                                            </td>
                                            <td
                                              width="15%"
                                              className="cellTextRight"
                                            >
                                              {this.state.totalAmtSec.totalAmt}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td width="30%" colSpan="2">
                                              Total Invoice value (in Figure)
                                            </td>
                                            <td colSpan="4"></td>
                                            <td className="cellTextRight">
                                              {" "}
                                              {
                                                this.state.totalAmtSec.totalAmt
                                              }{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width="30%" colSpan="2">
                                              Total Invoice value (in Words)
                                            </td>
                                            <td colSpan="5">
                                              {" "}
                                              {
                                                this.state.totalAmtSec
                                                  .totalAmtWords
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                    </div>
                                  
                                  
                                    <button
                                      type="button"
                                      style={{ width: 115, float: "right", margin: 20 }}
                                      className="signupbtn abtn btdpml hidecontent"
                                      onClick={this.cancel}
                                    >
                                      Back
                                    </button>
                                    <button
                                      type="button"
                                      style={{ width: 115, float: "right", margin: 20 }}
                                      className="signupbtn abtn btdpml hidecontent"
                                      onClick={this.printData}
                                    >
                                      Print
                                    </button>
                                    <button
                                      type="button"
                                      style={{ width: 115, float: "right", margin: 20 }}
                                      className="signupbtn abtn btdpml hidecontent"
                                      onClick={this.actionComplete}
                                    >
                                      Finish
                                    </button>
                               {/*      <button
                                      type="button"
                                      style={{ width: 115, float: "right", margin: 20 }}
                                      className="signupbtn abtn btdpml hidecontent"
                                      onClick={this.actionComplete}
                                      disabled={this.state.payment_status=="Completed"}
                                    >
                                      Pay
                                    </button> */}
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
              </section>
              <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Addapptprescription);
