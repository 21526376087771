import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/business/Header";
import Spinner from "../components/UI/Spinner";
import InputField from "../components/UI/InputField";
import Select from "react-select";
import axios from "axios";
import { CONFIG } from "../config/data";
import {
  searchDrug,
  getAllMasterDrug,
  getAllDrugTypes,
  getAllDrugCategories,
  getPrescription,
  consultDoctor,
  getTimeoutbycategory
} from "../config/url.json";
import Footerad from "../components/UI/Footerad";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import Dnaspinner from "../components/UI/Dnaspinner";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


class Prescription extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout=this.state;

    this.state = {
      timeout:timeout,
      isTimedOut: false,
      showModal: false,
    
      loading: false,
      consultData: null,
      records: [],
      searchRecords: [],
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        drug_unit: "",
        active_ingredients: "",
        manufacturer: "",
      },
      drugUnits: [
        {
          value: "ea",
          label: "ea",
        },
        {
          value: "mg",
          label: "mg",
        },
      ],
      drugList: [],
      drugTypes: [],
      drugCategories: [],
      successMessage: "",
      errorMessage: "",
    };
    this.columns = [
      {
        key: "medicine_description",
        text: "Medicine Description",
        className: "medicine_description",
        align: "left",
        sortable: true,
      },
      {
        key: "days",
        text: "Days",
        className: "days",
        align: "left",
        sortable: true,
        width: 100,
        cell: (record, i) => {
          return (
            <div class="form-group dayscount">
              <div
                class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                style={{ minWidth: "112px" }}
              >
                <span class="input-group-btn input-group-prepend">
                  <button
                    class="btn btn-primary bootstrap-touchspin-down"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "days", true)}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={record.days}
                  onChange={(e) => this.handleTableInput(e, i)}
                />
                <span class="input-group-btn input-group-append">
                  <button
                    class="btn btn-primary bootstrap-touchspin-up"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "days")}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "before_food",
        text: "Before Food",
        className: "before_food",
        align: "left",
        sortable: true,
        cell: (record, i) => {
          return (
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id={"bfood-" + i}
                name="before_food"
                onChange={this.handleTableCheckboxChange}
                defaultChecked={record.before_food}
              />
              <label class="custom-control-label" for={"bfood-" + i} />
            </div>
          );
        },
      },
      {
        key: "morning",
        text: "Morning",
        className: "morning",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record, i) => {
          return (
            <div class="form-group dayscount">
              <div
                class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                style={{ minWidth: "112px" }}
              >
                <span class="input-group-btn input-group-prepend">
                  <button
                    class="btn btn-primary bootstrap-touchspin-down"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "morning", true)}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={record.morning}
                  onChange={(e) => this.handleTableInput(e, i)}
                />
                <span class="input-group-btn input-group-append">
                  <button
                    class="btn btn-primary bootstrap-touchspin-up"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "morning")}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "noon",
        text: "Noon",
        className: "noon",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record, i) => {
          return (
            <div class="form-group dayscount">
              <div
                class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                style={{ minWidth: "112px" }}
              >
                <span class="input-group-btn input-group-prepend">
                  <button
                    class="btn btn-primary bootstrap-touchspin-down"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "noon", true)}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={record.noon}
                  onChange={(e) => this.handleTableInput(e, i)}
                />
                <span class="input-group-btn input-group-append">
                  <button
                    class="btn btn-primary bootstrap-touchspin-up"
                    type="button"
                    onClick={() => this.incOrDecValue(i, "noon")}
                  >
                    +
                  </button>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "night",
        text: "Night",
        className: "night",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record, i) => {
          return (
            <div class="d-flex">
              <div
                class="form-group dayscount"
                style={{
                  paddingRight: "12px",
                }}
              >
                <div
                  class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                  style={{ minWidth: "112px" }}
                >
                  <span class="input-group-btn input-group-prepend">
                    <button
                      class="btn btn-primary bootstrap-touchspin-down"
                      type="button"
                      onClick={() => this.incOrDecValue(i, "night", true)}
                    >
                      -
                    </button>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={record.night}
                    onChange={(e) => this.handleTableInput(e, i)}
                  />
                  <span class="input-group-btn input-group-append">
                    <button
                      class="btn btn-primary bootstrap-touchspin-up"
                      type="button"
                      onClick={() => this.incOrDecValue(i, "night")}
                    >
                      +
                    </button>
                  </span>
                </div>
              </div>
              <div
                className="text-center"
                style={{
                  padding: "12px 0 12px 12px",
                  borderLeft: "1px solid #ccc",
                  margin: "-12px 0",
                }}
              >
                {record.unit}
              </div>
            </div>
          );
        },
      },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  
  }

  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) {
    console.log("the time is over");
    this.setState({showModal: true})
    }
  }


  incOrDecValue(index, name, isDecrement = false) {
    const data = this.state.records;
    let value = parseInt(data[index][name]) || 0;
    if (!isDecrement) {
      data[index][name] = value + 1;
    } else if (value !== 0) {
      data[index][name] = value - 1;
    }
    // calculate total
    const total =
      (parseInt(data[index].morning) || 0) +
      (parseInt(data[index].noon) || 0) +
      (parseInt(data[index].night) || 0);
    data[index].total = total * parseInt(data[index].days || 0);

    this.setState({
      records: data,
    });
  }

  searchAndShowDrug() {
    const searchData = this.state.searchData;
    let postData = {};
    postData.drug_name = searchData.drug_name.label;
    postData.drug_category = searchData.drug_category.label;
    postData.drug_type = searchData.drug_type;
    postData.manufacturer = searchData.manufacturer;
    postData.active_ingredients = searchData.active_ingredients;
    postData.type = "user";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios
        .post(searchDrug, postData, CONFIG)
        .then((res) => {
          this.setState({ loading: false });
          const result = res.data;
          if (result.countOfRecords === 0) {
            this.setState({ searchRecords: [] });
            return;
          }
          const records = result.data;
          const data = [];
          records.forEach((element) => {
            data.push({
              id: element.id,
              drug_name: element.drug_name,
              drug_category: element.drug_category,
              drug_type: element.drug_type,
              manufacturer: element.manufacturer,
              active_ingredients: element.active_ingredients,
            });
          });

          this.setState({ searchRecords: data });
        })
        .catch((e) => {
          this.setState({ searchRecords: [], loading: false });
        });
    });
  }

  addDrug() {
    const selectedDrugs = this.state.records;
    this.state.searchRecords.map((record) => {
      if (record.isSelected) {
        selectedDrugs.push({
          medicine_description: record.drug_name,
          days: 0,
          before_food: 0,
          morning: 0,
          noon: 0,
          night: 0,
          total: 0,
          unit: "ml",
        });
      }
    });

    this.setState({
      records: selectedDrugs,
    });
  }

  submitPrescription() {
    const postData = this.state.consultData;
    postData.medicine_description = this.state.records;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios
        .post(consultDoctor, postData, CONFIG)
        .then((res) => {
          this.setState({ loading: false });
          const result = res.data;
          if (result.status === "1") {
            this.setState({ successMessage: result.message });
          } else {
            this.setState({
              errorMessage:
                result.message || "Failed to submit prescription details",
            });
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
            errorMessage: "Failed to submit consultant details",
          });
        });
    });
  }

  handleTableCheckboxChange = (e) => {
    let data = this.state.records;
    let id = e.target.id.split("-")[1];
    data[id][e.target.name] = e.target.checked ? 1 : 0;
    this.setState({ records: data });
  };

  handleTableInput = (e, index) => {
    let data = this.state.records;
    let name = e.target.name;
    let value = e.target.value;
    data[index][name] = value;
    this.setState({ records: data });
  };

  handleCheckbox = (e) => {
    let data = this.state.searchRecords;
    let id = e.target.id.split("-")[1];
    let name = e.target.name;
    let value = e.target.checked;
    data[id][name] = value;
    this.setState({ searchRecords: data });
  };

  handleSearchInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ searchData: data });
  };

  handleSearchSelect = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });
  };

  componentDidMount() {

    console.log(this.props.user.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.user.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            timeout: data.data.timeout
            
          });
        })
      .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const postData = { id: 50 };
    axios
      .post(getPrescription, postData, CONFIG)
      .then((res) => {
        let data = res.data.data || res.data.date;
        if (res.data.status !== "1" || !data || !data[0]) {
          return;
        }

        this.setState({
          consultData: data[0],
          records: data[0].medicine_description || [],
        });
      })
      .catch((e) => { });

    // get search select options
    axios
      .post(getAllMasterDrug, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugNameList = drugMasterRespons.data.data;
        let drugList = [];
        masterdrugNameList.forEach((element, index) => {
          drugList.push({
            value: element.id,
            label: element.drug_name,
            manufacturer: "",
            active_incredient: "",
          });
        });

        this.setState({ drugList });
      })
      .catch((e) => { });

    axios
      .post(getAllDrugTypes, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element, index) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch((e) => { });

    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;
        let drugCategories = [];
        list.forEach((element, index) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch((e) => { });
  }

  render() {
    const {
      loading,
      searchData,
      searchRecords,
      drugUnits,
      drugList,
      drugCategories,
      drugTypes,
      timeout
    } = this.state;
    return (
      <div id="layout-wrapper">
      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <section className="main-body hospital-profilegap">
              <div className="container-fluid">
                <div className="checkout-tabs">
                  <div className="row">
                    <div className="col-sm-2 pr0">
                      <SideBar />
                    </div>
                    <div class="col-sm-12 c-page-wrapper">
                    <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />

                      <div class="row">
                        <div class="col-sm-9 pr0">
                          <div class="hmeclr bgligtrn">
                            <h5 class="fnsz hptd">Prescription</h5>
                          </div>
                          <div class="card a-page-3-hight1">
                            <div class="card-body hight-scrol3">
                              <div class="tab-content" id="v-pills-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="table-responsive">
                                        <ReactDatatable
                                          config={this.config}
                                          records={this.state.records}
                                          columns={this.columns}
                                          className="table table-bordered table-striped kas  mobile_table"
                                        />
                                      </div>
                                      <div class="text-right mt-2">
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            this.submitPrescription()
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3 pl0">
                          <div class="card">
                            <div class="hmeclr bgligtrn">
                              <h5 class="fnsz hptd">Medicine Category</h5>
                            </div>
                            <div class="card-body">
                              <div class="tab-content" id="v-pills-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="row">
                                    {drugTypes.map((type, i) => {
                                      return (
                                        <div class="square-switch2 col-sm-6 pr-0">
                                          <input
                                            type="radio"
                                            id={"typechk-" + i}
                                            switch="none"
                                            name="drug_type"
                                            value={type.label}
                                            onChange={this.handleSearchInput}
                                          />
                                          <label
                                            for={"typechk-" + i}
                                            data-on-label={type.label}
                                            data-off-label={type.label}
                                            class="w-100 mr-0"
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card">
                            <div class="hmeclr bgligtrn">
                              <h5 class="fnsz hptd">Search Drug</h5>
                            </div>
                            <div class="card-body">
                              <div class="tab-content" id="v-pills-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="input-group mb-10">
                                        <span class="input-group-addon">
                                          <i class="glyphicon fa fa-search icontextb" />
                                        </span>
                                        <Select maxMenuHeight={120}
                                          isClearable={true}
                                          className="selectcls p-0"
                                          name="drug_name"
                                          isMandatory={true}
                                          value={searchData.drug_name}
                                          onChange={this.handleSearchSelect}
                                          options={drugList}
                                        />
                                      </div>
                                      <div class="input-group mb-10">
                                        <span class="input-group-addon">
                                          <i class="glyphicon fa fa-search icontextb" />
                                        </span>
                                        <Select maxMenuHeight={120}
                                          isClearable={true}
                                          className="selectcls p-0"
                                          name="drug_category"
                                          isMandatory={true}
                                          value={searchData.drug_category}
                                          onChange={this.handleSearchSelect}
                                          options={drugCategories}
                                        />
                                      </div>
                                      <div class="input-group mb-10">
                                        <span class="input-group-addon">
                                          <i class="glyphicon fa fa-search icontextb" />
                                        </span>
                                        <Select maxMenuHeight={120}
                                          isClearable={true}
                                          className="selectcls p-0"
                                          name="drug_unit"
                                          isMandatory={true}
                                          value={searchData.drug_unit}
                                          onChange={this.handleSearchSelect}
                                          options={drugUnits}
                                        />
                                      </div>
                                      <div class="input-group mb-10">
                                        <span class="input-group-addon">
                                          <i class="glyphicon fa fa-search icontextb" />
                                        </span>
                                        <InputField
                                          inputType={"text"}
                                          name={"manufacturer"}
                                          controlFunc={this.handleSearchInput}
                                          content={searchData.manufacturer}
                                          placeholder="Manufacturer"
                                        />
                                      </div>
                                      <div class="input-group mb-10">
                                        <span class="input-group-addon">
                                          <i class="glyphicon fa fa-search icontextb" />
                                        </span>
                                        <InputField
                                          inputType={"text"}
                                          name={"active_ingredients"}
                                          controlFunc={this.handleSearchInput}
                                          content={
                                            searchData.active_ingredients
                                          }
                                          placeholder={"Active Ingredients"}
                                        />
                                      </div>
                                      <div class="text-right">
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            this.searchAndShowDrug()
                                          }
                                        >
                                          Search
                                        </button>
                                      </div>
                                      <div class="card-body acrdnpad">
                                        {searchRecords.map((drug, i) => {
                                          return (
                                            <div class="custom-control custom-checkbox mb-1">
                                              <input
                                                type="checkbox"
                                                name="isSelected"
                                                class="custom-control-input"
                                                id={"drug-" + i}
                                                onChange={this.handleCheckbox}
                                              />
                                              <label
                                                class="custom-control-label"
                                                for={"drug-" + i}
                                              >
                                                {drug.drug_name}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {searchRecords && searchRecords.length ? (
                                        <div class="adcrt">
                                          <button
                                            onClick={() => this.addDrug()}
                                          >
                                            <i
                                              class="fa fa-shopping-cart"
                                              aria-hidden="true"
                                            />
                                            &nbsp;Add to Cart
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Footerad /> */}
                    </div>
                  </div>
                </div>
              </div>


           
            </section>
            <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Prescription);
