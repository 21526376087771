import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { CONFIG } from "../../config/data";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getallfooterad } from '../../config/url.json'
function mapStateToProps(state) {
    return {
        // appdata: state.AppData,
        // userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

class Footerad extends Component {
    constructor(props) {
        super(props);
        this.options = {
            autoplay: true,
            loop: true,
            nav: false,
            items: 1,
            dots: false,
        }
        this.state = {
            adverdisement: [],
        }
    }
    componentDidMount() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getallfooterad, null, CONFIG)
            .then((response) => {
                console.log(response.data)

                if (response.data.status === "1") {
                    // let info = short_details.data.data[0];
                    // info.profile_percentage_style = getProfilePercentage(info.profile_percentage);

                    this.setState({ adverdisement: response.data.data, loading: false });

                }
            })
            .catch((e) => {
                console.log("vendor info failed");
            });
    }
    render() {
        let { adverdisement } = this.state;
        return (
            <div className="row">
                <div className="col-sm-12">


                    {(adverdisement.length ?
                        (
                            <OwlCarousel className="owl-theme" {...this.options} loop margin={10} nav>
                                {adverdisement.map((data, i) => {
                                    return (<div key={i} className="item">
                                        <img src={data.footer_adverdisement} className="advertisment img-fluid" alt={data.vendor_name} />
                                    </div>)
                                })}

                            </OwlCarousel>
                        ) : (<img alt=''
                            src={require("../../assets/images/advertismenttop2.jpg")}
                            className="advertisment"
                        />))}

                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(Footerad);