import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import { Modal, Button } from "react-bootstrap";
import { getDateISOFormat, getPatientsubstring } from "../constants/globalLevelFunctions";
//import FooterSection from "../components/UI/FooterSection";
import axios from "axios";
import { CONFIG } from "../config/data";
import Spinner from "../components/UI/Spinner";
import {
    consultDoctor, getAllBusinessList, getCheckinDetails,getQEntryById, createNotification,
    UpdateQueueForCheckOut,sendemail,sendsms,updateclientappointmentstatus,searchBusiness,getDocByBusiness,getDocByBusinesswithspeciality
} from "../config/url.json";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import SpanField from "../components/UI/SpanField";
import TextArea from "../components/UI/TextArea";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Dnaspinner from '../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}
const followup = ['followup'];
class ReferralDoctor extends Component {
    constructor(props) {
        super(props);
        this.config = {
          page_size: 10,
          length_menu: [10, 20, 50],
          button: {
            excel: false,
            print: false,
          },
        };
        this.columns = [
          {
            key: "s_no",
            text: "S.No",
            className: "s_no",
            align: "left",
            sortable: true,
          },
          // {
          //   key: "first_name",
          //   text: "M/F",
          //   className: "first_name",
          //   align: "left",
          //   sortable: true,
          //   cell: (record) => {
            
          //     return (
          //       <Fragment>
          //       <i
          //      className="fa fa-user-md clrblue"
          //      aria-hidden="true"
          //      ></i>
          //      </Fragment>
          //     )
          //   }
          // },
          {
            key: "expected_wait_time",
            text: "Expected Wait Time",
            className: "expected_wait_time",
            align: "left",
            sortable: true,
          },
          {
            key: "hospital_name",
            text: "Hospital Name",
            className: "hospital_name",
            align: "left",
            sortable: true,
            cell: (record )=>{
              return( 
                <Fragment>
                {
                  record.business_name
                  }
                  -{" "}
                  {
                  record.wait_time
                  }{" "}
                  ,{" "}
                  {
                  record.kilometer
                  }{" "}
                  ,{" "}
                  {record.city}{" "}
                  ,
                  {record.state}
                  </Fragment>
              )
            }
          },
          {
            key: "phone_number",
            text: "Phone Number",
            width: 100,
            className: "phone_number",
            align: "left",
            sortable: true,
            cell: (record) => {
              return(
              <Fragment>
                   <a
               href={"tel:+91"+record.phone_number}
               title={
                record.phone_number
               }
               >
            <i
               className="fa fa-phone-square grnbk"
               aria-hidden="true"
               ></i>
            </a>
              </Fragment>
              )
            },
          },
          {
            key: "directions",
            text: "Directions",
            className: "directions",
            sortable: true,
            align: "left",
            cell: (record) => {
              return (
              <Fragment>
                  <a
               target="_blank"
               rel="noopener noreferrer"
               href={"https://www.google.com/maps/search/?api=1&query="+record.latitude+","+
               record.longitude
               }
               >
            <i
               className="fa fa-compass grnbk"
               aria-hidden="true"
               ></i>
            &nbsp;
            </a>
              </Fragment>
              )
            },
          },
          
    
          {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: (record) => {
              return (
              <Fragment>
                   <button
            onClick={this.showFamilydetail.bind(
            this,
            record
            )}
            className="signupbtn mtlogin"
            // data-toggle="modal"
            // data-target=".bs-example-modal-center"
            >
            Select
            </button>
              </Fragment>
              )
            },
         
          },
        ];
    
        this.state = {
            business_list: [],
            searchData:{business_name:"",speciality:""},
            speciality_error:"",
            searchresults: {
                data: [],
            },
            successMessage: "",
            errorMessage: "",
            selected_doc: { value: "", label: "" },
            doctor_list: [],
            openModal: false,
            selected_doctor_appointment_detail_list: [],
            selected_doc_schedule_type: "",
            checkindetails:{},
            queuein_data:{
                
            }
        }
    }
    componentDidMount() {
       
      
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        console.log(this.props.id)
        const checkinData = { id: this.props.id };

        axios
            .post(getCheckinDetails, checkinData, CONFIG)
            .then((res) => {
                let data = res.data.data || res.data.date;
                if (res.data.status !== "1" || !data) {
                    return;
                }

       
                console.log(res.data.status)
                this.setState({
                    checkindetails:res.data.data,
                    
                });
                console.log(this.state.checkindetails);

               
    
    
            }).catch((e) => { });

            axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.props.access_token}`;
              axios
                .get(getAllBusinessList, null, CONFIG)
                .then((list) => {
                  console.log(list)
                  let business = [];
                  let userlist = list.data.data;
                  userlist.forEach((element, index) => {
                    business.push({ label: element.business_name, value: element.user_id })
                  });
          
                  this.setState({ business_list: business });
                })
                .catch((e) => { console.log(e) });
    }
    clearMessage = () => {
      setTimeout(
        () =>
          this.setState({
            successMessage: "",
            errorMessage: "",
          
          }),
        5000
      );
    };
  
       searchBusiness = () => {
    console.log("param");

    
   
   let params = this.state.searchData;
    console.log(params);
    // if(params // 👈 null and undefined check
    // && Object.keys(params).length === 0 && params.constructor === Object){
    //   console.log(params);
    //   this.setState({speciality_error:"Please choose Speciality to Search",});
    //   return;
    // }
    if(null===params.speciality || "" === params.speciality)    
    {
      console.log("params");
      this.setState({speciality_error:"Select your Specialty field to search.",});
      return;
    } 
    else 
    {
      this.setState({ speciality_error: "", });
    }
  //  let params = this.state.searchData;
    console.log(params);
    let data = {
      
      business_name: params.business_name ? params.business_name.label : "",
      speciality: params.speciality ? params.speciality.value : "",
     
    };
  
    console.log(data);
    this.setState({loading: true});
    axios
      .post(searchBusiness, data, CONFIG)
      .then((searchresults) => {
        console.log("business list");

        if (searchresults.data.status === "1") {
          console.log(searchresults);
          this.setState({ searchresults: searchresults.data });
          let result = this.state.searchresults;
          if (result.data.length) {
            result.data.forEach((element, index) => {

              // console.log(element);
              /*      if (element.user_id !== "") {
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
   
                axios
                  .get(queueBusinessURL + element.user_id)
                  .then((details) => {
                    console.log("got response for waittime");
                    element.wait_time = details.data.waitTime;
                  })
                  .catch((e) => {});
              }*/
              element.s_no = index+1;
              result.data[index] = element;
            });
            console.log(result);
            window.setTimeout(() => {
              this.setState({ searchresults: result });
            }, 500);
          }
        } else {
          this.setState({ errorMessage: searchresults.data.message });
          this.setState({ 
            searchresults:{
              data: [],
            } 
          });
           window.setTimeout(() => {
            this.setState({
              error: "",
              errorMessage:"",
            });
          }, 5000);
        }
        this.setState({loading: false});
      })
      .catch((e) => {
        console.log("business list failed");
      });
  };
    
    handleInput = (e) => {
        let data = this.state.followupData;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;
        console.log(max);
        
        if (
            name === "follow_up_details" ||  name === "consultation_fee" ) 
            {  
              
              
          
            this.validateMaxLength(name, value, max);
          }
                  
           else
           {
            data[name] = value;
          }
          this.setState({ followupData: data });


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        this.setState({ loading: true }, () => {
            axios
                .post(consultDoctor, data, CONFIG)
                .then((res) => {
                    this.setState({ loading: false });
                    const result = res.data;
                    if (result.status === "1") {
                        this.setState({ successMessage: result.message });
                    } else {
                        this.setState({
                            errorMessage:
                                result.message || "Failed to submit prescription details",
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        loading: false,
                        errorMessage: "Failed to submit consultant details",
                    });
                });
        });
    }

    closePopup = (e) => {
        this.setState({
          openModal: false, errors: {}, error: "",successMessage:"",errorMessage:"",
           listofClientForQueue: []
        });
      };
    showFamilydetail = (value, el) => {
        this.setState({ loading: true });
       // console.log(this.state.searchData.speciality.value);
       if(this.state.searchData.speciality==undefined||this.state.searchData.speciality==null||this.state.searchData.speciality=="")
       {
        const data = {
          n_business_id: value.user_id,
          n_currentdate: getDateISOFormat(new Date()),
          
        };
        console.log(data);
        if (value.user_id !== "") {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getDocByBusiness, data, CONFIG)
            .then((result) => {
    
              let resData = result.data.data;
              console.log(result);
              console.log(resData);
              let queuein_data = {};
    
              queuein_data.n_business_id = value.user_id;
              queuein_data.business_name = value.business_name;
              queuein_data.address = value.address1 + " " + value.city;
              queuein_data.expected_wait_time = value.expected_wait_time;
    
              let doctor_appointment_detail_list = [];
              let docList = [];
    
              console.log(resData);
              if (resData) {
                resData.forEach((element, index) => {
                  let doctor_obj = {};
    
                  doctor_obj.doc_name = element.doctor_name;
                  doctor_obj.business_user_id = element.business_user_id;
                  doctor_obj.schedule_day_id = element.schedule_day_id;
                  doctor_obj.schedule_id = element.schedule_id;
                  doctor_obj.schedule_type = element.schedule_type;
                  
                  doctor_obj.appt_id = element.appt_id;
                  doctor_obj.doctor_Appointments = element.doctor_Appointments;
    
                  doctor_appointment_detail_list.push(doctor_obj);
                  docList.push({
                    value: element.business_user_id,
                    label: element.doctor_name,
                    speciality_name: element.speciality_name,
                  });
                });
              }
    
    
              console.log(docList);
    
              this.setState({
                doctor_list: docList,
                queuein_data:queuein_data,
                doctor_appointment_detail_list: doctor_appointment_detail_list,
              });
    
              console.log(this.state.doctor_appointment_detail_list);
    
              //By default select on doctor
              let selected_doc = docList[0];
              let selected_appointment = [];
              let selected_doc_schedule_type = this.setState.selected_doc_schedule_type;
    
              //Based on default doctor selection select corresponding appointment details
              doctor_appointment_detail_list.forEach((element, index) => {
                if (element.business_user_id == docList[0].value) {
                  selected_appointment = element.doctor_Appointments;
                  selected_doc_schedule_type = element.schedule_type;
                }
              });
    
              this.setState({
                selected_doc: selected_doc,
                selected_doctor_appointment_detail_list: selected_appointment,
                selected_doc_schedule_type: selected_doc_schedule_type
              });
              console.log(this.state.selected_doctor_appointment_detail_list);
              console.log(this.state.selected_doc);
    
              this.setState({ openModal: true, loading: false });
            })
            .catch((e) => { });
        }
      }else
      {
        const data = {
          n_business_id: value.user_id,
          n_currentdate: getDateISOFormat(new Date()),
          speciality:this.state.searchData.speciality.value,
                    
        };
        console.log(data);
        if (value.user_id !== "") {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getDocByBusinesswithspeciality, data, CONFIG)
            .then((result) => {
    
              let resData = result.data.data;
              console.log(result);
              console.log(resData);
              let queuein_data = {};
    
              queuein_data.n_business_id = value.user_id;
              queuein_data.business_name = value.business_name;
              queuein_data.address = value.address1 + " " + value.city;
              queuein_data.expected_wait_time = value.expected_wait_time;
    
              let doctor_appointment_detail_list = [];
              let docList = [];
    
              console.log(resData);
              if (resData) {
                resData.forEach((element, index) => {
                  let doctor_obj = {};
    
                  doctor_obj.doc_name = element.doctor_name;
                  doctor_obj.business_user_id = element.business_user_id;
                  doctor_obj.schedule_day_id = element.schedule_day_id;
                  doctor_obj.schedule_id = element.schedule_id;
                  doctor_obj.schedule_type = element.schedule_type;
                  doctor_obj.appt_id = element.appt_id;
                  doctor_obj.doctor_Appointments = element.doctor_Appointments;
    
                  doctor_appointment_detail_list.push(doctor_obj);
                  docList.push({
                    value: element.business_user_id,
                    speciality_name: element.speciality_name,
                    label: element.doctor_name,
                  });
                });
              }
    
    
              console.log(docList);
    
              this.setState({
                doctor_list: docList,
                queuein_data:queuein_data,
                doctor_appointment_detail_list: doctor_appointment_detail_list,
              });
    
              console.log(this.state.doctor_appointment_detail_list);
    
              //By default select on doctor
              let selected_doc = docList[0];
              let selected_appointment = [];
              let selected_doc_schedule_type = this.setState.selected_doc_schedule_type;
    
              //Based on default doctor selection select corresponding appointment details
              doctor_appointment_detail_list.forEach((element, index) => {
                if (element.business_user_id == docList[0].value) {
                  selected_appointment = element.doctor_Appointments;
                  selected_doc_schedule_type = element.schedule_type;
                }
              });
    
              this.setState({
                selected_doc: selected_doc,
                selected_doctor_appointment_detail_list: selected_appointment,
                selected_doc_schedule_type: selected_doc_schedule_type
              });
              console.log(this.state.selected_doctor_appointment_detail_list);
              console.log(this.state.selected_doc);
    
              this.setState({ openModal: true, loading: false });
            })
            .catch((e) => { });
        }
    
      }
    };
    handleselectDocChange = (selectedOptions, e) => {
        let data = this.state.selected_doc;
        data = selectedOptions;
    
        this.setState({ selected_doc: data });
    
        console.log(this.state.doctor_appointment_detail_list);
    
        let appointmentDetails = this.state.doctor_appointment_detail_list;
    
        let selected_appointment = this.state
          .selected_doctor_appointment_detail_list;
    
        let selected_doc_schedule_type = this.state.selected_doc_schedule_type;
    
        appointmentDetails.forEach((element, index) => {
          console.log(element.business_user_id);
          console.log(element.doctor_Appointments);
          if (element.business_user_id == selectedOptions.value) {
            console.log("true");
            selected_appointment = element.doctor_Appointments;
            selected_doc_schedule_type = element.schedule_type;
          } else {
            console.log("false");
          }
        });
        this.setState({
          selected_doctor_appointment_detail_list: selected_appointment,
          selected_doc_schedule_type: selected_doc_schedule_type
        });
      };

      handleselectChange = (selectedOptions, e) => {
        let data = this.state.searchData;
        let errors=this.state.errors;
        // console.log(e.name);
        data[e.name] = selectedOptions;
        
        // if (e.name === "business_name" && null != selectedOptions) {
        //   console.log(selectedOptions);
        //   data.latitude = selectedOptions.latitude;
        //   data.longitude = selectedOptions.longitude;
        // } else {
        //   data.latitude = this.state.latitude;
        //   data.longitude = this.state.longitude;
        // }
        this.setState({ searchData: data,errors,speciality_error:"" });
    
        
      };
      handleQueueInput = (e) => {
        let data = this.state.queuein_data;
        let name = e.target.name;
        let value = e.target.value;
        data[name] = value;
        this.setState({ queuein_data: data });
      }
      createNotification = ()=>{
        let { queuein_data, selected_doc,checkindetails } = this.state;
              console.log(queuein_data); 
              console.log(checkindetails); 
              let message = "<p>Hi "+checkindetails.first_name+" "+checkindetails.last_name+ ",</p>"+"Thank you for visiting "+ selected_doc.label + " referred you for an appointment at " + queuein_data.business_name + " hospital for checkups.<p> Please arrive 10 minutes prior to your appointment time.Thank you.</p><p>Sincerely,</p><p>KlinicQ Team.</p>";
              //return false;
             
              let notification_message="Hi "+checkindetails.first_name+" "+checkindetails.last_name+ ","+" Thank you for visiting "+ selected_doc.label + " referred you for an appointment at " + queuein_data.business_name + " hospital for checkups. Please arrive 10 minutes prior to your appointment time.Thank you.Sincerely,KlinicQ Team.";
             
              let notificationdata = {
                message_from: selected_doc.value,
                message_to: checkindetails.client_id,
                message: notification_message,
                link: "",

              }


              let reqdata = {
                "to" : checkindetails.email,
                "subject" : "Referral Doctor",
                "mailHeader" : "Referral Doctor",
                "message": message
            }
              let notificationdata1 = {
                "message_code": "REFERRAL_DOCTOR",
                "language_code": "en",
                "data": {
                  "doctor_name": selected_doc.label,
                  "business_name": queuein_data.business_name
      
                },
                "receiver": {
                  "phoneNumber": checkindetails.phone_code.concat(checkindetails.phone_number)
                }
              };

             
              this.setState({ loading: true }, () => {
                    axios.post(createNotification, notificationdata).then((res) => {



                      axios
        .post(sendemail, reqdata)
        .then((res) => {
          let resdata = res.data;  
          console.log(res);
        axios
          .post(sendsms, notificationdata1)
          .then((response) => { console.log(response) }).catch((err) => {
            console.log(err);
          })
        /*   toast.success("Referral doctor successfully.", {
            className: "toast-success",
            }); */
            this.setState({successMessage:"The Referral mail has been sent successfully.",loading: false});
            this.clearMessage();
      })
      .catch((e) => { console.log(e) });



                      this.closePopup();
                      // console.log(this.state.queuein_data); return false;
                      // this.props.history.push("/scheduleconfirmation/" + qid);
                    
                      // window.location.replace("/scheduleconfirmation/" + qId);
                    }).catch((e) => {

                    });
                  });

             


    
         
      }
      clear = () => {
        let search = {business_name:"",speciality:""}; 
        let searchresults= {
          data: [],
        };
        this.setState({searchresults:searchresults,searchData:search,speciality_error:"",errorMessage:""});
        
      }
    render() {
        let specialities_array = this.props.appdata
        ? this.props.appdata[0].specialities
        : [];
      let specialities = [];
      specialities_array.forEach((element, index) => {
        specialities.push({ value: element.id, label: element.name });
      });
      specialities_array = specialities;
        const {
            business_list,searchData,searchresults,selected_doc, doctor_list,loading,successMessage,
            errorMessage,
        } = this.state;
        return (

          <div className="col-sm-12 pr0">
       {/*    <Spinner loading={loading} /> */}
       {loading && <Dnaspinner />}
          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
          <div className="card">
          <div className="user-notification">
                                                <SpanField
                                                  classname="text-success mt-2"
                                                  title={successMessage}
                                                />
                                                <SpanField
                                                  classname="text-danger mt-2"
                                                  title={errorMessage}
                                                />
                                              </div>
             <div className="card-body">
                <div className="tab-content  pd-15" id="v-pills-tabContent">
                   <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                      <div className="row">
                         <div className="col-sm-4">
                            <div className="input-group">
                               {/* <span className="input-group-addon">
                               <i className="glyphicon fa fa-search icontextb"></i>
                               </span> */}
                               <Select maxMenuHeight={120}
                                  className="w-100 p-0"
                                  name="business_name"
                                  value={searchData.business_name}
                                  placeholder="Select Hospital"
                                  onChange={
                                  this.handleselectChange
                                  }
                                  options={business_list}
                                  isClearable={true}
                                  />
                            </div>
                         </div>
                         <div className="col-sm-4">
                            <div className="input-group">
                               <Select maxMenuHeight={120}
                                  isClearable={true}
                                  className="w-100 p-0"
                                  name="speciality"
                                  placeholder="Select Speciality"
                                  // isMulti
                                  value={searchData.speciality}
                                  onChange={this.handleselectChange}
                                  options={specialities_array}                                                                                           
                                  />
                               <SpanField
                                  classname="text-danger"
                                  title={this.state.speciality_error}
                                  />
                            </div>
                         </div>
                         <div className="col-sm-4">
                            <div className="form-group mb-0">
                               <button
                                  onClick={this.searchBusiness}
                                  className="signupbtn mt-0 abtn"
                                  >
                               Search
                               </button>
                               <button
                                  onClick={this.clear}
                                  className="signupbtn mt-0 abtn appttable"
                                  >
                               Cancel
                               </button>
                            </div>
                         </div>
                      </div>
                      {searchresults.data.length ? (
                      <div className="m-t-30 referralDoctorTable">
                          <ReactDatatable
                                        config={this.config}
                                        records={searchresults.data}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />
                        
                      </div>
                      ) : (
                      ""
                      )}
                   </div>
                </div>
             </div>
             {/*  
             <FooterSection />
             */}
          </div>
          <Modal show={this.state.openModal} onHide={this.closePopup}>
             <Modal.Header closeButton className="model-hptd">
                <Modal.Title className="h5 model-text-color">
                   Queue-In
                </Modal.Title>
             </Modal.Header>
             <Modal.Body className="pb-0">
                {/* <label>Who is this visit for?</label> */}
                <div>
                   {" "}
                   <span style={{ color: "#f46a6a" }}>
                   {this.state.error}
                   </span>
                </div>
                {/* 
                <div className="w-100 text-center">
                   <SpanField
                   classname="text-danger"
                   title={errors.n_client_id || errors.n_business_id || errors.n_business_user_id ||
                   errors.n_schedule_day_id || errors.message||errors.other_comments}
                   />
                </div>
                */}
                <div className="frllftfull">
                   <div className="form-group frllft m8">
                      <label className="ffgrd">Select Doctor</label>
                   </div>
                   <div className="form-group frlrit">
                      <div className="position-relative">
                         <div className="input-group">
                            <div className="input-group-prepend">
                               <span className="input-group-text inpico">
                               <i className="fas fa-search"></i>
                               </span>
                            </div>
                            <Select maxMenuHeight={120}
                               className="form-control p-0"
                               name="selected_doc"
                               value={selected_doc}
                               onChange={this.handleselectDocChange}
                               options={doctor_list}
                               />
                         </div>
                         <label className="mrgtp">
                         {selected_doc ? selected_doc.speciality_name:""}
                         </label>
                      </div>
                   </div>
                </div>
                
             </Modal.Body>
             <Modal.Footer>
                <div className="text-center w-100 m-0">
                   <Button
                      className=" signupbtn abtn pdrs"
                      onClick={this.createNotification.bind(this)}
                      >
                   Send
                   </Button> &nbsp;&nbsp;
                   <Button
                      onClick={this.closePopup}
                      className=" bg-red signupbtn abtn pdrs"
                      >
                   Cancel
                   </Button>
                </div>
             </Modal.Footer>
             
          </Modal>
          <div className="">
             <div className="text-center mt-2 float-left"><button className=" signupbtn  pad-6 margin-10 " onClick={this.props.onPreviousClick}  >Previous</button></div>
             <div className="text-center mt-2 mr-2 float-right"><button onClick={this.props.onNextClick} className=" signupbtn  pad-6 margin-10" >Next</button></div>
          </div>
       </div>


        );
    }
}

export default withRouter(connect(
    mapStateToProps,
)(ReferralDoctor)
);