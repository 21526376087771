import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { CONFIG } from "../../../../config/data";
import Select from "react-select";
import axios from "axios";
import {
  getClientQueueData,
  getReferralDetails,
  getupcomingappointment,
  getTimeoutbycategory,
  GetHistoryVitals,
  getNewsFeeds

} from "../../../../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../modal/IdleModal'
import moment from "moment";
// import { TimetoMin } from "../../../../constants/globalLevelFunctions";
import Moment from "moment";
import Spinner from "../../../../components/UI/Spinner";
import Dnaspinner from "../../../UI/Dnaspinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
// import Upload from "../../../Upload";

// import { getDistance } from 'geolib';

// function convertTZ(date, tzString) {
//   // date = date.toString();
//   return new Date(date).toLocaleString("en-US", { timeZone: tzString });
// }
const marks = [
  {
    value: 6.5,
    label: "UnderWeight",

  },
  {
    value: 19.5,
    label: "Normal",
  },
  {
    value: 33,
    label: "Overweight",
  },
  {
    value: 45,
    label: "Obseity",
  },
];

const google = window.google;
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
};
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout=this.state;
    this.state = {
      allnewsFeeds:[],
      showToast:false,
      timeout:timeout,
      isTimedOut: false,
      showModal: false,
      vitalDetails: [],
      prescription_count: 0,
      queue_data: [],
      businessData: {},
      distance: "",
      duration: "",
      selected_queue: {},
      referral_details: {},
      upcomingAppointment: [],
      upcomingfamilyAppointment: [],
      loading:false
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
   
  }
  
  handleClose() {
    this.setState({showModal: false})
  }
 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
  }
 
  handleselectChange = (selected_queue, e) => {
    console.log(selected_queue);
    // let data = this.state.queue_data;
    // console.log(e.name);
    // data[e.name] = selected_queue;
    this.setState({ selected_queue });
  }
  componentDidMount = () => {
    axios.defaults.headers.common[
      "Authorization"
  ] = `Bearer ${this.props.access_token}`;
  axios
      .post(getNewsFeeds, null)
      .then((response) => {
          console.log(response.data)
          let newsfeedslist = response.data.data;

          // newsfeedslist.forEach((element, index) => {
          //     let newsfeed = {};
          //     newsfeed.s_no = index + 1;
          //     newsfeed.short_description = element.short_description;
          //     newsfeed.link = element.link;
          //     newsfeed.content = element.content;
              
          //     newsfeed.id = element.id;

          //     newsfeedslist[index] = newsfeed;
          // });

          this.setState({ allnewsFeeds: newsfeedslist })
          // console.log(this.state.newsFeeds)
      }).catch(() => {
          console.log("get queue failed");
      });


    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            timeout: data.data.timeout
            
          });
        })
      .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let url = getReferralDetails;
    let data = { user_id: this.props.userdata.id };
    axios
      .post(url, data, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status !== "0")
          this.setState({
            referral_details: data.data,
          });
        else {
          this.setState({
            referral_details: {},
          });
        }
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;


    let reqdata = { client_id: this.props.userdata.id };
    axios
      .post(getupcomingappointment, reqdata, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status !== "0") {
          console.log(data.famiy_data)
          this.setState({
            upcomingAppointment: data.data,
            upcomingfamilyAppointment: data.famiy_data,
          });
        }

        else {
          this.setState({
            upcomingAppointment: [],
            upcomingfamilyAppointment: []
          });
        }
      })
      .catch((e) => { });

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      const postData1 = { client_id: this.props.userdata.id };
      axios
        .post(GetHistoryVitals, postData1, CONFIG)
        .then((res) => {
          let data = res.data.consultantHistory || res.data.consultantHistory;
          console.log(data);
          console.log(res.data);
          console.log(data.with_in_30[0].height);
          console.log(data.with_in_30[0].weight);
          console.log(data.with_in_30[0].bp);
          console.log(data.with_in_30[0].pulse);
          console.log(data.with_in_30[0].temperature);
          console.log(data.with_in_30[0].bmi);

          // if (res.data.status !== "1" || !data || !data[0]) {
          //   return;
          // }
          if (res.data.status === "1") {
            this.setState({
              with_in_week: data.with_in_week,
              with_in_30: data.with_in_30,
              with_in_3_months: data.with_in_3_months,
              with_in_6_months: data.with_in_6_months,
              with_in_year: data.with_in_year,
              with_in_2_years: data.with_in_2_years,
              Historyheight: data.with_in_week.length ? data.with_in_week[0].height
               : data.with_in_30.length ? data.with_in_30[0].height
               : data.with_in_3_months.length ? data.with_in_3_months[0].height
               : data.with_in_6_months.length ? data.with_in_6_months[0].height
               : data.with_in_year.length ? data.with_in_year[0].height
               : data.with_in_2_years.length ? data.with_in_2_years[0].height
                : [],
                Historyweight: data.with_in_week.length ? data.with_in_week[0].weight
               : data.with_in_30.length ? data.with_in_30[0].weight
               : data.with_in_3_months.length ? data.with_in_3_months[0].weight
               : data.with_in_6_months.length ? data.with_in_6_months[0].weight
               : data.with_in_year.length ? data.with_in_year[0].weight
               : data.with_in_2_years.length ? data.with_in_2_years[0].weight
                : [],

                Historytemperature: data.with_in_week.length ? data.with_in_week[0].temperature
                : data.with_in_30.length ? data.with_in_30[0].temperature
                : data.with_in_3_months.length ? data.with_in_3_months[0].temperature
                : data.with_in_6_months.length ? data.with_in_6_months[0].temperature
                : data.with_in_year.length ? data.with_in_year[0].temperature
                : data.with_in_2_years.length ? data.with_in_2_years[0].temperature
                 : [],
                 Historybmi: data.with_in_week.length ? data.with_in_week[0].bmi
               : data.with_in_30.length ? data.with_in_30[0].bmi
               : data.with_in_3_months.length ? data.with_in_3_months[0].bmi
               : data.with_in_6_months.length ? data.with_in_6_months[0].bmi
               : data.with_in_year.length ? data.with_in_year[0].bmi
               : data.with_in_2_years.length ? data.with_in_2_years[0].bmi
                : [],
                Historybp: data.with_in_week.length ? data.with_in_week[0].bp
               : data.with_in_30.length ? data.with_in_30[0].bp
               : data.with_in_3_months.length ? data.with_in_3_months[0].bp
               : data.with_in_6_months.length ? data.with_in_6_months[0].bp
               : data.with_in_year.length ? data.with_in_year[0].bp
               : data.with_in_2_years.length ? data.with_in_2_years[0].bp
                : [],
                Historypulse: data.with_in_week.length ? data.with_in_week[0].pulse
               : data.with_in_30.length ? data.with_in_30[0].pulse
               : data.with_in_3_months.length ? data.with_in_3_months[0].pulse
               : data.with_in_6_months.length ? data.with_in_6_months[0].pulse
               : data.with_in_year.length ? data.with_in_year[0].pulse
               : data.with_in_2_years.length ? data.with_in_2_years[0].pulse
                : [],
            });
          } else {
            this.setState({ error: res.data.message });
          }
        })
        .catch((e) => { });
  

    console.log(this.state.referral_details)
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
    // const checkinData = { client_id: this.props.userdata.id };
    // axios
    //   .post(getVitalDetail, checkinData, CONFIG)
    //   .then((res) => {
    //     let data = res.data;
    //     console.log(data);
    //     if (data.status !== "0")
    //       this.setState({
    //         vitalDetails: data.vital_detail,
    //         prescription_count: data.prescriptions,
    //       });
    //     else {
    //       this.setState({
    //         vitalDetails: [],
    //         prescription_count: data.prescriptions,
    //       });
    //     }
    //   })
    //   .catch((e) => { });
this.setState({loading:true});
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getClientQueueData, { client_id: this.props.userdata.id }, CONFIG)
      .then((result) => {
        if (result.data.status === "1") {
          let queue_data = [], selected_queue = {};
          if (result.data.data.length) {
            result.data.data.forEach((element, i) => {
              queue_data[i] = element
              if (element.status === 'Cancelled') {
                console.log(element.status);
                // queue_data[i].preference = '';
                // queue_data[i].expected_time = '';
                // queue_data[i].waitTime = '';
                // queue_data[i].preference = '';

              } else {

                queue_data[i].label = element.first_name + ' ' + element.last_name + " (" + element.appt_start_time + "-" + element.appt_end_time + ")";
                queue_data[i].value = element.first_name + ' ' + element.last_name + " (" + element.appt_start_time + "-" + element.appt_end_time + ")";

                console.log(element.latitude);



                console.log('businessresult')
                navigator.geolocation.getCurrentPosition(
                  (position) => {

                    var from = new google.maps.LatLng(
                      position.coords.latitude,
                      position.coords.longitude
                    );

                    var dest = new google.maps.LatLng(
                      element.latitude,
                      element.longitude
                    );

                    var service = new google.maps.DistanceMatrixService();
                    service.getDistanceMatrix(
                      {
                        origins: [from],
                        destinations: [dest],
                        travelMode: "DRIVING",
                      },
                      (response, status) => {
                        var distance = "";
                        var duration = "";
                        if (status !== "OK") {
                          alert("Error was: " + status);
                        } else {
                          console.log(response);


                          var results = response.rows[0].elements;
                          console.log(results);


                          var d = results[0];

                          distance = d.distance ? d.distance.text : "";
                          duration = d.duration ? d.duration.text : "";


                        }
                        console.log(distance);
                        console.log(duration);
                        queue_data[i].distance = distance;
                        queue_data[i].duration = duration;

                      }
                    );
                    this.setState({loading:false});

                  },
                  () => {
                    queue_data[i].distance = "";
                        queue_data[i].duration = "";
                        this.setState({loading:false});
                  }
                );
                console.log(queue_data[i])
                
              }

            });
            selected_queue = queue_data.length ? queue_data[0] : { label: "Select Queue", value: "", };
            this.setState({loading:false});
          }
          console.log(queue_data);
          console.log(selected_queue)

          this.setState({ queue_data: queue_data, selected_queue });
          var link = document.getElementById('queue_dropdown');

          link.click();

          this.setState({loading:false});
        } else {
          this.setState({ queue_data: [], businessData: {} });
        }
      })
      .catch((e) => {
        console.log(e.data);
        console.log("business user getbyid failed");
      });
      this.setState({loading:false});
  };
  gotofeeds = (data) => {
    this.props.history.push({
      pathname: "/feeds/"+data.id,
    });
  }
  gotoappointment = (data) => {
    console.log(data)

    console.log(this.props)
    this.props.history.push({
      pathname: "/client_book_appointments",
      state: {
        business_id: data.business_id,
        business_name: data.business_name,
        business_user_id: data.business_user_id,
        start_date: moment(data.appt_time).format('YYYY-MM-DD'),
        doc_name: data.doc_name,
        client_details: {
          client_id: data.client_id, name: data.name
        },
      }
    });
  }
  handleInput = (e) => {
    let selected_queue = this.state.selected_queue;
    selected_queue[e.target.name] = e.target.value

    selected_queue.bmi = ((selected_queue.weight /
      selected_queue.height /
      selected_queue.height) *
      10000).toFixed(1);
    this.setState({ selected_queue: selected_queue });
  }
  joinroom = (data)=>{
    console.log(data)
    this.props.history.push({
      pathname: "/join-room/"+data.client_appt_id+"/"+data.doctor_reg_id,
      state: {
        business_id: data.business_id,
        business_name: data.business_name,
        business_user_id: data.business_user_id,
        doc_name: data.doc_name,
        patient_name : data.patient_first_name+" "+data.patient_last_name,
        client_details: {
          client_id: data.client_id, name: data.name
        },
      }
    });
  }
  render() {
    const { prescription_count, queue_data, selected_queue, referral_details,timeout,
     Historyheight,Historyweight,Historytemperature,Historybmi,Historybp,Historypulse,allnewsFeeds,loading
    } = this.state;
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;
    return (
      <div className="checkout-tabs">
       {/*  <Spinner loading={loading} /> */}
       {loading && <Dnaspinner />}
      <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />
   
        <div>
          <div className="container-fluid">
            <h4 className="font-weight-bold">Dashboard</h4>
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

            <div className="row mt-2 clearfix">
              <div className="col-lg-6">

                <h5 className="hedtxt2  mt-3"> Appointment Details</h5>
              </div>
              <div className="col-lg-6 ">
                {queue_data.length ? (<Select maxMenuHeight={120}
                  className="form-control mt-2 queuedropdown css-2b097c-container w-50 fst-fs"
                  name="selected_queue"
                  // value={selected_queue.first_name}
                  inputId="queue_dropdown"
                  onChange={
                    this.handleselectChange
                  }
                  value={this.state.selected_queue}

                  options={queue_data}
                // isClearable={true}
                />) : ""}

              </div>
            </div>

            <div className="row queue-box ">
              <div className="col-lg-12 " >
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card ht115">
                      <div className="card-body d-flex ">


                        <div className="">
                          <p className="font-weight-bold">Token Number</p>
                          <h5 className="font-weight-bolder mt-3">{selected_queue.preference ? selected_queue.preference : "--"}</h5>
                        </div>

                        <div className="iconbox rounded-circle ml-auto" >
                          <div className="icon-title">
                            <i className="fa fa-male font-22 mr-1 " aria-hidden="true"></i>
                            <i className="fa fa-male font-22 " aria-hidden="true"></i>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card ht115">
                      <div className="card-body d-flex ">
                        <div className="" >

                          <p className="font-weight-bold">Appointment Time</p>
                          <h5 className="font-weight-bolder mt-3"> {selected_queue.expected_time ? Moment(selected_queue.expected_time).format("hh:mm A") : "--"}</h5>
                        </div>
                        <div className="iconbox rounded-circle ml-auto">
                            <div className="icon-title">
                              <i className="fa fa-male font-22 mr-1 " aria-hidden="true"></i>
                              <i className="fa fa-clock-o font-22 " aria-hidden="true"></i>
                            </div>
                          </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card ht115">
                      <div className="card-body d-flex ">
                        <div className="">
                          <p className="font-weight-bold">Wait Time </p>

                          <h5 className="font-weight-bolder mt-3"> {moment.duration(selected_queue.waitTime).asMinutes() > 0
                            ? moment.duration(selected_queue.waitTime).asMinutes().toFixed() + ' min'
                            // Moment(selected_queue.waitTime, 'HH:mm:ss:').diff(moment().startOf('day'), 'minutes') + ' min'
                            : "0 min"}</h5>
                        </div>

                        <div className="iconbox rounded-circle ml-auto">
                            <i className="fa fa fa-clock-o font-22 icon-title" aria-hidden="true"></i>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card ht115">
                      <div className="card-body d-flex">
                        <div className="">
                          <p className="font-weight-bold">Distance to Clinic</p>

                          <h5 className="font-weight-bolder mt-3">  {selected_queue.distance ? selected_queue.distance : '--'}</h5>
                        </div>
                        <div className="iconbox rounded-circle ml-auto">
                            <i className="fa fa-map-o font-22 icon-title" aria-hidden="true"></i>
                          </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card ht115" >
                      <div className="card-body d-flex">
                        <div className=" " >

                          <p className="font-weight-bold">Duration to Clinic </p>

                          <h5 className="font-weight-bolder mt-3">   {selected_queue.duration ? selected_queue.duration : '--'}</h5>
                        </div>

                        <div className="iconbox rounded-circle ml-auto">
                            <i className="fa fa-map-signs font-22 icon-title" aria-hidden="true"></i>
                          </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card ht115" >
                      <div className="card-body d-flex">
                        <div className="col-12  " >

                          <p className="font-weight-bold">
                            Status:{selected_queue.status}</p>

                          {selected_queue.q_id ? (<a className="btn btn-primary btn-block mb-10 btn-sz
                 " href={"/scheduleconfirmation/" + this.state.selected_queue.q_id}> Appointment Details</a>) : ""}

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div className="row ">

              <div className="col-sm-4 ">

                <div className="card m560">
                <div className="card-header"> <div className="card-title">Vital Details</div> </div>
                  <div className="card-body  pb-0 greyShade">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab">
                      

                        <div className="row">
                          <div className="col-sm-12">

                            <div className="row ">
                              <div className="cpag w-42 m-16 ">
                                <div className="card vitals">
                                  <div className="card-body pt ">
                                    <div className="text-left  s-118">
                                      <h5 className="border-0 small titleFlex justify-content-between font-weight-bolder">Height

                                        <img className="" alt=""
                                          src={require("../../../../assets/images/vitaldetails1.png")}

                                        />

                                      </h5>

                                    </div>

                                    <div className="input-group input-group-sm mb-0">
                                      <input type="text" disabled className="dis-inlnflx form-control" 
                                      name="height" value={(selected_queue.height==null)?Historyheight:selected_queue.height} onChange={this.handleInput} />
                                      <div className="input-group-append">
                                        <span className="input-group-text">cm </span>


                                      </div></div>
                                  </div>
                                </div>
                              </div>
                              <div className="cpag w-42 ">
                                <div className="card vitals
                                 ">
                                  <div className="card-body pt">
                                    <div className="text-left  s-118">
                                      <h5 className="border-0 titleFlex justify-content-between small font-weight-bolder">Weight
                                        <img className="" alt=""
                                          src={require("../../../../assets/images/vitaldetails2.png")} />

                                      </h5>
                                    </div>
                                    <div className="input-group input-group-sm mb-0">
                                      <input type="text" className="dis-inlnflx form-control" disabled name="weight" value={(selected_queue.weight==null)?Historyweight:selected_queue.weight} onChange={this.handleInput} />
                                      <div className="input-group-append">
                                        <span className="input-group-text">kg </span>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="cpag w-42 m-16">
                                <div className="card vitals">
                                  <div className="card-body pt ">
                                    <div className="text-left  s-118 pr-0 ">
                                      <h5 className="border-0 titleFlex justify-content-between small font-weight-bolder">Temp
                                        <img className="" alt=""
                                          src={require("../../../../assets/images/vitaldetails3.png")} />

                                      </h5>
                                    </div>
                                    <div className="input-group input-group-sm mb-0">
                                      <input type="text" className="dis-inlnflx form-control" disabled name="temperature" value={(selected_queue.temperature==null)?Historytemperature:selected_queue.temperature} />
                                      <div className="input-group-append">
                                        <span className="input-group-text"> °F </span>

                                      </div></div>
                                  </div>
                                </div>
                              </div>
                              <div className="cpag w-42">
                                <div className="card vitals">
                                  <div className="card-body pt">
                                    <div className="text-left  s-118">
                                      <h5 className="border-0 titleFlex justify-content-between small font-weight-bolder">BMI
                                        <img className="" alt=""
                                          src={require("../../../../assets/images/bmi_icon.png")} />

                                      </h5>
                                    </div>
                                    <div className="input-group input-group-sm mb-0">
                                      <input className="form-control" name="bmi" disabled type="text" value={(selected_queue.bmi==null)?Historybmi:selected_queue.bmi} />
                                      <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">BMI</span>

                                      </div></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row ">
                              <div className="cpag w-42 m-16 ">
                                <div className="card vitals">
                                  <div className="card-body pt">
                                    <div className="text-left  s-118">
                                      <h5 className="border-0 titleFlex justify-content-between small font-weight-bolder">BP
                                        <img className="" alt=""
                                          src={require("../../../../assets/images/vitaldetails4.png")} />
                                      </h5>
                                    </div>
                                    <div className="input-group input-group-sm mb-0">
                                      <input type="text" className="dis-inlnflx form-control" disabled name="bp" value={(selected_queue.bp==null)?Historybp:selected_queue.bp} />
                                      <div className="input-group-append">
                                        <span className="input-group-text">mmHg </span>
                                      </div></div>
                                  </div>
                                </div>
                              </div>
                              <div className="cpag w-42">
                                <div className="card vitals">
                                  <div className="card-body pt">
                                    <div className="text-left  s-118">
                                      <h5 className="border-0 titleFlex justify-content-between small font-weight-bolder">Pulse
                                        <img className="" alt=""
                                          src={require("../../../../assets/images/vitaldetails5.png")}
                                        />

                                      </h5>
                                    </div>
                                    <div className="input-group input-group-sm mb-0">
                                      <input type="text" className="dis-inlnflx form-control"disabled name="pulse" value={(selected_queue.pulse==null)?Historypulse:selected_queue.pulse} />
                                      <div className="input-group-append">
                                        <span className="input-group-text">per min</span>
                                      </div></div>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card m560">
                <div className="card-header"> <div className="card-title">BMI Result </div> </div>
                  <div className="card-body greyShade">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab"
                      >
                      
                        <div className='row rw16 justify-content-around' >

                          <div className="underWeight ml6 ">

                            <div className="dropback">
                              <div className="bg-wit hg0">
                                <img
                                  src={require("../../../../assets/images/unwt.JPG")}
                                  alt="unwt"
                                  className="bmistrengthprofile"
                                />
                                <span className="bmispan  underWeightbg">{"<" + 18.5}</span>

                              </div>

                            </div>
                          </div>
                          <div className="normall bm16" >
                            <div className="dropback">
                              <div className="bg-wit hg0">
                                <img
                                  src={require("../../../../assets/images/nrm.JPG")}
                                  alt="nrm"
                                  className="bmistrengthprofile"
                                />
                                <span className="bmispan normallbg" >{"18.5 - 24.9"}</span>

                              </div>
                            </div>
                          </div>
                          <div className="overWeight bm16">
                            <div className="dropback">
                              <div className="bg-wit hg0">
                                <img
                                  src={require("../../../../assets/images/ov.JPG")}
                                  alt="ov"
                                  className="bmistrengthprofile"
                                />
                                <span className="bmispan overWeightbg">{"25 - 34.9"}</span>

                              </div>
                            </div>
                          </div>
                          <div className="obese bm16">
                            <div className="dropback">
                              <div className="bg-wit hg0">
                                <img
                                  src={require("../../../../assets/images/obs.JPG")}
                                  alt="obs"
                                  className="bmistrengthprofile"
                                />
                                <span className="bmispan obesebg">{">" + 45}</span>

                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  <Typography id="discrete-slider-always" gutterBottom>
                          BMI = {selected_queue.bmi ? selected_queue.bmi : 0} Kg/m2
                           BMI = {46.8} Kg/m2 
                        </Typography>*/}
                        <Slider
                          defaultValue={selected_queue.bmi ? ((selected_queue.weight/selected_queue.height/selected_queue.height)* 10000).toFixed(1) : ((Historyweight/Historyheight/Historyheight)*10000).toFixed(1)}
                         /*  value={selected_queue.bmi ? ((selected_queue.weight /
                            selected_queue.height /
                            selected_queue.height) *
                            10000).toFixed(1) : 'NA'} */
                            value={selected_queue.bmi ? ((selected_queue.weight/selected_queue.height/selected_queue.height) *10000).toFixed(1) : ((Historyweight/Historyheight/Historyheight)*10000).toFixed(1)}
                          // getAriaValueText={"Normal"}
                          aria-labelledby="discrete-slider-always"
                          step={12}
                          max={50}
                          min={0.00000001}
                          marks={marks}
                          valueLabelDisplay="on"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card m560">
                <div className="card-header"> <div className="card-title">Health Tips </div> </div>
                  <div className="card-body greyShade">
                    <div className="newsFeddScroll font-family">
                    {allnewsFeeds.length ? allnewsFeeds.map(
                      (feeds, index) => {
                        return (
                        <div className="newsFedd">
                          <div className="block-ellipsis">

                            <p className="font-family">{feeds.short_description}</p>
                            <p dangerouslySetInnerHTML={{
    __html: feeds.content
  }}></p>




                          </div>
                          <a href="javascript:void(0)" onClick={() => this.gotofeeds(feeds)} className="signupbtn abtn button">Know More</a>
                    

                        </div>
                        )
                      }
                      ) : ""
                    }
                    
                    </div>
                      
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                
                <div className="card m560">
                <div className="card-header"> <div className="card-title">Notifications </div> </div>
                  <div className="card-body padright0 greyShade">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab"
                      >
                        <div className="upcomingAppointment">
                          <p className="mb-2">Upcoming appointment</p>
                          <div className="upcomingFlexs messagesc5 ">
                            
                            {
                            this.state.upcomingAppointment.length  ? this.state.upcomingAppointment.map(
                                 
                                  (data, i) => {
                                    return (data.payment_status=='Completed' || (data.payment_status=="Pending" && data.schedule_type === "inperson_direct"))?
                                    (<div key={i} className="upcomingFlex">
                                      <span><i className="fa fa-calendar-check-o"></i></span>
                                      <div className="d-flex justify-content-between w-100">
                                      <a className="patient-details" href="javascript:void(0)" onClick={() => this.gotoappointment(data)}>
                                        {data.patient_first_name+" "+data.patient_last_name}
                                        <span className="timeDate">{moment(data.appt_time).format('MMM-DD hh:mm a')}</span>
                                        </a>
                                        {( data.schedule_type=='online'?
                                      <a className="btn btn-primary btn-block
                                      "
                                    href="javascript:void(0)" onClick={()=>this.joinroom(data)}  
                                    >Meeting Invite</a>:"")}
                                      </div>
                                    </div>):("")
                                  }) 
                                  : ""
                                  }
                                {this.state.upcomingfamilyAppointment.length ? this.state.upcomingfamilyAppointment.map(
                                  (data, i) => {
                                    return (data.payment_status=='Completed' || (data.payment_status=="Pending" && data.schedule_type === "inperson_direct"))?(<div key={i} className="upcomingFlex">
                                    <span><i className="fa fa-calendar-check-o"></i></span>
                                    <div className="d-flex justify-content-between w-100">
                                    <a className="patient-details" href="javascript:void(0)" onClick={() => this.gotoappointment(data)}>
                                      {data.patient_first_name+" "+data.patient_last_name}
                                      <span className="timeDate">{moment(data.appt_time).format('MMM-DD hh:mm a')}</span>
                                      </a>
                                      {( data.schedule_type=='online'?
                                    <a className="btn btn-primary btn-block
                                    "
                                  href="javascript:void(0)" onClick={()=>this.joinroom(data)}  
                                  >Meeting Invite</a>:"")}
                                    </div>
                                  </div>):("")
                                  }) : ""}
                          </div>
                        </div>
              


                        <table className="messagesc5">
                          <tbody>
                            <tr>
                              <td>
                                <i className="fa fa-envelope"></i>
                              </td>
                              <td>
                                <a href="#">
                                  <h4 className="card-title"> <strong>{prescription_count} </strong> New prescription </h4>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>


                      </div>
                    </div>

                  </div>
                </div>


              </div>

              <div className="col-sm-4">

                <div className="card m560">
                <div className="card-header"> <div className="card-title">Guide </div> </div>
                  <div className="card-body greyShade">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab"
                      >
                      



                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-sm-4">

                <div className="card m560">
                <div className="card-header"> <div className="card-title">Offers </div> </div>
                  <div className="card-body greyShade" >
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab"
                      >
                      
                        <div className="row messagesc5">
                          <div className="col-6 text-center tablegraph">
                            <a href="/referrals">
                              <button className="pointwit">
                                Credit points  <span>{referral_details.total_credits}</span>
                              </button>
                            </a>
                          </div>
                          <div className="col-6 text-center tablegraph">
                            <a href="/referrals">

                              <button className="pointwit">
                                Number of Referrals <span>{referral_details.numberofreferrals}</span>
                              </button>
                            </a>
                          </div>
                          <div className="col-6 text-center tablegraph mt-25">
                            <a href="/referrals">

                              <button className="pointwit">
                                Coupons<span>{referral_details.total_coupons}</span>
                              </button>
                            </a>
                          </div>
                          <div className="col-6 text-center tablegraph mt-25">
                            <a href="/referrals">

                              <button className="pointwit">
                                Rewards <span>{referral_details.numberofrewards}</span>
                              </button>
                            </a>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-sm-12">
            <img
              src={require("../../../../assets/images/advertismenttop2.jpg")}
              className="advertisment" alt=""
            />
          </div>
        </div> */}
      </div >
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Dashboard));