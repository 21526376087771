import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import StarRatings from "react-star-ratings";
import { Spinner } from "../../components/UI/Spinner";
import BusinessUserHeader from "../../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import TextArea from "../../components/UI/TextArea";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import CheckboxOrRadioGroup from "../../components/UI/CheckboxOrRadioGroup";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import TimePicker from "react-bootstrap-time-picker";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Label from "../../components/UI/Label";
import {
  stringToLabelValue,
  calculate_age,
} from "../../constants/globalLevelFunctions";
import {
  nationalityArray,
  blood_group,
  gender,
  language,
} from "../../config/data";
import {
  getBusinessUserById,
  getReviwes,
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import FooterSection from "../../components/UI/FooterSection";
import Dnaspinner from "../../components/UI/Dnaspinner";

let nationality = [];
nationalityArray.forEach((element) => {
  nationality.push({ value: element, label: element });
});
const working_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    access_token: state.AuthReducer.access_token,
    userdata: state.AuthReducer.user,
  };
}


class ViewBusinessUserProfile extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      isDisable: true,
      reviewinfo: {
        friendliness: "",
        value_of_money: "",
        less_wait_time: "",
        best_treatment: "",
        easy_explanation: "",
        good_facility: "",
      },
      commentsinfo: {
        commenter: "",
        avatar: "",
        overall_rating: "",
        comment_text: "",
        reviewed_on: "",
      },
      commentsArr: [],
      businessUserData: {
        first_name: "",
        last_name: "",
        age: "",
        profile_pic: "",
        date_of_birth: "",
        address1: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        gender: "",
        blood_group: "",
        nationality: "",
        language: "",
        medical_licence_id: "",
        expiry_date: "",
        user_type: "business_user",
        working_hours: [
          {
            morning_shift_start: "",
            morning_shift_end: "",
            evening_shift_start: "",
            evening_shift_end: "",
          },
        ],
        working_days: [],
        speciality: [],
        educational_qualification: [],
        certification: [],
        experience: [],
        medical_licence_details: [],
      },
      errors: {
        first_name: "",
        last_name: "",
        profile_pic: "",
        date_of_birth: "",
        address1: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        gender: "",
        blood_group: "",
        nationality: "",
        medical_licence_id: "",
        expiry_date: "",
      },
    };
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    window.setTimeout(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(
          getBusinessUserById,
          { business_user_id: this.props.user_id },
          CONFIG
        )
        .then((userData) => {
          console.log("userData");
          console.log(userData);
          if (userData.data.status == 1) {
            let details = userData.data.data;
            console.log(details);
            let businessUserData = this.state.businessUserData;
            businessUserData.first_name = details.first_name;
            businessUserData.last_name = details.last_name;
            businessUserData.age = details.age;
            let sex = {};
            sex.label = details.gender;
            sex.value = details.gender;
            businessUserData.gender = sex;
            let bg = {};
            bg.label = details.blood_group;
            bg.value = details.blood_group;
            businessUserData.blood_group = bg;
            let nationality = {};
            nationality.label = details.nationality;
            nationality.value = details.nationality;
            businessUserData.nationality = nationality;
            businessUserData.language = details.language;
            businessUserData.address1 = details.address1;
            businessUserData.city = details.city;
            businessUserData.state = details.state;
            businessUserData.country = details.country;
            businessUserData.zip_code = details.zip_code;
            businessUserData.phone_number = details.phone_number;
            businessUserData.date_of_birth = details.date_of_birth;
            businessUserData.email = details.email;
            businessUserData.qualification = details.qualification;
            businessUserData.expiry_date = details.expiry_date;
            businessUserData.licence_id = details.licence_id;
            businessUserData.user_id = details.user_id;
            businessUserData.business_user_type = details.business_user_type;
            businessUserData.business_user_type_id =
              details.business_user_type_id;
            businessUserData.id = details.id;
             businessUserData.age = calculate_age(details.date_of_birth);
            businessUserData.profile_summary = details.profile_summary;

            /* Working Hours secton */
            if (
              "null" === details.working_hours ||
              null == details.working_hours
            ) {
              let working_hours = {
                morning_shift_start: "",
                morning_shift_end: "",
                evening_shift_start: "",
                evening_shift_end: "",
                hours_24: false,
              };
              businessUserData.working_hours = working_hours;
            } else {
              businessUserData.working_hours = (
                details.working_hours
              );
            }

            /* Working Days section */
            if (null == details.working_days) {
              details.working_days = [];
            } else {
              businessUserData.working_days = (details.working_days);
              if (7 === businessUserData.working_days.length) {
                businessUserData.working_all_days = true;
              } else {
                businessUserData.working_all_days = false;
              }
            }

            let speciality = [];
            let specialities_array = this.props.appdata
              ? this.props.appdata[0].specialities
              : [];
            if (details.speciality) {
              specialities_array.forEach((element) => {
                console.log(details.speciality.indexOf(element.id));
              /*   if (details.speciality.indexOf(element.id) > -1) {
                  speciality.push({
                    value: element.id,
                    label: element.name,
                  });
                } */
                if (details.speciality.includes(element.id) === true && details.speciality===element.id.toString()) {
                  speciality.push({
                    value: element.id,
                    label: element.name,
                  });
                }
              });
            }
            businessUserData.speciality = speciality;
            businessUserData.language = stringToLabelValue(
              businessUserData.language
            );

            businessUserData.educational_qualification = (
              details.educational_qualification
            );
            businessUserData.certification = (
              details.certification
            );
            businessUserData.experience = (details.experience);
            businessUserData.medical_licence_details = (
              details.medical_licence_details
            );
console.log(businessUserData);
            this.setState({ businessUserData });
            this.setState({ loading: false });
          }
        })
        .catch((e) => {
          console.log("business user getbyid failed",e);
        });
    }, 1000);
    /** Reviews */
    let data = {};
    data.user_id = this.props.user_id;
    data.user_type = "doctor";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getReviwes, data, CONFIG)
      .then((reviewResult) => {
        let reviewResultData = reviewResult.data;
        let commentsData = reviewResult.data.data.comments;
        let commentsArr = this.state.commentsArr;

        let reviewinfo = this.state.reviewinfo;
        reviewinfo.friendliness = reviewResultData.data.friendlyness;
        reviewinfo.value_of_money = reviewResultData.data.value_of_money;
        reviewinfo.less_wait_time = reviewResultData.data.less_wait_time;
        reviewinfo.best_treatment = reviewResultData.data.best_treatment;
        reviewinfo.easy_explanation = reviewResultData.data.easy_explanation;
        reviewinfo.good_facility = reviewResultData.data.good_facility;
        this.setState({ reviewinfo });

        commentsData.forEach((element, index) => {
          let commentinfo = {};
          let commentsArr = this.state.commentsArr;
          commentinfo.commenter = element.ReviewBy;
          commentinfo.avatar = element.avatar;
          commentinfo.overall_rating = element.over_all_review;
          commentinfo.comment_text = element.comments;
          commentinfo.reviewed_on = element.ReviewOn;
          commentsArr[index] = commentinfo;
        });
        this.setState({ commentsArr });
      })
      .catch(() => { });
  };
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.businessUserData;
    data[e.name] = selectedOptions;
    this.setState({ businessUserData: data });
  };
  handleWorkhoursChange(field, value) {
    let data = this.state.businessUserData;
    let working_hours_data =  data.working_hours;
    console.log(working_hours_data);
    if ("24Hours" === field && (typeof working_hours_data != "string")) {
      working_hours_data.hours_24 = !working_hours_data.hours_24;
      working_hours_data.morning_shift_start = '00:00';
      working_hours_data.morning_shift_end = '11:59';
      working_hours_data.evening_shift_start = '12:00';
      working_hours_data.evening_shift_end = '23:59';


    } else {
      working_hours_data.hours_24 = false;
      working_hours_data[`${field}`] = value.target.value;
    }

    data.working_hours = working_hours_data;

    this.setState({ businessUserData: data });
  }
  handleback()
  {

    document.location.href = "/businessuser-profile";
  }
  render() {

    let specializations_array = this.props.appdata
      ? this.props.appdata[0].specializations
      : [];
    let specializations = [];
    specializations_array.forEach((element) => {
      specializations.push({ value: element.id, label: element.name });
    });
    specializations_array = specializations;
    
    const {
      reviewinfo,
      commentsArr,
      businessUserData,
      errors,
      loading
    } = this.state;
    const phone_number = businessUserData.phone_number;

    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;


    return (
      <div className="main-content bacfot">
     {/*  <Spinner loading={this.state.loading} /> */}
     {loading && <Dnaspinner />}
      <div className="mainpage">
         <section className="my-head">
            <div className="container-fluid">
               {/* 
               <ProfileHeader headerdetails={headerinfo}/>
               */}
               <BusinessUserHeader user_id={this.props.user_id} />
            </div>
         </section>
         <div className="main-box">
         <div className="pr0 sidemenu">
            <BusinessUserSideBar />
         </div>
         <section className="main-body hospital-profilegap">
            <div className="container-fluid a-page-1-hight-fixed">
               <div className="checkout-tabs">
                  <div className="row ">
                     <div className="col-sm-12 pl0">
                      <div className="row">
                        
                      </div>
                        <div className="row">
                           <div
                           className={
                           this.state.expandmemDisabled
                           ? "col-sm-12 pr0"
                           : "col-sm-6 pr0"
                           }
                           >
                           {/* <div className="hmeclr bgligtrn">
                            
                          
                              <ul className="nav nav-pills manag" role="tablist">
                                 <li className="nav-item waves-effect waves-light">
                                    <a
                                    className={
                                    this.state.expandmemDisabled
                                    ? "nav-link btnview auto-width"
                                    : "nav-link active btnview auto-width"
                                    }
                                    
                                    >
                                    <span className="d-none d-sm-block">
                                    {" "}
                                    Personal &amp; Speciality{" "}
                                    </span>
                                    </a>
                                 </li>
                                 
                              </ul>
                           </div> */}
                           <div
                           className={
                           this.state.pageAction === "Edit"
                           ? "card non-Editable"
                           : "card a-page-1-hight-fixed"
                           }
                           >
                              <div class="card-header"> 
                              <div class="card-title">
                              Personal Information
                                </div> 
                              
                                </div>
                                
                           <div className="card-body">
                              <div
                                 className="tab-content"
                                 id="v-pills-tabContent"
                                 >
                                 <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                    >
                                    <div
                                    className="row"
                                    //   style={{
                                    //     display: this.state.perandspeDisabled
                                    //       ? "block"
                                    //       : "none",
                                    //   }}
                                    >
                                    <div className="col-sm-12">
                                       <div className="form-group frllftfull">
                                          <TextArea
                                             rows={5}
                                             resize={true}
                                             content={
                                             businessUserData.profile_summary
                                             }
                                             name={"profile_summary"}
                                             controlFunc={this.handleInputChange}
                                             placeholder={""}
                                             isvisible={this.state.isDisable}
                                             maxLength={200}
                                             />
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>First Name</label>
                                          </div>
                                          <div className="form-group frlrit">
                                             <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"first_name"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.first_name}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                             <SpanField
                                                classname="text-danger"
                                                title={errors.first_name}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Last Name</label>
                                          </div>
                                          <div className="form-group frlrit">
                                             <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"last_name"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.last_name}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                             <SpanField
                                                classname="text-danger"
                                                title={errors.last_name}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Age</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"age"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.age}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                                
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                             <label>Sex</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <Select maxMenuHeight={120}
                                                className="form-control p-0"
                                                name="gender"
                                                value={businessUserData.gender}
                                                onChange={this.handleselectChange}
                                                options={gender}
                                                isDisabled={true}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Blood group</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <Select maxMenuHeight={120}
                                                className="form-control p-0"
                                                name="blood_group"
                                                value={businessUserData.blood_group}
                                                onChange={this.handleselectChange}
                                                options={blood_group}
                                                isDisabled={true}
                                                />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                             <label>Nationality</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <Select maxMenuHeight={120}
                                                className="form-control p-0"
                                                name="nationality"
                                                value={businessUserData.nationality}
                                                onChange={this.handleselectChange}
                                                options={nationality}
                                                isDisabled={true}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Language</label>
                                          </div>
                                          <div className="form-group frlrit">
                                             <Select maxMenuHeight={120}
                                                className="form-control p-0 "
                                                isMulti
                                                name="language"
                                                value={businessUserData.language}
                                                onChange={this.handleselectChange}
                                                options={language}
                                                isvisible={this.state.isDisable}
                                                isDisabled={true}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Address</label>
                                          </div>
                                          <div className="form-group frlrit">
                                             <InputField
                                                isMandatory={true}
                                                id="address1"
                                                inputType={"text"}
                                                name={"address1"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.address1}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>City</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <InputField
                                                inputType={"text"}
                                                name={"city"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.city}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                             <label>State</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <RegionDropdown
                                             name="state"
                                             defaultOptionLabel="Select State"
                                             country={businessUserData.country}
                                             value={businessUserData.state || ""}
                                             className="form-control"
                                             onChange={(val) =>
                                             this.selectState(val)
                                             }
                                             placeholder="State"
                                             disabled={true}
                                             />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Country</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <CountryDropdown
                                             placeholder="Country"
                                             name="country"
                                             value={businessUserData.country || ""}
                                             className="form-control"
                                             onChange={(val) =>
                                             this.selectCountry(val)
                                             }
                                             disabled={true}
                                             />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                             <label>Zip</label>
                                          </div>
                                          <div className="form-group frlrclfgf">
                                             <InputField
                                                inputType={"text"}
                                                name={"zip_code"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.zip_code}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                             {" "}
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Phone</label>
                                          </div>
                                          <div className=" form-control frlrit">
                                             <div className="row">
                                                <PhoneInput
                                                international
                                                defaultCountry="IN"
                                                placeholder="Enter phone number"
                                                value={phone_number}
                                                onChange={
                                                this.handlePhoneInputChange
                                                }
                                                error={
                                                phone_number
                                                ? isValidPhoneNumber(
                                                phone_number
                                                )
                                                ? undefined
                                                : "Invalid phone number"
                                                : "Phone number required"
                                                }
                                                isvisible={this.state.isDisable}
                                                disabled={true}
                                                isDisabled={true}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Working days</label>
                                          </div>
                                          <div className=" frlrit">
                                             <div className=" mt-lg-0">
                                                <CheckboxOrRadioGroup
                                                   setName={"working_days"}
                                                   type={"checkbox"}
                                                   controlFunc={this.handleWorkingDays}
                                                   options={working_days}
                                                   name={"working_days"}
                                                   selectedOptions={
                                                   businessUserData.working_days
                                                   }
                                                   disabled={true}
                                                   />
                                             </div>
                                             <div className="square-switch">
                                                <input
                                                   type="checkbox"
                                                   id="allDaysId"
                                                   switch="none"
                                                   onChange={this.handleWorkingDays}
                                                   checked={
                                                   businessUserData.working_all_days
                                                   }
                                                   />
                                                <label
                                                   htmlFor="allDaysId"
                                                   data-on-label="All days"
                                                   data-off-label="All days"
                                                   className="dcte22 wid-115"
                                                   ></label>
                                             </div>
                                          </div>
                                       </div>

                                      {/*  <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Morning Working hours</label>
                                          </div>
                                          <div className="form-group frlrclfgf pdls">
                                             <TimePicker
                                             disabled={
                                             businessUserData.working_hours
                                             .hours_24
                                             }
                                             //   onChange={this.handleWorkhoursChange.bind(
                                             //     this,
                                             //     "morning_shift_start"
                                             //   )}
                                             //step={60}
                                             start="0"
                                             end="24"
                                             value={
                                             businessUserData.working_hours
                                             .morning_shift_start
                                             ? businessUserData.working_hours
                                             .morning_shift_start
                                             : ""
                                             }
                                             placeholder="From"
                                             />
                                          </div>
                                          <div className="form-group frlrclfgf pdrs">
                                             <TimePicker
                                             disabled={
                                             businessUserData.working_hours
                                             .hours_24
                                             }
                                             //   onChange={this.handleWorkhoursChange.bind(
                                             //     this,
                                             //     "morning_shift_end"
                                             //   )}
                                             //step={60}
                                             start="0"
                                             end="24"
                                             value={
                                             businessUserData.working_hours
                                             .morning_shift_end
                                             }
                                             placeholder="To"
                                             />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                             <div className="square-switch">
                                                <input
                                                type="checkbox"
                                                id="square-switch33"
                                                switch="none"
                                                // onChange={this.handleWorkhoursChange.bind(
                                                //   this,
                                                //   "24Hours"
                                                // )}
                                                checked={
                                                businessUserData.working_hours
                                                .hours_24
                                                }
                                                disabled={true}
                                                />
                                                <label
                                                   htmlFor="square-switch33"
                                                   data-on-label="24 hours"
                                                   data-off-label="24 hours"
                                                   className="dcte22 wid-115 mt-0"
                                                   ></label>
                                             </div>
                                          </div>
                                       </div>

                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Evening Working hours</label>
                                          </div>
                                          <div className="form-group frlrclfgf pdls">
                                             <TimePicker
                                             disabled={
                                             businessUserData.working_hours
                                             .hours_24
                                             }
                                             //   onChange={this.handleWorkhoursChange.bind(
                                             //     this,
                                             //     "evening_shift_start"
                                             //   )}
                                             // step={60}
                                             start="0"
                                             end="24"
                                             value={
                                             businessUserData.working_hours
                                             .evening_shift_start
                                             }
                                             placeholder="From"
                                             />
                                          </div>
                                          <div className="form-group frlrclfgf pdrs">
                                             <TimePicker
                                             disabled={
                                             businessUserData.working_hours
                                             .hours_24
                                             }
                                             //   onChange={this.handleWorkhoursChange.bind(
                                             //     this,
                                             //     "evening_shift_end"
                                             //   )}
                                             //step={60}
                                             start="0"
                                             end="24"
                                             value={
                                             businessUserData.working_hours
                                             .evening_shift_end
                                             }
                                             placeholder="To"
                                             />
                                          </div>
                                       </div> */}

<div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Morning hours" isMandatory={true} />
                                        </div>

                                        <div className="form-group frlrclfgf w25 pdls">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_start"
                                            )}
                                            //step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .morning_shift_start
                                                ? businessUserData.working_hours
                                                  .morning_shift_start
                                                : ""
                                            }
                                            placeholder="From"
                                          /> */}
                                          <input
                                           /*  disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            } */
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="morning_shift_start"
                                            onChange={this.handleWorkhoursChange.bind(                                            this,
                                              "morning_shift_start"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .morning_shift_start
                                              : ""}
                                              disabled={true}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.working_hours_morning}
                                          />
                                        </div>

                                        <div className="form-group frlrclfgf w25 pdrs">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_end"
                                            )}
                                            // step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .morning_shift_end
                                            }
                                            placeholder="To"
                                          /> */}
                                          <input
                                         /*    disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            } */
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="morning_shift_end"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "morning_shift_end"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .morning_shift_end
                                              : ""}
                                              disabled={true}
                                          />
                                        </div>

                                        <div className="form-group frlrclfgf-1">
                                          <div className="square-switch">
                                            <input
                                              type="checkbox"
                                              id="square-switch33"
                                              switch="none"
                                            /*   onChange={this.handleWorkhoursChange.bind(
                                                this,
                                                "24Hours"
                                              )}
                                              */
                                              checked={
                                                businessUserData.working_hours
                                                  .hours_24
                                              }
                                            />
                                            <label
                                              htmlFor="square-switch33"
                                              data-on-label="24 hours"
                                              data-off-label="24 hours"
                                              className="dcte22 wid-100 bm-5"
                                            ></label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="frllftfull">
                                        <div className="form-group frllft">
                                        <Label title="Evening hours" isMandatory={true} /> 
                                        </div>

                                        <div className="form-group frlrclfgf w25 pdls">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_start"
                                            )}
                                            // step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .evening_shift_start
                                            }
                                            placeholder="From"
                                          /> */}
                                          <input
                                           /*  disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            } */
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="evening_shift_start"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_start"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .evening_shift_start
                                              : ""}
                                              disabled={true}
                                          />
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.evening_shift_start}
                                          />
                                        </div>

                                        <div className="form-group frlrclfgf w25 pdrs">
                                          {/* <TimePicker
                                            disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            }
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_end"
                                            )}
                                            // step={60}
                                            start="0"
                                            end="24"
                                            value={
                                              businessUserData.working_hours
                                                .evening_shift_end
                                            }
                                            placeholder="To"
                                          /> */}
                                          <input
                                          /*   disabled={
                                              businessUserData.working_hours
                                                .hours_24
                                            } */
                                            type="time"
                                            className="form-control"
                                            placeholder=""
                                            name="evening_shift_end"
                                            onChange={this.handleWorkhoursChange.bind(
                                              this,
                                              "evening_shift_end"
                                            )}
                                            value={businessUserData.working_hours
                                              ? businessUserData
                                                .working_hours
                                                .evening_shift_end
                                              : ""}
                                              disabled={true}
                                          />
                                        </div>
                                      </div>

                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>Speciality</label>
                                          </div>
                                          <div className="form-group frlrit">
                                             <Select maxMenuHeight={120}
                                                className="form-control p-0"
                                                name="speciality"
                                                isMulti
                                                value={businessUserData.speciality}
                                                onChange={this.handleselectChange}
                                                options={specialities_array}
                                                isDisabled={true}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>License ID</label>
                                          </div>
                                          <div className="form-group frlrit">
                                          <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"license id"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.licence_id}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                          </div>
                                       </div>
                                       <div className="frllftfull">
                                          <div className="form-group frllft">
                                             <label>License Expiry</label>
                                          </div>
                                          <div className="form-group frlrit">
                                          <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"Expiry_date"}
                                                controlFunc={this.handleInputChange}
                                                content={businessUserData.expiry_date}
                                                placeholder={""}
                                                isvisible={this.state.isDisable}
                                                />
                                          </div>
                                       </div>
                                       <div className="col-sm-12 mg-left">
                        <button type="button"  className="signupbtn abtn btdpml pull-right mb-1 " onClick={() =>this.handleback()}>
                        <i class="fas fa-arrow-left"></i>  Back
                              </button>
                        </div>
                                    </div>
                                 </div>
                                 {/** experience and membership section */}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                  className="col-sm-6 pl0"
                  style={{
                  display: this.state.expandmemDisabled
                  ? "none"
                  : "block",
                  }}
                  >
                  <div className="card">
                      <div class="card-header"> <div class="card-title">Reviews</div> </div>
                     <div className="card-body">
                        <div
                           className="tab-content"
                           id="v-pills-tabContent"
                           >
                           <div
                              className="tab-pane fade show active"
                              id="v-pills-shipping"
                              role="tabpanel"
                              aria-labelledby="v-pills-shipping-tab"
                              >
                              <div className="row reviewsdesign">
                                 
                                 <div className="col-sm-12">
                                    <div>
                                       <button>Friendliness</button>
                                       <StarRatings
                                       rating={
                                       reviewinfo.friendliness
                                       ? reviewinfo.friendliness
                                       : 0
                                       }
                                       starRatedColor="#ebb338"
                                       starDimension="15px"
                                       starSpacing="3px"
                                       numberOfStars={5}
                                       name="rating"
                                       />
                                    </div>
                                    <div>
                                       <button>Value for money</button>
                                       <StarRatings
                                       rating={
                                       reviewinfo.value_of_money
                                       ? reviewinfo.value_of_money
                                       : 0
                                       }
                                       starRatedColor="#ebb338"
                                       starDimension="15px"
                                       starSpacing="3px"
                                       numberOfStars={5}
                                       name="rating"
                                       />
                                    </div>
                                    <div>
                                       <button>Less Wait time</button>
                                       <StarRatings
                                       rating={
                                       reviewinfo.less_wait_time
                                       ? reviewinfo.less_wait_time
                                       : 0
                                       }
                                       starRatedColor="#ebb338"
                                       starDimension="15px"
                                       starSpacing="3px"
                                       numberOfStars={5}
                                       name="rating"
                                       />
                                    </div>
                                    <div>
                                       {" "}
                                       <button>Best Treatment </button>
                                       <StarRatings
                                       rating={
                                       reviewinfo.best_treatment
                                       ? reviewinfo.best_treatment
                                       : 0
                                       }
                                       starRatedColor="#ebb338"
                                       starDimension="15px"
                                       starSpacing="3px"
                                       numberOfStars={5}
                                       name="rating"
                                       />
                                    </div>
                                    <div>
                                       <button>Easy Explanation</button>
                                       <StarRatings
                                       rating={
                                       reviewinfo.easy_explanation
                                       ? reviewinfo.easy_explanation
                                       : 0
                                       }
                                       starRatedColor="#ebb338"
                                       starDimension="15px"
                                       starSpacing="3px"
                                       numberOfStars={5}
                                       name="rating"
                                       />
                                    </div>
                                    <div>
                                       <button>Good facility </button>
                                       <StarRatings
                                       rating={
                                       reviewinfo.good_facility
                                       ? reviewinfo.good_facility
                                       : 0
                                       }
                                       starRatedColor="#ebb338"
                                       starDimension="15px"
                                       starSpacing="3px"
                                       numberOfStars={5}
                                       name="rating"
                                       />
                                    </div>
                                 </div>
                              </div>
                             
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="card">
                  <div class="card-header"> <div class="card-title">Comments</div> </div>
                  <div className="card-body">
                  <div className="row reviewsdesign">
                                 
                                 {commentsArr
                                 ? commentsArr.map((element, index) => {
                                 return (
                                 <div
                                    key={index}
                                    className="col-sm-12 cmdpad"
                                    >
                                    <table className="">
                                       <tbody>
                                          <tr>
                                             <td>
                                                <img
                                                src={
                                                element.avatar
                                                ? element.avatar
                                                : "http://klinicq.com/static/media/iconpatent.5ca3dcad.png"
                                                }
                                                className="riwwimg"
                                                />{" "}
                                             </td>
                                             <td>
                                                <h5 className="wwim">
                                                   {element.commenter
                                                   ? element.commenter
                                                   : ""}{" "}
                                                   <span>
                                                   {" "}
                                                   {element.reviewed_on
                                                   ? element.reviewed_on
                                                   : ""}
                                                   </span>
                                                </h5>
                                                <p
                                                style={{
                                                marginBottom: "3px",
                                                }}
                                                >
                                                <StarRatings
                                                rating={
                                                element.overall_rating
                                                ? element.overall_rating
                                                : 0
                                                }
                                                starRatedColor="#ebb338"
                                                starDimension="15px"
                                                starSpacing="3px"
                                                numberOfStars={5}
                                                name="rating"
                                                />
                                                </p>
                                                <p>
                                                   {element.comment_text
                                                   ? element.comment_text
                                                   : ""}
                                                </p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 );
                                 })
                                 : ""}
                              </div>
                  </div>
                  </div>
               </div>
            </div>
      </div>
   </div>
   </div>
   </div>
        </section>
   <FooterSection />
         </div>
    
   </div>
   </div>
    );
  }
}

export default connect(mapStateToProps)(ViewBusinessUserProfile);
