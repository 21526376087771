import React, {Component} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';

export class Popup extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(<Modal 
            {...this.props}
           
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton className="model-hptd">
              <Modal.Title className="h5 model-text-color">
                    {this.props.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div>{this.props.message}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.props.onConfirm} className="signupbtn abtn btdpml">Yes</Button>
              <Button onClick={this.props.onHide} className="signupbtn abtn btdpml">No</Button>
            </Modal.Footer>
          </Modal>)
    }
}

export default Popup;