import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import { supplier_status } from "../../config/data";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import {
    getAllSupplierList,
    addSupplier,
    updateSupplier,
    deleteSupplier,
    getTimeoutbycategory,
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import Validation from "../../actions/Validation";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Spinner } from "../../components/UI/Spinner";
import ExpirePopup from "../../components/UI/ExpirePopup"
import FooterSection from "../../components/UI/FooterSection";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Label from "../../components/UI/Label";
import Dnaspinner from '../../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}
const google = window.google;
const onlineOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Not Active" },
  ];
  

class SupplierList extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
    
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
      
            suppliescategorylist: [],
            records: [],
            addPopup: false,
            actions: "add",

            supplierData: {
                id: "",
                supplier_id: "",
                supplier_name: "",
                address: "",
                phone_number: "",
                phone_code: "+91",
                status: "Active",
                status_obj: { value: "", label: "" },
                checked: false,
                payment_terms:"",
                gst:"",
                discount:""

            },

            errors: {
                id: "",
                supplier_id: "",
                supplier_name: "",
                address: "",
                phone_number: "",
                status: "",
                payment_terms:"",
                gst:"",
                discount:""

            },
            tableSuccess: {
                message: "",
                status: "",
            },
            formSuccess: {
                message: "",
                status: "",
            },
        };
        this.columns = [
            {
                key: "s_no",
                text: "S.No",
                className: "s_no",
                align: "left",
                sortable: true,
                width:5,
            },
            {
                key: "id",
                text: "ID",
                className: "hidden-column",
                TrOnlyClassName: "hidden-column",

                align: "left",
                sortable: true,
            },
            {
                key: "supplier_id",
                text: "Supplier ID",
                className: "supplier_id",
                align: "left",
                sortable: true,
                width:100,
            },
            {
                key: "supplier_name",
                text: "Supplier Name",
                className: "supplier_name",
                align: "left",
                sortable: true,
                width:100,
            },
            {
                key: "address",
                text: "Address",
                className: "address",
                align: "left",
                sortable: true,
            },
            {
                key: "phone_number",
                text: "Phone Number",
                className: "phone_number",
                align: "left",
                sortable: true,
            },
          
            {
                key: "status",
                text: "Supplier List Status",
                className: "status sform-control",
                sortable: true,
                align: "left",
               
                cell: (record) => {
                  let obj = {
                    label: record.status,
                    value: "Active" === record.status ? 1 : 0,
                  };
                  return (
                    <Select maxMenuHeight={120}
                      className="form-control p-0"
                      name="status_obj"
                      value={obj}
                      onChange={() => this.handleColumnChange(record)}
                      options={onlineOptions}
                    />
                  );
                },
              },
        
            {
                key: "payment_terms",
                text: "Payment terms",
                className: "payment_terms",
                align: "left",
                sortable: true,
                width:20,
            },

            {
                key: "gst",
                text: "Tax(%)",
                className: "gst",
                sortable: true,
                align: "left",
            },
            {
                key: "discount",
                text: "Discount(%)",
                className: "discount",
                sortable: true,
                align: "left",
            },
    
            
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: true,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.onDeleteClick(record)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    }

    handlePlaceSelect() {
        let place = this.autocomplete.getPlace();
    
        let { supplierData } = this.state;
    
        var componentForm = {
          street_number: "long_name",
          administrative_area_level_1: "long_name",
          locality: "long_name",
          country: "long_name",
          sublocality_level_1: "long_name",
          postal_code: "long_name",
        };
        console.log(place);
        supplierData.address = place.formatted_address;
        
    
        this.setState({
            supplierData,
        });
      }
     
    handleClose() {
        this.setState({showModal: false})
      }
    
     
       handleLogout = (e) => {
        this.setState({showModal: false})
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
       
    };
    
      _onAction(e) {
        console.log('user did something', e)
        
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        
        console.log('user is active', e)
        this.setState({isTimedOut: false})
      }
       
      _onIdle = (e) =>  {
        console.log('user is idle', e)
        let isTimedOut  = this.state.isTimedOut
        let timeout=this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
       // this.props.logout();
        this.props.history.push("/");
       
      }
      }
      handlePhoneInput = (event) => {
        let errors=this.state.errors;
        let supplierData = this.state.supplierData;
        supplierData.phone_number = event;
        errors.phone_number="";
        this.setState({ supplierData,errors });
      };
    
    handleselectChange = (selectedOptions, e) => {
        let data = this.state.supplierData;
        data[e.name] = selectedOptions;
       
        // console.log(selectedOptions)
        this.setState({ supplierData: data });
    };
    UpdateColumnChange = (data) => {

        axios.put(updateSupplier, data, CONFIG).then((list) => {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].supplier_id = data.supplier_id;
            records[recIndex].supplier_name = data.supplier_name;
            records[recIndex].address = data.address;
            records[recIndex].phone_number = data.phone_number;
            records[recIndex].status = data.status;
            records[recIndex].payment_terms = data.payment_terms;
            records[recIndex].gst = data.gst;
            records[recIndex].discount = data.discount;
            records[recIndex].user_id = data.user_id;
            records[recIndex].id = data.id;

            this.setState({ records, tableSuccess: list.data });
            this.clearMessage();
        });
    };
    updateSupplier = (e) => {
        e.preventDefault();
        let { supplierData, errors } = this.state;
        let data = {};
        data.type = "supplierlist";
        data.supplier_id = supplierData.supplier_id;
        data.supplier_name = supplierData.supplier_name;
        data.address = supplierData.address;
        data.phone_number = supplierData.phone_number;
        data.status = supplierData.status_obj.label;
        data.payment_terms = supplierData.payment_terms;
        data.gst = supplierData.gst;
        data.discount = supplierData.discount;
        data.user_id = this.props.userdata.id;
        console.log(data);
      //  this.setState({ loading: true });
        errors = Validation(data, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
            // console.log(data); return false;

         //   let url = this.state.actions === "add" ? addSupplier : updatesupplier;
            if (this.state.actions === "add") {
                axios.post(addSupplier, data, CONFIG).then((list) => {
                    let res = list.data;
                    if (res.status === "1") {
                        let addsupplier = {};
                        addsupplier.supplier_id = data.supplier_id;
                        addsupplier.supplier_name = data.supplier_name;
                        addsupplier.address = data.address;
                        addsupplier.phone_number = data.phone_number;
                        addsupplier.status = data.status;
                        addsupplier.payment_terms = data.payment_terms;
                        addsupplier.gst = data.gst;
                        addsupplier.discount=data.discount;
                        addsupplier.user_id=data.user_id;
                        addsupplier.id = list.data.data[0].id;
                     //   addsupplier.id = list.data.data;

                        let records = this.state.records;
                        addsupplier.s_no = records.length + 1;
                        records.push(addsupplier);
                    
                      //  this.setState({ records});
                        toast.success("Supplier Added Successfully. ",{
                            className:"toast-success"
                          });
                    //    this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
              //      }
                        }
                });
            } else {
                data.id = supplierData.id;
                console.log(data)
                axios.put(updateSupplier, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { supplier_id: list.data.message } });
                    } else {
                        let records = this.state.records;

                        var recIndex = findWithAttr(records, "id", data.id);
                        console.log(recIndex);
                        console.log(records);
                        records[recIndex].supplier_id = data.supplier_id;
                        records[recIndex].supplier_name = data.supplier_name;
                        records[recIndex].address = data.address;
                        records[recIndex].phone_number = data.phone_number;
                        records[recIndex].status = data.status;
                        records[recIndex].payment_terms = data.payment_terms;
                        records[recIndex].gst = data.gst;
                        records[recIndex].discount = data.discount;
                        records[recIndex].user_id = data.user_id;
                        records[recIndex].id = data.id;

                      //  this.setState({ records, formSuccess: list.data });
                    //    this.setState({ loading: false });
                    toast.success("Supplier Updated Successfully. ",{
                        className:"toast-success"
                      });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            }
        }
    };

    clearErrors = (errors) => {
        errors.supplier_id = "";
        errors.supplier_name = "";
        errors.address = "";
        errors.phone_number = "";
        errors.status = "";
        errors.payment_terms = "";
        errors.gst = "";
        errors.discount = "";
        return errors;
    };

    cancel = () => {
        let data = this.state.supplierData;
       
        data.status_obj = {
                           }
        data.supplier_id = "";
        data.supplier_name = "";
        data.address = "";
        data.phone_number = "";
        data.status = "";
        data.payment_terms = "";
        data.gst = "";
        data.discount = "";
       
       let errors = this.state.errors;

        errors = this.clearErrors(errors);

        this.setState({ supplierData: data, actions: "add", errors: errors });
    };

    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

    editRecord(record) {
        console.log(record)
        let supplierData = {};
        supplierData.supplier_id = record.supplier_id;
        supplierData.supplier_name = record.supplier_name;
        supplierData.address = record.address;
        supplierData.phone_number = record.phone_number;
        supplierData.status = record.status;
        supplierData.status_obj = {
            label: record.status,
            value: ("Active" === record.status) ? 1 : 0
          };
        supplierData.checked = "Active" === supplierData.status ? true : false;
        supplierData.payment_terms = record.payment_terms;
        supplierData.gst = record.gst;
        supplierData.discount = record.discount;
        supplierData.user_id=this.props.userdata.id;
        supplierData.id = record.id;


        console.log(supplierData)
        let errors = this.state.errors;
        errors = this.clearErrors(errors);

        this.setState({
            supplierData: supplierData,
            actions: "edit",
            errors: errors,
        });
    }
    componentDidMount = () => {
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("address"),
            {}
          );
          this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
      
        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
        axios
          .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status === "1")
              this.setState({
                hospitaltimeout: data.data.timeout
    
              });
          })
          .catch(() => { });
    
        axios
            .post(getAllSupplierList, { user_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
                let supplierlist = list.data.data;

                supplierlist.forEach((element, index) => {
                    let supplies = {};
                    supplies.s_no = index + 1;
                    supplies.supplier_id = element.supplier_id;
                    supplies.supplier_name = element.supplier_name;
                    supplies.address = element.address;
                    supplies.phone_number = element.phone_number;
                    supplies.status = element.status;
                    supplies.payment_terms = element.payment_terms;
                    supplies.gst = element.gst;
                    supplies.discount = element.discount;
                    supplies.id = element.id;

                    supplierlist[index] = supplies;
                });

                // let clientmedicinehistory = {};
                console.log(supplierlist)
                this.setState({ records: supplierlist });
            })
            .catch((e) => { });

       
    };
    onDeleteClick(record) {
        this.setState({ addPopup: true, record: record });
    }
    deleteRecord(record) {
        let userId = record.id;
        this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(deleteSupplier, { id: userId }, CONFIG)
            .then((res) => {
                let response = res.data;
                if (response.status === "1") {
                    let records = this.state.records;
                    const deleteIndex = records.map((item) => item.id).indexOf(record.id);
                    records.splice(deleteIndex, 1);
                    this.setState({ records });
                }
                this.setState({ addPopup: false, record: "", loading: false });
                this.setState({ tableSuccess: res.data });
                toast.error("Supplier deleted successfully. ", {
                    className: "toast-error"
                  });
                
                this.clearMessage();
            })
            .catch((e) => { });
    }
    handleColumnChange = (record) => {
        record.status =
          "Active" === record.status ? "Not Active" : "Active";
        this.UpdateColumnChange(record);
    };
      
    handleInput = (e) => {
        let data = this.state.supplierData;
        let name = e.target.name;
        let value = e.target.value;
        const re = /^[0-9\b]+$/;
        if (name === "amount") {
            if (value === "" || re.test(value)) {
                data[name] = value;
            }
        } else {
            data[name] = value;
        }

        this.setState({ supplierData: data });
    };
    handleExpiresatChange = (date) => {
        let suppliesData = this.state.suppliesData;
        suppliesData.expires_at = date;
        this.setState({ suppliesData: suppliesData });
    };
    render() {
        
     let ExpirePopupClose = () => {
        window.location.reload();
        this.setState({ showexpirePopup: true })
      };
      let ExpirePopupConfirm = () => {
        window.location.href = "/showsubscription";
  
      };
   
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            supplierData,
            errors,
            formSuccess,
            tableSuccess,
            hospitaltimeout,
            loading
        } = this.state;
        return (
            <div id="layout-wrapper">  
          {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />  */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
            <div className="main-content bacfot">
            <div className="mainpage">
              <Header user_id={this.props.userdata.id} />
              <div className="main-box">
                <div className="sidemenu pr0">
                  <BusinessUserSideBar />
                </div>
  
            <section className="main-body hospital-profilegap">
           <div className="container-fluid">
            <div className="col-sm-12">
                <h4 className="font-weight-bold"> Supplier List </h4>
                <div className="row mt-4">

                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body ">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="v-pills-shipping"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-shipping-tab"
                                    >
                                        <div className="user-notification">
                                            <SpanField
                                                classname={
                                                    0 === tableSuccess.status
                                                        ? "text-danger user-notification"
                                                        : "text-success user-notification"
                                                }
                                                title={tableSuccess.message}
                                            />
                                        </div>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            className="table table-bordered table-striped kas  mobile_table"
                                        />
                                        <Popup
                                            show={this.state.addPopup}
                                            onHide={PopupClose}
                                            onConfirm={PopupConfirm}
                                            title={"Confirmation"}
                                            message={"Are you sure you want to delete this record?"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 pr0">
                        <div className="card b-12-hite">
                            <div className="card-body">
                                <div className="user-notification">
                                    <SpanField
                                        classname={
                                            0 === formSuccess.status
                                                ? "text-danger user-notification"
                                                : "text-success user-notification"
                                        }
                                        title={formSuccess.message}
                                    />
                                </div>
                                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                <div>
                                <div class="card-header"> <div class="card-title">
                          {this.state.actions === "add" ? "Add" : "Edit"}  Supplier
                      </div></div>
                                    <hr className="rowline" />

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Supplier ID" isMandatory={true} />                                                 </div>
                                                <div className="form-group frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"supplier_id"}
                                                                controlFunc={this.handleInput}
                                                                content={supplierData.supplier_id}
                                                                placeholder={""}
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.supplier_id}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Status" isMandatory={true} />    
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative sform-control">
                                                        <div className="input-group">
                                                            <Select
                                                                className="form-control p-0"
                                                                name="status_obj"
                                                                value={supplierData.status_obj}
                                                                onChange={this.handleselectChange}
                                                                options={onlineOptions}
                                                            />
                                                    

                                                         </div>
                                                           <SpanField
                                                            classname="text-danger"
                                                            title={errors.status}
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                      </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Supplier Name" isMandatory={true} />  
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"supplier_name"}
                                                                controlFunc={this.handleInput}
                                                                content={supplierData.supplier_name}
                                                                placeholder={""}
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.supplier_name}
                                                        />
                                                    </div>
                                                </div>
                                                   </div>
                                              </div>

                                              <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Payment terms" isMandatory={true} />  
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"payment_terms"}
                                                                controlFunc={this.handleInput}
                                                                content={supplierData.payment_terms}
                                                                placeholder={""}
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.payment_terms}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                              <div className="row">
                                                <div className="col-sm-6">
                                                      <div className="frllftfull">
                                                        <div className="form-group frllft mbbre">
                                                        <Label title="Address" isMandatory={true} />   
                                                       </div>
                                                  <div className="form-group frlrit">
                                              <div className="position-relative">
                                                <div className="input-group">

                                                <InputField
                                                inputType={"text"}
                                                  name={"address"}
                                                  id="address"
                                                   controlFunc={this.handleInput}
                                                    content={supplierData.address}
                                                     placeholder={""}
                                                      />
                                                </div>
                                                   <SpanField
                                                     classname="text-danger"
                                                     title={errors.address}
                                                    />
                                                       </div>
                                                         </div>
                                                               </div>
                                                              </div>
                 
                                       <div className="col-sm-6">
                                             <div className="form-group frllft mbbre">
                                             <Label title="Tax Percentage(%)" isMandatory={true} />   
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                        <InputField
                                                          inputType={"text"}
                                                          name={"gst"}
                                                         controlFunc={this.handleInput}
                                                         content={supplierData.gst}
                                                         placeholder={""}
                                                         />
                                                  
                                                        </div>
                                                        <SpanField
                                                     classname="text-danger"
                                                     title={errors.gst}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                              </div>
                                       
                                              <div className="row">
                                                <div className="col-sm-6">
                                                      <div className="frllftfull">
                                                        <div className="form-group frllft mbbre">
                                                        <Label title="Phone Number" isMandatory={true} />   
                                                       </div>
                                                  <div className="form-group frlrit">
                                              <div className="position-relative">
                                              <div className="form-control">
                                                <PhoneInput
                                                  international
                                                  defaultCountry="IN"
                                                  placeholder="Enter phone number"
                                                  value={supplierData.phone_number}
                                                    onChange={this.handlePhoneInput}
                                                      error={
                                                      supplierData.phone_number
                                                          ? isValidPhoneNumber(supplierData.phone_number)
                                                         ? undefined
                                                          : "Invalid phone number"
                                                          : "Phone number required"
                                                               }
                                                            max={30}
                                                                 />
                                             
                                                </div>
                                                   <SpanField
                                                     classname="text-danger"
                                                     title={errors.phone_number}
                                                    />
                                                       </div>
                                                         </div>
                                                               </div>
                                                              </div>
                 
                                       <div className="col-sm-6">
                                             <div className="form-group frllft mbbre">
                                             <Label title="Discount Percentage(%)" isMandatory={true} />      
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                        <InputField
                                                          inputType={"text"}
                                                          name={"discount"}
                                                         controlFunc={this.handleInput}
                                                         content={supplierData.discount}
                                                         placeholder={""}
                                                         />
                                                
                                                
                                                        </div>
                                                        <SpanField
                                                     classname="text-danger"
                                                     title={errors.discount}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                              </div>



                                    <div className="row">
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-6">
                                            <div className="form-group mb-0 btn-align-center">
                                                
                                                <button
                                                    href="patients-register"
                                                    className="signupbtn abtn btdpml"
                                                    onClick={this.updateSupplier}
                                                >
                                                  
                                                    {this.state.actions === "add" ? "+ Add" : "Update"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </section>
            <FooterSection />
</div>
</div>
            </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(SupplierList);
