import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ToggleButton from "../../components/UI/ToggleButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Label from "../../components/UI/Label";
import {
  getLabList,
  addLab,
  updateLab,
  deleteLab,
  getTimeoutbycategory,
  hospitallabimport,
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import Validation from "../../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import LabListExport from "./LabListExport";
import LabTemplate from "./LabTemplate";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../../components/UI/Popupinfo";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
const google = window.google;

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

const onlineOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

class LabList extends Component {
  constructor(props) {
    
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;
this.autocomplete = null;
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
     
      records: [],

      addPopup: false,
      actions: "add",

      labData: {
        laboratory_id: "",
        laboratory_name: "",
        laboratory_location: "",
        phone_number: "",
        phone_code: "+91",
        online_prescription_obj: { value: "", label: "" },
        online_prescription: "No",
        checked: false,
      },

      errors: {
        laboratory_id: "",
        laboratory_name: "",
        laboratory_location: "",
        online_prescription: "",
        phone_number: "",
        phone_code: "+91",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
     formSuccess : {
        message: "",
        status: "",
      },
      formaddSuccess : {
        message: "",
        status: "",
      },
      tableaddSuccess: {
        message: "",
        status: "",
      },
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "laboratory_id",
        text: "Laboratory ID",
        className: "laboratory_id",
        align: "left",
        sortable: true,
      },
      {
        key: "laboratory_name",
        text: "Laboratory Name",
        className: "laboratory_name",
        align: "left",
        sortable: true,
      },
      {
        key: "laboratory_location",
        text: "Address",
        className: "laboratory_location",
        sortable: true,
        align: "left",
      },
      {
        key: "phone_number",
        text: "Phone Number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        sortable: true,
        align: "left",
      },
      {
        key: "online_prescription",
        text: "Online Laboratory",
        className: "online_prescription sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.online_prescription,
            value: "Yes" === record.online_prescription ? 1 : 0,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="online_prescription_obj"
              value={obj}
              onChange={() => this.handleColumnChange(record)}
              options={onlineOptions}
            />
          );
        },
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }

  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
   
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
   
  }
  }


  UpdateColumnChange = (data) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios.put(updateLab, data, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", data.id);

      records[recIndex].laboratory_id = data.laboratory_id;
      records[recIndex].laboratory_name = data.laboratory_name;
      records[recIndex].laboratory_location = data.laboratory_location;
      records[recIndex].online_prescription = data.online_prescription;
      records[recIndex].phone_number = data.phone_number;
      records[recIndex].email = data.email;
      records[recIndex].id = data.id;

      this.setState({ records, tableSuccess: list.data });
      let content=list.data;
      //toast(content);
      //toast.success("api success");
      this.clearMessage();
    });
  };
  UpdateLab = (e) => {
    e.preventDefault();
    let { labData, errors } = this.state;
    labData.type = "labList";
    let data = {};
    data.laboratory_id = labData.laboratory_id;
    data.laboratory_name = labData.laboratory_name;
    data.laboratory_location = labData.laboratory_location;
    data.online_prescription = labData.online_prescription;
    data.phone_number = labData.phone_number;
    data.email=labData.email;
  
    errors = Validation(labData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      let data = this.state.labData;
      data.user_id = this.props.userdata.id;
      console.log(data);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let url = this.state.actions === "add" ? addLab : updateLab;
      if (this.state.actions === "add") {
        axios
          .post(url, data, CONFIG)
          .then((list) => {
            console.log(list.data.status);
            console.log(list.data.message);
            if ("0" === list.data.status) {
              setTimeout(() => this.setState({ formaddSuccess:list.data.message}),2000);
              this.setState({ errorMessage: list.data.message } );
             
              toast.error("Laboratory could not be Added.",{
                className:"toast-error"
              });
              this.clearMessage();
            } else {
              let addLab = {};
              addLab.laboratory_id = data.laboratory_id;
              addLab.laboratory_name = data.laboratory_name;
              addLab.id = list.data.data[0].id;
              addLab.laboratory_location = data.laboratory_location;
              addLab.online_prescription = data.online_prescription;
              addLab.phone_number = data.phone_number;
              addLab.email=data.email;
              let records = this.state.records;
              addLab.s_no = records.length + 1;
              records.push(addLab);

              this.setState({ records});
           //   this.setState({  formSuccess: list.data });
              var content=list.data;
              console.log(content);
            //   toast(content,{
             //    className:"success-toast"
             //  }
             //   );
      
             toast.success("The Laboratory details have been added successfully.",{
               className:"toast-success"
             });
              this.clearMessage();
              this.cancel();
            }
            
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getLabList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let labList = list.data.data;

        labList.forEach((element, index) => {
          let lab = {};
          lab.s_no = index + 1;
          lab.laboratory_id = element.laboratory_id;
          lab.laboratory_name = element.laboratory_name;
          lab.id = element.id;
          lab.online_prescription = element.online_prescription;
          lab.laboratory_location = element.laboratory_location;
          lab.online_prescription = element.online_prescription;
          lab.phone_number = element.phone_number;
          lab.email=element.email;
          labList[index] = lab;
        });

        this.setState({ records: labList });
      })
      .catch((e) => {
        console.log("e" + e);
      });
          })
          .catch((e) => { });
      } else {
        axios.put(url, data, CONFIG).then((list) => {
          if ("0" === list.data.status) {
            console.log(list.data.message);
            console.log(list.data.status);
         
            this.setState({ formaddSuccess: { message:list.data.message}});
          /*   this.setState({ errors: { laboratory_id: list.data.message } }); */
            toast.error("Laboratory could not be updated.",{
              className:"toast-error"
            });
          //  this.setState({ errors: { phone_number: list.data.message } });
          } else {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].laboratory_id = data.laboratory_id;
            records[recIndex].laboratory_name = data.laboratory_name;
            // records[recIndex].online_prescription = data.online_prescription_obj.label;
            records[recIndex].laboratory_location = data.laboratory_location;
            records[recIndex].phone_number = data.phone_number;
            records[recIndex].email= data.email;
            records[recIndex].online_prescription = data.online_prescription;
            records[recIndex].id = data.id;
            this.setState({ records});
          //  this.setState({ formSuccess: list.data });
            toast.success("The Laboratory details have been updated successfully.",{
              className:"toast-success"
            });
           
            this.clearMessage();
            this.cancel();
          }
        });
      }
    }
  };

  clearErrors = (errors) => {
    errors.laboratory_id = "";
    errors.laboratory_name = "";
    errors.laboratory_location = "";
    errors.online_prescription = "";
    errors.phone_number = "";
    return errors;
  };

  clearimport=()=>{
    this.setState({ file:"" });
  };
  cancel = () => {
   
    let data = this.state.labData;
    data.laboratory_id = "";
    data.laboratory_name = "";
    data.id = "";
    data.user_id = "";

    data.online_prescription_obj = {};
    data.laboratory_location = "";
    data.online_prescription = "No";
    data.phone_number = "";
    data.email="";
    data.checked = false;

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ labData: data, actions: "add", errors: errors,errorMessage:"",file:"" });
  };

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          errorMessage:"",
        }),
      5000
    );
  };
  editRecord(record) {
    let labData = this.state.labData;
    labData.laboratory_id = record.laboratory_id;
    labData.laboratory_name = record.laboratory_name;
    labData.id = record.id;
    labData.user_id = record.user_id;
    labData.laboratory_location = record.laboratory_location;
    labData.online_prescription = record.online_prescription;
    labData.phone_number = record.phone_number;
    labData.email=record.email;
    labData.checked = "Yes" === labData.online_prescription ? true : false;

    let errors = this.state.errors;
    errors = this.clearErrors(errors);

    this.setState({ labData: labData, actions: "edit", errors: errors });
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { labData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    console.log(place);
    labData.laboratory_location = place.formatted_address;
    

    this.setState({
      labData,
    });
  }
  componentDidMount = () => {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

    let members = [];

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getLabList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let labList = list.data.data;

        labList.forEach((element, index) => {
          let lab = {};
          lab.s_no = index + 1;
          lab.laboratory_id = element.laboratory_id;
          lab.laboratory_name = element.laboratory_name;
          lab.id = element.id;
          lab.online_prescription = element.online_prescription;
          lab.laboratory_location = element.laboratory_location;
          lab.online_prescription = element.online_prescription;
          lab.phone_number = element.phone_number;
          lab.email=element.email;
          labList[index] = lab;
        });

        this.setState({ records: labList });
      })
      .catch((e) => {
        console.log("e" + e);
      });
  };
  onDeleteClick(record) {
    console.log(record);
    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    console.log(record);
    let userId = record.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteLab, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "" });
        this.setState({ tableSuccess: res.data });
        toast.error("The Laboratory detail has been deleted successfully.",{
          className:"toast-error"
        });
       
        this.clearMessage();
        this.cancel();
      })
      .catch((e) => { });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.labData;
    data[e.name] = selectedOptions;
    this.setState({ labData: data });
  };
  handleColumnChange = (record) => {
    record.online_prescription =
      "Yes" === record.online_prescription ? "No" : "Yes";
    this.UpdateColumnChange(record);
  };
  
  validateMaxLength(name, value, max) {
    let data = this.state.labData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ labData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.labData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name === "laboratory_id" ||
      name === "laboratory_name" ||
      name === "laboratory_location" 
         
    ) {
      this.validateMaxLength(name, value, max);
    } else 
    {
    data[name] = value;
    }
    this.setState({ labData: data });
  };
  handlePhoneInput = (event) => {
    let errors=this.state.errors;
    let labData = this.state.labData;
    labData.phone_number = event;
    errors.phone_number="";
    this.setState({ labData,errors });
  };

  handleIOnlineOption = (checkedData, obj) => {
    console.log(checkedData);
    //if value is off make it on & viceversa
    obj.target.value = obj.target.value === "off" ? "on" : "off";

    let labData = this.state.labData;
    // if obj is "on" make backend variable as Yes and viceversa. It has to behave like radio button
    labData.online_prescription = obj.target.value === "on" ? "Yes" : "No";
    labData.checked = !checkedData;

    this.setState({ labData });
  };
  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    readXlsxFile(event.target.files[0]).then((rows) => {
        console.log(rows);
        console.log("rows");
        this.setState({ labData: rows });
        // `rows` is an array of rows
        // each row being an array of cells.
    })

};
onFileUpload = () => {

   if (this.state.labData.length) {
      axios.post(hospitallabimport, {data: this.state.labData,user_id:this.props.userdata.id}).then((result) => {
          console.log(result);
          this.setState({ formSuccess: result.data, file: "", labData: [] })
          axios
          .post(getLabList, { user_id: this.props.userdata.id }, CONFIG)
          .then((list) => {
            let labList = list.data.data;
    
            labList.forEach((element, index) => {
              let lab = {};
              lab.s_no = index + 1;
              lab.laboratory_id = element.laboratory_id;
              lab.laboratory_name = element.laboratory_name;
              lab.id = element.id;
              lab.online_prescription = element.online_prescription;
              lab.laboratory_location = element.laboratory_location;
              lab.online_prescription = element.online_prescription;
              lab.phone_number = element.phone_number;
              lab.email=element.email;
              labList[index] = lab;
            });
              this.setState({ records: labList });
          })
          .catch(() => { });
          this.clearMessage();
      });
  }

};
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const { labData, errors, formSuccess, tableSuccess,hospitaltimeout,errorMessage,loading,formaddSuccess,tableaddSuccess } = this.state;
    return (
      <div className="col-sm-12 checkout-tabs">
          <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

        <h4 className="font-weight-bold"> Laboratory List </h4>
        {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
        <div className="row">

          <div className="col-sm-12">
            <div className="card">
              <div className="card-body ">
                <div className="tab-content" id="v-pills-tabContent">
                <div className="float-right btn "> <LabListExport business_id={this.props.userdata.id} records={this.state.records}/></div>
                    
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
               
                <div className="user-notification">
                      <SpanField
                        classname={
                          "0" === tableSuccess.status
                            ? "text-danger user-notification"
                            : "text-success user-notification"
                        }
                        title={tableSuccess.message}
                      />
                    </div>
                   
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      className="table table-bordered table-striped kas  mobile_table"
                    />
                    <Popup
                      show={this.state.addPopup}
                      onHide={PopupClose}
                      onConfirm={PopupConfirm}
                      title={"Confirmation"}
                      message={"Are you sure you want to delete this record?"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
                                            <div className="card">
                                            <div class="card-header"> <div class="card-title">Import Lab</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                      
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    "0" === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div className="form-group d-flex justify-content-center mg-btm5">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml  btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                        <div className="float-right btn btn2 "> <LabTemplate /></div>
                                                        <div className="row">
                                                      
                                                            <div className="col-sm-4">
                                                                <div className="custom-file">
                                                                  
                                                                    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" type="file" onChange={this.onFileChange} name="file" className="custom-file-input " id="file" />
                                                                    <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                                                                    {/* <div className="form-group  mt-2">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml  btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div> */}
                                                                </div>
                                                             
                                                            </div>

                                                       
                                                           
                                                        </div>
                                                    </div>
                                                  {/*   <div className="form-group"><label className="control-label">{this.state.file ? this.state.file.name : ""}</label></div> */}
                                                </div>
                                            </div>
                                        </div> 
          <div className="col-sm-12 pr0">
            <div className="card b-12-hite">
            <div class="card-header"> <div class="card-title">    {this.state.actions === "add" ? "Add" : "Edit"} Laboratory</div> </div>
              <div className="card-body">
                <div className="user-notification">
                  <SpanField
                    classname={
                      "0" === formaddSuccess.status
                        ? "text-success user-notification"
                        : "text-danger user-notification"
                    }
                    title={formaddSuccess.message}
                  />
                   <SpanField
                                      classname="text-danger"
                                      title={errorMessage}
                                    />
                </div>
                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                <div>
                 
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                        <Label title="Laboratory ID" isMandatory={true} />
                         
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"laboratory_id"}
                                controlFunc={this.handleInput}
                                content={labData.laboratory_id}
                                placeholder={""}
                                max={5}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.laboratory_id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                        <Label title="Phone Number" isMandatory={true} />
                        
                        </div>

                        <div className="form-group frlrit">
                          <div className="form-control">
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              value={labData.phone_number}
                              onChange={this.handlePhoneInput}
                              error={
                                labData.phone_number
                                  ? isValidPhoneNumber(labData.phone_number)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                              max={30}
                            />
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                          <Label title="Laboratory Name" isMandatory={true} />
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"laboratory_name"}
                                controlFunc={this.handleInput}
                                content={labData.laboratory_name}
                                placeholder={""}
                                max={50}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.laboratory_name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                        <Label title="Address" isMandatory={true} />
                         </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"laboratory_location"}
                                controlFunc={this.handleInput}
                                content={labData.laboratory_location}
                                placeholder={""}
                                id="address"
                                max={200}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.laboratory_location}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                   <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             <Label title="Email" isMandatory={true} />  
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       id="email"
                                       inputType={"text"}
                                       name={"email"}
                                       controlFunc={this.handleInput}
                                       content={labData.email}
                                       placeholder={""}
                                       max={200}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.email}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                          <label className="ffgrd">Online Laboratory</label>
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <ToggleButton
                                name={"online_prescription"}
                                dataOn="Yes"
                                dataOff="No"
                                value={
                                  "" === labData.online_prescription ||
                                    "No" === labData.online_prescription
                                    ? "off"
                                    : "on"
                                }
                                checked={labData.checked}
                                controlFunc={this.handleIOnlineOption.bind(
                                  "online_prescription",
                                  labData.checked
                                )}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.online_prescription}
                            />
                            <div className="float-right">
                             <button
                          href="patients-register"
                          className="signupbtn abtn btdpml btn-margin"
                          onClick={this.UpdateLab}
                        >
                          {this.state.actions === "add" ? "+ Add" : "Update"}
                        </button>

                        <button
                          href="patients-register"
                          className="signupbtn abtn btdpml"
                          onClick={this.cancel}
                        >
                          {"Cancel"}
                        </button>
                        </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-0 btn-align-right ">
                        {/* <button
                          href="patients-register"
                          className="signupbtn abtn btdpml btn-margin"
                          onClick={this.UpdateLab}
                        >
                          {this.state.actions === "add" ? "+ Add" : "Update"}
                        </button>

                        <button
                          href="patients-register"
                          className="signupbtn abtn btdpml"
                          onClick={this.cancel}
                        >
                          {"Cancel"}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
           )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(LabList));
