import React, {Component} from 'react';


export class Spinner extends Component{
    constructor(props){
        super(props);
    }

    render(){

      if(this.props.loading){
        return( 
          <div className="loading" ></div>
       ) 
      }else{
        return( 
          <div></div>
       ) 
        }
      }
  }
export default Spinner;