
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Popup } from '../../components/UI/Popup';
import {
  getPharmacyList,
  addPharmacy,
  updatePharmacy,
  deletePharmacy,
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Validation from "../../actions/Validation";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}


function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}


class PharmacyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],

      addPopup: false,
      actions: "add",

        pharmacyData: {
        patient_name: "",
        phone_no: "",
        hospital_location: "",
        doctor_name:"",
        status: "",
      },

      errors: {
        patient_name: "",
        phone_no: "",
        hospital_location: "",
        doctor_name:"",
        status: "",
       
      },
      tableSuccess: {
        message: "",
        status: ""
      },
      formSuccess: {
        message: "",
        status: ""
      }
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "patient_name",
        text: "Patient Name",
        className: "patient_name",
        align: "left",
        sortable: true,
      },
      {
        key: "phone_no",
        text: "Phone Number",
        className: "phone_no",
        align: "left",
        sortable: true,
      },
      {
        key: "hospital_location",
        text: "Hospital",
        className: "hospital_location",
        sortable: true,
        align: "left",
      },
      {
        key: "doctor_name",
        text: "Doctor Name",
        className: "doctor_name",
        sortable: true,
        align: "left",
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        sortable: true,
        align: "left",
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
  }
  UpdateColumnChange = (data) => {

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios.put(updatePharmacy, data, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", data.id);


      records[recIndex].patient_name = data.patient_name;
      records[recIndex].phone_no = data.phone_no;
      records[recIndex].hospital_location = data.hospital_location;
      records[recIndex].doctor_name = data.doctor_name;
      records[recIndex].status = data.status;
      records[recIndex].action = data.action;


      this.setState({ records, tableSuccess: list.data });
      this.clearMessage();
    });
  }
  UpdatePharmacy = (e) => {
    e.preventDefault();
    let { pharmacyData, errors } = this.state;
    pharmacyData.type = "pharmacyList";

    errors = Validation(pharmacyData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      let data = this.state.pharmacyData;
      data.user_id = this.props.userdata.id;
      data.online_prescription = data.online_prescription_obj.label;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let url = this.state.actions === "add" ? addPharmacy : updatePharmacy;
      if (this.state.actions === "add") {
        axios.post(url, data, CONFIG).then((list) => {
          if (0 === list.data.status) {
            this.setState({ errors: { pharmacy_id: list.data.message } });
          } else {
            let addPharmacy = {};
            addPharmacy.pharmacy_id = data.pharmacy_id;
            addPharmacy.pharmacy_name = data.pharmacy_name;
            addPharmacy.online_prescription = data.online_prescription_obj.label;
            addPharmacy.id = list.data.data[0].id;
            addPharmacy.pharmacy_location = data.pharmacy_location;
            addPharmacy.online_prescription = data.online_prescription;
            let records = this.state.records;
            addPharmacy.s_no = records.length + 1;
            records.push(addPharmacy);

            this.setState({ records, formSuccess: list.data });

            this.clearMessage();
            this.cancel();
          }

        });
      } else {

        axios.put(url, data, CONFIG).then((list) => {

          if (0 === list.data.status) {
            this.setState({ success: list.data });
          } else {

            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].pharmacy_id = data.pharmacy_id;
            records[recIndex].pharmacy_name = data.pharmacy_name;
            records[recIndex].online_prescription = data.online_prescription_obj.label;
            records[recIndex].pharmacy_location = data.pharmacy_location;
            records[recIndex].online_prescription = data.online_prescription;
            records[recIndex].id = data.id;

            this.setState({ records, formSuccess: list.data });
            this.clearMessage();
            this.cancel();
          }
        });
      }

    }
  };

  clearErrors = (errors) => {

    errors.pharmacy_id = ""
    errors.pharmacy_name = ""
    errors.pharmacy_location = ""
    errors.online_prescription = ""
    return errors
  }

  cancel = () => {

    let data = this.state.pharmacyData;
    data.pharmacy_id = ""
    data.pharmacy_name = ""
    data.id = ""
    data.user_id = ""

    data.online_prescription_obj = {

    }
    data.pharmacy_location = ""
    data.online_prescription = ""

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ pharmacyData: data, actions: "add", errors: errors });
  }

  clearMessage = () => {
    setTimeout(() => this.setState({
      formSuccess: { message: "", },
      tableSuccess: { message: "" }
    }), 5000);
  }
  editRecord(record) {

    let pharmacyData = this.state.pharmacyData;
    pharmacyData.pharmacy_id = record.pharmacy_id;
    pharmacyData.pharmacy_name = record.pharmacy_name;
    pharmacyData.id = record.id;
    pharmacyData.user_id = record.user_id;

    pharmacyData.online_prescription_obj = {
      label: record.online_prescription,
      value: ("Yes" === record.online_prescription) ? 1 : 0
    }
    pharmacyData.pharmacy_location = record.pharmacy_location;
    pharmacyData.online_prescription = record.online_prescription;

    let errors = this.state.errors;
    errors = this.clearErrors(errors);

    this.setState({ pharmacyData: pharmacyData, actions: "edit", errors: errors });
  }
  componentDidMount = () => {


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getPharmacyList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let pharmacyList = list.data.data;

        pharmacyList.forEach((element, index) => {
          let pharmacy = {};
          pharmacy.s_no = index + 1;
          pharmacy.pharmacy_id = element.pharmacy_id;
          pharmacy.pharmacy_name = element.pharmacy_name;
          pharmacy.id = element.id;
          pharmacy.online_prescription = element.online_prescription;
          pharmacy.pharmacy_location = element.pharmacy_location;
          pharmacy.online_prescription = element.online_prescription;


          pharmacyList[index] = pharmacy;
        });


        // this.setState({ records: pharmacyList });
        this.setState({ records: [{
          s_no:"1",
          patient_name:"kumar",

          // ""
        }] });
      })
      .catch(() => { });

  };
  onDeleteClick(record) {
    console.log(record);
    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    console.log(record);
    let userId = record.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deletePharmacy, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records
            .map((item) => item.id)
            .indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "" });
        this.setState({ tableSuccess: res.data });
        this.clearMessage();
      })
      .catch(() => { });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.pharmacyData;
    data[e.name] = selectedOptions;
    this.setState({ pharmacyData: data });
  };
  handleColumnChange = (record) => {
    record.online_prescription = ("Yes" === record.online_prescription) ? "No" : "Yes";
    this.UpdateColumnChange(record);
  }
  handleInput = (e) => {
    let data = this.state.pharmacyData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ pharmacyData: data });
  };
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    const { tableSuccess } = this.state;
    return (

      <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-12">
        <h4 className="font-weight-bold"> Patient Prescription </h4>
        </div>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body ">
              <div
                className="tab-content"
                id="v-pills-tabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
  <div className="row">
                        <div className="col-sm-3">
                          <div className="required">
                            <label className="f12">
                              <img
                                alt=""
                                src={require("../../assets/images/phone.png")}
                                className="imgsfd"
                              />{" "}
                              Mobile number / Registration ID
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <div className="input-group">
                            <input class="form-control" name="phone_number" type="text" placeholder="" value=""/>

                              </div>
                              <span class="text-danger">
                                </span><div class="user-notification"><span class="text-success user-notification"></span></div></div></div>

                            
                           
                          





                         
                        <div className="col-sm-3 ">
                          <div className="form-group">
                            <div className="row">
                            <button type="button" class="signupbtn abtn btdpml">
                              <i class="fa fa-search-plus" aria-hidden="true">
                                </i>&nbsp;Submit</button>





                             
                            </div>
                          </div>
                        </div>
                        </div>



                  <div className="user-notification">
                    <SpanField
                      classname={(0 === tableSuccess.status) ? "text-danger user-notification" :
                        "text-success user-notification"}
                      title={tableSuccess.message}
                    /></div>
                  <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                    className="table table-bordered table-striped kas  mobile_table"
                  />
                  <Popup show={this.state.addPopup} onHide={PopupClose}
                    onConfirm={PopupConfirm} title={"Confirmation"}
                    message={"Are you sure you want to delete this record?"} />
                </div>
              </div>
            </div>
          </div>
        


               
                

              </div>
            </div>
          </div>
        

      

    

  );
}
}

export default connect(mapStateToProps)(PharmacyProfile);