import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllDisease,
  getAllDiseaseCategory,
  addDisease,
  updateDisease,
  deleteDisease,
  getAllMasterDisease,
  searchDisease,
  getTimeoutbycategory
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Validation from "../../src/actions/Validation";
import { Popup } from "../components/UI/Popup";
import { Spinner } from "../components/UI/Spinner";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import Label from "../components/UI/Label";
import ExpirePopup from "../components/UI/ExpirePopup";
import DiseasesExport from "./DiseasesExport";
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity

  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class Diseases extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      showexpirePopup: this.props.validity,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      addPopup: false,
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      adddiseaseFailiure: false,
      // addDisResponseMessage: false,
      masterDiseases: [],
      recordToDelete: [],
      searchrecords: [],
      records: [],
      actions: "add",
      newDatatoAdd: {},
      diseaseCategory: [],
      addDisResponseMessage: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      searchformSuccess: {
        message: "",
        status: "",
      },
      diseaseData: {
        disease_id: "",
        searchdisease_id:"",
        disease_name: "",
        disease_category: "",
      },
      errors: {
        id: "",
        disease_id: "",
        disease_name: "",
        disease_category: "",
        diseaseid_error:"",
      },
      searchData: {
        disease_id: "",
        disease_name: "",
        disease_category: "",
      },
      columns: [
        { title: "Disease Id", field: "disease_id" },
        { title: "Disease Name", field: "disease_name" },
        { title: "Disease Category", field: "disease_category" },
      ],
      data: [{ disease_id: "", disease_name: "", disease_category: "" }],
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "disease_id",
        text: "Disease ID",
        className: "disease_id",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "disease_name",
        text: "Disease Name",
        className: "disease_name",
        align: "left",
        sortable: true,
        width: 100,
      },

      {
        key: "disease_category",
        text: "Disease Category",
        className: "disease_category",
        sortable: true,
        align: "left",
        width: 100,
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.searchcolumns = [
      // {
      //   key: "s_no",
      //   text: "S.No",
      //   className: "s_no",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "disease_id",
        text: "Disease ID",
        className: "disease_id",
        align: "left",
        sortable: true,
        width: 50,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"searchdisease_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.searchdisease_id}
                placeholder={""}
                max={5}
              />
               <SpanField
                classname="text-danger"
                title={record.diseaseid_error}
              />
          {/*     <SpanField
                classname="text-danger"
                title={
                  record.id === this.state.errors.id
                    ? this.state.errors.disease_id
                    : ""
                }
              /> */}
               
            </Fragment>
          );
        },
      },
      {
        key: "disease_name_hide",
        text: "disease_name_hide",
        className: "disease_name_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "disease_category_hide",
        text: "disease_category_hide",
        className: "disease_category_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "disease_name",
        text: "Disease Name",
        className: "disease_name",
        align: "left",
        sortable: true,
        width: 100,

        // cell: (record) => {
        //   return (
        //     <Fragment>
        /* <CreatableSelect
          className="form-control p-0"
          name="disease_name"
          isMandatory={true}
          value={record.disease_name}
          onChange={(value) =>
            this.handleCategoryColumnChange(record, value, "disease_name")
          }
          options={this.state.masterDiseases}
        /> */
        //       <InputField
        //          inputType={"text"}
        //           isMandatory={true}
        //            name={"disease_name"}
        //            controlFunc={this.handleInput.bind(this)}
        //            content={record.disease_name.value}
        //             placeholder={""}
        //             max={50}
        //             />
        //       <SpanField
        //         classname="text-danger"
        //         title={
        //           record.id === this.state.adderrors.id
        //             ? this.state.adderrors.disease_name
        //             : ""
        //         }
        //       />
        //     </Fragment>
        //   );
        // },
      },

      {
        key: "disease_category",
        text: "Disease Category",
        className: "disease_category",
        sortable: true,
        align: "left",
        width: 100,

        // cell: (record) => {
        //   return (
        //     <Fragment>
        /* <CreatableSelect
          className="form-control p-0"
          name="disease_category"
          isMandatory={true}
          value={record.disease_category}
          onChange={(value) =>
            this.handleCategoryColumnChange(
              record,
              value,
              "disease_category"
            )
          }
          options={this.state.diseaseCategory}
        /> */
        //       <InputField
        //          inputType={"text"}
        //           isMandatory={true}
        //            name={"disease_category"}
        //            controlFunc={this.handleInput.bind(this)}
        //            content={record.disease_category.value}
        //             placeholder={""}
        //             max={50}
        //             />
        //       <SpanField
        //         classname="text-danger"
        //         title={
        //           record.id === this.state.adderrors.id
        //             ? this.state.adderrors.disease_category
        //             : ""
        //         }
        //       />
        //     </Fragment>
        //   );
        // },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.createDisease(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button> */}
            </Fragment>
          );
        },
      },
    ];
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)


  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();

  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          addDisResponseMessage: { message: "" },
          formSuccess: { message: "" },
          searchformSuccess: { message: "" },
        }),
      5000
    );
  };

  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value=event.target.value;
     if (
       event.target.name === "searchdisease_id"
 
     ) {
       if (value.length > max) {
         const value1 = value.slice(0, max);
         record.diseaseid_error  = "Maximum Length has exceeded.";
         record[event.target.name] = value1;
       }
      else {
       
       record[event.target.name] = event.target.value;
       record.diseaseid_error  = "";
     }
   }
    this.UpdateColumnChange(record);
  };

  handleColumnChange = (record, event) => {
    record[event.name] = record.label;
    this.UpdateColumnChange(record);
  };

  handleCategoryColumnChange = (value, record, field) => {
    value[field] = record;

    this.UpdateColumnChange(value);
  };

  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;

    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].disease_id = record.disease_id;
    records[recIndex].disease_name = record.disease_name;
    records[recIndex].disease_category = record.disease_category;

    this.setState({ searchrecords: records });
  };

  getDiseaseNameandCategory() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDisease, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let diseaselist = list.data.data;

        diseaselist.forEach((element, index) => {
          let disease = {};
          disease.s_no = index + 1;
          disease.disease_id = element.disease_id;
          disease.disease_name = element.disease_name;
          disease.disease_category = element.disease_category;
          disease.id = element.id;

          diseaselist[index] = disease;
        });

        // let clientmedicinehistory = {};
        this.setState({ records: diseaselist });
      })
      .catch(() => { });

    axios
      .post(getAllDiseaseCategory, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let diseaseCategory = list.data.data;

        let diseaseCategorylist = [];

        diseaseCategory.forEach((element) => {
          diseaseCategorylist.push({
            value: element.id,
            label: element.disease_category,
          });
        });

        // let clientmedicinehistory = {};
        this.setState({ diseaseCategorylist });
      })
      .catch(() => { });
  }

  createDisease(record) {
    let errors = this.state.errors;
    record.type = "adddisease";
    errors = Validation(record, errors);
    /* let formvalid = true;
    if (record.searchdisease_id === "") {
      record.diseaseid_error = "Please enter disease id";
      formvalid = false;
    } else {
      record.diseaseid_error = "";
    } */
    console.log(errors);
    console.log(record);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      let data = {};
      data.s_no = data.s_no;
      data.disease_name = record.disease_name;
      data.disease_id = record.searchdisease_id;
      data.user_id = this.props.userdata.id;
      data.disease_category = record.disease_category;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios.post(addDisease, data, CONFIG).then((list) => {
        let disease = {};
        let diseaseRes = list.data;
        if (diseaseRes.status === "1") {
          disease.id = diseaseRes.data[0].id;

          disease.disease_id = record.searchdisease_id;
          disease.disease_name = record.disease_name;
          disease.disease_category = record.disease_category;
          let records = this.state.records;
          disease.s_no = records.length + 1;
          records.push(disease);
          this.setState({ records });
          this.setState({ formSuccess: diseaseRes.message });
          toast.success("The Disease details have been added successfully.",{
            className:"toast-success"
          });
        } else {
         
          this.setState({ record, formSuccess: diseaseRes.message });
          toast.error(diseaseRes.message.toString(),{
            className:"toast-error"
          });
        }
        this.clearMessage();
      });
    }
  }

  UpdateDisease = (e) => {
    e.preventDefault();
    let { diseaseData, errors } = this.state;
    diseaseData.type = "disease";

    errors = Validation(diseaseData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
     // let data = this.state.diseaseData;
      let data = {};
      //data.s_no = data.s_no;
      data.disease_name = diseaseData.disease_name;
      data.id = this.state.diseaseData.id;
      data.user_id = this.props.userdata.id;
      data.disease_category = diseaseData.disease_category.label;
      data.disease_id=diseaseData.disease_id;
      let records = this.state.records;
      if (this.state.actions === "add") {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios.post(addDisease, data, CONFIG).then((list) => {
          let disease = {};
          let diseaseRes = list.data;
          console.log(diseaseRes);
          if (diseaseRes.status === "1") {
            disease.id = diseaseRes.data[0].id;

            disease.disease_id = data.disease_id;
            disease.disease_name = data.disease_name;
            disease.disease_category = data.disease_category;

            disease.s_no = records.length + 1;

            records.push(disease);
            this.setState({ records });
          //  this.setState({ formSuccess: list.data });
            toast.success("Diseases Added Successfully. ",{
              className:"toast-success"
            });
          } else {
            this.setState({ records });
            this.setState({ formSuccess: list.data });
          
            toast.error("Diseases could not be Added. ",{
              className:"toast-error"
            });
          }
          this.clearMessage();
         this.clearAll();
        });
      } else {
        axios.put(updateDisease, data, CONFIG).then(() => {
          let member = {};
          member.id = data.id;
          member.disease_id = data.disease_id;
          member.disease_name = data.disease_name;
          member.disease_category = data.disease_category;
          member.s_no = data.s_no;

          let records = this.state.records;

          /** Updating the data table */
          let existingRecords = this.state.records;
          var index = existingRecords.findIndex((item) => item.id === data.id);
          existingRecords.splice(index, 1, member);
          this.setState({ records: existingRecords });

          this.setState({ records});
          //this.setState({ formSuccess: list.data });
          toast.success("The Disease details have been updated successfully.",{
            className:"toast-success"
          });
          this.clearMessage();
          this.clearAll();
        });
      }
    }
  };
  editRecord(record) {
    // setTimeout(
    // () =>
    // this.getDiseaseNameandCategory(),
    // 5000
    // );

    let diseaseData = this.state.diseaseData;
    diseaseData.disease_id = record.disease_id;

    // diseaseData.disease_name = {
    //   label: record.disease_name,
    //   value: record.disease_name,
    // };
    diseaseData.disease_name=record.disease_name;
    diseaseData.id = record.id;
    diseaseData.s_no = record.s_no;
    diseaseData.user_id = record.user_id;
    diseaseData.disease_category = {
      label: record.disease_category,
      value: record.disease_category,
    };

    this.setState({ diseaseData: diseaseData, actions: "edit" });
  }
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch(() => { });


    this.getDiseaseNameandCategory();

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllMasterDisease, null, CONFIG)
      .then((masterDiseasesResponse) => {
        let masterDiseaseList = masterDiseasesResponse.data.data;
        let masterDiseases = [];
        masterDiseaseList.forEach((element) => {
          masterDiseases.push({
            value: element.id,
            label: element.disease_name,
          });
        });

        this.setState({ masterDiseases });
      })
      .catch(() => { });
  };

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  deleteRecord(record) {
    let userId = record.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDisease, { data: { id: userId } }, CONFIG)
      .then((res) => {

        let records = this.state.records;

        const deleteIndex = records.map((item) => item.id).indexOf(record.id);
        records.splice(deleteIndex, 1);
        this.setState({ records });

        this.setState({ addPopup: false, record: "", loading: false });
      //  this.setState({ addDisResponseMessage: res.data });
        toast.error("The Disease detail has been deleted successfully.",{
          className:"toast-error"
        });
        this.clearMessage();
        this.clearAll();
      })
      .catch(() => { });
  }

  okDelete() {
    let record = this.state.recordToDelete;

    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });

    this.setState({
      confirmedDelete: true,
    });

    let userId = record.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDisease, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;

          const deleteIndex = records
            .map((item) => item.user_id)
            .indexOf(record.user_id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
         
        }
      })
      .catch(() => { });

    this.setState({
      confirmedDelete: false,
    });
  }

  clearAll() {
    let errors=this.state.errors;
    let actions = this.state.actions;
    actions = "add";
    this.setState({ actions });
    let diseaseData = {};
    diseaseData.disease_id = "";
    diseaseData.disease_name = "";
    diseaseData.disease_category = "";
    errors={};
    this.setState({ diseaseData,errors, searchErrorMessage: "",searchrecords: [],search_action:false });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  handleselectChange = (selectedOptions, e) => {
    let errors=this.state.errors;
    let data = this.state.diseaseData;
    data[e.name] = selectedOptions;
    errors.disease_category="";
    this.setState({ diseaseData: data,searchErrorMessage: "" });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.diseaseData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ diseaseData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.diseaseData;
    let errors=this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name === "disease_id"||name === "disease_name"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors.disease_name="";
    }
    this.setState({ diseaseData: data,searchErrorMessage: "" });
  };

  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });
  };
  handlesearchInput = (e) => {
    let data = this.state.diseaseData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ searchData: data });
  };

  searchandshowDisease() {
    let diseaseData = this.state.diseaseData;

    if (
      (diseaseData.disease_name === "" || null === diseaseData.disease_name) &&
      (diseaseData.disease_category === "" ||
        null === diseaseData.disease_category)
    ) {
      this.setState({
        searchErrorMessage:
          "Choose any one of the search filter other than Disease ID.",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }

    let data = {};

    data.disease_name = diseaseData.disease_name;
    data.disease_category = diseaseData.disease_category
      ? diseaseData.disease_category.label
      : "";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(searchDisease, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });

        let result = searchres.data;
        if (result.countOfRecords > 0) {
          let records = result.data;

          let data = [];
          records.forEach((element) => {
            data.push({
              id: element.id,
              disease_id: "",
              disease_name_hide: element.disease_name,
              disease_category_hide: element.disease_category,
              disease_name: element.disease_name,
              disease_category: element.disease_category,
              // ? {
              //   label: element.disease_category,
              //   value: element.disease_category,
              // }
              // : "",
            });
          });

          this.setState({ searchrecords: data });
          this.setState({
            search_action: true,
          });
        } else {
          this.setState({ searchrecords: [] });
          this.setState({
            search_action: true,
          });
        }
      });
    });
  }

  render() {
    let ExPopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let ExPopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
     let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const {
      diseaseData,
      diseaseCategory,
      addDisResponseMessage,
      searchformSuccess,
      formSuccess,
      errors,
      searchErrorMessage,
      hospitaltimeout,
      loading
    } = this.state;
    return (
      <div id="layout-wrapper">
        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExPopupClose}
          onConfirm={ExPopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
        {/* <SideBarNew /> */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

     {/*    <Spinner loading={this.state.loading} /> */}
     {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <SideBar />
              </div>
              {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
              <section className="main-body hospital-profilegap">
                <div className="container-fluid checkout-tabs">
                  <div className="checkout-tabs">


                    <div className="col-sm-12">
                      <h4 className="font-weight-bold"> Disease List </h4>
                      <div className="row">

                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body ">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                  <div className="float-right btn "> <DiseasesExport business_id={this.props.userdata.id} records={this.state.records}/></div>
                    
               
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        "0" === addDisResponseMessage.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={addDisResponseMessage.message}
                                    />
                                      <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                  </div>

                                  <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    className="table table-bordered table-striped kas  mobile_table"
                                  />
                                  <Popup
                                    show={this.state.addPopup}
                                    onHide={PopupClose}
                                    onConfirm={PopupConfirm}
                                    title={"Confirmation"}
                                    message={
                                      "Are you sure you want to delete this record?"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="card m565">
                          <div class="card-header"> <div class="card-title">  {this.state.actions === "add"
                                    ? "Add"
                                    : "Edit"}{" "}
                                  / Search Disease</div> </div>
                            <div className="card-body">
                              <div>
                                <div className="user-notification pb-0">
                                  <SpanField
                                    classname={
                                      "0" === formSuccess.status
                                        ? "text-danger user-notification"
                                        : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                  />
                                </div>
                              </div>
                              <div>
                               

                                <div className="user-notification">
                                  {searchErrorMessage ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {searchErrorMessage}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="row">

                                  <div className="col-sm-6">
                                    <div className="frllftfull">
                                      <div className="form-group frllft mbbre">
                                       <Label title="Disease ID" isMandatory={true} />
                                      </div>
                                      <div className="form-group frlrit">
                                        <div className="position-relative">
                                          <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                            /> */}

                                            <InputField
                                              inputType={"text"}
                                              name={"disease_id"}
                                              controlFunc={this.handleInput}
                                              content={diseaseData.disease_id}
                                              placeholder={""}
                                              max={5}
                                            />
                                          </div>
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.disease_id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-6">
                                    <div className="frllftfull">
                                      <div className="form-group frllft mbbre">
                                        <Label title=" Disease Name" isMandatory={true} />
                                      </div>
                                      <div className="form-group frlrit ">
                                        <div className="position-relative ">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                             
                                            </div>
                                          
                                            {/* <Select 
                                              maxMenuHeight={120}
                                              className="form-control  p-0"
                                              name="disease_name"
                                              value={diseaseData.disease_name}
                                              onChange={this.handleselectChange}
                                              options={masterDiseases}
                                              isClearable={true}
                                            /> */}
                                             <InputField
                                            inputType={"text"}
                                            isMandatory={true}
                                            name={"disease_name"}
                                            controlFunc={this.handleInput}
                                            content={diseaseData.disease_name}
                                            placeholder={""}
                                            max={30}
                                          />
                                          </div>
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.disease_name}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                  </div>

                                  <div className="col-sm-6-1 float-left">
                                    <div className="frllftfull ">
                                      <div className="form-group frllft mbbre">
                                       <Label title=" Disease Category" isMandatory={true} />
                                      </div>
                                      <div className="form-group sform-control frlrit">
                                        <div className="position-relative ">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text inpico">
                                                <i className="fas fa-search"></i>
                                              </span>
                                            </div>
                                            <Select
                                              maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="disease_category"
                                              value={
                                                diseaseData.disease_category
                                              }
                                              isClearable={true}
                                              onChange={this.handleselectChange}
                                              options={this.state.diseaseCategorylist}
                                            />
                                          </div>
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.disease_category}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              
                                <div className="row mg-top2">

                                
                                  <div className="col-sm-12 text-right">
                                    
                                    <button
                                      href="patients-register"
                                      className="signupbtn abtn mr-2 phone-button"
                                      // className="signupbtn abtn btdpml mr-2"
                                      onClick={this.UpdateDisease}
                                    >
                                      {this.state.actions === "add"
                                        ? "Add"
                                        : "Update"}
                                    </button>
                                    <button
                                      className="signupbtn abtn mr-2 phone-button"
                                      disabled={this.state.actions=="edit"?true:false}
                                      onClick={this.searchandshowDisease.bind(
                                        this
                                      )}
                                    >
                                      Search
                                    </button>
                                    <button
                                        className="signupbtn abtn phone-button"
                                        // className="signupbtn abtn adbt"
                                        onClick={this.clearAll.bind(this)}
                                      >
                                        Reset
                                      </button>
                                  </div>
                                  
                                
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-sm-6 ">
                          <div className="card b-10-hite">
                            <div className="card-body">
                              <div>
                                <h5 className="regxs">Search</h5>
                                <hr className="rowline" />

                                <div className="user-notification">
                                  {searchErrorMessage ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {searchErrorMessage}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div> */}
                        {/* <div className="row">
                                  <div className="col-sm-10">
                                    <div className="frllftfull">
                                      <div className="form-group frllft mbbre">
                                        <label className="ffgrd">Disease ID</label>
                                      </div>
                                      <div className="form-group frlrit">
                                        <div className="position-relative">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text inpico">
                                                <i className="fas fa-search"></i>
                                              </span>
                                            </div>
                                            <InputField
                                              inputType={"text"}
                                              name={"disease_id"}
                                              controlFunc={
                                                this.handlesearchInput
                                              }
                                              content={searchData.disease_id}
                                              placeholder={""}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                        {/*                                 
                                <div className="row">
                                  <div className="col-sm-10">
                                    <div className="frllftfull">
                                      <div className="form-group frllft mbbre">
                                        <label className="ffgrd">
                                          Disease Name
                                        </label>
                                      </div>
                                      <div className="form-group sform-control frlrit">
                                        <div className="position-relative">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text inpico">
                                                <i className="fas fa-search"></i>
                                              </span>
                                            </div>
                                            <Select maxMenuHeight={120}
                                              maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="disease_name"
                                              isClearable={true}
                                              value={searchData.disease_name}
                                              onChange={
                                                this.handlesearchselectChange
                                              }
                                              options={masterDiseases}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-sm-10">
                                    <div className="frllftfull">
                                      <div className="form-group frllft mbbre">
                                        <label className="ffgrd">
                                          Disease Category
                                        </label>
                                      </div>
                                      <div className="form-group sform-control frlrit">
                                        <div className="position-relative">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text inpico">
                                                <i className="fas fa-search"></i>
                                              </span>
                                            </div>
                                            <Select maxMenuHeight={120}
                                              maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="disease_category"
                                              value={
                                                searchData.disease_category
                                              }
                                              isClearable={true}
                                              onChange={
                                                this.handlesearchselectChange
                                              }
                                              options={diseaseCategory}
                                            />
                                          </div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ marginTop: "60px" }}
                                        >
                                          <div className="col-sm-4"></div>
                                          <div className="col-sm-3">
                                            <div className="form-group mb-0">
                                              <button
                                                className="signupbtn abtn btdpml"
                                                onClick={this.searchandshowDisease.bind(
                                                  this
                                                )}
                                              >
                                                Search
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                        {/* </div>
                          </div>
                        </div> */}

                      </div>

                      <div
                        style={{
                          display: this.state.search_action ? "block" : "none",
                        }}
                      >
                
                        <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                          <div class="card-header"> <div class="card-title">  Search Result</div> </div>
                            <div className="card-body ">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        "0" === searchformSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={searchformSuccess.message}
                                    />
                                  </div>

                                  <ReactDatatable
                                    className="table table-bordered table-striped kas  mobile_table"
                                    config={this.searchconfig}
                                    records={this.state.searchrecords}
                                    columns={this.searchcolumns}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        
                      </div>

                      {/* <Footerad /> */}
                    </div>

                  </div>
                </div>

          
              </section>
           )}
    <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Diseases);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
