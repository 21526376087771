import React, { Component } from "react";
import { connect } from "react-redux";
import { CONFIG } from "../../../../config/data";
import axios from "axios";

import * as moment from "moment";
import { Line } from "react-chartjs-2";

import {
  getAllQueueReport,
  getAllReviewsReport,
  updateQueueManagement,
  getBusinessforBusinessUser,
  getCharturl,
  doctorUpcomingappointment,
  getTimeoutbycategory,
  getvideopathbyguidevideo,
  getAllAppointmentReport,
  getscheduletypeforbusinessuser,
  getbasicscheduletypeinfoforbusinessuser
} from "../../../../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../modal/IdleModal'


function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
};
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout=this.state;
  //  const videopath=this.state;
    
    this.state = {
      videopath:"",
       timeout:timeout,
      isTimedOut: false,
      showModal: false,
      queue_report_today: [],
      queue_report_yesterday: [],
      appointment_report_today: [],
      appointment_report_yesterday: [],
      reviews_data: [],
      doctorupcoming: [],
      chart_data: [],
      queue_data: {
        doctor_arrived: "Yes",
        hospital_opened: "Yes",
        hospital_opened_data: "No",
        doctor_arrived_data: "No",
        schedule_type: "",
        cycle_time: "",
        cycle_time_break: { label: "min", value: "min" },
        reschedule_time: "",
        reschedule_time_break: { label: "min", value: "min" },
        hospital_open_date: moment().format("YYYY-MM-DD"),
        hospital_open_time: moment().format("HH:mm"),
        doctor_arrival_date: moment().format("YYYY-MM-DD"),
        doctor_arrival_time: moment().format("HH:mm"),
      },
      errors: {},
      formSuccess: {
        message: "",
        status: "",
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
   
   // console.log(this.idleTimer);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
  if(isTimedOut===true)
  {
    localStorage.removeItem("token");
    this.props.logout();
    
  }
  }
  
  handleInput = (e) => {
    let data = this.state.queue_data;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "cycle_time" ||
      name === "reschedule_time"
      // || name === "email" ||
      // name === "website"
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ queue_data: data });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.queue_data;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      // const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ queue_data: data, errors: errors });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.queue_data;
    data[e.name] = selectedOptions;

    this.setState({ queue_data: data });
  };
  handleSechduleType = (e) => {
    let data = this.state.queue_data;
    data.schedule_type = e.target.value;
    this.setState({ queue_data: data });
  };
  componentDidMount = () => {
   
    console.log(this.props.userdata.user_type);
    
    axios
    .post(getscheduletypeforbusinessuser, { business_user_id: this.props.userdata.id}, CONFIG)
    .then((res) => {
      let data = res.data;
      console.log(res);
      console.log(data);
      console.log(data.data[0].schedule_type);
      if (data.status === "1")
        this.setState({
          schedule_type: data.data[0].schedule_type
          
        });
      })
    .catch((e) => { });
    axios
    .post(getbasicscheduletypeinfoforbusinessuser, { business_user_id: this.props.userdata.id}, CONFIG)
    .then((res) => {
      let data = res.data;
      console.log(res);
      console.log(data);
      console.log(data.data[0].schedule_type);
      if (data.status === "1")
        this.setState({
          basicscheduletypefordoctor: data.data[0].schedule_type
          
        });
      })
    .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getvideopathbyguidevideo, {name : "guide_video"}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.value);
        console.log(data.status);
      //  let videopath=this.state.videopath;
        if (data.status === "1")
        
          this.setState({
            videopath: data.data.value
            
          });
          var myVid = document.getElementById('vidObj');
          myVid.setAttribute("src", data.data.value);
          myVid.play()
        //  console.log(videopath);
          console.log(this.state.videopath);
        })
      .catch((e) => { });


    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            timeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

     

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    let urlbu =
      getBusinessforBusinessUser +
      "?business_user_id=" +
      this.props.userdata.id;
    axios
      .get(urlbu, null, CONFIG)
      .then((res) => {
        let data = res.data.data;

        let queue_data = this.state.queue_data;

        if (data.status !== "0") {
          // console.log(data.data[0])
          queue_data.business_user_id = data[0].business_user_id;
          queue_data.business_id = data[0].business_id;

          queue_data.cycle_time = data[0].cycle_time;

          this.setState({
            queue_data: queue_data,
          });
          this.getdata(this.state.queue_data.business_id);
          this.getAppointmentdata(this.state.queue_data.business_id);
        } else {
          queue_data.business_user_id = "";
          queue_data.business_id = "";
          queue_data.cycle_time = "00:00:00";
          //   queue_data.business_id = data.data.business_id;
          this.setState({
            queue_data,
          });
          //   this.setState({
          // queue_report_today: [],
          // queue_report_yesterday: [],
          //   });
        }
      })
      .catch((e) => { console.log(e); });




    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(doctorUpcomingappointment, { business_user_id: this.props.userdata.id }, CONFIG)
      .then((result) => {
        console.log(result);
        if (result.data.status === "1") {
          console.log(result.data.data);
          this.setState({ doctorupcoming: result.data.data })
        } else {
          this.setState({ doctorupcoming: [] })
        }
      }).catch((err) => {
        console.log('error')
      })
  };
  getdata = (business_id) => {
    console.log(business_id)
    console.log('business_id')
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    // let business_id = this.state.queue_data.business_id;
    let url = getAllQueueReport + "?business_id=" + business_id + "&business_user_id=" + this.props.userdata.id;
    axios
      .get(url, null, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status !== "0")
          this.setState({
            queue_report_today: data.data.today,
            queue_report_yesterday: data.data.yesterday,
          });
        else {
          this.setState({
            queue_report_today: [],
            queue_report_yesterday: [],
          });
        }
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let getAllReviewsReporturl =
      getAllReviewsReport + "?business_id=" + business_id;
    axios
      .get(getAllReviewsReporturl, null, CONFIG)
      .then((result) => {
        console.log(result);

        if (result.data.status === "1") {
          this.setState({ reviews_data: result.data.data[0] });
        } else {
          this.setState({ reviews_data: [] });
        }
      })
      .catch((e) => {
        console.log(e.data);
        console.log("business user getbyid failed");
      });
    // get graph data
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let getCharturldata = getCharturl + "?business_id=" + business_id;
    axios
      .get(getCharturldata, null, CONFIG)
      .then((result) => {
        console.log("result");
        console.log(result);

        if (result.data.status === "1") {

          let chart_data = {
            labels: Object.keys(result.data.data),
            datasets: [
              {
                label: "Check-In",
                backgroundColor: "rgba(41,184,246,0.4)",
                borderColor: "rgba(41,184,246,1)",
                borderWidth: 2,
                data: Object.values(result.data.data),
              },
            ],
          };
          this.setState({ chart_data: chart_data });
        } else {
          // this.setState({ chart_data: [] });
        }
      })
      .catch((e) => {
        console.log("business user getbyid failed");
      });
  }
  getAppointmentdata = (business_id) => {
    console.log(business_id)
    console.log('business_id')
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    // let business_id = this.state.queue_data.business_id;
    let url = getAllAppointmentReport + "?business_id=" + business_id + "&business_user_id=" + this.props.userdata.id;
    axios
      .get(url, null, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status !== "0")
          this.setState({
            appointment_report_today: data.data.today,
            appointment_report_yesterday: data.data.yesterday,
          });
        else {
          this.setState({
            appointment_report_today: [],
            appointment_report_yesterday: [],
          });
        }
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let getAllReviewsReporturl =
      getAllReviewsReport + "?business_id=" + business_id;
    axios
      .get(getAllReviewsReporturl, null, CONFIG)
      .then((result) => {
        console.log(result);

        if (result.data.status === "1") {
          this.setState({ reviews_data: result.data.data[0] });
        } else {
          this.setState({ reviews_data: [] });
        }
      })
      .catch((e) => {
        console.log(e.data);
        console.log("business user getbyid failed");
      });
    // get graph data
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let getCharturldata = getCharturl + "?business_id=" + business_id;
    axios
      .get(getCharturldata, null, CONFIG)
      .then((result) => {
        console.log("result");
        console.log(result);

        if (result.data.status === "1") {

          let chart_data = {
            labels: Object.keys(result.data.data),
            datasets: [
              {
                label: "Check-In",
                backgroundColor: "rgba(41,184,246,0.4)",
                borderColor: "rgba(41,184,246,1)",
                borderWidth: 2,
                data: Object.values(result.data.data),
              },
            ],
          };
          this.setState({ chart_data: chart_data });
        } else {
          // this.setState({ chart_data: [] });
        }
      })
      .catch((e) => {
        console.log("business user getbyid failed");
      });
  }
  handleToggle = (checkedData, obj) => {
    console.log(checkedData);
    console.log(obj.target.name);
    console.log(obj.target.value);
    //if value is off make it on & viceversa
    obj.target.value = obj.target.value === "on" ? "off" : "on";

    let queue_data = this.state.queue_data;
    // if obj is "on" make backend variable as Yes and viceversa. It has to behave like radio button
    queue_data[obj.target.name] = obj.target.value === "on" ? "Yes" : "No";
    queue_data[obj.target.name + "_data"] =
      obj.target.value === "on" ? "No" : "Yes";
    console.log(queue_data);
    this.setState({ queue_data });
  };
  updateQueueDetails = (event) => {
    event.preventDefault();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let queue_data = this.state.queue_data;
    let url = updateQueueManagement;
    let data = {
      business_id: queue_data.business_id,
      business_user_id: queue_data.business_user_id,
      cycle_time: queue_data.cycle_time,
      reschedule_time: "00:" + queue_data.reschedule_time + ":00",
      hospital_open_time: queue_data.hospital_open_time + ":00",
      doctor_arrival_time: queue_data.doctor_arrival_time + ":00",
      doctor_arrival_date: queue_data.doctor_arrival_date,
      schedule_type: queue_data.schedule_type,
    };
    axios
      .post(url, data, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status !== "0") {
          data.error_type = "success";
          this.setState({
            formSuccess: data,
          });
          this.setState({ loading: false });
          this.clearMessage();
        } else {
          data.error_type = "error";
          this.setState({
            formSuccess: data,
          });
          this.setState({ loading: false });
          this.clearMessage();
        }
      })
      .catch((e) => { });
  };
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
        }),
      5000
    );
  };
  render() {
    const {
      queue_report_yesterday,
      queue_report_today,
      appointment_report_today,
      appointment_report_yesterday,
      reviews_data,
      chart_data,
      timeout,
      videopath,
      schedule_type,
      basicscheduletypefordoctor
    } = this.state;
    
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;
    return (
      <div className="col-sm-12 pl0 checkout-tabs">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />
        <h4 className="font-weight-bold">Dashboard</h4>

        <div className="row queue-box mt-4">
          <div className="col-lg-7">
            <h5 className="hedtxt2">Today</h5>
            {schedule_type==="Queue"||basicscheduletypefordoctor==="Queue"?
            (<div className="row">

              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Queue-In</p>
                      <h4 className="font-weight-bolder mt-3">{queue_report_today.queuein}</h4>

                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-calendar-check-o font-22 icon-title" aria-hidden="true"></i>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Check-In</p>

                      <h4 className="font-weight-bolder mt-3">{queue_report_today.checkin}</h4>
                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-arrow-left icon-title" aria-hidden="true"></i>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Cancelled</p>
                      <h4 className="font-weight-bolder mt-3">{queue_report_today.cancelled}</h4>
                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-ban icon-title" aria-hidden="true"></i>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>):(<div className="row">

<div className="col-lg-4">
  <div className="card">
    <div className="card-body d-flex justify-content-between">
      <div className="">
        <p className="font-weight-bold">Scheduled</p>
        <h4 className="font-weight-bolder mt-3">{appointment_report_today.scheduled}</h4>

      </div>
      <div className="">
        <div className="iconbox rounded-circle">
          <i className="fa fa-calendar-check-o font-22 icon-title" aria-hidden="true"></i>
        </div>

      </div>
    </div>
  </div>
</div>
<div className="col-lg-4">
  <div className="card">
    <div className="card-body d-flex justify-content-between">
      <div className="">
        <p className="font-weight-bold">Check-In</p>

        <h4 className="font-weight-bolder mt-3">{appointment_report_today.checkin}</h4>
      </div>
      <div className="">
        <div className="iconbox rounded-circle">
          <i className="fa fa-arrow-left icon-title" aria-hidden="true"></i>
        </div>

      </div>
    </div>
  </div>
</div>

<div className="col-lg-4">
  <div className="card">
    <div className="card-body d-flex justify-content-between">
      <div className="">
        <p className="font-weight-bold">Cancelled</p>
        <h4 className="font-weight-bolder mt-3">{appointment_report_today.cancelled}</h4>
      </div>
      <div className="">
        <div className="iconbox rounded-circle">
          <i className="fa fa-ban icon-title" aria-hidden="true"></i>
        </div>
      </div>

    </div>
  </div>
</div>
</div>)}

          </div>
          <div className="col-lg-5">
            <h5 className="hedtxt2">Yesterday</h5>
            {schedule_type==='Queue'||schedule_type!=='appointment'?
            (<div className="row">
              <div className="col-lg-6">
                <div className="card bgg">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Consultation</p>
                      <h4 className="font-weight-bolder mt-3">{queue_report_yesterday.visits}</h4>
                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-calendar-check-o font-22 icon-title" aria-hidden="true"></i>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card bgg">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Cancelled</p>
                      <h4 className="font-weight-bolder mt-3">{queue_report_yesterday.cancelled}</h4>
                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-ban icon-title" aria-hidden="true"></i>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>):(<div className="row">
              <div className="col-lg-6">
                <div className="card bgg">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Consultation</p>
                      <h4 className="font-weight-bolder mt-3">{appointment_report_yesterday.visits}</h4>
                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-calendar-check-o font-22 icon-title" aria-hidden="true"></i>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card bgg">
                  <div className="card-body d-flex justify-content-between">
                    <div className="">
                      <p className="font-weight-bold">Cancelled</p>
                      <h4 className="font-weight-bolder mt-3">{appointment_report_yesterday.cancelled}</h4>
                    </div>
                    <div className="">
                      <div className="iconbox rounded-circle">
                        <i className="fa fa-ban icon-title" aria-hidden="true"></i>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>)}

          </div>
        </div>
        <div className="row">

          <div className="col-sm-4 pl0 pr0 hide">
            <div className="card">
              <div className="card-body pd-10 min-hitap-1">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <h5 className="fnsz hptd">Messages </h5>
                    <table className="messagesc5">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-comments"></i>
                          </td>
                          <td>
                            <a href="/#">
                              <p>You have 14 new messages</p>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-comments-o"></i>
                          </td>
                          <td>
                            <p>
                              <a href="/#">256 read messages</a>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body pd-10 min-hitap-1">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <h5 className="fnsz hptd">Approvals </h5>
                    <table className="messagesc5">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-envelope"></i>
                          </td>
                          <td>
                            <a href="/#">
                              <p>15 new medicines waiting for approval</p>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-envelope-open"></i>
                          </td>
                          <td>
                            <p>
                              <a href="/#">6 Approved appointments</a>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="col-sm-4">
            <div className="card">
            <div class="card-header"> <div class="card-title">Total patient visited last week</div> </div>
              <div className="card-body m313">
                <div className="tab-content" id="v-pills-tabContent">
                 



                  <Line data={chart_data} />
                </div>

              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card">
            <div class="card-header"> <div class="card-title">Reviews</div> </div>
              <div className="card-body m313">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
             
                    <table className="messagesc5">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-thumbs-o-up"></i>
                          </td>
                          <td>
                            <a href="/profile">
                              <p>{reviews_data.reviewLike} reviews</p>
                            </a>
                          </td>
                        </tr>
                        {/* <tr>
                              <td>
                                <i className="fa fa-thumbs-o-down"></i>
                              </td>
                              <td>
                                <p>
                                  <a href="#">27 reviews</a>
                                </p>
                              </td>
                            </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card">
                  <div className="card-body pd-10 min-hitap-1">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab"
                      >
                        <h5 className="fnsz hptd">Facility status </h5>
                        <table className="messagesc5 wid100">
                          <tbody>
                            <tr>
                              <td>
                                <a href="#">Total room's </a>
                              </td>
                              <td>
                                <a href="#">
                                  <p>10</p>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="#">Occupied</a>{" "}
                              </td>
                              <td>
                                <a href="#">
                                  <p>6</p>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="#">Availability </a>{" "}
                              </td>
                              <td>
                                <a href="#">
                                  <p>4</p>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
          </div>

          <div className="col-sm-4">
            <div className="card">
            <div class="card-header"> <div class="card-title">Notifications</div> </div>
              <div className="card-body m313">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                  
                    <table className="messagesc5">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-calendar-check-o"></i>
                          </td>
                          <td>
                            <a href="/consult">
                              <p>Upcoming appointment</p>
                            </a>
                          </td>
                        </tr>

                        {/* <tr>
                          <td>
                            <i className="fa fa-calendar"></i>
                          </td>
                          <td>
                            <p>
                              <a href="#">Calender</a>
                            </p>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    {this.state.doctorupcoming.length ? (
                      <table className="appttable">
                        <tr>
                          <th>Appointment time</th>
                          <th>Patient name</th>
                          <th>Meeting</th>
                        </tr>
                        <tbody>
                          {this.state.doctorupcoming.length ? this.state.doctorupcoming.map(
                            (data, i) => {
                              return (<tr key={i}>
                                <td>
                                  {/* <a target="_blank" href={"https://dqqpa02ik3.execute-api.ap-south-1.amazonaws.com/Prod?m=" + data.client_appt_id + '&name=' + this.props.userdata.first_name + '&type=d'}
                              //onClick={() => this.gotoappointment(data)}
                              >{moment(data.appt_time).format('MMM-DD hh:mm a')}</a> */}
                                  <div>{moment(data.appt_time).format('MMM-DD hh:mm a')}</div>
                                </td>
                                <td>
                                  {data.patient_name}
                                </td>
                                <td>
                                  <a target="_blank" rel="noopener noreferrer" href={"https://dqqpa02ik3.execute-api.ap-south-1.amazonaws.com/Prod?m=" + data.client_appt_id + '&name=' + this.props.userdata.first_name + '&type=d'}
                                  onClick={() => this.gotoappointment(data)}
                                  >Join</a>
                                </td>
                              </tr>)
                            }) : ""}
                        </tbody>
                      </table>) : ""}



                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card">
                  <div className="card-body pd-10 min-hitap-1">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-shipping"
                        role="tabpanel"
                        aria-labelledby="v-pills-shipping-tab"
                      >
                        <h5 className="fnsz hptd">Medicine stock</h5>
                        <table className="messagesc5 wid100">
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <a href="#">Injection vials</a>{" "}
                              </td>
                              <td>
                                <a href="#">
                                  <p>25</p>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <a href="#">Ointment</a>{" "}
                              </td>
                              <td>
                                <a href="#">
                                  <p>8</p>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <a href="#">Tablets</a>{" "}
                              </td>
                              <td>
                                <a href="#">
                                  <p>45</p>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
        <div className="row">

          <div className="col-sm-4 ">
            <div className="card ">
              <div className="card-body m313">
                <div className="text-center">
                {/* <ReactVideo id="vidObj"
                src=""
                primaryColor="red"
                autoPlay={true}
                // other props
            /> */}
          {/*   <video id="vidObj" width="100%" controls loop muted autoplay>
                    <source src="" type="video/mp4" />
                </video> */}
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade show active">


                    {/* <div className="row dashboard-doctorlist">
                      <div className="col-sm-12 topgapess"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-4 ">
            <div className="card ">
              <div className="card-body m313">
               {/*  <div className="text-center">
                  <h5 className="fnsz hptd">Dummy2</h5>
                </div> */}
                <div className="tab-content">
                  <div className="tab-pane fade show active">


                    <div className="row ">
                      <div className="col-sm-12 topgapess"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-4 ">
            <div className="card ">
              <div className="card-body m313">
                {/* <div className="text-center">
                  <h5 className="fnsz hptd">Dummy3</h5>
                </div> */}
                <div className="tab-content">
                  <div className="tab-pane fade show active">


                    <div className="row ">
                      <div className="col-sm-12 topgapess"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>








        <div className="row">
          <div className="col-sm-4 pl0 hide">
            <div className="card">
              <div className="card-body pd-10 min-hitap-1">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <h5 className="fnsz hptd">Statement </h5>
                    <table className="messagesc5">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-file-text-o"></i>
                          </td>
                          <td>
                            <a href="#">
                              <p>You have 3 new statements</p>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-download"></i>
                          </td>
                          <td>
                            <p>
                              <a href="#">
                                Download previous month statements
                              </a>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body pd-10  min-hitap-1">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <h5 className="fnsz hptd">Recent news </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <Footerad /> */}
      </div >
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
