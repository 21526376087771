import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/client/Header";
import Spinner from "../components/UI/Spinner";
import SpanField from "../components/UI/SpanField";
import Select from "react-select";

import axios from "axios";
import { CONFIG } from "../config/data";
import { getPrescription, getPrescriptionHistoryforAppt, getTimeoutbycategory,getClientEntireFamilyDetails } from "../config/url.json";
import * as moment from "moment";
import DatePicker from "react-date-picker";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


class ClientApptPrescription extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout = this.state;

    this.state = {
      timeout: timeout,
      isTimedOut: false,
      showModal: false,

      show_prescriptions: true,
      loading: false,
      records: [],
      filter: "",
      errors: {
        Todate: "",
        Fromdate: "",
      },
      result: [],
      selectedOption: ["0"],
      FamilyData: {
        member_name: "",
        user_id:"",
      },
      error: "",
    };
    this.columns = [
      {
        key: "medicine_description",
        text: "Medicine Name",
        className: "medicine_description",
        align: "left",
        sortable: true,
      },
      {
        key: "days",
        text: "Days",
        className: "days",
        align: "left",
        sortable: true,
      },
      {
        key: "before_food",
        text: "Before Food",
        className: "before_food",
        align: "left",
        sortable: true,
        cell: (record, i) => {
          return record.before_food === 1 ? (
            <div className="text-center">
              <i className="fa fa-check-circle grnbk" aria-hidden="true" />{" "}
            </div>
          ) : (
            ""
          );
        },
      },
      {
        key: "morning",
        text: "Morning",
        className: "morning",
        sortable: true,
        align: "left",
      },
      {
        key: "noon",
        text: "Noon",
        className: "noon",
        sortable: true,
        align: "left",
      },
      {
        key: "night",
        text: "Night",
        className: "night",
        sortable: true,
        align: "left",
      },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

   
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
   
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);

    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  } 
 }

  componentDidMount() {

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

      
    axios
    .post(getClientEntireFamilyDetails,{client_id: this.props.userdata.id}, CONFIG)
    .then((FamilyDataRespons) => {
      let FamilyDataList = FamilyDataRespons.data.data;
      let FamilymemberList = [];
      FamilyDataList.forEach((element, index) => {
          FamilymemberList.push({
          value: element.user_id,
          label: element.first_name + ' ' + element.last_name,
       
        });
      });
      let selected_familymember=FamilymemberList[0];
      this.setState({ FamilymemberList,selected_familymember:selected_familymember });
  
    
    }).catch((e) => { });
  

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            timeout: data.data.timeout

          });
      })
      .catch((e) => { });
      this.setState({ loading: true, filter: "month" });
   // this.getReport('month');

   let postData1 = { client_id: this.props.userdata.id, filter:"month"};
   // }
     
     axios
       .post(getPrescriptionHistoryforAppt, postData1, CONFIG)
       .then((res) => {
         let data = res.data.consultantHistory || res.data.consultantHistory;
         console.log(data);
         console.log(res.data);
         // if (res.data.status !== "1" || !data || !data[0]) {
         //   return;
         // }
         if (res.data.status === "1") {
           this.setState({
             result: data,
 
             records: (data.length && data.medicine_description != null)
               ? data.medicine_description
               : [],
             // show_prescriptions: data.length ? data.show_prescriptions : true,
             show_prescriptions: true,
             selectedOption:[],
             loading: false
 
           });
           
         } else {
           this.setState({ error: res.data.message, loading: false });
         }
       })
       .catch((e) => { });
  }
  handleselectChange = (element, i) => {
  /*   let data = this.state.FamilyData;
        data[i.name] = element;
        this.setState({ FamilyData: data }); */
        let newdata = this.state.selected_familymember;
        newdata = element;
    
        this.setState({ selected_familymember: newdata }); 
    this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
      
        let postData1 = { client_id: element.value, filter: this.state.filter, start_date: this.state.Fromdate, end_date: this.state.Todate };
       
        axios
            .post(getPrescriptionHistoryforAppt, postData1, CONFIG)
            .then((res) => {
                let data = res.data.consultantHistory || res.data.consultantHistory;
                console.log(data);
                console.log(res.data);
                if (res.data.status === "1") {
                    this.setState({
                        result: data,

                        records: (data.length>0) ? data : [],
                        records: (data.length && data.medicine_description != null)
                         ? data.medicine_description
                         : [],
                        show_prescriptions: true,
                        selectedOption: [],
                        loading: false

                    });
                } else {
                    this.setState({ error: res.data.message, loading: false });
                }
            })
            .catch((e) => { });
    
 //   this.setState({ selected_familymember }); 
  }
  refreshData = () => {
    const FamilyData = this.state.FamilyData;
    FamilyData.member_name="";
    this.setState({ FamilyData: FamilyData });
   
    this.setState({ loading: true, filter: "month" });

    this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
      
        let postData1 = { client_id: this.props.userdata.id, filter: 'month', start_date: this.state.Fromdate, end_date: this.state.Todate };
       
        axios
            .post(getPrescriptionHistoryforAppt, postData1, CONFIG)
            .then((res) => {
                let data = res.data.consultantHistory || res.data.consultantHistory;
                console.log(data);
                console.log(res.data);
                if (res.data.status === "1") {
                    this.setState({
                        result: data,

                        records: (data.length>0) ? data : [],
                        records: (data.length && data.medicine_description != null)
                         ? data.medicine_description
                         : [],
                        show_prescriptions: true,
                        selectedOption: [],
                        loading: false

                    });
                } else {
                    this.setState({ error: res.data.message, loading: false });
                }
            })
            .catch((e) => { });
   
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.props.access_token}`;
        
              
            axios
            .post(getClientEntireFamilyDetails,{client_id: this.props.userdata.id}, CONFIG)
            .then((FamilyDataRespons) => {
              let FamilyDataList = FamilyDataRespons.data.data;
              let FamilymemberList = [];
              FamilyDataList.forEach((element, index) => {
                  FamilymemberList.push({
                  value: element.user_id,
                  label: element.first_name + ' ' + element.last_name,
               
                });
               
              });
    this.setState({ selected_familymember: FamilymemberList[0]});
            })
  };

  clear=()=>
    {
        this.state.Fromdate="";
        this.state.Todate="";
       this.setState({result:[]});
    }
  handlePrescriptionChange = ( index, event) => {
    
    console.log(index);
    // console.log(event);
    // console.log(event.target.checked);
    let {

      // selectedOption,
      result,
      show_prescriptions,
      records,
    } = this.state;
  
      records = result[index].medicine_description;
      console.log(records)
      show_prescriptions = result[index].show_prescriptions;
   

    
    console.log(records);
    this.setState({
      selectedOption: event.target.checked ? [index] : [],
      records: records ? records : [],
      show_prescriptions: show_prescriptions
    });
  };
  handlefilterSelect = (e) => {
    const { name, value } = e.target;
    this.state.Fromdate="";
    this.state.Todate="";
    this.setState({
      [name]: value
    });
console.log(value);
    this.getReport(value,"","");
  }
  handleFromChange = (date) => {
    
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    this.setState({ Fromdate: offsetDate });
    
    this.getReport("custom",offsetDate,this.state.Todate);
  };
  handleToChange = (date) => {
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    this.setState({ Todate: offsetDate });
    
    this.getReport("custom",this.state.Fromdate,offsetDate);
  };

  getReport = (filter,from,to) => {
    console.log(filter);
    this.setState({loading: true});
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    // let {Todate,Fromdate} = this.state;
    console.log(from)
    console.log(to)
  // if(filter == 'custom' && Todate != null && Fromdate != null ){
    // postData1 = { client_id: this.props.userdata.id, filter: "month" };
  // } else {
    let postData1 = { client_id: this.state.selected_familymember.value, filter: filter,start_date:from,end_date:to };
  // }
    
    axios
      .post(getPrescriptionHistoryforAppt, postData1, CONFIG)
      .then((res) => {
        let data = res.data.consultantHistory || res.data.consultantHistory;
        console.log(data);
        console.log(res.data);
        // if (res.data.status !== "1" || !data || !data[0]) {
        //   return;
        // }
        if (res.data.status === "1") {
          this.setState({
            result: data,

            records: (data.length && data.medicine_description != null)
              ? data.medicine_description
              : [],
            // show_prescriptions: data.length ? data.show_prescriptions : true,
            show_prescriptions: true,
            selectedOption:[],
            loading: false

          });
          
        } else {
          this.setState({ error: res.data.message, loading: false });
        }
      })
      .catch((e) => { });
  }

  render() {
    const {
      loading,
      errors,
      selectedOption,
      show_prescriptions,
      error,
      result,
      timeout,
      family_data,
      FamilymemberList,
      FamilyData,
      selected_familymember,
    } = this.state;
    // console.log(with_in_week.length);
    return (
      <div id="layout-wrapper">
       {/*  <Spinner loading={loading} /> */}
       {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <SideBar />
              </div>

              <section className="main-body hospital-profilegap">
                <IdleTimeOutModal
                  showModal={this.state.showModal}
                  handleClose={this.handleClose}
                  handleLogout={this.handleLogout}
                />

                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={timeout} />

                <div className="container-fluid">
                
                  <div className="checkout-tabs">
                    <div className="row">
                      <div className="col-sm-12">
                        <h4 className="font-weight-bold">Prescription</h4>
                        
                        <div className="row mt-4">
                          {show_prescriptions ? (
                            <div className="col-sm-8 pr0">

                              <div>
                                <div className="card m444">
                                  <div className="card-body">
                                    {error ? (
                                      <span className="text-danger">{error}</span>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        className="tab-pane fade show active"
                                        id="v-pills-shipping"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-shipping-tab"
                                      >
                                        <div className="row d-flex justify-content-center">
                                          {/* <div className="col-sm-6"></div> */}
                                          <div className="col-sm-4 mg-btm1 mr-5">
                                          <Select maxMenuHeight={120} placeholder="Select Family Members..."
                                          className="form-control mt-2 float-right queuedropdown css-2b097c-container prescriptionWidth"
                                          name="selected_familymember"
                                         
                                          onChange={
                                          this.handleselectChange
                                            }
                                           value={selected_familymember}
                                           options={FamilymemberList}
                                          />
                                          </div>
                                        
                                  
                                          <div className="col-sm-12 reactDataTable">
                                            <ReactDatatable
                                              config={this.config}
                                              records={this.state.records}
                                              columns={this.columns}
                                              className="table table-bordered table-striped kas  mobile_table"
                                            />
                                          </div>
                                          {'\n'}
                                          <div className="col-sm-12">
                                  <button className="float-right btn btn-primary mt-3 " onClick={this.refreshData}>Refresh
                                  <i className="fa fa-refresh ml-1">
                                  </i></button>
    </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-8 pr0">

                              <div>
                                <div className="card m444">
                                  <div className="card-body ppb-6">
                                    {error ? (
                                      <span className="text-danger">{error}</span>
                                    ) : (
                                      ""
                                    )}
                                    To view this Prescription, Please Subscribe any of our plan <a href="/showsubscription" >Click here to subscribe</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-sm-4">

                            <div className="card m444">
                            <div class="card-header"> <div class="card-title">Selected period</div> </div>
                              <div className="card-body">
                               
                               
                                <div id="accordion">
                                  <div>

                                    <div
                                      id="s1"
                                      className="collapse show"
                                      data-parent="#accordion"
                                    >
                                      <div className="card-body  pt-0 p-0">
                                        <div className="row mb-3">
                                        <div className="col-lg-12">
                                        <div className="form-check fltx">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id="Monthly"
                                                name="filter"
                                                checked={this.state.filter === "month"}
                                                value="month"
                                                onChange={this.handlefilterSelect}

                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="Monthly"
                                              >
                                                Monthly
                                              </label>
                                            </div>

                                            <div className="form-check fltx">

                                              <input
                                                checked={this.state.filter === "custom"}
                                                className="form-check-input"
                                                type="radio"
                                                id="Custom"
                                                value="custom"
                                                name="filter"

                                                onChange={this.handlefilterSelect}


                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="Custom"
                                              >
                                                Custom
                                              </label>

                                            </div>
                                        </div>
                                     
                                        </div>
                                        {(this.state.filter === "custom") ?
                                          (<div className="row">
                                            <div className="col-lg-12">
                                              <div>
                                                <label>From Date</label>
                                              </div>
                                              <div className="form-group datepickerdiv">
                                                <DatePicker
                                                  className="form-control"
                                                  value={this.state.Fromdate}
                                                  format="dd-MM-yyyy"
                                                  onChange={(date) =>
                                                    this.handleFromChange(date)
                                                  }
                                                  placeholder={"Select the From date"}
                                                  yearPlaceholder='YYYY'
                                                  monthPlaceholder='MM'
                                                  dayPlaceholder='DD'
                                                />
                                              </div>
                                              <SpanField
                                                classname="text-danger"
                                                title={errors.Fromdate}
                                              />
                                            </div>

                                            <div className="col-lg-12">
                                              <div>
                                                <div>
                                                  <label>To Date</label>
                                                </div>
                                                <div className="form-group datepickerdiv">
                                                  <DatePicker
                                                    className="form-control"
                                                    value={this.state.Todate}
                                                    format="dd-MM-yyyy"
                                                    onChange={(date) =>
                                                      this.handleToChange(date)
                                                    }
                                                    placeholder={"Select the From date"}
                                                    yearPlaceholder='YYYY'
                                                    monthPlaceholder='MM'
                                                    dayPlaceholder='DD'
                                                  />
                                                </div>
                                                <SpanField
                                                  classname="text-danger"
                                                  title={errors.Todate}
                                                />
                                                   <button className=" btn btn-primary float-right" onClick={this.clear}>Clear
                                  <i className="fa ml-1"></i></button>
                                              </div>




                                            </div>

                                          </div>) : ""
                                        }
                                        <div className="row Prescriptionscrollnew">
                                          <div className="col-md-12">
                                          {result.length
                                            ? result.map((history, index) => {
                                              return (
                                                <div className="custom-control custom-checkbox notificatincls">
                                                  <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id={"with_in_week" + index}
                                                  checked={
                                                    selectedOption.indexOf(
                                                      index
                                                    ) > -1
                                                  }
                                                  onChange={this.handlePrescriptionChange.bind(
                                                    this,
                                                  
                                                    index
                                                  )}
                                                />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={
                                                      "with_in_week" + index
                                                    }
                                                  ><strong>
                                                    {moment(
                                                      history.created_at
                                                    ).format("DD MMM")}
                                                    -
                                                    {history.other_comments
                                                                                                            ? history
                                                                                                                .other_comments
                                                                                                            : ""}
                                                  </strong></label>
                                                
                                                </div>
                                              );
                                            })
                                            : (
                                              <div className="custom-control custom-checkbox notificatincls pl-0">No record found</div>
                                            ) }
                                          </div>
                                         
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <Footerad /> */}
                      </div>
                    </div>
                  </div>
                </div>



               

              </section>
              <FooterSection />
            </div>


          </div>
        </div>
      </div >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientApptPrescription);
