import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}


class Header extends Component {
    render() {
        return (
            <div>
                <div className='my-head'>
                    <div className="container-fluid">
                <div className='row'>
   <div className="col-sm-2 text-left pr0">
      <div className=" logocenter adminHeader ">
         <a href="#">
         {" "}
         <img
            src={require("../../../../assets/images/logo.jpg")}
            alt="Logo"
            />
         </a>
      </div>
   </div>
   </div>
</div>
                </div>
                
                 
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(Header);