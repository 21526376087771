import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import LogoSection from "../components/UI/LogoSection";
import FooterSection from "../components/UI/FooterSection";
import FooterAds from "../components/UI/FooterAds";
import InputField from "../components/UI/InputField";
import { resetpasswordClient } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function mapStateToProps(state) {
  return {};
}

const specialCharacterRegx = /^[A-Za-z0-9 ]+$/;
const upperCaseRegx = /^[A-Z0-9 ]+$/;
const lowerCaseRegx = /^[a-z0-9 ]+$/;
const isNumberRegx = /\d/;

class ForgetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      password_confirmation: "",
      email_phonenumber: "",
      errors: {},
      registered_detail: "",
      formSuccess: {
        status: "",
        message: ""
      },
      passValidity: {
        lowercase: false,
        uppercase: false,
        specialchar: false,
        number: false,
        maxlength: false,
      }

    };
  }
  handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    let errors = this.state.errors
    errors[name] = ""
    this.setState({ [name]: value, errors });

    let passValidity = this.state.passValidity;
    if (name === "password" || name === "password_confirmation") {
      if (value === "") {
        passValidity.maxlength = false;
        passValidity.number = false;
        passValidity.specialchar = false;
        passValidity.uppercase = false;
        passValidity.lowercase = false;
      } else {
        passValidity.maxlength = value.length >= 8 ? true : false;
        passValidity.number = isNumberRegx.test(value) ? true : false;
        passValidity.specialchar = specialCharacterRegx.test(value) ? false : true;
        passValidity.uppercase = upperCaseRegx.test(value) ? false : true;
        passValidity.lowercase = lowerCaseRegx.test(value) ? false : true;
        this.setState({ passValidity: passValidity });
        console.log(passValidity)
      }

    }

  };
  changePassword = (e) => {
    e.preventDefault();

    let errors = this.state.errors;

    const data = {
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      email_phonenumber: this.props.location.state.registered_detail,
      type: "changePassword",
    };

    errors = Validation(data, errors);
    this.setState({ errors: errors });


    if (errors.formIsValid) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios.post(resetpasswordClient, data, CONFIG).then((result) => {
        let resultData = result.data;
        if (resultData.status === 0) {
          this.setState({ error: resultData.message });
        } else {
          console.log(resultData);
          // alert("Password has been reset successfully");
          //    let formSuccess = {status:"1",message:"Password has been reset successfully."}
          let errors = {

          }
          this.setState({ errors })
          setTimeout(
            () =>
              window.location.href = "/",
            2000
          );
          toast.success("“You have successfully reset the password.” Please login! ", {
            className: "toast-success"

          });

        }

      })
    }


  };


  cancel = () => {
    document.location.href = "/";

  }
  componentDidMount = () => {
    //console.log(this.props.location.state)
    if (this.props.location.state === undefined) {
      // window.location.href = "/";
    } else {
      this.setState({ registered_detail: this.props.location.state.registered_detail })
    }
  };

  handlepassword = (e) => {

    $(".resettoggle-password").toggleClass("fa-eye fa-eye-slash");

    let input = $('#resetpassword');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  }
  handleresetpassword = (e) => {

    $(".confirmtoggle-password").toggleClass("fa-eye fa-eye-slash");

    let input = $('#confirmpassword');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  }
  render() {
    const {
      errors, formSuccess
    } = this.state;
    return (
      <div id="layout-wrapper">
        <div className="main-content grayback">
          <div className="mainpage">
            {/* <section className="my-head">
              <div className="container-fluid">
                <div className="row">
                  <LogoSection />
                </div>
              </div>
            </section> */}

            <section className="registerpage loginbg">
              <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />


              <div className="container">
                <div className="row">
                
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="logocenter registerLogo ml-0">
                        <a href="#">
                          <img alt="LOGO" src={require("../assets/images/loginlogo.png")} />
                        </a>
                      </div>

                    </div>
                    <h4 className="card-title text-left text_white">RESET PASSWORD</h4>
                    <div className="form-group">
                        <label className="text_white">Email / Phone </label>
                          <div className="form-control graylight">
                            <span>{this.state.registered_detail}</span>
                          </div>
                        </div>
                        <div className="form-group">
                        <label className="text_white">Enter your new password </label>
                          <div className="">
                            <InputField
                              isMandatory={true}
                              inputType={"password"}
                              name={"password"}
                              controlFunc={this.handleInput}
                              placeholder={""}
                              id="resetpassword"
                            />
                            <span toggle="#password-field" onClick={this.handlepassword} className="fa fa-fw fa-eye field-icon resettoggle-password"></span>
                            <SpanField
                              classname="text-danger"
                              title={errors.password}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                        <label className="text_white">Re-enter your new password</label>
                          <div className="">
                            <InputField
                              isMandatory={true}
                              inputType={"password"}
                              name={"password_confirmation"}
                              controlFunc={this.handleInput}
                              placeholder={""}
                              id="confirmpassword"
                            />
                            <span toggle="#password-field" onClick={this.handleresetpassword} className="fa fa-fw fa-eye field-icon confirmtoggle-password"></span>
                            <SpanField
                              classname="text-danger"
                              title={errors.password_confirmation || errors.matchPassword || errors.securePassword}
                            />
                            <div className="form-group  narssw text-left">
                              <SpanField
                                classname={
                                  0 === formSuccess.status
                                    ? "text-danger user-notification"
                                    : "text-success user-notification"
                                }
                                title={formSuccess.message}
                              />
                              <button
                                onClick={this.changePassword.bind(this)}
                                data-toggle="modal"
                                data-target=".bs-example-modal-center"
                                className="signupbtn abtn"
                              >
                                Submit
                              </button>
                              {" "}
                              <button
                                onClick={this.cancel}
                                data-toggle="modal"
                                data-target=".bs-example-modal-center"
                                className="signupbtn abtn"

                              >
                                Cancel
                              </button>

                            </div>
                          </div>
                        </div>
                        <ul className="listformat">
                          <li className={this.state.passValidity.lowercase ? "listValid" : "listInValid"}>
                            It contains at least one lowercase English
                            character.
                          </li>
                          <li className={this.state.passValidity.uppercase ? "listValid" : "listInValid"}>
                            It contains at least one uppercase English
                            character.
                          </li>
                          <li className={this.state.passValidity.specialchar ? "listValid" : "listInValid"}>It contains at least one special character. </li>
                          <li className={this.state.passValidity.maxlength ? "listValid" : "listInValid"}>Its length is at least 8.</li>
                          <li className={this.state.passValidity.number ? "listValid" : "listInValid"}>It contains at least one digit</li>
                        </ul>
                  </div>
                </div>
              </div>

            </section>
          </div>

        </div>
        {/* <FooterAds />
          <FooterSection /> */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ForgetPassword);
