import React,{Component} from 'react';
import LogoSection from "../components/UI/LogoSection";
import PageNavigation from "../components/container/PageNavigation";
import FooterSection from "../components/UI/FooterSection";
import SpanField from '../components/UI/SpanField';
import Validation from '../actions/Validation';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { forgotPasswordClient } from '../config/url.json';
import {verifyOTPClient} from '../config/url.json';
import {resendOTPClient} from '../config/url.json';
import {resetpasswordClient} from '../config/url.json';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import Iframe from 'react-iframe';



class video extends Component {
    render() {
        return (
            <embed src="https://dqqpa02ik3.execute-api.ap-south-1.amazonaws.com/Prod/?meeting=9098878"
               width="400" 
               height="400" /> 

      
            );
        }
}
export default video;