import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/container/layout/client/Header";
import SideBar from "../components/container/layout/client/SideBar";

import { Modal } from "react-bootstrap";
import Spinner from "../components/UI/Spinner";
import TextArea from "../components/UI/TextArea";
import Select from "react-select";
import axios from "axios";
import { Popupinfo } from "../components/UI/Popupinfo";
import { getGivenDocAvailability, RazorPayPayment, getCredentials, updateclientappointmentstatus, completePayment, saveClientAppointment, getdoctordetails,getBusinessShortDetail,getClientShortDetail,addCheckinURL,getpaymenttypeforbusiness,sendemail,CancelQueue,getServiceChargesbycategory } from "../config/url.json";

import { CONFIG } from "../config/data";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import TextField from "@material-ui/core/TextField";
import {
  getShiftSymbol,
  getGivenDateStringFormat,
  getAvaliableCount,
  getDateISOFormat
} from "../constants/globalLevelFunctions";
import {
  VISIT_TYPE
} from "../../src/constants/config/data";


import * as moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from "../components/UI/Dnaspinner";
var converter = require("number-to-words");


function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
const Payment_mode = [{ label: "Direct", value: "direct" }, { label: "Online", value: "online" }]
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
function getTwentyFourHourTime(amPmString) { 
  
  var d = new Date("08/12/2022 " + amPmString); 
  return d.getHours() + ':' + d.getMinutes(); 
}
class ClientBookAppointments extends Component {
  constructor(props) {
    super(props);
    var time = new Date();
   
    this.state = {
      is_expired:false,
      loggedInuserId: props.userdata.id,
      access_token: props.access_token,
      other_comments:props.location.state.other_comments,
      other_comments_error:"",
      preferredDate: {
        from: "",
        to: "",
        limit: {
          label: "All Slot",
          value: "all",
        },
      },
      visit_type: VISIT_TYPE[0],
      payment_mode: "",
      reschedule: false,
      appointmentsData: [],
      appointmentsSourceData: [],
      business_name: "",
      business_id: "",
      business_user_id: "",
      client_id: "",
      client_name: "",
      currentPage: 1,
      displayDates: {
        form: "",
        to: ""
      },
      loading: false,
     bookModel : false,
      editModel: false,
      rebookModel: false,
      selectedHours: {},
      selectedDate: "",
      showNextBtn: true,
      showPrevBtn: true,
      is_sametime:false,
      cancelModel: false,
      rescheduleObj: {
        client_appt_id: "",
        appt_date: "",
        appt_time: "",
        appt_status: "",
        process: "",
        schedule_type: "",
        other_comments:""
      },
     currenttime: time.toLocaleString('en-US', { hour: 'numeric', hour12: true, hour: '2-digit',
      minute: '2-digit', }),
      
    };
  }

  componentDidMount = () => {
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getServiceChargesbycategory, {category : 'Direct'}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.service_charges);
        if (data.status == "1")
          this.setState({
            directservice_charges: data.data.service_charges
            
          });
        })
      .catch((e) => { });

      axios
      .post(getServiceChargesbycategory, {category : 'Online'}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.service_charges);
        if (data.status == "1")
          this.setState({
            onlineservice_charges: data.data.service_charges
            
          });
        })
      .catch((e) => { });

    var presenttime=getTwentyFourHourTime(this.state.currenttime);
console.log(presenttime);

    let data = {};
    data.startdate = getDateISOFormat(new Date());
    var enddate = new Date();
if(this.props.location.state.start_date != undefined){
  data.startdate = this.props.location.state.start_date;
  enddate = new Date(this.props.location.state.start_date);
}
    
    enddate.setDate(enddate.getDate() + 30);

    data.enddate = getDateISOFormat(enddate);

    //Setting Perfered date for search fields
    let preferredDate = this.state.preferredDate;
    preferredDate.from = data.startdate;
    preferredDate.to = data.enddate;
    this.setState({ preferredDate });
console.log(data);
console.log(this.props.location.state.start_date);
    data.business_id = this.props.location.state.business_id;
    data.business_user_id = this.props.location.state.business_user_id;
    data.client_id = this.props.location.state.client_details.client_id;
this.setState({
  other_comments: this.props.location.state.other_comments,
})
console.log(this.props.location.state.other_comments);
console.log(this.state.other_comments);
    axios
      .post(getdoctordetails, data, CONFIG)
      .then((res) => {
        let response = res.data;
        console.log(response);
        // return false;
        if (response.status !== "0") {
          // window.location.href = "/vendor/" + response.data;
          this.setState({ doctor_details: response.data });
        }

      })
      .catch((e) => {
        console.log(e);
      });
      axios
      .post(getBusinessShortDetail, data, CONFIG)
      .then((res) => {
        let response = res.data;
        console.log(response);
        // return false;
        if (response.status !== "0") {
          // window.location.href = "/vendor/" + response.data;
          this.setState({ hospital_details: response.data[0] });
        }

      })
      .catch((e) => {
        console.log(e);
      });
      axios
      .post(getClientShortDetail, data, CONFIG)
      .then((res) => {
        let response = res.data;
        console.log(response);
        console.log(response.data[0].email);
        // return false;
        if (response.status !== "0") {
          // window.location.href = "/vendor/" + response.data;
          this.setState({ client_personal_details: response.data[0] });
        }

      })
      .catch((e) => {
        console.log(e);
      });
    // let resultData = mockData;
    this.searchApppointment(data);
    axios
    .post(getpaymenttypeforbusiness, { user_id: this.props.location.state.business_id}, CONFIG)
    .then((res) => {
      let data = res.data;
      console.log(res);
      console.log(data);

      console.log(data.data[0].payment_type);
      if (data.status === "1")
        this.setState({
          paymenttypeforhospital: data.data[0].payment_type
          
        });
      })
    .catch((e) => { });

  };

  searchApppointment = (data) => {
    this.setState({loading:true});
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios.post(getGivenDocAvailability, data, CONFIG)
      .then((result) => {
        console.log(result);
        console.log(result.data.data);
        let apptdata=result.data.data;
        if(apptdata.length==0)
        {
          toast.error("No appointments for the doctor.", {
            className: "toast-error",
           });
        }
        console.log("result");

        this.setState({
          business_name: this.props.location.state.business_name,
          client_details: this.props.location.state.client_details,
          other_comments: this.props.location.state.other_comments,
          business_id: data.business_id,
          business_user_id: data.business_user_id,
          doc_name: this.props.location.state.doc_name,
          appointmentsSourceData: result.data.data,
          appointmentsData: result.data.data,
        }, () => {
          this.setState({ loading: false });
        });
        console.log(this.state.appointmentsData[0].appointmentTimings[0].appointmentHours[3].hours);
       var oldtime= getTwentyFourHourTime(this.state.appointmentsData[0].appointmentTimings[0].appointmentHours[3].hours);
       console.log(oldtime);
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  handleDOBChange = (e, element) => {
    console.log(e.target.value);
    console.log(element);
    let preferredDate = this.state.preferredDate;
    preferredDate[element] = e.target.value;
    this.setState({ preferredDate: preferredDate });
    console.log(this.state.preferredDate);
  };

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.preferredDate;
    data[e.name] = selectedOptions;
    this.setState({ preferredDate: data });
  };

  handleNext = (type, e) => {
    console.log(type);
    console.log(this.state.appointmentsData);
    let sourceData = this.state.appointmentsSourceData;
    let currentPage = this.state.currentPage;
console.log(new Date(sourceData[0].appointmentDate));
console.log(currentPage);
let preferredDate = this.state.preferredDate;

console.log(preferredDate);

  if(currentPage === 1){
  console.log('true');
  var startdate = moment(preferredDate.from);
  startdate = startdate.subtract(3, "days").format("YYYY-MM-DD");
  var enddate = moment(preferredDate.to);
  enddate = enddate.subtract(3, "days").format("YYYY-MM-DD");
console.log(startdate,enddate);
  let data = {};
    data.startdate = startdate;
    data.enddate = enddate;
    
    


    //Setting Perfered date for search fields
    preferredDate.from = data.startdate;
    preferredDate.to = data.enddate;
    this.setState({ preferredDate });

    data.business_id = this.props.location.state.business_id;
    data.business_user_id = this.props.location.state.business_user_id;
    data.client_id = this.props.location.state.client_details.client_id;
this.searchApppointment(data);



} else if(currentPage === 10) {
  console.log('false');
  var startdate = moment(preferredDate.from);
  startdate = startdate.subtract(3, "days").format("YYYY-MM-DD");
  var enddate = moment(preferredDate.to);
  enddate = enddate.subtract(3, "days").format("YYYY-MM-DD");
console.log(startdate,enddate);
  let data = {};
    data.startdate = startdate;
    data.enddate = enddate;
    
    


    //Setting Perfered date for search fields
    preferredDate.from = data.startdate;
    preferredDate.to = data.enddate;
    this.setState({ preferredDate });

    data.business_id = this.props.location.state.business_id;
    data.business_user_id = this.props.location.state.business_user_id;
    data.client_id = this.props.location.state.client_details.client_id;
this.searchApppointment(data);

}
    if ("next" === type) {
      if (sourceData.length > currentPage * 3) {
        let displayData = [];
        sourceData.map((data, i) => {
          if (i > (currentPage * 3) && i <= (currentPage * 3) + 3) {
            displayData.push(data);
          }
        });
        this.setState({
          appointmentsData: displayData,
          currentPage: currentPage + 1,
          showNextBtn: true
        });
      } else {
        console.log("no data");
        this.setState({ showNextBtn: false });
      }
    } else if (currentPage > 1) {
      let displayData = [];
      sourceData.map((data, i) => {
        if (i < (currentPage * 3) - 3 && i >= (currentPage * 3) - 6) {
          displayData.push(data);
        }
      });
      this.setState({
        appointmentsData: displayData,
        currentPage: currentPage - 1,
        showNextBtn: true,
      });
    }

  };

  searchBookAppointment = () => {

    //make sure reschduleobj is empty
    let rescheduleObj = this.state.rescheduleObj;
    rescheduleObj = {
      client_appt_id: "",
      appt_date: "",
      appt_time: "",
      appt_status: "",
      process: "",
      schedule_type: "",
      other_comments:""
    }


    this.setState({ loading: true, rescheduleObj });

    this.setState({ loading: true });
    let data = {};

    //Setting Perfered date for search fields
    let preferredDate = this.state.preferredDate;
    console.log(preferredDate);

    data.startdate = preferredDate.from;
    data.enddate = preferredDate.to;
    data.business_id = this.props.location.state.business_id;
    data.business_user_id = this.props.location.state.business_user_id;
    data.client_id = this.props.location.state.client_details.client_id;

    // let resultData = mockData;
    this.searchApppointment(data);
  }
  
  timeClicked = (data, date) => {
    console.log(data);
    console.log(date);
    console.log(data.hours);
 

    var date1 = moment(date).format("YYYY-MM-DD");
    var dt = moment((date1+" "+data.hours), ["YYYY-MM-DD h:mm A"]).format("YYYY-MM-DD HH:mm:ss");

    var current = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log(current);
    
// console.log(dt.valueOf())
//     var time = new Date();
  
//     var currenttime=time.toLocaleString( { hour: 'numeric', hour12: true, hour: '2-digit',minute: '2-digit' });
// console.log(currenttime);
//     var presenttime=getTwentyFourHourTime(currenttime);
//      console.log(presenttime);
      if(dt<=current) 
      {
        this.setState({ is_expired: true,addPopup: true });
      //  this.setState({ addPopup: true });
      }else
      {
        this.setState({ is_expired: false,addPopup: false });
        let schedule_type = this.state.visit_type;
        let reschedule = this.state.reschedule;
    
        let rescheduleObj = this.state.rescheduleObj;
    
        if (rescheduleObj.client_appt_id !== '') {
          console.log("rebooking to another date");
          console.log(this.state.selectedHours.hours);
          console.log(this.state.rescheduleObj.appt_time);
         
          this.setState({
            selectedHours: data,
            selectedDate: getGivenDateStringFormat(date), //moment(date).format('DD-MMM-YYYY')
            appointmentdate: moment(date).format('YYYY-MM-DD'),
            visit_type: schedule_type,
            reschedule: reschedule,
            other_comments:data.other_comments,
          
          }, () => {
            if(this.state.rescheduleObj.appt_time!==this.state.selectedHours.hours)
            {
              this.setState({ rebookModel: true });
            
             } 
             else{
              toast.error("Cannot rebook the appointment on the same time", {
                className: "toast-error",
               });
             }
            
          });
        
        } else {
          if (data.client_appt_id != '') {
            schedule_type = { label: data.schedule_type, value: data.schedule_type }
            reschedule = true;
            let rescheduleObj = this.state.rescheduleObj;
            rescheduleObj.client_appt_id = data.client_appt_id;
            rescheduleObj.appt_time = data.hours;
            rescheduleObj.appt_date = getGivenDateStringFormat(date);
            rescheduleObj.other_comments = data.other_comments;
            this.setState({
              rescheduleObj,
              other_comments:data.other_comments
            });
            console.log(this.state.rescheduleObj);
          } else {
            reschedule = false;
            this.setState({other_comments:this.props.location.state.other_comments});
          }
          this.setState({
            selectedHours: data,
            selectedDate: getGivenDateStringFormat(date), //moment(date).format('DD-MMM-YYYY')
            appointmentdate: moment(date).format('YYYY-MM-DD'),
            visit_type: schedule_type,
            reschedule: reschedule,
            // other_comments:this.state.other_comments
          }, () => {
            this.setState({ bookModel: true });
          });
        }

      }
  
  }

  closePopup = (e) => {
    console.log(this.state.bookModel)
    // console.log(this.state.editModel)
    console.log(this.state.rebookModel)

    this.setState({
      bookModel: false,
      editModel: false,
      rebookModel: false,
     

    });

  };
  cancelAppointment = (e) => {
    console.log('ohi')
   /*  let selectedHours = this.state.selectedHours;
    let data = { client_appt_id: selectedHours.client_appt_id, "process": "Cancel" }
    axios.post(saveClientAppointment, data).then((res) => {
      console.log(res);
      toast.error("You Cancelled your Appointment with the doctor. ", {
        className: "toast-error",
        });
      setTimeout(
        () =>
          (res.data.status) ?
            window.location.href = '/dashboard' : ""
        ,
        1000
      );
      this.setState({ reschedule: false, bookModel: false });

    }).catch((err) => {

    }) */
    this.setState({
      cancelModel: true,
  });
    
  }
  conformCancelAppointment = () => {
      let selectedHours = this.state.selectedHours;
      let doc_firstname=this.state.doctor_details.first_name;
    let doc_lastname=this.state.doctor_details.last_name;
    let data = { client_appt_id: selectedHours.client_appt_id, "process": "Cancel" }
    axios.post(saveClientAppointment, data).then((res) => {
      console.log(res);
      toast.error("Your Slot Appointment with the Dr. "+ doc_firstname + doc_lastname +" has been successfully canceled.", {
        className: "toast-error",
        });
      setTimeout(
        () =>
          (res.data.status) ?
            window.location.href = '/dashboard' : ""
        ,
        1000
      );
      this.setState({ reschedule: false, bookModel: false });

    }).catch((err) => {

    })
    
  };
  handleQueueInput = (e) => {
   this.setState({other_comments:e.target.value})

  }
  bookAppointment = (e) => {
   
    console.log(this.state.other_comments);
    // return false
    
    if(this.state.other_comments === "" || this.state.other_comments === undefined || this.state.other_comments === null) {
      this.setState({other_comments_error : "Please enter reason for visit."});
      return false;
    }
    this.setState({other_comments_error : ""});
    if((this.state.payment_mode===""|| this.state.payment_mode === undefined || this.state.payment_mode === null)&& (this.state.visit_type.value==="inperson"))
    {
      this.setState({payment_type_error : "Select the payment type."});
      return false;
    }
    this.setState({payment_type_error : ""});
    let {visit_type,payment_mode} = this.state;
    let data = {
      "client_id": this.state.client_details.client_id,
      "business_id": this.state.business_id,
      "business_user_id": this.state.business_user_id,
      "appt_date": this.state.appointmentdate,
      "appt_time": moment(this.state.selectedHours.hours, ["h:mm A"]).format("HH:mm"),
      "schedule_type": this.state.visit_type.value,
      "appt_status": "Scheduled",
      "process": "Save",
      other_comments: this.state.other_comments
    };
    
    if(visit_type.value === 'inperson' && payment_mode ==='Direct'){
      data.schedule_type="inperson_direct";
    }
    // if (this.state.payment_mode == 'Direct') {
    //   data.schedule_type = "inperson_direct";
    // }

    //console.log(data); return false;
    axios.post(saveClientAppointment, data).then((res) => {
      console.log(res);
      this.setState({ client_appt_id: res.data.data[0] })
      let checkin_data = {
        "business_id": this.state.business_id,
        "client_id": this.state.client_details.client_id,
        "business_user_id": this.state.business_user_id,
        "other_comments": this.state.other_comments,
        "q_id": res.data.data[0],
        "schedule_type":"appointment"
        
      };
        console.log(checkin_data);
        let doc_firstname=this.state.doctor_details.first_name;
    let doc_lastname=this.state.doctor_details.last_name;
      axios
      .post(addCheckinURL, checkin_data, CONFIG)
      .then((result) => {
        if (result.data.status === "1") {
          if (this.state.payment_mode == 'Direct') {
            this.closePopup();
             toast.success("Your Appointment has been confirmed with Dr."+ doc_firstname + doc_lastname + "." , {
              className: "toast-success",
              });
             
            setTimeout(
              () =>
                (res.data.status) ?
                  window.location.href = '/dashboard' : ""
              ,
              1000
            );
            return true; 
            }
          this.displayRazorpay(res.data.data[0])
          
        }
      }).catch((err) => {

      })
    }).catch((err) => {

    })

  }
  sendBillingmail = (data) => {
   console.log(data);
    if((data.consulting_fees !== "" && data.consulting_fees !== null) && data.consulting_fees !== ""){
      let selectedRecord = this.state.client_personal_details;
    console.log(this.state.hospital_details);
      console.log(selectedRecord);

      let appt_date=moment(this.state.appointmentdate).format("DD MMM YYYY");
      let appt_time=this.state.selectedHours.hours;
      let doc_firstname=this.state.doctor_details.first_name;
      let doc_lastname=this.state.doctor_details.last_name;
      moment().format('LT');
     let feesinwords=converter.toWords(data.consulting_fees)+" rupees only";
     let TodayDate=moment(new Date()).format("DD MMM YYYY hh:mm A");
      // let style = "table,th,td{border: 1px solid #dfdfdf;}";
      let reqdata = { 
        "to" : selectedRecord.email,
        "subject" : "Billing for "+selectedRecord.first_name,
        "mailHeader" : "Billing for "+selectedRecord.first_name,
        "message": '<div class="row"><div class="col-md-6" style="text-align:left"><table style="text-align:left;margin-left:auto;" class="billing_table" cellpadding="10%"><tbody><tr style="background-color: #D3D3D3;"><td>Registration No.</td><td>'+selectedRecord.patient_id+'</td></tr><tr style="background-color: #D3D3D3;"><td>Patient Name</td><td>'+selectedRecord.first_name+'</td></tr><tr style="background-color: #D3D3D3;"><td>Hospital Address</td><td>'+ this.state.hospital_details.business_name + ", " + this.state.hospital_details.address1
        + ", " + this.state.hospital_details.city
        + ", " + this.state.hospital_details.state
        + " - " + this.state.hospital_details.zip_code+'</td></tr><tr style="background-color: #D3D3D3;"><td>Date</td><td>'+ TodayDate +'</td></tr></tbody></table></div></div><table class="table table-bordered table-striped" style="border: 1px solid #dfdfdf; width: 100%;margin-bottom:1rem;color: #495057;" id="as-react-datatable"><thead class=""><tr style="background-color: #2196f3;"><th class="sortable  text-left" style="border: 1px solid #dfdfdf;" width="">SNo</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Description SAC Code for service</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Amount</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Tax Value</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Rate</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">Amount</th><th style="border: 1px solid #dfdfdf;" class="sortable  text-left" width="">TotalAmount in (Rs)</th></tr></thead>',
      }
      
      /* data.medicine_description.forEach((element, index) => {*/
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td class="Sno">'+'1'+'</td><td class="sac">'+'Consultation Fees'+'</td><td class="amount">'+ data.consulting_fees +'</td><td class="tax_value">'+'NA' +'</td><td class="rate">'+ 'NA'+'</td><td class="amount">'+data.consulting_fees +'</td><td class="total">'+ data.consulting_fees +'</td></tr>';
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td colspan="2">'+'Total Amount'+'</td><td class="amount">'+ data.consulting_fees +'</td><td class="tax_value">'+'NA' +'</td><td class="rate">'+ 'NA'+'</td><td class="amount">'+data.consulting_fees +'</td><td class="total">'+ data.consulting_fees +'</td></tr>';
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td colspan="6">'+'Total Invoice value(in Figure)'+'</td><td class="total">'+ data.consulting_fees +'</td></tr>';
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td colspan="3">'+'Total Invoice value(in Words)'+'</td><td colspan="4">'+ feesinwords +'</td></tr>';
        /*   }); */

      reqdata.message += '</tbody></table>';
      reqdata.message+="Thank you for using our service! We are pleased to confirm your upcoming medical appointment with Dr. "+ doc_firstname + doc_lastname +"  on "+ appt_date + " at "+ appt_time +".<p> Please ensure you arrive 15 minutes early. If you have any relevant medical records, kindly bring them.</p></p> In case of questions or changes, feel free to contact us. We look forward to your visit.</p>";
      axios
      .post(sendemail, reqdata)
      .then((res) => {
        let resdata = res.data;
        console.log(resdata);
        
      }).catch((err) => {
        console.log(err);
        // successMessage = {message: err,status:"0"};
      })
    }
    

  }
  Onreschedule = (e) => {
    this.setState({ rebookModel: true });
    console.log(this.state.rescheduleObj.appt_time);
    console.log(this.state.selectedHours.hours);
   console.log(this.state.selectedDate);
   console.log(this.state.rescheduleObj.appt_date);
    this.closePopup();
  };

  OnConfirmreschedule = (e) => {
   
    let data = {
      "client_appt_id": this.state.rescheduleObj.client_appt_id,
      "business_id": this.state.business_id,
      "appt_date": moment(this.state.selectedDate).format('YYYY-MM-DD'),
      "appt_time": moment(this.state.selectedHours.hours, ["h:mm A"]).format("HH:mm"),
      "schedule_type": this.state.visit_type.value,
      "appt_status": "Reschedule",
      "process": "Save"
    };
    let doc_firstname=this.state.doctor_details.first_name;
    let doc_lastname=this.state.doctor_details.last_name;
    // console.log(data); return false;
    axios.post(saveClientAppointment, data).then((res) => {
      console.log(res);
      this.setState({ client_appt_id: res.data.data[0] });
      this.searchBookAppointment();
      this.closePopup();
      toast.success("Your Rescheduled appointment was confirmed with Dr."+ doc_firstname+" "+doc_lastname + ".", {
        className: "toast-success",
        });
    }).catch((err) => {

    })
  }
  beforerescheduleclose = () => {
    this.setState({
      rescheduleObj: {
        client_appt_id: "",
        appt_date: "",
        appt_time: "",
        appt_status: "",
        process: "",
        schedule_type: "",
        other_comments:""
      }
    })
    this.closePopup()
  }

  displayRazorpay = (client_appt_id) => {
//    console.log(client_appt_id)
    const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    const option = {
      method: 'POST',
      // headers: new Headers({ 'content-type': 'application/json' }),
      mode: 'no-cors'
    };

    let paymentForm = this.state.selectedHours;
    let data = {};
    /* let consulting_fees = this.state.doctor_details ? this.state.doctor_details.consulting_fees : "10";
    data.payable_amt = consulting_fees; */

    let consulting_fees = (this.state.doctor_details ) ?  this.state.doctor_details.consulting_fees:"";
   console.log(consulting_fees);
   console.log(this.state.onlineservice_charges);
    let service_charges=(this.state.visit_type.value=="online") ? this.state.onlineservice_charges : this.state.directservice_charges;
   
    let total_charges=parseInt(consulting_fees)+parseInt(service_charges);
    console.log(total_charges);
    // data.payable_amt =(this.state.payment_mode=="Online"|| this.state.visit_type.value=="online") ? total_charges : service_charges;
     data.payable_amt =(this.state.visit_type.value=="online")||(this.state.visit_type.value=="inperson" && this.state.payment_mode=="Online") ? total_charges : service_charges;
    let payment_status=(this.state.payment_mode=="Online")||(this.state.visit_type.value=="online") ? 'Completed':'Pending';
    let payment_type=(this.state.payment_mode==""?"Online":this.state.payment_mode);
    console.log(data);
    let doc_firstname=this.state.doctor_details.first_name;
    let doc_lastname=this.state.doctor_details.last_name;
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
let razorpay_key = "";
    axios
.post(getCredentials, {
  "credential_type":"razorpay"
})
.then((res) => {
  console.log(res.data);
  razorpay_key = res.data.data[0].value;
     
  axios.post(RazorPayPayment, data, data).then((res) => {
    console.log(res);
    let data = res.data;
    this.sendBillingmail(this.state.doctor_details);
    //  let vendorData = this.state.vendorData;
    //  let paymentForm = this.state.paymentForm;
    //  console.log(vendorData);
    console.log(razorpay_key);
    const options = {
      // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
      key: razorpay_key,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: 'Billing and Payment',
      description: '',
      image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
      handler: function (response) {
        console.log(response);
       // alert(response.razorpay_payment_id)
        // return false; 
        // alert(response.razorpay_order_id)
        // alert(response.razorpay_signature)
        let req_data = {
          'order_id': response.razorpay_order_id,
          'payment_id': response.razorpay_payment_id,
          //   'vendor_id': vendorData.id,
          //   vendor_subscription: vendorData.vendor_subscription.id,
          //    number_of_months: paymentForm.months.value,
          //    amount: paymentForm.payable_amt,
          amount: consulting_fees,
          payment_status: payment_status,
          service_charges:service_charges,
          scharges_paymentstatus:'Completed',
          payment_type:payment_type,
          client_appt_id: client_appt_id

        }
        toast.success("Thank you! Your Slot Appointment was scheduled with Dr."+ doc_firstname + doc_lastname + ".", {
          className: "toast-success",
          });
       
        axios.post(updateclientappointmentstatus, req_data, CONFIG).then((res) => {
          console.log(res);
         
          setTimeout(
            () =>
              (res.data.status) ?
                window.location.href = '/dashboard' : ""
            ,
            1000
          );
         
        }).catch((err) => {


        })
      },

      prefill: {
        name: this.props.userdata.first_name+" "+this.props.userdata.last_name,
        email: this.props.userdata.email,
        contact: this.props.userdata.phone_number
      },
       modal: {
        confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
        // This function is executed when checkout modal is closed
        // There can be 3 reasons when this modal is closed.
        ondismiss: async (reason) => {
          const {
            reason: paymentReason, field, step, code,
          } = reason && reason.error ? reason.error : {};
          // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
          if (reason === undefined) {
            console.log('cancelled');
            let cancel_data = {
              status:'Cancelled',
              amount: consulting_fees,
              payment_status: "",
              service_charges:service_charges,
              payment_type:payment_type,
              client_appt_id: client_appt_id
    
            };
            const handlePayment  = async (url, cancel_data) => {
              try {
                const response = await axios.post(url, cancel_data);
                return response.data;
              } catch (error) {
                console.error('Error:', error);
                throw error;
              }
            };   
            handlePayment(updateclientappointmentstatus,cancel_data);
            let selectedHours = this.state.selectedHours;
            let doc_firstname=this.state.doctor_details.first_name;
          let doc_lastname=this.state.doctor_details.last_name;
          let data = { client_appt_id: selectedHours.client_appt_id, "process": "Cancel" }
          axios.post(saveClientAppointment, data).then((res) => {
            console.log(res);

            let req_data = {
                    
              appt_status: 'Cancelled',
              client_appt_id: client_appt_id
  
            }
            axios
            .post(updateclientappointmentstatus, req_data, CONFIG)
            .then((res) => {console.log(res)}).catch((e) => {
           
          });
           /*  toast.error("Your Slot Appointment with the Dr. "+ doc_firstname + doc_lastname +" has been successfully canceled.", {
              className: "toast-error",
              }); */
            });

            toast.error("Your Appointment and Payment has been Cancelled." , {
              className: "toast-error",
              }); 

     //       handlePayment('Cancelled');
          } 
          // Reason 2 - When modal is auto closed because of time out
          else if (reason === 'timeout') {
            console.log('timedout');
            let cancel_data = {
              status:'Cancelled',
              amount: consulting_fees,
              payment_status: "",
              service_charges:service_charges,
              payment_type:payment_type,
              client_appt_id: client_appt_id
    
            };
            const handlePayment  = async (url, cancel_data) => {
              try {
                const response = await axios.post(url, cancel_data);
                return response.data;
              } catch (error) {
                console.error('Error:', error);
                throw error;
              }
            };   
            handlePayment(updateclientappointmentstatus,cancel_data);
            let selectedHours = this.state.selectedHours;
            let doc_firstname=this.state.doctor_details.first_name;
          let doc_lastname=this.state.doctor_details.last_name;
          let data = { client_appt_id: selectedHours.client_appt_id, "process": "Cancel" }
          axios.post(saveClientAppointment, data).then((res) => {
            console.log(res);
            let req_data = {
                    
              appt_status: 'Cancelled',
              client_appt_id: client_appt_id
  
            }
            axios
            .post(updateclientappointmentstatus, req_data, CONFIG)
            .then((res) => {console.log(res)}).catch((e) => {
           
          });
           /*  toast.error("Your Slot Appointment with the Dr. "+ doc_firstname + doc_lastname +" has been successfully canceled.", {
              className: "toast-error",
              }); */
            });
         
            toast.error("Your Appointment and Payment has been Cancelled." , {
              className: "toast-error",
              }); 
     //       handlePayment('timedout');
          } 
          // Reason 3 - When payment gets failed.
          else {
            console.log('failed');
            console.log(paymentReason);
            let cancel_data = {
              status:'Cancelled',
              amount: consulting_fees,
              payment_status: "",
              service_charges:service_charges,
              payment_type:payment_type,
              client_appt_id: client_appt_id
    
            };
            const handlePayment  = async (url, cancel_data) => {
              try {
                const response = await axios.post(url, cancel_data);
                return response.data;
              } catch (error) {
                console.error('Error:', error);
                throw error;
              }
            };   
            handlePayment(updateclientappointmentstatus,cancel_data);
            let selectedHours = this.state.selectedHours;
            let doc_firstname=this.state.doctor_details.first_name;
          let doc_lastname=this.state.doctor_details.last_name;
          let data = { client_appt_id: selectedHours.client_appt_id, "process": "Cancel" }
          axios.post(saveClientAppointment, data).then((res) => {
            console.log(res);
            let req_data = {
                    
              appt_status: 'Cancelled',
              client_appt_id: client_appt_id
  
            }
            axios
            .post(updateclientappointmentstatus, req_data, CONFIG)
            .then((res) => {console.log(res)}).catch((e) => {
           
          });
           /*  toast.error("Your Slot Appointment with the Dr. "+ doc_firstname + doc_lastname +" has been successfully canceled.", {
              className: "toast-error",
              }); */
            });
          
            toast.error("Your Appointment and Payment has been Cancelled." , {
              className: "toast-error",
              }); 
          /*  handlePayment('failed', {
              paymentReason, field, step, code,
            }); */
          }
        },
      },
      timeout: 300, // Time limit in Seconds
      theme: {
      color: '#037ac7', // Custom color for your checkout modal.
    },
  }
    const paymentObject = new window.Razorpay(options)
    paymentObject.on('payment.submit', (response) => {
      // paymentMethod.current = response.method;
     //  alert(response.method);
     });
    paymentObject.on('payment.failed', function (response){
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
});

    paymentObject.open()

  }).catch((e) => { console.log(e) });

    
}) 
.catch((e) => { 
  console.log(e)
});

  


  }
  handleselectUpdateChange = (selected) => {
    console.log(selected);
    this.setState({ visit_type: selected })
  }
  handlePaymentSelect = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      payment_type_error:"",
    });
  }
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    const { preferredDate, loading,oldtime,presenttime,doc_name,selectedHours,paymenttypeforhospital } = this.state;
    return (

      <div className="main-content bacfot">
      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
        <div className="mainpage">
          <Header user_id={this.state.loggedInuserId} />
          <div className="main-box">
            <div className="sidemenu">
              <SideBar />
            </div>

            <section className="main-body hospital-profilegap">
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

              <div className="container-fluid">
                <div className="checkout-tabs">
                  <div className="row">
                  <Popupinfo
                      show={this.state.addPopup}
                      onHide={PopupClose}
                      title={"Slot Expired!!"}
                      message={"The Slot has expired.Please choose an available time-slot."}
                    />
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-9 pr0">
                       
                          <div className="card">
                          <div class="card-header"> <div class="card-title">Appointment for {this.state.client_details ? this.props.location.state.client_details.name : ""}</div> </div>
                            <div className="card-body ppb-6">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="row">
                                   
                                    <div className="col-sm-12">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <label>Preferred dates</label>
                                          <div className="form-group">
                                            <TextField
                                              variant="outlined"
                                              label=""
                                              type="date"
                                              value={preferredDate.from}
                                              onChange={(e) =>
                                                this.handleDOBChange(e, "from")
                                              }
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <label>To</label>
                                          <div className="form-group">
                                            <TextField
                                              variant="outlined"
                                              label=""
                                              type="date"

                                              value={preferredDate.to}
                                              onChange={(e) =>
                                                this.handleDOBChange(e, "to")
                                              }
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-4">
                                          <label>&nbsp; </label>
                                          <div className="form-group">
                                            <div className="input-group">
                                              <button
                                                type="button"
                                                className="signupbtn abtn btdpml"
                                                onClick={this.searchBookAppointment}
                                              >
                                                <i
                                                  className="fa fa-search-plus"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;Search
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="col-sm-4">
                                        <label>Limit times to </label>
                                        <div className="form-group">
                                          <Select
                                            className="form-control p-0"
                                            name="limit"
                                            value={preferredDate.limit}
                                            onChange={this.handleselectChange}
                                            options={limit_times_to}
                                            isvisible={true}
                                          />
                                        </div>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card ">
                          <div class="card-header"> <div class="card-title"> {this.state.business_name} ( Doctor: {this.state.doc_name} )</div> </div>
                            <div>
                              <div className="card-body a-page-1-hight-new resultscrol">
                               
                                <div
                                  id="carouselExampleFade"
                                  className="carousel slide carousel-fade"
                                  data-ride="carousel"
                                  data-interval="100000000000"
                                >
                                  <div className="carousel-inner">
                                    <div className="carousel-item active">
                                      <div className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-10">
                                          <ul
                                            className="nav nav-tabs nav-tabs-custom nav-justified custoomnatb"
                                            role="tablist"
                                          >
                                            {this.state.appointmentsData.length ? this.state.appointmentsData.map(
                                              (data, i) => {
                                                if (i <= 2) {
                                                  return (

                                                    <li className="nav-item" key={i}>
                                                      <a
                                                        className={
                                                          i == 0
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                        }
                                                        data-toggle="tab"
                                                        href={"#home" + i}
                                                        role="tab"
                                                      >
                                                        <span className="d-none d-sm-block">
                                                          <h4>
                                                            {getGivenDateStringFormat(
                                                              data.appointmentDate
                                                            )}
                                                          </h4>
                                                          <p className="grenclr">
                                                            {getAvaliableCount(data.appointmentDate,data.appointmentTimings)} Available
                                                          </p>
                                                        </span>
                                                      </a>
                                                    </li>

                                                  );
                                                }
                                              }
                                            ) : ""}
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="tab-content active text-muted">
                                        {this.state.appointmentsData.length ? this.state.appointmentsData.map(
                                          (data, i) => {
                                            if (i <= 2) {
                                              return (
                                                <div
                                                  className={
                                                    i == 0
                                                      ? "tab-pane active topmar"
                                                      : "tab-pane topmar"
                                                  }
                                                  id={"home" + i}
                                                  role="tabpanel"
                                                >
                                                  {data.appointmentTimings.map(
                                                    (appoimentTime, j) => {
                                                      return (
                                                        <Fragment>
                                                          <div className="row">
                                                            <div className="col-sm-12">
                                                              <div className="apoimentleft">
                                                                <p>
                                                                  <i
                                                                    className={getShiftSymbol(
                                                                      appoimentTime.timingName
                                                                    )}
                                                                    aria-hidden="true"
                                                                  ></i>{" "}
                                                                  {
                                                                    appoimentTime.timingName
                                                                  }
                                                                </p>
                                                              </div>
                                                              <div className="apoimentright">
                                                                <div className="row">
                                                                  <div className="col-sm-12">
                                                                    {appoimentTime.appointmentHours.map(
                                                                      (
                                                                        hoursData,
                                                                        k
                                                                      ) => {
                                                                        return (

                                                                          <button
                                                                            className={
                                                                              hoursData.isAvailable ? "grenactiv" : "blueactiv"
                                                                            }
                                                                            onClick={() => this.timeClicked(hoursData, data.appointmentDate)}
                                                                            >
                                                                            {
                                                                              hoursData.hours
                                                                            }
                                                                          </button>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <hr className="hrbdr" />
                                                        </Fragment>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            }
                                          }
                                        ) : ""}
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    this.state.showPrevBtn ?
                                      <Fragment>
                                        <a
                                          className="carousel-control-prev"
                                          href="#carouselExampleFade"
                                          role="button"
                                          data-slide="prev"
                                          style={{ display: "block" }}
                                          onClick={this.handleNext.bind(this, "previous")}
                                        >
                                          <i
                                            className="fa fa-long-arrow-left slidearow"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="sr-only">Previous</span>
                                        </a>
                                      </Fragment> : ""}
                                  {
                                    this.state.showNextBtn ?
                                      <Fragment>
                                        <a
                                          className="carousel-control-next"
                                          href="#carouselExampleFade"
                                          role="button"
                                          data-slide="next"
                                          style={{ display: "block" }}
                                          onClick={this.handleNext.bind(this, "next")}
                                        >
                                          <i
                                            className="fa fa-long-arrow-right slidearow"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="sr-only">Next</span>
                                        </a>
                                      </Fragment> : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3 pl0">
                          <div className="icalendar hide"></div>
                          <div className="card">
                          <div class="card-header"> <div class="card-title">My Appointments</div> </div>
                            <div className="card-body m357">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="row">
                                    <div className="col-sm-12">
                                      
                                      <table className="btnclrsaa">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <button className="btnclrs clrwq1"></button>
                                            </td>
                                            <td>Appollo Hospital</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <button className="btnclrs clrwq2"></button>
                                            </td>
                                            <td>Vasan eyecare</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <button className="btnclrs clrwq3"></button>
                                            </td>
                                            <td>Vivek dental</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <button className="btnclrs clrwq4"></button>
                                            </td>
                                            <td>Velan skincare</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                          <div class="card-header"> <div class="card-title">Reminders</div> </div>
                            <div className="card-body m357">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="row">
                                    <div className="col-sm-12">
                                 
                                      <div className="remindhit"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Footerad /> */}
                    </div>
                  </div>
                </div>
              </div>
            
            </section>
            <FooterSection />

            <Modal
              show={this.state.bookModel}
              onHide={this.closePopup}
              centered={true}
            >
              <Modal.Header closeButton className="model-hptd">
                <Modal.Title className="h5 model-text-color">
                  Book an appointment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Hospital Name</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{this.state.business_name}</span>
                      </div>
                    </div>

                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Appointment Date</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{this.state.selectedDate}</span>
                      </div>
                    </div>

                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Appointment Time</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{this.state.selectedHours.hours}</span>
                      </div>
                    </div>


                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Visit Type</label>
                      </div>
                      <div className="form-group frlrit">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="visit_type"
                         
                          value={this.state.visit_type}
                          onChange={this.handleselectUpdateChange}
                          options={VISIT_TYPE}
                          isDisabled={this.state.reschedule==true}
                        />
                      </div>
                    </div>
                    {(this.state.visit_type.value
                      //&& !this.state.reschedule
                      == 'inperson' && !this.state.reschedule) ? (
                      <div className="frllftfull">
                        <div className="form-group frllft">
                          <label>Payment Type</label>
                        </div>




                        <Fragment className="form-group frlrit">
                        {this.state.paymenttypeforhospital=="Online"?
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">

                                <input
                                  checked={this.state.payment_mode==="Online"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Online"
                                  value="Online"
                                  name="payment_mode"

                                  onChange={this.handlePaymentSelect}


                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay online"
                                >
                                  Pay online
                                </label>
                               
                              </div>
                              
                            </div>
                            <SpanField classname="text-danger" title={this.state.payment_type_error} />
                          </div>
                           :""}
                         {this.state.paymenttypeforhospital==="Direct"?
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">
                              
                                <input
                                 checked={this.state.payment_mode==="Direct"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Direct"
                                  name="payment_mode"
                                  value="Direct"
                                  onChange={this.handlePaymentSelect}

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay at Clinic"
                                >
                                  Pay at Clinic
                                </label>
                               
                              </div>
                             
                            </div>
                            <SpanField classname="text-danger" title={this.state.payment_type_error} />
                          </div>
                          :""}
                        </Fragment>

                      </div>) : ""}

                      {(this.state.visit_type.value=="inperson" && this.state.payment_mode=="Online")|| this.state.visit_type.value=="online"?
                    (<><div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Consulting Fees</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.doctor_details ? this.state.doctor_details.consulting_fees : ""
                        } INR
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Service Charges</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.visit_type.value=="online" ? this.state.onlineservice_charges : this.state.directservice_charges} INR
                      </div>
                    </div></>): ""}
                    {this.state.visit_type.value=="inperson" && this.state.payment_mode=="Direct"?
                    (<div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Service Charges</label>
                      </div>
                      <div className="form-group frlrit">
                        {this.state.directservice_charges} INR
                      </div>
                    </div>):""}
{selectedHours.isAvailable ? (<div className="frllftfull" 
// style={{display:"none"}}
>
                      <div className="form-group">
                        <label>Reason for visit</label>

                        <TextArea
                          rows={7}
                          content={this.state.other_comments}
                          name={"other_comments"}
                          controlFunc={this.handleQueueInput}
                          placeholder={""}
                          maxLength={100}
                        />
                        <SpanField classname="text-danger" title={this.state.other_comments_error} />
                      </div>
                    </div>):""}
                    

                  </div>
                </div>
                <div>
                  <div className="row reverse">
                    <div className="form-group mb-0 w-100 text-center">
                      {this.state.reschedule ? (<Fragment>
                        <button className="grnbtn reddg" onClick={this.beforerescheduleclose}>
                          Close
                        </button>&nbsp;
                        <button className="grnbtn" onClick={this.cancelAppointment}>
                          Cancel
                        </button>
                        &nbsp;
                        <button className="grnbtn" onClick={this.Onreschedule}>
                          Reschedule
                        </button>
                        &nbsp;
                       {/*  <a className="grnbtn joinbtn" target="_blank" href={"https://dqqpa02ik3.execute-api.ap-south-1.amazonaws.com/Prod?m=" + this.state.selectedHours.client_appt_id + '&name=' + this.state.client_details.name + '&type=p'} //onClick={this.Onreschedule}
                        >
                          Join
                        </a> */}
                        </Fragment>) : (
                        <Fragment>
                           <div className="text-center w-100 m-0">
                           <button className="grnbtn" onClick={this.bookAppointment}>
                            Book
                          </button>&nbsp;
                          <button className="grnbtn reddg" onClick={this.closePopup}>
                            Close
                          </button>
                           </div>
                      
                         </Fragment>)}
                      {/* <button className="grnbtn" onClick={this.bookAppointment}>
                      Book
                  </button> */}
                    </div>

                  </div>

                </div>
              </Modal.Body>
            </Modal>


            <Modal
              show={this.state.rebookModel}
              onHide={this.closePopup}
              centered={true}
            >
              <Modal.Header closeButton className="model-hptd">
                <Modal.Title className="h5 model-text-color">
                  Rescheduling the appointment
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Hospital Name</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">{this.state.business_name}</span>
                      </div>
                    </div>


                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Visit Type</label>
                      </div>
                      <div className="form-group frlrit">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="visit_type"
                          value={this.state.visit_type}
                          isDisabled={true}
                          
                          onChange={this.handleselectUpdateChange}
                          options={VISIT_TYPE}
                        />
                      </div>
                    </div>

                    <div className="frllftfull">
                      <div className="form-group frllft">
                        <label>Consulting Fees</label>
                      </div>
                      <div className="form-group frlrit">
                        <span className="form-control graylight">
                          {this.state.doctor_details ? this.state.doctor_details.consulting_fees : ""
                          } INR </span>
                      </div>
                    </div>

                    <div>
                      <span>
                      Click the confirm button to reschedule the appointment from {this.state.rescheduleObj.appt_date} at {this.state.rescheduleObj.appt_time} to {this.state.selectedDate} at {this.state.selectedHours.hours}
                      </span>
                    </div>

                  </div>
                </div>
                <div>
                  <div className="row reverse">
                    <div className="form-group mb-0 text-center w-100">

                      &nbsp;
                      <button className="grnbtn" onClick={this.OnConfirmreschedule}>
                        Confirm
                     </button>
                     &nbsp;
                  
                      <button className="grnbtn reddg" onClick={this.beforerescheduleclose}>
                        Close
                      </button>
                      
                    </div>
                  </div>

                </div>
              </Modal.Body>
            </Modal>
            <Modal
                                                            show={this.state.cancelModel}
                                                            onHide={this.closePopup}
                                                            centered={true}
                                                        >
                                                            <Modal.Header closeButton className="model-hptd">
                                                                <Modal.Title className="h5 model-text-color">
                                                                    Cancel Appointment
                                                                </Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div>
                                                                    <p>
                                                                        Are you sure you want to Cancel the appoinment ?
                                                                    </p>

                                                                    <div className="row reverse">
                                                                    <div className="form-group mb-0 text-right">
                                                                            <button
                                                                                className="grnbtn "
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        cancelModel: false,
                                                                                        cancelRecord: {},
                                                                                    });
                                                                                }}
                                                                            >
                                                                                No
                                                                            </button>
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="form-group mb-0 text-right">
                                                                            <button
                                                                                className="grnbtn reddg"
                                                                                onClick={this.conformCancelAppointment}
                                                                            >
                                                                                Yes
                                                                            </button>
                                                                        </div>
                                                                       
                                                                      
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                        </Modal>
          </div>
        </div >
      </div>
    );
  }
}

export default connect(mapStateToProps)(ClientBookAppointments);
