import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';

import axios from "axios";
import {
    getSettingsData
} from "../config/url.json";
import support from "../assets/images/support.png";
function mapStateToProps(state) {
    return {

        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}


class SupportLatest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsData: {},
            loading: false
        }
    }
    componentDidMount() {




        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getSettingsData, null)
            .then((res) => {
              
                let settingsData = [];
                res.data.data.forEach((element, index) => {
                    let name = element.name;
                    settingsData[name] = element.value
                })
                console.log(settingsData)
                this.setState({ settingsData: settingsData })

            })
            .catch((e) => { });
    }
    

    render() {
        const { settingsData } = this.state;
        return (
            <div>
             <div className="checkout-tabs">
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div>
                                                <div>
                                                    <div className="">

                                                        <h4 className="font-weight-bold">Support</h4>
                                                    </div>
                                                    <div className="card mt-4">
                                                   
                                                    <div className="p-5 supportImg">
                                                                                <img src={support} className="img-fluid" />
                                                                            </div>
                                                    </div>
                                                </div>
                                                {/* <Footerad /> */}
                                            </div>
                                        </div>
                                     
                                        <div className='col-sm-6'>
                                            <div className="card">
                                            <div className="card-header"> <div className="card-title">Call Us</div> </div>
                                            <div className='card-body supportlftheight'>
                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <div className="form-group mb-0">
                                                                                        <label><strong>Phone Number</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                    {settingsData.support_phone_number?renderHTML(settingsData.support_phone_number):""}
                                                                                    </div>
                                                                                    <div className="form-group mb-0">
                                                                                        <label><strong>Email</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                    {settingsData.support_email_id?renderHTML(settingsData.support_email_id):""}
                                                                                       
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>info@klinicq.com</strong></label>
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                                <div className="col-sm-6">
                                                                                    <div className="form-group">
                                                                                        <label>Monday – Friday</label><br />
                                                                                        <label>8 a.m. – 11 p.m. IST</label><br />
                                                                                        <label>Saturday – Sunday</label><br />
                                                                                        <label>8 a.m. – 8 p.m. IST</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                            </div>
                                            </div>
                                        </div>

               
               
                </div>
            </div>
        </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(SupportLatest);