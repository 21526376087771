//import '../../App.scss';
import "../../assets/css/mystyle.css";
import React, {Component} from 'react';
import Header from "../../components/container/layout/client/Header";
import BusinessUserHeader from "../../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import ClientSideBar from "../../components/container/layout/client/SideBar";
import FooterSection from "../../components/UI/FooterSection";

import { getTimeoutbycategory } from "../../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';

import axios from "axios";
import { CONFIG } from "../../config/data";

import Room from '../Video/Room';
import { withRouter } from "react-router-dom";
import {validateVideoData,joinroom} from "../../config/url.json";
import { connect as connect1 } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { connect } = require('twilio-video');



function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    user: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
class JoinRoom extends Component {
    
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;

        this.state = {
          timeout: hospitaltimeout,
          isTimedOut: false,
          showModal: false,

          error:"",
          identity: this.props.location.state.patient_name,

          // identity: this.props.user.first_name+" "+this.props.user.last_name,
          type:this.props.user.user_type==="patient" ? "p":"d",
          client_appt_id:this.props.match.params.client_appt_id,
          roomName :this.props.match.params.roomName,
          room: null,
        }
        this.joinRoom = this.joinRoom.bind(this);
        this.returnToLobby = this.returnToLobby.bind(this);
        this.updateIdentity = this.updateIdentity.bind(this);
        this.removePlaceholderText = this.removePlaceholderText.bind(this);
      }
      componentDidMount = () => {
        console.log(this.props.user.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.user.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { });
    }
      async joinRoom() {
        try {
          console.log(this.state.client_appt_id);
          const endpoint = validateVideoData;
          const reqBody =JSON.stringify({
            client_appt_id: this.state.client_appt_id,
            type: this.state.type
          });
          const rawResponse = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Accept":"application/json",
              // "application_id": "c86ae3f5-3a96-49f2-af05-94746357d0ec",
            },
            body: reqBody
          });
          const content = await rawResponse.json();
        
          console.log(content);

if(content.status === "1"){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ roomName: this.state.roomName,'identity':this.state.identity })
};
const response = await fetch(joinroom,requestOptions);
const data = await response.json();
const room = await connect(data.token, {
name: 'cool-room',
audio: true,
video: true
});

this.setState({ room: room });
}
else {

  toast.error(content.message,{
    className:"toast-error"
  });


  this.setState({error:content.message});
}
          
        } catch(err) {
          console.log(err);
        }
      }
      returnToLobby() {
        this.setState({ room: null });
      }
      removePlaceholderText() {
        this.inputRef.current.placeholder = '';
      }
      updateIdentity(event) {
        this.setState({
          identity: event.target.value
        });
      }
      render() {
        const disabled = this.state.identity === '' ? true : false;
        const { hospitaltimeout } = this.state;
        return (


          <div className="main-content bacfot">

                <div className="mainpage">
                    <section className="my-head">
                        <div className="container-fluid">
                            { this.props.user.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.user.id} />
                            ) : (
                                <Header user_id={this.props.user.id} />
                            )}
                        </div>
                    </section>
                    <div className="main-box">
                        <div className="sidemenu ">
                            {this.props.user.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div>

                        <section className="main-body hospital-profilegap">
                            <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            />

                            <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} />

                            <div className="container-fluid a-page-1-hight-right">
                                <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                />

                                <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} />

                                    



          <div className="app">
          Press the join room button below to access the video call prior to the start of your appointment.
          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
            { 
              this.state.room === null
              ? <div className="lobby">
                   <input 
  value={this.state.identity} 
  onChange={this.updateIdentity} 
  className="joinRoom"
  ref={this.inputRef} 
  onClick={this.removePlaceholderText} 
  placeholder="What's your name?"/>
                  <button disabled={disabled} onClick={this.joinRoom} className="join-btn">Join Room</button>
                </div>
              : <Room returnToLobby={this.returnToLobby} room={this.state.room} />
            }
          </div>
          </div>
       
</section>
<FooterSection />
          </div>
          </div>
          </div>

        );
      }
}

export default withRouter(connect1(mapStateToProps)(JoinRoom));
