import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import { withRouter } from "react-router-dom";
import FooterSection from "../../components/UI/FooterSection";
import SpanField from "../../components/UI/SpanField";
import { Popup } from "../../components/UI/Popup";
import { getAllBusinessList,removeBusinessFromBusinesslist,GetQueueCompletedReport,getTimeoutbycategory } from "../../config/url.json";
import axios from "axios";
import { CONFIG } from "../../config/data";
import moment from "moment";
import Select from "react-select";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import Validation from "../../actions/Validation";
import DatePicker from "react-date-picker";
import SideBar from "../../components/container/SideBar";
import Header from "../../components/container/layout/business/Header";
import Label from "../../components/UI/Label";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisitListExport from "./VisitListExport";
import { Popupinfo } from "../../components/UI/Popupinfo";
function mapStateToProps(state) {
return {
appdata: state.AppData,
userdata: state.AuthReducer.user,
access_token: state.AuthReducer.access_token,
};
}
function mapDispatchToProps(dispatch) {
return {
   logout: () => dispatch({ type: "SET_LOGOUT" }),
};
}
const status = [
{ value: "Pending", label: "Pending" },
{ value: "Accepted", label: "Accepted" },
];
function findWithAttr(array, attr, value) {
for (var i = 0; i < array.length; i += 1) {
if (array[i][attr] === value) {
return i;
}
}
return -1;
}
class VisitList extends Component {
constructor(props) {
super(props);

this.idleTimer = React.createRef()
const hospitaltimeout=this.state;

this.state = {
   adminstatus: this.props.userdata.adminstatus,
   timeout:hospitaltimeout,
   isTimedOut: false,
   showModal: false,
tableSuccess: {
message: "",
status: "",
},
records: [],
actions: "update",
loading: false,
showDeleteConfirmPopup: false,
confirmedDelete: false,
deletePopup: false,
showcomponent:false,
hospitalreport:
{
//      id:"",
//    user_id: "",
Fromdate:"",
Todate:"",
},
errors: {
Fromdate:"",
Todate:"",
},
formSuccess: {
message: "",
status: "",
},
};
this.columns = [
{ text: "S.No",
className: "s_no",
key:"s_no",
align: "left",
sortable: true,
},
{
key: "business_name",
text: "Business Name",
className: "business_name",
align: "left",
sortable: true,
},
/* {
   key: "business_id",
   text: "Business id",
   className: "business_id",
   align: "left",
   sortable: true,
   },
 */   {
key: "doctor_name",
text: "Doctor Name",
className: "doctor_name",
align: "left",
sortable: true,
},
{
key: "Patient_id",
text: "Patient ID",
className: "Patient_id",
align: "left",
sortable: true,
},
{
key: "patient_name",
text: "Patient Name",
className: "patient_name",
align: "left",
sortable: true,
},
{
key: "email",
text: "Email",
className: "email",
align: "left",
sortable: true,
},
{
key: "date_of_birth",
text: "DOB",
className: "date_of_birth",
align: "left",
sortable: true,
cell: (record) => {
return moment(record.date_of_birth).format("DD-MM-YYYY");
},
},
{
key: "gender",
text: "Gender",
className: "gender",
sortable: true,
align: "left",
},
/*     {
key: "marital_status",
text: "Marital Status",
className: "marital_status",
sortable: true,
align: "left",
}, */
{
key: "blood_group",
text: "Blood Group",
className: "blood_group",
sortable: true,
align: "left",
},
{
key: "phone_number",
text: "Ph No.",
className: "phone_number",
sortable: true,
align: "left",
},
{
key: "ActualCheckIndate",
text: "Check In Date",
className: "ActualCheckIndate",
sortable: true,
align: "left",
},
{
key: "ActualCheckIntime",
text: "Check In time",
className: "ActualCheckIntime",
sortable: true,
align: "left",
},
{
key: "Checkouttime",
text: "Check out time",
className: "Checkouttime",
sortable: true,
align: "left",
},
/*   {
key: "created_time",
text: "Created Date",
className: "created_time",
sortable: true,
align: "left",
cell: (record) => {
var date=moment(record.created_time).format("DD-MMM-yyyy");
//console.log(date);
if(date ==="Invalid date")
{
return "";
}else
{
return moment(record.created_time).format("DD-MMM-yyyy");
}
},
},
*/
];
this.config = {
page_size: 10,
length_menu: [10, 20, 50],
button: {
excel: false,
print: false,
},
};
this.idleTimer = null
this.onAction = this._onAction.bind(this)
this.onActive = this._onActive.bind(this)
this.onIdle = this._onIdle.bind(this)
this.handleClose = this.handleClose.bind(this)
this.handleLogout = this.handleLogout.bind(this)
}
handleClose() {
   this.setState({showModal: false})
 }


  handleLogout = (e) => {
   this.setState({showModal: false})
   e.preventDefault();
   localStorage.removeItem("token");
   //this.props.logout();
   this.props.history.push("/");
};

 _onAction(e) {
  // console.log('user did something', e)
   
   this.setState({isTimedOut: false})
 }

 _onActive(e) {
   
   console.log('user is active', e)
   this.setState({isTimedOut: false})
 }
  
 _onIdle = (e) =>  {
   console.log('user is idle', e)
   let isTimedOut  = this.state.isTimedOut
   let timeout=this.state.timeout;
   console.log(timeout);
   console.log(isTimedOut);
   if (isTimedOut===false) 
   {
    // console.log("the time is over");
     this.setState({showModal: true})
     console.log(this.state.showModal);
      this.idleTimer.reset();
      this.setState({isTimedOut: true})

   } 
  if(isTimedOut===true)
  {
   localStorage.removeItem("token");
   //this.props.logout();
   this.props.history.push("/");
 }
 }

handleColumnChange = (record, selectedOptions) => {
record.status = selectedOptions ? selectedOptions.value : "Pending";
this.UpdateColumnChange(record);
};
UpdateColumnChange = (data) => {
// axios.defaults.headers.common[
//     "Authorization"
// ] = `Bearer ${this.props.access_token}`;
// let request = {
//     business_user_id: data.user_id, status: data.status
// }
// axios.post(updateDoctorStatus1, request).then((list) => {
let records = this.state.records;
//     // data = list.data.data;
//     if (list.data.status === "1") {
var recIndex = findWithAttr(records, "user_id", data.user_id);
records[recIndex].status = data.status;
this.setState({ records, tableSuccess: "" });
// }
this.clearMessage();
// });
};
handleselectChange = (selectedOptions, e) => {
let data = this.state.hospitalreport;
data[e.name] = selectedOptions;
this.setState({ hospitalreport: data });
};
clearMessage = () => {
setTimeout(
() =>
this.setState({
formSuccess: { message: "" },
tableSuccess: { message: "" },
}),
5000
);
};
handleFromDOBChange = (date) => {
let Hospitalreport = this.state.hospitalreport;
let errors=this.state.errors;
      
//Hospitalreport.Fromdate = date;
const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
//console.log(offsetDate);
Hospitalreport.Fromdate = offsetDate;
//errors.Fromdate="";
this.setState({ hospitalreport: Hospitalreport,errors:errors,Fromdate:offsetDate });
};
handleToDOBChange = (date) => {
let Hospitalreport = this.state.hospitalreport;
let errors=this.state.errors;
const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
Hospitalreport.Todate = offsetDate;
//errors.Todate="";
this.setState({ hospitalreport: Hospitalreport,errors:errors,Todate:offsetDate });
};
componentDidMount = () => {
   console.log(this.props.userdata.user_type);
   axios.defaults.headers.common[
     "Authorization"
   ] = `Bearer ${this.props.access_token}`;

    axios
     .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
     .then((res) => {
       let data = res.data;
       console.log(res);
       console.log(data);
       console.log(data.data.timeout);
       if (data.status === "1")
         this.setState({
           hospitaltimeout: data.data.timeout
           
         });
       })
     .catch((e) => { });

}
cancel = () => {
let data = this.state.hospitalreport;
data.id="";
data.business_name = "";
data.Fromdate="";
data.Todate="";
// data.records="";
this.setState({ hospitalreport: data, actions: "update" });
this.setState({  reportList: [] ,errorMessage:"",errors:"",showcomponent:false});
};
editRecord = (record) => {
console.log(record);
this.setState({ actions: "edit" });
let hospitaldata = this.state.hospitaldata;
hospitaldata.id=record.id;
hospitaldata.user_id = record.user_id;
hospitaldata.business_name=record.business_name;
hospitaldata.id = record.id;
hospitaldata.user_id = record.user_id;
hospitaldata.email=record.email;
hospitaldata.phone_number = record.phone_number;
hospitaldata.subscription={
label: record.subscription,
value: record.subscription,
};
hospitaldata.payment_status = {
label: record.payment_status,
value: record.payment_status,
};
if(hospitaldata.expires_at===null|| hospitaldata.expires_at==="")
{
hospitaldata.expires_at =record.expires_at;
}else
{
hospitaldata.expires_at=moment(record.expires_at,moment.defaultFormat).toDate();
}
this.handleHospitalChange("user_id", hospitaldata.user_id);
this.setState({ hospitaldata: hospitaldata });
};
clearAll() {
let actions = this.state.actions;
actions = "update";
this.setState({ actions });
let hospitaldata = {};
hospitaldata.id="";
hospitaldata.business_name="";
hospitaldata.email = "";
hospitaldata.phone_number = "";
hospitaldata.subscription="";
hospitaldata.payment_status="";
hospitaldata.expires_at="";
this.setState({ hospitaldata });
}
handleHospitalChange(value, field) {
let fields = this.state.hospitaldata;
// let errors = this.state.educationerror;
console.log(field);
if (value === "email") {
fields.email = field.target.value;
// errors[i].register_id = '';
}else if (value === "id") {
fields.id = field;
// errors[i].education = '';
} 
else if (value === "business_name") {
fields.business_name = field.target.value;
// errors[i].education = '';
}  
else if (value === "phone_number") {
fields.phone_number = field.target.value;
// errors[i].education = '';
}  
else if (value === "subscription") {
fields.subscription = field.target.value;
// errors[i].education = '';
}  
else if (value === "expires_at") {
fields.expires_at = field.target.value;
// errors[i].education = '';
}else if (value === "payment_status") {
fields.payment_status = field.target.value;
// errors[i].education = '';
}                
this.setState({
hospitaldata: fields,
});
}
getHospitalReport=(e)=> {
// let user_id = record.user_id;
let { hospitalreport, errors } = this.state;
hospitalreport.type = "hospitalreport";
errors = Validation(hospitalreport, errors);
this.setState({ errors: errors });
if (errors.formIsValid) {
let data = this.state.hospitalreport;
//  let data = {};
data.business_id=this.props.userdata.id;
//  console.log(moment.utc(hospitalreport.Fromdate).format('MM/DD/YYYY'));
data.Fromdate=hospitalreport.Fromdate;
data.Todate=hospitalreport.Todate;
axios.post(GetQueueCompletedReport, data, CONFIG).then((list) => {
console.log(data);
console.log(list.data);
let response=list.data;
let records= list.data.data;
if (records.length === 0) {
this.setState({errorMessage:"No Records Found", reportList: [] });
return;
}
if (response.status === "1") {
console.log(list.data.data);
this.setState({ records: list.data.data,showcomponent:true });
let records=list.data.data;
let reportList = [];
records.forEach((element, index) => {
let report = {};
report.s_no = index+1;
report.business_name=element.business_name;
report.business_id=element.business_id;
report.doctor_name=element.doctor_name;
report.Patient_id = element.Patient_id;
report.patient_name = element.patient_name;
report.date_of_birth = element.date_of_birth;
report.gender = element.gender;
report.blood_group = element.blood_group;
report.phone_number = element.phone_number;
report.email = element.email;
report.ActualCheckIndate=element.ActualCheckIndate;
report.ActualCheckIntime=element.ActualCheckIntime;
report.Checkouttime=element.Checkouttime;
reportList[index] = report;
});
this.setState({ reportList: reportList,errorMessage:"" });
this.setState({ formSuccess: list.data });
toast.success("Your Token visit reports have been listed successfully.",{
   className:"toast-success"
 });
this.clearAll();
this.clearMessage();
}
});
}
}
deleteRecord(record) {
let user_id = record.user_id;
axios.defaults.headers.common[
"Authorization"
] = `Bearer ${this.props.access_token}`;
axios
.post(removeBusinessFromBusinesslist, { user_id: user_id }, CONFIG)
.then((res) => {
let response = res.data;
if (response.status === "1") {
let records = this.state.records;
const deleteIndex = records.map((item) => item.user_id).indexOf(record.user_id);
records.splice(deleteIndex, 1);
this.setState({ records });
this.setState({ tableSuccess: res.data });
this.clearMessage();
}
})
.catch((e) => { });
this.setState({ deletePopup: false, record: null });
this.setState({
confirmedDelete: false,
});
axios.defaults.headers.common[
"Authorization"
] = `Bearer ${this.props.access_token}`;
axios
.get(getAllBusinessList, null, CONFIG)
.then((list) => {
console.log(list)
this.setState({ records: list.data.data });
})
.catch((e) => { console.log(e) });
}
render() {
let PopupClose = () => this.setState({ addPopup: false });
let PopupConfirm = () => this.deleteRecord(this.state.record);
let PopupHospitalClose = () => {
   this.setState({ hospitalpopup:true})
  
 };
const {
business_list,
hospitalreport,
formSuccess,
tableSuccess,
errors,
loading,
errorMessage,
hospitaltimeout
} = this.state;
return (
<div id="layout-wrapper">
<IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />
   
   {/* <!-- ============================================================== --> */}
   {/* <!-- Start right Content here --> */}
   {/* <!-- ============================================================== --> */}
   <div className="main-content grayback">
      <div className="mainpage">
         <Header user_id={this.props.userdata.id} />
         <section className="my-head">
            <div className="container-fluid"></div>
         </section>
         <div className="main-box">
            <div className="sidemenu pr0">
               <SideBar />
            </div>
            <section className="main-body hospital-profilegap">
               <div className="col-sm-12 checkout-tabs">
                  <h4 className="font-weight-bold"> Hospital Token Visit List </h4>
                  {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
                  <div className="row mt-4">
                     <div className="col-sm-12">
                        <div className="card">
                           <div className="card-body">
                              <div>
                                 <div className="user-notification">
                                    <SpanField
                                    classname={
                                    0 === formSuccess.status
                                    ? "text-danger user-notification"
                                    : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                    />
                                    <div className="user-notification">
                                       <SpanField
                                          classname="text-danger mt-2"
                                          title={errorMessage}
                                          />
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-lg-3">
                                       <div className="form-group">
                                       <Label title="From" isMandatory={true} />
                                          <DatePicker
                                             className="form-control"
                                             value={hospitalreport.Fromdate}
                                             format="dd-MM-yyyy"
                                             onChange={(date) =>
                                          this.handleFromDOBChange(date)
                                          }
                                          yearPlaceholder='YYYY'
                                          monthPlaceholder='MM'
                                          dayPlaceholder='DD'
                                          />
                                       </div>
                                       <SpanField
                                          classname="text-danger"
                                          title={errors.Fromdate}
                                          />
                                    </div>
                                    <div className="col-lg-3">
                                       <div>
                                          <div>
                                             
                                          </div>
                                          <div className="form-group">
                                          <Label title="To" isMandatory={true} />
                                             <DatePicker
                                                className="form-control"
                                                value={hospitalreport.Todate}
                                                format="dd-MM-yyyy"
                                                onChange={(date) =>
                                             this.handleToDOBChange(date)
                                             }
                                             yearPlaceholder='YYYY'
                                             monthPlaceholder='MM'
                                             dayPlaceholder='DD'
                                             />
                                          </div>
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.Todate}
                                             />
                                       </div>
                                    </div>
                                    <div className="col-lg-4">
                                    <div className="visibleHidden">
                                          <Label title="From Date" isMandatory={true}  />
                                       </div>
                                       <div>
                                          <button
                                             ref="btn"
                                             href="patients-register"
                                             className="signupbtn abtn btdpml btn-margin"
                                             onClick={this.getHospitalReport}>
                                             {loading ? (
                                             <LoadingSpinner title="Get Report" />
                                             ) : this.state.actions === "Update" ? (
                                             "Get Report"
                                             ) : (
                                             "Get Report"
                                             )}
                                          </button>
                                         &nbsp; &nbsp; &nbsp;
                                          <button
                                             href="patients-register"
                                             className="signupbtn abtn btdpml"
                                             onClick={this.cancel}
                                             >
                                          Cancel
                                          </button>
                                        {this.state.showcomponent ? <div className="float-right mg-right"><VisitListExport business_id={this.props.userdata.id} Fromdate={this.state.Fromdate} Todate={this.state.Todate} records={this.state.records}/></div>:""}
                                       </div>
                                    
                                    </div>
                                    
                                 </div>
                              </div>
                          
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-12">
                        <div className="card">
                           <div className="card-body ">
                              <div className="tab-content" id="v-pills-tabContent">
                                 <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                    >
                                    <div className="user-notification">
                                       <SpanField
                                       classname={
                                       0 === tableSuccess.status
                                       ? "text-danger user-notification"
                                       : "text-success user-notification"
                                       }
                                       title={tableSuccess.message}
                                       />
                                    </div>
                                    <div className=''>
                                    <ReactDatatable
                                       config={this.config}
                                       records={this.state.reportList}
                                       columns={this.columns}
                                       className="table table-bordered table-striped kas  mobile_table"
                                       />
                                    <Popup
                                       show={this.state.deletePopup}
                                       onHide={() =>
                                    this.setState({
                                    deletePopup: false,
                                    })
                                    }
                                    onConfirm={() =>
                                    this.deleteRecord(this.state.record)
                                    }
                                    title={"Confirmation"}
                                    message={
                                    "Are you sure you want to delete this record?"
                                    }
                                    />
                                 </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>)}

               </div>
            </section>
            <FooterSection />
         </div>
      </div>
   </div>
</div>
);
}
}
export default withRouter(connect(
mapStateToProps,
)(VisitList));