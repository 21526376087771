const AuthReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_LOGIN":
      return {
        ...state,
        loggedIn: true,
        user: actions.payload,
        referral_code: "",
        // message_count:0,
      };
    case "SET_LOGOUT":
      return {
        ...state,
        loggedIn: false,
        user: {},
        access_token: "",
        referral_code: "",
        message_count:0,
      };
    case "SET_USERDATA":
      return { ...state, loggedIn: false, user: actions.payload };
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        loggedIn: true,
        access_token: actions.payload,
        referral_code: "",
        // message_count:0,
      };
    case "SET_REFERALDATA":
      return { ...state, referral_code: actions.payload };
    case "SET_MessageCount":
      return { ...state, message_count: actions.payload };      
    default:
      return state;
  }
};

export default AuthReducer;
