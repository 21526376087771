import React, {  Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import LogoSection from "../components/UI/LogoSection";
import FooterSection from "../components/UI/FooterSection";
import FooterAds from "../components/UI/FooterAds";
import InputField from "../components/UI/InputField";
import { resetpasswordClient,GetEmailphnumberforDoctor } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";



function mapStateToProps(state) {
  return {};
}

const specialCharacterRegx =  /^[A-Za-z0-9 ]+$/ ;
const upperCaseRegx =  /^[A-Z0-9 ]+$/ ;
const lowerCaseRegx =  /^[a-z0-9 ]+$/ ;
const isNumberRegx = /\d/;

class ResetPassword extends Component {
    constructor(props) {
        super(props); 
        
        this.state = {
          password: "",
          password_confirmation: "",
          email_phonenumber:"",
          errors:{},
          registered_detail:"",
          formSuccess:{
            status:"",
            message:""
          },
          passValidity:{
            lowercase:false,
            uppercase: false,
            specialchar:false,
            number:false,
            maxlength:false,
          }
          
        };
      }
      handleInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        let errors = this.state.errors
        errors[name] = ""
        this.setState({ [name]: value,errors });

        let  passValidity = this.state.passValidity;
        if(name === "password" || name === "password_confirmation"){
          if(value === ""){
            passValidity.maxlength = false;
            passValidity.number = false;
            passValidity.specialchar = false;
            passValidity.uppercase = false;
            passValidity.lowercase = false;
          }else{
            passValidity.maxlength = value.length >= 8 ? true:false;
            passValidity.number = isNumberRegx.test(value) ? true: false;
            passValidity.specialchar = specialCharacterRegx.test(value)  ? false:true;
            passValidity.uppercase = upperCaseRegx.test(value) ? false:true;
            passValidity.lowercase = lowerCaseRegx.test(value) ? false:true;
            this.setState({passValidity: passValidity});
            console.log(passValidity)
          }
           
        }

      };
      changePassword = (e) => {
        e.preventDefault();

        let errors = this.state.errors;
   
      const data = {
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        email_phonenumber: this.state.email,
        type:"changePassword",
      };

      errors = Validation(data, errors);
      this.setState({errors:errors});
    

    if(errors.formIsValid){
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios.post(resetpasswordClient,data, CONFIG).then((result)=>{
        let resultData = result.data;
        if(resultData.status === 0){
          this.setState({ error: resultData.message });
        }else{
          console.log(resultData);
          // alert("Password has been reset successfully");
          let formSuccess = {status:"1",message:"“You have successfully reset the password.” Please login!"}
          let errors = {
            
          }
          this.setState({formSuccess:formSuccess,errors})
          setTimeout(
            () =>
            window.location.href = "/",
              2000
            );
          
        }
        
      })   
    }
    

      };

      cancel = () =>
      {
        document.location.href = "/";

      }

      componentDidMount = () => {
        axios
        .post(GetEmailphnumberforDoctor, { id: this.props.match.params.id }, CONFIG)
        .then((res) => {
          let data = res.data;
          console.log(res);
          console.log(data);
          console.log(data.data[0].email);
          if (data.status === "1")
            this.setState({
             email:data.data[0].email
            });
        })
        .catch((e) => { });
  


        console.log(this.props.location.state)
      /*   if(this.props.location.state === undefined){
          window.location.href = "/";
        }else{
       */   // this.setState({registered_detail:this.props.location.state.registered_detail})
     //   }
      };
      handlepassword = (e) => {

        $(".resettoggle-password").toggleClass("fa-eye fa-eye-slash");
        
        let input = $('#resetpassword');
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      }
      handleresetpassword = (e)=>{
        
        $(".confirmtoggle-password").toggleClass("fa-eye fa-eye-slash");
        
        let input = $('#confirmpassword');
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      }
  render() {
    const {
      errors,formSuccess,email
    } = this.state;
    const postData1 = { id: this.props.match.params.id };
      return (
      <div id="layout-wrapper">
        <div className="main-content grayback">
          <div className="mainpage">
            <section className="my-head">
              <div className="container-fluid">
                <div className="row">
                  <LogoSection />
                </div>
              </div>
            </section>

            <section className="main-body bacfot pbdee-50">
              <div className="width-50">
                <div className="">
                  <div
                    id="carouselExampleFade"
                    className="carousel slide carousel-fade"
                    data-ride="carousel"
                    data-interval="2500"
                  >
                    <ol className="carousel-indicators">
                      <li
                        data-target="#carouselExampleFade"
                        data-slide-to="0"
                        className=""
                      ></li>
                      <li
                        data-target="#carouselExampleFade"
                        data-slide-to="1"
                        className="active"
                      ></li>
                      <li
                        data-target="#carouselExampleFade"
                        data-slide-to="2"
                        className=""
                      ></li>
                      <li
                        data-target="#carouselExampleFade"
                        data-slide-to="3"
                        className=""
                      ></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item">
                        <img
                          className="d-block img-fluid"
                          src={require("../assets/images/banner1.jpg")}
                        />
                      </div>
                      <div className="carousel-item active">
                        <img
                          className="d-block img-fluid" alt=""
                          src={require("../assets/images/banner2.jpg") }
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block img-fluid" alt=""
                          src={require("../assets/images/banner3.jpg")}
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block img-fluid"  alt=""
                          src={require("../assets/images/banner4.jpg")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="width-100 imgpart">
                  <div className="row">
                    <div className="col-sm-6 campy">
                      <div>
                        <img  alt="" src={require("../assets/images/banner5.jpg")} />
                      </div>
                      <div>
                        <h4>Company news</h4>
                        <p>
                          {" "}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit sed do adipiscing ..
                        </p>
                        <button>Read More</button>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <img alt="" src={require("../assets/images/banner6.jpg")} />
                      </div>
                      <div>
                        <h4>General news</h4>
                        <p>
                          {" "}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit sed do adipiscing ..
                        </p>
                        <button>Read More</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="width-50">
                <div className="margin-50">
                <div>
                     <h4 class="card-title text-center">RESET PASSWORD</h4>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group row">
                          <div class="col-sm-5">
                            <label>Email / Phone </label>
                          </div>
                          <div class="col-sm-7 form-control graylight">
                          <span>{ email } </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group row">
                          <div class="col-sm-5">
                            <label>Enter your new password </label>
                          </div>
                          <div class="col-sm-7">
                            <InputField
                              isMandatory={true}
                              inputType={"password"}
                              name={"password"}
                              controlFunc={this.handleInput}
                              placeholder={""}
                              id="resetpassword"
                            />
                            <SpanField toggle="#password-field" onClick={this.handlepassword} class="fa fa-fw fa-eye field-icon resettoggle-password"/>
                            <SpanField
                              classname="text-danger"
                              title={errors.password}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group row">
                          <div class="col-sm-5">
                            <label>Re-enter your new password</label>
                          </div>
                          <div class="col-sm-7">
                            <InputField
                              isMandatory={true}
                              inputType={"password"}
                              name={"password_confirmation"}
                              controlFunc={this.handleInput}
                              placeholder={""}
                              id="confirmpassword"
                            />
                            <SpanField toggle="#password-field" onClick={this.handleresetpassword} className="fa fa-fw fa-eye field-icon confirmtoggle-password"/>
                            <SpanField
                              classname="text-danger"
                              title={errors.password_confirmation ||errors.matchPassword || errors.securePassword}
                            />
                            <div class="form-group  narssw">
                            <SpanField
                                                                    classname={
                                                                        0 === formSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={formSuccess.message}
                                                                />
                              <button
                               onClick={this.changePassword.bind(this)}
                                data-toggle="modal"
                                data-target=".bs-example-modal-center"
                                class="signupbtn abtn"
                              >
                                Submit
                              </button>
                              <button
                               onClick={this.cancel}
                               data-toggle="modal"
                                data-target=".bs-example-modal-center"
                                class="signupbtn abtn"
 
                              
                              >
                                Cancel
                              </button>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <ul class="listformat">
                          <li className={this.state.passValidity.lowercase?"listValid":"listInValid"}>
                            It contains at least one lowercase English
                            character.
                          </li>
                          <li className={this.state.passValidity.uppercase?"listValid":"listInValid"}>
                            It contains at least one uppercase English
                            character.
                          </li>
                          <li className={this.state.passValidity.specialchar?"listValid":"listInValid"}>It contains at least one special character. </li>
                          <li className={this.state.passValidity.maxlength?"listValid":"listInValid"}>Its length is at least 8.</li>
                          <li className={this.state.passValidity.number?"listValid":"listInValid"}>It contains at least one digit</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </section>
          </div>
         
        </div>
        <FooterAds />
          <FooterSection />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ResetPassword);
