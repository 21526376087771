import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getAllBusinessUserList } from "../config/url.json";
import Header from "../components/container/layout/client/Header";
import SideBar from "../components/container/SideBar";
import SearchFilter from "../views/SearchFilter";
import axios from "axios";
import { CONFIG } from "../config/data";
import { withRouter } from "react-router-dom";
import FooterSection from "../components/UI/FooterSection";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class ClientAppointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInuserId: props.userdata.id,
      access_token: props.access_token,
      users_list: [
        {
          businessuser_id: "",
          businessuser_name: "",
          speciality: "",
          experience: "",
          address: "",
          fees: "",
          feedback_percentage: "",
          no_of_feedback: "",
        },
      ],
    };
  }

  componentDidMount = () => {
    let users = [];

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;

    axios
      .get(getAllBusinessUserList, CONFIG)
      .then((list) => {
        console.log("buser");
        console.log(list.data);
        let userlist = list.data.data;
        userlist.forEach((element, index) => {
          let user = {};
          user.businessuser_id = element.business_user_id;
          user.businessuser_name = element.business_user_name;
          user.address = element.address;
          user.speciality = element.speciality;
          user.experience = element.experience;
          user.fees = element.fees;
          user.feedback_percentage = element.feedback_percentage;
          user.no_of_feedback = element.no_of_feedback;
          users[index] = user;
        });

        this.setState({ users_list: users });
      })
      .catch((e) => {});
  };

  handleBookAppointment = (e) =>{
    this.props.history.push({
      pathname:"/client_book_appointments",
     /* state:{
        registered_detail:this.state.registered_detail
       }*/
     });
  }

  render() {
    const { users_list } = this.state;
    return (
      <div className="main-content bacfot">
        <div className="mainpage">
          <Header user_id={this.state.loggedInuserId} />

          <section className="main-body hospital-profilegap">
            <div className="container-fluid">
              <div className="checkout-tabs">
                <div className="row">
                  <div className="col-sm-2 pr0">
                    <SideBar />
                  </div>
                  <div className="col-sm-10 pl0">
                    <div className="row">
                      <div className="col-sm-8 pr0">
                        <div className="hmeclr bgligtrn">
                       
                         <h5 class="fnsz hptd">Appointments</h5>
                        
                        </div>

                        <div className="card">
                          <div className="hight-scrol2">
                            <div className="card-body">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  {users_list.map((el, i) => (
                                    <Fragment key={i}>
                                      <div className="profilistds">
                                        <div className="row">
                                          <div className="col-sm-2">
                                            <img
                                              src={require("../assets/images/user1.jpg")}
                                            />
                                          </div>
                                          <div className="col-sm-7">
                                            <h4>{el.businessuser_name}</h4>
                                            <p>{el.speciality}</p>
                                            <p>
                                              {el.experience} experience overall
                                            </p>
                                            <p>
                                              <strong>{el.address}</strong>{" "}
                                              &nbsp;
                                              <i
                                                className="fa fa-dot-circle-o"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              Gold Dental Care + more
                                            </p>
                                            <p>
                                              <i
                                                className="fa fa-inr"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              {el.fees} Consultation free at
                                              client
                                            </p>
                                            <hr />
                                            <p>
                                              <button className="likeds">
                                                <i
                                                  className="fa fa-thumbs-up"
                                                  aria-hidden="true"
                                                  style={{ color: "#fff" }}
                                                ></i>{" "}
                                                &nbsp;{el.feedback_percentage}
                                              </button>{" "}
                                              <a href="#">
                                                <strong>
                                                  {el.no_of_feedback} feedback
                                                </strong>
                                              </a>
                                            </p>
                                          </div>
                                          <div className="col-sm-3">
                                            <div className="aval">
                                              <h5>
                                                <i
                                                  className="fa fa-calendar"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                &nbsp;Available Today
                                              </h5>
                                              <button className="btn btn2" onClick={() => this.handleBookAppointment()}>
                                                Book Appointment <br />{" "}
                                                <p>Instant Pay Available</p>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/** first column */}
                      <SearchFilter />
                    </div>
                    {/*endof first row */}
                  </div>
                </div>
              </div>
            </div>
           
          </section>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ClientAppointments));


