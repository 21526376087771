import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { removeBusinessFromBusinesslist,updateSubscriptionplan,getAllUserSubscriptions,
  getAllSubscriptionPlanList,getAllBasicSubscriptionPlanList,updateBasicSubscriptionplan } from "../config/url.json";
import axios from "axios";
import { CONFIG,time_limit,category } from "../config/data";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import Select from "react-select";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

const status = [
    { value: "Pending", label: "Pending" },
    { value: "Accepted", label: "Accepted" },
];
function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

class SubscriptionPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],
            basicrecords:[],
            actions: "update",
            loading: false,
            showDeleteConfirmPopup: false,
            confirmedDelete: false,
      
             deletePopup: false,
             subscriptionplan:
             {
               id:"",
              subscription_type:"",
              time_limit:"",
              price:"",
              discount:"",
              category:"",

             },
             basicsubscriptionplan:{
              id:"",
              subscription_type:"",
              trial_period:"",
             },
             usersubscriptionlist:[],
             errors: {
                
                subscription_type:"",
                time_limit:"",
                price:"",
                discount:"",
                category:"",
              
            },
            formSuccess: {
                message: "",
                status: "",
              },
        };

     
        this.columns = [
            {
                key: "id",
                text: "ID",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "category",
                text: "Category",
               // className: "category",
                align: "left",
                sortable: true,
            },
            {
                key: "subscription_type",
                text: "Subscription Type",
                className: "subscription_type",
                align: "left",
                sortable: true,
            },
            {
                key: "time_limit",
                text: "Timelimit",
                className: "time_limit",
                align: "left",
                sortable: true,
            },
            {
                key: "price",
                text: "Price",
                className: "price",
                sortable: true,
                align: "left",
            },
            {
              key: "discount",
              text: "Discount%",
              className: "discount",
              sortable: true,
              align: "left",
          },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                    this.setState({ deletePopup: true, record: record })
                                }
                            >
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                },
            },
        ];
        this.basiccolumns = [
        /*   {
              key: "id",
              text: "ID",
              className: "id",
              align: "left",
              sortable: true,
          }, */
        
          {
              key: "subscription_type",
              text: "Subscription Type",
              className: "subscription_type",
              align: "left",
              sortable: true,
          },
          {
              key: "trial_period",
              text: "Trial Period",
              className: "trial_period",
              align: "left",
              sortable: true,
          },
         
          {
              key: "action",
              text: "Action",
              className: "action",
              width: 100,
              align: "left",
              sortable: false,
              cell: (record) => {
                  return (
                      <Fragment>
                          <button
                              className="btn btn-primary btn-sm"
                              onClick={() => this.basiceditRecord(record)}
                              style={{ marginRight: "5px" }}
                          >
                              <i className="fa fa-edit"></i>
                          </button>
                          {/* <button
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                  this.setState({ deletePopup: true, record: record })
                              }
                          >
                              <i className="fa fa-trash"></i>
                          </button> */}
                      </Fragment>
                  );
              },
          },
      ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };

    UpdateColumnChange = (data) => {
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // let request = {
        //     business_user_id: data.user_id, status: data.status
        // }
        // axios.post(updateDoctorStatus1, request).then((list) => {
        let records = this.state.records;

        //     // data = list.data.data;
        //     if (list.data.status === "1") {
        var recIndex = findWithAttr(records, "user_id", data.user_id);

        records[recIndex].status = data.status;

        this.setState({ records, tableSuccess: "" });
        // }

        this.clearMessage();
        // });
    };
    handleselectChange = (selectedOptions, e) => {
      let data = this.state.subscriptionplan;
      data[e.name] = selectedOptions;
  
      this.setState({ subscriptionplan: data });
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

     
    componentDidMount = () => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getAllSubscriptionPlanList, null, CONFIG)
            .then((list) => {
                console.log(list)
                this.setState({ records: list.data.data });
            })
            .catch((e) => { console.log(e) });

            axios.defaults.headers.common[
              "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
              .post(getAllBasicSubscriptionPlanList, null, CONFIG)
              .then((list) => {
                  console.log(list)
                  this.setState({ basicrecords: list.data.data });
              })
              .catch((e) => { console.log(e) });

            axios
            .post(getAllUserSubscriptions, null, CONFIG)
            .then((result) => {
              console.log(result.data);
              if (result.data.status === "1") {
                let usersubscriptionlist = [];
                result.data.data.forEach((element, index) => {
                  let obj = {};
                  obj = element;
                  obj.label = element.subscription_name;
                  obj.value = element.id;
                  usersubscriptionlist[index] = obj;
                });
      
                this.setState({
                  usersubscriptionlist: usersubscriptionlist,
                });
              } else {
                alert("Subscription list empty");
              }
            })
            .catch((e) => {
              console.log(e);
              console.log("business user getbyid failed");
            });
      
    }
    cancel = () => {
        let data = this.state.subscriptionplan;
        data.id="";
        data.subscription_type = "";
        data.category="";
        data.time_limit = "";
        data.price = "";
        data.discount="";
              
        this.setState({ subscriptionplan: data, actions: "update" });
      };
    editRecord = (record) => {
        console.log(record);
        this.setState({ actions: "edit" });
    
        let subscriptionplan = this.state.subscriptionplan;
        subscriptionplan.id=record.id;
        subscriptionplan.category = {
            label: record.category,
            value: record.category,
          };
        subscriptionplan.subscription_type={
        label: record.subscription_type,
        value: record.subscription_type,
      };
      subscriptionplan.time_limit = {
          label: record.time_limit,
          value: record.time_limit,
        };
        subscriptionplan.price=record.price;
        subscriptionplan.discount = record.discount;
        
                
        this.handleSubscriptionPlanChange("id", subscriptionplan.id);
        this.setState({ subscriptionplan: subscriptionplan });
      };
      basiceditRecord = (record) => {
        console.log(record);
        this.setState({ actions: "edit" });
    
        let basicsubscriptionplan = this.state.basicsubscriptionplan;
        basicsubscriptionplan.id=record.id;
      
        basicsubscriptionplan.subscription_type=record.subscription_type;
           
        basicsubscriptionplan.trial_period = record.trial_period;
        
                
        this.handleBasicSubscriptionPlanChange("id", basicsubscriptionplan.id);
        this.setState({ basicsubscriptionplan: basicsubscriptionplan });
      };
      clearAll() {
        let actions = this.state.actions;
        actions = "update";
        this.setState({ actions });
        let subscriptionplan = {};
        subscriptionplan.id="";
        subscriptionplan.category="";
        subscriptionplan.subscription_type="";
        subscriptionplan.time_limit = "";
        subscriptionplan.price = "";
        subscriptionplan.discount="";
        this.setState({ subscriptionplan });
      }
      basicclearAll() {
        let actions = this.state.actions;
        actions = "update";
        this.setState({ actions });
        let basicsubscriptionplan = {};
        basicsubscriptionplan.id="";
        basicsubscriptionplan.subscription_type="";
        basicsubscriptionplan.trial_period = "";
      
        this.setState({ basicsubscriptionplan });
      }
      handleBasicSubscriptionPlanChange(value, field) {
        let fields = this.state.basicsubscriptionplan;
     
        console.log(fields);
         if (value === "subscription_type") {
          fields.subscription_type = field.target.value;
        
        }         
         
        else if (value === "trial_period") {
          fields.trial_period = field.target.value;
       
        }  
      
        this.setState({
          basicsubscriptionplan: fields,
        });
      }
    handleSubscriptionPlanChange(value, field) {
        let fields = this.state.subscriptionplan;
        // let errors = this.state.educationerror;
        console.log(fields);
         if (value === "subscription_type") {
          fields.subscription_type = field.target.value;
          // errors[i].register_id = '';
        }         // errors[i].education = '';
         
        else if (value === "price") {
          fields.price = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "discount") {
          fields.discount = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "category") {
            fields.category = field.target.value;
            // errors[i].education = '';
          }  
         
        else if (value === "time_limit") {
          fields.time_limit = field.target.value;
          // errors[i].education = '';
        
        }                
        this.setState({
            subscriptionplan: fields,
        });
      }
      updateSubscriptionplan=(e)=> {
     // let user_id = record.user_id;
        let { subscriptionplan, errors } = this.state;
        subscriptionplan.type = "subscriptionplan";
        errors = Validation(subscriptionplan, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
          let data = this.state.subscriptionplan;
    
      //  let data = {};
        
        data.id=subscriptionplan.id;
        data.category=subscriptionplan.category.label;
        data.subscription_type=subscriptionplan.subscription_type.label;
        data.time_limit=subscriptionplan.time_limit.label;
        data.price=subscriptionplan.price;
        data.discount=subscriptionplan.discount;
        console.log(data);
        axios.put(updateSubscriptionplan, data, CONFIG).then((list) => {
         console.log(list);
         console.log(list.data);
          let response=list.data;
          if (response.status === "1") {

          let member = {};

          member.id=data.id;
          member.category=data.category;
          member.subscription_type=data.subscription_type;
          member.time_limit = data.time_limit;
          member.price = data.price;
          member.discount=data.discount;
         
          let records = this.state.records;

          /** Updating the data table */
          let existingRecords = this.state.records;
          var index = existingRecords.findIndex((item) => item.id === data.id);
          existingRecords.splice(index, 1, member);
          this.setState({ records: existingRecords });

          this.setState({ records });
          this.setState({ formSuccess: list.data });
          this.clearAll();
          this.clearMessage();
         
          }
        });
      }
      }

      updateBasicSubscriptionplan=(e)=> {
        // let user_id = record.user_id;
           let { basicsubscriptionplan, errors } = this.state;
           basicsubscriptionplan.type = "basicsubscriptionplan";
           errors = Validation(basicsubscriptionplan, errors);
           this.setState({ errors: errors });
           if (errors.formIsValid) {
             let data = this.state.basicsubscriptionplan;
       
         //  let data = {};
           
           data.id=basicsubscriptionplan.id;
           data.subscription_type=basicsubscriptionplan.subscription_type;
        
           data.trial_period=basicsubscriptionplan.trial_period;
         
           console.log(data);
           axios.put(updateBasicSubscriptionplan, data, CONFIG).then((list) => {
            console.log(list);
            console.log(list.data);
             let response=list.data;
             if (response.status === "1") {
   
             let member = {};
   
             member.id=data.id;
             member.subscription_type=data.subscription_type;
             member.trial_period = data.trial_period;
          
            
             let basicrecords = this.state.basicrecords;
   
             /** Updating the data table */
             let existingRecords = this.state.basicrecords;
             var index = existingRecords.findIndex((item) => item.id === data.id);
             existingRecords.splice(index, 1, member);
             this.setState({ basicrecords: existingRecords });
   
             this.setState({ basicrecords });
             this.setState({ formSuccess: list.data });
             this.basicclearAll();
             this.clearMessage();
            
             }
           });
         }
         }
      deleteRecord(record) {
      
        let id = record.id;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(removeBusinessFromBusinesslist, { id: id }, CONFIG)
          .then((res) => {
            let response = res.data;
            if (response.status === "1") {
              let records = this.state.records;
    
              const deleteIndex = records.map((item) => item.id).indexOf(record.id);
              records.splice(deleteIndex, 1);
              this.setState({ records });
              this.setState({ tableSuccess: res.data });
              this.clearMessage();
            }
          })
          .catch((e) => { });
        this.setState({ deletePopup: false, record: null });
    
        this.setState({
          confirmedDelete: false,
        });
        axios.defaults.headers.common[
          "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
          .post(getAllSubscriptionPlanList, null, CONFIG)
          .then((list) => {
              console.log(list)
              this.setState({ records: list.data.data });
          })
          .catch((e) => { console.log(e) });

      }

    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            subscriptionplan,
            basicsubscriptionplan,
            formSuccess,
            tableSuccess,
            errors,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Subscription Plan </h4>
                                    <div className="row mt-4">

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                            show={this.state.deletePopup}
                                                             onHide={() =>
                                                             this.setState({
                                                              deletePopup: false,
                                                               })
                                                               }
                                                             onConfirm={() =>
                                                             this.deleteRecord(this.state.record)
                                                             }
                                                            title={"Confirmation"}
                                                              message={
                                                               "Are you sure you want to delete this record?"
                                                               }
                                                             />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                            <div className="card">
                            <div class="card-header"> <div class="card-title">       {" "}
                                    {this.state.actions === "Edit"
                                      ? "Edit"
                                      : "Edit"}{" "}
                                    Subscription Plan</div> </div>
                              <div className="card-body">
                                <div>
                                
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                   
                                  <div className="col-sm-4">
                                   
                                      <div>
                                        <div>
                                          <label>Category</label>
                                        </div>
                                        <div className="form-group sform-control">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="time_limit"
                                            value={subscriptionplan.category}
                                            onChange={this.handleselectChange}               
                                            options={category}
                                          />
                                        </div>
                                        <SpanField
                                                classname="text-danger"
                                                title={errors.category}
                                              />
                                      </div>
                                    </div>

                                  <div className="col-sm-4">
                                      <div>
                                        <label>Subscription Type</label>
                                      </div>
                                      <div className="form-group sform-control">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="subscription_type"
                                          value={subscriptionplan.subscription_type}
                                          onChange={this.handleselectChange}
                                          options={this.state.usersubscriptionlist}
                                        />
                                      </div>
                                      <SpanField
                                                classname="text-danger"
                                                title={errors.subscription_type}
                                              />
                                    </div>

                                   
                                    <div className="col-sm-4">
                                    
                                      <div>
                                        <div>
                                          <label>Time Limit</label>
                                        </div>
                                        <div className="form-group sform-control">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="time_limit"
                                            value={subscriptionplan.time_limit}
                                            onChange={this.handleselectChange}               
                                            options={time_limit}
                                          />
                                        </div>
                                        <SpanField
                                                classname="text-danger"
                                                title={errors.time_limit}
                                              />
                                      </div>
                                    </div>
                                   
                                    <div className="col-lg-4">
                                    <div>
                                        <label>Price</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"price"}
                                          controlFunc={this.handleSubscriptionPlanChange.bind(this,"price")}
                                          content={subscriptionplan.price}
                                          placeholder={""}
                                          max={50}
                                        />
                                 
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.price}
                                      /> 
                                     
                                    </div>

                               
                                     

                                    </div>
                                   
                                    <div className="col-lg-4">
                                    <div>
                                        <label>Discount %</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"discount"}
                                          controlFunc={this.handleSubscriptionPlanChange.bind(this,"discount")}
                                          content={subscriptionplan.discount}
                                          placeholder={""}
                                          max={50}
                                        />
                                  
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.discount}
                                      /> 
                                     
                                    </div>

                                
                                     

                                    </div>

                                                                   
                                   
                                   

                                   

                                  
                                  
                                  </div>
                                  <div className="row">
                                  <div className="col-sm-12">

<div className="form-group mt2rem mb-0 float-right">
<button
    ref="btn"
    href="patients-register"
    className="signupbtn abtn btdpml btn-margin"
    onClick={this.updateSubscriptionplan}>
                                           
    {loading ? (
      <LoadingSpinner title="Update" />
    ) : this.state.actions === "Update" ? (
      "Update"
    ) : (
      "Update"
    )}
  </button>
  <button
    href="patients-register"
    className="signupbtn abtn btdpml"
    onClick={this.cancel}
  >
    Cancel
  </button>
</div>
</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                            <div class="card-header"> <div class="card-title">       {" "}
                                   
                                   Basic Subscription Plan</div> </div>
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.basicrecords}
                                                                columns={this.basiccolumns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                            show={this.state.deletePopup}
                                                             onHide={() =>
                                                             this.setState({
                                                              deletePopup: false,
                                                               })
                                                               }
                                                             onConfirm={() =>
                                                             this.deleteRecord(this.state.record)
                                                             }
                                                            title={"Confirmation"}
                                                              message={
                                                               "Are you sure you want to delete this record?"
                                                               }
                                                             />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                            <div className="card">
                            <div class="card-header"> <div class="card-title">       {" "}
                            {this.state.actions === "Edit"
                                      ? "Edit"
                                      : "Edit"}{" "}
                                   Basic Subscription Plan</div> </div>
                              <div className="card-body">
                                <div>
                                
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                  
                             
                                    <div className="col-sm-4">
                                    <div>
                                        <label>Subscription Type</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"subscription_type"}
                                          controlFunc={this.handleBasicSubscriptionPlanChange.bind(this)}
                                          content={basicsubscriptionplan.subscription_type}
                                          placeholder={""}
                                          max={50}
                                        />
                                
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.subscription_type}
                                      /> 
                                     
                                    </div>
                                   </div>
                                    <div className="col-sm-4">
                                    <div>
                                        <label>Trial Period</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"trial_period"}
                                          controlFunc={this.handleBasicSubscriptionPlanChange.bind(this,"trial_period")}
                                          content={basicsubscriptionplan.trial_period}
                                          placeholder={""}
                                          max={50}
                                        />
                                 
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.trial_period}
                                      /> 
                                     
                                    </div>

                               
                                     

                                    </div>
                                  
                                                                   
                                   
                                   

                                   

                                  
                                  
                                  </div>
                                  <div className="row">
                                  <div className="col-sm-12">

<div className="form-group mt2rem mb-0 float-right">
<button
    ref="btn"
    href="patients-register"
    className="signupbtn abtn btdpml btn-margin"
    onClick={this.updateBasicSubscriptionplan}>
                                           
    {loading ? (
      <LoadingSpinner title="Update" />
    ) : this.state.actions === "Update" ? (
      "Update"
    ) : (
      "Update"
    )}
  </button>
  <button
    href="patients-register"
    className="signupbtn abtn btdpml"
    onClick={this.basicclearAll}
  >
    Cancel
  </button>
</div>
</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                                        </div>

                                    </div>
                                </div>
                             
                            </section>
                            <FooterSection />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
)(SubscriptionPlan);