import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/client/Header";
import axios from "axios";
import { getBusinessById, markAsFavourite, UpdateQueueRemove, getQEntryById, checkFavorite, createNotification } from "../config/url.json";
import { CONFIG } from "../config/data";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FooterSection from "../components/UI/FooterSection";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    user: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}



class ScheduleConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelModel: false,

      access_token: props.access_token,
      business_id: null,
      client_id: this.props.user.id,
      appointment_time: "",
      wait_time: "",
      direction_link: "",
      business_name: "",
      business_address: "",
      fav_class: "heart fa fa-heart-o",
      queue_data: {}
    };
  }
  componentDidMount() {

    let clientapppointmentId = this.props.match.params.id;
    if (clientapppointmentId !== null) {


      axios.defaults.headers.common["Authorization"] = `Bearer ${this.state.access_token}`;

      axios.post(getQEntryById, { q_id: clientapppointmentId }, CONFIG)
        .then((appt) => {
          console.log('app');

          if (!appt.data.data.length) {
            window.location.href = "/search"
          }
          let apptdata = appt.data.data[0];
          console.log(apptdata.business_id);
          console.log(apptdata);
          this.setState({ business_id: apptdata.business_id,first_name:apptdata.first_name,last_name:apptdata.last_name });
          this.setState({ appointment_time: apptdata.expected_time });
          this.setState({ wait_time: apptdata.waitTime });
          this.setState({ queue_data: apptdata });
          if (apptdata.business_id) {
            axios
              .post(getBusinessById, { business_id: apptdata.business_id }, CONFIG)
              .then((res) => {
                if (res.data.data) {
                  let responseData = res.data.data[0];
                  let direction_link = responseData.google_loc_id;
                  let latitude=responseData.latitude;
                  let longitude=responseData.longitude;
                  let business_name = responseData.business_name;
                  let business_address = responseData.address1 + ',' + responseData.city + ',' + responseData.country;
                  this.setState({ latitude });
                  this.setState({ longitude });
                  this.setState({ direction_link });
                  this.setState({ business_name });
                  this.setState({ business_address });

                }

              })
              .catch((e) => {
                if (e.response) {

                }
              });
            axios.defaults.headers.common["Authorization"] = `Bearer ${this.state.access_token}`;
            let data = { client_id: this.state.client_id, business_id: apptdata.business_id }
            axios.post(checkFavorite, data, CONFIG)
              .then((response) => {
                console.log(response)
                let fav_class = '';
                if (response.data.status === "1") {
                  fav_class = "fa fa-heart";
                } else {
                  fav_class = "heart fa fa-heart-o";
                }
                this.setState({ fav_class: fav_class });
              })
              .catch(() => {
                console.log("Get appoinment by id failed");
              });
          }
        })
        .catch(() => {
          console.log("Get appoinment by id failed");
        });


    }





  }
  markAsFavourite = () => {
    let { business_id, client_id } = this.state;

    if (business_id !== null) {
      let data = { client_id: client_id, business_id: business_id };

      axios.defaults.headers.common["Authorization"] = `Bearer ${this.state.access_token}`;

      axios
        .post(markAsFavourite, data)
        .then((businessData) => {
          let fav_class = this.state.fav_class;
          if (businessData.data.status === "1") {
            console.log("business added as favorite");
            fav_class = "fa fa-heart";
          } else {
            fav_class = "heart fa fa-heart-o";
          }
          this.setState({ fav_class: fav_class });

        })
        .catch(() => {
          console.log("business list failed");
        });
    }
  };

  getDirections = () => {
    //let business_id = "cb668490-ba40-11ea-b857-7b3a574a5218";

   // let direction_link = this.state.direction_link;
    let latitude=this.state.latitude;
    let longitude=this.state.longitude;
    window.open("https://www.google.com/maps/search/?api=1&query="+latitude+","+longitude, "_blank")

    // axios
    // .post(getBusinessById, { business_id: business_id }, CONFIG)
    // .then((res) => {
    //   if(res.data.data){
    //     let responseData = res.data.data[0];
    //   let direction_link =  responseData.google_loc_id;
    //   this.setState({direction_link});
    //   window.open(direction_link, "_blank")
    //   }
    // })
    // .catch((e) => {
    //   if (e.response) {
    //     let errors = e.response.data.errors;

    //   }
    // });
  }
  closePopup = () => {
    this.setState({
      cancelModel: false,
    });
  };

  cancelAppointmentandQueue() {
    this.setState({
      cancelModel: true,
    })
    // let clientapppointmentId = this.props.match.params.id;

    // axios.delete(queueURL + clientapppointmentId)
    //   .then((res) => {

    //   })
    //   .catch((e) => {
    //     console.log("cancel appointment failed");
    //   });


  }
  conformCancelAppointment = () => {
    let queue_data = this.state.queue_data
    console.log(this.state.queue_data); //return false;
    let queuedata = {
      "n_business_id": queue_data.business_id,
      "n_business_user_id": queue_data.business_user_id,
      "n_schedule_day_id": queue_data.schedule_day_id,
      "n_q_id": queue_data.q_id,
      "n_status": "D",
      "n_schedule_type": "D",
      "n_preference": 1
    }
    // {
    //   "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
    //     "n_schedule_day_id": "17a5fc03-4426-11eb-9047-0ad8e4601dac",
    //       "n_status": "D",
    //         "n_business_user_id": "122c2790-4426-11eb-b734-1d4f488af3f3",
    //           "n_schedule_type": "D",
    //             "n_q_id": "dda2c67c-c6e0-4743-9055-0780f7206108",
    //               "n_preference": 1
    // }
    let notificationdata = {
      message_from: queue_data.business_id,
      message_to: this.props.user.id,
      message: "Token has been cancelled successfully.",
      link: "/dashboard",
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(UpdateQueueRemove, queuedata)
      .then(() => {
        axios.post(createNotification, notificationdata).then(() => {
          toast.success("You Appointment has been cancelled successfully ", {
            className: "toast-success",
            });
          // console.log(res)
          setTimeout(
            () =>
              
                window.location.href = '/search'
            ,
            1000
          );
          // window.location.href = "/search"

        }).catch(() => {

        })
      }).catch(() => {
        console.log("Update queue failed");
      });
  };
  render() {


    //     var now = new Date()
    // var date = now.toLocaleDateString();
    // var time = now.toLocaleTimeString();
    // console.log(date + ' ' + time);

    const appointment_datetimeStr = this.state.appointment_time;
    var appointment_datetimeDate = new Date(appointment_datetimeStr);

    console.log('render');
    console.log(this.state);


    return (
      <Fragment>
        <div id="layout-wrapper">
          <div className="main-content bacfot">
            <div className="mainpage">
              <Header user_id={this.props.user.id} />
              <div className="main-box">
                <div className="sidemwnu">
                  <SideBar />
                </div>

                <section className="main-body hospital-profilegap">
                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                  <div className="container-fluid">
                    <div className="checkout-tabs">
                      <div className="row">

                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="hmeclr bgligtrn">
                                <ul
                                  className="nav nav-pills manag"
                                  role="tablist"
                                >
                                  <li className="nav-item waves-effect waves-light">
                                    <Link
                                      className="nav-link active btnview widthauto"
                                      to="#"
                                    >
                                      <span className="d-block d-sm-none">
                                        {" "}
                                        Queue-in{" "}
                                      </span>
                                      <span className="d-none d-sm-block">
                                        {" "}
                                        Queue-in{" "}
                                      </span>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="card a-page-3-hight1">
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="row">
                                        <div className="col-sm-12 a-page-4hospital">

                                          <h4>{this.state.business_name}</h4>
                                          <h5>{this.state.business_address}</h5>
                                          <p>
                                            Thank you for using our service! ”Your Appointment has been confirmed.”
                                          </p>
                                          <p>
                                          Please let us know of your arrival so we can complete your check-in.
                                          </p>
                                          <p> <b>{this.state.first_name}&nbsp;{this.state.last_name}</b> your Appointment is at:{this.state.appointment_time ? moment(this.state.appointment_time).format("hh:mm A") : ''}</p>
                         
                                          <p>   Estimated wait time : {moment.duration(this.state.wait_time).asMinutes() > 0  ? moment.duration(this.state.wait_time).asMinutes().toFixed() + ' minutes':"0 minutes"}</p>
                                          <div>
                                            <p>{
                                              this.state.queue_data.status !== 'Cancelled' ? (< button className="cancelbtn" onClick={this.cancelAppointmentandQueue.bind(this)}>
                                                <i
                                                  className="fa fa-ban"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                &nbsp; Cancel Appointment
                                              </button>
                                              ) : this.state.queue_data.status

                                            }
                                            </p>
                                            <p>
                                              <button
                                                onClick={this.markAsFavourite}
                                                className="favoritebtn"
                                              >
                                                <i
                                                  // className="fa fa-heart"
                                                  className={this.state.fav_class}
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                &nbsp;Mark as favorite
                                              </button>
                                            </p>
                                            <p>
                                              <button target="_blank" className="directionsbtn signupbtn" onClick={this.getDirections.bind(this)}>
                                                <i
                                                  className="fa fa-map-marker"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                &nbsp;Get Directions
                                              </button>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Modal
                                show={this.state.cancelModel}
                                onHide={this.closePopup}
                                centered={true}
                              >
                                <Modal.Header
                                  closeButton
                                  className="model-hptd"
                                >
                                  <Modal.Title className="h5 model-text-color">
                                    Cancel Appointment
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <div>
                                    <p>
                                      Are you sure want to cancel the appointment ?
                                    </p>

                                    <div className="row justify-content-center">
                                    <div className="form-group mb-0 text-right">
                                        <button
                                          className="grnbtn reddg"
                                          onClick={
                                            this.conformCancelAppointment
                                          }
                                        >
                                          Yes
                                        </button>
                                      </div>
                                      &nbsp; &nbsp;
                                    <div className="form-group mb-0 text-center">
                                        <button
                                          className="grnbtn mr-2"
                                          onClick={() => {
                                            this.setState({
                                              cancelModel: false,
                                              cancelRecord: {},
                                            });
                                          }}
                                        >
                                          No
                                        </button>
                                      </div>
                                     
                                      
                                     
                                    </div>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                          {/* <Footerad /> */}
                        </div>
                      </div>
                    </div>
                  </div>
             

                </section>
                <FooterSection />
              </div>
            </div>
          </div>
        </div>
      </Fragment >
    );
  }
}

export default connect(mapStateToProps)(ScheduleConfirmation);
