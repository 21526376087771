import React from 'react';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import "../../assets/css/mystyle.css";
import {GetAppointmentCompletedReport } from "../../config/url.json";
import axios from "axios";
import { CONFIG } from "../../config/data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const columns = [
 
 
  { header: 'Business Name', key: 'business_name' },
  { header: 'Doctor Name', key: 'doctor_name' },
  { header: 'Patient ID', key: 'Patient_id' },
  { header: 'Patient Name', key: 'patient_name' }, 
  { header: 'Email', key: 'email' },
  { header: 'Date Of Birth', key: 'date_of_birth' },
  { header: 'Gender', key: 'gender' },
  { header: 'Blood Group', key: 'blood_group' },
  { header: 'Phone Number', key: 'phone_number' },
  { header: 'Check In Date', key: 'ApptDate' },
  { header: 'Check In time', key: 'ApptTime' },
  { header: 'Check out time', key: 'ApptEndTime' },
 
 
 
];

const workSheetName = 'Worksheet-1';
const workBookName = 'ApptVisitList';
const myInputId = 'myInput';
const workbook = new Excel.Workbook();
export default function App(props) {

   const saveExcel = async () => {
    try {
     
      const myInput = document.getElementById(myInputId);
      const fileName =  workBookName;
    
        const newdata=props.records;
   
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);
    //  console.log(typeof(data));
      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 10;
        column.alignment = { horizontal: 'center' };
      });

      // loop through data and add each one to worksheet
      newdata.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });
    

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();
     
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
      toast.success("Appointment visit list has been downloaded successfully.",{
        className:"toast-success"
      });
   
      
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }

  };

  return (
    <>
      <style>
        {`
          table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            textAlign: center;
          }
           th, td { 
             padding: 4px;
           }
        `}
      </style>
      <div>
    
      <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

     
        <div>
          <button  className="signupbtn abtn btdpml btn-margin" onClick={saveExcel}>Download</button>
        </div>

       

     {/*    <div>
          <table style={{ margin: '0 auto' }}>
            <tr>
              {columns.map(({ header }) => {
                return <th>{header}</th>;
              })}
            </tr>

            {data.map((uniqueData) => {
              return (
                <tr>
                  {Object.entries(uniqueData).map((eachData) => {
                    const value = eachData[1];
                    return <td>{value}</td>;
                  })}
                </tr>
              );
            })}
          </table>
        </div> */}
      </div>
    </>
  );
}
