import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import {
    GetFollowupData,
    getBusinessforBusinessUser, getConsultingDoctors,getTimeoutbycategory,doctorUpcomingappointment
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Header from "../components/container/layout/business_user/Header";
import SideBar from "../components/container/SideBar";
import FooterSection from "../components/UI/FooterSection";
import Spinner from "../components/UI/Spinner";
import SpanField from "../components/UI/SpanField";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";
import Select from "react-select";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import Dnaspinner from '../components/UI/Dnaspinner';

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
      logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
  }
  
class DoctorAppointment extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const timeout=this.state;
    
        this.state = {
            timeout:timeout,
            isTimedOut: false,
            showModal: false,
            loading: false,
            business_id: "",
            records: [],
            doc_list: [],
            selected_doctor: "",
            doctor_shift: [],
            selected_shift: "",
            tableSuccess: {
                message: "",
                status: "",
            },
            btndisabled: false,
        }
        this.appointmentcolumns = [
            {
                key: "preference",
                text: "Appoinment No",
                className: "preference",
                align: "left",
                sortable: true,
            },
            {
                key: "id",
                text: "Patient ID",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "patient_name",
                text: "Patient Name",
                className: "patient_name",
                align: "left",
                sortable: true,
            },
            {
                key: "blood_group",
                text: "Blood Group",
                className: "blood_group",
                align: "left",
                sortable: true,
            },
            {
                key: "phone_number",
                text: "Phone Number",
                className: "phone_number",
                align: "left",
                sortable: true,
            },
            {
                key: "other_comments",
                text: "Reason For Visit",
                className: "other_comments",
                align: "left",
                sortable: true,
            },
            {
                key: "appt_date",
                text: "Appointment Date",
                className: "appt_date",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.appt_date).format("DD-MM-yyyy");
                },
            },
          
            {
                key: "appt_time",
                text: "Appointment Time",
                className: "appt_time",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.appt_time ? moment(record.appt_time).format("hh:mm A") : "";
                },
            },
            // {
            //     key: "wait_time",
            //     text: "Wait Time",
            //     className: "wait_time",
            //     align: "left",
            //     sortable: true,
            //     cell: (record) => {
            //         return record.wait_time ? moment.duration(record.wait_time).asMinutes().toFixed() + ' minutes' : "";
            //     },
            // },
            {
                key: "appt_status",
                text: "Status",
                className: "appt_status",
                align: "left",
                sortable: true,
            }, {
                key: "Action",
                text: "Action",
                className: "action",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    // console.log(i)
                    return (<td className="checkbtnou">
                        <button onClick={this.handleAction.bind(this, record, i)} disabled={(this.state.btndisabled && record.appt_status !== 'Consulting') || i !== 0 ? true : false} className="">
                            {record.appt_status === 'Check-In' ? 'Admit' : record.appt_status}</button>
                    </td>)
                }
            },
            {
                key: "Meeting",
                text: "Meeting",
                className: "meeting",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    // console.log(i)
                    return (
                        <a target="_blank" rel="noopener noreferrer" href={"https://dqqpa02ik3.execute-api.ap-south-1.amazonaws.com/Prod?m=" + record.client_appt_id + '&name=' + this.props.userdata.first_name + '&type=d'}
                        //onClick={() => this.gotoappointment(data)}
                        >Join</a>
                    )
                }
            },
        ];
      
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
        this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    }

    handleClose() {
        this.setState({showModal: false})
      }
    
     
       handleLogout = (e) => {
        this.setState({showModal: false})
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();
       
    };
    
      _onAction(e) {
        console.log('user did something', e)
        
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        
        console.log('user is active', e)
        this.setState({isTimedOut: false})
      }
       
      _onIdle = (e) =>  {
        console.log('user is idle', e)
        let isTimedOut  = this.state.isTimedOut
        let timeout=this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) {
        console.log("the time is over");
        this.setState({showModal: true})
       
         }
      }
     

      handleAction = (record, index, e) => {

        // console.log(record);
        // return false;
        this.setState({ loading: true })
        // axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
        // let clientapppointmentId = record.q_id;
        // axios.get(getQueueByid + clientapppointmentId)
        //     .then((queue_data) => {
        //         console.log(queue_data);

        // if (queue_data.data !== '' && queue_data.data != null) {
        // let queuein_data = queue_data.data
        // queuein_data.status = 'Consulting';
        // let clientlist = [];
        // clientlist.push(record.client_id);
        // queuein_data.clientList = clientlist;
        // console.log(queuein_data);
        // // queuein_data.clientList = [queuein_data.data.client_id];
        this.setState({ queue_data: record })
     

        // {
        //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
        //     "n_schedule_day_id": "f4f392a4-bdc8-11eb-97e3-0ad8e4601dac",   
        //     "n_status": "Check-In",   
        //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
        //     "n_schedule_type": "Queue",
        //     "n_q_id":"8c9d1193-aa87-4907-bb5b-96c6e3e441bd"
        // }
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
       // axios
          //  .post(updateQueueStatus, queuedata)
           // .then((response) => {
                // console.log(response); return false;
           //     this.setState({ loading: false });
                // socketObj.emit('queueStatusChanged', () => {});
           //     this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
                window.location.href = "/appointment/" + record.id



                // this.props.history.push("/consult");

         //   }).catch((e) => {
           //     console.log(e);
            //    console.log("Update queue failed");
           // });
        // }
        // }).catch((e) => {
        //     console.log("Get appoinment by id failed");
        // });


        // if (index === 0) {
        // this.props.history.push("/consult/" + record.checkin_id);
        // }
    }
      
   /*  hanldeAction = (record, index, e) => {

        // console.log(record);
        // return false;
        this.setState({ loading: true })
        // axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
        // let clientapppointmentId = record.q_id;
        // axios.get(getQueueByid + clientapppointmentId)
        //     .then((queue_data) => {
        //         console.log(queue_data);

        // if (queue_data.data !== '' && queue_data.data != null) {
        // let queuein_data = queue_data.data
        // queuein_data.status = 'Consulting';
        // let clientlist = [];
        // clientlist.push(record.client_id);
        // queuein_data.clientList = clientlist;
        // console.log(queuein_data);
        // // queuein_data.clientList = [queuein_data.data.client_id];
        this.setState({ queue_data: record })
        let queuedata = {
            "n_business_id": record.business_id,
            "n_business_user_id": this.props.userdata.id,
            "n_schedule_day_id": record.schedule_day_id,
            "n_q_id": record.q_id,
            "n_status": "Consulting",
            "n_preference": record.preference,
            "n_schedule_type": "Queue"
        }

        // {
        //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
        //     "n_schedule_day_id": "f4f392a4-bdc8-11eb-97e3-0ad8e4601dac",   
        //     "n_status": "Check-In",   
        //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
        //     "n_schedule_type": "Queue",
        //     "n_q_id":"8c9d1193-aa87-4907-bb5b-96c6e3e441bd"
        // }
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(updateQueueStatus, queuedata)
            .then((response) => {
                // console.log(response); return false;
                this.setState({ loading: false });
                // socketObj.emit('queueStatusChanged', () => {});
                this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
                window.location.href = "/consult/" + record.checkin_id



                // this.props.history.push("/consult");

            }).catch((e) => {
                console.log(e);
                console.log("Update queue failed");
            });
        // }
        // }).catch((e) => {
        //     console.log("Get appoinment by id failed");
        // });


        // if (index === 0) {
        // this.props.history.push("/consult/" + record.checkin_id);
        // }
    } */
    componentDidMount = () => {



        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
         axios
          .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status === "1")
              this.setState({
                timeout: data.data.timeout
                
              });
            })
          .catch((e) => { });
    

        this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        let urlbu =
            getBusinessforBusinessUser +
            "?business_user_id=" +
            this.props.userdata.id;
        axios
            .get(urlbu, null, CONFIG)
            .then((res) => {
                let data = res.data;
                // console.log(data.data);

                let doc_list = [];
                data.data.forEach((e, index) => {
                    // console.log(element)

                    let doctor_shift = [];
                    const reqdata = {
                        business_id: e.business_id,
                        // n_currentdate: getDateISOFormat(new Date()),
                    };
                    axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${this.props.access_token}`;
                    axios
                        .post(getConsultingDoctors, reqdata)
                        .then((result) => {

                            // console.log(JSON.parse(JSON.stringify(result.data.data[0].doctor_Appointments)), typeof result.data.data[0].doctor_Appointments)
                            result.data.data.forEach((element, index) => {
                                if (element.business_user_id === this.props.userdata.id) {                                    
                                   // if (element.schedule_type !== 'appointment')
                                        doc_list.push({ value: element.business_id, label: element.business_name, doctor_Appointments: element.doctor_Appointments });
                                }

                            });
                            // console.log(JSON.parse(JSON.stringify(doc_list[0].doctor_Appointments)));


                            if(doc_list.length){
                            doc_list[0].doctor_Appointments.forEach((doc, k) => {
                                doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
                            })
                        }



                            this.setState({ doctor_appointment: result.data.data, doc_list: doc_list, selected_doctor: doc_list[0], doctor_shift: doctor_shift, selected_shift: doctor_shift[0], loading: false, business_id: data.data[0].business_id });
                            this.GetFollowupData(this.state.doc_list[0].value, this.props.userdata.id, this.state.doctor_shift[0].value);
                       
                            axios
                            .post(doctorUpcomingappointment, { business_user_id: this.props.userdata.id,business_id: data.data[0].business_id }, CONFIG)
                            .then((result) => {
                              console.log(result);
                              if (result.data.status === "1") {
                                console.log(result.data.data);
                                this.setState({ doctorupcoming: result.data.data })
                              } else {
                                this.setState({ doctorupcoming: [] })
                              }
                            }).catch((err) => {
                              console.log('error')
                            })
                


                        })
                        .catch((e) => { console.log(e); });
                });
                this.setState({ loading: false })


            }).catch((e) => {
                this.setState({ loading: false })
            })
           
        /*     axios
            .post(doctorUpcomingappointment, { business_user_id: this.props.userdata.id,business_id: selected_doctor.value }, CONFIG)
            .then((result) => {
              console.log(result);
              if (result.data.status === "1") {
                console.log(result.data.data);
                this.setState({ doctorupcoming: result.data.data })
              } else {
                this.setState({ doctorupcoming: [] })
              }
            }).catch((err) => {
              console.log('error')
            })

 */

    };

    GetFollowupData = (business_id, business_user_id, schedule_day_id) => {
        this.setState({ loading: true })
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(GetFollowupData, { business_id: business_id, business_user_id: business_user_id, schedule_day_id: schedule_day_id }, CONFIG)
            .then((list) => {
                this.setState({ loading: false })
                let btndisabled = false
                list.data.data.forEach((element) => {
                    if (element.status === 'Consulting') {
                        btndisabled = true;
                    }
                })
                this.setState({ records: list.data.data, btndisabled: btndisabled, loading: false });
            })
            .catch((e) => { this.setState({ loading: false }) });
    }
    handleselectChange = (selectedoption, event) => {

        let { doc_list, selected_doctor } = this.state;
        let doctor_shift = [];
        if (event.name === "doc_list") {

            doc_list[event.name] = selectedoption
            // console.log(selectedoption);

            selectedoption.doctor_Appointments.forEach((doc, k) => {
                doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
            })

            this.setState({ doc_list: doc_list, selected_doctor: selectedoption, doctor_shift: doctor_shift, selected_shift: doctor_shift[0] });
           // this.GetFollowupData(selectedoption.value, this.props.userdata.id, doctor_shift[0].value)
          
        }
        if (event.name === 'doctor_shift') {

            this.setState({ selected_shift: selectedoption })

            // console.log(selected_doctor.value, this.props.userdata.id, selectedoption.value)
         //   this.GetFollowupData(selected_doctor.value, this.props.userdata.id, selectedoption.value)
        
        }
    }
    render() {
        const {

            tableSuccess,
            loading, doc_list,
            timeout
        } = this.state;
        return (
            <div id="layout-wrapper">
                <Header user_id={this.props.userdata.id} />
              {/*   <Spinner loading={loading} /> */}
              {loading && <Dnaspinner />}
                <div className="main-content bacfot">
                    <div className="mainpage">
                        <div className="main-box">


                            <div className="sidemenu">
                                <SideBar />
                            </div>
                            <section className="main-body hospital-profilegap">
                            <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />
                                <div className="container-fluid">
                                    <div className="checkout-tabs">
                                        <div className="row">

                                        <div className="col-lg-4">
                                                <h4 className="font-weight-bold">Doctor Appointments</h4>
                                            </div>
                                            <div className="col-lg-4">
                                                <Select maxMenuHeight={120}
                                                    className="form-control mt-2 queuedropdown"
                                                    name="doc_list"
                                                    // value={selected_queue.first_name}
                                                    onChange={
                                                        this.handleselectChange
                                                    }
                                                    value={this.state.selected_doctor}
                                                    options={doc_list}
                                                // isClearable={true}
                                                />
                                            </div>
                                           {/*  <div className="col-lg-4">
                                                <Select maxMenuHeight={120}
                                                    className="form-control mt-2 queuedropdown"
                                                    name="doctor_shift"
                                                    // value={selected_queue.first_name}
                                                    onChange={
                                                        this.handleselectChange
                                                    }
                                                    value={this.state.selected_shift}
                                                    options={this.state.doctor_shift}
                                                // isClearable={true}
                                                />
                                            </div> */}
                                           
                                           
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-body">


                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="v-pills-shipping"
                                                                role="tabpanel"
                                                                aria-labelledby="v-pills-shipping-tab"
                                                            >
                                                                <div className="user-notification">
                                                                    <SpanField
                                                                        classname={
                                                                            0 === tableSuccess.status
                                                                                ? "text-danger user-notification"
                                                                                : "text-success user-notification"
                                                                        }
                                                                        title={tableSuccess.message}
                                                                    />
                                                                </div>
                                                                <ReactDatatable
                                                                    config={this.config}
                                                                    records={this.state.doctorupcoming}
                                                                    columns={this.appointmentcolumns}
                                                                    className="table table-bordered table-striped kas  mobile_table"
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                {/* <Footerad /> */}
                             
                            </section>
                                <FooterSection />
                        </div>

                       

                    </div>


                </div>

            </div >

        );

    }
}

export default connect(
    mapStateToProps,mapDispatchToProps
)(DoctorAppointment);