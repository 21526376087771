import React, {Component} from 'react';
//import '../../App.scss';
import "../../assets/css/mystyle.css";
import Participant from './Participant';
import { withRouter } from "react-router-dom";
import { connect as connect1 } from "react-redux";
import OtpTimer from 'otp-timer'
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    user: state.AuthReducer.user,
   
  };
}
class Room extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
          remoteParticipants: Array.from(this.props.room.participants.values())
        }
      
        this.leaveRoom = this.leaveRoom.bind(this);
      }
      componentDidMount() {
        console.log(this.props.user.user_type);
        // Add event listeners for future remote participants coming or going
        this.props.room.on('participantConnected', participant => this.addParticipant(participant));
        this.props.room.on('participantDisconnected', participant => this.removeParticipant(participant));
        
        window.addEventListener("beforeunload", this.leaveRoom);
      }
      leaveRoom() {
        this.props.room.disconnect();
        this.props.returnToLobby();
      }
      componentWillUnmount() {
        this.leaveRoom();
      }
      addParticipant(participant) {
        console.log(`${participant.identity} has joined the room.`);
        
        this.setState({
          remoteParticipants: [...this.state.remoteParticipants, participant]
        });
      }
    
      removeParticipant(participant) {
        console.log(`${participant.identity} has left the room`);
    
        this.setState({
          remoteParticipants: this.state.remoteParticipants.filter(p => p.identity !== participant.identity)
        });
      }
      render() {
        return (
          <div className="room">
              <OtpTimer  seconds= {60} minutes={9} />
            <div className = "participants">
              <Participant key={this.props.room.localParticipant.identity} localParticipant="true" participant={this.props.room.localParticipant}/>
              {
                this.state.remoteParticipants.map(participant => 
                  <Participant key={participant.identity} participant={participant}/>
                )
              }
            </div>
            {this.props.user.user_type.toLowerCase() === "doctor" ? 
           (<button id="leaveRoom" onClick={this.leaveRoom}>Leave Room</button>):""}
          </div>
        );
      }
}
export default withRouter(connect1(mapStateToProps)(Room));

