import React, { Component } from "react";
import { connect } from "react-redux";
import BusinessProfile from "./BusinessProfile";
import BusinessUserProfile from "./BusinessUserProfile";
import ClientProfile from "./ClientProfile";
import axios from "axios";
import { CONFIG } from "../config/data";
import { withRouter } from "react-router-dom";
import{ getTimeoutbycategory} from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';


function mapStateToProps(state) {
  return {
    userdata: state.AuthReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout=this.state;
    this.state = {
      timeout:timeout,
      isTimedOut: false,
      showModal: false,
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
 
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
};

  _onAction(e) {
    
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
      }
  }
 
  componentDidMount() {

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            timeout: data.data.timeout
            
          });
        })
      .catch((e) => { });


    
  }

  render() {
    const {timeout}=this.state;
    return (
      <div>
            <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />

        {this.props.userdata.user_type === "hospital" ? (
          <BusinessProfile id={this.props.match.params.id} />
        ) : (this.props.userdata.user_type === "doctor" || this.props.userdata.user_type === "Doctor") ? (
          <BusinessUserProfile />
        ) : (
          
          <ClientProfile />
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Profile));