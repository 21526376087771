import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Popup } from '../../components/UI/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getPharmacyList,
  addPharmacy,
  updatePharmacy,
  deletePharmacy,
  getTimeoutbycategory,
  searchPharmacy,
  hospitalpharmacyimport
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import Validation from "../../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Tooltip from '@mui/material/Tooltip';
import Label from "../../components/UI/Label";
import PharmacyListExport from "./PharmacyListExport";
import PharmacyListTemplate from "./PharmacyListTemplate";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../../components/UI/Popupinfo";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

const google = window.google;
const onlineOptions = [{ value: 1, label: "Yes" }, { value: 0, label: "No" }];

class PharmacyList extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
    this.autocomplete = null;
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      records: [],
      searchrecords: [],
      addPopup: false,
      actions: "add",
      search_action: false,
      newDatatoAdd: {},
     searchErrorMessage: "",
      pharmacyData: {
        pharmacy_id: "",
        searchpharmacy_id:"",
        pharmacy_name: "",
        pharmacy_location: "",
        address: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        online_prescription_obj: { value: "", label: "" },
        online_prescription: "",
        pharmacy_verification:""
      },

      errors: {
        pharmacy_id: "",
        pharmacy_name: "",
        pharmacy_location: "",
        address: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email: "",
        online_prescription: "",
        pharmacy_verification:""
      },
      tableSuccess: {
        message: "",
        status: ""
      },
      formSuccess: {
        message: "",
        status: ""
      }
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "pharmacy_id",
        text: "Pharmacy ID",
        className: "pharmacy_id",
        align: "left",
        sortable: true,
      },
      {
        key: "pharmacy_name",
        text: "Pharmacy Name",
        className: "pharmacy_name",
        align: "left",
        sortable: true,
      },
      {
        key: "pharmacy_location",
        text: "Address",
        className: "pharmacy_location",
        sortable: true,
        align: "left",
        cell: (record) => {
          return (
            <Fragment>
              <Tooltip title={"Address is "+record.pharmacy_verification}  placement="top">
                <a href="#" className={(record.pharmacy_verification=="Not Verified")?"tooltiptext":""}>
                {record.pharmacy_location}
                </a>
              </Tooltip>
            </Fragment>
          );

        }

      },
       {
        key: "phone_number",
        text: "Phone Number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        sortable: true,
        align: "left",
      },
      {
        key: "online_prescription",
        text: "Online Prescription",
        className: "online_prescription sform-control",
        sortable: true,
        align: "left",
        // cell: (record) => {
        //   let obj = {
        //     label: record.online_prescription,
        //     value: ("Yes" === record.online_prescription) ? 1 : 0
        //   }
        //   return (
        //     <Select maxMenuHeight={120}
        //       className="form-control p-0"
        //       name="online_prescription_obj"
        //       value={
        //         obj
        //       }
        //       onChange={() => this.handleColumnChange(record)}
        //       options={onlineOptions}
        //     />
        //   );
        // },
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 9,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchcolumns = [
      
      {
        key: "pharmacy_id",
        text: "Pharmacy ID",
        className: "searchpharmacy_id",
        align: "left",
        sortable: true,
        width: 10,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"searchpharmacy_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.searchpharmacy_id}
                placeholder={""}
                max={5}
              />
              <SpanField classname="text-danger" title={record.pharmacyid_error} />
            </Fragment>
          );
        },
      },
      {
        key: "pharmacy_name",
        text: "Pharmacy Name",
        className: "pharmacy_name",
        align: "left",
        sortable: true,
      },
      {
        key: "pharmacy_location",
        text: "Address",
        className: "pharmacy_location",
        sortable: true,
        align: "left",
      },
      {
        key: "phone_number",
        text: "Phone Number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        sortable: true,
        align: "left",
      },
      {
        key: "online_prescription",
        text: "Online Prescription",
        className: "online_prescription",
        sortable: true,
        align: "left",
      },
   
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button> */}
            </Fragment>
          );
        },
      },
    ];
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  handlePhoneInput = (event) => {
    let pharmacyData = this.state.pharmacyData;
    let errors=this.state.errors;
    pharmacyData.phone_number = event;
    errors.phone_number="";
    this.setState({ pharmacyData,errors });
  };
  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();
    let errors=this.state.errors;
    let { pharmacyData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
   
    pharmacyData.pharmacy_location = place.formatted_address;
    pharmacyData.pharmacy_verification="Verified";

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
          pharmacyData.state = val;
        if (addressType === "locality")
          pharmacyData.city = val;
        if (addressType === "country") pharmacyData.country = val;
        // if (addressType === "street_number") {

        pharmacyData.address = place.formatted_address;
        // }
        // if (addressType === "sublocality_level_1") pharmacyData.address2 = val;
        if (addressType === "postal_code") pharmacyData.zip_code = val;
      }
    }
errors.city_error="";
errors.state_error="";
errors.country_error="";
errors.zip_code_error="";
errors.city="";
errors.state="";
errors.country="";
errors.zip_code="";
    this.setState({
      pharmacyData,errors
    });
  }
  handleClose() {
    this.setState({ showModal: false })
  }

  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  };

  _onAction(e) {
    // console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

 
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
  
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value=event.target.value;
     if (
       event.target.name === "searchpharmacy_id"
 
     ) {
       if (value.length > max) {
         const value1 = value.slice(0, max);
         record.pharmacyid_error  = "Maximum Length has exceeded.";
         record[event.target.name] = value1;
       }
      else {
       record.pharmacyid_error  = "";
       record[event.target.name] = event.target.value;
     }
   }
    this.UpdateSearchColumnChange(record);
  };

  addRecord = (record) => {
    let formvalid = true;
    if (record.searchpharmacy_id === "") {
      record.pharmacyid_error = "The Pharmacy ID field is required.";
      formvalid = false;
    } else {
      record.pharmacyid_error = "";
    }
   
   
     let records = this.state.searchrecords;
 /*    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex] = record;
    this.setState({ searchrecords: records });  */
    if (formvalid) {
      this.setState({ drug_action: "Add" });
      //this.setState({ newDatatoAdd: record, loading: true });
      // this.addorupdateDrug();

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      let selectedData = record;
      // console.log(selectedData); return false;
      let data = {};
      data.user_id = this.props.userdata.id;
      data.pharmacy_id = selectedData.searchpharmacy_id;
      data.pharmacy_name = selectedData.pharmacy_name;
      data.pharmacy_location = selectedData.pharmacy_location;
      data.address = selectedData.address;
      data.city = selectedData.city;
      data.country = selectedData.country;
      data.state = selectedData.state;
      data.zip_code = selectedData.zip_code;
      data.phone_number = selectedData.phone_number;
      data.email = selectedData.email;
      data.online_prescription=selectedData.online_prescription;
// console.log(data);return false;
      axios
        .post(addPharmacy, data, CONFIG)
        .then((addPharmacyResponse) => {
          let res = addPharmacyResponse.data;
         
          if (res.status === "1") {
            toast.success("The Pharmacy details have been added successfully.", {
              className: "toast-success"
            });
            let pharmacy = {};
            let records = this.state.records;
            pharmacy.s_no = this.state.records.length + 1;
            pharmacy.pharmacy_id = data.pharmacy_id;
            pharmacy.id = res.data[0].id;
            pharmacy.pharmacy_name = data.pharmacy_name;
            pharmacy.address = data.address;
            pharmacy.pharmacy_location=data.address;
            pharmacy.city = data.city;
            pharmacy.country = data.country;
            pharmacy.state = data.state;
            pharmacy.zip_code = data.zip_code;
            pharmacy.phone_number = data.phone_number;
            pharmacy.email = data.email;
            records.push(pharmacy);
            // this.setState({ searchrecords: records });
            this.setState({
              records: records,
              searchTableSuccess: res,
              loading: false,
            });
            this.clearMessage();
            this.cancel();
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            axios
              .post(getPharmacyList, { user_id: this.props.userdata.id }, CONFIG)
              .then((list) => {
                let pharmacyList = list.data.data;
                pharmacyList.forEach((element, index) => {
                  let pharmacy = {};
                  pharmacy.s_no = index + 1;
                  pharmacy.pharmacy_id = element.pharmacy_id;
                  pharmacy.pharmacy_name = element.pharmacy_name;
                  pharmacy.id = element.id;
                  pharmacy.online_prescription = element.online_prescription;
                  pharmacy.pharmacy_location = element.pharmacy_location;
                  pharmacy.phone_number = element.phone_number;
                  pharmacy.email = element.email;
                  pharmacy.address = element.address;
                  pharmacy.city = element.city;
                  pharmacy.country = element.country;
                  pharmacy.state = element.state;
                  pharmacy.zip_code = element.zip_code;
                  pharmacy.online_prescription = element.online_prescription;
                  pharmacy.pharmacy_verification=element.pharmacy_verification;
        
                  pharmacyList[index] = pharmacy;
                });
        
                this.setState({ records: pharmacyList });
              })
              .catch((e) => { });
        
  
          } 
          else {
            toast.error("Pharmacy could not be added ", {
              className: "toast-error"
            });
            this.setState({
              searchrecords: records,
              searchTableSuccess: res,
              loading: false,
            });
            this.clearMessage();
            this.cancel();
          }  
        })
        .catch(() => { });
    }
  };

  UpdateSearchColumnChange = (record) => {

    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);

    records[recIndex].pharmacy_id = record.pharmacy_id?record.pharmacy_id:"";
   /*  records[recIndex].pharmacy_name = record.pharmacy_name;
    records[recIndex].pharmacy_location = record.pharmacy_location;
    records[recIndex].address = record.address;
    records[recIndex].city = record.city;
    records[recIndex].country = record.country;
    records[recIndex].state = record.state;
    records[recIndex].zip_code = record.zip_code;
   records[recIndex].phone_number = record.phone_number; */

    this.setState({ searchrecords: records });
  }


  UpdateColumnChange = (record) => {
    
        let records = this.state.records;
        var recIndex = findWithAttr(records, "id", record.id);
    
        records[recIndex].pharmacy_id = record.pharmacy_id?record.pharmacy_id:"";
        records[recIndex].pharmacy_name = record.pharmacy_name;
        records[recIndex].pharmacy_location = record.pharmacy_location;
        records[recIndex].address = record.address;
        records[recIndex].city = record.city;
        records[recIndex].country = record.country;
        records[recIndex].state = record.state;
        records[recIndex].zip_code = record.zip_code;
        // records[recIndex].phone_number = record.phone_number;
    
        this.setState({ records: records });
      }
  UpdatePharmacy = (e) => {
    e.preventDefault();
    let { pharmacyData, errors } = this.state;
    pharmacyData.type = "pharmacyList";
    
    errors = Validation(pharmacyData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      let data = this.state.pharmacyData;
      data.user_id = this.props.userdata.id;
      data.online_prescription = data.online_prescription_obj.label;
      if (this.state.actions === "add") {  
      if(data.pharmacy_location=="")
      {
       data.pharmacy_location = data.address;
       data.pharmacy_verification="Not Verified";
       }else{
      
        data.pharmacy_verification="Verified";
      
       }
      }else
      {

              
        if(data.address==data.pharmacy_location)
        {
          data.pharmacy_verification=data.pharmacy_verification;
          console.log(data.pharmacy_verification);
        }
        else
        {
          data.address=data.address;
          data.pharmacy_verification="Not Verified";
          console.log(data.pharmacy_verification);
        }
      }
       console.log(data.pharmacy_location);
       console.log(data.address);
      
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let url = this.state.actions === "add" ? addPharmacy : updatePharmacy;
      if (this.state.actions === "add") {
        axios.post(url, data, CONFIG).then((list) => {
          if (0 == list.data.status) {
            toast.error("Pharmacy could not be added ", {
              className: "toast-error"
            });
            this.setState({ errors: { pharmacy_id: list.data.message } });
          } else {
            let addPharmacy = {};
            addPharmacy.pharmacy_id = data.pharmacy_id;
            addPharmacy.pharmacy_name = data.pharmacy_name;
            addPharmacy.online_prescription = data.online_prescription_obj.label;
            addPharmacy.id = list.data.data[0].id;
            addPharmacy.address = data.address;
            addPharmacy.phone_number = data.phone_number;
            addPharmacy.email = data.email;
            if(data.pharmacy_location="")
            {
            addPharmacy.pharmacy_location = data.address;
            addPharmacy.pharmacy_verification=data.pharmacy_verification;
            }else
            {
              addPharmacy.pharmacy_location = data.pharmacy_location;
              addPharmacy.pharmacy_verification=data.pharmacy_verification;
            }
            addPharmacy.city = data.city;
            addPharmacy.country = data.country;
            addPharmacy.state = data.state;
            addPharmacy.zip_code = data.zip_code;
            addPharmacy.online_prescription = data.online_prescription;
            let records = this.state.records;
            addPharmacy.s_no = records.length + 1;
            records.push(addPharmacy);

            this.setState({ records });
            // this.setState({ formSuccess: list.data });
            toast.success("The Pharmacy details have been added successfully.", {
              className: "toast-success"
            });
            this.clearMessage();
            this.cancel();
          }
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getPharmacyList, { user_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
              let pharmacyList = list.data.data;
              pharmacyList.forEach((element, index) => {
                let pharmacy = {};
                pharmacy.s_no = index + 1;
                pharmacy.pharmacy_id = element.pharmacy_id;
                pharmacy.pharmacy_name = element.pharmacy_name;
                pharmacy.id = element.id;
                pharmacy.online_prescription = element.online_prescription;
                pharmacy.pharmacy_location = element.pharmacy_location;
                pharmacy.phone_number = element.phone_number;
                pharmacy.email = element.email;
                pharmacy.address = element.address;
                pharmacy.city = element.city;
                pharmacy.country = element.country;
                pharmacy.state = element.state;
                pharmacy.zip_code = element.zip_code;
                pharmacy.online_prescription = element.online_prescription;
                pharmacy.pharmacy_verification=element.pharmacy_verification;
      
                pharmacyList[index] = pharmacy;
              });
      
              this.setState({ records: pharmacyList });
            })
            .catch((e) => { });
      

        });
      } else {

        axios.put(url, data, CONFIG).then((list) => {

          if (0 == list.data.status) {
            toast.error("Pharmacy could not be updated ", {
              className: "toast-error"
            });
            this.setState({ success: list.data });
          } else {
            let{editRecord}=this.state;
            let place = this.autocomplete.getPlace();
            let records = this.state.records;
            var addr = document.getElementById("address");
            
            // Get geocoder instance
            var geocoder = new google.maps.Geocoder();
             
            // Geocode the address
            geocoder.geocode({
                'address': addr.value
            }, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
             
                    // set it to the correct, formatted address if it's valid
                  //  addr.value = results[0].formatted_address;;
                //  data.pharmacy_verification="Verified";
                } else {
                         
                   // show an error if it's not
                //   alert("Invalid address");
                }
            });
            console.log(addr.value)
          //  console.log(place.formatted_address);
     /*        console.log(editRecord.address);
          if(data.address==editRecord.address)
          {
            data.address=data.address;
            data.pharmacy_verification=editRecord.pharmacy_verification;
          }else
          {
            data.address=place.formatted_address;
            data.pharmacy_verification="Verified";
                        
          }
      */       var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].pharmacy_id = data.pharmacy_id;
            records[recIndex].pharmacy_name = data.pharmacy_name;
            records[recIndex].online_prescription = data.online_prescription_obj.label;
            records[recIndex].phone_number = data.phone_number;
            records[recIndex].email = data.email;
            records[recIndex].address = data.address;
            records[recIndex].pharmacy_location = data.address;
            records[recIndex].city = data.city;
            records[recIndex].country = data.country;
            records[recIndex].state = data.state;
            records[recIndex].zip_code = data.zip_code;
            records[recIndex].online_prescription = data.online_prescription;
            records[recIndex].pharmacy_verification = data.pharmacy_verification;
            records[recIndex].id = data.id;

            this.setState({ records });
            //  this.setState({  formSuccess: list.data });
            toast.success("The Pharmacy details have been updated successfully. ", {
              className: "toast-success"
            });
            this.clearMessage();
            this.cancel();
          }
        });
      }

    }
  };

  clearErrors = (errors) => {

    errors.pharmacy_id = ""
    errors.pharmacy_name = ""
    errors.address = ""
    errors.phone_number = ""
    errors.email = ""
    errors.pharmacy_location = ""
    errors.city = ""
    errors.country = ""
    errors.state = ""
    errors.zip_code = ""
    errors.online_prescription = ""
    return errors
  }

  cancel = () => {
    let pharmacy_action = this.state.pharmacy_action;
    pharmacy_action = "Add";
    this.setState({ pharmacy_action });
    let data = this.state.pharmacyData;
    data.pharmacy_id = ""
    data.pharmacy_name = ""
    data.id = ""
    data.user_id = ""

    data.online_prescription_obj = {
      label:"",value:""
    }
    data.address = ""
    data.phone_number = ""
    data.email = ""
    data.pharmacy_location = ""
    data.city = ""
    data.country = ""
    data.state = ""
    data.zip_code = ""
    data.online_prescription = ""

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ pharmacyData: data, actions: "add", errors: errors, searchErrorMessage: "", searchrecords: [], search_action: false });
  }
 
  clearimport=()=>{
    this.setState({ file:"" });
  };
 
  clearMessage = () => {
    setTimeout(() => this.setState({
      formSuccess: { message: "", },
      tableSuccess: { message: "" }
    }), 5000);
  }
  editRecord(record) {
    let pharmacy_action = this.state.pharmacy_action;
    pharmacy_action = "Edit";
    this.setState({ pharmacy_action });
    let pharmacyData = this.state.pharmacyData;
    console.log(record)
    console.log(pharmacyData)
    pharmacyData.pharmacy_id = record.pharmacy_id;
    pharmacyData.pharmacy_name = record.pharmacy_name;
    pharmacyData.id = record.id;
    pharmacyData.user_id = record.user_id;

    pharmacyData.online_prescription_obj = {
      label: record.online_prescription,
      value: ("Yes" === record.online_prescription) ? 1 : 0
    }
    pharmacyData.address = record.pharmacy_location;
    pharmacyData.phone_number = record.phone_number;
    pharmacyData.email = record.email;
    pharmacyData.pharmacy_location = record.address;
    pharmacyData.city = record.city;
    pharmacyData.country = record.country;
    pharmacyData.state = record.state;
    pharmacyData.zip_code = record.zip_code;
    pharmacyData.online_prescription = record.online_prescription;
    pharmacyData.pharmacy_verification=record.pharmacy_verification;
    let errors = this.state.errors;
    errors = this.clearErrors(errors);

    this.setState({ pharmacyData: pharmacyData, actions: "edit", errors: errors,editRecord:record });
  }
  componentDidMount = () => {
    

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });


    let members = [];

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getPharmacyList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let pharmacyList = list.data.data;
        pharmacyList.forEach((element, index) => {
          let pharmacy = {};
          pharmacy.s_no = index + 1;
          pharmacy.pharmacy_id = element.pharmacy_id;
          pharmacy.pharmacy_name = element.pharmacy_name;
          pharmacy.id = element.id;
          pharmacy.online_prescription = element.online_prescription;
          pharmacy.pharmacy_location = element.pharmacy_location;
          pharmacy.phone_number = element.phone_number;
          pharmacy.email = element.email;
          pharmacy.address = element.address;
          pharmacy.city = element.city;
          pharmacy.country = element.country;
          pharmacy.state = element.state;
          pharmacy.zip_code = element.zip_code;
          pharmacy.online_prescription = element.online_prescription;
          pharmacy.pharmacy_verification=element.pharmacy_verification;

          pharmacyList[index] = pharmacy;
        });

        this.setState({ records: pharmacyList });
      })
      .catch((e) => { });

  };
  onDeleteClick(record) {
    
    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    
    let userId = record.id;

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deletePharmacy, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records
            .map((item) => item.id)
            .indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "" });
        toast.error("The Pharmacy detail has been deleted successfully.", {
          className: "toast-error"
        });
        //  this.setState({ tableSuccess: res.data });
        this.clearMessage();
        this.cancel();
      })
      .catch((e) => { });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.pharmacyData;
    let errors = this.state.errors
    errors.online_prescription = ""
    data[e.name] = selectedOptions;
    this.setState({ pharmacyData: data, errors });
  };
  handleColumnChange = (record) => {
    record.online_prescription = ("Yes" === record.online_prescription) ? "No" : "Yes";
    this.UpdateColumnChange(record);
  }
  validateMaxLength(name, value, max) {
    let data = this.state.pharmacyData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ pharmacyData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.pharmacyData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    let errors = this.state.errors
    if (
      name === "pharmacy_id" ||
      name === "pharmacy_name" ||
      name === "address"||
      name==="city"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = "";
    }
    this.setState({ pharmacyData: data, errors,searchErrorMessage: "" });
  };
  handleAddressInput = (e) => {
    let data = this.state.pharmacyData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    let errors = this.state.errors
    if (name === "address") {
     
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = "";
    }
    this.setState({ pharmacyData: data, errors,searchErrorMessage: "" });
  };
  searchandshowPharmacy() {
    let pharmacyData = this.state.pharmacyData;
   console.log(pharmacyData);
   
    if (
      pharmacyData.pharmacy_name === "" && pharmacyData.address === "" && pharmacyData.city === "" && pharmacyData.country === "" && pharmacyData.state === "" && pharmacyData.zip_code === "" && pharmacyData.online_prescription_obj.label === "" && pharmacyData.email === "" && pharmacyData.phone_number === ""
      ) {
        
        this.setState({
          searchErrorMessage:
            "Choose any one of the search filters other than Pharmacy ID.",
            searchrecords:[]
        });
        return;

      
      
    }  else {
      this.setState({
        searchErrorMessage: "",
      });
    } 
    let data = {};
    data.pharmacy_name = pharmacyData.pharmacy_name;
    data.address = pharmacyData.address;
    data.pharmacy_location = pharmacyData.pharmacy_location;
    data.city = pharmacyData.city;
    data.country = pharmacyData.country;
    data.state = pharmacyData.state;
    data.zip_code = pharmacyData.zip_code;
    data.phone_number = pharmacyData.phone_number;
    data.email = pharmacyData.email;
    data.online_prescription=pharmacyData.online_prescription_obj.label;
  
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(searchPharmacy, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        let data = [];
        if (result.countOfRecords > 0) {
          let records = result.data;
          //  console.log(this.state.masterdrugmeasure[0]);
          records.forEach((element) => {
            console.log(element);
            data.push({
              pharmacy_id: "",
              pharmacy_name: element.pharmacy_name,
              address: element.address,
              city: element.city,
              country: element.country,
              state: element.state,
              zip_code: element.zip_code,
              phone_number: element.phone_number,
              email: element.email,
              online_prescription:element.online_prescription,
              pharmacy_location: element.pharmacy_location,
              pharmacy_name_error: "",
              address_error: "",
              city_error: "",
              country_error: "",
              state_error: "",
              zip_code_error: "",
              phone_number_error: "",
            });
          });

          this.setState({ searchrecords: data });
          this.setState({
            search_action: true,
          });
        } else {
          this.setState({ searchrecords: [], search_action: true });
        }
      });
    });
  }
  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    readXlsxFile(event.target.files[0]).then((rows) => {
        console.log(rows);
        console.log("rows");
        this.setState({ pharmacyData: rows });
        // `rows` is an array of rows
        // each row being an array of cells.
    })

};
onFileUpload = () => {

   if (this.state.pharmacyData.length) {
      axios.post(hospitalpharmacyimport, {data: this.state.pharmacyData,user_id:this.props.userdata.id}).then((result) => {
          console.log(result);
          this.setState({ formSuccess: result.data, file: "", pharmacyData: [] })
          axios
              .post(getPharmacyList, { user_id: this.props.userdata.id }, CONFIG)
              .then((list) => {
                let pharmacyList = list.data.data;
                pharmacyList.forEach((element, index) => {
                  let pharmacy = {};
                  pharmacy.s_no = index + 1;
                  pharmacy.pharmacy_id = element.pharmacy_id;
                  pharmacy.pharmacy_name = element.pharmacy_name;
                  pharmacy.id = element.id;
                  pharmacy.online_prescription = element.online_prescription;
                  pharmacy.pharmacy_location = element.pharmacy_location;
                  pharmacy.phone_number = element.phone_number;
                  pharmacy.email = element.email;
                  pharmacy.address = element.address;
                  pharmacy.city = element.city;
                  pharmacy.country = element.country;
                  pharmacy.state = element.state;
                  pharmacy.zip_code = element.zip_code;
                  pharmacy.online_prescription = element.online_prescription;
                  pharmacy.pharmacy_verification=element.pharmacy_verification;
        
                  pharmacyList[index] = pharmacy;
                });
              this.setState({ records: pharmacyList });
          })
          .catch(() => { });
          this.clearMessage();
      });
  }

};
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const { pharmacyData, errors, formSuccess, tableSuccess, hospitaltimeout, searchErrorMessage,searchTableSuccess,loading } = this.state;
    return (

      <div className="col-sm-12">
   <IdleTimeOutModal
      showModal={this.state.showModal}
      handleClose={this.handleClose}
      handleLogout={this.handleLogout}
      />
   <IdleTimer
      ref={ref =>
   { this.idleTimer = ref }}
   element={document}
   onActive={this.onActive}
   onIdle={this.onIdle}
   onAction={this.onAction}
   debounce={250}
   timeout={hospitaltimeout} />
    {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

   <div className="row checkout-tabs">
      <div className="col-sm-12">
         <h4 className="font-weight-bold"> Pharmacy List </h4>
      </div>
      <div className="col-sm-12">
         <div className="card">
            <div className="card-body ">
               <div
                  className="tab-content"
                  id="v-pills-tabContent"
                  >
                       <div className="float-right btn "> <PharmacyListExport business_id={this.props.userdata.id} records={this.state.records}/></div>
                              
                  <div
                     className="tab-pane fade show active"
                     id="v-pills-shipping"
                     role="tabpanel"
                     aria-labelledby="v-pills-shipping-tab"
                     >
                     <div className="user-notification">
                        <SpanField
                        classname={(0 == tableSuccess.status) ? "text-danger user-notification" :
                        "text-success user-notification"}
                        title={tableSuccess.message}
                        />
                        <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
                     </div>
                     <ReactDatatable
                        config={this.config}
                        records={this.state.records}
                        columns={this.columns}
                        className="table table-bordered table-striped kas  mobile_table"
                        />
                        <p className="tooltiptext">* All the address marked in red are not verified</p>
                     <Popup show={this.state.addPopup} onHide={PopupClose}
                     onConfirm={PopupConfirm} title={"Confirmation"}
                     message={"Are you sure you want to delete this record?"} />
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="col-sm-12">
                                            <div className="card">
                                            <div class="card-header"> <div class="card-title">Import Pharmacy List</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                      
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    "0" === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div className="form-group d-flex justify-content-center mg-btm5">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                        <div className="float-right btn btn2 "> <PharmacyListTemplate /></div>
                              
                                                        <div className="row">
                                                      
                                                            <div className="col-sm-4">
                                                                <div className="custom-file text-left">
                                                                  
                                                                    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" type="file" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" />
                                                                    <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                                                                    {/* <div className="form-group  mt-2">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div> */}
                                                                </div>
                                                             
                                                            </div>

                                                       
                                                           
                                                        </div>
                                                    </div>
                                                  {/*   <div className="form-group"><label className="control-label">{this.state.file ? this.state.file.name : ""}</label></div> */}
                                                </div>
                                            </div>
                                        </div> 
      <div className="col-sm-12 pr0">
         <div className="card b-12-hite">
         <div class="card-header"> <div class="card-title"> {this.state.actions === "add"
                     ? "Add"
                     : "Edit"}{" "} / Search
                     Pharmacy</div> </div>
            <div className="card-body">
               <div className="user-notification">
                  <SpanField
                  classname={(0 == formSuccess.status) ? "text-danger user-notification" :
                  "text-success user-notification"}
                  title={formSuccess.message}
                  />
               </div>
               <div>
                 
                  <div className="user-notification">
                     {searchErrorMessage !== "" ? (
                     <div
                        className="alert alert-danger"
                        role="alert"
                        >
                        {searchErrorMessage}
                     </div>
                     ) : (
                     ""
                     )}
                  </div>
              
                  <div className="row">
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                              <Label title="Pharmacy ID" isMandatory={true} />
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       inputType={"text"}
                                       name={"pharmacy_id"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.pharmacy_id}
                                       placeholder={""}
                                       max={5}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.pharmacy_id}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                              <Label title="Online Prescription" isMandatory={true} />
                           </div>
                           <div className="form-group sform-control frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    {/* <div className="input-group-prepend">
                                       <span className="input-group-text inpico">
                                       <i className="fas fa-search"></i>
                                       </span>
                                    </div> */}
                                    <Select maxMenuHeight={120}
                                       className="form-control p-0"
                                       name="online_prescription_obj"
                                       value={
                                       pharmacyData.online_prescription_obj
                                       }
                                       onChange={this.handleselectChange}
                                       options={onlineOptions}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.online_prescription}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             <Label title="Pharmacy Name" isMandatory={true} />
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       inputType={"text"}
                                       name={"pharmacy_name"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.pharmacy_name}
                                       placeholder={""}
                                       max={50}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.pharmacy_name}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                              <Label title=" Address" isMandatory={true} />
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       id="address"
                                       inputType={"text"}
                                       name={"address"}
                                       controlFunc={this.handleAddressInput}
                                       content={pharmacyData.address}
                                       placeholder={""}
                                       max={200}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.address}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             
                              <Label title="City" isMandatory={true} />
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       inputType={"text"}
                                       name={"city"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.city}
                                       placeholder={""}
                                       max={50}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.city}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             <Label title="Country" isMandatory={true} />
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       id="country"
                                       inputType={"text"}
                                       name={"country"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.country}
                                       placeholder={""}
                                       max={200}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.country}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             <Label title="State" isMandatory={true} />  
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       inputType={"text"}
                                       name={"state"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.state}
                                       placeholder={""}
                                       max={50}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.state}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             <Label title="Zip/Pin Code" isMandatory={true} />  
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       id="zip_code"
                                       inputType={"text"}
                                       name={"zip_code"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.zip_code}
                                       placeholder={""}
                                       max={200}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.zip_code}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                               <Label title="Phone Number" isMandatory={true} />  
                            </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                 <div className="form-control">
                                 <PhoneInput
                                 international
                                 defaultCountry="IN"
                                 placeholder="Enter phone number"
                                 value={pharmacyData.phone_number}
                                 onChange={this.handlePhoneInput}
                                 error={
                                 pharmacyData.phone_number
                                 ? isValidPhoneNumber(
                                 pharmacyData.phone_number
                                 )
                                 ? undefined
                                 : "Invalid phone number"
                                 : "Phone number required"
                                 }
                                 />
                              </div>
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.phone_number}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className="frllftfull">
                           <div className="form-group frllft mbbre">
                             <Label title="Email" isMandatory={true} />  
                           </div>
                           <div className="form-group frlrit">
                              <div className="position-relative">
                                 <div className="input-group">
                                    <InputField
                                       id="email"
                                       inputType={"text"}
                                       name={"email"}
                                       controlFunc={this.handleInput}
                                       content={pharmacyData.email}
                                       placeholder={""}
                                       max={200}
                                       />
                                 </div>
                                 <SpanField
                                    classname="text-danger"
                                    title={errors.email}
                                    />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="row">
                  <div className="col-sm-12">
                        <div className="form-group mb-0 btn-align-right">
                           <button
                              href="patients-register"
                              className="signupbtn abtn mr-2 phone-button"
                              // className="signupbtn abtn btdpml btn-margin"
                              onClick={this.UpdatePharmacy}
                              >
                           {this.state.actions === "add"
                           ? "Add"
                           : "Update"}
                           </button>
                           <button
                              className="signupbtn abtn mr-2 phone-button"
                              // className="signupbtn abtn btdpml btn-margin"
                              disabled={this.state.pharmacy_action=="Edit"?true:false}
                              onClick={this.searchandshowPharmacy.bind(
                              this
                              )}
                              >
                           Search
                           </button>
                           <button
                              href="patients-register"
                              // className="signupbtn abtn btdpml btn-margin"
                              className="signupbtn abtn phone-button"
                              onClick={this.cancel}>
                           {"Reset"}
                           </button>
                        </div>
                    
                    </div>
                  </div>



              
                    
                    
                   
                
            </div>
         </div>
      </div>
      <div className="card w100"
                  style={{
                  display: this.state.search_action
                  ? "block"
                  : "none",
                  }}>
      <div class="card-header"> <div class="card-title"> Search Result </div> </div>
        <div className="card-body">
        <div>
                  
                    <div className="col-sm-12">
                      <div className="card">
                          <div className="card-body">
                            <div
                                className="tab-content"
                                id="v-pills-tabContent"
                                >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                  >
                                  <div className="user-notification">
                                    
                                  </div>
                                  <ReactDatatable
                                      className="table table-bordered table-striped kas  mobile_table"
                                      config={this.searchconfig}
                                      records={this.state.searchrecords}
                                      columns={this.searchcolumns}
                                      />
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                 </div>
        </div>
      </div>
   </div>
</div>
           )}
</div>

    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PharmacyList));

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
