import React, { Fragment } from "react";
import { YearPicker } from "react-dropdown-date";
import PropTypes from "prop-types";

const YearPick = (props) => (

    <Fragment>
        
                <YearPicker 
                    defaultValue={props.defaultValue}
                    // default is 1900
                    start={props.startyear}
                    // default is current year

                    end={props.endyear}
                   
                    // default is ASCENDING
                    reverse
                    // default is false
                    
                   
                    // mandatory
                    value={props.selectedOption}
                    // mandatory
                    onChange={props.controlFunc}
                    id={props.id}
                    name={props.name}
                    classes={'form-control'}
                    optionClasses={'option classes'}
                />


                   
    </Fragment>
);

YearPick.propTypes = {
  name: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
  controlFunc: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default YearPick;
