import React, { Component,Fragment  } from 'react';
import { connect } from 'react-redux';
import FooterSection from '../components/UI/FooterSection';
import axios from 'axios';
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/client/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { CONFIG } from "../config/data";

import { getNewsFeedsById,getTimeoutbycategory } from '../config/url.json';
function mapStateToProps(state) {
    return {
        user: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
class Feeds extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;

        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
            
            addPopup: false,

            action:"Add",
            newsFeeds: { short_description: "", content: "",link:""},
            
            formSuccess: { status: "", message: "" },
            tableSuccess:{status:"", message:""},
            errors: { short_description: "", content: "",link:""}
        }
        
       
    }
    
    
   

  
   
   
   
    componentDidMount() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.user.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch(() => { });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getNewsFeedsById, {id:this.props.match.params.id})
            .then((response) => {


                this.setState({ newsFeeds: response.data.data })
            }).catch(() => {
                console.log("get queue failed");
            });
    }
    
   
    
   
    render() {
        const {
            hospitaltimeout,
            newsFeeds
        } = this.state;
        return (
            <div className="main-content bacfot">

                <div className="mainpage">
                    <section className="my-head">
                        <div className="container-fluid">
                            { this.props.user.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.user.id} />
                            ) : (
                                <Header user_id={this.props.user.id} />
                            )}
                        </div>
                    </section>
                    <div className="main-box">
                        <div className="sidemenu ">
                            {this.props.user.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div>

                        <section className="main-body hospital-profilegap">
                            <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            />

                            <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} />

                            <div className="container-fluid a-page-1-hight-right">
                                <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                />

                                <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> 

                                <div className='checkout-tabs'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                        <h4 class="font-weight-bold">Feed</h4>
                                            <div className='card'>
                                                <div className="card-body container-fluid">
                                                <div className="newsFedd border-0">
                                    <div>
                                        <h4>{newsFeeds.short_description}</h4>
                                        <p dangerouslySetInnerHTML={{
                                            __html: newsFeeds.content
                                        }}></p>
                                    </div>
                                    {/* <a href="javascript:void(0)" onClick={() => this.gotofeeds(newsFeeds)} className="signupbtn abtn button">Know More</a> */}
                                  

                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                        
                                </div>



                               


                        </section>
                        <FooterSection />
                       
                    </div>
                    </div>
                </div>

           
        );
    }
}

export default connect(
    mapStateToProps,
)(Feeds);