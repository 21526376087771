import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../../components/UI/InputField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import {
  getAllStorageBinList,
  addStorageBin,
  updateStorageBin,
  deleteStorageBin,
  getTimeoutbycategory
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";

import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
// Import React Table
import "react-table/react-table.css";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Spinner } from "../../components/UI/Spinner";
import FooterSection from "../../components/UI/FooterSection";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Label from "../../components/UI/Label";
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
const onlineOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Not Active" },
];
const stockplacement = [
  { value: 1, label: "Mixed Bin" },
  { value: 0, label: "Fixed Bin" },
];
const stockremoval = [
  { value: 1, label: "FEFO" },
  
];
class StorageBin extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      records: [],

      searchrecords: [],
      storagebin_action: "Add",
      actions: "add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      newDatatoAdd: {},
      addPopup: false,
       StorageData: {
        id: "",
        storagebin_id: "",
        storage_type: "",
        capacity: "",
        stock_placement: "",
        stock_removal: "",
        rack: "",
        aisle: "",
        status:"",
        status_obj: { value: "", label: "" },
        stock_placement_obj: { value: "", label: "" },
        stock_removal_obj: { value: "", label: "" },
      },
      errors: {
        storagebin_id: "",
        storage_type: "",
        capacity: "",
        stock_placement: "",
        stock_removal: "",
        rack: "",
        aisle: "",
        status:"",
      },
      searchData: {
        storagebin_id: "",
        storage_type: "",
        capacity: "",
        stock_placement: "",
        stock_removal: "",
        rack: "",
        aisle: "",
        status:"",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
      columns: [
        { title: "Storage Bin Id", field: "storagebin_id" },
        { title: "Storage Type", field: "storage_type" },
        { title: "Capacity", field: "capacity" },
        { title: "Stock Placement", field: "stock_placement" },
        { title: "Stock Removal", field: "stock_removal" },
        { title: "Rack", field: "rack" },
        { title: "Aisle", field: "aisle" },
        { title: "Status", field: "status" },
      ],
      
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      
      },
      {
        key: "storagebin_id",
        text: "Storage Bin ID",
        className: "storagebin_id",
        align: "left",
        sortable: true,
       
      },
      {
        key: "storage_type",
        text: "Storage Type",
        className: "storage_type",
        align: "left",
        sortable: true,
        
      },

      {
        key: "capacity",
        text: "Capacity",
        className: "capacity",
        sortable: true,
        align: "left",
        
      },
      {
        key: "stock_placement",
        text: "Stock Placement",
        className: "stock_placement sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.stock_placement,
            value: "Mixed Bin" === record.stock_placement ? 1 : 0,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="stock_placement_obj"
              value={obj}
              onChange={() => this.handleStockplacementColumnChange(record)}
              options={stockplacement}
            />
          );
        },
      },
        
      {
        key: "stock_removal",
        text: "Stock Removal",
        className: "stock_removal sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.stock_removal,
            value: "FEFO" === record.stock_removal ? 1 : 0,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="stock_removal_obj"
              value={obj}
              onChange={() => this.handleStockremovalChange(record)}
              options={stockremoval}
            />
          );
        },
      },
      
      {
        key: "aisle",
        text: "Aisle",
        className: "aisle",
        sortable: true,
        align: "left",
        
      },
      {
        key: "rack",
        text: "Rack",
        className: "rack",
        sortable: true,
        align: "left",
       
      },

      {
        key: "status",
        text: "Storage Bin Status",
        className: "status sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.status,
            value: "Active" === record.status ? 1 : 0,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="status_obj"
              value={obj}
              onChange={() => this.handleStatusColumnChange(record)}
              options={onlineOptions}
            />
          );
        },
      },
    
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

   
  }

  updateStorageBin = (e) => {
    e.preventDefault();
    let { StorageData, errors } = this.state;
    let data = {};
    data.type = "storageBinlist";
    data.storagebin_id = StorageData.storagebin_id;
    data.storage_type = StorageData.storage_type;
    data.capacity = StorageData.capacity;
    data.stock_placement=StorageData.stock_placement_obj.label;
    data.stock_removal=StorageData.stock_removal_obj.label;
    data.rack = StorageData.rack;
    data.aisle = StorageData.aisle;
    data.status=StorageData.status_obj.label;
    data.user_id = this.props.userdata.id;
    console.log(data);
  //  this.setState({ loading: true });
    errors = Validation(data, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
        // console.log(data); return false;

     //   let url = this.state.actions === "add" ? addSupplier : updatesupplier;
        if (this.state.actions === "add") {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
    
            axios.post(addStorageBin, data, CONFIG).then((list) => {
                let res = list.data;
                if (res.status === "1") {
                    let addstorage = {};
                    addstorage.storagebin_id = data.storagebin_id;
                    addstorage.storage_type = data.storage_type;
                    addstorage.capacity = data.capacity;
                    addstorage.stock_placement = data.stock_placement;
                    addstorage.stock_removal = data.stock_removal;
                    addstorage.rack = data.rack;
                    addstorage.aisle = data.aisle;
                    addstorage.status=data.status;
                    addstorage.user_id=data.user_id;
                    addstorage.id = list.data.data[0].id;
              
                    let records = this.state.records;
                    addstorage.s_no = records.length + 1;
                    records.push(addstorage);
                
                  //  this.setState({ records});
                    toast.success("Storage bin added ",{
                        className:"toast-success"
                      });
                //    this.setState({ loading: false });
                    this.clearMessage();
                    this.cancel();
          //      }
                    }
            });
        } else {
            data.id = StorageData.id;
            console.log(data)
            axios.put(updateStorageBin, data, CONFIG).then((list) => {
                if ('0' === list.data.status) {
                    this.setState({ loading: false });
                    this.setState({ errors: { storagebin_id: list.data.message } });
                } else {
                    let records = this.state.records;

                    var recIndex = findWithAttr(records, "id", data.id);
                    console.log(recIndex);
                    
                    records[recIndex].storagebin_id = data.storagebin_id;
                    records[recIndex].storage_type = data.storage_type;
                    records[recIndex].capacity = data.capacity;
                    records[recIndex].stock_placement = data.stock_placement;
                    records[recIndex].stock_removal = data.stock_removal;
                    records[recIndex].rack = data.rack;
                    records[recIndex].aisle = data.aisle;
                    records[recIndex].status=data.status;
                    records[recIndex].id = data.id;

                  //  this.setState({ records, formSuccess: list.data });
                //    this.setState({ loading: false });
                toast.success("Storage bin updated ",{
                  className:"toast-success"
                });
          
                    this.clearMessage();
                    this.cancel();
                }
            });
        }
    }
};
 
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;

    this.UpdateColumnChange(record);
  };
  handleStatusColumnChange = (record) => {
    record.status = "Active" === record.status ? "Not Active" : "Active";
    this.UpdateColumnChange(record);
  };
 handleStockplacementColumnChange = (record) => {
  record.stock_placement="Mixed Bin"===record.stock_placement? "Fixed Bin" :"Mixed Bin";
  this.UpdateColumnChange(record);
 };
 handleStockremovalChange = (record) => {
  record.stock_removal="FEFO"===record.stock_removal? "FEFO" :"FEFO";
  this.UpdateColumnChange(record);
 };
  UpdateColumnChange = (record) => {
    axios.put(updateStorageBin, record, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", record.id);

      records[recIndex].storagebin_id = record.storagebin_id;
      records[recIndex].storage_type = record.storage_type;
      records[recIndex].capacity = record.capacity;
      records[recIndex].stock_placement = record.stock_placement;
      records[recIndex].stock_removal = record.stock_removal;
      records[recIndex].rack= record.rack;
      records[recIndex].aisle=record.aisle;
      records[recIndex].status = record.status;
      records[recIndex].user_id = record.user_id;
      records[recIndex].id = record.id;

      this.setState({ records, tableSuccess: list.data });
      toast.success("Storage bin updated ",{
        className:"toast-success"
      });
      this.clearMessage();
  });
  };
  cancel = () => {
    let data = this.state.StorageData;
   
    data.status_obj = {
                       }
    data.stock_placement_obj = {
                      }
    data.stock_removal_obj = {
                      }
    data.storagebin_id = "";
    data.storage_type = "";
    data.capacity = "";
    data.stock_placement = "";
    data.stock_removal = "";
    data.rack = "";
    data.aisle = "";
    data.status = "";
   
   let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ StorageData: data, actions: "add", errors: errors });
};

clearErrors = (errors) => {
  errors.storagebin_id = "";
  errors.storage_type = "";
  errors.capacity = "";
  errors.stock_placement = "";
  errors.stock_removal = "";
  errors.aisle = "";
  errors.rack = "";
  errors.status = "";
  return errors;
};

  componentDidMount = () => {
  
    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
        axios
          .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status === "1")
              this.setState({
                hospitaltimeout: data.data.timeout
    
              });
          })
          .catch(() => { });
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllStorageBinList, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let StorageBinList = list.data.data;
       
        StorageBinList.forEach((element, index) => {
          let StorageBinData = {};
          StorageBinData.s_no = index + 1;
          StorageBinData.id = element.id;
          StorageBinData.user_id = this.props.userdata.id;
          StorageBinData.storagebin_id = element.storagebin_id;
          StorageBinData.storage_type = element.storage_type;
          StorageBinData.capacity = element.capacity;
          StorageBinData.stock_placement = element.stock_placement;
          StorageBinData.stock_removal = element.stock_removal;
          StorageBinData.aisle = element.aisle;
          StorageBinData.rack = element.rack;
          StorageBinData.status = element.status;
          StorageBinList[index] = StorageBinData;
        });

        this.setState({ records: StorageBinList });
      })
      .catch((e) => { });


  };
  editRecord(record) {
    console.log(record)
    let StorageData = {};
    StorageData.storagebin_id = record.storagebin_id;
    StorageData.storage_type = record.storage_type;
    StorageData.capacity = record.capacity;
    StorageData.stock_placement = record.stock_placement;
    StorageData.stock_placement_obj={
      label: record.stock_placement,
      value: ("Mixed Bin" === record.stock_placement) ? 1 : 0
    };
    StorageData.status = record.status;
    StorageData.status_obj = {
        label: record.status,
        value: ("Active" === record.status) ? 1 : 0
      };
      StorageData.checked = "Active" === StorageData.status ? true : false;
      StorageData.stock_removal = record.stock_removal;
      StorageData.stock_removal_obj={
        label: record.stock_removal,
        value: ("FEFO" === record.stock_removal) ? 1 : 0
      };
      StorageData.rack = record.rack;
      StorageData.aisle = record.aisle;
      StorageData.user_id=this.props.userdata.id;
      StorageData.id = record.id;


    console.log(StorageData)
    let errors = this.state.errors;
    errors = this.clearErrors(errors);

    this.setState({
      StorageData: StorageData,
        actions: "edit",
        errors: errors,
    });
}

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.StorageData;
    data[e.name] = selectedOptions;
    this.setState({ StorageData: data });
  };
  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;

    data[e.name] = selectedOptions;
    
    this.setState({ searchData: data });
  };

  handleInput = (e) => {
    let data = this.state.StorageData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ StorageData: data });
  };


  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;

    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(deleteStorageBin, {  id: userId }, CONFIG)
      .then((res) => {
        let response = res.data;
        console.log(response);
        if (response.status === "1") {
          let records = this.state.records;

          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        toast.error("Storage bin deleted ", {
          className: "toast-error"
        });
   
        this.clearMessage();
      })
      .catch((e) => { });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearAll() {
    let drug_action = this.state.drug_action;
    drug_action = "Add";
    this.setState({ drug_action });
    let drugData = {};
    drugData.drug_id = "";
    drugData.drug_name = "";
    drugData.manufacturer = "";
    drugData.drug_category = "";
    drugData.drug_type = "";
    drugData.active_ingredients = "";
    drugData.unit_of_measure = "";
    this.setState({ drugData });
  }


  render() {
    let ExpirePopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let ExpirePopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    const {
      errors,
      searchData,
      loading,
      formSuccess,
      searchTableSuccess,
      editformSuccess,
      tableSuccess,
      searchErrorMessage,
      hospitaltimeout,
      StorageData


    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;

    return (
      <div id="layout-wrapper">  
          {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />  */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

      {/*   <Spinner loading={loading} /> */}

      {loading && <Dnaspinner />}
      <div className="main-content bacfot">
      <div className="mainpage">
        <Header user_id={this.props.userdata.id} />
        <div className="main-box">
          <div className="sidemenu pr0">
            <BusinessUserSideBar />
          </div>

      <section className="main-body hospital-profilegap">
     <div className="container-fluid">
    
        <div className="col-sm-12">
            <h4 className="font-weight-bold"> Storage Bin List </h4>
            <div className="row mt-4">

                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body ">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                >
                                    <div className="user-notification">
                                        <SpanField
                                            classname={
                                                0 === tableSuccess.status
                                                    ? "text-danger user-notification"
                                                    : "text-success user-notification"
                                            }
                                            title={tableSuccess.message}
                                        />
                                    </div>
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                    />
                                    <Popup
                                        show={this.state.addPopup}
                                        onHide={PopupClose}
                                        onConfirm={PopupConfirm}
                                        title={"Confirmation"}
                                        message={"Are you sure you want to delete this record?"}
                                    />
                                </div>
                                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 pr0">
                    <div className="card b-12-hite">
                        <div className="card-body">
                            <div className="user-notification">
                                <SpanField
                                    classname={
                                        0 === formSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                    }
                                    title={formSuccess.message}
                                />
                            </div>

                            <div>
                            <div class="card-header"> <div class="card-title">
                                    {this.state.actions === "add" ? "Add" : "Edit"}  Storage Bin
                  </div></div>
                                <hr className="rowline" />
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Storage Bin ID" isMandatory={true} />   
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"storagebin_id"}
                                                            controlFunc={this.handleInput}
                                                            content={StorageData.storagebin_id}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.storagebin_id}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Stock Placement" isMandatory={true} />  
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="stock_placement_obj"
                                                            value={StorageData.stock_placement_obj}
                                                            onChange={this.handleselectChange}
                                                            options={stockplacement}
                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.stock_placement}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Storage Type" isMandatory={true} />    
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"storage_type"}
                                                            controlFunc={this.handleInput}
                                                            content={StorageData.storage_type}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.storage_type}
                                                    />
                                                </div>
                                            
                                            </div>
                                            </div>
                                             </div>

                                    <div className="col-sm-6">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Stock Removal" isMandatory={true} />      
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                     
                                                        <Select
                                                            className="form-control p-0"
                                                            name="stock_removal_obj"
                                                            value={StorageData.stock_removal_obj}
                                                            onChange={this.handleselectChange}
                                                            options={stockremoval}
                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.stock_removal}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-6">
                                    <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Asile" isMandatory={true} />  
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"aisle"}
                                                            controlFunc={this.handleInput}
                                                            content={StorageData.aisle}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.aisle}
                                                    />
                                                </div>
                                            </div>
                                            </div> 
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Capacity" isMandatory={true} />  
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"capacity"}
                                                            controlFunc={this.handleInput}
                                                            content={StorageData.capacity}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.capacity}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                    <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Rack" isMandatory={true} />     
                                            </div>
                                            <div className="form-group frlrit">
                                                <div className="position-relative">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"rack"}
                                                            controlFunc={this.handleInput}
                                                            content={StorageData.rack}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.rack}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                       
                                           </div>

                                    <div className="col-sm-6">
                                        <div className="frllftfull">
                                            <div className="form-group frllft mbbre">
                                            <Label title="Status" isMandatory={true} />   
                                            </div>
                                            <div className="form-group  frlrit">
                                                <div className="position-relative">
                                                <div className="input-group">
                                                   
                                                        <Select
                                                            className="form-control p-0"
                                                            name="status_obj"
                                                            value={StorageData.status_obj}
                                                            onChange={this.handleselectChange}
                                                            options={onlineOptions}
                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.status}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    




                                <div className="row">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-0 btn-align-center">
                                            
                                            <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml"
                                                onClick={this.updateStorageBin}
                                            >
                                                 {this.state.actions === "add" ? "+ Add" : "Update"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
         </section>
         <FooterSection />
         </div></div></div>
</div>


    );
}
}
export default connect(mapStateToProps)(StorageBin);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
