import React, {Fragment} from 'react';

const LoadingSpinner = (props) => (
  <Fragment>
    {props.title}
    <i className="fa fa-spinner fa-spin" />
   
    </Fragment>
);

export default LoadingSpinner;
