import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import InputField from "../components/UI/InputField";
import { gender } from "../config/data";
import Label from "../components/UI/Label";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    user: state.AuthReducer.user,
  };
}

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business_list: [],

      latitude: "",
      longitude: "",
      searchData: {
        category: "",
        business_name: "",
        doctor_name: "",
        facility: "",
        speciality: "",
        city: "",
        zip_code: "",
        gender: "",
        distance: "5",
        distance_type: "KM",
        reviews: "",
        visit_type: "oncall",
      },
      errors: [
        {
          doctor_name: "",
          city: "",
                },
      ],
      //   category: this.props.appdata.category,
    };
  }

  searchBusiness = (e) => {
    e.preventDefault();
    this.props.setSearchData(this.state.searchData);

    window.location.replace("/search");
  };
  validateMaxLength(name, value, max) {
    let data = this.state.searchData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ searchData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name === "doctor_name" ||
      name === "city" 
      ) {
        this.validateMaxLength(name, value, max);
      } else {
    data[name] = value;
      }
    this.setState({ searchData: data });
  };
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;

    this.setState({ businessData: data });
  };
  reviewChange(value, field) {
    let data = this.state.searchData;
     console.log(data);
    /* To uncheck the star*/
    if (data.reviews === 5) {
      value = 0;
    }

    var searchReviewId = document.getElementById("SearchReviewId");
    console.log(searchReviewId);
    for (var i = 0; i < 5; i++) {
      if (i < value && data.reviews !== 5) {
        searchReviewId.children[i].classList.add("fa-star");
      } else {
        searchReviewId.children[i].classList.remove("fa-star");
      }
    }

    data.reviews = value;
    this.setState({ searchData: data });
  }
  handleDistanceChange = (event) => {
    let data = this.state.searchData;
    data.distance = event.target.value;
    this.setState({ searchData: data });
  };
  render() {
    let { searchData } = this.state;

    let category_array = this.props.appdata.length
      ? this.props.appdata[0].category
      : [];
    let category = [];

    category_array.forEach((element, index) => {
      category.push({ value: element.id, label: element.category_name });
    });
    category_array = category;

    let facility_list = this.props.appdata.length
      ? this.props.appdata[0].facilities
      : [];
    let facility = [];

    facility_list.forEach((element, index) => {
      facility.push({ value: element.id, label: element.facility_name });
    });
    facility_list = facility;
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;
    return (
      <div className="col-sm-4 pl0">
        <div className="card">
           <div class="card-header"> <div class="card-title">Search Hospital</div> </div>
          <div className="card-body favHeight">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-shipping"
                role="tabpanel"
                aria-labelledby="v-pills-shipping-tab"
              >
                <div className="row">
                  <div className="col-sm-12">
                  
                    {/* <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>Category*</label>
                      </div>
                      <div className="form-group frlrit2">
                        
                        <Select
                          className="form-control p-0"
                          name="category"
                          value={searchData.category}
                          onChange={this.handleselectChange}
                          options={category_array}
                        />
                      </div>
                    </div> */}
                    {/* <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label className="visitli">Visit type </label>
                      </div>
                      <div className="form-group frlrit2">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-check fltx">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="visit_type"
                                id="oncall"
                                onChange={this.handleInput}
                                value="oncall"
                                checked={
                                  searchData.visit_type == "oncall"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="oncall"
                              >
                                Oncall
                              </label>
                            </div>
                            <div className="form-check fltx">
                              <label
                                className="form-check-label"
                                htmlFor="inperson"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onChange={this.handleInput}
                                  name="visit_type"
                                  id="inperson"
                                  value="inperson"
                                  checked={
                                    searchData.visit_type == "inperson"
                                      ? true
                                      : false
                                  }
                                />
                                In-Person
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>Facility name</label>
                      </div>
                      <div className="form-group frlrit2">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="glyphicon fa fa-search icontextb"></i>
                          </span>
                          
                          <Select
                            className="form-control p-0"
                            name="facility"
                            value={searchData.facility}
                            onChange={
                              this.handleselectChange
                            }
                            options={facility_list}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>Doctor Name</label>
                      </div>
                      <div className="form-group frlrit2">
                        <div className="input-group">
                          <InputField
                            inputType={"text"}
                            name={"doctor_name"}
                            controlFunc={this.handleInput}
                            content={searchData.doctor_name}
                            placeholder={""}
                            max={50}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft2">
                        <Label title="Speciality" isMandatory={true}/>
                      
                      </div>
                      <div className="form-group sform-control frlrit2">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="speciality"
                          // isMulti
                          value={searchData.speciality}
                          onChange={this.handleselectChange}
                          options={specialities_array}
                        />
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>Facility</label>
                      </div>
                      <div className="form-group sform-control frlrit2">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="facility"
                          value={searchData.facility}
                          onChange={this.handleselectChange}
                          options={facility_list}
                        />
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group  frllft2">
                        <label>Gender</label>
                      </div>
                      <div className="form-group sform-control frlrit2">
                        <Select maxMenuHeight={120}
                          className="form-control p-0"
                          name="gender"
                          value={searchData.gender}
                          onChange={this.handleselectChange}
                          options={gender}
                        />
                        {/* <select className="form-control">
                                              <option>Male</option>
                                              <option>Select</option>
                                              <option>Select</option>
                                            </select> */}
                      </div>
                    </div>
                    <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>City</label>
                      </div>
                      <div className="form-group frlrit2">
                        <InputField
                          inputType={"text"}
                          // title={"Full name"}
                          name={"city"}
                          controlFunc={this.handleInput}
                          content={searchData.city}
                          placeholder={""}
                          max={50}
                        />
                      </div>
                    </div>

                    <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>Distance </label>
                      </div>
                      <div className="form-group ">
                        <div className="col-sm-12">
                          <div className="fltx">
                            <output name="rangeVal">
                              {searchData.distance}
                            </output>
                          </div>
                          <div className="form-check fltx">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="distance_type"
                              id="kM"
                              onChange={this.handleInput}
                              value="KM"
                              checked={searchData.distance_type ==="KM"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="KM"
                            >
                              KM
                            </label>
                          </div>
                          <div className="form-check fltx">
                            <label
                              className="form-check-label"
                              htmlFor="Miles"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                onChange={this.handleInput}
                                name="distance_type"
                                id="Miles"
                                value="Miles"
                                checked={
                                  searchData.distance_type ==
                                  "Miles"
                                }
                              />
                              Miles
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group frlrit2">
                        <input
                          className="form-control"
                          type="range"
                          id="range_01"
                          min="0"
                          max="200"
                          step="1"
                          value={searchData.distance}
                          onChange={
                            this.handleDistanceChange
                          }
                        />
                      </div>
                    </div>


                    {/* <div className="frllftfull">
                      <div className="form-group frllft2">
                        <label>Reviews </label>
                      </div>
                      <div className="form-group frlrit2 searchStar reviewsdesign">
                        <p id="SearchReviewId">
                          <i
                            className="fa fa-star-o"
                            aria-hidden="true"
                            onClick={this.reviewChange.bind(
                              this,
                              1
                            )}
                          ></i>
                          <i
                            className="fa fa-star-o"
                            aria-hidden="true"
                            onClick={this.reviewChange.bind(
                              this,
                              2
                            )}
                          ></i>
                          <i
                            className="fa fa-star-o"
                            aria-hidden="true"
                            onClick={this.reviewChange.bind(
                              this,
                              3
                            )}
                          ></i>
                          <i
                            className="fa fa-star-o"
                            aria-hidden="true"
                            onClick={this.reviewChange.bind(
                              this,
                              4
                            )}
                          ></i>
                          <i
                            className="fa fa-star-o"
                            aria-hidden="true"
                            onClick={this.reviewChange.bind(
                              this,
                              5
                            )}
                          ></i>
                        </p>
                      </div>
                    </div> */}

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="form-group mb-0">
                      <button
                        onClick={this.searchBusiness}
                        className="signupbtn abtn"
                      //   onclick="window.location.href='#'"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchData: (searchData) =>
      dispatch({ type: "SET_SEARCH_DATA", payload: searchData }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
