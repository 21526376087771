import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { addError } from "./actions/errors";
import { addAppdata } from "./actions/appdata";
import StoreFactory from "./store";
import sampleData from "./config/InitialState.json";
import axios from "axios";
import { AppDataurl, meData, checkvalidity,checkadddoctorvalidity,getReadCount } from "./config/url.json";
import { CONFIG } from "./config/data";
import jwt from "jsonwebtoken";

const initialState = localStorage["redux-store"]
  ? JSON.parse(localStorage["redux-store"])
  : sampleData;
const store = StoreFactory(initialState);

const saveState = () => {
  localStorage["redux-store"] = JSON.stringify(store.getState());
};
const handleError = (error) => {
  store.dispatch(addError(error.message));
};

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
};

let token = localStorage.getItem("token")

const jwt_secret =
  "bIZ6frdgw6xjfQjtgtrgowpxDlE3NS1XqaGc3hlvih7FWUqSE2bN24ptsI8Qqxr9";
// verify a token symmetric
if (token) {
  jwt.verify(token, jwt_secret, (err, decoded) => {
    if (err) {
      localStorage.removeItem("token");
      token = null;
    } else {
      // if (decoded.iss !== LaravelUrl + "/login") {
      //   localStorage.removeItem("token");
      //   token = null;
      // }
    }
  });
}
axios.interceptors.request.use(function (config) {
  // Do something before request is sent

  console.log('hi')
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
let state = store.getState();
let access_token = state.AuthReducer.access_token;
console.log(state.AuthReducer);
let user = state.AuthReducer.user;


if (state.AuthReducer.loggedIn) {
  let user_id = user.id;
  let url = AppDataurl + "?user_id=" + user_id;
  axios.get(url, CONFIG).then((res) => {
    store.dispatch(addAppdata(res.data));
  });
  // clear search filter from redux history if move to other pages
  if(window.location.pathname !== "/search"){
    const searchData= {
      category: "",
      business_name: "",
      doctor_name: "",
      facility: "",
      speciality: "",
      city: "",
      zip_code: "",
      gender: "",
      distance: "5",
      distance_type: "KM",
      reviews: "",
      visit_type: "oncall",
    };
    // store.dispatch(addAppdata(searchData));
    store.dispatch({ type: "SET_SEARCH_DATA", payload: searchData});
  }
  if (window.location.pathname === "/dashboard" || window.location.pathname === "/queuelist" || window.location.pathname === "/patient-checkin"
    || window.location.pathname === "/queue_assignment" || window.location.pathname === "/shift_planner" || window.location.pathname === "/client-profile"
    || window.location.pathname === "/clients" || window.location.pathname === "/businessuser-list" || window.location.pathname === "/facilitylist" || 
    window.location.pathname === "/drugs" || window.location.pathname === "/medicinesupplies" || window.location.pathname === "/pharmacyList" || window.location.pathname === "/labList" || 
    window.location.pathname === "/disease" || window.location.pathname === "/symptom") {




    console.log(user.id)
    console.log(user.user_type)



    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${access_token}`;
    axios
      .post(checkvalidity, { user_id: user.id, user_type: user.user_type }, CONFIG)
      .then((resp) => {


        store.dispatch({ type: "SET_VALIDITY", payload: resp.data.validity })


        //this.props.setValidity(resp.data.validity);
      })
      .catch((e) => { });
  }
/* 
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${access_token}`;
  axios
    .post(checkadddoctorvalidity, { user_id: user.id, user_type: user.user_type }, CONFIG)
    .then((resp) => {


      store.dispatch({ type: "SET_ADD_DOCTOR_VALIDITY", payload: resp.data.validity })
     
    })
    .catch((e) => { });
 */



  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${access_token}`;
  axios
    .post(checkvalidity, { user_id: user.id, user_type: user.user_type }, CONFIG)
    .then((resp) => {


      store.dispatch({ type: "SET_VALIDITY", payload: resp.data.validity })


      //this.props.setValidity(resp.data.validity);
    })
    .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${access_token}`;
    axios
      .post(getReadCount, { message_to: user.id }, CONFIG)
      .then((resp) => {
    
    console.log(resp.data.data);
        store.dispatch({ type: "SET_MessageCount", payload: resp.data.data })
    
    
        //this.props.setValidity(resp.data.validity);
      })
      .catch((e) => { });

}








axios.interceptors.response.use(
  res => {
    if (
      res &&
      res.data !== "undefined" && (
        res.data.message === "Token is Expired" || res.data.message === "Unauthorized" || res.data.message === 'Token is Invalid'
        //|| res.data.message === "Authorization Token not found"
      )
    ) {
      localStorage.removeItem("token");
      store.dispatch({ type: "SET_LOGOUT" });
      // alert("Your session is expired, please login again");
      window.location.replace("/");
    } else {
      return res;
    }
  },
  err => {
    if (err === 401) {
      console.log(err);
      localStorage.removeItem("token");
      store.dispatch({ type: "SET_LOGOUT" });
      // alert("Your session is expired, please login again");
      window.location.replace("/");
    }
  }
);

token = localStorage.getItem("token")
console.log(access_token)

console.log('token')
if (token) {

  //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios
    .post(meData, "", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => {
      store.dispatch({ type: "SET_LOGIN", payload: res.data });


      localStorage.setItem('token', access_token);

      render()

    })
    .catch((err) => {
      localStorage.removeItem("token");
      token = null;
      window.location.replace("/");
      // this.props.history.push("/signup");
    });

  // axios.defaults.headers.get["application_id"] =
  // "c86ae3f5-3a96-49f2-af05-94746357d0ec";
  axios.defaults.headers.get["Content-Type"] = "application/json";

  axios.get(AppDataurl, "", CONFIG).then((res) => {
    store.dispatch(addAppdata(res.data));
  });
} else {
  // axios.defaults.headers.get["application_id"] =
  // "c86ae3f5-3a96-49f2-af05-94746357d0ec";
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.get(AppDataurl, "", CONFIG).then((res) => {
    store.dispatch(addAppdata(res.data));
  });
  render();
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
window.addEventListener("error", handleError);
window.addEventListener("options", handleError);
store.subscribe(saveState);
window.React = React;
window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
