import React, { Component  } from 'react';
import { connect } from 'react-redux';
import AdminHeader from '../components/container/layout/admin/Header';
import AdminSideBar from '../components/container/layout/admin/SideBar';
import FooterSection from '../components/UI/FooterSection';
import SpanField from '../components/UI/SpanField';
import Label from '../components/UI/Label';
import axios from 'axios';
import { getSettingsData, updateSettingsData } from '../config/url.json';
import TextEditor from '../components/UI/TextEditor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function mapStateToProps(state) {
    return {

    };
}



class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            supportData: { support_phone_number: "", support_email_id: "",terms:"",privacy_policy: "",
            offers:"",
            faq:""},
            formSuccess: { status: "", message: "" },
            errors: { support_phone_number: "", support_email_id: "",terms:"",privacy_policy: "",
            offers:"",
            faq:"" }
        }
    }
   
    handleInput = (e) => {
        let data = this.state.supportData;
        data[e.target.name] = e.target.value;

        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        console.log(data);
        let reqdata = {
            support_email_id: data.support_email_id,
            support_phone_number: data.support_phone_number
        }
        axios
            .post(updateSettingsData, reqdata)
            .then((response) => {
                console.log(response.data)
                this.setState({ supportData: data });

            }).catch(() => {
                console.log("Update queue failed");
            });
    }
    componentDidMount() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getSettingsData, null)
            .then((response) => {
                // console.log(response.data)
                let supportData = [];
                response.data.data.forEach((element) => {

                    supportData[element.name] = element.value
                })
                console.log(supportData)
                this.setState({ supportData: supportData })
                // console.log(this.state.supportData)
            }).catch(() => {
                console.log("get queue failed");
            });
    }
    saveData = () => {
        console.log(document.getElementById('privacy_policy').value)
        let supportData = this.state.supportData;
        supportData.privacy_policy = document.getElementById('privacy_policy')? document.getElementById('privacy_policy').value : "";
        supportData.terms = document.getElementById('terms')? document.getElementById('terms').value : "";
        supportData.faq = document.getElementById('faq')? document.getElementById('faq').value : "";
        supportData.offers = document.getElementById('offers')? document.getElementById('offers').value : "";
        console.log(supportData)
        let request = {
            privacy_policy:supportData.privacy_policy,
            offers:supportData.offers,
            faq:supportData.faq,
            terms:supportData.terms
        };
        
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        
        axios
            .post(updateSettingsData, request)
            .then((response) => {
                console.log(response);
                console.log(response.data)
                this.setState({ supportData: supportData});
                toast.success("Saved Successfully. ", {
                    className: "toast-success"
                  });
            }).catch(() => {
                console.log("Update queue failed");
            });
    }
    
    render() {
        const {
            supportData,

            errors,
            formSuccess,
        } = this.state;
        return (
            <div className="main-content bacfot" >
                <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                    <div className="main-box adminMainBox">
                        <div className="sidemenu pr0">
                            <AdminSideBar />
                        </div>
                        <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                        <section className="main-body hospital-profilegap">
                            <div className="container-fluid">
                                <div className="checkout-tabs">
                                <div className="row">
                                            <div className="col-sm-12">


                                                <div className="card">
                                                <div className="card-header"> <div className="card-title">Settings</div> </div>
                                                    <div className="card-body m1089">
                                                        <div
                                                            className="tab-content"
                                                            id="v-pills-tabContent"
                                                        >
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="v-pills-shipping"
                                                                role="tabpanel"
                                                                aria-labelledby="v-pills-shipping-tab"
                                                            >
                                                                <div className="col-sm-12">
                                                                    <SpanField
                                                                        classname={
                                                                            "0" === formSuccess.status
                                                                                ? "text-danger user-notification"
                                                                                : "text-success user-notification"
                                                                        }
                                                                        title={formSuccess.message}
                                                                    />
                                                                 

                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Support Email ID"
                                                                                isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                            <input className="form-control" type="text" name="support_email_id" max={50} value={supportData.support_email_id} onChange={this.handleInput} />


                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.support_email_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Support Phone Number"
                                                                                isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                            <input className="form-control" type="text" name="support_phone_number" max={50} value={supportData.support_phone_number} onChange={this.handleInput} />


                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.support_phone_number}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Privacy Policy"
                                                                                //isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                        <TextEditor id="privacy_policy" content={supportData.privacy_policy} />


                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.privacy_policy}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Terms and Condition"
                                                                                //isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                        <TextEditor id="terms" content={supportData.terms} />





                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.terms}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Faq"
                                                                                //isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                        


                                                                        <TextEditor id="faq" content={supportData.faq} />


                                                                        <SpanField
                                                                            classname="text-danger"
                                                                            title={errors.faq}
                                                                        />
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Offers"
                                                                                //isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                       


                                                                        <TextEditor id="offers" content={supportData.offers} />


                                                                                <SpanField
                                                                                    classname="text-danger"
                                                                                    title={errors.offers}
                                                                                />
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        
                                                                        <div className="form-group text-center">

                                                                       


                                                                        <button id="admindashboard"
               
                className="signupbtn abtn button float-right" onClick={this.saveData}
                
                
              >Save</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>
                          
                        </section>
                        <FooterSection />
                    </div>
                  
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(AdminDashboard);