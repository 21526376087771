import React, { Component } from "react";
// import Modal from '../components/container/GenericModal';
// import { addProductInAPI } from "../actions/products";
// import ProductList from "../components/container/ProductList";
// import AppData from "../../components/container/AppData";
// import { doLogin } from "../../actions/login";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LogoSection from "../../components/UI/LogoSection";
import FooterSection from "../../components/UI/FooterSection";
import InputField from "../../components/UI/InputField";
import ErrorAlert from "../../components/UI/ErrorAlert";
import Validation from "../../actions/Validation";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import OtpTimer from 'otp-timer'

import {
  verifyOTPClient,
  resendOTPClient,
  textNotificationURL,
  emailNotificationURL,
  sendotp,
  sendsms,
  sendemail
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function mapStateToProps(state) {
  return {
    userdata: state.AuthReducer,
  };
}
class VerificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resendsuccess: true,
      data: {
        otp: "",
        unique_id: "",
        type: "verification",
        otp_data: "",
      },
      errors: {
        otp: "",
      },
      error: "",
    };
  }
  componentDidMount() {
    let data = this.state.data;
    console.log(this.props.userdata);
    let requestData = {
      id: this.props.userdata.user.id,
    };

    axios
      .post(sendotp, requestData, CONFIG)
      .then((response) => {
        console.log(response);
        data.unique_id = response.data.unique_id;
        data.otp_data = response.data.otp;
        console.log(this.props.userdata);
        console.log(this.props.userdata.user.phone_code);
        console.log(this.props.userdata.user.phone_number);
        let user = this.props.userdata.user;
        let reqdata = {
          "to" : user.email,
          "subject" : "OTP VERIFICATION",
          "mailHeader" : "OTP VERIFICATION",
          "message": "Hello "+user.first_name+",<p>Thank you for choosing KlinicQ.Use the following OTP to complete your Sign Up procedures and verify your account on KlinicQ.</p>" +response.data.otp+ "<p> OTP is valid for next 2 minutes.</p><p>Note:Never share this OTP with anyone for security reasons.</p><p>Regards,</p><p>Team KlinicQ.</p>"
      }
        let notificationdata = {
          "message_code": "SEND_OTP",
          "language_code": "en",
          "data": {
            "user_name": user.first_name,
            "otp": response.data.otp

          },
          "receiver": {
            "phoneNumber": user.phone_code.concat(user.phone_number)
          }
        };

        // notificationdata = {
        //   "message_code": "WLCME_MSG",
        //   "language_code": "en",
        //   "data": {
        //     "business_name": "Meenakshi",
        //     "customer_name": "Customer"
        //   },
        //   "receiver": {
        //     "phoneNumber": "+919944053206"
        //   }
        // }
        console.log(notificationdata);
        axios
        .post(sendemail, reqdata)
        .then((res) => {
          let resdata = res.data;  
          console.log(res);
        axios
          .post(sendsms, notificationdata)
          .then((response) => { console.log(response) }).catch((err) => {
            console.log(err);
          })
        this.setState({ data:data });
        window.setTimeout(() => {
          this.setState({
            resendsuccess: false,
          });
        }, 5000);

      })
      .catch((e) => { console.log(e) });
    })
  }
  resendOtp = (e) => {
    // e.preventDefault();
    this.clearForm();
    // errors = Validation(data, errors);

    // this.setState({ errors: errors });
    let email_phonenumber = "";
    let user = this.props.userdata.user;
    let url = "";
    let Notificationdata = {};
    if (this.props.userdata.user.phone_number !== null) {
      email_phonenumber = this.props.userdata.user.phone_number;
      url = textNotificationURL;
      Notificationdata.phoneNumber = email_phonenumber;
    } else if (this.props.userdata.user.email !== null) {
      email_phonenumber = this.props.userdata.user.email;
      url = emailNotificationURL;
      Notificationdata.email = email_phonenumber;
    }

    if (email_phonenumber !== "") {
      try {
        let data = {};
        data.email_phonenumber = email_phonenumber;
        const result = axios.post(resendOTPClient, data, CONFIG);

        const p = Promise.resolve(result);

       p.then((value) => {
          if (value.data.status === "1") {
            this.props.setUserData(value.data.user);
            this.setState({ resendotpvalue: value.data.otp });
            let data1 = this.state.data;

            data1.unique_id = value.data.unique_id;
            data1.otp_data=value.data.otp;
            //console.log(data1);

            // // let Notificationdata = {};
            // Notificationdata.trackingId = value.data.unique_id;
            // Notificationdata.message =
            //   value.data.otp +
            //   " is Your OTP for Mobile no verification to activate your account.";
            // const options = {
            //   headers: {
            //     Accept: "*/*",
            //   },
            // };
            let reqdata = {
              "to" : user.email,
              "subject" : "OTP VERIFICATION",
              "mailHeader" : "OTP VERIFICATION",
              "message": "Hi "+user.first_name+", Your OTP is "+value.data.otp+". OTP is valid for next 2 minutes."
          }
            let notificationdata = {
              "message_code": "SEND_OTP",
              "language_code": "en",
              "data": {
                "user_name": user.first_name,
                "otp": value.data.otp

              },
              "receiver": {
                "phoneNumber": user.phone_code.concat(user.phone_number)
              }
            };

            // notificationdata = {
            //   "message_code": "WLCME_MSG",
            //   "language_code": "en",
            //   "data": {
            //     "business_name": "Meenakshi",
            //     "customer_name": "Customer"
            //   },
            //   "receiver": {
            //     "phoneNumber": "+919944053206"
            //   }
            // }
            console.log(notificationdata);
            axios
            .post(sendemail, reqdata)
            .then((res) => {
              let resdata = res.data;  
              console.log(res);    
            axios
              .post(sendsms, notificationdata)
              .then((response) => {
                console.log(response);
                this.setState({ data: data1, resendsuccess: true });
              }).catch((error) => {
                console.log(error);
                if (e.response) {
                  this.setState({ error: error.response.data.errors });
                  window.setTimeout(() => {
                    this.setState({
                      error: "",
                    });
                  }, 5000);
                }
              })
            })

            // axios
            // .post(url, Notificationdata, options)
            // .then((response) => {
            //   // data.loading = false;
            //   // this.props.setUserData(value.data.data.user);
            //   // this.props.history.push("/verify/" + value.data.data.unique_id);
            //   this.setState({ data: data1, resendsuccess: true });
            //   this.props.history.push("/verify/" + value.data.unique_id);
            // })
            // .catch((error) => {
            //   if (e.response) {
            //     this.setState({ error: error.response.data.errors });
            //     window.setTimeout(() => {
            //       this.setState({
            //         error: "",
            //       });
            //     }, 5000);
            //   }
            // });

            window.setTimeout(() => {
              this.setState({
                resendsuccess: false,
              });
            }, 5000);
          }
          if (value.data.status === "0") {
            this.setState({ error: value.data.message });
            window.setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 5000);
          }
        });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
    }
  };
  handleOtp = (e) => {
    e.preventDefault();
    let { data, errors } = this.state;
    console.log(data);
    errors = Validation(data, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      try {
        if (this.props.userdata.referral_code !== "") {
          data.referral_code = this.props.userdata.referral_code;
        } else {
          data.referral_code = "";
        }
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
        data.otp_data=this.state.resendotpvalue;
        const result = axios.post(verifyOTPClient, data, CONFIG);

        const p = Promise.resolve(result);
        p.then((value) => {
          if (value.data.status === "1") {
            toast.success("Your OTP has been successfully verified",{
              className:"toast-success"
            }); 
            localStorage.setItem("token", value.data.access_token);
            // dispatch
            this.props.setLogin(value.data.user);
            this.props.setAuthToken(value.data.access_token);

            //this.props.history.push("/updateprofile");
            window.location.replace("/updateprofile");
          }
          if (value.data.status === "0") {
            this.setState({ error: value.data.message });
            window.setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 5000);
          }
        });
       
        // dispatch(
        //     addUserDeails(result.data)
        // )
     
    }
  };
  handleInput = (e) => {
    let data = this.state.data;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ data: data });
  };
  clearForm = () => {
    this.setState({
    
      data: {
        otp: "",
        unique_id: "",
        type: "verification",
        otp_data: "",
      },
      errors: {
       
        otp: "",
      },
    });
  };
  render() {
    const { data, errors, error,resendotpvalue } = this.state;
    return (
      <div id="layout-wrapper">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Start right Content here --> */}
        {/* <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="mainpage">
           
            <section className="pagenav">
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

              {/* <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="topbanner">VERIFY ACCOUNT ?</h4>
                    <a href="/">Home</a>{" "}
                    <i
                      className="fa fa-angle-double-right span"
                      aria-hidden="true"
                    ></i>{" "}
                    <a href="#" className="finelclr">
                      VERIFY ACCOUNT ?
                    </a>
                  </div>
                </div>
              </div> */}
            </section>
            {/* <section className="my-head">
              <div className="container-fluid">
                <div className="row">
                  <LogoSection />

                </div>
              </div>
            </section> */}
                <section className="my-head">
              <div className="container-fluid">
              <div className="logocenter verifyLogo justify-content-center ml-0">
                <a href="#">
                  <img alt="LOGO" src={require("../../assets/images/logo.png")} />
                </a>
              </div>
              </div>
            </section>
            <section className="main-body pl-0 verfiyPage">
              <div className="container">
                <div className="checkout-tabs">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      {error ? <ErrorAlert message={error} /> : null}
                    </div>
                  </div>
                  <div className="row justify-content-center">


                    <div className="col-lg-6">
                      <div className="card box-otp">
                        <div className="card-body pb-10 frgthight">
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="v-pills-shipping"
                              role="tabpanel"
                              aria-labelledby="v-pills-shipping-tab"
                            >
                              <div>
                                <h4 className="card-title text-center">
                                  OTP Verification
                                </h4>
                              </div>


                              <div className="row">
                            

                                <div className="col-lg-12">
                                 {/*    <h5>{this.state.data.otp_data}</h5>
                                  <h5>{this.state.resendotpvalue} </h5>  */}
                           <h5 className="text-center"><OtpTimer seconds={60} minutes={1}  resend={this.resendOtp} /></h5>
                                  <p>
                                  A One Time Password has been sent to your registered email and mobile number,
                                   please enter below.

                                  </p>


                                  <div className="form-group">
                                    <label>Enter OTP</label>


                                    <InputField
                                      // isMandatory={true}
                                      inputType={"text"}
                                      // title={'Last Name'}
                                      name={"otp"}
                                      controlFunc={this.handleInput}
                                      content={data.otp}
                                      placeholder={
                                        "Enter the 6 digit OTP here to verify"
                                      }
                                    />
                                    <SpanField
                                      classname="text-danger"
                                      title={errors.otp}
                                    />
                                  </div>


                                  <div className="form-group mb-0">

                                    <button
                                      onClick={this.handleOtp}
                                      className="btn btn-lg btn-block btn-nucleo"
                                    >
                                      Verify
                                    </button>
                                
                                  </div>

                                  {/* <div className="col-sm-12">
                                    <div className="row">
                                      <div className="col-xs-8">
                                        <div className="form-group mt-1">
                                          <label>
                                            Still did not receive OTP yet ?
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-xs-4">
                                        <div className="form-group mb-0">
                                          <button
                                            onClick={this.resendOtp}
                                            className="btn btn-link font-weight-bold text-decoration-none text-success"
                                          >
                                            Resend OTP
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>
          </div>
          <FooterSection />
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
    setUserData: (user) => dispatch({ type: "SET_USERDATA", payload: user }),
    setAuthToken: (token) =>
      dispatch({ type: "SET_ACCESS_TOKEN", payload: token }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerificationPage)
);
