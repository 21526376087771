import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import {
  getAllReferralDetails,
  updateReferralDetails,
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}


const status = [
  { value: "Pending", label: "Pending" },
  { value: "Accepted", label: "Accepted" },
];
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

class ReferralDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],

      formSuccess: {
        message: "",
        status: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "category",
        text: "Category",
        className: "category1",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email-Id",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "phone_number",
        text: "Phone number",
        className: "phone_number",
        align: "left",
        sortable: true,
      },
      {
        key: "credit_amount",
        text: "Credit Points",
        className: "credit_amount",
        sortable: true,
        align: "left",
      },
      {
        key: "points_redeemed_status",
        text: "Points status",
        className: "points_redeemed_status",
        sortable: true,
        align: "left",
      },

      {
        key: "status",
        text: "Status",
        className: "status",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.status,
            value: record.status,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="status"
              value={obj}
              onChange={() => this.handleColumnChange(record)}
              options={status}
              disabled={(option) => option.value === "Accepted"}
            // disabled={record.status == "Accepted"}
            />
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    
  }
  UpdateColumnChange = (data) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios.post(updateReferralDetails, data, CONFIG).then((list) => {
      let records = this.state.records;

      data = list.data.data;
      var recIndex = findWithAttr(records, "id", data.id);

      records[recIndex].name = data.name;
      records[recIndex].status = data.status;
      records[recIndex].credit_amount = data.credit_amount
        ? data.credit_amount
        : 0;

      records[recIndex].category = data.category;
      records[recIndex].email = data.email;
      records[recIndex].phone_number = data.phone_number;

      records[recIndex].id = data.id;

      this.setState({ records, tableSuccess: list.data });
      this.clearMessage();
    });
  };

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
        }),
      5000
    );
  };

  componentDidMount = () => {

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllReferralDetails, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let Referrals = list.data.data;

        Referrals.forEach((element, index) => {
          let pharmacy = {};
          pharmacy.s_no = index + 1;
          pharmacy.name = element.name;
          pharmacy.credit_amount = element.credit_amount
            ? element.credit_amount
            : 0;
          pharmacy.category = element.category;
          pharmacy.id = element.id;
          pharmacy.email = element.email;
          pharmacy.status = element.status;
          pharmacy.phone_number = element.phone_number;
          pharmacy.points_redeemed_status=element.points_redeemed_status;
          Referrals[index] = pharmacy;
        });

        this.setState({ records: Referrals });
      })
      .catch(() => { });
  };

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.pharmacyData;
    data[e.name] = selectedOptions;
    this.setState({ pharmacyData: data });
  };
  handleColumnChange = (record) => {
    record.status = "Accepted" === record.status ? "Pending" : "Accepted";
    this.UpdateColumnChange(record);
  };
  handleInput = (e) => {
    let data = this.state.pharmacyData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ pharmacyData: data });
  };
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    const { tableSuccess } = this.state;
    return (
      <div id="layout-wrapper">
      {/* <!-- ============================================================== --> */}
      {/* <!-- Start right Content here --> */}
      {/* <!-- ============================================================== --> */}
      <div className="main-content grayback">
          <div className="mainpage">
              <div className="main-box">
 
      <section className="main-body hospital-profilegap">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <div className="hmeclr bgligtrn">
              <h5 className="fnsz hptd"> Referral Details </h5>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body ">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <div className="user-notification">
                      <SpanField
                        classname={
                          0 === tableSuccess.status
                            ? "text-danger user-notification"
                            : "text-success user-notification"
                        }
                        title={tableSuccess.message}
                      />
                    </div>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      className="table table-bordered table-striped kas  mobile_table"
                    />
                    <Popup
                      show={this.state.addPopup}
                      onHide={PopupClose}
                      onConfirm={PopupConfirm}
                      title={"Confirmation"}
                      message={"Are you sure you want to delete this record?"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
       </div>
       </div>
   </div>
</div>
    );
  }
}

export default connect(mapStateToProps)(ReferralDetails);
