
import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../../components/UI/FooterSection";

import BusinessUserSideBar from "../../components/container/SideBar";
import Header from "../../components/container/layout/business/Header";

import { Spinner } from "../../components/UI/Spinner";

import LabList from "./LabList";
import PharmacyList from "./PharmacyList";
import FacilityList from "./FacilityList";
import Rooms from "./Rooms";
import PatientCheckin from "./PatientCheckin";
import ReferralDetails from "./ReferralDetails";
import BusinessuserProfile from "./BusinessuserProfile";
import ClientProfile from "./ClientProfile";
import MedicineSupplies from "../../views/MedicineSupplies";
import PharmacyProfile from "./PharmacyProfile";
import ExpirePopup from "../../components/UI/ExpirePopup";
import AppointmentCheckin from "../../views/AppointmentCheckin";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity

  };
}


class BusinessLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showexpirePopup: this.props.validity,
    };
  }


  getPage = () => {
    if ("MedicineSupplies" === this.props.page) {
      return <MedicineSupplies />;
    }
    else if ("labList" === this.props.page) {
      console.log(this.props);
      return <LabList />;
    } else if ("patientCheckin" === this.props.page) {
      return <PatientCheckin />;
    } else if ("appointmentCheckin" === this.props.page) {
      return <AppointmentCheckin />;
    } else if ("facilityList" === this.props.page) {
      return <FacilityList />;
    } else if ("pharmacyList" === this.props.page) {
      return <PharmacyList />;
    } else if ("pharmacyProfile" === this.props.page) {
      return <PharmacyProfile />;
    } else if ("ReferralDetails" === this.props.page) {

      return <ReferralDetails />;
    } else if ("businessuserProfile" === this.props.page) {

      return <BusinessuserProfile />;
    } else if ("clientProfile" === this.props.page) {
      return <ClientProfile />;
    }
    else if ("rooms" === this.props.page) {
      return <Rooms />;
    }
  };

  render() {

    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    return (
      <div id="layout-wrapper">
        {("ReferralDetails" === this.props.page || "businessuserProfile" === this.props.page) ? "" : (
          <ExpirePopup
            show={!this.state.showexpirePopup}
            onHide={PopupClose}
            onConfirm={PopupConfirm}
            title={"Alert"}
            message={"Your current plan has been expired. Please activate new plan and try again"}
          />)}

        {/* <SideBarNew /> */}
        <Spinner loading={this.state.loading} />
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />

            <div className="main-box">
              <div className="pr0 sidemenu">
                <BusinessUserSideBar />
              </div>
              <section className="main-body hospital-profilegap">
                <div className="container-fluid">

                  <div className="row">

                    {this.getPage()}
                    
                  </div>
                  {/* <Footerad /> */}

                </div>

              </section>
              <FooterSection />
            </div>



          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(BusinessLayout);
