import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import axios from "axios";
import {
    getSettingsData
} from "../config/url.json";

function mapStateToProps(state) {
    return {

        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsData: {},
            loading: false
        }
    }
    componentDidMount() {




        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getSettingsData, null)
            .then((res) => {
              
                let settingsData = [];
                res.data.data.forEach((element, index) => {
                    let name = element.name;
                    settingsData[name] = element.value
                })
                console.log(settingsData)
                this.setState({ settingsData: settingsData })

            })
            .catch((e) => { });
    }
    

    render() {
        const { settingsData } = this.state;
        return (

            <div>
            <div className="m-50">
                <div className="m-50">
                {settingsData.privacy_policy?renderHTML(settingsData.privacy_policy):""}
               
                </div>
            </div>
        </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(PrivacyPolicy);