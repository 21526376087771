import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import IdleTimer from 'react-idle-timer';
import Select from "react-select";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TextArea from "../components/UI/TextArea";
import PersonIcon from "@material-ui/icons/Person";
import { ExpirePopup } from "../components/UI/ExpirePopup";
import * as moment from "moment";
import DatePicker from "react-date-picker";
import { Modal } from "react-bootstrap";
import Label from "../components/UI/Label";
import S3 from "react-aws-s3";
import {
    blood_group,
    gender,
    relationship,
} from "../constants/config/data";
import {
    getPatientAppointmentColor, calculate_age
} from "../constants/globalLevelFunctions";
import { Spinner } from "../components/UI/Spinner";
import {
    getMasterSymptoms,
    getTimeoutbycategory,
    UpdateQueueRemove,
    getTodayAppointmentbyphonenumber, getSymptomByCategory,addCheckinURL,
    updateclientappointmentstatus,saveClientAppointment,addMedicalrecordspath
} from "../config/url.json";
import {clientmedimageconfig} from "../../src/config/data";
import axios from "axios";
import { CONFIG } from "../config/data";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from '../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
        validity: state.ValidityReducer.validity

    };
}
function mapDispatchToProps(dispatch) {
    return {
      logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
  }

class AppointmentCheckin extends Component {
    fileObj = [];
    fileArray = [];
 
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.searchData = {
            phone_number: "",
            errors: "",
        };

        this.clientData = {

            user_id: "",
            business_user_id:"",
            id: "",
            relationship: {},
            first_name: "",
            last_name: "",
            date_of_birth: "",
            gender: "",
            blood_group: "",
            doctor_name:"",

            //  checkin_category: checkin_category,
            reason_for_visit: "",
            other_comments: "",

            //patient basic check up
            height: "",
            weight: "",
            bmi: "",
            temperature: "",
            bp: "",
            pulse: "",
            symptoms: "",
        };
        this.state = {
            adminstatus: this.props.userdata.adminstatus,
            selectedSymptoms: [],
            symptoms: [],
            activeIndex: '',
            cloneSymptoms: [],

            symptomCategory: [],
            patientBasicData: [],
            is_add_btn: false,
            searchData: this.searchData,
            showexpirePopup: true,
            timeout: hospitaltimeout,
            isTimedOut: false,
            loading: false,
            cancelModel: false,
            searchSuccess: {
                message: "",
                status: "",
            },
            clientData: this.clientData,
            errors: {},
            showModal: false,
            selectedFiles: undefined,
            progressInfos: [],
            message: [],

             fileInfos: [],
             file: [null],
             fileUploaded:false,
             uploadedUser_id:"",
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.selectFiles = this.selectFiles.bind(this);
    }
    handleClose() {
        this.setState({ showModal: false })
    }
    handleLogout = (e) => {

        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
       // this.props.logout();
        this.props.history.push("/");

    };
    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
         {
         // console.log("the time is over");
         this.setState({showModal: true})
         console.log(this.state.showModal);
         this.idleTimer.reset();
         this.setState({isTimedOut: true})

        } 
          if(isTimedOut===true)
              {
              localStorage.removeItem("token");
             // this.props.logout();
              this.props.history.push("/");
            }
    }
   /*  handleDOBChange = (date) => {
        let data = this.state.clientData;
        let errors = this.state.errors;
        const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        data.date_of_birth = offsetDate;
        errors.date_of_birth = "";
        this.setState({ clientData: data, errors });
      }; */
    componentDidMount = () => {
      
       console.log(this.state.clientData.user_id); 
        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status == "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch(() => { });

        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getMasterSymptoms, { user_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
                let symptomCategory = list.data.data;
                let symptomCategorylist = [];
                symptomCategory.forEach((element) => {
                    symptomCategorylist.push({
                        value: element.id,
                        label: element.symptom_category,
                    });
                });
                var result = symptomCategorylist.reduce((unique, o) => {
                    if (!unique.some(obj => obj.label === o.label)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);

                result.push({ label: "Others", value: "0" })
                this.setState({ symptomCategory: result });
            })
            .catch(() => { });
    }
    cancelAppointment = () => {
        console.log(this.state.clientData)
        this.setState({
            cancelModel: true,
        });
    };
    handleReset = () => {
        // let data = this.state.clientData;
        let data = Object.assign({}, this.clientData);
        console.log(data);
        let errors = this.state.errors;
        errors = {};

        this.setState({
            clientData: data,
            selectedPerson: "addNewFamilyPerson",
            family_details: [],
            is_add_btn: false,
            disable_insert_queue: false,
            is_queued: false,
            is_check_in: false,
            expected_time: "",
            errors: errors,
            // symptoms:[],
           reason_for_visit: "",
            selectedSymptoms: [],
            selectedFiles:undefined,
            fileObj: [],
            fileArray : [],
            url:"",
            file:"",
        });
    };
    conformCancelAppointment = () => {
        console.log(this.state.clientData);
        let clientData = this.state.clientData;
      
        let data = { client_appt_id: clientData.client_appt_id, "process": "Cancel" }
        axios.post(saveClientAppointment, data).then((response) => {
            if (response.data.status === 1) {
                
                let req_data = {
                    
                    appt_status: 'Cancelled',
                    client_appt_id: clientData.client_appt_id
        
                  }
                  axios
                  .post(updateclientappointmentstatus, req_data, CONFIG)
                  .then((res) => {console.log(res)}).catch((e) => {
                 
                });
              
                let searchSuccess = {
                    message: "Your appointment was canceled successfully.",
                    status: "1",
                };
                toast.success("Your appointment was canceled successfully.", {
                    className: "toast-success"
                });
                this.setState({  loading: false, cancelModel: false });
                this.clearMessage();
                this.handleReset();
                //update the check-in patient height, weigth and temperature
                // this.saveCheckInData();
                // this.getPatientDetail();
                this.reloadPage();
            
        }
        })
       
 
            .catch(() => {
                this.setState({ loading: false, cancelModel: false });
                alert("Error while update queue status");
            });
    };
    getPatientDetail = () => {
        // event.preventDefault();

        let searchData = this.state.searchData;
        if (searchData.phone_number === "") {
            searchData.error = "Phone number is required";
            this.setState({ searchData: searchData });
            return;
        }
        if (searchData.phone_number.length !== 10) {
            searchData.error = "Enter a valid phone number";
            this.setState({ searchData: searchData });
            return;
          }
        // console.log(searchData);return false;
        if (searchData.phone_number !== "") {
            this.setState({ loading: true });
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            axios
                .post(
                    getTodayAppointmentbyphonenumber,
                    { phone_number: searchData.phone_number },
                    CONFIG
                )
                .then((result) => {
                    console.log(result);
                    this.setState({ loading: false });
                    this.handleReset();
                    let patientBasicData = [];
                    console.log(result.data.data.length)
                    if (result.data.status === "1" && result.data.data.length) {
                        let response = result.data.data;
                        let is_queued = false, is_check_in = false;
                        response.map((data) => {
                            console.log(data);
                           // data.reason_for_visit="";
                            is_queued = (data.status === "SCHEDULED" || data.status==="RESCHEDULE");
                            is_check_in = (data.status === "Check-In"|| data.status==="Consulting");

                            data.gender = { label: data.gender, value: data.gender };
                            data.blood_group = {
                                label: data.blood_group,
                                value: data.blood_group,
                            };

                            data.date_of_birth = moment(
                                data.date_of_birth,
                                moment.defaultFormat
                            ).toDate();

                            if (!data.relationship) {
                                data.relationship = {
                                    label: "self",
                                    value: "self",
                                };
                            } else {
                                data.relationship = {
                                    label: data.relationship,
                                    value: data.relationship,
                                };
                            }


                            data.height = data.height;

                            data.weight = data.weight;
                            data.bmi = data.bmi;
                            data.temperature = data.temperature;
                            data.bp = data.bp;
                            data.pulse = data.pulse;
                            data.queue_number = data.preference;
                            data.reason_for_visit = data.reason_for_visit;
                            data.symptoms = data.symptoms;
                            data.other_comments = data.other_comments;
                            data.business_user_id = data.business_user_id;
                            patientBasicData.push(data);
                        });
console.log(patientBasicData);

                        // let family_details = result.data.family_details;

                        searchData.error = "";
                        this.setState({
                            // family_details: family_details,
                            searchData: searchData,
                            searchSuccess: result.data.message,
                            is_queued: is_queued,
                            is_check_in: is_check_in,
                            // is_add_btn: false,
                        });
                       /*  toast.success("Patient details listed Successfully. ", {
                            className: "toast-success"
                        }); */
                        //By default select the parent profile picture in navigation icon

                        //Family Details

                        // family_details.map((data, i) => {

                        //     if (data.queue_data.length) {
                        //         data.queue_data = data.queue_data[0];
                        //     }
                        //     patientBasicData.push(data);
                        // });

                        this.setState({
                            patientBasicData: patientBasicData,
                            selectedPerson: result.data.data[0].client_id,
                        });
                        console.log(this.state.selectedPerson.value);
console.log( result.data.data[0].client_appt_id);
                        // console.log(data)
                        this.setState({
                            clientData: patientBasicData[0],

                        });

                        this.clearMessage();
                    } else {
                        console.log(result.data.message)
                        searchData.error = "No appoinment found";
                        this.setState({
                            searchData,

                            clientData: {},
                            patientBasicData: [],
                            loading: false,
                            selectedPerson: "",
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    console.log(e.data);
                    console.log("business user getbyid failed");
                    //          searchData.error = e.data.message;
                    this.setState({ loading: false });
                });
        }
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    searchSuccess: { message: "" },
                    loading:false
                }),

            5000
        );
    };
    reloadPage = () => {
        this.handleReset();
        let patientBasicData = this.state.patientBasicData;
        patientBasicData = [];

        let searchData = this.state.searchData;
        searchData = { phone_number: "", errors: "" };

        let selectedPerson = this.state.selectedPerson;
        selectedPerson = "";

        this.setState({
            patientBasicData: patientBasicData,
            selectedPerson: selectedPerson,
            searchData: searchData,
            fileObj: [],
            fileArray : [],
            file:""
        });
    };
    handlePhoneInput = (event) => {
        event.preventDefault();
        let data = this.state.searchData;
        let errors = this.state.errors;
        data[event.target.name] = event.target.value
            .replace(/\s/g, "")
            .replace(/[^0-9+]+/g, "");
        data.error = "";
        this.setState({ searchData: data, errors });
    };
    handleSymptomSearch = (e) => {
        let cloneSymptoms = this.state.cloneSymptoms;
        if (null == e.target.value || "" === e.target.value) {
            this.setState({
                symptoms: cloneSymptoms
            });
        } else {
            //let symptoms = this.state.symptoms;
            const filteredSymptopms = cloneSymptoms.filter(symptom => {
                return symptom.symptom_name.toLowerCase().includes(e.target.value.toLowerCase());
            });
            this.setState({
                symptoms: filteredSymptopms
            });
        }


    };
    selectSymptom = (symptom, e) => {
        const options = this.state.selectedSymptoms;
        let index;

        // check if the check box is checked or unchecked
        if (e.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(e.target.value);
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(e.target.value);
            options.splice(index, 1);
        }
        let selectedSymptoms = [];



        options.forEach((element) => {

            selectedSymptoms.push({
                value: element,
                label: element,
            });
        });
        this.setState({ selectedSymptoms: options });

        let consult = this.state.clientData;

        consult.symptoms = selectedSymptoms;


        this.setState({
            clientData: consult,
        });
    };
    selectCheckin = (details) => {

        let data = this.state.clientData;

        data = {
            first_name: details.first_name,
            last_name: details.last_name,
            phone_number: details.phone_number,
            user_id: details.user_id,
            business_user_id:details.business_user_id,
            doctor_name:details.doctor_name, 
            id: details.id,
            date_of_birth: moment(
                details.date_of_birth,
                moment.defaultFormat
            ).toDate(),
            reason_for_visit: "",
            symptoms: "",
            height: "",
            weight: "",
            bmi: "",
            temperature: "",
            bp: "",
            pulse: "",
            selectedSymptoms: [],
            other_comments: "",
            selectedFiles:undefined,
            fileObj: [],
            fileArray : [],
            file:""

        };

        if ("string" === typeof details.gender) {
            data.gender = {
                label: details.gender,
                value: details.gender,
            };
        } else {
            data.gender = details.gender;
        }

        if ("string" === typeof details.relationship) {
            data.relationship = {
                label: details.relationship,
                value: details.relationship,
            };
        } else {
            data.relationship = details.relationship;
        }

        if ("string" === typeof details.blood_group) {
            data.blood_group = {
                label: details.blood_group,
                value: details.blood_group,
            };
        } else {
            data.blood_group = details.blood_group;
        }

        //If queue data is present then show the paitent selected doctor

        let is_queued = false;
        let is_check_in = false;
        if (details.status != '') {

            is_queued = (details.status === "SCHEDULED" || details.status==="RESCHEDULE");
            is_check_in = (details.status === "Check-In"|| details.status==="Consulting");
            data.height = details.height ? details.height : "";

            data.weight = details.weight ? details.weight : "";
            data.bmi = details.bmi ? details.bmi : "";
            data.temperature = details.temperature ? details.temperature : "";
            data.bp = details.bp ? details.bp : "";
            data.pulse = details.pulse ? details.pulse : "";
            data.reason_for_visit = details.reason_for_visit;
            data.symptoms = details.symptoms;
            data.other_comments = details.other_comments;
            data.appt_time=details.appt_time;



        } else {
            data.height = details.height ? details.height : "";

            data.weight = details.weight ? details.weight : "";
            data.bmi = details.bmi ? details.bmi : "";
            data.temperature = details.temperature ? details.temperature : "";
            data.bp = details.bp ? details.bp : "";
            data.pulse = details.pulse ? details.pulse : "";
            data.reason_for_visit = details.reason_for_visit;
            data.symptoms = details.symptoms;
            data.other_comments = details.other_comments;
        }
data.client_appt_id = details.client_appt_id;

        this.setState({
            clientData: data,

            is_queued: is_queued,
            is_check_in: is_check_in,
            selectedPerson:data.client_id,
            is_add_btn: false,
        });
    };
    handleChange = ( event,newValue) => {
        this.handleReset();
        console.log(newValue);
        this.setState({
            selectedPerson: newValue,
           
          });
        console.log(this.state.patientBasicData);
        if ("addNewFamilyPerson" !== newValue) {
            let detail = this.state.patientBasicData.filter((obj) => obj.client_id === newValue);
            console.log(detail[0]);
            this.selectCheckin(detail[0]);
        }
        if(document.getElementById("uploadfile").value!="")
    {
    document.getElementById("uploadfile").value = "";
    }
    };
    handlePatientChange(value, field) {
        let fields = this.state.clientData;
        let errors = this.state.errors;
        if (value === "gender") {
            fields.gender = field;
            errors.gender = "";
            // errors[i].specialization = '';
        } else if (value === "reason_for_visit") {
            fields.reason_for_visit = field;
            errors.reason_for_visit = "";

        } else if (value === "blood_group") {
            fields.blood_group = field;
        } else if (value === "emergency_schedule") {
            fields.emergency_schedule = field;
        } else if (value === "checkin_category") {
            fields.checkin_category = field;

            if (field.label === "Normal") {
                this.setState({ disable_insert_queue: false });
            } else {
                this.setState({ disable_insert_queue: true });
            }
            this.getQueueList();
        } else if (value === "disease") {
            fields.disease = field;
            // errors[i].specialization = '';
        } else if (value === "relationship") {
            fields.relationship = field;
            errors.relationship = "";
        }

        this.setState({
            clientData: fields,
        });
    }
    searchAndShowSymptom(searchData, index) {

        let data = {};
        data.symptom_category = searchData.label;
        data.user_id = this.props.userdata.id;
        console.log(data.user_id);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        this.setState({ loading: true }, () => {
            axios.post(getSymptomByCategory, data, CONFIG).then((searchres) => {
                this.setState({ loading: false });
                let errors=this.state;
                let result = searchres.data;
                if (result.countOfRecords > 0) {
                    let records = result.data;
                    let symptoms = [];
                    records.forEach((element) => {
                        symptoms.push({
                            symptom_name: element.symptom_name,
                            id: element.id,
                        });
                    });
                    errors.symptoms="";
                    this.setState({
                        symptoms: symptoms,
                        cloneSymptoms: symptoms,
                        // consult: { symptoms: symptoms[0].symptom_name },
                        activeIndex: index,
                        errors:errors
                        // selectedSymptoms: [],
                    });
                } else {
                    this.setState({
                        symptoms: [],
                        // consult: { symptoms: [] },
                        activeIndex: index,
                        // selectedSymptoms: [],
                    });
                }
            });
        });
    }
    handleCheckin = (event) => {
        //socketObj.emit("queueStatusChanged", () => { });

        event.preventDefault();
        let clientData = this.state.clientData;
        let searchData = this.state.searchData;
        console.log(clientData)
        if(clientData.status === "Check-In"){
            return ;
        }
        if (searchData.phone_number === "") {
            return;
        }
        let errors = {};
        if (!this.state.clientData.reason_for_visit) {
            errors.reason_for_visit = "Please choose a diagnosis";
            this.setState({ errors: errors });
            return;
        } else {
            errors.reason_for_visit = "";
            this.setState({ errors: errors });
        }
        if (this.state.clientData.symptoms==""||this.state.clientData.symptoms==null||this.state.clientData.symptoms==undefined) {
            errors.symptoms = "Please choose the symptoms";
            this.setState({ errors: errors });
            return;
          } else {
            errors.symptoms = "";
            this.setState({ errors: errors });
          }

       
        // console.log(clientData);return false;
        this.setState({ loading: true });
        let Checkindata = {
            business_id: this.props.userdata.id,
            business_user_id:clientData.business_user_id,

            client_id: clientData.user_id,
            reason_for_visit: clientData.reason_for_visit,
            other_comments: clientData.other_comments,
            height: clientData.height,
            weight: clientData.weight,
            bmi: clientData.bmi,
            temperature: clientData.temperature,
            bp: clientData.bp,
            pulse: clientData.pulse,
            q_id: clientData.client_appt_id,
            symptoms: clientData.symptoms
        };
        console.log(Checkindata);
        axios
            .post(addCheckinURL, Checkindata, CONFIG)
            .then((result) => {


                let req_data = {
                    
                    appt_status: 'Check-In',
                    client_appt_id: clientData.client_appt_id
        
                  }
                  axios
                  .post(updateclientappointmentstatus, req_data, CONFIG)
                  .then((res) => {
                      console.log(res);
                      if (result.data.status === "1") {
                        // let searchSuccess = {
                        //     message: "Patient Check-In details added successfully",
                        //     status: "1",
                        // };
                        toast.success("Your Patient has been checked in successfully. ", {
                            className: "toast-success"
                        });
                        searchData.phone_number = "";
                        searchData.error="";

                       
                        
                        
                    }
    
                    // this.getPatientDetail();
                    this.reloadPage();
                        this.clearMessage();
                }).catch(() => {
                 
                });
                console.log(result.data); //return false;
             
            })
            .catch((e) => {
                let searchSuccess = {
                    message: e.data,
                    status: "0",
                };
                this.setState({ searchSuccess, loading: false });
                this.clearMessage();
            });
    };
    handleInput = (e) => {
        e.preventDefault();
        let data = this.state.clientData;
        data[e.target.name] = e.target.value;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;
    
        if (e.target.name === 'height' || e.target.name === 'weight') {
    
          data.bmi = ((data.weight /
            data.height /
            data.height) *
            10000).toFixed(1);
    
        }
    
        if (
          name === "first_name" ||
          name === "last_name"
        ) {
          this.validateMaxLength(name, value, max);
        } else if (e.target.name === "height" || e.target.name === "weight" || e.target.name === "pulse") {
          data[e.target.name] = e.target.value.replace(/[^0-9]/ig, '');
        }
        else if (e.target.name === "bp") {
          data[e.target.name] = e.target.value.replace(/[^0-9+\-\/\*\(\)]/ig, '');
        }
        else if (e.target.name === "temperature") {
          data[e.target.name] = e.target.value.replace(/[^0-9]^[+-]?\d*(?:[.,]\d*)?$/ig, '');
        }
        else {
    
          data[name] = value;
    
        }
        this.setState({ clientData: data });
      };
      validateMaxLength(name, value, max) {
        let data = this.state.clientData;
        let errors = this.state.errors;
    
    
        if (value.length > max) {
          const value1 = value.slice(0, max);
          errors[name] = "Maximum Length has exceeded.";
          data[name] = value1;
        } else {
          errors[name] = "";
    
        }
        this.setState({ clientData: data, errors });
      }
    render() {
        const { searchSuccess, errors, symptomCategory, activeIndex, hospitaltimeout,message,selectedFiles,loading } = this.state;
        let disease_array = this.props.appdata
            ? this.props.appdata[0].diseases
            : [];
        let disease = [];
        disease_array.forEach((element) => {
            disease.push({
                label: element.disease_name,
                value: element.disease_name,
            });
        });
        disease_array = disease;
        let PopupClose = () => {
            window.location.reload();
            this.setState({ showexpirePopup: true })
        };
        let PopupHospitalClose = () => {
            this.setState({ hospitalpopup:true})
           
          };
        let PopupConfirm = () => {
            window.location.href = "/showsubscription"
            // this.props.history.push({
            //   pathname: "/showsubscription",
            //   state: {}
            // });
        };


        return (
            <div className="container-fluid">
                <IdleTimeOutModal
                    showModal={this.state.showModal}
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />

                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={hospitaltimeout} />

                <ExpirePopup
                    show={!this.state.showexpirePopup}
                    onHide={PopupClose}
                    onConfirm={PopupConfirm}
                    title={"Alert"}
                    message={"Your current plan has been expired. Please activate new plan and try again"}
                />
           {/*      <Spinner loading={this.state.loading} /> */}
           {loading && <Dnaspinner />}
                {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
                <div className="row checkout-tabs">

                    <div className="col-sm-12">

                        <h4 className="font-weight-bold">Hospital Details</h4>

                        <div className="card mt-4">

                            <div className="card-body pad-btm0">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="v-pills-shipping"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-shipping-tab"
                                    >
                                        <div className="row">
                                            <div className="user-notification">
                                                <SpanField
                                                    classname={
                                                        0 === searchSuccess.status
                                                            ? "text-danger user-notification"
                                                            : "text-success user-notification"
                                                    }
                                                    title={searchSuccess.message}
                                                />
                                                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                            </div>
                                            <div className="col-sm-2">
                                                <div className="required">
                                                    <label className="f12">
                                                        <img
                                                            alt=""
                                                            src={require("../assets/images/phone.png")}
                                                            className="imgsfd"
                                                        />{" "}
                                                        Mobile number 
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"phone_number"}
                                                            controlFunc={this.handlePhoneInput}
                                                            content={this.state.searchData.phone_number}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={this.state.searchData.error}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-3 bm-9">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <table className="tablebtn">
                                                            <tbody>
                                                                <tr><td><button
                                                                            type="button"
                                                                            className="signupbtn abtn btdpml"
                                                                            onClick={this.getPatientDetail}
                                                                        >
                                                                            Search
                                                                        </button>
                                                                    </td>
                                                                    &nbsp;
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="s1"
                                                                            onClick={this.reloadPage}
                                                                        >
                                                                            Reset
                                                                        </button>
                                                                    </td>
                                                                    &nbsp;
                                                                    <td>
                                                                    {this.state.patientBasicData.length ?   (
                                                                            <button
                                                                                type="button"
                                                                                onClick={this.cancelAppointment}
                                                                                className=" s3"
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        ):""}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {this.state.patientBasicData.length === 0 ? (
                                ""
                            ) : (
                                <div >

                                    <BottomNavigation

                                        style={{ paddingLeft: 30 + "px", paddingBottom: 25 + "px" }}

                                        value={this.state.selectedPerson}
                                        showLabels
                                        onChange={this.handleChange}
                                    >
                                        {this.state.patientBasicData.map((data) => {
                                            return (
                                                <BottomNavigationAction
                                                    type="button"
                                                    className={"signupbtn2 MuiButtonBase-root MuiBottomNavigationAction-root btnview mr-20 span Mui-selected " + getPatientAppointmentColor(data.status)+" "}
                                                    label={data.first_name + " , " + data.last_name}
                                                    value={data.user_id}
                                                    title={data.first_name + " , " + data.last_name}
                                                    icon={
                                                        data.length != 0 ? (
                                                            <PersonIcon
                                                                fontSize={"small"}
                                                                htmlColor={getPatientAppointmentColor(data.status)}
                                                            />
                                                        ) : (
                                                            <PersonIcon fontSize={"small"} />
                                                        )
                                                    }

                                                />

                                            );
                                        })}


                                        {/* <BottomNavigationAction
                                            label="Add New Person"
                                            value="addNewFamilyPerson"
                                            icon={<PersonAddIcon fontSize={"small"} />}
                                        /> */}
                                    </BottomNavigation>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-sm-7 pr0">
                        <div className="card">
                        <div class="card-header"> <div class="card-title">Patient Details</div> </div>
                            <div className="card-body">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="v-pills-shipping"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-shipping-tab"
                                    >
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="frllftfull">
                                                    <div className="form-group frllft required">
                                                        <label>Registration ID</label>
                                                    </div>
                                                    <div className="form-group frlrclfgf ">
                                                        <InputField
                                                        
                                                            inputType={"text"}
                                                            name={"id"}
                                                            controlFunc={this.handleInput}
                                                            content={this.state.clientData.id}
                                                            placeholder={""}
                                                            isvisible={true}
                                                        />
                                                    </div>
                                                    <div className="form-group frlrclfgf-1 required">
                                                        <label>Relationship</label>
                                                    </div>
                                                    <div className="form-group sform-control frlrclfgf">
                                                        {this.state.clientData.relationship &&
                                                            this.state.clientData.relationship.label ===
                                                            "self" ? (
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"relationship"}
                                                                controlFunc={this.handleInput}
                                                                content={
                                                                    this.state.clientData.relationship.label
                                                                }
                                                                placeholder={""}
                                                                isvisible={true}
                                                            />
                                                        ) : (
                                                            <Select maxMenuHeight={120}
                                                                className="form-control p-0"
                                                                name="relationship"
                                                                value={this.state.clientData.relationship}
                                                                onChange={this.handlePatientChange.bind(
                                                                    this,
                                                                    "relationship"
                                                                )}
                                                                options={relationship}
                                                                isDisabled={this.state.is_check_in}
                                                            />
                                                        )}
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.relationship}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="frllftfull">
                                                    <div className="form-group frllft required">
                                                        <label>Date of Birth</label>
                                                    </div>
                                                    <div className="form-group frlrclfgf">
                                                        <DatePicker
                                                        disabled
                                                            className="form-control"
                                                            value={this.state.clientData.date_of_birth}
                                                            format="dd-MM-yyyy"
                                                            onChange={(date) => this.handleDOBChange(date)}
                                                            yearPlaceholder='YYYY'
                                                            monthPlaceholder='MM'
                                                            dayPlaceholder='DD'
                                                        />
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.date_of_birth}
                                                        />
                                                    </div>
                                                    <div className="form-group frlrclfgf-1">
                                                        <label>Age</label>
                                                    </div>
                                                    <div className="form-group frlrclfgf">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"age"}
                                                            // controlFunc={this.handleInput}
                                                            content={calculate_age(
                                                                this.state.clientData.date_of_birth
                                                            )}
                                                            
                                                            placeholder={""}
                                                            isvisible={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="frllftfull">
                                                    <div className="form-group frllft required">
                                                        <label>First Name</label>
                                                    </div>
                                                    <div className="form-group frlrclfgf">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"first_name"}
                                                            controlFunc={this.handleInput}
                                                            content={this.state.clientData.first_name}
                                                            placeholder={""}
                                                            max={50}
                                                            isvisible={true}
                                                        />
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.first_name}
                                                        />
                                                    </div>
                                                    <div className="form-group frlrclfgf-1 required">
                                                        <label>Last Name</label>
                                                    </div>
                                                    <div className="form-group frlrclfgf">
                                                        <InputField
                                                            inputType={"text"}
                                                            name={"last_name"}
                                                            controlFunc={this.handleInput}
                                                            content={this.state.clientData.last_name}
                                                            placeholder={""}
                                                            max={50}
                                                            isvisible={true}
                                                        />
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.last_name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="frllftfull">
                                                    <div className="form-group frllft required">
                                                        <label>Gender</label>
                                                    </div>
                                                    <div className="form-group sform-control frlrclfgf">
                                                        <Select maxMenuHeight={120}
                                                            className="form-control p-0"
                                                            name="gender"
                                                            value={this.state.clientData.gender}
                                                            onChange={this.handlePatientChange.bind(
                                                                this,
                                                                "gender"
                                                            )}
                                                            isDisabled={true}
                                                            options={gender}
                                                        />
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.gender}
                                                        />
                                                    </div>
                                                    <div className="form-group frlrclfgf-1">
                                                        <label>Blood Group</label>
                                                    </div>
                                                    <div className="form-group sform-control frlrclfgf">
                                                        <Select maxMenuHeight={120}
                                                            className="form-control p-0"
                                                            name="blood_group"
                                                            value={this.state.clientData.blood_group}
                                                            onChange={this.handlePatientChange.bind(
                                                                this,
                                                                "blood_group"
                                                            )}
                                                            isDisabled={true}
                                                            options={blood_group}
                                                        />
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.blood_group}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="frllftfull">
                                                    <div className="form-group frllft">
                                                        <label>Category</label>
                                                    </div>
                                                    <div className="form-group sform-control frlrclfgf">
                                                        <Select maxMenuHeight={120}
                                                            className="form-control p-0"
                                                            name="checkin_category"
                                                            value={checkin_category.filter(
                                                                (option) => option.label === "Normal"
                                                            ) && this.state.clientData.checkin_category}
                                                            onChange={this.handlePatientChange.bind(
                                                                this,
                                                                "checkin_category"
                                                            )}
                                                            options={checkin_category}
                                                        />
                                                    </div>
                                                    <div className="form-group frlrclfgf-1">
                                                        <label className="terd">Insert Queue Number </label>
                                                    </div>
                                                    <div className="form-group sform-control frlrclfgf">
                                                        {this.state.clientData.checkin_category && this.state.clientData.checkin_category.label === 'Normal' ? (<InputField
                                                            inputType={"number"}
                                                            name={"queue_number"}
                                                            controlFunc={this.handleInput}
                                                            content={this.state.clientData.queue_number}
                                                            placeholder={""}
                                                            isvisible={
                                                                this.state.disable_insert_queue ? false : true
                                                            }
                                                        />) : (<Select maxMenuHeight={120}
                                                            className="form-control p-0"
                                                            name="emergency_schedule"
                                                            value={this.state.clientData.emergency_schedule}
                                                            onChange={this.handlePatientChange.bind(
                                                                this,
                                                                "emergency_schedule"
                                                            )}
                                                            disabled={this.state.disable_insert_queue}
                                                            options={emergency_queue_data}
                                                        />)}

                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.emergency_schedule}
                                                        />

                                                    </div>
                                                </div> */}

                                                <div>
                                                    <div className="frllftfull">
                                                        <div
                                                            className={
                                                                !this.state.is_add_btn
                                                                    ? "form-group frllft required"
                                                                    : "form-group frllft "
                                                            }
                                                        >
                                                            <label>Diagnosis</label>
                                                        </div>
                                                        <div className="form-group sform-control frlrit">
                                                            <Select maxMenuHeight={120}
                                                                isMulti
                                                                className="form-control p-0 "
                                                                name="reason_for_visit"
                                                                value={this.state.clientData.reason_for_visit}
                                                                onChange={this.handlePatientChange.bind(
                                                                    this,
                                                                    "reason_for_visit"
                                                                )}
                                                                options={disease_array}
                                                            />
                                                            <SpanField
                                                                classname="text-danger"
                                                                title={errors.reason_for_visit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="frllftfull">
                                                        <div
                                                            className={
                                                                !this.state.is_add_btn
                                                                    ? "form-group frllft required"
                                                                    : "form-group frllft"
                                                            }
                                                        >
                                                            <label>Symptoms</label>
                                                        </div>
                                                        <div className="form-group sform-control frlrit">

                                                            <Select maxMenuHeight={120}
                                                                isMulti
                                                                className="form-control p-0"
                                                                disabled={true}
                                                                name="symptoms"
                                                                value={this.state.clientData.symptoms}
                                                                isDisabled={true}
                                                            />
                                                            <SpanField
                                                                classname="text-danger"
                                                                title={errors.symptoms}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="frllftfull">
                                                        <div
                                                            className={
                                                                !this.state.is_add_btn
                                                                    ? "form-group frllft mbbre required"
                                                                    : "form-group frllft mbbre"
                                                            }
                                                        >
                                                            <label className="ffgrd">Appointment Doctor</label>
                                                        </div>
                                                        <div className="form-group sform-control frlrit">
                                                            <div className="position-relative">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text inpico">
                                                                            <i className="fas fa-search"></i>
                                                                        </span>
                                                                    </div>
                                                                    {/* <Select maxMenuHeight={120}
                                                                        className="form-control p-0"
                                                                        name="selected_doc"
                                                                        value={selected_doc}
                                                                        onChange={this.handleselectDocChange}
                                                                        options={doctor_list}
                                                                    /> */}
                                                                    <InputField
                                                                        inputType={"text"}
                                                                        name={"doctor_name"}
                                                                        controlFunc={this.handleInput}
                                                                        content={this.state.clientData.doctor_name}
                                                                        placeholder={""}
                                                                        isvisible={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="frllftfull">
                                                        <div
                                                            className={
                                                                !this.state.is_add_btn
                                                                    ? "form-group frllft mbbre required"
                                                                    : "form-group frllft mbbre"
                                                            }
                                                        >
                                                            <label className="ffgrd">Appointment Timing</label>
                                                        </div>

                                                        <div className="row">
                                                            {this.state.clientData.appt_time}
                                                        </div>

                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.message}
                                                    />

                                                    <div className="frllftfull">
                                                        <div className="form-group">
                                                            <label>Reason For Visit</label>

                                                            <TextArea
                                                                rows={7}
                                                                content={this.state.clientData.other_comments}
                                                                name={"other_comments"}
                                                                controlFunc={this.handleInput}
                                                                placeholder={""}
                                                                maxLength={200}
                                                            />
                                                        </div>
                                                    </div>
                                                 
                                                   
                                                </div>
                                                {(this.state.selectedPerson!==undefined || this.state.selectedPerson!=="")?(
                                                <div>
     <Label title="Patient Old Records Images" />
      <p>* Please upload maximum of 10 images</p>
        <div className="row my-3">
          <div className="col-8">
            <label className="btn btn-default p-0">
              <input type="file" multiple accept="image/*" id="uploadfile" onChange={this.selectFiles} />
            </label>
          </div>
        </div>
     
        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}
                                                    </div>):""}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <table className="tablebtn ">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {/* {this.state.is_add_btn == true ?
                                                                            <button

                                                                                className="button s1"
                                                                                onClick={this.addPatient}
                                                                            >
                                                                                Add Patient{" "}
                                                                            </button> : <button
                                                                                disabled={
                                                                                    !this.state.is_add_btn ? "disabled" : ""
                                                                                }
                                                                                className="button s1"
                                                                                onClick={this.addPatient}
                                                                            >
                                                                                Add Patient{" "}
                                                                            </button>} */}
                                                                    </td>
                                                                    {this.state.is_add_btn ? null : (
                                                                        <td>
                                                                            <button
                                                                                onClick={
                                                                                    this.state.is_check_in || this.state.is_queued
                                                                                        ? this.handleCheckin
                                                                                        : this.handleCheckin
                                                                                }
                                                                                className="s2"
                                                                            >
                                                                                {this.state.is_check_in
                                                                                    ? "Checked-In"
                                                                                    : "Check In"}
                                                                            </button>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        {" "}
                                                                        <button
                                                                            onClick={this.handleReset}
                                                                            className="s3"
                                                                        >
                                                                            Reset
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <Modal
                                                            show={this.state.cancelModel}
                                                            onHide={this.closePopup}
                                                            centered={true}
                                                        >
                                                            <Modal.Header closeButton className="model-hptd">
                                                                <Modal.Title className="h5 model-text-color">
                                                                    Cancel Appointment
                                                                </Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div>
                                                                    <p>
                                                                        Are you sure you want to Cancel the appoinment ?
                                                                    </p>

                                                                    <div className="row reverse">
                                                                    <div className="form-group mb-0 text-right">
                                                                            <button
                                                                                className="grnbtn "
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        cancelModel: false,
                                                                                        cancelRecord: {},
                                                                                    });
                                                                                }}
                                                                            >
                                                                                No
                                                                            </button>
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="form-group mb-0 text-right">
                                                                            <button
                                                                                className="grnbtn reddg"
                                                                                onClick={this.conformCancelAppointment}
                                                                            >
                                                                                Yes
                                                                            </button>
                                                                        </div>
                                                                       
                                                                      
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-5 pl0">
                        <div className="card">
                        <div class="card-header"> <div class="card-title">Vital Details</div> </div>
                            <div className="card-body">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="v-pills-shipping"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-shipping-tab"
                                    >
                                        <div className="row">
                                            <div className="col-sm-12">
                                             
                                                <div className="rowGrid vdRow">
                                                    <div className="rowGridList">
                                                    <div className="card vitals cpag1">
                                                                <div className="card-body">
                                                                    <div className="text-left p-1 s-118">
                                                                        <h5 className="border-0 small mb-0 titleFlex justify-content-between">Height
                                                                            <img className="mr-37"
                                                                                alt=""
                                                                                src={require("../assets/images/vitaldetails1.png")}
                                                                            />                              </h5>
                                                                    </div>

                                                                    <div className="input-group input-group-sm mb-0 w-75 mt-2">
                                                                        <input type="text" className="dis-inlnflx form-control" name="height" value={this.state.clientData.height} onChange={this.handleInput} />
                                                                        <div className="input-group-append">
                                                                        {/* <span className="input-group-text">cm </span> */}
                                                                            <span className="input-group-text px-3">cm </span>

                                                                        </div></div>
                                                                </div>
                                                    </div>
                                                    </div>
                                                    <div className="rowGridList">
                                                    <div className="card vitals cpag1">
                                                            <div className="card-body">
                                                                <div className="text-left p-1 s-118">
                                                                    <h5 className="border-0 small mb-0 titleFlex justify-content-between">Weight
                                                                        <img className="mr-37"
                                                                            alt=""
                                                                            src={require("../assets/images/vitaldetails2.png")}
                                                                        />
                                                                    </h5>
                                                                </div>
                                                                <div className="input-group input-group-sm mb-0 w-75 mt-2">
                                                                    <input type="text" className="dis-inlnflx form-control" name="weight"
                                                                        value={this.state.clientData.weight} onChange={this.handleInput} />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text px-3">kg </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rowGridList">
                                                    <div className="card vitals cpag1">
                                                            <div className="card-body">
                                                                <div className="text-left p-1 s-118">
                                                                    <h5 className="border-0 small mb-0 titleFlex justify-content-between">Temp
                                                                        <img className="mr-6"
                                                                            alt=""
                                                                            src={require("../assets/images/vitaldetails3.png")}
                                                                        />
                                                                    </h5>
                                                                </div>
                                                                <div className="input-group input-group-sm mb-0 w-75 mt-2">
                                                                    <input type="text" className="dis-inlnflx form-control" name="temperature" value={this.state.clientData.temperature} onChange={this.handleInput} />
                                                                    <div className="input-group-append">
                                                                    {/* <span className="input-group-text">°F </span> */}
                                                                        <span className="input-group-text px-3">°F </span>

                                                                    </div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rowGridList">
                                                    <div className="card vitals cpag1">
                                                            <div className="card-body">
                                                                <div className="text-left p-1 s-118">
                                                                    <h5 className="border-0 small mb-0 titleFlex justify-content-between ">BMI
                                                                    {/* <h5 className="border-0 small mb-0 titleFlex ">BMI */}
                                                                        <img className="mr-53"
                                                                            alt=""
                                                                            src={require("../assets/images/bmi_icon.png")}
                                                                        />

                                                                    </h5>
                                                                </div>
                                                                <div className="input-group input-group-sm mb-0 w-75 mt-2">
                                                                    <input className="form-control" name="bmi" type="text" disabled value={this.state.clientData.bmi} onChange={this.handleInput} />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text px-3" id="basic-addon2">BMI</span>
                                                                    </div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rowGridList">
                                                    <div className="card vitals cpag1">
                                                            <div className="card-body">
                                                                <div className="text-left p-1 s-118">
                                                                    <h5 className="border-0 small mb-0 titleFlex justify-content-between">BP
                                                                        <img className="mr-53"
                                                                            alt=""
                                                                            src={require("../assets/images/vitaldetails4.png")}
                                                                        />
                                                                    </h5>
                                                                </div>
                                                                <div className="input-group input-group-sm mb-0 w-75 mt-2">
                                                                    <input type="text" className="dis-inlnflx form-control" name="bp" value={this.state.clientData.bp} onChange={this.handleInput} />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">mmHg </span>

                                                                    </div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rowGridList">
                                                    <div className="card vitals cpag1">
                                                            <div className="card-body">
                                                                <div className="text-left p-1 s-118">
                                                                    <h5 className="border-0 small mb-0 titleFlex justify-content-between">Pulse
                                                                        <img className="mr-70"
                                                                            alt=""
                                                                            src={require("../assets/images/vitaldetails5.png")}
                                                                        />
                                                                    </h5>
                                                                </div>
                                                                <div className="input-group input-group-sm mb-0 w-75 mt-2">
                                                                    <input type="text" className="dis-inlnflx form-control" name="pulse" value={this.state.clientData.pulse} onChange={this.handleInput} />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">per min</span>

                                                                    </div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                  
                                                </div>
                                            
                                            </div>
                                          
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                            <div className="card h309">
                            <div class="card-header"> <div class="card-title">Symptom List</div> </div>
                                <div className='card-body'>
                                <div
                                                                className="tab-content"
                                                                id="v-pills-tabContent"
                                                            >
                                                                <div
                                                                    className="tab-pane fade show active"
                                                                    id="v-pills-shipping"
                                                                    role="tabpanel"
                                                                    aria-labelledby="v-pills-shipping-tab"
                                                                >
                                                                    <div
                                                                        className="card"

                                                                    >
                                                                       
                                                                        <div className="input-group">
                                                                            <span className="input-group-addon "><i className="glyphicon fa fa-search icontextb"></i></span>
                                                                            <InputField
                                                                                inputType={"text"}
                                                                                name={"symptomSearch"}
                                                                                controlFunc={this.handleSymptomSearch}
                                                                                content={
                                                                                    this.state.symptomSearch
                                                                                }
                                                                                placeholder={""}
                                                                            />
                                                                        </div>



                                                                    </div>
                                                                    <div className="row symtomsscrol">

                                                                        <div className="col-sm-12 c-pagebutton">
                                                                            {this.state.selectedSymptoms}

                                                                            {this.state.symptoms.map((symptom) => {
                                                                                return (
                                                                                    <div className="custom-control custom-checkbox mb-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            value={symptom.symptom_name}
                                                                                            id={symptom.id}
                                                                                            onClick={this.selectSymptom.bind(
                                                                                                this,
                                                                                                symptom
                                                                                            )}
                                                                                            checked={this.state.selectedSymptoms.indexOf(symptom.symptom_name) > -1}

                                                                                        />
                                                                                        <label
                                                                                            className="custom-control-label"
                                                                                            htmlFor={symptom.id}
                                                                                        >
                                                                                            {symptom.symptom_name}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                </div>
                            </div>
                            </div>
                            <div className='col-sm-6'>
                            <div className="card">
                            <div class="card-header"> <div class="card-title">Symptom Category</div> </div>
                                <div className='card-body  Appointresultscrol'>
                                <div className="">
                                                            <div className="c-pagebutton c-pagebtn">
                                                                {symptomCategory.map(
                                                                    (symptom, index) => {
                                                                        return (
                                                                            <button
                                                                                disabled={this.state.is_check_in}
                                                                                className={
                                                                                    (activeIndex === index && activeIndex !== '')
                                                                                        ? "active"
                                                                                        : ""
                                                                                }
                                                                                onClick={() =>
                                                                                    this.searchAndShowSymptom(
                                                                                        symptom,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                {symptom.label}
                                                                            </button>
                                                                        );
                                                                    }
                                                                )}

                                                            </div>

                                                        </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
           )}
            </div>
        );
    }
    selectFiles(event) {
        /*  this.setState({
           progressInfos: [],
           selectedFiles: event.target.files,
         }); */
        
         let selectedFiles= event.target.files;
         this.fileObj.push(selectedFiles);
        /*  const MAX_LENGTH = 11;
         if (Array.from(event.target.files).length > MAX_LENGTH) {
           event.preventDefault();
           alert(`Cannot upload files more than ${MAX_LENGTH}`);
           return;
         } */
     //    console.log(this.fileObj[0].length);
         if(this.fileObj[0].length>=11)
         {
           alert('exceeds');
         }else{
          // let a=this.fileObj[0].length;
          
         for (let i = 0; i < this.fileObj[0].length; i++) {
           
         //  console.log(this.fileObj[0][i]);
           //  this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
          
             let file = event.target.files[i];
            // console.log(event.target.files[i]);
             let newfileName = event.target.files[i].name;
             console.log(newfileName);
         let uploadedUser_id=this.state.selectedPerson;
             // console.log(imageconfig);
             clientmedimageconfig.dirName = 'client_previous_medicalrecords/' + uploadedUser_id
             const reacts3client = new S3(clientmedimageconfig);
             console.log(reacts3client)
             reacts3client.uploadFile(file, newfileName).then(data => {
               console.log(data);
             
               console.log(data.location);
               if (data.status === 204) {
                 console.log('success');
                
                 let resultdata = {
                   'client_id': this.state.clientData.user_id,
                   'business_id':this.props.userdata.id,
                   'image_url': data.location,
                               }
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${this.props.access_token}`;
                  axios.post(addMedicalrecordspath, resultdata, CONFIG).then(() => {
                     let searchSuccess = {
                  message: "Medical records uploaded successfully",
                  status: "1",
                  };
                   this.setState({ searchSuccess ,uploadedUser_id,selectedFiles:undefined });
     
                 })
                
              } else {
                 console.log('error');
              //   this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
               }
             });
         }
         
        // this.setState({ file: this.fileArray })
       }
       }
}

export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(AppointmentCheckin));