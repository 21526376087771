import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import axios from "axios";
import InputField from "../components/UI/InputField";
import { Spinner } from "../components/UI/Spinner";
import { addSpeciality, updateSpeciality, getAllSpecialities, deleteSpeciality } from "../config/url.json";
import CreatableSelect from "react-select/creatable";
import { CONFIG, imageconfig } from "../config/data";

import S3 from "react-aws-s3";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from '../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class SpecialityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: "add",
            deletePopup: false,
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],
            errors: { speciality_name: "", image_url: "" },
            loading: false,
            specialityData: { speciality_name: "", image_url: "", id: "" },
            formSuccess: {
                message: "",
                status: "",
            },
            allspeciality: []
        };
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };

        this.columns = [

            {
                key: "id",
                text: "ID",
                className: "id",
                align: "left",
                sortable: true,
                width: 100,


            },
            {
                key: "name",
                text: "Speciality Name",
                className: "name",
                align: "left",
                sortable: true,
                width: 100,



            },

            {
                key: "image_url",
                text: "Image",
                className: "image_url imgwidth",
                sortable: true,
                align: "left",
                width: 100,
                cell: (record) => {
                    return (
                        <Fragment >
                            {record.image_url ? (<img src={record.image_url} alt={record.name} />) : <img src="https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/speciality/speciality.png" alt="default speciality" />}

                        </Fragment>
                    );
                },


            }, {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                    this.setState({ deletePopup: true, record: record })
                                }
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },




        ];
    }

    editRecord(record) {
        console.log(record);
        let specialityData = this.state.specialityData;
        specialityData.speciality_name = record.name;
        specialityData.image_url = record.image_url;
        specialityData.id = record.id;
        console.log(specialityData);
        let errors = this.state.errors;
        errors = this.clearErrors(errors);

        this.setState({
            specialityData: specialityData,
            actions: "edit",
            errors: errors,
        });
    }
    clearErrors = (errors) => {
        errors.speciality_name = "";
        errors.image_url = "";
        errors.id = "";
        return errors;
    };
    cancel = () => {
        let data = this.state.specialityData;
        data.image_url = "";
        data.speciality_name = "";
        data.id = "";

        let errors = this.state.errors;

        errors = this.clearErrors(errors);

        this.setState({ specialityData: data, actions: "add", errors: errors });
    };

    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };
    onDeleteClick(record) {
        this.setState({ deletePopup: true, record: record });
    }
    deleteRecord(record) {
        let userId = record.id;
        this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .delete(deleteSpeciality, { data: { id: userId } }, CONFIG)
            .then((res) => {
                let response = res.data;
                if (response.status === "1") {
                    let records = this.state.records;
                    const deleteIndex = records.map((item) => item.id).indexOf(record.id);
                    records.splice(deleteIndex, 1);
                    this.setState({ records });
                }
                this.setState({ deletePopup: false, record: "", loading: false });
                this.setState({ tableSuccess: res.data });
                toast.error("Speciality deleted. ", {
                    className: "toast-error"
                  });
                this.clearMessage();
            })
            .catch((e) => { });
    }
    imageSelectHandler = event => {

        let files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
            console.log('no files');
            return false;
        }
        console.log(files);
        console.log(files[0]);


        let file = event.target.files[0];
        let newfileName = event.target.files[0].name;

newfileName = newfileName.replace(/ /g, "");
        imageconfig.dirName = 'speciality/'
        console.log(imageconfig);

        const reacts3client = new S3(imageconfig);
        console.log(reacts3client)
        reacts3client.uploadFile(file, newfileName).then(data => {
            console.log(data)
            if (data.status === 204) {
                console.log('success');

                let resultData = this.state.specialityData;
                resultData.image_url = data.location;
                console.log(resultData)
                this.setState({ specialityData: resultData });
                // axios.defaults.headers.common[
                //   "Authorization"
                // ] = `Bearer ${this.props.access_token}`;
                // axios.post(updateavatar, resultdata, CONFIG).then((result) => {
                //     this.setState({ profile_img: URL.createObjectURL(file), deletePopup: false });
                // })
            } else {
                console.log('error');
                this.setState({ profile_img: URL.createObjectURL(file), deletePopup: false });
            }
        })

    }
    handleInput = (e) => {
        let data = this.state.specialityData;
        let name = e.target.name;
        let value = e.target.value;
        data[name] = value;
        this.setState({ specialityData: data });
    };
    getmaster = () => {


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .get(getAllSpecialities, "", CONFIG)
            .then((Response) => {
                let masterdrugNameList = Response.data.data;
                console.log(Response.data.data)
                let allspeciality = [];
                masterdrugNameList.forEach((element, index) => {

                    allspeciality.push({ value: element.name, label: element.name });
                });
                this.setState({ records: masterdrugNameList, allspeciality: allspeciality, loading: false });
            })
            .catch((e) => { });
    }

    componentDidMount() {

        this.getmaster()
    }

    updateSpeciality = (e) => {
        e.preventDefault();
        let { specialityData, errors } = this.state;
        let formvalid = true;
        console.log(specialityData)
        if (specialityData.speciality_name === "") {
            console.log('jo')
            formvalid = false;
            errors.speciality_name = "Please enter a name";
        } else {
            errors.speciality_name = "";
        }
        if (specialityData.image_url === "") {
            console.log('jo')
            formvalid = false;
            errors.image_url = "Please choose image";
        } else {
            errors.image_url = "";
        }

        this.setState({ errors: errors });

        console.log(specialityData); 
        // return false;
        if (formvalid) {
            let data = this.state.specialityData;
            console.log(data);
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            let url = this.state.actions === "add" ? addSpeciality : updateSpeciality;
            if (this.state.actions === "add") {
                axios.post(url, data, CONFIG).then((list) => {
                    if (0 === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { speciality_name: list.data.message } });
                    } else {

                        this.setState({ records: list.data.data, formSuccess: list.data });

                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            } else {
                axios.post(url, data, CONFIG).then((list) => {
                    console.log(list.data);
                    if ("0" === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { speciality_name: list.data.message } });
                    } else {


                        this.setState({ records: list.data.data, formSuccess: list.data });
                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            }
        }
    };

    render() {
        let PopupClose = () => this.setState({ deletePopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            specialityData,
            formSuccess,
            tableSuccess,
            errors,
            allspeciality,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
              {/*   <Spinner loading={loading} /> */}
              {loading && <Dnaspinner />}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3">Speciality List </h4>
                                    <div className="row mt-4">



                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                                show={this.state.deletePopup}
                                                                onHide={PopupClose}
                                                                onConfirm={PopupConfirm}
                                                                title={"Confirmation"}
                                                                message={"Are you sure you want to delete this record?"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 pr0">
                                    <div className="card b-12-hite">
                                    <div class="card-header"> <div class="card-title"> {this.state.actions === "add" ? "Add" : "Edit"} Speciality</div> </div>
                                        <div className="card-body">
                                            <div className="user-notification">
                                                <SpanField
                                                    classname={
                                                        0 === formSuccess.status
                                                            ? "text-danger user-notification"
                                                            : "text-success user-notification"
                                                    }
                                                    title={formSuccess.message}
                                                />
                                            </div>

                                            <div>
                                             
                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <div className="frllftfull">
                                                            <div className="form-group frllft mbbre">
                                                                <label className="ffgrd">Speciality Name</label>
                                                            </div>
                                                            <div className="form-group frlrit">
                                                                <div className="position-relative">
                                                                    <div className="input-group">
                                                                        <InputField
                                                                            inputType={"text"}
                                                                            name={"speciality_name"}
                                                                            controlFunc={this.handleInput}
                                                                            content={specialityData.speciality_name}
                                                                            placeholder={""}
                                                                        />
                                                                    </div>
                                                                    <SpanField
                                                                        classname="text-danger"
                                                                        title={errors.speciality_name}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="frllftfull">

                                                            <div className="form-group mb-4" >
                                                                <div className="custom-file text-left">

                                                                    <input type="file" onChange={this.imageSelectHandler} name="image_url" className="custom-file-input" id="image_url" />
                                                                    <label className="custom-file-label" htmlFor="file">Choose file</label>

                                                                </div><SpanField
                                                                    classname="text-danger"
                                                                    title={errors.image_url}
                                                                />
                                                            </div>
                                                            <div className="image_url">
                                                                <img src={specialityData.image_url} alt={specialityData.speciality_name} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>





                                                <div className="row">
                                                    <div className="col-sm-6"></div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-0 btn-align-right">
                                                            <button
                                                                href="patients-register"
                                                                className="signupbtn abtn btdpml btn-margin"
                                                                onClick={this.updateSpeciality}
                                                            >
                                                                {this.state.actions === "add" ? "+ Add" : "Update"}
                                                            </button>

                                                            <button
                                                                href="patients-register"
                                                                className="signupbtn abtn btdpml"
                                                                onClick={this.cancel}
                                                            >
                                                                {"Cancel"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                           
                            </section>
                                <FooterSection />
                        </div>
                    </div>
                </div>
            </div >



        );
    }
}

export default connect(
    mapStateToProps,
)(SpecialityList);