import { ADD_APP_DATA } from '../constants/appdata.json';

export const addAppdata = (appdata) =>
    ({
        type: ADD_APP_DATA,
        payload: appdata
    });

// export const clearError = index =>
//     ({
//         type: CLEAR_ERROR,
//         payload: index
//     });
