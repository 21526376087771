import React, { Component } from "react";
import { connect } from "react-redux";
import ViewBusinessProfile from "./ViewBusinessProfile";
import ViewBusinessUserProfile from "./ViewBusinessUserProfile";
import ViewClientProfile from "./ViewClientProfile";
function mapStateToProps(state) {
  return {
    searchData: state.SearchReducer.searchData,
  };
}


class ViewProfile extends Component {
  constructor(props) {
    super(props);
    
  }
  render() {
    return (
      <div>
        {this.props.location.state.user_type === "hospital" ? (
          <ViewBusinessProfile user_id={this.props.location.state.user_id} />
        ) : this.props.location.state.user_type === "employee" ? (
          <ViewBusinessUserProfile user_id={this.props.location.state.user_id} />
        ) : (
          <ViewClientProfile user_id={this.props.location.state.user_id} />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ViewProfile);
