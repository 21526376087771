import React from "react";

const Button = (props) => {
  // console.log(props.style);
  if (props.btntype === "submit") {
    return (
      <button
      id={props.id}
        style={props.style}
        className={props.clsname}
        type={props.btntype}
        disabled={props.disabled}
      >
        {props.loading && (
          <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px", marginLeft: "5px" }}
          />
        )}
        {props.title}

        {/* {props.loading && <span>Loading Data from Server</span>} */}
        {/* {!props.loading && <span>Fetch Data from Server</span>} */}
      </button>
    );
  } else {
    return (
      <button
        id={props.id}
        style={props.style}
        className={props.clsname}
        type={props.btntype}
        onClick={props.action}
        disabled={props.disabled}
      >
        {props.loading && (
          <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px", marginLeft: "5px" }}
          />
        )}
        {props.title}
      </button>
    );
  }
};

export default Button;
