import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import SideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
function mapStateToProps(state) {
    return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    };
}

class ClientCheckin extends Component {
    render() {
        return (
            <div id="layout-wrapper">

       
        {/* <SideBarNew /> */}

        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <section className="main-body hospital-profilegap">
              <div className="container-fluid">
                <div className="checkout-tabs">
                <div class="row">
                    <div class="col-sm-2 pr0">
                    <SideBar />
                    </div>

                    <div className="col-sm-10">
                    <div className="row">

                    <div class="col-sm-12">
                     <div class="hmeclr bgligtrn">
                        <h5 class="fnsz hptd">Hospital Details</h5>
                     </div>
                     <div class="card">
                        <div class="card-body" style={{paddingBottom: 0}}>
                           <div class="tab-content" id="v-pills-tabContent">
                              <div class="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                 <div class="row">
                                    <div class="col-sm-3">
                                    </div>
                                    <div class="col-sm-2">
                                       <div>
                                          <label><img alt="" src={require("../assets/images/phone.png")} class="imgsfd" /> Mobile number</label>
                                       </div>
                                    </div>
                                    <div class="col-sm-3">
                                       <div class="form-group">
                                          <div class="input-group">
                                             <input id="email" type="text" class="form-control" name="text" placeholder="" />
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-sm-2">
                                       <div class="form-group">
                                          <button href="patients-register" class="signupbtn abtn" onclick="window.location.href='#'" style={{marginTop: 0,padding:"9px"}}>Submit</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>



                  <div class="col-sm-6 pr0">
                     <div class="card">
                        <div class="card-body">
                           <div class="tab-content" id="v-pills-tabContent">
                              <div class="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                 <div class="row">
                                    <div class="col-sm-12">
                                    <h5 class="regxs regxsd">Patient details</h5>
                                    
                                    <hr />
                                  
                                       <div class="frllftfull">
                                          <div class="form-group frllft">
                                             <label>Registration ID</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                             <input type="text" class="form-control" placeholder="" />
                                          </div>
                                          <div class="form-group frlrclfgf-1">
                                             <label>State</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                            <input type="date" class="form-control" placeholder="" />
                                          </div>
                                       </div>
									   <div class="frllftfull">
                                          <div class="form-group frllft">
                                             <label>First name</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                             <input type="text" class="form-control" placeholder="" />
                                          </div>
                                          <div class="form-group frlrclfgf-1">
                                             <label>Last name</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                            <input type="text" class="form-control" placeholder="" />
                                          </div>
                                       </div>
                                       <div class="frllftfull">
                                          <div class="form-group frllft">
                                             <label>Sex</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                             <select class="form-control">
                                                <option>Male</option>
                                                <option>Male</option>
                                                <option>Male</option>
                                             </select>
                                          </div>
                                          <div class="form-group frlrclfgf-1">
                                             <label>Blood group</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                             <select class="form-control">
                                                <option>O+ve</option>
                                                <option>O+ve</option>
                                                <option>O+ve</option>
                                             </select>
                                          </div>
                                       </div>
									    <div class="frllftfull">
                                          <div class="form-group frllft">
                                             <label>Category</label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                             <select class="form-control">
                                                <option>Emergency </option>
                                                <option>Emergency </option>
                                                <option>Emergency </option>
                                             </select>
                                          </div>
                                          <div class="form-group frlrclfgf-1">
                                             <label class="terd">Insert queue number </label>
                                          </div>
                                          <div class="form-group frlrclfgf">
                                              <input type="number" class="form-control" placeholder="" />
                                          </div>
                                       </div>
									   <div class="frllftfull">
                                          <div class="form-group frllft">
                                             <label>Reason for visit</label>
                                          </div>
                                          <div class="form-group frlrit">
                                             <input type="text" class="form-control" placeholder="" />
											 
											 <div class="width-100 mt-10">
											 <div class="square-switch">
                                                      <input type="checkbox" id="square-switch5" switch="none" />
                                                      <label for="square-switch5" data-on-label="Injury" data-off-label="Injury"></label>
                                                   </div>
                                                   <div class="square-switch">
                                                      <input type="checkbox" id="square-switch6" switch="none" />
                                                      <label for="square-switch6" data-on-label="Fever" data-off-label="Fever"></label>
                                                   </div>
                                                   <div class="square-switch">
                                                      <input type="checkbox" id="square-switch7" switch="none" />
                                                      <label for="square-switch7" data-on-label="Cold" data-off-label="Cold"></label>
                                                   </div>
                                                   <div class="square-switch">
                                                      <input type="checkbox" id="square-switch7" switch="none" />
                                                      <label for="square-switch7" data-on-label="Asthma" data-off-label="Asthma"></label>
                                                   </div>
												    <div class="square-switch">
                                                      <input type="checkbox" id="square-switch7" switch="none" />
                                                      <label for="square-switch7" data-on-label="Allergy" data-off-label="Allergy"></label>
                                                   </div>
												 </div>  
                                          </div>
										  
										
                                       </div>
									   <div class="frllftfull">
                                          <div class="form-group">
                                             <label>Other comments</label>
                                             <textarea class="form-control" rows="7" id="comment" maxLength={100}></textarea>
                                          </div>
                                       </div>
									   
									   <div class="row">
                                        <div class="col-sm-12">
                                            <table class="tablebtn">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <button class="s1">Add Patient </button>
                                                    </td>
                                                    <td>
                                                        <button class="s2">Check In</button>
                                                    </td>
                                                    <td> <button class="s3">Reset</button></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                   
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>




                  <div class="col-sm-6 pl0">
                     <div class="card">
                        <div class="card-body">
                           <div class="tab-content" id="v-pills-tabContent">
                              <div class="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                 <div class="row">
                                    <div class="col-sm-12">
									<h5 class="regxs regxsd">Vital details</h5>
										
									<hr />
									
									<div class="row">
									<div class="col-sm-4 reviewsdesign2">
									<img  alt="" src="assets/images/vitaldetails1.png" />
									<button>Height</button>
									</div>
									<div class="col-sm-4 reviewsdesign2">
									<img alt="" src="assets/images/vitaldetails2.png" />
									<button>Weight</button>
									</div>
									<div class="col-sm-4 reviewsdesign2">
									<img alt="" src="assets/images/vitaldetails3.png" />
									<button>BMI</button>
									</div>
									</div>
									
									<div class="row">
									<div class="col-sm-4 reviewsdesign2">
									<img alt="" src="assets/images/vitaldetails4.png" />
									<button>Temperature* </button>
									</div>
									<div class="col-sm-4 reviewsdesign2">
									<img alt="" src="assets/images/vitaldetails5.png" />
									<button>BP </button>
									</div>
									<div class="col-sm-4 reviewsdesign2">
									<img alt="" src="assets/images/vitaldetails6.png" />
									<button>Pulse*</button>
									</div>
									</div>
									
									
                                 
									   
									  
                                   
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>



                     <div class="card">
                           <div class="card-body ">
                              <div class="tab-content" id="v-pills-tabContent">
                                 <div class="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                    <div class="row">
                                       <div class="col-sm-12">
                                          <h5 class="regxs regxsd">Additional Check-in</h5>
                                          <hr class="rowline" />
                                          <div class="table-responsive">
                                             <table id="datatable" class="table table-bordered dt-responsive nowrap" style={{borderCollapse:"collapse",borderSpacing: 0, width: "100%"}}>
                                                <thead>
                                                   <tr>
                                                      <th>Patient name</th>
                                                      <th>Date of birth</th>
                                                      <th>Blood Group</th>
                                                      <th>Gender</th>
                                                      <th>Relationship</th>
                                                      <th>Check in</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   <tr>
                                                      <td>Kumar </td>
                                                      <td>12/12/2000</td>
                                                      <td>O+</td>
                                                      <td>Male</td>
                                                      <td>Son</td>
                                                      <td><button class="quebtn">Select</button></td>
                                                   </tr>
                                                  <tr>
                                                      <td>Mira</td>
                                                      <td>10/02/1972</td>
                                                      <td>-</td>
                                                      <td>Female</td>
                                                      <td>Spouse</td>
                                                      <td><button class="quebtn">Select</button></td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                 </div>


                  </div>    
                    </div>

                </div>
            </div>
             </div>
            
            </section>
            <FooterSection />
            
        </div>
        </div>
        
        </div>
        
        );
    }
}

export default connect(
    mapStateToProps,
)(ClientCheckin);