import React, { Component,Fragment } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import Heading4 from "../../components/UI/Heading4";
import InputField from "../UI/InputField";
import TextArea from "../UI/TextArea";
import CheckboxOrRadioGroup from "../UI/CheckboxOrRadioGroup";
// import Button from '../UI/Button'
import axios from "axios";
import { UpdateBusiness, updateavatar } from "../../config/url.json";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Label from "../UI/Label";
import Validation from "../../actions/Validation";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import SpanField from "../UI/SpanField";
import Select from "react-select";
import { AUTH_CONFIG, CONFIG, imageconfig } from "../../config/data";
import { getBusinessById } from "../../config/url.json";
import ErrorAlert from "../UI/ErrorAlert";
import S3 from "react-aws-s3";


// const token = localStorage.getItem("token");
function mapStateToProps(state) {
  console.log(state.appdata);

  return {
    appdata: state.AppData,
  };
}
const google = window.google;
const scheduleType = [{ label: "Token", value: "Queue" }, { label: "Appointment", value: "Appointment" }];
const working_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class BusinessSignup1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSchedule: [],
      file: "",
      imagePreviewUrl: "",
      businessData: {
        user_id: "",
        business_name: "",
        email: "",
        // password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        user_type: "hospital",
        phone_number: "",
        phone_code: "",
        fax_number: "",
        website: "",
        year_of_build: "",
        capacity: "",
        schedule_type: "",
        selected_category: "",
        payment_type:"",
        working_hours: {
          morning_opens_at: '',
          morning_close_at: '',
          evening_opens_at: '',
          evening_close_at: '',
          hours_24: false,
        },

        working_days: [],
        working_all_days: false,
        speciality: [],
        facility: [],
        profile_summary: "",
        google_loc_id:"",
      },
      errors: {
        business_name: "",
        email: "",
        // password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        fax_number: "",
        website: "",
        year_of_build: "",
        capacity: "",
        schedule_type: "",
        working_hours_morning: "",
        working_hours_evening: "",
        working_days: "",
        speciality: "",
        facility: "",
        profile_summary: "",
        phone_number:"",
      },
      error: "",
    };
    // this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.autocomplete = null;
    this.handleWorkhoursChange = this.handleWorkhoursChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    let { businessData,errors } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    // console.log(place);
    // console.log(place.name);
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    businessData.latitude = lat.toString();
    businessData.longitude = lng.toString();
    businessData.google_loc_id = place.url;
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
          businessData.state = val;
        if (addressType === "locality")
          businessData.city = val;
        if (addressType === "country") businessData.country = val;
        // if (addressType === "street_number") {

        businessData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1") businessData.address2 = val;
        if (addressType === "postal_code") businessData.zip_code = val;
      }
    }
      errors.state="";
      errors.city="";
      errors.zip_code="";
    this.setState({
      businessData,errors
    });
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    axios
      .post(
        getBusinessById,
        { business_id: this.props.userdata.id },
        AUTH_CONFIG
      )
      .then((response) => {
        console.log("userData");
        console.log(response.data);
        if (response.data.status === "1") {
          //   this.setState({});
          //   console.log(response.data.data);
          let data = response.data.data[0];
          console.log(data);
          let speciality = [];
          let specialities_array = this.props.appdata
            ? this.props.appdata[0].specialities
            : [];
          if (data.speciality) {
            let arr1=data.speciality.split(',');
           arr1.forEach((e1,i)=>specialities_array.forEach((element, index) => {
              if (e1==element.id) {
                speciality.push({
                  value: element.id,
                  label: element.name,
                });
              }
            }
           ))
          }
          data.speciality = speciality;

          let facility = [];
          let facilities_array = this.props.appdata
            ? this.props.appdata[0].facilities
            : [];
          if (data.facility) {
            facilities_array.forEach((element, index) => {
              if (data.facility.indexOf(element.id) > -1) {
                facility.push({
                  value: element.id,
                  label: element.facility_name,
                });
              }
            });
          }


          data.facility = facility;

          let working_hours = {
            morning_opens_at: '',
            morning_close_at: '',
            evening_opens_at: '',
            evening_close_at: '',
            hours_24: false,
          };
          if ("null" === data.working_hours || null == data.working_hours || "" === data.working_hours) {
            data.working_hours = working_hours;
          } else {
            data.working_hours = data.working_hours;
          }

          data.schedule_type = data.schedule_type ? data.schedule_type : "";
          // data.working_all_days = data.working_all_days;
          // if (data.working_days !== null) {
          //   data.working_all_days = false;
          // } else if (data.working_days.length === 7) {
          //   data.working_all_days = true;
          // }

          /* Working Days section */
          if (null == data.working_days) {
            data.working_days = [];
          } else {
            data.working_days = data.working_days;
            if (7 === data.working_days.length) {
              data.working_all_days = true;
            } else {
              data.working_all_days = false;
            }
          }

          if (null !== data.category) {
            this.category_array.forEach((element, index) => {
              if (data.category.indexOf(element.value) > -1) {
                data.selected_category = element;
              }
            });
          }
          console.log(data);
          data.phone_number = data.phone_number.includes("+91") ? data.phone_number : data.phone_code.concat(data.phone_number);

          // data.phone_number = data.phone_number ? data.phone_code.concat(data.phone_number) : "";
          console.log(data);

          // var errors = Validation(data, this.state.errors);
          // data.phone_number = data.phone_code.concat(data.phone_number);
          this.setState({ businessData: data });
        } else {
          this.setState({ error: response.data.message });
        }
      })
      .catch((e) => {
        console.log("business user getbyid failed");
      });
  }
  handlePaymentSelect = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }
  handleInput = (e) => {
    let data = this.state.businessData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
let errors = this.state.errors
    if (
      name === "capacity" ||
      name === "year_of_build" ||
      name === "business_name" ||
      name === "address1" ||
      name === "address2" ||
      name === "city" ||
      name === "zip_code" ||
      name === "fax_number" ||
      name === "email" ||
      name === "website"||
      name === "phone_number"
      
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name]=""
    }
    this.setState({ businessData: data,errors });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.businessData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      // const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ businessData: data, errors: errors });
  }
  /* for country detail change */
  selectCountry(val) {
    let data = this.state.businessData;
    data.country = val;
    let errors = this.state.errors
    errors.country = ""
    this.setState({ businessData: data,errors });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.businessData;
    data.state = val;
    let errors = this.state.errors
    errors.state=""
    this.setState({ businessData: data,errors });
  }
  handlePhoneInput = (event) => {
    let businessData = this.state.businessData;
    businessData.phone_number = event;
    let errors = this.state.errors
    errors.phone_number = ""
    this.setState({ businessData,errors });
  };
  handleSechduleType = (e) => {
    let data = this.state.businessData;
    data.schedule_type = [e.target.value];
    let errors = this.state.errors
    errors.schedule_type= ""
    this.setState({ businessData: data,errors });
  };
  handleWorkingDays = (e) => {
    let data = this.state.businessData;
    // let working_days_data = data.working_days;
    let working_all_days_data = data.working_all_days;
let errors = this.state.errors
    if ("allDaysId" === e.target.id) {
      working_all_days_data = !working_all_days_data;

      data.working_all_days = working_all_days_data;

      data.working_all_days
        ? (data.working_days = working_days)
        : (data.working_days = []);
    } else {
      const newSelection = e.target.value;
      let newSelectionArray;

      if (data.working_days.indexOf(newSelection) > -1) {
        newSelectionArray = data.working_days.filter((s) => s !== newSelection);
        console.log(newSelectionArray);
      } else {
        newSelectionArray = [...data.working_days, newSelection];
        console.log(newSelectionArray);
      }

      data.working_days = newSelectionArray;
      let wd = [];
      data.working_days.forEach((element, index) => {
        if (working_days.indexOf(element) > -1) {
          wd.push(element);
        }
      });

      data.working_days = wd;
    }
if(data.working_days.length>0)
errors.working_days = ""

    data.working_days.length === 7
      ? (data.working_all_days = true)
      : (data.working_all_days = false);

    this.setState({ businessData: data,errors });
  };
  /* for work detail change */
  handleWorkhoursChange(field, value) {
    let data = this.state.businessData;
    let working_hours_data = data.working_hours;
    let errors = this.state.errors;
    console.log(working_hours_data);
    if(working_hours_data == ""){
      working_hours_data =
      {
        morning_opens_at: '',
        morning_close_at: '',
        evening_opens_at: '',
        evening_close_at: '',
        hours_24: false,
      };
    }
    if ("24Hours" === field) {
      working_hours_data.hours_24 = !working_hours_data.hours_24;
      working_hours_data.morning_opens_at = '00:00'; errors.morning_opens_at="";
      working_hours_data.morning_close_at = '11:59';errors.morning_close_at="";
      working_hours_data.evening_opens_at = '12:00';errors.evening_opens_at="";
      working_hours_data.evening_close_at = '23:59';errors.evening_close_at="";
    } else {
      working_hours_data.hours_24 = false;
      working_hours_data[`${field}`] = value.target.value;
      errors[`${field}`] ="";
    }
    console.log(working_hours_data);
    data.working_hours = working_hours_data;

errors.working_hours = ""
    this.setState({ businessData: data,errors });
  }

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.businessData;
    data[e.name] = selectedOptions;
let errors = this.state.errors
errors[e.name] = ""
    this.setState({ businessData: data,errors });
  };
  handleFormSubmit = (e) => {
    e.preventDefault();

    let { businessData, errors } = this.state;
    console.log(businessData);
    businessData.user_type = "hospitalsignup";

    errors = Validation(businessData, errors);

    this.setState({ errors: errors });
    if (errors.formIsValid) {
      console.log("form is valid");

      let speciality = [];
      let data = businessData;

      if (data.speciality) {
        data.speciality.forEach((element) => speciality.push(element.value));
      }

      data.speciality = speciality.toString();

      data.category = data.selected_category
        ? data.selected_category.value
        : "";

      data.category = data.category.toString();

      data.schedule_type = data.schedule_type ? data.schedule_type : "";

      data.schedule_type = data.schedule_type.toString();

      let facility = [];

      if (data.facility) {
        data.facility.forEach((element) => facility.push(element.value));
      }

      data.facility = facility.toString();
      data.payment_type=this.state.payment_mode;
    
      try {

        // data.phone_number = data.phone_number.replace(data.phone_code, '');
        data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
        data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;

        const result = axios.put(UpdateBusiness, data, AUTH_CONFIG);
        console.log(result);
        const p = Promise.resolve(result);

        let resultdata = p.then((value) => {
          // console.log(value.data.status);
          // console.log(value.data.data);
          if (value.data.status === "1") {
            // this.props.history.push("/dashboard");
            window.location.replace("/dashboard");
          }
          if (value.data.status === "0") {
            data.phone_number = data.phone_code.concat(data.phone_number);

            this.setState({ error: value.data.message, businessData: data,errors:errors });
          }
        });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
    }
  };
  imageUpload = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    let newfileName = event.target.files[0].name;

    // console.log(imageconfig);
    imageconfig.dirName = 'business/' + this.props.userdata.id
    console.log(imageconfig);

    const reacts3client = new S3(imageconfig);
    console.log(reacts3client)
    reacts3client.uploadFile(file, newfileName).then(data => {
      console.log(data)
      if (data.status === 204) {
        console.log('success');
        let resultdata = {
          'avatar': data.location,
          'user_id': this.props.userdata.id,
          'user_type': "business"
        }
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        axios.post(updateavatar, resultdata, CONFIG).then((result) => {
          this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
        })
      } else {
        console.log('error');
        this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
      }
    });

  };
  render() {
    const { businessData, errors, error } = this.state;
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let facilities_array = this.props.appdata
      ? this.props.appdata[0].facilities
      : [];
    let facilities = [];
    facilities_array.forEach((element, index) => {
      facilities.push({ value: element.id, label: element.facility_name });
    });
    facilities_array = facilities;

    let category_array = this.props.appdata.length
      ? this.props.appdata[0].category
      : [];
    let category = [];

    category_array.forEach((element, index) => {
      category.push({ value: element.id, label: element.category_name });
    });
    this.category_array = category;
    return (
      <form className="custom-validation" onSubmit={this.handleFormSubmit}>
        {error ? <ErrorAlert message={error} /> : null}

        <div className="col-lg-12">
          <div className="card  bghome">
            <div className="card-body  pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <Heading4 classname="card-title" title="BASIC DETAILS" />
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Hospital name"}
                          readOnly={true}
                          name={"business_name"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.business_name
                              ? businessData.business_name
                              : ""
                          }
                          placeholder={""}
                          max={35}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.business_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="row">Profile picture</label>
                        <input type="file" accept="image/*" name="uploadedimage" onChange={this.imageUpload} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          id="address1"
                          inputType={"text"}
                          title={"Address 1"}
                          name={"address1"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.address1 ? businessData.address1 : ""
                          }
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address1}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          // isMandatory={true}
                          inputType={"text"}
                          title={"Address 2"}
                          name={"address2"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.address2 ? businessData.address2 : ""
                          }
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address2}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"City"}
                          name={"city"}
                          controlFunc={this.handleInput}
                          content={businessData.city ? businessData.city : ""}
                          placeholder={""}
                          max={20}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.city}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Country" isMandatory={true} />

                      <CountryDropdown
                        placeholder="Country"
                        name="country"
                        value={businessData.country || ""}
                        className="form-control"
                        onChange={(val) => this.selectCountry(val)}
                      />
                      <SpanField
                        classname="text-danger"
                        title={errors.country}
                      />
                    </div>
                    <div className="col-sm-3">
                      <Label title="State" isMandatory={true} />

                      <RegionDropdown
                        name="state"
                        defaultOptionLabel="Select State"
                        country={businessData.country}
                        value={businessData.state || ""}
                        className="form-control"
                        onChange={(val) => this.selectState(val)}
                        placeholder="State"
                      />
                      <SpanField classname="text-danger" title={errors.state} />
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Zip/Pincode"}
                          name={"zip_code"}
                          controlFunc={this.handleInput}
                          content={businessData.zip_code ? businessData.zip_code : ""}
                          placeholder={""}
                          max={10}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.zip_code}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <Label title="Phone number" isMandatory={true} />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-control">
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              name="phone_number"
                              disabled="true"
                              placeholder="Enter phone number"
                              value={businessData.phone_number}
                              onChange={this.handlePhoneInput}
                              error={
                                businessData.phone_number
                                  ? isValidPhoneNumber(
                                    businessData.phone_number
                                  )
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                              max={10}
                            />
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.phone_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Fax"}
                          name={"fax_number"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.fax_number
                              ? businessData.fax_number
                              : ""
                          }
                          placeholder={""}
                          max={15}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.fax_number}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group ">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Email"}
                          name={"email"}
                          readOnly= {true}
                          controlFunc={this.handleInput}
                          content={businessData.email ? businessData.email : ""}
                          placeholder={""}
                          max={35}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.email}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Website"}
                          name={"website"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.website ? businessData.website : ""
                          }
                          placeholder={""}
                          max={35}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.website}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Year of built"}
                          name={"year_of_build"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.year_of_build
                              ? businessData.year_of_build
                              : ""
                          }
                          placeholder={""}
                          max={4}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.year_of_build}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          inputType={"text"}
                          title={"Capacity"}
                          name={"capacity"}
                          controlFunc={this.handleInput}
                          content={
                            businessData.capacity ? businessData.capacity : ""
                          }
                          placeholder={""}
                          max={6}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.capacity}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <Label title="Business category" isMandatory={true} />

                      <div className="form-group">
                        <div className="input-group sform-control">
                          <span className="input-group-addon">
                            <i className="glyphicon fa fa-search icontextb"></i>
                          </span>
                          {/* <input id="email" type="text" className="form-control" name="text" placeholder="Search" /> */}
                          <Select
                            maxMenuHeight={120}
                            className="form-control p-0"
                            name="selected_category"
                            value={businessData.selected_category}
                            onChange={this.handleselectChange}
                            options={this.category_array}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6"></div>
                  </div>
                  {/* <!-- end row --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card  bghome">
            <div className="card-body pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <Heading4 classname="card-title" title="OTHER DETAILS" />
                  </div>
                  <div className="formlayout ml-mr0">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5 className="txtss mt-0">Working hours *</h5>
                            <hr className="mrgtp" />
                          </div>
                          <div className="col-sm-2">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Morning : </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>From</label>

                                  {/* <TimePicker
                                    disabled={
                                      businessData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_opens_at"
                                    )}
                                    // step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessData.working_hours
                                        ? businessData.working_hours
                                          .morning_opens_at
                                        : ""
                                    }
                                    placeholder="From"
                                  /> */}
                                  <input
                                    disabled={
                                      businessData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="morning_opens_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_opens_at"
                                    )}
                                    value={businessData.working_hours
                                      ? businessData
                                        .working_hours
                                        .morning_opens_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.morning_opens_at}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>To</label>

                                  {/* <TimePicker
                                    disabled={
                                      businessData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_close_at"
                                    )}
                                    //step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessData.working_hours
                                        ? businessData.working_hours
                                          .morning_close_at
                                        : ""
                                    }
                                    placeholder="To"
                                  /> */}
                                  <input
                                    disabled={
                                      businessData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="morning_close_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "morning_close_at"
                                    )}
                                    value={businessData.working_hours
                                      ? businessData
                                        .working_hours
                                        .morning_close_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.morning_close_at}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3 text-center hal">
                            <label className="dpns">&nbsp;</label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch33"
                                switch="none"
                                onChange={this.handleWorkhoursChange.bind(
                                  this,
                                  "24Hours"
                                )}
                                checked={businessData.working_hours.hours_24}
                              />
                              <label
                                htmlFor="square-switch33"
                                data-on-label="24 hours"
                                data-off-label="24 hours"
                                className="dcte22 wid-115"
                              ></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-2">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Evening : </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>From</label>

                                  {/* <TimePicker
                                    disabled={
                                      businessData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_opens_at"
                                    )}
                                    // step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessData.working_hours
                                        ? businessData.working_hours
                                          .evening_opens_at
                                        : ""
                                    }
                                    placeholder="From"
                                  /> */}
                                  <input
                                    disabled={
                                      businessData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="evening_opens_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_opens_at"
                                    )}
                                    value={businessData.working_hours
                                      ? businessData
                                        .working_hours
                                        .evening_opens_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.evening_opens_at}
                                  />
                                  {/* <input type="time" className="form-control" placeholder="" /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>To</label>

                                  {/* <TimePicker
                                    disabled={
                                      businessData.working_hours.hours_24
                                    }
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_close_at"
                                    )}
                                    // step={60}
                                    start="0"
                                    end="24"
                                    value={
                                      businessData.working_hours
                                        ? businessData.working_hours
                                          .evening_close_at
                                        : ""
                                    }
                                    placeholder="To"
                                  /> */}
                                  <input
                                    disabled={
                                      businessData.working_hours
                                        .hours_24
                                    }
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="evening_close_at"
                                    onChange={this.handleWorkhoursChange.bind(
                                      this,
                                      "evening_close_at"
                                    )}
                                    value={businessData.working_hours
                                      ? businessData
                                        .working_hours
                                        .evening_close_at
                                      : ""}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.evening_close_at}
                                  />
                                  {/* <input type="time" className="form-control" placeholder="" /> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3 text-center hal"></div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5 className="txtss">Working days*</h5>
                            <hr />
                            <div className="mt-4 mt-lg-0">
                              <CheckboxOrRadioGroup
                                // title={'Which kinds of pets would you like to adopt?'}
                                setName={"working_days"}
                                type={"checkbox"}
                                controlFunc={this.handleWorkingDays}
                                options={working_days}
                                selectedOptions={businessData.working_days}
                              />

                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="allDaysId"
                                  switch="none"
                                  onChange={this.handleWorkingDays}
                                  checked={businessData.working_all_days}
                                />
                                <label
                                  htmlFor="allDaysId"
                                  data-on-label="All days"
                                  data-off-label="All days"
                                  className="dcte22 wid-115"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.working_days}
                          />
                          <div className="col-sm-12">
                            <Label title="Schedule type" isMandatory={true} />

                            {/* <CheckboxOrRadioGroup
                              // title={'Which kinds of pets would you like to adopt?'}
                              setName={"schedule_type"}
                              type={"radio"}
                              controlFunc={this.handleSechduleType}
                              options={scuedueType}
                              selectedOptions={businessData.schedule_type}
                            /> */}

                            <div className="form-group">
                              {scheduleType.map((option) => {
                                return (
                                  <div
                                    key={option.label}
                                    className="custom-control-1 custom-radio-1 mb-2 mylft "
                                  >
                                    <input
                                      className="form-radio1"
                                      name="schedule_type"
                                      onChange={this.handleSechduleType}
                                      value={option.value}
                                      checked={businessData.schedule_type.indexOf(option.value) > -1}
                                      type="radio"
                                      id={option.label}
                                    />
                                    <label className="custom-control-label-1" htmlFor={option.label}>
                                      {option.label}
                                    </label>
                                  </div>)
                              })}
                            </div>
                          </div>
                          <SpanField
                            classname="text-danger"
                            title={errors.schedule_type}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="formlayout ml-mr0">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="txtss">Speciality* </h5>
                        <hr />
                        <div className="row mb-10">
                          <div className="col-sm-12">
                            <div className="input-group">
                              {/* <input id="email" type="text" className="form-control" name="text" placeholder="Search" /> */}
                              <Select
                                maxMenuHeight={120}
                                className="form-control p-0"
                                name="speciality"
                                isMulti
                                value={businessData.speciality}
                                onChange={this.handleselectChange}
                                options={specialities_array}
                              />
                              <SpanField
                                classname="text-danger width-100"
                                title={errors.speciality}
                              />
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <div className="col-sm-6">
                        <h5 className="txtss">Facilities</h5>
                        <hr />
                        <div className="row mb-10">
                          <div className="col-sm-12">
                            <div className="input-group">
                              {/* <input id="email" type="text" className="form-control" name="text" placeholder="Search" /> */}
                              <Select
                                maxMenuHeight={120}
                                className="form-control p-0 border-0"
                                name="facility"
                                isMulti
                                value={businessData.facility}
                                onChange={this.handleselectChange}
                                options={facilities_array}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* {businessData.facility
                            ? businessData.facility.map((facility, index) => {
                                return (
                                  <div key={index} className="square-switch">
                                    <input
                                      type="checkbox"
                                      id={facility.label}
                                      switch="none"
                                    />
                                    <label
                                      htmlFor={facility.label}
                                      data-on-label={facility.label}
                                      data-off-label={facility.label}
                                    ></label>
                                  </div>
                                );
                              })
                            : ""} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>&nbsp;&nbsp;&nbsp;</div>
                  <div>&nbsp;&nbsp;&nbsp;</div>
                  <div className="frllftfull">
                        <div className="form-group frllft">
                          <label>Payment Type</label>
                        </div>




                        <Fragment className="form-group frlrit">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-check fltx">

                                <input
                                  checked={this.state.payment_mode ==="Online"}
                                  className="form-check-input"
                                  type="radio"
                                  id="Online"
                                  value="Online"
                                  name="payment_mode"

                                  onChange={this.handlePaymentSelect}


                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay online"
                                >
                                  Pay online
                                </label>

                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="Direct"
                                  name="payment_mode"
checked={this.state.payment_mode ==="Direct"}
                                  value="Direct"
                                  onChange={this.handlePaymentSelect}

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay at Clinic"
                                >
                                  Pay at Clinic
                                </label>
                              </div>
                            </div>
                          </div>
                        </Fragment>

                      </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <Label title="Profile summary " />

                        <TextArea
                          // title={'Profile summary'}
                          rows={5}
                          resize={false}
                          content={
                            businessData.profile_summary
                              ? businessData.profile_summary
                              : ""
                          }
                          name={"profile_summary"}
                          controlFunc={this.handleInput}
                          placeholder={""}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <!-- end row --> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 btncent">
            <div className="form-group signupformbtn">
              <a href="hospital-profile">
                <button className="signupbtn">Sign Up</button>
              </a>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps)(BusinessSignup1));
