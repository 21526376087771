import React, { Fragment } from "react";
import PropTypes from "prop-types";

const CheckboxField = (props) => (
  <div>
    {props.options.map((option) => {
      return (
        <Fragment key={option.key}>
          <div
            className="custom-control custom-checkbox mb-2"
            data-toggle="tooltip"
            title=""
          >
            <input
              className="custom-control-input"
              //name={props.name}
              onChange={props.controlFunc}
              value={option.key}
              checked={
                props.selectedOptions
                  ? props.selectedOptions.indexOf(option.key) > -1
                  : 0
              }
              name={props.name ? props.name : ""}
              type={props.type}
              id={option.key + props.page}
            />
            <label
              className="custom-control-label"
              htmlFor={option.key + props.page}
            >
              {option.value}
            </label>
          </div>
        </Fragment>
      );
    })}
  </div>
);

CheckboxField.propTypes = {
  type: PropTypes.oneOf(["checkbox"]).isRequired,
  setName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  controlFunc: PropTypes.func.isRequired,
};

export default CheckboxField;
