import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

export class ExpirePopup extends Component {


    render() {
        return (<Modal
            {...this.props}

            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton className="model-hptd">
                <Modal.Title className="h5 model-text-color">
                    {this.props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{this.props.message}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.onConfirm} className="signupbtn abtn btdpml">Activate</Button>
                <Button onClick={this.props.onHide} className="signupbtn abtn btdpml">Cancel</Button>
            </Modal.Footer>
        </Modal>)
    }
}

export default ExpirePopup;