import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';

import axios from "axios";
import {
    getSettingsData,supportApi
} from "../config/url.json";
import { support_categories, issue_description } from "../config/data";
import Select from "react-select";
import Label from "../components/UI/Label";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import InputField from "../components/UI/InputField";
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import support from "../assets/images/support.png";
import { CONFIG } from "../config/data";
function mapStateToProps(state) {
    return {

        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}


class SupportNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsData: {},
            loading: false
        }
    }
   
    componentDidMount() {




    }
    

    render() {
        const { settingsData ,supportData,errors, formSuccess} = this.state;
        return (
            <div>
            <section className="main-body hospital-profilegap">
   

                            <div className="container-fluid">
                            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                <div className="checkout-tabs">
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div>
                                                <div>
                                                    <div className="">

                                                        <h4 className="font-weight-bold">Support</h4>
                                                    </div>
                                                    <div className="card mt-4">
                                                   
                                                    <div className="p-5 supportImg">
                                                                                <img src={support} className="img-fluid" />
                                                                            </div>
                                                    </div>
                                                </div>
                                                {/* <Footerad /> */}
                                            </div>
                                        </div>
                                     
                                        <div className='col-sm-6'>
                                            <div className="card">
                                            <div className="card-header"> <div className="card-title">Call Us</div> </div>
                                            <div className='card-body supportlftheight'>
                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <div className="form-group mb-0">
                                                                                        <label><strong>Phone Number</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>+91 8015774643</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group mb-0">
                                                                                        <label><strong>Email</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>info@nucleosws.com</strong></label>
                                                                                       
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>info@klinicq.com</strong></label>
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                                <div className="col-sm-6">
                                                                                    <div className="form-group">
                                                                                        <label>Monday – Friday</label><br />
                                                                                        <label>8 a.m. – 11 p.m. IST</label><br />
                                                                                        <label>Saturday – Sunday</label><br />
                                                                                        <label>8 a.m. – 8 p.m. IST</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                      {/*   <div className='col-sm-6'>
                                        <div className="card">
                                            <div className="card-header"> <div className="card-title">Message Us</div> </div>
                                            <div className='card-body'>
                                            <div className="row">
                                                                        <div className="col-sm-12">
                                                                            
                                                                      
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="Name" isMandatory={true} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group">
                                                                                        <InputField
                                                                                            isMandatory={true}
                                                                                            inputType={"text"}
                                                                                            // title={"Business name"}
                                                                                            name={"name"}
                                                                                            controlFunc={this.handleInput}
                                                                                            content={supportData.name}
                                                                                            placeholder={""}
                                                                                            max={50}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.name}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                             <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="Email" isMandatory={true} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group">
                                                                                        <InputField
                                                                                            isMandatory={true}
                                                                                            inputType={"text"}
                                                                                            name={"email"}
                                                                                            controlFunc={this.handleInput}
                                                                                            content={supportData.email}
                                                                                            placeholder={""}
                                                                                            max={50}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.email}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="Category" isMandatory={true} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group sform-control">
                                                                                        <Select maxMenuHeight={120}
                                                                                            className="form-control p-0"
                                                                                            name="category"
                                                                                            value={supportData.category}
                                                                                            onChange={this.handleselectChange}
                                                                                            options={support_categories}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.category}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="How would you describe the Issue" isMandatory={true} />
                                                                                      
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group sform-control">
                                                                                        <Select maxMenuHeight={120}
                                                                                            className="form-control p-0"
                                                                                            name="issue_description"
                                                                                            value={supportData.issue_description}
                                                                                            onChange={this.handleselectChange}
                                                                                            options={issue_description}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.issue_description}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                        <label>Others  </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group">
                                                                                        <textarea className="form-control" rows="4" maxLength={200} onChange={this.handleInput}
                                                                                            value={supportData.others}
                                                                                            name="others" id="comment" placeholder="Describe the issue 
                                                   "></textarea>
                                                                                    </div>
                                                                                    {/* <p className="actvh">Attachment <i className="fa fa-paperclip" aria-hidden="true"></i> <i className="fa fa-folder-open-o"></i></p> */}
                                                                            {/*     </div>
                                                                            </div>
                                                                           

                                                                    <div className="user-notification">
                                                                                <SpanField
                                                                                    classname={
                                                                                        "0" === formSuccess.status
                                                                                            ? "text-danger user-notification"
                                                                                            : "text-success user-notification"
                                                                                    }
                                                                                    title={formSuccess.message}
                                                                                />
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className='col-sm-4'></div>
                                                                                <div className='col-sm-8'>
                                                                                <div className="form-group mb-0 mrgtp">
                                                                                    <button //href="patients-register" 
                                                                                        onClick={this.sendsupportmail}
                                                                                        className="signupbtn abtn adbt " data-toggle="modal"
                                                                                    // data-target=".bs-example-modal-center"
                                                                                    >Send</button>
                                                                                </div>
                                                                                </div>
                                                                           
                                                                            </div>
                                                                        </div>
                                                                      
                                                                    </div>
                                            </div> */}
                                          {/*  </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>


                      
                        </section>
        </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(SupportNew);