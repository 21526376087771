import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import FooterSection from "../../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
// import BusinessUserSideBar from "../../components/container/SideBar";
// import Header from "../../components/container/layout/business/Header";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Popup } from "../../components/UI/Popup";
import Label from "../../components/UI/Label";
import {
  getAllFacilities,
  getAllFacilityCategory,
  addFacility,
  updateFacility,
  deleteFacility,
  getTimeoutbycategory
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import Validation from "../../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import { Popupinfo } from "../../components/UI/Popupinfo";
import Header from "../../components/container/layout/business/Header";
import BusinessUserSideBar from "../../components/container/SideBar";
import TextArea from "../../components/UI/TextArea";
import { Spinner } from "../../components/UI/Spinner";
import DatePicker from "react-date-picker";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { ExpirePopup } from "../../components/UI/ExpirePopup";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

const availabilityOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

class InpFacilityList extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      records: [],
      addPopup: false,
      showexpirePopup: this.props.validity,
      actions: "add",
      facilityCategory: [],
      facilityData: {
        id: "",
        facility_id: "",
        facility_name: "",
        facility_category: "",
        facility_category_id: "",
        facility_category_obj: { value: "", label: "" },
        facility_availability: { value: "", label: "" },
        occupied: "",
        availability: "",
        available: "",
        total: "",
      },

      errors: {
        facility_id: "",
        facility_name: "",
        facility_category: "",
        occupied: "",
        availability: "",
        available: "",
        total: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
    };
    this.columns = [
      {
       // key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
      //  key: "facility_category",
        text: "Category",
        className: "facility_category",
        sortable: true,
        align: "left",
      },
      {
      //  key: "facility_id",
        text: "Facility ID",
        className: "facility_id",
        align: "left",
        sortable: true,
      },
      {
      //  key: "facility_name",
        text: "Description",
        className: "facility_name",
        align: "left",
        sortable: true,
      },
      {
        key: "facility_type",
        text: "Facility Type",
        className: "facility_type",
        align: "left",
        sortable: true,
      },
      
      {
        key: "facility_category_id",
        text: "Category ID",
        className: "hidden-column",
        TrOnlyClassName: "hidden-column",
        sortable: true,
        align: "left",
      },

     /*  {
        key: "availability",
        text: "Availability",
        className: "availability sform-control",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.availability,
            value: "Yes" === record.availability ? 1 : 0,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="facility_availability"
              value={obj}
              onChange={() => this.handleColumnChange(record)}
              options={availabilityOptions}
            />
          );
        },
      }, */
     /*  {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
      {
        key: "occupied",
        text: "Occupied",
        className: "occupied",
        sortable: true,
        align: "left",
      },
      {
        key: "available",
        text: "Available",
        className: "available",
        sortable: true,
        align: "left",
      }, */

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <Tooltip title="Edit">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              </Tooltip>
              <Tooltip title="Delete">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
              </Tooltip>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  
  }
  
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
   
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
  }

  UpdateColumnChange = (data) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios.put(updateFacility, data, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", data.id);

      records[recIndex].facility_id = data.facility_id;
      records[recIndex].facility_name = data.facility_name;
      records[recIndex].availability = data.availability;
      records[recIndex].facility_category = data.facility_category;
      records[recIndex].facility_category_id = data.facility_category_id;
      records[recIndex].occupied = data.occupied;
      // console.log(records[recIndex].available);
      // if (records[recIndex].availability === "No") {
      //   records[recIndex].available = "0";
      //   records[recIndex].occupied = records[recIndex].total;
      // }

      records[recIndex].available = data.available;
      records[recIndex].total = data.total;
      records[recIndex].id = data.id;

      this.setState({ records, tableSuccess: list.data });
      this.clearMessage();
    });
  };
  UpdateFacility = (e) => {
    e.preventDefault();
    let { facilityData, errors } = this.state;
    facilityData.type = "facilityList";
    this.setState({ loading: true });
    errors = Validation(facilityData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      let data = this.state.facilityData;
      data.user_id = this.props.userdata.id;
      data.availability = data.facility_availability.label;
      data.facility_category_id = data.facility_category_obj.value;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let url = this.state.actions === "add" ? addFacility : updateFacility;
      if (this.state.actions === "add") {
        axios.post(url, data, CONFIG).then((list) => {
          if (0 === list.data.status) {
            this.setState({ loading: false });
            this.setState({ errors: { facility_id: list.data.message } });
          } else {
            let addFacility = {};
            addFacility.facility_id = data.facility_id;
            addFacility.facility_name = data.facility_name;
            addFacility.facility_category = data.facility_category_obj.label;
            addFacility.facility_category_id = data.facility_category_id;
            addFacility.availability = data.facility_availability.label;
            addFacility.id = list.data.data[0].id;
            addFacility.occupied = data.occupied;
            addFacility.available = data.available;
            addFacility.total = data.total;
            let records = this.state.records;
            addFacility.s_no = records.length + 1;
            records.push(addFacility);
            this.setState({ records });
           // this.setState({ formSuccess: list.data });
            toast.success("The Facility details have been added successfully.",{
              className:"toast-success"
            });
            this.setState({ loading: false });
            this.clearMessage();
            this.cancel();
          }
        });
      } else {
        axios.put(url, data, CONFIG).then((list) => {
          if (0 === list.data.status) {
            this.setState({ loading: false });
            this.setState({ errors: { facility_id: list.data.message } });
          } else {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].facility_id = data.facility_id;
            records[recIndex].facility_name = data.facility_name;
            records[recIndex].availability = data.facility_availability.label;
            records[recIndex].facility_category =
              data.facility_category_obj.label;
            records[recIndex].facility_category_id =
              data.facility_availability.value;
            records[recIndex].occupied = data.occupied;
            records[recIndex].available = data.available;
            records[recIndex].total = data.total;
            records[recIndex].id = data.id;

            this.setState({ records });
          //  this.setState({ formSuccess: list.data });
            toast.success("The Facility details have been updated successfully.",{
              className:"toast-success"
            });
            this.setState({ loading: false });
            this.clearMessage();
            this.cancel();
          }
        });
      }
    }
  };

  clearErrors = (errors) => {
    errors.facility_id = "";
    errors.facility_name = "";
    errors.facility_category = "";
    errors.occupied = "";
    errors.availability = "";
    errors.available = "";
    errors.total = "";
    return errors;
  };

  cancel = () => {
    let data = this.state.facilityData;
    data.facility_id = "";
    data.facility_name = "";
    data.id = "";
    data.user_id = "";
    data.facility_category_obj = {};
    data.facility_availability = {};
    data.occupied = "";
    data.available = "";
    data.total = "";

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ facilityData: data, actions: "add", errors: errors });
  };

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
        }),
      5000
    );
  };

  editRecord(record) {
    let facilityData = this.state.facilityData;
    facilityData.facility_id = record.facility_id;
    facilityData.facility_name = record.facility_name;
    facilityData.id = record.id;
    facilityData.user_id = record.user_id;
    facilityData.facility_category_obj = {
      label: record.facility_category,
      value: record.facility_category_id,
    };
    facilityData.facility_availability = {
      label: record.availability,
      value: "Yes" === record.availability ? 1 : 0,
    };
    facilityData.occupied = record.occupied;
    facilityData.available = record.available;
    facilityData.total = record.total;

    let errors = this.state.errors;
    errors = this.clearErrors(errors);

    this.setState({
      facilityData: facilityData,
      actions: "edit",
      errors: errors,
    });
  }
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });



    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllFacilities, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let facilitieslist = list.data.data;

        facilitieslist.forEach((element, index) => {
          let facility = {};
          facility.s_no = index + 1;
          facility.facility_id = element.FacilityId;
          facility.facility_name = element.FacilityName;
          facility.facility_category = element.FacilityCategory;
          facility.facility_category_id = element.FacilityCategoryId;
          facility.id = element.id;
          facility.availability = element.availability;
          facility.occupied = element.occupied;
          facility.available = element.available;
          facility.total = element.total;

          facilitieslist[index] = facility;
        });

        // let clientmedicinehistory = {};
        this.setState({ records: facilitieslist });
      })
      .catch((e) => { });

    axios
      .post(getAllFacilityCategory, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let facilityCategory = list.data.data;

        let facilityCategorylist = [];

        facilityCategory.forEach((element, index) => {
          facilityCategorylist.push({
            value: element.id,
            label: element.name,
          });
        });

        // let clientmedicinehistory = {};
        this.setState({ facilityCategory: facilityCategorylist });
      })
      .catch((e) => { });
  };
  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteFacility, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
       // this.setState({ tableSuccess: res.data });
        toast.error("The Facility detail has been deleted successfully.",{
          className:"toast-error"
        });
        this.clearMessage();
      })
      .catch((e) => { });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.facilityData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    errors.facility_category="";
    if (data.facility_availability.value === 0) {
      data.available = "0";
      // data.occupied = data.total;
    } else {
      data.available = data.total - data.occupied;
    }
    this.setState({ facilityData: data });
  };
  handleselectavailabilityChange= (selectedOptions, e) => {
    let data = this.state.facilityData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    errors.availability="";
  }
  handleColumnChange = (record) => {
    record.availability = "Yes" === record.availability ? "No" : "Yes";
    if (record.availability === "No") {
      record.available = "0";
    } else {
      record.available = record.total - record.occupied
    }
    this.UpdateColumnChange(record);
  };

  validateMaxLength(name, value, max) {
    let data = this.state.facilityData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ facilityData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.facilityData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    //const re = /^[0-9\b]+$/;
   let errors=this.state.errors;

    
    if (name === "total" || name === "available" || name === "occupied")
     {
      
        data[e.target.name] = e.target.value.replace(/[^0-9]/ig, '');
        errors.occupied="";
        errors.total="";
        errors.available="";
  
    
    }
    if (data.facility_availability.value === 0) {
      data.available = "0";
      // data.occupied = data.total;
   
      data.available = data.total - data.occupied;
    }
    if (name === "occupied" || name === "total")
    {
      data.available = data.total - data.occupied;
   
     }
    else if (
      name === "facility_id" ||
      name === "facility_name" 
      
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      
    }

    this.setState({ facilityData: data });
  };
  render() {
     let Popupclose = () => this.setState({ addPopup: false }); 
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupClose = () => {
        window.location.reload();
        this.setState({ showexpirePopup: true })
      };
    const {
      facilityData,
      facilityCategory,
      errors,
      formSuccess,
      tableSuccess,
      hospitaltimeout
    } = this.state;
    return (
      <div className="col-sm-12 checkout-tabs">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />
            <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />

       
        {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
       
            <div className="main-content bacfot">
                <div className="mainpage">
                  <Header user_id={this.props.userdata.id} />
                  <div className="main-box">
                    <div className="sidemenu pr0">
                      <BusinessUserSideBar />
                    </div>
            <section className="main-body hospital-profilegap">  
        <div className="row mt-4">
      
      
          <div className="col-sm-12">
          <h4 className="font-weight-bold">Inpatient facility Details</h4>
            <div className="card">
              <div className="card-body ">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <div className="user-notification">
                      <SpanField
                        classname={
                          0 === tableSuccess.status
                            ? "text-danger user-notification"
                            : "text-success user-notification"
                        }
                        title={tableSuccess.message}
                      />
                      <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                    </div>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      className="table table-bordered table-striped kas  mobile_table"
                    />
                    <Popup
                      show={this.state.addPopup}
                      onHide={Popupclose}
                      onConfirm={PopupConfirm}
                      title={"Confirmation"}
                      message={"Are you sure you want to delete this record?"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 pr0">
            <div className="card">
            <div class="card-header"> <div class="card-title"> {this.state.actions === "add" ? "Add" : "Edit"} Facility</div> </div>
              <div className="card-body">
                <div className="user-notification">
                  <SpanField
                    classname={
                      0 === formSuccess.status
                        ? "text-danger user-notification"
                        : "text-success user-notification"
                    }
                    title={formSuccess.message}
                  />
                </div>

                <div className="card ">
                <div className="row">
                    <div className="col-sm-12">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                        <Label title="Category" isMandatory={true} />
                        </div>
                        <div className="form-group sform-control frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text inpico">
                                  <i className="fas fa-search"></i>
                                </span>
                              </div>
                              <Select maxMenuHeight={120}
                                className="form-control p-0"
                                name="facility_category_obj"
                                value={facilityData.facility_category_obj}
                               // onChange={this.handleselectChange}
                                options={facilityCategory}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.facility_category}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                 
                    <div className="col-sm-12"></div>
               
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                     
                    </div>
                  
                  </div>
                  <div className="col-sm-12 pr0">
                  <div className="row">
                    <div className="col-sm-12">
                    
                    </div>
               
                  </div>
                  <div className="row">
                      <div className="col-sm-12">

                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                        <Label title="Facility ID" isMandatory={true} />
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"facility_id"}
                                controlFunc={this.handleInput}
                                content={facilityData.facility_id}
                                placeholder={""}
                                max={50}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.facility_id}
                            />
                          </div>
                        </div>
                      </div>

                       <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                        <Label title="Description" isMandatory={true} /> 
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"facility_name"}
                                controlFunc={this.handleInput}
                                content={facilityData.facility_name}
                                placeholder={""}
                                max={50}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.facility_name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card-title card-header">Patient Room</div>  
                      <br/>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Facility Type</label>
                          </div>
                          <div className="form-group frlrclfgf ">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="facility_type"
                                                        
                             
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Ward</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                         
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="ward"
                                                        
                             
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.relationship}
                            />
                          </div>
                        </div>

                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Room Type</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="ward"
                                                        
                             
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.date_of_birth}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Floor</label>
                          </div>
                          <div className="form-group frlrclfgf">
                          <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="floor"
                                                        
                             
                            />
                          </div>
                        </div>

                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Room No</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"first_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                            //  content={this.state.clientData.first_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.first_name}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Bed No</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"last_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                          //    content={this.state.clientData.last_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.last_name}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Condition</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            <Select maxMenuHeight={120}
                              className="form-control p-0"
                              name="gender"
                              isDisabled={!this.state.is_add_btn?true:false}
                         //    value={this.state.clientData.gender}
                              /* onChange={this.handlePatientChange.bind(
                                this,
                                "gender"
                              )} */
                            //  options={gender}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.gender}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Next Service</label>
                          </div>
                          <div className="form-group sform-control frlrclfgf">
                            <Select maxMenuHeight={120}
                              className="form-control p-0"
                              isDisabled={!this.state.is_add_btn?true:false}
                              name="blood_group"
                         //     value={this.state.clientData.blood_group}
                              /* onChange={this.handlePatientChange.bind(
                                this,
                                "blood_group"
                              )} */
                           //   options={blood_group}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.blood_group}
                            />
                          </div>
                        </div>
                     

                     

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Capacity" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                           
                                              name={"capacity"}
                                              controlFunc={this.handleInput}
                                    //          content={this.state.clientData.city}
                                              placeholder={""}
                                              max={30}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.city}
                                            />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                            <Label title="Additional Bed" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                          <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              name={"additional_bed"}
                                              controlFunc={this.handleInput}
                                    //          content={this.state.clientData.city}
                                              placeholder={""}
                                              max={30}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.country}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Price per day</label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                         {/*    <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Add on Price</label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2 ">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                          {/*   <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                        </div>
                                       
                                      
                        <div>
                          <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft required"
                                  : "form-group frllft "
                              }
                            >
                              <label>Features</label>
                            </div>
                            <div className="form-group sform-control frlrit">
                              <Select maxMenuHeight={120}
                                isMulti
                                className="p-0 "
                                name="reason_for_visit"
                          //     value={this.state.clientData.reason_for_visit}
                               /*  onChange={this.handlePatientChange.bind(
                                  this,
                                  "reason_for_visit"
                                )} */
                             //   options={disease_array}
                              />
                              <SpanField
                                classname="text-danger"
                                title={errors.reason_for_visit}
                              />
                            </div>
                          </div>
                      {/*     <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft required"
                                  : "form-group frllft"
                              }
                            >
                              <label>Symptoms</label>
                            </div>
                            <div className="form-group sform-control frlrit">

                              <Select maxMenuHeight={120}
                                isMulti
                                className="form-controls w-100 p-0"
                                disabled={true}
                                name="symptoms"
                             //   value={this.state.clientData.symptoms}
                                isDisabled={true}
                              />
                              <SpanField
                                classname="text-danger"
                                title={errors.symptoms}
                              />
                            </div>
                          </div>

                          <div className="frllftfull">
                            <div
                              className={
                                !this.state.is_add_btn
                                  ? "form-group frllft mbbre required"
                                  : "form-group frllft mbbre"
                              }
                            >
                              <label className="ffgrd">Select Doctor</label>
                            </div>
                            <div className="form-group sform-control frlrit">
                              <div className="position-relative">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text inpico">
                                      <i className="fas fa-search"></i>
                                    </span>
                                  </div>
                                  <Select maxMenuHeight={120}
                                    className="form-control p-0"
                                    name="selected_doc"
                                   // value={selected_doc}
                                  //  onChange={this.handleselectDocChange}
                                 //   options={doctor_list}
                                    isDisabled={this.state.is_check_in}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                       
                          <SpanField
                            classname="text-danger"
                            title={errors.message}
                          /> */}

                         



                        </div>
                       
                      
                      </div>
                    </div>
                 

                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6">
                      <div className="form-group mb-0 btn-align-right mt-2 facilitylistbtn">
                        <button
                          href="patients-register"
                          className="signupbtn abtn btdpml btn-margin"
                          onClick={this.UpdateFacility}
                        >
                          {this.state.actions === "add" ? "+ Add" : "Update"}
                        </button>

                        <button
                          href="patients-register"
                          className="signupbtn abtn btdpml"
                          onClick={this.cancel}
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 pl0">
            <div className="card ">
            <div class="card-header"> <div class="card-title">Medical Equipment</div> </div>
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                  
                   

                  </div>
                </div>
                
                      <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Serial Number</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"first_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                           //   content={this.state.clientData.first_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.first_name}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Manufacturer</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"last_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                           //  content={this.state.clientData.last_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.last_name}
                            />
                          </div>
                      </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Mfg. Date</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"first_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                          //    content={this.state.clientData.first_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.first_name}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Equipment Type</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"last_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                           //   content={this.state.clientData.last_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.last_name}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Next Service</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"first_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                            //  content={this.state.clientData.first_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.first_name}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1">
                            <label>Condition</label>
                          </div>
                          <div className="form-group frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"last_name"}
                              readOnly={!this.state.is_add_btn?true:false}
                              controlFunc={this.handleInput}
                        //      content={this.state.clientData.last_name}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.last_name}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Asset Price </label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                         {/*    <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Price per usage</label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2 ">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                          {/*   <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                        </div>
<br/>

                        <div class="card-header"> <div class="card-title">Ambulance Service</div> </div>
                        <br/>
                          <div className="card-body">
                      
                                            <Fragment>
                                              
                                            
                                                 
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Plate No</label>
                                                </div>
                                                <div className="form-group frlrclfgf">
                                                <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                                                  <SpanField
                                                    classname="text-danger"
                                                //    title={payment_details_error[i].card_name}
                                                  />
                                                </div>
                                                <div className="form-group frlrclfgf-1">
                                                  <label>VIN No.</label>
                                                </div>
                                                <div className="form-group frlrclfgf">
                                                <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                                                </div>
                                              </div>
      
                                              <div className="frllftfull">
                          <div className="form-group frllft required">
                            <label>Initial Miles </label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                         {/*    <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Asset Price</label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2 ">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                          {/*   <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                        </div>
                        <div className="frllftfull">
                          <div className="form-group frllft">
                            <label>Price per usage</label>
                          </div>
                          <div className="form-group  frlrclfgf-2">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                          
                          <div className="form-group frlrclfgf-2">
                          &nbsp;
                          &nbsp;
                          &nbsp;
                        <span>{" "}</span>
                         {/*    <label>Currency</label> */}
                          </div>
                          &nbsp;
                          <div className="form-group frlrclfgf-2">
                          
                             <InputField
                              inputType={"text"}
                              name={"currency"}
                              controlFunc={this.handleInput}
                          //    content={Materialmovement.currency}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.currency}
                            />
                          </div>
                          <div className="form-group frlrclfgf-1 required">
                            <label>Condition</label>
                          </div>
                          <div className="form-group  frlrclfgf">
                            <InputField
                              inputType={"text"}
                              name={"cost"}
                              controlFunc={this.handleInput}
                     //         content={Materialmovement.cost}
                              placeholder={""}
                              max={50}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.cost}
                            />
                          </div>
                      
                        </div>
      
                                           
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>
                                                    Features
                                                  </label>
                                                </div>
                                                <div className="form-group frlrit">
                                                <Select maxMenuHeight={120}
                                isMulti
                                className="p-0 "
                                name="reason_for_visit"
                          //     value={this.state.clientData.reason_for_visit}
                               /*  onChange={this.handlePatientChange.bind(
                                  this,
                                  "reason_for_visit"
                                )} */
                             //   options={disease_array}
                              />
                                                  <SpanField
                                                    classname="text-danger"
                                               //     title={payment_details_error[i].payment_option}
                                                  />
                                                </div>
                                              </div>
                                            </Fragment>
                                  
                          </div>
              </div>
            </div>
           
      
          </div>

                    
        </div>
       </section>
       </div>
      </div>
        </div>
       
           )}
     </div>
     
    );
                }
            }
  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(InpFacilityList));
