import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { PropTypes } from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import axios from "axios";
import {
   sendemail
} from "../config/url.json";
function mapStateToProps(state) {
    return {

        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}


class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            gender: '',
            age: '',
            email:'',
            phnumber:'',
            query:''
          };
      
        this.state = {
            loading: false
        }
    }
     componentWillMount() {
        const { steps } = this.props;
        const { name, gender, age,email,phnumber,query } = steps;
    
        this.setState({ name, gender, age,email,phnumber,query });
      }
     
    componentDidMount() {

     // this.handleEnd = this.handleEnd.bind(this);


           }
    

    render() {
        const {name,gender,age,email,phnumber,query}=this.state;
        return (

          
                <div className="m-50">
                   <div style={{ width: '100%' }}>
        <h4>Summary</h4>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{name.value}</td>
            </tr>
          {/*   <tr>
              <td>Gender</td>
              <td>{gender.value}</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>{age.value}</td>
            </tr> */}
            <tr>
              <td>Email</td>
              <td>{email.value}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{phnumber.value}</td>
            </tr>  
            <tr>
              <td>Query</td>
              <td>{query.value}</td>
            </tr>          
          </tbody>
        </table>
      </div>
                </div>
           
        );
    }
   
    
}

Help.propTypes = {
  steps: PropTypes.object,
};

Help.defaultProps = {
  steps: undefined,
};
const theme = {
  background: '#f5f8fb',
  fontFamily: 'Poppins, sans-serif',
  headerBgColor: '#01baf2',
  headerFontColor: '#4a4a4a',
  headerFontSize: '15px',
  botBubbleColor: '#01baf2',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};
const config ={
  width: "400px",
  height: "500px",
  floating: true,
};
class SimpleForm extends Component {
  
  handleEnd({ steps, values }) {
    let i= 1,j=0;
    let reqdata = { 
      "to" : "joanna.vijaygerard@gmail.com",
      "subject" : "Chat Conversation with "+ values[0],
      "mailHeader" : "Chat Conversation with "+ values[0],
      "message": '<div class="row"><div class="col-md-6" style="text-align:left">' }
    
   
      reqdata.message += '<tr style="background-color: #f8f9fa;"><td style="text-align:left">'+ "Name"+'</td><td style="text-align:right">'+ values[0]+'</td></tr>'
      reqdata.message += '<tr style="background-color: #f8f9fa;"><td style="text-align:left">'+ " phone number "  +'</td><td style="text-align:right">'+ values[1]+'</td></tr>'
      reqdata.message += '<tr style="background-color: #f8f9fa;"><td style="text-align:left">'+ "Email" + '</td><td style="text-align:right">'+ values[2]+'</td></tr>'
      reqdata.message += '<tr style="background-color: #f8f9fa;"><td style="text-align:left">'+  "Query" +'</td><td style="text-align:right">'+ values[3]+'</td></tr>'
     
     /*  for(i=5;i<=steps.length;i=i+2)
       {
        
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td style="text-align:left">'+steps[i].message+'</td></tr>';
       
        }
         for(j=0;j<=values.length;j++)
        {
        reqdata.message += '<tr style="background-color: #f8f9fa;"><td style="text-align:right">'+values[j]+'</td></tr>';
         
        }
      
       */

      reqdata.message += '</tbody></table>';
    axios
    .post(sendemail, reqdata)
    .then((res) => {
      let resdata = res.data;
      console.log(resdata);
  })
   //  console.log(steps.length);
    // let i=1;
   /*   for(i=1;i<=steps.length;i=i+2)
     {
      console.log(steps[i].message);
        
     } */
   //  let j=0;
   /*   for(j=0;j<=values.length;j++)
     {
      console.log(values[j]);
        
     } */

   /*   console.log(steps[1].message);
     console.log(steps[3].message);
     console.log(values);
     console.log(values[0]);
     console.log(values[1]);
     console.log(values[2]);
     console.log(values[3]);  */
 //   alert(`Chat handleEnd callback! Number: ${values[0]}`);
  }

  
  render() {
   
    return (
      <ThemeProvider theme={theme}>
       
       

{/* <ChatBot headerTitle="KlinicQ Chat"  bubbleOptionStyle={{ width:100 }}
        steps={[
          {
            id:'intro', 
            message:'Hello,I am chatbot created by KlinicQ to help you with general information about our product and also how to get started with KlinicQ chat.Are you Ready?', 
            trigger:'intro-user',
          },
          {
            id:'intro-user', 
            options:[
            {value:'y', label:'Yes', trigger:'yes-response'},
            {value:'n', label:'No', trigger:'no-response'},
            ] 
          },
          {
            id:'yes-response', 
            message:'Great!What would you like to know.?', 
            trigger:'klinicq-main',
          },
          {
            id: 'klinicq-main',
            options: [
              { value: 1, label: 'Tell me more about KlinicQ?', trigger: 'klinicq-details' },
              { value: 2, label: 'How is KlinicQ Different?', trigger: 'klinicq-different' },
              { value: 3, label: 'How does KlinicQ works?', trigger: 'klinicq-works' },
              { value: 4, label: 'How can i get started', trigger: 'klinicq-details' },
              { value: 5, label: 'Subscribe to Updates', trigger: 'klinicq-details' },
              { value: 6, label: 'I am good ,Go Back.', trigger: 'klinicq-main' },
            ],
          },
          {
      id: 'klinicq-details',
      component: (
        <div><p><h5>1. what is a KlinicQ?</h5> </p>
        <p>KlinicQ is a clinical management system used to manage doctor schedules, clinical operations 
          that helps to manage patients flow through an easy-to-use digital solution.</p>
        <p><h5>2.In what way KlinicQ will be helpful?</h5></p>
        <p>Seamless integration between hospital, lab, pharmacy, doctors and patients are done in very 
        efficient way, patients book their appointment based on variety of selection criteria.
        </p><p><h5>3.How can a KlinicQ help my Business?</h5></p> 
         <p>If your goal is to reduce or eliminate lines and patient wait times to improve 
          patient satisfaction and receive feedback, increase the productivity of hospital staff’s,
           boost the efficiency of your Hospital. Gain key insights into your customers’ behavior – or all the above – 
           KlinicQ is the one-stop-shop solution you’ve been waiting for.</p>
           <p><h5>4.Can you give some example ?</h5></p>
           <p>KlinicQ healthcare customers have seen a 20% increase in net promoter scores (NPS) by getting patients 
            out of the waiting room and by increasing survey</p>
</div>
      ),
      trigger:'goback',
    },
    {
      id: 'klinicq-different',
      component: (
        <div><p><h5>How is KlinicQ Different?</h5> </p>
        <p>The KlinicQ healthcare queue management system and our healthcare patient scheduling app save patients and caregivers time and energy by using cloud-based software to better serve and easily connect with patients. By improving patient 
          satisfaction scores by up to 99%, we can help you deliver needed care quickly.</p>
        
</div>
      ),
      trigger:'klinicq-product',
    },
    {
      id: 'klinicq-works',
      component: (
        <div><p><h5>How does KlinicQ works?</h5> </p>
        <p>Once you create an account ,you get access to the management dashboard,
          which is your control panel to create,edit and delete the data.
Learn More about
</p>
        
</div>
      ),
      trigger:'klinicq-options',
    }, {
      id: 'klinicq-options',
      options: [
        { value: 1, label: 'Create an account', trigger: '' },
        { value: 2, label: 'Product details', trigger: '' },
        { value: 3, label: 'Modules', trigger: '' },
        { value: 4, label: 'Subscription Package', trigger: '' },
        { value: 5, label: 'More features', trigger: '' },
        { value: 6, label: 'I am good ,Go Back.', trigger: 'klinicq-main' },
      ],
    },
    {
      id: 'klinicq-product',
      options: [
        { value: 1, label: 'KlinicQ Algorithm', trigger: 'klinicq-algorithm' },
        { value: 2, label: 'Amazon web services technology', trigger: 'amazon-web-services' },
        { value: 3, label: 'Appointment Scheduling', trigger: 'appointment-scheduling' },
        { value: 4, label: 'Tracking and  reporting', trigger: 'tracking-reporting' },
        { value: 5, label: 'Virtual Updates', trigger: '' },
        { value: 6, label: 'Kiosk System', trigger: '' },
        { value: 7, label: 'Customized Advertisement', trigger: '' },
        { value: 8, label: 'Security management', trigger: '' },
        { value: 9, label: 'I am good ,Go Back.', trigger: 'klinicq-main' },
      ],
    },
    {
      id:'goback', 
      options:[
      {value:'y', label:'Go Back', trigger:'klinicq-main'}
      // {value:'n', label:'Bye', trigger:'2'},
      ] 
    },{
      id: 'klinicq-algorithm',
      component: (
        <div><p><h5>KlinicQ Algorithm</h5> </p>
       

<p>KlinicQ algorithm is developed to solve a fundamental and global human problem which is wasting time waiting in line.

Contact Us at <a href="https://klinicq.com/support">https://klinicq.com/support</a> to get more information on the technology and policies.

To know more about go to <a href="https://klinicq.com">https://klinicq.com</a>.

</p>




        
</div>
      ),
     
    },{
      id: 'amazon-web-services',
      component: (
        <div><p><h5>Amazon web service technology
        </h5> </p>
     
      <p>KlinicQ app is web-based and supported by all standard browsers, responsive
      and react components will adjust the screens based on the devices.,
    Patients and doctors have Android native application., all the data is stored in AWS cloud 
    server so that data can be retrieved from any where and anytime.
    </p>



        
</div>
      ),
      trigger:'klinicq-product',   
    }, 
     {
      id: 'appointment-scheduling',
      component: (
        <div><p><h5>Appointment Scheduling Software</h5> </p>
        <p><h5>What is Appointment </h5></p>

<p>Appointment system is to book an appointment in the allocated time slots.
  on-premise office visit and virtual doctor visit for the present day or future day can be booked with this option </p>

<p><h5>What is meant by Token </h5>
</p>
<p>Token system is to book an appointment for a present day based on first in first out strategy.</p>



        
</div>
      ),
      trigger:'klinicq-product',   
    }, 

    {
      id: 'tracking-reporting',
      component: (
        <div><p><h5>Tracking & Reporting</h5> </p>
            <p>Get insights into your customer’s habits by using the real-time data for your 
              lines and locations. These features allow you to learn more about customers who aren’t 
              showing up and who are returning. You can also learn about the outcomes for different transaction
               types and the duration of service.</p>



        
</div>
      ),
      trigger:'klinicq-product',   
    }, 
          {
            id:'no-response', 
            message:'Sorry to hear that.', 
            end:true,
          },
        ]}
        {...config}
      /> */}
<ChatBot headerTitle="KlinicQ Chat"  bubbleOptionStyle={{ width:200 ,height:40,}} bubbleStyle={{  }}

        steps={[
          {
            id: '1',
            message: 'What is your name?',
            trigger: 'name',
          }
          ,
          {
            id:'name', 
            user:true, 
            trigger: '3',
            validator: (value) => {
               if (/^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(value))
                 {
                   return true;
                 }
               else
                 {
                   return'Please input alphabet characters only.';
                 }
            }
          },
        
          {
            id: '3',
            message: 'Hi {previousValue}! What is your phone number?',
            trigger: 'phnumber',
          },
              
        {
          id:'phnumber',
          user:true,
          trigger:5,
          validator: (value) => {
            if (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value))
            {
              return true;
            }
          else
            {
              return'Please enter a valid phone number.';
            }
          }
        }, 
         {
            id:'5',
            message:'Hello. What is your email?',
            trigger:'email'
          }, 
          {
            id:'email', 
            user:true,
            trigger:'7', 
            validator: (value) => {
               if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))
                 {
                   return true;
                 }
               else
                 {
                   return'Please enter a valid email.';
                 }
            }},
            {
              id:'7',
              message:'Hi. What is your Query?Pls be Specific',
              trigger:'query'
            }, 
            {
              id: 'query',
              user:true,
              trigger: 'start-chat',
            },
        /*   {
            id: '8',
            message: 'Great! Check out your summary',
            trigger: 'review',
          },
          {
            id: 'review',
            component: <Help />,
            asMessage: true,
            trigger: 'submit',
          },
          {
            id:'submit', 
            options:[
            {value:'n', label:'Submit', trigger:'end-message'}, 
            ] 
          }, */
         /*  {
            id: 'update',
            message: 'Would you like to update some field?',
            trigger: 'update-question',
          },
          {
            id: 'update-question',
            options: [
              { value: 'yes', label: 'Yes', trigger: 'update-yes' },
              { value: 'no', label: 'No', trigger: 'end-message' },
            ],
          },
          {
            id: 'update-yes',
            message: 'What field would you like to update?',
            trigger: 'update-fields',
          }, */
       /*    {
            id: 'update-fields',
            options: [
              { value: 'name', label: 'Name', trigger: 'update-name' },
              { value: 'gender', label: 'Gender', trigger: 'update-gender' },
              { value: 'age', label: 'Age', trigger: 'update-age' },
            ],
          },
          {
            id: 'update-name',
            update: 'name',
            trigger: '7',
          },
          {
            id: 'update-gender',
            update: 'gender',
            trigger: '7',
          },
          {
            id: 'update-age',
            update: 'age',
            trigger: '7',
          }, */
       /*    {
            id: 'end-message',
            message: 'Thanks! Your data was submitted successfully!',
            trigger:'start-chat',
           
          }, */
          {
            id:'start-chat', 
            options:[
            {value:'y', label:'Start Chat', trigger:'intro'},
           
            ] 
          },
          {
            id:'intro', 
            message:'Hello,I am chatbot created by KlinicQ to help you with general information about our product and also how to get started with KlinicQ chat.Are you Ready?', 
            trigger:'intro-user',
          },
          {
            id:'intro-user', 
            options:[
            {value:'y', label:'Yes', trigger:'yes-response'},
            {value:'n', label:'No', trigger:'end'},
            ] 
          },
          {
            id:'yes-response', 
            message:'Great!What would you like to know.?', 
            trigger:'klinicq-main',
          }, {
            id: 'klinicq-main',
            options: [
              { value: 1, label: 'Tell me more about KlinicQ?', trigger: 'klinicq-details' },
              { value: 2, label: 'How is KlinicQ Different?', trigger: 'klinicq-different' },
              { value: 3, label: 'How does KlinicQ works?', trigger: 'klinicq-works' },
              { value: 4, label: 'How can i get started', trigger: 'klinicq-how-can-i-get-started' },
              { value: 5, label: 'Subscribe to Updates', trigger: 'klinicq-details' },
              { value: 6, label: 'I am good ,Go Back.', trigger: 'klinicq-main' },
              { value: 7, label: 'Finish', trigger: 'end' },
            ],
          },
          {
      id: 'klinicq-details',
      component: (
        <div><p><h5>1. what is a KlinicQ?</h5> </p>
        <p>KlinicQ is a clinical management system used to manage doctor schedules, clinical operations 
          that helps to manage patients flow through an easy-to-use digital solution.</p>
        <p><h5>2.In what way KlinicQ will be helpful?</h5></p>
        <p>Seamless integration between hospital, lab, pharmacy, doctors and patients are done in very 
        efficient way, patients book their appointment based on variety of selection criteria.
        </p><p><h5>3.How can a KlinicQ help my Business?</h5></p> 
         <p>If your goal is to reduce or eliminate lines and patient wait times to improve 
          patient satisfaction and receive feedback, increase the productivity of hospital staff’s,
           boost the efficiency of your Hospital. Gain key insights into your customers’ behavior – or all the above – 
           KlinicQ is the one-stop-shop solution you’ve been waiting for.</p>
           <p><h5>4.Can you give some example ?</h5></p>
           <p>KlinicQ healthcare customers have seen a 20% increase in net promoter scores (NPS) by getting patients 
            out of the waiting room and by increasing survey</p>
</div>
      ),
      trigger:'goback',
    },
    {
      id: 'klinicq-different',
      component: (
        <div><p><h5>How is KlinicQ Different?</h5> </p>
        <p>The KlinicQ healthcare queue management system and our healthcare patient scheduling app save patients and caregivers time and energy by using cloud-based software to better serve and easily connect with patients. By improving patient 
          satisfaction scores by up to 99%, we can help you deliver needed care quickly.</p>
        
</div>
      ),
      trigger:'klinicq-product',
    },
    {
      id: 'klinicq-works',
      component: (
        <div><p><h5>How does KlinicQ works?</h5> </p>
        <p>Once you create an account ,you get access to the management dashboard,
          which is your control panel to create,edit and delete the data.
Learn More about
</p>
        
</div>
      ),
      trigger:'klinicq-works-options',
    },
    {
      id: 'klinicq-how-can-i-get-started',
      component: (
        <div><p><h5></h5> </p>
        <p>
        Glad you asked.
        To get started you can:


        </p>
</div>
      ),
      trigger:'createanaccount',
    },
    {
      id: 'create-a-new-account',
      component: (
        <div><p><h5></h5> </p>
        <p>
        Thank you for your interest in KlinicQ product.I am here to help you create 
        your free account.It will only take few minutes.
        </p>
        <p>what is your Name ?</p>
</div>
      ),
      trigger:'accountname',
    },
    {
      id:'accountname', 
      user:true, 
      trigger: 'newaccountname',
      validator: (value) => {
         if (/^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(value))
           {
             return true;
           }
         else
           {
             return'Please input alphabet characters only.';
           }
      }
    },
    {
      id: 'newaccountname',
      message: 'Hi {previousValue}! Nice to meet you.',
      trigger: 'ques-phonenum',
    },
    {
      id: 'ques-phonenum',
      message: 'What is your phone number?',
      trigger: 'accountphnumber',
    },
    {
      id:'accountphnumber',
      user:true,
      trigger:'accountsteps',
      validator: (value) => {
        if (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value))
        {
          return true;
        }
      else
        {
          return'Please enter a valid phone number.';
        }
      }
    }, {
      id: 'accountsteps',
      component: (
        <div><p><h5></h5> </p>
        <p>
        You can be able to create an account based on your requirements,
         such as Client login,Doctor login and Hospital login
        </p>
        <p>Once you log in to your account,Click here to know the instructions & tips to get you started.

</p>
         
</div>
      ),
      trigger:'login-instructions',
    },
    {
      id: 'login-instructions',
      options:[
        {value:'y', label:'Here', trigger:'login-steps'},
              ] ,

    },{
      id: 'login-steps',
      component: (
        <div>
       

<p>Click the link to create an account <a href="https://klinicq.com">https://klinicq.com</a>.
Click the Register button and create an account based on Client,Doctor or Hospital.
Fill in the Details and Sign Up based on Needs.
</p>




        
</div>
      ),
      trigger: 'end',   
    },
    
     {
      id: 'klinicq-works-options',
      options: [
        { value: 1, label: 'Create an account', trigger: 'create-an-account' },
        { value: 2, label: 'Product details', trigger: 'product-details' },
        { value: 3, label: 'Modules', trigger: 'modules' },
        { value: 4, label: 'Subscription Package', trigger: 'subscription-package' },
        { value: 5, label: 'I am good ,Go Back.', trigger: 'klinicq-main' },
      ],
    },
    {
      id: 'klinicq-product',
      options: [
        { value: 1, label: 'KlinicQ Algorithm', trigger: 'klinicq-algorithm' },
        { value: 2, label: 'Amazon web services technology', trigger: 'amazon-web-services' },
        { value: 3, label: 'Appointment Scheduling', trigger: 'appointment-scheduling' },
        { value: 4, label: 'Tracking and  reporting', trigger: 'tracking-reporting' },
        { value: 5, label: 'Virtual Updates', trigger: 'virtual-updates' },
        { value: 6, label: 'Kiosk System', trigger: 'kiosk-system' },
        { value: 7, label: 'Customized Advertisement', trigger: 'customized-advertisement' },
        { value: 8, label: 'Security management', trigger: 'security-management' },
        { value: 9, label: 'I am good ,Go Back.', trigger: 'klinicq-main' },
      ],
    }, {
      id:'gotoklinicqproduct', 
      options:[
      {value:'y', label:'Go Back', trigger:'klinicq-product'},
      {value:'n', label:'Finish', trigger:'end'},
      ] 
    },
    {
      id:'goback', 
      options:[
      {value:'y', label:'Go Back', trigger:'klinicq-main'},
      {value:'n', label:'Finish', trigger:'end'},
      ] 
    },
    {
      id:'gotoklinicqworksoptions', 
      options:[
      {value:'y', label:'Go Back', trigger:'klinicq-works-options'},
      {value:'n', label:'Finish', trigger:'end'},
          ] 
    },
    {
      id:'createanaccount', 
      options:[
      {value:'y', label:'Create an account', trigger:'create-a-new-account'},
      {value:'n', label:'Finish', trigger:'end'},
          ] 
    },
    {
      id: 'klinicq-algorithm',
      component: (
        <div><p><h5>KlinicQ Algorithm</h5> </p>
       

<p>KlinicQ algorithm is developed to solve a fundamental and global human problem which is wasting time waiting in line.

Contact Us at <a href="https://klinicq.com/support">https://klinicq.com/support</a> to get more information on the technology and policies.

To know more about go to <a href="https://klinicq.com">https://klinicq.com</a>.

</p>




        
</div>
 
      ),
      trigger:'gotoklinicqproduct',   
    },
    {
      id: 'amazon-web-services',
      component: (
        <div><p><h5>Amazon web service technology
        </h5> </p>
     
      <p>KlinicQ app is web-based and supported by all standard browsers, responsive
      and react components will adjust the screens based on the devices.,
    Patients and doctors have Android native application., all the data is stored in AWS cloud 
    server so that data can be retrieved from any where and anytime.
    </p>



        
</div>
      ),
      trigger:'gotoklinicqproduct',   
    }, 
     {
      id: 'appointment-scheduling',
      component: (
        <div><p><h5>Appointment Scheduling Software</h5> </p>
        <p><h5>What is Appointment </h5></p>

<p>Appointment system is to book an appointment in the allocated time slots.
  on-premise office visit and virtual doctor visit for the present day or future day can be booked with this option </p>

<p><h5>What is meant by Token </h5>
</p>
<p>Token system is to book an appointment for a present day based on first in first out strategy.</p>



        
</div>
      ),
      trigger:'gotoklinicqproduct',   
    }, 
    {
      id: 'tracking-reporting',
      component: (
        <div><p><h5>Tracking & Reporting</h5> </p>
            <p>Get insights into your customer’s habits by using the real-time data for your 
              lines and locations. These features allow you to learn more about customers who aren’t 
              showing up and who are returning. You can also learn about the outcomes for different transaction
               types and the duration of service.</p>



        
</div>
      ),
      trigger:'gotoklinicqproduct',   
    },
    {
      id: 'virtual-updates',
      component: (
        <div><p><h5>Virtual Updates </h5> </p>
            <p>Patients can join a virtual queue online via KlinicQ mobile app,
               website and using their mobile phone (text or voice), or at an on-site kiosk. 
               KlinicQ healthcare system algorithm process several data points to provide an accurate
                forecasted wait time for each patients & they can receive real-time updates
                 about their estimated wait times.</p>



        
</div>
      ),
      trigger:'gotoklinicqproduct',     
    }, 
    {
      id: 'kiosk-system',
      component: (
        <div><p><h5>Kiosk System </h5> </p>
            <p>Kiosks in hospital waiting room allow customers to add themselves to a queue 
              without hospital staff assistance. In the touch screen patients can enter their
               phone number or scan the QR code to retrieve their appointment and Patients vital details 
               can be measured at Kiosk system.
</p>



        
</div>
      ),
      trigger:'gotoklinicqproduct',     
    }, 
    {
      id: 'customized-advertisement',
      component: (
        <div><p><h5>Customized Advertisement </h5> </p>
            <p>KlinicQ can deliver personalized and targeted communication with your patients using SMS marketing and KlinicQ app. 
              Tailor your promotions with text or slideshow marketing.</p>



        
</div>
      ),
      trigger:'gotoklinicqproduct',     
    },
     {
      id: 'security-management',
      component: (
        <div><p><h5>Security Management </h5> </p>
            <p>Role based security will be provided to the doctors, hospital, Pharmacy & Lab.,
               medical records and history of all patient records are maintained securely and it 
               will not be sold for marketing agencies.
               Privacy & security is our moto and no to selling personal data. 
</p>
        
</div>
      ),
      trigger:'gotoklinicqproduct',     
    },
    {
      id: 'create-an-account',
      component: (
        <div><p><h5>Create An Account</h5> </p>
       

<p>
There are 3 Steps to create an account in which patient,doctor and hospital shall able to 
register with mobile number through mobile app or web portal.</p>

<p>1.Signup Process</p>
<p> 2.OTP verification</p>
<p>3.Basic Details</p>





        
</div>
 
      ),
      trigger:'gotoklinicqworksoptions',   
    },
    {
      id: 'product-details',
      component: (
        <div><p><h5>Product Details</h5> </p>
       

<p>
KlinicQ is a clinical management system used to manage doctor schedules,
 clinical operations that helps to manage patients flow through an easy-to-use digital
  solution.Seamless integration between hospital, lab, pharmacy, doctors and patients.


</p>




        
</div>
 
      ),
      trigger:'gotoklinicqworksoptions',   
    },
    {
      id: 'modules',
      component: (
        <div><p><h5>Module Description.</h5> </p>
       

<p>
Three types of modules are available such as Patient,Doctor and Hospital.
Klinicq offers patients to schedule their appointments and get token.
Clinical management system offers Shift planner system for setting up ,
Configuring working hours, capacity, overbooking and managing Doctor’s calendars and their availability.

</p>




        
</div>
 
      ),
      trigger:'gotoklinicqworksoptions',   
    },
    {
      id: 'subscription-package',
      component: (
        <div><p><h5>Subscription Packages</h5> </p>
       

<p>
There are different  types of packages like silver,gold and platinum with 

specific time limits such as daily,weekly,monthly,quarterly and yearly.The time limits 

are designed in such a way which are beneficial to all.


</p>




        
</div>
 
      ),
      trigger:'gotoklinicqworksoptions',   
    }, 
          {
            id:'no-response', 
            message:'Sorry to hear that.', 
            end:true,
          },
          {
            id:'end', 
            message:'Thanks for using our klinicQ chat.', 
            end:true,
          },
        ]}
        handleEnd={this.handleEnd.bind(this)}
        {...config}
        />
    
      </ThemeProvider>

    );
  }
}

export default SimpleForm;
