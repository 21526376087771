import React, { Component,Fragment } from "react";
import { connect } from "react-redux";
import ProfileLogo from "../components/UI/ProfileLogo";
import axios from "axios";
// import { getReviwes, getClientById } from "../config/url.json";
import { AUTH_CONFIG, CONFIG,card_type, } from "../config/data";
import TextArea from "../components/UI/TextArea";
import Label from "../components/UI/Label";
import InputField from "../components/UI/InputField";
import CheckboxOrRadioGroup from "../components/UI/CheckboxOrRadioGroup";
import StarRatings from "react-star-ratings";
import { Spinner } from "../components/UI/Spinner";
import FooterSection from "../components/UI/FooterSection";
import { ToastContainer, toast } from 'react-toastify';
import MonthPick from "../components/UI/MonthPick";
import YearPick from "../components/UI/YearPick";
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router-dom";
import {
  getReviwes,
  UpdateBusiness,
  getBusinessById,
  getTimeoutbycategory,
  DeleteAccount
} from "../config/url.json";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Validation from "../actions/Validation";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import TimePicker from "react-bootstrap-time-picker";
import SpanField from "../components/UI/SpanField";
import Select from "react-select";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import Footerad from "../components/UI/Footerad";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { Popupinfo } from "../components/UI/Popupinfo";
import Popup from "../components/UI/Popup";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
const currentYear = new Date().getFullYear();
const google = window.google;
const scuedueType = ["Queue", "Appointment"];
export const paymenttype = ["Online","Direct"];


const working_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class BusinessProfile extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    console.log(props.id);
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
    
      deletePopup: false,
      businessData: {
        business_name: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        google_loc_id: "",
        user_type: "hospital",
        phone_number: "",
        fax_number: "",
        website: "",
        year_of_build: "",
        capacity: "",
        payment_type:"",
        schedule_type: [],
        working_hours: {
          morning_opens_at: '',
          morning_close_at: '',
          evening_opens_at: '',
          evening_close_at: '',
          hours_24: false,
        },
        working_days: [],
        speciality: [],
        facility: [],
        profile_summary: "",
        payment_details: [
          {
            "credit_card": "",
            "card_name": "",
            "card_type": {
              "label": "",
              "value": ""
            },
            "expiry_month": "",
            "expiry_year": "",
            "cvv": "",
            "payment_option": ""
          },
        ],
      },
      payment_details: [
        {
          "credit_card": "",
          "card_name": "",
          "card_type": {
            "label": "",
            "value": ""
          },
          "expiry_month": "",
          "expiry_year": "",
          "cvv": "",
          "payment_option": ""
        },
      ],
      payment_details_error: [{
        "credit_card": "",
        "card_name": "",
        "card_type": {
          "label": "",
          "value": ""
        },
        "expiry_month": "",
        "expiry_year": "",
        "cvv": "",
        "payment_option": ""
      },],
      errors: {
        business_name: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        fax_number: "",
        website: "",
        year_of_build: "",
        capacity: "",
        schedule_type: "",
        working_hours_morning: "",
        working_hours_evening: "",
        working_days: "",
        speciality: "",
        facility: "",
        profile_summary: "",
      },
      reviewinfo: {
        friendliness: "",
        value_of_money: "",
        less_wait_time: "",
        best_treatment: "",
        easy_explanation: "",
        good_facility: "",
      },
      commentsinfo: {
        commenter: "",
        avatar: "",
        overall_rating: "",
        comment_text: "",
        reviewed_on: "",
      },
      commentsArr: [],
      editable: false,
      formSuccess: {
        message: "",
        status: "",
      },
      loading: false,
    };
    this.autocomplete = null;
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
   
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }
  

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "", status: "" },
        }),
      3000
    );
  };
  handlePlaceSelect = () => {
    let place = this.autocomplete.getPlace();

    let businessData = this.state.businessData;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    businessData.latitude = lat.toString();
    businessData.longitude = lng.toString();
    businessData.google_loc_id = place.url;

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;

        if (addressType === "administrative_area_level_1")
          businessData.state = val;
        if (addressType === "locality")
          businessData.city = val;
        if (addressType === "country") businessData.country = val;
        // if (addressType === "street_number") {

        businessData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1") businessData.address2 = val;
        if (addressType === "postal_code") businessData.zip_code = val;
      }
    }

    // let ClientData = this.state;
    this.setState({
      businessData,
      google_address: true,
    });
  };
  handleInput = (e) => {
    let data = this.state.businessData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "capacity" ||
      name === "year_of_build" ||
      name === "business_name" ||
      name === "address1" ||
      name === "address2" ||
      name === "city" ||
      name === "zip_code" ||
      name === "fax_number"
      // || name === "email" ||
      // name === "website"
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ businessData: data });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.businessData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      // const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ businessData: data, errors: errors });
  }
  /* for country detail change */
  selectCountry(val) {
    let data = this.state.businessData;
    data.country = val;
    this.setState({ businessData: data });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.businessData;
    data.state = val;
    this.setState({ businessData: data });
  }
  handlePhoneInput = (event) => {
    let businessData = this.state.businessData;
    businessData.phone_number = event;
    this.setState({ businessData });
  };
  handleSechduleType = (e) => {
    let data = this.state.businessData;
    data.schedule_type = [e.target.value];
    this.setState({ businessData: data });
  };
  handleWorkingDays = (e) => {
    let data = this.state.businessData;
    let working_days_data = data.working_days;
    let working_all_days_data = data.working_all_days;

    if ("allDaysId" === e.target.id) {
      working_all_days_data = !working_all_days_data;

      data.working_all_days = working_all_days_data;

      data.working_all_days
        ? (data.working_days = working_days)
        : (data.working_days = []);
    } else {
      const newSelection = e.target.value;
      let newSelectionArray;

      if (data.working_days.indexOf(newSelection) > -1) {
        newSelectionArray = data.working_days.filter((s) => s !== newSelection);
      } else {
        newSelectionArray = [...data.working_days, newSelection];
      }

      data.working_days = newSelectionArray;
      let wd = [];
      data.working_days.forEach((element, index) => {
        if (working_days.indexOf(element) > -1) {
          wd.push(element);
        }
      });

      data.working_days = wd;
    }

    data.working_days.length === 7
      ? (data.working_all_days = true)
      : (data.working_all_days = false);

    this.setState({ businessData: data });
  };
  /* for work detail change */
  handleWorkhoursChange(field, value) {
    let data = this.state.businessData;
    let working_hours_data = data.working_hours;

if(working_hours_data === ""){
  working_hours_data = {
    morning_opens_at: '',
    morning_close_at: '',
    evening_opens_at: '',
    evening_close_at: '',
    hours_24: false,
  };
}
    if ("24Hours" === field) {
      working_hours_data.hours_24 = !working_hours_data.hours_24;
      working_hours_data.morning_opens_at = '00:00';
      working_hours_data.morning_close_at = '11:59';
      working_hours_data.evening_opens_at = '12:00';
      working_hours_data.evening_close_at = '23:59';
    } else {
      working_hours_data.hours_24 = false;
      working_hours_data[`${field}`] = value.target.value;
    }

    data.working_hours = working_hours_data;

    this.setState({ businessData: data });
  }

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.businessData;
    data[e.name] = selectedOptions;

    this.setState({ businessData: data });
  };
  // handleFormSubmit = (e) => {
  //   e.preventDefault();

  //   let { businessData, errors } = this.state;

  //   errors = Validation(businessData, errors);

  //   this.setState({ errors: errors });
  //   if (errors.formIsValid) {
  //     console.log("form is valid");

  //     // LoginApi(ClientData);

  //     try {
  //       const result = axios.post(UpdateBusiness, businessData, {
  //         headers: {
  //           "application-id": "c86ae3f5-3a96-49f2-af05-94746357d0ec",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "OPTIONS,GET,PUT,POST,DELETE",
  //           "Access-Control-Allow-Headers": "X-Requested-With, Content-Type",
  //         },
  //       });
  //       console.log(result.data);
  //       // dispatch(
  //       //     addUserDeails(result.data)
  //       // )
  //     } catch (e) {
  //       // dispatch(
  //       // addError(e.message)
  //       // )
  //     }
  //   }
  // };
  componentDidMount() {

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status == "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    /** Reviews */
    let data = {};
    data.user_id = this.props.userdata.id;
    data.user_type = this.props.userdata.user_type;
    // data.user_type = "doctor";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(
        getReviwes,
        { user_id: data.user_id, user_type: data.user_type },
        CONFIG
      )
      .then((reviewResult) => {
        let reviewResultData = reviewResult.data;
        let commentsData = reviewResultData.data.comments;
        console.log(commentsData);
        // let commentsArr = this.state.commentsArr;

        let reviewinfo = this.state.reviewinfo;
        reviewinfo.friendliness = reviewResultData.data.friendlyness;
        reviewinfo.value_of_money = reviewResultData.data.value_of_money;
        reviewinfo.less_wait_time = reviewResultData.data.less_wait_time;
        reviewinfo.best_treatment = reviewResultData.data.best_treatment;
        reviewinfo.easy_explanation = reviewResultData.data.easy_explanation;
        reviewinfo.good_facility = reviewResultData.data.good_facility;
        this.setState({ reviewinfo });

        this.setState({ commentsArr: commentsData });
      })
      .catch((e) => { });
    setTimeout(
      () => this.getBusinessById(),

      1000
    );
  }
  handlePaymentSelect = (e) => {
    /* const { name, value } = e.target;

    this.setState({
      [name]: value
    });
 */
    let data = this.state.businessData;
    data.payment_type = [e.target.value];
    this.setState({ businessData: data });
  }
  addPaymentDetail() {
    this.setState((prevState) => ({
      payment_details: [
        ...prevState.payment_details,
        {
          "credit_card": "",
          "card_name": "",
          "card_type": {
            "label": "",
            "value": ""
          },
          "expiry_month": "",
          "expiry_year": "",
          "cvv": "",
          "payment_option": ""
        },
      ],
    }));
    this.setState((prevState) => ({
      payment_details_error: [
        ...prevState.payment_details_error,
        {
          "credit_card": "",
          "card_name": "",
          "card_type": {
            "label": "",
            "value": ""
          },
          "expiry_month": "",
          "expiry_year": "",
          "cvv": "",
          "payment_option": ""
        },
      ],
    }));
  }

  removePaymentDetail(i) {
    let payment_details = [...this.state.payment_details];
    payment_details.splice(i, 1);
    this.setState({ payment_details });
  }

  handlePaymentDetailChange(i, value, field) {

    
    let fields = this.state.payment_details;

    let payment_details_error = this.state.payment_details_error;
    if (value === "credit_card" || value === "card_name" || value === "payment_option" || value === "cvv") {

      // const re = /^[0-9\b]+$/;
      // console.log(field.target.value);
      //   if (field.target.value === '' || re.test(field.target.value)) {
      //      this.setState({value: field.target.value})
      //   }  
      
      let max = field.target.max;
      let v = field.target.value;

      if (v.length > max) {
        const value1 = v.slice(0, max);
        payment_details_error[i][value] = "Maximum Length has exceeded.";
        fields[i][value] = value1;
      } else {
        payment_details_error[i][value] = "";
        if(value === "card_name")
          fields[i][value] = v.replace(/[^a-zA-Z]/ig,'');
        else if(value === "credit_card" || value === "cvv")
          fields[i][value] = v.replace(/[^0-9]/ig,'');
        else 
        fields[i][value] = v;

      }

      console.log(payment_details_error);
      console.log(fields);
    } else if (value === "card_type" || value === "expiry_month" || value === "expiry_year") {
      fields[i][value] = field;
    }
    this.setState({
      payment_details_error: payment_details_error,
      payment_details: fields
    });
  }


  getBusinessById = () => {
    axios
      .post(
        getBusinessById,
        { business_id: this.props.userdata.id },
        AUTH_CONFIG
      )
      .then((response) => {
        if (response.data.status === "1") {
         
          let data = response.data.data[0];

          let speciality = [];
          let specialities_array = this.props.appdata
            ? this.props.appdata[0].specialities
            : [];

            console.log(data.speciality);
            console.log(data.speciality.length);
          
          if (data.speciality) {
        let arr1=data.speciality.split(',');
           arr1.forEach((e1,i)=>specialities_array.forEach((element, index) => {
              if (e1==element.id) {
                speciality.push({
                  value: element.id,
                  label: element.name,
                });
              }
            }
           ))
            };
          
          
          if (!data.payment_details || data.payment_details == null||data.payment_details =="") {
            data.payment_details = [{
              "credit_card": "",
              "card_name": "",
              "card_type": {
                "label": "",
                "value": ""
              },
              "expiry_month": "",
              "expiry_year": "",
              "cvv": "",
              "payment_option": ""
            }];
          } else {
             data.payment_details = data.payment_details;
          }
          data.speciality = speciality;
          console.log(data.payment_details);
          let facility = [];
          let facilities_array = this.props.appdata
            ? this.props.appdata[0].facilities
            : [];
          if (data.facility) {
            facilities_array.forEach((element, index) => {
              if (data.facility.indexOf(element.id) > -1) {
                facility.push({
                  value: element.id,
                  label: element.facility_name,
                });
              }
            });
          }

          data.facility = facility;
          let working_hours = {
            morning_opens_at: '',
            morning_close_at: '',
            evening_opens_at: '',
            evening_close_at: '',
            hours_24: false,
          };

          if ("null" === data.working_hours || null == data.working_hours) {
            data.working_hours = working_hours;
          } else {
            data.working_hours = data.working_hours;
          }

          /* Working Days section */
          if (null == data.working_days) {
            data.working_days = [];
          } else {
            data.working_days = data.working_days;
            if (7 === data.working_days.length) {
              data.working_all_days = true;
            } else {
              data.working_all_days = false;
            }
          }
          console.log(data);

          // data.phone_number = (data.phone_number.includes(data.phone_code) && data.phone_code != null)? data.phone_number : data.phone_code.concat(data.phone_number);
          data.phone_number = data.phone_code == null ? "+91"+data.phone_number : data.phone_code.concat(data.phone_number);
          data.payment_type=data.payment_type.toString();
          this.setState({ businessData: data,payment_details:data.payment_details });
        }
      })
      .catch((e) => {
        console.log(e)
       });
  };
  editProfile() {
    //enable the fields to edit
    this.setState({ editable: !this.state.editable });
  }
  cancelEdit() {
    // this.setState({ editDisabled: !this.state.editDisabled });
    this.setState({ editable: !this.state.editable });
  }
  updateProfile = (e) => {
    e.preventDefault();

    let { businessData, errors } = this.state;

    businessData.user_type = "hospitalsignup";

    errors = Validation(businessData, errors);
    console.log(errors,businessData); 
    this.setState({ errors: errors });
    // return false; 
    if (errors.formIsValid) {
      this.setState({ loading: true });
      let speciality = [];
      let data = businessData;

      if (data.speciality) {
        data.speciality.forEach((element) => speciality.push(element.value));
      }
      // data.category = businessData.category_array[0].value;
      data.speciality = speciality.toString();
      data.schedule_type = data.schedule_type.toString();
      let facility = [];

      if (data.facility) {
        data.facility.forEach((element) => facility.push(element.value));
      }

      data.facility = facility.toString();
      // data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
      // data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;
      data.payment_details = this.state.payment_details;
      data.payment_type=data.payment_type.toString();

      try {
        const result = axios.put(UpdateBusiness, data, AUTH_CONFIG);

        const p = Promise.resolve(result);

        let resultdata = p.then((value) => {
          if (value.data.status === "1") {
            // this.props.history.push("/dashboard");

            let speciality = [];
            let specialities_array = this.props.appdata
              ? this.props.appdata[0].specialities
              : [];
            if (data.speciality) {
              let arr1=data.speciality.split(',');
           arr1.forEach((e1,i)=>specialities_array.forEach((element, index) => {
            if (e1==element.id) {
                speciality.push({
                  value: element.id,
                  label: element.name,
                });
              }
            }
           ))
            }
            data.speciality = speciality;

            let facility = [];
            let facilities_array = this.props.appdata
              ? this.props.appdata[0].facilities
              : [];
            if (data.facility) {
              facilities_array.forEach((element, index) => {
                if (data.facility.indexOf(element.id) > -1) {
                  facility.push({
                    value: element.id,
                    label: element.facility_name,
                  });
                }
              });
            }

            data.facility = facility;
            console.log(data);
            // data.phone_number = data.phone_code.concat(data.phone_number);

            this.setState({ businessData: data });
            toast.success("Your profile has been updated successfully.",{
              className:"toast-success"
            });
          }
          if (value.data.status === "0") {
            this.setState({
              error: value.data.message,
              formSuccess: value.data,
            });
            toast.error("Profile could not be updated.",{
              className:"toast-error"
            });
          }
          this.clearMessage();
          setTimeout(() => this.setState({ loading: false }), 1000);
        //  this.setState({ formSuccess: value.data });
        });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        this.setState({ loading: false });
        // dispatch(
        // addError(e.message)
        // )
      }
    }
  };
 
  DeleteAccount=(user_id,user_type)=> {
   
   // let user_id = this.props.userdata.id;
 //  let user_type=this.props.userdata.user_type;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(DeleteAccount, { user_id: user_id ,user_type:user_type}, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
         
          toast.error("Hospital Account deleted. ", {
            className: "toast-error"
          });
         
        }
      })
      .catch((e) => { });
    this.setState({ deletePopup: false, record: null });

    this.setState({
      confirmedDelete: false,
    });
  

  };
  enableEditable = (e) => {
    e.preventDefault();
    console.log(this.state.editable);
    this.setState({ editable: !this.state.editable });
  };
  render() {
    const {
      businessData,
      errors,
      payment_details,
      payment_details_error,
      reviewinfo,
      commentsArr,
      formSuccess,
      hospitaltimeout,loading
    } = this.state;
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () =>
      this.DeleteAccount(this.props.userdata.id,this.props.userdata.user_type);
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let specialities = [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let facilities_array = this.props.appdata
      ? this.props.appdata[0].facilities
      : [];
    let facilities = [];
    facilities_array.forEach((element, index) => {
      facilities.push({ value: element.id, label: element.facility_name });
    });
    facilities_array = facilities;

    return (
      <div id="layout-wrapper">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

       {/*  <Spinner loading={this.state.loading} /> */}
       {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <section className="my-head">
              <div className="container-fluid">

                <BusinessHeader user_id={this.props.userdata.id} />

                {/* <div className="col-sm-5 pl0">
                    <img
                      alt="Advertisement"
                      src={require("../assets/images/advertismenttop.jpg")}
                      className="advertisment"
                    />
                  </div> */}

              </div>
            </section>
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>
              
              {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
              <section className="main-body hospital-profilegap">
                <div className="container-fluid">
                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                  <div className="checkout-tabs">
                  <table className="deleteaccountbtn mb-2">
                              <tbody>
                                <tr>
                                        <td></td>
                                  
                                         <button
                                            type="submit"
                                            onClick={() =>
                  this.setState({ deletePopup: true})
                }
                                            className="s3 float-right"
                                          >
                                            Delete Account
                                          </button>
                                        
                                        </tr>
                                        </tbody></table>
                                      
                  <div className="row">
                        <div className="col-sm-6 pr0">
                      
                          <div className="row reviewsdesign">
                            <div className="col-sm-12">

                              {/* <button
                                  className="fa fa-edit"
                                  type="button"
                                  onClick={this.enableEditable.bind(this)}
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={this.cancelEdit.bind(this)}
                                  style={{
                                    display: this.state.editable
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  style={{
                                    display: this.state.editable
                                      ? "block"
                                      : "none",
                                  }}
                                  onClick={this.updateProfile}
                                >
                                  Save Changes
                                </button> */}
                                
                            </div>
                          </div>
                         
                          <form
                            className="custom-validation"
                            onSubmit={this.handleFormSubmit}
                          >
                          
                           
                            <div className="card">
                            <div class="card-header"> <div class="card-title">Hospital Details</div> </div>
                              <div className="card-body mainpghit">
                               
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <div className="frllftfull">
                                          <div className="form-group">
                                            {/* <label>Profile summary</label> */}
                                            <Label title="Profile Summary " />

                                            {/* <textarea
                                              className="form-control"
                                              rows="4"
                                              id="comment"
                                            ></textarea> */}
                                            <TextArea
                                              // title={'Profile summary'}
                                              rows={4}
                                              // resize={false}
                                              content={
                                                businessData.profile_summary
                                              }
                                              name={"profile_summary"}
                                              controlFunc={this.handleInput}
                                              placeholder={""}
                                              maxLength={200}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Hospital Name" />
                                          </div>
                                          <div className="form-group frlrit">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                            /> */}
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={"Business name"}
                                              name={"business_name"}
                                              controlFunc={this.handleInput}
                                              content={
                                                businessData.business_name
                                              }
                                              placeholder={""}
                                              max={50}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.business_name}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                                          <div className=" frllft">
                                            <Label title="Schedule type" isMandatory={true} />
                                          </div>

                                          <div className=" frlrit">
                                            <CheckboxOrRadioGroup
                                              // title={'Which kinds of pets would you like to adopt?'}
                                              setName={"schedule_type"}
                                              type={"radio"}
                                              controlFunc={this.handleSechduleType}
                                              options={scuedueType}
                                              selectedOptions={businessData.schedule_type}
                                              value={businessData.schedule_type}
                                              checked={businessData.schedule_type==="Q"?true:false}
                                            />
                                            <SpanField
                                              classname="text-danger offset-1"
                                              title={errors.schedule_type}
                                            />
                                          </div>

                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Address1" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              id="address1"
                                              inputType={"text"}
                                              title=""
                                              name={"address1"}
                                              controlFunc={this.handleInput}
                                              content={businessData.address1}
                                              placeholder={""}
                                              max={100}
                                            // isvisible={this.state.isDisable}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.address1}
                                            />
                                          </div>
                                          <div className="form-group frllft">
                                            <Label title="Address2" />
                                          </div>
                                          <div className="form-group frlrit">
                                            <InputField
                                              isMandatory={true}
                                              id="address2"
                                              inputType={"text"}
                                              name={"address2"}
                                              controlFunc={this.handleInput}
                                              content={businessData.address2}
                                              placeholder={""}
                                              max={100}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.address2}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="City" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={'City'}
                                              name={"city"}
                                              controlFunc={this.handleInput}
                                              content={businessData.city}
                                              placeholder={""}
                                              max={30}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.city}
                                            />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                            <Label title="Country" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <CountryDropdown
                                              placeholder="Country"
                                              name="country"
                                              value={businessData.country || ""}
                                              className="form-control"
                                              onChange={(val) =>
                                                this.selectCountry(val)
                                              }
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.country}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="State" />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <RegionDropdown
                                              name="state"
                                              defaultOptionLabel="Select State"
                                              country={businessData.country}
                                              value={businessData.state || ""}
                                              className="form-control"
                                              onChange={(val) =>
                                                this.selectState(val)
                                              }
                                              placeholder="State"
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.state}
                                            />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                            <Label title="Zip/Pincode" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                            /> */}
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              // title={"Zip/Pincode"}
                                              name={"zip_code"}
                                              controlFunc={this.handleInput}
                                              content={businessData.zip_code}
                                              placeholder={""}
                                              max={10}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.zip_code}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <label>Primary Mobile </label>
                                          </div>
                                          <div className=" frlrit">
                                            <div className="form-control">
                                              <PhoneInput
                                              className=""
                                                international
                                                defaultCountry="IN"
                                                placeholder="Enter phone number"
                                             //   readOnly={true}
                                                disabled={true}
                                                value={
                                                  businessData.phone_number
                                                }
                                                onChange={this.handlePhoneInput}
                                                error={
                                                  businessData.phone_number
                                                    ? isValidPhoneNumber(
                                                      businessData.phone_number
                                                    )
                                                      ? undefined
                                                      : "Invalid phone number"
                                                    : "Phone number required"
                                                }
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.phone_number}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Fax Number"  />
                                          </div>

                                          <div className="form-group frlrit">
                                            <InputField
                                              inputType={"text"}
                                              // title={"Fax"}
                                              name={"fax_number"}
                                              controlFunc={this.handleInput}
                                              content={businessData.fax_number}
                                              placeholder={""}
                                              max={10}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.fax_number}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Building Capacity"  />
                                          </div>
                                          <div className="form-group frlrclfgf">
                                            <InputField
                                              inputType={"text"}
                                              name={"capacity"}
                                              controlFunc={this.handleInput}
                                              content={businessData.capacity}
                                              placeholder={""}
                                              max={10}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.capacity}
                                            />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                            <Label title="Year of Build" />
                                          </div>
                                          <div className="form-group frlrclfgf-1">
                                            <InputField
                                              inputType={"text"}
                                              // title={"Year of built"}
                                              name={"year_of_build"}
                                              controlFunc={this.handleInput}
                                              content={
                                                businessData.year_of_build
                                              }
                                              placeholder={""}
                                              max={4}
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.year_of_build}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Working Days" isMandatory={true} />
                                          </div>
                                          <div className="form-group frlrit">
                                            <div className="">
                                              <CheckboxOrRadioGroup
                                                // title={'Which kinds of pets would you like to adopt?'}
                                                setName={"working_days"}
                                                type={"checkbox"}
                                                controlFunc={
                                                  this.handleWorkingDays
                                                }
                                                options={working_days}
                                                selectedOptions={
                                                  businessData.working_days
                                                }
                                              />

                                              {/* <div className="custom-control custom-checkbox mb-2 mylft mtbttd">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="customCheck1"
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="customCheck1"
                                                >
                                                  Su
                                                </label>
                                              </div> */}

                                              <div className="square-switch float-right mg-r1">
                                                <input
                                                  type="checkbox"
                                                  id="allDaysId"
                                                  switch="none"
                                                  onChange={
                                                    this.handleWorkingDays
                                                  }
                                                  checked={
                                                    businessData.working_all_days
                                                  }
                                                />
                                                <label
                                                  htmlFor="allDaysId"
                                                  data-on-label="All days"
                                                  data-off-label="All days"
                                                  className="dcte22 wid-115"
                                                ></label>
                                              </div>
                                            </div>
                                          </div>
                                          <SpanField
                                            classname="text-danger offset-3"
                                            title={errors.working_days}
                                          />
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Working Hours" isMandatory={true} />
                                          </div>
                                          <div className="frlrit">
                                            <div className="flex form-group">
                                              <div className="cusWidth">
                                              <label>Morning From</label>
                                          <input
                                                  disabled={
                                                    businessData.working_hours
                                                      .hours_24
                                                  }
                                                  type="time"
                                                  className="form-control"
                                                  placeholder=""
                                                  name="morning_opens_at"
                                                  onChange={this.handleWorkhoursChange.bind(
                                                    this,
                                                    "morning_opens_at"
                                                  )}
                                                  value={businessData.working_hours
                                                    ? businessData
                                                      .working_hours
                                                      .morning_opens_at
                                                    : ""}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    errors.working_hours_morning
                                                  }
                                                />
                                              </div>
                                              <div className="cusWidth">
                                              <label>Morning To</label>
                                              <input
                                                  disabled={
                                                    businessData.working_hours
                                                      .hours_24
                                                  }
                                                  type="time"
                                                  className="form-control"
                                                  placeholder=""
                                                  name="morning_close_at"
                                                  onChange={this.handleWorkhoursChange.bind(
                                                    this,
                                                    "morning_close_at"
                                                  )}
                                                  value={businessData.working_hours
                                                    ? businessData
                                                      .working_hours
                                                      .morning_close_at
                                                    : ""}
                                                />
                                              </div>
                                                
                                            </div>
                                            <div className="flex form-group">
                                             
                                              <div className="cusWidth">
                                              <label>Evening From</label>
                                                <input
                                                  disabled={
                                                    businessData.working_hours
                                                      .hours_24
                                                  }
                                                  type="time"
                                                  className="form-control"
                                                  placeholder=""
                                                  name="evening_opens_at"
                                                  onChange={this.handleWorkhoursChange.bind(
                                                    this,
                                                    "evening_opens_at"
                                                  )}
                                                  value={businessData.working_hours
                                                    ? businessData
                                                      .working_hours
                                                      .evening_opens_at
                                                    : ""}
                                                />
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    errors.working_hours_evening
                                                  }
                                                />
                                              </div>
                                           
                                              <div className="cusWidth">
                                              <label>Evening To</label>
                                              <input
                                                  disabled={
                                                    businessData.working_hours
                                                      .hours_24
                                                  }
                                                  type="time"
                                                  className="form-control"
                                                  placeholder=""
                                                  name="evening_close_at"
                                                  onChange={this.handleWorkhoursChange.bind(
                                                    this,
                                                    "evening_close_at"
                                                  )}
                                                  value={businessData.working_hours
                                                    ? businessData
                                                      .working_hours
                                                      .evening_close_at
                                                    : ""}
                                                />
                                              </div>
                                            </div>
                                            <div className="flex form-group float-right mg-r1">
                                              <div className="cusWidth ">
                                              <div className="square-switch">
                                                  <input
                                                    type="checkbox"
                                                    id="square-switch3ss"
                                                    switch="none"
                                                    onChange={this.handleWorkhoursChange.bind(
                                                      this,
                                                      "24Hours"
                                                    )}
                                                    checked={
                                                      businessData.working_hours
                                                        .hours_24
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="square-switch3ss"
                                                    data-on-label="24 Hours"
                                                    data-off-label="24 Hours"
                                                    className="dcte22 wid-100"
                                                  ></label>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                         
                                        </div>

                                        <div className="frllftfull">

                                          <div className="form-group frllft">
                                            <Label title="Speciality" isMandatory={true} />
                                          </div>
                                          <div className="form-group sform-control frlrit">
                                            {/* <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                            /> */}
                                         
                                         {/*   {(businessData.speciality.length>0)?"MultiSpeciality": 
                                          */}  
                                           <Select maxMenuHeight={120}
                                              className=" p-0"
                                              name="speciality"
                                              isMulti
                                              value={businessData.speciality}
                                              onChange={this.handleselectChange}
                                              options={specialities_array}
                                            />
                                            
                                            <SpanField
                                              classname="text-danger"
                                              title={
                                                errors.speciality
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                                          <div className="form-group frllft">
                                            <Label title="Facility" isMandatory={true} />
                                          </div>
                                          <div className="form-group sform-control frlrit">
                                            <Select maxMenuHeight={120}
                                              className="form-controlw w-100 p-0"
                                              name="facility"
                                              isMulti
                                              value={businessData.facility}
                                              onChange={this.handleselectChange}
                                              options={facilities_array}
                                            />
                                          </div>
                                        </div>
                                        <div className="frllftfull">
                        <div className="form-group frllft">
                        <Label title="Payment Type" isMandatory={true} />
                        </div>




                      {/*   <Fragment className="form-group frlrit">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">

                                <input
                                  checked={(businessData.payment_type ==="Online")?true:false}
                                  className="form-check-input"
                                  type="radio"
                                  id="Online"
                                 // value="Online"
                                  name="payment_type"
                                  selectedOptions={businessData.payment_type}
                                    value={businessData.payment_type}
                               //   selectedOption={this.state.payment_mode ==="Online"?true:false}
                                  onChange={this.handlePaymentSelect}


                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay online"
                                >
                                  Pay online
                                </label>

                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check fltx">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="Direct"
                                  name="payment_type"
                                  checked={(businessData.payment_type ==="Direct")?true:false}
                                 // value="Direct"
                                  selectedOptions={businessData.payment_type}
                                    value={businessData.payment_type}
                                  onChange={this.handlePaymentSelect}
                             //     selectedOption={this.state.payment_mode ==="Direct"?true:false}
                                  
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Pay at Clinic"
                                >
                                  Pay at Clinic
                                </label>
                              </div>
                            </div>
                          </div>
                        </Fragment> */}
 <CheckboxOrRadioGroup
                                              // title={'Which kinds of pets would you like to adopt?'}
                                              setName={"payment_type"}
                                              type={"radio"}
                                              controlFunc={this.handlePaymentSelect}
                                              options={paymenttype}
                                              selectedOptions={businessData.payment_type}
                                              value={businessData.payment_type}
                                              checked={businessData.payment_type==="Direct"?true:false}
                                            />
                                           
                      </div>
                      
                                  
                                      </div>

                                    
                                      <div className="col-sm-12 btncent">
                                        
                                        <div className="user-notification">
                                          <SpanField
                                            classname="text-success user-notification"
                                            title={formSuccess.message}
                                          />
                                        </div>
                                        <div className="form-group signupformbtn mb-0 Hpudatebtn">
                                          <button
                                            type="submit"
                                            onClick={this.updateProfile}
                                            className="signupbtn"
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-sm-6 pl0">
                          <div className="card">
                          <div class="card-header"> <div class="card-title">Payment Details</div> </div>
                          <div className="card-body">
                          {payment_details.length ? payment_details.map((el, i) => (
                                            <Fragment key={i}>
                                              
                                              {/* <div className="frllftfull  mt-10">
                                                {i === 0 ? (
                                                  <h5 className="regxs regxsd">
                                                    Payment Details
                                                    
                                                  </h5>
                                                ) : (
                                                  ""
                                                )}
                                                <hr className="mrgtp" />
                                               
                                              </div> */}
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Card Number</label>
                                                </div>
                                                <div className="form-group  frlrit">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    value={this.state.value}
                                                    // title={'Last Name'}
                                                    name={"credit_card"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "credit_card"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i]
                                                          .credit_card
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable}
                                                    max={16}
      
                                                  />
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].credit_card}
                                                  />
                                                </div>
                                              </div>
      
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Name in Card</label>
                                                </div>
                                                <div className="form-group frlrit">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"card_name"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "card_name"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i].card_name
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable}
                                                    max={50}
      
                                                  />
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].card_name}
                                                  />
                                                </div>
                                                
                                              </div>
      
                                              <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Expiration Date</label>
                                                </div>
                                                <div className="form-group frlrclfgf-1">
                                                  <MonthPick
                                                    defaultValue="MM"
                                                    year={
                                                      payment_details[i] ? payment_details[i].expiry_month : ""
                                                    }
                                                    selectedOption={
                                                      payment_details[i]
                                                        ? payment_details[i]
                                                          .expiry_month
                                                        : ""
                                                    }
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
      
                                                      "expiry_month"
                                                    )}
                                                    id="expiry_month"
                                                    name="expiry_month"
                                                  />
                                                  
                                                </div>
      
                                                {/* <div className="form-group frlrclfgf-1">
                                                  <label>Expiry Year</label>
                                                </div> */}
                                                <div className="form-group frlrclfgf-1">
                                                  <YearPick
                                                    defaultValue="YY"
                                                    startyear={currentYear}
                                                    endyear={currentYear + 20}
                                                    selectedOption={payment_details[i]? payment_details[i].expiry_year : "" }
                                                    controlFunc={this.handlePaymentDetailChange.bind(this,i,"expiry_year")}
                                                    id="expiry_year"
                                                    name="expiry_year"
                                                  />
                                                </div>
                                                <div className="form-group frllft">
                                                  <label className="ml-2">CVV</label>
                                                </div>
                                                <div className="form-group frlrit frlrclfgf float-right mg-tp3">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"cvv"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "cvv"
                                                    )}
                                                    content={
                                                      payment_details[i]
                                                        ? payment_details[i].cvv
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable}
                                                    max={3}
      
                                                  />
      
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].cvv}
                                                  />
                                                </div>
                                              </div>
      
                                              <div className="frllftfull">
                                              <div className="form-group frllft">
                                                  <label>Card Type</label>
                                                </div>
                                                <div className="form-group sform-control frlrit ">
                                                  <Select 
                                                  
                                                  //maxMenuHeight={120}
                                                    className="form-control p-0"
                                                    name="card_type"
                                                    value={
                                                      payment_details[i]
                                                        ? payment_details[i].card_type
                                                        : ""
                                                    }
                                                    onChange={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "card_type"
                                                    )}
                                                    options={card_type}
                                                  />
                                                </div>
                                                
                                              </div>
      
                                              <div className="form-group frllftfull">
                                              <div className="form-group frllft">
                                                  <label>
                                                    Netbanking/ Paytm / Paypal
                                                  </label>
                                                  {/* <label>
                                                    &nbsp;&nbsp; Netbanking
                                                  </label> */}
                                                </div>
                                                <div className="form-group frlrit">
                                                  <InputField
                                                    isMandatory={true}
                                                    inputType={"text"}
                                                    // title={'Last Name'}
                                                    name={"payment_option"}
                                                    controlFunc={this.handlePaymentDetailChange.bind(
                                                      this,
                                                      i,
                                                      "payment_option"
                                                    )}
                                                    content={
                                                      payment_details[i]
      
                                                        ? payment_details[i]
                                                          .payment_option
                                                        : ""
                                                    }
                                                    placeholder={""}
                                                    isvisible={this.state.isDisable}
                                                    max={50}
                                                  />
                                                  <SpanField
                                                    classname="text-danger"
                                                    title={payment_details_error[i].payment_option}
                                                  />
                                                </div>
                                              </div>
                                            </Fragment>
                                          )):""}
                          </div>
                         
                          </div>
                          <div className="card">
                          <div class="card-header"> <div class="card-title">Reviews</div> </div>
                            <div
                              className="card-body"

                            >
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="row reviewsdesign">
                                   
                                    <div className="col-sm-12">
                                      <div>
                                        <button>Friendliness</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.friendliness
                                              ? reviewinfo.friendliness
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="5px"
                                          numberOfStars={5}
                                          name="rating"
                                          starEmptyColor="#ffffff"
                                        />
                                      </div>
                                      <div>
                                        <button>Value for money</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.value_of_money
                                              ? reviewinfo.value_of_money
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="5px"
                                          numberOfStars={5}
                                          name="rating"
                                          starEmptyColor="#ffffff"
                                        />
                                      </div>
                                      <div>
                                        <button>Less wait time</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.less_wait_time
                                              ? reviewinfo.less_wait_time
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="5px"
                                          numberOfStars={5}
                                          name="rating"
                                          starEmptyColor="#ffffff"
                                        />
                                      </div>
                                      <div>
                                        {" "}
                                        <button>Best treatment </button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.best_treatment
                                              ? reviewinfo.best_treatment
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="5px"
                                          numberOfStars={5}
                                          name="rating"
                                          starEmptyColor="#ffffff"
                                        />
                                      </div>
                                      <div>
                                        <button>Easy explanation</button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.easy_explanation
                                              ? reviewinfo.easy_explanation
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="5px"
                                          numberOfStars={5}
                                          name="rating"
                                          starEmptyColor="#ffffff"
                                        />
                                      </div>
                                      <div>
                                        <button>Good facility </button>
                                        <StarRatings
                                          rating={
                                            reviewinfo.good_facility
                                              ? reviewinfo.good_facility
                                              : 0
                                          }
                                          starRatedColor="#ebb338"
                                          starDimension="15px"
                                          starSpacing="5px"
                                          numberOfStars={5}
                                          name="rating"
                                          starEmptyColor="#ffffff"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card">
                          <div class="card-header"> <div class="card-title">Comments</div> </div>
                          <div className="card-body">
                          <div className="row reviewsdesign">
                                 

                                    {commentsArr ? (
                                      commentsArr.map((element, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="col-sm-12 cmdpad"
                                          >
                                            <table className="width-100">
                                              <tbody>
                                                <tr>
                                                  <td style={{ width: "10%" }}>
                                                    <img
                                                      src={
                                                        element.avatar
                                                          ? element.avatar
                                                          : "http://klinicq.com/static/media/iconpatent.5ca3dcad.png"
                                                      }
                                                      className="riwwimg"
                                                    />{" "}
                                                  </td>
                                                  <td style={{ width: "70%" }}>
                                                    <h5 className="wwim">
                                                      {element.ReviewBy
                                                        ? element.ReviewBy
                                                        : ""}{" "}
                                                      <span
                                                        style={{
                                                          marginRight: "40",
                                                        }}
                                                      >
                                                        {" "}
                                                        {element.ReviewOn
                                                          ? element.ReviewOn
                                                          : ""}
                                                      </span>
                                                    </h5>
                                                    <p
                                                      style={{
                                                        marginBottom: "3px",
                                                      }}
                                                    >
                                                      <StarRatings
                                                        rating={
                                                          element.over_all_review
                                                            ? element.over_all_review
                                                            : 0
                                                        }
                                                        starRatedColor="#ebb338"
                                                        starDimension="15px"
                                                        starSpacing="3px"
                                                        numberOfStars={5}
                                                        name="rating"
                                                      />
                                                    </p>
                                                    <p>
                                                      {element.comments
                                                        ? element.comments
                                                        : ""}
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="col-sm-12">
                                        No comments found.
                                      </div>
                                    )}
                                  </div>
    
                          
                                  
                          </div>
                          </div>
                        </div>
                      </div>
                     
                  </div>
                  
                </div>
                
              </section>)}
              <Popup
                                      show={this.state.deletePopup}
                                      onHide={() =>
                                        this.setState({
                                          deletePopup: false,
                                        })
                                      }
                                      onConfirm={PopupConfirm}
                                      title={"Confirmation"}
                                      message={
                                        "Are you sure you want to delete your account?"
                                      }
                                    />
                                    
              <FooterSection />
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(BusinessProfile));
