import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/client/Header";
import Spinner from "../components/UI/Spinner";
import axios from "axios";
import { CONFIG } from "../config/data";
import { getPrescriptionHistory } from "../config/url.json";
import * as moment from "moment";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class Consultationsummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      records: [],
      with_in_week: [],
      with_in_30: [],
      with_in_3_months: [],
      with_in_6_months: [],
      with_in_year: [],
      with_in_2_years: [],
      selectedOption: ["with_in_week0"],
      error: "",
      show_prescriptions: true
    };
    this.columns = [
      {
        key: "medicine_description",
        text: "Medicine Description",
        className: "medicine_description",
        align: "left",
        sortable: true,
      },
      {
        key: "days",
        text: "Days",
        className: "days",
        align: "left",
        sortable: true,
      },
      {
        key: "before_food",
        text: "Before Food",
        className: "before_food",
        align: "left",
        sortable: true,
        cell: (record, i) => {
          return record.before_food === 1 ? (
            <div className="text-center">
              <i className="fa fa-check-circle grnbk" aria-hidden="true" />{" "}
            </div>
          ) : (
            ""
          );
        },
      },
      {
        key: "morning",
        text: "Morning",
        className: "morning",
        sortable: true,
        align: "left",
      },
      {
        key: "noon",
        text: "Noon",
        className: "noon",
        sortable: true,
        align: "left",
      },
      {
        key: "night",
        text: "Night",
        className: "night",
        sortable: true,
        align: "left",
      },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
  }

  componentDidMount() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const postData1 = { client_id: this.props.userdata.id };
    axios
      .post(getPrescriptionHistory, postData1, CONFIG)
      .then((res) => {
        let data = res.data.consultantHistory || res.data.consultantHistory;
        console.log(data);
        console.log(res.data);
        // if (res.data.status !== "1" || !data || !data[0]) {
        //   return;
        // }
        if (res.data.status === "1") {
          this.setState({
            with_in_week: data.with_in_week,
            with_in_30: data.with_in_30,
            with_in_3_months: data.with_in_3_months,
            with_in_6_months: data.with_in_6_months,
            with_in_year: data.with_in_year,
            with_in_2_years: data.with_in_2_years,
            records: data.with_in_week.length
              ? data.with_in_week[0].medicine_description
              : [],
            show_prescriptions: data.with_in_week.length
              ? data.with_in_week[0].show_prescriptions
              : true,
          });
        } else {
          this.setState({ error: res.data.message });
        }
      })
      .catch((e) => { });
      
  }
  handlePrescriptionChange = (data, dataindex, index, event) => {
    console.log(data);
    // console.log(dataindex);
    // console.log(index);
    // console.log(event);
    // console.log(event.target.checked);
    let {
      with_in_week,
      with_in_30,
      with_in_3_months,
      with_in_6_months,
      with_in_year,
      with_in_2_years,
      show_prescriptions,
      records,
    } = this.state;
    if (data === "with_in_week") {
      records = with_in_week[index].medicine_description;
      show_prescriptions = with_in_week[index].show_prescriptions;
    }

    if (data === "with_in_30") {
      records = with_in_30[index].medicine_description;
      show_prescriptions = with_in_30[index].show_prescriptions;
    }
    if (data === "with_in_3_months") {
      records = with_in_3_months[index].medicine_description;
      show_prescriptions = with_in_3_months[index].show_prescriptions;
    }
    if (data === "with_in_6_months") {
      records = with_in_6_months[index].medicine_description;
      show_prescriptions = with_in_6_months[index].show_prescriptions;
      console.log(show_prescriptions)
    }
    if (data === "with_in_year") {
      records = with_in_year[index].medicine_description;
      show_prescriptions = with_in_year[index].show_prescriptions;
    }
    if (data === "with_in_2_years") {
      records = with_in_2_years[index].medicine_description;
      show_prescriptions = with_in_2_years[index].show_prescriptions;
    }

    console.log(records);
    console.log(show_prescriptions);
    this.setState({
      selectedOption: event.target.checked ? [dataindex] : [],
      records: event.target.checked ? records : [],
      show_prescriptions: show_prescriptions
    });
  };

  render() {
    const {
      loading,
      with_in_week,
      with_in_30,
      with_in_3_months,
      with_in_6_months,
      with_in_year,
      with_in_2_years,
      selectedOption,
      show_prescriptions,
      error,
    } = this.state;
    console.log(with_in_week.length);
    return (
      <div id="layout-wrapper">
      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <section className="main-body hospital-profilegap">
              <div className="container-fluid">
                <div className="checkout-tabs">
                  <div className="row">
                    <div className="col-sm-2 pr0">
                      <SideBar />
                    </div>
                    <div className="col-sm-10 pl22">
                      <div className="row">
                        <div className="col-sm-9 pr0">
                          <div
                            className="hmeclr bgligtrn"
                            style={{ paddingBottom: "0px" }}
                          >
                            <h5 class="regxs">Prescription</h5>
                          </div>
                          <div>
                            <div className="card">
                              <div className="card-body ppb-6">
                                {error ? (
                                  <span className="text-danger">{error}</span>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="row">
                                      <div className="col-sm-12">{show_prescriptions ? (<ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />) : ""}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3 pl0">
                          <div className="hmeclr bgligtrn">
                            <h5 className="regxs">Prescription</h5>
                          </div>
                          <div className="card">
                            <div className="card-body b-page-2ds">
                              <div id="accordion">
                                <div>
                                  <a
                                    className="card-link"
                                    data-toggle="collapse"
                                    href="#s1"
                                  >
                                    <div className="prescriptionss1">
                                      With in a week
                                    </div>
                                  </a>
                                  <div
                                    id="s1"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body ese">
                                      {with_in_week.length
                                        ? with_in_week.map((history, index) => {
                                          return (
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={"with_in_week" + index}
                                                checked={
                                                  selectedOption.indexOf(
                                                    "with_in_week" + index
                                                  ) > -1
                                                }
                                                onChange={this.handlePrescriptionChange.bind(
                                                  this,
                                                  "with_in_week",
                                                  "with_in_week" + index,
                                                  index
                                                )}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={
                                                  "with_in_week" + index
                                                }
                                              >
                                                {moment(
                                                  history.created_at
                                                ).format("DD MMM")}
                                                -
                                                {
                                                  history.reason_for_visit[0]
                                                    .label
                                                }
                                              </label>
                                            </div>
                                          );
                                        })
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a
                                    className="card-link"
                                    data-toggle="collapse"
                                    href="#s2"
                                  >
                                    <div className="prescriptionss1">
                                      With in 30 days
                                    </div>
                                  </a>
                                  <div
                                    id="s2"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body ese">
                                      {with_in_30.length
                                        ? with_in_30.map((history, index) => {
                                          return (
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={"with_in_30" + index}
                                                checked={
                                                  selectedOption.indexOf(
                                                    "with_in_30" + index
                                                  ) > -1
                                                }
                                                onChange={this.handlePrescriptionChange.bind(
                                                  this,
                                                  "with_in_30",
                                                  "with_in_30" + index,
                                                  index
                                                )}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={"with_in_30" + index}
                                              >
                                                {moment(
                                                  history.created_at
                                                ).format("DD MMM")}
                                                -
                                                {
                                                  history.reason_for_visit[0]
                                                    .label
                                                }
                                              </label>
                                            </div>
                                          );
                                        })
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a
                                    className="card-link"
                                    data-toggle="collapse"
                                    href="#s3"
                                  >
                                    <div className="prescriptionss1">
                                      With in 3 months
                                    </div>
                                  </a>
                                  <div
                                    id="s3"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body ese">
                                      {with_in_3_months.length
                                        ? with_in_3_months.map(
                                          (history, index) => {
                                            return (
                                              <div className="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id={
                                                    "with_in_3_months" + index
                                                  }
                                                  checked={
                                                    selectedOption.indexOf(
                                                      "with_in_3_months" +
                                                      index
                                                    ) > -1
                                                  }
                                                  onChange={this.handlePrescriptionChange.bind(
                                                    this,
                                                    "with_in_3_months",
                                                    "with_in_3_months" +
                                                    index,
                                                    index
                                                  )}
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={
                                                    "with_in_3_months" + index
                                                  }
                                                >
                                                  {moment(
                                                    history.created_at
                                                  ).format("DD MMM")}
                                                  -
                                                  {
                                                    history
                                                      .reason_for_visit[0]
                                                      .label
                                                  }
                                                </label>
                                              </div>
                                            );
                                          }
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a
                                    className="card-link"
                                    data-toggle="collapse"
                                    href="#s4"
                                  >
                                    <div className="prescriptionss1">
                                      With in 6 months
                                    </div>
                                  </a>
                                  <div
                                    id="s4"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body ese">
                                      {with_in_6_months.length
                                        ? with_in_6_months.map(
                                          (history, index) => {
                                            return (
                                              <div className="card-body ese">
                                                <div className="custom-control custom-checkbox">
                                                  <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={
                                                      "with_in_6_months" +
                                                      index
                                                    }
                                                    checked={
                                                      selectedOption.indexOf(
                                                        "with_in_6_months" +
                                                        index
                                                      ) > -1
                                                    }
                                                    onChange={this.handlePrescriptionChange.bind(
                                                      this,
                                                      "with_in_6_months",
                                                      "with_in_6_months" +
                                                      index,
                                                      index
                                                    )}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={
                                                      "with_in_6_months" +
                                                      index
                                                    }
                                                  >
                                                    {moment(
                                                      history.created_at
                                                    ).format("DD MMM")}
                                                    -
                                                    {
                                                      history
                                                        .reason_for_visit[0]
                                                        .label
                                                    }
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a
                                    className="card-link"
                                    data-toggle="collapse"
                                    href="#s5"
                                  >
                                    <div className="prescriptionss1">
                                      With in a Year
                                    </div>
                                  </a>
                                  <div
                                    id="s5"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body ese">
                                      {with_in_year.length
                                        ? with_in_year.map((history, index) => {
                                          return (
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={"with_in_year" + index}
                                                checked={
                                                  selectedOption.indexOf(
                                                    "with_in_year" + index
                                                  ) > -1
                                                }
                                                onChange={this.handlePrescriptionChange.bind(
                                                  this,
                                                  "with_in_year",
                                                  "with_in_year" + index,
                                                  index
                                                )}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={
                                                  "with_in_year" + index
                                                }
                                              >
                                                {moment(
                                                  history.created_at
                                                ).format("DD MMM")}
                                                -
                                                {
                                                  history.reason_for_visit[0]
                                                    .label
                                                }
                                              </label>
                                            </div>
                                          );
                                        })
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a
                                    className="card-link"
                                    data-toggle="collapse"
                                    href="#s6"
                                  >
                                    <div className="prescriptionss1">
                                      With in 2 Year
                                    </div>
                                  </a>
                                  <div
                                    id="s6"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body ese">
                                      {with_in_2_years.length
                                        ? with_in_2_years.map(
                                          (history, index) => {
                                            return (
                                              <div className="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id={
                                                    "with_in_2_years" + index
                                                  }
                                                  checked={
                                                    selectedOption.indexOf(
                                                      "with_in_2_years" +
                                                      index
                                                    ) > -1
                                                  }
                                                  onChange={this.handlePrescriptionChange.bind(
                                                    this,
                                                    "with_in_2_years",
                                                    "with_in_2_years" + index,
                                                    index
                                                  )}
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={
                                                    "with_in_2_years" + index
                                                  }
                                                >
                                                  {moment(
                                                    history.created_at
                                                  ).format("DD MMM")}
                                                  -
                                                  {
                                                    history
                                                      .reason_for_visit[0]
                                                      .label
                                                  }
                                                </label>
                                              </div>
                                            );
                                          }
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Footerad /> */}
                    </div>
                  </div>
                </div>
              </div>

         
            </section>
    <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Consultationsummary);
