import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import BusinessSignup1 from "../components/container/BusinessSignup1";
import BusinessUserSignup1 from "../components/container/BusinessUserSignup1";
import ClientSignup1 from "../components/container/ClientSignup1";
import LogoSection from "../components/UI/LogoSection";
import FooterSection from "../components/UI/FooterSection";
import Heading4 from "../components/UI/Heading4";
import logo from '../assets/images/logo.png'

function mapStateToProps(state) {
  return {
    userdata: state.AuthReducer.user,
  };
}


class UpdateProfile extends Component {
  render() {
    
    return (
      <div id="layout-wrapper">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Start right Content here --> */}
        {/* <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="mainpage updateProfileMainpage">
          

            <section className="pagenav">
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-sm-12">
                    {this.props.userdata.user_type.toLowerCase() === "hospital" ||
                    this.props.userdata.user_type.toLowerCase() === "business" ? (
                      <Fragment>
                        <Heading4
                          classname="topbanner"
                          title="REGISTER HOSPITAL"
                        />
                        <a href="/">Home</a>{" "}
                        <i
                          className="fa fa-angle-double-right span"
                          aria-hidden="true"
                        ></i>{" "}
                        <p className="finelclr">
                          Register Hospital
                        </p>
                      </Fragment>
                    ) : this.props.userdata.user_type.toLowerCase() === "doctor" ||
                      this.props.userdata.user_type.toLowerCase() === "business_user" ? (
                      <Fragment>
                        <Heading4
                          classname="topbanner"
                          title="REGISTER DOCTOR"
                        />
                        <a href="/">Home</a>{" "}
                        <i
                          className="fa fa-angle-double-right span"
                          aria-hidden="true"
                        ></i>{" "}
                        <a className="finelclr">
                          Register DOCTOR
                        </a>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Heading4
                          classname="topbanner"
                          title="REGISTER CLIENT"
                        />
                        <a href="/">Home</a>{" "}
                        <i
                          className="fa fa-angle-double-right span"
                          aria-hidden="true"
                        ></i>{" "}
                        <a className="finelclr">
                          Register Client
                        </a>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="my-head">
              <div className="container-fluid">
              <div className="logocenter updateProfilelogo justify-content-center ml-0">
                <a href="/">
                  <img alt="LOGO" src={logo} />
                </a>
              </div>
              </div>
            </section>
            <section className="main-body pl-0 updatebg">
              <div className="container-fluid max-wid-85">
                <div className="checkout-tabs">
                  <div className="row">
                    {this.props.userdata.user_type.toLowerCase() === "hospital" ||
                    this.props.userdata.user_type.toLowerCase() === "business" ? (
                      <BusinessSignup1 userdata={this.props.userdata} />
                    ) : this.props.userdata.user_type.toLowerCase() === "doctor" ||
                      this.props.userdata.user_type.toLowerCase() === "business_user" ? (
                      <BusinessUserSignup1 userdata={this.props.userdata} />
                    ) : (
                      <ClientSignup1 userdata={this.props.userdata} />
                    )}
                  </div>
                </div>
                {/* <!-- end row --> */}
              </div>
              {/* <!-- container-fluid --> */}
            </section>
          </div>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(UpdateProfile);
