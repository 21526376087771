import React, { Component, Fragment } from "react";
import { Redirect} from "react-router-dom";
import { connect } from "react-redux";
// import Heading4 from '../../components/UI/Heading4';
import InputField from "../UI/InputField";
import ToggleButton from "../UI/ToggleButton";
import MonthPick from "../UI/MonthPick";
import ErrorAlert from "../UI/ErrorAlert";
import { withRouter } from "react-router-dom";
import DatePicker from "react-date-picker";
// import Button from '../UI/Button'
import axios from "axios";
import { updateClient, getClientById, updateavatar } from "../../config/url.json";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Label from "../UI/Label";
import Validation from "../../actions/Validation";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import CheckboxGroup from "../../components/UI/CheckboxGroup";
// import TimePicker from 'react-bootstrap-time-picker';
import SpanField from "../UI/SpanField";
import Select from "react-select";
import ModernDatepicker from "react-modern-datepicker";
import Button from "../UI/Button";
import YearPick from "../UI/YearPick";
import * as moment from "moment";
import { SOCIAL_HISTORY } from "../../constants/config/data";
import S3 from "react-aws-s3";
import {
  nationalityArray,
  blood_group,
  gender,
  language,
  relationship,
  employee_status,
  marital_status,
  card_type,
  AUTH_CONFIG,
  CONFIG,
  imageconfig
} from "../../config/data";
import { stringToLabelValue } from "../../constants/globalLevelFunctions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    access_token: state.AuthReducer.access_token,
    userdata: state.AuthReducer.user,
  };
}
const google = window.google;

let nationality = [];
nationalityArray.forEach((element) => {
  nationality.push({ value: element, label: element });
});
const currentYear = new Date().getFullYear();

class ClientSignup1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      image: "",
      clientData: {
        user_id: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        blood_group: "",
        gender: "",
        marital_status: "",
        phone_code: "",
        nationality: "",
        language: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        user_type: "patient",
        phone_number: "",
        emergency_contact: {
          first_name: "",
          last_name: "",
          relationship: "",
          phone_number: "",
        },
        vehicle_details: {
          vehicle_make: "",
          vehicle_model: "",
          vehicle_registration: "",
          year_of_make: "",
          vin_number: "",
        },
        payment_details: {
          credit_card: "",
          card_name: "",
          expiry_month: "",
          expiry_year: "",
          cvv: "",
          payment_option: "",
        },
        employment_details: {
          employer_name: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
          phone_number: "",
          employee_status: "",
        },
        insurance_details: {
          insurance_provider: "",
          policy_id_number: "",
          health_insurance_confirmation: "",
          patient_policy_holder: "",
        },
        medical_history: [],
        family_medical_history: [],
        social_habits: [],
      },
      family_medical_history_SelectedOptions: [],
      selectedSocialHabits: [],
      newselectedSocialHabits: [],
      family_details: [
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
      errors: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        blood_group: "",
        gender: "",
        marital_status: "",
        nationality: "",
        language: "",
        email: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        emergency_contact: {
          first_name: "",
          last_name: "",
          relationship: "",
          phone_number: "",
        },
        employment_details: {
          employer_name: "",
          address: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
          phone_number: "",
          employee_status: "",
        },
      },
      family_details_errors: [
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
      insurance_details_errors: [
        {
          insurance_provider: "",
          policy_id_number: "",

        }

      ],
    };
    // this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.autocomplete = null;
    this.empautocomplete = null;
    this.handleWorkhoursChange = this.handleWorkhoursChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleEmployeePlaceSelect = this.handleEmployeePlaceSelect.bind(this);

  }

  handleEmployeePlaceSelect() {
    let newplace = this.empautocomplete.getPlace();

    let { clientData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < newplace.address_components.length; i++) {
      var addressType = newplace.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = newplace.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1")
          clientData.employment_details.state = val;
        if (addressType === "locality")
          clientData.employment_details.city = val;
        if (addressType === "country")
          clientData.employment_details.country = val;

        clientData.employment_details.address = newplace.name;
        // if (addressType === "sublocality_level_1")
        //   clientData.employment_details.address2 = val;
        if (addressType === "postal_code")
          clientData.employment_details.zip_code = val;
      }
    }

    this.setState({
      clientData,
    });
  }
  handlePlaceSelect() {

    let place = this.autocomplete.getPlace();
    console.log(place);
    let { clientData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "administrative_area_level_1")
          clientData.state = val;
        if (addressType === "locality")
          clientData.city = val;
        if (addressType === "country") clientData.country = val;

        clientData.address1 = place.name;
        if (addressType === "sublocality_level_1") clientData.address2 = val;
        if (addressType === "postal_code") clientData.zip_code = val;
      }
    }

    this.setState({
      clientData,
    });
  }

  /* for education change */
  addFamilyDetail() {
    this.setState((prevState) => ({
      family_details: [
        ...prevState.family_details,
        {
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          relationship: "",
          blood_group: "",
          phone_number: "",
        },
      ],
    }));
  }

  removeFamilyDetail(i) {
    let family_details = [...this.state.family_details];
    family_details.splice(i, 1);
    this.setState({ family_details });
  }
  handleEmploymentDetails(field, value) {
    let data = this.state.clientData;
    let errors=this.state.errors;
    if (
      field === "country" ||
      field === "state" ||
      field === "employee_status" 
     
    ){
      data.employment_details[field] = value;
    }
      else if (field === "phone_number") {
   
         if ((value !== "" && value !== undefined) &&
           !isValidPhoneNumber(data.employment_details[field]) && value.length > 13) {
      
            errors.employment_details[field] = "Please enter valid employee Phone number.";
         
   
         } else {
   
          
           data.employment_details[field] = value;
           errors.employment_details[field] = "";
         }
        }
    this.setState({ clientData: data });
  }
  handleEmploymentDetailsInput = (e) => {

    let data = this.state.clientData.employment_details;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "employer_name" ||
      name === "address" ||
      name === "city" ||
      name === "zip_code"
    ) {
      this.validateMaxLengthemploymentdetails(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ employment_details: data });


  };
  validateMaxLengthemploymentdetails(name, value, max) {
    let data = this.state.clientData.employment_details;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ employment_details: data, errors: errors });
  }
  handleParentMedicalHistory = (e) => {
    let data = this.state.family_medical_history_SelectedOptions;
    let newSelection = e.target.value;

    if (data.includes(newSelection)) {
      data.splice(data.indexOf(newSelection), 1);
    } else {
      data.push(newSelection);
    }

    this.setState({ family_medical_history_SelectedOptions: data });
  };

  handleSocialHabitSelection = (e) => {
    let data = this.state.selectedSocialHabits;
    let newSelection = e.target.value;
    let isExist = false;

    if (data != null) {
      // prepopulate existing values
      if (data.includes(newSelection)) {
        data.splice(data.indexOf(newSelection), 1);
      } else {
        for (let i = 0; i < data.length; i++) {
          if (data[i].includes(newSelection.split("|").shift())) {
            data[i] = newSelection;
            isExist = true;
            break;
          }
        }
        if (!isExist) data.push(newSelection);
      }
      this.setState({ selectedSocialHabits: data });
    } else {
      //new signup
      let newSocialHabits = this.state.newselectedSocialHabits;
      newSelection.split("|").shift();
      newSocialHabits.push(newSelection);
      this.setState({ newselectedSocialHabits: newSocialHabits });
      this.setState({ selectedSocialHabits: newSocialHabits });
    }
  };

  handleFamilyMedicalHistory = (e) => {
    let errors = this.state.errors;
    const newSelection = e.target.value;
    let newSelectionArray;
    let data = this.state.clientData;
    if (data.family_medical_history.indexOf(newSelection) > -1) {
      newSelectionArray = data.family_medical_history.filter(
        (s) => s !== newSelection
      );
    } else {
      newSelectionArray = [...data.family_medical_history, newSelection];
    }

    data.family_medical_history = newSelectionArray;
    this.setState({ clientData: data, errors });
  };
  handleMedicalHistory = (e) => {
    let errors = this.state.errors;
    const newSelection = e.target.value;
    let newSelectionArray;
    let data = this.state.clientData;
    if (data.medical_history.indexOf(newSelection) > -1) {
      newSelectionArray = data.medical_history.filter(
        (s) => s !== newSelection
      );
    } else {
      newSelectionArray = [...data.medical_history, newSelection];
    }

    data.medical_history = newSelectionArray;
    this.setState({ clientData: data, errors });
  };

  handleInsuranceDetails(field, value) {
    let errors = this.state.errors;
    let data = this.state.clientData;
    if(field==="insurance_provider"||field==="policy_id_number")
    {

      if(!data.insurance_details[field].match(/^[A-Za-z0-9]+$/)){
       errors.insurance_details[field]="Enter a valid insurance detail.";
    }else
    {
     data.insurance_details[field]=value;
     errors.insurance_details[field]="";
                     
    }    
    
   }
    if (
      field === "health_insurance_confirmation" ||
      field === "patient_policy_holder"
    ) {
      data.insurance_details[field] =
        value.target.checked === true ? "Yes" : "No";
    } else {
      data.insurance_details[field] = value.target.value;
    }

    this.setState({ clientData: data, errors });
  }
  handleInsuranceDetailsInput = (e) => {
    let errors = this.state.errors;
    let data = this.state.clientData.insurance_details;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "insurance_provider" ||
      name === "policy_id_number"
    ) {
      this.validateMaxLengthinsurancedetails(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ insurance_details: data, errors });


  };
  validateMaxLengthinsurancedetails(name, value, max) {
    let data = this.state.clientData.insurance_details;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ insurance_details: data, errors });
  }

  handelVehicleDetails(field, value) {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data.vehicle_details[field] = value.target.value;
    this.setState({ clientData: data, errors });
  }
  handelPaymentDetails(field, value) {
    let errors = this.state.errors;
    let data = this.state.clientData;
    if (
      field === "card_type" ||
      field === "expiry_month" ||
      field === "expiry_year"
    ) {
      data.payment_details[field] = value;
    } else {
      data.payment_details[field] = value.target.value;
    }
    this.setState({ clientData: data, errors });
  }
  handelEmergencyContact(field, value) {
    let errors = this.state.errors;
    let data = this.state.clientData;
    if (field === "relationship")
    {
      data.emergency_contact[field] = value;
    }
      else if (field === "phone_number")
      {
    // console.log(value);

      if ((value !== "" && value !== undefined) &&
        !isValidPhoneNumber(data.emergency_contact[field]) && value.length > 13) {
    //    console.log(value);
         errors.emergency_contact[field] = "Please enter a valid Phone number.";
        //const value1 = value.slice(0, 13);

        //data.emergency_contact[field] = value1;

      } else {

        data.emergency_contact[field] = value;
        errors.emergency_contact[field] = "";
       
      }

    }
    this.setState({ clientData: data, errors });
  }
  handleEmergencyContactInput = (e) => {
    let errors = this.state.errors;
    let data = this.state.clientData.emergency_contact;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "first_name" ||
      name === "last_name"
    ) {
      this.validateMaxLengthemergencycontact(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ emergency_contact: data, errors });


  };
  validateMaxLengthemergencycontact(name, value, max) {
    let data = this.state.clientData.emergency_contact;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ emergency_contact: data, errors });
  }


  handleFamilyDetailChange(i, value, field) {
    let fields = this.state.family_details;
    // let errors = this.state.educationerror;

    if (value === "first_name") {
      fields[i].first_name = field.target.value;
      // errors[i].year_month_of_completion = '';
    } else if (value === "last_name") {
      fields[i].last_name = field.target.value;
      // errors[i].register_id = '';
    } else if (value === "date_of_birth") {
      fields[i].date_of_birth = field;
      // errors[i].education = '';
    } else if (value === "gender") {
      fields[i].gender = field;
      // errors[i].specialization = '';
    } else if (value === "relationship") {
      fields[i].relationship = field;
      // errors[i].university = '';
    } else if (value === "blood_group") {
      fields[i].blood_group = field;
    } else if (value === "phone_number") {
      fields[i].phone_number = field;
    }

    this.setState({
      family_details: fields,
    });
  }

  handleFamilyDetailChangeInput = (e) => {
    let errors = this.state.errors;
    let data = this.state.family_details;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "first_name" ||
      name === "last_name"
    ) {
      this.validateMaxLengthfamilydetails(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ family_details: data, errors });


  };
  validateMaxLengthfamilydetails(name, value, max) {
    let data = this.state.family_details;
    let errors = this.state.family_details_errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ family_details: data, errors });
  }
  handleDOBChange = (date) => {
    console.log(date);

    if (date !== "") {
      let errors = this.state.errors;
      let clientData = this.state.clientData;
      clientData.date_of_birth = date;

      this.setState({ clientData: clientData });
      errors.date_of_birth = "";
      this.setState({ errors });
    }

  };
  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);

    this.empautocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address"),
      {}
    );

    this.empautocomplete.addListener("place_changed", this.handleEmployeePlaceSelect);

    window.setTimeout(() => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      axios
        .post(getClientById, { client_id: this.props.userdata.id }, AUTH_CONFIG)
        .then((response) => {
          if (response.data.status === "1") {
            let data = response.data.data;
            console.log(data.date_of_birth);

            let family_details = response.data.data.family_details;
            if (family_details == null) {
              family_details = [
                {
                  first_name: "",
                  last_name: "",
                  date_of_birth: "",
                  gender: "",
                  relationship: "",
                  blood_group: "",
                  phone_number: "",
                },
              ];
            } else {
              if (family_details.length)
                family_details[0].date_of_birth = moment(
                  family_details[0].date_of_birth,
                  moment.defaultFormat
                ).toDate();
            }

            this.setState({ family_details: family_details });
            console.log(data.payment_details)
            if (
              "null" === data.payment_details ||
              null == data.payment_details
            ) {
              data.payment_details = {
                credit_card: "",
                card_name: "",
                expiry_month: "",
                expiry_year: "",
                cvv: "",
                payment_option: "",
              };
            } else {
              // data.payment_details = data.payment_details;
            }
            if (
              "null" === data.vehicle_details ||
              null == data.vehicle_details
            ) {
              data.vehicle_details = {
                vehicle_make: "",
                vehicle_model: "",
                vehicle_registration: "",
                year_of_make: "",
                vin_number: "",
              };
            } else {
              data.vehicle_details = JSON.parse(data.vehicle_details);
            }
            if (data.emergency_contact == null) {
              data.emergency_contact = {
                first_name: "",
                last_name: "",
                relationship: "",
                phone_number: "",
              };
            }
            if (data.employment_details == null) {
              data.employment_details = {
                employer_name: "",
                address: "",
                city: "",
                country: "",
                state: "",
                zip_code: "",
                phone_number: "",
                employee_status: "",
              };
            }
            if (data.insurance_details === null || data.insurance_details === "") {
              data.insurance_details = {
                insurance_provider: "",
                policy_id_number: "",
                health_insurance_confirmation: "",
                patient_policy_holder: "",
              };
            }
            console.log(data.date_of_birth);

            const yourDate = data.date_of_birth;

            data.date_of_birth = data.date_of_birth ? moment(yourDate, moment.defaultFormat).toDate() : "";
            //moment(yourDate).format('DD-MM-YYYY').toDate();
            //
            this.handleDOBChange(data.date_of_birth);

            if (data.family_medical_history == null) {
              data.family_medical_history = [];
            } else {
              this.setState({
                family_medical_history_SelectedOptions: this.getInitalLoadFamilyMedicalHistory(
                  data
                ),
              });
            }

            if ("null" === data.social_habits) {
              this.setState({ selectedSocialHabits: [] });
            } else {
              this.setState({
                selectedSocialHabits: data.social_habits,
              });
            }

            if (data.medical_history == null) {
              data.medical_history = [];
            }

            let convertedblood = [];
            if (data.blood_group != null) {
              convertedblood = {
                label: data.blood_group,
                value: data.blood_group,
              };
            }

            let convertedgender = [];
            if (data.gender != null) {
              convertedgender = {
                label: data.gender,
                value: data.gender,
              };
            }
            let convertedmarital_status = [];
            if (data.marital_status != null) {
              convertedmarital_status =
              {
                label: data.marital_status,
                value: data.marital_status,
              };

            }

            data.language = stringToLabelValue(data.language);

            console.log(data);
            data.phone_number = data.phone_number ? data.phone_code.concat(data.phone_number) : "";



            console.log(data);
            let convertednationality = [];
            if (data.nationality != null) {
              convertednationality = {
                label: data.nationality,
                value: data.nationality,
              };
            }
            data.nationality = convertednationality;
            data.gender = convertedgender;
            data.blood_group = convertedblood;
            data.marital_status = convertedmarital_status;
            // data.first_name = response.data.data;
            console.log(data);

            this.setState({
              clientData: data,
              // family_details: JSON.parse(response.data.data.family_details),
            });
          } else {
            this.setState({ error: response.data.message });
          }
        })
        .catch((e) => {
          console.log(e)
          console.log("business user getbyid failed");
        });
    }, 1000);
  }
  validateMaxLength(name, value, max) {
    let data = this.state.clientData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ clientData: data, errors: errors });
  }
  handleInput = (e) => {

    let data = this.state.clientData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    let { errors } = this.state;

    if (
      name === "first_name" ||
      name === "last_name" ||
      name === "email" ||
      name === "city" ||
      name === "zip_code" ||
      name === "phone_number" ||
      name === "address1" ||
      name === "address2"
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ clientData: data });
    errors[name] = "";
    errors.address1 = "";
    errors.city = "";
    errors.state = "";
    errors.country = "";
    errors.zip_code = "";
    this.setState({ errors });
  };
  /* for country detail change */
  selectCountry(val) {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data.country = val;
    this.setState({ clientData: data });
    errors.country = "";
    this.setState({ errors });
  }
  /* for state detail change */

  selectState(val) {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data.state = val;
    this.setState({ clientData: data });
    errors.state = "";
    this.setState({ errors });
  }
  handlePhoneInput = (event) => {
    let errors = this.state.errors;
    let clientData = this.state.clientData;
    clientData.phone_number = event;
    this.setState({ clientData });
    errors.phone_number = "";
    this.setState({ errors });
  };
  handleSechduleType = (e) => {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data.schedule_type = [e.target.value];
    this.setState({ clientData: data });
    errors.schedule_type = "";
    this.setState({ errors });
  };
  handleWorkingDays = (e) => {
    let errors = this.state.errors;
    const newSelection = e.target.value;
    let newSelectionArray;
    let data = this.state.clientData;
    if (data.working_days.indexOf(newSelection) > -1) {
      newSelectionArray = data.working_days.filter((s) => s !== newSelection);
    } else {
      newSelectionArray = [...data.working_days, newSelection];
    }

    data.working_days = newSelectionArray;
    if (data.working_days.length > 0) errors.working_days = "";

    this.setState({ clientData: data });

    this.setState({ errors });
  };
  /* for work detail change */
  handleWorkhoursChange(value, field) {
    let errors = this.state.errors;
    let fields = this.state.clientData;

    if (value === "morning_opens_at") {
      fields.working_hours.morning_opens_at = field;
    } else if (value === "morning_close_at") {
      fields.working_hours.morning_close_at = field;
    } else if (value === "evening_opens_at") {
      fields.working_hours.evening_opens_at = field;
    } else if (value === "evening_close_at") {
      fields.working_hours.evening_close_at = field;
    }

    this.setState({ clientData: fields });
    errors.working_hours = "";
    this.setState({ errors });
  }

  handleselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data[e.name] = selectedOptions;
    this.setState({ clientData: data });
    errors.language = "";
    this.setState({ errors: errors });
  };

  handlebloodgroupselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data[e.name] = selectedOptions;

    this.setState({ clientData: data });
    errors.blood_group = "";
    this.setState({ errors });
  };

  handlegenderselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data[e.name] = selectedOptions;

    this.setState({ clientData: data });
    errors.gender = "";
    this.setState({ errors });
  };
  handlenationalityselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data[e.name] = selectedOptions;

    this.setState({ clientData: data });
    errors.nationality = "";
    this.setState({ errors });
  };


  handlemaritalstatusselectChange = (selectedOptions, e) => {
    let errors = this.state.errors;
    let data = this.state.clientData;
    data[e.name] = selectedOptions;

    this.setState({ clientData: data });
    errors.marital_status = "";
    this.setState({ errors });
  };

  /* remove unwanted pipe sign and F-Father & M-mother wordings */
  getFamilyMedicalHistoryDetails = () => {
    let selectedFamilyDiseases = this.state
      .family_medical_history_SelectedOptions;

    let fatherDiseases = [];
    let motherDiseases = [];

    selectedFamilyDiseases.forEach((element) => {
      if (element.includes("F")) {
        fatherDiseases.push(parseInt(element.replace("|F", "")));
      } else if (element.includes("M")) {
        motherDiseases.push(parseInt(element.replace("|M", "")));
      }
    });

    let familyHistoryDiseases = [
      { relationship: "Father", disease: fatherDiseases },
      { relationship: "Mother", disease: motherDiseases },
    ];

    return familyHistoryDiseases;
  };

  getInitalLoadFamilyMedicalHistory = (data) => {
    let FamilyhistoryData = [];
    if (data.family_medical_history !== "" &&
      data.family_medical_history != null && !data.family_medical_history.length) {
      data.family_medical_history.map((element) => {
        if ("Father" === element.relationship) {
          element.disease.forEach((element) => {
            FamilyhistoryData.push(element + "|F");
          });
        } else if ("Mother" === element.relationship) {
          element.disease.forEach((element) => {
            FamilyhistoryData.push(element + "|M");
          });
        }
      });
    }
    return FamilyhistoryData;
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    let { clientData, errors, family_details } = this.state;
    clientData.family_details = family_details;
    clientData.user_type = "patient";

    errors = Validation(clientData, errors);
    console.log(errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      // LoginApi(ClientData);
      let data = {};
      data.blood_group = clientData.blood_group.label;
      data.gender = clientData.gender.label;
      data.marital_status = clientData.marital_status.label;

      let lang = [];

      if (clientData.language) {
        clientData.language.forEach((element) => lang.push(element.value));
      }
      data.language = lang.toString();

      data.nationality = clientData.nationality.label;
      data.first_name = clientData.first_name;
      data.last_name = clientData.last_name;
      data.address1 = clientData.address1;
      data.address2 = clientData.address2;
      data.city = clientData.city;
      data.country = clientData.country;
      data.state = clientData.state;
      data.zip_code = clientData.zip_code;
      const offsetDate = new Date(clientData.date_of_birth.getTime() - (clientData.date_of_birth.getTimezoneOffset() * 60000));
      clientData.date_of_birth = offsetDate;
    
      data.date_of_birth = offsetDate;
      // moment(clientData.date_of_birth).format('DD-MM-YYYY').toString()
      data.phone_number = clientData.phone_number;
      data.email = clientData.email;
      data.user_id = clientData.user_id;
      data.emergency_contact = clientData.emergency_contact;

      data.vehicle_details = clientData.vehicle_details;
      data.employment_details = clientData.employment_details;
      data.insurance_details = clientData.insurance_details;
      data.medical_history = clientData.medical_history;

      //get parent object for family medical history
      data.family_medical_history = this.getFamilyMedicalHistoryDetails();
      data.social_habits = this.state.selectedSocialHabits;
      data.payment_details = clientData.payment_details;
      data.family_details = clientData.family_details;
      // data.phone_number = data.phone_number.replace(data.phone_code, '');
      data.phone_code = "+" + parsePhoneNumber(clientData.phone_number).countryCallingCode;
      data.phone_number = parsePhoneNumber(clientData.phone_number).nationalNumber;


      try {
        const result = axios.put(updateClient, data, AUTH_CONFIG);
        const p = Promise.resolve(result);
        p.then((value) => {
          if (value.data.status === "1") {
            toast.success("SUCCESS!! Your Registration is Complete. ", {
              className: "toast-success",
              state: { showToast: true }
            });
            // this.fileUpload(this.state.file).then((response)=>{
              // console.log(response.data);

              window.location.replace("/dashboard");

            // })
            // this.props.history.push("/dashboard");
          }
          if (value.data.status === "0") {
            data.phone_number = data.phone_code.concat(data.phone_number);

            this.setState({ error: value.data.message,clientData:data });
          }
        });
        // dispatch(
        //     addUserDeails(result.data)
        // )
      } catch (e) {
        // dispatch(
        // addError(e.message)
        // )
      }
    } else {
    }
  };
  onUploadChange = (event) => {
    // this.setState({file:e.target.files[0]});
    let file = event.target.files[0];
    let newfileName = event.target.files[0].name;

    // console.log(imageconfig);
    imageconfig.dirName = 'client/' + this.props.userdata.id
    console.log(imageconfig);

    const reacts3client = new S3(imageconfig);
    console.log(reacts3client)
    reacts3client.uploadFile(file, newfileName).then(data => {
      console.log(data)
      if (data.status === 204) {
        console.log('success');
        let resultdata = {
          'avatar': data.location,
          'user_id': this.props.userdata.id,
          'user_type': "client"
        }
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        axios.post(updateavatar, resultdata, CONFIG).then(() => {
          this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
        })
      } else {
        console.log('error');
        this.setState({ profile_img: URL.createObjectURL(file), addPopup: false });
      }
    });
  }
  // fileUpload(file){
  //   console.log(file);
  //   if(file){
  //     const data = new FormData();
  //     // data.append("title", title);
  //     data.append("file", file);
  //     // data.append("desc", desc);
  //     axios.defaults.headers.common[
  //       "Authorization"
  //     ] = `Bearer ${this.props.access_token}`;
  //     axios.post(uploadImage+'?user_id='+this.props.userdata.id,data, CONFIG).then((result)=>{
  //       console.log(result)
  //     })    
  //   }

  // }



  render() {
    const {
      clientData,
      errors,
      family_details,
      family_details_errors,
      insurance_details_errors,
      error,
      family_medical_history_SelectedOptions,
      selectedSocialHabits,
    } = this.state;

    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    let disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];
    let disease = [];
    disease_array.forEach((element) => {
      disease.push(element.disease_name);
    });
    disease_array = disease;
    let parent_disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];

    let specialities = [];
    specialities_array.forEach((element) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;

    let facilities_array = this.props.appdata
      ? this.props.appdata[0].facilities
      : [];
    let facilities = [];
    facilities_array.forEach((element) => {
      facilities.push({ value: element.id, label: element.facility_name });
    });
    facilities_array = facilities;
    return (
      <form className="custom-validation" encType="multipart/form-data" onSubmit={this.handleFormSubmit}>
        {error ? <ErrorAlert message={error} /> : null}

        <div className="col-lg-12">
        <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={8000} showToast={true} closeOnClick={false} pauseOnHover />

          <div className="card  bghome ptbac1">
            <div className="card-body pd-0">
           
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <h4 className="card-title">PERSONAL DETAILS</h4>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="First name" isMandatory={true} />
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          name={"first_name"}
                          readOnly={true}
                          controlFunc={this.handleInput}
                          content={
                            clientData.first_name ? clientData.first_name : ""
                          }
                          placeholder={""}
                          max={50}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.first_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Last name"}
                          name={"last_name"}
                          readOnly={true}
                          max={50}
                          controlFunc={this.handleInput}
                          content={
                            clientData.last_name ? clientData.last_name : ""
                          }
                          placeholder={""}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.last_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">

                      <div className="form-group">
                        <label className="row">Profile picture</label>
                        <input type="file" accept="image/*" name="uploadedimage" onChange={this.onUploadChange} />
                      </div>

                    </div>

                    <div className="col-sm-3">
                      <div className="form-group datepickerdiv">
                        <Label title="DOB" isMandatory={true} />

                        <DatePicker
                          className="form-control"
                          disabled={true}
                          value={clientData.date_of_birth}
                          format="dd-MM-yyyy"
                          onChange={(date) => this.handleDOBChange(date)}
                          yearPlaceholder='YYYY'
                          monthPlaceholder='MM'
                          dayPlaceholder='DD'
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.date_of_birth}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          id="address1"
                          inputType={"text"}
                          title={"Address 1"}
                          name={"address1"}
                          controlFunc={this.handleInput}
                          content={
                            clientData.address1 ? clientData.address1 : ""
                          }
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address1}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <InputField
                          // isMandatory={true}
                          inputType={"text"}
                          title={"Address 2"}
                          name={"address2"}
                          controlFunc={this.handleInput}
                          content={
                            clientData.address2 ? clientData.address2 : ""
                          }
                          placeholder={""}
                          max={100}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.address2}
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-3">
                                            <div className="form-group">
                                                <InputField
                                                    isMandatory={true}
                                                    inputType={'text'}
                                                    title={'City'}
                                                    name={'city'}
                                                    controlFunc={this.handleInput}
                                                    content={clientData.city}
                                                    placeholder={''} />
                                                <SpanField classname="text-danger" title={errors.city} />
                                            </div>
                                        </div> */}
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"City"}
                          name={"city"}
                          max={20}
                          controlFunc={this.handleInput}
                          content={clientData.city ? clientData.city : ""}
                          placeholder={""}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.city}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Country" isMandatory={true} />

                        <CountryDropdown
                          placeholder="Country"
                          name="country"
                          value={clientData.country || ""}
                          className="form-control"
                          onChange={(val) => this.selectCountry(val)}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.country}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="State" isMandatory={true} />

                        <RegionDropdown
                          name="state"
                          defaultOptionLabel="Select State"
                          country={clientData.country}
                          value={clientData.state || ""}
                          className="form-control"
                          onChange={(val) => this.selectState(val)}
                          placeholder="State"
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.state}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                          isMandatory={true}
                          inputType={"text"}
                          title={"Zip/Pincode"}
                          name={"zip_code"}
                          max={10}
                          controlFunc={this.handleInput}
                          content={clientData.zip_code}
                          placeholder={""}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.zip_code}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <Label title="Phone number" isMandatory={true} />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-control">
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              disabled={true}
                              value={clientData.phone_number}
                              onChange={this.handlePhoneInput}
                              error={
                                clientData.phone_number
                                  ? isValidPhoneNumber(clientData.phone_number)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />

                            <SpanField
                              classname="text-danger"
                              title={errors.phone_number}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-3">
                                            <div className="form-group mb-0">
                                                <label>Primary mobile number*</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-4">
                                                    <div className="form-group">
                                                        <select className="form-control">
                                                            <option>+91</option>
                                                            <option>+91</option>
                                                            <option>+91</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xs-8">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="col-sm-3">
                      <div className="form-group">
                        <InputField
                           isMandatory={true}
                          inputType={"text"}
                          title={"Email"}
                          name={"email"}
                          readOnly={true}
                          max={50}
                          controlFunc={this.handleInput}
                          content={clientData.email}
                          placeholder={""}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.email}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group sform-control">
                        <Label title="Blood group" isMandatory={true} />

                        {/* <select className="form-control">
                                                    <option>A+ve</option>
                                                    <option>A+ve</option>
                                                </select> */}
                        <Select
                          maxMenuHeight={120}
                          className="form-control p-0"
                          name="blood_group"
                          value={clientData.blood_group}
                          onChange={this.handlebloodgroupselectChange}
                          options={blood_group}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.blood_group}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group sform-control">
                        <Label title="Sex" isMandatory={true} />

                        {/* <select className="form-control">
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select> */}
                        <Select
                          maxMenuHeight={120}
                          className="form-control p-0"
                          name="gender"
                          value={clientData.gender}
                          onChange={this.handlegenderselectChange}
                          options={gender}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.gender}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Marital status" isMandatory={true} />

                        {/* <select className="form-control">
                                                    <option>Married</option>
                                                    <option>Married</option>
                                                </select> */}
                        <Select
                          maxMenuHeight={120}
                          className="p-0"
                          name="marital_status"
                          value={clientData.marital_status}
                          onChange={this.handlemaritalstatusselectChange}
                          options={marital_status}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.marital_status}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Nationality" isMandatory={true} />

                        {/* <select className="form-control">
                                                    <option>Indian</option>
                                                    <option>Indian</option>
                                                </select> */}
                        <Select
                          maxMenuHeight={120}
                          className="p-0"
                          name="nationality"
                          value={clientData.nationality}
                          onChange={this.handlenationalityselectChange}
                          options={nationality}
                        />
                        <SpanField
                          classname="text-danger"
                          title={errors.nationality}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <Label title="Languages" isMandatory={false} />

                      <div className="mobiletop">
                        <div className="position-relative">
                          <div className="input-group sform-control selectclas">
                            {/* <div className="input-group-prepend">
                              <span className="input-group-text inpico">
                                <i className="fas fa-search"></i>
                              </span>
                            </div> */}
                            {/* <input type="text" className="form-control" placeholder="" /> */}
                            <Select
                              maxMenuHeight={120}
                              className="form-control p-0 "
                              isMulti
                              name="language"
                              value={clientData.language}
                              onChange={this.handleselectChange}
                              options={language}
                            />
                            <SpanField
                              classname="text-danger"
                              title={errors.language}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formlayout">
                      <div className="row">
                        {family_details.map((family_details, i) => (
                          <Fragment key={i}>
                            <div className="col-sm-12">
                              {i === 0 ? (
                                <h5 className="txtss mt-0 mb-15">
                                  Family details
                                  <button
                                    type="button"
                                    onClick={this.addFamilyDetail.bind(this)}
                                    className="plusbtn"
                                  >
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </h5>
                              ) : (
                                ""
                              )}
                              <hr className="mrgtp" />
                              {i !== 0 ? (
                                <button
                                  type="button"
                                  onClick={this.removeFamilyDetail.bind(this)}
                                  className="plusbtn"
                                >
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <Label
                                      title="First name"
                                      isMandatory={false}
                                    />

                                    <InputField
                                      isMandatory={true}
                                      inputType={"text"}
                                      // title={'First name'}
                                      name={"first_name"}
                                      controlFunc={this.handleFamilyDetailChange.bind(
                                        this,
                                        i,
                                        "first_name"
                                      )}
                                      content={family_details.first_name}
                                      placeholder={""}
                                      max={50}
                                    />
                                    <SpanField
                                      classname="text-danger"
                                      title={family_details_errors.first_name}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <Label
                                      title="Last name"
                                      isMandatory={false}
                                    />

                                    <InputField
                                      isMandatory={true}
                                      inputType={"text"}
                                      // title={'Last Name'}
                                      name={"last_name"}
                                      controlFunc={this.handleFamilyDetailChange.bind(
                                        this,
                                        i,
                                        "last_name"
                                      )}
                                      content={family_details.last_name}
                                      placeholder={""}
                                      max={50}
                                    />
                                    <SpanField
                                      classname="text-danger"
                                      title={family_details_errors.last_name}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group datepickerdiv">
                                    <Label
                                      title="Date of birth"
                                      isMandatory={false}
                                    />
                                    {/* <input type="date" className="form-control" placeholder="" /> */}

                                    <DatePicker
                                      className="form-control"
                                      value={family_details.date_of_birth}
                                      format="dd-MM-yyyy"
                                      onChange={(date) =>
                                        this.handleFamilyDetailChange(
                                          i,
                                          "date_of_birth",
                                          date
                                        )
                                      }
                                      yearPlaceholder='YYYY'
                                      monthPlaceholder='MM'
                                      dayPlaceholder='DD'
                                    />
                                    {/* <SpanField classname="text-danger" title={errors.date_of_birth} /> */}
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <Label title="Sex" isMandatory={false} />
                                    {/* <select className="form-control">
                                                                                <option>Female</option>
                                                                                <option>Female</option>
                                                                            </select> */}
                                    <Select
                                      maxMenuHeight={120}
                                      className="form-control p-0"
                                      name="gender"
                                      value={family_details.gender}
                                      onChange={this.handleFamilyDetailChange.bind(
                                        this,
                                        i,
                                        "gender"
                                      )}
                                      options={gender}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group sform-control">
                                    <Label
                                      title="Relationship"
                                      isMandatory={false}
                                    />
                                    {/* <select className="form-control">
                                                                                <option>Spouse</option>
                                                                                <option>Spouse</option>
                                                                            </select> */}
                                    <Select
                                      className="form-control p-0"
                                      name="relationship"
                                      value={family_details.relationship}
                                      onChange={this.handleFamilyDetailChange.bind(
                                        this,
                                        i,
                                        "relationship"
                                      )}
                                      options={relationship}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <Label
                                      title="Blood group"
                                      isMandatory={false}
                                    />

                                    {/* <select className="form-control">
                                                                                <option>A+ve</option>
                                                                                <option>A+ve</option>
                                                                                <option>A+ve</option>
                                                                            </select> */}
                                    <Select
                                      maxMenuHeight={120}
                                      className="form-control p-0"
                                      name="blood_group"
                                      value={family_details.blood_group}
                                      onChange={this.handleFamilyDetailChange.bind(
                                        this,
                                        i,
                                        "blood_group"
                                      )}
                                      options={blood_group}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group mb-0">
                                    <Label
                                      title="Mobile number"
                                      isMandatory={false}
                                    />
                                  </div>
                                  <div className="row">
                                    {/* <div className="col-xs-4">
                                                                                <div className="form-group">
                                                                                    <select className="form-control">
                                                                                        <option>+91</option>
                                                                                        <option>+91</option>
                                                                                        <option>+91</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xs-8">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" placeholder="" />
                                                                                </div>
                                                                            </div> */}
                                    <div className="col-sm-12">
                                      <div className="form-control">
                                        <PhoneInput
                                          international
                                          // defaultCountry="IN"
                                          placeholder="Enter phone number"
                                          value={family_details.phone_number}
                                          onChange={this.handleFamilyDetailChange.bind(
                                            this,
                                            i,
                                            "phone_number"
                                          )}
                                          error={
                                            family_details.phone_number
                                              ? isValidPhoneNumber(
                                                family_details.phone_number
                                              )
                                                ? undefined
                                                : "Invalid phone number"
                                              : "Phone number required"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    </div>

                    <div className="formlayout w100">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">
                            Emergency contact
                          </h5>
                          <hr className="mrgtp" />
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label title="First Name" isMandatory={false} />
                                {/* <input type="text" className="form-control" placeholder="" /> */}

                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"first_name"}
                                  controlFunc={this.handleEmergencyContactInput}
                                  content={
                                    clientData.emergency_contact.first_name
                                  }
                                  placeholder={""}
                                  max={50}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label title="Last Name" isMandatory={false} />
                                {/* <input type="text" className="form-control" placeholder="" /> */}
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"last_name"}
                                  controlFunc={this.handleEmergencyContactInput}
                                  content={
                                    clientData.emergency_contact.last_name
                                  }
                                  placeholder={""}
                                  max={50}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group sform-control">
                                <Label
                                  title="Relationship"
                                  isMandatory={false}
                                />
                                {/* <select className="form-control">
                                                                    <option>Uncle</option>
                                                                    <option>Uncle</option>
                                                                </select> */}
                                <Select
                                  maxMenuHeight={120}
                                  className="form-control p-0"
                                  name="relationship"
                                  value={
                                    clientData.emergency_contact.relationship
                                  }
                                  onChange={this.handelEmergencyContact.bind(
                                    this,
                                    "relationship"
                                  )}
                                  options={relationship}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group mb-0">
                                <Label
                                  title="Mobile number"
                                  isMandatory={false}
                                />
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-control">
                                    <PhoneInput
                                      international
                                      defaultCountry="IN"
                                      placeholder="Enter phone number"
                                      value={
                                        clientData.emergency_contact
                                          .phone_number
                                      }
                                      onChange={this.handelEmergencyContact.bind(
                                        this,
                                        "phone_number"
                                      )}
                                      error={
                                        clientData.emergency_contact
                                          .phone_number
                                          ? isValidPhoneNumber(
                                            clientData.emergency_contact
                                              .phone_number
                                          )
                                            ? undefined
                                            : "Invalid phone number"
                                          : "Phone number required"
                                      }
                                    />
                                    <SpanField
                                            classname="text-danger"
                                            title={errors.emergency_contact ? errors.emergency_contact.phone_number:""}
                                          />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="formlayout w100">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">Vehicle Details</h5>
                          <hr className="mrgtp" />
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Vehicle make"
                                  isMandatory={false}
                                />

                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"vehicle_make"}
                                  controlFunc={this.handelVehicleDetails.bind(
                                    this,
                                    "vehicle_make"
                                  )}
                                  content={
                                    clientData.vehicle_details.vehicle_make
                                      ? clientData.vehicle_details.vehicle_make
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Vehicle model"
                                  isMandatory={false}
                                />
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"vehicle_model"}
                                  controlFunc={this.handelVehicleDetails.bind(
                                    this,
                                    "vehicle_model"
                                  )}
                                  content={
                                    clientData.vehicle_details.vehicle_model
                                      ? clientData.vehicle_details.vehicle_model
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Year of make"
                                  isMandatory={false}
                                />
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"year_of_make"}
                                  controlFunc={this.handelVehicleDetails.bind(
                                    this,
                                    "year_of_make"
                                  )}
                                  content={
                                    clientData.vehicle_details.year_of_make
                                      ? clientData.vehicle_details.year_of_make
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group mb-0">
                                <Label title="VIN Number" isMandatory={false} />
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"vin_number"}
                                  controlFunc={this.handelVehicleDetails.bind(
                                    this,
                                    "vin_number"
                                  )}
                                  content={
                                    clientData.vehicle_details.vin_number
                                      ? clientData.vehicle_details.vin_number
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group mb-0">
                                <Label
                                  title="Vehicle Registration
                                                                "
                                  isMandatory={false}
                                />
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"vehicle_registration"}
                                  controlFunc={this.handelVehicleDetails.bind(
                                    this,
                                    "vehicle_registration"
                                  )}
                                  content={
                                    clientData.vehicle_details
                                      .vehicle_registration
                                      ? clientData.vehicle_details
                                          .vehicle_registration
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="card  bghome ptbacr">
            <div className="card-body pd-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-shipping"
                  role="tabpanel"
                  aria-labelledby="v-pills-shipping-tab"
                >
                  <div>
                    <h4 className="card-title">EMPLOYMENT DETAILS</h4>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Employer" isMandatory={false} />

                        <InputField
                          isMandatory={false}
                          inputType={"text"}
                          // title={'Last Name'}
                          name={"employer_name"}
                          controlFunc={this.handleEmploymentDetailsInput}
                          content={
                            clientData.employment_details.employer_name
                              ? clientData.employment_details.employer_name
                              : ""
                          }
                          placeholder={""}
                          max={30}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Address" isMandatory={false} />
                        {/* <input type="text" className="form-control filepad" placeholder="" /> */}
                        <InputField
                          isMandatory={false}
                          inputType={"text"}
                          // title={'Last Name'}
                          name={"address"}
                          id={"address"}
                          controlFunc={this.handleEmploymentDetailsInput}
                          content={
                            clientData.employment_details.address
                              ? clientData.employment_details.address
                              : ""
                          }
                          placeholder={""}
                          max={50}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="City" isMandatory={false} />
                        {/* <input type="text" className="form-control" placeholder="" /> */}
                        <InputField
                          isMandatory={false}
                          inputType={"text"}
                          // title={'Last Name'}
                          name={"city"}
                          max={20}
                          controlFunc={this.handleEmploymentDetailsInput}
                          content={
                            clientData.employment_details.city
                              ? clientData.employment_details.city
                              : ""
                          }
                          placeholder={""}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Country" isMandatory={false} />

                        <CountryDropdown
                          placeholder="Country"
                          name="country"
                          value={clientData.employment_details.country || ""}
                          className="form-control"
                          onChange={(val) =>
                            this.handleEmploymentDetails("country", val)
                          }
                        />
                        {/* <SpanField classname="text-danger" title={errors.country} /> */}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="State" isMandatory={false} />

                        <RegionDropdown
                          name="state"
                          defaultOptionLabel="Select State"
                          country={clientData.employment_details.country}
                          value={clientData.employment_details.state || ""}
                          className="form-control"
                          onChange={(val) =>
                            this.handleEmploymentDetails("state", val)
                          }
                          placeholder="State"
                        />
                        {/* <SpanField classname="text-danger" title={errors.state} /> */}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Label title="Zip/Postal Code" isMandatory={false} />
                        {/* <input type="text" className="form-control" placeholder="" /> */}
                        <InputField
                          isMandatory={false}
                          inputType={"text"}
                          // title={'Last Name'}
                          name={"zip_code"}
                          controlFunc={this.handleEmploymentDetailsInput}
                          content={
                            clientData.employment_details.zip_code
                              ? clientData.employment_details.zip_code
                              : ""
                          }
                          placeholder={""}
                          max={10}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <Label title="Mobile number" isMandatory={false} />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-control">
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              value={clientData.employment_details.phone_number}
                              max={15}
                              onChange={this.handleEmploymentDetails.bind(
                                this,
                                "phone_number"
                              )}
                              error={
                                clientData.employment_details.phone_number
                                  ? isValidPhoneNumber(
                                    clientData.employment_details.phone_number
                                  )
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />
                             <SpanField
                                          classname="text-danger"
                                          title={errors.employment_details?errors.employment_details.phone_number:""}
                                        /> 
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group sform-control">
                        <Label title="Employer status" isMandatory={false} />
                        {/* <select className="form-control">
                                                    <option>Employed</option>
                                                    <option>Employed</option>
                                                </select> */}
                        <Select
                          maxMenuHeight={120}
                          className="form-control p-0"
                          name="employee_status"
                          value={clientData.employment_details.employee_status}
                          onChange={this.handleEmploymentDetails.bind(
                            this,
                            "employee_status"
                          )}
                          options={employee_status}
                        />
                      </div>
                    </div>
                    <div className="formlayout">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">
                            Insurance details
                          </h5>
                          <hr className="mrgtp" />
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Insurance provider"
                                  isMandatory={false}
                                />
                                {/* <input type="text" className="form-control" placeholder="" /> */}
                                <InputField
                                  // isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"insurance_provider"}
                                  controlFunc={this.handleInsuranceDetailsInput}
                                  content={
                                    clientData.insurance_details
                                      .insurance_provider
                                      ? clientData.insurance_details
                                        .insurance_provider
                                      : ""
                                  }
                                  placeholder={""}
                                  max={50}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.insurance_details?errors.insurance_details.insurance_provider:""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Policy ID Number"
                                  isMandatory={false}
                                />
                                {/* <input type="text" className="form-control" placeholder="" /> */}
                                <InputField
                                  // isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"policy_id_number"}
                                  controlFunc={this.handleInsuranceDetailsInput}
                                  content={
                                    clientData.insurance_details
                                      .policy_id_number
                                      ? clientData.insurance_details
                                        .policy_id_number
                                      : ""
                                  }
                                  placeholder={""}
                                  max={50}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.insurance_details?errors.insurance_details.policy_id_number:""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {/* <!-- <div className="form-group mb-0">
                                                                <label>Policy Holder Confirmation</label>

                                                            </div>--> */}
                              <div className="row">
                                <div className="col-sm-6">
                                  <Label
                                    title="Health Insurance Confirmation"
                                    isMandatory={false}
                                  />
                                  <div className="form-group">
                                    {/* <label className="switch">
                                                                            <input className="switch-input" type="checkbox" />
                                                                            <span className="switch-label" data-on="Yes" data-off="No"></span>
                                                                            <span className="switch-handle"></span>
                                                                        </label> */}
                                    <ToggleButton
                                      name="health_insurance_confirmation"
                                      dataOn="Yes"
                                      dataOff="No"
                                      controlFunc={this.handleInsuranceDetails.bind(
                                        this,
                                        "health_insurance_confirmation"
                                      )}
                                      checked={
                                        clientData.insurance_details
                                          .health_insurance_confirmation ===
                                          "Yes"
                                          ? true
                                          : false
                                      }
                                    />
                                    {/* <label className="switch">
                                  <input className="switch-input" type="checkbox" />
                                   <span className="switch-label" data-on="Yes" data-off="No"></span>
                                   <span className="switch-handle"></span>
                                   </label> */}
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <Label
                                    title="Is the patient the policy holder?"
                                    isMandatory={false}
                                  />
                                  <div className="form-group">
                                    <ToggleButton
                                      name="patient_policy_holder"
                                      dataOn="Yes"
                                      dataOff="No"
                                      controlFunc={this.handleInsuranceDetails.bind(
                                        this,
                                        "patient_policy_holder"
                                      )}
                                      checked={
                                        clientData.insurance_details
                                          .patient_policy_holder === "Yes"
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formlayout w100">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss">Medical History</h5>
                          <hr />
                          <div className="mt-4 mt-lg-0">
                            <div className="row">
                              {disease_array.map((option) => {
                                return (
                                  <Fragment key={option}>
                                    <div className="col-sm-3">
                                      <div className="custom-control custom-checkbox mb-1">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={option}
                                          value={option}
                                          onChange={this.handleMedicalHistory}
                                          checked={
                                            clientData.medical_history.indexOf(
                                              option
                                            ) > -1
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={option}
                                        >
                                          {option}
                                        </label>
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formlayout w100">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss">Family medical history</h5>
                          <hr />
                          <div className="mt-4 mt-lg-0">
                            <div className="row cusPadlr">
                              <div className="customwidth check-box-title1">
                                <label class="group-checkbox-title visibilityHideen">Blood Cancer</label>
                                <div className="custom-checkbox  my-group-Father-center labelwidth">
                                  Father
                                </div>
                                <div className="custom-checkbox my-group-Mother-center">
                                  Mother
                                </div>
                              </div>
                              <div className="customwidth check-box-title2">
                              <label class="group-checkbox-title visibilityHideen">Blood Cancer</label>
                                <div className="custom-checkbox my-group-Father-center labelwidth">
                                  Father
                                </div>
                                <div className="custom-checkbox  my-group-Mother-center">
                                  Mother
                                </div>
                              </div>
                              <div className="customwidth check-box-title3">
                              <label class="group-checkbox-title visibilityHideen">Blood Cancer</label>
                                <div className="custom-checkbox mb-2 my-group-Father-center labelwidth">
                                  Father
                                </div>
                                <div className="custom-checkbox mb-2 my-group-Mother-center">
                                  Mother
                                </div>
                              </div>
                              <div className="customwidth check-box-title3">
                              <label class="group-checkbox-title visibilityHideen">Blood Cancer</label>
                                <div className="custom-checkbox mb-2 my-group-Father-center labelwidth">
                                  Father
                                </div>
                                <div className="custom-checkbox mb-2 my-group-Mother-center">
                                  Mother
                                </div>
                              </div>
                            </div>
                            <div className="row cusPadlr">
                              {parent_disease_array.map((option, i) => {
                                return (
                                  <Fragment key={option.id + i}>
                                    <CheckboxGroup
                                      title={option.disease_name}
                                      className="customwidth"
                                      setName={"parents"}
                                      type={"checkbox"}
                                      options={[
                                        option.id + "|F",
                                        option.id + "|M",
                                      ]}
                                      selectedOptions={
                                        family_medical_history_SelectedOptions
                                      }
                                      controlFunc={
                                        this.handleParentMedicalHistory
                                      }
                                      page={"ClientUpdateProfile"}
                                    />
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="formlayout w100 socialHistory">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss">Social history</h5>
                          <hr />
                          <div className="mt-4 mt-lg-0">
                            <div className="row">
                              {SOCIAL_HISTORY.map((option, i) => {
                                return (
                                  <Fragment key={option.id + i}>
                                    <CheckboxGroup
                                      title={option.name}
                                      className="col-sm-6 social-habits"
                                      islabel="true"
                                      setName={"socialHabits"}
                                      type={"checkbox"}
                                      options={[
                                        option.id + "|Daily",
                                        option.id + "|Weekly",
                                        option.id + "|Monthly",
                                        option.id + "|Never",
                                      ]}
                                      selectedOptions={selectedSocialHabits}
                                      controlFunc={
                                        this.handleSocialHabitSelection
                                      }
                                      page={"ClientUpdateProfile"}
                                    />
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="formlayout w100 hide">
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="txtss mt-0 mb-15">Payment details</h5>
                          <hr className="mrgtp" />
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Credit card"
                                  isMandatory={false}
                                />
                                {/* <input type="text" className="form-control" placeholder="" /> */}

                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"credit_card"}
                                  controlFunc={this.handelPaymentDetails.bind(
                                    this,
                                    "credit_card"
                                  )}
                                  content={
                                    clientData.payment_details.credit_card
                                      ? clientData.payment_details.credit_card
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Name in card"
                                  isMandatory={false}
                                />
                                {/* <input type="text" className="form-control" placeholder="" /> */}
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"card_name"}
                                  controlFunc={this.handelPaymentDetails.bind(
                                    this,
                                    "card_name"
                                  )}
                                  content={
                                    clientData.payment_details.card_name
                                      ? clientData.payment_details.card_name
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label title="Card type" isMandatory={false} />

                                <Select
                                  maxMenuHeight={120}
                                  className="form-control p-0"
                                  name="card_type"
                                  value={
                                    clientData.payment_details.card_type
                                      ? clientData.payment_details.card_type
                                      : ""
                                  }
                                  onChange={this.handelPaymentDetails.bind(
                                    this,
                                    "card_type"
                                  )}
                                  options={card_type}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group mb-0">
                                <Label
                                  title="Expiration date"
                                  isMandatory={false}
                                />
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <MonthPick
                                    defaultValue="Select Month"
                                    year={
                                      clientData.payment_details.expiry_month
                                    }
                                    value={
                                      clientData.payment_details.expiry_month
                                        ? clientData.payment_details
                                          .expiry_month
                                        : ""
                                    }
                                    controlFunc={this.handelPaymentDetails.bind(
                                      this,

                                      "expiry_month"
                                    )}
                                    id="expiry_month"
                                    name="expiry_month"
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <YearPick
                                    defaultValue="Select Year"
                                    startyear={currentYear}
                                    endyear={currentYear + 50}
                                    value={
                                      clientData.payment_details.expiry_year
                                        ? clientData.payment_details.expiry_year
                                        : ""
                                    }
                                    controlFunc={this.handelPaymentDetails.bind(
                                      this,

                                      "expiry_year"
                                    )}
                                    id="expiry_year"
                                    name="expiry_year"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label title="CVV" isMandatory={false} />
                                {/* <input type="text" className="form-control" placeholder="" /> */}
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"cvv"}
                                  controlFunc={this.handelPaymentDetails.bind(
                                    this,
                                    "cvv"
                                  )}
                                  content={
                                    clientData.payment_details.cvv
                                      ? clientData.payment_details.cvv
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Label
                                  title="Netbanking / Paytm / Paypal"
                                  isMandatory={false}
                                />
                                {/* <input type="text" className="form-control" placeholder="" /> */}
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                  // title={'Last Name'}
                                  name={"payment_option"}
                                  controlFunc={this.handelPaymentDetails.bind(
                                    this,
                                    "payment_option"
                                  )}
                                  content={
                                    clientData.payment_details.payment_option
                                      ? clientData.payment_details
                                        .payment_option
                                      : ""
                                  }
                                  placeholder={""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 btncent">
          <div className="form-group signupformbtn">
            {/* <button className="signupbtn" data-toggle="modal" data-target=".bs-example-modal-center">Sign Up</button> */}
            <Button
              clsname="signupbtn abtn"
              btntype="submit"
              title="Sign Up"
              action=""
            />
           {/*  <Redirect to={{
            pathname: '/dashboard',
            state: { showToast: true }
        }}
/> */}
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ClientSignup1));
