import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import BusinessUserSideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
import InputField from "../components/UI/InputField";
import axios from "axios";
import { Spinner } from "../components/UI/Spinner";
import ToggleButton from "../components/UI/ToggleButton";
import DatePicker from "react-date-picker";
import Validation from "../actions/Validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as moment from "moment";
import {
  getDrugPlanningDetailsbyid,
  updateDrugPlanningDetails,
  getAllDrugTypes,
  getAllDrugCategories,
  deleteDrug,
  
  getAllDrugMeasures,
  getsuppliescategory,
  addDrugPlanningDetails
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";


import SpanField from "../components/UI/SpanField";
// Import React Table
import "react-table/react-table.css";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class AddDrugs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      suppliers_category:[],
      searchrecords: [],
      drug_action: this.props.match.params.id != undefined ? "Edit" : "Add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      mastergeneric_names:[],
      newDatatoAdd: {},
      addPopup: false,
      drugList: {
        s_no: "",
        drug_id: "",
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      drugData: {
        id: "",
        drug_status:"Active",
        
        vendor_drug_id:"",
        generic_name:"",
        drug_id: "",
        manufacturer: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        active_ingredients: "",
        unit_of_measure: "",
        remarks:"",
        batch_management:"",
        batch_number:"",
        serial_management:"",
        serial_number:"",
        mrp:"",
        expiry_date:"",
        stock_keeping_unit:"",
        ordering_unit:"",
        safety_stock:"",
        maximum_stock_level:"",
        minimum_lot_size:"",
        maximum_lot_size:"",
        re_order_point:"",
        storage_bin:"",
        rake:"",
        asile:"",
        costing_details:"",
        price_per_unit:"",
        discount_percentage:"",
        tax_category:"",
        gst_tax_percentage:""

      },
      errors: {
        id: "",
        drug_status:"",
        
        vendor_drug_id:"",
        generic_name:"",
        drug_id: "",
        manufacturer: "",
        drug_name: "",
        drug_type: "",
        drug_category: "",
        active_ingredients: "",
        unit_of_measure: "",
        remarks:"",
        batch_management:"",
        batch_number:"",
        serial_management:"",
        serial_number:"",
        mrp:"",
        expiry_date:"",
        stock_keeping_unit:"",
        ordering_unit:"",
        safety_stock:"",
        maximum_stock_level:"",
        minimum_lot_size:"",
        maximum_lot_size:"",
        re_order_point:"",
        storage_bin:"",
        rake:"",
        asile:"",
        costing_details:"",
        price_per_unit:"",
        discount_percentage:"",
        tax_category:"",
        gst_tax_percentage:""
      },
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
  
      data: [
        {
          drug_id: "1",
          drug_name: "da",
          drug_category: "ds",
          drug_type: "sdd",
          manufacturer: "sdsd",
          active_ingredients: "adsfd",
        },
      ],
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
        width: 100,
      },

      {
        key: "drug_category",
        text: "Drug Category",
        className: "drug_category",
        sortable: true,
        align: "left",
        width: 100,
      },

      {
        key: "drug_type",
        text: "Drug Type",
        className: "drug_type",
        sortable: true,
        align: "left",
        width: 100,
      },
 
      {
        key: "manufacturer",
        text: "Manufacturer",
        className: "manufacturer",
        sortable: true,
        align: "left",
        width: 100,
      },

      {
        key: "active_ingredients",
        text: "Active Incredients",
        className: "active_ingredients",
        sortable: true,
        align: "left",
        width: 100,
      },
      {
        key: "unit_of_measure",
        text: "Unit of Measure",
        className: "unit_of_measure",
        sortable: true,
        align: "left",
        width: 100,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.searchcolumns = [
      // {
      //   key: "s_no",
      //   text: "S.No",
      //   className: "s_no",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
        width: 10,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"drug_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.drug_id}
                placeholder={""}
              />
              <SpanField classname="text-danger" title={record.drugid_error} />
            </Fragment>
          );
        },
      },
      {
        key: "drug_name_hide",
        text: "drug_name_hide",
        className: "drug_name hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_category_hide",
        text: "drug_category_hide",
        className: "drug_category_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_type_hide",
        text: "drug_type_hide",
        className: "drug_type_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "unit_of_measure_hide",
        text: "unit_of_measure_hide",
        className: "unit_of_measure_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
        width: 100,
        cell: (record) => {
          return (
            <Fragment>
              <Select
                className="form-control p-0"
                name="drug_name"
                isMandatory={true}
                value={record.drug_name}
                onChange={(value) =>
                  this.handleCategoryColumnChange(record, value, "drug_name")
                }
                options={this.state.masterdrugNames}
              />
              <SpanField
                classname="text-danger"
                title={record.drugname_error}
              />
            </Fragment>
          );
        },
      },
      {
        key: "unit_of_measure",
        text: "Unit of Measure",
        className: "unit_of_measure",
        align: "left",
        sortable: true,
        width: 100,
        cell: (record) => {
          return (
            <Fragment>
              <Select
                className="form-control p-0"
                name="unit_of_measure"
                isMandatory={true}
                value={record.unit_of_measure}
                onChange={(value) =>
                  this.handleCategoryColumnChange(record, value, "unit_of_measure")
                }
                defaultValue={record.unit_of_measure ? record.unit_of_measure : this.state.masterdrugmeasure[0]}
                options={this.state.masterdrugmeasure}
              />
              <SpanField
                classname="text-danger"
                title={record.measure_error}
              />
            </Fragment>
          );
        },
      },

      {
        key: "drug_category",
        text: "Drug Category",
        className: "drug_category",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record) => {
          return (
            <Fragment>
              <Select
                className="form-control p-0"
                name="drug_category"
                isMandatory={true}
                value={record.drug_category}
                onChange={(value) =>
                  this.handleCategoryColumnChange(
                    record,
                    value,
                    "drug_category"
                  )
                }
                options={this.state.drugCategories}
              />
              <SpanField
                classname="text-danger"
                title={record.drugcategory_error}
              />
            </Fragment>
          );
        },
      },

      {
        key: "drug_type",
        text: "Drug Type",
        className: "drug_type",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record) => {
          return (
            <Fragment>
              <Select
                className="form-control p-0"
                name="drug_name"
                isMandatory={true}
                value={record.drug_type ? record.drug_type : ""}
                defaultValue=""
                onChange={(value) =>
                  this.handleCategoryColumnChange(record, value, "drug_type")
                }
                options={this.state.drugTypes}
              />
              <SpanField
                classname="text-danger"
                title={record.drugtype_error}
              />
            </Fragment>
          );
        },
      },

      {
        key: "manufacturer",
        text: "Manufacturer",
        className: "manufacturer",
        sortable: true,
        align: "left",
        width: 20,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <InputField
              inputType={"text"}
              isMandatory={true}
              name={"manufacturer"}
              controlFunc={this.handleInputsearch.bind(this, record)}
              content={record.manufacturer}
              placeholder={""}
            />
          );
        },
      },

      {
        key: "active_ingredients",
        text: "Active Incredients",
        className: "active_ingredients",
        sortable: true,
        align: "left",
        width: 20,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <InputField
              inputType={"text"}
              isMandatory={true}
              name={"active_ingredients"}
              controlFunc={this.handleInputsearch.bind(this, record)}
              content={record.active_ingredients}
              placeholder={""}
            />
          );
        },
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button> */}
            </Fragment>
          );
        },
      },
    ];
  }

  handleCategoryColumnChange = (value, record, field) => {
    // console.log(field);
    // console.log(value);
    // console.log(record);
    value[field] = record;
    // console.log(record);
    // record.availability = "Yes" === record.availability ? "No" : "Yes";
    this.UpdateColumnChange(value);
  };
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;

    this.UpdateColumnChange(record);
  };
  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].drug_id = record.drug_id;
    records[recIndex].drug_name = record.drug_name;
    records[recIndex].drug_category = record.drug_category;
    records[recIndex].drug_type = record.drug_type;

    this.setState({ searchrecords: records });
  };
  componentDidMount = () => {
   console.log(this.props.match.params.id);
if(this.props.match.params.id != undefined) {
  let id = this.props.match.params.id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getDrugPlanningDetailsbyid, {id:id}, CONFIG)
      .then((drugres) => {
        console.log(drugres);
        let drugRespons = drugres.data;
        console.log(drugRespons);
        if(drugRespons.status === "1"){
          let drugData = drugRespons.countOfRecords == 1 ? drugRespons.data[0] : [];
          console.log(drugData);
          drugData.batch_number = drugData.batch_management.batch_number;
          drugData.serial_number = drugData.serial_management.serial_number;
          drugData.batch_management =drugData.batch_management.batch_management;
          drugData.serial_management =drugData.serial_management.serial_management;
          drugData.stock_keeping_unit = {label:drugData.stock_keeping_unit,value:drugData.stock_keeping_unit};
          drugData.ordering_unit = {label:drugData.ordering_unit,value:drugData.ordering_unit};
          drugData.tax_category = {label:drugData.tax_category,value:drugData.tax_category};
          drugData.drug_category = {label:drugData.drug_category,value:drugData.drug_category};
          drugData.drug_type = {label:drugData.drug_type,value:drugData.drug_type};
          const yourDate = drugData.expiry_date;

          drugData.expiry_date = drugData.expiry_date ? moment(yourDate, moment.defaultFormat).toDate() : "";
          //  console.log(data.date_of_birth);
          this.handleExpiryDate(drugData.expiry_date);
          this.setState({drugData:drugData});
        }
        
      })
      .catch((e) => { });
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugTypes, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch(() => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;

        let drugCategories = [];
        list.forEach((element) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch(() => { });

   

    axios
      .post(getAllDrugMeasures, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugmeasureList = drugMasterRespons.data.data;
        console.log(masterdrugmeasureList);
        let masterdrugmeasure = [];
        masterdrugmeasureList.forEach((element) => {
          masterdrugmeasure.push({
            value: element.unit_of_measure,
            label: element.unit_of_measure,

          });
        });

        this.setState({ masterdrugmeasure });
      })
      .catch(() => { });


      axios
            .post(getsuppliescategory, null, CONFIG)
            .then((list) => {
                let suppliescategorylist = list.data.data;

                suppliescategorylist.forEach((element, index) => {
                    let suppliescategory = {};
                    suppliescategory.category_name = element.category_name;
                    suppliescategory.label = element.category_name;
                    suppliescategory.value = element.id;
                    suppliescategory.tax_percentage = element.tax_percentage;
                    suppliescategory.id = element.id;

                    suppliescategorylist[index] = suppliescategory;
                });

                // let clientmedicinehistory = {};
                
                this.setState({ suppliers_category: suppliescategorylist });
            })
            .catch(() => { });
  };
  
  

  handleselectChange = (selectedOptions, e) => {
    let data = this.state.drugData;
    data[e.name] = selectedOptions;
    // if (e.name === "drug_name") {
    //   data.manufacturer = selectedOptions.manufacturer;
    //   data.active_ingredients = selectedOptions.active_ingredients;
    // }
    this.setState({ drugData: data });
  };
  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;

    // if (e.name === "drug_name") {
    //   data.manufacturer = selectedOptions.manufacturer;
    //   data.active_ingredients = selectedOptions.active_ingredients;
    // } else {
    //   data[e.name] = selectedOptions;
    // }
    // if (e.name === "manufacturer" || e.name === "active_ingredients") {
    //   data[e.name] = selectedOptions.target.value;
    // } else {
    data[e.name] = selectedOptions;
    // }
    this.setState({ searchData: data });
  };

  handleInput = (e) => {
    let data = this.state.drugData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ drugData: data });
  };

  

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDrug, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        this.clearMessage();
      })
      .catch(() => { });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearAll() {
    let drug_action = this.state.drug_action;
    drug_action = "Add";
    this.setState({ drug_action });
    let drugData = {};
    drugData.drug_id = "";
    drugData.drug_name = "";
    drugData.manufacturer = "";
    drugData.drug_category = "";
    drugData.drug_type = "";
    drugData.active_ingredients = "";
    drugData.unit_of_measure = "";
    this.setState({ drugData });
  }

  updateDrugplanningdetails() {
    let {drugData,errors} = this.state;
    
    

    // this.setState({ loading: true });
  
    drugData.type = "updatedrugplanningdata";
    console.log(drugData);
    errors = Validation(drugData, errors);
    this.setState({errors: errors});
    // console.log(errors);return false;
    let batch_management = {
      batch_management:drugData.batch_management,
      batch_number:drugData.batch_number
    }; let serial_management = {
      serial_management:drugData.serial_management,
      serial_number:drugData.serial_number
    };
    if (errors.formIsValid) {
      let data = {
        drug_id:drugData.drug_id,
        drug_status:drugData.drug_status,
        drug_name:drugData.drug_name,
        vendor_drug_id:drugData.vendor_drug_id,
        drug_category:drugData.drug_category.label,
        drug_type:drugData.drug_type.label,
        active_ingredients:drugData.active_ingredients,
        manufacturer:drugData.manufacturer,
        generic_name:drugData.generic_name,
        remarks:drugData.remarks,
        batch_management:batch_management,
        serial_management:serial_management,
        mrp:drugData.mrp,
        expiry_date:drugData.expiry_date,
        unit_of_measure:drugData.stock_keeping_unit.label,
        stock_keeping_unit:drugData.stock_keeping_unit.label,
        ordering_unit:drugData.ordering_unit.label,
        safety_stock:drugData.safety_stock,
        maximum_stock_level:drugData.maximum_stock_level,
        minimum_lot_size:drugData.minimum_lot_size,
        maximum_lot_size:drugData.maximum_lot_size,
        re_order_point:drugData.re_order_point,
        storage_bin:drugData.storage_bin,
        rake:drugData.rake,
        asile:drugData.asile,
        price_per_unit:drugData.price_per_unit,
        discount_percentage:drugData.discount_percentage,
        tax_category:drugData.tax_category.label,
        gst_tax_percentage:drugData.gst_tax_percentage,
        id:this.props.match.params.id
        
      }

      axios
        .post(updateDrugPlanningDetails, data, CONFIG)
        .then((addDrugResponse) => {
          let res = addDrugResponse.data;
          console.log(res);
          if (res.status === "1") {
           
            this.setState({
              
              // searchTableSuccess: res,
              loading: false,
            });

            toast.success(res.message, {
              className: "toast-success"
            });

            setTimeout(
              () =>
                
                  window.location.href = '/druglist'
              ,
              1000
            );
            
          } else {
            this.setState({
              // searchrecords: records,
              // searchTableSuccess: res,
              loading: false,
            });
            toast.error(res.message, {
              className: "toast-error"
            });
            
          }
        })
        .catch(() => { });
    }
   
  }

  saveDrugplanningdetails() {
    let {drugData,errors} = this.state;
    
    

    // this.setState({ loading: true });
  
    drugData.type = "adddrugplanningdata";
    console.log(drugData);
    errors = Validation(drugData, errors);
    this.setState({errors: errors});
    // console.log(errors);return false;
    let batch_management = {
      batch_management:drugData.batch_management,
      batch_number:drugData.batch_number
    }; let serial_management = {
      serial_management:drugData.serial_management,
      serial_number:drugData.serial_number
    };
    if (errors.formIsValid) {
      let data = {
        drug_id:drugData.drug_id,
        drug_status:drugData.drug_status,
        drug_name:drugData.drug_name,
        vendor_drug_id:drugData.vendor_drug_id,
        drug_category:drugData.drug_category.label,
        drug_type:drugData.drug_type.label,
        active_ingredients:drugData.active_ingredients,
        manufacturer:drugData.manufacturer,
        generic_name:drugData.generic_name,
        remarks:drugData.remarks,
        batch_management:batch_management,
        serial_management:serial_management,
        mrp:drugData.mrp,
        expiry_date:drugData.expiry_date,
        unit_of_measure:drugData.stock_keeping_unit.label,
        stock_keeping_unit:drugData.stock_keeping_unit.label,
        ordering_unit:drugData.ordering_unit.label,
        safety_stock:drugData.safety_stock,
        maximum_stock_level:drugData.maximum_stock_level,
        minimum_lot_size:drugData.minimum_lot_size,
        maximum_lot_size:drugData.maximum_lot_size,
        re_order_point:drugData.re_order_point,
        storage_bin:drugData.storage_bin,
        rake:drugData.rake,
        asile:drugData.asile,
        price_per_unit:drugData.price_per_unit,
        discount_percentage:drugData.discount_percentage,
        tax_category:drugData.tax_category.label,
        gst_tax_percentage:drugData.gst_tax_percentage,
        user_id:this.props.userdata.id
        
      }

      axios
        .post(addDrugPlanningDetails, data, CONFIG)
        .then((addDrugResponse) => {
          let res = addDrugResponse.data;
          console.log(res);
          if (res.status === "1") {
           
            this.setState({
              
              // searchTableSuccess: res,
              loading: false,
            });

            toast.success(res.message, {
              className: "toast-success"
            });

            setTimeout(
              () =>
                
                  window.location.href = '/druglist'
              ,
              1000
            );
            
          } else {
            this.setState({
              // searchrecords: records,
              // searchTableSuccess: res,
              loading: false,
            });
            toast.error(res.message, {
              className: "toast-error"
            });
            
          }
        })
        .catch(() => { });
    }
   
  }
  handleDrugstatus(field,value){
    console.log(field,value.target.checked);
    let drug_status = value.target.checked===true?"Active":"In-active";
    this.setState(prevState => ({
      drugData: {                   // object that we want to update
          ...prevState.drugData,    // keep all other key-value pairs
          drug_status: drug_status      // update the value of specific key
          
      }
  }));
  }
  handleBatchManagement(field, value) {
    let data = this.state.drugData;
    let errors = this.state.errors;
    
    console.log(field);

    if (
      field === "batch_management" ||
      field === "serial_management"
    ) {
      data[field] = value.target.checked === true ? "Yes" : "No";
    } else {
      let max = value.target.max;
      value = value.target.value;
      if (value.length > max) {
        const value1 = value.slice(0, max);
        errors[field] = "Maximum Length has exceeded.";
        data.insurance_details[field] = value1;
      } else {
        errors[field] = "";
        data.insurance_details[field] = value;
      }
    }
console.log(data)
    this.setState({ drugData: data });
  }
  handletaxcategorychange (field,value){
    console.log(field,value);
    this.setState(prevState => ({
      drugData: {                   // object that we want to update
          ...prevState.drugData,    // keep all other key-value pairs
          gst_tax_percentage: value.tax_percentage,       // update the value of specific key
          tax_category:value
      }
  }));
  console.log(this.state.drugData);
    // this.setState({ drugData:drugData });

  }
  handleExpiryDate = (date) => {
    this.setState(prevState => ({
      drugData: {                   // object that we want to update
          ...prevState.drugData,    // keep all other key-value pairs
          expiry_date: date       // update the value of specific key
          
      }
  }));
  };

  render() {
    const {
      drugData,
      suppliers_category,
      errors,
      loading,
      searchTableSuccess,
      editformSuccess,
      searchErrorMessage,
    } = this.state;

    return (
      <div id="layout-wrapper">
  
   
   <div className="main-content bacfot">
  {/*  <Spinner loading={loading} /> */}
  {loading && <Dnaspinner/>}
      <div className="mainpage">
         <Header user_id={this.props.userdata.id} />
         <div className="main-box">
            <div className="sidemenu pr0">
               <BusinessUserSideBar />
            </div>
            <section className="main-body hospital-profilegap">
            
               <div className="container-fluid">
                  <div className="checkout-tabs">
                     <div className="col-lg-14">
                        <h4 className="font-weight-bold"> {this.state.drug_action} Drug </h4>
                        <div className="row ">
                           <div className="col-sm-5 pr0">
                              <div className="card b-9-hite">
                                 <div className="card-body">
                                    <h5 className="regxs">
                                       Drug Details
                                    </h5>
                                    <div className="user-notification-box">
                                       <SpanField
                                       classname={
                                       "0" === editformSuccess.status
                                       ? "text-danger user-notification"
                                       : "text-success user-notification"
                                       }
                                       title={editformSuccess.message}
                                       />
                                    </div>
                                    <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
                                    <div>
                                       <hr className="rowline" />
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Drug Status</label>
                                             </div>
                                             <div className="form-group frlrit">
                                                <ToggleButton
                                                name="drug_status"
                                                dataOn={"Active"}
                                                dataOff={"In-active"}
                                                controlFunc={this.handleDrugstatus.bind(
                                                this,
                                                "drug_status"
                                                )}
                                                checked={
                                                  drugData.drug_status
                                                     ===
                                                    "Active"
                                                    ? true
                                                    : false
                                                }
                                                />
                                                <SpanField
                                                   classname="text-danger"
                                                   title={errors.drug_status}
                                                   />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre required">
                                                <label>Drug ID</label>
                                             </div>
                                             <div className="form-group frlrit ">
                                                <InputField
                                                   inputType={"text"}
                                                   isMandatory={true}
                                                   name={"drug_id"}
                                                   controlFunc={this.handleInput.bind(
                                                   this
                                                   )}
                                                   content={drugData.drug_id}
                                                   placeholder={""}
                                                   />
                                                <SpanField
                                                   classname="text-danger"
                                                   title={errors.drug_id}
                                                   />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre required">
                                                <label>Drug Name</label>
                                             </div>
                                             <div className="form-group frlrit ">
                                                <InputField
                                                   inputType={"text"}
                                                   isMandatory={true}
                                                   name={"drug_name"}
                                                   controlFunc={this.handleInput.bind(
                                                   this
                                                   )}
                                                   content={drugData.drug_name}
                                                   placeholder={""}
                                                   />
                                                <SpanField
                                                   classname="text-danger"
                                                   title={errors.drug_name}
                                                   />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Vendor Drug ID</label>
                                             </div>
                                             <div className="form-group frlrit">
                                                <InputField
                                                   inputType={"text"}
                                                   isMandatory={true}
                                                   name={"vendor_drug_id"}
                                                   controlFunc={this.handleInput.bind(
                                                   this
                                                   )}
                                                   content={drugData.vendor_drug_id}
                                                   placeholder={""}
                                                   />
                                                <SpanField
                                                   classname="text-danger"
                                                   title={errors.vendor_drug_id}
                                                   />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Drug Category</label>
                                             </div>
                                             <div className="form-group frlrit">
                                             <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="drug_category"
                                                           value={drugData.drug_category}
                                                            onChange={this.handleselectChange}
                                                            options={this.state.drugCategories}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.drug_category}
                                                    />
                                                </div>
                                             
                                                
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Drug Type</label>
                                             </div>
                                             <div className="form-group frlrit">
                                             <div className="position-relative">
                                                <div className="input-group">
                                                        <Select
                                                            className="form-control p-0"
                                                            name="drug_type"
                                                           value={drugData.drug_type}
                                                            onChange={this.handleselectChange}
                                                            options={this.state.drugTypes}

                                                        />


                                                    </div>
                                                    <SpanField
                                                        classname="text-danger"
                                                        title={errors.drug_type}
                                                    />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Active Ing.</label>
                                             </div>
                                             <div className="form-group frlrit">
                                                <InputField
                                                   inputType={"text"}
                                                   name={"active_ingredients"}
                                                   controlFunc={this.handleInput}
                                                   content={drugData.active_ingredients}
                                                   placeholder={""}
                                                   />
                                                   <SpanField
                                                      classname="text-danger"
                                                      title={errors.active_ingredients}
                                                      />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Manufacturer</label>
                                             </div>
                                             <div className="form-group frlrit">
                                                <InputField
                                                   inputType={"text"}
                                                   name={"manufacturer"}
                                                   controlFunc={this.handleInput}
                                                   content={drugData.manufacturer}
                                                   placeholder={""}
                                                   />
                                                   <SpanField
                                                      classname="text-danger"
                                                      title={errors.manufacturer}
                                                      />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre required">
                                                <label>Generic Name</label>
                                             </div>
                                             <div className="form-group frlrit">
                                                
                                             <InputField
                                                   inputType={"text"}
                                                   name={"generic_name"}
                                                   controlFunc={this.handleInput}
                                                   content={drugData.generic_name}
                                                   placeholder={""}
                                                   />
                                                   <SpanField
                                                      classname="text-danger"
                                                      title={errors.generic_name}
                                                      />
                                                
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    {/* <div className="row mb-20">
                                       <div className="col-sm-3"></div>
                                       <div className="col-sm-8">
                                          <div className="form-group mb-0">
                                             <button
                                                className="signupbtn abtn btdpml"
                                                onClick={this.saveDrugplanningdetails.bind(
                                                this
                                                )}
                                                >
                                               
                                                Search
                                             </button>
                                             <button
                                                href="patients-register"
                                                className="signupbtn abtn btdpml ml-25"
                                                onClick={this.add}
                                                >
                                             {"+Add"}
                                             </button>
                                          </div>
                                       </div>
                                    </div> */}
                                    <div className="row">
                                       <div className="col-sm-10">
                                          <div className="frllftfull">
                                             <div className="form-group frllft mbbre">
                                                <label>Remarks</label>
                                             </div>
                                             <div className="form-group frlrit">
                                                <InputField
                                                   inputType={"text"}
                                                   isMandatory={true}
                                                   name={"remarks"}
                                                   controlFunc={this.handleInput.bind(
                                                   this
                                                   )}
                                                   content={drugData.remarks}
                                                   placeholder={""}
                                                   />
                                                <SpanField
                                                   classname="text-danger"
                                                   title={errors.remarks}
                                                   />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-7 pr0">
                              <div className=" card m565">
                                 <div className="card-body">
                                    <div>
                                       <h5 className="regxs">Planning Details</h5>
                                       <hr className="rowline" />
                                    </div>
                                    <div className="user-notification">
                                       {searchErrorMessage ? (
                                       <div
                                          className="alert alert-danger"
                                          role="alert"
                                          >
                                          {searchErrorMessage}
                                       </div>
                                       ) : (
                                       ""
                                       )}
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft required">
                                          <label>Batch Management</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <ToggleButton
                                          name="batch_management"
                                          dataOn={"Yes"}
                                          dataOff={"No"}
                                          controlFunc={this.handleBatchManagement.bind(
                                          this,
                                          "batch_management"
                                          )}
                                          checked={
                                            drugData.batch_management
                                           ===
                                              "Yes"
                                              ? true
                                              : false
                                          }
                                          />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.batch_management}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 required">
                                          <label>Serial Number</label>
                                       </div>
                                       <div className="form-group sform-control frlrclfgf">
                                          <ToggleButton
                                          name="serial_management"
                                          dataOn={"Yes"}
                                          dataOff={"No"}
                                          controlFunc={this.handleBatchManagement.bind(
                                          this,
                                          "serial_management"
                                          )}
                                          checked={
                                            drugData.serial_management ===
                                              "Yes"
                                              ? true
                                              : false
                                          }
                                          />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.serial_management}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                      {drugData.batch_management === "Yes" ? (
                                        <Fragment>
                                      <div className="form-group frllft required">
                                          <label>Batch Number</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"batch_number"}
                                             controlFunc={this.handleInput}
                                             content={drugData.batch_number}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                             <SpanField
                                             classname="text-danger"
                                             title={errors.batch_number}
                                             />
                                       </div></Fragment>):
                                       (<Fragment><div className="form-group frllft ">
                                    </div>
                                    <div className="form-group frlrclfgf ">
                                      
                                    </div></Fragment>)}
                                       {drugData.serial_management === "Yes" ? (
                                       <Fragment>
                                       <div className="form-group frlrclfgf-1 required">
                                          <label>Serial Number</label>
                                       </div>
                                       <div className="form-group frlrit datepickerdiv" style={{width:"28%"}}>
                                       <InputField
                                             inputType={"text"}
                                             name={"serial_number"}
                                             controlFunc={this.handleInput}
                                             content={drugData.serial_number}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.serial_number}
                                             />
                                       </div></Fragment>):(<Fragment><div className="form-group frllft ">
                                    </div>
                                    <div className="form-group frlrclfgf ">
                                      
                                    </div></Fragment>)}
                                       
                                    </div>

                                    <div className="frllftfull">
                                       <div className="form-group frllft required">
                                          <label>MRP</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"mrp"}
                                             controlFunc={this.handleInput}
                                             content={drugData.mrp}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                              <SpanField
                                             classname="text-danger"
                                             title={errors.mrp}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 required">
                                          <label>Expiry Date</label>
                                       </div>
                                       <div className="form-group frlrit datepickerdiv" style={{width:"28%"}}>
                                          <DatePicker
                                          className="form-control"
                                          value={drugData.expiry_date}
                                          format="dd-MM-yyyy"
                                          onChange={(date) =>
                                          this.handleExpiryDate(date)
                                          }
                                          yearPlaceholder='YYYY'
                                          monthPlaceholder='MM'
                                          dayPlaceholder='DD'
                                          />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.expiry_date}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft required">
                                          <label>Stock Keeping Unit</label>
                                       </div>
                                       <div className="form-group sform-control frlrclfgf">
                                          <Select
                                             className="form-control p-0"
                                             name="stock_keeping_unit"
                                             isMandatory={true}
                                             value={drugData.stock_keeping_unit}
                                             onChange={this.handleselectChange}
                                             options={this.state.masterdrugmeasure}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.stock_keeping_unit}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 required">
                                          <label>Ordering Unit</label>
                                       </div>
                                       <div className="form-group sform-control frlrclfgf">
                                          <Select
                                             className="form-control p-0"
                                             name="ordering_unit"
                                             isMandatory={true}
                                             value={drugData.ordering_unit}
                                             onChange={this.handleselectChange}
                                             options={this.state.masterdrugmeasure}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.ordering_unit}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft ">
                                          <label>Safety Stock</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"safety_stock"}
                                             controlFunc={this.handleInput}
                                             content={drugData.safety_stock}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                             <SpanField
                                             classname="text-danger"
                                             title={errors.safety_stock}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 ">
                                          <label>Max Stock Level</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"maximum_stock_level"}
                                             controlFunc={this.handleInput}
                                             content={drugData.maximum_stock_level}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.maximum_stock_level}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft ">
                                          <label>Min lot size </label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"minimum_lot_size"}
                                             controlFunc={this.handleInput}
                                             content={drugData.minimum_lot_size}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.minimum_lot_size}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 ">
                                          <label>Max lot size</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"maximum_lot_size"}
                                             controlFunc={this.handleInput}
                                             content={drugData.maximum_lot_size}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.maximum_lot_size}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft ">
                                          <label>Re-order Point </label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"re_order_point"}
                                             controlFunc={this.handleInput}
                                             content={drugData.re_order_point}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.re_order_point}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 ">
                                          <label>Storage Bin</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"storage_bin"}
                                             controlFunc={this.handleInput}
                                             content={drugData.storage_bin}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.storage_bin}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft ">
                                          <label>Rake </label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"rake"}
                                             controlFunc={this.handleInput}
                                             content={drugData.rake}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.rake}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 ">
                                          <label>Asile</label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"asile"}
                                             controlFunc={this.handleInput}
                                             content={drugData.asile}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.asile}
                                             />
                                       </div>
                                    </div>
                                    
                                    <div class="row">
                                       <div class="col-sm-12 mt-3">
                                          <h5 className="regxs">Costing Details</h5>
                                          <hr className="rowline" />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft required">
                                          <label>Price Per Unit </label>
                                       </div>
                                       <div className="form-group frlrclfgf ">
                                          <InputField
                                             inputType={"text"}
                                             name={"price_per_unit"}
                                             controlFunc={this.handleInput}
                                             content={drugData.price_per_unit}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.price_per_unit}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 ">
                                          <label>Discount %</label>
                                       </div>
                                       <div className="form-group frlrclfgf">
                                          <InputField
                                             inputType={"text"}
                                             name={"discount_percentage"}
                                             controlFunc={this.handleInput}
                                             content={drugData.discount_percentage}
                                             placeholder={""}
                                             isvisible={false}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.discount_percentage}
                                             />
                                       </div>
                                    </div>
                                    <div className="frllftfull">
                                       <div className="form-group frllft required">
                                          <label>Tax Category</label>
                                       </div>
                                       <div className="form-group sform-control frlrclfgf">
                                          <Select
                                          className="form-control p-0"
                                          name="tax_category"
                                          value={drugData.tax_category}
                                          onChange={this.handletaxcategorychange.bind(
                                          this,
                                          "tax_category"
                                          )}
                                          options={suppliers_category}
                                          />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.tax_category}
                                             />
                                       </div>
                                       <div className="form-group frlrclfgf-1 required">
                                          <label>GST Tax %</label>
                                       </div>
                                       <div className="form-group sform-control frlrclfgf">
                                          <InputField
                                             inputType={"text"}
                                             name={"gst_tax_percentage"}
                                             controlFunc={this.handleInput}
                                             content={drugData.gst_tax_percentage}
                                             placeholder={""}
                                             isvisible={true}
                                             />
                                          <SpanField
                                             classname="text-danger"
                                             title={errors.gst_tax_percentage}
                                             />
                                       </div>
                                    </div>
                                    <div className="row m-b-50">
                                       <div className="col-sm-4"></div>
                                       <div className="col-sm-3">
                                          <div className="form-group mb-0">
                                          {this.state.drug_action === "Add" ? (<button
                                                className="signupbtn abtn btdpml"
                                                onClick={this.saveDrugplanningdetails.bind(
                                                this
                                                )}
                                                >
                                                {/* {" "}
                                                {loading ? (
                                                <LoadingSpinner title="Search" />
                                                ) : (
                                                "Search"
                                                )} */}
                                                Save
                                             </button>):(<button
                                                className="signupbtn abtn btdpml"
                                                onClick={this.updateDrugplanningdetails.bind(
                                                this
                                                )}
                                                >
                                                {/* {" "}
                                                {loading ? (
                                                <LoadingSpinner title="Search" />
                                                ) : (
                                                "Search"
                                                )} */}
                                                Update
                                             </button>)} 
                                             
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div
                           className="w100"
                           style={{
                           display: this.state.search_action
                           ? "block"
                           : "none",
                           }}
                           >
                           <div className="col-sm-12">
                              <div className="hmeclr bgligtrn">
                                 <h5 className="fnsz hptd"> Search Result </h5>
                              </div>
                           </div>
                           <div className="col-sm-12">
                              <div className="card">
                                 <div className="card-body ">
                                    <div
                                       className="tab-content"
                                       id="v-pills-tabContent"
                                       >
                                       <div
                                          className="tab-pane fade show active"
                                          id="v-pills-shipping"
                                          role="tabpanel"
                                          aria-labelledby="v-pills-shipping-tab"
                                          >
                                          <div className="user-notification">
                                             <SpanField
                                             classname={
                                             "0" === searchTableSuccess.status
                                             ? "text-danger user-notification"
                                             : "text-success user-notification"
                                             }
                                             title={searchTableSuccess.message}
                                             />
                                          </div>
                                          <ReactDatatable
                                             className="table table-bordered dt-responsive nowrap dataTable no-footer"
                                             config={this.searchconfig}
                                             records={this.state.searchrecords}
                                             columns={this.searchcolumns}
                                             />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* 
                     <Footerad />
                     */}
                  </div>
               </div>
         </div>
         </section>
         <FooterSection />
      </div>
   </div>
</div>
</div>
    );
  }
}

export default connect(mapStateToProps)(AddDrugs);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
