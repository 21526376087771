import React, { Component } from "react";
import { connect } from "react-redux";
import $ from 'jquery';
/* Import Components */
import GenderField from "../UI/GenderField";
import InputField from "../UI/InputField";
// import Select from '../UI/Select';
import Button from "../UI/Button";
import Label from "../UI/Label";
import SpanField from "../UI/SpanField";
// import PlacesAutocomplete from '../UI/PlacesAutocomplete';
import Validation from "../../actions/Validation";
import axios from "axios";
import { RegisterUrl } from "../../config/url.json";
// import { addError } from './errors';

import DatePicker from "react-date-picker";
import PhoneInput, { isValidPhoneNumber,parsePhoneNumber } from "react-phone-number-input";
import CheckboxField from "../UI/CheckboxField";
import "react-phone-number-input/style.css";
import { CONFIG } from "../../config/data";
import { withRouter } from "react-router-dom";
import ErrorAlert from "../UI/ErrorAlert";
import * as moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





function mapStateToProps(state) {
  return {};
}

const agreeOptions = [
  {
    key: 1,
    value: "Receive relevant offers and promotional communication from Nucleo",
  },
  { key: 2, value: "By signing up, I agree to  our terms and conditions and privacy policies", //<a href="/legal/terms/update" id="terms-link" target="_blank" rel="nofollow">Terms</a>, <a href="/about/privacy/update" id="privacy-link" target="_blank" rel="nofollow">Data Policy</a>" },
}
];

class ClientSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        phone_code: "",
        gender: "",
        date_of_birth: "",
        user_type: "patientsignup",
        user_type_id: "1",
        agreedOption: [1],
        // user_type: "patiensignup",
      },
      errors: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        phone_code: "",
        gender: "",
        date_of_birth: "",
        agreeTC: "",
      },
      genderoptions: ["Male", "Female"],
      loading: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleInput = this.handleInput.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handlepassword = (e) => {

    $(".clienttoggle-password").toggleClass("fa-eye fa-eye-slash");
    
    let input = $('#clientpassword');
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  }
  handleInput = (e) => {
    let data = this.state.ClientData;
    let max = e.target.max;
    let name = e.target.name;
    let value = e.target.value;
    let errors = this.state.errors;
    if (
      name === "first_name" ||
      name === "last_name" ||
      name === "email" ||
      name === "password"
      
    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
      
    }

    this.setState({ ClientData: data,errors:errors });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.ClientData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      // const value1 = value.slice(0, max);
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ ClientData: data, errors: errors });
  }
  handleCheck = (e) => {
    const newSelection = e.target.value;
    let data = this.state.ClientData;

    if (data.agreedOption.includes(parseInt(newSelection, 10))) {
      data.agreedOption.splice(
        data.agreedOption.indexOf(parseInt(newSelection, 10)),
        1
      );
    } else {
      data.agreedOption.push(parseInt(newSelection, 10));
    }

let errors = this.state.errors;
if(data.agreedOption.length > 1){
  errors.agreeTC = "";
}


    this.setState({ ClientData: data,errors:errors });
  };
  validateEmail(email) {
    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // return re.test(email);
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  }

  handleDOBChange = (date) => {
    console.log(date);
    
    let clientData = this.state.ClientData;
    let errors = this.state.errors;
    errors.date_of_birth = "";
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    clientData.date_of_birth=offsetDate;
    this.setState({ ClientData: clientData,errors:errors });
  };
  handleFormSubmit(e) {
    e.preventDefault();

    let { ClientData, errors } = this.state;

    errors = Validation(ClientData, errors);
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      this.setState({ loading: true });

      ClientData.user_type = "patient";
      let data = ClientData;
      


      data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
      data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;
      
      data.date_of_birth=data.date_of_birth;
      //data.date_of_birth=moment(data.date_of_birth).format('Y-M-D');
      data.user_type = "patient";
     // data.date_of_birth = moment(data.date_of_birth).format('YYYY-MM-DD hh:mm:ss A')
      console.log(data);
      try {
        const result = axios.post(RegisterUrl, data, CONFIG);
        const p = Promise.resolve(result);

        p.then((value) => {
          this.setState({ loading: false });
          let data = ClientData;
          console.log(data.date_of_birth);
          data.phone_number = data.phone_code.concat(data.phone_number);

          data.user_type = "patientsignup";
          this.setState({
            error: value.data.message,
            ClientData: data,
          });
          if (value.data.status === "1") {
           
            // let Notificationdata = {};
            // const options = {
            //   headers: {
            //     Accept: "*/*",
            //   },
            // };
            // console.log(value);
            // Notificationdata.phoneNumber = ClientData.phone_number;
            // Notificationdata.trackingId = value.data.data.unique_id;
            // Notificationdata.message =
            //   value.data.data.otp +
            //   " is Your OTP for Mobile no verification to activate your account.";
            // // let isValidEmail = this.validateEmail(data["emailphone"]);
            // let data = ClientData;
            // data.user_type = "patientsignup";
            // this.setState({
            //   // error: value.data.message,
            //   ClientData: data,
            // });
            // axios
            //   .post(textNotificationURL, Notificationdata, options)
            //   .then((response) => {
            //     console.log(response);
            //     // data.loading = false;
            //     this.props.setUserData(value.data.data.user);
            //     this.props.history.push("/verify/" + value.data.data.unique_id);
            //   })
            //   .catch((error) => {
            //     if (e.response) {
            //       this.setState({ error: error.response.data.errors });
            //       window.setTimeout(() => {
            //         this.setState({
            //           error: "",
            //         });
            //       }, 5000);
            //     }
            //   });
            // console.log(value.data.unique_id);
            this.props.setUserData(value.data.data.user);
           
            this.props.history.push("/verify");
          }
          if (value.data.status === "0") {
            
            window.setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 5000);
          }
        });
      } catch (e) {
        window.setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 5000);
      }
    }
  }

  handlePhoneInput = (event) => {
    let ClientData = this.state.ClientData;
    ClientData.phone_number = event;
    let errors = this.state.errors;
    errors.phone_number = "";
    this.setState({ ClientData,errors });
  };
  handleAddressInput = () => {
    // if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         var geolocation = {
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //         };
    //         var circle = new google.maps.Circle(
    //             { center: geolocation, radius: position.coords.accuracy });
    //         autocomplete.setBounds(circle.getBounds());
    //     });
    // }
  };

  render() {
    const { ClientData, genderoptions, errors, error, loading } = this.state;
    const value = ClientData.phone_number;
    return (
      <form
        ref="clientform"
        id="clientform"
        className="custom-validation"
        onSubmit={this.handleFormSubmit}
      >
        {error ? <ErrorAlert message={error} /> : null}
        <div className="row clientSignUpclr">
          <div className="col-sm-6">
          <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

            <div className="form-group">
              <InputField
              id="clientfirstname"
                isMandatory={true}
                inputType={"text"}
                title={"First Name"}
                name={"first_name"}
                controlFunc={this.handleInput}
                content={ClientData.first_name}
                placeholder={""}
                max={50}
              />
              <SpanField classname="text-danger" title={errors.first_name} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="clientlastname"
                isMandatory={true}
                inputType={"text"}
                title={"Last Name"}
                name={"last_name"}
                controlFunc={this.handleInput}
                content={ClientData.last_name}
                placeholder={""}
                max={35}
              />
              <SpanField classname="text-danger" title={errors.last_name} />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <InputField
              id="clientemail"
                 isMandatory={true}
                inputType={"text"}
                title={"Email"}
                name={"email"}
                controlFunc={this.handleInput}
                content={ClientData.email}
                placeholder={""}
                max={35}
              />
              <SpanField classname="text-danger" title={errors.email} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-0">
              <Label title="Phone Number" isMandatory={true} />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-control">
                  <PhoneInput
                  id="clientphonenumber"
                    international
                    country="IN"
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={value}
                    onChange={this.handlePhoneInput}
                    error={
                      value
                        ? isValidPhoneNumber(value)
                          ? undefined
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                    max={13}
                  />
                </div>
                <SpanField
                  classname="text-danger"
                  title={errors.phone_number}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <InputField
                isMandatory={true}
                inputType={"password"}
                title={"Password"}
                name={"password"}
                controlFunc={this.handleInput}
                content={ClientData.password}
                placeholder={""}
                id="clientpassword"
                max={35}
              />
              <span toggle="#password-field" onClick={this.handlepassword} class="fa fa-fw fa-eye field-icon clienttoggle-password"></span>
              <SpanField classname="text-danger" title={errors.password} />

            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group ">
              <Label title="DOB" isMandatory={true} />
              {/* <DateField

                                name={'date_of_birth'}
                                controlFunc={this.handleInput}
                                // content={ClientData.password}
                                placeholder={'mm-dd-yyyy'} /> */}
              <DatePicker
              id="clientdateofbirth"
                className="form-control"
          
                value={ClientData.date_of_birth}
                format="dd-MM-yyyy"
                onChange={(date) => this.handleDOBChange(date)}
                yearPlaceholder='YYYY'
                monthPlaceholder='MM'
                dayPlaceholder='DD'
               
              />
              <SpanField classname="text-danger" title={errors.date_of_birth} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <Label title="Gender" isMandatory={true} />

              <div className="row">
                <div className="col-sm-12">
                  {genderoptions.map((option) => {
                    return (
                      <GenderField
                      id="client"
                        key={option}
                        name="gender"
                        labelcls="inputimgslct"
                        spanclass={
                          option === "Male"
                            ? "checkmark chekca1"
                            : "checkmark chekca2"
                        }
                        value={option}
                        controlFunc={this.handleInput}
                        title={option}
                      />
                    );
                  })}
                </div>
              </div>
              <SpanField classname="text-danger" title={errors.gender} />
            </div>
          </div>
          <div className="col-sm-12">
          <CheckboxField
            setName={"agreeTC"}
            className="col-sm-12"
            type={"checkbox"}
            controlFunc={this.handleCheck}
            options={agreeOptions}
            name={"agreeTC"}
            page={"client"}
            selectedOptions={ClientData.agreedOption}
          />
          </div>
          

          <div className="col-sm-12">
            <SpanField classname="text-danger" title={errors.agreeTC} />
          </div>
          <div className="col-sm-12">
          <p className="_58mv">By clicking Sign Up, you agree to our <a href="/terms" id="terms-link" target="_blank" rel="nofollow">Terms</a>, <a href="/privacy_policy" id="privacy-link" target="_blank" rel="nofollow">Privacy Policy</a>.</p>
          </div>
        
          <div className="col-sm-12 btncent">
            <div className="form-group mb-0">
              <Button id="clientsignup"
                disabled={loading}
                loading={loading}
                clsname="signupbtn abtn button"
                btntype="submit"
                title="Sign Up"
                action=""
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (user) => dispatch({ type: "SET_USERDATA", payload: user }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientSignup)
);

// export default withRouter(ClientSignup);
