import React, { Component } from "react";
import { connect } from "react-redux";
// import ProfileHeader from "../components/container/ProfileHeader";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import BusinessDashboard from "../components/container/layout/business/Dashboard";
import BusinessUserDashboard from "../components/container/layout/business_user/Dashboard";
import ClientDashboard from "../components/container/layout/client/Dashboard";
import FooterSection from "../components/UI/FooterSection";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // vitalDetails: [],
      // prescription_count: 0,
      // queue_data: [],
    };
  }

  componentDidMount = () => {
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
    // const checkinData = { client_id: this.props.userdata.id };
    // axios
    //   .post(getVitalDetail, checkinData, CONFIG)
    //   .then((res) => {
    //     let data = res.data;
    //     console.log(data);
    //     if (data.status !== "0")
    //       this.setState({
    //         vitalDetails: data.vital_detail,
    //         prescription_count: data.prescriptions,
    //       });
    //     else {
    //       this.setState({
    //         vitalDetails: [],
    //         prescription_count: data.prescriptions,
    //       });
    //     }
    //   })
    //   .catch((e) => {});
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
    // axios
    //   .post(getQEntryByClientId, { client_id: this.props.userdata.id }, CONFIG)
    //   .then((result) => {
    //     console.log(result);
    //     if (result.data.status === "1") {
    //       this.setState({ queue_data: result.data.data[0] });
    //     } else {
    //       this.setState({ queue_data: [] });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e.data);
    //     console.log("business user getbyid failed");
    //   });
  };
  render() {
    // const { vitalDetails, prescription_count, queue_data } = this.state;
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;
    return (
      <div className="main-content bacfot">
        <div className="mainpage">
          <section className="my-head2">
            <div className="container-fluid ">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessHeader user_id={this.props.userdata.id} />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserHeader user_id={this.props.userdata.id} />
              ) : (
                    <Header user_id={this.props.userdata.id} />
                  )}
            </div>
          </section>
          <div className="main-box">
            <div className="sidemenu pr0">
              {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                <BusinessUserSideBar />
              ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                <BusinessUserSideBar />
              ) : (
                    <ClientSideBar />
                  )}
            </div>

            <section className="main-body hospital-profilegap">
              <div className="container-fluid">

                <div className="row">

                  {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                    <BusinessDashboard user_id={this.props.userdata.id} />
                  ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                    <BusinessUserDashboard user_id={this.props.userdata.id} />
                  ) : (
                        <ClientDashboard user_id={this.props.userdata.id} />
                      )}
                </div>




              </div>
             
            </section>
            <FooterSection />
          </div>
         
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Dashboard);
