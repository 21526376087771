import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Spinner from "../components/UI/Spinner";
import axios from "axios";
import { CONFIG } from "../config/data";
import SpanField from "../components/UI/SpanField";
import { getPrescription, getPrescriptionHistory, getCheckinDetails,GetHistoryVitals,getClientspreviousmedicalrecords } from "../config/url.json";
import * as moment from "moment";
import DatePicker from "react-date-picker";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; // This only needs to be imported once in your app

function mapStateToProps(state) {
    return {
        access_token: state.AuthReducer.access_token,

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class ConsultationHistory extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        console.log("props")
        this.state = {
            checkinData: [],
            loading: false,
            records: [],
            selectedOption: ["0"],
            filter: "",
            result: [],
            errors: {
                Todate: "",
                Fromdate: "",
            },
            error: "",
            photoIndex: 0,
            isOpen: false,
            noImage:false,
        };
        this.columns = [
            {
                key: "medicine_description",
                text: "Medicine Description",
                className: "medicine_description",
                align: "left",
                sortable: true,
            },
            {
                key: "days",
                text: "Days",
                className: "days",
                align: "left",
                sortable: true,
            },
            {
                key: "before_food",
                text: "Before Food",
                className: "before_food",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    return record.before_food === 1 ? (
                        <div className="text-center">
                            <i className="fa fa-check-circle grnbk" aria-hidden="true" />{" "}
                        </div>
                    ) : (
                        ""
                    );
                },
            },
            {
                key: "morning",
                text: "Morning",
                className: "morning",
                sortable: true,
                align: "left",
            },
            {
                key: "noon",
                text: "Noon",
                className: "noon",
                sortable: true,
                align: "left",
            },
            {
                key: "night",
                text: "Night",
                className: "night",
                sortable: true,
                align: "left",
            },
            {
                key: "total",
                text: "Total",
                className: "total",
                sortable: true,
                align: "left",
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    componentDidMount() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        console.log(this.props.id)
        this.setState({ loading: true, filter: "month" });
        const checkinData = { id: this.props.id };
        axios
            .post(getCheckinDetails, checkinData, CONFIG)
            .then((res) => {
                let data = res.data.data || res.data.date;
                if (res.data.status !== "1" || !data) {
                    return;
                }

                this.setState({
                    checkinData: data,
                });
               
                this.getReport('month',"","");
                axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${this.props.access_token}`;
                  const postData1 = { client_id: this.state.checkinData.client_id };
                  axios
                    .post(GetHistoryVitals, postData1, CONFIG)
                    .then((res) => {
                      let data = res.data.consultantHistory || res.data.consultantHistory;
                      console.log(data);
                      console.log(res.data);
                      console.log(data.with_in_30[0].height);
                      console.log(data.with_in_30[0].weight);
                      console.log(data.with_in_30[0].bp);
                      console.log(data.with_in_30[0].pulse);
                      console.log(data.with_in_30[0].temperature);
                      console.log(data.with_in_30[0].bmi);
            
                      // if (res.data.status !== "1" || !data || !data[0]) {
                      //   return;
                      // }
                      if (res.data.status === "1") {
                        this.setState({
                          with_in_week: data.with_in_week,
                          with_in_30: data.with_in_30,
                          with_in_3_months: data.with_in_3_months,
                          with_in_6_months: data.with_in_6_months,
                          with_in_year: data.with_in_year,
                          with_in_2_years: data.with_in_2_years,
                          Historyheight: data.with_in_week.length ? data.with_in_week[0].height
                           : data.with_in_30.length ? data.with_in_30[0].height
                           : data.with_in_3_months.length ? data.with_in_3_months[0].height
                           : data.with_in_6_months.length ? data.with_in_6_months[0].height
                           : data.with_in_year.length ? data.with_in_year[0].height
                           : data.with_in_2_years.length ? data.with_in_2_years[0].height
                            : [],
                            Historyweight: data.with_in_week.length ? data.with_in_week[0].weight
                           : data.with_in_30.length ? data.with_in_30[0].weight
                           : data.with_in_3_months.length ? data.with_in_3_months[0].weight
                           : data.with_in_6_months.length ? data.with_in_6_months[0].weight
                           : data.with_in_year.length ? data.with_in_year[0].weight
                           : data.with_in_2_years.length ? data.with_in_2_years[0].weight
                            : [],
            
                            Historytemperature: data.with_in_week.length ? data.with_in_week[0].temperature
                            : data.with_in_30.length ? data.with_in_30[0].temperature
                            : data.with_in_3_months.length ? data.with_in_3_months[0].temperature
                            : data.with_in_6_months.length ? data.with_in_6_months[0].temperature
                            : data.with_in_year.length ? data.with_in_year[0].temperature
                            : data.with_in_2_years.length ? data.with_in_2_years[0].temperature
                             : [],
                             Historybmi: data.with_in_week.length ? data.with_in_week[0].bmi
                           : data.with_in_30.length ? data.with_in_30[0].bmi
                           : data.with_in_3_months.length ? data.with_in_3_months[0].bmi
                           : data.with_in_6_months.length ? data.with_in_6_months[0].bmi
                           : data.with_in_year.length ? data.with_in_year[0].bmi
                           : data.with_in_2_years.length ? data.with_in_2_years[0].bmi
                            : [],
                            Historybp: data.with_in_week.length ? data.with_in_week[0].bp
                           : data.with_in_30.length ? data.with_in_30[0].bp
                           : data.with_in_3_months.length ? data.with_in_3_months[0].bp
                           : data.with_in_6_months.length ? data.with_in_6_months[0].bp
                           : data.with_in_year.length ? data.with_in_year[0].bp
                           : data.with_in_2_years.length ? data.with_in_2_years[0].bp
                            : [],
                            Historypulse: data.with_in_week.length ? data.with_in_week[0].pulse
                           : data.with_in_30.length ? data.with_in_30[0].pulse
                           : data.with_in_3_months.length ? data.with_in_3_months[0].pulse
                           : data.with_in_6_months.length ? data.with_in_6_months[0].pulse
                           : data.with_in_year.length ? data.with_in_year[0].pulse
                           : data.with_in_2_years.length ? data.with_in_2_years[0].pulse
                            : [],
                        });
                      } else {
                        this.setState({ error: res.data.message });
                      }
                    })
                    .catch((e) => { });
        
                    axios.defaults.headers.common[
                        "Authorization"
                      ] = `Bearer ${this.props.access_token}`;
                  
                  axios
                  .post(getClientspreviousmedicalrecords,{client_id: this.state.checkinData.client_id}, CONFIG)
                  .then((ImageurlRespons) => {
                    let ImageurldataList = ImageurlRespons.data.data;
                    console.log(ImageurldataList);
                    if(ImageurldataList==undefined)
                    {
                        this.setState({ noImage:true });
                        
                    }
                   
                 /*    if(ImageurldataList.status=="1")
                    {
                     */
                    console.log(ImageurldataList);
                    let ImageurlList = [];
                    ImageurldataList.forEach((element, index) => {
                        ImageurlList.push({
                        value: element.image_url,
                        label: element.image_url,
                     
                      });
                    });
                    const stringnew=ImageurlList.map(({value})=>value)
                    
                  //  console.log(stringnew);
                  //  let stringpath=stringnew[0];
                   // let stringone=stringnew[1];
                   console.log(ImageurlList);
                
                for(var i=0;i<stringnew.length;i++){
                    this.imageSrc = stringnew[i];
                    console.log(this.imageSrc);
                    
                    }
                    this.setState({ ImageurlList,stringnew });
        //        }
                  }).catch((e) => { });
                
               
            })
            .catch((e) => { });
         
    }
    refreshData = () => {
       
        this.setState({ loading: true, filter: "month" });
        this.getReport('month',"","");
       
    };
    clear=()=>
    {
        this.state.Fromdate="";
        this.state.Todate="";
       this.setState({result:[]});
    }
    handlePrescriptionChange = (index, event) => {

        console.log(index);
        // console.log(event);
        // console.log(event.target.checked);
        let {

            // selectedOption,
            result,
            show_prescriptions,
            records,
            consultation_fee,
            business_name,
            first_name,
            last_name,
            created_at,
        } = this.state;

        records = result[index];
        console.log(records)
        show_prescriptions = result[index].show_prescriptions;
        consultation_fee=result[index].consultation_fee;
        business_name=result[index].business_name;
        first_name=result[index].first_name;
        last_name=result[index].last_name;
        created_at=result[index].created_at;


        console.log(records);
        this.setState({
            selectedOption: event.target.checked ? [index] : [],
            records: records ? records : [],
            show_prescriptions: show_prescriptions
        });
    };
    handlefilterSelect = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
        console.log(value);
        this.getReport(value, "", "");
    }
    handleFromChange = (date) => {
        const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        
        this.setState({ Fromdate: offsetDate });

        this.getReport("custom", offsetDate, this.state.Todate);
    };
    handleToChange = (date) => {
        const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        this.setState({ Todate: offsetDate });

        this.getReport("custom", this.state.Fromdate,offsetDate);
    };

    getReport = (filter, from, to) => {
        console.log(filter);
        this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        // let {Todate,Fromdate} = this.state;
        console.log(from)
        console.log(to)
        // if(filter == 'custom' && Todate != null && Fromdate != null ){
        // postData1 = { client_id: this.props.userdata.id, filter: "month" };
        // } else {
        let postData1 = { client_id: this.state.checkinData.client_id, filter: filter, start_date: from, end_date: to };
        // }

        axios
            .post(getPrescriptionHistory, postData1, CONFIG)
            .then((res) => {
                let data = res.data.consultantHistory || res.data.consultantHistory;
                console.log(data);
                console.log(res.data);
                // if (res.data.status !== "1" || !data || !data[0]) {
                //   return;
                // }
                if (res.data.status === "1") {
                    console.log(data);
                    this.setState({
                        result: data,

                        records: (data.length)
                            ? data
                            : [],
                        // show_prescriptions: data.length ? data.show_prescriptions : true,
                        show_prescriptions: true,
                        selectedOption: [],
                        loading: false

                    });
                } else {
                    this.setState({ error: res.data.message, loading: false });
                }
            })
            .catch((e) => { });
    }

    render() {
        const {
            loading,
            result,
            selectedOption,
            records,
            errors,
            Historyheight,
            Historyweight,
            Historytemperature,
            Historybmi,
            Historybp,
            Historypulse,
            stringnew,
            photoIndex,
             isOpen,
             noImage
        } = this.state;
        // console.log(with_in_week.length);
        return (
            <Fragment>
                <div className="col-sm-8 pr0">
                    {/* <Spinner loading={loading} /> */}
                   
                    <div className="row">
                    
                        <div className="col-sm-6 pr0">
                            <div className="card">
                            
                            <div class="card-header"> <div class="card-title">Discharge summary</div> </div>
                                <div className="card-body h393">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                 
                                                    <p>Diagnosis: <strong>{records.reason_for_visit ? records.reason_for_visit.map(e => e.label).join(', ') : ""}</strong></p>
                                                    <p>Reason for visit: <strong>{records.other_comments}</strong></p>
                                                    <p>Prescription:</p>
                                                    {records.medicine_description ? records.medicine_description.map((element, index) => {
                                                        return (
                                                            <li key={index} className="">
                                                                <strong>{element.medicine_description}</strong></li>)
                                                    }) : ""}
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                            <div class="card-header"> <div class="card-title">Client Old Medical Images</div> </div>
                                <div className="card-body  h393">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                            <div className="row">
                                                <div className="col-sm-12">
                                             
                                                <div>
                                                   
        <button type="button" disabled={noImage} onClick={() => this.setState({ isOpen: true })}>
          Click to Open Medical Images
        </button>

        {isOpen && (
          <Lightbox
            mainSrc={stringnew[photoIndex]}
            nextSrc={stringnew[(photoIndex + 1) % stringnew.length]}
            prevSrc={stringnew[(photoIndex + stringnew.length - 1) % stringnew.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + stringnew.length - 1) % stringnew.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % stringnew.length,
              })
            }
          />
        )}
      </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 pr0">
                            <div className="card">
                            <div class="card-header"> 
                            <div class="card-title">Previous Vital details</div> 
                            <div>
                           
                            </div>
                            </div>
                                <div className="card-body  pb-12 h393">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                  
                                                    <div className="row">
                                                        <div class="cpag w-40 ml25 m-16 ">
                                                            <div class="card vitals ">
                                                                <div class="card-body pt ">
                                                                    <div className="text-left titleFlex justify-content-between s-118">
                                                                        <h5 className="border-0 small mb-0">Height
                                                                          
                                                                        </h5>
                                                                        <img class=""
                                                                                src={require("../assets/images/vitaldetails1.png")} />
                                                                    </div>

                                                                    <div class="input-group input-group-sm mt-2">
                                                                        <input type="text" class="dis-inlnflx form-control" disabled name="height" value={(records.height==null)?Historyheight:records.height} />
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">cm </span>
                                                                        </div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cpag w-40">
                                                            <div class="card vitals">
                                                                <div class="card-body pt">
                                                                    <div className="text-left titleFlex justify-content-between s-118">
                                                                        <h5 className="border-0 small mb-0">Weight
                                                                        
                                                                        </h5>
                                                                        <img class=""
                                                                                src={require("../assets/images/vitaldetails2.png")} />
                                                                    </div>
                                                                    <div className="input-group input-group-sm mt-2">
                                                                        <input type="text" class="dis-inlnflx form-control" disabled name="weight" value={(records.weight==null)?Historyweight:records.weight} />
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">kg </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div class="cpag w-40 ml25 m-16">
                                                            <div class="card vitals">
                                                                <div class="card-body pt  ">
                                                                    <div className="text-left titleFlex justify-content-between s-118">
                                                                        <h5 className="border-0 small mb-0">Temp
                                                                         
                                                                        </h5>
                                                                        <img class=""
                                                                                src={require("../assets/images/vitaldetails3.png")} />
                                                                    </div>
                                                                    <div class="input-group input-group-sm mt-2">
                                                                        <input type="text" class="dis-inlnflx form-control" disabled name="temperature" value={(records.temperature==null)?Historytemperature:records.temperature} />
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">F </span>

                                                                        </div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cpag w-40">
                                                            <div class="card vitals">
                                                                <div class="card-body pt">
                                                                    <div className="text-left titleFlex justify-content-between s-118">
                                                                        <h5 className="border-0 small mb-0">BMI
                                                                           
                                                                        </h5>
                                                                        <img class=""
                                                                                src={require("../assets/images/bmi_icon.png")} />
                                                                    </div>
                                                                    <div class="input-group input-group-sm mt-2">
                                                                        <input class="form-control" name="bmi" disabled type="text" value={(records.bmi==null)?Historybmi:records.bmi} />
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text" id="basic-addon2">bmi</span>

                                                                        </div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row ">
                                                        <div class="cpag w-40 ml25 m-16 ">
                                                            <div class="card vitals">
                                                                <div class="card-body pt">
                                                                    <div className="text-left titleFlex justify-content-between s-118">
                                                                        <h5 className="border-0 small mb-0">BP
                                                                         
                                                                        </h5>
                                                                        <img class=""
                                                                                src={require("../assets/images/vitaldetails4.png")} />
                                                                    </div>
                                                                    <div class="input-group input-group-sm mt-2">
                                                                        <input type="text" class="dis-inlnflx form-control" name="bp" disabled value={(records.bp==null)?Historybp:records.bp} />
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">mmHg </span>
                                                                        </div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cpag w-40">
                                                            <div class="card vitals">
                                                                <div class="card-body pt">
                                                                    <div className="text-left titleFlex justify-content-between s-118">
                                                                        <h5 className="border-0 small mb-0">Pulse
                                                                       
                                                                        </h5>
                                                                        <img class=""
                                                                                src={require("../assets/images/vitaldetails5.png")} />
                                                                    </div>
                                                                    <div class="input-group input-group-sm mt-2">
                                                                        <input type="text" class="dis-inlnflx form-control" disabled name="pulse" value={(records.pulse==null)?Historypulse:records.pulse} />
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">per min</span>
                                                                        </div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                        <div className="text-center">
                                  <button className=" btn btn-primary" onClick={this.refreshData}>Refresh
                                  <i className="fa fa-refresh ml-1">
                                  </i></button>
    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                            <div class="card-header"> <div class="card-title">Others</div> </div>
                                <div className="card-body h393">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                            <div className="row">
                                                <div className="col-sm-12">
                                               
                                                                                            <p><label>Hospital Name: <strong>{records.business_name}</strong></label></p>
                                                                                            <p><label>Doctor Name: <strong>{records.first_name}&nbsp;{records.last_name}</strong></label></p>
                                                                                            <p><label>Date of Visit: <strong>{records.created_at}</strong></label></p>
                                                                                            <p><label>Follow up Days: <strong>{records.follow_up_details ? records.follow_up_details : ""}</strong></label></p>
                                                                                            <p><label>Amount: <strong>{records.consultation_fee}</strong></label></p>
                                                                                           
                                                                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                 


                </div >
                
                <div className="col-sm-4 pl0">
              
                    <div className="card">
                    <div class="card-header"> <div class="card-title">Prescription</div> </div>
                        <div className="card-body Presystemresultscrol h393 pt-0">
                            <div id="accordion">
                                <div>
                                  
                                    <div
                                        id="s1"
                                        className="collapse show"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body ese ">
                                            <div className="row mb-2">
                                                <div className="col-sm-3">
                                                    <div className="form-check fltx">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="Monthly"
                                                            name="filter"
                                                            checked={this.state.filter === "month"}
                                                            value="month"
                                                            onChange={this.handlefilterSelect}

                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="Monthly"
                                                        >
                                                            Monthly
                                                        </label>
                                                    </div>

                                                 
                                                </div>
                                                <div className="col-sm-3">
                                                <div className="form-check fltx">

<input
    checked={this.state.filter === "custom"}
    className="form-check-input"
    type="radio"
    id="Custom"
    value="custom"
    name="filter"

    onChange={this.handlefilterSelect}


/>
<label
    className="form-check-label"
    htmlFor="Custom"
>
    Custom
</label>

</div>
                                                </div>
                                            </div>
                                            {(this.state.filter === "custom") ?
                                                (<div className="row">
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <label>From Date</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <DatePicker
                                                                className="form-control pickerclass"
                                                                value={this.state.Fromdate}
                                                                format="dd-MM-yyyy"
                                                                onChange={(date) =>
                                                                    this.handleFromChange(date)
                                                                }
                                                                placeholder={"Select the From date"}
                                                                yearPlaceholder='YYYY'
                                                                monthPlaceholder='MM'
                                                                dayPlaceholder='DD'
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.Fromdate}
                                                        />
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div>
                                                            <div>
                                                                <label>To Date</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <DatePicker
                                                                    className="form-control pickerclass"
                                                                    value={this.state.Todate}
                                                                    format="dd-MM-yyyy"
                                                                    onChange={(date) =>
                                                                        this.handleToChange(date)
                                                                    }
                                                                    placeholder={"Select the From date"}
                                                                    yearPlaceholder='YYYY'
                                                                    monthPlaceholder='MM'
                                                                    dayPlaceholder='DD'
                                                                />
                                                            </div>
                                                            <SpanField
                                                                classname="text-danger"
                                                                title={errors.Todate}
                                                            />
                                                        </div>

                                                        <button className=" btn btn-primary float-right" onClick={this.clear}>Clear
                                  <i className="fa ml-1">
                                  </i></button>


                                                    </div>

                                                </div>) : ""
                                            }
                                            {result.length
                                                ? result.map((history, index) => {
                                                    return (
                                                        <div className="custom-control custom-checkbox notificatincls">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={"with_in_week" + index}
                                                                checked={
                                                                    selectedOption.indexOf(
                                                                        index
                                                                    ) > -1
                                                                }
                                                                onChange={this.handlePrescriptionChange.bind(
                                                                    this,

                                                                    index
                                                                )}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={
                                                                    "with_in_week" + index
                                                                }
                                                            ><strong>
                                                                    {moment(
                                                                        history.created_at
                                                                    ).format("DD MMM")}
                                                                     - 
                                                                    {history.other_comments
                                                                        ? history.
                                                                        other_comments
                                                                        : ""}
                                                                </strong> </label>
                                                        </div>
                                                    );
                                                })
                                                : (<div className="custom-control custom-checkbox notificatincls">No record found</div>)}
                                        </div>
                                    </div>
                                </div>
                               
                               
                               
                               
                               
                            </div>
                        </div>
                    </div>
                </div>


                {/* <Footerad />


                <FooterSection /> */}
            </Fragment>
        );

    }
}

export default connect(
    mapStateToProps,
)(ConsultationHistory);