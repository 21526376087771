const ValidityReducer = (state = {}, actions) => {
    switch (actions.type) {
        case "SET_VALIDITY":
            return { validity: actions.payload };
          /*   case "SET_ADD_DOCTOR_VALIDITY":
                return { adddoctorvalidity: actions.payload }; */
    
        default:
            return state;
    }
};

export default ValidityReducer;
