import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import FooterSection from "../../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
// import BusinessUserSideBar from "../../components/container/SideBar";
// import Header from "../../components/container/layout/business/Header";
import InputField from "../../components/UI/InputField";
import SpanField from "../../components/UI/SpanField";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PersonIcon from "@material-ui/icons/Person";
import { Popup } from "../../components/UI/Popup";
import Label from "../../components/UI/Label";
import {
  getAllFacilities,
  addRoom,
  updateRoom,
  updateRoomAvailability,
  deleteRoom,
  getAllRooms,
  getTimeoutbycategory,
  getclientbyphonenumber,
  getDocByBusiness,getBusinessById,getAllBusinessUserList,admitPatient
} from "../../config/url.json";
import * as moment from "moment";

import {
  getDateISOFormat,
  getPatientProfileColor,
  calculate_age,setDaysoftheWeek
} from "../../constants/globalLevelFunctions";
import { CONFIG } from "../../config/data";
import Select from "react-select";
import Validation from "../../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

const availabilityOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const roomStatus = [
  { value: "Cleaned", label: "Cleaned" },
  { value: "Sanitised", label: "Sanitised" },
  { value: "Not-cleaned", label: "Not-cleaned" },
  { value: "In-progress", label: "In-progress" },
  
];

const initalValue = {
  from: "",
  to: "",
  totalDays: 0,
  patient_id:"",
  doctor_id:"",
  selectedDoc: "",
  
  errors: {
    selectedDoc: "",
    shift: "",
    to: "",
    patient_id:"",
    doctor_id:"",
    from: "",
    
  },
};
class Rooms extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      searchData:{phone_number:"",error:""},
      patientBasicData:[],
      all_nurse:[],
      selectedPerson:"",
      room_id:[],
      admitdata: initalValue,
      doctor_list: [],
      selected_doc: {},
      selected_nurse:{},
      openModal: false,
      selectedRoom:{},
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      records: [],
      addPopup: false,
      actions: "add",
      facilityCategory: [],
      roomData: {
        id: "",
        room_id: "",
        description: "",
        facilities:"",
        status:"",
        price_per_day:"",
        is_available:""
      },

      errors: {
        room_id: "",
        description: "",
        facilities:"",
        status:"",
        price_per_day:"",
        is_available:""
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "room_id",
        text: "Room ID",
        className: "room_id",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "description",
      //   text: "Description",
      //   className: "description",
      //   align: "left",
      //   sortable: true,
      // },

      {
        key: "facilities_name",
        text: "Facilities",
        className: "facilities_name",
        sortable: true,
        align: "left",
      },
      {
        key: "status",
        text: "Room Status",
        className: "status",        
        sortable: true,
        align: "left",
      },
      {
        key: "price_per_day",
        text: "Price Per Day",
        className: "price_per_day",        
        sortable: true,
        align: "left",
      },
      {
        key: "admit_date",
        text: "Admission date",
        className: "admit_date",        
        sortable: true,
        align: "left",
        cell: (record) => {
         
          return (
            record.is_patient_available == "1" ? record.admit_date:""
          );
        },
      },
      {
        key: "discharge_date",
        text: "Discharge date",
        className: "discharge_date",        
        sortable: true,
        align: "left",
        cell: (record) => {
         
          return (
            record.is_patient_available == "1" ? record.discharge_date:""
          );
        },
      },

      {
        key: "is_available",
        text: "Availability",
        className: "is_available",
        sortable: true,
        align: "left",
        cell: (record) => {
          let obj = {
            label: record.is_available,
            value: record.is_available,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="is_available"
              value={obj}
              onChange={(selectedOption) => this.handleColumnChange(record,selectedOption)}
              options={availabilityOptions}
            />
          );
        },
      },
      

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <Tooltip title="Edit">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              </Tooltip>
              {/* <Tooltip title="Delete">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
              </Tooltip> */}
              <Tooltip title="Admit">
                <button onClick={() => {
                                          let admitdata = this.state.admitdata;
                                          admitdata = record;
                                          let searchData = this.state.searchData;
                                          searchData.phone_number="";
                                          console.log(record);
                                          
                                          // admitdata.from = moment(record.admit_date).format('dd-mm-yyyy');
                                          // admitdata.to = getDateISOFormat(record.discharge_date);
                                          admitdata.errors = {};
                                          this.setState({
                                            selectedRoom:record,
                                            admitdata,
                                            searchData,patientBasicData:[]
                                          });
                                          this.setState({ openModal: true, });
                                        }}
                                        className="btn btn-primary btn-sm "
                                        style={{ marginRight: "5px",position:"absolute",float:"left" }} >Admit</button>
              </Tooltip>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
   
};
closePopup = (e) => {
  this.setState({
    openModal: false,
    editModel: false,
    deleteConfirm: false
  });
};
  _onAction(e) {
    
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
  }

  UpdateColumnChange = (data) => {
    
    // let data = this.state.roomData;
      data.business_id = this.props.userdata.id;
      
      data.is_available = data.is_available;
      data.id = data.id;
      // data.description = data.description;
      // data.price_per_day = data.price_per_day;
      // data.status = data.status;
      // data.facilities = data.facilities;
      // console.log(data);return false;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios.post(updateRoomAvailability, data, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "id", data.id);

      
      // console.log(records[recIndex].available);
      // if (records[recIndex].availability === "No") {
      //   records[recIndex].available = "0";
      //   records[recIndex].occupied = records[recIndex].total;
      // }

      records[recIndex].is_available = data.is_available;
      
      records[recIndex].id = data.id;

      this.setState({ records, tableSuccess: list.data });
      this.clearMessage();
    });
  };
  consultPatient = (e) => {
    let selectedRoom = this.state.selectedRoom;
    window.location.href="/inpatient/"+selectedRoom.id;
  }
  admitpatient = (e) => {
    let data = this.state.admitdata;
    let errors = this.state.admitdata.errors;
    let formIsValid=true; errors.doctor_id="";errors.patient_id="";
    errors.from="";errors.to="";
    
    console.log(data);
    if(this.state.selected_doc.value == ""){
      errors.doctor_id = "Please choose doctor";
      formIsValid=false;
    }
    if(this.state.selectedPerson == ""){
      errors.patient_id = "Please choose patient by search by mobile number";
      formIsValid=false;
    }
    if(data.from == ""){
      errors.from = "Please select admission date";
      formIsValid=false;
    }
    if(data.to == ""){
      errors.to = "Please select discharging date";
      formIsValid=false;
    }
   
   
   
if(formIsValid){
  let selectedRoom = this.state.selectedRoom;
  data.doctor_id = this.state.selected_doc.value;
  data.nurse_id = this.state.selected_nurse.value;
  data.patient_id = this.state.selectedPerson;
  data.room_id = selectedRoom.id;
  data.admit_date = getDateISOFormat(data.from);
  data.discharge_date = getDateISOFormat(data.to);
  console.log(data); // return false;

  data.action_type = "admit";
  data.status = "Admitted";
  axios.post(admitPatient, data, CONFIG).then((list) => {
    if ("0" === list.data.status) {
      this.setState({ loading: false });
      this.setState({ errors: { room_id: list.data.message } });
    } else {
      this.getAllrooms();
      selectedRoom.is_patient_available="1";

    //  this.setState({ formSuccess: list.data });
      toast.success("Patient admitted Successfully.",{
        className:"toast-success"
      });
      this.setState({ loading: false,selectedRoom });
      this.clearMessage();
      this.closePopup();
      this.cancel();
    }
  });
}

    

  }
  UpdateFacility = (e) => {
    e.preventDefault();
    let { roomData, errors } = this.state;
    roomData.page = "Rooms";
    this.setState({ loading: true });
    errors = Validation(roomData, errors);
    this.setState({ errors: errors });
    // console.log(roomData,errors);return false;
    if (errors.formIsValid) {
      let data = this.state.roomData;
      data.business_id = this.props.userdata.id;
      data.is_available = data.is_available.value.toString();
      data.room_id = data.room_id;
      data.description = data.description;
      data.price_per_day = data.price_per_day;
      data.status = data.status.label;
      data.facilities = data.facilities;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      let url = this.state.actions === "add" ? addRoom : updateRoom;
      if (this.state.actions === "add") {
        axios.post(url, data, CONFIG).then((list) => {
          console.log(list);
          if ("0" === list.data.status) {
            this.setState({ loading: false });
            this.setState({ errors: { facility_id: list.data.message } });
          } else {
            // let addFacility = {};
            // addFacility.room_id = data.room_id.label;
            // addFacility.room_data = {label:data.room_id.label,value:data.room_id.value};
            // addFacility.description = data.description;
            // addFacility.is_available = data.is_available;
            // addFacility.price_per_day = data.price_per_day;
            // // addFacility.facilities = data.facilities;
            // addFacility.id = list.data.data[0].id;
            // addFacility.status = data.status;
            
            // let records = this.state.records;
            // addFacility.s_no = records.length + 1;


            // let fac=[];
            // data.facilities.forEach((element,k) => {
              
            //   fac.push(element.label);
            // });
            // console.log(fac);
            // addFacility.facilities_name = fac.toString();


            // records.push(addFacility);
            this.getAllrooms();
            // this.setState({ records });
           // this.setState({ formSuccess: list.data });
            toast.success("Room Added Successfully.",{
              className:"toast-success"
            });
            this.setState({ loading: false });
            this.clearMessage();
            this.cancel();
          }
        });
      } else {
        console.log(data);
        axios.put(url, data, CONFIG).then((list) => {
          if (0 === list.data.status) {
            this.setState({ loading: false });
            this.setState({ errors: { room_id: list.data.message } });
          } else {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].room_id = data.room_id.label;
            records[recIndex].room_data = {label:data.room_id.label,value:data.room_id.value};
            records[recIndex].description = data.description;
            records[recIndex].is_available = data.is_available;
            records[recIndex].price_per_day =
              data.price_per_day;
            
            records[recIndex].id = data.id;
            records[recIndex].status = data.status;

            let fac=[];
            data.facilities.forEach((element,k) => {
              
              fac.push(element.label);
            });
            console.log(fac);
            records[recIndex].facilities_name = fac.toString();


            this.setState({ records });
          //  this.setState({ formSuccess: list.data });
            toast.success("Room Updated Successfully.",{
              className:"toast-success"
            });
            this.setState({ loading: false });
            this.clearMessage();
            this.cancel();
          }
        });
      }
    }
  };

  clearErrors = (errors) => {
    errors.facility_id = "";
    errors.facility_name = "";
    errors.facility_category = "";
    errors.occupied = "";
    errors.availability = "";
    errors.available = "";
    errors.total = "";
    return errors;
  };

  cancel = () => {
    let data = this.state.roomData;
    data.room_id = "";
    data.description = "";
    data.id = "";
    data.business_id = "";
    data.facilities = "";
    data.status = "";
    data.price_per_day = "";
    data.is_available = "";

    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ roomData: data, actions: "add", errors: errors });
  };

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
        }),
      5000
    );
  };

  editRecord(record) {
    console.log(record);
    let roomData = this.state.roomData;
    roomData.room_id = record.room_data;
    roomData.facilities = record.facilities;

    roomData.description = record.description;
    roomData.id = record.id;
    roomData.business_id = record.business_id;
    roomData.status = {
      label: record.status,
      value: record.status,
    };
    roomData.is_available = {
      label: record.is_available,
      value: record.is_available
    };
    roomData.price_per_day = record.price_per_day;
    // roomData.available = record.available;
    // roomData.total = record.total;

    let errors = this.state.errors;
    errors = this.clearErrors(errors);

    this.setState({
      roomData: roomData,
      actions: "edit",
      errors: errors,
    });
  }
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getAllRooms, { business_id: this.props.userdata.id }, CONFIG)
        .then((list) => {
          let facilitieslist = list.data.data;
  
          facilitieslist.forEach((element, index) => {
            console.log(element);
            let facility = {};
            facility.s_no = index + 1;
            facility.room_id = element.room_id.label;
            facility.room_data = element.room_id;
            facility.admit_date = element.admit_date;
            facility.discharge_date = element.discharge_date;
            facility.is_available = element.is_available;
            facility.price_per_day = element.price_per_day;
            facility.description = element.description;
            facility.is_patient_available = element.is_patient_available;
            facility.doctor_name = element.doctor_name;
            facility.patient_name = element.patient_name;
            facility.nurse_name = element.nurse_name;
            facility.inpatient_id = element.inpatient_id;
            facility.status = element.status;
            facility.facilities = element.facilities;
            facility.business_id = element.business_id;
          console.log(element);
          let fac=[];
            element.facilities.forEach((element,k) => {
              
              fac.push(element.label);
            });
            console.log(fac);
            facility.facilities_name = fac.toString();
            facility.id = element.id;
            
  
            facilitieslist[index] = facility;
          });
  
       
          this.setState({records:facilitieslist});
        })
        .catch((e) => {console.log(e) });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllFacilities, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
       
        let facilityCategory = list.data.data;

        let facilityCategorylist = [];
        let room_id = [];

        facilityCategory.forEach((element, index) => {
          console.log(element);
          if(element.FacilityCategoryId === 5){ // allow only rooms facilities
            facilityCategorylist.push({
              value: element.id,
              label: element.FacilityName,
            });
          }

          if(element.FacilityCategoryId === 2){ // allow only rooms facilities
            room_id.push({
              value: element.id,
              label: element.FacilityName,
            });
          }
          
        });

        // let clientmedicinehistory = {};
        this.setState({ facilityCategory: facilityCategorylist,room_id:room_id });
        
      })
      .catch((e) => { });

     
      axios
        .post(
          getAllBusinessUserList,
          { business_id: this.props.userdata.id },
          CONFIG
        )
        .then((list) => {
          let users = [];let selected_nurse={};
          let userlist = list.data.data;
          userlist.forEach((element, index) => {
            if(element.designation == "Nurse"){
              users.push({
                value: element.user_id,
                label: element.first_name,
              });

            }
              
            
          });
          selected_nurse = users.length ? users[0]:{};
          this.setState({ all_nurse: users,selected_nurse: selected_nurse });
        })
        .catch((e) => { console.error(e) });

        

      const data = {
        n_business_id: this.props.userdata.id,
        n_currentdate: getDateISOFormat(new Date()),
      };
  
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getDocByBusiness, data, CONFIG)
        .then((result) => {
          let resData = result.data.data;
          console.log(resData);
          let doctor_appointment_detail_list = [];
          let docList = [];
  
          resData.forEach((element, index) => {
            // if (element.schedule_type === "Queue" || element.schedule_type === "Q") {
              let doctor_obj = {};
  
              doctor_obj.doc_name = element.doctor_name;
              doctor_obj.business_user_id = element.business_user_id;
              doctor_obj.schedule_day_id = element.schedule_day_id;
              doctor_obj.schedule_id = element.schedule_id;
              doctor_obj.appt_id = element.appt_id;
              doctor_obj.doctor_Appointments = element.doctor_Appointments;
  
              doctor_appointment_detail_list.push(doctor_obj);
              docList.push({
                value: element.business_user_id,
                label: element.doctor_name,
              });
  
            // }
  
          });
  
          this.setState({
            doctor_list: docList,
            doctor_appointment_detail_list: doctor_appointment_detail_list,
          });
  
          //By default select first doctor
          let selected_doc = docList[0];
          let selected_appointment = [];
  
          //Based on default doctor selection select corresponding appointment details
          doctor_appointment_detail_list.forEach((element, index) => {
            if (element.business_user_id === docList[0].value) {
              selected_appointment = element.doctor_Appointments;
            }
          });
  
          this.setState({
            selected_doc: selected_doc,
            selected_doctor_appointment_detail_list: selected_appointment,
          });
  
          axios
            .post(
              getBusinessById,
              { business_id: this.props.userdata.id },
              CONFIG
            )
            .then((response) => {
              let responseData = response.data;
              if (responseData.status === "1") {
                let data = responseData.data[0];
  
                this.setState({
                  businessdata: data,
                });
                this.setState({ loading: false });
              }
            });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });

    
  };
  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });
  }
  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteRoom, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
       // this.setState({ tableSuccess: res.data });
        toast.error("Room deleted.",{
          className:"toast-error"
        });
        this.clearMessage();
      })
      .catch((e) => { });
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.roomData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    errors[e.name] = "";
    this.setState({ roomData: data });
  };
  handleselectavailabilityChange= (selectedOptions, e) => {
    
    let data = this.state.roomData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    errors[e.name]="";
    this.setState({ roomData: data,errors });
  }
  handleColumnChange = (record,selectedOption) => {
    console.log(record); //return false;
    console.log(selectedOption.label); //return false;
   
    record.is_available = selectedOption.label;
    
    
    this.UpdateColumnChange(record);
  };

  validateMaxLength(name, value, max) {
    let data = this.state.roomData;
    let errors = this.state.errors;
    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ roomData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.roomData;
    let name = e.target.name;
    let value = e.target.value;
    console.log(name, value);
    let max = e.target.max;
    //const re = /^[0-9\b]+$/;
   let errors=this.state.errors;

    
      data[name] = value;
      errors[name] = ""
    

    this.setState({ roomData: data,errors });
  };
  handlePhoneInput = (event) => {
    event.preventDefault();
    let data = this.state.searchData;
    
    data[event.target.name] = event.target.value
      .replace(/\s/g, "")
      .replace(/[^0-9+]+/g, "");
    data.error = "";
    this.setState({ searchData: data });
  };
  getPatientDetail = () => {
    let searchData = this.state.searchData;
    if (searchData.phone_number === "") {
      searchData.error = "Phone number is required";
      this.setState({ searchData: searchData });
      return;
    }
    // if (searchData.phone_number.length !== 10) {
    //   searchData.error = "Enter a valid phone number";
    //   this.setState({ searchData: searchData });
    //   return;
    // }


    // if (searchData.phone_number !== "" && searchData.phone_number.length === 10) {
    if (searchData.phone_number !== "") {
      this.setState({ loading: true });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          getclientbyphonenumber,
          { phone_number: searchData.phone_number },
          CONFIG
        )
        .then((result) => {
          this.setState({ loading: false });
          // this.handleReset();
          if (result.data.status === "1") {
            let data = result.data.client;
            data.reason_for_visit = "";

            data.queue_data = result.data.queue_data;
            data.gender = { label: data.gender, value: data.gender };
            data.blood_group = {
              label: data.blood_group,
              value: data.blood_group,
            };

            // data.date_of_birth = moment(
            //   data.date_of_birth,
            //   moment.defaultFormat
            // ).toDate();

            if (!data.relationship) {
              data.relationship = {
                label: "self",
                value: "self",
              };
            } else {
              data.relationship = {
                label: data.relationship,
                value: data.relationship,
              };
            }

            let family_details = result.data.family_details;

            searchData.error = "";
            this.setState({
              family_details: family_details,
              searchData: searchData,
              // searchSuccess: result.data,
              is_add_btn: false,
            });
           /*  toast.success("Patient family members listed. ", {
              className: "toast-success"
            }); */
            //By default select the parent profile picture in navigation icon
            let patientBasicData = [];
            patientBasicData.push(data);
            //Family Details

            family_details.map((data, i) => {

              if (data.queue_data.length) {
                data.queue_data = data.queue_data[0];
              }
              patientBasicData.push(data);
            });
console.log(patientBasicData);
            this.setState({
              patientBasicData: patientBasicData,
              selectedPerson: result.data.client.user_id,
            });

            //If queue data is present then show the paitent selected doctor
            let selected_doc = this.state.selected_doc;
            console.log(selected_doc);
            let is_queued = false;
            let is_check_in = false;
            let expected_time = "";
            console.log(data.queue_data);
            if (data.queue_data.length != 0) {
              console.log(data.queue_data);
              is_queued = true;
              is_check_in = data.queue_data.status === "Check-In";
              expected_time = data.queue_data.expected_time;
              let q_doctor_id = data.queue_data.business_user_id;

              this.state.doctor_list.map((data, i) => {
                if (data.value === q_doctor_id) {
                  selected_doc = data;
                }
              });

              data.height = data.queue_data.height;
              //  data.reason_for_visit = data.queue_data.reason_for_visit;
              console.log(data.queue_data);
              data.weight = data.queue_data.weight;
              data.bmi = data.queue_data.bmi;
              data.temperature = data.queue_data.temperature;
              data.bp = data.queue_data.bp;
              data.pulse = data.queue_data.pulse;
              data.queue_number = data.queue_data.preference;
              data.reason_for_visit = data.queue_data.reason_for_visit;
              data.symptoms = data.queue_data.symptoms;
              data.other_comments = data.queue_data.other_comments;

              data.checkin_category = {
                label: data.queue_data.queue_type ? data.queue_data.queue_type : 'Normal',
                value: data.queue_data.queue_type ? data.queue_data.queue_type : 'Normal',
              };



            } else {
              data.checkin_category = {
                label: "Normal",
                value: "Normal",
              };
            }
            console.log(data)
            this.setState({
              clientData: data,
              selected_doc: selected_doc,
              is_queued: is_queued,
              is_check_in: is_check_in,
              expected_time: expected_time,
              selectedPerson: result.data.client.user_id,
            });
            // this.getQueueList();
            // this.clearMessage();
           
          } else {
            searchData.error = result.data.message;
            this.setState({
              searchData,
              is_add_btn: true,
              clientData: {},
              patientBasicData: [],
              loading: false,
              selectedPerson: "",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          console.log(e.data);
          console.log("business user getbyid failed");
          //          searchData.error = e.data.message;
          this.setState({ loading: false });
        });
    }
  };
  handleDOBChange = (e, element) => {
    if ("from" === element || "to" === element) {
      this.setState({
        [element]: e,
      });
    } else {
      let admitdata = this.state.admitdata;
      let errors=this.state.admitdata.errors;
      if ("admitdata.from" === element) {
        admitdata.from = e;
        errors.from="";
      } else {
        admitdata.to = e;
        errors.to="";
      }


      this.setState({
        admitdata
      });

      //this will set the daysof the week name in createshift
      setDaysoftheWeek(admitdata, e);

      //Calculate the date difference
      const diff = moment(this.state.admitdata.to).diff(
        moment(this.state.admitdata.from)
      );
      const diffDuration = moment.duration(diff);

      admitdata.totalDays = isNaN(diffDuration.days())
        ? 0
        : diffDuration.days() + 1;


      this.setState({
        admitdata,
      });
    }
  };
  handleChange = (event, newValue) => {
    // this.handleReset();
    this.setState({
      selectedPerson: newValue,
    });
    console.log(newValue);
    
  };
  dischargePatient = () => {
let selectedRoom = this.state.selectedRoom;
if(selectedRoom.inpatient_id===null){
  
  return false;
}

let data = this.state.admitdata;
data.doctor_id = this.state.selected_doc.value;
  data.nurse_id = this.state.selected_nurse.value;
  data.patient_id = this.state.selectedPerson;
  data.room_id = this.state.selectedRoom.id;
  // data.admit_date = getDateISOFormat(data.from);
  // data.discharge_date = getDateISOFormat(data.to);
  console.log(data); // return false;

  data.action_type = "discharge";
  data.status = "Discharged";
  console.log(data);
  axios.post(admitPatient, data, CONFIG).then((list) => {
    if ("0" === list.data.status) {
      this.setState({ loading: false });
      this.setState({ errors: { room_id: list.data.message } });
    } else {
      this.getAllrooms();
    //  this.setState({ formSuccess: list.data });
      toast.success("Patient discharged Successfully.",{
        className:"toast-success"
      });
      this.setState({ loading: false });
      this.clearMessage();
      this.closePopup();
      this.cancel();
    }
  });


  }
  getAllrooms=()=>{
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllRooms, { business_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let facilitieslist = list.data.data;

        facilitieslist.forEach((element, index) => {
          let facility = {};
          facility.s_no = index + 1;
          facility.room_id = element.room_id.label;
          facility.room_data = element.room_id;
          facility.admit_date = element.admit_date;
          facility.discharge_date = element.discharge_date;
          facility.is_available = element.is_available;
          facility.price_per_day = element.price_per_day;
          facility.description = element.description;
          facility.is_patient_available = element.is_patient_available;
          facility.doctor_name = element.doctor_name;
          facility.patient_name = element.patient_name;
          facility.nurse_name = element.nurse_name;
          facility.inpatient_id = element.inpatient_id;
          facility.status = element.status;
          facility.facilities = element.facilities;
          facility.business_id = element.business_id;
        console.log(element);
        let fac=[];
          element.facilities.forEach((element,k) => {
            
            fac.push(element.label);
          });
          console.log(fac);
          facility.facilities_name = fac.toString();
          facility.id = element.id;
          

          facilitieslist[index] = facility;
        });

     
        this.setState({records:facilitieslist});
      })
      .catch((e) => {console.log(e) });
  }
  handleselectnurseChange = (selectedOptions, e) =>{
    this.setState({selected_nurse: selectedOptions});
  }
  handleselectDocChange = (selectedOptions, e) => {
    this.setState({selected_doc: selectedOptions});
  }
  render() {
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    const {
      roomData,
      facilityCategory,
      errors,
      formSuccess,
      tableSuccess,
      hospitaltimeout,
      selectedRoom,
      admitdata,
      selected_doc,doctor_list,room_id,selected_nurse,all_nurse
    } = this.state;
    return (
      <div className="col-sm-12 checkout-tabs">
   <IdleTimeOutModal showModal={this.state.showModal} handleClose={this.handleClose} handleLogout={this.handleLogout} />
   <IdleTimer ref={ref=> { this.idleTimer = ref }} element={document} onActive={this.onActive} onIdle={this.onIdle} onAction={this.onAction} debounce={250} timeout={hospitaltimeout} /> <h4 className="font-weight-bold"> Room List </h4>
     <div className="row mt-4">
       <div className="col-sm-12">
         <div className="card">
           <div className="card-body ">
             <div className="tab-content" id="v-pills-tabContent">
               <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                 <div className="user-notification">
                   <SpanField classname={ 0===tableSuccess.status ? "text-danger user-notification" : "text-success user-notification" } title={tableSuccess.message} />
                   <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
                 </div>
                 <ReactDatatable config={this.config} records={this.state.records} columns={this.columns} className="table table-bordered table-striped kas  mobile_table" />
                 <Popup show={this.state.addPopup} onHide={PopupClose} onConfirm={PopupConfirm} title={"Confirmation"} message={"Are you sure you want to delete this record ?"} />
               </div>
             </div>
           </div>
         </div>
       </div>
       <div className="col-sm-12 pr0">
         <div className="card b-12-hite">
           <div className="card-header">
             <div className="card-title"> {this.state.actions === "add" ? "Add" : "Edit"} Room</div>
           </div>
           <div className="card-body">
             <div className="user-notification">
               <SpanField classname={ 0===formSuccess.status ? "text-danger user-notification" : "text-success user-notification" } title={formSuccess.message} />
             </div>
             <div>
               <div className="row">
                 <div className="col-sm-6">
                   <div className="frllftfull">
                     <div className="form-group frllft mbbre">
                       <Label title="Room ID" isMandatory={true} />
                     </div>
                     <div className="form-group frlrit">
                       <div className="position-relative">
                         <div className="input-group">
                           <div className="input-group-prepend">
                             <span className="input-group-text inpico">
                               <i className="fas fa-search"></i>
                             </span>
                           </div>
                           <Select maxMenuHeight={120} className="form-control p-0" name="room_id" value={roomData.room_id} onChange={this.handleselectChange} options={room_id} />
                         </div>
                         <SpanField classname="text-danger" title={errors.room_id} />
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="frllftfull">
                     <div className="form-group frllft mbbre">
                       <Label title="Availability" isMandatory={true} />
                     </div>
                     <div className="form-group sform-control frlrit">
                       <div className="position-relative">
                         <div className="input-group">
                           <div className="input-group-prepend">
                             <span className="input-group-text inpico">
                               <i className="fas fa-search"></i>
                             </span>
                           </div>
                           <Select maxMenuHeight={120} className="form-control p-0" name="is_available" value={roomData.is_available} onChange={this.handleselectavailabilityChange} options={availabilityOptions} />
                         </div>
                         <SpanField classname="text-danger" title={errors.is_available} />
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="row">
                 <div className="col-sm-6">
                   <div className="frllftfull">
                     <div className="form-group frllft mbbre">
                       <Label title="Description" isMandatory={false} />
                     </div>
                     <div className="form-group frlrit">
                       <div className="position-relative">
                         <div className="input-group">
                           <InputField inputType={"text"} name={"description"} controlFunc={this.handleInput} content={roomData.description} placeholder={""} max={50} />
                         </div>
                         <SpanField classname="text-danger" title={errors.description} />
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className=" col-sm-6">
                   <div className="frllftfull">
                     <div className="form-group frllft mbbre">
                       <Label title="Price per day" isMandatory={true} />
                     </div>
                     <div className="form-group frlrit">
                       <div className="position-relative">
                         <div className="input-group">
                           <InputField inputType={"text"} name={"price_per_day"} controlFunc={this.handleInput} content={roomData.price_per_day} placeholder={""} />
                         </div>
                         <SpanField classname="text-danger" title={errors.price_per_day} />
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="row">
                 <div className="col-sm-6">
                   <div className="frllftfull">
                     <div className="form-group frllft mbbre">
                       <Label title="Facilities" isMandatory={true} />
                     </div>
                     <div className="form-group sform-control frlrit">
                       <div className="position-relative">
                         <div className="input-group">
                           <div className="input-group-prepend">
                             <span className="input-group-text inpico">
                               <i className="fas fa-search"></i>
                             </span>
                           </div>
                           <Select maxMenuHeight={120} className="form-control p-0" isMulti name="facilities" value={roomData.facilities} onChange={this.handleselectChange} options={facilityCategory} />
                         </div>
                         <SpanField classname="text-danger" title={errors.facilities} />
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="frllftfull">
                     <div className="form-group frllft mbbre">
                       <Label title="Room Status" isMandatory={true} />
                     </div>
                     <div className="form-group frlrit">
                       <div className="position-relative">
                         <div className="input-group">
                           <div className="input-group-prepend">
                             <span className="input-group-text inpico">
                               <i className="fas fa-search"></i>
                             </span>
                           </div>
                           <Select maxMenuHeight={120} className="form-control p-0" name="status" value={roomData.status} onChange={this.handleselectChange} options={roomStatus} />
                         </div>
                         <SpanField classname="text-danger" title={errors.status} />
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="row">
                 <div className="col-sm-6"></div>
                 <div className="col-sm-6">
                   <div className="form-group mb-0 btn-align-right">
                     <button href="patients-register" className="signupbtn abtn btdpml btn-margin" onClick={this.UpdateFacility}> {this.state.actions === "add" ? "+ Add" : "Update"} </button>
                     <button href="patients-register" className="signupbtn abtn btdpml" onClick={this.cancel}> {"Cancel"} </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     <Modal className="searchpatient" show={this.state.openModal} onHide={this.closePopup} centered={true}>
       <Modal.Header closeButton className="model-hptd">
         <Modal.Title className="h5 model-text-color"> Admit Patient </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <div className="col-sm-12">
           <div className="frllftfull"> 
           {/* {selectedRoom.is_patient_available+"ddd"+typeof selectedRoom.is_patient_available} */}
           {selectedRoom.is_patient_available=="1"?
           ( 
            <div className="frllftfull">
               <div className="form-group frllft mbbre required">
                 <label className="ffgrd">Patient Name</label>
               </div>
               <div className="form-group sform-control frlrit"> {selectedRoom.patient_name} </div>
             </div> 
             ):( 
             <div className="row align-items-center">
               <div className="col-sm-3">
                 <div className="">
                   <label className="f12">
                     <img alt="" src={require("../../assets/images/phone.png")} className="imgsfd" />{" "} Mobile number </label>
                 </div>
               </div>
               <div className="col-sm-6">
                 <div className="form-group">
                   <div className="input-group">
                     <InputField inputType={"text"} name={"phone_number"} controlFunc={this.handlePhoneInput} content={this.state.searchData.phone_number} placeholder={""} />
                     <span className="input-group-text inpico" onClick={this.getPatientDetail}>
                       <i className="fas fa-search"></i>
                     </span>
                   </div>
                   <SpanField classname="text-danger" title={this.state.searchData.error} />
                 </div>
               </div>
               <div className="col-sm-3 bm-9">
                 <div className="form-group mb-0">
            
                 {/* <button type="button" className="signupbtn abtn btdpml" onClick={this.getPatientDetail}> Search </button> */}
                 <button type="button" className="grnbtn" onClick={this.reloadPage}> Reset </button>
                  
                 </div>
               </div>
             </div> ) } 
            </div> {Object.keys(this.state.patientBasicData).length === 0 ? ( "" ) : ( <div>
             <BottomNavigation style={{ paddingLeft: 30 + "px", paddingBottom: 25 + "px" }} value={this.state.selectedPerson} showLabels onChange={this.handleChange}> {this.state.patientBasicData.map((data, i) => { return (
               <BottomNavigationAction type="button" className={"signupbtn2 btnview mr-20 span "}
    label={data.first_name + " , " + data.last_name}
    value={data.user_id}
    title={data.first_name + " , " + data.last_name}
    icon={
    data.queue_data.length != 0 ? (
    
							<PersonIcon
        fontSize={" small"} // htmlColor={getPatientProfileColor(data.queue_data)} 
        /> ) : (
               <PersonIcon fontSize={"small"} /> ) } /> ); })}
             </BottomNavigation>
           </div> )}
           <SpanField classname="text-danger" title={this.state.admitdata.errors ? this.state.admitdata.errors.patient_id:""} />
           <div className="frllftfull">
             <div className={ !this.state.is_add_btn ? "form-group frllft mbbre required" : "form-group frllft mbbre" }>
               <label className="ffgrd">Select Doctor</label>
             </div>
             <div className="form-group sform-control frlrit"> 
             {selectedRoom.is_patient_available=="1"?(
              <div className="form-group">{selectedRoom.doctor_name}</div>
             ):(<div className="position-relative">
                 <div className="input-group">
                   <div className="input-group-prepend">
                     <span className="input-group-text inpico">
                       <i className="fas fa-search"></i>
                     </span>
                   </div>
                   <Select maxMenuHeight={120} className="form-control p-0" name="selected_doc" value={selected_doc} onChange={this.handleselectDocChange} options={doctor_list} />
                 </div>
                 <SpanField classname="text-danger" title={this.state.admitdata.errors ? this.state.admitdata.errors.doctor_id:""} />
               </div> )}
              
              </div>
             <div className="form-group frllft mbbre">
               <label className="ffgrd">Select Nurse</label>
             </div>
             <div className="form-group sform-control frlrit">
             {selectedRoom.is_patient_available=="1"?(
              <div className="form-group">{selectedRoom.nurse_name}</div>
             ):(
               <div className="position-relative">
                 <div className="input-group">
                   <div className="input-group-prepend">
                     <span className="input-group-text inpico">
                       <i className="fas fa-search"></i>
                     </span>
                   </div>
                   <Select maxMenuHeight={120} className="form-control p-0" name="selected_nurse" value={selected_nurse} onChange={this.handleselectnurseChange} options={all_nurse} />
                 </div>
                 <SpanField classname="text-danger" title={this.state.admitdata.errors ? this.state.admitdata.errors.nurse_id:""} />
               </div>
             )}
             </div>
           </div>
           <div className="frllftfull">
             <div className="form-group frllft">
               <label>Date</label>
             </div>
             <div className="form-group frlrit">
             {selectedRoom.is_patient_available=="1"?(
              <div className="form-group">Admit Date:{moment(selectedRoom.admit_date).format('DD-MM-YYYY')} <br/>Discharge Date:{moment(admitdata.discharge_date).format('DD-MM-YYYY')} {admitdata.totalDays}</div>
              
             ):(
               <table className="width-100">
                 <tbody>
                   <tr>
                     <td style={{ width: "30%" }}>
                       <DatePicker className="form-control" value={this.state.admitdata.from} format="dd-MM-yyyy" onChange={(date)=> this.handleDOBChange(date, "admitdata.from") } />
                         <SpanField classname="text-danger" title={this.state.admitdata.errors ? this.state.admitdata.errors.from:""} />
                     </td>
                     <td className="text-center" style={{ width: "10%" }}> To </td>
                     <td style={{ width: "30%" }}>
                       <DatePicker className="form-control" value={this.state.admitdata.to} minDate={this.state.admitdata.from} format="dd-MM-yyyy" onChange={(date)=> this.handleDOBChange(date, "admitdata.to") } />
                         <SpanField classname="text-danger" title={this.state.admitdata.errors ? this.state.admitdata.errors.to:""} />
                     </td>
                     <td className="text-center" style={{ width: "15%" }}>
                       <span>{this.state.admitdata.totalDays} Days</span>
                     </td>
                   </tr>
                 </tbody>
               </table>
             )}
             </div>
           </div>
         </div>
         <div>
           <div className="row">
             <div className="col-sm-4">
               <div className="form-group mb-0"> {/* {this.state.selectedRoom.is_patient_available} {this.state.selectedRoom.id} */} <button disabled={selectedRoom.is_patient_available=="1" ?true:false} className="grnbtn" onClick={this.admitpatient}> Admit </button>
               </div>
             </div>
             <div className="col-sm-4">
               <div className="form-group mb-0">
                 <button className="grnbtn" onClick={this.consultPatient} disabled={selectedRoom.is_patient_available=="0" ?true:false}> Consult </button>
               </div>
             </div>
             <div className="col-sm-4">
               <div className="form-group mb-0">
                 <button className="grnbtn reddg" onClick={this.dischargePatient} disabled={selectedRoom.is_patient_available=="0" ?true:false}> Discharge </button>
               </div>
             </div>
           </div>
         </div>
       </Modal.Body>
     </Modal>
 </div> 
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Rooms));
