import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import { Spinner } from "../components/UI/Spinner";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import { getAppointmentStatus } from "../constants/globalLevelFunctions";
import { GetHospitalAppointmentList, getDocByBusiness } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import Select from "react-select";
import { ExpirePopup } from "../components/UI/ExpirePopup";
import * as moment from "moment";
import { getDateISOFormat } from "../constants/globalLevelFunctions";
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from '../components/UI/Dnaspinner';

function mapStateToProps(state) {
    return {
      appdata: state.AppData,
      userdata: state.AuthReducer.user,
      access_token: state.AuthReducer.access_token,
      validity: state.ValidityReducer.validity
    };
  }

function mapDispatchToProps(dispatch) {
    return {

    };
}

class AppointmentList extends Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
          adminstatus: this.props.userdata.adminstatus,
          showexpirePopup: this.props.validity,
          timestamp: 'no timestamp yet',
          doc_list: [],
          selected_doctor: "",
          doctor_appointment: [],
          queue: {
            id: "",
            client_id: "",
            business_id: "",
            first_name: "",
            last_name: "",
            age: "",
            date_of_birth: "",
            gender: "",
            marital_status: "",
            phone_number: "",
            status: "cancelled",
            patient_name: "",
            actual_checkin_time: "",
            expected_time: "",
            waitTime: "",
          },
          queueList: [],
          selectedQueue: [],
          loading: false,
        };
        this.columns = [
            // {
            //   key: "token_no",
            //   text: "Token No",
            //   className: "token_no",
            //   align: "left",
            //   sortable: true,
            // },
            {
              key: "s_no",
              text: "S.No",
              className: "s_no",
              align: "left",
              sortable: true,
            },
            {
              key: "patient_name",
              text: "Patient Name",
              className: "patient_name",
              align: "left",
              sortable: true,
            },
            {
              key: "patient_id",
              text: "Patient ID",
              className: "patient_id",
              align: "left",
              sortable: true,
            },
            {
              key: "phone_number",
              text: "Phone Number",
              className: "phone_number",
              align: "left",
              sortable: true,
              cell: (record) => {
                return record.phone_number ? record.phone_number:record.parent_phone_number ;
              },
            },
            
      
          

            {
                key: "appt_time",
                text: "Appointment Time",
                className: "appt_time",
                sortable: true,
                align: "left",
                cell: (record) => {
                    return moment(record.appt_time).format("hh:mm A");
                },
            },
            {
              key: "appt_status",
              text: "Status",
              className: "appt_status",
              sortable: true,
              align: "left",
              cell: (record) => {
                if(record.appt_status === 'RESCHEDULE')
                {
                 return "Rescheduled";
                } else if(record.appt_status==='SCHEDULED')
                {
                 return "Scheduled";
                }else
                return record.appt_status;
            },
            },
            {
              key: "payment_status",
              text: "Payment Status",
              className: "payment_status",
              sortable: true,
              align: "left",
             
            },
            {
              key: "schedule_type",
              text: "Appointment Type",
              className: "schedule_type",
              sortable: true,
              align: "left",
              cell: (record) => {
                return record.schedule_type === 'online' ? 'Online':record.schedule_type;
            },
            },
            {
              key: "appt_statusscheduled",
              text: "Scheduled",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
               return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          (getAppointmentStatus(record, "SCHEDULED")||getAppointmentStatus(record, "RESCHEDULE"))
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            {
              key: "appt_statuschekin",
              text: "Check-In",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
                return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          getAppointmentStatus(record, "Check-In")
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            {
              key: "appt_statusconsult",
              text: "Consultation",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
                return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          getAppointmentStatus(record, "Consulting")
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            {
              key: "appt_statuscheckout",
              text: "Check-Out",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
                return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          getAppointmentStatus(record, "Check-Out")
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            // {
            //   key: "status",
            //   text: "Cancelled",
            //   className: "text-center",
            //   sortable: true,
            //   align: "left",
            //   cell: (record) => {
            //     return (
            //       <Fragment>
            //         <a href="#">
            //           <i
            //             className={
            //               getAppointmentStatus(record, "Cancelled")
            //                 ? "fa fa-times-circle redbk"
            //                 : ""
            //             }
            //             aria-hidden="true"
            //           ></i>{" "}
            //         </a>
            //       </Fragment>
            //     );
            //   },
            // },
           
            
          ];
          this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
              excel: false,
              print: false,
            },
          };
    }


    componentDidMount = () => {
        // socketObj.on('getAppointmentStatus', () => {
        //   this.refreshData();
        // });
        // let record = this.state.queue;
        // let list = this.state.queueList;
        // list.push(record);
        this.setState({ loading: true });
    
        const data = {
          n_business_id: this.props.userdata.id,
          n_currentdate: getDateISOFormat(new Date()),
        };
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(getDocByBusiness, data, CONFIG)
          .then((result) => {
    
            let doc_list = [], doctor_shift = [];
            result.data.data.forEach((element, index) => {
              if (element.schedule_type.toLowerCase() === 'appointment')
                doc_list.push({ value: element.business_user_id, label: element.doctor_name, doctor_Appointments: element.doctor_Appointments });
            });
            // if (doc_list.length) {
            //   doc_list[0].doctor_Appointments.forEach((doc, k) => {
            //     doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
            //   })
            // }
    
    
            this.setState({ doctor_appointment: result.data.data, doc_list: doc_list, selected_doctor: doc_list[0], 
                //doctor_shift: doctor_shift, selected_shift: doctor_shift[0], 
                loading: false });
            this.getAppointments(doc_list[0].value, //doctor_shift[0].value
            );
    
    
          })
          .catch((e) => { console.log(e); this.setState({ loading: false }) });
        // axios.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // axios
        //   .post(
        //     GetallQEntries,
        //     { business_id: this.props.userdata.id },
        //     CONFIG
        //   )
        //   .then((res) => {
    
        //     this.setState({ loading: false });
        //     let doc_list = [];
        //     if (res.data.status === "1") {
    
        //       res.data.data.forEach((element, index) => {
    
        //         doc_list.push({ value: index, label: element[0].doc_name });
        //       });
    
        //       this.setState({ queueList: res.data.data, doc_list: doc_list, selected_doctor: doc_list[0], selectedQueue: res.data.data[0] })
        //     } else {
        //       this.setState({ queueList: [], selectedQueue: [], doc_list: [] })
    
        //     }
    
        //   })
        //   .catch((e) => { });
      };
      getAppointments(business_user_id) {
        this.setState({ loading: true });
        if (business_user_id !== "" ) {
          let reqdata = {
            business_id: this.props.userdata.id,
            business_user_id: business_user_id,
           // schedule_day_id: schedule_day_id
          }
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(GetHospitalAppointmentList, reqdata, CONFIG)
            .then((result) => {
              let doctorupcoming = result.data.data, doctorupcomingarray = [];
              doctorupcoming.forEach((element, index) => {

                element.s_no = index + 1;
                
                doctorupcomingarray.push(element);
            });
              this.setState({ selectedQueue: doctorupcomingarray, loading: false });
            }).catch((err) => { console.log('queue list error' + err) });
        }
      }
    
      refreshData = () => {
    
    
        this.getAppointments(this.state.selected_doctor.value)
      };
    
    

    
      handleselectChange = (selectedoption, event) => {
    
        let { doc_list } = this.state;
        let doctor_shift = [];
        if (event.name === "doc_list") {
    
          doc_list[event.name] = selectedoption
    
    
          selectedoption.doctor_Appointments.forEach((doc, k) => {
            doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
          })
    
          this.setState({ doc_list: doc_list, selected_doctor: selectedoption, doctor_shift: doctor_shift, selected_shift: doctor_shift[0] });
          this.getAppointments(selectedoption.value)
        }
        if (event.name === 'doctor_shift') {
    
          this.setState({ selected_shift: selectedoption })
          this.getAppointments(this.state.selected_doctor.value)
        }
      }
    render() {
        const { headerinfo, queueList, doc_list,loading } = this.state;
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;
    console.log(this.state.showexpirePopup)
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      this.props.history.push({
        pathname: "/showsubscription",
        state: {}
      });
     
    };
        return (
            <div className="main-content bacfot">
            <ExpirePopup
              show={!this.state.showexpirePopup}
              onHide={PopupClose}
              onConfirm={PopupConfirm}
              title={"Alert"}
              message={"Your current plan has been expired. Please activate new plan and try again"}
            />
            <div className="mainpage">
              <section className="my-head">
                <div className="container-fluid">
                  {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                    <BusinessHeader user_id={this.props.userdata.id} />
                  ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                    <BusinessUserHeader user_id={this.props.userdata.id} />
                  ) : (
                    <Header user_id={this.props.userdata.id} />
                  )}
                </div>
              </section>
              <div className="main-box">
    
                <div className="sidemenu pr0">
                  {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                    <BusinessUserSideBar />
                  ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                    <BusinessUserSideBar />
                  ) : (
                    <ClientSideBar />
                  )}
                </div>
                {(this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
                <section className="main-body hospital-profilegap">
                  <div className="container-fluid">
                    <div className="checkout-tabs">
                   {/*    <Spinner loading={this.state.loading} /> */}
                   {loading && <Dnaspinner />}
                      <div className="row align-items-center mb-2">
                        <div className="col-lg-4">
    
                          {/* <p className="App-intro">
                            This is the timer value: {this.state.timestamp}
                          </p> */}
    
                          <h4 className="font-weight-bold mb-0"> Appointment List </h4>
                        </div>
                      
                        {/* <div className="col-lg-4">
                          <Select maxMenuHeight={120}
                            className="form-control mt-2 queuedropdown"
                            name="doctor_shift"
                            // value={selected_queue.first_name}
                            onChange={
                              this.handleselectChange
                            }
                            value={this.state.selected_shift}
                            options={this.state.doctor_shift}
                          // isClearable={true}
                          />
                        </div> */}
    
                      
                      </div>
                      <div className="row">
                      <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body ">
                            {/* <div className="col-lg-4 mg-btm2 float-right mg-right1"> */}
                            {/* <div className='row d-flex justify-content-center'>
                            
                            
                            <div className="col-lg-4">
                            <div className=''> */}
                            <div className="row justify-content-center">
                             
                             <div className="col-12 col-md-4 col-lg-4 mg-btm2">
                             <div className="">
                            <div className='form-group '>
                          <Select maxMenuHeight={120}
                            className="prescriptionWidth"
                            name="doc_list"
                            // value={selected_queue.first_name}
                            onChange={
                              this.handleselectChange
                            }
                            value={this.state.selected_doctor}
                            options={doc_list}
                          // isClearable={true}
                          />
                          </div>
                          </div>
                          </div>
                        </div>
                              <div
                                className=" tab-content d-inline"
                                id="v-pills-tabContent"
                              >
                                
                                <button className="float-right btn btn-primary d-inline" onClick={this.refreshData}>Refresh
                                  <i className="fa fa-refresh">
                                  </i></button>
                                <div>
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className='ram'>
                                  <ReactDatatable
                                    config={this.config}
                                    records={this.state.selectedQueue}
                                    columns={this.columns}
                                    className="table table-bordered table-striped kas  mobile_table"
                                    // table-responsive remove from the classname
                                  />
                                </div>
                                </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Footerad /> */}
    
                    </div>
                  </div>
               
                  
                </section>)}

                <FooterSection />
              </div>
            </div>
          </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(AppointmentList);