


import React from 'react';

function FooterAds(props) {
    return (

        <section className="footd">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-2">
                            <ul className="footerlist">
                            <li><a href="/">About  </a></li>
                            <li><a href="/">Press </a></li>
                            <li><a href="/">Jobs </a></li>
                            <li><a href="/">Blog </a></li>
                            </ul>
                        </div>
                        <div className="col-sm-2">
                            <ul className="footerlist">
                            <li><a href="/">Apps &amp; Mobile </a></li>
                            <li><a href="/">Security </a></li>
                            <li><a href="/">Safety </a></li>
                            <li><a href="/">Safety </a></li>
                            </ul>
                        </div>
                        <div className="col-sm-2">
                            <ul className="footerlist">
                            <li><a href="/">Terms &amp; Conditions </a></li>
                            <li><a href="/">Privacy policy  </a></li>
                            <li><a href="/">Code of Condut </a></li>
                            <li><a href="/">FAQ </a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4 footd11">
                            <ul className="footerlist">
                            <li><a href="#">Social</a></li>
                            </ul>
                            <a href="/"><img src={require('../../assets/images/media1.png')}/></a>
                            <a href="/"><img src={require('../../assets/images/media2.png')}/></a>
                            <a href="/"><img src={require('../../assets/images/media3.png')}/></a>
                            <a href="/"><img src={require('../../assets/images/media4.png')}/></a>
                            <a href="/"><img src={require('../../assets/images/media5.png')}/></a>
                            <a href="/"><img src={require('../../assets/images/media6.png')}/></a>
                        </div>
                        <div className="col-sm-2 text-center">
                            <div>
                            <a href="/"><img src={require('../../assets/images/play1.png')}/></a>
                            </div>
                            <div>
                            <a href="/"><img src={require('../../assets/images/play2.png')}/></a>
                            </div>
                        </div>
                    </div>
                </div>
        </section>        

    );
}

export default FooterAds;