import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import { getallclientbyBusiness,getTimeoutbycategory } from "../../config/url.json";
import { CONFIG } from "../../config/data";
import * as moment from "moment";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../../components/container/modal/IdleModal';
import { Popupinfo } from "../../components/UI/Popupinfo";

function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchData: (searchData) =>
      dispatch({ type: "SET_SEARCH_DATA", payload: searchData }),
      logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
class ClientProfile extends Component
 {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout=this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      timeout:hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      records: [],
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "registration_id",
        text: "Registration ID",
        className: "registration_id",
        align: "left",
        sortable: true,
      },
      {
        key: "name",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "date_of_birth",
        text: "Date of Birth",
        className: "date_of_birth",
        sortable: true,
        align: "left",
        cell: (record) => {
         
          var datePart = record.date_of_birth.match(/\d+/g),
          year = datePart[0].substring(0,4), // get four digits
          month = datePart[1], day = datePart[2];
          let converteddate=day+'/'+month+'/'+year;
           return moment(converteddate).format("DD-MMM-YYYY");
        
          },
      },
      {
        key: "blood_group",
        text: "Blood Group",
        className: "blood_group",
        sortable: true,
        align: "left",
      },
      {
        key: "phone_number",
        text: "Mobile Number",
        className: "phone_number",
        sortable: true,
        align: "left",
      },
      {
        key: "gender",
        text: "Gender",
        className: "gender",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.viewProfile(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-eye"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  
  }

  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
};

  _onAction(e) {
    console.log('user did something', e)
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    console.log('user is idle', e)
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
  }
 
  viewProfile = (user) => {
    console.log(user);
   // this.props.setSearchData({ user_id: user.user_id, user_type: "client" });

    this.props.history.push({
      pathname: "/viewprofile",
      state: {
        user_id: user.user_id, user_type: "client"
      }
    });
   // window.location.replace("/viewprofile");
  };
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getallclientbyBusiness,{ business_id: this.props.userdata.id  }, CONFIG)
      .then((list) => {
        let clientList = list.data;
        let clientDataArr = [];
        clientList.data.sort((a,b) => a.id - b.id);
        clientList.data.forEach((element, index) => {
          let clientData = {};
          clientData.s_no = index + 1;
          clientData.registration_id = element.id;
          clientData.user_id = element.client_id;
          clientData.name = element.first_name + " " + element.last_name;
          clientData.age = element.age;
          clientData.blood_group = element.blood_group;
          clientData.phone_code=element.phone_code;
          clientData.phone_number = element.phone_code.concat(element.phone_number);
          clientData.gender = element.gender;
          if (element.gender === 1) {
            clientData.gender = "Female";
          } else if (element.gender === 0) {
            clientData.gender = "Male";
          }  else if (element.gender === 2){
            clientData.gender = "Other";
          }

          clientData.maritial_status = element.marital_status;
          clientData.date_of_birth = element.date_of_birth;
          if (element.date_of_birth !== "") {
            clientData.date_of_birth = moment(element.date_of_birth).format(
              "DD-MM-YYYY"
            );
          }

          clientDataArr[index] = clientData;
        });
        this.setState({ records: clientDataArr });
      })
      .catch((e) => { console.log(e) });
  };
  render() {
    const {hospitaltimeout}=this.state;
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    return (
      <div className="col-sm-12 checkout-tabs">
         <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

        <h4 className="font-weight-bold">Patient Profile </h4>
        {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
        <div className="row mt-4">

          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-shipping"
                    role="tabpanel"
                    aria-labelledby="v-pills-shipping-tab"
                  >
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      className="table table-bordered table-striped kas  mobile_table"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}

        {/* <Footerad /> */}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientProfile));
