import React from "react";

function ProfileLogo(props) {
  return (
    <div className="col-sm-2 text-left pr0">
      <div className="text-center">
        <a href="/">
          <img alt="LOGO" src={require("../../assets/images/logo.jpg")} />
        </a>
      </div>
    </div>
  );
}

export default ProfileLogo;
