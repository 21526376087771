import React from "react";

function FooterSection(props) {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer adv mt-auto ">
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className=" d-sm-block text-center ">
              
              © {currentYear} Nucleo software solutions, All rights reserved
      </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
