import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import SideBar from "../components/container/layout/client/SideBar";
import BusinessSidebar from "../components/container/SideBar";
import Header from "../components/container/layout/client/Header";
import { Spinner } from "../components/UI/Spinner";
import FooterSection from '../components/UI/FooterSection';
import axios from "axios";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';

import {
    getNotification, markasread,readAllMessages,getTimeoutbycategory
} from "../config/url.json";
import { CONFIG } from "../config/data";
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from '../components/UI/Dnaspinner';
import {
        getGivenDateStringFormat,
     } from "../constants/globalLevelFunctions";
function mapStateToProps(state) {
    return {

        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

class InboxController extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const timeout=this.state;
    
        this.state = {
            adminstatus: this.props.userdata.adminstatus,
            timeout:timeout,
            isTimedOut: false,
            showModal: false,
          
            notifications: [],
            loading: false
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
      
    }
    handleClose() {
        this.setState({showModal: false})
      }
    
     
       handleLogout = (e) => {
        this.setState({showModal: false})
        e.preventDefault();
        localStorage.removeItem("token");
      //  this.props.logout();
      this.props.history.push('/');
   
    };
    
      _onAction(e) {
        console.log('user did something', e)
        
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        
        console.log('user is active', e)
        this.setState({isTimedOut: false})
      }
       
      _onIdle = (e) =>  {
        console.log('user is idle', e)
        let isTimedOut  = this.state.isTimedOut
        let timeout=this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
    {
    localStorage.removeItem("token");
   // this.props.logout();
   this.props.history.push('/');
   
    }
      }
      
   
    componentDidMount() {

        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
         axios
          .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status == "1")
              this.setState({
                timeout: data.data.timeout
                
              });
            })
          .catch((e) => { });
    


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getNotification, { message_to: this.props.userdata.id }, CONFIG)
            .then((res) => {
                let data = res.data;

                this.setState({ notifications: data.data })

            })
            .catch((e) => { });

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            let data = {
                message_to: this.props.userdata.id
            }
            axios
                .post(readAllMessages, data, CONFIG)
                .then((response) => {
                    // console.log(response.data)
                    let hospitals = response.data.data;
    
                    this.setState({ hospitals: hospitals })
                }).catch((e) => {
                    console.log("get queue failed");
                });
    }
    markasread = (notification, e) => {

        if (!notification.read) {
            axios
                .post(markasread, { id: notification.id })
                .then((res) => {
                    window.location.href = notification.link ? notification.link : "";

                })
                .catch((e) => { });
        }
        // window.location.href = notification.link ? notification.link : "";

    }

    render() {
        let PopupHospitalClose = () => {
            this.setState({ hospitalpopup:true})
           
          };
        const { notifications,timeout,loading } = this.state;
        return (

            <div id="layout-wrapper">
              {/*   <Spinner loading={this.state.loading} /> */}
              {loading && <Dnaspinner />}
                <div className="main-content bacfot">
                    <div className="mainpage">
                    <section className="my-head">
                        <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div>
                    </section>
                        <div className="main-box">
                            <div className="sidemenu">
                                {(this.props.userdata.user_type.toLowerCase() === 'hospital' || this.props.userdata.user_type.toLowerCase() === 'client') ? <BusinessSidebar/> : <SideBar />}
                            </div>
                            <section className="main-body hospital-profilegap">
                            <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />

                                    <div className='container-fluid'>
                                        <div className='checkout-tabs'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                            <h4 className="font-weight-bold">Notifications</h4>
                                            {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

                                    <div className="card">
                                    <div className="card-body container-fluid">
                                    <div className="row notificatincls a-page-1-hight-profile ">

                                        <List sx={{ width: '100%', maxWidth: 1015, bgcolor: 'background.paper' }}>
                                            {notifications.map((notification, index) => {
                                                return (<Fragment key={index}>
                                                    <ListItem alignItems="flex-start" >
                                                        <ListItemAvatar>
                                                            <Avatar alt={notification.msf_first_name} src={notification.avatar ? notification.avatar : "/static/images/avatar/1.jpg"}  />
                                                        </ListItemAvatar>
                                                        <a onClick={this.markasread.bind(this, notification)} href="#">
                                                            <div className={(notification.read) ? "light" : "dark"}>
                                                                <ListItemText
                                                                    primary={notification.msf_first_name}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography
                                                                                sx={{ display: 'inline' }}
                                                                                component="span"
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {notification.message}
                                                                            </Typography>
                                                                            {getGivenDateStringFormat(notification.created_at)}
                                                                        </React.Fragment>
                                                                    }
                                                                /></div></a>
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                </Fragment>)
                                            })}
                                        </List>


                                    </div>
                                </div>
                                    </div>)}
                                            </div> 
                                        </div> 
                                        </div>
                                        
                                    </div> 
                                    
                                    
                                  

                              
                            </section>
                            <FooterSection />
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

export default connect(
    mapStateToProps,
)(InboxController);