import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LogoSection from "../components/UI/LogoSection";
import Label from "../components/UI/Label";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import Button from "../components/UI/Button";
import Validation from "../actions/Validation";
import Spinner from "../components/UI/Spinner";
import axios from "axios";

import { getVendorbyid, updateVendor, getAllSubscriptions, RazorPayPayment,getCredentials, checkPromoCode, completePayment } from "../config/url.json";
import { vendor_category, CONFIG, imageconfig, Razor_CONFIG } from "../config/data";
import { monthNumList } from "../constants/config/data";
import S3 from "react-aws-s3";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import Dnaspinner from "../components/UI/Dnaspinner";
import FooterSection from '../components/UI/FooterSection';
import AdminHeader from '../components/container/layout/admin/Header';
// const currentYear = new Date().getFullYear();
const google = window.google;
function mapStateToProps(state) {
  console.log(state);
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    loggedIn: state.AuthReducer.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

class UpdateVendorForm extends Component {
  constructor(props) {
    super(props);
    console.log(props.match.params.id);
    this.state = {
      isEditable: false,
      vendorData: {
        id: this.props.match.params.id,
        vendor_name: "",
        vendor_category: "",
        vendor_photo: "",
        vendor_url: "",
        vendor_website: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        google_address: false,
        phone_code: "+91",
        phone_number: "",
        email: "",
        type: "vendorform",
        vendor_subscription: "",
      },
      paymentForm: {
        promo_code: "",
        months: monthNumList[0],
        subscription_id: "",
        payable_amt: "",
        coupon_amt: ""
      },
      vendor_subscription_list: [],
      displayPromo: false,
      errors: {
        vendor_name: "",
        vendor_category: "",
        vendor_photo: "",
        vendor_website: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        phone_number: "",
        email: "",
        vendor_subscription: "",
      },
    };
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.vendorData;
    data[e.name] = selectedOptions;

    this.setState({ vendorData: data });


    let paymentForm = this.state.paymentForm;
    if (paymentForm.months !== "") {
      console.log(paymentForm);
      paymentForm.promo_code = ""
      paymentForm.payable_amt = paymentForm.months.value * (this.state.vendorData.vendor_subscription ? this.state.vendorData.vendor_subscription.amount : 0);
      this.setState({ paymentForm, displayPromo: false });
    }


  };
  handlePaymentFormselectChange = (selectedOptions, e) => {
    let data = this.state.paymentForm;
    data[e.name] = selectedOptions;

    data.payable_amt = selectedOptions.value * (this.state.vendorData.vendor_subscription ? this.state.vendorData.vendor_subscription.amount : 0);
    data.promo_code = ""
    this.setState({ paymentForm: data, displayPromo: false });

  };
  handleInput = (e) => {
    let data = this.state.vendorData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ vendorData: data, errors: errors });
  };
  handlePaymentInput = (e) => {
    let data = this.state.paymentForm;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ paymentForm: data });
  }
  handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('myImage', this.state.file);
    let { vendorData, errors } = this.state;
    console.log(vendorData);
    errors = Validation(vendorData, errors);
    console.log(errors);
    // if (vendorData.address1 !== "") {
    //     errors.address1 = "Please choose address from authcomplete";
    // }
    // return false;
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      this.setState({ loading: true });

      console.log("form is valid");


      // Details of the uploaded file 
      console.log(this.state.vendorData.vendor_photo);

      // Request made to the backend api 
      // Send formData object 
      //   axios.post(uploadfile, formData, CONFIG);

      try {
        let { vendorData } = this.state;
        let data = {}
        Object.assign(data, vendorData);;
        data.vendor_category = data.vendor_category.value;
        data.vendor_subscription = data.vendor_subscription.value;
        data.phone_number = data.phone_number.replace(data.phone_code, "");
       
        axios
          .post(updateVendor, data, CONFIG)
          .then((res) => {
            let response = res.data;
            console.log(response);
            // return false;
            if (response.status !== "0") {
              // window.location.href = "/vendor/" + response.data;
              this.setState({ isEditable: false });
            }
            this.setState({ loading: false });
          })
          .catch((e) => {
            this.setState({ loading: false });
            console.log(e);
          });
      } catch (e) {
        this.setState({ loading: false });

        // dispatch(
        // addError(e.message)
        // )
      }
    }
  };
  componentDidMount() {
    this.setState({ loading: true });

    axios
      .post(getAllSubscriptions, null, CONFIG)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === "1") {
          let vendor_subscription_list = [];
          result.data.data.forEach((element, index) => {
            let obj = {};
            obj = element;
            obj.label = element.subscription_name;
            obj.value = element.id;
            vendor_subscription_list[index] = obj;
          });

          this.setState({
            vendor_subscription_list: vendor_subscription_list
          });
        } else {
          alert("Subscription list empty");
        }
        this.callgetVendorById();
      })
      .catch((e) => {
        console.log(e);
        console.log("business user getbyid failed");
      });



  }
  callgetVendorById() {

    axios
      .post(getVendorbyid, { id: this.props.match.params.id }, CONFIG)
      .then((result) => {
        console.log(result);
        if (result.data.status === "1") {
          let data = result.data.data;
          // data.phone_number = this.state.vendorData.phone_code.concat(data.phone_number);

          let vendor_subscription_list = this.state.vendor_subscription_list;
          data.vendor_subscription = vendor_subscription_list.filter(function (item) {
            console.log(item);
            return item.id == data.vendor_subscription;
          })[0];

          data.vendor_category = {
            label: data.vendor_category,
            value: data.vendor_category,
          };
          console.log(data)
          this.setState({ vendorData: data });

          let paymentForm = this.state.paymentForm;
          if (paymentForm.months.label !== "") {
            paymentForm.promo_code = ""
            paymentForm.payable_amt = paymentForm.months.value * (this.state.vendorData.vendor_subscription ? this.state.vendorData.vendor_subscription.amount : 0);
            this.setState({ paymentForm, displayPromo: false });
          }
        } else {
          this.setState({ vendorData: [] });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(e.data);
        console.log("business user getbyid failed");
      });



  }
  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    console.log(place);

    let { vendorData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    vendorData.latitude = lat.toString();
    vendorData.longitude = lng.toString();
    vendorData.place_url = place.url;

    // console.log(place.name);
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
          vendorData.state = val;
        if (addressType === "locality")
          vendorData.city = val;
        if (addressType === "country") vendorData.country = val;
        // if (addressType === "street_number") {

        vendorData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1") vendorData.address2 = val;
        if (addressType === "postal_code") vendorData.zipcode = val;
      }
    }

    // let ClientData = this.state;
    this.setState({
      vendorData,
      google_address: true,
    });
  }

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.vendorData;
    data.country = val;
    this.setState({ vendorData: data });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.vendorData;
    data.state = val;
    this.setState({ vendorData: data });
  }
  handlePhoneInput = (event) => {
    let vendorData = this.state.vendorData;
    vendorData.phone_number = event;
    this.setState({ vendorData });
  };
  editform = (e) => {
    e.preventDefault();
    this.setState({ isEditable: true });
    setTimeout(() => {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address1"),
        {}
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }, 2000);
  };

  applyPromo = (e) => {
    e.preventDefault();

    let { errors, paymentForm, vendorData } = this.state;
    let data = {};
    console.log(vendorData.vendor_subscription)
    data.subscription_id = vendorData.vendor_subscription.id;
    data.promo_code = paymentForm.promo_code;
    data.months = paymentForm.months.value;
    data.type = "applyPromo";

    errors = Validation(data, errors);
    console.log(errors);

    this.setState({ errors: errors });
    if (errors.formIsValid) {
      this.setState({ loading: true });

      console.log("form is valid");

      try {

        axios
          .post(checkPromoCode, data, CONFIG)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 1) {
              let paymentForm = this.state.paymentForm;

              paymentForm.coupon_amt = (res.data.percentage / 100) * (vendorData.vendor_subscription.amount * paymentForm.months.value);
              paymentForm.payable_amt = res.data.payable_amt;
            } else {
              errors.promo_code = res.data.message;
              this.setState({ errors: errors });
            }
            this.setState({ loading: false });
          })
          .catch((e) => {
            console.log(e);
          });

      } catch (e) {
        this.setState({ loading: false });
      }
    }



  }

  onFileUpload = () => {
    // Create an object of formData 
    const formData = new FormData();
    // Update the formData object 
    formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    // Details of the uploaded file 
    console.log(this.state.selectedFile);
  };

  cancelPaymentForm = () => {
    let paymentForm = this.state.paymentForm;
    paymentForm.promo_code = "";
    paymentForm.payable_amt = "";
    paymentForm.coupon_amt = "";
    paymentForm.months = {};

    this.setState({ paymentForm, displayPromo: false });
    // this.setState({ displayPromo: !this.state.displayPromo });

  }

  onFileChange = event => {

    let vendorData = this.state.vendorData;
    // vendorData.vendor_photo = event.target.files[0];
    vendorData.vendor_photo = event.target.files[0];
    vendorData.vendor_url = URL.createObjectURL(event.target.files[0]);
    // Update the state 
    this.setState({ vendorData });

    console.log(vendorData.vendor_photo);
    this.setState({ loading: true });

    event.preventDefault();

    console.log(event.target.files[0]);
    let file = event.target.files[0];
    let newfileName = event.target.files[0].name;
    imageconfig.dirName = 'vendor'
    console.log(imageconfig);
    const reacts3client = new S3(imageconfig);
    console.log(reacts3client)
    reacts3client.uploadFile(file, newfileName).then(data => {
      console.log(data)
      if (data.status === 204) {
        console.log('success');
        let vendorData = this.state.vendorData;
        vendorData.vendor_photo = data.location;
        this.setState({ vendorData: vendorData, loading: false })
      } else {
        console.log('error');
        this.setState({ loading: true });
      }
    })


  };

  clickPay = () => {
    let { errors, paymentForm } = this.state;
    if (paymentForm.months || paymentForm.months == "") {
      errors.months = "Please choose month"
      errors.formIsValid = false;
    }

    console.log(errors);
    this.setState({ errors });

    if (errors.formIsValid) {
      this.displayRazorpay();
    }
  }

  displayRazorpay = () => {

    const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    const option = {
      method: 'POST',
      // headers: new Headers({ 'content-type': 'application/json' }),
      mode: 'no-cors'
    };

    let paymentForm = this.state.paymentForm;
    let data = {};
    data.payable_amt = paymentForm.payable_amt;
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${this.props.access_token}`;
    let razorpay_key = "";
    axios
.post(getCredentials, {
  "credential_type":"razorpay"
})
.then((res) => {
  console.log(res.data);
  razorpay_key = res.data.data[0].value;

    axios.post(RazorPayPayment, data, data).then((res) => {
      console.log(res);
      let data = res.data;

      let vendorData = this.state.vendorData;
      let paymentForm = this.state.paymentForm;
      console.log(vendorData);
      const options = {
        // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
        key: razorpay_key,
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: 'Billing and Payment',
        description: '',
        image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
        handler: function (response) {
         // alert(response.razorpay_payment_id)
        //  alert(response.razorpay_order_id)
        // alert(response.razorpay_signature)
          let data = {
            'order_id': response.razorpay_order_id,
            'payment_id': response.razorpay_payment_id,
            'vendor_id': vendorData.id,
            vendor_subscription: vendorData.vendor_subscription.id,
            number_of_months: paymentForm.months.value,
            amount: paymentForm.payable_amt,

          }
          axios.post(completePayment, data, CONFIG).then((res) => {
            setTimeout(
              () =>
                (res.data.status) ?
                  window.location.href = '/vendorform' : ""

              ,
              500
            );
          }).catch((err) => {


          })
        },

        prefill: {
          name: vendorData.vendor_name,
          email: vendorData.email,
          contact: vendorData.phone_number
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()

    }).catch((e) => { console.log(e) });
  }).catch((e) => { 
    console.log(e)
  });
    
  }
  handleback()
  {
    document.location.href = "/VendorForm";
  
  }

  render() {
    const { vendorData, errors, loading } = this.state;

    return (
      <div id="layout-wrapper">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Start right Content here --> */}
        {/* <!-- ============================================================== --> */}
        <div className="main-content grayback">
        {/*   <Spinner loading={loading} /> */}
        {loading && <Dnaspinner />}
          <div className="mainpage">
          <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                    <div className="main-box adminMainBox">
              <div className="sidemenu pr0">
                <AdminSideBar />
              </div>
              {/* <section className="my-head">
              <div className="container-fluid">
                <div className="row">
                  <LogoSection />
                  
                </div>
              </div>
            </section> */}

              <section className="main-body hospital-profilegap checkout-tabs">
                {/* {error ? <ErrorAlert message={error} /> : null} */}

                <div className="col-lg-12">
                  <form
                    className="custom-validation"
                    encType="multipart/form-data"
                    onSubmit={this.handleFormSubmit}
                  >
                    <div className="card  bghome ptbac1">
                      <div className="card-body pd-0">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-shipping"
                            role="tabpanel"
                            aria-labelledby="v-pills-shipping-tab"
                          >
                            <div>
                              <h4 className="card-title mb-2">
                                VENDOR FORM
                                <button
                                  type="submit"
                                  className="btn btn-primary float-right px-5"
                                  onClick={this.editform}
                                >
                                  Edit
                                </button>
                              </h4>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Label title="Vendor name" isMandatory={true} />
                                  <InputField
                                    isMandatory={true}
                                    inputType={"text"}
                                    name={"vendor_name"}
                                    controlFunc={this.handleInput}
                                    content={
                                      vendorData.vendor_name
                                        ? vendorData.vendor_name
                                        : ""
                                    }
                                    placeholder={""}
                                    max={50}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.vendor_name}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Label
                                    title="Vendor Category"
                                    isMandatory={true}
                                  />
                                  <Select maxMenuHeight={120}
                                    className="form-control p-0"
                                    name="vendor_category"
                                    value={vendorData.vendor_category}
                                    onChange={this.handleselectChange}
                                    options={vendor_category}
                                    isDisabled={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.vendor_category}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Vendor picture</label>
                                  {this.state.isEditable ? (
                                    <div className="custom-file">
                                      <input
                                        accept="image/*"
                                        type="file"
                                        className="custom-file-input"
                                        onChange={this.onFileChange}
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="customFile"
                                      >
                                        Choose file
                                      </label>
                                    </div>
                                  ) : null}

                                  <img
                                    style={{ width: "100%", marginTop: "20px" }}
                                    alt="Profile"
                                    src={
                                      vendorData.vendor_photo
                                        ? vendorData.vendor_photo
                                        : ""
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Label title="Website" isMandatory={false} />

                                  <InputField
                                    isMandatory={true}
                                    inputType={"text"}
                                    name={"vendor_website"}
                                    controlFunc={this.handleInput}
                                    content={
                                      vendorData.vendor_website
                                        ? vendorData.vendor_website
                                        : ""
                                    }
                                    placeholder={""}
                                    max={50}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.vendor_website}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <InputField
                                    isMandatory={true}
                                    id="address1"
                                    inputType={"text"}
                                    title={"Address 1"}
                                    name={"address1"}
                                    controlFunc={this.handleInput}
                                    content={
                                      vendorData.address1
                                        ? vendorData.address1
                                        : ""
                                    }
                                    placeholder={""}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.address1}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <InputField
                                    // isMandatory={true}
                                    inputType={"text"}
                                    title={"Address 2"}
                                    name={"address2"}
                                    controlFunc={this.handleInput}
                                    content={
                                      vendorData.address2
                                        ? vendorData.address2
                                        : ""
                                    }
                                    placeholder={""}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.address2}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <InputField
                                    isMandatory={true}
                                    inputType={"text"}
                                    title={"City"}
                                    name={"city"}
                                    max={20}
                                    controlFunc={this.handleInput}
                                    content={
                                      vendorData.city ? vendorData.city : ""
                                    }
                                    placeholder={""}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.city}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Label title="Country" isMandatory={true} />

                                  <CountryDropdown
                                    placeholder="Country"
                                    name="country"
                                    value={vendorData.country || ""}
                                    className="form-control"
                                    onChange={(val) => this.selectCountry(val)}
                                    disabled={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.country}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Label title="State" isMandatory={true} />

                                  <RegionDropdown
                                    name="state"
                                    defaultOptionLabel="Select State"
                                    country={vendorData.country}
                                    value={vendorData.state || ""}
                                    className="form-control"
                                    onChange={(val) => this.selectState(val)}
                                    placeholder="State"
                                    disabled={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.state}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <InputField
                                    isMandatory={true}
                                    inputType={"text"}
                                    title={"Zip/Pincode"}
                                    name={"zipcode"}
                                    max={10}
                                    controlFunc={this.handleInput}
                                    content={vendorData.zipcode}
                                    placeholder={""}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.zipcode}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group mb-0">
                                  <Label
                                    title="Phone number"
                                    isMandatory={true}
                                  />
                                </div>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-control">
                                      <PhoneInput
                                        international
                                        defaultCountry="IN"
                                        placeholder="Enter phone number"
                                        value={vendorData.phone_number}
                                        onChange={this.handlePhoneInput}
                                        error={
                                          vendorData.phone_number
                                            ? isValidPhoneNumber(
                                              vendorData.phone_number
                                            )
                                              ? undefined
                                              : "Invalid phone number"
                                            : "Phone number required"
                                        }
                                        disabled={!this.state.isEditable}
                                      />
                                    </div>
                                    <SpanField
                                      classname="text-danger"
                                      title={errors.phone_number}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <InputField
                                    isMandatory={true}
                                    inputType={"text"}
                                    title={"Email"}
                                    name={"email"}
                                    max={50}
                                    controlFunc={this.handleInput}
                                    content={vendorData.email ? vendorData.email : ""}
                                    placeholder={""}
                                    isvisible={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.email}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Label
                                    title="Vendor Subscription"
                                    isMandatory={true}
                                  />
                                  <Select maxMenuHeight={120}
                                    className="form-control p-0"
                                    name="vendor_subscription"
                                    value={vendorData.vendor_subscription}
                                    onChange={this.handleselectChange}
                                    options={this.state.vendor_subscription_list}
                                    isDisabled={!this.state.isEditable}
                                  />
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.vendor_subscription}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.isEditable ? (
                      <div className="col-sm-12 btncent">
                        <div className="form-group signupformbtn float-right">
                          {/* <button className="signupbtn" data-toggle="modal" data-target=".bs-example-modal-center">Sign Up</button> */}
                          <Button
                            style={{ width: 115 }}
                            clsname="signupbtn abtn"
                            btntype="submit"
                            title="Update"
                            action=""
                          />
                          &nbsp;
                          <button
                            type="button"
                            style={{ width: 115 }}
                            className="signupbtn abtn btdpml"
                            onClick={() => {
                              this.setState({ isEditable: false });
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                </div>

                <div className="col-lg-6 float-right">
                  <div className="card  bghome ptbac1">
                    <div className="card-body pd-0">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-shipping"
                          role="tabpanel"
                          aria-labelledby="v-pills-shipping-tab"
                        >
                          <div>
                            <h4 className="card-title mt-2">PAYMENT FORM</h4>
                          </div>
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="toppadding5">
                                <Label title="Vendor Subscription" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Select maxMenuHeight={120}
                                  className="form-control p-0"
                                  name="vendor_subscription"
                                  value={vendorData.vendor_subscription}
                                  onChange={this.handleselectChange}
                                  options={this.state.vendor_subscription_list}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-3">
                              <div className="toppadding5">
                                <Label title="Subscription Amount" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <p className="form-control readOnlyContent">{vendorData.vendor_subscription ? vendorData.vendor_subscription.amount : null}</p>
                              </div>
                            </div>
                          </div>


                          <div className="row">
                            <div className="col-sm-3">
                              <div className="toppadding5">
                                <Label title="No of Months" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Select maxMenuHeight={120}
                                  className="form-control p-0"
                                  name="months"
                                  value={this.state.paymentForm.months}
                                  onChange={this.handlePaymentFormselectChange}
                                  options={monthNumList}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.months}
                                />
                              </div>
                            </div>
                          </div>



                          <div className="row">
                            <div className="col-sm-6 cursorpoint" onClick={() => {
                              this.setState({ displayPromo: !this.state.displayPromo });
                            }}>
                              {this.state.displayPromo ? <i className="fa fa-arrow-circle-down"></i> : <i className="fa fa-arrow-circle-right"></i>}
                              <span className="promotionalcode">
                                Enter promotional code
                              </span>
                            </div>
                          </div>

                          {this.state.displayPromo ? (
                            <Fragment><div className="row">
                              <div className="col-sm-3">
                                <div className="toppadding5">
                                  <Label title="Promo Code" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <InputField
                                  inputType={"text"}
                                  name={"promo_code"}
                                  controlFunc={this.handlePaymentInput}
                                  content={
                                    this.state.paymentForm.promo_code
                                      ? this.state.paymentForm.promo_code
                                      : ""
                                  }
                                  placeholder={"Enter Code"}
                                  max={50}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.promo_code}
                                />
                              </div>
                              <div className="col-sm-3">
                                <div className="form-group">
                                  <button className="btn btn-primary" onClick={this.applyPromo}>Apply</button>
                                </div>

                              </div>
                            </div>

                              <div className="row">
                                <div className="col-sm-3">
                                  <div className="toppadding5">
                                    <Label title="Coupon Amount" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <p className="form-control readOnlyContent">{this.state.paymentForm.coupon_amt}</p>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          ) : null}


                          <div className="row">
                            <div className="col-sm-3">
                              <div className="toppadding5">
                                <Label title="Total Amount" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <p className="form-control readOnlyContent">{this.state.paymentForm.payable_amt}</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 btncent">
                            <div className="form-group signupformbtn float-right">
                              <button
                                type="button"
                                style={{ width: 115 }}
                                className="signupbtn abtn btdpml mr-2"
                                onClick={this.displayRazorpay}
                              >
                                Pay
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                style={{ width: 115 }}
                                className="signupbtn abtn btdpml mr-2"
                                onClick={this.cancelPaymentForm}
                              >
                                Cancel
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                style={{ width: 115 }}
                                className="signupbtn abtn btdpml"
                                onClick={this.handleback}
                              >
                                Back
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <FooterSection />
            </div>
          </div>
        </div>
        {/* <FooterAds /> */}
        {/* <FooterSection /> */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(UpdateVendorForm);
