import { ADD_APP_DATA } from '../constants/appdata';
export const AppData = (state = [], action) => {
    switch (action.type) {
        case ADD_APP_DATA:
            return [
                action.payload
            ];

        default:
            return state
    }
};
export default AppData;