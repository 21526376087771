import React from 'react';

const GenderField = (props) => (

    < label className={props.labelcls} > {props.title} <input id={props.id + props.title} type="radio" value={props.title} onChange={props.controlFunc} name={props.name} />
        <span className={props.spanclass}></span>
    </label >
    // <label className="inputimgslct">Female<input type="radio" name="radio" />
    //     <span className="checkmark chekca2"></span>
    // </label>


);


export default GenderField;