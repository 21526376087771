import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import { support_categories, issue_description } from "../config/data";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import SpanField from "../components/UI/SpanField";
import InputField from "../components/UI/InputField";
import support from "../assets/images/support.png";
import Label from "../components/UI/Label";
import Validation from "../actions/Validation";
import { Spinner } from "../components/UI/Spinner";
import { supportApi,getTimeoutbycategory,sendemail } from "../config/url.json";
import { CONFIG } from "../config/data";
import axios from "axios";
import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from '../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
      //  appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

/* function mapDispatchToProps(dispatch) {
    return {
      logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
  } */
  
class SupportDefault extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const timeout=this.state;
    
        this.state = {
          //  useremail: this.props.userdata.email,
            timeout:timeout,
            isTimedOut: false,
            showModal: false,
            
            supportData: {
                name: "",
                category: "",
                 email: "",
                // phone_number: "",
                issue_description: "",
                others: "",
              //  user_id: props.userdata.id,
            },
            errors: {
                name: "",
                category: "",
                email: "",
                phone_number: "",
                issue_description: "",
                others: "",
            },
            formSuccess: {
                message: "",
                status: "",
            },

        };
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
       
    }
    handleClose() {
        this.setState({showModal: false})
      }
    
     
       handleLogout = (e) => {
        this.setState({showModal: false})
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();
       
    };
    
      _onAction(e) {
        console.log('user did something', e)
        
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        
        console.log('user is active', e)
        this.setState({isTimedOut: false})
      }
       
      _onIdle = (e) =>  {
        console.log('user is idle', e)
        let isTimedOut  = this.state.isTimedOut
        let timeout=this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
      }
      
    
    handlePhoneInput = (event) => {
        let supportData = this.state.supportData;
        supportData.phone_number = event;
        this.setState({ supportData });
    };
    handleInput = (e) => {
        let data = this.state.supportData;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;

        if (name === "name" || name === "email" || name === "phone_number") {
            this.validateMaxLength(name, value, max);
        } else {
            data[name] = value;
        }
        this.setState({ supportData: data });
    };
    validateMaxLength(name, value, max) {
        let data = this.state.supportData;
        let errors = this.state.errors;
        if (value.length > max) {
            const value1 = value.slice(0, max);
            errors[name] = "Maximum Length has exceeded.";
            data[name] = value1;
        } else {
            // const value1 = value.slice(0, max);
            errors[name] = "";
            data[name] = value;
        }
        this.setState({ supportData: data, errors: errors });
    }
    handleselectChange = (selectedOptions, e) => {
        let data = this.state.supportData;
        let errors = this.state.errors
        errors[e.name] = "";
        data[e.name] = selectedOptions;

        this.setState({ supportData: data,errors });
    };
    sendData = (e) => {
        e.preventDefault();

        let { supportData, errors } = this.state;

        supportData.type = "support";

        errors = Validation(supportData, errors);

        this.setState({ errors: errors });
        if (errors.formIsValid) {
            this.setState({ loading: true });

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            let supportData = this.state.supportData;
            let url = supportApi;
            supportData.category = supportData.category.label;
            supportData.issue_description = supportData.issue_description.label;
            axios
                .post(url, supportData, CONFIG)
                .then((res) => {
                    let data = res.data;
                    console.log(data);
                    if (data.status !== "0") {
                        data.error_type = "success";
                     //   this.setState({
                       //     formSuccess: data,

                        //});
                     
                        toast.success("Your ticket has been created.Our support team will get back to you as soon as possible.",{
                            className:"toast-success"
                          });
                        this.setState({ loading: false });
                       
                    } else {
                        data.error_type = "error";
                    //    this.setState({
                    //        formSuccess: data,
                     //   });
                        toast.error("Your message could not be sent.",{
                            className:"toast-error"
                          });
                        this.setState({ loading: false });
                        this.clearMessage();
                    }

                    setTimeout(() => { 
                        let formSuccess = this.state.formSuccess;
                        formSuccess.message =""
                        formSuccess.status =""
                        this.setState({formSuccess:formSuccess})
                    },2000);
                })
                .catch((e) => { });
        }

    }
    sendsupportmail=(e)=>{
        let { supportData, errors } = this.state;
        supportData.type = "support";

        errors = Validation(supportData, errors);

        this.setState({ errors: errors });
        if (errors.formIsValid) {
        let reqdata = {
            "to" : "support@klinicq.com ",
            "subject" : supportData.issue_description.value,
            "mailHeader" : "From "+supportData.name ,
            "message": "<p>" +supportData.issue_description.value + "</p>" + "<p>"+ supportData.others + "</p>"+ "<p>" + supportData.email + "</p>"
          }
          axios
          .post(sendemail, reqdata)
          .then((res) => {
            let resdata = res.data;
            let reqdata = {
                "to" : supportData.email,
                "subject" : supportData.issue_description.value,
                "mailHeader" : "From "+supportData.name ,
                "message": "Dear "+supportData.name+ ",<p>Your support ticket on Password issues has been submitted. We try to reply to all tickets as soon as possible,usually within 24 hrs. Kindly check the reference copy of your submission.</p>Issue Description: " +supportData.issue_description.value + "</p>" + "<p>Ticket Message: "+ supportData.others + "</p>"+ "<p>Your Email ID: " + supportData.email + "</p>" + "<p>Once our staff replies to your ticket. you will receive a response email notification. <mm,/p><p>Sincerely,</p><p>KlinicQ Team.</p>"
              }
              axios
              .post(sendemail, reqdata)
              .then((res) => {
                let resdata = res.data;
                this.clearMessage();
                toast.success("Your ticket has been created.Our support team will get back to you as soon as possible.",{
                    className:"toast-success"
                  });
              })
          }).catch((err) => {
            console.log(err);
         //   successMessage = {message: err,status:"0"};
          })
        }
    }
   
    clearMessage = () => {
      
                this.setState({
                    // formSuccess: { message: "" },
                    supportData: {
                        name: "",
                        category: "",
                         email: "",
                        // phone_number: "",
                        issue_description: "",
                        others: "",
                  //      user_id: this.props.userdata.id,
                    },
                    errors:{
                        name: "",
                        category: "",
                        email: "",
                        phone_number: "",
                        issue_description: "",
                        others: "",
                    }
                })
           
    }
    render() {
        const { supportData, errors, formSuccess,timeout,loading } = this.state;

        return (
            <div className="main-content bacfot">
              {/*   <Spinner loading={this.state.loading} />
 */}
        {loading && <Dnaspinner />}
                <div className="mainpage">
                  {/*   <section className="my-head">
                        <div className="container-fluid">
                           
                                <Header  />
                          
                        </div>
                    </section>   */}
                 {/*    <div className="main-box">
                        <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}
                        <section className="main-body hospital-profilegap">
                        <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />

                            <div className="container-fluid">
                            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                <div className="checkout-tabs">
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div>
                                                <div>
                                                    <div className="">

                                                        <h4 className="font-weight-bold">Support</h4>
                                                    </div>
                                                    <div className="card mt-4">
                                                   
                                                    <div className="p-5 supportImg">
                                                                                <img src={support} className="img-fluid" />
                                                                            </div>
                                                    </div>
                                                </div>
                                                {/* <Footerad /> */}
                                            </div>
                                        </div>
                                     
                                        <div className='col-sm-6'>
                                            <div className="card">
                                            <div className="card-header"> <div className="card-title">Call Us</div> </div>
                                            <div className='card-body supportlftheight'>
                                            <div className="row">
                                                                                <div className="col-sm-6">
                                                                                    <div className="form-group mb-0">
                                                                                        <label><strong>Phone Number</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>+91 8015774643</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group mb-0">
                                                                                        <label><strong>Email</strong></label>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>info@nucleosws.com</strong></label>
                                                                                       
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label><strong>info@klinicq.com</strong></label>
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                                <div className="col-sm-6">
                                                                                    <div className="form-group">
                                                                                        <label>Monday – Friday</label><br />
                                                                                        <label>8 a.m. – 11 p.m. IST</label><br />
                                                                                        <label>Saturday – Sunday</label><br />
                                                                                        <label>8 a.m. – 8 p.m. IST</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="card">
                                            <div className="card-header"> <div className="card-title">Message Us</div> </div>
                                            <div className='card-body'>
                                            <div className="row">
                                                                        <div className="col-sm-12">
                                                                            
                                                                      
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="Name" isMandatory={true} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group">
                                                                                        <InputField
                                                                                            isMandatory={true}
                                                                                            inputType={"text"}
                                                                                            // title={"Business name"}
                                                                                            name={"name"}
                                                                                            controlFunc={this.handleInput}
                                                                                            content={supportData.name}
                                                                                            placeholder={""}
                                                                                            max={50}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.name}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                             <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="Email" isMandatory={true} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group">
                                                                                        <InputField
                                                                                            isMandatory={true}
                                                                                            inputType={"text"}
                                                                                            name={"email"}
                                                                                            controlFunc={this.handleInput}
                                                                                            content={supportData.email}
                                                                                            placeholder={""}
                                                                                            max={50}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.email}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="Category" isMandatory={true} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group sform-control">
                                                                                        <Select maxMenuHeight={120}
                                                                                            className="form-control p-0"
                                                                                            name="category"
                                                                                            value={supportData.category}
                                                                                            onChange={this.handleselectChange}
                                                                                            options={support_categories}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.category}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                    <Label title="How would you describe the Issue" isMandatory={true} />
                                                                                      
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group sform-control">
                                                                                        <Select maxMenuHeight={120}
                                                                                            className="form-control p-0"
                                                                                            name="issue_description"
                                                                                            value={supportData.issue_description}
                                                                                            onChange={this.handleselectChange}
                                                                                            options={issue_description}
                                                                                        />
                                                                                        <SpanField
                                                                                            classname="text-danger"
                                                                                            title={errors.issue_description}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-4">
                                                                                    <div className="form-group">
                                                                                        <label>Others  </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-8">
                                                                                    <div className="form-group">
                                                                                        <textarea className="form-control" rows="4" maxLength={200} onChange={this.handleInput}
                                                                                            value={supportData.others}
                                                                                            name="others" id="comment" placeholder="Describe the issue 
                                                   "></textarea>
                                                                                    </div>
                                                                                    {/* <p className="actvh">Attachment <i className="fa fa-paperclip" aria-hidden="true"></i> <i className="fa fa-folder-open-o"></i></p> */}
                                                                                </div>
                                                                            </div>
                                                                           

                                                                    <div className="user-notification">
                                                                                <SpanField
                                                                                    classname={
                                                                                        "0" === formSuccess.status
                                                                                            ? "text-danger user-notification"
                                                                                            : "text-success user-notification"
                                                                                    }
                                                                                    title={formSuccess.message}
                                                                                />
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className='col-sm-4'></div>
                                                                                <div className='col-sm-8'>
                                                                                <div className="form-group mb-0 mrgtp">
                                                                                    <button //href="patients-register" 
                                                                                        onClick={this.sendsupportmail}
                                                                                        className="signupbtn abtn adbt " data-toggle="modal"
                                                                                    // data-target=".bs-example-modal-center"
                                                                                    >Send</button>
                                                                                </div>
                                                                                </div>
                                                                           
                                                                            </div>
                                                                        </div>
                                                                      
                                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                      
                        </section>
                    {/*   <FooterSection /> */}
                        {/* {showmodel ? (<div className="modal bs-example-modal-center show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="false" style={{ display: 'block' }}>

                        </section>
                        {/* {showmodel ? (<div className="modal bs-example-modal-center show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="false" style={{ display: 'block' }}>

                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0">Thanks you</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Thanks for your contacting us. Your ticket number
                                    is 12345 our support team will contact you shortly
                                    to resolve your issue
            </p>
                                    <p>Thanks for your patience </p>
                                </div>
                            </div>
                        </div>
                    </div>) : ""} */}
                    </div>

               

            </div>

        );
    }
    componentDidMount = () => {
      //  console.log(this.props.userdata.user_type);
      /*   axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
         axios
          .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status == "1")
              this.setState({
                timeout: data.data.timeout
                
              });
            })
          .catch((e) => { });
     */
    

    }
}

export default connect(
    mapStateToProps,
)(SupportDefault);