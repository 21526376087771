import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/business/Header";
import Spinner from "../components/UI/Spinner";
import axios from "axios";
import { CONFIG } from "../config/data";
import { getPrescription } from "../config/url.json";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class Printapptrescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      records: [],
      with_in_week: [],
      with_in_30: [],
      with_in_3_months: [],
      with_in_6_months: [],
      with_in_year: [],
      with_in_2_years: [],
      selectedOption: ["with_in_week0"],
      error: "",
    };
    this.columns = [
      {
        key: "medicine_description",
        text: "Medicine Description",
        className: "medicine_description",
        align: "left",
        sortable: true,
      },
      {
        key: "days",
        text: "Days",
        className: "days",
        align: "left",
        sortable: true,
      },
      {
        key: "before_food",
        text: "Before Food",
        className: "before_food",
        align: "left",
        sortable: true,
        cell: (record, i) => {
          return record.before_food === 1 ? (
            <div className="text-center">
              <i className="fa fa-check-circle grnbk" aria-hidden="true" />{" "}
            </div>
          ) : (
            ""
          );
        },
      },
      {
        key: "morning",
        text: "Morning",
        className: "morning",
        sortable: true,
        align: "left",
      },
      {
        key: "noon",
        text: "Noon",
        className: "noon",
        sortable: true,
        align: "left",
      },
      {
        key: "night",
        text: "Night",
        className: "night",
        sortable: true,
        align: "left",
      },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
  }

  componentDidMount() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const postData1 = { id: this.props.match.params.id };
    axios
      .post(getPrescription, postData1, CONFIG)
      .then((res) => {
        let data = res.data.consultantHistory || res.data.consultantHistory;
        console.log(data);
        console.log(res.data);
        // if (res.data.status !== "1" || !data || !data[0]) {
        //   return;
        // }
        console.log(res.data.status);
        if (res.data.status === "1") {
          console.log(res.data.data[0]);
          this.setState({
            // with_in_week: data.with_in_week,
            // with_in_30: data.with_in_30,
            // with_in_3_months: data.with_in_3_months,
            // with_in_6_months: data.with_in_6_months,
            // with_in_year: data.with_in_year,
            // with_in_2_years: data.with_in_2_years,
            records: (res.data.data[0] && null != res.data.data[0].medicine_description)
              ? res.data.data[0].medicine_description
              : [],
          });
          // if(res.data.data[0].medicine_description)
          setTimeout(() => {
            const printableElements = document.getElementById("layout-wrapper")
              .innerHTML;
            const orderHtml =
              "<html><head><title></title></head><body>" +
              printableElements +
              "</body></html>";
            const oldPage = document.body.innerHTML;
            document.body.innerHTML = orderHtml;
            console.log(this.props.match.params);

            window.print();

            if (this.props.match.params.user_type.toLowerCase() == 'doctor') {
              document.location.href = '/consult/' + this.props.match.params.id
            } else {
              document.location.href = "/ApptCheckout";
            }
           // document.body.innerHTML = oldPage;
            // console.log(this.props.history.goBack())
            // document.location.href = "/queue_assignment";

            // document.location.href = this.props.history.goBack()
          }, 1000);
        } else {
          this.setState({ error: res.data.message });
        }
      })
      .catch((e) => { });
  }

  render() {
    const {
      loading,
      with_in_week,
      with_in_30,
      with_in_3_months,
      with_in_6_months,
      with_in_year,
      with_in_2_years,
      selectedOption,
      error,
    } = this.state;
    // console.log(with_in_week.length);
    return (
      <div id="layout-wrapper">
      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage main-box">
        {/*     <Header user_id={this.props.userdata.id} /> */}
            <div className="sidemenu">
            <SideBar />
                        </div>
            <section className="main-body hospital-profilegap">
              <div className="container-fluid">
                <div className="checkout-tabs">
                <div className="row">
                        <div className="col-sm-12">
                   
                          <div>
                            <div className="card">
                            <div class="card-header d-flex align-items-center justify-content-between"> 
                            <div class="card-title">Prescription</div> 
                            <button className="signupbtn float-right" onClick={() => this.props.history.goBack()}>Back</button>
                            </div>
                              <div className="card-body ppb-6">
                                {error ? (
                                  <span className="text-danger">{error}</span>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <ReactDatatable
                                          config={this.config}
                                          records={this.state.records}
                                          columns={this.columns}
                                          className="table table-bordered table-striped kas  mobile_table"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
              </div>
           
            </section>
            <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Printapptrescription);
