import React, { Component } from "react";

export default class ErrorAlert extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      // <Fragment>
      //   {this.props.message && (
      //     <FlashMessage duration={5000} persistOnHover={true}>
      //       <div className="alert alert-danger" role="alert">
      //         <strong>{this.props.message}</strong>
      //       </div>
      //     </FlashMessage>
      //   )}
      // </Fragment>
      <div className="alert alert-danger alert-dismissible" role="alert">
        {/* <span
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </span> */}
        {/* <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button> */}

        <strong> {this.props.message}</strong>
      </div>
    );
  }
}
