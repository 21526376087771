import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/container/layout/client/Header";
import SideBar from "../components/container/layout/client/SideBar";
import Select from "react-select";
import DatePicker from "react-date-picker";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import Popup from "../components/UI/Popup";
import Spinner from "../components/UI/Spinner";
import Label from "../components/UI/Label";

import {
  blood_group,
  gender,
  relationship,
} from "../config/data";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import {
  getClientById,
  removeFamilyDetails,
  addFamilyDetails,
  updateFamilyDetails,
  getTimeoutbycategory,

} from "../config/url.json";
import { CONFIG } from "../config/data";
import ReactDatatable from "@ashvin27/react-datatable";
import * as moment from "moment";
import { SOCIAL_HISTORY } from "../constants/config/data";
import CheckboxGroup from "../components/UI/CheckboxGroup";
import { calculate_age } from "../constants/globalLevelFunctions";
import FooterSection from "../components/UI/FooterSection";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { element } from "prop-types";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


let family_details_form = {
  id: "",
  user_id: "",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  gender: "",
  relationship: "",
  blood_group: "",
  phone_number: "",
  medicine_history: {
    current_intake_medicine: "Yes",
    intake_medicine_notes: "",
    medicin_alergy: "Yes",
    medicine_alergy_notes: "",
  },
  medical_history: [],
  social_habits: [],
  family_medical_history: [],
};

class ClientFamilyDetails extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout = this.state;

    this.state = {
      timeout: timeout,
      isTimedOut: false,
      showModal: false,
      loading:false,
      showDeleteConfirmPopup: false,
      deletePopup: false,
      confirmedDelete: false,
      loggedInuserId: props.userdata.id,
      access_token: props.access_token,
      emp_action: "Add new",
      enableAddMemberbtn: true,
      records: [],
      family_details: {
        id: "",
        user_id: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        relationship: "",
        blood_group: "",
        phone_number: "",
        phone_code:"+91",
        medicine_history: {
          current_intake_medicine: "",
          intake_medicine_notes: "",
          medicin_alergy: "",
          medicine_alergy_notes: "",
        },
        medical_history: [],
        social_habits: [],
        family_medical_history: [],
      },
      medicine_history_form: {
        current_intake_medicine: "",
        intake_medicine_notes: "",
        medicin_alergy: "",
        medicine_alergy_notes: "",
      },
      // family_medical_history_SelectedOptions: [],

      // selectedSocialHabits: [],
      newselectedSocialHabits: [],
      addMemberSuccess: "",
      errors: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        blood_group: "",
        relationship: "",
        phone_number: "",
      },
      recordToDelete: [],
    };

    this.cancelDelete = this.cancelDelete.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);

    this.columns = [
      {
        key: "id",
        text: "Registration ID",
        className: "id",
        align: "left",
        sortable: true,
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "age",
        text: "Age",
        className: "age",
        sortable: true,
        align: "left",
      },
      {
        key: "gender",
        text: "Gender",
        className: "gender",
        sortable: true,
        align: "left",
      },
      {
        key: "date_of_birth",
        text: "Date Of Birth",
        className: "dob",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.date_of_birth ? moment(record.date_of_birth).format("DD-MMM-yyyy") : "";
        },
      },
      {
        key: "blood_group",
        text: "Blood Group",
        className: "blood_group",
        sortable: true,
        align: "left",
      },
      {
        key: "relationship",
        text: "Relationship",
        className: "relationship",
        sortable: true,
        align: "left",
      },
      {
        key: "phone_number",
        text: "Mobile Number",
        className: "phone_number",
        sortable: true,
        align: "left",
        cell: (record) => {          
          if(record.phone_number=="") return "";
          else
          return   record.phone_number;
        },
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  this.editRecord(record);
                }}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>

              {"Self" != record.relationship ? (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    this.setState({ deletePopup: true, record: record })
                  }
                >
                  <i className="fa fa-trash"></i>
                </button>
              ) : null}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }

  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");

  };

  _onAction(e) {
    

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
      }
  }


  editRecord(record) {
    // reset the existing row selected value
    console.log(record);
    let currentValue = Object.assign(this.state.family_details, record);
    
console.log(record);
    this.setState({ emp_action: "Edit" });

    let bg = {};
    blood_group.forEach((element, index) => {
      if (element.label == record.blood_group) {
        bg.label = element.label;
        bg.value = element.value;
      }
    });
    currentValue.blood_group = bg;
    console.log(currentValue.medicine_history );
    if(currentValue.medicine_history === ""){
      currentValue.medicine_history =  {
        current_intake_medicine: "Yes",
        intake_medicine_notes: "",
        medicin_alergy: "Yes",
        medicine_alergy_notes: "",
      };
    }

if(currentValue.phone_number && !isValidPhoneNumber(currentValue.phone_number) && !currentValue.phone_number.includes("+91")){
currentValue.phone_number = currentValue.phone_code.concat(currentValue.phone_number)
} else {
  currentValue.phone_number = currentValue.phone_number
}
    const yourDate = record.date_of_birth;
    let date_of_birth = record.date_of_birth ? moment(yourDate, moment.defaultFormat).toDate() : "";
    currentValue.date_of_birth = date_of_birth;

    let sex = {};
    gender.forEach((element, index) => {
      if (element.label == record.gender) {
        sex.label = element.label;
        sex.value = element.value;
      }
    });

    currentValue.gender = sex;

    if ("Self" != currentValue.relationship) {
      let relation = {};
      relationship.forEach((element, index) => {
        if (element.label == record.relationship) {
          relation.label = element.label;
          relation.value = element.value;
        }
      });
       console.log(currentValue.relationship);
      currentValue.relationship = relation;
    }else
    {
      currentValue.relationship="Self";
      console.log(currentValue.relationship);
    }
    console.log(currentValue);
;
    this.setState({ family_details: currentValue });
  }

  deleteRecord(record) {

    this.setState({ deletePopup: false, record: null });
    
    let userId = record.user_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .delete(removeFamilyDetails, { data: { user_id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status == "1") {
          let records = this.state.records;
          const deleteIndex = records
            .map((item) => item.user_id)
            .indexOf(record.user_id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
          toast.error("Family member details successfully deleted.",{
            className:"toast-error"
          });
          this.cancelEdit();
        }
      })
      .catch((e) => { });

      
 
    // this.setState({
    //   showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    // });
    this.setState({
      confirmedDelete: false,
    });
  //  this.setState({ recordToDelete: record });
  }

  handleInput = (e) => {
    let data = this.state.family_details;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;

    if (
      name === "first_name" ||
      name === "last_name"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ family_details: data });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.family_details;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ family_details: data, errors: errors });
  }
  handleFamilyDetailChange(value, field) {
    let fields = this.state.family_details;
    
    let errors = this.state.errors;

    if (value === "first_name") {
      fields.first_name = field.target.value;
      errors.first_name = "";
      // errors[i].year_month_of_completion = '';
    } else if (value === "last_name") {
      fields.last_name = field.target.value;
      errors.last_name = "";
      // errors[i].register_id = '';
    } else if (value === "date_of_birth") {
      fields.date_of_birth = field;
      errors.date_of_birth = "";
      // errors[i].education = '';
    } else if (value === "gender") {
      fields.gender = field;
      errors.gender = "";
      // errors[i].specialization = '';
    } else if (value === "relationship") {
      fields.relationship = field;
      errors.relationship = "";
      // errors[i].university = '';
    } else if (value === "blood_group") {
      fields.blood_group = field;
      errors.blood_group = "";
    } else if (value === "phone_number") {
      fields.phone_number = field;
      errors.phone_number = "";
    } else if (value === "intake_medicine_notes") {
      fields.medicine_history.intake_medicine_notes = field.target.value;
    } else if (value === "medicine_alergy_notes") {
      fields.medicine_history.medicine_alergy_notes = field.target.value;
    }

    this.setState({
      family_details: fields,errors
    });
  }

  handleWorkingDays = (e) => {
    /*const newSelection = e.target.value;
   let newSelectionArray;
   let data = this.state.businessuserData;
   if (data.working_days.indexOf(newSelection) > -1) {
      newSelectionArray = data.working_days.filter(s => s !== newSelection)
   } else {
      newSelectionArray = [...data.working_days, newSelection];
   }

   data.working_days = newSelectionArray;
   this.setState({ businessuserData: data });*/
  };

  componentDidMount = () => {

    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        
        if (data.status == "1")
          this.setState({
            timeout: data.data.timeout

          });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .post(getClientById, { client_id: this.state.loggedInuserId }, CONFIG)
      .then((list) => {
        let memberlist = list.data.data.family_details;
        let members = [];

        memberlist.forEach((element, index) => {
          let member = {};
          member = element;
          member.family_medical_history = member.family_medical_history;
          member.social_habits = null == member.social_habits ? [] : member.social_habits;
          console.log(member.medicine_history)
          member.medicine_history = (null == member.medicine_history || "" === member.medicine_history) ? this.state.medicine_history_form : member.medicine_history;
          member.age = calculate_age(member.date_of_birth);
          
          member.phone_code= "+91";
          if(member.phone_number)
            member.phone_number= ( member.phone_number.includes("+91")) ? member.phone_number : member.phone_code.concat(member.phone_number);
          else
          member.phone_number ="";
          
          members[index] = member;
          memberlist.sort((a,b) => a.id - b.id);
          console.log(memberlist);
        });
     
      

let primary = {};
        primary = list.data.data;
      // primary.phone_number= primary.phone_number.includes("+91") ? primary.phone_number : primary.phone_code.concat(primary.phone_number);
       
       primary.phone_code = "+91";
        primary.relationship = "Self";
        primary.family_medical_history = primary.family_medical_history;
        primary.social_habits = null == primary.social_habits ? [] : primary.social_habits;
        primary.medicine_history = (null == primary.medicine_history || "" == primary.medicine_history) ? this.state.medicine_history_form : primary.medicine_history;
        if(primary.phone_number)
        primary.phone_number= ( primary.phone_number.includes("+91")) ? primary.phone_number : primary.phone_code.concat(primary.phone_number);
      else
      primary.phone_number ="";
        primary.age = calculate_age(primary.date_of_birth);
        memberlist.unshift(primary);

        this.setState({ records: memberlist });
      })
      .catch((e) => { console.log(e) });
  };

  addFamilyMember() {
    let family_details = this.state.family_details;
    family_details.user_id = this.state.loggedInuserId;

    let data = Object.assign({}, family_details);
    
    data.blood_group = family_details.blood_group.label;
    data.gender = family_details.gender.label;
    data.date_of_birth = data.date_of_birth;
 
    
    // data.date_of_birth=moment(data.date_of_birth).format('DD-MM-YYYY').toString();
    // console.log(data);return false;
    data.relationship =
      "Self" == family_details.relationship
        ? "Self"
        : family_details.relationship.label;
    data.family_medical_history = this.getFamilyMedicalHistoryDetails(data);

    data.type = "AddFamilyDetails";
    let { errors } = this.state;
    errors = Validation(data, errors);
    this.setState({ errors: errors});

    if (errors.formIsValid) {
    
      if(data.phone_number != ''){
        data.phone_code = parsePhoneNumber(data.phone_number).countryCallingCode ? ("+" + parsePhoneNumber(data.phone_number).countryCallingCode):"";
        data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;
      } else{
        data.phone_code = "";
        data.phone_number = "";
      }
      this.setState({loading:true })
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.state.access_token}`;
      axios
        .post(addFamilyDetails, data, CONFIG)
        .then((res) => {
          let response = res.data;
            
          if (response.status == "1") {
            let addMemberSuccess = this.state.addMemberSuccess;
            addMemberSuccess = response.message;
            this.setState({ loading:false });
           // this.setState({ addMemberSuccess});
           toast.success("Your Family member details successfully added.",{
            className:"toast-success"
          });
           /*  let records = this.state.records;
            let currentRecord = Object.assign({}, family_details);

            currentRecord.relationship = family_details.relationship.label;
            currentRecord.blood_group = family_details.blood_group.label;
            currentRecord.gender = family_details.gender.label;
            currentRecord.id = response.register_id;
            currentRecord.user_id = response.user_id;
            currentRecord.age = calculate_age(family_details.date_of_birth);

            records.push(currentRecord);
            this.setState({ records }); */
           
              //to clear all form data after successfull update
                this.cancelEdit();
                this.clearAll();
                this.clearMessage();

            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.state.access_token}`;
            axios
              .post(getClientById, { client_id: this.state.loggedInuserId }, CONFIG)
              .then((list) => {
                let memberlist = list.data.data.family_details;
                let members = [];
        
                memberlist.forEach((element, index) => {
                  let member = {};
                  member = element;
                  member.family_medical_history = this.getInitalLoadFamilyMedicalHistory(member);
                  member.social_habits =
                    null == member.social_habits ? [] : member.social_habits;
                  member.medicine_history =
                    null == member.medicine_history
                      ? this.state.medicine_history_form
                      : member.medicine_history;
                  member.age = calculate_age(member.date_of_birth);
                  member.phone_code= "+91";
          if(member.phone_number)
            member.phone_number= ( member.phone_number.includes("+91")) ? member.phone_number : member.phone_code.concat(member.phone_number);
          else
          member.phone_number ="";
                  members[index] = member;
                  memberlist.sort((a,b) => a.id - b.id);
                });
        
                let primary = {};
                primary = list.data.data;
               
                primary.phone_number= primary.phone_number.includes("+91") ? primary.phone_number : primary.phone_code.concat(primary.phone_number);
                primary.relationship = "Self";
                primary.family_medical_history = this.getInitalLoadFamilyMedicalHistory(primary);
                primary.social_habits =
                  null == primary.social_habits ? [] : primary.social_habits;
                primary.medicine_history =
                  (null == primary.medicine_history || "" == primary.medicine_history)
                    ? this.state.medicine_history_form
                    : primary.medicine_history;
                primary.age = calculate_age(primary.date_of_birth);
                memberlist.unshift(primary);
        
                this.setState({ records: memberlist });
              })
              .catch((e) => { console.log(e) });
                
          }else {
            this.setState({loading:false});
            toast.error(response.message,{
              className:"toast-error"
            });
          }
        })
        .catch((e) => { });
    }

    
  }

  clearAll() {
    let family_details = this.state.family_details;
    // let family_details = {};
    family_details.first_name = "";
    family_details.last_name = "";
    family_details.blood_group = "";
    family_details.date_of_birth = "";
    family_details.phone_number = "";
    family_details.gender = "";
    family_details.relationship = "";
    family_details.medical_history= [];
    family_details.social_habits= [];
    family_details.family_medical_history=[];
    family_details.medicine_history=[];
    family_details.medicine_history.current_intake_medicine="";
    family_details.medicine_history.intake_medicine_notes="";
    family_details.medicine_history.medicin_alergy="";
    family_details.medicine_history.medicine_alergy_notes="";
    this.setState({ family_details });
  }

  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          addMemberSuccess: ""
        }), 5000);
        

  };

  updateFamilyMember() {
    let family_details = this.state.family_details;

    let newdata = Object.assign({}, family_details);
console.log(newdata);
    newdata.blood_group = family_details.blood_group.label;
    
    newdata.gender = family_details.gender.label;
    newdata.relationship =
      "Self" === family_details.relationship
        ? "Self"
        : family_details.relationship.label;
        let { errors } = this.state;
        console.log(newdata);
    /* if("Self" !== family_details.relationship){
      
        if(data.phone_number != "")
        data.phone_number =  isValidPhoneNumber(data.phone_number) ? data.phone_number : data.phone_code.concat(data.phone_number);
        else 
        data.phone_number = ""; 
      
    } */
    newdata.id = family_details.user_id;
   // newdata.family_medical_history = this.getFamilyMedicalHistoryDetails(newdata);
    // if(data.phone_number != "")
    // data.phone_number = data.phone_number && isValidPhoneNumber(data.phone_number) ? data.phone_number : data.phone_code.concat(data.phone_number);
    // else 
    // data.phone_number = "";


  
     
    newdata.date_of_birth = newdata.date_of_birth;
    newdata.type = "UpdateFamilyDetails";
    
    errors = Validation(newdata, errors);
    console.log(errors);
    console.log(newdata); 
    // return false;
    this.setState({ errors: errors });
    if (errors.formIsValid) {
      console.log(errors.formIsValid);
       if (newdata.phone_number && newdata.phone_number.length !== 13 && newdata.phone_number !='' && newdata.phone_number !='+91') {
        errors.phone_number = "Enter a valid phone number";
        this.setState({ errors:errors });
        return;
      }
      if(newdata.phone_number && newdata.phone_number != '' && newdata.phone_number !='+91'){
        newdata.phone_code = "+" + parsePhoneNumber(newdata.phone_number).countryCallingCode;
        newdata.phone_number = parsePhoneNumber(newdata.phone_number).nationalNumber;
      } else{
        newdata.phone_code = "";
        newdata.phone_number = "";
      } 
     this.setState({loading:true});
    
      // due to memory limit in json removing unwanted family details to reduce the size.
       delete newdata.family_details;
    
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
   
 
     axios
      .post(updateFamilyDetails, newdata, CONFIG)
      .then((response) => {
        let updateResponse = response.data;
        console.log(response);
        if (updateResponse.status == "1") {
          this.setState({ loading:false });
          let updateMemberSuccess = this.state.addMemberSuccess;
          updateMemberSuccess = updateResponse.message;
         // this.setState({ addMemberSuccess: updateMemberSuccess });
         toast.success("Your profile has successfully updated.",{
          className:"toast-success"
        });

        console.log(family_details);// return false;
          /** Updating the data table */
          let existingRecords = this.state.records;
          let currentRecord = Object.assign({}, family_details);

          var index = existingRecords.findIndex(
            (item) => item.user_id === currentRecord.user_id
          );
if(currentRecord.phone_number === "+91"){
  currentRecord.phone_number = "";
}
          currentRecord.relationship =
            "Self" === family_details.relationship
              ? "Self"
              : family_details.relationship.label;
          currentRecord.blood_group = family_details.blood_group.label;
          currentRecord.gender = family_details.gender.label;
          currentRecord.age = calculate_age(currentRecord.date_of_birth);

          Object.assign(existingRecords[index], currentRecord);

          this.setState({ records: existingRecords,loading:false });

          //to clear all form data after successfull update
          this.cancelEdit();
          this.clearAll();
          this.clearMessage();
        } else {
          this.setState({loading:false});
          toast.error(updateResponse.message,{
            className:"toast-error"
          });
        }
        
      })
      .catch((e) => { });
    }
  }

  cancelEdit() {
    this.setState({ emp_action: "Add new" });
    let family_details = Object.assign({}, family_details_form);
    this.setState({ family_details: family_details_form });
    //  console.log(this.state.family_details)
    let errors = this.state.errors;
    errors = {};
    this.setState({ errors:errors });
    this.clearAll();
  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  okDelete() {
    let record = this.state.recordToDelete;

    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });

    this.setState({
      confirmedDelete: true,
    });

    let family_details = this.state.family_details;

    let userId = record.user_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .delete(removeFamilyDetails, { data: { user_id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status == "1") {
          let records = this.state.records;
          const deleteIndex = records
            .map((item) => item.user_id)
            .indexOf(record.user_id);
          records.splice(deleteIndex, 1);
          this.setState({ records });

          this.cancelEdit();
        }
      })
      .catch((e) => { });

    this.setState({
      confirmedDelete: false,
    });
  }

  /* remove unwanted pipe sign and F-Father & M-mother wordings */
  getFamilyMedicalHistoryDetails = (data) => {
    let selectedFamilyDiseases = data.family_medical_history;

    let fatherDiseases = [];
    let motherDiseases = [];

    selectedFamilyDiseases.forEach((element) => {
      if (element.includes("F")) {
        fatherDiseases.push(parseInt(element.replace("|F", "")));
      } else if (element.includes("M")) {
        motherDiseases.push(parseInt(element.replace("|M", "")));
      }
    });

    let familyHistoryDiseases = [
      { relationship: "Father", disease: fatherDiseases },
      { relationship: "Mother", disease: motherDiseases },
    ];

    return familyHistoryDiseases;
  };

  getInitalLoadFamilyMedicalHistory = (data) => {
    let FamilyhistoryData = [];

    if (data.family_medical_history != ""  &&
      data.family_medical_history != null && data.family_medical_history.length) {
      data.family_medical_history.map((element) => {
        if ("Father" === element.relationship) {
          element.disease.forEach((element) => {
            FamilyhistoryData.push(element + "|F");
          });
        } else if ("Mother" === element.relationship) {
          element.disease.forEach((element) => {
            FamilyhistoryData.push(element + "|M");
          });
        }
      });
    }

    return FamilyhistoryData;
  };
  handleMedicalHistory = (e) => {
    const newSelection = e.target.value;
    let newSelectionArray;
    let data = this.state.family_details;
    if (data.medical_history.indexOf(newSelection) > -1) {
      newSelectionArray = data.medical_history.filter(
        (s) => s !== newSelection
      );
    } else {
      newSelectionArray = [...data.medical_history, newSelection];
    }

    data.medical_history = newSelectionArray;
    this.setState({ family_details: data });
  };
  handleSocialHabitSelection = (e) => {
    let data = this.state.family_details;
    let selectedSocialHabits = data.social_habits;
    let newSelection = e.target.value;
    let isExist = false;
    
    if (selectedSocialHabits != null) {
      
      // prepopulate existing values
      if (selectedSocialHabits.includes(newSelection)) {
        selectedSocialHabits.splice(
          selectedSocialHabits.indexOf(newSelection),
          1
        );
      } else {
        for (let i = 0; i < selectedSocialHabits.length; i++) {
          if (
            selectedSocialHabits[i].includes(newSelection.split("|").shift())
          ) {
            selectedSocialHabits[i] = newSelection;
            isExist = true;
            break;
          }
        }
        if (!isExist) selectedSocialHabits.push(newSelection);
      }
      this.setState({ data });
    }
  };
  handleParentMedicalHistory = (e) => {
    let data = this.state.family_details;
    let fmh = data.family_medical_history;
    let newSelection = e.target.value;
    
    if (fmh.includes(newSelection)) {
      fmh.splice(fmh.indexOf(newSelection), 1);
    } else {
      fmh.push(newSelection);
    }

    this.setState({ data });
  };

  handleMedicineIntake = (event) => {
    let data = this.state.family_details;
    
    data.medicine_history.current_intake_medicine = event.target.value;
    if ("No" === event.target.value) {
      data.medicine_history.intake_medicine_notes = "";
    }
    this.setState({ data });
  };

  handleMedicinealergy = (event) => {
    let data = this.state.family_details;
    data.medicine_history.medicin_alergy = event.target.value;
    if ("No" === event.target.value) {
      data.medicine_history.medicine_alergy_notes = "";
    }
    this.setState({ data });
  };

  render() {

    const { timeout } = this.state;
    let loading = this.state.loading;
    let diseasesArr = this.props.appdata[0].diseases;
    const modalVar = this.state.showDeleteConfirmPopup;
    //    console.log(family_details);
    let oddDiseaseArr = [];
    let evenDiseaseArr = [];
    let medicalHistorydiseasesArr = [];

    let disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];
    let disease = [];
    disease_array.forEach((element, index) => {
      disease.push(element.disease_name);
    });
    disease_array = disease;
    let parent_disease_array = this.props.appdata
      ? this.props.appdata[0].diseases
      : [];
    for (let i = 0; i <= diseasesArr.length; i++) {
      if (i % 2 == 0) {
        if (diseasesArr[i] != undefined) {
          evenDiseaseArr.push(diseasesArr[i]);
        }
      } else {
        if (diseasesArr[i] != undefined) {
          oddDiseaseArr.push(diseasesArr[i]);
        }
      }

      if (diseasesArr[i] != undefined) {
        medicalHistorydiseasesArr.push(diseasesArr[i].disease_name);
      }
    }

    var medicalHisgroup1 = medicalHistorydiseasesArr;
    var medicalHisgroup2 = medicalHisgroup1.splice(
      0,
      Math.ceil(medicalHisgroup1.length / 2)
    );
    var medicalHisgroup3 = medicalHisgroup1.splice(
      0,
      Math.ceil(medicalHisgroup1.length / 2)
    );
    var medicalHisgroup4 = medicalHisgroup2.splice(
      0,
      Math.ceil(medicalHisgroup2.length / 2)
    );

    return (
      <div className="main-content bacfot">
        <div className="mainpage">
          <Header user_id={this.state.loggedInuserId} />
          <div className="main-box">
            <div className="sidemenu">
              <SideBar />
            </div>
            <section className="main-body hospital-profilegap">
            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

              <IdleTimeOutModal
                showModal={this.state.showModal}
                handleClose={this.handleClose}
                handleLogout={this.handleLogout}
              />

              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={timeout} />

              <div className="container-fluid checkout-tabs">
            {/*   <Spinner loading={loading} /> */}
            {loading && <Dnaspinner />}
              <div className="row">
              <div className="col-lg-12">
                  <h4 className="font-weight-bold"> Family Details & Medical History </h4>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-12 mt-4">
                  <div className="card">
                  <div class="card-header"> <div class="card-title">Family Members</div> </div>
                    <div className="card-body ">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                          <div className="row">
                            <div className="col-sm-12 selectHosTable table-responsive">
                             
                              <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                className="table table-bordered table-striped kas  mobile_table"
                              />
                                <Popup
                                      show={this.state.deletePopup}
                                      onHide={() =>
                                        this.setState({
                                          deletePopup: false,
                                        })
                                      }
                                      onConfirm={() =>
                                        this.deleteRecord(this.state.record)
                                      }
                                      title={"Confirmation"}
                                      message={
                                        "Are you sure you want to delete this record?"
                                
                            
                                
                                      }
                                    />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                  <div class="card-header"> <div class="card-title">{this.state.emp_action} Member{" "}</div> </div>
                    <div className="card-body ">
                      <div
                        className="tab-content"
                        id="v-pills-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-shipping"
                          role="tabpanel"
                          aria-labelledby="v-pills-shipping-tab"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                            
                              <div>
                                <span style={{ color: "green" }}>
                                  {this.state.addMemberSuccess}
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="frllftfull">
                                    <div>
                                    <Label title="First Name" isMandatory={true} /> 
                                    </div>
                                    <div className="form-group">
                                      <InputField
                                        inputType={"text"}
                                        
                                        name={"first_name"}
                                        controlFunc={this.handleInput}
                                        content={
                                          this.state.family_details
                                            .first_name
                                        }
                                        placeholder={""}
                                        max={50}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={
                                          this.state.errors.first_name
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div>
                                      <label>Mobile Number</label>
                                    </div>
                                    <div className="form-control">
                                      <div className="row">
                                        <div className="phonenoleft2">
                                          <div className="form-group">
                                            <PhoneInput
                                              international
                                              defaultCountry="IN"
                                              placeholder="Enter phone number"
                                              disabled={this.state.emp_action === "Edit" && this.state.family_details.relationship ==="Self" ?true:false}
                                              value={
                                                this.state.family_details
                                                  .phone_number
                                              }
                                              onChange={this.handleFamilyDetailChange.bind(
                                                this,
                                                "phone_number"
                                              )}
                                              error={
                                                this.state.family_details
                                                  .phone_number
                                                  ? isValidPhoneNumber(
                                                    this.state
                                                      .family_details
                                                      .phone_number
                                                  )
                                                    ? undefined
                                                    : "Invalid phone number"
                                                  : "Phone number required"
                                              }
                                            />
                                            <SpanField
                                              classname="text-danger"
                                              title={this.state.errors.phone_number}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="frllftfull">
                                    <div>
                                    <Label title="Last Name " isMandatory={true} />  
                                     </div>
                                    <div className="form-group">
                                      <InputField
                                        inputType={"text"}
                                        name={"last_name"}
                                        controlFunc={this.handleInput}
                                        content={
                                          this.state.family_details
                                            .last_name
                                        }
                                        placeholder={""}
                                        max={50}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={
                                          this.state.errors.last_name
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="frllftfull">
                                    <div>
                                    <Label title="Sex" isMandatory={true} />   
                                    </div>
                                    <div className="form-group sform-control">
                                      <div className="form-group">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="gender"
                                          value={
                                            this.state.family_details
                                              .gender
                                          }
                                          onChange={this.handleFamilyDetailChange.bind(
                                            this,
                                            "gender"
                                          )}
                                          options={gender}
                                        />
                                        <SpanField
                                          classname="text-danger"
                                          title={this.state.errors.gender}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="frllftfull">
                                    <div>
                                    <Label title="Blood Group " isMandatory={true} /> 
                                     </div>
                                    <div className="form-group sform-control">
                                      <Select maxMenuHeight={120}
                                        className="form-control p-0"
                                        name="blood_group"
                                        value={
                                          this.state.family_details
                                            .blood_group
                                        }
                                        onChange={this.handleFamilyDetailChange.bind(
                                          this,
                                          "blood_group"
                                        )}
                                        options={blood_group}
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={
                                          this.state.errors.blood_group}
                                      />
                                    </div>
                                  </div>
                                  {"Self" !=
                                    this.state.family_details
                                      .relationship ? (
                                    <div className="frllftfull">
                                      <div>
                                      <Label title="Relationship" isMandatory={true} /> 
                                      </div>
                                      <div className="form-group sform-control">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="relationship"
                                          value={
                                            this.state.family_details
                                              .relationship
                                          }
                                          onChange={this.handleFamilyDetailChange.bind(
                                            this,
                                            "relationship"
                                          )}
                                          options={relationship}
                                        ></Select>
                                        <SpanField
                                          classname="text-danger"
                                          title={
                                            this.state.errors.relationship
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-3">
                                  <div className="frllftfull">
                                    <div>
                                    <Label title="Date of Birth " isMandatory={true} />  
                                    </div>
                                    <div className="form-group ">
                                      <DatePicker
                                        className="form-control"
                                        value={
                                          this.state.family_details
                                            .date_of_birth
                                        }
                                        format="dd-MM-yyyy"
                                        onChange={(date) =>
                                          this.handleFamilyDetailChange(
                                            "date_of_birth",
                                            date
                                          )
                                        }
                                        yearPlaceholder='YYYY'
                                        monthPlaceholder='MM'
                                        dayPlaceholder='DD'
                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={
                                          this.state.errors.date_of_birth
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-7">
                      <div className="card">
                      <div class="card-header"> <div class="card-title">Medical History</div> </div>
                        <div className="card-body ">
                          <div
                            className="tab-content"
                            id="v-pills-tabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="v-pills-shipping"
                              role="tabpanel"
                              aria-labelledby="v-pills-shipping-tab"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                

                                  <div className="mhgrid">
                                    {disease_array.map((option) => {
                                      return (
                                        <Fragment key={option}>
                                          <div className="mhgridlist">
                                            <div className="custom-control custom-checkbox mb-1">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={option}
                                                value={option}
                                                onChange={this.handleMedicalHistory}
                                                checked={
                                                  this.state
                                                    .family_details
                                                    .medical_history &&
                                                  this.state.family_details.medical_history.indexOf(
                                                    option
                                                  ) > -1
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={option}
                                              >
                                                {option}
                                              </label>
                                            </div>
                                          </div>
                                        </Fragment>
                                      );
                                    })}
                                  </div>

                                  <div
                                    style={{
                                      display:
                                        this.state.family_details
                                          .medical_history &&
                                          this.state.family_details
                                            .medical_history.length !== 0
                                          ? "none"
                                          : "block",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>


                          
                          </div>
                        </div>
                      </div>
                      <div className="card">
                      <div class="card-header"> <div class="card-title"> Family Medical History</div> </div>
                        <div className="card-body">
                        <div className="row familyHistorylabel">
                              <div className="col-sm-12">
                                <div className="mt-15">
                                 
                                  <div className="mt-4 mt-lg-0">
                                  <div className="row">
                                    <div className="col-sm-6">
                                        <div className="cusflex  check-box-title1">
                                    <label class="group-checkbox-title visibilityHideen">Blood Cancer</label>
                                    <div className="custom-checkbox  labelwidth">
                                      Father
                                    </div>
                                    <div className="custom-checkbox ">
                                      Mother
                                    </div>
                                  </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="cusflex  check-box-title1">
                                    <label class="group-checkbox-title visibilityHideen">Blood Cancer</label>
                                    <div className="custom-checkbox  labelwidth">
                                      Father
                                    </div>
                                    <div className="custom-checkbox ">
                                      Mother
                                    </div>
                                  </div>
                                    </div>
                            
                            </div>
                                    <div className="row">
                                      {parent_disease_array.map(
                                        (option, i) => {
                                          return (
                                            <Fragment key={option.id + i}>
                                              <CheckboxGroup
                                                title={option.disease_name}
                                                className="col-sm-6"
                                                setName={"parents"}
                                                type={"checkbox"}
                                                options={[
                                                  option.id + "|F",
                                                  option.id + "|M",

                                                ]}

                                                selectedOptions={this.state.family_details.family_medical_history}
                                                controlFunc={this.handleParentMedicalHistory}
                                                page={"ClientFamilyDetails"}
                                              /> 
                                             {/*   <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={option.disease_name}
                                                value={[
                                                  option.id + "|F",
                                                  option.id + "|M",

                                                ]}
                                                onChange={this.handleParentMedicalHistory}
                                                checked={
                                                  this.state.family_details.family_medical_history
                                                }
                                              />
 */}
                                            </Fragment>


                                          );
                                        }
                                      )}

                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="card ">
                      <div class="card-header"> <div class="card-title">Medicine History</div> </div>
                        <div className="card-body">
                          <div
                            className="tab-content"
                            id="v-pills-tabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="v-pills-shipping"
                              role="tabpanel"
                              aria-labelledby="v-pills-shipping-tab"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                
                                  <div className="row">
                                    <div className="frllftfull pd-25">
                                      <div>
                                        <label>
                                          Taking any medications,
                                          currently?
                                        </label>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <div className="form-check fltx">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="current_intake_medicine"
                                              id="current_intake_medicineYes"
                                              value="Yes"
                                              checked={
                                                this.state.family_details
                                                  .medicine_history
                                                  .current_intake_medicine ===
                                                "Yes"
                                              }
                                              onChange={
                                                this.handleMedicineIntake
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="current_intake_medicineYes"
                                            >
                                              Yes
                                            </label>
                                          </div>
                                          <div className="form-check fltx">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="current_intake_medicine"
                                              id="current_intake_medicineNo"
                                              onChange={
                                                this.handleMedicineIntake
                                              }
                                              checked={
                                                this.state.family_details
                                                  .medicine_history
                                                  .current_intake_medicine ===
                                                "No"
                                              }
                                              value="No"
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="current_intake_medicineNo"
                                            >
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-10">
                                        <label>
                                          If yes, please list it here
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <InputField
                                          inputType={"text"}
                                          name={"intake_medicine_notes"}
                                          controlFunc={this.handleFamilyDetailChange.bind(
                                            this,
                                            "intake_medicine_notes"
                                          )}
                                          content={
                                            this.state.family_details
                                              .medicine_history
                                              .intake_medicine_notes
                                          }
                                          isvisible={
                                            (this.state.family_details
                                              .medicine_history
                                              .current_intake_medicine !== "" && this.state.family_details
                                              .medicine_history
                                              .current_intake_medicine !== "Yes") ? true:false
                                            
                                          }
                                          placeholder={""}
                                        />
                                      </div>
                                    </div>
                                    <div className="frllftfull pd-25">
                                      <div>
                                        <label>
                                          Allergy to any medication ?{" "}
                                        </label>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <div className="form-check fltx">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="medicin_alergy"
                                              id="medicin_alergyYes"
                                              value="Yes"
                                              checked={
                                                this.state.family_details
                                                  .medicine_history
                                                  .medicin_alergy ===
                                                "Yes"
                                              }
                                              onChange={
                                                this.handleMedicinealergy
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="medicin_alergyYes"
                                            >
                                              Yes
                                            </label>
                                          </div>
                                          <div className="form-check fltx">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="medicin_alergy"
                                              id="medicin_alergyNo"
                                              value="No"
                                              checked={
                                                this.state.family_details
                                                  .medicine_history
                                                  .medicin_alergy === "No"
                                              }
                                              onChange={
                                                this.handleMedicinealergy
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="medicin_alergyNo"
                                            >
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-10">
                                        <label>
                                          If yes, please list it here
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <InputField
                                          inputType={"text"}
                                          name={"medicine_alergy_notes"}
                                          controlFunc={this.handleFamilyDetailChange.bind(
                                            this,
                                            "medicine_alergy_notes"
                                          )}
                                          content={
                                            this.state.family_details
                                              .medicine_history
                                              .medicine_alergy_notes
                                          }
                                          isvisible=
                                          {
                                            (this.state.family_details
                                              .medicine_history
                                              .medicin_alergy !== "" && this.state.family_details
                                              .medicine_history
                                              .medicin_alergy !== "Yes") ? true:false
                                            
                                          }
                                          placeholder={""}
                                        />
                                      </div>
                                    </div>

                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card h371">
                      <div class="card-header"> <div class="card-title"> Social History</div> </div>
                        <div className="card-body">
                        <div className="frllftfull">
                                      <div className="col-sm-12">
                                      
                                        <div className="mt-4 mt-lg-0 socialHistory">
                                          <div className="row">
                                            {SOCIAL_HISTORY.map(
                                              (option, i) => {
                                                return (
                                                  <Fragment
                                                    key={option.id + i}
                                                  >
                                                    <CheckboxGroup
                                                      title={option.name}
                                                      className="col-sm-6 social-habits"
                                                      islabel="true"
                                                      setName={
                                                        "socialHabits"
                                                      }
                                                      type={"checkbox"}
                                                      options={[
                                                        option.id +
                                                        "|Daily",
                                                        option.id +
                                                        "|Weekly",
                                                        option.id +
                                                        "|Monthly",
                                                        option.id +
                                                        "|Never",
                                                      ]}
                                                      selectedOptions={
                                                        this.state
                                                          .family_details
                                                          .social_habits
                                                      }
                                                      controlFunc={
                                                        this
                                                          .handleSocialHabitSelection
                                                      }
                                                      page={
                                                        "ClientUpdateProfile"
                                                      }
                                                    />
                                                  </Fragment>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 btncent">
                    <div className="form-group signupformbtn">
                      <button
                        style={{
                          display:
                            this.state.emp_action === "Add new"
                              ? "inline-block"
                              : "none",
                        }}
                        className="signupbtn abtn  adbt"
                        onClick={this.addFamilyMember.bind(this)}
                      >
                        <i
                          className="fa fa-user-plus"
                          aria-hidden="true"
                        ></i>{" "}
                        &nbsp;Add
                      </button>

                      <button
                        style={{
                          display:
                            this.state.emp_action === "Edit"
                              ? "inline-block"
                              : "none",
                        }}
                        className="signupbtn abtn mr-1 adbt"
                        onClick={this.updateFamilyMember.bind(this)}
                      >
                        Update
                      </button>
                      <button
                        style={{
                          display:
                            this.state.emp_action === "Edit"
                              ? "inline-block"
                              : "none",
                        }}
                        className="signupbtn abtn  adbt"
                        onClick={this.cancelEdit.bind(this)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  {/* 
                  <div className="row">
                    <div className="col-sm-12">
                      <img
                        src={require("../assets/images/advertismenttop2.jpg")}
                        className="advertisment"
                      />
                    </div>
                  </div> */}

                  {/* <Modal isOpen={modalVar}>
                    <ModalHeader>
                      <label className="text-center wdt100">
                        Confirmation
                      </label>
                    </ModalHeader>
                    <ModalBody>
                      <div className="col-sm-12">Are you sure?</div>
                      <div className="row">
                        <div className="col-sm-3">
                          <Button
                            color="success"
                            onClick={this.okDelete.bind(this)}
                          >
                            Ok
                          </Button>
                        </div>
                        <div className="col-sm-3">
                          <Button
                            color="danger"
                            onClick={this.cancelDelete}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal> */}

                </div>
              </div>

              </div>
           
            </section>
             <FooterSection />
          </div>

        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientFamilyDetails);