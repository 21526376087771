import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { getallMasterLabList, updateLabStatus,addMasterLab,labimport } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import Select from "react-select";
import FooterSection from '../components/UI/FooterSection';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import InputField from "../components/UI/InputField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Validation from "../actions/Validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import LabExport from "./LabExport";
import AdminLabTemplate from "./AdminLabTemplate";

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

const workSheetName = 'Worksheet-1';
const workBookName = 'MyWorkBook';
const myInputId = 'myInput';
const columns = [
  { header: 'S.No', key: 's_no' },
  { header: 'Lab ID', key: 'id' },
  { header: 'Laboratory Name', key: 'laboratory_name' },
  { header: 'Laboratory Location', key: 'laboratory_location' },
  { header: 'Zip code', key: 'zip_code' },
  { header: 'Country', key: 'country' },
  { header: 'State', key: 'state' },
  { header: 'City', key: 'city' },
  { header: 'Phone Number', key: 'phone_number' },
  { header: 'Online Prescription', key: 'online_prescription' },

];
const status = [
    { value: "Pending", label: "Pending" },
    { value: "Accepted", label: "Accepted" },
];
const onlineOptions = [{ value: 1, label: "Yes" }, { value: 0, label: "No" }];
const google = window.google;

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

class AdminLabList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSuccess: {
                message: "",
                status: "",
            },
            loading: false,

            formSuccess: {
                message: "",
                status: ""
              },
      labData: {
        laboratory_name: "",
        laboratory_location: "",
        address:"",
        country:"",
        state:"",
        city:"",
        zip_code:"",
        phone_number:"",
        google_address: false,
        online_prescription_obj: { value: "", label: "" },
        online_prescription: ""
      },
            errors: {
                laboratory_name: "",
                laboratory_location: "",
                address:"",
                country:"",
                state:"",
                city:"",
                zip_code:"",
                phone_number:"",
                online_prescription: ""
              },
        
            records: []
        }
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
   
        this.columns = [
          {
            key: "s_no",
            text: "S.No",
            className: "s_no",
            align: "left",
            sortable: true,
          
          }, 
           {
                key: "id",
                text: "Lab ID",
                className: "id",
                align: "left",
                sortable: true,
            },
           /*  {
                key: "laboratory_id",
                text: "Labouratory ID",
                className: "laboratory_id",
                align: "left",
                sortable: true,
            }, */
            {
                key: "laboratory_name",
                text: "Laboratory Name",
                className: "laboratory_name",
                align: "left",
                sortable: true,
            },
            {
                key: "laboratory_location",
                text: "Laboratory Location",
                className: "laboratory_location",
                align: "left",
                sortable: true,
            },
            {
                key: "country",
                text: "Country",
                className: "country",
                align: "left",
                sortable: true,
            },
            {
                key: "state",
                text: "State",
                className: "state",
                align: "left",
                sortable: true,
            },
            {
                key: "city",
                text: "City",
                className: "city",
                align: "left",
                sortable: true,
            },
            {
                key: "zip_code",
                text: "zip code",
                className: "zip_code",
                align: "left",
                sortable: true,
            },
            {
                key: "phone_number",
                text: "Phone Number",
                className: "phone_number",
                sortable: true,
                align: "left",
            },

            {
                key: "online_prescription",
                text: "Online Prescription",
                className: "online_prescription",
                sortable: true,
                align: "left",
            },

         /*    {
                key: "status",
                text: "Status",
                className: "status statuswidth",
                tHeadClassName: "csutomcolspan",

                sortable: true,
                align: "left",
                cell: (record) => {
                    let obj = {
                        label: record.status,
                        value: record.status,
                    };
                    return (
                        <Select
                            maxMenuHeight={120}
                            className="form-control p-0"
                            name="status"
                            value={obj}
                            onChange={(data) => this.handleColumnChange(record, data)}
                            options={status}
                            disabled={(option) => option.value === "Accepted"}
                        // disabled={record.status == "Accepted"}
                        />
                    );
                },
            },
          */   // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: (record) => {
            //         return (
            //             <Fragment>
            //                 <button
            //                     className="btn btn-primary btn-sm"
            //                     onClick={() => this.editRecord(record)}
            //                     style={{ marginRight: "5px" }}
            //                 >
            //                     <i className="fa fa-edit"></i>
            //                 </button>
            //                 <button
            //                     className="btn btn-danger btn-sm"
            //                     onClick={() =>
            //                         this.setState({ deletePopup: true, record: record })
            //                     }
            //                 >
            //                     <i className="fa fa-trash"></i>
            //                 </button>
            //             </Fragment>
            //         );
            //     },
            // },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    onFileChange = event => {

      // Update the state
      this.setState({ file: event.target.files[0] });
      readXlsxFile(event.target.files[0]).then((rows) => {
          console.log(rows);
          console.log("rows");
          this.setState({ labData: rows });
          // `rows` is an array of rows
          // each row being an array of cells.
      })

  };
  onFileUpload = () => {

    let data =
        { data: this.state.labData };
    if (this.state.labData.length) {
        axios.post(labimport, data).then((result) => {
            console.log(result);
            this.setState({ formSuccess: result.data, file: "", labimport: [] })
           // this.getmaster();
            this.clearMessage();
            axios.defaults.headers.common[
              "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
              .post(getallMasterLabList, null, CONFIG)
              .then((list) => {
                  console.log(list)
  
                  this.setState({ records: list.data.data });
                  let users = [];
                  let userlist = list.data.data;
                  userlist.forEach((element, index) => {
                    let user = {};
                    user.s_no = index + 1;
                    user.id=element.id;
                    user.laboratory_name = element.laboratory_name;
                    user.laboratory_location=element.laboratory_location;
                    user.zip_code=element.zip_code;
                    user.country=element.country;
                    user.state=element.state;
                    user.city=element.city;
                    user.phone_number = element.phone_number;
                /*     user.email=element.email; */
                    user.online_prescription=element.online_prescription;
                    users[index] = user;
                  });
          
                  this.setState({ records: users });
              })
              .catch((e) => { });
        });
    }






};

clearimport=()=>{
  this.setState({ file:"" });
};

  
    handleInput = (e) => {
        let data = this.state.labData;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;
        let errors = this.state.errors
        if (
          
          name === "laboratory_name" ||
          name === "laboratory_location" 
             
        ) {
          this.validateMaxLength(name, value, max);
        } else {
        data[name] = value;
        errors[name] = value;
        }
        this.setState({ labData: data,errors });
      };
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };
    handleFormSubmit = (e) => {
        e.preventDefault();
    
        let { labData, errors, google_address } = this.state;
       
        errors = Validation(labData, errors);
        console.log(errors);
        if (!google_address && labData.address !== "") {
          errors.address = "Please choose address from autocomplete";
        }
        this.setState({ errors: errors });
        if (errors.formIsValid) {
          this.setState({ loading: true });
    
          console.log("form is valid");
    
          try {
            let data = this.state.labData;
            data.laboratory_name = data.laboratory_name;
            data.laboratory_location=data.laboratory_location;
            data.online_prescription=data.online_prescription_obj.label;
            data.online_prescription=data.online_prescription;
            data.address=data.address;
            data.city=data.city;
            data.country=data.country;
            data.state=data.state;
            data.zip_code=data.zip_code;
            data.phone_number = data.phone_number.replace(data.phone_code, "");
             console.log(data);
             const result = axios
              .post(addMasterLab, data, CONFIG)
              .then((res) => {
                let response = res.data;
    
                // return false;
                if (response.status == "1") {

                    toast.success("laboratory  Added Successfully. ",{
                        className:"toast-success"
                      });
                      this.clearMessage();
                      this.cancel();
                      axios.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${this.props.access_token}`;
                    axios
                        .post(getallMasterLabList, null, CONFIG)
                        .then((list) => {
                            console.log(list)
            
                            this.setState({ records: list.data.data });
                            let users = [];
                            let userlist = list.data.data;
                            userlist.forEach((element, index) => {
                              let user = {};
                              user.s_no = index + 1;
                              user.id=element.id;
                              user.laboratory_name = element.laboratory_name;
                              user.laboratory_location=element.laboratory_location;
                              user.zip_code=element.zip_code;
                              user.country=element.country;
                              user.state=element.state;
                              user.city=element.city;
                              user.phone_number = element.phone_number;
                             user.online_prescription=element.online_prescription;
                            
                              users[index] = user;
                            });
                    
                            this.setState({ records: users });
                        })
                        .catch((e) => { });
                } 
              })
              .catch((e) => {
                console.log(e);
              });
            // dispatch(
            //     addUserDeails(result.data)
            // )
          } catch (e) {
            this.setState({ loading: false });
    
            // dispatch(
            // addError(e.message)
            // )
          }
        }
      };
      handleselectChange = (selectedOptions, e) => {
        let data = this.state.labData;
        let errors = this.state.errors
        errors.online_prescription = ""
        data[e.name] = selectedOptions;
        this.setState({ labData: data,errors });
      };
  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();

    console.log(place);

    let { labData } = this.state;

    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
    var lat = place.geometry.location.lat();
    // get lng
    var lng = place.geometry.location.lng();
    labData.latitude = lat.toString();
    labData.longitude = lng.toString();
    labData.place_url = place.url;
    labData.laboratory_location=place.formatted_address;
     console.log(place.formatted_address);
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
        labData.state = val;
        if (addressType === "locality")
        labData.city = val;
        if (addressType === "country") labData.country = val;
        // if (addressType === "street_number") {

            labData.address= place.name;
        // }
       // if (addressType === "sublocality_level_1") pharmacyData.address2 = val;
        if (addressType === "postal_code") labData.zip_code = val;
      }
    }

    // let ClientData = this.state;
    this.setState({
        labData,
      google_address: true,
    });
  }

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.labData;
    data.country = val;
    this.setState({ labData: data });
  }
  /* for state detail change */

  selectState(val) {
    let data = this.state.labData;
    data.state = val;
    this.setState({ labData: data });
  }
  handlePhoneInput = (event) => {
    let labData = this.state.labData;
    labData.phone_number = event;
    this.setState({ labData });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.labData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ labData: data, errors: errors });
  }

    UpdateColumnChange = (data) => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        let request = {
            lab_id: data.id, status: data.status
        }
        axios.post(updateLabStatus, request).then((list) => {
            let records = this.state.records;
            console.log(list.data)
            console.log(data)
            // data = list.data.data;
            if (list.data.status === "1") {
                var recIndex = findWithAttr(records, "id", data.id);

                records[recIndex].status = data.status;

                this.setState({ records, tableSuccess: list.data });
            }

            this.clearMessage();
        });
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };
    
    cancel = () => {

        let data = this.state.labData;
        data.laboratory_name = "";
        data.id = "";
        data.online_prescription_obj = {
    
        };
        data.laboratory_location = "";
        data.online_prescription = "";
        data.address="";
        data.city="";
        data.country="";
        data.state="";
        data.phone_number="";
        data.zip_code="";
        let errors = this.state.errors;
    
        errors = this.clearErrors(errors);
    
        this.setState({ labData: data, actions: "add", errors: errors });
      }
      clearErrors = (errors) => {

        errors.laboratory_name = "";
        errors.laboratory_location = "";
        errors.online_prescription = "";
        return errors
      }
    
    componentDidMount = () => {
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("address"),
            {}
          );
          this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
   
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(getallMasterLabList, null, CONFIG)
            .then((list) => {
                console.log(list)

                this.setState({ records: list.data.data });
                
                let users = [];
                let userlist = list.data.data;
                userlist.forEach((element, index) => {
                  let user = {};
                  user.s_no = index + 1;
                  user.id=element.id;
                  user.laboratory_name = element.laboratory_name;
                  user.laboratory_location=element.laboratory_location;
                  user.zip_code=element.zip_code;
                  user.country=element.country;
                  user.state=element.state;
                  user.city=element.city;
                  user.phone_number = element.phone_number;
                  user.online_prescription=element.online_prescription;
                  users[index] = user;
                 
                });
        
                this.setState({ records: users});
          
              //  console.log(JSON.stringify(this.state.records));
               
            })
            .catch((e) => { });
    }
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {

            tableSuccess,
            formSuccess,
            labData,
            errors,
            loading,
            data,
            exportdataone
        } = this.state;
        return (
          
            <div id="layout-wrapper">
               <style>
        {`
          table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            textAlign: center;
          }
           th, td { 
             padding: 4px;
           }
        `}
      </style>
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Lab List </h4>
                                    <div className="row mt-4">
                                    <div className="col-sm-12">
                                    <div className="card">
                                    <div className="card-header"> <div className="card-title">Import Lab</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                       
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    0 === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div className="float-right btn "> <AdminLabTemplate /></div>
                                                        <div className="form-group  mt-2">
                                                                    <div className='d-flex justify-content-center mg-btm7'>
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                        <div className="row">
                                                            <div className="col-sm-4" >
                                                                <div className="custom-file text-left">
                                                                    <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" />
                                                                   {/*  <label className="custom-file-label" htmlFor="file">Choose file</label>
  */}                                                                   <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                                                                  {/* <div className="form-group  mt-2">
                                                                    <div className='justify-content-center'>
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    </div>
                                                                </div> */}
                                                                </div>
                                                            </div>



                                                         
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                       {/*  <label className="control-label">{this.state.file ? this.state.file.name : ""}</label> */}
                                                    </div>
                                                </div>
                                             
                                            </div>
                                            </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body  ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                          {/*    Export to : <input id={myInputId} defaultValue={workBookName} /> .xlsx
                                                              <button onClick={this.saveExcel}>Export</button> */}
                                                            
                                                            <Popup
                                                                show={this.state.addPopup}
                                                                onHide={PopupClose}
                                                                onConfirm={PopupConfirm}
                                                                title={"Confirmation"}
                                                                message={"Are you sure you want to delete this record?"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                    <div className="card">
                                    <div className="card-header"> <div className="card-title">Export Lab</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                       
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    "0" === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div >
                                                            <div className="col-sm-4" >
                                                                
                                                                <LabExport records={this.state.records}/>
                                                                
                                                               
                                                            </div>



                                                         
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                       {/*  <label className="control-label">{this.state.file ? this.state.file.name : ""}</label> */}
                                                    </div>
                                                </div>
                                             
                                            </div>
                                            </div>
                                        <div className="col-sm-12 pr0">
            <div className="card ">
            <div className="card-header"> <div className="card-title">  {this.state.actions === "add"
                      ? "Add"
                      : "Add"}{" "}
                    Laboratory</div> </div>
              <div className="card-body">
                <div className="user-notification">
                  <SpanField
                    classname={(0 == formSuccess.status) ? "text-danger user-notification" :
                      "text-success user-notification"}
                    title={formSuccess.message}
                  />
                   <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                </div>
                <div>
                 
                 
                  <div className="row">
                          <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                            Laboratory Name
                          </label>
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">

                              <InputField
                                inputType={"text"}
                                name={"laboratory_name"}
                                controlFunc={this.handleInput}
                                content={labData.laboratory_name}
                                placeholder={""}
                                max={50}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.laboratory_name}
                            />
                          </div>
                        </div>
                      </div>
                          </div>
                           <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                            Address
                          </label>
                        </div>
                        <div className="form-group frlrit">
                          <div className="position-relative">
                            <div className="input-group">

                              <InputField
                              id="address"
                                inputType={"text"}
                                name={"address"}
                                controlFunc={this.handleInput}
                                content={labData.address}
                                placeholder={""}
                                max={200}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.address1}
                            />
                          </div>
                        </div>
                      </div>
                           </div>
                    
                            <div className="col-sm-6">
                    <div className="frllftfull">
                    <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                            City
                          </label>
                        </div>
                              <div className="form-group frlrit">
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                //  title={"City"}
                                  name={"city"}
                                  max={20}
                                  controlFunc={this.handleInput}
                                  content={labData.city ? labData.city : ""}
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.city}
                                />
                              </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                            State
                          </label>
                        </div>
                              <div className="form-group frlrit">
                             {/*    <Label title="State" isMandatory={true} />
 */}
                                <RegionDropdown
                                  name="state"
                                  defaultOptionLabel="Select State"
                                  country={labData.country}
                                  value={labData.state || ""}
                                  className="form-control"
                                  onChange={(val) => this.selectState(val)}
                                  placeholder="State"
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.state}
                                />
                                
                              </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                            Country
                          </label>
                        </div>
                              <div className="form-group frlrit">
                            {/*     <Label title="Country" isMandatory={true} />
 */}
                                <CountryDropdown
                                  placeholder="Country"
                                  name="country"
                                  value={labData.country || ""}
                                  className="form-control"
                                  onChange={(val) => this.selectCountry(val)}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.country}
                                />
                              </div>
                            </div>
                           
                            <div className="col-sm-6">
                            <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                          Zip/Pincode
                          </label>
                        </div>
                              <div className="form-group frlrit">
                                <InputField
                                  isMandatory={true}
                                  inputType={"text"}
                                 // title={"Zip/Pincode"}
                                  name={"zip_code"}
                                  max={10}
                                  controlFunc={this.handleInput}
                                  content={labData.zip_code}
                                  placeholder={""}
                                />
                                <SpanField
                                  classname="text-danger"
                                  title={errors.zip_code}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                          Phone number
                          </label>
                        </div>
                              <div className="form-group mb-0">
                               {/*  <Label title="Phone number" isMandatory={true} /> */}
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-control">
                                    <PhoneInput
                                      international
                                      defaultCountry="IN"
                                      placeholder="Enter phone number"
                                      value={labData.phone_number}
                                      onChange={this.handlePhoneInput}
                                      error={
                                        labData.phone_number
                                          ? isValidPhoneNumber(
                                            labData.phone_number
                                          )
                                            ? undefined
                                            : "Invalid phone number"
                                          : "Phone number required"
                                      }
                                    />
                                  </div>
                                  <SpanField
                                    classname="text-danger"
                                    title={errors.phone_number}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                      <div className="frllftfull">
                        <div className="form-group frllft mbbre">
                          <label className="ffgrd">
                            Online Prescription
                          </label>
                        </div>
                        <div className="form-group sform-control frlrit">
                          <div className="position-relative">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text inpico">
                                  <i className="fas fa-search"></i>
                                </span>
                              </div>
                              <Select maxMenuHeight={120}
                                className="form-control p-0"
                                name="online_prescription_obj"
                                value={
                                    labData.online_prescription_obj
                                }
                                onChange={this.handleselectChange}
                                options={onlineOptions}
                              />
                            </div>
                            <SpanField
                              classname="text-danger"
                              title={errors.online_prescription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="row">
                  
                    <div className="col-sm-12">
                      <div className="form-group text-center float-right">
                        <button
                          href="patients-register"
                          className="signupbtn abtn btdpml btn-margin"
                          onClick={this.handleFormSubmit}
                        >
                          {this.state.actions === "add"
                            ? "+ Add"
                            : "Add"}
                        </button>

                        <button
                          href="patients-register"
                          className="signupbtn abtn btdpml"
                          onClick={this.cancel}>
                          {"Cancel"}
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </section>
                            <FooterSection />
                        </div>
                       
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(AdminLabList);