import { language } from "../config/data";
import { working_days } from "../constants/config/data";
import color from "@material-ui/core/colors/amber";
import * as moment from "moment";
/* NUll check for parse array object and adding sequence number for S:NO column */
export function parseObjectValue(val) {
  let resultObj = [];
  let getVal = JSON.parse(val);
  if ("null" === getVal || null == getVal) {
    resultObj = [];
  } else {
    resultObj = getVal;
  }
  /*adding sequence number*/
  resultObj.forEach((resultObj, index) => {
    resultObj.s_no = index + 1;
  });
  return resultObj;
}

/* Language Conversion */
export function stringToLabelValue(val) {
  let convertedlanguage = [];
  if (val) {
    language.forEach((element, index) => {
      if (val.indexOf(element.value) > -1) {
        convertedlanguage.push({
          value: element.value,
          label: element.label,
        });
      }
    });
  }
  return convertedlanguage;
}

/* hide and show the column*/
export function show_hide_column(table, isDisable) {
  console.log(isDisable);
  let lastthead = table.tHead.lastChild.lastChild;
  if (isDisable) {
    lastthead.classList.add("showcolumn");
    lastthead.classList.remove("hidecolumn");
  } else {
    lastthead.classList.add("hidecolumn");
    lastthead.classList.remove("showcolumn");
  }

  let tbody = table.getElementsByTagName("tbody")[0];
  var allrows = tbody.getElementsByTagName("tr");
  console.log(allrows);
  allrows.forEach(function (row) {
    if (isDisable) {
      console.log("if");
      row.lastChild.classList.add("showcolumn");
      row.lastChild.classList.remove("hidecolumn");
    } else {
      console.log("else");
      row.lastChild.classList.add("hidecolumn");
      row.lastChild.classList.remove("showcolumn");
    }
  });
}

export function getKeyFromValue(mapData, value) {
  let Key = [...mapData.entries()]
    .filter(({ 1: v }) => v === value)
    .map(([k]) => k);
  return Key;
}

export function getMaxIdOfRecords(records) {
  let returnId = "";
  if (records.length > 0) {
    let maxId = Math.max.apply(
      Math,
      records.map(function (o) {
        return o.id;
      })
    );
    returnId = maxId + 1;
  } else {
    returnId = records.length + 1;
  }
  return returnId;
}

export function calculate_age(date) {
  let today = new Date();
  let birthDate = new Date(date); // create a date object directly from `dob1` argument
  let age_now = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
}
export function getAppointmentStatus(record, columnType) {
  let returnStatus = false;
  switch (columnType) {
    case "SCHEDULED":
      returnStatus = true;
      break;
    case "Check-In":
      if ("SCHEDULED" === record.appt_status || "Cancelled" === record.appt_status || "RESCHEDULE" === record.appt_status) {
        returnStatus = false;
      } else {
        returnStatus = true;
      }
      break;
    case "Consulting":
      if (
        "SCHEDULED" === record.appt_status ||
        "Check-In" === record.appt_status ||
        "Cancelled" === record.appt_status||
        "RESCHEDULE" === record.appt_status
      ) {
        returnStatus = false;
      } else {
        returnStatus = true;
      }
      break;
    case "Check-Out":
      if (
        "SCHEDULED" === record.appt_status ||
        "Check-In" === record.appt_status ||
        "Consulting" === record.appt_status ||
        "Cancelled" === record.appt_status ||
         "RESCHEDULE" === record.appt_status
      ) {
        returnStatus = false;
      } else {
        returnStatus = true;
      }
      break;
      case "RESCHEDULE":
        if ("RESCHEDULE" === record.appt_status)
      returnStatus = true;
      break;
    default:
      if ("Cancelled" === record.appt_status) returnStatus = true;
      else returnStatus = false;
      break;
  }
  return returnStatus;
}
export function getQueueStatus(record, columnType) {
  let returnStatus = false;
  switch (columnType) {
    case "queue-in":
      returnStatus = true;
      break;
    case "Check-In":
      if ("queue-in" === record.status || "Cancelled" === record.status) {
        returnStatus = false;
      } else {
        returnStatus = true;
      }
      break;
    case "Consulting":
      if (
        "queue-in" === record.status ||
        "Check-In" === record.status ||
        "Cancelled" === record.status
      ) {
        returnStatus = false;
      } else {
        returnStatus = true;
      }
      break;
    case "Check-Out":
      if (
        "queue-in" === record.status ||
        "Check-In" === record.status ||
        "Consulting" === record.status ||
        "Cancelled" === record.status
      ) {
        returnStatus = false;
      } else {
        returnStatus = true;
      }
      break;
    default:
      if ("Cancelled" === record.status) returnStatus = true;
      else returnStatus = false;
      break;
  }
  return returnStatus;
}

export function getTimeFormat(time, type) {
  let TimeFormatValue = "";
  if (type == "Mins") {
    TimeFormatValue = "00:" + time + ":00";
  } else {
    TimeFormatValue = time + ":00:00";
  }
  return TimeFormatValue;
}
export function TimetoMin(time) {
  var actualTime = time.split(':');
  console.log("The time=" + time);
  var totalSeconds = (+actualTime[0]) * 60 * 60 + (+actualTime[1]) * 60 + (+actualTime[2]);
  return totalSeconds / 60
}

export function getTimeFormatToUI(time) {
  console.log(time);
  let time_Ui_Data = {};
  let arrayTime = time.split(":");
  if (arrayTime[0] != "00") {
    time_Ui_Data.Type = "Hours";
    time_Ui_Data.Time = arrayTime[0];
  } else {
    time_Ui_Data.Type = "Mins";
    time_Ui_Data.Time = arrayTime[1];
  }
  return time_Ui_Data;
}



export function getDateISOFormat(givenDate) {

  let dd = ("0" + givenDate.getDate()).slice(-2);
  //let dd = givenDate.getDate();
  let mm = ("0" + (givenDate.getMonth() + 1)).slice(-2);
  //let mm = givenDate.getMonth() + 1;
  let yyyy = givenDate.getFullYear();

  return yyyy + "-" + mm + "-" + dd;

}

export function getShiftSymbol(data) {
  let shiftSymbol = ''
  switch (data) {
    case 'Morning':
      shiftSymbol = 'fa fa-coffee';
      break;
    case 'Afternoon':
      shiftSymbol = 'fa fa-sun-o';
      break;
    case 'Evening':
      shiftSymbol = 'fas fa-cloud-moon';
      break;
    default:
      shiftSymbol = 'fa fa-moon-o';
  }
  return shiftSymbol;
}

export function getGivenDateStringFormat(date) {
  var date = new Date(date);
  return date.toDateString();
}

export function getAvaliableCount(appointmentdate,data) {
  let count = 0;
 // var today = new Date(),
  //  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  
 // var date1 = moment(date).format("YYYY-MM-DD");
 
  var current = moment().format("YYYY-MM-DD HH:mm:ss");
  data.forEach(element => {
    element.appointmentHours.forEach(e => {
      var dt = moment((appointmentdate+" "+e.hours), ["YYYY-MM-DD h:mm A"]).format("YYYY-MM-DD HH:mm:ss");
      console.log(current);
     console.log(dt);
      if (e.isAvailable && dt>=current) count++;
    })
  });
  switch (count) {
    case 0: return 'No Slots'
    case 1: return count + ' Slot'
    default: return count + ' Slots'
  }
}

export function getFrequencyFromDays(data) {

  let result = []
  data.forEach(element => {
    var val = "";


    switch (element) {
      case "Sun":
        val = 1; break;
      case "Mon":
        val = 2; break;
      case "Tue": val = 3; break;
      case "Wed": val = 4; break;
      case "Thu": val = 5; break;
      case "Fri": val = 6; break;
      default: val = 7;
    }

    if (val !== "")
      result.push(val)
  });

  return result.toString();

}

export function getPatientProfileColor(queue_data) {
  let color = 'white';
  if(queue_data.length!=0){
   // console.log(queue_data.status)
    switch (queue_data.status ? queue_data.status : "") {
      case "queue-in":
        color = 'orange'; break;
      case "Check-In":
        color = 'green'; break;
      default: color = 'white';
    }
  }
 
  return color;
}


export function getPatientAppointmentColor(status) {
  let color = 'white';
  if(status != ""){
   // console.log(queue_data.status)
    switch (status) {
      case "SCHEDULED":
        color = 'orange'; break;
      case "RESCHEDULE":
          color = 'orange'; break;        
      case "Check-In":
        color = 'green'; break;
      case "Consulting":
        color = 'green'; break;
      default: color = 'white';
    }
  }
 
  return color;
}



export function getPatientsubstring(queue_data) 
{
 let str=queue_data;
  let substr=str.substring(0,20);
  return substr;
}
export function gethhmmss(data) {
  console.log(data);

  var result = [];
  var morning_shift = {};
  var evening_shift = {};

  var dt = new Date();
  var startDate = getDateISOFormat(dt) + 'T';

  dt.setDate(dt.getDate() + 30);
  var endDate = getDateISOFormat(dt) + 'T';

  if (data.morning_shift_start !== undefined && data.morning_shift_start != null) {
    var a = data.morning_shift_start.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var morning_shift_start = (+a[0]) * 60 * 60 + (+a[1]) * 60;

    var b = data.morning_shift_end.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var morning_shift_end = (+b[0]) * 60 * 60 + (+b[1]) * 60;

    morning_shift.type = "Morning";
    morning_shift.from = startDate + new Date(morning_shift_start * 1000).toISOString().substr(11, 8);
    morning_shift.to = endDate + new Date(morning_shift_end * 1000).toISOString().substr(11, 8);
    result.push(morning_shift);
  } else if (data.morning_opens_at !== undefined && data.morning_opens_at != null) {
    var a = data.morning_opens_at.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var morning_opens_at = (+a[0]) * 60 * 60 + (+a[1]) * 60;

    var b = data.morning_close_at.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var morning_close_at = (+b[0]) * 60 * 60 + (+b[1]) * 60;

    morning_shift.type = "Morning";
    morning_shift.from = startDate + new Date(morning_opens_at * 1000).toISOString().substr(11, 8);
    morning_shift.to = endDate + new Date(morning_close_at * 1000).toISOString().substr(11, 8);
    result.push(morning_shift);
  }

  if (data.evening_shift_start !== undefined && data.evening_shift_start != null) {
    var a = data.evening_shift_start.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var evening_shift_start = (+a[0]) * 60 * 60 + (+a[1]) * 60;

    var b = data.evening_shift_end.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var evening_shift_end = (+b[0]) * 60 * 60 + (+b[1]) * 60;

    evening_shift.type = "Evening";
    evening_shift.from = startDate + new Date(evening_shift_start * 1000).toISOString().substr(11, 8);
    evening_shift.to = endDate + new Date(evening_shift_end * 1000).toISOString().substr(11, 8);
    result.push(evening_shift);
  } else if (data.evening_opens_at !== undefined && data.evening_opens_at != null) {
    var a = data.evening_opens_at.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var evening_opens_at = (+a[0]) * 60 * 60 + (+a[1]) * 60;

    var b = data.evening_close_at.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var evening_close_at = (+b[0]) * 60 * 60 + (+b[1]) * 60;

    evening_shift.type = "Evening";
    evening_shift.from = startDate + new Date(evening_opens_at * 1000).toISOString().substr(11, 8);
    evening_shift.to = endDate + new Date(evening_close_at * 1000).toISOString().substr(11, 8);
    result.push(evening_shift);
  }

  console.log(result);
  return result;
}

export function getProfilePercentage(data) {
  if (data === '100%') {
    return '0px';
  } if (data === '95%') {
    return '5px';
  } if (data === '90%') {
    return '10px';
  } if (data === '85%') {
    return '15px';
  }
  if (data === '80%') {
    return '20px';
  }
  if (data === '75%') {
    return '25px';
  } if (data === '70%') {
    return '30px';
  } if (data === '65%') {
    return '35px';
  } if (data === '60%') {
    return '40px';
  } if (data === '55%') {
    return '45px';
  } if (data === '50%') {
    return '50px';
  } if (data === '45%') {
    return '55px';
  } if (data === '40%') {
    return '60px';
  } if (data === '35%') {
    return '65px';
  }
  if (data === '30%') {
    return '70px';
  }
  if (data === '25%') {
    return '75px';
  }
  if (data === '20%') {
    return '80px';
  }
  if (data === '15%') {
    return '85px';
  }
  if (data === '10%') {
    return '90px';
  } if (data === '5%') {
    return '95px';
  } if (data === '0%') {
    return '110px';
  }
}

//input:  createShift object
//out: createShift object array list of working days is added
export function setDaysoftheWeek(createShift) {
  let s = [];

  if (createShift.from && createShift.to) {
    let d = createShift.from;
    while (d <= createShift.to && s.length <= 7) {
      if (s.indexOf(working_days[d.getDay()]) === -1) {
        s.push(working_days[d.getDay()]);
      }
      d = new Date(d.getTime() + (24 * 60 * 60 * 1000));
    }
  }
  createShift.working_days = s;
  console.log(createShift);
}

export function renameKey(obj, oldKey, newKey) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}