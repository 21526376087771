import { combineReducers } from "redux";

import { Errors } from "./ErrosReducer";
import { users } from "./UserReducer";
import { AppData } from "./AppDataReducer";
import AuthReducer from "./AuthReducer";
import SearchReducer from "./SearchReducer";
import ValidityReducer from "./ValidityReducer";

export const Reducers = combineReducers({
  Errors,
  users,
  AppData,
  AuthReducer,
  SearchReducer,
  ValidityReducer
});
