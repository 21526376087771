import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { Popup } from "../components/UI/Popup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getsupplies,
    getsuppliescategory,
    addsupplies,
    updatesupplies,
    deletesupplies,
    getTimeoutbycategory,
    suppliesimport
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Validation from "../actions/Validation";
import { withRouter } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import Label from "../components/UI/Label";
import MedicineSuppliesExport from "./MedicineSuppliesExport";
import MedicineSuppliesTemplate from "./MedicineSuppliesTemplate";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../components/UI/Popupinfo";
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
      logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
  }
  

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}


class MedicineSupplies extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout=this.state;
    
        this.state = {
            adminstatus: this.props.userdata.adminstatus,
            timeout:hospitaltimeout,
            isTimedOut: false,
            showModal: false,
            loading: false,
            suppliescategorylist: [],
            records: [],
            addPopup: false,
            actions: "add",

            suppliesData: {
                id: "",
                supplies_id: "",
                supplies_name: "",
                supplies_category: "",
                tax_percentage: "",
                amount: ""

            },

            errors: {
                id: "",
                supplies_id: "",
                supplies_name: "",
                supplies_category: "",
                tax_percentage: "",
                amount: ""
            },
            tableSuccess: {
                message: "",
                status: "",
            },
            formSuccess: {
                message: "",
                status: "",
            },
        };
        this.columns = [
            {
                key: "s_no",
                text: "S.No",
                className: "s_no",
                align: "left",
                sortable: true,
            },
            {
                key: "id",
                text: "ID",
                className: "hidden-column",
                TrOnlyClassName: "hidden-column",

                align: "left",
                sortable: true,
            },
            {
                key: "supplies_id",
                text: "Supplies ID",
                className: "supplies_id",
                align: "left",
                sortable: true,
            },
            {
                key: "supplies_name",
                text: "Supplies Name",
                className: "supplies_name",
                align: "left",
                sortable: true,
            },

            {
                key: "tax_percentage",
                text: "Tax Percentage(%)",
                className: "tax_percentage",
                sortable: true,
                align: "left",
            },
            {
                key: "supplies_category_label",
                text: "Supplies Category",
                className: "supplies_category_label",
                sortable: true,
                align: "left",
            },

            {
                key: "amount",
                text: "Amount",
                className: "amount",
                sortable: true,
                align: "left",
            },

            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: true,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.onDeleteClick(record)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    
    }
    handleClose() {
        this.setState({showModal: false})
      }
    
     
       handleLogout = (e) => {
        this.setState({showModal: false})
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
       
    };
    
      _onAction(e) {
        console.log('user did something', e)
        
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        
        console.log('user is active', e)
        this.setState({isTimedOut: false})
      }
       
      _onIdle = (e) =>  {
        console.log('user is idle', e)
        let isTimedOut  = this.state.isTimedOut
        let timeout=this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
  if(isTimedOut===true)
  {
    localStorage.removeItem("token");
    this.props.logout();
    this.props.history.push("/");
  }
      }
    
    handleselectChange = (selectedOptions, e) => {
        let data = this.state.suppliesData;
        let errors=this.state.errors;
        data[e.name] = selectedOptions;
        data.tax_percentage = selectedOptions.tax_percentage
        // console.log(selectedOptions)
        errors.supplies_category="";
        this.setState({ suppliesData: data ,errors});
    };
    UpdateColumnChange = (data) => {

        axios.put(updatesupplies, data, CONFIG).then((list) => {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].supplies_id = data.supplies_id;
            records[recIndex].supplies_name = data.supplies_name;
            records[recIndex].supplies_category = data.supplies_category;
            records[recIndex].tax_percentage = data.tax_percentage;
            records[recIndex].amount = data.amount;
            records[recIndex].id = data.id;

            this.setState({ records, tableSuccess: list.data });
            this.clearMessage();
        });
    };
    updateSupplies = (e) => {
        e.preventDefault();
        let { suppliesData, errors } = this.state;
        let data = {};
        data.type = "medicinesupplies";
        data.supplies_id = suppliesData.supplies_id;
        data.supplies_name = suppliesData.supplies_name;
        data.tax_percentage = suppliesData.tax_percentage;
        data.supplies_category = suppliesData.supplies_category ? suppliesData.supplies_category.value : '';
        data.amount = suppliesData.amount;
        data.business_id = this.props.userdata.id;
        console.log(data);
       // this.setState({ loading: true });
        errors = Validation(data, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
            // console.log(data); return false;

            let url = this.state.actions === "add" ? addsupplies : updatesupplies;
            if (this.state.actions === "add") {
                axios.post(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });
                        toast.error("Medicine Supplies could not be Added. ",{
                            className:"toast-error"
                          });
                        this.setState({ errors: { supplies_id: list.data.message } });
                    } else {
                        let addsupplies = {};
                        addsupplies.supplies_id = data.supplies_id;
                        addsupplies.supplies_name = data.supplies_name;
                        addsupplies.supplies_category = data.supplies_category;
                        addsupplies.supplies_category_label = suppliesData.supplies_category.label;
                        addsupplies.tax_percentage = data.tax_percentage
                        addsupplies.amount = data.amount;


                        addsupplies.id = list.data.data;

                        let records = this.state.records;
                        addsupplies.s_no = records.length + 1;
                        records.push(addsupplies);
                        this.setState({ records });
                       // this.setState({  formSuccess: list.data });
                        toast.success("The Medicine Supplies have been added successfully.",{
                            className:"toast-success"
                          });
                        
                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            } else {
                data.id = suppliesData.id;
                console.log(data)
                axios.put(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { supplies_id: list.data.message } });
                        toast.error("Medicine Supplies could not be Updated. ",{
                            className:"toast-error"
                          });
                    } else {
                        let records = this.state.records;

                        var recIndex = findWithAttr(records, "id", data.id);
                        console.log(recIndex);
                        console.log(records);
                        records[recIndex].supplies_id = data.supplies_id;
                        records[recIndex].supplies_name = data.supplies_name;
                        records[recIndex].tax_percentage = data.tax_percentage;
                        records[recIndex].supplies_category_label = suppliesData.supplies_category.label;
                        records[recIndex].supplies_category = data.supplies_category;
                        records[recIndex].amount = data.amount;

                        records[recIndex].id = data.id;

                        this.setState({ records});
                       // this.setState({ formSuccess: list.data})
                        toast.success("The Medicine Supply details have been updated successfully.",{
                            className:"toast-success"
                          });
                        
                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            }
        }
    };

    clearErrors = (errors) => {
        errors.supplies_id = "";
        errors.supplies_name = "";
        errors.supplies_category="";
        errors.tax_percentage = "";
        errors.amount = "";
        return errors;
    };

    cancel = () => {
        let data = this.state.suppliesData;
        data.supplies_id = "";
        data.supplies_name = "";
        data.id = "";
        data.tax_percentage = "";
        data.amount = "";
        data.supplies_category = "";

        let errors = this.state.errors;

        errors = this.clearErrors(errors);

        this.setState({ suppliesData: data, actions: "add", errors: errors });
    };
   
    clearimport=()=>{
        this.setState({ file:"" });
      };
   
      clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

    editRecord(record) {
        console.log(record)
        let suppliesData = {};
        suppliesData.supplies_id = record.supplies_id;
        suppliesData.supplies_name = record.supplies_name;
        suppliesData.tax_percentage = record.tax_percentage;
        suppliesData.supplies_category = { label: record.supplies_category_label, value: record.supplies_category };
        suppliesData.amount = record.amount;
        suppliesData.id = record.id;


        console.log(suppliesData)
        let errors = this.state.errors;
        errors = this.clearErrors(errors);

        this.setState({
            suppliesData: suppliesData,
            actions: "edit",
            errors: errors,
        });
    }
    componentDidMount = () => {
        console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
    
         axios
          .post(getTimeoutbycategory, {category : this.props.userdata.user_type}, CONFIG)
          .then((res) => {
            let data = res.data;
            console.log(res);
            console.log(data);
            console.log(data.data.timeout);
            if (data.status === "1")
              this.setState({
                hospitaltimeout: data.data.timeout
                
              });
            })
          .catch((e) => { });
    
        axios
            .post(getsupplies, { business_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
                let supplieslist = list.data.data;

                supplieslist.forEach((element, index) => {
                    let supplies = {};
                    supplies.s_no = index + 1;
                    supplies.supplies_id = element.supplies_id;
                    supplies.supplies_name = element.supplies_name;
                    supplies.tax_percentage = element.tax_percentage
                    supplies.supplies_category = element.supplies_category;
                    supplies.supplies_category_label = element.supplies_category_label;
                    supplies.amount = element.amount;
                    supplies.id = element.id;

                    supplieslist[index] = supplies;
                });

                // let clientmedicinehistory = {};
                console.log(supplieslist)
                this.setState({ records: supplieslist });
            })
            .catch((e) => { });

        axios
            .post(getsuppliescategory, null, CONFIG)
            .then((list) => {
                let suppliescategorylist = list.data.data;

                suppliescategorylist.forEach((element, index) => {
                    let suppliescategory = {};
                    suppliescategory.category_name = element.category_name;
                    suppliescategory.label = element.category_name;
                    suppliescategory.value = element.id;
                    suppliescategory.tax_percentage = element.tax_percentage;
                    suppliescategory.id = element.id;

                    suppliescategorylist[index] = suppliescategory;
                });

                // let clientmedicinehistory = {};
                console.log(suppliescategorylist)
                this.setState({ suppliescategorylist: suppliescategorylist });
            })
            .catch((e) => { });

    };
    onDeleteClick(record) {
        this.setState({ addPopup: true, record: record });
      

    }
    deleteRecord(record) {
        let userId = record.id;
        this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .post(deletesupplies, { id: userId }, CONFIG)
            .then((res) => {
                let response = res.data;
                if (response.status === "1") {
                    let records = this.state.records;
                    const deleteIndex = records.map((item) => item.id).indexOf(record.id);
                    records.splice(deleteIndex, 1);
                    this.setState({ records });
                }
                this.setState({ addPopup: false, record: "", loading: false });
                toast.error("The Medicine Supply detail has been deleted successfully.",{
                    className:"toast-error"
                  });
               // this.setState({ tableSuccess: res.data });
                this.clearMessage();
                this.cancel();
            })
            .catch((e) => { });
            axios
            .post(getsupplies, { business_id: this.props.userdata.id }, CONFIG)
            .then((list) => {
                let supplieslist = list.data.data;
    
                supplieslist.forEach((element, index) => {
                    let supplies = {};
                    supplies.s_no = index + 1;
                    supplies.supplies_id = element.supplies_id;
                    supplies.supplies_name = element.supplies_name;
                    supplies.tax_percentage = element.tax_percentage
                    supplies.supplies_category = element.supplies_category;
                    supplies.supplies_category_label = element.supplies_category_label;
                    supplies.amount = element.amount;
                    supplies.id = element.id;
    
                    supplieslist[index] = supplies;
                });
    
                // let clientmedicinehistory = {};
                console.log(supplieslist)
                this.setState({ records: supplieslist });
            })
            .catch((e) => { });
    }
    validateMaxLength(name, value, max) {
        let data = this.state.suppliesData;
        let errors = this.state.errors;
    
        if (value.length > max) {
          const value1 = value.slice(0, max);
          errors[name] = "Maximum Length has exceeded.";
          data[name] = value1;
        } else {
          errors[name] = "";
          data[name] = value;
        }
        this.setState({ suppliesData: data, errors: errors });
      }
    handleInput = (e) => {
        let data = this.state.suppliesData;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;
        const re = /[^0-9]/;
    if (
      name === "supplies_id" ||
      name === "supplies_name" ||
      name === "amount"
         
    )
     {
      this.validateMaxLength(name, value, max);
    } else {
        data[name] = value;
        
     
    }
    if (name === "amount")
    {
       if (value === "" || re.test(value))
        {
         //  data[name] = value;
           data[e.target.name] = e.target.value.replace(/[^0-9]/ig, '');
       }
   }
    
   
        // } else {
        //     data[name] = value;
        // }

        this.setState({ suppliesData: data });
    };
    handleExpiresatChange = (date) => {
        let suppliesData = this.state.suppliesData;
        suppliesData.expires_at = date;
        this.setState({ suppliesData: suppliesData });
    };
    onFileChange = event => {

        // Update the state
        this.setState({ file: event.target.files[0] });
        readXlsxFile(event.target.files[0]).then((rows) => {
            console.log(rows);
            console.log("rows");
            this.setState({ suppliesData: rows });
            // `rows` is an array of rows
            // each row being an array of cells.
        })
    
    };
    onFileUpload = () => {
    
       if (this.state.suppliesData.length) {
          axios.post(suppliesimport, {data: this.state.suppliesData,business_id:this.props.userdata.id}).then((result) => {
              console.log(result);
              this.setState({ formSuccess: result.data, file: "", suppliesData: [] })
              axios
              .post(getsupplies, { business_id: this.props.userdata.id }, CONFIG)
              .then((list) => {
                  let supplieslist = list.data.data;
  
                  supplieslist.forEach((element, index) => {
                      let supplies = {};
                      supplies.s_no = index + 1;
                      supplies.supplies_id = element.supplies_id;
                      supplies.supplies_name = element.supplies_name;
                      supplies.tax_percentage = element.tax_percentage
                      supplies.supplies_category = element.supplies_category;
                      supplies.supplies_category_label = element.supplies_category_label;
                      supplies.amount = element.amount;
                      supplies.id = element.id;
  
                      supplieslist[index] = supplies;
                  });
                  this.setState({ records: supplieslist });
              })
              .catch(() => { });
              this.clearMessage();
          });
      }
    
    };
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        let PopupHospitalClose = () => {
            this.setState({ hospitalpopup:true})
           
          };
        const {
            suppliesData,
            loading,
            errors,
            formSuccess,
            tableSuccess,
            hospitaltimeout
        } = this.state;
        return (


            <div className="col-sm-12 checkout-tabs">
                <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={hospitaltimeout} />

                <h4 className="font-weight-bold"> Medicine Supplies List </h4>
                {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

                <div className="row mt-4">

                    <div className="col-sm-12 ">
                        <div className="card">
                            <div className="card-body ">
                                <div className="tab-content" id="v-pills-tabContent">
                                <div className="float-right btn "> <MedicineSuppliesExport business_id={this.props.userdata.id} records={this.state.records} /></div>
                             
                                    <div
                                        className="tab-pane fade show active"
                                        id="v-pills-shipping"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-shipping-tab"
                                    >
                                        <div className="user-notification">
                                            <SpanField
                                                classname={
                                                    "0" === tableSuccess.status
                                                        ? "text-danger user-notification"
                                                        : "text-success user-notification"
                                                }
                                                title={tableSuccess.message}
                                            />
                                            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                        </div>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            className="table table-bordered table-striped kas  mobile_table"
                                        />
                                        <Popup
                                            show={this.state.addPopup}
                                            onHide={PopupClose}
                                            onConfirm={PopupConfirm}
                                            title={"Confirmation"}
                                            message={"Are you sure you want to delete this record ?"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                                            <div className="card">
                                            <div class="card-header"> <div class="card-title">Import Medicine Supplies</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                      
                                                        <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    "0" === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div>
                                                        <div className="form-group d-flex justify-content-center mg-btm5">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                        <div className="float-right btn btn2 "> <MedicineSuppliesTemplate /></div>

                                                        <div className="row">
                                                      
                                                            <div className="col-sm-4" >
                                                                <div className="custom-file text-left">
                                                                  
                                                                    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" type="file" onChange={this.onFileChange} name="file" className="custom-file-input" id="file" />
                                                                    <label className="custom-file-label" htmlFor="file">{this.state.file ? this.state.file.name : ""}</label>
                                                                    {/* <div className="form-group  mt-2 ">
                                                                    <button
                                                                        ref="btn"
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.onFileUpload}>

                                                                        {loading ? (
                                                                            <LoadingSpinner title="Update" />
                                                                        ) : this.state.actions === "Update" ? (
                                                                            "Upload"
                                                                        ) : (
                                                                            "Upload"
                                                                        )}
                                                                    </button>
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.clearimport}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div> */}
                                                                </div>
                                                             
                                                            </div>

                                                       
                                                           
                                                        </div>
                                                    </div>
                                                  {/*   <div className="form-group"><label className="control-label">{this.state.file ? this.state.file.name : ""}</label></div> */}
                                                </div>
                                            </div>
                                        </div> 
                    <div className="col-sm-12 pr0">
                        <div className="card b-12-hite">
                              <div class="card-header"> <div class="card-title">{this.state.actions === "add" ? "Add" : "Edit"} Medicine Supplies</div> </div>
                            <div className="card-body">
                             {/*    <div className="user-notification">
                                    <SpanField
                                        classname={
                                            0 === formSuccess.status
                                                ? "text-danger user-notification"
                                                : "text-success user-notification"
                                        }
                                        title={formSuccess.message}
                                    />
                                </div>
 */}
                                <div>
                                  

                                    <div className="row">
                                        
                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Supplies ID" isMandatory={true} />
                                                   
                                                </div>
                                                <div className="form-group frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"supplies_id"}
                                                                controlFunc={this.handleInput}
                                                                content={suppliesData.supplies_id}
                                                                placeholder={""}
                                                                max={50}
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.supplies_id}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Supplies Name" isMandatory={true} />
                                                   
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"supplies_name"}
                                                                controlFunc={this.handleInput}
                                                                content={suppliesData.supplies_name}
                                                                placeholder={""}
                                                                max={50}
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.supplies_name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Supplies Category" isMandatory={true} />
                                                    
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative sform-control">
                                                        <div className="input-group">
                                                            {/* <InputField
                                                                inputType={"text"}
                                                                name={"supplies_category"}
                                                                controlFunc={this.handleInput}
                                                                content={suppliesData.supplies_category}
                                                                placeholder={""}
                                                            /> */}
                                                            <Select maxMenuHeight={100}
                                                                className="form-control p-0"
                                                                name="supplies_category"
                                                                value={suppliesData.supplies_category}
                                                                onChange={this.handleselectChange}
                                                                options={this.state.suppliescategorylist}
                                                            />


                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.supplies_category}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group frllft mbbre">
                                                <Label title="Tax Percentage(%)" />
                                                    <label className="ffgrd"> </label>
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            {suppliesData.tax_percentage ? suppliesData.tax_percentage : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="frllftfull">
                                                <div className="form-group frllft mbbre">
                                                <Label title="Price" isMandatory={true} />
                                                   
                                                </div>
                                                <div className="form-group  frlrit">
                                                    <div className="position-relative">
                                                        <div className="input-group">
                                                            <InputField
                                                                inputType={"text"}
                                                                name={"amount"}
                                                                controlFunc={this.handleInput}
                                                                content={suppliesData.amount}
                                                                placeholder={""}
                                                                max={15}
                                                            />
                                                        </div>
                                                        <SpanField
                                                            classname="text-danger"
                                                            title={errors.amount}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row ">
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-6">
                                            <div className="form-group mb-0 btn-align-right mg-top3">
                                                <button
                                                    href="patients-register"
                                                    className="signupbtn abtn btdpml btn-margin"
                                                    onClick={this.updateSupplies}
                                                >
                                                    {this.state.actions === "add" ? "+ Add" : "Update"}
                                                </button>

                                                <button
                                                    href="patients-register"
                                                    className="signupbtn abtn btdpml"
                                                    onClick={this.cancel}
                                                >
                                                    {"Cancel"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           )}
            </div>




        );
    }
}

export default withRouter(connect(
    mapStateToProps,mapDispatchToProps
)(MedicineSupplies));
