import React from "react";


function LogoSection(props) {
  return (
    <div className="col-md-6 text-left">
      <div className="logocenter">
        <a href="#">
          <img alt="LOGO" src={require("../../assets/images/logo.jpg")} />
        </a>
      </div>
    </div>
  );
}

export default LogoSection;
