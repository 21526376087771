import React from 'react';
import PropTypes from 'prop-types';
import StarField from '../UI/StarField';

const Label = (props) => (
    <label className={props.classname}>{props.title}{props.isMandatory ? <StarField /> : ''}</label>


);

Label.propTypes = {
    title: PropTypes.string.isRequired,
    isMandatory: PropTypes.bool


};

export default Label;