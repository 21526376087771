import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { getAllBusinessList,GetAppointmentReport } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import moment from "moment";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import Select from "react-select";
import { Spinner } from "../components/UI/Spinner";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import Validation from "../actions/Validation";
import Dnaspinner from '../components/UI/Dnaspinner';

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}
const current_date = new Date();

class AppointmentReport extends Component {
    constructor(props) {
        super(props);
        // this.monthPickerRef = React.useRef(null);
        this.state = {
            month: current_date.getFullYear()+"-"+current_date.getMonth()+1,
            // year: current_date.getFullYear(),
            tableSuccess: {
                message: "",
                status: "",
            },
            scheduled: null ,
            records: [],
            hospital_name:"",
            city:"",
            actions: "update",
            loading: false,
            showDeleteConfirmPopup: false,
            confirmedDelete: false,
      
             deletePopup: false,
             hospitalreport:
             {
         //      id:"",
          //    user_id: "",
              business_name:"",
              Fromdate:"",
              Todate:"",
             },
             errors: {
                business_name:"",
                Fromdate:"",
                Todate:"",
            },
            formSuccess: {
                message: "",
                status: "",
              },
        };

     
        this.columns = [
           { text: "S.No",
            className: "s_no",
            key:"s_no",
            align: "left",
            sortable: true,
           
        },
            // {
            //     key: "business_name",
            //     text: "Business Name",
            //     className: "business_name",
            //     align: "left",
            //     sortable: true,
            // },
            {
                key: "doctor_name",
                text: "Doctor Name",
                className: "doctor_name",
                align: "left",
                sortable: true,
            },
            {
                key: "speciality",
                text: "Speciality",
                className: "speciality",
                align: "left",
                sortable: true,
            },
            {
                key: "checkout",
                text: "Total no of Check-Out",
                className: "checkout",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return record.checkout > 0 ? record.checkout : 0
                },
            },
            {
                key: "queue_in",
                text: "Total no of Queue-in",
                className: "queue_in",
                align: "left",
                sortable: true,
            },
            {
                key: "checkin",
                text: "Total no of Walkin",
                className: "checkin",
                align: "left",
                sortable: true,
            },
            {
                key: "previouscheckout",
                text: "Last month Total Checkout",
                className: "previouscheckout",
                align: "left",
                sortable: true,
                
            },
            {
              key: "beforepreviouscheckout",
              text: "Last month Before Total Checkout",
              className: "beforepreviouscheckout",
              align: "left",
              sortable: true,
              
          },
            
        /*     {
                key: "marital_status",
                text: "Marital Status",
                className: "marital_status",
                sortable: true,
                align: "left",
            }, */
           
       
      /*   {
          key: "created_time",
          text: "Created Date",
          className: "created_time",
          sortable: true,
          align: "left",
          cell: (record) => {

          var date=moment(record.created_time).format("DD-MMM-yyyy");
            //console.log(date);
           if(date ==="Invalid date")
           {
            return "";

           }else
           {
            return moment(record.created_time).format("DD-MMM-yyyy");
           }

          },
      },
 */
           
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };

    handleChange (m) {
      this.setState({scheduled: m}, () => console.log(this.state.scheduled))
    }

    UpdateColumnChange = (data) => {
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // let request = {
        //     business_user_id: data.user_id, status: data.status
        // }
        // axios.post(updateDoctorStatus1, request).then((list) => {
        let records = this.state.records;

        //     // data = list.data.data;
        //     if (list.data.status === "1") {
        var recIndex = findWithAttr(records, "user_id", data.user_id);

        records[recIndex].status = data.status;

        this.setState({ records, tableSuccess: "" });
        // }

        this.clearMessage();
        // });
    };
    handleselectChange = (selectedOptions, e) => {
      let data = this.state.hospitalreport;
      data[e.name] = selectedOptions;
  
      this.setState({ hospitalreport: data });
    };
    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

    handleFromDOBChange = (date) => {
      let Hospitalreport = this.state.hospitalreport;
      Hospitalreport.Fromdate = date;
      this.setState({ hospitalreport: Hospitalreport });
    };
    handleToDOBChange = (date) => {
        let Hospitalreport = this.state.hospitalreport;
        Hospitalreport.Todate = date;
        this.setState({ hospitalreport: Hospitalreport });
      };
   
    componentDidMount = () => {

      const monthControl = document.querySelector('input[type="month"]');
      const date= new Date()
      const month=("0" + (date.getMonth() + 1)).slice(-2)
      const year=date.getFullYear()
      monthControl.value = `${year}-${month}`;
      this.setState({month:`${year}-${month}`});

        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .get(getAllBusinessList, null, CONFIG)
            .then((list) => {
              console.log(list)
              let business = [];
              let userlist = list.data.data;
              userlist.forEach((element, index) => {
                business.push({ label: element.business_name, value: element.user_id })
              });
      
              this.setState({ business_list: business });
            })
            .catch((e) => { console.log(e) });
        
               
    }
    cancel = () => {
        let data = this.state.hospitalreport;
        data.id="";
        data.business_name = "";
        data.Fromdate="";
        data.Todate="";
        this.setState({ hospitalreport: data, actions: "update" });
        this.setState({  reportList: [] });
      };
      Printpage=()=>
      {   
        window.print();
      }

    editRecord = (record) => {
        console.log(record);
        this.setState({ actions: "edit" });
    
        let hospitaldata = this.state.hospitaldata;
        hospitaldata.id=record.id;
        hospitaldata.user_id = record.user_id;
        hospitaldata.business_name=record.business_name;
        hospitaldata.id = record.id;
        hospitaldata.user_id = record.user_id;
        hospitaldata.email=record.email;
        hospitaldata.phone_number = record.phone_number;
        hospitaldata.subscription={
        label: record.subscription,
        value: record.subscription,
      };
        hospitaldata.payment_status = {
          label: record.payment_status,
          value: record.payment_status,
        };
        
        if(hospitaldata.expires_at===null|| hospitaldata.expires_at==="")
        {
          hospitaldata.expires_at =record.expires_at;
        }else
        {
          hospitaldata.expires_at=moment(record.expires_at,moment.defaultFormat).toDate();
        }
                
        this.handleHospitalChange("user_id", hospitaldata.user_id);
        this.setState({ hospitaldata: hospitaldata });
      };
    
      clearAll() {
        let actions = this.state.actions;
        actions = "update";
        this.setState({ actions });
        let hospitaldata = {};
        hospitaldata.id="";
        hospitaldata.business_name="";
        hospitaldata.email = "";
        hospitaldata.phone_number = "";
        hospitaldata.subscription="";
        hospitaldata.payment_status="";
        hospitaldata.expires_at="";
        this.setState({ hospitaldata });
      }

    handleHospitalChange(value, field) {
        let fields = this.state.hospitaldata;
        // let errors = this.state.educationerror;
        console.log(field);
         if (value === "email") {
          fields.email = field.target.value;
          // errors[i].register_id = '';
        }else if (value === "id") {
          fields.id = field;
          // errors[i].education = '';
        } 
        else if (value === "business_name") {
          fields.business_name = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "phone_number") {
          fields.phone_number = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "subscription") {
          fields.subscription = field.target.value;
          // errors[i].education = '';
        }  
        else if (value === "expires_at") {
          fields.expires_at = field.target.value;
          // errors[i].education = '';
        }else if (value === "payment_status") {
          fields.payment_status = field.target.value;
          // errors[i].education = '';
        }                
        this.setState({
          hospitaldata: fields,
        });
      }
    getHospitalReport=(e)=> {
     // let user_id = record.user_id;
        let { hospitalreport,month, errors } = this.state;
      //  hospitaldata.type = "hospitallist";
        errors = Validation(hospitalreport, errors);
        this.setState({ errors: errors,loading: true});
        if (errors.formIsValid) {
          let data = this.state.hospitalreport;
    
      //  let data = {};
        
        data.business_id=hospitalreport.business_name.value;
        data.month = month;
        console.log(data);
      
        axios.post(GetAppointmentReport, data, CONFIG).then((list) => {
         console.log(data);
         
          let response=list.data;
          console.log(response);
          if (response.status === "1") {   
              console.log(list.data.data);
            // this.setState({ records: list.data.data });
            let records=list.data.data;
            let reportList = [];
            records.forEach((element, index) => {
                let report = {};
                report.s_no = index+1;
                
                report.doctor_name=element.first_name+" "+element.last_name;
               report.speciality = element.Specialities;
               report.id = element.id;

                report.checkout=Number(element.checkout)+Number(element.completed);
                report.queue_in=element.queue_in;
                report.checkin=element.checkin;
                report.completed=element.completed;
                report.consulting=element.consulting;
                report.previouscheckout=element.previouscheckout;
                report.beforepreviouscheckout=element.beforepreviouscheckout;
                // console.log(typeof(Number(element.checkout)));

                reportList[index] = report;
              });
              console.log(reportList);
              this.setState({ reportList: reportList,hospital_name:response.hospital_name,city:response.city });
          this.setState({ formSuccess: list.data });
          this.clearAll();
          this.clearMessage();
         
          }
          this.setState({loading:false})
        });
      }
      }
      
      onMonthChange = (e) =>{
        console.log(e.target.value);
        e.preventDefault();
        this.setState({month:e.target.value});

      }
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const value = { year: 2020, month: 9 };
        
        const lang = {
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          from: "From",
          to: "To"
        };
        const {
            business_list,
            hospitalreport,
            formSuccess,
            tableSuccess,
            errors,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>
                         {/*    <Spinner loading={this.state.loading} /> */}
                         {loading && <Dnaspinner />}

                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Appointment Report </h4>
                                    <div className="row mt-4">
                                    <div className="col-sm-12">
                            <div className="card">
                              <div className="card-body">
                                <div>
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                  <div className="col-lg-4">
                                  <div>
                                        <label>Business Name</label>
                                      </div>
                                      <div className="form-group">
                                      <Select maxMenuHeight={120}
                                                  className="w-100 p-0"
                                                  name="business_name"
                                                  value={hospitalreport.business_name}
                                                  onChange={
                                                    this.handleselectChange
                                                  }
                                                  options={business_list}
                                                  isClearable={true}
                                                />
                                    <div><span>&nbsp;</span></div>
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.business_name}
                                      /> 
                                     
                                    </div>

                                    <div className="col-lg-4">
                                    
                                      </div>
                                     

                                    </div>
                                    <div className="col-lg-3">
                                    <div>
                                          <label>Select Month</label>
                                        </div>
                                        <div className="form-group">
                                        <input className='form-control' type="month" id="start" value={this.state.month} name="start" onChange={this.onMonthChange}
       min="2018-03" />
                                              </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.Fromdate}
                                        />
                                      </div>
                                      
                                    <div className="col-lg-4 m-t-30">
                                    <button
                                          ref="btn"
                                          href="patients-register"
                                          className="signupbtn abtn btdpml btn-margin"
                                          onClick={this.getHospitalReport}>
                                                                                 
                                          {loading ? (
                                            <LoadingSpinner title="Get Report" />
                                          ) : "Get Report"}
                                        </button>
                                     <button
                                          href="patients-register"
                                          className="signupbtn abtn btdpml btn-margin"
                                          onClick={this.cancel}
                                        >
                                          Cancel
                                        </button>
                                   
                                      </div>
                                     
                                        

                                    </div>

                                   

                                   
                                     

                                    </div>

                                   

                                    
                                   
                                 
                              </div>
                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <h1 className='text-center'>{this.state.hospital_name}</h1>
                                                            <h4 className='text-center'>{this.state.city}</h4>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.reportList}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                            show={this.state.deletePopup}
                                                             onHide={() =>
                                                             this.setState({
                                                              deletePopup: false,
                                                               })
                                                               }
                                                             onConfirm={() =>
                                                             this.deleteRecord(this.state.record)
                                                             }
                                                            title={"Confirmation"}
                                                              message={
                                                               "Are you sure you want to delete this record?"
                                                               }
                                                             />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       

                                    </div>
                                </div>
                             
                            </section>
                            <FooterSection />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    mapStateToProps,
)(AppointmentReport);