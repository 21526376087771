import React, { Fragment } from "react";
import PropTypes from "prop-types";

const CheckboxOrRadioGroup = (props) => (
  <div className="form-groups">
    {
      props.options.map((option) => {
        return (
          <Fragment key={option}>
            {props.type === "checkbox" ? (
              <div
                className="custom-control custom-checkbox mb-2 mylft "
                data-toggle="tooltip"
                title=""
              >
                <input
                  className="custom-control-input"
                  name={props.name}
                  onChange={props.controlFunc}
                  value={option}
                  checked={
                    props.selectedOptions
                      ? props.selectedOptions.indexOf(option) > -1
                      : 0
                  }
                  name={props.name ? props.name : ""}
                  type={props.type}
                  id={option}
                  disabled={props.disabled}
                />
                <label className="custom-control-label" htmlFor={option}>
                  {option}
                </label>
              </div>
            ) : (
              // < div key={option} className="custom-control custom-checkbox mb-2 mylft mr-30" >
              //     <input
              //         className="form-checkbox"
              //         name={props.name}
              //         onChange={props.controlFunc}
              //         value={option}
              //         checked={props.selectedOptions.indexOf(option) > -1}
              //         type={props.type} id={option} />
              //     <label className="custom-control-label" htmlFor={option}>{option}</label>
              // </div>
              <div
                key={option}
                className="custom-control-1 custom-radio-1 mb-2 mylft "
              >
                <input
                  className="form-radio1 "
                  name={props.name}
                  onChange={props.controlFunc}
                  value={option}
                  checked={props.selectedOptions ? props.selectedOptions.indexOf(option) > -1 : ""}
                  type={props.type}
                  id={option}
                  disabled={props.disabled}
                />
                <label className="custom-control-label-1" htmlFor={option}>
                  {option}
                </label>
              </div>
            )}
          </Fragment>
        );
      })
    }
  </div >
);

CheckboxOrRadioGroup.propTypes = {
  // title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  setName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  controlFunc: PropTypes.func.isRequired,
};

export default CheckboxOrRadioGroup;
