import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllSymptom,
  getAllSymptomCategory,
  addSymptom,
  updateSymptom,
  deleteSymptom,
  searchSymptom,
  getMasterSymptoms,
  getTimeoutbycategory
} from "../config/url.json";
import Label from "../components/UI/Label";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Validation from "../actions/Validation";
import CreatableSelect from "react-select/creatable";
import Spinner from "../components/UI/Spinner";
import Popup from "../components/UI/Popup";
import Footerad from "../components/UI/Footerad";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import ExpirePopup from "../components/UI/ExpirePopup"
import SymptomsExport from "./SymptomsExport";
import { Popupinfo } from "../components/UI/Popupinfo";
import LoadingHeartrate from "../components/UI/Heartrate";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity

  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}


class Symptoms extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      showexpirePopup: this.props.validity,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      records: [],
      searchrecords: [],
      loading: false,
      deletePopup: false,
      record: null,
      actions: "add",
      symptomCategory: [],
      editRecord: null,
      symptomData: {
        symptom_id: "",
        searchsymptom_id:"",
        symptom_name: "",
        symptom_category: "",
        type: "symptom",
      },
      masterSymptoms: [],
      masterSymptomCategory: [],
      errors: {
        symptom_id: "",
        symptom_name: "",
        symptom_category: "",
        symptomid_error:"",
      },
      searchData: {
        symptom_name: "",
        symptom_category: "",
      },
      successMessage: "",
      errorMessage: "",
      search_action: false,
      searchTableSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
    
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      {
        key: "symptom_id",
        text: "Symptom ID",
        className: "Symptom_id",
        align: "left",
        sortable: true,
      },
      {
        key: "symptom_name",
        text: "Symptom Name",
        className: "symptom_name",
        align: "left",
        sortable: true,
      },

      {
        key: "symptom_category",
        text: "Symptom Category",
        className: "symptom_category",
        sortable: true,
        align: "left",
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() =>
                  this.setState({ deletePopup: true, record: record })
                }
              >
                <i className="fa fa-trash" />
              </button>
            </Fragment>
          );
        
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.searchcolumns = [
      {
        key: "symptom_id",
        text: "Symptom ID",
        className: "symptom_id",
        sortable: true,
        align: "left",
        width: 10,
        cell: (record) => {
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"searchsymptom_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.searchsymptom_id}
                placeholder={""}
                max={5}
              />
              <SpanField
                classname="text-danger"
                title={record.symptomid_error}
              />
            </Fragment>
          );
        },
      },
      {
        key: "symptom_name",
        text: "Symptom Name",
        className: "symptom_name",
        sortable: true,
        align: "left",
        width: 100,

        // cell: (record) => {
        //   return (
        //     <Fragment>
        //       <InputField
        //         inputType={"text"}
        //         isMandatory={true}
        //         name={"symptom_name"}
        //         controlFunc={this.handleInput.bind(this)}
        //         content={record.symptom_name}
        //         placeholder={""}
        //       />
        //       <SpanField
        //         classname="text-danger"
        //         title={record.symptomname_error}
        //       />
        //     </Fragment>
        //   );
        // },

      },
      {
        key: "symptom_category_hide",
        text: "symptom_category_hide",
        className: "symptom_category_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "symptom_category",
        text: "Symptom Category",
        className: "symptom_category",
        sortable: true,
        align: "left",
        width: 100,

        // cell: (record) => {
        //   return (
        //     <Fragment>
        /* <CreatableSelect
          className="form-control p-0"
          name="symptom_category"
          isMandatory={true}
          value={record.symptom_category}
          onChange={(value) =>
            this.handleCategoryColumnChange(
              record,
              value,
              "symptom_category"
            )
          }
          options={this.state.symptomCategory}
        /> */
        //        <InputField
        //          inputType={"text"}
        //           isMandatory={true}
        //            name={"symptom_category"}
        //            controlFunc={this.handleInput.bind(this)}
        //            content={record.symptom_category.value}
        //             placeholder={""}
        //             max={50}
        //             />
        //       <SpanField
        //         classname="text-danger"
        //         title={record.symptomcategory_error}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();

  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }

  componentDidMount = () => {

    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getMasterSymptoms, null, CONFIG)
      .then((masterlist) => {

        let masterlists = masterlist.data.data;
        let masterSymptoms = [], masterSymptomCategory = [];
        masterlists.forEach((element, index) => {
          masterSymptoms.push({ label: element.symptom_name, value: element.symptom_name })
          masterSymptomCategory.push({ label: element.symptom_category, value: element.symptom_category })
        });

        var masterSymptomCategory1 = masterSymptomCategory.reduce((unique, o) => {
          if (!unique.some(obj => obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);
        console.log(masterSymptoms);
        console.log(masterSymptomCategory);
        this.setState({ masterSymptoms: masterSymptoms, masterSymptomCategory: masterSymptomCategory1 });
      })
      .catch((e) => { });



    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllSymptom, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let symptomlist = list.data.data;

        symptomlist.forEach((element, index) => {
          let symptom = {};
          symptom.s_no = index + 1;
          symptom.symptom_id = element.symptom_id;
          symptom.symptom_name = element.symptom_name;
          symptom.symptom_category = element.symptom_category;
          symptom.id = element.id;

          symptomlist[index] = symptom;
        });

        this.setState({ records: symptomlist });
      })
      .catch((e) => { });

    axios
      .post(getAllSymptomCategory, { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let symptomCategory = list.data.data;
        let symptomCategorylist = [];

        symptomCategory.forEach((element, index) => {
          symptomCategorylist.push({
            value: element.id,
            label: element.symptom_category,
          });
        });

        this.setState({ symptomCategory: symptomCategorylist });
      })
      .catch((e) => { });
  };

  UpdateSymptom = (e) => {
    e.preventDefault();
    let { symptomData, errors } = this.state;
    symptomData.type = "symptom";
    errors = Validation(symptomData, errors);
    console.log(errors);
    console.log(symptomData);
    this.setState({ errors: errors });
    if (!errors.formIsValid) {
      return;
    }
    let data = {};
  //  let data = this.state.symptomData;

    let symptomDataupdated = data;
    data.user_id = this.props.userdata.id;
    data.symptom_category = symptomData.symptom_category
      ? symptomData.symptom_category.label
      : "";
    data.symptom_name = symptomData.symptom_name
      ? symptomData.symptom_name.label
      : "";
    data.id = this.state.symptomData.id;
    data.symptom_id=symptomData.symptom_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios({
      method: this.state.actions === "add" ? "post" : "put",
      url: this.state.actions === "add" ? addSymptom : updateSymptom,
      data: data,
      headers: CONFIG.headers,
    }).then(
      (res) => {
        let resData = res.data;
        console.log(res.data);
        if(res.data.status=="0")
        {
          console.log(res.data.message);
        //  this.setState({ successMessage: res.data.message });
        }
        if (res.status !== 200 || !resData) {
        
          this.setState({
            errors: {
              symptom_id: "Symptom ID already exists",
            },
          });
       //   this.cancel();
          this.clearMessage();

         return;
                 }

       /*  if (res.data.status === "0") {
          this.setState({
            errors: {
              symptom_id: "Select Symptom Name and Symptom Category",
            },
          });
        */   // let symptomDataupdated = {
          //   symptom_id: data.symptom_id,
          //   symptom_name: data.symptom_name,
          //   user_id: data.user_id,
          //   symptom_category: this.state.symptomData.symptom_category,
          // };

      /*     this.setState({ symptomData: symptomDataupdated });
          return;
        }
 */
        let member = {};
        member.type = "symptom";
        member.symptom_id = data.symptom_id;
        member.symptom_name = data.symptom_name;
        member.symptom_category = data.symptom_category;
        let records = this.state.records;
        resData = res.data.data;
        if (this.state.actions === "add" && resData) {
          member.id = resData[0] && resData[0].id > 0 ? resData[0].id : null;
          member.s_no = records.length + 1;
          records.push(member);
          toast.success("The Symptom details have been added successfully.",{
            className:"toast-success"
          });
      
        } else if (this.state.actions === "edit" && this.state.editRecord) {
          member.id = data.id;
          member.s_no = this.state.editRecord.s_no;
          records.splice(records.indexOf(this.state.editRecord), 1, member);
          this.setState({
            editRecord: null,
          });
          toast.success("The Symptom details have been Updated successfully.",{
            className:"toast-success"
          });
        } else {
         
          this.setState({
            errors: {
              error: "Symptom ID already exists",
            },
            searchrecords:records,
          });
      //   this.cancel();
         this.clearMessage();
          toast.error(res.data.message +".Symptom could not be Added. ",{
            className:"toast-error"
          });
          return;
         
        }
        
        this.setState({
          records: records,
          actions: "add",
          errorMessage: "",
         /*  successMessage: `Symptom ${this.state.actions === "add" ? "added" : "updated"
            } successfully.`,
          editformSuccess: `Symptom ${this.state.actions === "add" ? "added" : "updated"
            } successfully.`, */
          symptomData: {
            symptom_id: "",
            symptom_name: "",
            symptom_category: "",
          },
        });
        this.clearMessage();
      },
      (err) => {
        this.setState({
          actions: "add",
          errorMessage: `Symptom failed to ${this.state.actions === "add" ? "add" : "update"
            }.`,
          successMessage: "",
          symptomData: {
            symptom_id: "",
            symptom_name: "",
            symptom_category: "",
          },
        });
        this.clearMessage();
      }
    );
  };

  clearForm = () => {
    let errors=this.state.errors;
    this.setState({
      actions: "add",
      editRecord: null,
      symptomData: {
        symptom_id: "",
        symptom_name: "",
        symptom_category: "",
      },
      errors: {
        symptom_id: "",
        symptom_name: "",
        symptom_category: "",
        error: "",
      },
      successMessage: "",
      errorMessage: "",
    });
    errors={};
    this.setState({errors,searchrecords: [], searchErrorMessage: "", search_action: false});
  };

  clearMessage() {
  //  let errors=this.state.errors;
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        searchTableSuccess:"",
        errors:"",
      });
    }, 5000);
   /*  errors.error="";

    errors={};
    this.setState({errors}); */
  }
  cancel = () => {
    let data = this.state.symptomData;
    data.symptom_id = "";
    data.symptom_name = "";
    data.id = "";
    data.symptom_category = "";
   
   
    this.setState({ symptomData: data });
};
  editRecord(record) {

    let symptomData = this.state.symptomData;
    symptomData.symptom_id = record.symptom_id;
    symptomData.symptom_name = {
      label: record.symptom_name,
      value: record.symptom_name,
    };
    symptomData.id = record.id;
    symptomData.user_id = record.user_id;
    symptomData.symptom_category = {
      label: record.symptom_category,
      value: record.symptom_category,
    };
    this.setState({ editRecord: record });
    this.setState({ symptomData: symptomData, actions: "edit" });
  }

  deleteRecord(record) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteSymptom, { data: { id: record.id } }, CONFIG)
      .then((res) => {
        const resData = res.data;
        if (res.status !== 200 || !resData || resData.status === "0") {
          this.setState({
            errorMessage: "Unexpected error occured. Please try again.",
          });
          this.clearMessage();
          this.cancel();
          return;
        }

        let records = this.state.records;
        const deleteIndex = records.indexOf(record);
        records.splice(deleteIndex, 1);
        this.setState({
          records
          
        });
        // this.setState({        
        //   successMessage: "Symptom deleted successfully.",
        // });
        toast.error("The Symptom detail has been deleted successfully.",{
          className:"toast-error"
        });
        this.clearMessage();
         this.cancel();
      })
      .catch((e) => {
        this.setState({
          errorMessage: "Symptom failed to delete.",
        });
        toast.error("Symptom could not be deleted. ",{
          className:"toast-error"
        });
        this.clearMessage();
      });
    this.setState({ deletePopup: false, record: null });
  }

  handleselectsymptom_categoryChange = (selectedOptions, e) => {
    let errors=this.state.errors;
    let data = this.state.symptomData;
    data[e.name] = selectedOptions;
    errors.symptom_category="";
    this.setState({ symptomData: data,errors });
  };
  handleselectChange = (selectedOptions, e) => {
    let errors=this.state.errors;
    let data = this.state.symptomData;
    data[e.name] = selectedOptions;
    errors.symptom_name="";
    this.setState({ symptomData: data,errors,searchErrorMessage: "" });
  };

  validateMaxLength(name, value, max) {
    let data = this.state.symptomData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
   //   errors[name] = "";
      data[name] = value;
    }
    this.setState({ symptomData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.symptomData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name === "symptom_id"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
    }
    this.setState({ symptomData: data,searchErrorMessage: "" });
  };

  handleCategoryColumnChange = (value, record, field) => {
    value[field] = record;
    this.UpdateColumnChange(value);
  };

  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
   let value=event.target.value;
    if (
      event.target.name === "searchsymptom_id"

    ) {
      if (value.length > max) {
        const value1 = value.slice(0, max);
        record.symptomid_error  = "Maximum Length has exceeded.";
        record[event.target.name] = value1;
      }
     else {
      record.symptomid_error  = "";
      record[event.target.name] = event.target.value;
    }
  }
    this.UpdateColumnChange(record);
  };

  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].symptom_id = record.symptom_id;
    records[recIndex].symptom_name = record.symptom_name;
    records[recIndex].symptom_category = record.symptom_category;
    this.setState({ searchrecords: records });
  };

  handleSearchInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    
    this.setState({ searchData: data });
  };

  searchAndShowSymptom() {
    let symptomData = this.state.symptomData;
    if (
      (symptomData.symptom_name === "" || null == symptomData.symptom_name) &&
      (symptomData.symptom_category === "" || null == symptomData.symptom_category)
    ) {
      this.setState({
        searchErrorMessage:
          "Choose any one of the search filters other than Symptom ID.",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }
    let data = {};
    data.symptom_name = symptomData.symptom_name.label;
    data.symptom_category = symptomData.symptom_category.label;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(searchSymptom, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        if (result.countOfRecords > 0) {
          let records = result.data;
          let symptoms = [];
          records.forEach((element) => {
            symptoms.push({
              symptom_id: "",
              symptom_name: element.symptom_name,
              symptom_category_hide: element.symptom_category,
              symptom_category: element.symptom_category,

              id: element.id,
              symptomid_error: "",
              symptomname_error: "",
              symptomcategory_error: "",
            });
          });

          this.setState({ searchrecords: symptoms });
          this.setState({
            search_action: true,
          });
        } else {
          this.setState({ searchrecords: [], search_action: true });
        }
      });
    });
  }

  addRecord = (record) => {
    let formvalid = true;
    if (record.searchsymptom_id === "") {
      record.symptomid_error = "Please enter symptom id";
      formvalid = false;
    } else {
      record.symptomid_error = "";
    }
    if (record.symptom_name === "") {
      formvalid = false;
      record.symptomname_error = "Please enter symptom name";
    } else {
      record.symptomname_error = "";
    }
    if (record.symptom_category === "") {
      formvalid = false;
      record.symptomcategory_error = "Please enter symptom category";
    } else {
      record.symptomcategory_error = "";
    }
    let _searchRecords = this.state.searchrecords;
    const recIndex = findWithAttr(_searchRecords, "id", record.id);
    _searchRecords[recIndex] = record;
    this.setState({ searchrecords: _searchRecords });
    if (!formvalid) {
      return;
    }

    this.setState({ actions: "add", loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let selectedData = record;
    console.log(selectedData)
    let data = {};
    data.user_id = this.props.userdata.id;
    data.symptom_id = selectedData.searchsymptom_id;
    data.symptom_name = selectedData.symptom_name;
    data.symptom_category = selectedData.symptom_category;
console.log(data);
    axios
      .post(addSymptom, data, CONFIG)
      .then((result) => {
        let res = result.data;
        if (res.status === "1") {
          let symptom = {};
          let records = this.state.records;
          symptom.s_no = this.state.records.length + 1;
          symptom.symptom_id = data.symptom_id;
          symptom.id = res.data[0].id;
          symptom.symptom_name = data.symptom_name;
          symptom.symptom_category = data.symptom_category;
          console.log(symptom);
          records.push(symptom);
          this.setState({
            records: records,
            searchTableSuccess: res,
            loading: false,
          });

          this.clearMessage();
          this.cancel();
        } else {
          this.setState({
            searchrecords: _searchRecords,
            searchTableSuccess: res,
            loading: false,
          });
          this.clearMessage();
        }
      })
      .catch((e) => { });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });
  };

  render() {
    const {
      symptomData,
      // symptomCategory,
      errors,
      searchData,
      successMessage,
      errorMessage,
      searchTableSuccess,
      loading,
      searchErrorMessage,
      editformSuccess,
      hospitaltimeout
    } = this.state;
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    return (
      <div id="layout-wrapper">
        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

      {loading && <LoadingHeartrate />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <SideBar />
              </div>
              {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

              <section className="main-body hospital-profilegap">
                <div className="container-fluid checkout-tabs">
                  <div className="checkout-tabs">



                    <div className="row">
                      <div className="col-sm-12">

                        <h4 className="font-weight-bold"> Symptom List </h4>

                      </div>
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                                  <div className="float-right"> <SymptomsExport business_id={this.props.userdata.id} records={this.state.records}/></div>
                                  
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <ReactDatatable
                                  config={this.config}
                                  records={this.state.records}
                                  columns={this.columns}
                                  className="table table-bordered table-striped kas  mobile_table"
                                />
                                <Popup
                                  show={this.state.deletePopup}
                                  onHide={() =>
                                    this.setState({ deletePopup: false })
                                  }
                                  onConfirm={() =>
                                    this.deleteRecord(this.state.record)
                                  }
                                  title={"Confirmation"}
                                  message={
                                    "Are you sure you want to delete this record?"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 pr0">
                        <div className="card m565">
                        <div class="card-header"> <div class="card-title">  {this.state.actions === "add"
                                  ? "Add"
                                  : "Edit"}{" "}
                                / Search Symptom</div> </div>
                          <div className="card-body">
                            <div>
                           
                              <div className="user-notification">
                                <SpanField
                                  classname="text-danger"
                                  title={errors.error}
                                />
                               
                                <SpanField
                                  classname="text-danger"
                                  title={errorMessage}
                                />
                                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                              </div>

                              <div className="user-notification-box">

                                <SpanField
                                  classname={
                                    "0" === editformSuccess.status
                                      ? "text-danger user-notification"
                                      : "text-success user-notification"
                                  }
                                  title={editformSuccess.message}
                                />
                                 <div className="user-notification">
                                  <SpanField
                                    classname={
                                      "0" === successMessage.status
                                        ? "text-success user-notification"
                                        : "text-danger user-notification"
                                    }
                                    title={successMessage}
                                  />
                                </div>
                                 {/*  <SpanField
                                  classname="text-success"
                                  title={successMessage}
                                /> */}
                              </div>
                              <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <Label title=" Symptom ID" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search" />
                                            </span>
                                          </div>
                                          <InputField
                                            inputType={"text"}
                                            name={"symptom_id"}
                                            controlFunc={this.handleInput}
                                            content={symptomData.symptom_id}
                                            placeholder={""}
                                            max={5}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.symptom_id}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <Label title="Symptom Name" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          {/* <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search" />
                                            </span>
                                          </div> */}
                                          {/* <InputField
                                              inputType={"text"}
                                              name={"symptom_name"}
                                              controlFunc={this.handleInput}
                                              content={symptomData.symptom_name}
                                              placeholder={""}
                                            /> */}
                                          <Select maxMenuHeight={120}
                                            className="w-100 p-0"
                                            name="symptom_name"
                                            value={
                                              symptomData.symptom_name
                                            }
                                            onChange={this.handleselectChange}
                                            options={this.state.masterSymptoms}
                                            isClearable={true}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.symptom_name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                </div>
                                <div className="col-sm-6-1 float-left">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <Label title=" Symptom Category" isMandatory={true} />
                                    </div>
                                    <div className="form-group sform-control frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          {/* <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search" />
                                            </span>
                                          </div> */}
                                          <Select maxMenuHeight={120}
                                            className="w-100 p-0"
                                            name="symptom_category"
                                            value={
                                              symptomData.symptom_category
                                            }
                                            onChange={this.handleselectsymptom_categoryChange}
                                            options={this.state.masterSymptomCategory}
                                            isClearable={true}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.symptom_category}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                           

                            
                              <div className="row mg-top2">
                             
                                <div className="col-sm-12 text-right">
                                  <button
                                    className="signupbtn abtn btdpml mr-2"
                                    onClick={this.UpdateSymptom}
                                  >
                                    {this.state.actions === "add"
                                      ? "Add"
                                      : "Update"}
                                  </button>
                                  <button
                                    className="signupbtn abtn btdpml mr-2"
                                    disabled={this.state.actions=="edit"?true:false}
                                    onClick={this.searchAndShowSymptom.bind(
                                      this
                                    )}
                                  >
                                    Search
                                  </button>
                                  <button
                                      className="signupbtn abtn  adbt"
                                      onClick={this.clearForm}
                                    >
                                      Reset
                                    </button>
                                </div>
                               
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-sm-6 pr0">
                        <div className="card b-10-hite">
                          <div className="card-body">
                            <div>
                              <h5 className="regxs">Search</h5>
                              <hr className="rowline" />

                              <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div> */}
                      {/* 
                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label className="ffgrd">
                                        Symptom Name
                                      </label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search" />
                                            </span>
                                          </div>
                                          {/* <InputField
                                            inputType={"text"}
                                            name={"symptom_name"}
                                            controlFunc={
                                              this.handleSearchInput
                                            }
                                            content={searchData.symptom_name}
                                            placeholder={""}
                                          /> */}
                      {/* <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="symptom_name"
                                            value={
                                              searchData.symptom_name
                                            }
                                            onChange={this.handlesearchselectChange}
                                            options={this.state.masterSymptoms}
                                            isClearable={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                      {/* 
                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label className="ffgrd">
                                        Symptom Category
                                      </label>
                                    </div>
                                    <div className="form-group sform-control frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search" />
                                            </span>
                                          </div>


                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="symptom_category"
                                            isClearable={true}
                                            value={
                                              searchData.symptom_category
                                            }
                                            onChange={
                                              this.handlesearchselectChange
                                            }
                                            options={this.state.masterSymptomCategory}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group mt-25 m-50">
                                        <button
                                          className="signupbtn abtn btdpml"
                                          onClick={this.searchAndShowSymptom.bind(
                                            this
                                          )}
                                        >
                                          Search
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
 */}
                      {/*    </div>
                          </div>
                        </div>
                      </div> */}

                      <div
                        className="w100"
                        style={{
                          display: this.state.search_action
                            ? "block"
                            : "none",
                        }}
                      >
                        <div className="col-sm-12">
                          <div className="hmeclr bgligtrn">
                            <h5 className="fnsz hptd"> Search Result </h5>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body ">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        "0" === searchTableSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={searchTableSuccess.message}
                                    />
                                  </div>
                                  <ReactDatatable
                                    className="table table-bordered table-striped kas  mobile_table"
                                    config={this.searchconfig}
                                    records={this.state.searchrecords}
                                    columns={this.searchcolumns}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Footerad /> */}


                  </div>

                </div>
              
              </section>)}
                  <FooterSection />
            </div>



          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Symptoms);

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
