
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import SpanField from "../components/UI/SpanField";
import axios from "axios";
import { Popup } from "../components/UI/Popup";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';


import {
    getallfooterad,

    addfooterad,
    updatefooterad,
} from "../config/url.json";
import S3 from "react-aws-s3";
import { CONFIG, imageconfig } from "../config/data";
import Select from "react-select";
import Validation from "../actions/Validation";
import FooterSection from '../components/UI/FooterSection';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}
const status = [
    { value: "Active", label: "Active" },
    { value: "In-active", label: "In-active" },
];

const availabilityOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
];

class FooterAdverdisement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            addPopup: false,
            actions: "add",

            footerData: {
                id: "",
                footer_adverdisement: "",
                status: "",


            },

            errors: {
                id: "",
                footer_adverdisement: "",
                status: "",

            },
            tableSuccess: {
                message: "",
                status: "",
            },
            formSuccess: {
                message: "",
                status: "",
            },
            vendor_image_url: "",
        };
        this.columns = [
            {
                key: "s_no",
                text: "S.No",
                className: "s_no",
                align: "left",
                sortable: true,
            },
            {
                key: "id",
                text: "Id",
                className: "hidden-column",
                TrOnlyClassName: "hidden-column",

                align: "left",
                sortable: true,
            },
            {
                key: "footer_adverdisement",
                text: "Footer Advertisment",
                className: "footer_adverdisement",
                align: "left",
                sortable: true,
                cell: (record) => {

                    return (
                        <img
                            alt="banner"
                            style={{ maxWidth: "100%" }}
                            src={record.footer_adverdisement}
                        />);
                },
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
            },



            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: true,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.onDeleteClick(record)}
                            >
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    UpdateColumnChange = (data) => {

        axios.put(updatefooterad, data, CONFIG).then((list) => {
            let records = this.state.records;

            var recIndex = findWithAttr(records, "id", data.id);

            records[recIndex].footer_adverdisement = data.footer_adverdisement;
            records[recIndex].status = data.status;
            // records[recIndex].expires_at = data.expires_at;
            records[recIndex].id = data.id;

            this.setState({ records, tableSuccess: list.data });
            this.clearMessage();
        });
    };
    updatefooterad = (e) => {
        e.preventDefault();
        let { footerData, errors } = this.state;
        let data = {};
        data.type = "footerAd";
        // data.expires_at = footerData.expires_at ? moment(footerData.expires_at).format("YYYY-MM-DD") : "";
        data.footer_adverdisement = footerData.footer_adverdisement;
        data.status = footerData.status.value;
        this.setState({ loading: true });
        errors = Validation(data, errors);
        this.setState({ errors: errors });
        if (errors.formIsValid) {
            // console.log(data); return false;

            let url = this.state.actions === "add" ? addfooterad : updatefooterad;
            if (this.state.actions === "add") {
                axios.post(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });

                        this.setState({ errors: { footer_adverdisement: list.data.message } });
                    } else {
                        let addfooterad = {};
                        addfooterad.footer_adverdisement = data.footer_adverdisement;
                        addfooterad.status = data.status;
                        // addfooterad.expires_at = moment(data.expires_at).format('DD-MM-YYYY');

                        addfooterad.id = list.data.data;

                        let records = this.state.records;
                        addfooterad.s_no = records.length + 1;
                        records.push(addfooterad);
                        this.setState({ records, formSuccess: list.data });

                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            } else {
                data.id = footerData.id;
                console.log(data)
                axios.post(url, data, CONFIG).then((list) => {
                    if ('0' === list.data.status) {
                        this.setState({ loading: false });
                        this.setState({ errors: { footer_adverdisement: list.data.message } });
                    } else {
                        let records = this.state.records;

                        var recIndex = findWithAttr(records, "id", data.id);
                        console.log(recIndex);
                        console.log(records);
                        records[recIndex].footer_adverdisement = data.footer_adverdisement;
                        records[recIndex].status = data.status;
                        // records[recIndex].expires_at = moment(data.expires_at).format('DD-MM-YYYY');
                        records[recIndex].id = data.id;

                        this.setState({ records, formSuccess: list.data });
                        this.setState({ loading: false });
                        this.clearMessage();
                        this.cancel();
                    }
                });
            }
        }
    };

    clearErrors = (errors) => {
        errors.footer_adverdisement = "";
        errors.status = "";
        // errors.expires_at = "";
        return errors;
    };

    cancel = () => {
        let data = this.state.footerData;
        data.footer_adverdisement = "";
        data.status = "";
        data.id = "";
        // data.expires_at = "";

        let errors = this.state.errors;

        errors = this.clearErrors(errors);

        this.setState({ footerData: data, actions: "add", errors: errors });
    };

    clearMessage = () => {
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };

    editRecord(record) {
        console.log(record)
        let footerData = {};
        footerData.footer_adverdisement = record.footer_adverdisement;
        footerData.status = { "label": record.status, "value": record.status };
        footerData.id = record.id;


        console.log(footerData)
        let errors = this.state.errors;
        errors = this.clearErrors(errors);

        this.setState({
            footerData: footerData,
            actions: "edit",
            errors: errors,
        });
    }
    componentDidMount = () => {

        axios
            .post(getallfooterad, { user_type: 'admin' }, CONFIG)
            .then((list) => {
                let footeradlist = list.data.data;

                footeradlist.forEach((element, index) => {
                    let footerad = {};
                    footerad.s_no = index + 1;
                    footerad.footer_adverdisement = element.footer_adverdisement;
                    // footerad.expires_at = moment(element.expires_at).format('DD-MM-YYYY');
                    footerad.status = element.status;
                    footerad.id = element.id;

                    footeradlist[index] = footerad;
                });

                // let clientmedicinehistory = {};
                console.log(footeradlist)
                this.setState({ records: footeradlist });
            })
            .catch((e) => { });


    };
    // onDeleteClick(record) {
    //     this.setState({ addPopup: true, record: record });
    // }
    // deleteRecord(record) {
    //     let userId = record.id;
    //     this.setState({ loading: true });

    //     axios
    //         .delete(deletefooterad, { data: { id: userId } }, CONFIG)
    //         .then((res) => {
    //             let response = res.data;
    //             if (response.status === "1") {
    //                 let records = this.state.records;
    //                 const deleteIndex = records.map((item) => item.id).indexOf(record.id);
    //                 records.splice(deleteIndex, 1);
    //                 this.setState({ records });
    //             }
    //             this.setState({ addPopup: false, record: "", loading: false });
    //             this.setState({ tableSuccess: res.data });
    //             this.clearMessage();
    //         })
    //         .catch((e) => { });
    // }

    handleInput = (e) => {
        let data = this.state.footerData;
        let name = e.target.name;
        let value = e.target.value;
        const re = /^[0-9\b]+$/;
        if (name === "percentage") {
            if (value === "" || re.test(value)) {
                data[name] = value;
            }
        } else {
            data[name] = value;
        }

        this.setState({ footerData: data });
    };

    onUploadChange = (event) => {
        event.preventDefault();

        console.log(event.target.files[0]);
        let file = event.target.files[0];

        let img = URL.createObjectURL(event.target.files[0]);

        this.setState({ vendor_image_url: img });
        let newfileName = event.target.files[0].name;
        imageconfig.dirName = "footer_adverdisement";

        console.log(imageconfig);
        const reacts3client = new S3(imageconfig);
        console.log(reacts3client);
        reacts3client.uploadFile(file, newfileName).then((data) => {
            console.log(data);
            if (data.status === 204) {
                console.log("success");
                let footerData = this.state.footerData;
                footerData.footer_adverdisement = data.location;
                this.setState({ footerData: footerData });
            } else {
                console.log("error");
            }
        });
    };
    handleColumnChange = (selectedOptions) => {
        console.log(selectedOptions)
        let footerData = this.state.footerData;
        footerData.status = selectedOptions
        this.setState({ footerData: footerData });
    };
    render() {
        let PopupClose = () => this.setState({ addPopup: false });
        let PopupConfirm = () => this.deleteRecord(this.state.record);
        const {
            footerData,

            errors,
            formSuccess,
            tableSuccess,
        } = this.state;
        return (

            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>
                            {/* <section className="my-head">
                                <div className="container-fluid">
                                    <div className="row">
                                        <LogoSection />
                                    </div>
                                </div>
                            </section> */}

                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Footer Advertisment List </h4>
                                    <div className="row mt-3">

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                            <Popup
                                                                show={this.state.addPopup}
                                                                onHide={PopupClose}
                                                                onConfirm={PopupConfirm}
                                                                title={"Confirmation"}
                                                                message={"Are you sure you want to delete this record ?"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 pr0">
                                            <div className="card b-12-hite">
                                            <div class="card-header"> <div class="card-title">{this.state.actions === "add" ? "Add" : "Edit"} Footer Advertisment</div> </div>
                                                <div className="card-body">
                                                    <div className="user-notification">
                                                        <SpanField
                                                            classname={
                                                                0 === formSuccess.status
                                                                    ? "text-danger user-notification"
                                                                    : "text-success user-notification"
                                                            }
                                                            title={formSuccess.message}
                                                        />
                                                    </div>

                                                    <div>
                                                     
                                                          
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="frllftfull">
                                                                    <div className="form-group frllft mbbre">
                                                                        <label className="ffgrd">Footer Adverdisement</label>
                                                                    </div>
                                                                    <div className="form-group frlrit">
                                                                        <div className="position-relative">
                                                                            <div className="custom-file">
                                                                                <input
                                                                                    accept="image/*"
                                                                                    type="file"
                                                                                    className="custom-file-input"
                                                                                    onChange={this.onUploadChange}
                                                                                />
                                                                                <label
                                                                                    className="custom-file-label"
                                                                                    htmlFor="customFile"
                                                                                >
                                                                                    Choose file</label>
                                                                            </div>
                                                                            <div className="vendorImageDisplay">
                                                                                <img
                                                                                    alt="banner"
                                                                                    style={{ maxWidth: "100%"}}
                                                                                    
                                                                                    src={footerData.footer_adverdisement}
                                                                                />
                                                                            </div>
                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.promo_code}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="frllftfull">
                                                                    <div className="form-group frllft mbbre">
                                                                        <label className="ffgrd">Status</label>
                                                                    </div>
                                                                    <div className="form-group  frlrit">
                                                                        <div className="position-relative">
                                                                            <div className="input-group">
                                                                                <Select 
                                                                                     className="form-control p-0 "
                                                                                name="status"
                                                                                    value={footerData.status}
                                                                                    onChange={(data) => this.handleColumnChange(data)}
                                                                                    options={status}
                                                                                // disabled={(option) => option.value === "Accepted"}
                                                                                // disabled={record.status == "Accepted"}
                                                                                />
                                                                            </div>
                                                                            <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.status}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>




                                                        <div className="row">
                                                            <div className="col-sm-6"></div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group mb-0 btn-align-right">
                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml btn-margin"
                                                                        onClick={this.updatefooterad}
                                                                    >
                                                                        {this.state.actions === "add" ? "+ Add" : "Update"}
                                                                    </button>

                                                                    <button
                                                                        href="patients-register"
                                                                        className="signupbtn abtn btdpml"
                                                                        onClick={this.cancel}
                                                                    >
                                                                        {"Cancel"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                           
                            </section>
                            <FooterSection />
                        </div>
                       
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(FooterAdverdisement);
