import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminHeader from '../components/container/layout/admin/Header';
import AdminSideBar from '../components/container/layout/admin/SideBar';
import FooterSection from '../components/UI/FooterSection';
import SpanField from '../components/UI/SpanField';
import Select from "react-select";
import Label from '../components/UI/Label';
function mapStateToProps(state) {
    return {

    };
}


class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationdata: { category: "" },
            formSuccess: { status: "", message: "" },
            category: [{ label: "hospitals", value: "hospitals" }, { label: "hospitals", value: "hospitals" }, { label: "hospitals", value: "hospitals" },]
            // errors: { support_phone_number: "", support_email_id: "" }
        }
    }
    handleColumnChange = (e) => {
        console.log(e)
        this.setState({})

    }
    componentDidMount() {
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // axios
        //     .post(getAllBusinessList, null)
        //     .then((response) => {
        //         // console.log(response.data)
        //         let hospitals = response.data.data;

        //         this.setState({ hospitals: hospitals })
        //     }).catch((e) => {
        //         console.log("get queue failed");
        //     });
    }
    render() {
        const {
            notificationdata,

            formSuccess,
        } = this.state;
        return (
            <div className="main-content bacfot" >
                <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                    <div className="main-box adminMainBox">
                        <div className="sidemenu pr0">
                            <AdminSideBar />
                        </div>

                        <section className="main-body hospital-profilegap">
                            <div className="container-fluid">
                                <div className="checkout-tabs row">
                                    <div className="col-sm-12">

                                        <div className="row">
                                            <div className="col-sm-8">


                                                <div className="card">
                                                <div class="card-header"> <div class="card-title">Settings</div> </div>
                                                    <div className="card-body">
                                                        <div
                                                            className="tab-content"
                                                            id="v-pills-tabContent"
                                                        >
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="v-pills-shipping"
                                                                role="tabpanel"
                                                                aria-labelledby="v-pills-shipping-tab"
                                                            >
                                                                <div className="col-sm-12">
                                                                    <SpanField
                                                                        classname={
                                                                            "0" === formSuccess.status
                                                                                ? "text-danger user-notification"
                                                                                : "text-success user-notification"
                                                                        }
                                                                        title={formSuccess.message}
                                                                    />
                                                                  

                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Hospitals"
                                                                                isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">

                                                                            <Select maxMenuHeight={120}
                                                                                className="w-100 p-0"
                                                                                name="status"
                                                                                value={notificationdata.category}
                                                                                onChange={(data) => this.handleColumnChange(data)}
                                                                                options={this.state.category}
                                                                            // disabled={(option) => option.value === "Accepted"}
                                                                            // disabled={record.status == "Accepted"}
                                                                            />


                                                                            {/* <SpanField
                                                                                classname="text-danger"
                                                                                title={errors.support_email_id}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="frllftfull">
                                                                        <div className="form-group frllft">
                                                                            <Label
                                                                                title="Support Phone Number"
                                                                                isMandatory={true}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group frlrit">





                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                        </section>
                        <FooterSection />

                    </div>

                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(Notification);