import React from 'react';
import PropTypes from 'prop-types';

const TextArea = (props) => (

    <textarea
        className="form-control"
        style={props.resize ? null : { resize: 'none' }}
        name={props.name}
        rows={props.rows}
        value={props.content}
        onChange={props.controlFunc}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        disabled={props.isvisible} />
);

TextArea.propTypes = {
    // title: PropTypes.string.isRequired,
    rows: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    // content: PropTypes.string.isRequired,
    resize: PropTypes.bool,
    placeholder: PropTypes.string,
    maxLength:PropTypes.number,
    controlFunc: PropTypes.func.isRequired
};

export default TextArea;