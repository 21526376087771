import React from 'react';
// import PropTypes from 'prop-types';

const SpanField = (props) => (
    <span className={props.classname} onClick={props.onClick}>{props.title}</span>


);

// Label.propTypes = {
//     title: PropTypes.string.isRequired,
//     isMandatory: PropTypes.bool


// };

export default SpanField;