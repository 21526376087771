import React,{Fragment} from "react";
import { connect } from "react-redux";
import { withRouter , Link} from "react-router-dom";
function SideBar(props) {


  

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    props.logout();
    props.history.push("/");
  };
  
  //let message_count = props.appdata?props.appdata[0].message_count : "";
  let message_count=0;

  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  
 
  return (
<Fragment>
  
  <div className={click ? "sidebar-toggleon toggle" : " toggle"} >

  <button  onClick={()=>handleClick()} ><i className="fas fa-bars"></i></button>
    
    <div className="vertical-menu displaymobilenone scrollbox">
      <div data-simplebar="">
        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
         
          {/*   <!-- Left Menu Start -->*/}
          <ul className="metismenu list-unstyled" id="side-menu">
            
            {/* <li className="sidmnl">
              <Link
                to={"/dashboard"}
                className="waves-effect"
                aria-expanded="false"
              >
                 <i className="fa-solid fa-house"></i>
                <span>Dashboard</span>
              </Link>
            </li> */}
            <li className="sidmnl">
              <a
                href={"/dashboard"}
                className="waves-effect"
                aria-expanded="false"
              >
                 <i className="fa-solid fa-house"></i>
                <span>Dashboard</span>
              </a>
            </li>
        
            {/* <li className="sidmnl">
              <Link to="/favourites" className="waves-effect"
                aria-expanded="false">
           <i className="fas fa-star"></i>
                    <span>Favourites{" "}</span>
                  
                </Link>
            </li> */}
             <li className="sidmnl">
              <a href="/favourites" className="waves-effect"
                aria-expanded="false">
           <i className="fas fa-star"></i>
                    <span>Favorites{" "}</span>
                  
                </a>
            </li>
            {/* <li className="sidmnl">
                <a href="/appointments" className="waves-effect"
                aria-expanded="false">
                  <i>
                    <img
                      src={require("../../../../assets/images/a-page/icon3.png")}
                    />
                    </i>
                    <span>
                    Appointments</span>
                  
                </a>
                </li> */}

                {/* <li className="sidmnl">
                 <Link to="/search" className="waves-effect"
                aria-expanded="false">
                 <i className="fas fa-calendar-check"></i>
                    <span>
                    Book Appointment</span>
                  
                </Link>
              </li>  */}

             <li className="sidmnl">
              <a 
                href="/search" 
                className="waves-effect"
                aria-expanded="false">
                 <i className="fas fa-calendar-check"></i>
                    <span>Book Appointment</span>
                </a>
            </li>

            <li className="sidmnl">
              <a
                href="/familydetails"
                className=" waves-effect"
                aria-expanded="false"
              >
              <i className="fas fa-users"></i>
                <span> Family Details</span>
              </a>
                 </li>
                {/* <a href="#">
                  <li>
                    <img
                      src={require("../../../../assets/images/a-page/icon6.png")}
                    />
                    Add vehicle
                  </li>
                </a> */}

            <li>
              <a className="has-arrow waves-effect" aria-expanded="false">
              <i className="fas fa-notes-medical"></i>
                <span>Token Reports</span>
              </a>
              <ul className="ulcls mm-collapse">
                <a href="/prescription">
                  <li>
                  <i className="fas fa-file-medical-alt"></i>
                    Prescription
                  </li>
                </a>
                <a href="/consultation">
                  <li>
                  <i className="fas fa-hospital-user"></i>
                    Consultation summary
                  </li>
                </a>
                {/* <a href="#">
                  <li>
                    <img
                      alt=""
                      src={require("../../../../assets/images/a-page/icon9.png")}
                    />
                    Medical History
                  </li>
                </a>
                <a href="#">
                  <li>
                    <img
                      alt=""
                      src={require("../../../../assets/images/a-page/icon10.png")}
                    />
                    Test results
                  </li>
                </a> */}
              </ul>
            </li>
            <li>
              <a  className="has-arrow waves-effect" aria-expanded="false">
              <i className="fas fa-notes-medical"></i>
                <span>Appointment Reports</span>
              </a>
              <ul className="ulcls mm-collapse">
                <a href="/apptprescription">
                  <li>
                  <i className="fas fa-file-medical-alt"></i>
                    Prescription
                  </li>
                </a>
                <a href="/apptconsultation">
                  <li>
                  <i className="fas fa-hospital-user"></i>
                    Consultation summary
                  </li>
                </a>
                {/* <a href="#">
                  <li>
                    <img
                      alt=""
                      src={require("../../../../assets/images/a-page/icon9.png")}
                    />
                    Medical History
                  </li>
                </a>
                <a href="#">
                  <li>
                    <img
                      alt=""
                      src={require("../../../../assets/images/a-page/icon10.png")}
                    />
                    Test results
                  </li>
                </a> */}
              </ul>
            </li>
            <li className="sidmnl">
              <a
                href="/inbox"
                className="waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-bell"></i>
                <span>Notifications</span>
                <span className="badge badge-light notificationicon"></span>
               {props.message_count > 0 ? (<span className="pulse bg-danger"></span>):""} 
               {/* <span className="pulse bg-danger"></span> */}
    {/* <span className="icon-button__badge"></span> */}
              </a>
            </li>
            <li className="sidmnl">
              <a
                href="/referrals"
                className="waves-effect"
                aria-expanded="false"
              >
                <i className="fas fa-sync-alt"></i>
                <span>Referrals</span>
              </a>
            </li>
            
            {/* <li className="sidmnl">
              <a href="#" className="waves-effect" aria-expanded="false">
                <i className="">
                  <img
                    alt=""
                    src={require("../../../../assets/images/a-page/icon11.png")}
                  />
                </i>
                <span>Finance</span>
              </a>
            </li> */}
             <li>
              <a href="/clientmedicinescheduling" className="waves-effect" aria-expanded="false">
              <i className="fas fa-file-invoice-dollar"></i>
                <span>Client Medicine Scheduling</span>
              </a>
            </li>
             <li>
              <a href="/showsubscriptionclient" className="waves-effect" aria-expanded="false">
              <i className="fas fa-file-invoice-dollar"></i>
                <span>Show Subscription</span>
              </a>
            </li>
            <li>
              <a href="/qr_code" className="waves-effect" aria-expanded="false">
              <i className="fas fa-qrcode"></i>
                <span>QR code</span>
              </a>
            </li>
            <li>
              <a href="/viewoffers" className="waves-effect" aria-expanded="false">
              <i className="fas fa-percent"></i>
                <span>Offers</span>
              </a>
            </li>

            <li>
              <a href="/faqweb" className="waves-effect" aria-expanded="false">
              <i className="fas fa-comment-dots"></i>
                <span>FAQ</span>
              </a>
            </li>
            <li>
              <a href="/supportweb" className="waves-effect" aria-expanded="false">
              <i className="fas fa-headset"></i>
                <span>Support</span>
              </a>
            </li>
            <li className="sidmnl">
              <a
                href={"/profile"}
                className="waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-user"></i>
                <span>My Account</span>
              </a>
            </li>
            <li className="sidmnl sidmnlss">
              <a
               
                className="waves-effect"
                aria-expanded="false"
                onClick={handleLogout}
              >
              <i className="fas fa-sign-out-alt"></i>
                <span>Sign out</span>
              </a>
            </li>
          </ul>
        </div>
        {/*<!-- Sidebar -->*/}
      </div>
    </div>
    </div>
   </Fragment> 
  );
}
const mapStateToProps = (state) => {
  
  return {
    loggedIn: state.AuthReducer.loggedIn,
    appdata: state.AppData,
    user: state.AuthReducer.user,
    message_count: state.AuthReducer.message_count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
