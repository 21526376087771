import React, { Fragment } from 'react';
import { MonthPicker } from 'react-dropdown-date';
import PropTypes from 'prop-types';



const MonthPick = (props) => (
    <Fragment>


                    <MonthPicker
                    defaultValue={props.defaultValue}
                    // to get months as numbers
                    // default is full name
                    short
                    // default is Titlecase
                    caps
                    // mandatory if end={} is given in YearPicker
                    endYearGiven
                    // mandatory
                    year={props.year}
                    // default is false
                    
                    
                    // mandatory
                    value={props.selectedOption}
                    // mandatory
                    onChange={props.controlFunc}
                    id={props.id}
                    name={props.name}
                    classes={'form-control mb-3'}
                    optionClasses={'option classes'}
                />
        
               
                   
        </Fragment>
);


MonthPick.propTypes = {
    name: PropTypes.string.isRequired,
    selectedOption: PropTypes.string,
    controlFunc: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export default MonthPick;
