import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDatatable from "@ashvin27/react-datatable";
import SpanField from "../components/UI/SpanField";
import { Popup } from "../components/UI/Popup";
import { getAllClientList, updateDoctorStatus1 ,removePatientFromPatientlist,getAllUserSubscriptions,updatePatientbyAdmin} from "../config/url.json";
import axios from "axios";
import { CONFIG,payment_status } from "../config/data";
import moment from "moment";
import AdminSideBar from '../components/container/layout/admin/SideBar';
import AdminHeader from '../components/container/layout/admin/Header';
import Select from "react-select";
import FooterSection from "../components/UI/FooterSection";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber  } from "react-phone-number-input";
import InputField from "../components/UI/InputField";
import Validation from "../actions/Validation";
import DatePicker from "react-date-picker";
import PatientListExport from "./PatientListExport";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}


class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableSuccess: {
                message: "",
                status: "",
            },
            records: [],
            actions: "update",
            loading: false,
            showDeleteConfirmPopup: false,
            confirmedDelete: false,
      
             deletePopup: false,
             patientdata:
             {
               id:"",
               first_name:"",
               last_name:"",
              user_id: "",
              email:"",
              blood_group:"",
              gender:"",
              phone_code:"+91",
              phone_number:"",
              subscription:"",
              payment_status:"",
              expires_at:"",

             },
             usersubscriptionlist:[],
             errors: {
                id:"",
                first_name:"",
                email:"",
                phone_number:"",
                subscription:"",
                payment_status:"",
                expires_at:"",

            },
            formSuccess: {
                message: "",
                status: "",
              },
        };
        
        this.columns = [
          {
            key: "s_no",
            text: "S.No",
            className: "s_no",
            align: "left",
            sortable: true,
          
          }, 
          {
                key: "id",
                text: "Patient ID",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "first_name",
                text: "First Name",
                className: "first_name",
                align: "left",
                sortable: true,
            },
            {
                key: "last_name",
                text: "Last Name",
                className: "last_name",
                align: "left",
                sortable: true,
            },
            {
                key: "date_of_birth",
                text: "Date Of Birth",
                width: 92,
                className: "date_of_birth",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return moment(record.date_of_birth).format("DD-MM-YYYY");
                },
            },
            {
                key: "gender",
                text: "Gender",
                className: "gender",
                sortable: true,
                align: "left",
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true,
            },
            // {
            //     key: "marital_status",
            //     text: "Marital Status",
            //     className: "marital_status",
            //     sortable: true,
            //     align: "left",
            // },
            {
                key: "blood_group",
                text: "Blood Group",
                className: "blood_group",
                sortable: true,
                align: "left",
            },
            {
                key: "phone_number",
                text: "Phone Number",
                className: "phone_number",
                sortable: true,
                align: "left",
            },
            {
                key: "subscription",
                text: "Subscription",
                className: "subscription",
                sortable: true,
                align: "left",
            },
            {
              key: "payment_status",
              text: "Payment Status",
              className: "payment_status",
              sortable: true,
              align: "left",
          },
          {
            key: "expires_at",
            text: "Expires At",
            className: "expires_at",
            sortable: true,
            align: "left",
            cell: (record) => {
                var date=moment(record.expires_at).format("DD-MMM-yyyy");
                console.log(date);
               if(date ==="Invalid date")
               {
                return "";
 
               }else
               {
                return moment(record.expires_at).format("DD-MMM-yyyy");
               }
            },
        },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                    this.setState({ deletePopup: true, record: record })
                                }
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
    }
    handleColumnChange = (record, selectedOptions) => {

        record.status = selectedOptions ? selectedOptions.value : "Pending";
        this.UpdateColumnChange(record);
    };

    UpdateColumnChange = (data) => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        let request = {
            business_user_id: data.user_id, status: data.status
        }
        axios.post(updateDoctorStatus1, request).then((list) => {
            let records = this.state.records;

            // data = list.data.data;
            if (list.data.status === "1") {
                var recIndex = findWithAttr(records, "user_id", data.user_id);

                records[recIndex].status = data.status;

                this.setState({ records, tableSuccess: list.data.message });
            }

            this.clearMessage();
        });
    };
    clearMessage = () => {

      this.setState({
        errors: {
        id:"",
        first_name:"",
        email:"",
        phone_number:"",
        subscription:"",
        payment_status:"",
        expires_at:"",

    }
  });
        setTimeout(
            () =>
                this.setState({
                    formSuccess: { message: "" },
                    tableSuccess: { message: "" },
                }),
            5000
        );
    };
    cancel = () => {
        let data = this.state.patientdata;
        data.id="";
        data.first_name = "";
        data.last_name="";
        data.email = "";
        data.phone_number = "";
        data.subscription="";
        data.payment_status="";
        data.expires_at="";
              
        this.setState({ patientdata: data, actions: "update" });
      };
    handleselectChange = (selectedOptions, e) => {
        let data = this.state.patientdata;
        data[e.name] = selectedOptions;
    
        this.setState({ patientdata: data });
      };
    handlePatientChange(value, field) {
        let fields = this.state.patientdata;
        // let errors = this.state.educationerror;
        console.log(field);
         if (value === "email") {
          fields.email = field.target.value;
          // errors[i].register_id = '';
        }else if (value === "id") {
          fields.id = field;
          // errors[i].education = '';
        } 
        else if (value === "first_name") {
          fields.first_name = field;
          // errors[i].education = '';
        }  
        else if (value === "phone_number") {
          fields.phone_number = field;
          // errors[i].education = '';
        }  
        else if (value === "subscription") {
          fields.subscription = field;
          // errors[i].education = '';
        }  
        else if (value === "expires_at") {
          fields.expires_at = field;
          // errors[i].education = '';
        }else if (value === "payment_status") {
          fields.payment_status = field;
          // errors[i].education = '';
        }                
        this.setState({
          hospitaldata: fields,
        });
      }
    editRecord = (record) => {
        console.log(record);
        this.setState({ actions: "edit" });
    
        let patientdata = this.state.patientdata;
        patientdata.id=record.id;
        patientdata.user_id = record.user_id;
        patientdata.first_name=record.first_name;
        patientdata.last_name=record.last_name;
        patientdata.gender=record.gender;
        patientdata.blood_group=record.blood_group;
        patientdata.email=record.email;
        patientdata.phone_number = record.phone_number;
        patientdata.subscription={
        label: record.subscription,
        value: record.subscription,
      };
         patientdata.payment_status = {
          label: record.payment_status,
          value: record.payment_status,
        };
           
         
        if(patientdata.expires_at===null|| patientdata.expires_at==="")
        {
            patientdata.expires_at =record.expires_at;
        }else
        {
            patientdata.expires_at=moment(record.expires_at,moment.defaultFormat).toDate();
        }
        
        let errors = this.state.errors;
        errors = this.clearErrors(errors);
                    
        this.handlePatientChange("user_id", patientdata.user_id);
        this.setState({ patientdata: patientdata });
      };
      clearErrors = (errors) => {
        errors.first_name = "";
        errors.subscription = "";
        errors.payment_status = "";
        errors.expires_at = "";
        errors.phone_number = "";
        errors.email="";
        return errors;
      };
    
    deleteRecord(record) {
      
        let user_id = record.user_id;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(removePatientFromPatientlist, { user_id: user_id }, CONFIG)
          .then((res) => {
            let response = res.data;
            if (response.status === "1") {
              let records = this.state.records;
    
              const deleteIndex = records.map((item) => item.user_id).indexOf(record.user_id);
              records.splice(deleteIndex, 1);
              this.setState({ records });
              this.setState({ tableSuccess: res.data });
              toast.error("Patient deleted. ", {
                className: "toast-error"
              });
              this.clearMessage();

            }
          })
          .catch(() => { });
        this.setState({ deletePopup: false, record: null });
    
        this.setState({
          confirmedDelete: false,
        });
        axios.defaults.headers.common[
          "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
          .get(getAllClientList, null, CONFIG)
          .then((list) => {
             console.log(list.data[0]);
             
              this.setState({ records: list.data.data });
              let users = [];
              let userlist = list.data.data;
              console.log(userlist);
              userlist.forEach((element, index) => {
                let user = {};
                user.s_no = index + 1;
                user.id=element.id;
                user.user_id=element.user_id;
                user.first_name = element.first_name;
                user.last_name = element.last_name;
                user.gender=element.gender;
                user.blood_group=element.blood_group;
                user.date_of_birth=element.date_of_birth;
               // user.phone_number = element.phone_number;
                user.phone_code=element.phone_code;
                user.phone_number = element.phone_number.includes("+91") ? element.phone_number : "+91"+element.phone_number;
         
                user.email = element.email;
                user.subscription = element.subscription;
                user.payment_status = element.payment_status;
                user.expires_at = element.expires_at;
                users[index] = user;
              });
              console.log(users);
              this.setState({ records: users });
          })
          .catch((e) => { console.log(e) });

      }
      clearAll() {
        let actions = this.state.actions;
        actions = "update";
        this.setState({ actions });
        let patientdata = {};
        patientdata.id="";
        patientdata.first_name="";
        patientdata.last_name="";
        patientdata.email = "";
        patientdata.phone_number = "";
        patientdata.subscription="";
        patientdata.payment_status="";
        patientdata.expires_at="";
        this.setState({ patientdata });
      }
      updatePatient=()=> {
        // let user_id = record.user_id;
           let { patientdata, errors } = this.state;
           patientdata.type = "patientlist";
           errors = Validation(patientdata, errors);
           this.setState({ errors: errors });
           if (errors.formIsValid) {
             let data = this.state.patientdata;
       
         //  let data = {};
           
           data.user_id=patientdata.user_id;
           data.id=patientdata.id;
           data.first_name=patientdata.first_name;
           data.last_name=patientdata.last_name;
           data.gender=patientdata.gender;
           data.blood_group=patientdata.blood_group;
           data.email = patientdata.email;
           if(data.phone_number && data.phone_number != '' && data.phone_number !='+91'){
            data.phone_code = "+" + parsePhoneNumber(patientdata.phone_number).countryCallingCode;
            data.phone_number = parsePhoneNumber(patientdata.phone_number).nationalNumber;
          }
        //   data.phone_number = patientdata.phone_number;
           data.subscription=patientdata.subscription.label;
           data.payment_status=patientdata.payment_status.label;
           data.expires_at=patientdata.expires_at;
   
           axios.put(updatePatientbyAdmin, data, CONFIG).then((list) => {
            console.log(data);
            console.log(list.data);
             let response=list.data;
             if (response.status === "1") {
   
             let member = {};
   
             member.id=data.id;
             member.user_id = data.user_id;
             member.first_name=data.first_name;
             member.last_name=data.last_name;
             member.gender=data.gender;
             member.blood_group=data.blood_group;
             member.email = data.email;
             member.phone_number = data.phone_number;
             member.subscription=data.subscription;
             member.payment_status=data.payment_status;
             member.expires_at=data.expires_at;
   
             let records = this.state.records;
   
             /** Updating the data table */
             let existingRecords = this.state.records;
             var index = existingRecords.findIndex((item) => item.user_id === data.user_id);
             existingRecords.splice(index, 1, member);
             this.setState({ records: existingRecords });
   
             this.setState({ records });
             this.setState({ formSuccess: list.data });
             this.cancel();
             this.clearMessage();
            
             }
           });
         }
         }
      handleDOBChange = (date) => {
        let Hospitaldata = this.state.hospitaldata;
        Hospitaldata.expires_at = date;
        this.setState({ hospitaldata: Hospitaldata });
      };
    componentDidMount = () => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
            .get(getAllClientList, null, CONFIG)
            .then((list) => {
              console.log(list.data.data);
              //  let userlist = list.data.data;
               // this.setState({ records: userlist });
               let users = [];
               let userlist = list.data.data;
               userlist.forEach((element, index) => {
                 let user = {};
                 user.s_no = index + 1;
                 user.id=element.id;
                 user.user_id=element.user_id;
                 user.first_name = element.first_name;
                 user.last_name = element.last_name;
                 user.gender=element.gender;
                 user.blood_group=element.blood_group;
                 user.phone_code=element.phone_code;
                 user.phone_number = (element.phone_number==null)?element.phone_number: (element.phone_number.includes(element.phone_code)? element.phone_number :element.phone_code+element.phone_number);
         
                 user.date_of_birth=element.date_of_birth;
                 user.email = element.email;
                 user.subscription = element.subscription;
                 user.payment_status = element.payment_status;
                 user.expires_at = element.expires_at;
                 users[index] = user;
               });
       
               this.setState({ records: users });
            })
            .catch(() => { });
            
            axios
            .post(getAllUserSubscriptions, null, CONFIG)
            .then((result) => {
              console.log(result.data);
              if (result.data.status === "1") {
                let usersubscriptionlist = [];
                result.data.data.forEach((element, index) => {
                  let obj = {};
                  obj = element;
                  obj.label = element.subscription_name;
                  obj.value = element.id;
                  usersubscriptionlist[index] = obj;
                });
      
                this.setState({
                  usersubscriptionlist: usersubscriptionlist,
                });
              } else {
                alert("Subscription list empty");
              }
            })
            .catch((e) => {
              console.log(e);
              console.log("business user getbyid failed");
            });

    }
    render() {
        const {

            patientdata,
            formSuccess,
            tableSuccess,
            errors,
            loading,
        } = this.state;
        return (
            <div id="layout-wrapper">
                {/* <!-- ============================================================== --> */}
                {/* <!-- Start right Content here --> */}
                {/* <!-- ============================================================== --> */}
                <div className="main-content grayback">
                    <div className="mainpage">
                    <section className="my-head2">
                        <div className="container-fluid">
                            <AdminHeader />
                        </div>
                    </section>
                        <div className="main-box adminMainBox">
                            <div className="sidemenu pr0">
                                <AdminSideBar />
                            </div>


                            <section className="main-body hospital-profilegap checkout-tabs">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold mt-3 mb-3"> Patient List </h4>
                                    <div className="row mt-4">
                                    <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-shipping"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-shipping-tab"
                                                        >
                                                            <div className="user-notification">
                                                                <SpanField
                                                                    classname={
                                                                        0 === tableSuccess.status
                                                                            ? "text-danger user-notification"
                                                                            : "text-success user-notification"
                                                                    }
                                                                    title={tableSuccess.message}
                                                                />
                                                            </div>
                                                            <div className='ram'>
                                                            <ReactDatatable
                                                                config={this.config}
                                                                records={this.state.records}
                                                                columns={this.columns}
                                                                className="table table-bordered table-striped kas  mobile_table"
                                                            />
                                                          <Popup
                                                            show={this.state.deletePopup}
                                                             onHide={() =>
                                                             this.setState({
                                                              deletePopup: false,
                                                               })
                                                               }
                                                             onConfirm={() =>
                                                             this.deleteRecord(this.state.record)
                                                             }
                                                            title={"Confirmation"}
                                                              message={
                                                               "Are you sure you want to delete this record?"
                                                               }
                                                             />
                                                             </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                            <div className="card">
                            <div className="card-header"> <div className="card-title">  {" "}
                                    {this.state.actions === "Edit"
                                      ? "Edit"
                                      : "Edit"}{" "}
                                    Patient</div> </div>
                              <div className="card-body">
                                <div>
                                
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        0 === formSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={formSuccess.message}
                                    />
                                  </div>
                                  <div className="row">
                                  <div className="col-lg-4">
                                    <div>
                                        <label>Patient ID</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"id"}
                                          controlFunc={this.handlePatientChange.bind(this)}
                                          content={patientdata.id}
                                          placeholder={""}
                                          max={50}
                                        />
                                    
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.id}
                                      /> 
                                     
                                    </div>

                                    
                                     

                                    </div>
                                    <div className="col-lg-4">
                                    <div>
                                        <label>Patient Name</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"first_name"}
                                          controlFunc={this.handlePatientChange.bind(this)}
                                          content={patientdata.first_name+" "+patientdata.last_name}
                                          placeholder={""}
                                          max={50}
                                        />
                                    
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.first_name}
                                      /> 
                                     
                                    </div>

                                    
                                     

                                    </div>

                                    <div className="col-lg-4">
                                    <div>
                                        <label>Email</label>
                                      </div>
                                      <div className="form-group">
                                      <InputField
                                          inputType={"text"}
                                          name={"email"}
                                          controlFunc={this.handlePatientChange.bind(
                                            this,
                                            "email"
                                          )}
                                          content={patientdata.email}
                                          placeholder={""}
                                          max={50}
                                        />
                                    
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.email}
                                      /> 
                                     
                                    </div>

                                    
                                     

                                    </div>

                                    <div className="col-lg-4">
                                      <div>
                                        <label>Phone Number</label>
                                      </div>
                                      <div className="form-group form-control">
                                      <PhoneInput
                                                international
                                                defaultCountry="IN"
                                                placeholder="Enter phone number"
                                                value={patientdata.phone_number}
                                                onChange={this.handlePatientChange.bind(
                                                  this,
                                                  "phone_number"
                                                )}
                                                error={
                                                    patientdata.phone_number
                                                    ? isValidPhoneNumber(
                                                        patientdata.phone_number
                                                    )
                                                      ? undefined
                                                      : "Invalid phone number"
                                                    : "Phone number required"
                                                }
                                                // disabled={true}
                                              />
                                              <div><span>&nbsp;</span>
                                              <SpanField
                                                classname="text-danger"
                                                title={errors.phone_number}
                                              />
                                              </div>
                                              
                                      </div>
                                      

                                    </div>

                                    
                                    <div className="col-sm-4">
                                      <div>
                                        <label>Subscription</label>
                                      </div>
                                      <div className="form-group sform-control">
                                        <Select maxMenuHeight={120}
                                          className="form-control p-0"
                                          name="subscription"
                                          value={patientdata.subscription}
                                          onChange={this.handleselectChange}
                                          options={this.state.usersubscriptionlist}
                                        />
                                      </div>
                                      <SpanField
                                                classname="text-danger"
                                                title={errors.subscription}
                                              />
                                    </div>
                                    <div className="col-sm-4">
                                      <div>

                                      </div>
                                      <div>
                                        <div>
                                          <label>Expiration Date</label>
                                        </div>
                                        <div className="form-group">
                                          <DatePicker
                                            className="form-control"
                                            value={patientdata.expires_at}
                                            format="dd-MM-yyyy"
                                            onChange={(date) =>
                                              this.handleDOBChange(date)
                                            }
                                            placeholder={"Select the expiration date"}
                                            yearPlaceholder='YYYY'
                                            monthPlaceholder='MM'
                                            dayPlaceholder='DD'
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.expires_at}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-sm-4">
                                      <div>

                                      </div>
                                      <div>
                                        <div>
                                          <label>Payment Status</label>
                                        </div>
                                        <div className="form-group sform-control">
                                          <Select maxMenuHeight={120}
                                            className="form-control p-0"
                                            name="payment_status"
                                            value={patientdata.payment_status}
                                            onChange={this.handleselectChange}               
                                            options={payment_status}
                                          />
                                        </div>
                                        <SpanField
                                                classname="text-danger"
                                                title={errors.payment_status}
                                              />
                                      </div>
                                    </div>

                                    <div className="col-lg-4">
                                      </div>
                                    <div className="col-lg-4">
                                      </div>

                                    <div className="col-lg-4">
                                      </div>
                                    <div className="col-sm-12">

                                      <div className="form-group text-center mb-0 float-right mg-tp3">
                                      <button
                                          ref="btn"
                                          href="patients-register"
                                          className="signupbtn abtn btdpml btn-margin"
                                          onClick={this.updatePatient}>
                                                                                 
                                          {loading ? (
                                            <LoadingSpinner title="Update" />
                                          ) : this.state.actions === "Update" ? (
                                            "Update"
                                          ) : (
                                            "Update"
                                          )}
                                        </button>
                                        <button
                                          href="patients-register"
                                          className="signupbtn abtn btdpml"
                                          onClick={this.cancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                    <div className="card">
                                    <div className="card-header"> <div className="card-title">Export Patient List</div> </div>
                                                <div className="card-body">
                                                    <div>
                                                       
                                                     {/*    <div className="user-notification">
                                                            <SpanField
                                                                classname={
                                                                    "0" === formSuccess.status
                                                                        ? "text-danger user-notification"
                                                                        : "text-success user-notification"
                                                                }
                                                                title={formSuccess.message}
                                                            />
                                                        </div> */}
                                                        <div >
                                                            <div className="col-sm-4" >
                                                                
                                                              <PatientListExport records={this.state.records} />
                                                                
                                                               
                                                            </div>



                                                         
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                       {/*  <label className="control-label">{this.state.file ? this.state.file.name : ""}</label> */}
                                                    </div>
                                                </div>
                                             
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            
                            </section>
                                <FooterSection />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(PatientList);