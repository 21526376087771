import React, { Component } from 'react';
import { connect } from "react-redux";
import SideBar from "../components/container/layout/client/SideBar";
import Header from "../components/container/layout/client/Header";
import Spinner from "../components/UI/Spinner";
import FooterSection from "../components/UI/FooterSection";
import SpanField from "../components/UI/SpanField";
import Select from "react-select";

import axios from "axios";
import { CONFIG } from "../config/data";
import { getPrescriptionHistoryforAppt, getTimeoutbycategory,getClientEntireFamilyDetails,GetHistoryVitalsforAppt,getClientspreviousmedicalrecords  } from "../config/url.json";
import * as moment from "moment";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import DatePicker from "react-date-picker";
import { data } from 'jquery';
import { consultation_fee } from '../constants/config/data';
import Lightbox from 'react-18-image-lightbox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from '../components/UI/Dnaspinner';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}

class ClientApptConsultation extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const timeout = this.state;

        this.state = {
            timeout: timeout,
            isTimedOut: false,
            showModal: false,
            show_prescriptions: true,
            loading: false,
            records: [],
            result: [],
            selectedOption: ["0"],
            filter: "",
            error: "", errors: {
                Todate: "",
                Fromdate: "",
            },
            FamilyData: {
                member_name: "",
                user_id:"",
              },
              photoIndex: 0,
              isOpen: false
        };
        this.columns = [
            {
                key: "medicine_description",
                text: "Medicine Description",
                className: "medicine_description",
                align: "left",
                sortable: true,
            },
            {
                key: "days",
                text: "Days",
                className: "days",
                align: "left",
                sortable: true,
            },
            {
                key: "before_food",
                text: "Before Food",
                className: "before_food",
                align: "left",
                sortable: true,
                cell: (record, i) => {
                    return record.before_food === 1 ? (
                        <div className="text-center">
                            <i className="fa fa-check-circle grnbk" aria-hidden="true" />{" "}
                        </div>
                    ) : (
                        ""
                    );
                },
            },
            {
                key: "morning",
                text: "Morning",
                className: "morning",
                sortable: true,
                align: "left",
            },
            {
                key: "noon",
                text: "Noon",
                className: "noon",
                sortable: true,
                align: "left",
            },
            {
                key: "night",
                text: "Night",
                className: "night",
                sortable: true,
                align: "left",
            },
            {
                key: "total",
                text: "Total",
                className: "total",
                sortable: true,
                align: "left",
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                print: false,
            },
        };
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
      }
    }


    componentDidMount() {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
      
      axios
      .post(getClientEntireFamilyDetails,{client_id: this.props.userdata.id}, CONFIG)
      .then((FamilyDataRespons) => {
        let FamilyDataList = FamilyDataRespons.data.data;
        let FamilymemberList = [];
        FamilyDataList.forEach((element, index) => {
            FamilymemberList.push({
            value: element.user_id,
            label: element.first_name + ' ' + element.last_name,
         
          });
        });
        let selected_familymember=FamilymemberList[0];
        this.setState({ FamilymemberList,selected_familymember:selected_familymember });
            
      }).catch((e) => { });
        console.log(this.props.userdata.user_type);

        axios
  .post(getClientspreviousmedicalrecords,{client_id: this.props.userdata.id}, CONFIG)
  .then((ImageurlRespons) => {
    let ImageurldataList = ImageurlRespons.data.data;
    /* console.log(ImageurlRespons.data.status);
    if(ImageurlRespons.data.status=="0")
    {
        this.setState({ error: ImageurlRespons.data.message });
    } */
    console.log(ImageurldataList);

    let ImageurlList = [];
    ImageurldataList.forEach((element, index) => {
        ImageurlList.push({
        value: element.image_url,
        label: element.image_url,
     
      });
    });
    const stringnew=ImageurlList.map(({value})=>value)
    
    console.log(stringnew[0]);
  //  let stringpath=stringnew[0];
   // let stringone=stringnew[1];
   //console.log(ImageurlList);

  for(var i=0;i<stringnew.length;i++){
    this.imageSrc = stringnew[i];
    console.log(this.imageSrc);
    
    }
    this.setState({ ImageurlList,stringnew });
  
  }).catch((e) => { });

        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        timeout: data.data.timeout

                    });
            })
            .catch((e) => { });
        this.setState({ loading: true, filter: "month" });
       // this.getReport('month');

        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          const postData1 = { client_id: this.props.userdata.id };
          axios
            .post(GetHistoryVitalsforAppt, postData1, CONFIG)
            .then((res) => {
              let data = res.data.consultantHistory || res.data.consultantHistory;
              console.log(data);
              console.log(res.data);
              console.log(data.with_in_30[0].height);
              console.log(data.with_in_30[0].weight);
              console.log(data.with_in_30[0].bp);
              console.log(data.with_in_30[0].pulse);
              console.log(data.with_in_30[0].temperature);
              console.log(data.with_in_30[0].bmi);
    
              // if (res.data.status !== "1" || !data || !data[0]) {
              //   return;
              // }
              if (res.data.status === "1") {
                this.setState({
                  with_in_week: data.with_in_week,
                  with_in_30: data.with_in_30,
                  with_in_3_months: data.with_in_3_months,
                  with_in_6_months: data.with_in_6_months,
                  with_in_year: data.with_in_year,
                  with_in_2_years: data.with_in_2_years,
                  Historyheight: data.with_in_week.length ? data.with_in_week[0].height
                   : data.with_in_30.length ? data.with_in_30[0].height
                   : data.with_in_3_months.length ? data.with_in_3_months[0].height
                   : data.with_in_6_months.length ? data.with_in_6_months[0].height
                   : data.with_in_year.length ? data.with_in_year[0].height
                   : data.with_in_2_years.length ? data.with_in_2_years[0].height
                    : [],
                    Historyweight: data.with_in_week.length ? data.with_in_week[0].weight
                   : data.with_in_30.length ? data.with_in_30[0].weight
                   : data.with_in_3_months.length ? data.with_in_3_months[0].weight
                   : data.with_in_6_months.length ? data.with_in_6_months[0].weight
                   : data.with_in_year.length ? data.with_in_year[0].weight
                   : data.with_in_2_years.length ? data.with_in_2_years[0].weight
                    : [],
    
                    Historytemperature: data.with_in_week.length ? data.with_in_week[0].temperature
                    : data.with_in_30.length ? data.with_in_30[0].temperature
                    : data.with_in_3_months.length ? data.with_in_3_months[0].temperature
                    : data.with_in_6_months.length ? data.with_in_6_months[0].temperature
                    : data.with_in_year.length ? data.with_in_year[0].temperature
                    : data.with_in_2_years.length ? data.with_in_2_years[0].temperature
                     : [],
                     Historybmi: data.with_in_week.length ? data.with_in_week[0].bmi
                   : data.with_in_30.length ? data.with_in_30[0].bmi
                   : data.with_in_3_months.length ? data.with_in_3_months[0].bmi
                   : data.with_in_6_months.length ? data.with_in_6_months[0].bmi
                   : data.with_in_year.length ? data.with_in_year[0].bmi
                   : data.with_in_2_years.length ? data.with_in_2_years[0].bmi
                    : [],
                    Historybp: data.with_in_week.length ? data.with_in_week[0].bp
                   : data.with_in_30.length ? data.with_in_30[0].bp
                   : data.with_in_3_months.length ? data.with_in_3_months[0].bp
                   : data.with_in_6_months.length ? data.with_in_6_months[0].bp
                   : data.with_in_year.length ? data.with_in_year[0].bp
                   : data.with_in_2_years.length ? data.with_in_2_years[0].bp
                    : [],
                    Historypulse: data.with_in_week.length ? data.with_in_week[0].pulse
                   : data.with_in_30.length ? data.with_in_30[0].pulse
                   : data.with_in_3_months.length ? data.with_in_3_months[0].pulse
                   : data.with_in_6_months.length ? data.with_in_6_months[0].pulse
                   : data.with_in_year.length ? data.with_in_year[0].pulse
                   : data.with_in_2_years.length ? data.with_in_2_years[0].pulse
                    : [],
                });
              } else {
                this.setState({ error: res.data.message });
              }
            })
            .catch((e) => { });
            let postData2 = { client_id: this.props.userdata.id, filter: "month" };
           
            axios
                .post(getPrescriptionHistoryforAppt, postData2, CONFIG)
                .then((res) => {
                    let data = res.data.consultantHistory || res.data.consultantHistory;
                    console.log(data);
                    console.log(res.data);
                    if (res.data.status === "1") {
                        this.setState({
                            result: data,
    
                            records: (data.length>0) ? data : [],
                           
                            show_prescriptions: true,
                            selectedOption: [],
                            loading: false
    
                        });
                    } else {
                        this.setState({ error: res.data.message, loading: false });
                    }
                })
                .catch((e) => { });
          
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // const postData1 = { client_id: this.props.userdata.id };
        // axios
        //     .post(getPrescriptionHistory, postData1, CONFIG)
        //     .then((res) => {
        //         let data = res.data.consultantHistory || res.data.consultantHistory;
        //         console.log(data);
        //         console.log(res.data);
        //         // if (res.data.status !== "1" || !data || !data[0]) {
        //         //   return;
        //         // }
        //         if (res.data.status === "1") {
        //             this.setState({
        //                 with_in_week: data.with_in_week,
        //                 with_in_30: data.with_in_30,
        //                 with_in_3_months: data.with_in_3_months,
        //                 with_in_6_months: data.with_in_6_months,
        //                 with_in_year: data.with_in_year,
        //                 with_in_2_years: data.with_in_2_years,
        //                 records: data.with_in_week.length
        //                     ? data.with_in_week[0]
        //                     : [],
        //                 show_prescriptions: data.with_in_week.length ? data.with_in_week[0].show_prescriptions : true,
        //             });
        //         } else {
        //             this.setState({ error: res.data.message });
        //         }
        //     })
        //     .catch((e) => { });
    }
    handleselectChange = (element, i) => {
      /*   let data = this.state.FamilyData;
        data[i.name] = element;
        this.setState({ FamilyData: data }); */
        let newdata = this.state.selected_familymember;
        newdata = element;
        this.setState({ selected_familymember: newdata });
       this.setState({Historyheight:[],Historyweight:[],Historytemperature:[],Historybmi:[],Historybp:[],Historypulse:[]});
        console.log(element);
     
        this.setState({ loading: true });
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${this.props.access_token}`;
          
            let postData1 = { client_id: element.value, filter: this.state.filter, start_date: this.state.Fromdate, end_date: this.state.Todate };
           
            axios
                .post(getPrescriptionHistoryforAppt, postData1, CONFIG)
                .then((res) => {
                    let data = res.data.consultantHistory || res.data.consultantHistory;
                    console.log(data);
                    console.log(res.data);
                    if (res.data.status === "1") {
                        this.setState({
                            result: data,
    
                            records: (data.length>0) ? data : [],
                           
                            show_prescriptions: true,
                            selectedOption: [],
                            loading: false
    
                        });
                    } else {
                        this.setState({ error: res.data.message, loading: false });
                    }
                })
                .catch((e) => { });
                axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${this.props.access_token}`;
                  const postData2 = { client_id: element.value };
                  axios
                    .post(GetHistoryVitalsforAppt, postData2, CONFIG)
                    .then((res) => {
                      let data = res.data.consultantHistory || res.data.consultantHistory;
                      console.log(data);
                      console.log(res.data);
                      console.log(data.with_in_30[0].height);
                      console.log(data.with_in_30[0].weight);
                      console.log(data.with_in_30[0].bp);
                      console.log(data.with_in_30[0].pulse);
                      console.log(data.with_in_30[0].temperature);
                      console.log(data.with_in_30[0].bmi);
            
                      // if (res.data.status !== "1" || !data || !data[0]) {
                      //   return;
                      // }
                      if (res.data.status === "1") {
                        this.setState({
                          with_in_week: data.with_in_week,
                          with_in_30: data.with_in_30,
                          with_in_3_months: data.with_in_3_months,
                          with_in_6_months: data.with_in_6_months,
                          with_in_year: data.with_in_year,
                          with_in_2_years: data.with_in_2_years,
                          Historyheight: data.with_in_week.length ? data.with_in_week[0].height
                           : data.with_in_30.length ? data.with_in_30[0].height
                           : data.with_in_3_months.length ? data.with_in_3_months[0].height
                           : data.with_in_6_months.length ? data.with_in_6_months[0].height
                           : data.with_in_year.length ? data.with_in_year[0].height
                           : data.with_in_2_years.length ? data.with_in_2_years[0].height
                            : [],
                            Historyweight: data.with_in_week.length ? data.with_in_week[0].weight
                           : data.with_in_30.length ? data.with_in_30[0].weight
                           : data.with_in_3_months.length ? data.with_in_3_months[0].weight
                           : data.with_in_6_months.length ? data.with_in_6_months[0].weight
                           : data.with_in_year.length ? data.with_in_year[0].weight
                           : data.with_in_2_years.length ? data.with_in_2_years[0].weight
                            : [],
            
                            Historytemperature: data.with_in_week.length ? data.with_in_week[0].temperature
                            : data.with_in_30.length ? data.with_in_30[0].temperature
                            : data.with_in_3_months.length ? data.with_in_3_months[0].temperature
                            : data.with_in_6_months.length ? data.with_in_6_months[0].temperature
                            : data.with_in_year.length ? data.with_in_year[0].temperature
                            : data.with_in_2_years.length ? data.with_in_2_years[0].temperature
                             : [],
                             Historybmi: data.with_in_week.length ? data.with_in_week[0].bmi
                           : data.with_in_30.length ? data.with_in_30[0].bmi
                           : data.with_in_3_months.length ? data.with_in_3_months[0].bmi
                           : data.with_in_6_months.length ? data.with_in_6_months[0].bmi
                           : data.with_in_year.length ? data.with_in_year[0].bmi
                           : data.with_in_2_years.length ? data.with_in_2_years[0].bmi
                            : [],
                            Historybp: data.with_in_week.length ? data.with_in_week[0].bp
                           : data.with_in_30.length ? data.with_in_30[0].bp
                           : data.with_in_3_months.length ? data.with_in_3_months[0].bp
                           : data.with_in_6_months.length ? data.with_in_6_months[0].bp
                           : data.with_in_year.length ? data.with_in_year[0].bp
                           : data.with_in_2_years.length ? data.with_in_2_years[0].bp
                            : [],
                            Historypulse: data.with_in_week.length ? data.with_in_week[0].pulse
                           : data.with_in_30.length ? data.with_in_30[0].pulse
                           : data.with_in_3_months.length ? data.with_in_3_months[0].pulse
                           : data.with_in_6_months.length ? data.with_in_6_months[0].pulse
                           : data.with_in_year.length ? data.with_in_year[0].pulse
                           : data.with_in_2_years.length ? data.with_in_2_years[0].pulse
                            : [],
                        });
                      } else {
                        this.setState({ error: res.data.message });
                      }
                    })
                    .catch((e) => { });
        
     //   this.setState({ selected_familymember }); 


     this.setState({stringnew:[]});
  axios
  .post(getClientspreviousmedicalrecords,postData2, CONFIG)
  .then((ImageurlRespons) => {
    let ImageurldataList = ImageurlRespons.data.data;
    if(ImageurlRespons.data.status==='0')
    {
        this.setState({ errorMessage: ImageurlRespons.data.message });
        toast.error(ImageurlRespons.data.message , {
            className: "toast-error"
          });
          
   //  console.log(ImageurlRespons.data.message);
    }else{
    console.log(ImageurldataList);
    let ImageurlList = [];
    ImageurldataList.forEach((element, index) => {
        ImageurlList.push({
        value: element.image_url,
        label: element.image_url,
     
      });
    });
    const stringnew=ImageurlList.map(({value})=>value)
     
  for(var i=0;i<stringnew.length;i++){
    this.imageSrc = stringnew[i];
    console.log(this.imageSrc);
    
    }
    this.setState({ ImageurlList,stringnew,errorMessage:"" });
}
  }).catch((e) => { });
  

      }
    handlePrescriptionChange = (index, event) => {

        console.log(index);
        // console.log(event);
        // console.log(event.target.checked);
        let {

            // selectedOption,
            result,
            show_prescriptions,
            consultation_fee,
            business_name,
            first_name,
            last_name,
            created_at,
            pharmacy_details,
            records,
        } = this.state;

        records = result[index];
        console.log(records)
        show_prescriptions = result[index].show_prescriptions;
        consultation_fee=result[index].consultation_fee;
        business_name=result[index].business_name;
        first_name=result[index].first_name;
        last_name=result[index].last_name;
        created_at=result[index].created_at;
        pharmacy_details=result[index].pharmacy_details;
        console.log(records);
        this.setState({
            selectedOption: event.target.checked ? [index] : [],
            records: records ? records : [],
            show_prescriptions: show_prescriptions,
            consultation_fee:consultation_fee

        });
    };
    handlefilterSelect = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
        console.log(value);
        this.getReport(value, "", "");
    }
    handleFromChange = (date) => {
        const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        this.setState({ Fromdate: offsetDate });

        this.getReport("custom", offsetDate, this.state.Todate);
    };
    handleToChange = (date) => {
        const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        this.setState({ Todate: offsetDate });

        this.getReport("custom", this.state.Fromdate, offsetDate);
    };

    getReport = (filter, from, to) => {
        console.log(filter);
        this.setState({ loading: true });
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        // let {Todate,Fromdate} = this.state;
        console.log(from)
        console.log(to)
        // if(filter == 'custom' && Todate != null && Fromdate != null ){
        // postData1 = { client_id: this.props.userdata.id, filter: "month" };
        // } else {
        let postData1 = { client_id: this.state.selected_familymember.value, filter: filter, start_date: from, end_date: to };
        // }

        axios
            .post(getPrescriptionHistoryforAppt, postData1, CONFIG)
            .then((res) => {
                let data = res.data.consultantHistory || res.data.consultantHistory;
                console.log(data);
                console.log(res.data);
                // if (res.data.status !== "1" || !data || !data[0]) {
                //   return;
                // }
                if (res.data.status === "1") {
                    console.log(data.length);
                    this.setState({
                        result: data,

                        records: (data.length) ? data : [],
                        show_prescriptions: true,
                        selectedOption: [],
                        loading: false

                    });
                   
                } else {
                    this.setState({ error: res.data.message, loading: false });
                }
            })
            .catch((e) => { });
    }
    refreshData = () => {
        const FamilyData = this.state.FamilyData;
        FamilyData.member_name="";
        this.setState({ FamilyData: FamilyData });
       
        this.setState({ loading: true, filter: "month" });
        this.getReport('month');
       
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          const postData1 = { client_id: this.props.userdata.id };
          axios
            .post(GetHistoryVitalsforAppt, postData1, CONFIG)
            .then((res) => {
              let data = res.data.consultantHistory || res.data.consultantHistory;
              console.log(data);
              console.log(res.data);
              console.log(data.with_in_30[0].height);
              console.log(data.with_in_30[0].weight);
              console.log(data.with_in_30[0].bp);
              console.log(data.with_in_30[0].pulse);
              console.log(data.with_in_30[0].temperature);
              console.log(data.with_in_30[0].bmi);
    
              // if (res.data.status !== "1" || !data || !data[0]) {
              //   return;
              // }
              if (res.data.status === "1") {
                this.setState({
                  with_in_week: data.with_in_week,
                  with_in_30: data.with_in_30,
                  with_in_3_months: data.with_in_3_months,
                  with_in_6_months: data.with_in_6_months,
                  with_in_year: data.with_in_year,
                  with_in_2_years: data.with_in_2_years,
                  Historyheight: data.with_in_week.length ? data.with_in_week[0].height
                   : data.with_in_30.length ? data.with_in_30[0].height
                   : data.with_in_3_months.length ? data.with_in_3_months[0].height
                   : data.with_in_6_months.length ? data.with_in_6_months[0].height
                   : data.with_in_year.length ? data.with_in_year[0].height
                   : data.with_in_2_years.length ? data.with_in_2_years[0].height
                    : [],
                    Historyweight: data.with_in_week.length ? data.with_in_week[0].weight
                   : data.with_in_30.length ? data.with_in_30[0].weight
                   : data.with_in_3_months.length ? data.with_in_3_months[0].weight
                   : data.with_in_6_months.length ? data.with_in_6_months[0].weight
                   : data.with_in_year.length ? data.with_in_year[0].weight
                   : data.with_in_2_years.length ? data.with_in_2_years[0].weight
                    : [],
    
                    Historytemperature: data.with_in_week.length ? data.with_in_week[0].temperature
                    : data.with_in_30.length ? data.with_in_30[0].temperature
                    : data.with_in_3_months.length ? data.with_in_3_months[0].temperature
                    : data.with_in_6_months.length ? data.with_in_6_months[0].temperature
                    : data.with_in_year.length ? data.with_in_year[0].temperature
                    : data.with_in_2_years.length ? data.with_in_2_years[0].temperature
                     : [],
                     Historybmi: data.with_in_week.length ? data.with_in_week[0].bmi
                   : data.with_in_30.length ? data.with_in_30[0].bmi
                   : data.with_in_3_months.length ? data.with_in_3_months[0].bmi
                   : data.with_in_6_months.length ? data.with_in_6_months[0].bmi
                   : data.with_in_year.length ? data.with_in_year[0].bmi
                   : data.with_in_2_years.length ? data.with_in_2_years[0].bmi
                    : [],
                    Historybp: data.with_in_week.length ? data.with_in_week[0].bp
                   : data.with_in_30.length ? data.with_in_30[0].bp
                   : data.with_in_3_months.length ? data.with_in_3_months[0].bp
                   : data.with_in_6_months.length ? data.with_in_6_months[0].bp
                   : data.with_in_year.length ? data.with_in_year[0].bp
                   : data.with_in_2_years.length ? data.with_in_2_years[0].bp
                    : [],
                    Historypulse: data.with_in_week.length ? data.with_in_week[0].pulse
                   : data.with_in_30.length ? data.with_in_30[0].pulse
                   : data.with_in_3_months.length ? data.with_in_3_months[0].pulse
                   : data.with_in_6_months.length ? data.with_in_6_months[0].pulse
                   : data.with_in_year.length ? data.with_in_year[0].pulse
                   : data.with_in_2_years.length ? data.with_in_2_years[0].pulse
                    : [],
                });
              } else {
                this.setState({ error: res.data.message });
              }
            })
            .catch((e) => { });
            axios
            .post(getClientEntireFamilyDetails,{client_id: this.props.userdata.id}, CONFIG)
            .then((FamilyDataRespons) => {
              let FamilyDataList = FamilyDataRespons.data.data;
              let FamilymemberList = [];
              FamilyDataList.forEach((element, index) => {
                  FamilymemberList.push({
                  value: element.user_id,
                  label: element.first_name + ' ' + element.last_name,
               
                });
               
              });
    this.setState({ selected_familymember: FamilymemberList[0]});
            })
    };
    clear=()=>
    {
        this.state.Fromdate="";
        this.state.Todate="";
       this.setState({result:[]});
    }
    render() {
        const {
            loading,
            result,
            selectedOption,
            show_prescriptions,
            records,
            timeout, errors,
            family_data,
            FamilymemberList,
            FamilyData,
            // error,
            Historyheight,Historyweight,Historytemperature,Historybmi,Historybp,Historypulse,Historyfamilyheight,
            selected_familymember,
            stringnew,
            photoIndex,
            isOpen,
        } = this.state;
        // console.log(with_in_week.length);
        return (
            <div id="layout-wrapper">
              {/*   <Spinner loading={loading} /> */}
              {loading && <Dnaspinner />}
                <div className="main-content bacfot">
                    <div className="mainpage">
                        <Header user_id={this.props.userdata.id} />
                        <div className="main-box">
                            <div className="sidemenu">
                                <SideBar />
                            </div>
                            <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />
                                 
                            <section className="main-body hospital-profilegap">
                                <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                />

                                <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={timeout} />

                                <div className="container-fluid">
                                    <div className="checkout-tabs">
                                        <div className="row">

                                            <div className="col-sm-12">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                <h4 className="font-weight-bold mb-5"> Consultation summary</h4>
                                                <div className='col-sm-6 mt-3'>
                                                <button className=" btn btn-primary float-right ml-2 mt-2" onClick={this.refreshData}>Refresh
                                  <i className="fa fa-refresh ml-1">
                                  </i></button>
                                                <Select maxMenuHeight={120}
                                                
                  className="form-control mt-2 float-right queuedropdown css-2b097c-container w-50"
                  placeholder="Select Family Members..."
                  name="selected_familymember"
                  onChange={
                    this.handleselectChange
                  }
                  value={selected_familymember}
                  options={FamilymemberList}
                   />
                    
                                                           {/* <div className="col-sm-6 text-right ">
                                  <button className=" btn btn-primary" onClick={this.refreshData}>Refresh
                                  <i className="fa fa-refresh ml-1">
                                  </i></button>
    </div> */}
                   </div>
                                                </div>
        
                                                <div className="row">
                                                
                  
                                                    <div className="col-sm-8 pr0">
                                                        {show_prescriptions ? (
                                                            <div className="row">

                                                                <div className="col-sm-6 pr0">
                                                                    <div className="card">
                                                                    <div class="card-header"> <div class="card-title">Assessment</div> </div>
                                                                        <div className="card-body  c-page-cardheight">
                                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                                <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-sm-12F">
                                                                                            
                                                                                            <p><strong>Diagnosis:</strong> {records.reason_for_visit ? records.reason_for_visit.map(e => e.label).join(', ') : ""}</p>
                                                                                            <p><strong>Reason for visit:</strong> {records.other_comments}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                 {/*    <div className="card">
                                                                    <div class="card-header"> <div class="card-title">Prescriptions</div> </div>
                                                                        <div className="card-body  c-page-cardheight">
                                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                                <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                         
                                                                                            {records.medicine_description ? records.medicine_description.map((element, index) => {
                                                                                                return (
                                                                                                    <li key={index} className="">
                                                                                                        <strong>{element.medicine_description}</strong>
                                                                                                     
                                                                                                    </li>)
                                                                                            }) : ""} 
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="card">
                                                                    <div class="card-header"> <div class="card-title">Old Medical Images</div> </div>
                                                                        <div className="card-body  c-page-cardheight">
                                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                                <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                        {/* <div className="form-group multi-preview">
                                                                                         {(stringnew || []).map(image_url => (
                                                                                            <img src={image_url} alt="..." />
                                                                                        ))}
                                                                                </div> */}
                                                                                
                                                                                        <div>
        <button type="button" onClick={() => this.setState({ isOpen: true })}>
          Click to Open Medical Images
        </button>

        {isOpen && (
          <Lightbox
            mainSrc={stringnew[photoIndex]}
            nextSrc={stringnew[(photoIndex + 1) % stringnew.length]}
            prevSrc={stringnew[(photoIndex + stringnew.length - 1) % stringnew.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + stringnew.length - 1) % stringnew.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % stringnew.length,
              })
            }
          />
        )}
      </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 pl0">
                                                                    <div className="card">
                                                                    <div class="card-header"> <div class="card-title">Vital Details</div> </div>
                                                                        <div className="card-body  c-page-cardheight pb-15">
                                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                                <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                                                                 
                                                                                    <div className="rowGrid">

                                                                                            <div className="rowGridList">
                                                                                                <div class="cpag">
                                                                                                    <div class="card vitals mr-1">
                                                                                                        <div class="card-body pt ">
                                                                                                            <div className="text-center p-1 s-118">
                                                                                                                <h5 className="border-0 small mb-0 titleFlex align-items-center justify-content-between font-weight-bolder">Height
                                                                                                                    <img class="ml-19"
                                                                                                                        alt="" src={require("../assets/images/vitaldetails1.png")} />
                                                                                                                </h5>

                                                                                                            </div>

                                                                                                            <div class="input-group input-group-sm mb-0">
                                                                                                                <input type="text" class="dis-inlnflx form-control" disabled name="height" value={(records.height==null)?Historyheight:records.height} />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span class="input-group-text">cm </span>


                                                                                                                </div></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                            </div>
                                                                                            <div className="rowGridList">
                                                                                            <div class="cpag ">
                                                                                                    <div class="card vitals">
                                                                                                        <div class="card-body pt">
                                                                                                            <div className="text-center p-1 s-118">
                                                                                                                <h5 className="border-0 small mb-0 titleFlex align-items-center justify-content-between font-weight-bolder">Weight
                                                                                                                    <img class="ml-19"
                                                                                                                        alt="" src={require("../assets/images/vitaldetails2.png")} />


                                                                                                                </h5>
                                                                                                            </div>
                                                                                                            <div className="input-group input-group-sm mb-0">
                                                                                                                <input type="text" class="dis-inlnflx form-control" disabled name="weight" value={(records.weight==null)?Historyweight:records.weight} />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span class="input-group-text">Kg </span>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                           
                                                                                            <div className="rowGridList ">
                                                                                                <div class="cpag">
                                                                                                    <div class="card vitals mr-1">
                                                                                                        <div class="card-body  pt">
                                                                                                            <div className="text-center p-1 pr-0 s-118">
                                                                                                                <h5 className="border-0 small mb-0 titleFlex align-items-center justify-content-between font-weight-bolder">Temp
                                                                                                                    <img alt="" src={require("../assets/images/vitaldetails3.png")} />

                                                                                                                </h5>
                                                                                                            </div>
                                                                                                            <div class="input-group input-group-sm mb-0">
                                                                                                                <input type="text" class="dis-inlnflx form-control" disabled name="temperature" value={(records.temperature==null)?Historytemperature:records.temperature} />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span class="input-group-text">°F </span>

                                                                                                                </div></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                               
                                                                                            </div>
                                                                                            <div className="rowGridList">
                                                                                            <div class="cpag">
                                                                                                    <div class="card vitals">
                                                                                                        <div class="card-body pt">
                                                                                                            <div className="text-center p-1 s-118">
                                                                                                                <h5 className="border-0 small mb-0 titleFlex align-items-center justify-content-between font-weight-bolder">BMI
                                                                                                                    <img class="ml-26" alt=""
                                                                                                                        src={require("../assets/images/bmi_icon.png")} />

                                                                                                                </h5>
                                                                                                            </div>
                                                                                                            <div class="input-group input-group-sm mb-0">
                                                                                                                <input class="form-control" name="bmi" disabled type="text" value={(records.bmi==null)?Historybmi:records.bmi} />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span class="input-group-text" id="basic-addon2">bmi</span>

                                                                                                                </div></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                            <div className="rowGridList">
                                                                                                <div class="cpag">
                                                                                                    <div class="card vitals mr-1">
                                                                                                        <div class="card-body pt">
                                                                                                            <div className="text-center p-1 s-118">
                                                                                                                <h5 className="border-0 small mb-0 titleFlex align-items-center justify-content-between font-weight-bolder">BP
                                                                                                                    <img class="ml-26"
                                                                                                                        alt="" src={require("../assets/images/vitaldetails4.png")} />
                                                                                                                </h5>
                                                                                                            </div>
                                                                                                            <div class="input-group input-group-sm mb-0">
                                                                                                                <input type="text" class="dis-inlnflx form-control" disabled name="bp" value={(records.bp==null)?Historybp:records.bp} />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span class="input-group-text">mmHg </span>
                                                                                                                </div></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                             
                                                                                            </div>
                                                                                            <div className="rowGridList">
                                                                                            <div class="cpag">
                                                                                                    <div class="card vitals">
                                                                                                        <div class="card-body pt">
                                                                                                            <div className="text-center p-1 s-118">
                                                                                                                <h5 className="border-0 small mb-0 titleFlex align-items-center justify-content-between font-weight-bolder">Pulse
                                                                                                                    <img class="m-22"
                                                                                                                        alt="" src={require("../assets/images/vitaldetails5.png")} />

                                                                                                                </h5>
                                                                                                            </div>
                                                                                                            <div class="input-group input-group-sm mb-0">
                                                                                                                <input type="text" class="dis-inlnflx form-control" disabled name="pulse" value={(records.pulse==null)?Historypulse:records.pulse} />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span class="input-group-text">per min</span>
                                                                                                                </div></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                    <div class="card-header"> <div class="card-title">Others</div> </div>
                                                                        <div className="card-body c-page-cardheight">
                                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                                <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-sm-12F">
                                                                                           
                                                                                           <p>
                                                                                            <label><strong>Hospital Name: </strong>{records.business_name}</label>
                                                                                            </p>
                                                                                            <p>
                                                                                                <label> <strong>Doctor Name: </strong>
                                                                                                {records.first_name}&nbsp;{records.last_name}
                                                                                                </label>
                                                                                                </p>
                                                                                            <p>
                                                                                                <label> <strong>Date of Visit: </strong>
                                                                                                {records.created_at}
                                                                                                </label>
                                                                                                </p>
                                                                                            <p>
                                                                                                <label> <strong>Follow up Days: </strong>
                                                                                                {records.follow_up_details ? records.follow_up_details : ""}
                                                                                                </label>
                                                                                                </p>
                                                                                            <p>
                                                                                                <label> <strong>Amount: </strong>
                                                                                                {records.consultation_fee}
                                                                                                </label>
                                                                                                </p>
                                                                                           
                                                                                          {/*   <p><label>Pharmacy Name: <strong>{records.pharmacy_details}</strong></label></p> */}
                                                                                        </div>
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>) :
                                                            (<div className="row">
                                                                <div className="col-sm-6 pr0">
                                                                    <div className="card">
                                                                        <div className="card-body  c-page-14git m395">
                                                                            To view this Consultation Summary, Please subscribe any of our Plan <a href="/showsubscription" >Click here to subscribe</a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>)}
                                                    </div>
                                                    <div class="col-sm-4 p10 ">


                                                        <div className="card m704">
                                                        <div class="card-header"> <div class="card-title">History</div> </div>
                                                            <div className="card-body resultscrollnew">
                                                                <div className="tab-pane fade show active">
                                                                 
                                                                </div>
                                                                <div id="accordion">
                                                                    <div>

                                                                        <div
                                                                            id="s1"
                                                                            className="collapse show "
                                                                            data-parent="#accordion"
                                                                        >
                                                                            <div className="card-body ese ">
                                                                                <div className="row ">
                                                                                    <div className="col-sm-6">
                                                                                        <div className="form-check fltx">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                id="Monthly"
                                                                                                name="filter"
                                                                                                checked={this.state.filter === "month"}
                                                                                                value="month"
                                                                                                onChange={this.handlefilterSelect}

                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="Monthly"
                                                                                            >
                                                                                                Monthly
                                                                                            </label>
                                                                                        </div>

                                                                                       
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                    <div className="form-check fltx">

                                                                                        <input
                                                                                            checked={this.state.filter === "custom"}
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            id="Custom"
                                                                                            value="custom"
                                                                                            name="filter"

                                                                                            onChange={this.handlefilterSelect}


                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="Custom"
                                                                                        >
                                                                                            Custom
                                                                                        </label>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {(this.state.filter === "custom") ?
                                                                                    (<div className="row">
                                                                                        <div className="col-lg-12">
                                                                                            <div>
                                                                                                <label>From Date</label>
                                                                                            </div>
                                                                                            <div className="form-group datepickerdiv">
                                                                                                <DatePicker
                                                                                                    className="form-control"
                                                                                                    value={this.state.Fromdate}
                                                                                                    format="dd-MM-yyyy"
                                                                                                    onChange={(date) =>
                                                                                                        this.handleFromChange(date)
                                                                                                    }
                                                                                                    placeholder={"Select the From date"}
                                                                                                    yearPlaceholder='YYYY'
                                                                                                    monthPlaceholder='MM'
                                                                                                    dayPlaceholder='DD'
                                                                                                />
                                                                                            </div>
                                                                                            <SpanField
                                                                                                classname="text-danger"
                                                                                                title={errors.Fromdate}
                                                                                            />
                                                                                        </div>

                                                                                        <div className="col-lg-12">
                                                                                            <div>
                                                                                                <div>
                                                                                                    <label>To Date</label>
                                                                                                </div>
                                                                                                <div className="form-group datepickerdiv">
                                                                                                    <DatePicker
                                                                                                        className="form-control"
                                                                                                        value={this.state.Todate}
                                                                                                        format="dd-MM-yyyy"
                                                                                                        onChange={(date) =>
                                                                                                            this.handleToChange(date)
                                                                                                        }
                                                                                                        yearPlaceholder='YYYY'
                                                                                                        monthPlaceholder='MM'
                                                                                                        dayPlaceholder='DD'
                                                                                                        placeholder={"Select the From date"}
                                                                                                    />
                                                                                                </div>
                                                                                                <SpanField
                                                                                                    classname="text-danger"
                                                                                                    title={errors.Todate}
                                                                                                />
                                                                                                <button className=" btn btn-primary float-right" onClick={this.clear}>Clear
                                  <i className="fa ml-1">
                                  </i></button>
                                                                                            </div>




                                                                                        </div>

                                                                                    </div>) : ""
                                                                                }
                                                                                {result.length
                                                                                    ? result.map((history, index) => {
                                                                                        return (
                                                                                          
                                                                                            <div className="custom-control custom-checkbox notificatincls">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="custom-control-input"
                                                                                                    id={"with_in_week" + index}
                                                                                                    checked={
                                                                                                        selectedOption.indexOf(
                                                                                                            index
                                                                                                        ) > -1
                                                                                                    }
                                                                                                    onChange={this.handlePrescriptionChange.bind(
                                                                                                        this,

                                                                                                        index
                                                                                                    )}
                                                                                                />
                                                                                                <label
                                                                                                    className="custom-control-label"
                                                                                                    htmlFor={
                                                                                                        "with_in_week" + index
                                                                                                    }
                                                                                                ><strong>
                                                                                                        {moment(
                                                                                                            history.created_at
                                                                                                        ).format("DD MMM")}
                                                                                                        -
                                                                                                        {history.other_comments
                                                                                                            ? history
                                                                                                                .other_comments
                                                                                                            : ""}
                                                                                                    </strong> </label>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                    : (<div className="custom-control custom-checkbox notificatincls pl-0">No record found</div>)}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <Footerad /> */}
                                </div>

                        

                            </section>
                            <FooterSection />

                        </div>


                    </div>

                </div>
            </div>
        );

    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ClientApptConsultation);