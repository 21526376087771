import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../../components/UI/FooterSection";
import SideBar from "../../components/container/SideBar";
// import Header from "../../components/container/layout/business/Header";
import ConsultHeader from "../../components/container/layout/ConsultHeader";
// import Prescription from "../../views/Prescription";
import FollowupDetails from "../../views/FollowupDetails";
import ReferralDoctor from "../../views/ReferralDoctor";
import ConsultationHistory from "../../views/ConsultationHistory";
import * as moment from "moment";

import axios from "axios";
import {
  getMasterSymptoms,
  getSymptomByCategory,
  consultDoctor,
  getAllHospitalDrug,
  getAllActiveingredientsforhospital,
  getAllgenericnameforhospital,
  admitPatient,
  getInpatientconsultingDetails,
  getAllgenericnameforactiveingredients,
  getAllMasterDisease,
  getAllSymptom,
  getinpatientdetails,
   //prescriptions code
  searchDrug,
  getAllMasterDrug,
  getAllDrugTypes,
  getAllDrugCategories,
  getPrescription,
  // updatequeueURL,
  getQEntryById,
  updatePrescription,
  UpdateQueueForCheckOut,
  BaseUrl,sendemail,sendsms
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import Spinner from "../../components/UI/Spinner";
import SpanField from "../../components/UI/SpanField";
import InputField from "../../components/UI/InputField";
import TextArea from "../../components/UI/TextArea";
import Select from "react-select";

// prescriptions code
import ReactDatatable from "@ashvin27/react-datatable";
import Footerad from "../../components/UI/Footerad";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dnaspinner from "../../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}

class InPatientPrescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userdata: {},
      loading: false,
      diseaseList: [],
      symptomCategory: [],
      symptoms: [],
      cloneSymptoms: [],
      consult: {
        client_id: "",
        business_id: "",
        reason_for_visit: [],
        
        other_comments:"",
        medicine_history:[],
      },
      error:{
        other_comments:"",
      },
      successMessage: "",
      errorMessage: "",
      PrescriptionerrorMessage: "",
      PrescriptionsuccessMessage: "",
      selectedSymptoms: [],
      symptomlist: [],
      activeIndex: '',
      activeTab: "Prescription",
      inpatientdetails: {
        medicine_history:[]
      },

      // prescriptions codes
      consultData: null,
      records: [],
      searchRecords: [],
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        drug_unit: "",
        active_ingredients: "",
        manufacturer: "",
      },
      drugUnits: [
        {
          value: "ea",
          label: "ea",
        },
        {
          value: "mg",
          label: "mg",
        },
      ],
      drugList: [],
      ActiveIngredientList:[],
      GenericNameList:[],
      drugTypes: [],
      drugCategories: [],
      // successMessage: "",
      // errorMessage: "",
      QueuesuccessMsg: "",

    };

    this.columns = [
    /*   {
        key: "drug_id",
        text: "drug id",
        className: "drug_id",
        align: "left",
        sortable: true,
      }, */
    /*   {
        key: "drug_id",
        text: "drug_id",
        className: "sform-control",
        sortable: false,
        align: "left",
        width: "25%",
        cell: (drug_id) => {
          return this.generatetagforcolumn(drug_id);
        }, 
      }, */
      {
        key: "medicine_description",
        text: "Medicine ",
        className: "medicine_description",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "medicine_id",
      //   text: "medicine id",
      //   className: "hide",
      //   TrOnlyClassName: "hide",
      //   align: "left",
      //   sortable: true,
      // },
      // {
      //   key: "days",
      //   text: "Days",
      //   className: "days",
      //   align: "left",
      //   sortable: true,
      //   width: 100,
      //   cell: (record, i) => {
      //     return (
      //       <div class="form-group dayscount">
      //         <div
      //           class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
      //           style={{ minWidth: "112px" }}
      //         >
      //           <span class="input-group-btn input-group-prepend">
      //             <button
      //               class="btn btn-primary bootstrap-touchspin-down"
      //               type="button"
      //               onClick={() => this.incOrDecValue(i, "days", true)}
      //             >
      //               -
      //             </button>
      //           </span>
      //           <input
      //             type="text"
      //             className="form-control"
      //             value={record.days}
      //             onChange={(e) => this.handleTableInput(e, i)}
      //           />
      //           <span class="input-group-btn input-group-append">
      //             <button
      //               class="btn btn-primary bootstrap-touchspin-up"
      //               type="button"
      //               onClick={() => this.incOrDecValue(i, "days")}
      //             >
      //               +
      //             </button>
      //           </span>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   key: "before_food",
      //   text: "Before Food",
      //   className: "before_food",
      //   align: "left",
      //   sortable: true,
      //   cell: (record, i) => {
      //     return (
      //       <div class="custom-control custom-checkbox">
      //         <input
      //           type="checkbox"
      //           class="custom-control-input"
      //           id={"bfood-" + i}
      //           name="before_food"
      //           onChange={this.handleTableCheckboxChange}
      //           defaultChecked={record.before_food}
      //         />
      //         <label class="custom-control-label" for={"bfood-" + i} />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   key: "morning",
      //   text: "Morning",
      //   className: "morning",
      //   sortable: true,
      //   align: "left",
      //   width: 100,
      //   cell: (record, i) => {
      //     return (
      //       <div class="form-group dayscount">
      //         <div
      //           class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
      //           style={{ minWidth: "112px" }}
      //         >
      //           <span class="input-group-btn input-group-prepend">
      //             <button
      //               class="btn btn-primary bootstrap-touchspin-down"
      //               type="button"
      //               onClick={() => this.incOrDecValue(i, "morning", true)}
      //             >
      //               -
      //             </button>
      //           </span>
      //           <input
      //             type="text"
      //             className="form-control"
      //             value={record.morning}
      //             onChange={(e) => this.handleTableInput(e, i)}
      //           />
      //           <span class="input-group-btn input-group-append">
      //             <button
      //               class="btn btn-primary bootstrap-touchspin-up"
      //               type="button"
      //               onClick={() => this.incOrDecValue(i, "morning")}
      //             >
      //               +
      //             </button>
      //           </span>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   key: "noon",
      //   text: "Noon",
      //   className: "noon",
      //   sortable: true,
      //   align: "left",
      //   width: 100,
      //   cell: (record, i) => {
      //     return (
      //       <div class="form-group dayscount">
      //         <div
      //           class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
      //           style={{ minWidth: "112px" }}
      //         >
      //           <span class="input-group-btn input-group-prepend">
      //             <button
      //               class="btn btn-primary bootstrap-touchspin-down"
      //               type="button"
      //               onClick={() => this.incOrDecValue(i, "noon", true)}
      //             >
      //               -
      //             </button>
      //           </span>
      //           <input
      //             type="text"
      //             className="form-control"
      //             value={record.noon}
      //             onChange={(e) => this.handleTableInput(e, i)}
      //           />
      //           <span class="input-group-btn input-group-append">
      //             <button
      //               class="btn btn-primary bootstrap-touchspin-up"
      //               type="button"
      //               onClick={() => this.incOrDecValue(i, "noon")}
      //             >
      //               +
      //             </button>
      //           </span>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   key: "night",
      //   text: "Night",
      //   className: "night",
      //   sortable: true,
      //   align: "left",
      //   width: 100,
      //   cell: (record, i) => {
      //     return (
      //       <div class="d-flex">
      //         <div
      //           class="form-group dayscount"
      //           style={{
      //             paddingRight: "12px",
      //           }}
      //         >
      //           <div
      //             class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
      //             style={{ minWidth: "112px" }}
      //           >
      //             <span class="input-group-btn input-group-prepend">
      //               <button
      //                 class="btn btn-primary bootstrap-touchspin-down"
      //                 type="button"
      //                 onClick={() => this.incOrDecValue(i, "night", true)}
      //               >
      //                 -
      //               </button>
      //             </span>
      //             <input
      //               type="text"
      //               className="form-control"
      //               value={record.night}
      //               onChange={(e) => this.handleTableInput(e, i)}
      //             />
      //             <span class="input-group-btn input-group-append">
      //               <button
      //                 class="btn btn-primary bootstrap-touchspin-up"
      //                 type="button"
      //                 onClick={() => this.incOrDecValue(i, "night")}
      //               >
      //                 +
      //               </button>
      //             </span>
      //           </div>
      //         </div>
      //         <div
      //           className="text-center"
      //           style={{
      //             padding: "12px 0 12px 12px",
      //             borderLeft: "1px solid #ccc",
      //             margin: "-12px 0",
      //           }}
      //         >
      //           {record.unit}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 5,
      show_pagination:true,
      show_length_menu:false,
      show_filter:false,
      length_menu: [5, 10, 50],
      button: {
        excel: false,
        print: false,
      },
    };
  }
  // prescription code
  incOrDecValue(index, name, isDecrement = false) {
    const data = this.state.records;
    let value = parseInt(data[index][name]) || 0;
    console.log(value);
    if(value>1||value==1||value==0){
      if (!isDecrement) {
        data[index][name] = value + 1;
      } else if (value !== 0) {
        data[index][name] = value - 1;
      }
      // calculate total
      let total = 1;
  
      if (data[index].drug_type === 'Syrup' || data[index].drug_type === 'Ointment') {
        total = 1;
      } else {
        total =
          (parseInt(data[index].morning) || 0) +
          (parseInt(data[index].noon) || 0) +
          (parseInt(data[index].night) || 0);
        data[index].total = total * parseInt(data[index].days || 0);
      }
    } else {
      data.splice(index, 1);
    }
    


    this.setState({
      records: data,
    });
  }
  clearsearch()
  {
    const searchData = this.state.searchData;
    searchData.drug_name="";
    searchData.active_ingredients="";
    searchData.generic_name="";
    searchData.manufacturer="";
    searchData.drug_id="";
    this.setState({ searchData: searchData,searchRecords: [], PrescriptionerrorMessage: "" });
   
  }
  searchAndShowDrug() {
    const searchData = this.state.searchData;
   
    let postData = {};
    postData.drug_name = searchData.drug_name ? searchData.drug_name.label : "";
    postData.drug_category = searchData.drug_category ? searchData.drug_category.label : "";
    postData.drug_type = searchData.drug_type ? searchData.drug_type.label : "";
    postData.user_id = this.props.userdata.id;
    postData.manufacturer = searchData.manufacturer;
    postData.active_ingredients = searchData.active_ingredients ? searchData.active_ingredients.label : "";
    postData.generic_name = searchData.generic_name ? searchData.generic_name.label : "";
    postData.drug_id = searchData.drug_id;
    postData.type = "user";
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios
        .post(searchDrug, postData, CONFIG)
        .then((res) => {
          this.setState({ loading: false });
          const result = res.data;
          if (result.countOfRecords === 0) {
            this.setState({errorMessage:"Drug not found in list", searchRecords: [] });
            return;
          }
          const records = result.data;
          const data = [];
          records.forEach((element) => {
            data.push({
              id: element.id,
              drug_id: element.drug_id,
              drug_name: element.drug_name,
              drug_category: element.drug_category,
              drug_type: element.drug_type,
              manufacturer: element.manufacturer,
              active_ingredients: element.active_ingredients,
              generic_name:element.generic_name,
              unit_of_measure: element.unit_of_measure,
            });
          });

          this.setState({ searchRecords: data });
            this.setState({successMessage: result.message });

        })
        .catch(() => {
          this.setState({ searchRecords: [], loading: false });
        });
    });
    this.clearMessage();
  }


  isMedicineExist(record, drug_id) {
    let checkedvalue = false;
    record.forEach((element) => {
      if (element.medicine_id === drug_id) {
        checkedvalue = true;


      }
      
    })
    return checkedvalue;
  }
  addDrug() {
    const selectedDrugs = this.state.records;

    this.state.searchRecords.map((record) => {
      
      if (record.isSelected) {
             console.log(record.id);
        let isMedicineExist = this.isMedicineExist(selectedDrugs, record.id);
        console.log(isMedicineExist);
         if (selectedDrugs.indexOf())
        if (!isMedicineExist) {
          if (record.drug_type === 'Syrup' || record.drug_type === 'Ointment') {
            selectedDrugs.push({
              medicine_description: record.generic_name,
              drug_type: record.drug_type,
              medicine_id: record.id,
              days: 1,
              before_food: 0,
              morning: 0,
              noon: 0,
              night: 0,
              total: 0,
              unit: record.unit_of_measure,
            });
          
          } else {
            selectedDrugs.push({
              medicine_description: record.generic_name,
              drug_type: record.drug_type,
              medicine_id: record.id,
              days: 1,
              before_food: 0,
              morning: 0,
              noon: 0,
              night: 0,
              total: 0,
              unit: record.unit_of_measure,
            });
          
          }

          this.setState({ PrescriptionerrorMessage: "", records: selectedDrugs});
          this.setState({ PrescriptionsuccessMessage: "Drug successfully added to cart." });
        }else
        {
          this.setState({ PrescriptionsuccessMessage: "Drug already added to cart." });
        }
        
      }
    
    });
    this.clearMessage();
    this.clearsearch();
  }

 /*  generatetagforcolumn = (billing_record) => {
    if (consultation_fee !== billing_record.description) {
      return (
        <Select maxMenuHeight={120}
          placeholder=""
          isClearable={true}
          openMenuOnClick={false}
          value={billing_record.selectedSearchBoxValue}
          options={this.state.d}
          className="customselectbilling"
          onChange={(record) => {
            let records = this.state.records;
            var recIndex = findWithAttr(records, "s_no", billing_record.s_no);
            if (null != record) {
              records[recIndex].selectedSearchBoxValue = record;
              records[recIndex].fee = record.amount;
              records[recIndex].gst_rate = record.tax_percentage + " %";
              records[recIndex].gst_amount =
                (record.tax_percentage / 100) * record.amount;
              records[recIndex].total_amount =
                Number(records[recIndex].fee) +
                Number(records[recIndex].gst_amount);
            } else {
              records[recIndex].selectedSearchBoxValue = "";
              records[recIndex].fee = "";
              records[recIndex].gst_rate = "";
              records[recIndex].gst_amount = "";
              records[recIndex].total_amount = "";
            }

            console.log(recIndex);
            console.log(records.length - 1);
            if (recIndex === records.length - 1) {
              let default_rec = Object.assign({}, record);
              default_rec.s_no = records.length + 1;
              records.push(default_rec);
            }
            this.setState({ records }, () => {
              this.calculateSection();
            });
          }}
        />
      );
    } else {
      return billing_record.description;
    }
  };
 */
 

  deleteRecord(record) {
  
        const selectedDrugs = this.state.records;
        console.log(selectedDrugs);
        const deleteIndex = selectedDrugs.indexOf(record);
        selectedDrugs.splice(deleteIndex, 1);
        this.setState({records: selectedDrugs });
        this.setState({ PrescriptionerrorMessage: "Drug removed from the cart." });
        this.clearMessage();
  }

  handleTableCheckboxChange = (e) => {
    let data = this.state.records;
    let id = e.target.id.split("-")[1];
    data[id][e.target.name] = e.target.checked ? 1 : 0;
    this.setState({ records: data });
  };

  handleSymptomSearch = (e) => {
    let cloneSymptoms = this.state.cloneSymptoms;
    if (null == e.target.value || "" === e.target.value) {
      this.setState({
        symptoms: cloneSymptoms
      });
    } else {
      //let symptoms = this.state.symptoms;
      const filteredSymptopms = cloneSymptoms.filter(symptom => {
        return symptom.symptom_name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      this.setState({
        symptoms: filteredSymptopms
      });
    }


  };

  handleTableInput = (e, index) => {
    let data = this.state.records;
    let name = e.target.name;
    let value = e.target.value;
    data[index][name] = value;
    this.setState({ records: data });
  };

  handleCheckbox = (e) => {
    let data = this.state.searchRecords;
    let id = e.target.id.split("-")[1];

    let name = e.target.name;
    let value = e.target.checked;
    data[id][name] = value;
    this.setState({ searchRecords: data });

  };

  handleSearchInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ searchData: data });
  };

  handleSearchSelect = (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });
    //console.log(selectedOptions.label);
    
   

  };
  handleSearchGenericNameselect= (selectedOptions, e) => {
    let data = this.state.searchData;
    data[e.name] = selectedOptions;
    this.setState({ searchData: data });

    
  };
  // handleSearchactiveingredientsSelect= (activeselectedOptions, e) => {
  //   let data = this.state.searchData;
  //   data[e.name] = activeselectedOptions;
  //   this.setState({ searchData: data });

  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${this.props.access_token}`;
  //   const inpatientdetails = { id: this.props.match.params.id };
  //   axios
  //     .post(getInpatientconsultingDetails, inpatientdetails, CONFIG)
  //     .then((res) => {
  //       this.setState({ inpatientdetails: res.data.data })
      

  //       if (res.data.status === "1") {
         
   
  //     axios
  //     .post(getAllgenericnameforactiveingredients,{user_id:res.data.data.business_id,active_ingredients:activeselectedOptions.label}, CONFIG)
  //     .then((GenericNameRespons) => {
  //       let GenericNameFullList = GenericNameRespons.data.data;
  //       let GenericNameList = [];
  //       GenericNameFullList.forEach((element, index) => {
  //         GenericNameList.push({
  //           value: element.id,
  //           label: element.generic_name,
  //           manufacturer: ""
            
  //         });
  //       });

  //       this.setState({ GenericNameList });
      
  //     }).catch((e) => { });
  //   }  
    
  // }).catch((e) => { });


  // };
  componentDidMount = () => {
console.log(moment().format());

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const data = { room_id: this.props.match.params.room_id,business_id:this.props.userdata.id };
    axios
      .post(getInpatientconsultingDetails, data, CONFIG)
      .then((res) => {
        console.log(res.data);


        if (res.data.status === "1") {
let inpatientdetails = res.data.data[0];
if(inpatientdetails.medicine_history === null || typeof inpatientdetails.medicine_history === "number"){
  inpatientdetails.medicine_history = [];
}
inpatientdetails.other_comments = "";
console.log(inpatientdetails);
          this.setState({ inpatientdetails})
          let consult = res.data.data[0];
          if(consult.medicine_history === null || typeof inpatientdetails.medicine_history === "number"){
            consult.medicine_history = [];
          }
          console.log(consult.medicine_history);
          consult.other_comments = "";
          this.setState({ consult: consult })

      

        }


      }).catch(() => { });



    axios
      .post(getAllDrugTypes, null, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch(() => { });






    axios
      .post(getAllMasterDisease, null, CONFIG)
      .then((list) => {
        const data = list.data.data;
        let diseaseList = [];
        data.forEach((element) => {
          diseaseList.push({
            value: element.disease_name,
            label: element.disease_name,
          });
        });

        this.setState({ diseaseList: diseaseList });
      })
      .catch(() => { });

    // consultation codes

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    const postData = { id: this.props.match.params.id };
    axios
      .post(getPrescription, postData, CONFIG)
      .then((res) => {
        let data = res.data.data || res.data.date;
        if (res.data.status !== "1" || !data || !data[0]) {
          return;
        }
        console.log(data[0]);
        this.setState({
          consultData: data[0],
          records: data[0].medicine_description || [],
          consult: data[0] || [],
        });
      })
      .catch(() => { });

    // get search select options
/*     axios
      .post(getAllMasterDrug, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugNameList = drugMasterRespons.data.data;
        let drugList = [];
        masterdrugNameList.forEach((element, index) => {
          drugList.push({
            value: element.id,
            label: element.drug_name,
            manufacturer: "",
            active_incredient: "",
          });
        });

        this.setState({ drugList });
      })
      .catch((e) => { });
 */

      axios
      .post(getAllHospitalDrug,{user_id:this.props.userdata.id}, CONFIG)
      .then((drugRespons) => {
        let drugNameList = drugRespons.data.data;
        let drugList = [];
        drugNameList.forEach((element) => {
          
          drugList.push({
            value: element.id,
            label: element.drug_name,
            manufacturer: "",
            active_incredient: "",
          });
        });

        this.setState({ drugList });
      
      }).catch(() => { });


  

    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;
        let drugCategories = [];
        list.forEach((element) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch(() => { });

     
  };

  searchAndShowSymptom(searchData, index) {

    let data = {};
    data.symptom_category = searchData.label;
    data.user_id = this.props.userdata.id;
     axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(getSymptomByCategory, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        if (result.countOfRecords > 0) {
          let records = result.data;
          let symptoms = [];
          records.forEach((element) => {
            symptoms.push({
              symptom_name: element.symptom_name,
              id: element.id,
            });
          });
let options = [];
let consultsymp = this.state.consult.symptoms;
if(consultsymp){
  consultsymp.forEach((el) => {
    options.push(el.label);
    
  });

}
  
  
       let uniqueoptions = options.filter((c, index) => {
        return options.indexOf(c) === index;
    });


          this.setState({
            symptoms: symptoms,
            cloneSymptoms: symptoms,
            // consult: { symptoms: symptoms[0].symptom_name },
            activeIndex: index,
            selectedSymptoms: uniqueoptions,
          });
        } else {
          this.setState({
            symptoms: [],
            // consult: { symptoms: [] },
            activeIndex: index,
            // selectedSymptoms: [],
          });
        }
      });
    });
  }

  selectSymptom = (symptom, e) => {
    const options = this.state.selectedSymptoms;
    let index;

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      options.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(e.target.value);
      options.splice(index, 1);
    }
    let consult = this.state.consult;

    let selectedSymptoms = [];

    let consultsymp = consult.symptoms;
    if(consultsymp){
      consultsymp.forEach((el) => {
              options.push(el.label);              
            });  
    }
    

     let uniqueoptions = options.filter((c, index) => {
      return options.indexOf(c) === index;
  });
  uniqueoptions.forEach((e)=>{
    selectedSymptoms.push({label:e,value:e})
  })
    this.setState({ selectedSymptoms: uniqueoptions });

   
    consult.symptoms = selectedSymptoms;


    this.setState({
      consult,
    });
  };

  submitAssesment=()=> {
    const data = this.state.consult;
    
    let error = this.state.error;
    // console.log(data); return false;
    if(data.other_comments === ""){
      error.other_comments = "Please enter a comment";
      this.setState({ error:error});
      return;
    }
    data.medicine_description = this.state.records; // TODO: replace this with actual client id
    
    let medicine_history ={
      "date":moment().format(),
      "other_comments":data.other_comments,
      "vitals":{
          "bp":data.bp,
          "pulse":data.pulse,
          "temperature":data.temperature
      },
      "tablets":data.medicine_description
  }
  console.log(data.medicine_history);
 let med_history = data.medicine_history;
 med_history.push(medicine_history);
   data.medicine_history = med_history;

    // console.log(data);return data;
    data.other_comments = data.other_comments;
    data.room_id = this.props.match.params.room_id;
    data.status = "Consulting";
    data.action_type = "consult";
    
   
  
  // console.log(data, typeof data.medicine_history);return false;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(admitPatient, data, CONFIG).then(
        (res) => {
          const result = res.data;
          console.log(result);
          if (result.status === "1") {
            this.setState({ successMessage: result.message, consult: data });
            window.location.href="/rooms";
          } else {
            this.setState({
              errorMessage:
                result.message || "Failed to submit consultant details",
              consult: data,
            });
          }

        },
        () => {
          this.setState({
            loading: false,
            errorMessage: "Failed to submit consultant details",
            consult: data,
            activeTab: "Prescription"
          });
        }
      );
    });
    this.clearMessage();
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          successMessage: "",
          errorMessage: "",
          PrescriptionsuccessMessage: "",
          PrescriptionerrorMessage: "",
        }),
      5000
    );
  };

  handleInput = (e) => {
    let data = this.state.consult;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ consult: data });
  };

  handleSelect = (selectedOptions, e) => {

    let data = this.state.consult;
    data[e.name] = selectedOptions;
    if (e.name === "symptoms") {
      

      
let options = [];


      let consultsymp = selectedOptions;
if(consultsymp){
  consultsymp.forEach((el) => {
    options.push(el.label);
    
  });

}
  
  
       let uniqueoptions = options.filter((c, index) => {
        return options.indexOf(c) === index;
    });

this.setState({selectedSymptoms:uniqueoptions})

     
    }
    this.setState({ consult: data });
  };
  activateTab(activateTab) {

    this.setState({ activeTab: activateTab });
  }
  CheckOut = () => {

    this.setState({ loading: true })
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
    let clientapppointmentId = this.state.inpatientdetails.q_id;
    axios.post(getQEntryById, { q_id: clientapppointmentId }, CONFIG)
      .then((queue_data) => {

        if (queue_data.data.status === '1') {
          let queuein_data = queue_data.data.data[0]
          queuein_data.status = 'Check-Out'
          this.setState({ queue_data: queuein_data })
          let queuedata = {
            "n_business_id": queuein_data.business_id,
            "n_business_user_id": queuein_data.business_user_id,
            "n_schedule_day_id": queuein_data.schedule_day_id,
            "n_q_id": queuein_data.q_id,
            "n_status": "Check-Out",
            "n_schedule_type": "CHKOUT",
            "n_preference": "1"
          }

          // return false;
          //   {
          //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
          //     "n_schedule_day_id": "f4e6f51b-bdc8-11eb-97e3-0ad8e4601dac",   
          //     "n_status": "Check-Out",   
          //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
          //     "n_schedule_type": "CHKOUT",
          //     "n_q_id":"2a8efbc1-035b-452c-96fd-f1f2f531885f",
          //     "n_preference":"1"
          // }
// console.log(postData); console.log(queuein_data);return false;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(UpdateQueueForCheckOut, queuedata)
            .then(() => {





            let reqdata = {
                "to" : "elangamanir@gmail.com",
                "subject" : "Followup Remainder",
                "mailHeader" : "Followup Remainder",
                "message": "Hi Name, Your have followup remainder in 2 days in myhos for checkup. "
            }
            let notificationdata = {
              "message_code": "FOLLOW_UP",
              "language_code": "en",
              "data": {
                "patient_name": queuein_data.first_name,
                "followup_days": reqdata.message,
                "business_name":"",
                // patient_name,followup_days,business_name
        
              },
              "receiver": {
                "phoneNumber": "+919944053296"
              }
            };
            console.log(reqdata);
              // console.log(this.props.userdata);
              
              axios
                .post(sendemail, reqdata)
                .then((res) => {
                  let resdata = res.data;                            
                  axios
                  .post(sendsms, notificationdata)
                  .then((result) => {
                    console.log(result)
                  }).catch((err) => {
                    console.log(err);
                  })
                  console.log(resdata);
                  this.setState({ loading: false });
                  let data = {
                    message:"",
                    status:""
                  }
                  if (resdata===true) {
                    data.error_type = "success";
                    data.message = "Referral email send successfully"
                    data.status = "1"
                    this.setState({
                      formSuccess: data,
                    });
                    this.setState({ loading: false });
                    this.clearMessage();
                  } else {
                    data.error_type = "error";
                    data.message = "Failed"
                    data.status = "0"
                    this.setState({
                      formSuccess: data,
                    });
                    this.setState({ loading: false });
                    this.clearMessage();
                  }
                })
                .catch(() => { });




              let data = { id: this.props.match.params.id, business_id: queuein_data.business_id };
              axios.post(updatePrescription, data, CONFIG).then(() => {
                return false

              }).catch(() => {
                console.log("Update queue failed");
                toast.error("Check out could not be Completed.",{
                  className:"toast-error"
                });
              });
              this.setState({ loading: false })


             // this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
             toast.success("The Consultation was completed successfully.",{
              className:"toast-success"
            });
           

              window.location.href = "/consult";
              // this.props.history.push("/consult");

            }).catch(() => {
              console.log("Update queue failed");
            });
        }
      }).catch(() => {
        console.log("Get appoinment by id failed");
      });

  }
  render() {
    const {
      consult,
      successMessage,
      errorMessage,
      PrescriptionerrorMessage, PrescriptionsuccessMessage,
      loading,
      error,
      //prescriptionss code
      searchData,
      searchRecords,
      drugList,
      QueuesuccessMsg,
      inpatientdetails    } = this.state;
    return (
      <div id="layout-wrapper" >
        <input type="hidden" id="usertype" value="doctor" />
       {/*  <Spinner loading={loading} /> */}
       {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
          <section class="my-head">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-2 text-left pr0">
              <div class="text-center logocenter">
                <a href="index">
                  {" "}
                  <img alt="" src={require("../../assets/images/logo.jpg")} />
                </a>
              </div>
            </div>
            <div class="col-sm-3 pl0">
              <div class="row">
                <div class="col-sm-5">
                  <div class="doctorimg">
                     <img alt="" src={inpatientdetails.avatar
                          ? inpatientdetails.avatar
                          :require("../../assets/images/user.png")} /> 
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="doctorprofile">
                    <h5>
                      <span class="witfnt">
                        Patient ID:
                        {inpatientdetails.registeration_id}
                      </span>
                    </h5>
                    <h5>
                      <span class="witfnt">
                         {inpatientdetails.patient_name}, {inpatientdetails.age}
                        {inpatientdetails.gender} 
                      </span>
                      {inpatientdetails.blood_group}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7 pl0 rw16">

              {/* <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between s-118">
                      <h5 className="border-0 small mb-0">Height
                      </h5>
                      <img alt="" 
                          src={require("../../assets/images/vitaldetails1.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={inpatientdetails.height}
                        name="height"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">cm </span>



                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between s-118 ">
                      <h5 className="border-0 small mb-0">Weight
                      </h5>
                      <img alt="" class=""
                          src={require("../../assets/images/vitaldetails2.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={inpatientdetails.weight}
                       // disabled
                        name="weight"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">kg </span>

                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between pr-0 s-118 ">
                      <h5 className="border-0 small mb-0">Temperature
                       

                      </h5>
                      <img alt="" class="btn-space"
                          src={require("../../assets/images/vitaldetails4.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={inpatientdetails.temperature}
                       // disabled
                        // placeholder="Temperature"
                        name="temperature"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">°F
                        </span>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between pr-0 s-118 ">
                      <h5 className="border-0 small mb-0">BP
                       

                      </h5>
                      <img alt="" class=""
                          src={require("../../assets/images/vitaldetails5.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={inpatientdetails.bp}
                      //  disabled
                        name="bp"
                        onChange={this.handleInput}
                      />

                      <div class="input-group-append">
                        <span class="input-group-text p-0">mmHg </span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div class="cpag">
                <div class="card vitals">

                  <div class="card-body">
                    <div className="text-left titleFlex justify-content-between pr-0 s-118 ">
                      <h5 className="border-0 small mb-0">Pulse
                       

                      </h5>
                      <img alt="" class=""
                          src={require("../../assets/images/vitaldetails5.png")}
                        />
                    </div>

                    <div className="input-group input-group-sm mt-2">

                      <input
                        type="text"
                        className="dis-inlnflx form-control"
                        value={inpatientdetails.pulse}
                    //    disabled
                        name="pulse"
                        onChange={this.handleInput}

                      />

                      <div class="input-group-append">
                        <span class="input-group-text p-0">per min</span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>






            </div>
          </div>
        </div>
      </section>
            <div className="main-box">
              <div className="sidemenu">
                <SideBar />
              </div>

              <section className="main-body hospital-profilegap">
              <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                <div className="container-fluid">
                
                  <div className="checkout-tabs">
                    <div className="row">

                      <div className="col-sm-12 pl0 c-page-wrapper">

                        <div className="col-sm-12">
                          <div className="hmeclr cnpag bg-transparent  pb-12">
                            <ul className="nav nav-pills manag ctab" role="tablist">

                             
                     
                            </ul>

                           
                          </div>
                          {QueuesuccessMsg ? (<div className="abtn">
                            <SpanField
                              classname="text-success  mt-2"
                              title={QueuesuccessMsg}
                            /></div>
                          ) : ""}
                        </div>


                        <Fragment>
                        
                          
                          

                          <div
                            class="row"
                            className="row"
                            style={{
                              display:
                                this.state.activeTab === "Prescription"
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <div class="col-sm-9 pr0">
                             
                              <div class="card treat-historyheight">
                              <div class="card-header"> <div class="card-title">Prescription</div> </div>
                                <div class="card-body ">
                                  <div
                                    class="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      class="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div className="user-notification">
                                            <SpanField
                                              classname="text-success mt-2"
                                              title={PrescriptionsuccessMessage}
                                            />
                                            <SpanField
                                              classname="text-danger mt-2"
                                              title={PrescriptionerrorMessage}
                                            />

                                          </div>
                                          <div className="row">
                                            <div className="col-sm-4">
                                            <div className="row">
                                            <div className="col-sm-12">
                                            <div className="frllftfull">
                                                <div className="form-group frllft">
                                                  <label>Assessment</label>
                                                </div>
                                               
                                              <TextArea
                                                style={{ minHeight: 100 }}
                                                name={"other_comments"}
                                                controlFunc={this.handleInput}
                                                content={consult.other_comments}
                                                maxLength={100}
                                              />
                                              <SpanField
                                              classname="text-error mt-2"
                                              title={error.other_comments}
                                            />
</div>
                                              <div className="text-center">
                                                <button
                                                  className=" signupbtn  pad-6 margin-10"
                                                  onClick={() =>
                                                    this.submitAssesment()
                                                  }
                                                >
                                                  Submit
                                                </button>
                                               
                                                
                                              </div>
                                              <div className="user-notification">
                                                <SpanField
                                                  classname="text-success mt-2"
                                                  title={successMessage}
                                                />
                                                <SpanField
                                                  classname="text-danger mt-2"
                                                  title={errorMessage}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          </div>
                                          <div className="col-sm-8">
                                        <div className="doctorconsultTable" >
                                           <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            className="table table-bordered table-striped kas  mobile_table"
                                          />
                                          </div>
                                          </div>
                                        </div>
                                   
                                        <h4 className="mt-2 mb-4"><b className="text-center">Treatment history</b></h4>
                                   <div class="row treat-history-scroll">
                               
{(inpatientdetails.medicine_history != null && inpatientdetails.medicine_history.length )? (
  inpatientdetails.medicine_history.map((medicine, i) => {
    return (
      <div key={i} className="col-md-12">
        <div className="treat-history">
        <p><b>Date:</b>{moment(medicine.date).format("DD-MM-YYYY hh:mm")}</p>

<p><b>Assessment:</b>{medicine.other_comments} </p>
<p>
  <b>Temperature:</b> {medicine.vitals.temperature}<b> BP:</b>{medicine.vitals.bp}<b> Pulse:</b>{medicine.vitals.pulse} 
</p>
<p><b>Medicines given:</b>{medicine.tablets ? (medicine.tablets.map((tablet)=>{
  return (
    <li>{tablet.medicine_description}</li>
  )
}
)
):""}</p>
        </div>

     
      </div>
    );
    }
  )
):""}
  
    {/* {
    inpatientdetails.medicine_history!=""?(
      inpatientdetails.medicine_history.map((medicine, i) => {
        return (
          <div key={i}>
            <p>Date:{medicine.date}</p>

            <p>{medicine.other_comments} </p>
            <p>
              Temperature: {medicine.temperature} BP:{medicine.bp} Pulse:{medicine.pulse} 
            </p>
            <p>Medicines given:</p>
          </div>
        );
        }
      )
      ):""
   
    } */}
  


                                   </div>
                                      
                                        

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-3 pl0">
                           
                              <div class="card  treat-historyheight">
                              <div class="card-header"> <div class="card-title">Search Drug</div> </div>
                                <div class="card-body">
                                  <div
                                    class="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      class="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                       <div className="user-notification">
                                                <SpanField
                                                  classname="text-success mt-2"
                                                  title={successMessage}
                                                />
                                                <SpanField
                                                  classname="text-danger mt-2"
                                                  title={errorMessage}
                                                />
                                              </div>
                                      <div class="row">
                                        <div class="col-sm-12">

                                      {/*     <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="drug_type"
                                              placeholder="Drug Type"
                                              isMandatory={true}
                                              value={searchData.drug_type}
                                              onChange={this.handleSearchSelect}
                                              options={drugTypes}
                                            />
                                          </div> */}
                                          {/* <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <InputField
                                              inputType={"text"}
                                              name={"manufacturer"}
                                              controlFunc={this.handleSearchInput}
                                              content={searchData.manufacturer}
                                              placeholder="Manufacturer"
                                            />
                                          </div> */}
                                          <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="drug_name"
                                              placeholder="Drug Name"
                                              isMandatory={true}
                                              value={searchData.drug_name}
                                              onChange={this.handleSearchSelect}
                                              options={drugList}
                                            />
                                          </div>
                                          {/* <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="active_ingredients"
                                              placeholder="Active Ingredients"
                                              isMandatory={true}
                                              value={searchData.active_ingredients}
                                              onChange={this.handleSearchactiveingredientsSelect}
                                              options={ActiveIngredientList}
                                            />
                                          </div> */}
                                          {/* <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              className="selectcls p-0"
                                              name="generic_name"
                                              placeholder="Generic Name"
                                              isMandatory={true}
                                              value={searchData.generic_name}
                                              onChange={this.handleSearchGenericNameselect}
                                              options={GenericNameList}
                                            />
                                          </div> */}
                                       {/*    <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <Select maxMenuHeight={120}
                                              isClearable={true}
                                              placeholder="Drug Category"
                                              className="selectcls p-0"
                                              name="drug_category"
                                              isMandatory={true}
                                              value={searchData.drug_category}
                                              onChange={this.handleSearchSelect}
                                              options={drugCategories}
                                            />
                                          </div> */}
                                          {/* <div class="input-group mb-10">
                                          <span class="input-group-addon">
                                            <i class="glyphicon fa fa-search icontextb" />
                                          </span>
                                          <Select
                                            isClearable={true}
                                            className="selectcls p-0"
                                            name="drug_unit"
                                            isMandatory={true}
                                            value={searchData.drug_unit}
                                            onChange={this.handleSearchSelect}
                                            options={drugUnits}
                                          />
                                        </div> */}
                                          
                                          {/* <div class="input-group mb-10">
                                            <span class="input-group-addon">
                                              <i class="glyphicon fa fa-search icontextb" />
                                            </span>
                                            <InputField
                                              inputType={"text"}
                                              name={"drug_id"}
                                              controlFunc={this.handleSearchInput}
                                              content={
                                                searchData.drug_id
                                              }
                                              placeholder={"Drug Id"}
                                            />
                                          </div> */}
                                          <div class="text-center">
                                            <button
                                              className="float-center signupbtn pad-6 margin-10"

                                              onClick={() =>
                                                this.searchAndShowDrug()
                                              }
                                            >
                                              Search
                                            </button>
                                            <button
                                              className="float-center signupbtn pad-6 margin-10"

                                              onClick={() =>
                                                this.clearsearch()
                                              }
                                            >
                                              clear
                                            </button>
                                          </div>
                                          <div class="card-body">
                                            {searchRecords.map((drug, i) => {
                                              return (
                                                <div class="custom-control custom-checkbox mb-1">
                                                  <input
                                                    type="checkbox"
                                                    name="isSelected"
                                                    class="custom-control-input"
                                                    id={"drug-" + i}
                                                    onChange={this.handleCheckbox}
                                                  />
                                                  <label
                                                    class="custom-control-label"
                                                    for={"drug-" + i}
                                                  >
                                                    {drug.generic_name}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          {searchRecords &&
                                            searchRecords.length ? (
                                            <div class="adcrt mb-2">
                                              <button
                                                onClick={() => this.addDrug()}
                                              >
                                                <i
                                                  class="fa fa-shopping-cart"
                                                  aria-hidden="true"
                                                />
                                                &nbsp;Add to Cart
                                              </button>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                         
                        </Fragment>
                        {/* <Footerad /> */}

                      </div>
                    </div>
                  </div>


                </div>
           

              </section>
    <FooterSection />
            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default connect(mapStateToProps)(InPatientPrescription);
