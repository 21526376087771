import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { CONFIG } from "../config/data";
import SideBar from "../components/container/SideBar";
import Header from "../components/container/layout/business/Header";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  getAllBusinessUserList,
  removeshiftplanner,
  addEmployee,
  updateEmployee,
  removeBusinessUserFromBusiness,
  searchEmployee,
  addBusinessUserToBusiness,
  createShiftPlanner,
  getBusinessById,
  getTimeoutbycategory,
  sendemail,
  BaseUrl,sendsms,inactiveBusinessUser,
  checkadddoctorvalidity
} from "../config/url.json";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import InputField from "../components/UI/InputField";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { blood_group, gender, maritial_status, designation } from "../config/data";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import Spinner from "../components/UI/Spinner";
import moment from "moment";
import Popup from "../components/UI/Popup";
import FooterSection from "../components/UI/FooterSection";
import { AUTH_CONFIG } from "../config/data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getFrequencyFromDays,
  gethhmmss,
  getDateISOFormat,
} from "../constants/globalLevelFunctions";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import ExpirePopup from '../components/UI/ExpirePopup'
import Tooltip from '@mui/material/Tooltip';
import Label from "../components/UI/Label";
import { Popupinfo } from "../components/UI/Popupinfo";
import DoctorPopup from "../components/UI/DoctorPopup";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity,
    adddoctorvalidity:state.ValidityReducer.adddoctorvalidity

  };
}
const specialities=
[
  {value:"1",label:"Brain and Nerves"},
  {value:"2",label:"Urinary Issues"}
];
const onlineOptions = [
  { value: "Active", label: "Active" },
  { value: "In-active", label: "In-active" },
];
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

class BusinessUserList extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;
       this.columns = [
        {
          key: "s_no",
          text: "S.No",
          className: "s_no",
          align: "left",
          sortable: true,
        },
         {
        key: "employee_id",
        text: "Doctor ID",
        className: "emp_id",
        align: "left",
        sortable: true,
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "Specialities",
        text: "Speciality",
        className: "speciality",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
           
             (record.designation === "doctor" || record.designation === "Doctor") ? (record.Specialities
              
             ):('-')
           
          )
        },
    /*     cell: (record) => {
          let obj = {
            label: record.Specialities,
            value: record.speciality,
          };
          return (
            <Select maxMenuHeight={120}
              className="form-control p-0"
              name="speciality"
              value={obj}
              onChange={() => this.handlespcialityColumnChange(record)}
              options={specialities}
            />
          );
        }, */
      },
      {
        key: "dob",
        text: "DOB",
        width: 100,
        className: "dob",
        align: "left",
        sortable: true,
        cell: (record) => {
          return moment(record.dob).format("DD-MM-YYYY");
        },
      },
      {
        key: "gender",
        text: "Gender",
        className: "gender",
        sortable: true,
        align: "left",
      },
      // {
      //   key: "blood_group",
      //   text: "Blood Group ",
      //   className: "blood_group",
      //   sortable: true,
      //   align: "left",
      // },
      {
        key: "status",
        text: "Status",
        className: "status",
        sortable: true,
        align: "left",
        cell: (record) => {
          return (
           
             (record.designation === "doctor" || record.designation === "Doctor") ? (record.status
              
             ):('-')
           
          )
        },
      },
      {
        key: "designation",
        text: "Designation",
        className: "designation",
        sortable: true,
        align: "left",
      },
      {
        key: "mobile_number",
        text: "Mobile Number",
        className: "mobile_number",
        sortable: true,
        align: "left",
      },
      {
        key: "Edit",
        text: "Edit",
        className: "Edit",
        width: 80,
        align: "left",
        sortable: false,
        cell: (record) => {
          let obj = {
            label: record.doc_status === "Yes" ? "Active" : "In-active",
            value: record.doc_status === "Yes" ? "Active" : "In-active",
          };
          return (
            <Fragment>
                 <Tooltip title="Edit">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              </Tooltip>
                         
            </Fragment>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 200,
        align: "left",
        sortable: false,
        cell: (record) => {
          let obj = {
            label: record.doc_status === "Yes" ? "Active" : "In-active",
            value: record.doc_status === "Yes" ? "Active" : "In-active",
          };
          return (
            <Fragment>
       
              {(record.designation === "doctor" || record.designation === "Doctor") ? (
                <Select maxMenuHeight={120}
                className="form-control p-0"
                name="status_obj"
                value={obj}
                onChange={(data) => this.handleStatusColumnChange(record,data)}
                options={onlineOptions}
              />
              ):(
                <Tooltip title="Delete">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    this.setState({ deletePopup: true, record: record })
                  }
                >
                  <i className="fa fa-trash"></i>
                </button>
                </Tooltip>
              )}
              
            </Fragment>
          );
        },
      },
     
    ];

    this.searchcolumns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: 20,
      },
        {
        key: "employee_id",
        text: "Employee ID",
        className: "employee_id",
        align: "left",
        sortable: true,
        width: 20,
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
        width: 60,
        // cell: (record) => {
        //   return (
        //     <Fragment>
        //       <InputField
        //         inputType={"text"}
        //         isMandatory={true}
        //         name={"first_name"}
        //         controlFunc={this.handleInputsearch.bind(this, record)}
        //         content={record.first_name}
        //         placeholder={""}
        //       />
        //       <SpanField
        //         classname="text-danger"
        //         title={record.errors?.first_name}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
        width: 60,
        // cell: (record) => {
        //   return (
        //     <Fragment>
        //       <InputField
        //         inputType={"text"}
        //         isMandatory={true}
        //         name={"last_name"}
        //         controlFunc={this.handleInputsearch.bind(this, record)}
        //         content={record.last_name}
        //         placeholder={""}
        //       />
        //       <SpanField
        //         classname="text-danger"
        //         title={record.errors?.last_name}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      {
        key: "designation",
        text: "Designation",
        className: "designation",
        align: "left",
        sortable: true,
        width: 100,


      },
      {
        key: "Specialities",
        text: "Speciality",
        className: "speciality",
        align: "left",
        sortable: true,
        width: 100,
        // cell: (record) => {
        //   return (
        //     <Fragment>
        //       <CreatableSelect
        //         className="form-control p-0"
        //         name="speciality"
        //         isMandatory={true}
        //         value={record.speciality}
        //         onChange={(value) =>
        //           this.handleSearchSelectChange(record, value, "speciality")
        //         }
        //         options={this.state.specialities_array}
        //       />
        //       <SpanField
        //         classname="text-danger"
        //         title={record.errors?.speciality}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      // {
      //   key: "dob",
      //   text: "Date Of Birth",
      //   className: "dob",
      //   align: "left",
      //   sortable: true,
      //   width: 100,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         <div className="form-group datepickerdiv">
      //           {/* <DatePicker
      //             date={record.dob}
      //             format={"DD-MM-YYYY"}
      //             showBorder
      //             onChange={(date) => this.handleInputsearch(date, record)}
      //             placeholder={"Select your DOB"}
      //           /> */}
      //           <DatePicker
      //             className="form-control"
      //             value={record.dob}
      //             format="dd-MM-yyyy"
      //             name="dob"
      //             onChange={(date) => this.handledobsearch(date, record)}
      //           />
      //         </div>
      //         <SpanField
      //           classname="text-danger"
      //           title={record.errors ? record.errors.dob : ""}
      //         />
      //       </Fragment>
      //     );
      //   },
      // },
      {
        key: "gender",
        text: "Sex",
        className: "gender",
        sortable: true,
        align: "left",
        width: 20,
        // cell: (record) => {
        //   return (
        //     <Fragment>
        //       <CreatableSelect
        //         className="form-control p-0"
        //         name="gender"
        //         isMandatory={true}
        //         value={record.gender}
        //         onChange={(value) =>
        //           this.handleSearchSelectChange(record, value, "gender")
        //         }
        //         options={gender}
        //       />
        //       <SpanField
        //         classname="text-danger"
        //         title={record.errors?.gender}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      // {
      //   key: "marital_status",
      //   text: "Marital Status",
      //   className: "marital_status",
      //   sortable: true,
      //   align: "left",
      //   width: 100,
      //   // cell: (record) => {
      //   //   return (
      //   //     <Fragment>
      //   //       <CreatableSelect
      //   //         className="form-control p-0"
      //   //         name="marital_status"
      //   //         isMandatory={true}
      //   //         value={record.marital_status}
      //   //         onChange={(value) =>
      //   //           this.handleSearchSelectChange(record, value, "marital_status")
      //   //         }
      //   //         options={maritial_status}
      //   //       />
      //   //       <SpanField
      //   //         classname="text-danger"
      //   //         title={record.errors ? record.errors.marital_status : ""}
      //   //       />
      //   //     </Fragment>
      //   //   );
      //   // },
      // },
      {
        key: "consulting_fees",
        text: "Consulting Fees",
        className: "consulting_fees",
        sortable: true,
        align: "left",
        width: 100,
        cell: (record) => {
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"consulting_fees"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.consulting_fees}
                placeholder={""}
              />
               <SpanField
                classname="text-danger"
                title={record.errors.consulting_fees ? record.errors.consulting_fees : ''}
              /> 
            </Fragment>
          );
        },
      },
      {
        key: "phone_number",
        text: "Mobile Number",
        className: "phone_number",
        sortable: true,
        align: "left",
        width: 50,
        cell: (record) => {
          return (
            <div className="form-control">
              <PhoneInput
                international
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={record.phone_number}
                onChange={(value) =>
                  this.handleSearchSelectChange(record, value, "phone_number")
                }
                disabled={true}
                error={
                  record.phone_number
                    ? isValidPhoneNumber(record.phone_number)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
              />
              <SpanField
                classname="text-danger"
                title={record.errors?.mobile_number}
              />
            </div>
          );
        },
      },
      // {
      //   key: "emergency_contact",
      //   text: "Emergency Contact",
      //   className: "emergency_contact",
      //   sortable: true,
      //   align: "left",
      //   width: 100,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         <PhoneInput
      //           international
      //           defaultCountry="IN"
      //           placeholder="Enter phone number"
      //           value={record.emergency_contact}
      //           onChange={(value) =>
      //             this.handleSearchSelectChange(
      //               record,
      //               value,
      //               "emergency_contact"
      //             )
      //           }
      //           error={
      //             record.emergency_contact
      //               ? isValidPhoneNumber(record.emergency_contact)
      //                 ? undefined
      //                 : "Invalid phone number"
      //               : "Phone number required"
      //           }
      //         />
      //         <SpanField
      //           classname="text-danger"
      //           title={record.errors?.emergency_contact}
      //         />
      //       </Fragment>
      //     );
      //   },
      // },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      showexpirePopup: this.props.validity,
      showadddoctorPopup:this.props.adddoctorvalidity,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      searchErrorMessage: "",
      headerinfo: {
        profile_name: "",
        qualification: "",
        specialization: "",
        work_place: "",
        id: "",
        languages_known: "",
        profile_percentage: "",
        rating: "",
      },
      loading: false,
      deletePopup: false,
      record: null,
      records: [],
      editRecord: null,
      showDocFields: false,
      employeeData: {
        first_name: "",
        last_name: "",
        gender: "",
        designation: "",
        speciality: "",
        date_of_birth: "",
        phone_number: "",
        phone_code: "",
        blood_group: "",
        emergency_contact: "",
        marital_status: "",
        email: "",
        business_id: "",
        consulting_fees: "",
      },
      errors: {
        first_name: "",
        last_name: "",
        gender: "",
        designation: "",
        speciality: "",
        date_of_birth: "",
        phone_number: "",
        blood_group: "",
        emergency_contact: "",
        marital_status: "",
        email: "",
        business_id: "",
        error: "",
        consulting_fees: "",
      },
      addEmployeeSuccess: {
        message: "",
        status: "",
      },
      actions: "add",
      successMessage: "",
      errorMessage: "",
      searchData: {
        employee_id: "",
        first_name: "",
        last_name: "",
        designation: "",
        speciality: "",
        phone_number: "",
      },
      searchrecords: [],
      search_action: false,
      searchTableSuccess: {
        message: "",
        status: "",
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleClose() {
    this.setState({ showModal: false })
  }

  handleStatusColumnChange = (record,data) => {
    
  console.log(record)
  console.log(data)
  
  // let inactive = {label: "In-active",value:"In-active"};
  // let active = {label: "Active",value:"Active"};
    record.doc_status = data;
    console.log(record);
    this.UpdatestatusColumnChange(record);
  };

  UpdatestatusColumnChange = (record) => {
    let data = {
      business_id:this.props.userdata.id,
      business_user_id:record.user_id,
      user_status: record.doc_status.label === "Active" ? "Yes" : "No",
    }
    // console.log({label:record.doc_status,value:record.doc_status} );
    // console.log(data); returns false;
    // record.user_id = this.props.userdata.id;
    axios.post(inactiveBusinessUser, data, CONFIG).then((list) => {
      let records = this.state.records;

      var recIndex = findWithAttr(records, "user_id", record.user_id);

      records[recIndex].employee_id = record.employee_id;
      records[recIndex].doc_status = record.doc_status.label === "Active" ? "Yes" : "No";
      
      records[recIndex].user_id = record.user_id;
      records[recIndex].professional_id = record.professional_id;
      records[recIndex].email = record.email;
      records[recIndex].first_name = record.first_name;
      records[recIndex].last_name = record.last_name;
      records[recIndex].designation = record.designation;
      records[recIndex].speciality = record.speciality;
      records[recIndex].Specialities = record.Specialities;
      records[recIndex].dob = record.dob;
      records[recIndex].gender = record.gender;
      records[recIndex].marital_status = record.marital_status;
      records[recIndex].blood_group = record.blood_group;
      records[recIndex].mobile_number = record.mobile_number;
      records[recIndex].emergency_contact = record.emergency_contact;
      records[recIndex].consulting_fees = record.consulting_fees;

      this.setState({ records, tableSuccess: list.data });
      toast.success("Business User "+( record.doc_status === "Yes" ? "Active":"In-active" ) +" from business ",{
        className:"toast-success"
      });
      this.clearMessage();
  });
  };


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();

  };

  _onAction(e) {
    // console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
  
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
   
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
   if(isTimedOut===true)
   {
    localStorage.removeItem("token");
    this.props.logout();
  }
  }

  addRecord = (record) => {
    let _searchRecords = this.state.searchrecords;
  
    let errors = {};
    const index = findWithAttr(_searchRecords, "user_id", record.user_id);
    _searchRecords = Object.assign({}, _searchRecords[index]);

    _searchRecords.gender = _searchRecords.gender.value;
    _searchRecords.designation = _searchRecords.designation;
    _searchRecords.speciality = _searchRecords.speciality;
    _searchRecords.marital_status = _searchRecords.marital_status;
    _searchRecords.blood_group = _searchRecords.blood_group;
    _searchRecords.user_type = "employee_list";
    errors = Validation(_searchRecords, errors);

    const searchrecords = this.state.searchrecords;
    searchrecords[index].errors = errors;
    this.setState({ searchrecords: searchrecords });
   
    // return false;
    if (!errors.formIsValid) {
      return;
    }

    const data = Object.assign({}, this.state.searchrecords[index]);
    data.date_of_birth = data.dob;
    data.emergency_number = data.emergency_contact;
    data.blood_group = data.blood_group;
    data.gender = data.gender.label;
    data.designation = data.designation.value;
    data.marital_status = data.marital_status;
    data.speciality = data.speciality;
    data.Specialities = record.Specialities;
    data.business_id = this.props.userdata.id;
    delete data.dob;
    delete data.emergency_contact;
    delete data.errors;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(checkadddoctorvalidity, {user_id:this.props.userdata.id,user_type : this.props.userdata.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.adddoctorvalidity);
        if (data.status == "1")
          this.setState({
            doctorvalidity: data.adddoctorvalidity
            
          });
          if(data.adddoctorvalidity==false)
          {
            this.setState({
              showadddoctorPopup: true,
              loading:false
              
            });
          }else
          {
            this.setState({ actions: "add", loading: true });
            let records = this.state.records;
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            let requestdata = {
              business_id: this.props.userdata.id,
              business_user_id: record.user_id,
              consulting_fees: record.consulting_fees
            };
        
            axios
              .post(addBusinessUserToBusiness, requestdata, CONFIG)
              .then((res) => {
                let resData = res.data;
                
                if (resData.status === "1") {
          
                  record.mobile_number = record.phone_number;
                  
                  let records = this.state.records;
                  records.push(record);
        
                  this.setState({
                    records: records,
                     searchTableSuccess: resData,
                     loading: false,
                  });
                  
                  toast.success("Employee added to the Hospital Successfully.",{
                    className:"toast-success"
                  });
                  
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.props.access_token}`;
            axios
              .post(
                getAllBusinessUserList,
                { business_id: this.props.userdata.id },
                CONFIG
              )
              .then((list) => {
                let users = [];
                let userlist = list.data.data;
                userlist.sort((a,b) => a.id - b.id);
                userlist.forEach((element, index) => {
                  let user = {};
                  user.s_no = index + 1;
                  user.employee_id = element.id;
                  user.status = element.status;
        
                  user.user_id = element.user_id;
                  user.professional_id = element.professional_id;
                  user.email = element.email;
                  user.doc_status = element.doc_status;
                  user.first_name = element.first_name;
                  user.last_name = element.last_name;
                  user.designation = element.designation;
                  user.speciality = element.speciality;
                  user.Specialities = element.Specialities;
                  user.dob = element.date_of_birth;
                  user.gender = element.gender;
                  user.marital_status = element.marital_status;
                  user.blood_group = element.blood_group;
               
                  user.mobile_number = (element.phone_number.includes(element.phone_code) || element.phone_code== null )? element.phone_number :
                    element.phone_code.concat(element.phone_number);
                  user.phone_code = element.phone_code;
                  user.emergency_contact = element.emergency_number;
                  user.consulting_fees = element.consulting_fees;
                  users[index] = user;
                 
                });
        
                this.setState({ records: users });
              })
              .catch((e) => { console.error(e) });
        
        
                  this.clearMessage();
                  if(record.designation == "doctor"||record.designation == "Doctor"){
        //on success of adding the doctor to hospital call shift planner
        this.createEvent(record);
        
                  }
                  
                } else {
                  this.setState({
                    records: records,
                    searchTableSuccess: resData,
                    loading: false,
                  });
                  toast.error("Your Employee details couldn’t be added.",{
                    className:"toast-error"
                  });
                  this.clearMessage();
                }
              })
              .catch((e) => { });
          }
        })
        .catch((e) => { });
  
  };

  createEvent(record) {
    
    let data = {};
    console.log(record); 
    axios
    .post(
      getBusinessById,
      { business_id: this.props.userdata.id },
      AUTH_CONFIG
    )
    .then((response) => {
      console.log("userData");
      console.log(response.data);
      if (response.data.status === "1") 
      {
        let hospitalData = response.data.data[0];
      console.log(hospitalData);
      data.businessUserid = record.user_id;
      data.businessId = this.props.userdata.id;
      data.startDate = getDateISOFormat(new Date());
  
      var future = new Date();
      future.setDate(future.getDate() + 30);
  
      data.endDate = getDateISOFormat(future);
      data.isAllDayEvent = false;
     
     if (undefined === record.working_days||null===record.working_days) {
       
        data.frequency = getFrequencyFromDays(hospitalData.working_days);
      }else
      {
        data.frequency = getFrequencyFromDays(JSON.parse(record.working_days));
        
      }
      data.status = "Scheduled";
      if (undefined === record.schedule_type||null===record.schedule_type)
       {
        data.scheduleType = hospitalData.schedule_type;
      }
      else
      {
        data.scheduleType=record.schedule_type;
      }
      if (undefined === record.working_hours||null===record.working_hours) {
        
        data.sessions = gethhmmss(hospitalData.working_hours);
      }else
      {
        data.sessions = gethhmmss(JSON.parse(record.working_hours));
        
      }
      this.createApiCall(data);
       
      /* if (data.sessions == null || undefined === data.sessions||data.sessions=="") {
        data.sessions = gethhmmss(hospitalData.working_hours);
      }
      if (data.frequency == null || undefined === data.frequency||data.frequency=="") {
        data.frequency = getFrequencyFromDays(hospitalData.working_days);
      }
      data.scheduleType = hospitalData.schedule_type;
      console.log("inside recall")
      console.log(data);
      this.createApiCall(data); */
    }
 



  /*   if (null !== record.working_hours && null !== record.working_days) {
      console.log("inside else")
      this.createApiCall(data);
    } else {
      axios
        .post(
          getBusinessById,
          { business_id: this.props.userdata.id },
          AUTH_CONFIG
        )
        .then((response) => {
          console.log("userData");
          console.log(response.data);
          if (response.data.status === "1") {
            let hospitalData = response.data.data[0];
          console.log(hospitalData);
            if (data.sessions == null || undefined === data.sessions||data.sessions=="") {
              data.sessions = gethhmmss(hospitalData.working_hours);
            }
            if (data.frequency == null || undefined === data.frequency||data.frequency=="") {
              data.frequency = getFrequencyFromDays(hospitalData.working_days);
            }
            data.scheduleType = hospitalData.schedule_type;
            console.log("inside recall")
            console.log(data);
            this.createApiCall(data);
          }
        });
    } */
  });


    //email notification
    let reqdata = {
      "to" : record.email,
      "subject" : "Registration Success",

      "mailHeader" : "Welcome to Nucleo",
      "message": "Hi "+ record.first_name +",Your account has been created successfully.Reset your password using this link below \r\n"+BaseUrl+"/reset-password/"+ record.user_id+" \r\n Or copy and paste the URL into your browser:\r\n"+BaseUrl+"/reset-password/"+ record.user_id+"\r\nThanks, The Nucleo team."

    };
    let notificationdata = {
      "message_code": "ADD_BUSINESSUSER",
      "language_code": "en",
      "data": {
        "first_name": record.first_name,
        "base_url": BaseUrl,
        "user_id":record.user_id

      },
      "receiver": {
        "phoneNumber": record.mobile_number
      }
    };
    
      
      axios
        .post(sendemail, reqdata)
        .then((res) => {
          let resdata = res.data;


          
          axios
          .post(sendsms, notificationdata)
          .then((result) => {
            console.log(result)
          }).catch((err) => {
            console.log(err);
          })
          console.log(resdata);
          this.setState({ loading: false });
          let data = {
            message:"",
            status:""
          }
          if (resdata===true) {
            data.error_type = "success";
            data.message = "Registration Success"
            data.status = "1"
            this.setState({
              formSuccess: data,
            });
            this.setState({ loading: false });
            this.clearMessage();
          } else {
            data.error_type = "error";
            data.message = "Failed"
            data.status = "0"
            this.setState({
              formSuccess: data,
            });
            this.setState({ loading: false });
            this.clearMessage();
          }
        })
        .catch((e) => { });




  }

  createApiCall(data) {

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(createShiftPlanner, data, CONFIG)
      .then((res) => {
        let customMsg = {};
        if (res.data.status === 1) {
          customMsg = {
            status: res.data.status,
            message: "Bussiness user add successfully & Events created"
          }
          toast.success("Shifts created successfully for the Employee .",{
            className:"toast-success"
          });
        } else {
          customMsg = {
            status: res.data.status,
            message: "Bussiness user add successfully & Events Failed"
          }
          toast.error("Shifts could not be created for the Employee .",{
            className:"toast-error"
          });
        }

        this.setState({
          addEmployeeSuccess: customMsg,
          loading: false,
        });
        this.clearMessage();
      })
      .catch((e) => {
        let customMsg = {};
        customMsg = {
          status: 0,
          message: "Bussiness user could not added successfully"
        }
        this.setState({
          addEmployeeSuccess: customMsg,
          loading: false,
        });
        toast.error("Your Employee details couldn’t be added.",{
          className:"toast-error"
        });
        this.clearMessage();
      });
  }

  editRecord(record) {
    console.log(record);// return false;
    let employeeData = this.state.employeeData;
    employeeData.first_name = record.first_name;
    employeeData.last_name = record.last_name;
    employeeData.doc_status = record.doc_status;
    employeeData.date_of_birth = moment(record.dob).toDate();
    employeeData.phone_number = record.mobile_number;
    // employeeData.phone_number = (record.mobile_number.includes(record.phone_code) && record.phone_code !== null) ? record.mobile_number : record.phone_code.concat(record.mobile_number);
    employeeData.emergency_contact = record.emergency_contact;
    employeeData.employee_id = record.employee_id;
    employeeData.user_id = record.user_id;
    employeeData.professional_id = record.professional_id;
    employeeData.email = record.email;
   employeeData.business_id=this.props.userdata.id;
    employeeData.marital_status = {
      label: record.marital_status,
      value: record.marital_status,
    };
    employeeData.blood_group = {
      label: record.blood_group,
      value: record.blood_group,
    };
    employeeData.designation = {
      label: record.designation,
      value: record.designation,
    };
    //console.log(employeeData);
    //console.log(record);
    if (record.designation === "doctor" || record.designation==="Doctor") {
      this.setState({ showDocFields: true });
    } else {
      this.setState({ showDocFields: false });
    }
    let specialities_array = this.props.appdata
    ? this.props.appdata[0].specialities
    : [];
let speciality = [];
console.log(record.speciality);

    if(record.speciality){
      specialities_array.forEach((element, index) => {
        
        if (record.speciality.includes(element.id) === true && record.speciality===element.id.toString()) {
          console.log(element)
        // if (record.speciality && record.speciality.indexOf(element.id) > -1) {
          
          speciality.push({
            value: element.id,
            label: element.name,
          });
        }
      });
    }
  console.log(speciality)
  


    employeeData.speciality =speciality;
   /*  if(employeeData.speciality==""||employeeData.speciality==undefined)
    {
      employeeData.speciality=record.speciality;
    } */
    employeeData.consulting_fees = record.consulting_fees;

    employeeData.gender = {
      label: record.gender,
      value: record.gender,
    };
    console.log(employeeData);
    
    this.setState({
      editRecord: record,
      employeeData: employeeData,
      actions: "edit",
    });
    
  }

  deleteRecord(record) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let data = {
      business_id: this.props.userdata.id,
      business_user_id: record.user_id,
    };
    axios
      .post(removeBusinessUserFromBusiness, data, CONFIG)
      .then((res) => {
        const resData = res.data;
        console.log(resData);
        if (res.status !== 200 || !resData || resData.status === "0") {
          this.setState({
            errorMessage: "Unexpected error occured. Please try again.",
          });
          this.clearMessage();
          return;
        }

        // remove schedule api
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        let removedata = {
          "removeBusinessUserEvents": true,
          "businessUserid": record.user_id,
          "businessId": this.props.userdata.id
        }

        axios
          .post(removeshiftplanner, removedata, CONFIG)
          .then((res) => {
            let records = this.state.records;
            const deleteIndex = records.indexOf(record);
            records.splice(deleteIndex, 1);
            this.setState({
              records        
            });
           /*  this.setState({
              
              successMessage: "Employee schedules deleted successfully."
            }); */
            toast.error("Your Employee details have been deleted successfully.",{
              className:"toast-error"
            });
            this.clearMessage();
          }).catch((e) => {
            this.setState({
              errorMessage: "Failed to delete employee schedules.",
            });
            toast.error("Employee could not be deleted.",{
              className:"toast-error"
            });
            this.clearMessage();
          })


      })
      .catch((e) => {
        this.setState({
          errorMessage: "Employee failed to delete.",
        });
        this.clearMessage();
      });
    this.setState({ deletePopup: false, record: null });
  }

  handleInputChange = (e) => {
    let data = this.state.employeeData;
    let errors=this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
   if (e.target.name === "consulting_fees") {
    data[e.target.name] = e.target.value.replace(/[^0-9]/ig, '');
  }else
  {
    data[name] = value;
  }
    errors[name] = ""
    this.setState({ employeeData: data,errors });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;
    let errors=this.state.errors;
    data[e.name] = selectedOptions;
    this.setState({ selectedOptions: data });
  };

  handleselectChange = (selectedOptions, e) => {

    let data = this.state.employeeData;
    let errors=this.state.errors;
    if (e.name === "designation") {
      if (selectedOptions.value === "doctor") {
        this.setState({ showDocFields: true });
      } else {
        this.setState({ showDocFields: false });
      }
    }

    data[e.name] = selectedOptions;
    errors[e.name] = ""
    this.setState({ employeeData: data,errors });
  };

  handlespecialityselectChange = (selectedOptions, e) => {
    let data = this.state.employeeData;
    let errors=this.state.errors;

    data[e.name] = selectedOptions;
    errors[e.name] = ""
    this.setState({ employeeData: data,errors });
  };
  handlespcialityColumnChange = (record) => {
    record.speciality = record.speciality ? record.specialities:"";
    this.UpdateColumnChange(record);
  };

  handleDOBChange = (date) => {
    let employeeData = this.state.employeeData;
    let errors=this.state.errors;
    const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    employeeData.date_of_birth = offsetDate;
    errors.date_of_birth="";
    this.setState({ employeeData: employeeData,errors });
  };

  handlePhoneInputChange = (event) => {
    let employeeData = this.state.employeeData;
    let errors=this.state.errors;
    employeeData.phone_number = event;
    errors.phone_number="";
    this.setState({ employeeData,errors });
  };

  handleEmergencyPhoneInputChange = (event) => {
    let employeeData = this.state.employeeData;
    employeeData.emergency_contact = event;
    this.setState({ employeeData });
  };

  handleSearchInput = (e) => {
    let data = this.state.searchData;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    this.setState({ searchData: data });
  };

  handleSearchPhoneInputChange = (event) => {
    let searchData = this.state.searchData;
    searchData.phone_number = event;
    this.setState({ searchData });
  };

  handleSearchSelectChange = (value, record, field) => {
    value[field] = record;
    this.UpdateColumnChange(value);
  };
  handledobsearch(date, record) {
    record.dob = date;
    this.UpdateColumnChange(record);
  }
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;

    this.UpdateColumnChange(record);
  };

  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "user_id", record.user_id);
    records[recIndex].employee_id = record.employee_id;
    records[recIndex].user_id = record.user_id;
    records[recIndex].professional_id = record.professional_id;
    records[recIndex].email = record.email;
    records[recIndex].first_name = record.first_name;
    records[recIndex].last_name = record.last_name;
    records[recIndex].designation = record.designation;
    records[recIndex].speciality = record.speciality;
    records[recIndex].Specialities = record.Specialities;
    records[recIndex].dob = record.dob;
    records[recIndex].gender = record.gender;
    records[recIndex].marital_status = record.marital_status;
    records[recIndex].blood_group = record.blood_group;
    records[recIndex].mobile_number = record.mobile_number;
    records[recIndex].emergency_contact = record.emergency_contact;
    records[recIndex].consulting_fees = record.consulting_fees;

    this.setState({ searchrecords: records });
  };

  clearsearch = () => {
    var searchData = this.state.searchData;
    var search_action=this.state.search_action;
    let errors=this.state.errors;
    searchData.first_name="";
    searchData.last_name="";
    searchData.phone_number="";
    searchData.employee_id="";
    searchData.speciality="";
    searchData.designation="";
    searchData.consulting_fees="";
    errors.id="";
   // errors.consulting_fees ="";
    this.setState({search_action: false,searchErrorMessage:"" });
    
  };
  
  searchAndShowEmployee() {
    let searchData = this.state.searchData;
    console.log(searchData.phone_number.length);
    if(searchData.phone_number!==""){
    if (searchData.phone_number.length !== 13) {
        this.setState({  searchErrorMessage:  "Enter a valid phone number"});
      return;
    }
  }
    if (
      (searchData.first_name === null || searchData.first_name === "") &&
      (searchData.last_name === null || searchData.last_name === "") &&
      (searchData.phone_number === "" || searchData.phone_number === null) &&
      (searchData.employee_id === "" || searchData.employee_id === null) &&
      (searchData.speciality === "" || searchData.speciality === null) &&
      (searchData.designation === "" || searchData.designation === null)
    ) {
      this.setState({
        searchErrorMessage:
          "Choose any one of the search filter to search.",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }
    let data = {};
  let errors=this.state;
    data.speciality = searchData.speciality.value;
    data.designation = searchData.designation.value;
    data.first_name = searchData.first_name;
    data.last_name = searchData.last_name;
    
    data.phone_number =(searchData.phone_number!=="")? parsePhoneNumber(searchData.phone_number).nationalNumber:"";
    data.id = searchData.employee_id;
   /*  data.user_type = "search_employee_list";
    errors = Validation(data, errors);
    this.setState({ errors: errors }); */
    console.log(data);
 //    if(errors.formIsValid)
 //   { 
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      console.log(data);
      axios.post(searchEmployee, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        console.log(result);
        if (result.countOfRecords > 0) {
          let records = result.data;
          //console.log(searchdata.designation);
          //console.log(result);
          let users = [];
          records.forEach((element,index) => {
            users.push({
              s_no : index + 1,
              employee_id: element.id,
              user_id: element.user_id,
              professional_id: element.id,
              first_name: element.first_name,
              last_name: element.last_name,
              consulting_fees: element.consulting_fees ? element.consulting_fees : "",
              dob: moment(element.date_of_birth).toDate(),
              phone_number: element.phone_number.includes(element.phone_code) ? element.phone_number : element.phone_code + element.phone_number,
              emergency_contact: element.emergency_number,
              email: element.email,
              designation: element.designation,
              schedule_type:element.schedule_type,
              // speciality: {
              //   label: element.speciality,
              //   value: element.speciality,
              // },
              speciality: element.speciality,
              Specialities: element.Specialities,
              // gender: {
              //   label: element.gender,
              //   value: element.gender,
              // },
              gender: element.gender,
              // marital_status: {
              //   label: element.marital_status,
              //   value: element.marital_status,
              // },
              // blood_group: {
              //   label: element.blood_group,
              //   value: element.blood_group,
              // },

              marital_status: element.marital_status,
              blood_group: element.blood_group,
              working_days: (element.working_days),
              working_hours: (element.working_hours),
              // consulting_fees: '',

              errors: {},
            });
          });

          this.setState({ searchrecords: users, search_action: true });
        } else {
          this.setState({ searchrecords: [], search_action: true });
        }
      });
    });
 // }
  }

  saveEmployee = (e) => {

    e.preventDefault();
  
  
    let { employeeData, errors, editRecord } = this.state;
    console.log(editRecord);
    let data = employeeData;
    data.user_type = "employee_list";
    
    console.log(data);
    errors = Validation(employeeData, errors);
   
    console.log(errors)
    this.setState({ errors: errors });
    // return false;
    if (!errors.formIsValid) {
      console.log('hiiii')
      return;
    }
    // return false;
    console.log(data);
    data.emergency_number = data.emergency_contact;
    data.blood_group = data.blood_group ? data.blood_group.label:"";
    data.gender = data.gender.label;
    data.marital_status = data.marital_status.label;
    data.designation = data.designation.value;
    data.speciality = data.speciality?data.speciality.value:"";
   
    data.business_id = this.props.userdata.id;

    // delete data.user_type;
    // delete data.emergency_contact;
    if (this.state.actions === "edit") {
      data.user_id = editRecord.user_id;
     
    }
    data.phone_code = "+" + parsePhoneNumber(data.phone_number).countryCallingCode;
    data.phone_number = parsePhoneNumber(data.phone_number).nationalNumber;

    //console.log(data); return false;
    this.setState({ loading: true });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    let records = this.state.records;
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    data.user_type = data.designation;
   
    if((data.designation=="Doctor"|| data.designation=="doctor") && (this.state.actions !== "edit"))
    {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
  
       axios
        .post(checkadddoctorvalidity, {user_id:this.props.userdata.id,user_type : this.props.userdata.user_type}, CONFIG)
        .then((res) => {
          let checkdata = res.data;
          console.log(res);
          console.log(checkdata);
          console.log(checkdata.adddoctorvalidity);
          if (checkdata.status == "1")
            this.setState({
              doctorvalidity: checkdata.adddoctorvalidity,
              
            });
            if(checkdata.adddoctorvalidity==false)
            {
              this.setState({
                showadddoctorPopup: true,
                loading:false,
             
              });
              
            }
            if(checkdata.adddoctorvalidity==true)
            { 
              let { employeeData, errors, editRecord } = this.state;
              let data = employeeData;
              if (this.state.actions === "add") {
                console.log(data);
                axios
               .post(addEmployee, data, CONFIG)
  
               .then((res) => {
            
            let resData = res.data;
            console.log(resData.data);
            console.log(resData.status);
            if (resData.status === "1") {
              this.setState({  
                errorMessage: "",
              addEmployeeSuccess: { status: "", message: "" }});
              let user = {};
              let records = this.state.records;
              user.s_no = records.length + 1;
              user.employee_id = resData.data;
              console.log(user);
              console.log(resData);
              user.user_id = resData.user.id;
              user.professional_id = resData.data;
              user.email = employeeData.email;
              user.first_name = employeeData.first_name;
              user.last_name = employeeData.last_name;
              user.designation = employeeData.designation;
              user.speciality = employeeData.speciality;
              user.dob = employeeData.date_of_birth;
              user.gender = employeeData.gender;
              user.marital_status = employeeData.marital_status;
              user.blood_group = employeeData.blood_group;
              user.doc_status = "Yes";
              // user.mobile_number = employeeData.phone_number;
              // console.log(employeeData)
              
              
              user.mobile_number = (employeeData.phone_number.includes(employeeData.phone_code)|| employeeData.phone_code === null) ? employeeData.phone_number : employeeData.phone_code.concat(employeeData.phone_number);
              user.emergency_contact = employeeData.emergency_number;
              user.consulting_fees = employeeData.consulting_fees;
  
  
  
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (employeeData.speciality === element.id) {
                  user.speciality = element.id;
                  user.Specialities = element.name;
                }
                
              });
  console.log(user); //return false;
              records.push(user);
              this.setState({
                records: records,
          //        addEmployeeSuccess: resData,
                 loading: false,
              });
              toast.success("The Employee details have been added successfully.",{
                className:"toast-success"
              });
              this.clearMessage();
              this.clearForm();
              
              console.log(user);
              if(employeeData.designation == "doctor"||employeeData.designation == "Doctor"){
                //on success of adding the doctor to hospital call shift planner
                this.createEvent(user);
              }
              
            } else {
              let empdata = employeeData;
  
              empdata.designation = {
                label: employeeData.designation,
                value: employeeData.designation,
              };
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (employeeData.speciality === element.id) {
                  empdata.speciality = {
                    label: element.name,
                    value: element.id
                  };
                }
                console.log(employeeData.speciality);
              });
  
  
              empdata.gender = {
                label: employeeData.gender,
                value: employeeData.gender,
              };
              empdata.blood_group = {
                label: employeeData.blood_group,
                value: employeeData.blood_group,
              };
              empdata.marital_status = {
                label: employeeData.marital_status,
                value: employeeData.marital_status,
              };
              console.log(employeeData);
              let phncode = employeeData.phone_code;
              console.log(phncode);
              empdata.phone_number = employeeData.phone_number.includes(phncode) ? employeeData.phone_number : employeeData.phone_code.concat(employeeData.phone_number);
  
              empdata.phone_code = employeeData.phone_code
              this.setState({
                records: records,
                addEmployeeSuccess: resData,
                employeeData: empdata,
                loading: false,
              });
        //      window.location.reload();
            }
          })
          .catch((e) => { console.log(e) });
      } else {
         if(data.speciality==undefined)
        {
        data.speciality=editRecord.speciality;
        }
        
        console.log(data.speciality);
        let specialities_array = this.props.appdata
          ? this.props.appdata[0].specialities
          : [];
        axios
          .put(updateEmployee, data, CONFIG)
          .then((list) => {
            let user = {};
            console.log(list);
            if (list.data.status === "1") {
              this.setState({ loading: false });
              toast.success("The Employee details have been updated successfully.",{
                className:"toast-success"
              });
                    
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          getAllBusinessUserList,
          { business_id: this.props.userdata.id },
          CONFIG
        )
        .then((list) => {
          let users = [];
          let userlist = list.data.data;
          userlist.forEach((element, index) => {
            let user = {};
            user.s_no = index + 1;
            user.employee_id = element.id;
            user.status = element.status;
  
            user.user_id = element.user_id;
            user.professional_id = element.professional_id;
            user.email = element.email;
            user.doc_status = element.doc_status;
            user.first_name = element.first_name;
            user.last_name = element.last_name;
            user.designation = element.designation;
            user.speciality = element.speciality;
            user.Specialities = element.Specialities;
            user.dob = element.date_of_birth;
            user.gender = element.gender;
            user.marital_status = element.marital_status;
            user.blood_group = element.blood_group;
         
            user.mobile_number = (element.phone_number.includes(element.phone_code) || element.phone_code== null )? element.phone_number :
              element.phone_code.concat(element.phone_number);
            user.phone_code = element.phone_code;
            user.emergency_contact = element.emergency_number;
            user.consulting_fees = element.consulting_fees;
            users[index] = user;
            userlist.sort((a,b) => a.id - b.id);
          });
  
          this.setState({ records: users });
        })
        .catch((e) => { console.error(e) });
  
  
              this.clearMessage();
              this.clearForm();
              console.log(data);
              user.employee_id = data.employee_id;
  
              console.log(user);
  
              user.user_id = data.user_id;
              user.professional_id = data.employee_id;
              user.email = data.email;
              user.first_name = data.first_name;
              user.last_name = data.last_name;
              user.doc_status = data.doc_status;
  
              // user.speciality = employeeData.speciality.label;
  
  
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (user.speciality === element.id) {
                  user.speciality = element.id;
                  user.Specialities = element.name;
                }
              });
              user.speciality=user.speciality.value;
              const offsetDate = new Date(data.date_of_birth.getTime() - (data.date_of_birth.getTimezoneOffset() * 60000));
              data.date_of_birth = offsetDate;
             
              user.dob = offsetDate;
              user.gender = data.gender;
              user.marital_status = data.marital_status;
              user.blood_group = data.blood_group;
              // user.mobile_number = data.phone_number;
              user.mobile_number = data.phone_code.concat(data.phone_number)
  
              user.emergency_contact = data.emergency_number;
              user.consulting_fees = data.consulting_fees;
              user.designation = data.designation;
              /** Updating the data table */
              let existingRecords = this.state.records;
              var index = existingRecords.findIndex(
                (item) => item.employee_id === data.employee_id
              );
              existingRecords.splice(index, 1, user);
              
              this.setState({ records: existingRecords });
  
              this.setState({ records});
              //this.setState({  addEmployeeSuccess: list.data });
            
              
             //window.location.reload();
            } else {
              let empdata = employeeData;
              console.log(empdata)
  
  
  
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (employeeData.speciality === element.id) {
                  empdata.speciality = {
                    label: element.name,
                    value: element.id
                  };
                }
              });
  
              empdata.gender = {
                label: employeeData.gender,
                value: employeeData.gender,
              };
              empdata.blood_group = {
                label: employeeData.blood_group,
                value: employeeData.blood_group,
              };
              empdata.marital_status = {
                label: employeeData.marital_status,
                value: employeeData.marital_status,
              };
              empdata.phone_number = employeeData.phone_code.concat(employeeData.phone_number)
              empdata.phone_code = employeeData.phone_code;
              this.setState({
                records,
                addEmployeeSuccess: list.data,
                employeeData: empdata,
                loading: false
              });
           //   window.location.reload();
            }
          })
          .catch((e) => { });
      }

    }
          })
        .catch((e) => { });
  
    }
    if(data.designation=="Doctor"|| data.designation=="doctor" && this.state.actions == "edit")
    {
      if(data.speciality==undefined)
      {
      data.speciality=editRecord.speciality;
      }
      
      let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    axios
      .put(updateEmployee, data, CONFIG)
      .then((list) => {
        let user = {};
        console.log(list);
        if (list.data.status === "1") {
          this.setState({ loading: false });
           toast.success("The Employee details have been updated successfully.",{
            className:"toast-success"
          }); 
          this.clearMessage();
          this.clearForm();

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(
              getAllBusinessUserList,
              { business_id: this.props.userdata.id },
              CONFIG
            )
            .then((list) => {
              let users = [];
              let userlist = list.data.data;
              userlist.sort((a,b) => a.id - b.id);
              userlist.forEach((element, index) => {
                let user = {};
                user.s_no = index + 1;
                user.employee_id = element.id;
                user.status = element.status;
      
                user.user_id = element.user_id;
                user.professional_id = element.professional_id;
                user.email = element.email;
                user.doc_status = element.doc_status;
                user.first_name = element.first_name;
                user.last_name = element.last_name;
                user.designation = element.designation;
                user.speciality = element.speciality;
                user.Specialities = element.Specialities;
                user.dob = element.date_of_birth;
                user.gender = element.gender;
                user.marital_status = element.marital_status;
                user.blood_group = element.blood_group;
             
                user.mobile_number = (element.phone_number.includes(element.phone_code) || element.phone_code== null )? element.phone_number :
                  element.phone_code.concat(element.phone_number);
                user.phone_code = element.phone_code;
                user.emergency_contact = element.emergency_number;
                user.consulting_fees = element.consulting_fees;
                users[index] = user;
              
              });
      
              this.setState({ records: users });
            })
            .catch((e) => { console.error(e) });



          this.clearMessage();
          this.clearForm();
          console.log(data);
          user.employee_id = data.employee_id;

          console.log(user);

          user.user_id = data.user_id;
          user.professional_id = data.employee_id;
          user.email = data.email;
          user.first_name = data.first_name;
          user.last_name = data.last_name;
          user.doc_status = data.doc_status;

          // user.speciality = employeeData.speciality.label;


          specialities_array.forEach((element, index) => {
            // console.log(details.speciality.indexOf(element.id));

            if (user.speciality === element.id) {
              user.speciality = element.id;
              user.Specialities = element.name;
            }
          });
          user.speciality=user.speciality.value;
          const offsetDate = new Date(data.date_of_birth.getTime() - (data.date_of_birth.getTimezoneOffset() * 60000));
          data.date_of_birth = offsetDate;
         
          user.dob = offsetDate;
          user.gender = data.gender;
          user.marital_status = data.marital_status;
          user.blood_group = data.blood_group;
          // user.mobile_number = data.phone_number;
          user.mobile_number = data.phone_code.concat(data.phone_number)

          user.emergency_contact = data.emergency_number;
          user.consulting_fees = data.consulting_fees;
          user.designation = data.designation;
          /** Updating the data table */
          let existingRecords = this.state.records;
          var index = existingRecords.findIndex(
            (item) => item.employee_id === data.employee_id
          );
          existingRecords.splice(index, 1, user);
          
          this.setState({ records: existingRecords });

          this.setState({ records});
          //this.setState({  addEmployeeSuccess: list.data });
        
          
         //window.location.reload();
        } else {
          let empdata = employeeData;
          console.log(empdata)



          specialities_array.forEach((element, index) => {
            // console.log(details.speciality.indexOf(element.id));

            if (employeeData.speciality === element.id) {
              empdata.speciality = {
                label: element.name,
                value: element.id
              };
            }
          });

          empdata.gender = {
            label: employeeData.gender,
            value: employeeData.gender,
          };
          empdata.blood_group = {
            label: employeeData.blood_group,
            value: employeeData.blood_group,
          };
          empdata.marital_status = {
            label: employeeData.marital_status,
            value: employeeData.marital_status,
          };
          empdata.phone_number = employeeData.phone_code.concat(employeeData.phone_number)
          empdata.phone_code = employeeData.phone_code;
          this.setState({
            records,
            addEmployeeSuccess: list.data,
            employeeData: empdata,
            loading: false
          });
       //   window.location.reload();
        }
      })
      .catch((e) => { });
        
    
   
    }
    console.log(this.state.doctorvalidity);
   if(data.designation=="Staff" ||data.designation=="Nurse")
    {
      if (this.state.actions === "add") {
        console.log(data);
        axios
          .post(addEmployee, data, CONFIG)
  
          .then((res) => {
            
            let resData = res.data;
            console.log(resData.data);
            console.log(resData.status);
            if (resData.status === "1") {
              this.setState({  
                errorMessage: "",
              addEmployeeSuccess: { status: "", message: "" }});
              let user = {};
              user.employee_id = resData.data;
              console.log(user);
              console.log(resData);
              user.user_id = resData.user.id;
              user.professional_id = resData.data;
              user.email = employeeData.email;
              user.first_name = employeeData.first_name;
              user.last_name = employeeData.last_name;
              user.designation = employeeData.designation;
              user.speciality = employeeData.speciality;
              user.dob = employeeData.date_of_birth;
              user.gender = employeeData.gender;
              user.marital_status = employeeData.marital_status;
              user.blood_group = employeeData.blood_group;
              user.doc_status = "Yes";
              // user.mobile_number = employeeData.phone_number;
              // console.log(employeeData)
              
              
              user.mobile_number = (employeeData.phone_number.includes(employeeData.phone_code)|| employeeData.phone_code === null) ? employeeData.phone_number : employeeData.phone_code.concat(employeeData.phone_number);
              user.emergency_contact = employeeData.emergency_number;
              user.consulting_fees = employeeData.consulting_fees;
  
  
  
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (employeeData.speciality === element.id) {
                  user.speciality = element.id;
                  user.Specialities = element.name;
                }
                
              });
                            console.log(user); //return false;
              records.push(user);
              this.setState({
                records: records,
          //        addEmployeeSuccess: resData,
                 loading: false,
              });
              toast.success("The Employee details have been added successfully.",{
                className:"toast-success"
              });
              this.clearMessage();
              this.clearForm();
              
              console.log(user);
              if(employeeData.designation == "doctor"||employeeData.designation == "Doctor"){
                //on success of adding the doctor to hospital call shift planner
                this.createEvent(user);
              }
              
            } else {
              let empdata = employeeData;
  
              empdata.designation = {
                label: employeeData.designation,
                value: employeeData.designation,
              };
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (employeeData.speciality === element.id) {
                  empdata.speciality = {
                    label: element.name,
                    value: element.id
                  };
                }
                console.log(employeeData.speciality);
              });
  
  
              empdata.gender = {
                label: employeeData.gender,
                value: employeeData.gender,
              };
              empdata.blood_group = {
                label: employeeData.blood_group,
                value: employeeData.blood_group,
              };
              empdata.marital_status = {
                label: employeeData.marital_status,
                value: employeeData.marital_status,
              };
              console.log(employeeData);
              let phncode = employeeData.phone_code;
              console.log(phncode);
              empdata.phone_number = employeeData.phone_number.includes(phncode) ? employeeData.phone_number : employeeData.phone_code.concat(employeeData.phone_number);
  
              empdata.phone_code = employeeData.phone_code
              this.setState({
                records: records,
                addEmployeeSuccess: resData,
                employeeData: empdata,
                loading: false,
              });
        //      window.location.reload();
            }
          })
          .catch((e) => { console.log(e) });
      } else {
         if(data.speciality==undefined)
        {
        data.speciality=editRecord.speciality;
        }
        
        console.log(data.speciality);
        let specialities_array = this.props.appdata
          ? this.props.appdata[0].specialities
          : [];
        axios
          .put(updateEmployee, data, CONFIG)
          .then((list) => {
            let user = {};
            console.log(list);
            if (list.data.status === "1") {
              this.setState({ loading: false });
              toast.success("The Employee details have been updated successfully.",{
                className:"toast-success"
              });
                    
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(
          getAllBusinessUserList,
          { business_id: this.props.userdata.id },
          CONFIG
        )
        .then((list) => {
          let users = [];
          let userlist = list.data.data;
          userlist.forEach((element, index) => {
            let user = {};
            user.s_no = index + 1;
            user.employee_id = element.id;
            user.status = element.status;
  
            user.user_id = element.user_id;
            user.professional_id = element.professional_id;
            user.email = element.email;
            user.doc_status = element.doc_status;
            user.first_name = element.first_name;
            user.last_name = element.last_name;
            user.designation = element.designation;
            user.speciality = element.speciality;
            user.Specialities = element.Specialities;
            user.dob = element.date_of_birth;
            user.gender = element.gender;
            user.marital_status = element.marital_status;
            user.blood_group = element.blood_group;
         
            user.mobile_number = (element.phone_number.includes(element.phone_code) || element.phone_code== null )? element.phone_number :
              element.phone_code.concat(element.phone_number);
            user.phone_code = element.phone_code;
            user.emergency_contact = element.emergency_number;
            user.consulting_fees = element.consulting_fees;
            users[index] = user;
            userlist.sort((a,b) => a.id - b.id);
          });
  
          this.setState({ records: users });
        })
        .catch((e) => { console.error(e) });
  
  
              this.clearMessage();
              this.clearForm();
              console.log(data);
              user.employee_id = data.employee_id;
  
              console.log(user);
  
              user.user_id = data.user_id;
              user.professional_id = data.employee_id;
              user.email = data.email;
              user.first_name = data.first_name;
              user.last_name = data.last_name;
              user.doc_status = data.doc_status;
  
              // user.speciality = employeeData.speciality.label;
  
  
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (user.speciality === element.id) {
                  user.speciality = element.id;
                  user.Specialities = element.name;
                }
              });
              user.speciality=user.speciality.value;
              const offsetDate = new Date(data.date_of_birth.getTime() - (data.date_of_birth.getTimezoneOffset() * 60000));
              data.date_of_birth = offsetDate;
             
              user.dob = offsetDate;
              user.gender = data.gender;
              user.marital_status = data.marital_status;
              user.blood_group = data.blood_group;
              // user.mobile_number = data.phone_number;
              user.mobile_number = data.phone_code.concat(data.phone_number)
  
              user.emergency_contact = data.emergency_number;
              user.consulting_fees = data.consulting_fees;
              user.designation = data.designation;
              /** Updating the data table */
              let existingRecords = this.state.records;
              var index = existingRecords.findIndex(
                (item) => item.employee_id === data.employee_id
              );
              existingRecords.splice(index, 1, user);
              
              this.setState({ records: existingRecords });
  
              this.setState({ records});
              //this.setState({  addEmployeeSuccess: list.data });
            
              
             //window.location.reload();
            } else {
              let empdata = employeeData;
              console.log(empdata)
  
  
  
              specialities_array.forEach((element, index) => {
                // console.log(details.speciality.indexOf(element.id));
  
                if (employeeData.speciality === element.id) {
                  empdata.speciality = {
                    label: element.name,
                    value: element.id
                  };
                }
              });
  
              empdata.gender = {
                label: employeeData.gender,
                value: employeeData.gender,
              };
              empdata.blood_group = {
                label: employeeData.blood_group,
                value: employeeData.blood_group,
              };
              empdata.marital_status = {
                label: employeeData.marital_status,
                value: employeeData.marital_status,
              };
              empdata.phone_number = employeeData.phone_code.concat(employeeData.phone_number)
              empdata.phone_code = employeeData.phone_code;
              this.setState({
                records,
                addEmployeeSuccess: list.data,
                employeeData: empdata,
                loading: false
              });
           //   window.location.reload();
            }
          })
          .catch((e) => { });
      }

    }
   
  };

  clearMessage() {
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
        addEmployeeSuccess: { status: "", message: "" },
        searchTableSuccess: { status: "", message: "" },
        formSuccess: { message: "" },
        tableSuccess: { message: "" },
      });
    }, 3000);
  }

  clearForm = () => {
    this.setState({
      actions: "add",
      editRecord: null,
      addEmployeeSuccess: { status: "", message: "" },
      employeeData: {
        first_name: "",
        last_name: "",
        gender: "",
        speciality: "",
        designation: "",
        date_of_birth: "",
        phone_number: "",
        blood_group: "",
        emergency_contact: "",
        marital_status: "",
        business_id: "",
        consulting_fees: "",
        email: "",
      },
      showDocFields:false,
      errors: {
        first_name: "",
        last_name: "",
        gender: "",
        speciality: "",
        designation: "",
        date_of_birth: "",
        phone_number: "",
        blood_group: "",
        emergency_contact: "",
        marital_status: "",
        email: "",
        business_id: "",
        consulting_fees: "",
        error: "",
      },
    });
  };

  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch((e) => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(
        getAllBusinessUserList,
        { business_id: this.props.userdata.id },
        CONFIG
      )
      .then((list) => {
        let users = [];
        let userlist = list.data.data;
        userlist.sort((a,b) => a.id - b.id);
        userlist.forEach((element, index) => {
          let user = {};
          user.s_no = index + 1;
          user.employee_id = element.id;
          user.status = element.status;

          user.user_id = element.user_id;
          user.professional_id = element.professional_id;
          user.email = element.email;
          user.first_name = element.first_name;
          user.last_name = element.last_name;
          user.designation = element.designation;
          user.speciality = element.speciality;
          user.Specialities = element.Specialities;
          user.dob = element.date_of_birth;
          user.gender = element.gender;
          user.marital_status = element.marital_status;
          user.blood_group = element.blood_group;
          user.doc_status = element.doc_status;
          console.log(element.phone_number);
          console.log(element.phone_code);
          user.mobile_number = (element.phone_number.includes(element.phone_code) || element.phone_code== null )? element.phone_number :
            element.phone_code.concat(element.phone_number);
          user.phone_code = element.phone_code;
          user.emergency_contact = element.emergency_number;
          user.consulting_fees = element.consulting_fees;
          users[index] = user;
         
        });

        this.setState({ records: users });
      })
      .catch((e) => { console.error(e) });

    let specialities = [];
    let specialities_array = this.props.appdata
      ? this.props.appdata[0].specialities
      : [];
    specialities_array.forEach((element, index) => {
      specialities.push({ value: element.id, label: element.name });
    });
    specialities_array = specialities;
    this.setState({ specialities_array: specialities_array });
  };


  render() {
    const {
      employeeData,
      errors,
      successMessage,
      errorMessage,
      loading,
      searchData,
      searchTableSuccess,
      specialities_array,
      addEmployeeSuccess,
      searchErrorMessage,
      hospitaltimeout
    } = this.state;
    let PopupClose = () => {
   //   window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    let DoctorPopupClose = () => {
              this.setState({ showadddoctorPopup: false })
       };
     
       let DoctorPopupConfirm = () => {
         window.location.href = "/showsubscription";
   
       };
    return (
      <div id="layout-wrapper">
        <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={PopupClose}
          onConfirm={PopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        />
        <DoctorPopup
          show={this.state.showadddoctorPopup}
          onHide={DoctorPopupClose}
          onConfirm={DoctorPopupConfirm}
          title={"Alert"}
          message={"You are not allowed to add more employee in the hospital. Please subscribe to another plan and try again"}
        />
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

      {/*   <Spinner loading={loading} /> */}
      {loading && <Dnaspinner />}
        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <section className="my-head">
              <div className="container-fluid"></div>
            </section>
            <div className="main-box">
              <div className="sidemenu pr0">
                <SideBar />
              </div>

              <section className="main-body hospital-profilegap">
                <div className="container-fluid">
                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                  <div className="checkout-tabs">




                    <h4 className="font-weight-bold">Employee List</h4>
                    {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

                    <div className="row mt-4">
                      <div className="col-sm-12">

                        <div className="card">
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="businessUserListTable">
                                      <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        className="table table-bordered table-striped kas  mobile_table"
                                      />
                                      <Popup
                                        show={this.state.deletePopup}
                                        onHide={() =>
                                          this.setState({
                                            deletePopup: false,
                                          })
                                        }
                                        onConfirm={() =>
                                          this.deleteRecord(this.state.record)
                                        }
                                        title={"Confirmation"}
                                        message={
                                          "Are you sure you want to delete this record?"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                        <div class="card-header"> <div class="card-title"> {this.state.actions === "add"
                                        ? "Add"
                                        : "Edit"}
                                      &nbsp;Employee</div> </div>
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                   
                                    {/* <div>
                                        <span style={{ color: "green" }}>
                                          {this.state.addEmployeeSuccess}
                                        </span>
                                      </div> */}
                                    <div className="user-notification">
                                      <SpanField
                                        classname={
                                          "0" === addEmployeeSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                        }
                                        title={addEmployeeSuccess.message}
                                      />
                                      <SpanField
                                        classname="text-success"
                                        title={successMessage}
                                      />
                                    </div>


                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="frllftfull">
                                          <div>
                                          <Label title="First Name" isMandatory={true} />  
                                          </div>
                                          <div className="form-group">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              name={"first_name"}
                                              controlFunc={
                                                this.handleInputChange
                                              }
                                              content={
                                                employeeData.first_name
                                              }
                                              placeholder={""}
                                              max={50}
                                            />
                                             <SpanField
                                            classname="text-danger"
                                            title={errors.first_name}
                                          />
                                          </div>
                                         
                                        </div>

                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Phone" isMandatory={true} />  
                                          </div>
                                          <div className="form-control">
                                            <div className="row m-0">
                                              <div className="phonenorith">
                                                <div className="form-group">
                                                  <PhoneInput
                                                    international
                                                    defaultCountry="IN"
                                                    placeholder="Enter phone number"
                                                    disabled={this.state.actions === "edit"?true:false}
                                                    value={
                                                      employeeData.phone_number
                                                    }
                                                    onChange={
                                                      this
                                                        .handlePhoneInputChange
                                                    }
                                                    error={
                                                      employeeData.phone_number
                                                        ? isValidPhoneNumber(
                                                          employeeData.phone_number
                                                        )
                                                          ? undefined
                                                          : "Invalid phone number"
                                                        : "Phone number required"
                                                    }
                                                  />
                                                  <SpanField
                                                  classname="text-danger"
                                                  title={errors.phone_number}
                                                />
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="frllftfull mt-3">
                                          <div>
                                          <Label title="Email" isMandatory={true} />    
                                          </div>
                                          <div className="row">
                                            <div className="form-group col-md-12">
                                              <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"email"}
                                                readOnly={this.state.actions === "edit"?true:false}
                                                controlFunc={
                                                  this.handleInputChange
                                                }
                                                content={employeeData.email}
                                                placeholder={""}
                                                max={50}
                                              />
                                              <SpanField
                                                classname="text-danger"
                                                title={errors.email}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Designation" isMandatory={true} /> 
                                          </div>
                                          <div className="form-group sform-control">
                                            <Select maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="designation"
                                              value={employeeData.designation}
                                              onChange={
                                                this.handleselectChange
                                              }
                                              options={designation}
                                            />
                                             <SpanField
                                            classname="text-danger"
                                            title={errors.designation}
                                          />
                                          </div>
                                         
                                        </div>



                                      </div>

                                      <div className="col-md-4">
                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Last Name" isMandatory={true} />    
                                          </div>
                                          <div className="form-group">
                                            <InputField
                                              isMandatory={true}
                                              inputType={"text"}
                                              name={"last_name"}
                                              controlFunc={
                                                this.handleInputChange
                                              }
                                              content={employeeData.last_name}
                                              placeholder={""}
                                              max={50}
                                            />
                                             <SpanField
                                            classname="text-danger"
                                            title={errors.last_name}
                                          />
                                          </div>
                                         
                                        </div>
                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Date of Birth" isMandatory={true} />  
                                          </div>
                                          <div className="form-group datepickerdiv">
                                            <DatePicker
                                              className="form-control"
                                              value={
                                                employeeData.date_of_birth
                                              }
                                              format="dd-MM-yyyy"
                                              onChange={(date) =>
                                                this.handleDOBChange(date)
                                              }
                                              yearPlaceholder='YYYY'
                                              monthPlaceholder='MM'
                                              dayPlaceholder='DD'
                                            />
                                             <SpanField
                                            classname="text-danger"
                                            title={errors.date_of_birth}
                                          />
                                          </div>
                                         
                                        </div>
                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Emergency #" />
                                          </div>
                                          <div className="form-control sform-control">
                                            <div className="row m-0">
                                              <div className="phonenorith">
                                                <div className="form-group">
                                                  <PhoneInput
                                                    international
                                                    defaultCountry="IN"
                                                    placeholder="Enter phone number"
                                                    value={
                                                      employeeData.emergency_contact
                                                    }
                                                    onChange={
                                                      this
                                                        .handleEmergencyPhoneInputChange
                                                    }
                                                    max={10}
                                                    error={
                                                      employeeData.emergency_contact
                                                        ? isValidPhoneNumber(
                                                          employeeData.emergency_contact
                                                        )
                                                          ? undefined
                                                          : "Invalid phone number"
                                                        : "Phone number required"
                                                    }
                                                  />{" "}
                                                </div>
                                                <SpanField
                                                  classname="text-danger"
                                                  title={
                                                    errors.emergency_contact
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                        {this.state.showDocFields ? (
                                          <div className="frllftfull mt-3">
                                            <div>
                                            <Label title="Speciality" isMandatory={true} /> 
                                            </div>
                                            <div className="form-group sform-control">
                                              <Select maxMenuHeight={120}
                                                className="form-control p-0"
                                                name="speciality"
                                                value={employeeData.speciality}
                                                onChange={
                                                  this.handlespecialityselectChange}
                                                options={specialities_array}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.speciality}
                                            />
                                          </div>) : ""}
                                      </div>

                                      <div className="col-md-4">
                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Gender" isMandatory={true} />   
                                          </div>
                                          <div className="form-group">
                                            <Select maxMenuHeight={120}
                                              classNameName="form-control p-0"
                                              name="gender"
                                              value={employeeData.gender}
                                              onChange={
                                                this.handleselectChange
                                              }
                                              options={gender}
                                            />
                                            <SpanField
                                            classname="text-danger"
                                            title={errors.gender}
                                          />
                                          </div>
                                          
                                        </div>
                                        <div className="frllftfull">
                                          <div>
                                          <Label title="Blood Group" isMandatory={true} /> 
                                          </div>
                                          <div className="form-group">
                                            <Select maxMenuHeight={120}
                                              classNameName="form-control p-0"
                                              name="blood_group"
                                              value={employeeData.blood_group}
                                              onChange={
                                                this.handleselectChange
                                              }
                                              options={blood_group}
                                            />
                                             <SpanField
                                            classname="text-danger"
                                            title={errors.blood_group}
                                          />
                                          </div>
                                         
                                        </div>
                                        <div className="frllftfull">

                                          <div>
                                          <Label title="Marital Status" />   
                                          </div>
                                          <div className="form-group">
                                            <Select maxMenuHeight={120}
                                              classNameName="form-control p-0"
                                              name="marital_status"
                                              value={
                                                employeeData.marital_status
                                              }
                                              onChange={
                                                this.handleselectChange
                                              }
                                              options={maritial_status}
                                            />
                                          </div>
                                          <SpanField
                                            classname="text-danger"
                                            title={errors.marital_status}
                                          />
                                        </div>

                                        {this.state.showDocFields ? (
                                          <div className="frllftfull">
                                            <div>
                                            <Label title="Consulting Fees" isMandatory={true} /> 
                                            </div>
                                            <div className="form-group">
                                              <InputField
                                                isMandatory={true}
                                                inputType={"text"}
                                                name={"consulting_fees"}
                                                controlFunc={
                                                  this.handleInputChange
                                                }
                                                content={
                                                  employeeData.consulting_fees
                                                }
                                                placeholder={""}
                                              />
                                            </div>
                                            <SpanField
                                              classname="text-danger"
                                              title={errors.consulting_fees}
                                            />
                                          </div>) : ""}


                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <SpanField
                                      classname="text-danger"
                                      title={errors.error}
                                    />

                                    <SpanField
                                      classname="text-danger"
                                      title={errorMessage}
                                    />
                                  </div>
                                  <div className="col-sm-12 btncent text-right">
                                    <div className="form-group mb-0">
                                      <button
                                        className="signupbtn abtn btdpml mr-1"
                                        onClick={this.saveEmployee}
                                      >
                                        <i
                                          className="fa fa-user-plus"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;&nbsp;
                                        {this.state.actions === "add"
                                          ? "Add"
                                          : "Update"}
                                      </button>
                                      <button
                                        className="signupbtn abtn btdpml"
                                        onClick={this.clearForm}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                        <div class="card-header"> <div class="card-title">Search</div> </div>
                          <div className="card-body ">
                            <div
                              className="tab-content"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-shipping"
                                role="tabpanel"
                                aria-labelledby="v-pills-shipping-tab"
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                  
                                    <div className="user-notification">
                                      {searchErrorMessage ? (
                                        <div
                                          className="alert alert-danger"
                                          role="alert"
                                        >
                                          {searchErrorMessage}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="frllftfull">
                                          <div>
                                            <label>Employee ID</label>
                                          </div>
                                          <div className="form-group">
                                            <InputField
                                              inputType={"text"}
                                              name={"employee_id"}
                                              controlFunc={
                                                this.handleSearchInput
                                              }
                                              content={searchData.employee_id}
                                              placeholder={""}
                                              max={50}
                                            />
                                              <SpanField
                                            classname="text-danger"
                                            title={errors.id}
                                          />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div>
                                            <label>
                                              Designation
                                            </label>
                                          </div>
                                          <div className="form-group sform-control">
                                            <Select maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="designation"
                                              value={searchData.designation}
                                              onChange={
                                                this.handlesearchselectChange
                                              }
                                              options={[
                                                { label: "Doctor", value: "doctor" },
                                                
                                              ]}
                                            />
                                          </div>
                                          {/* <SpanField
                                            classname="text-danger"
                                            title={errors.designation}
                                          /> */}
                                        </div>


                                      </div>
                                      <div className="col-md-4">
                                        <div className="frllftfull">
                                          <div>
                                            <label>First Name </label>
                                          </div>
                                          <div className="form-group">
                                            <InputField
                                              inputType={"text"}
                                              name={"first_name"}
                                              controlFunc={
                                                this.handleSearchInput
                                              }
                                              content={searchData.first_name}
                                              placeholder={""}
                                              max={50}
                                            />
                                          </div>
                                        </div>


                                        <div className="frllftfull">
                                          <div>
                                            <label>Speciality</label>
                                          </div>
                                          <div className="form-group sform-control">
                                            {/* <InputField
                                                inputType={"text"}
                                                name={"speciality"}
                                                controlFunc={
                                                  this.handleSearchInput
                                                }
                                                content={searchData.speciality}
                                                placeholder={""}
                                              /> */}
                                            <Select maxMenuHeight={120}
                                              className="form-control p-0"
                                              name="speciality"
                                              value={searchData.speciality}
                                              onChange={
                                                this.handlesearchselectChange
                                              }
                                              options={specialities_array}
                                            />
                                          </div>
                                        </div>


                                      </div>
                                      <div className="col-md-4">
                                        <div className="frllftfull">
                                          <div>
                                            <label>Last Name</label>
                                          </div>
                                          <div className="form-group">
                                            <InputField
                                              inputType={"text"}
                                              name={"last_name"}
                                              controlFunc={
                                                this.handleSearchInput
                                              }
                                              content={searchData.last_name}
                                              placeholder={""}
                                              max={50}
                                            />
                                          </div>
                                        </div>

                                        <div className="frllftfull">
                                          <div>
                                            <label>Mobile Number </label>
                                          </div>
                                          <div className="form-control">
                                            <div className="row m-0">
                                              <div className="phonenorith">
                                                <div className="form-group">
                                                  <PhoneInput
                                                    international
                                                    defaultCountry="IN"
                                                    placeholder="Enter phone number"
                                                    value={
                                                      searchData.phone_number
                                                    }
                                                    onChange={
                                                      this
                                                        .handleSearchPhoneInputChange
                                                    }
                                                    error={
                                                      searchData.phone_number
                                                        ? isValidPhoneNumber(
                                                          searchData.phone_number
                                                        )
                                                          ? undefined
                                                          : "Invalid phone number"
                                                        : "Phone number required"
                                                    }
                                                    max={10}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 btncent text-right">
                                        <div className="form-group mb-0 mt-2">
                                          <button
                                            className="signupbtn abtn btdpml"
                                            onClick={this.searchAndShowEmployee.bind(
                                              this
                                            )}
                                          >
                                            Search
                                          </button>
                                          &nbsp;&nbsp;
                                          <button
                                        className="signupbtn abtn btdpml"
                                        onClick={this.clearsearch}
                                      >
                                        Cancel
                                      </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card"  style={{
                            display: this.state.search_action
                              ? "block"
                              : "none",
                          }}>
                        <div class="card-header"> <div class="card-title">Search Result</div> </div>
                        <div className="card-body">
                        <div
                          className="row"
                         
                        >
                       
                          <div className="col-sm-12">
                          <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="user-notification">
                                      <SpanField
                                        classname={
                                          "0" === searchTableSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                        }
                                        title={searchTableSuccess.message}
                                      />
                                    </div>
                                    <ReactDatatable
                                      className="table table-bordered dt-responsive nowrap dataTable no-footer"
                                      config={this.searchconfig}
                                      records={this.state.searchrecords}
                                      columns={this.searchcolumns}
                                    />
                                  </div>
                                </div>
                          </div>
                        </div>
                        </div>
                        </div>
                     
                      </div>
                    </div>)}

                    {/* <Footerad /> */}
                  </div>
                </div>
                
              </section>
              <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessUserList);

function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
