import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "../components/container/layout/client/Header";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/layout/client/SideBar";
//import SideBarNew from "../components/container/layout/client/SideBarNew";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import SpanField from "../components/UI/SpanField";
import Select from "react-select";
import TextArea from "../components/UI/TextArea";
import Label from "../components/UI/Label";
import {
  getallFavouritesbyclientId,
  getClientFamilyDetails,
  getBusinessScheduleDayId,
  queueURL,
  queueBusinessURL,
  getDocByBusiness,
  addCheckinURL,
  addQueue_modified,
  getTimeoutbycategory,
  createNotification,
  getpaymenttypeforbusiness,
  getdoctordetails,
  CancelQueue,
  UpdateQueueRemove,
  updateclientqueuestatus,
  RazorPayPayment,
  getCredentials,
} from "../config/url.json";
import { CONFIG } from "../config/data";
import SearchFilter from "../views/SearchFilter";
import { withRouter } from "react-router-dom";
import { Spinner } from "../components/UI/Spinner";
import FooterSection from "../components/UI/FooterSection";
import Footerad from "../components/UI/Footerad";
import { getDateISOFormat, getPatientsubstring } from "../constants/globalLevelFunctions";
import Validation from "../actions/Validation";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal'
import Dnaspinner from "../components/UI/Dnaspinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    user: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  
    userdata: state.AuthReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

class ClientFavourites extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const timeout=this.state;
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
      },
      // {
      //   key: "first_name",
      //   text: "M/F",
      //   className: "first_name",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
        
      //     return (
      //       <Fragment>
      //       <i
      //      className="fa fa-user-md clrblue"
      //      aria-hidden="true"
      //      ></i>
      //      </Fragment>
      //     )
      //   }
      // },
      {
        key: "business_name",
        text: "Hospital Name",
        className: "business_name",
        align: "left",
        sortable: true,
      },
      {
        key: "speciality_detail",
        text: "Speciality",
        className: "speciality_detail",
        align: "left",
        sortable: true,
      },
      {
        key: "address",
        text: "Address",
        className: "address",
        align: "left",
        sortable: true,
       
      },
      {
        key: "wait_time",
        text: "Wait Time",
        width: 100,
        className: "wait_time",
        align: "left",
        sortable: true,
       
      },
      {
        key: "directions",
        text: "Directions",
        className: "directions",
        sortable: true,
        align: "left",
        cell: (record) => {
          return (
          <Fragment>
              <a
           target="_blank"
           rel="noopener noreferrer"
           href={"https://www.google.com/maps/search/?api=1&query="+record.latitude+","+
           record.longitude
           }
           >
        <i
           className="fa fa-compass grnbk"
           aria-hidden="true"
           ></i>
        &nbsp;
        </a>
          </Fragment>
          )
        },
      },
      

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 200,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
          <Fragment>
               <button
        onClick={this.showFamilydetail.bind(
        this,
        record
        )}
        className="signupbtn mtlogin"
        // data-toggle="modal"
        // data-target=".bs-example-modal-center"
        >
        Select
        </button>
          </Fragment>
          )
        },
     
      },
    ];
    this.state = {
      timeout:timeout,
      isTimedOut: false,
      showModal: false,
    
      loading: false,
      loggedInuserId: props.user.id,
      loggedInuserName: props.user.first_name,
      access_token: props.access_token,
      payment_mode: "",
      favouritesList: [
        {
          business_name: "",
          businessId: "",
          wait_time: "",
          speciality_detail: "",
          address: "",
        },
      ],
      queuein_data: {
        business_name: "",
        schedule_day_id: "",
        n_schedule_day_id: "",
        businessId: "",
        address: "",
        expected_wait_time: "",
        no_of_additional_persion: "",
        payment_mode:"",

      },
      selectedPlace: { value: "", label: "" },
      doctor_list: [],
      selected_doc: { value: "", label: "" },
      doctor_appointment_detail_list: [],
      selected_doctor_appointment_detail_list: [],
      selected_doc_schedule_type: "",

      family_list: [{ client_id: "", name: "", relationship: "" }],
      enableqin: false,
      listofClientForQueue: [],
      loggedInClient: { client_id: "", name: "", relationship: "" },
      error: "",

      openModal: false,
      errors: {
        doctor_name: "",
        other_comments:"",
        payment_type_error:""
      },
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  
  }
  handleClose() {
    this.setState({showModal: false})
  }

 
   handleLogout = (e) => {
    this.setState({showModal: false})
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();
   
};

  _onAction(e) {
  
    
    this.setState({isTimedOut: false})
  }
 
  _onActive(e) {
    
    
    this.setState({isTimedOut: false})
  }
   
  _onIdle = (e) =>  {
    
    let isTimedOut  = this.state.isTimedOut
    let timeout=this.state.timeout;
    if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
        this.props.history.push("/");
      }
  }
  
  showFamilydetail = (value, el) => {
console.log(value);
   
    const data = {
      n_business_id: value.businessId,
      n_currentdate: getDateISOFormat(new Date()),
    };
    if (value.businessId !== "") {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getDocByBusiness, data, CONFIG)
        .then((result) => {

          let resData = result.data.data;
          
          let queuein_data = {};

          queuein_data.n_business_id = value.businessId;
          queuein_data.business_name = value.business_name;
          queuein_data.address = value.address;
          queuein_data.expected_wait_time = value.expected_wait_time;

          let doctor_appointment_detail_list = [];
          let docList = [];

         
          if (resData) {
            resData.forEach((element, index) => {
              let doctor_obj = {};

              doctor_obj.doc_name = element.doctor_name;
              doctor_obj.business_user_id = element.business_user_id;
              doctor_obj.schedule_day_id = element.schedule_day_id;
              doctor_obj.schedule_id = element.schedule_id;
              doctor_obj.schedule_type = element.schedule_type;
              doctor_obj.appt_id = element.appt_id;
              doctor_obj.doctor_Appointments = element.doctor_Appointments;

              doctor_appointment_detail_list.push(doctor_obj);
              docList.push({
                value: element.business_user_id,
                label: element.doctor_name,
                speciality_name: element.speciality_name,
              });
            });
          }


         

          this.setState({
            doctor_list: docList,
            queuein_data: queuein_data,
            doctor_appointment_detail_list: doctor_appointment_detail_list,
          });

          axios
          .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:docList[0].value}, CONFIG)
          .then((res) => {
            let response = res.data;
            console.log(response);
            // return false;
            if (response.status !== "0") {
              // window.location.href = "/vendor/" + response.data;
              this.setState({ doctor_details: response.data });
            }
      
          })
          .catch((e) => {
            console.log(e);
          });
          //By default select on doctor
          let selected_doc = docList[0];
          let selected_appointment = [];
          let selected_doc_schedule_type = this.setState.selected_doc_schedule_type;

          //Based on default doctor selection select corresponding appointment details
          doctor_appointment_detail_list.forEach((element, index) => {
            if (element.business_user_id == docList[0].value) {
              selected_appointment = element.doctor_Appointments;
              selected_doc_schedule_type = element.schedule_type;
            }
          });

          this.setState({
            selected_doc: selected_doc,
            selected_doctor_appointment_detail_list: selected_appointment,
            selected_doc_schedule_type: selected_doc_schedule_type
          });
         

          this.setState({ openModal: true });
        })
        .catch((e) => { });
        axios
        .post(getpaymenttypeforbusiness, { user_id: value.businessId}, CONFIG)
        .then((res) => {
          let data = res.data;
          console.log(res);
          console.log(data);
    
          console.log(data.data[0].payment_type);
          if (data.status === "1")
            this.setState({
              paymenttypeforhospital: data.data[0].payment_type
              
            });
          })
        .catch((e) => { });

      
    }
  };
  closePopup = (e) => {
    this.setState({
      openModal: false, errors: {}, error: "",payment_mode:"",
      queuein_data: {}, listofClientForQueue: []
    });
  };

  openbookQueueAppointment = (e)=>{
     
    let { queuein_data, errors } = this.state;
    let listofClientForQueue = this.state.listofClientForQueue;

    //Need to change from string to array after fix the queue issue
    // queuein_data.n_client_id = listofClientForQueue;
    console.log(listofClientForQueue);
    queuein_data.n_client_id = listofClientForQueue.length > 0 ? listofClientForQueue[0].client_id : null;
    queuein_data.clientList = listofClientForQueue;
    queuein_data.n_status = "queue-in";
    queuein_data.n_isActive = "yes";
    queuein_data.n_savedtime = new Date();
    if (this.state.selected_doc) {
      queuein_data.n_business_user_id = this.state.selected_doc.value;
      queuein_data.doc_name = this.state.selected_doc.label;
    } else {
      queuein_data.n_business_user_id = ""
    }

    queuein_data.type = "createQueue";
    queuein_data.schedule_type = this.state.selected_doc_schedule_type.toLowerCase();
    queuein_data.payment_mode=this.state.payment_mode;

    this.setState({ queuein_data: queuein_data });
   
    this.setState({payment_type_error : ""});
    errors = Validation(queuein_data, errors);
    console.log(errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {



      if (errors.formIsValid && queuein_data.schedule_type.toLowerCase() != "appointment") {

        /* */
        let listofClientIdForQueue = []
        listofClientForQueue.map((item) => {
          listofClientIdForQueue.push(item.client_id);
        });
        queuein_data.clientList = listofClientIdForQueue;
        // console.log(queuein_data); return false;

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(addQueue_modified, this.state.queuein_data, CONFIG)
          .then((response) => {
            console.log(response.data);
            let result = response.data;
            console.log(result.data[0]);
            if (result.data[0].status === '100') {
              let qId = result.data[0].qId;

              this.displayRazorpay(result.data[0].qId);
              let Checkindata = {
                business_id: queuein_data.n_business_id,
                client_id: queuein_data.n_client_id,
                business_user_id : this.state.selected_doc.value,
                other_comments: queuein_data.other_comments,
                q_id: qId,

              };
              console.log(Checkindata);
              let notificationdata = {
                message_from: queuein_data.n_business_id,
                message_to: this.props.user.id,
                message: "Token has been created successfully.",
                link: "/scheduleconfirmation/" + qId,

              }


              axios
                .post(addCheckinURL, Checkindata, CONFIG)
                .then((result) => {
                  if (result.data.status === "1") {
                  
                   
                   
                 
                    axios.post(createNotification, notificationdata).then((res) => {
                      console.log(qId)
                      this.setState({ qId: qId });
                      this.closePopup();
                      this.openbookAppointment();
                     
                     /*  toast.success("Your Appointment has been confirmed with Dr. "+ this.state.selected_doc.label , {
                        className: "toast-success",
                        }); 
                        setTimeout(
                          () =>
                            (res.data.status) ?
                              window.location.href = '/dashboard' : ""
                          ,
                          1000
                        ); */
                     // window.location.replace("/scheduleconfirmation/" + qId);
                    }).catch((e) => {

                    });

                  }
                })
                .catch((e) => {

                });

             //   this.setState({ bookModel: true,openModal:false });
            } else {
              console.log(result);
              // this.closePopup();

              let errors = this.state.errors;
              errors.message = result.data[0].message;
              this.setState({ errors: errors });
            }
          })
          .catch((e) => { });

      }
    }
   
  };
  
  createAppointment() {

    let { queuein_data, errors } = this.state;
    let listofClientForQueue = this.state.listofClientForQueue;

    //Need to change from string to array after fix the queue issue
    // queuein_data.n_client_id = listofClientForQueue;
    console.log(listofClientForQueue);
    queuein_data.n_client_id = listofClientForQueue.length > 0 ? listofClientForQueue[0].client_id : null;
    queuein_data.clientList = listofClientForQueue;
    queuein_data.n_status = "queue-in";
    queuein_data.n_isActive = "yes";
    queuein_data.n_savedtime = new Date();
    if (this.state.selected_doc) {
      queuein_data.n_business_user_id = this.state.selected_doc.value;
      queuein_data.doc_name = this.state.selected_doc.label;
    } else {
      queuein_data.n_business_user_id = ""
    }

    queuein_data.type = "createQueue";
    queuein_data.schedule_type = this.state.selected_doc_schedule_type.toLowerCase();
   

    this.setState({ queuein_data: queuein_data });

    errors = Validation(queuein_data, errors);
    console.log(errors);
    this.setState({ errors: errors });


 if (errors.formIsValid) {
        this.props.history.push({
          pathname: "/client_book_appointments",
          state: {
            business_id: this.state.queuein_data.n_business_id,
            business_name: this.state.queuein_data.business_name,
            business_user_id: this.state.queuein_data.n_business_user_id,
            doc_name: this.state.queuein_data.doc_name,
            other_comments: this.state.queuein_data.other_comments,
            client_details: queuein_data.clientList[0],
          }
        });
      }
    
  }
  createAppointmentandAddQueue() {

    let { queuein_data, errors } = this.state;
    let listofClientForQueue = this.state.listofClientForQueue;

    //Need to change from string to array after fix the queue issue
    // queuein_data.n_client_id = listofClientForQueue;
    
    queuein_data.n_client_id = listofClientForQueue.length > 0 ? listofClientForQueue[0].client_id : null;
    queuein_data.clientList = listofClientForQueue;
    queuein_data.n_status = "queue-in";
    queuein_data.n_isActive = "yes";
    queuein_data.n_savedtime = new Date();
    if (this.state.selected_doc) {
      queuein_data.n_business_user_id = this.state.selected_doc.value;
      queuein_data.doc_name = this.state.selected_doc.label;
    } else {
      queuein_data.n_business_user_id = ""
    }

    queuein_data.type = "createQueue";
    queuein_data.schedule_type = this.state.selected_doc_schedule_type;

    this.setState({ queuein_data: queuein_data });

    errors = Validation(queuein_data, errors);
    this.setState({ errors: errors });
console.log(errors)
    if (errors.formIsValid) {



      if (errors.formIsValid && queuein_data.schedule_type != "appointment") {

        /* */
        let listofClientIdForQueue = []
        listofClientForQueue.map((item) => {
          listofClientIdForQueue.push(item.client_id);
        });
        queuein_data.clientList = listofClientIdForQueue;
       

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(addQueue_modified, this.state.queuein_data, CONFIG)
          .then((response) => {
           
            let result = response.data;
           
            if (result.data[0].status === '100') {
              let qId = result.data[0].qId;


              let Checkindata = {
                business_id: queuein_data.n_business_id,
                client_id: queuein_data.n_client_id,
                business_user_id:this.state.selected_doc.value,
                other_comments: queuein_data.other_comments,
                q_id: qId,

              };

              axios
                .post(addCheckinURL, Checkindata, CONFIG)
                .then((result) => {
                  if (result.data.status === "1") {

                    this.closePopup();
                   
                    // this.props.history.push("/scheduleconfirmation/" + qid);
                    window.location.replace("/scheduleconfirmation/" + qId);
                  }
                })
                .catch((e) => {

                });


            } else {
             
              // this.closePopup();
              let errors = this.state.errors;
              errors.message = result.data[0].message;
              this.setState({ errors: errors });
            }
          })
          .catch((e) => { });

      } else if (errors.formIsValid) {
        this.props.history.push({
          pathname: "/client_book_appointments",
          state: {
            business_id: this.state.queuein_data.n_business_id,
            business_name: this.state.queuein_data.business_name,
            business_user_id: this.state.queuein_data.n_business_user_id,
            doc_name: this.state.queuein_data.doc_name,
            other_comments: this.state.queuein_data.other_comments,
            client_details: queuein_data.clientList[0],
          }
        });
      }
    }
  }

  selectMemberForQueueIn(value, element) {

   
    // let selectedClientId = value.client_id;
    let selectedClientId = value;
    let { errors, listofClientForQueue } = this.state

    if (!listofClientForQueue.includes(selectedClientId)) {
      listofClientForQueue.push(selectedClientId);
    } else {
      const deleteIndex = listofClientForQueue
        .map((item) => item)
        .indexOf(selectedClientId);
      listofClientForQueue.splice(deleteIndex, 1);
    }
    if (listofClientForQueue.length === 0) {

      errors.n_client_id = "Please select atleast one person";
      errors.formIsValid = false;
      this.setState({ errors: errors });
    } else {
      errors.n_client_id = "";
      this.setState({ listofClientForQueue });
    }
  }

  componentDidMount = () => {
 this.setState({ loading: true });
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

     axios
      .post(getTimeoutbycategory, {category : this.props.user.user_type}, CONFIG)
      .then((res) => {
        let data = res.data;
       
        if (data.status == "1")
          this.setState({
            timeout: data.data.timeout
            
          });
        })
      .catch((e) => { });

    let members = [];

    let loggedInClient = {};
    loggedInClient.client_id = this.state.loggedInuserId;
    loggedInClient.name = this.state.loggedInuserName;
    loggedInClient.relationship = "Self";

    this.setState({ loggedInClient });
    this.getData();
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .post(
        getallFavouritesbyclientId,
        { client_id: this.state.loggedInuserId },
        CONFIG
      )
      .then((list) => {
       
        let favouritesList = [];

        let favouritesArr = list.data.data;
        
        favouritesArr.forEach((element, index) => {
          let favourite = {};
          favourite.s_no = index+1;
          favourite.business_name = element.business_name;
          favourite.businessId = element.businessId;
          favourite.speciality_detail = element.speciality_detail;
          favourite.address = element.Address;
          favourite.wait_time = element.expected_wait_time;
          // if (favourite.businessId != "") {
          //   axios
          //     .get(queueBusinessURL + favourite.businessId)
          //     .then((details) => {
          //       
          //       favourite.wait_time = details.data.waitTime;
          //     })
          //     .catch((e) => { });
          // }
          // if (favourite.wait_time !== "") {
            favouritesList[index] = favourite;
          // }

        
        });

       // window.setTimeout(() => {
          this.setState({ favouritesList, loading: false });
     //   }, 1000);

      })
      .catch((e) => { });
  };
  getData = () => {

    // let loggedInClient = {};
    // loggedInClient.client_id = this.props.user.id;
    // loggedInClient.name = this.props.user.first_name;
    // loggedInClient.relationship = "Self";

    // this.setState({ loggedInClient });
    // this.setState({ latitude: latitude, longitude: longitude });
    //To get the Family details
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getClientFamilyDetails, { client_id: this.props.user.id }, CONFIG)
      .then((client_family) => {
      

        let list = [];
        list.unshift(this.state.loggedInClient);
        this.setState({ family_list: list });

        let dataArr = client_family.data.data;

        let family_list = this.state.family_list;
        dataArr.forEach((element, index) => {
          let family_member = {};
          family_member.client_id = element.user_id;
          family_member.name = element.first_name + " " + element.last_name;
          family_member.relationship = element.relationship;

          family_list[index + 1] = family_member;
          this.setState({ family_list: family_list });
        });
        this.setState({ enableqin: true });
      })
      .catch((e) => { });
  }

  handlequeueIn(value, el) {
    
    

    let queuein_data = {};
    queuein_data.business_name = value.business_name;
    queuein_data.address = value.address;
    queuein_data.expected_wait_time = value.expected_wait_time;
    queuein_data.businessId = value.businessId;
    queuein_data.expected_wait_time = value.wait_time;

    if (value.businessId != "") {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.state.access_token}`;
      axios
        .get(getBusinessScheduleDayId + value.businessId)
        .then((schedules) => {
          let scheduleArr = schedules.data;

          scheduleArr.forEach((element, index) => {
            queuein_data.schedule_day_id = element.schedule_day_id;
          });
        })
        .catch((e) => { });
    }

    this.setState({ queuein_data });

    let family_list = [];

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.state.access_token}`;
    axios
      .post(
        getClientFamilyDetails,
        { client_id: this.state.loggedInuserId },
        CONFIG
      )
      .then((client_family) => {
        let dataArr = client_family.data.data;

        dataArr.forEach((element, index) => {
          let family_member = {};
          family_member.client_id = element.id;
          family_member.name = element.first_name + " " + element.last_name;
          family_member.relationship = element.relationship;

          family_list[index] = family_member;
          this.setState({ family_list });
        });

        let list = this.state.family_list;
        list.unshift(this.state.loggedInClient);
        this.setState({ family_list: list });
        this.setState({ enableqin: true });
      })
      .catch((e) => { });
  }
  handleQueueInput = (e) => {
    let data = this.state.queuein_data;
    let errors=this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    data[name] = value;
    errors[name]="";
    this.setState({ queuein_data: data,errors });
   
  }
  handleselectDocChange = (selectedOptions, e) => {
    let data = this.state.selected_doc;
    data = selectedOptions;

    this.setState({ selected_doc: data });

    

    let appointmentDetails = this.state.doctor_appointment_detail_list;

    let selected_appointment = this.state
      .selected_doctor_appointment_detail_list;

    let selected_doc_schedule_type = this.state.selected_doc_schedule_type;

    appointmentDetails.forEach((element, index) => {
      
      if (element.business_user_id == selectedOptions.value) {
        
        selected_appointment = element.doctor_Appointments;
        selected_doc_schedule_type = element.schedule_type;
      } else {
        
      }
    });
    this.setState({
      selected_doctor_appointment_detail_list: selected_appointment,
      selected_doc_schedule_type: selected_doc_schedule_type
    });
    axios
    .post(getdoctordetails,  {business_id:this.state.queuein_data.n_business_id,business_user_id:selectedOptions.value}, CONFIG)
    .then((res) => {
      let response = res.data;
      console.log(response);
      // return false;
      if (response.status !== "0") {
        // window.location.href = "/vendor/" + response.data;
        this.setState({ doctor_details: response.data,payment_mode:"" });
      }

    })
    .catch((e) => {
      console.log(e);
    });

  };

  selectMemberForQueueIn(value, element) {

  
    // let selectedClientId = value.client_id;
    let selectedClientId = value;
    let { errors, listofClientForQueue } = this.state

    if (!listofClientForQueue.includes(selectedClientId)) {
      listofClientForQueue.push(selectedClientId);
    } else {
      const deleteIndex = listofClientForQueue
        .map((item) => item)
        .indexOf(selectedClientId);
      listofClientForQueue.splice(deleteIndex, 1);
    }
    if (listofClientForQueue.length === 0) {

      errors.n_client_id = "Please select atleast one person";
      errors.message="";
      errors.formIsValid = false;
      this.setState({ errors: errors });
    } else {
      errors.n_client_id = "";
      errors.message="";
      this.setState({ listofClientForQueue });
    }
  }
  handleTimingSelect = (event) => {
    let data = this.state.queuein_data;
    let errors=this.state.errors;
    data.n_schedule_day_id = event.target.value;
    errors.n_schedule_day_id="";
    this.setState({ queuein_data: data });

  };
  handlePaymentSelect = (e) => {
    const { name, value } = e.target;
    let errors = this.state.errors;
    if(e.target)
    {
      errors.payment_type_error="";
    }
 
    this.setState({
      [name]: value,
      payment_type_error:"",
      
    });
  }
  displayRazorpay = (q_id) => {
    /*  let clientData = this.state.clientData;
     let queue_data = clientData.queue_data; */
     let { queuein_data, errors } = this.state;
         console.log(q_id)
         const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')
     
         if (!res) {
           alert('Razorpay SDK failed to load. Are you online?')
           return
         }
         const option = {
           method: 'POST',
           // headers: new Headers({ 'content-type': 'application/json' }),
           mode: 'no-cors'
         };
     
         let paymentForm = this.state.selectedHours;
         let data = {};
         let consulting_fees = ((this.state.doctor_details && this.state.payment_mode=="Online")) ?  this.state.doctor_details.consulting_fees:"";
         let service_charges=10;
         let total_charges=parseInt(consulting_fees)+parseInt(service_charges);
          data.payable_amt =(this.state.payment_mode=="Online") ? total_charges : service_charges;
         let payment_status=(this.state.payment_mode=="Online") ? 'Completed':'Pending';
         let payment_type=this.state.payment_mode;
         let selected_doc=this.state.selected_doc.label;
        //
         console.log(data);
        /*  let doc_firstname=this.state.doctor_details.first_name;
         let doc_lastname=this.state.doctor_details.last_name; */
         // axios.defaults.headers.common[
         //   "Authorization"
         // ] = `Bearer ${this.props.access_token}`;
     let razorpay_key = "";
         axios
     .post(getCredentials, {
       "credential_type":"razorpay"
     })
     .then((res) => {
       console.log(res.data);
       razorpay_key = res.data.data[0].value;
     
     
     
       axios.post(RazorPayPayment, data, data).then((res) => {
         console.log(res);
         let data = res.data;
       //  this.sendBillingmail(this.state.doctor_details);
         //  let vendorData = this.state.vendorData;
         //  let paymentForm = this.state.paymentForm;
         //  console.log(vendorData);
         console.log(razorpay_key);
         const options = {
           // key: __DEV__ ? 'rzp_test_CObt3PWG73pjBs' : 'PRODUCTION_KEY',
           key: razorpay_key,
           currency: data.currency,
           amount: data.amount.toString(),
           order_id: data.id,
           name: 'Billing and Payment',
           description: '',
           image: 'https://wtmprofileimagesbucket.s3.ap-south-1.amazonaws.com/logo.png',
           handler: function (response) {
             console.log(response);
           //  alert(response.razorpay_payment_id)
             // return false; 
             // alert(response.razorpay_order_id)
             // alert(response.razorpay_signature)
             
             /* let req_data = {
               status:'queue-in',
               'order_id': response.razorpay_order_id,
               'payment_id': response.razorpay_payment_id,
               //   'vendor_id': vendorData.id,
               //   vendor_subscription: vendorData.vendor_subscription.id,
               //    number_of_months: paymentForm.months.value,
               //    amount: paymentForm.payable_amt,
               amount: consulting_fees,
               payment_status: payment_status,
               service_charges:service_charges,
               payment_type:payment_type,
               q_id: q_id
     
             } */
             toast.success("Your Appointment has been confirmed with Dr. "+ selected_doc , {
               className: "toast-success",
               }); 
               const url = updateclientqueuestatus;
               let req_data = {
                 status:'queue-in',
                 'order_id': response.razorpay_order_id,
                 'payment_id': response.razorpay_payment_id,
                 //   'vendor_id': vendorData.id,
                 //   vendor_subscription: vendorData.vendor_subscription.id,
                 //    number_of_months: paymentForm.months.value,
                 //    amount: paymentForm.payable_amt,
                 amount: consulting_fees,
                 payment_status: payment_status,
                 service_charges:service_charges,
                 scharges_paymentstatus:'Completed',
                 payment_type:payment_type,
                 q_id: q_id
       
               };
                                
              axios.post(updateclientqueuestatus, req_data, CONFIG).then((res) => {
               console.log(res);
              
               setTimeout(
                 () =>
                   (res.data.status) ?
                     window.location.href ="/scheduleconfirmation/" + q_id : ""
                 ,
                 1000
               );
              
             }).catch((err) => {
     
     
             }) 
           },
     
           prefill: {
             name: this.props.userdata.first_name+" "+this.props.userdata.last_name,
             email: this.props.userdata.email,
             contact: this.props.userdata.phone_number
           },
           modal: {
             confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
             // This function is executed when checkout modal is closed
             // There can be 3 reasons when this modal is closed.
             ondismiss: async (reason) => {
               const {
                 reason: paymentReason, field, step, code,
               } = reason && reason.error ? reason.error : {};
               // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
               if (reason === undefined) {
                 console.log('cancelled');
                 let cancel_data = {
                   status:'Cancelled',
                   amount: consulting_fees,
                   payment_status: "",
                   service_charges:service_charges,
                   payment_type:payment_type,
                   q_id: q_id
         
                 };
                 const handlePayment  = async (url, cancel_data) => {
                   try {
                     const response = await axios.post(url, cancel_data);
                     return response.data;
                   } catch (error) {
                     console.error('Error:', error);
                     throw error;
                   }
                 };  
                 let queuedata = {
                   "n_business_id": queuein_data.n_business_id,
                   "n_business_user_id": queuein_data.n_business_user_id,
                   "n_schedule_day_id": queuein_data.n_schedule_day_id,
                   "n_q_id": q_id,
                   "n_status": "D",
                   "n_schedule_type": "D",
                   "n_preference": 1
                 }
                
                 axios.defaults.headers.common[
                   "Authorization"
                 ] = `Bearer ${this.props.access_token}`;
                 axios
                   .post(UpdateQueueRemove, queuedata)
                   .then(() => {
                   
                   }).catch(() => {
                     console.log("Update queue failed");
                   });  
                   let cancelData = {
                    
                      q_id: q_id,
                    
                    };
                   axios.defaults.headers.common[
                     "Authorization"
                   ] = `Bearer ${this.props.access_token}`;
               
                   axios
                     .post(CancelQueue, cancelData)
                     .then(() => {
                   
                     }).catch(() => {
                       console.log("Cancelld Queue");
                     });  
                 handlePayment(updateclientqueuestatus,cancel_data);
                 toast.error("Your Appointment has been Cancelled." , {
                  className: "toast-error",
                  }); 
          //       handlePayment('Cancelled');
               } 
               // Reason 2 - When modal is auto closed because of time out
               else if (reason === 'timeout') {
                 console.log('timedout');
                 let cancel_data = {
                   status:'Cancelled',
                   amount: consulting_fees,
                   payment_status: "",
                   service_charges:service_charges,
                   payment_type:payment_type,
                   q_id: q_id
         
                 };
                 const handlePayment  = async (url, cancel_data) => {
                   try {
                     const response = await axios.post(url, cancel_data);
                     return response.data;
                   } catch (error) {
                     console.error('Error:', error);
                     throw error;
                   }
                 };  
                 let queuedata = {
                   "n_business_id": queuein_data.n_business_id,
                   "n_business_user_id": queuein_data.n_business_user_id,
                   "n_schedule_day_id": queuein_data.n_schedule_day_id,
                   "n_q_id": q_id,
                   "n_status": "D",
                   "n_schedule_type": "D",
                   "n_preference": 1
                 }
                
                 axios.defaults.headers.common[
                   "Authorization"
                 ] = `Bearer ${this.props.access_token}`;
                 axios
                   .post(UpdateQueueRemove, queuedata)
                   .then(() => {
                   
                   }).catch(() => {
                     console.log("Update queue failed");
                   });  
                   let cancelData = {
                    
                     q_id: q_id,
                   
                   };
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${this.props.access_token}`;
              
                  axios
                    .post(CancelQueue, cancelData)
                    .then(() => {
                  
                    }).catch(() => {
                      console.log("Cancelld Queue");
                    });   
                 handlePayment(updateclientqueuestatus,cancel_data);
                 toast.error("Your Appointment has been Cancelled." , {
                  className: "toast-error",
                  }); 
          //       handlePayment('timedout');
               } 
               // Reason 3 - When payment gets failed.
               else {
                 console.log('failed');
                 console.log(paymentReason);
                 let cancel_data = {
                   status:'Cancelled',
                   amount: consulting_fees,
                   payment_status: "",
                   service_charges:service_charges,
                   payment_type:payment_type,
                   q_id: q_id
         
                 };
                 const handlePayment  = async (url, cancel_data) => {
                   try {
                     const response = await axios.post(url, cancel_data);
                     return response.data;
                   } catch (error) {
                     console.error('Error:', error);
                     throw error;
                   }
                 };  
                 let queuedata = {
                   "n_business_id": queuein_data.n_business_id,
                   "n_business_user_id": queuein_data.n_business_user_id,
                   "n_schedule_day_id": queuein_data.n_schedule_day_id,
                   "n_q_id": q_id,
                   "n_status": "D",
                   "n_schedule_type": "D",
                   "n_preference": 1
                 }
                
                 axios.defaults.headers.common[
                   "Authorization"
                 ] = `Bearer ${this.props.access_token}`;
                 axios
                   .post(UpdateQueueRemove, queuedata)
                   .then(() => {
                   
                   }).catch(() => {
                     console.log("Update queue failed");
                   });   
                   let cancelData = {
                    
                     "n_q_id": q_id,
                   
                   };
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${this.props.access_token}`;
              
                  axios
                    .post(CancelQueue, cancelData)
                    .then(() => {
                  
                    }).catch(() => {
                      console.log("Cancelld Queue");
                    });  
                 handlePayment(updateclientqueuestatus,cancel_data);
                 toast.error("Your Appointment has been Cancelled." , {
                  className: "toast-error",
                  }); 
               /*  handlePayment('failed', {
                   paymentReason, field, step, code,
                 }); */
               }
             },
           },
           timeout: 300, // Time limit in Seconds
           theme: {
           color: '#037ac7', // Custom color for your checkout modal.
     },
         }
         const paymentObject = new window.Razorpay(options)
         paymentObject.on('payment.submit', (response) => {
          // paymentMethod.current = response.method;
         //  alert(response.method);
         });
         paymentObject.on('payment.failed', function (response){
           // alert(response.error.code);
           // alert(response.error.description);
           // alert(response.error.source);
           // alert(response.error.step);
           // alert(response.error.reason);
           // alert(response.error.metadata.order_id);
           // alert(response.error.metadata.payment_id);
     });
     
         paymentObject.open()
     
       }).catch((e) => { console.log(e) });
     
     }) 
     .catch((e) => { 
       console.log(e)
     });
     
       
     
     
       }
  render() {
    const { favouritesList, queuein_data, family_list,timeout,loading } = this.state;
    
    const { selected_doc, doctor_list, errors } = this.state;

    return (
      <Fragment>
        <div id="layout-wrapper">
        {/*   <Spinner loading={this.state.loading} /> */}
          <div className="main-content bacfot">
          {/*   <Spinner loading={this.state.loading} /> */}
          {loading && <Dnaspinner />}
            <div className="mainpage">
              <Header user_id={this.state.loggedInuserId} />
              <div className="main-box">
                <div className="sidemenu">
                  <SideBar />
                </div>



                <section className="main-body hospital-profilegap">
                <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={timeout} />

                  <div className="container-fluid">
                    <div className="checkout-tabs">
                      <div className="">
                        <h4 className="font-weight-bold"> Favorites</h4>
                      </div>
                      <div className="row">


                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-8 pr0">

                              {favouritesList.length ? (<div className="card">
                              <div class="card-header"> <div class="card-title">Select Hospital</div> </div>
                                <div className="card-body  favHeight">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="row">
                                        <div className="col-sm-12 adminPharmacyTable">
                                          {favouritesList.length ? (<h5 className="">
                                            
                                          </h5>) : ""}

                                         
                                          <ReactDatatable
                                           config={this.config}
                                           records={favouritesList}
                                           columns={this.columns}
                                           className="table table-bordered table-striped kas  mobile_table"
                                         />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              ) : (<div className="card">
                                <div className="card-body favHeight">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="row">
                                        <div className="col-sm-12">

                                          No Data Available
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              )}

                              {/* <div
                                className="card"
                                style={{
                                  display: this.state.enableqin ? "block" : "none",
                                }}
                              >
                                <div className="card-body ">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="v-pills-shipping"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-shipping-tab"
                                    >
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5 className="fnsz">
                                            {queuein_data.business_name} ,{" "}
                                            {queuein_data.address}{" "}
                                            <span className="estimatedwait">
                                              Estimated wait time{" "}
                                              <button>
                                                {queuein_data.expected_wait_time}
                                              </button>
                                            </span>
                                          </h5>
                                          <input
                                            type="hidden"
                                            name="business_id"
                                            value={queuein_data.businessId}
                                          />
                                          <hr />

                                          <div className="row">
                                            <div className="col-sm-8">
                                              <label>Who is this visit for?</label>
                                              <div>
                                                {" "}
                                                <span style={{ color: "red" }}>
                                                  {this.state.error}
                                                </span>
                                              </div>

                                              {family_list.map((el, i) => (
                                                <Fragment key={i}>
                                                  <div className="square-switch">
                                                    <input
                                                      type="checkbox"
                                                      id={"square-switch" + (i + 1)}
                                                      switch="none"
                                                      onClick={this.selectMemberForQueueIn.bind(
                                                        this,
                                                        el
                                                      )}
                                                    />
                                                    <label
                                                      for={"square-switch" + (i + 1)}
                                                      data-on-label={el.name}
                                                      data-off-label={
                                                        el.name +
                                                        "-" +
                                                        el.relationship
                                                      }
                                                    ></label>
                                                  </div>
                                                </Fragment>
                                              ))}
                                            </div>
                                          </div>
                                          <div className="col-sm-4">
                                            <label className="mobilehide">
                                              &nbsp;
                                      </label>
                                            <div className="form-group mb-0">
                                              <button
                                                href="patients-register"
                                                className="signupbtn abtn  adbt"
                                                onClick={this.createAppointmentandAddQueue.bind(
                                                  this
                                                )}
                                              >
                                                Queue-in
                                        </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                             */}
                            </div>
                            <SearchFilter />
                          </div>
                        </div>
                      </div>
                      {/* <Footerad /> */}
                      {/** end of row */}
                    </div>
                  </div>

              

                </section>
                <FooterSection />
              </div>



            </div>
          </div >
          <Modal show={this.state.openModal} onHide={this.closePopup}>



<Modal.Header closeButton className="model-hptd">
  <Modal.Title className="h5 model-text-color">
    Queue-In
  </Modal.Title>
</Modal.Header>
<Modal.Body className="pb-0">

  <h5 className="text-center">
    {queuein_data.business_name} , {queuein_data.address}{" "}
  </h5>
  <input
    type="hidden"
    name="business_id"
    value={queuein_data.businessId}
  />
    <Label className="ffgrd f-w-b" title ="Who is this visit for?" isMandatory="true" />
  
          <div>
            {" "}
            <span style={{ color: "#f46a6a" }}>
              {this.state.error}
            </span>
          </div>
          <div className="w-100 text-center">
          <SpanField
            classname="text-danger"
            title={errors.n_client_id || errors.n_business_id || errors.n_business_user_id || errors.message}
          />
          </div>
       
            <div className="dgrids">
          {family_list.map((el, i) => (
            <Fragment key={i}>
            
              <div className="square-switch ">
                <input
                  type="checkbox"
                  id={"square-switch" + (i + 1)}
                  switch="none"
                  onClick={this.selectMemberForQueueIn.bind(
                    this,
                    el
                  )}
                  
                />
                <label
                  className="w-100"
                  htmlFor={"square-switch" + (i + 1)}
                  data-on-label={getPatientsubstring(el.name)}
                  data-off-label={
                    getPatientsubstring(el.name) + "-" + el.relationship
                  }
                ></label>
              </div>
            
             
            </Fragment>
          ))}
          </div>

  <div className="frllftfull">
    <div className="form-group frllft m8">
      <Label className="ffgrd f-w-b" title ="Select Doctor" />
     </div>
    <div className="form-group frlrit">
      <div className="position-relative">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text inpico">
              <i className="fas fa-search"></i>
            </span>
          </div>
          <Select maxMenuHeight={120}
            className="form-control p-0"
            name="selected_doc"
            value={selected_doc}
            onChange={this.handleselectDocChange}
            options={doctor_list}
          />
              {/* <Select maxMenuHeight={120}
                        className="form-control p-0"
                        name="selected_doc"
                        defaultvalue={doctor_list[0]}
                        onChange={this.handleselectDocChange}
                        options={doctor_list}
                        placeholder="Select a doctor"
                      /> */}

        </div>
        <label className="mrgtp">
                    
                    
                     {selected_doc ? selected_doc.speciality_name:""}
                     </label>
      </div>
    </div>
  </div>

  <div className="frllftfull">
    <div className="form-group frllft mbbre">
    <Label className="ffgrd f-w-b" title ="Select timing" isMandatory="true" />
      </div>
    {(this.state.selected_doc_schedule_type.toLowerCase() == "appointment") ?
      "Click Next to select the time" :

      <div className="row">
        {this.state.selected_doctor_appointment_detail_list ? (
          this.state.selected_doctor_appointment_detail_list.map(
          (option, i) => {
            return (
              <Fragment key={option.event_name + i}>
                <div className="col-sm-12">
                  <div className="form-check fltx">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={option.event_name}
                      id={option.event_name}
                      value={option.schedule_day_id}
                      checked={
                        this.state.queuein_data
                          .n_schedule_day_id ===
                        option.schedule_day_id
                      }
                      onChange={this.handleTimingSelect}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={option.event_name}
                    >
                      {option.event_name} ({option.starttime} -{" "}
                      {option.endtime})
                    </label>
                    
                  </div>
                  <label className="pull-right">
                    Wait time: {option.wait_time}</label>
                </div>
              </Fragment>
              
            );
          }
        )
        ) :""}
        <SpanField
            classname="text-danger"
            title={errors.n_schedule_day_id || errors.message}
          />
      </div>
    }
  </div>
  <div className="frllftfull">
    <div className="form-group">
      <Label className="ffgrd f-w-b" title ="Reason for visit" isMandatory="true" />
      <TextArea
        rows={4}
        isMandatory={true}
        content={this.state.queuein_data.other_comments}
        name={"other_comments"}
        controlFunc={this.handleQueueInput}
        placeholder={""}
        maxLength={100}
      />
      <SpanField
            classname="text-danger"
            title={errors.other_comments}
          />
    </div>
  </div>
  {this.state.selected_doc_schedule_type.toLowerCase()!='appointment'?
  (<div className="frllftfull">
     < >
            <div className="form-group frllft">
              <label>Payment Type</label>
            </div>
           
              <div className="form-group frlrit">
            {this.state.paymenttypeforhospital=="Online"?
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-check fltx">

                    <input
                      checked={this.state.payment_mode==="Online"}
                      className="form-check-input"
                      type="radio"
                      id="Online"
                      value="Online"
                      name="payment_mode"

                      onChange={this.handlePaymentSelect}


                    />
                    <label
                      className="form-check-label"
                      htmlFor="Pay online"
                    >
                      Online
                    </label>
                   
                  </div>
                  
                </div>
                <SpanField classname="text-danger" title={errors.payment_type_error} />
              </div>
               :""}
             {this.state.paymenttypeforhospital==="Direct"?
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-check fltx">
                  
                    <input
                     checked={this.state.payment_mode==="Direct"}
                      className="form-check-input"
                      type="radio"
                      id="Direct"
                      name="payment_mode"
                      value="Direct"
                      onChange={this.handlePaymentSelect}

                    />
                    <label
                      className="form-check-label"
                      htmlFor="Pay at Clinic"
                    >
                      Cash
                    </label>
                   
                  </div>
                 
                </div>
                <SpanField classname="text-danger" title={errors.payment_type_error} />
              </div>
              :""}
              </div>
            </>
          </div>):""}

      {this.state.payment_mode=="Online"?
        (<><div className="frllftfull">
          <div className="form-group frllft">
            <label>Consulting Fees</label>
          </div>
          <div className="form-group frlrit">
            {this.state.doctor_details ? this.state.doctor_details.consulting_fees : ""
            } INR
          </div>
        </div>
        <div className="frllftfull">
          <div className="form-group frllft">
            <label>Service Charges</label>
          </div>
          <div className="form-group frlrit">
            {10} INR
          </div>
        </div></>): ""}
        {this.state.payment_mode=="Direct"?
        (<div className="frllftfull">
          <div className="form-group frllft">
            <label>Service Charges</label>
          </div>
          <div className="form-group frlrit">
            {10} INR
          </div>
        </div>):""}

</Modal.Body>
<Modal.Footer>
    <div className="text-center w-100 m-0">
    {this.state.selected_doc_schedule_type.toLowerCase()=='appointment'?
    (<Button
    className=" signupbtn abtn pdrs"
     onClick={this.createAppointment.bind(this)} 

  >
    Next
  </Button>):""} &nbsp;&nbsp;
  {this.state.selected_doc_schedule_type.toLowerCase()!='appointment'?
 ( <Button
    className=" signupbtn abtn pdrs"
   /*  disabled={this.state.IsNext} */
     onClick={this.openbookQueueAppointment.bind(this)} 

  >
    Next
  </Button>):""} &nbsp;&nbsp;
  
  <Button
    onClick={this.closePopup}
    className=" bg-red signupbtn abtn pdrs"
  >
    Cancel
  </Button>
    </div>
  

</Modal.Footer>



</Modal>

        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ClientFavourites));
