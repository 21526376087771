import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckboxOrRadioGroup from '../components/UI/CheckboxOrRadioGroup';
import InputField from '../components/UI/InputField';
//import FooterSection from "../components/UI/FooterSection";
import axios from "axios";
import { CONFIG } from "../config/data";
import {
    consultDoctor, getMasterPharmacy, getCheckinDetails,getQEntryById, updatePrescription,
    UpdateQueueForCheckOut,sendemail,sendsms,updateclientappointmentstatus,getconsultingfeesforbusinessuser,updateconsultationfee,
    getBusinessShortDetail,getBusinessuserShortDetail,getPharmacydetailsbyid
} from "../config/url.json";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import SpanField from "../components/UI/SpanField";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Visibility } from '@material-ui/icons';
function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}
const followup = ['followup'];
class FollowupDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkindetails:{},
            followupData: {
                followup: 0,
                fee: 0,
                prescription: 0,
                pharmacy_array: [],
                pharmacy_details:"",
                follow_up_details:"",
                consultation_fee:"",  
                pharmacy_location:"", 
            },
          
            errors:
            {
                follow_up_details:"",
                consultation_fee:"",               

            },
            checkinData: {

            }
        }
    }
    componentDidMount() {
      

        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        console.log(this.props.id)
        const checkinData = { id: this.props.id };
        axios
            .post(getCheckinDetails, checkinData, CONFIG)
            .then((res) => {
                
                let data = res.data.data || res.data.date;
                if (res.data.status !== "1" || !data) {
                    return;
                }
                let followupData = this.state.followupData;
                followupData.client_id = data.client_id;
                followupData.business_id = data.business_id;
                followupData.consultation_fee= data.consultation_fee;
            followupData.follow_up_details = data.follow_up_details;
            followupData.pharmacy_details = data.pharmacy_details;
            followupData.prescription = data.prescription;
            followupData.followup = data.followup;
            followupData.fee = data.fee;
            console.log(followupData);
                // followupData.diagnosis = "ddd";
                followupData.id = data.id;

                this.setState({
                    checkinData: data,
                    checkindetails:res.data.data,
                    followupData: followupData,
                    pharmacy_name:this.state.followupData.pharmacy_details.label,
                    pharmacy_id:this.state.followupData.pharmacy_details.value
                });
                console.log(this.state.checkindetails);
                console.log(this.state.followupData.pharmacy_details.label);
                console.log(this.state.followupData.pharmacy_details.value);

                
                axios
                .post(getMasterPharmacy, {user_id: this.state.checkindetails.business_id}, CONFIG)
                .then((res) => {
                    console.log(res);
                    let pharmacy_array = res.data.data;
    
                    let pharmacyList = [];
    
                    pharmacy_array.forEach((element, index) => {
                        pharmacyList.push({
                            value: element.id,
                            label: element.pharmacy_name,
                        });
                    });
    
                    this.setState({ pharmacy_array: pharmacyList });
                })
               
        
                axios
                .post(getBusinessShortDetail, { business_id: this.state.checkindetails.business_id }, CONFIG)
                .then((res) => {
                  let response = res.data;
                  console.log(response);
                  // return false;
                  if (response.status !== "0") {
                    
                    this.setState({ hospital_details: response.data[0],hospital_address:response.data[0].address1,
                      business_name:response.data[0].business_name,hospital_city:response.data[0].city,
                      hospital_state:response.data[0].state,hospital_zipcode:response.data[0].zip_code });
                  }
                  console.log(this.state.hospital_details);
                  console.log(this.state.hospital_address);

                })
      
                .catch((e) => { });
                if(followupData.consultation_fee === "" || followupData.consultation_fee === null || followupData.consultation_fee === undefined){
                    let reqdata = {
                        business_id:this.state.checkindetails.business_id,
                        business_user_id: this.props.userdata.id,
                       
                      };
                    axios
                        .post(getconsultingfeesforbusinessuser, reqdata, CONFIG)
                        .then((res) => {
                            
                            let data = res.data;
                            console.log(res);
                            console.log(data);
                            console.log(data.data[0].consulting_fees);
                            followupData.consultation_fee =data.data[0].consulting_fees; 
                            if (data.status === "1"){
                                this.setState({
                                    consultation_fee: data.data[0].consulting_fees,
                                    followupData
                                    
                                  });
                            }
                  
                   
                    
                  })
                .catch((e) => { });
                }
               
                       
        
    
            }).catch((e) => { });

            axios
            .post(getBusinessuserShortDetail,{business_user_id:this.props.userdata.id}, CONFIG)
            .then((res) => {
              let response = res.data;
              console.log(response.data);
              console.log(response.data.last_name);

              // return false;
              if (response.status == "1") {
                // window.location.href = "/vendor/" + response.data;
                this.setState({ doctor_name: response.data.first_name +' '+ response.data.last_name,speciality_name:response.data.speciality_name });
              }
        
            })
            .catch((e) => {
              console.log(e);
            });

            
    }
    updateData = (e) => {
        // let data = this.state.followupData;
        // axios.defaults.headers.common[
        //     "Authorization"
        // ] = `Bearer ${this.props.access_token}`;
        // this.setState({ loading: true }, () => {
        //     axios
        //         .post(consultDoctor, data, CONFIG)
        //         .then((res) => {
        //             this.setState({ loading: false });
        //             const result = res.data;
        //             if (result.status === "1") {
        //                 this.setState({ successMessage: result.message });
        //             } else {
        //                 this.setState({
        //                     errorMessage:
        //                         result.message || "Failed to submit prescription details",
        //                 });
        //             }
        //         })
        //         .catch((e) => {
        //             this.setState({
        //                 loading: false,
        //                 errorMessage: "Failed to submit consultant details",
        //             });
        //         });
            this.props.history.push("/print_prescription/" + this.props.match.params.id + "/doctor");
            this.props.history.replace({
              pathname: "/print_prescription/" + this.props.match.params.id + "/doctor",
              state: {
                business_id:this.state.checkinData.business_id,
                Reg_id:this.state.checkinData.Reg_id,
                patient_name:this.state.checkinData.first_name,
                business_user_id:this.state.checkinData.business_user_id,
                business_name:this.state.business_name,
                doctor_name:this.state.doctor_name,
                speciality_name:this.state.speciality_name,
                pharmacy_name:this.state.followupData.pharmacy_details.label,
                pharmacy_location: this.state.pharmacy_location,
                hospital_address:this.state.hospital_address,
                hospital_city:this.state.hospital_city,
                hospital_state:this.state.hospital_state,
                hospital_zipcode:this.state.hospital_zipcode
              }
            });
        // });
    }
    CheckOut = (e) => {

        this.setState({ loading: true }) 
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.access_token}`;
        let clientapppointmentId = this.state.checkinData.q_id;
        let checkindetails = this.state.checkindetails;
        let followupData = this.state.followupData;
        console.log(this.state.checkindetails);
        console.log(this.state.followupData);
        let msgreqdata = {
            "to" : checkindetails.email,
            "subject" : "Followup Remainder",
            "mailHeader" : "Followup Remainder",
            "message": `Dear ${checkindetails.first_name}, <p>Just a friendly remainder,you have an upcoming appointment in ${followupData.follow_up_details} days at ${checkindetails.business_name} for checkups.<p> Please arrive 10 mins prior to your appointment time.Thank you.</p><p>Sincerely,</p><p>KlinicQ Team.</p> `
        }
        console.log(msgreqdata);
        let notificationdata = {
            "message_code": "FOLLOW_UP",
            "language_code": "en",
            "data": {
              "patient_name": checkindetails.first_name,
              "followup_days": followupData.follow_up_details,
              "business_name":checkindetails.business_name,
              // patient_name,followup_days,business_name
      
            },
            "receiver": {
              "phoneNumber": checkindetails.phone_code.concat(checkindetails.phone_number)
            }
          };
          console.log(notificationdata);
        //  return false;
        if(checkindetails.schedule_type == 'appointment' && checkindetails.appointment_type =='inperson'||checkindetails.schedule_type == 'appointment' && checkindetails.appointment_type =='inperson_direct')
        {
             let reqdata = {
                "order_id": "",
                 "payment_id": "",
                  "amount": "",
                  "appt_status":"Check-Out",
                  "payment_status": "",
                     "checkin_id":checkindetails.id,
                         "client_appt_id": checkindetails.q_id
                              };
                            console.log(checkindetails);

// return false;

                  axios
                  .post(updateclientappointmentstatus, reqdata, CONFIG)
                  .then((response) => {
                    if(checkindetails.follow_up_details != "" && checkindetails.email != ""){
                        
                       


                        
                        console.log(msgreqdata);
                          // console.log(this.props.userdata);
                          
                          axios
                            .post(sendemail, msgreqdata)
                            .then((res) => {
                              let resdata = res.data;                            
                              axios
                              .post(sendsms, notificationdata)
                              .then((result) => {
                                console.log(result)
                              }).catch((err) => {
                                console.log(err);
                              })
                              console.log(resdata);
                              this.setState({ loading: false });
                              let data = {
                                message:"",
                                status:""
                              }
                              if (resdata===true) {
                                data.error_type = "success";
                                data.message = "Referral email send successfully"
                                data.status = "1"
                                this.setState({
                                  formSuccess: data,
                                });
                                this.setState({ loading: false });
                                
                              } else {
                                data.error_type = "error";
                                data.message = "Failed"
                                data.status = "0"
                                this.setState({
                                  formSuccess: data,
                                });
                                this.setState({ loading: false });
                               
                              }
                            })
                            .catch((e) => { });
                
                      }
                      this.setState({ loading: false })
      
      
                      // this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
                    
                      toast.success("Check out Completed Successfully.",{
                        className:"toast-success"
                      });
                    
                     setTimeout(
                        () =>
                        window.location.href = "/consult",
                        5000
                    );
         
                      
                  }).catch((error) => {
                    console.log("Update queue failed");
                  })
        }
        else if(checkindetails.schedule_type == 'appointment' && checkindetails.appointment_type=='online'){
            let reqdata = {
               "order_id": "",
                "payment_id": "",
                 "amount": "",
                 "appt_status":"Completed",
                 "payment_status": "",
                    "checkin_id":checkindetails.id,
                        "client_appt_id": checkindetails.q_id
                             };
                           console.log(checkindetails);

// return false;

                 axios
                 .post(updateclientappointmentstatus, reqdata, CONFIG)
                 .then((response) => {
                   if(checkindetails.follow_up_details != "" && checkindetails.email != ""){
                       
                      


                       
                       console.log(msgreqdata);
                         // console.log(this.props.userdata);
                         
                         axios
                           .post(sendemail, msgreqdata)
                           .then((res) => {
                             let resdata = res.data;                            
                             axios
                             .post(sendsms, notificationdata)
                             .then((result) => {
                               console.log(result)
                             }).catch((err) => {
                               console.log(err);
                             })
                             console.log(resdata);
                             this.setState({ loading: false });
                             let data = {
                               message:"",
                               status:""
                             }
                             if (resdata===true) {
                               data.error_type = "success";
                               data.message = "Referral email send successfully"
                               data.status = "1"
                               this.setState({
                                 formSuccess: data,
                               });
                               this.setState({ loading: false });
                               
                             } else {
                               data.error_type = "error";
                               data.message = "Failed"
                               data.status = "0"
                               this.setState({
                                 formSuccess: data,
                               });
                               this.setState({ loading: false });
                              
                             }
                           })
                           .catch((e) => { });
               
                     }
                     this.setState({ loading: false })
     
     
                     // this.setState({ QueuesuccessMsg: "Queue status updated successfully" });
                   
                     toast.success("The Consultation was completed successfully.",{
                      className:"toast-success"
                    });
                   
                    setTimeout(
                       () =>
                       window.location.href = "/consult",
                       5000
                   );
        
                     
                 }).catch((error) => {
                   console.log("Update queue failed");
                 })
       } else {
            axios.post(getQEntryById, { q_id: clientapppointmentId }, CONFIG)
            .then((queue_data) => {
      
              if (queue_data.data.status === '1') {
                let queuein_data = queue_data.data.data[0]
                queuein_data.status = 'Check-Out'
                this.setState({ queue_data: queuein_data })
                let queuedata = {
                  "n_business_id": queuein_data.business_id,
                  "n_business_user_id": queuein_data.business_user_id,
                  "n_schedule_day_id": queuein_data.schedule_day_id,
                  "n_q_id": queuein_data.q_id,
                  "n_status": "Check-Out",
                  "n_schedule_type": "CHKOUT",
                  "n_preference": "1"
                }
      
                // return false;
                //   {
                //     "n_business_id": "7405dfa0-a5c4-11ea-ba30-05b511e3603e",
                //     "n_schedule_day_id": "f4e6f51b-bdc8-11eb-97e3-0ad8e4601dac",   
                //     "n_status": "Check-Out",   
                //     "n_business_user_id": "ef98dcf0-bdc8-11eb-a631-39b54728a3e8",
                //     "n_schedule_type": "CHKOUT",
                //     "n_q_id":"2a8efbc1-035b-452c-96fd-f1f2f531885f",
                //     "n_preference":"1"
                // }
              
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${this.props.access_token}`;
                axios
                  .post(UpdateQueueForCheckOut, queuedata)
                  .then((response) => {
      
      
      
      
      if(checkindetails.follow_up_details != ""){
               
        console.log(msgreqdata);
        // console.log(this.props.userdata);
        
        axios
          .post(sendemail, msgreqdata)
          .then((res) => {
            let resdata = res.data;                            
            axios
            .post(sendsms, notificationdata)
            .then((result) => {
              console.log(result)
            }).catch((err) => {
              console.log(err);
            })
            console.log(resdata);
            this.setState({ loading: false });
            let data = {
              message:"",
              status:""
            }
            if (resdata===true) {
              data.error_type = "success";
              data.message = "Referral email send successfully"
              data.status = "1"
              this.setState({
                formSuccess: data,
              });
              this.setState({ loading: false });
              
            } else {
              data.error_type = "error";
              data.message = "Failed"
              data.status = "0"
              this.setState({
                formSuccess: data,
              });
              this.setState({ loading: false });
             
            }
          })
          .catch((e) => { });
      }
                  
      
      
      
                    let data = { id: this.props.match.params.id, business_id: queuein_data.business_id };
                    axios.post(updatePrescription, data, CONFIG).then((list) => {
                        toast.success("Check out Completed Successfully.",{
                            className:"toast-success"
                          });  window.location.href = "/consult";
                      return false
      
                    }).catch((e) => {
                      console.log("Update queue failed");
                      toast.error("Check out could not be Completed.",{
                        className:"toast-error"
                      });
                    });
                    this.setState({ loading: false })
      
      
                    // setTimeout(
                    //     () =>
                    //     toast.success("Check out Completed Successfully.",{
                    //         className:"toast-success"
                    //       }),  window.location.href = "/consult",
                    //     5000
                    // );
                
                    // this.props.history.push("/consult");
      
                  }).catch((e) => {
                    console.log("Update queue failed");
                  });
              }
            }).catch((e) => {
              console.log("Get appoinment by id failed");
            });

        }
        
    
      }
    handleCheckbox = (e) => {
        console.log(e.target.checked);
        console.log(e.target.name);
        let data = this.state.followupData;
        let name = e.target.name;
        data[name] = e.target.checked === true ? 1 : 0;
        data.client_id = this.state.checkinData.client_id;
        data.business_id = this.state.checkinData.business_id;
        data.consultation_fee=data.consultation_fee;
        
        this.setState({ followupData: data });
        console.log(data)
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        this.setState({ loading: true }, () => {
            axios
                .post(consultDoctor, data, CONFIG)
                .then((res) => {
                    this.setState({ loading: false });
                    const result = res.data;
                    if (result.status === "1") {
                        this.setState({ successMessage: result.message });
                    } else {
                        this.setState({
                            errorMessage:
                                result.message || "Failed to submit prescription details",
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        loading: false,
                        errorMessage: "Failed to submit consultant details",
                    });
                });
        });
    }
    validateMaxLength(name, value, max) {
        let data = this.state.followupData;
        let errors = this.state.errors;
        if(name==="follow_up_details"||name==="consultation_fee")
        {
            value = value.replace(/[^0-9]/ig,'');
        }
        if (value.length > max) {
          const value1 = value.slice(0, max);
          errors[name] = "Maximum Length has exceeded.";
          data[name] = value1;
        } else {
          errors[name] = "";
          data[name] = value;
        }
        this.setState({ followupData: data, errors: errors });
      }
    
    handleInput = (e) => {
        let data = this.state.followupData;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;
        console.log(max);
        
        if (
            name === "follow_up_details" ) 
            {  
              
              
          
            this.validateMaxLength(name, value, max);
          }
                  
           else
           {
            data[name] = value;
          }
          this.setState({ followupData: data });


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        this.setState({ loading: true }, () => {
            axios
                .post(consultDoctor, data, CONFIG)
                .then((res) => {
                    this.setState({ loading: false });
                    const result = res.data;
                    if (result.status === "1") {
                        this.setState({ successMessage: result.message });
                    } else {
                        this.setState({
                            errorMessage:
                                result.message || "Failed to submit prescription details",
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        loading: false,
                        errorMessage: "Failed to submit consultant details",
                    });
                });
        });
    }
    handleconsultationfeeInput = (e) => {
        let data = this.state.followupData;
        let name = e.target.name;
        let value = e.target.value;
        let max = e.target.max;
        console.log(max);
        this.setState({ followupData: data }); 
        if ( name === "consultation_fee" ) 
            {  
              
              
          
            this.validateMaxLength(name, value, max);
          }
                  
           else
           {
            data[name] = value;
          }
          this.setState({ followupData: data });


        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        
    axios
    .put(updateconsultationfee, { id: this.props.id,consultation_fee:data.consultation_fee }, CONFIG)
    .then((res) => {
      let data = res.data.data || res.data.date;
      if (res.data.status !== "1" || !data) {
        return;
      }
    }).catch((e) => { });

    }

    handleselectChange = (selectedOptions, e) => {
        console.log(selectedOptions);
        console.log(e.name);
        let data = this.state.followupData;
        data[e.name] = selectedOptions;
        console.log(selectedOptions);
        console.log(selectedOptions.value);
        this.setState({ followupData: data });

        axios
        .post(getPharmacydetailsbyid,{id:this.state.followupData.pharmacy_details.value}, CONFIG)
        .then((res) => {
          //console.log(res);
          // console.log(res.data);
          let response = res.data;
          console.log(response.data[0]);
          console.log(response.data[0].pharmacy_location);

          // return false;
          if (response.status == "1") {
            // window.location.href = "/vendor/" + response.data;
            this.setState({ pharmacy_location: response.data[0].pharmacy_location });

            this.state.followupData.pharmacy_location=response.data[0].pharmacy_location;
            axios.defaults.headers.common[
              "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          this.setState({ loading: true }, () => {
              axios
                  .post(consultDoctor, data, CONFIG)
                  .then((res) => {
                      this.setState({ loading: false });
                      const result = res.data;
                      if (result.status === "1") {
                          this.setState({ successMessage: "Your Assessment has been updated successfully." });
                      } else {
                          this.setState({
                              errorMessage:
                                  result.message || "Failed to submit prescription details",
                          });
                      }
                  })
                  .catch((e) => {
                      this.setState({
                          loading: false,
                          errorMessage: "Failed to submit consultant details",
                      });
                  });
          });


          }
    
        })
        .catch((e) => {
          console.log(e);
        });

   
      };
    render() {
        const {
            followupData, pharmacy_array,errors,consultation_fee
        } = this.state;
        return (

            <div className="col-sm-12 pr0">
   <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                <div className="card h-100">
                    <div className="card-body">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-shipping" role="tabpanel" aria-labelledby="v-pills-shipping-tab">

                                <div class="row">

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Followup days</label>

                                        </div>

                                    </div>

                                    <div class="col-sm-4">
                                        {/* <CheckboxOrRadioGroup
                                            // title={'Which kinds of pets would you like to adopt?'}
                                            name={"followup"}
                                            type={"checkbox"}
                                            controlFunc={this.handleCheckbox}
                                            options={followup}
                                            selectedOptions={followupData.followup}
                                        /> */}
                                        <div
                                            className="custom-control custom-checkbox mb-2 mylft "
                                            data-toggle="tooltip"
                                            title=""
                                        >
                                            <input className="custom-control-input"
                                                type="checkbox"
                                                id="followup"

                                                name={"followup"}
                                                onChange={this.handleCheckbox}
                                                checked={followupData.followup}
                                            />
                                            <label className="custom-control-label" htmlFor="followup">
                                                Followup
                                            </label>

                                        </div>
                                        <div class="form-group">
                                            <input type="text" 
                                            onChange={this.handleInput} 
                                            name="follow_up_details" 
                                            class="form-control" 
                                            placeholder="Days"
                                            value={followupData.follow_up_details}
                                            max={5} />
                                            <SpanField
                          classname="text-danger"
                          title={errors.follow_up_details}
                        />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Consultation fees</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        {/* <CheckboxOrRadioGroup
                                            // title={'Which kinds of pets would you like to adopt?'}
                                            name={"fee"}
                                            type={"checkbox"}
                                            controlFunc={this.handleCheckbox}
                                            options={['fee']}
                                            selectedOptions={followupData.fee}
                                        /> */}
                                        <div
                                            className="custom-control custom-checkbox mb-2 mylft "
                                            data-toggle="tooltip"
                                            title=""
                                        >
                                            <input className="custom-control-input"
                                                type="checkbox"
                                                id="fee"

                                                name={"fee"}
                                                onChange={this.handleCheckbox}
                                                checked={followupData.fee}
                                            />
                                            <label className="custom-control-label" htmlFor="fee">
                                                Fee
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <input 
                                            onChange={this.handleconsultationfeeInput} 
                                            type="text" 
                                            name="consultation_fee" 
                                            class="form-control" 
                                            placeholder="Amount"
                                            value={followupData.consultation_fee}
                                            max={5} />
                                            <SpanField
                          classname="text-danger"
                          title={errors.consultation_fee}
                        />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label>Print prescription</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">

                                        <div
                                            className="custom-control custom-checkbox mb-2 mylft "
                                            data-toggle="tooltip"
                                            title=""
                                        >
                                            <input className="custom-control-input"
                                                type="checkbox"
                                                id="Prescription"

                                                name={"prescription"}
                                                onChange={this.handleCheckbox}
                                                checked={followupData.prescription}
                                            />
                                            <label className="custom-control-label" htmlFor="Prescription">
                                                Prescription
                                            </label>
                                        </div>
                                    </div>
                                    </div>
                                    <div className='row'>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label className='visibilityHideen'>Print Prescription</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div class="form-group">
                                            {/* <input type="text" class="form-control" placeholder="Amount" /> */}
                                            <Select maxMenuHeight={100}
                                                className=" p-0"
                                                name="pharmacy_details"
                                                value={
                                                    followupData.pharmacy_details
                                                }
                                                onChange={this.handleselectChange}
                                                options={pharmacy_array}
                                                isClearable={true}
                                            />

                                        </div>
                                        
                                    </div>
                                    
                                    </div>
                                    <div className='row'>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label className='visibilityHideen'>Print Prescription</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                    <div className="d-flex"><div className="">
                                    <button className=" signupbtn  pad-6  mr-3 " onClick={this.props.onPreviousClick}  >Previous</button>
                                    <button onClick={this.updateData} className="float-center signupbtn mr-3  pad-6">Print</button>&nbsp;
                                        <button onClick={this.CheckOut} className="float-center signupbtn mr-3   pad-6" >Check-Out</button>
                                       
                                        <button onClick={this.props.onNextClick} className=" signupbtn  pad-6 " >Next</button>
                                        </div></div>
                                    </div>
                                    </div>
                                
                               
                            </div>
                            
                        </div>
                    </div>
                   {/*  <FooterSection /> */}
                </div>
            </div>


        );
    }
}

export default withRouter(connect(
    mapStateToProps,
)(FollowupDetails)
);